/*
 * Copyright (C) 2019-2020 Lumity, Inc. - All Rights Reserved
 *
 * CONFIDENTIAL
 *
 * All information contained herein is, and remains the property of Lumity, Inc. and its partners, if any.
 * The intellectual and technical concepts contained herein are proprietary to Lumity, Inc. and its partners
 * and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless
 * prior written permission is obtained from Lumity, Inc.
 */
import React from 'react';
import { Button } from 'components/Atoms';
import './GridCellButton.scss';

const GridCellButton = (props) => {
  const { onClick, disabled, children , usernameCol } = props;
  return (
    <Button
      color="link"
      onClick={onClick}
      className={`grid-cell-button ${usernameCol}`}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default GridCellButton;
