/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import './FileDelete.scss';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import ConfirmationBox from '../../../../../../components/DialogBox/ConfirmationBox/ConfirmationBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteBillingFiles } from '../../../../actions/billingAction';
import { BILLING_FILE_PUBLISHED } from '../../../../constants';

class FileDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPublishedFile: false
    };
  }

  static propTypes = {
    onClose: PropTypes.func
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.deleteBillingFilesStatus !==
        prevProps.deleteBillingFilesStatus &&
      !this.props.deleteBillingFilesStatusError
    ) {
      NotificationManager.success('File deleted successfully');
      this.props.onClose();
    }
  }

  /*
    Delete billing files and history along with summary of submitted billing Id
  */
  deleteFiles = () => {
    const { status, id } = this.props.billingRow;
    if (BILLING_FILE_PUBLISHED === status) {
      this.setState({ isPublishedFile: true });
    } else {
      this.props.deleteBillingFiles(id);
    }
  };

  /*
    Confirmation from the user if the file is already published
  */
  toggleRemoveDocumentConfirmation = () => {
    this.setState((prevState) => ({
      isPublishedFile: !prevState.isPublishedFile
    }));
  };

  renderFileDeleteForm = () => {
    return (
      <div className="action-buttons">
        <Button
          className="cancel-btn"
          color="white"
          onClick={this.props.onClose}
        >
          Cancel
        </Button>

        <Button
          className="delete-btn"
          color="primary"
          onClick={this.deleteFiles}
        >
          Delete Billing File
        </Button>
      </div>
    );
  };

  render() {
    const { id } = this.props.billingRow;
    const { isPublishedFile } = this.state;
    return (
      <ApplicationWrapper>
        <div className="file-delete">
          <div className="text-style">
            Are you sure you want to delete this billing file? This action
            cannot be undone and will result in having any attached files
            removed.
          </div>
          {this.renderFileDeleteForm()}
        </div>
        <ConfirmationBox
          message={
            'This file is already published, Are you sure you want to delete?'
          }
          isOpen={isPublishedFile}
          toggle={() => this.toggleRemoveDocumentConfirmation()}
          confirmText={
            <>
              <FontAwesomeIcon icon="trash-alt" className="mr-2" />
              Delete
            </>
          }
          confirmCallback={() => {
            this.props.deleteBillingFiles(id);
            this.toggleRemoveDocumentConfirmation();
          }}
        />
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteBillingFiles: (billRecordId) =>
    dispatch(deleteBillingFiles(billRecordId))
});

const mapStateToProps = (state) => {
  const {
    deleteBillingFilesStatus,
    deleteBillingFilesStatusError
  } = state.billingReducer;
  return {
    deleteBillingFilesStatus,
    deleteBillingFilesStatusError
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileDelete);
