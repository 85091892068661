/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/fileImport.actionTypes';

const initialState = {
  fileImportHistory: {
    data: [],
    isFetching: false,
    error: null
  },
  importFileValidation: {
    data: null,
    inProgress: false,
    progress: 0,
    error: null
  },
  importFileSubmission: {
    inProgress: false,
    error: null
  },
  selectedImportData: {
    data: null,
    inProgress: false,
    error: null
  },
  downloadNotifiersStatus: {
    inProgress: false,
    error: null
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_FILE_IMPORT_HISTORY_IN_PROGRESS:
      return {
        ...state,
        fileImportHistory: {
          ...state.fileImportHistory,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_FILE_IMPORT_HISTORY_COMPLETED:
      return {
        ...state,
        fileImportHistory: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_FILE_IMPORT_HISTORY_FAILED:
      return {
        ...state,
        fileImportHistory: {
          ...state.fileImportHistory,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.VALIDATE_IMPORT_FILE_IN_PROGRESS:
      return {
        ...state,
        importFileValidation: {
          data: null,
          inProgress: true,
          progress: 0,
          error: null
        }
      };
    case ActionTypes.VALIDATE_IMPORT_FILE_PROGRESS:
      return {
        ...state,
        importFileValidation: {
          ...state.importFileValidation,
          progress: action.payload
        }
      };
    case ActionTypes.VALIDATE_IMPORT_FILE_COMPLETED:
      return {
        ...state,
        importFileValidation: {
          data: action.payload,
          inProgress: false,
          progress: 0,
          error: null
        }
      };
    case ActionTypes.VALIDATE_IMPORT_FILE_FAILED:
      return {
        ...state,
        importFileValidation: {
          ...state.importFileValidation,
          inProgress: false,
          progress: 0,
          error: action.payload
        }
      };
    case ActionTypes.SUBMIT_IMPORT_FILE_IN_PROGRESS:
      return {
        ...state,
        selectedImportData: {},
        importFileSubmission: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.SUBMIT_IMPORT_FILE_COMPLETED:
      return {
        ...state,
        importFileSubmission: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.SUBMIT_IMPORT_FILE_FAILED:
      return {
        ...state,
        importFileSubmission: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.PARSE_IMPORT_DATA_IN_PROGRESS:
      return {
        ...state,
        selectedImportData: { data: null, inProgress: true, error: null }
      };
    case ActionTypes.PARSE_IMPORT_DATA_COMPLETED:
      return {
        ...state,
        selectedImportData: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.PARSE_IMPORT_DATA_FAILED:
      return {
        ...state,
        selectedImportData: {
          data: null,
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.DOWNLOAD_NOTIFIERS_IN_PROGRESS:
      return {
        ...state,
        downloadNotifiersStatus: {
          inProgress: true,
          error: false
        }
      };
    case ActionTypes.DOWNLOAD_NOTIFIERS_COMPLETED:
      return {
        ...state,
        downloadNotifiersStatus: {
          inProgress: false,
          error: false
        }
      };
    case ActionTypes.DOWNLOAD_NOTIFIERS_FAILED:
      return {
        ...state,
        downloadNotifiersStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
