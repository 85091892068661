/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { connect } from 'react-redux';

export const isAuthorized = (allowIf, currentRoles) => {
  if (allowIf && currentRoles) {
    const allowIfList = Array.isArray(allowIf) ? allowIf : [allowIf];
    const currentRolesList = Array.isArray(currentRoles)
      ? currentRoles
      : [currentRoles];
    let count = 0;
    currentRolesList.forEach((role) => {
      if (allowIfList.indexOf(role) !== -1) {
        count++;
      }
    });

    return count > 0;
  }

  return false;
};

const mapStateToProps = (state) => {
  const { appBootupInfo } = state.AppBase.authReducer;
  return {
    appBootupInfo
  };
};

// No dispatches yet but defined to make connect semantic.
const mapDispatchToProps = () => {
  return {};
};

/**
 * Higher Order Component used to secure a given component.
 * @param {Component} Component The component to be secured.
 * @param {String | Array} roles The role(s) that the higher order component needs to check.
 */
export const permitIf = (Component, roles) => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )((props) => {
    const { appBootupInfo = {}, ...rest } = props;
    if (isAuthorized(roles, appBootupInfo.roles)) {
      return <Component {...rest} />;
    }
    return <Component {...rest} disabled />;
  });
};
