/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  DataTableWithServerPagination,
  ColumnFilter
} from 'components/DataTables';
import { SyncStatusBadge } from 'components/Badges';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from 'components/Atoms';
// constants
import { FULL_DATE_FORMAT } from 'constants/dateConstants';
import {
  ADAPTER_NAMES,
  MENU_ACTIONS,
  STATUS_STATES,
  VENDOR_IDS,
  INTEGRATION_TYPES
} from 'modules/Integrations/constants';
import { EMDASH_LITERAL } from 'constants/commonConstants';
// styles
import './HRIntegrationSyncStatusList.scss';
import HRISResponse from 'modules/Employers/pages/EmployerDetails/containers/HRISResponse';
import { isHRISDisabled } from 'modules/Integrations/util';
export const employerFilters = [
  { value: 'Addepar', label: 'Addepar' },
  { value: 'Alector, LLC', label: 'Alector, LL' },
  { value: 'Barkbox', label: 'Barkbox' }
];

export const fromFilters = [
  { value: 'BAMBOO_HR', label: 'BambooHR' },
  { value: 'WORKDAY', label: 'Workday' },
  { value: 'GENERIC_FILE_IMPORT', label: 'Generic File Import' }
];

export const toFilters = [{ value: 'Flock', label: 'Flock' }];

export const statusFilters = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'SYNCING', label: 'Syncing' },
  { value: 'SUCCESS', label: 'Success' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'PARTIAL_SUCCESS', label: 'Partial Success' }
];

const customStyle = {
  headCells: {
    inactiveSortStyle: {
      opacity: 1,
      // Target the sort icon when inactive
      span: {
        opacity: 1
      }
    }
  }
};

export const DOWNLOAD_CONFIG = 'Download Config File'
class HRIntegrationSyncStatusList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        status: [],
        from: []
        // employerName: [],
        // source: [],
        // target: []
      },
      isHrisResponseModalOpen: false,
      hrisResponseEmployerId: ''
    };
    this._refreshTimer = setInterval(() => {
      this.reloadList();
    }, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this._refreshTimer);
  }

  updateFilterValues = (selector) => (values) => {
    let filters = { ...this.state.filters };
    filters[selector] = values;
    this.setState({ filters: filters }, () => {
      this.reloadList();
    });
  };

  reloadList = () => {
    this.syncStatusList.triggerReload();
  };

  resetFilters = () => {
    this.setState(
      {
        filters: {
          status: [],
          from: []
          // employerName: [],
          // source: [],
          // target: []
        }
      },
      () => this.reloadList()
    );
  };

  getAllSyncDetails = (
    page,
    size,
    sort,
    query,
    type = INTEGRATION_TYPES.HRIS
  ) => {
    const { filters } = this.state;
    const { getAllSyncDetails, organizationId } = this.props;
    getAllSyncDetails({
      page,
      size,
      sort,
      query,
      filters,
      type,
      organizationId
    });
  };

  renderActionsCell = (row) => {
    const { syncStatus, source } = row;
    const { menuClick } = this.props;

    const failedMenuOptions = [
      {
        label: 'View Errors',
        action: MENU_ACTIONS.VIEW
      },
      {
        label: 'Download Data Error Report',
        action: MENU_ACTIONS.DOWNLOAD
      }
    ];
    let menuOptions = [];
    switch (source) {
      case ADAPTER_NAMES.GENERIC_FILE_IMPORT.code:
        if (
          syncStatus === STATUS_STATES.FAILED ||
          syncStatus === STATUS_STATES.PARTIAL_SUCCESS
        ) {
          menuOptions = [...failedMenuOptions];
        }
        menuOptions = [
          ...menuOptions,
          {
            label: 'Download File',
            action: MENU_ACTIONS.DOWNLOAD_FILE
          }
        ];
        break;
      default:
        if (
          syncStatus === STATUS_STATES.FAILED ||
          syncStatus === STATUS_STATES.PARTIAL_SUCCESS
        ) {
          menuOptions = [...failedMenuOptions];
        }
        menuOptions = [
          ...menuOptions,
          {
            label: DOWNLOAD_CONFIG,
            action: MENU_ACTIONS.DOWNLOAD_CONFIG_FILE
          }
        ];
        break;
    }
    if (
      !(
        syncStatus === STATUS_STATES.SYNCING ||
        syncStatus === STATUS_STATES.PENDING
      )
    ) {
      return (
        <UncontrolledDropdown className="actions">
          <DropdownToggle tag="a" className="cursor-pointer" caret>
            Select
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                this.setState({
                  isHrisResponseModalOpen: true,
                  hrisResponseEmployerId: row.employerId
                });
              }}
              disabled={isHRISDisabled(source, VENDOR_IDS)}
            >
              Download HRIS Response
            </DropdownItem>
            {menuOptions.map(({ label, action }, index) => (
              <DropdownItem
                key={index}
                onClick={() => menuClick(action, row)}
                disabled={
                  label === DOWNLOAD_CONFIG &&
                  syncStatus === null
                }
              >
                {label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  };

  toggleHrisResponseModal = () => {
    this.setState({
      isHrisResponseModalOpen: !this.state.isHrisResponseModalOpen
    });
  };

  render() {
    const { data, organizationId } = this.props;
    const { filters } = this.state;
    return (
      <>
        <DataTableWithServerPagination
          onRef={(ref) => (this.syncStatusList = ref)}
          className="list-hr-integrations"
          customStyle={customStyle}
          hideSearchBar
          noDataComponent={
            <div className="no-data-reset">
              <p className="message">No Records to Display</p>
              <Button color="link" onClick={this.resetFilters}>
                Reset
              </Button>
            </div>
          }
          columnData={[
            {
              name: 'Employer',
              selector: 'employerName',
              grow: 1.5,
              cell: ({ employerName, employerId }) => {
                return (
                  <Link
                    to={`/brokers/${organizationId}/employers/${employerId}/integrations`}
                  >
                    {employerName}
                  </Link>
                );
              }
            },
            {
              name: (
                <ColumnFilter
                  columnHeader="From"
                  filters={fromFilters}
                  selectedFilters={filters.from}
                  onFilter={this.updateFilterValues('from')}
                />
              ),
              selector: 'from',
              sortable: false,
              grow: 1.5,
              cell: ({ source }) =>
                (source && ADAPTER_NAMES[source].name) || '-'
            },
            {
              name: 'To',
              selector: 'to',
              sortable: false,
              grow: 1.5,
              cell: ({ target }) =>
                (target && ADAPTER_NAMES[target].name) || '-'
            },
            {
              name: 'Imported Time',
              selector: 'endedTs',
              sortable: false,
              grow: 2,
              format: (row) => {
                const { endedTs } = row;
                return endedTs
                  ? moment(endedTs).format(FULL_DATE_FORMAT)
                  : EMDASH_LITERAL;
              }
            },
            {
              name: (
                <ColumnFilter
                  columnHeader="Status"
                  filters={statusFilters}
                  selectedFilters={filters.status}
                  onFilter={this.updateFilterValues('status')}
                />
              ),
              grow: 3,
              selector: 'status',
              cell: ({ syncStatus }) => {
                return (
                  <div>
                    <SyncStatusBadge syncStatus={syncStatus} />
                  </div>
                );
              }
            },
            {
              name: 'Actions',
              grow: 1,
              ignoreRowClick: true,
              allowOverflow: true,
              cell: (data) => {
                return this.renderActionsCell(data);
              }
            }
          ]}
          fetchData={(page, size, sort, query, type = INTEGRATION_TYPES.HRIS) =>
            this.getAllSyncDetails(page, size, sort, query, type)
          }
          pageData={data}
        />
        <HRISResponse
          isOpen={this.state.isHrisResponseModalOpen}
          employerId={this.state.hrisResponseEmployerId}
          toggle={this.toggleHrisResponseModal}
        />
      </>
    );
  }
}

export default HRIntegrationSyncStatusList;
