/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import * as _ from 'lodash';
import { Button, Row, Col, Input, Alert, FormGroup } from 'components/Atoms';
import { TextFormGroup } from 'components/DataForm';
import Icon from 'components/Icons';

import '../DialogIntegrationDetails.scss';

// constants
import { ERROR_MSGS } from 'modules/Integrations/constants';

export const EmploymentStatusRow = ({
  lfiEmploymentStatus,
  lumityEmploymentStatus,
  lumityEmploymentStatuses,
  removeEmploymentStatusRow,
  updateEmploymentStatusRow,
  fieldErrors
}) => {
  return (
    <div className="lfi-employment-mapping-field-row">
      <Button
        color="link"
        onClick={removeEmploymentStatusRow}
        className="remove-icon"
      >
        <Icon icon="minus-circle" />
      </Button>
      <Row>
        <Col>
          <TextFormGroup
            labelDisplay="LFI Employment Status"
            inputName="lfiEmploymentStatus"
            inputValue={lfiEmploymentStatus}
            onChange={updateEmploymentStatusRow}
            placeholder="Enter LFI Employment Status"
            isInvalid={!!(fieldErrors && fieldErrors['lfiEmploymentStatus'])}
          />
        </Col>
        <Col>
          <FormGroup>
            <label>
              <b>Lumity Employment Status</b>
            </label>
            <Input
              type="select"
              name="lumityEmploymentStatus"
              onChange={updateEmploymentStatusRow}
              value={lumityEmploymentStatus}
              invalid={!!(fieldErrors && fieldErrors['lumityEmploymentStatus'])}
            >
              <option key="select" value="">
                Select Option
              </option>
              {lumityEmploymentStatuses.map((status) => {
                return (
                  <option key={status} value={status}>
                    {status}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export const EmploymentTypeRow = ({
  lfiEmploymentType,
  lumityEmploymentType,
  lumityEmploymentTypes,
  removeEmploymentTypeRow,
  updateEmploymentTypeRow,
  fieldErrors
}) => {
  return (
    <div className="lfi-employment-mapping-field-row">
      <Button
        color="link"
        onClick={removeEmploymentTypeRow}
        className="remove-icon"
      >
        <Icon icon="minus-circle" />
      </Button>
      <Row>
        <Col>
          <TextFormGroup
            labelDisplay="LFI Employment Type"
            inputName="lfiEmploymentType"
            inputValue={lfiEmploymentType}
            onChange={updateEmploymentTypeRow}
            placeholder="Enter LFI Employment Type"
            isInvalid={!!(fieldErrors && fieldErrors['lfiEmploymentType'])}
          />
        </Col>
        <Col>
          <FormGroup>
            <label>
              <b>Lumity Employment Type</b>
            </label>
            <Input
              type="select"
              name="lumityEmploymentType"
              onChange={updateEmploymentTypeRow}
              value={lumityEmploymentType}
              invalid={!!(fieldErrors && fieldErrors['lumityEmploymentType'])}
            >
              <option key="select" value="">
                Select Option
              </option>
              {lumityEmploymentTypes.map((status) => {
                return (
                  <option key={status} value={status}>
                    {status}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};
export class LFIEmploymentStatusMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employmentStatusMappings: props.selectedEmploymentStatusMappings || [
        {
          lfiEmploymentStatus: '',
          lumityEmploymentStatus: ''
        }
      ],
      employmentTypeMappings: props.selectedEmploymentTypeMappings || [
        {
          lfiEmploymentType: '',
          lumityEmploymentType: ''
        }
      ],
      employmentStatusErrors: [],
      employmentTypeErrors: [],
      statusErrorMsgs: [],
      typeErrorMsgs: []
    };
  }

  addError(index, errorFieldName, errors) {
    return {
      ...errors,
      [index]: {
        ...errors[index],
        [errorFieldName]: true
      }
    };
  }

  onSubmit = () => {
    let statusErrors = {};
    let typeErrors = {};
    let statusErrorMsgs = [];
    let typeErrorMsgs = [];
    let lfiEmploymentStatuses = [];
    let lumityEmploymentStatuses = [];
    let lfiEmploymentTypes = [];
    let lumityEmploymentTypes = [];
    const { employmentStatusMappings, employmentTypeMappings } = this.state;
    let updatedStatusMappings = employmentStatusMappings.filter(
      ({ lfiEmploymentStatus, lumityEmploymentStatus }, index) => {
        let filterCheck = true;
        if (_.isEmpty(lfiEmploymentStatus) && _.isEmpty(lumityEmploymentStatus))
          return false;
        if (_.isEmpty(lfiEmploymentStatus)) {
          statusErrors = this.addError(
            index,
            'lfiEmploymentStatus',
            statusErrors
          );
          statusErrorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          filterCheck = false;
        } else {
          if (lfiEmploymentStatuses.includes(lfiEmploymentStatus)) {
            statusErrors = this.addError(
              index,
              'lfiEmploymentStatus',
              statusErrors
            );
            statusErrorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
            filterCheck = false;
          } else {
            lfiEmploymentStatuses.push(lfiEmploymentStatus);
          }
        }
        if (_.isEmpty(lumityEmploymentStatus)) {
          statusErrors = this.addError(
            index,
            'lumityEmploymentStatus',
            statusErrors
          );
          statusErrorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          filterCheck = false;
        } else {
          if (lumityEmploymentStatuses.includes(lumityEmploymentStatus)) {
            statusErrors = this.addError(
              index,
              'lumityEmploymentStatus',
              statusErrors
            );
            statusErrorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
            filterCheck = false;
          } else {
            lumityEmploymentStatuses.push(lumityEmploymentStatus);
          }
        }
        return filterCheck;
      }
    );
    let updatedTypeMappings = employmentTypeMappings.filter(
      ({ lfiEmploymentType, lumityEmploymentType }, index) => {
        let filterCheck = true;
        if (_.isEmpty(lfiEmploymentType) && _.isEmpty(lumityEmploymentType))
          return false;
        if (_.isEmpty(lfiEmploymentType)) {
          typeErrors = this.addError(index, 'lfiEmploymentType', typeErrors);
          typeErrorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          filterCheck = false;
        } else {
          if (lfiEmploymentTypes.includes(lfiEmploymentType)) {
            typeErrors = this.addError(index, 'lfiEmploymentType', typeErrors);
            typeErrorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
            filterCheck = false;
          } else {
            lfiEmploymentTypes.push(lfiEmploymentType);
          }
        }
        if (_.isEmpty(lumityEmploymentType)) {
          typeErrors = this.addError(index, 'lumityEmploymentType', typeErrors);
          typeErrorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          filterCheck = false;
        } else {
          if (lumityEmploymentTypes.includes(lumityEmploymentType)) {
            typeErrors = this.addError(
              index,
              'lumityEmploymentType',
              typeErrors
            );
            typeErrorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
            filterCheck = false;
          } else {
            lumityEmploymentTypes.push(lumityEmploymentType);
          }
        }
        return filterCheck;
      }
    );
    if (_.isEmpty(statusErrors) && _.isEmpty(typeErrors)) {
      if (_.isEmpty(updatedStatusMappings)) updatedStatusMappings = null;
      if (_.isEmpty(updatedTypeMappings)) updatedTypeMappings = null;
      const employmentMappingsConfig = {
        employmentStatusMappings: updatedStatusMappings,
        employmentTypeMappings: updatedTypeMappings
      };
      this.props.onNext(employmentMappingsConfig);
    } else {
      this.setState({
        employmentStatusErrors: statusErrors,
        employmentTypeErrors: typeErrors,
        statusErrorMsgs: _.uniq(statusErrorMsgs),
        typeErrorMsgs: _.uniq(typeErrorMsgs)
      });
    }
  };

  addEmploymentStatusRow = () => {
    this.setState({
      employmentStatusMappings: [
        ...this.state.employmentStatusMappings,
        {
          lfiEmploymentStatus: '',
          lumityEmploymentStatus: ''
        }
      ]
    });
  };

  removeEmploymentStatusRow = (removeIndex) => (e) => {
    const { employmentStatusMappings, employmentStatusErrors } = this.state;
    const updatedStatusErrors = { ...employmentStatusErrors };
    const updatedEmploymentStatusMappings = employmentStatusMappings.filter(
      (_employmentStatus, index) => removeIndex !== index
    );
    if (updatedStatusErrors[removeIndex]) {
      delete updatedStatusErrors[removeIndex];
    }
    this.setState({
      employmentStatusMappings: updatedEmploymentStatusMappings,
      employmentStatusErrors: updatedStatusErrors
    });
  };

  updateEmploymentStatusRow = (updateIndex) => (e) => {
    const { name, value } = e.target;
    const {
      employmentStatusMappings,
      employmentStatusErrors: errors
    } = this.state;
    const updatedEmploymentStatusMappings = [...employmentStatusMappings];
    updatedEmploymentStatusMappings[updateIndex][name] = value;
    if (value && errors[updateIndex] && errors[updateIndex][name]) {
      delete errors[updateIndex][name];
      if (_.isEmpty(errors[updateIndex])) delete errors[updateIndex];
    }
    this.setState({
      employmentStatusMappings: updatedEmploymentStatusMappings
    });
  };

  renderEmploymentStatusMappings = () => {
    const { lumityEmploymentStatuses = [] } = this.props;
    const { employmentStatusMappings, employmentStatusErrors } = this.state;
    return employmentStatusMappings.map(
      ({ lfiEmploymentStatus, lumityEmploymentStatus }, index) => {
        return (
          <EmploymentStatusRow
            key={index}
            lfiEmploymentStatus={lfiEmploymentStatus}
            lumityEmploymentStatus={lumityEmploymentStatus}
            lumityEmploymentStatuses={lumityEmploymentStatuses}
            removeEmploymentStatusRow={this.removeEmploymentStatusRow(index)}
            updateEmploymentStatusRow={this.updateEmploymentStatusRow(index)}
            fieldErrors={employmentStatusErrors[index]}
          />
        );
      }
    );
  };

  addEmploymentTypeRow = () => {
    this.setState({
      employmentTypeMappings: [
        ...this.state.employmentTypeMappings,
        {
          lfiEmploymentType: '',
          lumityEmploymentType: ''
        }
      ]
    });
  };

  removeEmploymentTypeRow = (removeIndex) => (e) => {
    const { employmentTypeMappings, employmentTypeErrors } = this.state;
    const updatedTypeErrors = { ...employmentTypeErrors };
    const updatedEmploymentTypeMappings = employmentTypeMappings.filter(
      (_employmentType, index) => removeIndex !== index
    );
    if (updatedTypeErrors[removeIndex]) {
      delete updatedTypeErrors[removeIndex];
    }
    this.setState({
      employmentTypeMappings: updatedEmploymentTypeMappings,
      employmentTypeErrors: updatedTypeErrors
    });
  };

  updateEmploymentTypeRow = (updateIndex) => (e) => {
    const { name, value } = e.target;
    const { employmentTypeMappings, employmentTypeErrors: errors } = this.state;
    const updatedEmploymentTypeMappings = [...employmentTypeMappings];
    updatedEmploymentTypeMappings[updateIndex][name] = value;
    if (value && errors[updateIndex] && errors[updateIndex][name]) {
      delete errors[updateIndex][name];
      if (_.isEmpty(errors[updateIndex])) delete errors[updateIndex];
    }
    this.setState({
      employmentTypeMappings: updatedEmploymentTypeMappings
    });
  };

  renderEmploymentTypeMappings = () => {
    const { lumityEmploymentTypes = [] } = this.props;
    const { employmentTypeMappings, employmentTypeErrors } = this.state;
    return employmentTypeMappings.map(
      ({ lfiEmploymentType, lumityEmploymentType }, index) => {
        return (
          <EmploymentTypeRow
            key={index}
            lfiEmploymentType={lfiEmploymentType}
            lumityEmploymentType={lumityEmploymentType}
            lumityEmploymentTypes={lumityEmploymentTypes}
            removeEmploymentTypeRow={this.removeEmploymentTypeRow(index)}
            updateEmploymentTypeRow={this.updateEmploymentTypeRow(index)}
            fieldErrors={employmentTypeErrors[index]}
          />
        );
      }
    );
  };

  onPrevious = () => {
    const { employmentStatusMappings, employmentTypeMappings } = this.state;
    const employmentMappingsConfig = {
      employmentStatusMappings,
      employmentTypeMappings
    };
    this.props.onPrevious(employmentMappingsConfig);
  };

  render() {
    const { selectedVendor } = this.props;
    const {
      employmentStatusErrors,
      employmentTypeErrors,
      statusErrorMsgs,
      typeErrorMsgs
    } = this.state;
    return (
      <div className="step-container mapping-container employment-info-mapping__container">
        <div className="body">
          <p className="subtitle">{selectedVendor.name}</p>
          <h3 className="employment-mappings__subheading">Employment Status</h3>
          {!_.isEmpty(employmentStatusErrors) &&
            statusErrorMsgs.map((errorMsg, index) => (
              <Alert key={index} color="danger" className="error-alert">
                {errorMsg}
              </Alert>
            ))}
          {this.renderEmploymentStatusMappings()}
          <Row>
            <Button color="link" onClick={this.addEmploymentStatusRow}>
              +Add Employment Status Mapping
            </Button>
          </Row>
          <h3 className="employment-mappings__subheading">Employment Type</h3>
          {!_.isEmpty(employmentTypeErrors) &&
            typeErrorMsgs.map((errorMsg, index) => (
              <Alert key={index} color="danger" className="error-alert">
                {errorMsg}
              </Alert>
            ))}
          {this.renderEmploymentTypeMappings()}
          <Row>
            <Button color="link" onClick={this.addEmploymentTypeRow}>
              +Add Employment Type Mapping
            </Button>
          </Row>
        </div>
        <Row className="action-btns">
          <Col>
            <Button
              className="form-element"
              outline
              color="primary"
              size="lg"
              onClick={this.onPrevious}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              className="form-element"
              color="primary"
              size="lg"
              onClick={this.onSubmit}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
