/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import {
  GET_OFFERS,
  GET_DENTAL_OFFERS,
  GET_VISION_OFFERS,
  GET_LIFE_OFFERS,
  GET_OFFERS_ERROR,
  CREATE_OFFER,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_ERROR,
  UPDATE_PLAN_CONTRIBUTIONS,
  UPDATE_PLAN_CONTRIBUTIONS_ERROR,
  GET_MEDICAL_OFFERS_TO_PUBLISH,
  GET_DENTAL_OFFERS_TO_PUBLISH,
  GET_VISION_OFFERS_TO_PUBLISH,
  GET_LIFE_OFFERS_TO_PUBLISH,
  GET_OFFERS_TO_PUBLISH_ERROR,
  ADD_PLANS_TO_OFFER_IN_PROGRESS,
  ADD_PLANS_TO_OFFER_SUCCESS,
  ADD_PLANS_TO_OFFER_ERROR,
  GET_OFFER_BUNDLE_IN_PROGRESS,
  GET_OFFER_BUNDLE_SUCCESS,
  GET_OFFER_BUNDLE_ERROR,
  GET_CARRIERS_IN_PROGRESS,
  GET_CARRIERS_SUCCESS,
  GET_CARRIERS_ERROR
} from '../actionTypes/offer.actionsTypes';

export default (
  state = {
    offers: {
      medicalOffers: [],
      dentalOffers: [],
      visionOffers: [],
      lifeOffers: [],
      offerError: null
    },
    offersToPublish: {
      medicalOffersToPublish: [],
      dentalOffersToPublish: [],
      visionOffersToPublish: [],
      lifeOffersToPublish: [],
      offersToPublishError: null
    },
    createOffer: {
      loading: false,
      offerError: null,
      createdBundle: null
    },
    planContribution: {
      updatedPlanContributions: false,
      updatedPlanContributionsError: null
    },
    addPlanToOffer: {
      inProgress: false,
      addToOfferError: null,
      isAddSuccess: false
    },
    fetchOffer: {
      loading: false,
      bundleData: null,
      error: null
    },
    offerCarriers: {
      carriersData: null,
      error: null,
      loading: false
    }
  },
  { type, payload }
) => {
  switch (type) {
    case GET_OFFERS:
      return {
        ...state,
        offers: {
          ...state.offers,
          medicalOffers: payload
        }
      };
    case GET_DENTAL_OFFERS:
      return {
        ...state,
        offers: {
          ...state.offers,
          dentalOffers: payload
        }
      };
    case GET_VISION_OFFERS:
      return {
        ...state,
        offers: {
          ...state.offers,
          visionOffers: payload
        }
      };
    case GET_LIFE_OFFERS:
      return {
        ...state,
        offers: {
          ...state.offers,
          lifeOffers: payload
        }
      };
    case GET_MEDICAL_OFFERS_TO_PUBLISH:
      return {
        ...state,
        offersToPublish: {
          ...state.offersToPublish,
          medicalOffersToPublish: payload
        }
      };
    case GET_DENTAL_OFFERS_TO_PUBLISH:
      return {
        ...state,
        offersToPublish: {
          ...state.offersToPublish,
          dentalOffersToPublish: payload
        }
      };
    case GET_VISION_OFFERS_TO_PUBLISH:
      return {
        ...state,
        offersToPublish: {
          ...state.offersToPublish,
          visionOffersToPublish: payload
        }
      };
    case GET_LIFE_OFFERS_TO_PUBLISH:
      return {
        ...state,
        offersToPublish: {
          ...state.offersToPublish,
          lifeOffersToPublish: payload
        }
      };
    case GET_OFFERS_ERROR:
      return {
        ...state,
        offers: {
          ...state.offers,
          offerError: payload
        }
      };
    case GET_OFFERS_TO_PUBLISH_ERROR:
      return {
        ...state,
        offers: {
          ...state.offersToPublish,
          offersToPublishError: payload
        }
      };
    case CREATE_OFFER:
      return {
        ...state,
        createOffer: {
          loading: true,
          offerError: null,
          createdBundle: null
        }
      };
    case CREATE_OFFER_SUCCESS:
      return {
        ...state,
        createOffer: {
          loading: false,
          offerError: null,
          createdBundle: payload
        }
      };
    case CREATE_OFFER_ERROR:
      return {
        ...state,
        createOffer: {
          loading: false,
          offerError: payload,
          createdBundle: null
        }
      };
    case UPDATE_PLAN_CONTRIBUTIONS:
      return {
        ...state,
        planContribution: {
          ...state.planContribution,
          updatedPlanContributions: true
        }
      };
    case UPDATE_PLAN_CONTRIBUTIONS_ERROR:
      return {
        ...state,
        planContribution: {
          updatedPlanContributions: false,
          updatedPlanContributionsError: payload
        }
      };
    case ADD_PLANS_TO_OFFER_IN_PROGRESS:
      return {
        ...state,
        addPlanToOffer: {
          inProgress: true,
          addToOfferError: null,
          isAddSuccess: false
        }
      };
    case ADD_PLANS_TO_OFFER_SUCCESS:
      return {
        ...state,
        addPlanToOffer: {
          inProgress: false,
          addToOfferError: null,
          isAddSuccess: true
        }
      };
    case ADD_PLANS_TO_OFFER_ERROR:
      return {
        ...state,
        addPlanToOffer: {
          inProgress: false,
          addToOfferError: payload,
          isAddSuccess: false
        }
      };
    case GET_OFFER_BUNDLE_IN_PROGRESS:
      return {
        ...state,
        fetchOffer: {
          loading: false,
          bundleData: null,
          error: null
        }
      };
    case GET_OFFER_BUNDLE_SUCCESS:
      return {
        ...state,
        fetchOffer: {
          loading: false,
          bundleData: payload,
          error: null
        }
      };
    case GET_OFFER_BUNDLE_ERROR:
      return {
        ...state,
        fetchOffer: {
          loading: false,
          bundleData: null,
          error: payload
        }
      };
    case GET_CARRIERS_IN_PROGRESS:
      return {
        ...state,
        offerCarriers: {
          carriersData: null,
          error: null,
          loading: true
        }
      };
    case GET_CARRIERS_SUCCESS:
      return {
        ...state,
        offerCarriers: {
          carriersData: payload,
          error: null,
          loading: false
        }
      };
    case GET_CARRIERS_ERROR:
      return {
        ...state,
        offerCarriers: {
          carriersData: null,
          error: null,
          loading: false
        }
      };
    default:
      return {
        ...state
      };
  }
};
