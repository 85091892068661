/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';

import { Input } from 'components/Atoms';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import AddButton from 'components/Buttons/AddButton';
import { DOCUMENTS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { permitIf } from 'components/hoc/Permit';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';

const ConfigureDocument = (props) => {
  const {
    switchDocumentEnabled,
    config,
    documentFeatureEnabled,
    isRootDocumentCreated
  } = props;

  const SecuredAddButton = permitIf(
    AddButton,
    DOCUMENTS_FEATURE_ROLE_MAPPING.common
  );
  return (
    <ContentContainer>
      <div>
        <h3>
          <b>Document Access</b>
        </h3>
      </div>
      <hr className="divider" />
      <EmployerConfigToggleV2
        iconClass="icon-calendar-style"
        message="Documents on ER Portal"
        config={{
          lastUpdatedTs: config.statusChangedTs,
          lastUpdatedByLoginId: config.statusChangedUser,
          value: String(documentFeatureEnabled)
        }}
        switchCallBack={switchDocumentEnabled}
        rolesList={DOCUMENTS_FEATURE_ROLE_MAPPING.common}
      ></EmployerConfigToggleV2>
    </ContentContainer>
  );
};

export default ConfigureDocument;
