/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
const MEDICAL_LIST_PATH =
  '/brokers/:brokerId/employers/:employerId/medical-plans';
const MEDICAL_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/medical-plans/create';
const MEDICAL_EDIT_PREFIX =
  '/brokers/:brokerId/employers/:employerId/medical-plans/:planId/edit';
const MEDICAL_EDIT_BASIC_PATH = MEDICAL_EDIT_PREFIX + '/basic';
const MEDICAL_EDIT_COVERAGE_PATH = MEDICAL_EDIT_PREFIX + '/coverage';
const MEDICAL_EDIT_SERVICES_PATH = MEDICAL_EDIT_PREFIX + '/services';
const MEDICAL_EDIT_RX_PATH = MEDICAL_EDIT_PREFIX + '/rx';
const MEDICAL_EDIT_RATES_PATH = MEDICAL_EDIT_PREFIX + '/rates';
const MEDICAL_VIEW_PREFIX =
  '/brokers/:brokerId/employers/:employerId/medical-plans/:planId/view';
const MEDICAL_VIEW_BASIC_PATH = MEDICAL_VIEW_PREFIX + '/basic';
const MEDICAL_VIEW_COVERAGE_PATH = MEDICAL_VIEW_PREFIX + '/coverage';
const MEDICAL_VIEW_SERVICES_PATH = MEDICAL_VIEW_PREFIX + '/services';
const MEDICAL_VIEW_RX_PATH = MEDICAL_VIEW_PREFIX + '/rx';
const MEDICAL_VIEW_RATES_PATH = MEDICAL_VIEW_PREFIX + '/rates';

const MEDICAL_CLONE_PREFIX =
  '/brokers/:brokerId/employers/:employerId/medical-plans/:planId/clone';
const MEDICAL_CLONE_BASIC_PATH = MEDICAL_CLONE_PREFIX + '/basic';
const MEDICAL_CLONE_COVERAGE_PATH = MEDICAL_CLONE_PREFIX + '/coverage';
const MEDICAL_CLONE_SERVICES_PATH = MEDICAL_CLONE_PREFIX + '/services';
const MEDICAL_CLONE_RX_PATH = MEDICAL_CLONE_PREFIX + '/rx';
const MEDICAL_CLONE_RATES_PATH = MEDICAL_CLONE_PREFIX + '/rates';

export {
  MEDICAL_LIST_PATH,
  MEDICAL_CREATE_PATH,
  MEDICAL_EDIT_PREFIX,
  MEDICAL_EDIT_BASIC_PATH,
  MEDICAL_EDIT_COVERAGE_PATH,
  MEDICAL_EDIT_SERVICES_PATH,
  MEDICAL_EDIT_RX_PATH,
  MEDICAL_EDIT_RATES_PATH,
  MEDICAL_VIEW_PREFIX,
  MEDICAL_VIEW_BASIC_PATH,
  MEDICAL_VIEW_COVERAGE_PATH,
  MEDICAL_VIEW_SERVICES_PATH,
  MEDICAL_VIEW_RX_PATH,
  MEDICAL_VIEW_RATES_PATH,
  MEDICAL_CLONE_PREFIX,
  MEDICAL_CLONE_BASIC_PATH,
  MEDICAL_CLONE_COVERAGE_PATH,
  MEDICAL_CLONE_SERVICES_PATH,
  MEDICAL_CLONE_RX_PATH,
  MEDICAL_CLONE_RATES_PATH
};
