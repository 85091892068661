/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import {
  CREATE_DOCUMENT,
  CREATE_DOCUMENT_ERROR,
  CREATE_FOLDER,
  CREATE_FOLDER_ERROR,
  CREATE_FOLDER_IN_PROGRESS,
  DELETE_DOCUMENT,
  GET_DOCUMENT_PAGE,
  GET_DOCUMENT_PAGE_ERROR,
  RENAME_DOCUMENT,
  RENAME_DOCUMENT_ERROR,
  RENAME_DOCUMENT_IN_PROGRESS,
  UPLOAD_FILE_IN_PROGRESS,
  GET_DOCUMENT_ENABLE_STATUS,
  GET_DOCUMENT_ENABLE_STATUS_ERROR,
  DELETE_DOCUMENT_IN_PROGRESS,
  DELETE_DOCUMENT_ERROR,
  GET_DOCUMENT_NAME_EXIST_OR_NOT
} from '../actionTypes/document.actionTypes';

export default (
  state = {
    documentsPageData: {
      content: null,
      metadata: null,
      path: [],
      materializedPath: ''
    },
    uploadDocument: {
      document: null,
      error: null,
      inProgress: null
    },
    renameDocument: {
      document: null,
      error: null
    },
    createFolder: {
      folder: null,
      error: null
    },
    deleteDocument: {
      document: null,
      error: null
    },
    documentsPageErrorResponse: null,
    documentErrorResponse: null,
    documentFeatureConfig: { enabled: false },
    documentNameExist: null
  },
  { type, payload }
) => {
  switch (type) {
    case GET_DOCUMENT_PAGE:
      return {
        ...state,
        documentsPageData: payload
      };
    case GET_DOCUMENT_PAGE_ERROR:
      return {
        ...state,
        documentsPageErrorResponse: payload
      };
    case CREATE_DOCUMENT:
      return {
        ...state,
        uploadDocument: {
          document: payload,
          inProgress: false,
          error: null
        }
      };
    case CREATE_DOCUMENT_ERROR:
      return {
        ...state,
        uploadDocument: {
          document: null,
          inProgress: false,
          error: payload
        }
      };
    case UPLOAD_FILE_IN_PROGRESS:
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          inProgress: true,
          error: null
        }
      };
    case CREATE_FOLDER:
      return {
        ...state,
        createFolder: {
          folder: payload,
          error: null
        }
      };
    case CREATE_FOLDER_IN_PROGRESS:
      return {
        ...state,
        createFolder: {
          ...state.createFolder,
          error: null
        }
      };
    case CREATE_FOLDER_ERROR:
      return {
        ...state,
        createFolder: {
          folder: payload,
          error: payload
        }
      };
    case RENAME_DOCUMENT:
      return {
        ...state,
        renameDocument: {
          document: payload,
          error: null
        }
      };
    case RENAME_DOCUMENT_IN_PROGRESS:
      return {
        ...state,
        renameDocument: {
          ...state.document,
          document: null,
          error: null
        }
      };
    case RENAME_DOCUMENT_ERROR:
      return {
        ...state,
        renameDocument: {
          document: null,
          error: payload
        }
      };
    case GET_DOCUMENT_ENABLE_STATUS:
      return {
        ...state,
        documentFeatureConfig: payload
      };
    case GET_DOCUMENT_ENABLE_STATUS_ERROR:
      return {
        ...state,
        documentFeatureConfig: { enabled: false }
      };
    case DELETE_DOCUMENT:
      return {
        ...state,
        deleteDocument: {
          document: payload.id,
          error: null
        }
      };
    case DELETE_DOCUMENT_IN_PROGRESS:
      return {
        ...state,
        deleteDocument: {
          ...state.deleteDocument,
          document: null,
          error: null
        }
      };
    case DELETE_DOCUMENT_ERROR:
      return {
        ...state,
        deleteDocument: {
          document: null,
          error: payload
        }
      };
    case GET_DOCUMENT_NAME_EXIST_OR_NOT:
      return {
        ...state,
        documentNameExist: payload
      };
    default:
      return {
        ...state
      };
  }
  /* eslint-enable */
};
