/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'components/Atoms';

import FullscreenModal from 'components/FullscreenModal';
import Notifications from 'components/Notifications';
import { DataTableWithClientPagination } from 'components/DataTables';
import PageActionButton from 'components/Buttons';

import './SimilarPlansModal.scss';

class SimilarPlansModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
  };
  static defaultProps = {
    isOpen: false
  };

  constructor(props) {
    super(props);

    this.state = {
      benAdmin: ''
    };
  }

  componentDidUpdate(prevProps) {
    const {
      isOpen,
      isValidating,
      validationError,
      isProcessing,
      processingError,
      parseError
    } = this.props;

    // Prevent multiple notifications from multiple modals by checking if open.
    if (isOpen) {
      if (
        !isValidating &&
        validationError &&
        isValidating !== prevProps.isValidating
      ) {
        Notifications.error('Failed to validate the import file.');
      }

      if (!isProcessing && isProcessing !== prevProps.isProcessing) {
        if (processingError) {
          Notifications.error('Failed to process the import file.');
        } else {
          Notifications.success('Successfully processed the import file.');
        }
      }

      if (parseError && parseError !== prevProps.parseError) {
        Notifications.error('Server error. Please try again later.');
      }
    }
  }

  resetContext = () => {
    this.setState({ benAdmin: '' });
  };

  goToReImportScreen = () => {
    const { selectedImportData } = this.props;

    const { source } = selectedImportData;
    this.setState({ benAdmin: source });
  };

  onSelectBenAdmin = (benAdmin) => {
    this.setState({ benAdmin });
  };

  sizeRanges = [
    {
      min: 0,
      max: 100,
      label: '0-100'
    },
    {
      min: 100,
      max: 250,
      label: '100-250'
    },
    {
      min: 250,
      label: '250+'
    }
  ];

  columnData = [
    {
      name: <div className="similar-plans-header">Employer</div>,
      selector: 'employer',
      sortable: false
    },
    {
      name: <div className="similar-plans-header">Plans</div>,
      selector: 'plans'
    },
    {
      name: <div className="similar-plans-header">Size</div>,
      selector: 'size'
    },
    {
      name: <div className="similar-plans-header">Region (HQ)</div>,
      selector: 'region'
    }
  ];

  getSize = (size) => {
    const value = this.sizeRanges.reduce(
      (prev, curr) =>
        prev
          ? prev
          : (!curr.max && curr.min < size) ||
            (curr.min <= size && curr.max >= size)
            ? curr.label
            : null,
      null
    );
    return value;
  };

  render() {
    const { isOpen, toggle, cluster, benefitCategory } = this.props;
    const uBenefitCategory = benefitCategory
      ? benefitCategory.toString().toUpperCase()
      : benefitCategory;
    let similarPlans = [];
    if (cluster && cluster.length > 0) {
      similarPlans = cluster.map((cItem) => {
        const { employerName, censusInfo = {} } = cItem;
        const { regionHQ, employerSize } = censusInfo;
        return {
          employer: employerName,
          region: regionHQ,
          size: this.getSize(employerSize),
          plans: cItem.planCountPerBenefitCategory[uBenefitCategory]
            ? cItem.planCountPerBenefitCategory[uBenefitCategory]
            : 0
        };
      });
    }
    return (
      <FullscreenModal
        isOpen={isOpen}
        toggle={toggle}
        onClosed={this.resetContext}
        title="Employers in Cluster"
        subTitle={`${similarPlans ? similarPlans.length : 0} Similar Employers`}
        closeText={'close'}
        modelClassName="similar-plans-modal"
      >
        <Row>
          <Col>
            <DataTableWithClientPagination
              columnData={this.columnData}
              rowData={similarPlans}
              pagination={false}
              hideSearchBar
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <PageActionButton onClick={toggle}>Close</PageActionButton>
          </Col>
        </Row>
      </FullscreenModal>
    );
  }
}

export default withRouter(
  connect((state) => {
    const {
      importFileValidation,
      importFileSubmission,
      selectedImportData
    } = state.Employees.fileImportReducer;
    return {
      isValidating: importFileValidation.inProgress,
      validationData: importFileValidation.data,
      validationError: importFileValidation.error,
      isProcessing: importFileSubmission.inProgress,
      processingError: importFileSubmission.error,
      selectedImportData: selectedImportData.data,
      parseError: selectedImportData.error
    };
  })(SimilarPlansModal)
);
