/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { Collapse } from 'reactstrap';

import SummaryRow from './SummaryRow';
import './SummaryPreview.scss';

class PlanSummaryRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { summary } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <SummaryRow
          showCollapseIcon={summary.tierSummaries.length > 0}
          name={summary.planName}
          summary={summary}
          className={
            summary.tierSummaries.length ? 'plan-row' : 'plan-row-wo-tiers'
          }
          onClickIcon={this.toggle}
          isOpen={isOpen}
        />
        <Collapse isOpen={isOpen} className="collapse-summary">
          {/* tierSummaries can be an empty [] */}
          {summary.tierSummaries.map((tierSummary) => (
            <SummaryRow
              key={`${summary.planName}_${tierSummary.tier}`}
              name={tierSummary.tier}
              summary={tierSummary}
              className="tier-row"
            />
          ))}
        </Collapse>
      </>
    );
  }
}

export default PlanSummaryRow;
