/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import PopupComp from 'reactjs-popup';
import './Confirmation.scss';
import { Button, Col, Row } from 'components/Atoms';

export default class Confirmation extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  closeModal() {
    this.setState({ open: false });
    if (this.props.onClose instanceof Function) {
      this.props.onClose();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({ open: nextProps.open });
    }
  }

  render() {
    const { className, confirmBtnText, onConfirm } = this.props;
    const contentStyle = {
      background: 'none',
      border: 'none',
      backgroundColor: 'transparent',
      marginTop: '5%',
      width: 'auto'
    };
    const overlayStyle = {
      backgroundColor: 'rgba(0,0,0,0.5)'
    };
    return <PopupComp
      className={`popup-modal ${className}`}
      open={this.state.open}
      contentStyle={contentStyle}
      overlayStyle={overlayStyle}
      closeOnDocumentClick
      onClose={this.closeModal.bind(this)}>
      <div className="confirm dialog">
        <div className="popup-title">{this.props.title}</div>
        <div className="popup-body">
          <div className="message">
            {this.props.message}
          </div>
          <div className="button-container">
            <Row>
              <Col>
                <Button color="secondary" className="cancel-button" size="lg" onClick={() => this.closeModal()} >
                                    Cancel
                </Button>
                <Button
                  color="success"
                  className="action-button"
                  size="lg"
                  onClick={(e) => (onConfirm && onConfirm instanceof Function) ? onConfirm(e) : e.preventDefault()} >
                  {confirmBtnText}
                </Button>
              </Col>
            </Row>
          </div>
        </div>

      </div>
    </PopupComp>;
  }
}
