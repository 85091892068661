/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import NumberFormat from 'react-number-format';

import { Row, Col, Label } from 'components/Atoms';
import { formatCurrency, formatBMKPlanCurrentValue } from 'util/commonUtil';
import { isInputAllowed } from '../../util';

import '../ModelingSplitInput/ModelingSplitInput.scss';

const ModelingInput = (props) => {
  const {
    isEditMode,
    planKey,
    title,
    onEventChange,
    onChange,
    plan,
    value = '',
    id,
    name,
    prefix = '$',
    inputType = 'text',
    suffix = '',
    type,
    format,
    placeholder,
    currentValue,
    isBenchMarkValue = false,
    isTextValue = false,
    decimalScale = 2,
    inValid
  } = props;

  return (
    <Row className="modeling-input">
      <Col className="current-plan-detail-line-inner">
        <Row>
          <Col xs="6">
            <Label className="plan-attr-lable">{title}</Label>
          </Col>
          <Col className="plan-attr-lable-text" xs={isBenchMarkValue && 3}>
            {!isEditMode && isBenchMarkValue ? (
              <Label className="plan-attr-lable-select-medical">
                {isTextValue || suffix
                  ? omitNulls(value) !== undefined
                    ? !format
                      ? formatBMKPlanCurrentValue(prefix, value, suffix)
                      : `${value}${suffix}`
                    : '-'
                  : value === '' || value === null
                  ? '-'
                  : formatCurrency(value)}
              </Label>
            ) : (
              <NumberFormat
                prefix={prefix}
                type={inputType}
                className={
                  inValid
                    ? 'float-right line-item-input is-invalid'
                    : 'float-right line-item-input'
                }
                id={id}
                name={name}
                value={value}
                onBlur={(event) =>
                  onEventChange(event, onChange, plan, type, planKey)
                }
                allowNegative={false}
                suffix={suffix}
                format={format}
                placeholder={placeholder}
                decimalScale={decimalScale}
                isAllowed={(values) => isInputAllowed(values, format)}
              />
            )}
          </Col>
          {isBenchMarkValue && (
            <Col xs="3" sm="3" className="text-right">
              <Label className="plan-attr-lable-current">
                {isTextValue || suffix
                  ? omitNulls(currentValue)
                    ? !format
                      ? formatBMKPlanCurrentValue(prefix, currentValue, suffix)
                      : `${currentValue}${suffix}`
                    : currentValue === 0 ? `${prefix}0${suffix}` : ''
                  : formatCurrency(currentValue)}
              </Label>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

const omitNulls = (stringValue) => {
  if (stringValue === null || stringValue === 'null' || stringValue === '') {
    return undefined;
  }
  return stringValue;
};

export default ModelingInput;
