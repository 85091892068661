/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const GET_ORGANISATIONS_LIST_IN_PROGRESS =
  'GET_ORGANISATIONS_LIST_IN_PROGRESS';
export const GET_ORGANISATIONS_COMPLETED = 'GET_ORGANISATIONS_COMPLETED';
export const GET_ORGANISATIONS_LIST_FAILED = 'GET_ORGANISATIONS_LIST_FAILED';

export const CREATE_ORGANISATION_IN_PROGRESS =
  'CREATE_ORGANISATION_IN_PROGRESS';
export const CREATE_ORGANISATION_COMPLETED = 'CREATE_ORGANISATION_COMPLETED';
export const CREATE_ORGANISATION_FAILED = 'CREATE_ORGANISATION_FAILED';

export const GET_ORGANISATIONS_PAGE_IN_PROGRESS =
  'GET_ORGANISATIONS_PAGE_IN_PROGRESS';
export const GET_ORGANISATIONS_PAGE_COMPLETED =
  'GET_ORGANISATIONS_PAGE_COMPLETED';
export const GET_ORGANISATIONS_PAGE_FAILED = 'GET_ORGANISATIONS_PAGE_FAILED';

export const UPDATE_ORGANISATION_IN_PROGRESS =
  'UPDATE_ORGANISATION_IN_PROGRESS';
export const UPDATE_ORGANISATION_COMPLETED = 'UPDATE_ORGANISATION_COMPLETED';
export const UPDATE_ORGANISATION_FAILED = 'UPDATE_ORGANISATION_FAILED';

export const GET_ORGANISATION_IN_PROGRESS = 'GET_ORGANISATION_IN_PROGRESS';
export const GET_ORGANISATION_COMPLETED = 'GET_ORGANISATION_COMPLETED';
export const GET_ORGANISATION_FAILED = 'GET_ORGANISATION_FAILED';

export const DELETE_ORGANIZATION_IN_PROGRESS =
  'DELETE_ORGANIZATION_IN_PROGRESS';
export const DELETE_ORGANIZATION_COMPLETED = 'DELETE_ORGANIZATION_COMPLETED';
export const DELETE_ORGANIZATION_FAILED = 'DELETE_ORGANIZATION_FAILED';
