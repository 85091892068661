/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import EmployerFeatureConfigurationService from 'services/EmployerFeatureConfigurationService';
import EmployerGroupConfigurationService from 'services/EmployerGroupConfigurationService';
import * as ActionTypes from '../actionTypes/currentPlansToggles.actionTypes';
import { MODULE_CURRENT_PLANS_ENABLED } from '../constants';

export const getCurrentPlansFeatureConfig = (employerId) => {
  //Get feature configuration
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_CURRENT_PLANS_FEATURE_CONFIGURATION });
    try {
      const response = await EmployerFeatureConfigurationService.findConfigStatus(
        employerId,
        MODULE_CURRENT_PLANS_ENABLED
      );
      dispatch({
        type: ActionTypes.GET_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_CURRENT_PLANS_FEATURE_CONFIGURATION_ERROR,
        payload: error
      });
    }
  };
};

export const createCurrentPlansFeatureConfig = (
  configKey,
  employerId,
  enableValue
) => {
  const config = {
    employerId: employerId,
    key: configKey,
    value: String(true)
  };

  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.createConfigModule(
        config
      );

      dispatch({
        type: ActionTypes.CREATE_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CREATE_CURRENT_PLANS_FEATURE_CONFIGURATION_ERROR,
        payload: error
      });
    }
  };
};

export const updateCurrentPlansFeatureConfig = (config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.UPDATE_CURRENT_PLANS_FEATURE_CONFIGURATION
      });

      await EmployerFeatureConfigurationService.updateConfigModule(config);
      const response = await EmployerFeatureConfigurationService.findConfigStatus(
        config.employerId,
        MODULE_CURRENT_PLANS_ENABLED
      );

      dispatch({
        type: ActionTypes.UPDATE_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_CURRENT_PLANS_FEATURE_CONFIGURATION_ERROR,
        payload: error
      });
    }
  };
};

export const getBenefitGroupConfig = (employerId, benefitGroupName) => {
  //Get employer benefit group toggle configuration
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_BENEFIT_GROUPS_CONFIGURATION });
    try {
      const response = await EmployerGroupConfigurationService.findGroupConfigStatus(
        employerId,
        benefitGroupName
      );
      dispatch({
        type: ActionTypes.GET_BENEFIT_GROUPS_CONFIGURATION_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BENEFIT_GROUPS_CONFIGURATION_ERROR,
        payload: error
      });
    }
  };
};

export const createBenefitGroupConfig = (
  employerId,
  benefitGroupName,
  enabled
) => {
  const config = {
    employerId: employerId,
    benefitGroupName: benefitGroupName,
    enabled: enabled
  };

  return async (dispatch) => {
    try {
      const response = await EmployerGroupConfigurationService.createGroupConfig(
        config
      );

      dispatch({
        type: ActionTypes.CREATE_BENEFIT_GROUPS_CONFIGURATION_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CREATE_BENEFIT_GROUPS_CONFIGURATION_ERROR,
        payload: error
      });
    }
  };
};

export const updateBenefitGroupConfig = (config) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.UPDATE_BENEFIT_GROUPS_CONFIGURATION });
      await EmployerGroupConfigurationService.updateGroupConfig(config);
      const response = await EmployerGroupConfigurationService.findGroupConfigStatus(
        config.employerId,
        config.benefitGroupName
      );

      dispatch({
        type: ActionTypes.UPDATE_BENEFIT_GROUPS_CONFIGURATION_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_BENEFIT_GROUPS_CONFIGURATION_ERROR,
        payload: error
      });
    }
  };
};

export const getBenefitGroupConfigList = (employerId) => {
  //Get employer benefit group toggle configurations list
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_BENEFIT_GROUP_CONFIGURATIONS_LIST });
    try {
      const response = await EmployerGroupConfigurationService.findAllGroupConfigs(
        employerId
      );
      dispatch({
        type: ActionTypes.GET_BENEFIT_GROUP_CONFIGURATIONS_LIST_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BENEFIT_GROUP_CONFIGURATIONS_LIST_ERROR,
        payload: error
      });
    }
  };
};
