/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import _ from 'lodash';
import * as ActionTypes from '../actionTypes/employer.actionTypes';

import EmployerService from 'services/EmployerService';
import AdapterService from 'services/AdapterService';
import { EmployerSource, FlockBenAdminURL } from 'constants/employerConstants';
import EmployerFeatureConfigurationService from '../../../services/EmployerFeatureConfigurationService';
import { EmployerSourceType } from '../constants';

export const getEmployersPage = (
  page,
  size,
  sort,
  query,
  types,
  locations,
  archived,
  organizationId
) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_EMPLOYERS_PAGE_IN_PROGRESS });
    EmployerService.findAll(
      page,
      size,
      sort,
      query,
      types,
      locations,
      archived,
      organizationId
    )
      .then((response) =>
        dispatch({
          type: ActionTypes.GET_EMPLOYERS_PAGE_COMPLETED,
          payload: response.data
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_EMPLOYERS_PAGE_FAILED,
          payload: error.response
        })
      );
  };
};

export const updateEmployer = (oldEmployer, newEmployer, newLogo) => {
  //remove address property before comparing objects
  delete newEmployer.address;

  const employerId = oldEmployer.id;
  return async (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_EMPLOYER_IN_PROGRESS });
    try {
      if (!_.isEqual(oldEmployer, newEmployer)) {
        await EmployerService.update(employerId, newEmployer);
      }

      if (newLogo) {
        newEmployer.logoUrl = (
          await EmployerService.uploadLogo(employerId, newLogo)
        ).data;
      }

      dispatch({
        type: ActionTypes.UPDATE_EMPLOYER_COMPLETED,
        payload: newEmployer
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_EMPLOYER_FAILED,
        payload: error.response.data ? error.response.data : error.response
      });
    }
  };
};

export const deleteEmployer = (employerId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.DELETE_EMPLOYER_IN_PROGRESS });
    EmployerService.deleteEmployer(employerId)
      .then(() => dispatch({ type: ActionTypes.DELETE_EMPLOYER_COMPLETED }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.DELETE_EMPLOYER_FAILED,
          payload: error.response
        })
      );
  };
};

export const archiveEmployer = (employerId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.ARCHIVE_EMPLOYER_IN_PROGRESS });
    EmployerService.archiveEmployer(employerId)
      .then(() => dispatch({ type: ActionTypes.ARCHIVE_EMPLOYER_COMPLETED }))
      .catch((error) =>
        dispatch({
          type: ActionTypes.ARCHIVE_EMPLOYER_FAILED,
          payload: error.response
        })
      );
  };
};

export const createEmployer = (formData, logo) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CREATE_EMPLOYER_IN_PROGRESS });
    try {
      const employer = (
        await EmployerService.create({
          ...formData
        })
      ).data;
      if (employer) {
        if (logo) {
          await EmployerService.uploadLogo(employer.id, logo);
        }
      }
      dispatch({
        type: ActionTypes.CREATE_EMPLOYER_COMPLETED
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CREATE_EMPLOYER_FAILED,
        payload: error.response.data ? error.response.data : error.response
      });
    }
  };
};

export const findEmployerById = (employerId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_EMPLOYER_IN_PROGRESS });
    EmployerService.findById(employerId)
      .then((response) =>
        dispatch({
          type: ActionTypes.GET_EMPLOYER_COMPLETED,
          payload: response.data
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_EMPLOYER_FAILED,
          payload: error.response
        })
      );
  };
};

export const previewEmployer = (formData) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.PREVIEW_EMPLOYER_IN_PROGRESS });
    AdapterService.findEmployer({
      ...formData,
      employerSource: EmployerSource.LUMITY.value
    })
      .then((response) =>
        dispatch({
          type: ActionTypes.PREVIEW_EMPLOYER_COMPLETED,
          payload: response.data
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.PREVIEW_EMPLOYER_FAILED,
          payload: error.response
        })
      );
  };
};

export const importEmployer = (formData) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.IMPORT_EMPLOYER_IN_PROGRESS });
    AdapterService.create({
      ...formData,
      employerSource: EmployerSource.LUMITY.value
    })
      .then(() =>
        dispatch({
          type: ActionTypes.IMPORT_EMPLOYER_COMPLETED
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.IMPORT_EMPLOYER_FAILED,
          payload: error.response
        })
      );
  };
};
export const getEmployerNotificationConfig = (employerId, key) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_EMPLOYER_NOTIFICATION_CONFIG_IN_PROGRESS
      });
      const response = await EmployerFeatureConfigurationService.findConfigStatus(
        employerId,
        key
      );
      dispatch({
        type: ActionTypes.GET_EMPLOYER_NOTIFICATION_CONFIG_COMPLETED,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_EMPLOYER_NOTIFICATION_CONFIG_FAILED,
        payload: error
      });
    }
  };
};
export const updateEmployerNotificationConfig = (
  configId,
  configKey,
  employerId,
  enableValue
) => {
  return async (dispatch) => {
    try {
      const config = {
        id: configId,
        employerId: employerId,
        key: configKey,
        value: String(enableValue === 'false')
      };
      dispatch({
        type: ActionTypes.UPDATE_EMPLOYER_NOTIFICATION_CONFIG_IN_PROGRESS
      });
      await EmployerFeatureConfigurationService.updateConfigModule(config);
      dispatch({
        type: ActionTypes.UPDATE_EMPLOYER_NOTIFICATION_CONFIG_COMPLETED,
        payload: true
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_EMPLOYER_NOTIFICATION_CONFIG_FAILED,
        payload: error
      });
    }
  };
};
export const getHRISResponses = (employerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_EMPLOYER_HRIS_RESPONSE_IN_PROGRESS
      });
      const response = await AdapterService.getHRISResponses(employerId);
      dispatch({
        type: ActionTypes.GET_EMPLOYER_HRIS_RESPONSE_COMPLETED,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_EMPLOYER_HRIS_RESPONSE_FAILED,
        payload: error
      });
    }
  };
};
export const getBenAdminResponses = (employerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_EMPLOYER_BEN_ADMIN_RESPONSE_IN_PROGRESS
      });
      const response = await AdapterService.getBenAdminResponses(employerId);
      dispatch({
        type: ActionTypes.GET_EMPLOYER_BEN_ADMIN_RESPONSE_COMPLETED,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_EMPLOYER_BEN_ADMIN_RESPONSE_FAILED,
        payload: error
      });
    }
  };
}
export const getEmployerIndustries = () => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_EMPLOYER_INDUSTRIES_IN_PROGRESS });
    await EmployerService.getEmployerIndustries()
      .then((response) =>
        dispatch({
          type: ActionTypes.GET_EMPLOYER_INDUSTRIES_COMPLETED,
          payload: response.data
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_EMPLOYER_INDUSTRIES_FAILED,
          payload: error.response
        })
      );
  };
};
