/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/location.actionTypes';

const initialState = {
  locationsPage: {
    data: {
      content: [],
      metadata: {}
    },
    isFetching: true,
    error: null
  },
  locationDetails: {
    data: {},
    isUpdating: false,
    isCreating: false,
    error: null
  },
  deleteLocationStatus: {
    inProgress: false,
    error: null
  },
  createLocationStatus: {
    inProgress: false,
    error: null
  },
  locationList: {
    data: [],
    inProgress: false,
    error: null
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_LOCATIONS_PAGE_IN_PROGRESS:
      return {
        ...state,
        locationsPage: {
          ...state.locationsPage,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_LOCATIONS_PAGE_COMPLETED:
      return {
        ...state,
        locationsPage: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_LOCATIONS_PAGE_FAILED:
      return {
        ...state,
        locationsPage: {
          ...state.locationsPage,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_LOCATION_BY_ID_IN_PROGRESS:
      return {
        ...state,
        locationDetails: {
          ...state.locationDetails,
          isFetching: true,
          isUpdating: false,
          isCreating: false,
          error: null
        }
      };
    case ActionTypes.GET_LOCATION_BY_ID_COMPLETED:
      return {
        ...state,
        locationDetails: {
          data: action.payload,
          isFetching: false,
          isUpdating: false,
          isCreating: false,
          error: null
        }
      };
    case ActionTypes.GET_LOCATION_BY_ID_FAILED:
      return {
        ...state,
        locationDetails: {
          ...state.locationDetails,
          isFetching: false,
          isUpdating: false,
          isCreating: false,
          error: action.payload
        }
      };
    case ActionTypes.UPDATE_LOCATION_IN_PROGRESS:
      return {
        ...state,
        locationDetails: {
          ...state.locationDetails,
          isUpdating: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_LOCATION_COMPLETED:
      return {
        ...state,
        locationDetails: {
          data: action.payload,
          isUpdating: false,
          error: null
        }
      };
    case ActionTypes.UPDATE_LOCATION_FAILED:
      return {
        ...state,
        locationDetails: {
          ...state.locationDetails,
          isUpdating: false,
          error: action.payload
        }
      };
    case ActionTypes.DELETE_LOCATION_IN_PROGRESS:
      return {
        ...state,
        deleteLocationStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.DELETE_LOCATION_COMPLETED:
      return {
        ...state,
        deleteLocationStatus: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.DELETE_LOCATION_FAILED:
      return {
        ...state,
        deleteLocationStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.CREATE_LOCATION_IN_PROGRESS:
      return {
        ...state,
        locationDetails: {
          ...state.locationDetails,
          isCreating: true,
          error: null
        }
      };
    case ActionTypes.CREATE_LOCATION_COMPLETED:
      return {
        ...state,
        locationDetails: {
          data: { address: {} },
          isCreating: false,
          error: null
        }
      };
    case ActionTypes.CREATE_LOCATION_FAILED:
      return {
        ...state,
        locationDetails: {
          ...state.locationDetails,
          isCreating: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_LOCATION_LIST_IN_PROGRESS:
      return {
        ...state,
        locationList: {
          ...state.locationList,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.GET_LOCATION_LIST_COMPLETED:
      return {
        ...state,
        locationList: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.GET_LOCATION_LIST_FAILED:
      return {
        ...state,
        locationList: {
          ...state.locationList,
          inProgress: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
