/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import _ from 'lodash';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Atoms';

// components
import VendorSelector from './VendorSelector';
import VendorConfiguration from './VendorConfiguration';
import CustomFields from './CustomFields';
import { PayGroupMapping } from './PayGroupMapping';
import { BenefitGroupConfiguration } from './BenefitGroupConfiguration';
import { EmploymentStatusMapping } from './EmploymentStatusMapping';
import { WorkdayPayGroupMappping } from './workday/WorkdayPayGroupMappping';
import { EmploymentStatusConfig } from './workday/EmploymentStatusConfig';
import { CustomFieldsMapping } from './workday/CustomFieldsMapping';
import { BenefitGroupMapping } from './workday/BenefitGroupMapping';
import WorkdayVendorConfiguration from './workday/WorkdayVendorConfiguration';
import {
  LFICustomFields,
  LFIVendorConfiguration,
  LFIPayGroupMapping,
  LFIBenefitGroupConfiguration,
  LFIEmploymentStatusMapping
} from './LFI';

// styles
import './DialogIntegrationDetails.scss';

// constants
import {
  VENDORS_LIST,
  VENDOR_NAMES,
  BAMBOO_HR_CONFIG_ERROR_LABEL_CODE_MAP,
  BAMBOO_HR_SAVE_TYPE
} from 'modules/Integrations/constants';

//actions
import * as ActionTypes from 'modules/Integrations/actionTypes/integrations.actionTypes';

const TOTAL_STEP_COUNT = 6;
/**
 * Component for generate Synced status badge.
 * @param props
 * @returns {*}
 */

class DialogIntegrationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVendor: props.selectedVendor ? props.selectedVendor : null,
      vendorConfiguration: null,
      customFields: null,
      payGroupMappings: null,
      benefitGroupConfiguration: null,
      employmentStatusMappings: null,
      step: props.step ? props.step : 1,
      isEditable: false
    };
  }

  componentDidMount() {
    const {
      integrationConfigDetails,
      history,
      bambooConfigDetails,
      match
    } = this.props;
    const { location } = history;
    const { state } = location;
    const { params } = match;

    let screenNo = _.defaultTo(state && state.step, 2);
    let selectedVendor;

    if (
      integrationConfigDetails &&
      params.edit &&
      state &&
      state.vendorType === VENDOR_NAMES.WORKDAY
    ) {
      selectedVendor = {
        name: VENDOR_NAMES.WORKDAY
      };

      const {
        customFields,
        payGroupMappings,
        benefitGroupMappings,
        employmentStatusMappings
      } = integrationConfigDetails;
      let workdayConfig = integrationConfigDetails
        ? {
          workdayHrUrl: integrationConfigDetails.workdayHrUrl,
          workdayHrUsername: integrationConfigDetails.workdayHrUsername,
          workdayHrPassword: integrationConfigDetails.workdayHrPassword,
          syncSalary: integrationConfigDetails.syncSalary,
          salaryOverride: integrationConfigDetails.salaryOverride,
          partTimeBenefitsEligible:
            integrationConfigDetails.partTimeBenefitsEligible,
          ignoreTerminatedEmployeesCutoffDate:
            integrationConfigDetails.ignoreTerminatedEmployeesCutoffDate,
          ignoreTerminatedEmployees:
            integrationConfigDetails.ignoreTerminatedEmployees
        }
        : null;

      this.setState({
        selectedVendor: selectedVendor,
        step: screenNo,
        isEditable: true,
        vendorConfiguration: workdayConfig,
        benefitGroupConfiguration: benefitGroupMappings,
        customFields,
        payGroupMappings,
        employmentStatusMappings
      });
    }

    if (
      bambooConfigDetails &&
      params.edit &&
      state &&
      state.vendorType === VENDOR_NAMES.BAMBOO_HR
    ) {
      selectedVendor = {
        name: VENDOR_NAMES.BAMBOO_HR
      };

      const {
        customFields,
        payGroupMappings,
        benefitGroupMappings,
        employmentStatusMappings,
        ignoredBambooHrBenefitGroups
      } = bambooConfigDetails;
      let bambooConfig = bambooConfigDetails
        ? {
          bambooHrApiKey: bambooConfigDetails.bambooHrApiKey,
          bambooHrEmployerName: bambooConfigDetails.bambooHrEmployerName,
          syncSalary: bambooConfigDetails.syncSalary,
          salaryOverride: bambooConfigDetails.salaryOverride,
          partTimeBenefitsEligible:
            bambooConfigDetails.partTimeBenefitsEligible,
          ignoreTerminatedEmployeesCutoffDate: this.getFormattedDate(
            bambooConfigDetails.ignoreTerminatedEmployeesCutoffDate
          ),
          ignoreTerminatedEmployees:
            bambooConfigDetails.ignoreTerminatedEmployees
        }
        : null;

      let formattedBenefitGroups = this.formatBHRBenefitGroupMappings(
        benefitGroupMappings,
        ignoredBambooHrBenefitGroups
      );
      let formattedCustomFields = this.formatBHRCustomFields(customFields);

      this.setState(
        {
          selectedVendor,
          step: screenNo,
          isEditable: true,
          vendorConfiguration: bambooConfig,
          benefitGroupConfiguration: formattedBenefitGroups,
          customFields: formattedCustomFields,
          payGroupMappings,
          employmentStatusMappings,
          ignoredBambooHrBenefitGroups
        },
        () => this.getBHRSetupDetails()
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { requestStatus, serviceError } = this.props;
    if (requestStatus === prevProps.requestStatus) return;
    switch (requestStatus) {
      case ActionTypes.GET_BHR_SETUP_DETAILS_SUCCESS: {
        break;
      }
      case ActionTypes.GET_BHR_SETUP_DETAILS_ERROR: {
        const errorCode =
          serviceError &&
          serviceError.response &&
          serviceError.response.data &&
          serviceError.response.data.code;
        this.setState({
          error:
            BAMBOO_HR_CONFIG_ERROR_LABEL_CODE_MAP[errorCode] ||
            'Invalid Configuration'
        });
        break;
      }
      default:
        break;
    }
  }

  getFormattedDate(date) {
    if (date) {
      let cutoffDate = new Date(date);
      let month = ('0' + (cutoffDate.getMonth() + 1)).slice(-2);
      let day = ('0' + cutoffDate.getDate()).slice(-2);
      let year = cutoffDate.getFullYear();
      return month + '/' + day + '/' + year;
    }
    return null;
  }

  formatBHRBenefitGroupMappings = (
    benefitGroupMappings,
    ignoredBambooHrBenefitGroups
  ) => {
    let formattedBenefitGroups = {};
    let ignoredBGroup = {};
    let ignoredBenefitGRoupIds = [];

    benefitGroupMappings.forEach((group) => {
      formattedBenefitGroups[
        group.bambooHrBenefitGroup.bambooHrBenefitGroupId
      ] = { 'lumityBenefitGroup': group.benefitGroup };
    });

    ignoredBambooHrBenefitGroups.map((ignoredGroup) =>
      ignoredBenefitGRoupIds.push(ignoredGroup.bambooHrBenefitGroupId)
    );
    ignoredBenefitGRoupIds.forEach((ignoredGroup) => {
      ignoredBGroup['ignore'] = true;
      formattedBenefitGroups[ignoredGroup] = ignoredBGroup;
    });

    return formattedBenefitGroups;
  };

  formatBHRCustomFields = (customFields) => {
    return customFields.map((field) => {
      return {
        vendorField: field['bambooHrField'],
        customFieldValue: field['bambooHrCustomFieldName']
      };
    });
  };

  setVendorSelection = (vendor) => {
    this.setState({ selectedVendor: vendor, step: 2 });
  };

  syncBenefitGroups = (bambooHrBenefitGroups) => {
    const { benefitGroupConfiguration } = this.state;
    let allowedBenefitIds = [];
    bambooHrBenefitGroups.forEach(benefitGroup => {
      allowedBenefitIds.push(benefitGroup.bambooHrBenefitGroupId)
    })

    const refactoredBenefitGroups = Object.keys(benefitGroupConfiguration)
      .filter(key => allowedBenefitIds.includes(key))
      .reduce((obj, key) => {
        obj[key] = benefitGroupConfiguration[key];
        return obj;
      }, {});

    this.setState({ benefitGroupConfiguration: refactoredBenefitGroups });
    return refactoredBenefitGroups;

  }

  // Process the inputs for benefitGroupConfiguration and map to API objects
  processBenefitGroups = (bambooHrBenefitGroups) => {
    const benefitGroupMappings = [];
    const ignoredBambooHrBenefitGroups = [];
    const { benefitGroupConfiguration } = this.state;

    bambooHrBenefitGroups.forEach((benefitGroup) => {
      const { bambooHrBenefitGroupId: groupId } = benefitGroup;
      const { lumityBenefitGroup, ignore } = benefitGroupConfiguration[groupId];
      if (ignore) {
        ignoredBambooHrBenefitGroups.push(benefitGroup);
      } else {
        benefitGroupMappings.push({
          bambooHrBenefitGroup: benefitGroup,
          benefitGroup: lumityBenefitGroup
        });
      }
    });
    return {
      ignoredBambooHrBenefitGroups,
      benefitGroupMappings
    }
  }

  syncLatestBenGroups = (bambooHrBenefitGroups) => {
    const { benefitGroupConfiguration } = this.state;
    let allBenefitIds = [];
    bambooHrBenefitGroups.forEach(benefitGroup => {
      allBenefitIds.push(benefitGroup.bambooHrBenefitGroupId)
    });
    let allowedOldBenefitIds = allBenefitIds.filter(id => benefitGroupConfiguration.hasOwnProperty(id));
    let existingBenefitGroups = bambooHrBenefitGroups.filter(benefitGroup => {
      return allowedOldBenefitIds.includes(benefitGroup.bambooHrBenefitGroupId);
    });
    return existingBenefitGroups;
  }

  formatPayGroups = (payGroupMappings) => {
    let payGroups = [];
    for (const [key, value] of Object.entries(payGroupMappings)) {
      let newObject = { 'bambooHrPayGroup': key, 'payGroup': value }
      payGroups.push(newObject)
    }
    return payGroups;
  }

  formatEmpStatus = (employmentStatusMappings) => {
    let empStatus = [];
    for (const [key, value] of Object.entries(employmentStatusMappings)) {
      let newEmpStatus = { 'employmentStatus': value.employmentStatus, 'employmentType': value.employmentType, 'bambooHrEmploymentStatus': key };
      empStatus.push(newEmpStatus)
    }
    return empStatus;
  }

  generateBambooHRConfig = () => {
    let processedBenefits;
    const {
      selectedVendor,
      vendorConfiguration,
      customFields,
      payGroupMappings,
      employmentStatusMappings,
      forceSave,
    } = this.state;
    const { vendorSetupDetails } = this.props.getVendorInfo(
      selectedVendor.name
    );
    const { bambooHrBenefitGroups } = vendorSetupDetails;

    if (forceSave) {
      let refactoredBenefits = this.syncBenefitGroups(bambooHrBenefitGroups);
      this.setState({ benefitGroupConfiguration: refactoredBenefits });
      processedBenefits = this.processBenefitGroups(this.syncLatestBenGroups(bambooHrBenefitGroups));
    } else {
      processedBenefits = this.processBenefitGroups(bambooHrBenefitGroups);
    }

    return {
      ...vendorConfiguration,
      customFields:
        (customFields &&
          customFields.map(({ customFieldValue, vendorField }) => ({
            bambooHrCustomFieldName: customFieldValue,
            bambooHrField: vendorField
          }))) ||
        [],
      payGroupMappings: !forceSave
        ? Object.entries(payGroupMappings).map(
          ([bambooHrPayGroup, payGroup]) => ({
            bambooHrPayGroup,
            payGroup
          })
        )
        : !payGroupMappings.length ? this.formatPayGroups(payGroupMappings) : payGroupMappings,
      benefitGroupMappings: processedBenefits.benefitGroupMappings,
      ignoredBambooHrBenefitGroups: processedBenefits.ignoredBambooHrBenefitGroups,
      employmentStatusMappings: forceSave && !employmentStatusMappings.length ?
        this.formatEmpStatus(employmentStatusMappings)
        : employmentStatusMappings
    };
  };

  generateLFIConfig = () => {
    const {
      vendorConfiguration,
      customFields,
      payGroupMappings,
      benefitGroupConfiguration,
      employmentStatusMappings
    } = this.state;
    let benefitGroupMappings = {};
    let ignoredLFIBenefitGroups = [];
    if (benefitGroupConfiguration) {
      ignoredLFIBenefitGroups = benefitGroupConfiguration
        .filter(({ ignore }) => ignore)
        .map(({ lfiBenefitGroup }) => lfiBenefitGroup);
      benefitGroupConfiguration
        .filter(({ ignore }) => !ignore)
        .forEach(({ lfiBenefitGroup, lumityBenefitGroup }) => {
          benefitGroupMappings[lfiBenefitGroup] = lumityBenefitGroup;
        });
    }

    return {
      ...vendorConfiguration,
      customFields,
      payGroupMappings,
      benefitGroupMappings,
      ignoredLFIBenefitGroups,
      ...employmentStatusMappings
    };
  };

  generateWorkdayConfig = () => {
    const {
      vendorConfiguration,
      customFields,
      payGroupMappings,
      benefitGroupConfiguration,
      employmentStatusMappings
    } = this.state;

    return {
      ...vendorConfiguration,
      customFields: customFields || [],
      payGroupMappings: payGroupMappings || [],
      benefitGroupMappings: benefitGroupConfiguration || [],
      employmentStatusMappings: employmentStatusMappings || []
    };
  };

  saveConfiguration = () => {
    const { selectedVendor, isEditable, forceSave } = this.state;
    const { saveConfiguration } = this.props.getVendorInfo(selectedVendor.name);
    let config;
    switch (selectedVendor.name) {
      case VENDOR_NAMES.BAMBOO_HR:
        config = this.generateBambooHRConfig();
        break;
      case VENDOR_NAMES.GFI:
        config = this.generateLFIConfig();
        break;
      case VENDOR_NAMES.WORKDAY:
        config = this.generateWorkdayConfig();
        break;
      default:
        break;
    }
    if (selectedVendor.name === VENDOR_NAMES.WORKDAY) {
      saveConfiguration(config, isEditable);
    } else if (selectedVendor.name === VENDOR_NAMES.BAMBOO_HR) {
      let saveType = forceSave ? BAMBOO_HR_SAVE_TYPE.BASIC_CONFIG : BAMBOO_HR_SAVE_TYPE.MAPPINGS_CONFIG;
      saveConfiguration(config, saveType);
    } else {
      saveConfiguration(config);
    }
  };

  saveAndCloseConfigs = (view) => (isEditable = false, viewData = []) => {
    const { selectedVendor } = this.state;
    this.setState(
      {
        [view]: viewData,
        isEditable,
        forceSave: selectedVendor.name === VENDOR_NAMES.BAMBOO_HR ? true : false
      },
      () => {
        this.saveConfiguration();
      }
    );
  };

  toggle = () => {
    const { isEditable } = this.state;
    this.setState(
      {
        selectedVendor: null,
        vendorConfiguration: null,
        customFields: null,
        payGroupMappings: null,
        benefitGroupConfiguration: null,
        employmentStatusMappings: null,
        step: 1
      },
      () => this.props.toggle(isEditable)
    );
  };

  onNext = (view) => (viewData) => {
    const { step, isEditable } = this.state;
    if (step === TOTAL_STEP_COUNT) {
      this.setState(
        {
          [view]: viewData
        },
        () => {
          this.saveConfiguration(isEditable);
        }
      );
    } else {
      const nextStep = this.state.step + 1;
      this.setState({
        step: nextStep,
        [view]: viewData
      });
    }
  };

  onPrevious = (view) => (viewData) => {
    const prevStep = this.state.step - 1;
    this.setState({
      step: prevStep,
      [view]: viewData
    });
  };

  getBHRSetupDetails = () => {
    const { vendorConfiguration, customFields } = this.state;
    const { getBHRSetupDetails } = this.props;
    getBHRSetupDetails({
      ...vendorConfiguration,
      customFields:
        (customFields &&
          customFields.map(({ customFieldValue, vendorField }) => ({
            bambooHrCustomFieldName: customFieldValue,
            bambooHrField: vendorField
          }))) ||
        []
    });
  };

  getCustomFieldsView(selectedVendor) {
    const { customFields: baseCustomFields, vendorConfiguration } = this.state;
    let customFields = baseCustomFields;
    if (baseCustomFields && baseCustomFields.length === 0) {
      customFields = [{}];
    }
    switch (selectedVendor.name) {
      case VENDOR_NAMES.BAMBOO_HR: {
        const {
          vendorCustomFieldList,
          getVendorCustomFields
        } = this.props.getVendorInfo(selectedVendor.name);
        return (
          <CustomFields
            selectedVendor={selectedVendor}
            vendorFieldList={vendorCustomFieldList}
            selectedCustomFields={customFields}
            getVendorCustomFields={getVendorCustomFields}
            onPrevious={this.onPrevious('customFields')}
            onNext={this.onNext('customFields')}
            vendorConfiguration={vendorConfiguration}
          ></CustomFields>
        );
      }
      case VENDOR_NAMES.GFI: {
        return (
          <LFICustomFields
            selectedVendor={selectedVendor}
            selectedCustomFields={customFields}
            onPrevious={this.onPrevious('customFields')}
            onNext={this.onNext('customFields')}
          ></LFICustomFields>
        );
      }
      case VENDOR_NAMES.WORKDAY: {
        const {
          vendorCustomFieldList,
          getVendorCustomFields
        } = this.props.getVendorInfo(selectedVendor.name);
        return (
          <CustomFieldsMapping
            selectedVendor={selectedVendor}
            vendorFieldList={vendorCustomFieldList}
            selectedCustomFields={customFields}
            getVendorCustomFields={getVendorCustomFields}
            onPrevious={this.onPrevious('customFields')}
            onNext={this.onNext('customFields')}
          />
        );
      }
      default:
        break;
    }
  }

  getVendorConfigurationView(selectedVendor) {
    const { customFields, vendorConfiguration, isEditable } = this.state;
    const { employerId } = this.props;
    switch (selectedVendor.name) {
      case VENDOR_NAMES.BAMBOO_HR:
        return (
          <VendorConfiguration
            selectedVendor={selectedVendor}
            customFields={customFields}
            vendorConfiguration={vendorConfiguration}
            onPrevious={this.onPrevious('vendorConfiguration')}
            onNext={this.onNext('vendorConfiguration')}
            isEditable={isEditable}
            saveAndCloseConfigs={this.saveAndCloseConfigs(
              'vendorConfiguration'
            )}
          />
        );
      case VENDOR_NAMES.GFI:
        return (
          <LFIVendorConfiguration
            employerId={this.props.employerId}
            selectedVendor={selectedVendor}
            customFields={customFields}
            vendorConfiguration={vendorConfiguration}
            onPrevious={this.onPrevious('vendorConfiguration')}
            onNext={this.onNext('vendorConfiguration')}
          />
        );
      case VENDOR_NAMES.WORKDAY:
        return (
          <WorkdayVendorConfiguration
            selectedVendor={selectedVendor}
            customFields={customFields}
            vendorConfiguration={vendorConfiguration}
            onPrevious={this.onPrevious('vendorConfiguration')}
            onNext={this.onNext('vendorConfiguration')}
            saveAndCloseConfigs={this.saveAndCloseConfigs(
              'vendorConfiguration'
            )}
            employerId={employerId}
            isEditable={isEditable}
          />
        );
      default:
        break;
    }
  }

  getPayGroupMappingView(selectedVendor) {
    const { vendorSetupDetails } = this.props.getVendorInfo(
      selectedVendor.name
    );
    const { payGroupMappings, isEditable } = this.state;
    switch (selectedVendor.name) {
      case VENDOR_NAMES.BAMBOO_HR:
        const { bambooHrPayGroups } = vendorSetupDetails;
        return (
          <PayGroupMapping
            selectedVendor={selectedVendor}
            payGroupMappings={payGroupMappings}
            bambooHrPayGroups={bambooHrPayGroups}
            onPrevious={this.onPrevious('payGroupMappings')}
            onNext={this.onNext('payGroupMappings')}
            isEditable={isEditable}
          />
        );
      case VENDOR_NAMES.GFI:
        return (
          <LFIPayGroupMapping
            selectedVendor={selectedVendor}
            payGroupMappings={payGroupMappings}
            onPrevious={this.onPrevious('payGroupMappings')}
            onNext={this.onNext('payGroupMappings')}
          ></LFIPayGroupMapping>
        );
      case VENDOR_NAMES.WORKDAY:
        return (
          <WorkdayPayGroupMappping
            selectedVendor={selectedVendor}
            payGroupMappings={payGroupMappings}
            onPrevious={this.onPrevious('payGroupMappings')}
            onNext={this.onNext('payGroupMappings')}
          />
        );
      default:
        break;
    }
  }

  getBenefitGroupConfigurationView(selectedVendor) {
    const { vendorSetupDetails } = this.props.getVendorInfo(
      selectedVendor.name
    );
    const { benefitGroupConfiguration, isEditable } = this.state;
    switch (selectedVendor.name) {
      case VENDOR_NAMES.BAMBOO_HR: {
        const { employerBenefitGroups } = this.props;
        const { bambooHrBenefitGroups } = vendorSetupDetails;
        return (
          <BenefitGroupConfiguration
            selectedVendor={selectedVendor}
            benefitGroupConfiguration={benefitGroupConfiguration}
            lumityBenefitGroups={employerBenefitGroups}
            bambooHrBenefitGroups={bambooHrBenefitGroups}
            onPrevious={this.onPrevious('benefitGroupConfiguration')}
            onNext={this.onNext('benefitGroupConfiguration')}
            isEditable={isEditable}
          />
        );
      }
      case VENDOR_NAMES.GFI: {
        const {
          lumityBenefitGroups: employerBenefitGroups
        } = vendorSetupDetails;
        return (
          <LFIBenefitGroupConfiguration
            selectedVendor={selectedVendor}
            benefitGroupConfiguration={benefitGroupConfiguration}
            lumityBenefitGroups={employerBenefitGroups}
            onPrevious={this.onPrevious('benefitGroupConfiguration')}
            onNext={this.onNext('benefitGroupConfiguration')}
          />
        );
      }
      case VENDOR_NAMES.WORKDAY: {
        const { employerBenefitGroups } = this.props;
        return (
          <BenefitGroupMapping
            selectedVendor={selectedVendor}
            benefitGroupConfiguration={benefitGroupConfiguration}
            lumityBenefitGroups={employerBenefitGroups}
            onPrevious={this.onPrevious('benefitGroupConfiguration')}
            onNext={this.onNext('benefitGroupConfiguration')}
          />
        );
      }
      default:
        break;
    }
  }

  getEmploymentStatusMappingView(selectedVendor) {
    const { vendorSetupDetails } = this.props.getVendorInfo(
      selectedVendor.name
    );
    const { employmentStatusMappings, isEditable } = this.state;
    switch (selectedVendor.name) {
      case VENDOR_NAMES.BAMBOO_HR: {
        const {
          bambooHrEmploymentStatuses,
          lumityEmploymentStatuses,
          lumityEmploymentTypes
        } = vendorSetupDetails;
        return (
          <EmploymentStatusMapping
            selectedVendor={selectedVendor}
            bambooHrEmploymentStatuses={bambooHrEmploymentStatuses}
            lumityEmploymentStatuses={lumityEmploymentStatuses}
            lumityEmploymentTypes={lumityEmploymentTypes}
            selectedEmploymentStatusMappings={employmentStatusMappings}
            onPrevious={this.onPrevious('employmentStatusMappings')}
            onNext={this.onNext('employmentStatusMappings')}
            isEditable={isEditable}
          ></EmploymentStatusMapping>
        );
      }
      case VENDOR_NAMES.GFI: {
        const {
          lumityEmploymentStatuses,
          lumityEmploymentTypes
        } = vendorSetupDetails;
        const employmentMappingsConfig = employmentStatusMappings || {
          employmentStatusMappings: null,
          employmentTypeMappings: null
        };
        return (
          <LFIEmploymentStatusMapping
            selectedVendor={selectedVendor}
            lumityEmploymentStatuses={lumityEmploymentStatuses}
            lumityEmploymentTypes={lumityEmploymentTypes}
            selectedEmploymentStatusMappings={
              employmentMappingsConfig.employmentStatusMappings
            }
            selectedEmploymentTypeMappings={
              employmentMappingsConfig.employmentTypeMappings
            }
            onPrevious={this.onPrevious('employmentStatusMappings')}
            onNext={this.onNext('employmentStatusMappings')}
          ></LFIEmploymentStatusMapping>
        );
      }
      case VENDOR_NAMES.WORKDAY: {
        const {
          lumityEmploymentStatuses,
          lumityEmploymentTypes
        } = vendorSetupDetails;
        return (
          <EmploymentStatusConfig
            selectedVendor={selectedVendor}
            lumityEmploymentStatuses={lumityEmploymentStatuses}
            lumityEmploymentTypes={lumityEmploymentTypes}
            selectedEmploymentStatusMappings={employmentStatusMappings}
            onPrevious={this.onPrevious('employmentStatusMappings')}
            onNext={this.onNext('employmentStatusMappings')}
          />
        );
      }
      default:
        break;
    }
  }

  renderIntegrationInfo = () => {
    const { step, isEditable, selectedVendor } = this.state;
    switch (step) {
      case 1:
        return {
          header: 'Add HRIS Integration',
          view: (
            <VendorSelector
              vendorList={VENDORS_LIST}
              selectedVendor={selectedVendor}
              setSelection={this.setVendorSelection}
            />
          )
        };
      case 2:
        return {
          header: isEditable ? 'Edit HRIS Integration' : 'Add HRIS Integration',
          view: this.getVendorConfigurationView(selectedVendor)
        };

      case 3:
        return {
          header:
            selectedVendor.name === VENDOR_NAMES.WORKDAY
              ? 'Field Name Mapping'
              : 'Custom Fields',
          view: this.getCustomFieldsView(selectedVendor)
        };
      case 4:
        return {
          header:
            selectedVendor.name === VENDOR_NAMES.WORKDAY
              ? 'Pay Group Mapping' : selectedVendor.name === VENDOR_NAMES.BAMBOO_HR ?
                'Flock Pay Group' : 'Lumity Pay Group',
          view: this.getPayGroupMappingView(selectedVendor)
        };
      case 5:
        return {
          header:
            selectedVendor.name === VENDOR_NAMES.WORKDAY
              ? 'Benefit Group Mapping'
              : 'Lumity Benefit Group',
          view: this.getBenefitGroupConfigurationView(selectedVendor)
        };
      case 6:
        return {
          header: 'Employment Status Mapping',
          view: this.getEmploymentStatusMappingView(selectedVendor)
        };
      default:
        break;
    }
  };

  render() {
    const { isOpen } = this.props;
    const { header, view } = this.renderIntegrationInfo();
    return (
      <Modal
        isOpen={isOpen}
        toggle={this.toggle}
        backdrop="static"
        keyboard={false}
        wrapClassName="dialog-add-integration"
        className="dialog-add-integration__dialog"
        contentClassName="dialog-add-integration__content"
      >
        <ModalHeader className="modal-header" toggle={this.toggle}>
          {header}
        </ModalHeader>
        <ModalBody className="modal-body">{view}</ModalBody>
        <ModalFooter className="modal-footer"></ModalFooter>
      </Modal>
    );
  }
}

export default DialogIntegrationDetails;
