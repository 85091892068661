/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import ActionMenu from './../ActionMenu/ActionMenu';

import { Row, Label } from 'components/Atoms';
import { AgGrid } from 'components/DataTables';

import StarRatings from 'react-star-ratings';

import './OffersTable.scss';
import ConfigurePlanRatesPopUp from '../ConfigurePlanRatesPopUp/ConfigurePlanRatesPopUp';
import CustomTooltip from './../OffersCustomTooltip/CustomTooltip';
import { formatCurrency } from 'util/commonUtil';
import { OFFERS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';

class OffersDataTable extends Component {
  getCarrierName(responseObj) {
    return responseObj.carrier;
  }

  constructor(props) {
    super(props);
    const mdvCols = [
      {
        headerName: '',
        field: 'type',
        width: 25,
        suppressSizeToFit: true,
        pinned: 'left',
        suppressMovable: true,
        cellClass: 'bundle-type-label',
        cellClassRules: {
          'bundle-class-current': function(params) {
            return params.value === 'CURRENT';
          },
          'bundle-class-recommended': function(params) {
            return params.data.recommended;
          },
          'bundle-class-renewal': function(params) {
            return params.value === 'RENEWAL';
          },
          'bundle-class-other': function(params) {
            return params.value === 'OTHER';
          }
        },
        valueGetter: (params) =>
          params.data.type === 'OTHER' && params.data.recommended
            ? 'RECOMMENDED'
            : params.data.type === 'OTHER'
            ? ''
            : params.data.type
      },
      {
        headerName: 'Offer Name',
        field: 'name',
        minWidth: 200,
        cellRendererFramework: bundleNameRenderer,
        suppressMovable: true,
        cellRendererParams: {
          redirectToOfferPreview: (param) => props.redirectToOfferPreview(param)
        }
      },
      {
        headerName: 'Carrier',
        field: 'carrierName',
        minWidth: 150,
        suppressMovable: true,
        headerClass: 'carrier-header-style ',
        valueGetter: (params) => this.getCarrierName(params.data)
      },
      {
        headerName: 'Total Annual Premium',
        field: 'planType',
        minWidth: 150,
        suppressMovable: true,
        cellRendererFramework: TotalAnnualPremium
      },
      {
        headerName: 'Impact',
        minWidth: 180,
        suppressMovable: true,
        cellRendererFramework: Impact
      },
      {
        headerName: 'Plans',
        field: 'total',
        cellRendererFramework: TotalImpactVsCurrent,
        suppressMovable: true,
        minWidth: 220,
        cellClass: 'impact-cell',
        tooltipComponent: 'customTooltip',
        tooltipValueGetter: function(params) {
          let plansList = [];
          if (params.data.category === 'MEDICAL') {
            plansList = params.data.medicalPlans;
          } else if (params.data.category === 'DENTAL') {
            plansList = params.data.dentalPlans;
          } else if (params.data.category === 'VISION') {
            plansList = params.data.visionPlans;
          }
          if (plansList.length > 0) {
            return { data: plansList };
          }
        }
      },
      {
        headerName: 'Provider Network',
        minWidth: 120,
        suppressMovable: true,
        cellRendererFramework: ProviderNetwork
      },
      {
        headerName: 'Publish Status',
        minWidth: 100,
        suppressMovable: true,
        cellRendererFramework: PublishStatus
      },
      {
        headerName: 'Actions',
        minWidth: 70,
        field: 'actions',
        cellRendererFramework: showActionsMenu,
        suppressMovable: true,
        cellClass: 'select-dropdown-cell',
        cellRendererParams: {
          publishSingleOffer: (param) => props.publishSingleOffer(param),
          openNegotiationPopup: (param) => this.openNegotiationPopup(param),
          redirectToOfferPreview: (param) => props.redirectToOfferPreview(param)
        }
      }
    ];

    const lifeCols = [
      {
        headerName: '',
        field: 'type',
        width: 25,
        suppressSizeToFit: true,
        pinned: 'left',
        suppressMovable: true,
        cellClass: function(params) {
          if (params.value === 'CURRENT') {
            return 'bundle-type-label bundle-class-current';
          } else if (params.data.recommended) {
            return 'bundle-type-label bundle-class-recommended';
          } else if (params.value === 'RENEWAL') {
            return 'bundle-type-label bundle-class-renewal';
          } else {
            return 'bundle-type-label bundle-class-other';
          }
        },
        valueGetter: (params) =>
          params.data.type === 'OTHER' && params.data.recommended
            ? 'RECOMMENDED'
            : params.data.type === 'OTHER'
            ? ''
            : params.data.type
      },
      {
        headerName: 'Offer Name',
        field: 'name',
        minWidth: 200,
        cellRendererFramework: bundleNameRenderer,
        suppressMovable: true,
        cellRendererParams: {
          redirectToOfferPreview: (param) => props.redirectToOfferPreview(param)
        }
      },
      {
        headerName: 'Carrier',
        field: 'carrierName',
        minWidth: 150,
        suppressMovable: true,
        headerClass: 'carrier-header-style ',
        valueGetter: (params) => this.getCarrierName(params.data)
      },
      {
        headerName: 'Total Annual Premium',
        field: 'planType',
        minWidth: 150,
        suppressMovable: true,
        cellRendererFramework: TotalAnnualLifePremium
      },
      {
        headerName: 'Plans',
        field: 'total',
        cellRendererFramework: TotalImpactVsCurrent,
        suppressMovable: true,
        minWidth: 220,
        cellClass: 'impact-cell',
        tooltipComponent: 'customTooltip',
        tooltipValueGetter: function(params) {
          let plansList = [...params.data.lifePlans];
          if (plansList.length > 0) {
            return { data: plansList };
          }
        }
      },
      {
        headerName: 'Provider Network',
        minWidth: 120,
        suppressMovable: true,
        cellRendererFramework: ProviderNetwork
      },
      {
        headerName: 'Publish Status',
        minWidth: 100,
        suppressMovable: true,
        cellRendererFramework: PublishStatus
      },
      {
        headerName: 'Actions',
        minWidth: 70,
        field: 'actions',
        cellRendererFramework: showActionsMenu,
        suppressMovable: true,
        cellClass: 'select-dropdown-cell',
        cellRendererParams: {
          publishSingleOffer: (param) => props.publishSingleOffer(param),
          redirectToOfferPreview: (param) => props.redirectToOfferPreview(param)
        }
      }
    ];
    this.state = {
      type: props.type,
      employerId: props.employerId,
      columnDefs: props.type === 'LIFE' ? lifeCols : mdvCols,
      frameworkComponents: { customTooltip: CustomTooltip }
    };
  }
  componentDidUpdate(prevProps) {
    const { type, offers } = this.props;
    if (type === 'MEDICAL' && offers !== prevProps.offers) {
      setTimeout(() => this.api.setRowData(offers), 0);
    }
    if (type === 'DENTAL' && offers !== prevProps.offers) {
      setTimeout(() => this.api.setRowData(offers), 0);
    }
    if (type === 'VISION' && offers !== prevProps.offers) {
      setTimeout(() => this.api.setRowData(offers), 0);
    }
    if (type === 'LIFE' && offers !== prevProps.offers) {
      setTimeout(() => this.api.setRowData(offers), 0);
    }
  }

  onGridReady(param) {
    this.api = param.api;
    this.props.getOffers(this.state.employerId, this.state.type);
  }

  onGridSizeChanged(params) {
    const height = document.getElementsByTagName('html')[0].offsetHeight - 359;
    document.getElementsByClassName(
      'proposals-table-size'
    )[0].style.height = `${height}px`;
    params.api.sizeColumnsToFit();
  }

  openNegotiationPopup = (event, bundleData) => {
    this.setState({
      selectedBundle: event.data,
      isNegotiationPopupOpen: true
    });
  };

  render() {
    return (
      <ContentContainer className="proposal-bundles">
        <div className="ag-theme-balham proposals-table-size bundle-data-table ">
          <AgGrid
            columnDefs={this.state.columnDefs}
            rowHeight="120"
            onGridReady={(param) => this.onGridReady(param)}
            onGridSizeChanged={this.onGridSizeChanged.bind(this)}
            rowSelection="multiple"
            suppressCellSelection={true}
            suppressDragLeaveHidesColumns={true}
            paginationAutoPageSize={true}
            frameworkComponents={this.state.frameworkComponents}
            reactNext={true}
          />

          <ConfigurePlanRatesPopUp
            className="negotiation-popup"
            type={this.props.type}
            selectedBundle={this.state.selectedBundle}
            isNegotiationPopupOpen={this.state.isNegotiationPopupOpen}
            onClose={() => this.setState({ isNegotiationPopupOpen: false })}
            savePlanContributions={this.props.savePlanContributions}
          />
        </div>
      </ContentContainer>
    );
  }
}

const showActionsMenu = (props) => {
  const roleMapping = OFFERS_FEATURE_ROLE_MAPPING.common;
  let itemList = [];
  if (props.data.type !== 'CURRENT') {
    if (props.data.status === 'PUBLISHED') {
      itemList = itemList.concat([
        {
          action: 'Preview Offer Detail Page',
          onClick: () => {
            props.redirectToOfferPreview(props.data.id);
          },
          roleMapping: roleMapping
        }
      ]);
    } else {
      itemList = itemList.concat([
        {
          action: 'Preview Offer Detail Page',
          onClick: () => {
            props.redirectToOfferPreview(props.data.id);
          },
          roleMapping: roleMapping
        },
        {
          action: 'Publish Offer',
          onClick: () => props.publishSingleOffer(props.data.id),
          roleMapping: roleMapping
        }
      ]);
    }
    return (
      <div>
        <ActionMenu ItemsList={itemList} />
      </div>
    );
  } else {
    return <div></div>;
  }
};
const bundleNameRenderer = (props) => {
  if (props.data.type === 'CURRENT') {
    return <span className="link-name">{props.value}</span>;
  } else {
    return (
      <span
        className="link-name proposal-plan-name"
        onClick={() => {
          props.redirectToOfferPreview(props.data.id);
        }}
      >
        {props.value}
      </span>
    );
  }
};
const TotalImpactVsCurrent = (props) => {
  let plansList = [];
  if (props.data.category === 'MEDICAL') {
    plansList = props.data.medicalPlans;
  } else if (props.data.category === 'DENTAL') {
    plansList = props.data.dentalPlans;
  } else if (props.data.category === 'VISION') {
    plansList = props.data.visionPlans;
  } else if (props.data.category === 'LIFE') {
    plansList = [...props.data.lifePlans];
  }
  if (plansList.length > 0) {
    return (
      <div className="impact-cell-style">
        {plansList.map((plan) => (
          <React.Fragment key={`impact-cell-${plan}`}>
            <Label>{plan}</Label>
            <br />
          </React.Fragment>
        ))}
      </div>
    );
  } else {
    return <div></div>;
  }
};

const TotalAnnualPremium = (props) => {
  const contribution = props.data.totalContribution
    ? props.data.totalContribution
    : 0.0;
  const erPremium = props.data.totalErPremium ? props.data.totalErPremium : 0.0;
  const eePremium = props.data.totalEePremium ? props.data.totalEePremium : 0.0;
  return (
    <div className="impact-cell-style">
      <Label>
        <strong>{formatCurrency(contribution)}</strong>
      </Label>
      <br />
      <Label>ER Cost: {formatCurrency(erPremium)}</Label>
      <br />
      <Label>EE Cost: {formatCurrency(eePremium)}</Label>
    </div>
  );
};

const TotalAnnualLifePremium = (props) => {
  const premium = props.data.totalPremium ? props.data.totalPremium : 0.0;
  return (
    <div className="impact-cell-style">
      <Label>
        <strong>{formatCurrency(premium)}</strong>
      </Label>
      <br />
    </div>
  );
};

const Impact = (props) => {
  if (props.data.type === 'CURRENT') {
    return 'n/a';
  } else {
    const {
      totalEeImpact,
      totalErImpact,
      totalEeImpactPercent,
      totalErImpactPercent,
      negotiatedErDiff,
      negotiatedEeDiff,
      negotiatedErDiffPercent,
      negotiatedEeDiffPercent
    } = props.data.comparisons;
    const erImpact = negotiatedErDiff ? negotiatedErDiff : totalErImpact;
    const eeImpact = negotiatedEeDiff ? negotiatedEeDiff : totalEeImpact;
    const erImpactPercentage = negotiatedErDiffPercent
      ? negotiatedErDiffPercent
      : totalErImpactPercent;
    const eeImpactPercentage = negotiatedEeDiffPercent
      ? negotiatedEeDiffPercent
      : totalEeImpactPercent;
    return (
      <div className="impact-cell-style impact-column">
        <Row>
          <Label> ER: {formatCurrency(erImpact)}</Label>
          <Label className="ml-1 itc-container">{erImpactPercentage}%</Label>
        </Row>
        <Row>
          <Label> EE: {formatCurrency(eeImpact)}</Label>
          <Label className="ml-1 itc-container">{eeImpactPercentage}%</Label>
        </Row>
      </div>
    );
  }
};

const ProviderNetwork = (props) => {
  let rating = parseFloat(props.data.providerRating);
  if (isNaN(rating)) {
    rating = 0;
  }
  return (
    <StarRatings
      className="mt-4"
      rating={rating}
      starRatedColor="#FFC933"
      numberOfStars={5}
      name="rating"
      starDimension="12px"
      starSpacing="1px"
    />
  );
};

const PublishStatus = (props) => {
  return (
    <div className="impact-cell-style">
      <Label className="itc-container mt-3">{props.data.status}</Label>
    </div>
  );
};

export default OffersDataTable;
