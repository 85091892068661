/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actions/auth.actions';

const initialState = {
  appBootupInfo: null,
  inProgress: true,
  requestType: null,
  error: null,
  passResetRequestInprogress: false,
  tokenValidationError: null,
  validationInProgress: false,
  resetPasswordUsername: null,
  resetPasswordError: null,
  resetPasswordSuccess: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_IN_PROGRESS:
    case ActionTypes.LOGOUT_IN_PROGRESS:
    case ActionTypes.PING_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
        appBootupInfo: null,
        requestType: action.type,
        error: null
      };
    case ActionTypes.LOGIN_FAILED:
    case ActionTypes.LOGOUT_FAILED:
    case ActionTypes.PING_FAILED:
      return {
        ...state,
        appBootupInfo: null,
        inProgress: false,
        requestType: action.type,
        error: action.error
      };
    case ActionTypes.LOGOUT_COMPLETED:
      return {
        ...state,
        appBootupInfo: null,
        inProgress: false,
        requestType: action.type,
        error: null
      };
    case ActionTypes.PING_COMPLETED:
      return {
        ...state,
        appBootupInfo: action.data,
        inProgress: false,
        requestType: action.type,
        error: null
      };
    case ActionTypes.REQUEST_PASSWORD_RESET_IN_PROGRESS:
      return {
        ...state,
        passResetRequestInprogress: true,
        error: null
      };
    case ActionTypes.REQUEST_PASSWORD_RESET_COMPLETED:
      return {
        ...state,
        passResetRequestInprogress: false,
        error: null
      };
    case ActionTypes.REQUEST_PASSWORD_RESET_FAILED:
      return {
        ...state,
        passResetRequestInprogress: false,
        error: action.data
      };
    case ActionTypes.PASSWORD_RESET_TOKEN_VALIDATE_IN_PROGRESS:
      return {
        ...state,
        validationInProgress: true,
        tokenValidationError: null
      };
    case ActionTypes.PASSWORD_RESET_TOKEN_VALIDATE_COMPLETED:
      return {
        ...state,
        validationInProgress: false,
        tokenValidationError: null,
        resetPasswordUsername: action.data
      };
    case ActionTypes.PASSWORD_RESET_TOKEN_VALIDATE_FAILED:
      return {
        ...state,
        validationInProgress: false,
        tokenValidationError: action.data
      };
    case ActionTypes.RESET_PASSWORD_IN_PROGRESS:
      return {
        ...state,
        resetPasswordError: null,
        resetPasswordSuccess: false
      };
    case ActionTypes.RESET_PASSWORD_COMPLETED:
      return {
        ...state,
        resetPasswordError: null,
        resetPasswordSuccess: true
      };
    case ActionTypes.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPasswordSuccess: false,
        resetPasswordError: action.data
      };
    default:
      return {
        ...state
      };
  }
};

export default authReducer;
