import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col, Button } from 'components/Atoms';

import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import BenchmarkService from 'services/BenchmarkService';
import PublishConfirmationModal from '../../../components/PublishConfirmationModal/PublishConfirmationModal';
import ValidationTable, {
  VALIDATION_INCOMPLETE,
  VALIDATION_PASSED,
  VALIDATION_SKIPPED
} from './ValidationTable';
import {
  saveBenchmark,
  findBenchmark,
  findPublishedBenchmark
} from '../../../actions/benchmarkActions';
import { redirectBack } from '../../../util';
import { BENCHMARK_PUBLISHED } from '../../../constants';

import './Preview.scss';
import OverridesTable from './OverridesTable';
import SavingsOverrideModal from './components/SavingsOverrideModal';
import { NotificationManager } from 'react-notifications';

class Preview extends React.Component {
  constructor(props) {
    super(props);
    const { employerId, benchmarkId } = this.props.match.params;
    const { benchmark } = this.props;

    this.state = {
      employerId: employerId,
      benchmarkId: benchmarkId,
      benchmarkObj: benchmark,
      isPublishConfirmationModalOpen: false,
      isSavingsOverrideOpen: false,
      publishAndContinue: false,
      isPublishDisabled: true,
      publishing: false,
      benefitCategoryValidationResult: {
        CENSUS_INFO: VALIDATION_INCOMPLETE,
        MEDICAL: VALIDATION_INCOMPLETE,
        DENTAL: VALIDATION_INCOMPLETE,
        VISION: VALIDATION_INCOMPLETE
      },
      overridingSavings: false
    };
  }

  componentDidMount() {
    const { match, findBenchmark, findPublishedBenchmark } = this.props;
    const { benchmarkId, employerId } = match.params;

    if (benchmarkId) {
      findBenchmark(benchmarkId);
    }

    if (employerId) {
      findPublishedBenchmark(employerId);
    }
  }

  componentDidUpdate(previousProps) {
    const { benchmark, benchmarkSaveResult, match, findBenchmark } = this.props;
    const { publishing, overridingSavings } = this.state;
    const { employerId, brokerId } = match.params;

    const benchmarkObj = benchmark;
    const { benchmarkId } = benchmarkObj;

    if (!_.isEmpty(benchmark) && benchmark !== previousProps.benchmark) {
      this.setIsBenchmarkEnabled(benchmark);
      this.setState({
        benchmarkObj
      });
    }
    if (
      benchmarkSaveResult !== previousProps.benchmarkSaveResult &&
      publishing
    ) {
      this.setState({ publishing: false });
      this.props.history.push(
        `/brokers/${brokerId}/employers/${employerId}/benchmarks`
      );
    }
    if (
      benchmarkSaveResult !== previousProps.benchmarkSaveResult &&
      overridingSavings
    ) {
      findBenchmark(benchmarkId);
      NotificationManager.success('Savings data updated successfully');
      this.setState({ overridingSavings: false });
    }
  }

  getFailedBenefitCategoryCount = (validationResult) => {
    const benefitCategories = ['MEDICAL', 'DENTAL', 'VISION'];
    return benefitCategories.reduce(
      (prev, benefitCategory) =>
        prev +
        (validationResult[benefitCategory] === VALIDATION_INCOMPLETE ? 1 : 0),
      0
    );
  }

  getSkippedBenefitCategoryCount = (validationResult) => {
    const benefitCategories = ['MEDICAL', 'DENTAL', 'VISION'];
    return benefitCategories.reduce(
      (prev, benefitCategory) =>
        prev +
        (validationResult[benefitCategory] === VALIDATION_SKIPPED ? 1 : 0),
      0
    );
  }

  setIsBenchmarkEnabled = (benchmark) => {
    const { benefitCategoryValidationResult } = this.state;
    let isPublishDisabled = undefined;

    isPublishDisabled =
      benefitCategoryValidationResult['CENSUS_INFO'] !== VALIDATION_PASSED ||
      this.getFailedBenefitCategoryCount(benefitCategoryValidationResult) > 0 ||
      this.getSkippedBenefitCategoryCount(benefitCategoryValidationResult) === 3 ||
      _.isNull(benchmark.summaryInfo) ||
      (benchmark &&
        benchmark.summaryInfo &&
        !benchmark.summaryInfo.isOverridden);
    this.setState({
      isPublishDisabled: isPublishDisabled
    });
  };

  onPublishButtonChange = () => {
    const { benchmarkObj } = this.state;
    benchmarkObj.status = BENCHMARK_PUBLISHED;
    this.setState({
      benchmarkObj
    });

    const { match } = this.props;
    const { employerId, benchmarkId } = match.params;

    this.savePublishedStatus(benchmarkId, benchmarkObj, employerId);
    this.setState((prevState) => ({
      publishAndContinue: true,
      isPublishConfirmationModalOpen: !prevState.isPublishConfirmationModalOpen
    }));
    NotificationManager.success('Published Benchmark successfully');
  };

  savePublishedStatus = (benchmarkId, benchmarkObj, employerId) => {
    if (benchmarkId) {
      this.setState({ publishing: true });
      this.props.saveBenchmark(benchmarkId, benchmarkObj, employerId);
    }
  };

  removeSummaryInformationForEmptyPlans = (benchmarkData) => {
    const { currentPlans } = benchmarkData || {};

    let benefitCategories = ['MEDICAL', 'DENTAL', 'VISION'];
    let omittedBenefitCategories = [];

    _.each(benefitCategories, (benefitCategory) => {
      if(currentPlans && _.isEmpty(currentPlans[benefitCategory])) {
        omittedBenefitCategories.push(benefitCategory);
      }
    });

    _.each(omittedBenefitCategories, (benefitCategory) => {
      delete benchmarkData.summaryInfo.benefitCategorySummary[benefitCategory];
    });
  }

  saveOverridenSalesInfo = (benchmarkId, benchmarkObj, employerId) => {
    this.removeSummaryInformationForEmptyPlans(benchmarkObj);
    if (benchmarkId) {
      this.setState({ overridingSavings: true });
      this.props.saveBenchmark(
        benchmarkId,
        benchmarkObj,
        employerId,
        'savings'
      );
    }
  };

  togglePublishConfirmationModal = () => {
    this.setState((prevState) => ({
      isPublishConfirmationModalOpen: !prevState.isPublishConfirmationModalOpen
    }));
  };

  toggleSavingsOverride = () => {
    this.setState((prevState) => ({
      isSavingsOverrideOpen: !prevState.isSavingsOverrideOpen
    }));
  };

  onClickSaveButton = (employerId) => {
    const { match, history } = this.props;
    const { params } = match;
    const { brokerId } = params;
    history.push(`/brokers/${brokerId}/employers/${employerId}/benchmarks`);
  };

  onClickCancelButton = () => {
    this.togglePublishConfirmationModal();
  };

  previewBenchmark = () => {
    const { benchmarkId } = this.state;
    const { employerId } = this.props.match.params;
    const benchmarkPreviewUrl = BenchmarkService.getPreviewBenchmarkUrl(
      benchmarkId,
      'Summary',
      employerId
    );
    window.open(benchmarkPreviewUrl, '_blank');
  };

  render() {
    const { history, match, publishedBenchmark = {}, benchmark } = this.props;
    const {
      employerId,
      benchmarkId,
      isPublishConfirmationModalOpen,
      isSavingsOverrideOpen,
      isPublishDisabled,
      benchmarkObj,
      benefitCategoryValidationResult
    } = this.state;
    const { benchmarkName } = benchmarkObj;
    const isReadOnly = match.path.includes('/view/');
    const { params } = match;
    const { brokerId } = params;

    return (
      <ApplicationWrapper>
        <HeaderContainer
          auto_id="benchmarks"
          title="Preview & Publish"
          backButtonUnderTittle={!isReadOnly}
          history={history}
          redirectBack={() => {
            this.props.history.push(
              redirectBack(
                undefined,
                'publish',
                employerId,
                benchmarkId,
                brokerId
              )
            );
          }}
          subtitle={benchmarkName}
        />
        <div className="content-container-header">Errors & Validation</div>

        <Row className="benchmark-preview">
          <Col className="page-content" xs="auto">
            <ContentContainer>
              <ValidationTable
                employerId={employerId}
                benchmarkId={benchmarkId}
                benchmark={benchmark}
                history={history}
                benefitCategoryValidationResult={
                  benefitCategoryValidationResult
                }
                match={match}
                isReadOnly={isReadOnly}
              ></ValidationTable>
            </ContentContainer>
          </Col>
          <Col className="page-actions" xs="auto">
            {!isReadOnly && (
              <>
                <Row>
                  <Col>
                    <Button
                      auto_id="save-and-close"
                      type="submit"
                      color="primary"
                      size="lg"
                      className="right-side-button"
                      onClick={() => {
                        this.onClickSaveButton(employerId);
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>
                    <Button
                      type="submit"
                      disabled={isPublishDisabled}
                      color="primary"
                      size="lg"
                      className="right-side-button"
                      onClick={this.togglePublishConfirmationModal}
                    >
                      Publish to ER Portal
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            <Row className="mt-1">
              <Col>
                <Button
                  type="submit"
                  disabled={isPublishDisabled}
                  color="primary"
                  outline={true}
                  size="lg"
                  className="right-side-button"
                  onClick={this.previewBenchmark}
                >
                  Preview
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="content-container-header mt-5">Overrides</div>
        <Row className="benchmark-preview">
          <Col className="page-content" xs="auto">
            <ContentContainer>
              <OverridesTable
                employerId={employerId}
                benchmarkId={benchmarkId}
                benchmark={benchmark}
                status={
                  benchmark &&
                  benchmark.summaryInfo &&
                  benchmark.summaryInfo.isOverridden
                    ? 'COMPLETE'
                    : 'INCOMPLETE'
                }
                onEditClick={this.toggleSavingsOverride}
                history={history}
                match={match}
                isReadOnly={isReadOnly}
              ></OverridesTable>
            </ContentContainer>
          </Col>
        </Row>
        <SavingsOverrideModal
          isOpen={isSavingsOverrideOpen}
          benchmark={benchmark}
          onConfirm={this.saveOverridenSalesInfo}
          toggle={this.toggleSavingsOverride}
        />
        <PublishConfirmationModal
          isOpen={isPublishConfirmationModalOpen}
          toggle={this.togglePublishConfirmationModal}
          onPublishButtonChange={this.onPublishButtonChange}
          publishedBenchmarkName={publishedBenchmark.benchmarkName}
          onButtonChange={this.onClickCancelButton}
        />
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  findBenchmark: (benchmarkId) => dispatch(findBenchmark(benchmarkId)),

  findPublishedBenchmark: (employerId) =>
    dispatch(findPublishedBenchmark(employerId)),

  saveBenchmark: (benchmarkId, benchmarkObj, employerId, step) =>
    dispatch(saveBenchmark(benchmarkId, benchmarkObj, employerId, step))
});

const mapStateToProps = (state) => {
  const {
    benchmark,
    publishedBenchmark,
    benchmarkSaveData
  } = state.benchmarkReducer;

  return {
    benchmark,
    publishedBenchmark,
    benchmarkSaveResult: benchmarkSaveData.benchmarkSaveResult
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
