/*
 * Copyright (C) 2020 Lumity, Inc. - All Rights Reserved
 *
 * CONFIDENTIAL
 *
 * All information contained herein is, and remains the property of Lumity, Inc. and its partners, if any.
 * The intellectual and technical concepts contained herein are proprietary to Lumity, Inc. and its partners
 * and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless
 * prior written permission is obtained from Lumity, Inc.
 */

import {
  GET_ALL_MOBILE_VERSIONS_START,
  GET_ALL_MOBILE_VERSIONS_SUCCESS,
  GET_ALL_MOBILE_VERSIONS_FAILURE,
  ADD_NEW_MOBILE_VERSION_START,
  ADD_NEW_MOBILE_VERSION_SUCCESS,
  ADD_NEW_MOBILE_VERSION_FAILURE
} from '../actions/mobile.action';

export default (
  state = {
    mobileVersionData: {
      isLoading: false,
      versionData: {
        content: null,
        metadata: null
      },
      versionError: null
    },
    createdVersionData: {
      isLoading: false,
      versionData: null,
      versionError: null
    }
  },
  { type, payload }
) => {
  switch (type) {
    case GET_ALL_MOBILE_VERSIONS_START:
      return {
        ...state,
        mobileVersionData: {
          isLoading: true,
          versionData: state.mobileVersionData.versionData
        }
      };
    case GET_ALL_MOBILE_VERSIONS_SUCCESS:
      return {
        ...state,
        mobileVersionData: {
          isLoading: false,
          versionData: payload
        }
      };
    case GET_ALL_MOBILE_VERSIONS_FAILURE:
      return {
        ...state,
        mobileVersionData: {
          isLoading: false,
          versionError: payload
        }
      };
    case ADD_NEW_MOBILE_VERSION_START:
      return {
        ...state,
        createdVersionData: {
          isLoading: true,
          versionData: state.versionData,
          versionError: state.versionError
        }
      };
    case ADD_NEW_MOBILE_VERSION_SUCCESS:
      return {
        ...state,
        createdVersionData: {
          isLoading: false,
          versionData: payload,
          versionError: state.createdVersionData.versionError
        }
      };
    case ADD_NEW_MOBILE_VERSION_FAILURE:
      return {
        ...state,
        createdVersionData: {
          isLoading: false,
          versionData: state.createdVersionData.versionData,
          versionError: payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
