/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import axios from 'axios';
import Util, { CONTENT_TYPE_HEADER, CONTENT_TYPE_MULTIPART_FORM } from '../util/apiUtil';

//configs
const baseURL = `${Util.baseApi}/${Util.version}/adapters`;

export default {
  /**
   * Employer lookup
   *
   * @returns {AxiosPromise<any>}
   */
  findEmployer(payload) {
    return axios.post(`${baseURL}/findEmployerDetails`, payload);
  },

  /**
   * Import new employer.
   *
   * @param payload - Employer payload/employee sync Data
   * @returns {AxiosPromise<any>}
   */
  create(payload) {
    return axios.post(`${baseURL}/createEmployerDetails`, payload);
  },

  /**
   * Import employer data files
   *
   * @param {*} employerId
   * @param {*} fileType
   * @param {*} fileBlob
   * @param {*} source
   * @param {*} fileName
   * @param {*} progressNotifier
   */
  uploadDataFile(
    employerId,
    fileType,
    planYear,
    fileBlob,
    source,
    fileName,
    progressNotifier
  ) {
    const formData = new FormData();
    formData.append('file', fileBlob, fileName);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      },
      onUploadProgress: progressNotifier
    };

    return axios.post(
      `${baseURL}/fileImport/${employerId}/file?isPreview=true&source=${source}&fileType=${fileType}&planYearId=${planYear}&send-notifications=${false}`,
      formData,
      config
    );
  },

  /**
   * Submit employer data files after correct validation errors
   *
   * @param {*} employerId
   * @param {*} fileType
   * @param {*} fileBlob
   * @param {*} source
   * @param {*} fileName
   * @param {*} isSendNotifications
   */
  submitDataFile(employerId, fileType, planYear, fileBlob, source, fileName, isSendNotifications) {
    const formData = new FormData();
    formData.append('file', fileBlob, fileName);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };

    return axios.post(
      `${baseURL}/fileImport/${employerId}/file?isPreview=false&source=${source}&fileType=${fileType}&planYearId=${planYear}&send-notifications=${isSendNotifications}`,
      formData,
      config
    );
  },

  createEmployeeAndIndividualFromForm(employerId, employeeForm) {
    return axios.post(
      `${baseURL}/forms/${employerId}/employeeForm`,
      employeeForm
    );
  },

  /**
   * Get employer imported file history
   *
   * @param {*} employerId
   */
  getEmployerFileHistory(employerId) {
    return axios.get(`${baseURL}/fileImport/${employerId}`);
  },

  downloadFile(syncDetailId) {
    return axios.get(`${baseURL}/fileImport/files/download/${syncDetailId}`, {
      responseType: 'blob'
    });
  },

  deleteDependent(employeeId, individualId) {
    return axios.delete(
      `${baseURL}/employee/${employeeId}/individual/${individualId}`
    );
  },

  /**
   * Get employer hris responses
   *
   * @param {*} employerId
   */
  getHRISResponses(employerId) {
    return axios.get(
      `${baseURL}/integrations/hris/employers/${employerId}/s3Documents`
    );
  },

  /**
   * Get employer benAdmin Responses
   * @param {*} employerId
   * @returns
   */
  getBenAdminResponses(employerId) {
    return axios.get(
      `${baseURL}/integrations/ben-admin/employers/${employerId}/ben-admin-responses`
    )
  },

  /**
   * get hris response
   * @param key
   * @returns {file}
   */
  downloadHRISResponse(key, fileName) {
    return `${baseURL}/integrations/hris/downloadHRISResponse?key=${key}&fileName=${fileName}`;
  },

  /**
   * get benadmin response
   * @param key
   * @returns {file}
   */
  downloadBenAdminResponse(key, fileName) {
    return `${baseURL}/integrations/ben-admin/download-benadmin-response?key=${key}&fileName=${fileName}`;
  },

  /**
   * Change individual's SSN
   * @param employeeId
   * @param individualId
   * @param newSsn
   */
  changeSsn(employeeId, individualId, newSsn) {
    return axios.put(
      `${baseURL}/employee/${employeeId}/individual/${individualId}/change-ssn`,
      null,
      { params: { newSsn: newSsn } }
    );
  }
};
