/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/options.actionTypes';
import { BenefitCategory } from 'constants/benefitConstants';

const initialState = {
  carrierOptions: {
    data: [],
    isFetching: false,
    error: null
  },
  planServiceTypes: {
    data: {
      [BenefitCategory.MEDICAL.value]: null,
      [BenefitCategory.DENTAL.value]: null,
      [BenefitCategory.VISION.value]: null
    },
    isFetching: false,
    error: null
  },
  plansUSAStates: {
    data: [],
    isFetching: false,
    error: null
  }
};

const optionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CARRIER_OPTIONS_IN_PROGRESS:
      return {
        ...state,
        carrierOptions: {
          data: [],
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_CARRIER_OPTIONS_COMPLETED:
      return {
        ...state,
        carrierOptions: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_CARRIER_OPTIONS_FAILED:
      return {
        ...state,
        carrierOptions: {
          data: [],
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_PLAN_SERVICE_TYPES_IN_PROGRESS:
      return {
        ...state,
        planServiceTypes: {
          ...state.planServiceTypes,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_PLAN_SERVICE_TYPES_COMPLETED:
      return {
        ...state,
        planServiceTypes: {
          data: {
            ...state.planServiceTypes.data,
            [action.benefitCategory]: {
              allServiceTypes: action.options,
              defaultServiceTypes: action.defaults
            }
          },
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_PLAN_SERVICE_TYPES_FAILED:
      return {
        ...state,
        planServiceTypes: {
          ...state.planServiceTypes,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_PLAN_USA_STATES_IN_PROGRESS:
      return {
        ...state,
        plansUSAStates: {
          data: [],
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_PLAN_USA_STATES_COMPLETED:
      return {
        ...state,
        plansUSAStates: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_PLAN_USA_STATES_FAILED:
      return {
        ...state,
        plansUSAStates: {
          // Intentionally invalidate old content.
          data: [],
          isFetching: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default optionsReducer;
