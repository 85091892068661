import axios from 'axios';
import Util from '../util/apiUtil';
import { EXPIRE_TIME, LOGGED_IN_USER_EMAIL } from '../constants/authConstants';
import { getCurrentUnixTime } from '../util/dateUtil';

export default {
  /**
   * get Auth token.
   *
   * @param payload - Login Credentials
   * @returns {AxiosPromise<any>}
   */
  getOAuthToken(email, password) {
    const formData = new FormData();
    formData.set('username', email);
    formData.set('password', password);
    formData.set('grant_type', 'password');
    formData.set('scope', 'ADMIN');

    return axios.post(`${Util.baseApi}/oauth/token`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Web-Auth-Scope': 'ADMIN'
      }
    });
  },

  /**
   * Store logged in user details.
   * @param expiresIn - Expires in
   * @param loggedUser - Logged user email
   */
  storeAuthDetails(expiresIn, loggedUser) {
    localStorage.setItem(EXPIRE_TIME, getCurrentUnixTime() + expiresIn);
    localStorage.setItem(LOGGED_IN_USER_EMAIL, loggedUser);
  },

  clearAuthDetails() {
    localStorage.clear(EXPIRE_TIME);
    localStorage.clear(LOGGED_IN_USER_EMAIL);
  },

  whoami() {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    return axios.get(`${Util.baseApi}/v1/individuals/whoami`, config);
  },

  /**
   * Sign out user from the backend and clear logged user details.
   */
  signOut() {
    return axios.delete(`${Util.baseApi}/oauth/token`).then(() => {
      this.clearAuthDetails();
    });
  },

  requestResetPassword(username) {
    return axios.post(`${Util.baseApi}/v1/resetPassword/request`, {
      username: username,
      internalAdmin: true
    });
  },

  validateResetPasswordToken(token) {
    return axios.get(
      `${Util.baseApi}/v1/resetPassword/validate?token=${token}`
    );
  },

  resetPassword(token, newPassword) {
    return axios.post(`${Util.baseApi}/v1/resetPassword`, {
      token: token,
      newPassword: newPassword
    });
  },

  changeUsername(individualId, newUsername) {
    const baseUrl = `${Util.baseApi}/${Util.version}/email-migrations`;
    return axios.put(
      `${baseUrl}/individuals/${individualId}/change-username`,
      null,
      { params: { newEmail: newUsername } }
    );
  }
};
