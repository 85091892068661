/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
export const GET_FILE_IMPORT_HISTORY_IN_PROGRESS =
  'GET_FILE_IMPORT_HISTORY_IN_PROGRESS';
export const GET_FILE_IMPORT_HISTORY_COMPLETED =
  'GET_FILE_IMPORT_HISTORY_COMPLETED';
export const GET_FILE_IMPORT_HISTORY_FAILED = 'GET_FILE_IMPORT_HISTORY_FAILED';

export const VALIDATE_IMPORT_FILE_IN_PROGRESS =
  'VALIDATE_IMPORT_FILE_IN_PROGRESS';
export const VALIDATE_IMPORT_FILE_PROGRESS = 'VALIDATE_IMPORT_FILE_PROGRESS';
export const VALIDATE_IMPORT_FILE_COMPLETED = 'VALIDATE_IMPORT_FILE_COMPLETED';
export const VALIDATE_IMPORT_FILE_FAILED = 'VALIDATE_IMPORT_FILE_FAILED';

export const SUBMIT_IMPORT_FILE_IN_PROGRESS = 'SUBMIT_IMPORT_FILE_IN_PROGRESS';
export const SUBMIT_IMPORT_FILE_COMPLETED = 'SUBMIT_IMPORT_FILE_COMPLETED';
export const SUBMIT_IMPORT_FILE_FAILED = 'SUBMIT_IMPORT_FILE_FAILED';

export const PARSE_IMPORT_DATA_IN_PROGRESS = 'PARSE_IMPORT_DATA_IN_PROGRESS';
export const PARSE_IMPORT_DATA_COMPLETED = 'PARSE_IMPORT_DATA_COMPLETED';
export const PARSE_IMPORT_DATA_FAILED = 'PARSE_IMPORT_DATA_FAILED';

export const DOWNLOAD_NOTIFIERS_IN_PROGRESS = 'DOWNLOAD_NOTIFIERS_IN_PROGRESS';
export const DOWNLOAD_NOTIFIERS_COMPLETED = 'DOWNLOAD_NOTIFIERS_COMPLETED';
export const DOWNLOAD_NOTIFIERS_FAILED = 'DOWNLOAD_NOTIFIERS_FAILED';
