/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { Button, Col, Row } from 'components/Atoms';
import PropTypes from 'prop-types';
import SaveWarning from '../SaveWarning/SaveWarning';

/**
 * Component for 'Save' and 'Save & Continue' buttons.
 * @param props
 * @returns {*}
 */
const SaveButtons = (props) => {

  const { disabledSaveAndContinue, disabledSave, showWarning, saveAndContinueButtonText, saveButtonText, hideSave, hideSaveAndContinue } = props;
  return (
    <>
      {!hideSaveAndContinue &&

      <SimpleSaveButton rowClass="mt-5" onPress={() => props.onClickSaveAndContinue()} buttonText={saveAndContinueButtonText} disabled={disabledSaveAndContinue}/>
      }
      {!hideSave &&

      <SimpleSaveButton outline onPress={() => props.onClickSave()} buttonText={saveButtonText} disabled={disabledSave}/>
      }
      {showWarning &&
      <SaveWarning />
      }
    </>
  );
};
const SimpleSaveButton=(props)=>{
  const { rowClass, disabled, outline, onPress, buttonText } = props;
  return(
    <Row className={rowClass}>
      <Col>
        <Button disabled={disabled} outline={outline} color="primary" className="right-side-button" size="lg" onClick={() => onPress()}>
          {buttonText}
        </Button>
      </Col>
    </Row>);
};

SaveButtons.propTypes = {
  disabledSaveAndContinue: PropTypes.bool,
  saveAndContinueButtonText: PropTypes.string,
  disabledSave: PropTypes.bool,
  saveButtonText: PropTypes.string,
  showWarning: PropTypes.bool,
  hideSaveAndContinue:PropTypes.bool,
  hideSave:PropTypes.bool
};

SaveButtons.defaultProps = {
  disabledSaveAndContinue: false,
  saveAndContinueButtonText: 'Save and Continue >',
  disabledSave: false,
  saveButtonText: 'Save',
  showWarning: true,
  hideSaveAndContinue:false,
  hideSave:false
};
export default SaveButtons;

