/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, Row, Col, Input, FormGroup, Alert } from 'components/Atoms';
import Icon from 'components/Icons';

import { VENDOR_NAMES, ERROR_MSGS } from 'modules/Integrations/constants';

export const WORKDAY_PAY_GROUP = 'workdayHrPayGroup';

export class WorkdayPayGroupMappping extends Component {
  static propTypes = {
    payGroupMappings: PropTypes.objectOf(PropTypes.string),
    // Expect (payGroupMappings) => any
    onPrevious: PropTypes.func,
    // Expect (payGroupMappings) => any
    onNext: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      payGroupMappings:
        props.payGroupMappings && props.payGroupMappings.length
          ? props.payGroupMappings
          : [
              {
                workdayHrPayGroup: '',
                payGroup: ''
              }
            ],
      ignoredPayGroups: [],
      errorMsgs: [],
      errors: {}
    };
  }

  updatePayGroupMapping = (index) => (e) => {
    let { payGroupMappings, errors } = this.state;
    const updatedPayGroupMappings = [...payGroupMappings];
    const payGroup = updatedPayGroupMappings[index];
    const { name, value } = e.target;

    payGroup[name] = value;
    if (value && errors[index] && errors[index][name]) {
      delete errors[index][name];
      if (_.isEmpty(errors[index])) delete errors[index];
    }
    this.setState({
      payGroupMappings: updatedPayGroupMappings
    });
  };

  onPrevious = () => {
    const { payGroupMappings } = this.state;
    const { selectedVendor, onPrevious } = this.props;
    let payGroupMappingsConfig = [];
    let workdayPayGroups = [];
    let errors = {};
    let errorMsgs = [];
    if (selectedVendor.name === VENDOR_NAMES.WORKDAY) {
      payGroupMappings.forEach(({ workdayHrPayGroup, payGroup }, index) => {
        if (_.isEmpty(workdayHrPayGroup) && _.isEmpty(payGroup)) return false;
        if (_.isEmpty(workdayHrPayGroup)) {
          errors = this.addError(index, WORKDAY_PAY_GROUP, errors);
          errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          return false;
        } else {
          if (workdayPayGroups.includes(workdayHrPayGroup)) {
            errors = this.addError(index, WORKDAY_PAY_GROUP, errors);
            errorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
          } else {
            workdayPayGroups.push(workdayHrPayGroup);
          }
        }
        if (_.isEmpty(payGroup)) {
          errors = this.addError(index, 'payGroup', errors);
          errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
        }

        workdayHrPayGroup = _.trim(workdayHrPayGroup);
        payGroup = _.trim(payGroup);
        payGroupMappingsConfig.push({
          workdayHrPayGroup,
          payGroup
        });
      });
    }

    if (_.isEmpty(errors)) {
      // set to null if it is empty array
      if (_.isEmpty(payGroupMappingsConfig)) {
        payGroupMappingsConfig = null;
      }
      onPrevious(payGroupMappings);
    } else {
      this.setState({
        errors,
        errorMsgs: _.uniq(errorMsgs)
      });
    }
  };

  addError(index, errorFieldName, errors) {
    errors = {
      ...errors,
      [index]: {
        ...errors[index],
        [errorFieldName]: true
      }
    };
    return errors;
  }

  onNext = () => {
    const { payGroupMappings } = this.state;
    const { selectedVendor, onNext } = this.props;
    let payGroupMappingsConfig = [];
    let workdayPayGroups = [];
    let errors = {};
    let errorMsgs = [];
    if (selectedVendor.name === VENDOR_NAMES.WORKDAY) {
      payGroupMappings.forEach(({ workdayHrPayGroup, payGroup }, index) => {
        if (_.isEmpty(workdayHrPayGroup) && _.isEmpty(payGroup)) return false;
        if (_.isEmpty(workdayHrPayGroup)) {
          errors = this.addError(index, WORKDAY_PAY_GROUP, errors);
          errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          return false;
        } else {
          if (workdayPayGroups.includes(workdayHrPayGroup)) {
            errors = this.addError(index, WORKDAY_PAY_GROUP, errors);
            errorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
          } else {
            workdayPayGroups.push(workdayHrPayGroup);
          }
        }
        if (_.isEmpty(payGroup)) {
          errors = this.addError(index, 'payGroup', errors);
          errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
        }

        workdayHrPayGroup = _.trim(workdayHrPayGroup);
        payGroup = _.trim(payGroup);
        payGroupMappingsConfig.push({
          workdayHrPayGroup,
          payGroup
        });
      });
    }
    if (_.isEmpty(errors)) {
      // set to null if it is empty array
      if (_.isEmpty(payGroupMappingsConfig)) {
        payGroupMappingsConfig = null;
      }
      onNext(payGroupMappingsConfig);
    } else {
      this.setState({
        errors,
        errorMsgs: _.uniq(errorMsgs)
      });
    }
  };

  addMoreConfigurations = () => {
    this.setState({
      payGroupMappings: [
        ...this.state.payGroupMappings,
        {
          workdayHrPayGroup: '',
          payGroup: ''
        }
      ]
    });
  };

  removeFieldRow = (index) => (e) => {
    const { payGroupMappings, errors } = this.state;
    const updatedPayGroupMappings = [...payGroupMappings];

    updatedPayGroupMappings.splice(index, 1);
    if (errors[index]) {
      delete errors[index];
      if (_.isEmpty(errors[index])) delete errors[index];
    }
    this.setState({
      payGroupMappings: updatedPayGroupMappings
    });
  };

  render() {
    const { selectedVendor } = this.props;
    const { payGroupMappings, errors, errorMsgs } = this.state;
    return (
      <div className="step-container mapping-container">
        <div className="body margin-bottom-30">
          <p className="subtitle">{selectedVendor.name}</p>
          {!_.isEmpty(errors) &&
            errorMsgs.map((errorMsg, index) => (
              <Alert key={index} color="danger" className="error-alert">
                {errorMsg}
              </Alert>
            ))}
          <Row className="group-sub-row">
            <Col className="group-sub-headers">
              {selectedVendor.name} Pay Group
            </Col>
            <Col className="group-sub-headers">BenAdmin Pay Group</Col>
          </Row>
          {payGroupMappings.map((payGroup, index) => (
            <Row>
              <Col md="1">
                <Button
                  color="link"
                  onClick={this.removeFieldRow(index)}
                  className="remove-group-icon"
                >
                  <Icon icon="minus-circle" />
                </Button>
              </Col>
              <Col className="pay-group-label workday-text-box">
                <FormGroup>
                  <Input
                    key={index}
                    type="text"
                    name="workdayHrPayGroup"
                    onChange={this.updatePayGroupMapping(index)}
                    value={payGroup.workdayHrPayGroup}
                    invalid={
                      errors[index] && errors[index][WORKDAY_PAY_GROUP]
                    }
                  />
                </FormGroup>
              </Col>
              <Col className="pay-group-label">
                <FormGroup>
                  <Input
                    key={index}
                    type="text"
                    name="payGroup"
                    onChange={this.updatePayGroupMapping(index)}
                    value={payGroup.payGroup}
                    invalid={errors[index] && errors[index]['payGroup']}
                  />
                </FormGroup>
              </Col>
            </Row>
          ))}
          <Row>
            <Button color="link" onClick={this.addMoreConfigurations}>
              +Add More
            </Button>
          </Row>
        </div>
        <Row className="action-btns">
          <Col>
            <Button
              className="form-element"
              outline
              color="primary"
              size="lg"
              onClick={this.onPrevious}
              disabled={!_.isEmpty(errors)}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              className="form-element"
              color="primary"
              size="lg"
              onClick={this.onNext}
              disabled={!_.isEmpty(errors)}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
