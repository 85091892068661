import React, { Component } from 'react';
import { ModalHeader, ModalBody, Col, Row, Alert } from 'reactstrap';
import {
  getOEMeetingUsers,
  getOeMeeting,
  saveOeMeeting,
  deleteOeMeeting,
  clearOEMeeting
} from '../../../../actions/benGuidePlanAction';
import { connect } from 'react-redux';
import _ from 'lodash';

import Switch from 'components/Buttons/Switch';
import FullscreenModal from 'components/FullscreenModal';
import { OE_MEETING_CONFIG, OE_MEETING_STATES } from '../../../../constants';
import ZoomMeetingConfigForm from '../ZoomMeetingConfigForm/ZoomMeetingConfigForm';
import CustomMeetingConfigForm from '../CustomMeetingConfigForm/CustomMeetingConfigForm';
import ConfirmationPopover from '../ConfirmationPopover/ConfirmationPopover';
import NotificationManager from 'components/Notifications/Notifications';
import { isCustomMeetingOngoing } from 'modules/BenGuides/util';

import './ScheduleMeeting.scss';
class ScheduleMeeting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isZoomEnabled: false,
      isOtherEnabled: false,
      isZoomPopoverOpen: false,
      isOtherPopoverOpen: false,
      changed: false,
      hasPlannedMeeting: false
    };
  }

  componentDidUpdate(prevProps) {
    const {
      isOpen,
      benGuide = {},
      saveInprogress,
      error,
      deleteInprogress,
      clearOEMeeting
    } = this.props;
    const { isZoomEnabled, isOtherEnabled } = this.state;
    const { oeMeeting } = benGuide || {};

    if (!prevProps.isOpen && isOpen) {
      const { type } = oeMeeting || {};
      if (type) {
        this.setState({ changed: true, hasPlannedMeeting: true }, () => {
          this.toggleConfig(type, true);
        });
      }
    }

    //close popover when modal is closing
    if (!isOpen && prevProps.isOpen) {
      clearOEMeeting();
      this.setState({
        isZoomEnabled: false,
        isOtherEnabled: false,
        isZoomPopoverOpen: false,
        isOtherPopoverOpen: false,
        changed: false,
        hasPlannedMeeting: false
      });
    }

    if (prevProps.saveInprogress && !saveInprogress && !error) {
      NotificationManager.success(
        _.isEmpty(prevProps.oeMeeting)
          ? 'OE Meeting saved successfully'
          : 'OE Meeting updated successfully'
      );
      this.setState({ hasPlannedMeeting: true });
    }

    if (prevProps.saveInprogress && !saveInprogress && error) {
      NotificationManager.error('Unable to schedule open enrollment meeting');
    }

    if (prevProps.deleteInprogress && !deleteInprogress && !error) {
      NotificationManager.success('OE Meeting deleted successfully');
      if (!isOtherEnabled || !isZoomEnabled) {
        this.setState({ hasPlannedMeeting: false, changed: false });
      }
    }
  }

  getOeMeetingLinkTextByState = (state) => {
    switch (state) {
      case OE_MEETING_STATES.SCHEDULED.value:
        return 'Update';
      case OE_MEETING_STATES.STARTED.value:
        return 'Update';
      case OE_MEETING_STATES.RECORDING_PROCESSING.value:
        return 'Reschedule';
      case OE_MEETING_STATES.RECORDING_FAILED.value:
        return 'Reschedule';
      case OE_MEETING_STATES.RECORDING_SUCCESS.value:
        return 'Reschedule';
      default:
        return 'Configure';
    }
  };

  handleChangeConfig = (option, toggleState) => {
    const { changed, hasPlannedMeeting } = this.state;
    if (changed || hasPlannedMeeting) {
      this.togglePopover(option);
      return;
    }
    this.toggleConfig(option, toggleState);
  };

  togglePopover = (option) => {
    switch (option) {
      case OE_MEETING_CONFIG.ZOOM.value:
        this.setState((prevState) => ({
          ...prevState,
          isZoomPopoverOpen: !prevState.isZoomPopoverOpen,
          isOtherPopoverOpen: false
        }));
        break;
      case OE_MEETING_CONFIG.CUSTOM.value:
        this.setState((prevState) => ({
          ...prevState,
          isOtherPopoverOpen: !prevState.isOtherPopoverOpen,
          isZoomPopoverOpen: false
        }));
        break;
      default:
        break;
    }
  };

  toggleConfig = (option, enabled) => {
    const { oeMeeting, benGuide } = this.props;
    const { hasPlannedMeeting } = this.state;
    const { meetingNumber } = oeMeeting || {};
    const { type } = oeMeeting || {};

    if (hasPlannedMeeting && !_.isEmpty(oeMeeting)) {
      this.handleDeleteOeMeeting(benGuide, meetingNumber, type);
    }

    switch (option) {
      case OE_MEETING_CONFIG.ZOOM.value:
        this.setState((prevState) => ({
          ...prevState,
          isZoomEnabled: enabled ? enabled : !prevState.isZoomEnabled,
          isOtherEnabled: false,
          isZoomPopoverOpen: false,
          changed: enabled
        }));
        break;
      case OE_MEETING_CONFIG.CUSTOM.value:
        this.setState((prevState) => ({
          ...prevState,
          isZoomEnabled: false,
          isOtherEnabled: enabled ? enabled : !prevState.isOtherEnabled,
          isOtherPopoverOpen: false,
          changed: enabled
        }));
        break;
      default:
        break;
    }
  };

  handleDeleteOeMeeting = (benGuide, meetingNumber, type) => {
    const { deleteOeMeeting } = this.props;
    deleteOeMeeting(benGuide, meetingNumber, type);
  };

  render() {
    const {
      isOpen,
      onToggle,
      oeMeeting: scheduledOeMeeting,
      benGuide
    } = this.props;
    const { state = '', type } = scheduledOeMeeting || {};
    const {
      isZoomEnabled,
      isOtherEnabled,
      isZoomPopoverOpen,
      isOtherPopoverOpen
    } = this.state;

    const isMeetingCompleted = Boolean(
      state === OE_MEETING_STATES.RECORDING_PROCESSING.value ||
        state === OE_MEETING_STATES.RECORDING_SUCCESS.value ||
        state === OE_MEETING_STATES.RECORDING_FAILED.value
    );

    const switchDisabled = Boolean(
      (scheduledOeMeeting &&
        scheduledOeMeeting.state &&
        scheduledOeMeeting.state === OE_MEETING_STATES.STARTED.value) ||
        (scheduledOeMeeting &&
          type === OE_MEETING_CONFIG.CUSTOM.value &&
          isCustomMeetingOngoing(scheduledOeMeeting))
    );

    return (
      <FullscreenModal
        isOpen={isOpen}
        toggle={onToggle}
        className="schedule-oe-meeting-wrapper"
      >
        <ModalHeader>
          <div className="title">
            {this.getOeMeetingLinkTextByState(state)} OE Video Meeting
          </div>
        </ModalHeader>

        <ModalBody>
          <React.Fragment>
            {state &&
              (isMeetingCompleted ? (
                <Row>
                  <Col>
                    <Alert color="danger">
                      The Zoom meeting is completed. If you reschedule this, it
                      will delete any records of the current meeting and create
                      a new one.
                    </Alert>
                  </Col>
                </Row>
              ) : scheduledOeMeeting.meetingExpiredOrDeleted &&
                type === OE_MEETING_CONFIG.ZOOM.value ? (
                <Row>
                  <Col>
                    <Alert color="danger">
                      This Zoom meeting is not valid. Please reschedule to
                      create a new meeting.
                    </Alert>
                  </Col>
                </Row>
              ) : (
                <></>
              ))}

            {state === OE_MEETING_STATES.STARTED.value && (
              <Row>
                <Col>
                  <Alert color="danger" className="error-alert mt-3">
                    This Zoom meeting is currently in progress.
                  </Alert>
                </Col>
              </Row>
            )}
            {type === OE_MEETING_CONFIG.CUSTOM.value &&
              isCustomMeetingOngoing(scheduledOeMeeting) && (
                <Row>
                  <Col>
                    <Alert color="danger" className="error-alert mt-3">
                      This Custom meeting is currently in progress.
                    </Alert>
                  </Col>
                </Row>
              )}
          </React.Fragment>

          <div className="config-toggle-container">
            <div
              className={`meeting-form-container ${
                isZoomEnabled ? 'active' : ''
              }`}
            >
              <div className="config-toggle">
                <div>
                  <b>Zoom Meeting (Lumity)</b>
                </div>
                <Switch
                  id="zoom-meeting-switch"
                  checked={isZoomEnabled}
                  onChange={(state) =>
                    this.handleChangeConfig(OE_MEETING_CONFIG.ZOOM.value, state)
                  }
                  disabled={switchDisabled}
                />
                <ConfirmationPopover
                  className="oe-meeting-popover"
                  isOpen={isZoomPopoverOpen}
                  onConfirm={() =>
                    this.toggleConfig(
                      OE_MEETING_CONFIG.ZOOM.value,
                      !isZoomEnabled
                    )
                  }
                  target="zoom-meeting-switch"
                  onDecline={() =>
                    this.togglePopover(OE_MEETING_CONFIG.ZOOM.value, false)
                  }
                  meetingConfig={OE_MEETING_CONFIG.ZOOM.value}
                  isToggleEnabled={isZoomEnabled}
                />
              </div>
              {isZoomEnabled && (
                <div className="selected-option">
                  <ZoomMeetingConfigForm
                    isOpen={isOpen}
                    benGuide={benGuide}
                    onDeleteOeMeeting={this.handleDeleteOeMeeting}
                    onToggle={onToggle}
                  />
                </div>
              )}
            </div>

            <div
              className={`meeting-form-container ${
                isOtherEnabled ? 'active' : ''
              }`}
            >
              <div className="config-toggle">
                <div>
                  <b>Custom Meeting (Manual)</b>
                </div>
                <Switch
                  id="other-meeting-switch"
                  checked={isOtherEnabled}
                  onChange={(state) =>
                    this.handleChangeConfig(
                      OE_MEETING_CONFIG.CUSTOM.value,
                      state
                    )
                  }
                  disabled={switchDisabled}
                />
                <ConfirmationPopover
                  className="oe-meeting-popover"
                  isOpen={isOtherPopoverOpen}
                  onConfirm={() =>
                    this.toggleConfig(
                      OE_MEETING_CONFIG.CUSTOM.value,
                      !isOtherEnabled
                    )
                  }
                  target="other-meeting-switch"
                  onDecline={() =>
                    this.togglePopover(OE_MEETING_CONFIG.CUSTOM.value)
                  }
                  meetingConfig={OE_MEETING_CONFIG.CUSTOM.value}
                  isToggleEnabled={isOtherEnabled}
                />
              </div>
              {isOtherEnabled && (
                <div className="selected-option">
                  <CustomMeetingConfigForm
                    benGuide={benGuide}
                    onDeleteOeMeeting={this.handleDeleteOeMeeting}
                    onToggle={onToggle}
                    isOpen={isOpen}
                  />
                </div>
              )}
            </div>
          </div>
        </ModalBody>
      </FullscreenModal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getOEMeetingUsers: () => dispatch(getOEMeetingUsers()),
  getOeMeeting: (benGuideId) => dispatch(getOeMeeting(benGuideId)),
  saveOeMeeting: (benGuide, oeMeeting) =>
    dispatch(saveOeMeeting(benGuide, oeMeeting)),
  deleteOeMeeting: (benGuide, meetingNumber, type) =>
    dispatch(deleteOeMeeting(benGuide, meetingNumber, type)),
  clearOEMeeting: () => dispatch(clearOEMeeting())
});

const mapStateToProps = (state) => {
  const { oeMeeingUsers, oeMeeting } = state.benGuideReducer;
  return {
    users: oeMeeingUsers.users,
    ...oeMeeting
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleMeeting);
