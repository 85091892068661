/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from './context.actions';

export const setEmployerContext = (employerContext) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_EMPLOYER_CONTEXT,
      payload: employerContext
    });
  };
};

export const setNavigationContext = (navigationTree) => {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.SET_NAVIGATION_CONTEXT,
      payload: navigationTree
    });
};

export const setOrganisationContext = (organizationContext) => {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.SET_ORGANISATION_CONTEXT,
      payload: organizationContext
    });
};
