/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const FIND_EMPLOYER_CONFIGURATION_MODULES = 'FIND_EMPLOYER_CONFIGURATION_MODULES';
export const FIND_EMPLOYER_CONFIGURATION_MODULES_ERROR = 'FIND_EMPLOYER_CONFIGURATION_MODULES_ERROR';
export const CREATE_EMPLOYER_CONFIGURATION_MODULES = 'CREATE_EMPLOYER_CONFIGURATION_MODULES';
export const CREATE_EMPLOYER_CONFIGURATION_MODULES_ERROR = 'CREATE_EMPLOYER_CONFIGURATION_MODULES_ERROR';
export const UPDATE_EMPLOYER_CONFIGURATION_MODULES = 'UPDATE_EMPLOYER_CONFIGURATION_MODULES';
export const UPDATE_EMPLOYER_CONFIGURATION_MODULES_ERROR = 'UPDATE_EMPLOYER_CONFIGURATION_MODULES_ERROR';

export const GET_RENEWALS_ENABLE_STATUS = 'GET_RENEWALS_ENABLE_STATUS';
export const GET_RENEWALS_ENABLE_STATUS_ERROR = 'GET_RENEWALS_ENABLE_STATUS_ERROR';
export const FIND_CONFIG_STATUS = 'FIND_CONFIG_STATUS';
export const FIND_CONFIG_STATUS_ERROR = 'FIND_CONFIG_STATUS_ERROR';

export const FIND_EMPLOYER_CONFIGURATION_ISSUE_LOG_ACCOUNT_LEVEL = 'FIND_EMPLOYER_CONFIGURATION_ISSUE_LOG_ACCOUNT_LEVEL';
export const FIND_EMPLOYER_CONFIGURATION_ISSUE_LOG_EMPLOYEE_LEVEL = 'FIND_EMPLOYER_CONFIGURATION_ISSUE_LOG_EMPLOYEE_LEVEL';

export const GET_ISSUE_LOG_EMPLOYER = 'GET_ISSUE_LOG_EMPLOYER';
export const GET_ISSUE_LOG_EMPLOYER_ERROR = 'GET_ISSUE_LOG_EMPLOYER_ERROR';