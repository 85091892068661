/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { Col, Row } from 'components/Atoms';
import Icon from 'components/Icons';
import moment from 'moment';
import { permitIf } from 'components/hoc/Permit';

import SwitchWithConfirmation from 'components/Buttons/SwitchWithConfirmation';
import './EmployerConfigToggleV2.scss';
const EmployerConfigToggleV2 = (props) => {
  const {
    toggleId,
    iconClass,
    config,
    message,
    warningMessage,
    switchCallBack,
    disabled,
    showLoading = false,
    rolesList
  } = props;

  const SecuredSwitchWithConfirmation = permitIf(
    SwitchWithConfirmation,
    rolesList
  );

  const Switcher = (props) => {
    return props.rolesList ? (
      <SecuredSwitchWithConfirmation {...props} warningMessage={warningMessage}/>
    ) : (
      <SwitchWithConfirmation {...props} warningMessage={warningMessage}/>
    );
  };
  return (
    <>
      <Row className="epm-config-wrapper">
        <Col className={`mt-2 status-icon-column ${iconClass || ''}`}>
          {config.value === 'true' ? (
            <Icon icon="check" size="lg" className="toggle-enabled-color" />
          ) : (
            <Icon icon="times" size="lg" className="toggle-disabled-color" />
          )}
        </Col>
        <Col className="mt-2 status-column">
          <b className="mx-1">{message} </b>
          {config.lastUpdatedTs && config.lastUpdatedByLoginId && (
            <i>
              {config.value === 'true' ? 'enabled' : 'disabled'}
              {` ${moment(config.lastUpdatedTs).format('lll')} by ${
                config.lastUpdatedByLoginId
              }`}
            </i>
          )}
        </Col>
        <Col className="mt-2 toggle-switch-column text-right">
          <div className="float-right">
            <Switcher
              toggleId={toggleId}
              disabled={disabled ? disabled : false}
              className="mr-2"
              value={config.value === 'true'}
              message={
                config.value === 'true'
                  ? `Are you sure you would like to disable ${message}?`
                  : `Are you sure you would like to enable ${message}?`
              }
              confirmText={config.value === 'true' ? 'Disable' : 'Enable'}
              confirmCallback={switchCallBack}
              rolesList={rolesList}
            />
            {showLoading && <Icon icon="circle-notch" spin className="mt-1" />}
          </div>
        </Col>
      </Row>
      <div className="mt-2 mx-3">
        {config.value === 'true' && props.children}
      </div>
    </>
  );
};

export default EmployerConfigToggleV2;
