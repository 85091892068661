/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const GET_ALL_SYNC_DETAILS = 'GET_ALL_SYNC_DETAILS';
export const GET_ALL_SYNC_DETAILS_SUCCESS = 'GET_ALL_SYNC_DETAILS_SUCCESS';
export const GET_ALL_SYNC_DETAILS_ERROR = 'GET_ALL_SYNC_DETAILS_ERROR';

export const START_ALL_SYNC = 'START_ALL_SYNC';
export const START_ALL_SYNC_SUCCESS = 'START_ALL_SYNC_SUCCESS';
export const START_ALL_SYNC_ERROR = 'START_ALL_SYNC_ERROR';

export const GET_EMPLOYER_INTEGRATION_DETAILS =
  'GET_EMPLOYER_INTEGRATION_DETAILS';
export const GET_EMPLOYER_INTEGRATION_DETAILS_SUCCESS =
  'GET_EMPLOYER_INTEGRATION_DETAILS_SUCCESS';
export const GET_EMPLOYER_INTEGRATION_DETAILS_ERROR =
  'GET_EMPLOYER_INTEGRATION_DETAILS_ERROR';

export const GET_INTEGRATIONS_FEATURE_CONF = 'GET_INTEGRATIONS_FEATURE_CONF';
export const GET_INTEGRATIONS_FEATURE_CONF_SUCCESS =
  'GET_INTEGRATIONS_FEATURE_CONF_SUCCESS';
export const GET_INTEGRATIONS_FEATURE_CONF_ERROR =
  'GET_INTEGRATIONS_FEATURE_CONF_ERROR';

export const GET_BENADMIN_EMPLOYER_SYNC_DETAILS =
  'GET_BENADMIN_EMPLOYER_SYNC_DETAILS';
export const GET_BENADMIN_EMPLOYER_SYNC_DETAILS_SUCCESS =
  'GET_BENADMIN_EMPLOYER_SYNC_DETAILS_SUCCESS';
export const GET_BENADMIN_EMPLOYER_SYNC_DETAILS_ERROR =
  'GET_BENADMIN_EMPLOYER_SYNC_DETAILS_ERROR';

export const START_EMPLOYER_BENADMIN_SYNC = 'START_EMPLOYER_BENADMIN_SYNC';
export const START_EMPLOYER_BENADMIN_SYNC_SUCCESS =
  'START_EMPLOYER_BENADMIN_SYNC_SUCCESS';
export const START_EMPLOYER_BENADMIN_SYNC_ERROR =
  'START_EMPLOYER_BENADMIN_SYNC_ERROR';

export const GET_LAST_BENADMIN_SYNC_STATUS = 'GET_LAST_BENADMIN_SYNC_STATUS';
export const GET_LAST_BENADMIN_SYNC_STATUS_SUCCESS =
  'GET_LAST_BENADMIN_SYNC_STATUS_SUCCESS';
export const GET_LAST_BENADMIN_SYNC_STATUS_ERROR =
  'GET_LAST_BENADMIN_SYNC_STATUS_DETAILS_ERROR';

export const TOGGLE_DAILY_SYNC = 'TOGGLE_DAILY_SYNC';
export const TOGGLE_DAILY_SYNC_SUCCESS = 'TOGGLE_DAILY_SYNC_SUCCESS';
export const TOGGLE_DAILY_SYNC_ERROR = 'TOGGLE_DAILY_SYNC_ERROR';

export const GET_BENADMIN_INTEGRATIONS_FEATURE_CONF =
  'GET_BENADMIN_INTEGRATIONS_FEATURE_CONF';
export const GET_BENADMIN_INTEGRATIONS_FEATURE_CONF_SUCCESS =
  'GET_BENADMIN_INTEGRATIONS_FEATURE_CONF_SUCCESS';
export const GET_BENADMIN_INTEGRATIONS_FEATURE_CONF_ERROR =
  'GET_BENADMIN_INTEGRATIONS_FEATURE_CONF_ERROR';

export const UPDATE_BENADMIN_INTEGRATIONS_FEATURE_CONF =
  'UPDATE_BENADMIN_INTEGRATIONS_FEATURE_CONF';
export const UPDATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_SUCCESS =
  'UPDATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_SUCCESS';
export const UPDATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_ERROR =
  'UPDATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_ERROR';

export const CREATE_BENADMIN_INTEGRATIONS_FEATURE_CONF =
  'CREATE_BENADMIN_INTEGRATIONS_FEATURE_CONF';
export const CREATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_SUCCESS =
  'CREATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_SUCCESS';
export const CREATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_ERROR =
  'CREATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_ERROR';

export const UPDATE_BENADMIN_DAILY_SCHEDULE = 'UPDATE_BENADMIN_DAILY_SCHEDULE';
export const UPDATE_BENADMIN_DAILY_SCHEDULE_SUCCESS =
  'UPDATE_BENADMIN_DAILY_SCHEDULE_SUCCESS';
export const UPDATE_BENADMIN_DAILY_SCHEDULE_ERROR =
  'UPDATE_BENADMIN_DAILY_SCHEDULE_ERROR';

export const GET_BENADMIN_DAILY_SCHEDULE = 'GET_BENADMIN_DAILY_SCHEDULE';
export const GET_BENADMIN_DAILY_SCHEDULE_SUCCESS =
  'GET_BENADMIN_DAILY_SCHEDULE_SUCCESS';
export const GET_BENADMIN_DAILY_SCHEDULE_ERROR =
  'GET_BENADMIN_DAILY_SCHEDULE_ERROR';

export const GET_BENADMIN_SYNC_ERROR_FILTERS =
  'GET_BENADMIN_SYNC_ERROR_FILTERS';
export const GET_BENADMIN_EMPLOYEE_SYNC_FILTERS_SUCCESS =
  'GET_BENADMIN_EMPLOYEE_SYNC_FILTERS_SUCCESS';
export const GET_BENADMIN_ENROLLMENT_SYNC_FILTERS_SUCCESS =
  'GET_BENADMIN_ENROLLMENT_SYNC_FILTERS_SUCCESS';
export const GET_BENADMIN_SYNC_ERROR_FILTERS_ERROR =
  'GET_BENADMIN_SYNC_ERROR_FILTERS_ERROR';

export const GET_BENADMIN_SYNC_ERROR_LIST = 'GET_BENADMIN_SYNC_ERROR_LIST';
export const GET_BENADMIN_EMPLOYEE_SYNC_ERROR_LIST_SUCCESS =
  'GET_BENADMIN_EMPLOYEE_SYNC_ERROR_LIST_SUCCESS';
export const GET_BENADMIN_ENROLLMENT_SYNC_ERROR_LIST_SUCCESS =
  'GET_BENADMIN_ENROLLMENT_SYNC_ERROR_LIST_SUCCESS';
export const GET_BENADMIN_SYNC_ERROR_LIST_ERROR =
  'GET_BENADMIN_SYNC_ERROR_LIST_ERROR';

export const DOWNLOAD_BENADMIN_SYNC_ERROR_REPORT =
  'DOWNLOAD_BENADMIN_SYNC_ERROR_REPORT';
export const DOWNLOAD_BENADMIN_SYNC_ERROR_REPORT_SUCCESS =
  'DOWNLOAD_BENADMIN_SYNC_ERROR_REPORT_SUCCESS';
export const DOWNLOAD_BENADMIN_SYNC_ERROR_REPORT_ERROR =
  'DOWNLOAD_BENADMIN_SYNC_ERROR_REPORT_ERROR';

export const GET_BEN_ADMIN_CONFIG_DETAILS = 'GET_BEN_ADMIN_CONFIG_DETAILS';
export const GET_BEN_ADMIN_CONFIG_DETAILS_SUCCESS =
  'GET_BEN_ADMIN_CONFIG_DETAILS_SUCCESS';
export const GET_BEN_ADMIN_CONFIG_DETAILS_ERROR =
  'GET_BEN_ADMIN_CONFIG_DETAILS_ERROR';
