/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import { Row, Col, Button } from 'components/Atoms';
import Loader from 'components/CircularProgress';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import { NotificationManager } from 'react-notifications';
import { getUrlParameter } from 'util/urlUtil';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import SurveyInfo from './components/SurveyInfo';
import RecipientList from './components/RecipientList';
import SurveyResult from './components/SurveyResult';
import DialogSendSurvey from './components/DialogSendSurvey';
import * as ActionTypes from '../../actionTypes/surveys.actionTypes';
import PageActionButton from 'components/Buttons/PageActionButton';

import {
  getSurveyDetails,
  getSurveyParticipants,
  publishSurvey,
  sendSurvey,
  downloadSurveyResponses
} from '../../actions/surveysAction';

import AddButton from 'components/Buttons/AddButton';
import { ERROR_CODE_MSG_MAP } from '../../constants';

import './SurveyDetails.scss';

class SurveyDetails extends React.Component {
  constructor(props) {
    super(props);
    const { employerId, surveyId } = this.props.match.params;
    this.employerId = employerId;
    this.surveyId = surveyId;
    let selectedTab = getUrlParameter('to');
    const tabSelection =
      ['surveyInfo', 'participants', 'results'].indexOf(selectedTab) === -1
        ? 'surveyInfo'
        : selectedTab;
    this.state = {
      employerId,
      surveyId,
      activeTab: tabSelection,
      bShowSendDialog: false,
      selectedParticipant: null
    };
    this.props.getSurveyDetails(surveyId);
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { surveyId } = params;
    this.props.getSurveyDetails(surveyId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { requestStatus, serviceError, match } = this.props;
    const { requestStatus: prevRequestStatus } = prevProps;
    const { params  } = match;
    const { surveyId } = params;
    if (prevRequestStatus === requestStatus) return;

    if (serviceError) {
      // Generic Error
     switch (requestStatus) {
       case ActionTypes.PUBLISH_SURVEY_ERROR:
         const errorCode = serviceError.response.data.code;
         NotificationManager.error(
           ERROR_CODE_MSG_MAP[errorCode] || serviceError.message
         );
         break;
       default:
         NotificationManager.error(serviceError.message);
         break;
     }
      return;
    }
    switch (requestStatus) {
      case ActionTypes.PUBLISH_SURVEY_SUCCESS: {
        NotificationManager.success('Survey Sent to Employer Successfully');
        this.props.getSurveyDetails(surveyId);
        return;
      }
      case ActionTypes.SEND_SURVEY_SUCCESS: {
        NotificationManager.success(
          'Survey emails have been scheduled successfully'
        );
        this.setState(
          { bShowSendDialog: false, selectedParticipant: null },
          () => {
            this.props.getSurveyDetails(this.state.surveyId);
          }
        );
        return;
      }
      case ActionTypes.SEND_SURVEY_ERROR: {
        NotificationManager.warning(
          'Notifications for this client are turned off. Please turn on notifications to re-send the survey.'
        );
        this.setState(
          { bShowSendDialog: false, selectedParticipant: null },
          () => {
            this.props.getSurveyDetails(this.state.surveyId);
          }
        );
        return;
      }
      default:
        break;
    }
  }

  sendSurvey = (email) => {
    // send to all pending participants or resend to an email.
    this.props.sendSurvey(this.surveyId, email ? [email] : undefined);
  };

  toggleSendDialog = () => {
    this.setState({
      bShowSendDialog: !this.state.bShowSendDialog,
      selectedParticipant: null
    });
  };

  showSendConfirmation = (email) => {
    this.setState({
      bShowSendDialog: true,
      selectedParticipant: email
    });
  };

  setActiveTab = (tabId) => {
    this.setState({ activeTab: tabId });
  };

  editSurvey = () => {
    const { brokerId } = this.props.match.params;
    const employerId = this.employerId;
    const surveyId = this.surveyId;
    const { match } = this.props;
    const { params } = match;
    this.props.history.push(
      `/brokers/${brokerId}/employers/${employerId}/surveys/edit/${surveyId}`
    );
  };

  publishSurvey = () => {
    const surveyId = this.surveyId;
    this.props.publishSurvey(surveyId);
  };

  downloadSurveyResponses = () => {
    const surveyId = this.surveyId;
    this.props.downloadSurveyResponses(surveyId);
  };

  renderActionPane(tabID) {
    const { status, pendingCount } = this.props.surveyDetails || {};
    switch (tabID) {
      case 'surveyInfo': {
        return (
          <React.Fragment>
            <AddButton onClick={this.editSurvey} title="Edit Survey" />
            <PageActionButton
              outline
              disabled={status !== 'DRAFT'}
              onClick={this.publishSurvey}
            >
              Publish & Notify ER Admin
              </PageActionButton>
          </React.Fragment>
        );
      }

      case 'participants': {
        return (
          <AddButton
            onClick={() => this.showSendConfirmation()}
            disabled={pendingCount === 0}
            title={
              pendingCount === 0 ? (
                <span> Send Survey</span>
              ) : (
                <span>
                  <span>Send Survey to </span>
                  <br />
                  <span>{`${pendingCount} Pending Recipients`}</span>
                </span>
              )
            }
          />
        );
      }
      case 'results': {
        return (
          <div className="downloader">
            <h5 className="title">Individual results</h5>
            {status !== 'AWAITING_APPROVAL' && status !== 'DRAFT' && (
              <Button
                className="link"
                color="link"
                onClick={this.downloadSurveyResponses}
              >
                Download
              </Button>
            )}
          </div>
        );
      }
      default:
        break;
    }
  }
  render() {
    const employerId = this.employerId;
    const surveyId = this.surveyId;
    const {
      surveyDetails,
      isLoading,
      surveyParticipants,
      getSurveyParticipants
    } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    const { surveyName, reportUrl, pendingCount } = surveyDetails || {};
    const { bShowSendDialog, activeTab, selectedParticipant } = this.state;

    return (
      <ApplicationWrapper>
        <HeaderContainer title={surveyName ? surveyName : 'Survey'} />
        <Row className="page-survey-details">
          <Col className="page-content">
            <Tabs
              className="tab-view"
              defaultTab={activeTab}
              onChange={(tabId) => this.setActiveTab(tabId)}
            >
              <TabList className="tab-list">
                <Tab tabFor="surveyInfo">Survey Information</Tab>
                <Tab tabFor="participants">
                  Recipients ({pendingCount} Pending)
                </Tab>
                <Tab tabFor="results">Results</Tab>
              </TabList>
              <TabPanel tabId="surveyInfo">
                <ContentContainer>
                  <SurveyInfo data={surveyDetails} />
                </ContentContainer>
              </TabPanel>
              <TabPanel tabId="participants">
                <ContentContainer>
                  <RecipientList
                    data={surveyParticipants}
                    surveyId={surveyId}
                    employerId={employerId}
                    showSendConfirmation={this.showSendConfirmation}
                    getSurveyParticipants={getSurveyParticipants}
                  />
                </ContentContainer>
              </TabPanel>
              <TabPanel tabId="results">
                <ContentContainer>
                  {reportUrl && <SurveyResult url={reportUrl} />}
                </ContentContainer>
              </TabPanel>
            </Tabs>
          </Col>
          <Col className="page-actions">{this.renderActionPane(activeTab)}</Col>
        </Row>
        <DialogSendSurvey
          isOpen={bShowSendDialog}
          toggle={this.toggleSendDialog}
          confirmCallBack={this.sendSurvey}
          userCount={pendingCount}
          selectedParticipant={selectedParticipant}
        />
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSurveyDetails: (employerId, value) =>
    dispatch(getSurveyDetails(employerId, value)),
  getSurveyParticipants: (config) => dispatch(getSurveyParticipants(config)),
  publishSurvey: (surveyId) => dispatch(publishSurvey(surveyId)),
  sendSurvey: (id, email) => dispatch(sendSurvey(id, email)),
  downloadSurveyResponses: (surveyId) =>
    dispatch(downloadSurveyResponses(surveyId))
});

const mapStateToProps = (state) => {
  const {
    surveyDetails,
    surveyParticipants,
    requestStatus,
    serviceError,
    isLoading
  } = state.surveysReducer;
  return {
    surveyDetails,
    surveyParticipants,
    requestStatus,
    serviceError,
    isLoading
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetails);
