/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/renewals.actionTypes';

const initialState = {
  configStatus: { id: null, value: 'false', lastUpdatedTs: null },
  offersConfigStatus: { id: null, value: 'false', lastUpdatedTs: null },
  employerOffersConfigsUpdate: false,
  employerOffersConfigsUpdateError: false,
  employerRenewalLoading: false,
  renewalPeriodConfigLoading: false
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FIND_RENEWAL_CONFIG_STATUS:
      return {
        ...state,
        configStatus: action.payload
      };
    case ActionTypes.FIND_OFFRES_CONFIG_STATUS:
      return {
        ...state,
        offersConfigStatus: action.payload
      };
    case ActionTypes.CREATE_RENEWAL_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        // Re-assiging the state value to trigger state change at component level
        // since it's always 204 from the backend
        employerConfigCreate: Object.assign(action.payload)
      };
    case ActionTypes.CREATE_RENEWAL_EMPLOYER_CONFIGURATION_MODULES_ERROR:
      return {
        ...state,
        employerConfigCreateError: action.payload
      };
    case ActionTypes.UPDATE_RENEWAL_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        // Re-assiging the state value to trigger state change at component level
        // since it's always 204 from the backend
        employerConfigUpdate: Object.assign(action.payload)
      };
    case ActionTypes.UPDATE_RENEWAL_EMPLOYER_CONFIGURATION_MODULES_ERROR:
      return {
        ...state,
        employerConfigUpdateError: action.payload
      };
    case ActionTypes.UPDATE_EMPLOYER_OFFERS_CONFIGS_IN_PROGRESS:
      return {
        ...state,
        renewalPeriodConfigLoading: true
      };
    case ActionTypes.UPDATE_EMPLOYER_OFFERS_CONFIGS:
      return {
        ...state,
        renewalPeriodConfigLoading: false,
        // Re-assiging the state value to trigger state change at component level
        // since it's always 204 from the backend
        employerOffersConfigsUpdate: Object.assign(action.payload)
      };
    case ActionTypes.UPDATE_EMPLOYER_OFFERS_CONFIGS_ERROR:
      return {
        ...state,
        renewalPeriodConfigLoading: false,
        employerOffersConfigsUpdateError: action.payload
      };
    case ActionTypes.GET_RENEWAL_EMPLOYER_IN_PROGRESS:
      return {
        ...state,
        employerRenewalLoading: true
      };
    case ActionTypes.GET_RENEWAL_EMPLOYER_SUCCESS:
      return {
        ...state,
        employerRenewalLoading: false,
        employerRenewal: action.payload
      };
    case ActionTypes.GET_RENEWAL_EMPLOYER_ERROR:
      return {
        ...state,
        employerRenewalLoading: false,
        employerErrorResponse: action.payload
      };
    default:
      return {
        ...state
      };
  }
};
