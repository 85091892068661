/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import _ from 'lodash';

import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import ContentContainer from 'containers/ContentContainer/ContentContainer';

import { Row, Col, Alert, Button } from 'components/Atoms';
import SaveButtons from 'components/SaveButtons/SaveButtons';
import ButtonFileUploadFormGroup from 'components/DataForm/ButtonFileUploadFormGroup';
import NotificationManager from 'components/Notifications';

import { uploadOfferPlan } from '../../../../actions/offerPlanAction';
import { saveOfferModel } from '../../../../actions/offerModelingAction';

import Util from 'util/apiUtil';
import CurrentPlanFileHistory from '../CurrentPlanFileHistory';
import { getCurrentPlansFileHistory } from '../../../../actions/offerModelingAction';
import { OFFER_BUNDLE_PATH } from '../../../../routes';
import { MESSAGE_CONSTANTS } from '../../../../constants';

class OfferUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUploading: false,
      uploadFileName: null,
      isDisable: true,
      showErrors: false
    };
  }

  componentDidMount() {
    this.setState({ showErrors: false });
    NotificationManager.warning(
      'Uploading a new spreadsheet will result in clearing all existing data.'
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { match, uploadingStatus, errorResponse } = this.props;
    if (prevProps.uploadingStatus !== uploadingStatus) {
      if (uploadingStatus === 'UPLOAD_ERROR') {
        this.setState({ isDisable: true });
      } else if (uploadingStatus === 'UPLOAD_COMPLETE') {
        NotificationManager.success('File processed successfully');
        this.setState({
          isDisable:
            prevProps.uploadedOfferPlan &&
            prevProps.uploadedOfferPlan.traceLog &&
            prevProps.uploadedOfferPlan.traceLog.length > 0 &&
            prevProps.uploadedOfferPlan.traceLog.filter(
              (trace) => trace.type === 'ERROR'
            ).length > 0
        });
        this.props.getCurrentPlansFileHistory(match.params.employerId);
      }
    }
    if (
      errorResponse !== prevProps.errorResponse &&
      !_.isEmpty(errorResponse)
    ) {
      if (errorResponse.code && MESSAGE_CONSTANTS[errorResponse.code]) {
        NotificationManager.error(MESSAGE_CONSTANTS[errorResponse.code]);
      } else {
        NotificationManager.error('Failed to upload offers file.');
      }
    }
  }

  selectFile = async (event) => {
    this.setState({ isUploading: true, showErrors: true });
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.setState({ uploadFileName: selectedFile.name });
      const fileBlob = new Blob([selectedFile], { type: 'xlsx' });

      const { match } = this.props;
      const { params } = match;
      const { employerId } = params;
      this.props.uploadProposalPlan(employerId, selectedFile.name, fileBlob);
    }
  };

  renderWarningList() {
    const mappingIssueList = this.props.uploadedOfferPlan.traceLog.filter(
      (trace) => trace.type === 'MAPPING'
    );
    const issueSheets = [
      ...new Set(
        mappingIssueList.map(
          (mappingIssue) => mappingIssue.cellLocation.sheetName
        )
      )
    ];
    return (
      <ul>
        {issueSheets.map((sheetName) => (
          <li>{sheetName}</li>
        ))}
      </ul>
    );
  }

  renderCriticalErrors() {
    const { errorResponse } = this.props;
    if (errorResponse && errorResponse.traceLog) {
      return (
        <ul>
          {errorResponse.traceLog
            .filter((trace) => trace.type === 'ERROR')
            .map((trace) => (
              <li>{trace.message}</li>
            ))}
        </ul>
      );
    }
    if (errorResponse.code !== undefined) {
      return (
        <ul>
          <li>
            {`Internal Server Error -
            ${
              MESSAGE_CONSTANTS[errorResponse.code]
                ? MESSAGE_CONSTANTS[errorResponse.code]
                : this.props.errorResponse.code
            }`}
          </li>
        </ul>
      );
    } else {
      return (
        <ul>
          <li>Internal Server Error</li>
        </ul>
      );
    }
  }

  continue = () => {
    const { match } = this.props;
    const { params } = match;

    this.props.history.push(
      generatePath(OFFER_BUNDLE_PATH, {
        ...params
      })
    );
  };

  render() {
    const { uploadFileName } = this.state;
    const { match } = this.props;
    const { params } = match;
    const { employerId } = params;

    return (
      <Row>
        <Col md={10}>
          <ApplicationWrapper>
            <HeaderContainer title="Step 1: Upload Data File" />
            <ContentContainer>
              <Row className="mt-4">
                <Col md="12">
                  <ButtonFileUploadFormGroup
                    id="proposal-uploader"
                    labelDisplay="Data File (.xlsx format)"
                    inputName={'proposal-uploader'}
                    fileLabel={uploadFileName}
                    onClickChoose={(event) => this.selectFile(event)}
                    acceptedFileTypes=".xlsx"
                    showLoading={this.props.uploadingStatus === 'UPLOADING'}
                    showProgress={true}
                    showRemove={false}
                    progress={uploadFileName ? this.props.progress : 0}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <a
                    href={`${Util.baseApi}/${Util.version}/offer-proposals/document/template`}
                  >
                    Download Raw Template
                  </a>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mt-4">
                  {((this.state.showErrors &&
                    this.props.uploadingStatus === 'UPLOAD_ERROR') ||
                    (this.props.uploadedOfferPlan &&
                      this.props.uploadedOfferPlan.traceLog &&
                      this.props.uploadedOfferPlan.traceLog.length > 0 &&
                      this.props.uploadedOfferPlan.traceLog.filter(
                        (trace) => trace.type === 'ERROR'
                      ).length > 0)) && (
                    <Alert color="danger">
                      Critical error(s) occurred which prevented processing the
                      file.
                      <ul>{this.renderCriticalErrors()}</ul>
                    </Alert>
                  )}
                  {this.state.showErrors &&
                    this.props.uploadedOfferPlan &&
                    this.props.uploadedOfferPlan.traceLog &&
                    this.props.uploadedOfferPlan.traceLog.length > 0 &&
                    this.props.uploadedOfferPlan.traceLog.filter(
                      (trace) => trace.type === 'MAPPING'
                    ).length > 0 && (
                      <Alert color="warning">
                        The file processed successfully. However, there{' '}
                        {this.props.uploadedOfferPlan.traceLog.length === 1
                          ? ' was '
                          : ' were '}{' '}
                        <strong>
                          {this.props.uploadedOfferPlan.traceLog.length}
                        </strong>{' '}
                        mapping{' '}
                        {this.props.uploadedOfferPlan.traceLog.length === 1
                          ? 'issue'
                          : 'issues'}{' '}
                        identified.
                        <br /> You can view them by downloading the file with
                        comments.
                        <br />
                        <a
                          href={`${Util.baseApi}/${Util.version}/offer-proposals/document/${this.props.uploadedOfferPlan.documentReference}?spreadSheetType=COMMENTS`}
                          download="offer-spreadsheet-with-comments.xlsx"
                        >
                          <Button className="btn-success mt-2">
                            Download File
                          </Button>
                        </a>
                        <div className="mapping-issue-list mt-2">
                          The issues were identified in the following sheets.
                          {this.renderWarningList()}
                        </div>
                      </Alert>
                    )}
                </Col>
              </Row>
            </ContentContainer>
            <HeaderContainer title="File History" />
            <ContentContainer>
              <CurrentPlanFileHistory employerId={employerId} />
            </ContentContainer>
          </ApplicationWrapper>
        </Col>
        <Col md={2}>
          <SaveButtons
            disabledSaveAndContinue={
              this.props.uploadingStatus === 'UPLOADING' || this.state.isDisable
            }
            saveAndContinueButtonText={'Continue'}
            disabledSave={false}
            hideSaveAndContinue={false}
            hideSave={true}
            onClickSaveAndContinue={() => this.continue()}
            showWarning={false}
          />
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  uploadProposalPlan: (employerId, fileName, fileBlob) =>
    dispatch(uploadOfferPlan(employerId, fileName, fileBlob)),
  getCurrentPlansFileHistory: (employerId) =>
    dispatch(getCurrentPlansFileHistory(employerId)),
  saveProposalModel: (bundleId, bundle, isComplete, type) =>
    dispatch(saveOfferModel(bundleId, bundle, isComplete, type))
});

const mapStateToProps = (state) => {
  const {
    uploadedOfferPlan,
    uploadingStatus,
    progress,
    errorResponse
  } = state.offersReducer.OfferPlanReducer;
  return {
    uploadingStatus,
    uploadedOfferPlan,
    errorResponse,
    progress
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferUpload);
