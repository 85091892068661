/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icons';
import './ContentContainer.scss';

/**
 * Content Container for wrapping common content.
 *
 * @param props
 * @returns {*}
 */
const ContentContainer = (props) => {
  const { header, icon, pageActions, children, className } = props;

  return (
    <>
      {(header || icon || pageActions) && (
        <div className="content-container-header">
          <h2 className="sub-header">
            {icon && <Icon icon={icon} />}
            {header || ''}
          </h2>
          {pageActions || <></>}
        </div>
      )}
      <div className={`panel--white ${className || ''}`}>
        <div className="panel--white-body">{children}</div>
      </div>
    </>
  );
};

ContentContainer.propTypes = {
  header: PropTypes.string,
  icon: PropTypes.string,
  actions: PropTypes.string
};

export default ContentContainer;
