import React from 'react';
import PropTypes from 'prop-types';

import { SyncStatusIcon } from 'components/Icons';
import { EMDASH_LITERAL } from 'constants/commonConstants';
import { SyncStatus } from 'constants/syncConstants';

import './SyncStatusBadge.scss';

const SyncStatusBadge = (props) => {
  const { syncStatus } = props;
  const syncStatusConfigs = SyncStatus[syncStatus];

  if (!syncStatusConfigs) {
    return EMDASH_LITERAL;
  }

  const { color, label } = syncStatusConfigs;
  return (
    <span className={`sync-status-badge ${color}`}>
      <SyncStatusIcon syncStatus={syncStatus} />
      {label}
    </span>
  );
};

SyncStatusBadge.propTypes = {
  // If not provided or invalid, then dash is rendered.
  syncStatus: PropTypes.oneOf(Object.keys(SyncStatus)).isRequired
};

export default SyncStatusBadge;
