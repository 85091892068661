/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import axios from 'axios';
import Util from 'util/apiUtil';

const baseURL = `${Util.baseApi}/${Util.version}/adapters/integrations`;

const cancelInfiniteSyncRequest = (integrationToken) => {
  const { currentToken } = integrationToken;
  if (typeof currentToken === 'function') {
    currentToken();
  }
};

var CancelToken = axios.CancelToken;
const integrationToken = {};

export default {
  /**
   * get all surveys by given query object
   * @param page
   * @param size
   * @param sort
   * @param query
   * @returns {AxiosPromise<any>}
   */
  getEmployerSyncDetails({ employerId, page, size, sort, query, filters }) {
    cancelInfiniteSyncRequest(integrationToken);
    return axios.get(
      `${baseURL}/hris/employer/${employerId}/syncDetails?${Util.getPagingQueryString(
        page,
        size,
        sort,
        query
      )}&${Util.serializeQuery(filters)}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          integrationToken.currentToken = c;
        })
      }
    );
  },

  getAllSyncDetails({
    page,
    size,
    sort,
    query,
    type,
    filters,
    organizationId
  }) {
    return axios.get(
      `${baseURL}/hris/employers/syncDetails?${Util.getPagingQueryString(
        page,
        size,
        sort,
        query,
        type
      )}&${Util.serializeQuery(filters)}&organizationId=${organizationId}`
    );
  },

  getAllBenAdminSyncDetails({ page, size, sort, query, type, filters, organizationId }) {
    return axios.get(
      `${baseURL}/ben-admin/FLOCK/employers/sync-details?${Util.getPagingQueryString(
        page,
        size,
        sort,
        query,
        type
      )}&${Util.serializeQuery(filters)}&organizationId=${organizationId}`
    );
  },

  getEmployerIntegrationDetails(employerId, type) {
    return axios.get(
      `${baseURL}/employer/${employerId}${type ? `?type=${type}` : ''}`
    );
  },

  getBambooHRHRISFields() {
    return axios.get(`${baseURL}/hris/bambooHr/hrisFields`);
  },

  getBHRSetupDetails(config) {
    const params = {};
    return axios.post(`${baseURL}/hris/bambooHr/setupDetails`, config, {
      params: params
    });
  },

  getLFISetupDetails(employerId) {
    return axios.get(
      `${baseURL}/hris/lumityFileImport/${employerId}/setupDetails`
    );
  },

  saveBambooHrisConfig(employerId, config, saveType) {
    const params = {};
    return axios.post(
      `${baseURL}/hris/employer/${employerId}/setup/bambooHr?type=${saveType}`,
      config,
      {
        params: params
      }
    );
  },

  saveLFIHrisConfig(employerId, config) {
    return axios.post(
      `${baseURL}/hris/employer/${employerId}/setup/lumityFileImport`,
      config
    );
  },

  updateBambooHrisConfig(employerId, config) {
    const params = {};
    return axios.put(
      `${baseURL}/hris/employer/${employerId}/setup/bambooHr`,
      config,
      {
        params: params
      }
    );
  },

  deleteBambooHrisConfig(employerId, config) {
    return axios.delete(
      `${baseURL}/hris/employer/${employerId}/setup/bambooHr`
    );
  },

  startEmployerSync(employerId, config) {
    const { source } = config;
    return axios.post(
      `${baseURL}/hris/employer/${employerId}/sync?source=${source}&target=FLOCK`
    );
  },
  getLastSyncStatus(employerId) {
    return axios.get(
      `${baseURL}/hris/employers/${employerId}/schedules/latest`
    );
  },

  toggleDailySync(employerId, bEnabled) {
    return axios.post(
      `${baseURL}/hris/employers/${employerId}/schedules/configuration?enabled=${bEnabled}`
    );
  },

  startAllSync(config, organizationId) {
    const params = { organizationId: organizationId };
    return axios.post(`${baseURL}/hris/employers/sync`, config, {
      params: params
    });
  },

  startAllBenAdminSync(config, organizationId) {
    const params = { organizationId: organizationId };
    return axios.post(`${baseURL}/ben-admin/FLOCK/employers/sync`, config, {
      params: params
    });
  },

  getErrorList({
    syncDetailsId,
    employerId,
    page,
    size,
    sort,
    query,
    filters,
    excludeTerminatedEmployees
  }) {
    return axios.get(
      `${baseURL}/hris/employer/${employerId}/syncDetails/${syncDetailsId}/errors?${Util.getPagingQueryString(
        page,
        size,
        sort,
        query
      )}&${Util.serializeQuery(
        filters
      )}&excludeTerminated=${excludeTerminatedEmployees}`
    );
  },

  downloadErrorReport(employerId, syncDetailsId, excludeTerminatedEmployees) {
    return window.open(
      `${baseURL}/hris/employer/${employerId}/syncDetails/${syncDetailsId}/errors/export?excludeTerminated=${excludeTerminatedEmployees}`
    );
  },

  getSyncErrorFilers(employerId, syncDetailsId, excludeTerminatedEmployees) {
    return axios.get(
      `${baseURL}/hris/employer/${employerId}/syncDetails/${syncDetailsId}/errors/filters?excludeTerminated=${excludeTerminatedEmployees}`
    );
  },

  getWorkdayHRISFields() {
    return axios.get(`${baseURL}/hris/workday/fields`);
  },

  validateWorkdayConfigs(employerId, config) {
    return axios.post(
      `${baseURL}/hris/workday/configurations/validate`,
      config
    );
  },

  saveWorkdayConfig(employerId, config) {
    const params = {};
    return axios.post(
      `${baseURL}/hris/workday/employer/${employerId}/configurations`,
      config,
      {
        params: params
      }
    );
  },

  updateWorkdayConfig(employerId, config) {
    const params = {};
    return axios.put(
      `${baseURL}/hris/workday/employer/${employerId}/configurations`,
      config,
      {
        params: params
      }
    );
  },

  getIntegrationConfigDetails(employerId, type) {
    return axios.get(
      `${baseURL}/hris/workday/employer/${employerId}${
        type ? `?type=${type}` : ''
      }`
    );
  },

  getBambooHRConfigDetails(employerId, type) {
    return axios.get(`${baseURL}/hris/bamboo-hr/employer/${employerId}`);
  },

  async downloadFile(employerId, syncDetailsId) {
    const downloadURL = `${baseURL}/hris/employer/${employerId}/syncDetails/${syncDetailsId}/genericFileImport/download`;
    try {
      await axios.get(downloadURL);
      return window.open(downloadURL);
    } catch (e) {
      throw e;
    }
  },

  getHrisIntegrationFixedErrorsByEmployerId(employerId) {
    return axios.get(
      `${baseURL}/benAdmin/FLOCK/employer/${employerId}/flock-ids-sync-job-status`
    );
  },

  fixInvalidMappings(employerId) {
    return axios.post(
      `${baseURL}/benAdmin/FLOCK/employer/${employerId}/sync-flock-ids`
    );
  },

  async downloadConfigFile(employerId, syncDetailsId) {
    const downloadURL = `${baseURL}/hris/employer/${employerId}/sync-details/${syncDetailsId}/config/export`;
    try {
      await axios.get(downloadURL);
      return window.open(downloadURL);
    } catch (e) {
      throw e;
    }
  },

  saveBenAdminConfig(benAdminType, employerId, config) {
    const params = {};
    return axios.post(
      `${baseURL}/ben-admin/${benAdminType}/employer/${employerId}/configure`,
      config,
      {
        params: params
      }
    );
  },

  getBenAdminConfigDetails(benAdminType, employerId) {
    return axios.get(`${baseURL}/ben-admin/${benAdminType}/employer/${employerId}`);
  },

  startEmployerBenAdminSync(employerId, config) {
    const { source } = config;
    return axios.post(
      `${baseURL}/ben-admin/FLOCK/employer/${employerId}/sync?source=${source}&target=PLATFORM`
    );
  },

  getBenAdminEmployerSyncDetails({
    employerId,
    benAdminType,
    page,
    size,
    sort,
    query,
    filters
  }) {
    cancelInfiniteSyncRequest(integrationToken);
    return axios.get(
      `${baseURL}/ben-admin/${benAdminType}/employer/${employerId}/sync-details?${Util.getPagingQueryString(
        page,
        size,
        sort,
        query
      )}&${Util.serializeQuery(filters)}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          integrationToken.currentToken = c;
        })
      }
    );
  },

  updateBenAdminDailySync(employerId, config) {
    const { benAdminEnabled, cronExpression } = config;
    let dailySchedule = {
      cronExpression
    };
    return axios.post(
      `${baseURL}/ben-admin/employer/${employerId}/schedule/configuration?enabled=${benAdminEnabled}`,
      dailySchedule
    );
  },

  getBenAdminDailySchedule(employerId) {
    return axios.get(`${baseURL}/ben-admin/employer/${employerId}/schedule`);
  },

  getBenAdminSyncErrorList({
    syncDetailsId,
    employerId,
    page,
    size,
    sort,
    query,
    filters,
    syncType
  }) {
    return axios.get(
      `${baseURL}/ben-admin/employer/${employerId}/sync-details/${syncDetailsId}/errors?${Util.getPagingQueryString(
        page,
        size,
        sort,
        query
      )}&${Util.serializeQuery(filters)}&syncType=${syncType}`
    );
  },

  getBenAdminSyncErrorFilters({ employerId, syncDetailsId, syncType }) {
    return axios.get(
      `${baseURL}/ben-admin/employer/${employerId}/sync-details/${syncDetailsId}/errors/filters?syncType=${syncType}`
    );
  },

  getLastBenAdminSyncStatus(employerId) {
    return axios.get(
      `${baseURL}/ben-admin/employers/${employerId}/schedules/latest`
    );
  },

  async downloadBenAdminSyncErrorReport(employerId, syncDetailsId, syncType) {
    const downloadURL = `${baseURL}/ben-admin/employer/${employerId}/sync-details/${syncDetailsId}/errors/export?integrationSyncType=${syncType}`;
    try {
      await axios.get(downloadURL);
      return window.open(downloadURL);
    } catch (e) {
      throw e;
    }
  }
};
