/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import { Table } from 'components/Atoms';
import _ from 'lodash';

import './OfferPlanDetails.scss';
import { BENEFIT_KIND_MEDICAL, BENEFIT_KIND_LIFE } from '../../../../constants';
import { formatCurrency } from 'util/commonUtil';

const IN_NETWORK = 'In Network';
const OUT_OF_NETWORK = 'Out of Network';
const DEDUCTIBLE = 'Deductible';

const SINGLE = 'Single';
const FAMILY = 'Family';
const EMBEDDED = 'Embedded';

const RX_TIER_1 = 'Rx Tier 1';
const RX_TIER_2 = 'Rx Tier 2';
const RX_TIER_3 = 'Rx Tier 3';
const RX_TIER_4 = 'Rx Tier 4';
const TIER = 'Tier';
/**
 * Component for generate Proposal Plan Details PopUp
 * @param props
 * @returns {*}
 */
class OfferPlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      networkType: IN_NETWORK
    };
  }
  setNetworkType = () => {
    const { networkType } = this.state;
    if (networkType === IN_NETWORK) {
      this.setState({
        networkType: OUT_OF_NETWORK
      });
    } else {
      this.setState({
        networkType: IN_NETWORK
      });
    }
  };
  render() {
    const { name, planContribution, services } = this.props.data;
    const type = this.props.type;

    let content = (
      <>
        <Table className="plan-table">
          <thead>
            <tr>
              <th className="remove-top-border"></th>
              <th
                className="add-left-border plan-detail-header-border"
                colSpan="2"
              >
                {name}
              </th>
            </tr>
          </thead>

          {buildPremium(planContribution)}
          {type.toUpperCase() !== BENEFIT_KIND_LIFE && (
            <>
              {buildServices(
                services,
                this.state.networkType,
                this.setNetworkType,
                this.props.data,
                type
              )}
            </>
          )}
        </Table>
      </>
    );
    return content;
  }
}

/**
 * Build Premium data.
 *
 * @param planContribution
 * @returns {*}
 */
const buildPremium = (planContribution) => {
  if (
    !planContribution ||
    !planContribution.tierContributionsDefault ||
    !planContribution.tierContributionsDefault.EE ||
    !planContribution.tierContributionsDefault.ES ||
    !planContribution.tierContributionsDefault.EC ||
    !planContribution.tierContributionsDefault.EF
  ) {
    return <></>;
  }

  const data = planContribution.tierContributionsDefault;

  return (
    <>
      <thead className="title-background">
        <tr>
          <th className="table-header-col-width align-center">Monthly Rates</th>
          <th className="table-col-width"></th>
        </tr>
      </thead>
      <tbody className="plan-detail-border">
        <tr>
          <th scope="row">EE</th>
          <td className="add-left-border">{formatCurrency(data.EE.premium)}</td>
        </tr>
        <tr className="plan-detail-border">
          <th scope="row">EE + Spouse</th>
          <td className="add-left-border">{formatCurrency(data.ES.premium)}</td>
        </tr>
        <tr className="plan-detail-border">
          <th scope="row">EE + Child</th>
          <td className="add-left-border">{formatCurrency(data.EC.premium)}</td>
        </tr>
        <tr className="plan-detail-border">
          <th scope="row">EE + Family</th>
          <td className="add-left-border">{formatCurrency(data.EF.premium)}</td>
        </tr>
      </tbody>
    </>
  );
};

/**
 * Build Deductible data
 *
 * @param deductibles
 * @returns {*}
 */
const buildDeductible = (deductibles, networkType) => {
  if (!deductibles) {
    return <></>;
  }
  const inNetworkSingleDeductible = find(
    deductibles,
    DEDUCTIBLE,
    IN_NETWORK,
    SINGLE
  );
  const inNetworkFamilyDeductible = find(
    deductibles,
    DEDUCTIBLE,
    IN_NETWORK,
    FAMILY
  );
  const inNetworkEmbededDeductible = find(
    deductibles,
    DEDUCTIBLE,
    IN_NETWORK,
    EMBEDDED
  );
  const outOfNetworkSingleDeductible = find(
    deductibles,
    DEDUCTIBLE,
    OUT_OF_NETWORK,
    SINGLE
  );
  const outOfNetworkFamilyDeductible = find(
    deductibles,
    DEDUCTIBLE,
    OUT_OF_NETWORK,
    FAMILY
  );
  const outOfNetworkEmbededDeductible = find(
    deductibles,
    DEDUCTIBLE,
    OUT_OF_NETWORK,
    EMBEDDED
  );

  return (
    <>
      <tr>
        <th scope="row">
          Deductible({SINGLE} / {FAMILY} / {EMBEDDED})
        </th>
        {networkType === IN_NETWORK ? (
          <td className="add-left-border">
            {defaultValue(inNetworkSingleDeductible.value)} /{' '}
            {defaultValue(inNetworkFamilyDeductible.value)} /{' '}
            {defaultValue(inNetworkEmbededDeductible.value)}
          </td>
        ) : (
          <td className="add-left-border">
            {defaultValue(outOfNetworkSingleDeductible.value)} /{' '}
            {defaultValue(outOfNetworkFamilyDeductible.value)} /{' '}
            {defaultValue(outOfNetworkEmbededDeductible.value)}
          </td>
        )}
      </tr>
    </>
  );
};

/**
 * Build Rx Tiers
 *
 * @param rxTiers
 * @returns {*}
 */
const buildRxTiers = (rxTiers, networkType) => {
  let inNetworkRxTier1 = null;
  let inNetworkRxTier2 = null;
  let inNetworkRxTier3 = null;
  let inNetworkRxTier4 = null;

  if (networkType === IN_NETWORK) {
    inNetworkRxTier1 = find(rxTiers, RX_TIER_1, IN_NETWORK, TIER);
    inNetworkRxTier2 = find(rxTiers, RX_TIER_2, IN_NETWORK, TIER);
    inNetworkRxTier3 = find(rxTiers, RX_TIER_3, IN_NETWORK, TIER);
    inNetworkRxTier4 = find(rxTiers, RX_TIER_4, IN_NETWORK, TIER);
  } else {
    inNetworkRxTier1 = find(rxTiers, RX_TIER_1, OUT_OF_NETWORK, TIER);
    inNetworkRxTier2 = find(rxTiers, RX_TIER_2, OUT_OF_NETWORK, TIER);
    inNetworkRxTier3 = find(rxTiers, RX_TIER_3, OUT_OF_NETWORK, TIER);
    inNetworkRxTier4 = find(rxTiers, RX_TIER_4, OUT_OF_NETWORK, TIER);
  }

  return (
    <>
      <tr>
        <th scope="row">RX cost(Tier 1 / Tier 2 / Tier 3 / Tier 4)</th>
        {
          <td className="add-left-border">
            {defaultValue(inNetworkRxTier1.value)} /{' '}
            {defaultValue(inNetworkRxTier2.value)} /{' '}
            {defaultValue(inNetworkRxTier3.value)} /{' '}
            {defaultValue(inNetworkRxTier4.value)}
          </td>
        }
      </tr>
    </>
  );
};

/**
 * Build OOP Max
 *
 * @param oopMax
 * @returns {*}
 */
const buildOopMax = (oopMax, networkType) => {
  let oopSingle = null;
  let oopFamily = null;
  let oopEmbedded = null;

  if (networkType === IN_NETWORK) {
    oopSingle = find(oopMax, undefined, IN_NETWORK, SINGLE);
    oopFamily = find(oopMax, undefined, IN_NETWORK, FAMILY);
    oopEmbedded = find(oopMax, undefined, IN_NETWORK, EMBEDDED);
  } else {
    oopSingle = find(oopMax, undefined, OUT_OF_NETWORK, SINGLE);
    oopFamily = find(oopMax, undefined, OUT_OF_NETWORK, FAMILY);
    oopEmbedded = find(oopMax, undefined, OUT_OF_NETWORK, EMBEDDED);
  }

  return (
    <>
      <tr>
        <th scope="row">Out Of Pocket Max</th>
        {
          <td className="add-left-border" colSpan="2">
            {defaultValue(oopSingle.value)} / {defaultValue(oopFamily.value)} /{' '}
            {defaultValue(oopEmbedded.value)}
          </td>
        }
      </tr>
    </>
  );
};

/**
 * Build Services
 *
 * @param services
 * @returns {*}
 */
const buildServices = (services, networkType, setNetworkType, data, type) => {
  if (!services) {
    return <></>;
  }

  let processedServices = [];

  _.forEach(services, function(service, index) {
    const foundIndex = _.findIndex(processedServices, { name: service.name });
    const found = processedServices[foundIndex];

    if (found) {
      if (service.fieldType === IN_NETWORK) {
        found.inNetworkValue = service.value;
      }
      if (service.fieldType === OUT_OF_NETWORK) {
        found.outOfNetworkValue = service.value;
      }
      processedServices.splice(foundIndex, 1, found);
    } else {
      const newService = {
        name: service.name,
        inNetworkValue: service.fieldType === IN_NETWORK ? service.value : null,
        outOfNetworkValue:
          service.fieldType === OUT_OF_NETWORK ? service.value : null
      };
      processedServices.push(newService);
    }
  });

  return (
    <>
      <thead className="title-background">
        <tr>
          <th className="custom-width-thead">Services</th>
          <th className="custom-width-thead select-th-height">
            <select
              className="header-select custom-select"
              name="networkType"
              onChange={(event) => {
                setNetworkType();
              }}
            >
              <option value="IN_NETWORK">In Network</option>
              <option value="OUT_NETWORK">Out Network</option>
            </select>
          </th>
        </tr>
      </thead>
      <tbody>
        {type.toUpperCase() === BENEFIT_KIND_MEDICAL && (
          <>
            {buildDeductible(data.deductibles, networkType)}
            {buildRxTiers(data.rxTiers, networkType)}
            {buildOopMax(data.oops, networkType)}
          </>
        )}
        {processedServices.map((service) => {
          return (
            <tr key={service.name}>
              <th scope="row">{service.name}</th>
              {networkType === IN_NETWORK ? (
                <td className="add-left-border">
                  {defaultValue(service.inNetworkValue)}
                </td>
              ) : (
                <td className="add-left-border">
                  {defaultValue(service.outOfNetworkValue)}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

/**
 * Check value.If null, returns '-'
 * @param value
 * @returns {*}
 */
const defaultValue = (value) => {
  return value ? value : <>&mdash;</>;
};

/**
 * Filter by name, fieldType and subFieldType.
 * @param data - data array
 * @param name - Name
 * @param fieldType - Field Type
 * @param fieldSubType - Field Sub Type
 */
const find = (data, name, fieldType, fieldSubType) => {
  const payload =
    name !== undefined
      ? { name, fieldType, fieldSubType }
      : { fieldType, fieldSubType };
  const value = _.find(data, payload);
  if (value === undefined) {
    return {};
  }
  return value;
};

export default OfferPlanDetails;
