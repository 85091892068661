/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import LayoutWrapper from 'components/Layout';
import routes, { PrivateRoute } from 'routes';
import configureStore from './store';

import 'styles/App.scss';

class Main extends Component {
  render() {
    return (
      <Provider store={configureStore()}>
        <BrowserRouter>
          <LayoutWrapper>
            <Switch>
              {routes.map((pageRoute, index) => (
                <PrivateRoute
                  key={index}
                  path={pageRoute.path}
                  component={pageRoute.component}
                  exact
                />
              ))}
            </Switch>
          </LayoutWrapper>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default Main;
