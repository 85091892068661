/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import axios from 'axios';
import Util from '../util/apiUtil';

//configs
const baseURL = `${Util.baseApi}/${Util.version}/plans`;

export default {
  getSelfFundedClaimsPlanConfigurations(employerId, startDate, benefitKind) {
    return axios.get(
      `${baseURL}/employers/${employerId}/self-funded-claims?startDate=${startDate}&benefitKind=${benefitKind}`
    );
  },

  getAllNonArchivedPlans(employerId, planYearIds) {
    return axios.get(
      `${baseURL}/employers/${employerId}/non-archived-all-plans/?planYearIds=${planYearIds}`
    );
  }
  
};
