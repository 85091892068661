/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';

export default class CustomDataFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectionList: new Set(),
      uniqueFields: new Set()
    };

    this.valueGetter = this.props.valueGetter;

    this.onChange = this.onChange.bind(this);
  }

  isFilterActive() {
    return this.state.selectionList && this.state.selectionList.size > 0;
  }

  doesFilterPass(params) {
    return !this.state.selectionList ||
      (this.state.selectionList && this.state.selectionList.size === 0)
      ? true
      : this.state.selectionList.has(this.valueGetter(params.node).toString());
  }

  getModel() {
    return this.state.selectionList;
  }

  setModel(model) {
    this.setState({ ...this.state, selectionList: model });
  }

  afterGuiAttached(params) {
    this.hidePopup = params.hidePopup;
    const uniqueFields = new Set();
    this.props.rowModel.rowsToDisplay.forEach((rowNode) => {
      uniqueFields.add(this.props.valueGetter(rowNode));
    });
    this.setState({ ...this.state, uniqueFields });
  }

  onChange(event) {
    if (event.target.checked) {
      this.state.selectionList.add(event.target.value);
    } else {
      this.state.selectionList.delete(event.target.value);
    }

    this.setState({ ...this.state });
  }

  resetFilters() {
    this.setState({ ...this.state, selectionList: new Set() }, () =>
      this.props.filterChangedCallback()
    );
    this.hidePopup();
  }

  render() {
    return (
      <div className="filter-container">
        <div className="filter-list">
          {Array.from(this.state.uniqueFields)
            .sort()
            .map((rowNode, indx) => {
              return (
                <>
                  <input
                    className="filter-item"
                    type="checkbox"
                    checked={
                      this.state.selectionList &&
                      this.state.selectionList.has(rowNode)
                    }
                    onChange={this.onChange}
                    key={`filter_key_${indx}`}
                    value={rowNode}
                  />
                  <span>{rowNode}</span>
                  <br />
                </>
              );
            })}
        </div>
        <hr />
        <div className="filter-btn-container">
          <div
            className="btn-filter"
            onClick={() => {
              this.props.filterChangedCallback();
              this.hidePopup();
            }}
          >
            {' '}
            OK{' '}
          </div>{' '}
          <div
            className="btn-filter btn-reset"
            onClick={() => this.resetFilters()}
          >
            {' '}
            Reset{' '}
          </div>
        </div>
      </div>
    );
  }
}
