/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/roles.actionTypes';

const initialState = {
  adminRolesList: {
    data: null,
    isFetching: false,
    error: null
  },
  usersPageForRole: {
    data: {
      content: [],
      metadata: {}
    },
    isFetching: false,
    error: null
  },
  adminUserRoles: {
    roles: [],
    individualId: '',
    isFetching: false,
    isUpdating: false,
    error: null
  }
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ADMIN_ROLES_LIST_IN_PROGRESS:
      return {
        ...state,
        adminRolesList: {
          data: null,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_ADMIN_ROLES_LIST_COMPLETED:
      return {
        ...state,
        adminRolesList: {
          ...state.adminRolesList,
          isFetching: false,
          data: action.payload
        }
      };
    case ActionTypes.GET_ADMIN_ROLES_LIST_FAILED:
      return {
        ...state,
        adminRolesList: {
          ...state.adminRolesList,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_USERS_PAGE_FOR_ROLE_IN_PROGRESS:
      return {
        ...state,
        usersPageForRole: {
          ...state.usersPageForRole,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_USERS_PAGE_FOR_ROLE_COMPLETED:
      return {
        ...state,
        usersPageForRole: {
          ...state.usersPageForRole,
          isFetching: false,
          data: action.payload
        }
      };
    case ActionTypes.GET_USERS_PAGE_FOR_ROLE_FAILED:
      return {
        ...state,
        usersPageForRole: {
          ...state.usersPageForRole,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_ADMIN_USER_ROLES_IN_PROGRESS:
      return {
        ...state,
        adminUserRoles: {
          ...state.adminUserRoles,
          roles: [],
          individualId: '',
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_ADMIN_USER_ROLES_COMPLETED:
      return {
        ...state,
        adminUserRoles: {
          ...state.adminUserRoles,
          roles: action.roles,
          individualId: action.individualId,
          isFetching: false
        }
      };
    case ActionTypes.GET_ADMIN_USER_ROLES_FAILED:
      return {
        ...state,
        adminUserRoles: {
          ...state.adminUserRoles,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.UPDATE_ADMIN_USER_ROLES_IN_PROGRESS:
      return {
        ...state,
        adminUserRoles: {
          ...state.adminUserRoles,
          isUpdating: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_ADMIN_USER_ROLES_COMPLETED:
      return {
        ...state,
        adminUserRoles: {
          ...state.adminUserRoles,
          roles: action.roles,
          individualId: action.individualId,
          isUpdating: false
        }
      };
    case ActionTypes.UPDATE_ADMIN_USER_ROLES_FAILED:
      return {
        ...state,
        adminUserRoles: {
          ...state.adminUserRoles,
          isUpdating: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default rolesReducer;
