/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BILLING_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { permitIf } from 'components/hoc/Permit';
import ButtonFileUploadFormGroup from '../../../../../../components/DataForm/ButtonFileUploadFormGroup';
import {
  uploadBillingFile,
  saveBillingFileWithoutPublish,
  saveAndPublishBillingFile,
  getPreviewData,
  publishFromGridDataFile
} from '../../../../actions/billingAction';
import SelectFormGroup from '../../../../../../components/DataForm/SelectFormGroup';
import './FileUpload.scss';
import {
  BILLING_FILE_TYPES_DISPLAY_MAP,
  SUMMARY_AND_DETAIL_FILE_TYPE,
  DETAIL_FILE_TYPE,
  SUMMARY_FILE_TYPE,
  BILLING_FILE_PUBLISHED,
  BILLING_FILE_DRAFT,
  BILLING_FILE_VALIDATE,
  XLSX,
  BILLING_MONTH_FORMAT
} from '../../../../constants';
import { getBillingMonthList } from '../../../../util';
import BillingSummaryPreview from '../BillingSummaryPreview';
import moment from 'moment';
import Loader from '../../../../../../components/CircularProgress/Loader';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billingFileType: '',
      billingMonth: getBillingMonthList()[2], //Always 3rd item is the current month
      isPreviewEnable: false,
      isFileEnable: true,
      showFilePreview: false,
      isValidFile: true,
      detailFile: {
        uploadFileName: null,
        file: '',
        fileBlob: null
      },
      summaryFile: {
        uploadFileName: null,
        file: '',
        fileBlob: null
      },
      uploadFileName: null,
      uploadFileNameSummary: null,
      saving: false,
      showDetailFile: false,
      showSummaryFile: false,
      isEnableMonth: true,
      isEnableFileType: true,
      previewDataGrid: null,
      publishFromGrid: false,
      saveNotPublish: true,
      publishStatus: 'Publish',
      isDisablePublish: true,
      showSummaryLoader: false
    };
  }

  static propTypes = {
    employerId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    selectedFile: PropTypes.object,
    selectFileSummary: PropTypes.object,
    uploadDataFile: PropTypes.func,
    saveAndPublishBillingFile: PropTypes.func,
    uploadingStatus: PropTypes.string,
    progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  componentDidMount() {
    const { billingRow, getPreviewData } = this.props;
    if (billingRow.id) {
      this.setState({ previewData: null });
      const billingId = billingRow.id;
      getPreviewData(billingId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fileTypeListErrorResponse,
      billingSave,
      billingSaveAndPublish,
      onClose,
      previewData,
      billingRow,
      previewDataError,
      fromGridData
    } = this.props;
    if (
      this.props.billingPreview.uploadingStatus !==
      prevProps.billingPreview.uploadingStatus
    ) {
      switch (this.props.billingPreview.uploadingStatus) {
        case 'UPLOAD_ERROR':
          NotificationManager.error('Processing the data file failed');
          this.setState({
            showFilePreview: false,
            isPreviewEnable: false,
            showSummaryLoader: false,
            isFileEnable: true
          });
          break;
        case 'UPLOAD_COMPLETE':
          NotificationManager.success('File validated successfully');
          this.setState({
            isPreviewEnable: false,
            isFileEnable: true,
            previewDataGrid: this.props.billingPreview.filePreviewResult
              ? this.props.billingPreview.filePreviewResult
              : null,
            isDisablePublish: false,
            showSummaryLoader: false,
            saveNotPublish: true
          });
          break;
        default:
          break;
      }
    }
    if (
      fileTypeListErrorResponse !== prevProps.fileTypeListErrorResponse &&
      fileTypeListErrorResponse
    ) {
      NotificationManager.error('Failed to retrieve File Types');
    }
    if (billingSave.savingStatus !== prevProps.billingSave.savingStatus) {
      switch (billingSave.savingStatus) {
        case 'SAVING':
          this.setState({ saving: true });
          break;
        case 'SAVE_ERROR':
          NotificationManager.error('Failed to save the billing file');
          this.setState({ saving: false, isFileEnable: true });
          break;
        case 'SAVE_COMPLETED':
          NotificationManager.success('File saved successfully');
          this.setState({ saving: false });
          onClose();
          break;
        default:
          break;
      }
    }
    if (
      billingSaveAndPublish.savingStatus !==
      prevProps.billingSaveAndPublish.savingStatus
    ) {
      switch (billingSaveAndPublish.savingStatus) {
        case 'SAVE_ERROR':
          NotificationManager.error(
            'Failed to save and publish the billing file'
          );
          this.setState({ saving: false, isFileEnable: true });
          break;
        case 'SAVE_COMPLETED':
          NotificationManager.success('File saved and published successfully');
          this.setState({ saving: false });
          onClose();
          break;
        default:
          break;
      }
    }
    if (previewDataError !== prevProps.previewDataError && previewDataError) {
      NotificationManager.error('Failed to retrieve preview data .');
      onClose();
    } else {
      if (
        previewData !== prevProps.previewData &&
        previewData &&
        previewData[0].billingFileType
      ) {
        const billingMonth = `${billingRow.billingMonth} ${billingRow.year}`;
        billingRow.files.forEach((file) => {
          if (file.type === DETAIL_FILE_TYPE) {
            this.setState({ uploadFileName: file.fileName });
          }
          if (file.type === SUMMARY_FILE_TYPE) {
            this.setState({ uploadFileNameSummary: file.fileName });
          }
        });
        this.setState({
          isEnableFileType: false,
          isEnableMonth: false,
          billingMonth: moment(billingMonth, BILLING_MONTH_FORMAT).format(
            BILLING_MONTH_FORMAT
          ),
          billingFileType: billingRow.billingFileType,
          publishFromGrid: true,
          previewDataGrid: previewData,
          saveNotPublish: false,
          isDisablePublish: false,
          showSummaryLoader: false
        });
        if (billingRow.status === BILLING_FILE_PUBLISHED) {
          this.setState({
            publishStatus: 'Re-Publish',
            isDisablePublish: true
          });
        }
        this.fileUploadEnable(billingRow.billingFileType);
      }
    }
    if (fromGridData.savingStatus !== prevProps.fromGridData.savingStatus) {
      switch (fromGridData.savingStatus) {
        case 'SAVING':
          this.setState({ saving: true });
          break;
        case 'SAVE_ERROR':
          NotificationManager.error('Failed published the billing file');
          this.setState({ saving: false, isFileEnable: false });
          break;
        case 'SAVE_COMPLETED':
          NotificationManager.success('File published successfully');
          this.setState({ saving: false });
          onClose();
          break;
        default:
          break;
      }
    }
  }

  /*
    show or hide file upload sections based on the selected file type
  */
  onChangeFileType = (event) => {
    this.setState({
      summaryFile: {},
      detailFile: {},
      uploadFileName: null,
      uploadFileNameSummary: null,
      isPreviewEnable: false,
      previewDataGrid: null
    });

    const { value } = event.target;
    this.setState({ billingFileType: value });
    this.fileUploadEnable(value);
  };

  fileUploadEnable = (billingFileType) => {
    switch (billingFileType) {
      case DETAIL_FILE_TYPE:
        this.setState({ showDetailFile: true, showSummaryFile: false });
        break;

      case SUMMARY_FILE_TYPE:
        this.setState({ showDetailFile: false, showSummaryFile: true });
        break;

      case SUMMARY_AND_DETAIL_FILE_TYPE:
        this.setState({ showDetailFile: true, showSummaryFile: true });
        break;

      default:
        this.setState({ showDetailFile: false, showSummaryFile: false });
        break;
    }
  };
  /*
    Set billing month to the redux state
  */
  onChangeMonths = (event) => {
    const { value } = event.target;
    this.setState({ billingMonth: value });
    if (!value) {
      this.setState({ isPreviewEnable: false });
    }
  };

  onChangePreviewEnable = (uploadFile) => {
    this.setState({ previewDataGrid: null });
    const {
      billingFileType,
      summaryFile,
      detailFile,
      publishFromGrid
    } = this.state;
    if (SUMMARY_AND_DETAIL_FILE_TYPE === billingFileType) {
      if (
        uploadFile.file === 'detailFile' ? summaryFile.file : detailFile.file
      ) {
        this.setState({ isPreviewEnable: true });
      } else if (publishFromGrid) {
        if (uploadFile.file === 'detailFile') {
          this.setState({ uploadFileNameSummary: null });
        }
        if (uploadFile.file === 'summaryFile') {
          this.setState({ uploadFileName: null });
        }
      }
    } else if (uploadFile.file) {
      this.setState({ isPreviewEnable: true });
    }
  };

  /*
    Select detail file
  */
  selectFile = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileBlob = new Blob([selectedFile], { type: selectedFile.type });
      const detailFile = {
        uploadFileName: selectedFile.name,
        file: 'detailFile',
        fileBlob: fileBlob
      };
      this.setState({
        detailFile: detailFile,
        uploadFileName: detailFile.uploadFileName
      });
      this.onChangePreviewEnable(detailFile);
    }
  };

  /*
    Select summary file
  */
  selectFileSummary = (event) => {
    const selectedFileSummary = event.target.files[0];
    if (selectedFileSummary) {
      const fileBlobSummary = new Blob([selectedFileSummary], {
        type: selectedFileSummary.type
      });
      const summaryFile = {
        uploadFileName: selectedFileSummary.name,
        file: 'summaryFile',
        fileBlob: fileBlobSummary
      };
      this.setState({
        summaryFile: summaryFile,
        uploadFileNameSummary: summaryFile.uploadFileName
      });
      this.onChangePreviewEnable(summaryFile);
    }
  };

  /*
    Send File for validaion
  */
  showFilePreview = () => {
    const { employerId, uploadDataFile } = this.props;
    const apiType = BILLING_FILE_VALIDATE;
    const {
      billingMonth,
      detailFile,
      summaryFile,
      billingFileType
    } = this.state;
    uploadDataFile(
      employerId,
      detailFile,
      summaryFile,
      billingFileType,
      billingMonth,
      apiType
    );
    this.setState({
      showFilePreview: true,
      showSummaryLoader: true,
      isPreviewEnable: false,
      isFileEnable: false
    });
  };

  /*
    Publish file to the ER Portal
  */
  publish = () => {
    const {
      detailFile,
      summaryFile,
      billingFileType,
      billingMonth,
      publishFromGrid
    } = this.state;
    const {
      employerId,
      saveAndPublishBillingFile,
      billingRow,
      publishFromGridDataFile
    } = this.props;
    const apiType = BILLING_FILE_PUBLISHED;
    if (publishFromGrid && !detailFile.fileBlob && !summaryFile.fileBlob) {
      publishFromGridDataFile(billingRow.id);
    } else {
      saveAndPublishBillingFile(
        employerId,
        detailFile,
        summaryFile,
        billingFileType,
        billingMonth,
        apiType
      );
    }
    this.setState({
      saving: true,
      isDisablePublish: true,
      isFileEnable: false
    });
  };

  /*
    Save file without publishing to the ER Portal
  */
  saveWithoutPublish = () => {
    const {
      detailFile,
      summaryFile,
      billingFileType,
      billingMonth
    } = this.state;
    const { employerId, saveBillingFile } = this.props;
    const apiType = BILLING_FILE_DRAFT;
    saveBillingFile(
      employerId,
      detailFile,
      summaryFile,
      billingFileType,
      billingMonth,
      apiType
    );
    this.setState({ isDisablePublish: true, isFileEnable: false });
  };

  renderFileUploadForm = () => {
    const {
      billingFileType,
      uploadFileName,
      uploadFileNameSummary,
      isPreviewEnable,
      billingMonth,
      showDetailFile,
      showSummaryFile,
      isEnableFileType,
      isEnableMonth,
      showSummaryLoader,
      isFileEnable
    } = this.state;
    const { billingPreview, fileTypeList } = this.props;
    let months = getBillingMonthList();
    if (!months.includes(billingMonth)) {
      months.push(billingMonth);
    }

    const SecuredButtonFileUploadFormGroup = permitIf(
      ButtonFileUploadFormGroup,
      BILLING_FEATURE_ROLE_MAPPING.common
    );
    return (
      <div className="billing-file-upload-form">
        <SelectFormGroup
          labelDisplay="Billing Month"
          inputName="billingMonth"
          inputValue={billingMonth}
          options={months}
          disabled={!isEnableMonth}
          optionsMap={(type) => (
            <option key={type} value={type}>
              {type}
            </option>
          )}
          onChange={this.onChangeMonths}
        />
        {fileTypeList && (
          <SelectFormGroup
            labelDisplay="File Type"
            inputName="billingFileType"
            inputValue={billingFileType}
            onChange={this.onChangeFileType}
            disabled={!isEnableFileType}
            options={fileTypeList}
            optionsMap={(fileTypeList) => (
              <option key={fileTypeList} value={fileTypeList}>
                {BILLING_FILE_TYPES_DISPLAY_MAP[fileTypeList]}
              </option>
            )}
          />
        )}
        {showDetailFile && (
          <SecuredButtonFileUploadFormGroup
            id="billing-file-uploader"
            labelDisplay="Choose Detailed File"
            inputName={'billing-file-uploader'}
            fileLabel={uploadFileName}
            onClickChoose={(event) => this.selectFile(event)}
            acceptedFileTypes={XLSX}
            showRemove={false}
            progress={billingPreview.progress}
            buttonText="File"
            disabled={!isFileEnable}
          />
        )}

        {showSummaryFile && (
          <SecuredButtonFileUploadFormGroup
            id="billing-file-uploader-summary"
            labelDisplay="Choose Summary File"
            inputName={'billing-file-uploader-summary'}
            fileLabel={uploadFileNameSummary}
            onClickChoose={(event) => this.selectFileSummary(event)}
            acceptedFileTypes={XLSX}
            showRemove={false}
            progress={billingPreview.progress}
            buttonText="File"
            disabled={!isFileEnable}
          />
        )}

        <div>
          <Button
            color="primary"
            size="lg"
            className="billing-file-submit-button"
            disabled={!isPreviewEnable}
            onClick={this.showFilePreview}
          >
            Preview Billing File &nbsp;
            {billingPreview.uploadingStatus === 'UPLOADING' && (
              <FontAwesomeIcon icon="circle-notch" className="mr-2" spin />
            )}
          </Button>
        </div>
        <div className="loader">{showSummaryLoader && <Loader />}</div>
      </div>
    );
  };

  render() {
    const {
      saving,
      previewDataGrid,
      saveNotPublish,
      publishStatus,
      isDisablePublish
    } = this.state;
    const { billingSaveAndPublish, fromGridData, billingSave } = this.props;
    const SecuredButton = permitIf(Button, BILLING_FEATURE_ROLE_MAPPING.common);
    return (
      <div className="billing-file-upload-content">
        <Row>
          <Col className="text-center">
            <img
              src={this.props.employer.logoUrl}
              height="40px"
              alt="logoUrl"
            />
          </Col>
        </Row>
        {this.renderFileUploadForm()}

        <>
          {previewDataGrid && previewDataGrid.length ? (
            <>
              <BillingSummaryPreview summary={previewDataGrid} />
              <div className="billing-file-upload-form">
                <Col xs="12" className="text-center">
                  <SecuredButton
                    color="primary"
                    size="lg"
                    className="billing-publish-button"
                    disabled={isDisablePublish}
                    onClick={this.publish}
                  >
                    {publishStatus}
                    {billingSaveAndPublish.savingStatus === 'SAVING' && (
                      <FontAwesomeIcon
                        icon="circle-notch"
                        className="mr-2 ml-2"
                        spin
                      />
                    )}
                    {fromGridData.savingStatus === 'SAVING' && (
                      <FontAwesomeIcon
                        icon="circle-notch"
                        className="mr-2 ml-2"
                        spin
                      />
                    )}
                  </SecuredButton>
                </Col>
                {saveNotPublish && (
                  <Col xs="12" className="text-center">
                    <Button
                      color="link"
                      size="lg"
                      className="billing-save-button"
                      disabled={saving}
                      onClick={this.saveWithoutPublish}
                    >
                      Save, but do not {publishStatus.toLowerCase()}
                      {billingSave.savingStatus === 'SAVING' && (
                        <FontAwesomeIcon
                          icon="circle-notch"
                          className="mr-2 ml-2"
                          spin
                        />
                      )}
                    </Button>
                  </Col>
                )}
              </div>
            </>
          ) : (
            <div className="text-center p-4"></div>
          )}
        </>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  uploadDataFile: (
    employerId,
    detailFile,
    summaryFile,
    billingFileType,
    billingMonth,
    apiType
  ) =>
    dispatch(
      uploadBillingFile(
        employerId,
        detailFile,
        summaryFile,
        billingFileType,
        billingMonth,
        apiType
      )
    ),
  saveBillingFile: (
    employerId,
    detailFile,
    summaryFile,
    billingFileType,
    billingMonth,
    apiType
  ) =>
    dispatch(
      saveBillingFileWithoutPublish(
        employerId,
        detailFile,
        summaryFile,
        billingFileType,
        billingMonth,
        apiType
      )
    ),
  saveAndPublishBillingFile: (
    employerId,
    detailFile,
    summaryFile,
    billingFileType,
    billingMonth,
    apiType
  ) =>
    dispatch(
      saveAndPublishBillingFile(
        employerId,
        detailFile,
        summaryFile,
        billingFileType,
        billingMonth,
        apiType
      )
    ),
  getPreviewData: (billRecodId) => dispatch(getPreviewData(billRecodId)),
  publishFromGridDataFile: (billRecodId) =>
    dispatch(publishFromGridDataFile(billRecodId))
});

const mapStateToProps = (state) => {
  const { employerContext } = state.AppBase.contextReducer;
  const {
    fileTypeList,
    billingPreview,
    fileTypeListErrorResponse,
    billingSave,
    billingSaveAndPublish,
    previewData,
    previewDataError,
    fromGridData
  } = state.billingReducer;
  return {
    billingPreview,
    employer: employerContext,
    fileTypeList,
    fileTypeListErrorResponse,
    billingSave,
    billingSaveAndPublish,
    previewData,
    previewDataError,
    fromGridData
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
