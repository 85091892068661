import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import { FormGroup, Label } from 'components/Atoms';

class TagFormGroup extends React.Component {
  render() {
    const {
      labelDisplay,
      inputName,
      placeholder = '',
      feedback,
      suggestions = [],
      tags = [],
      onDelete,
      onAdd,
      isInvalid,
      maxLength = 2,
      handleInputChange,
      readOnly = false,
      allowDragDrop
    } = this.props;

    return (
      <FormGroup className="tag-form-group">
        <Label for={inputName}>{labelDisplay}</Label>
        <ReactTags
          tags={tags}
          suggestions={suggestions}
          handleDelete={onDelete}
          handleAddition={onAdd}
          inputFieldPosition="inline"
          minQueryLength={1}
          inline
          placeholder={placeholder}
          removeComponent={(props) => (
            <div {...props}>
              <span aria-hidden="true">&times;</span>
            </div>
          )}
          renderSuggestion={({ text }) => <div>{text}</div>}
          maxLength={maxLength}
          handleInputChange={handleInputChange}
          readOnly={readOnly}
          allowDragDrop={allowDragDrop}
          autofocus={false}
          autocomplete={true}
        />
        {isInvalid ? <div className="invalid">{feedback}</div> : ''}
      </FormGroup>
    );
  }
}

export default TagFormGroup;
