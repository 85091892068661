/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'components/Atoms';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import DataTableWithServerPagination from 'components/DataTables';
import { BILLING_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { permitIf } from 'components/hoc/Permit';
import {
  getEmployersBillingPage,
  getFileTypeList,
  downloadBill,
  findConfigStatus,
  createConfigModule,
  updateConfigModule
} from '../../actions/billingAction';
import { NotificationManager } from 'react-notifications';
import ActionDropdown from '../BillingList/components/ActionDropdown/ActionDropdown';
import FullscreenModal from 'components/FullscreenModal';
import FileUpload from '../BillingList/components/FileUpload/FileUpload';
import './billingList.scss';
import {
  MODULE_BILLING_ENABLED,
  BILLING_FILE_TYPES_DISPLAY_MAP,
  BILLING_FILE_STATUS_DISPLAY_MAP,
  BILLING_MONTH_FORMAT,
  DETAIL_FILE_TYPE,
  SUMMARY_FILE_TYPE,
  SUMMARY_AND_DETAIL_FILE_TYPE,
  CARRIER
} from '../../constants';
import FileHistory from '../BillingList/components/FileHistory/FileHistory';
import CarrierFiles from '../BillingList/components/CarrierFiles/CarrierFiles';
import FileDelete from '../BillingList/components/FileDelete/FileDelete';
import moment from 'moment';
import { saveFileAs } from 'util/documentUtil';
import PageActionButton from 'components/Buttons';

class BillingList extends React.Component {
  constructor(props) {
    super(props);
    const employerId = this.props.match.params.employerId;
    this.state = {
      isImportModalOpen: false,
      isHistoryModalOpen: false,
      isCarrierFilesModalOpen: false,
      isDeleteFilesModalOpen: false,
      employerId: employerId,
      selectedRow: null
    };
  }

  componentDidMount() {
    const employerId = this.props.match.params.employerId;
    this.props.findConfigStatus(employerId, MODULE_BILLING_ENABLED);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      employersBillingPageErrorResponse,
      downloadBillSuccess,
      downloadBillError,
      downloadBillName
    } = this.props;
    if (
      employersBillingPageErrorResponse !==
        prevProps.employersBillingPageErrorResponse &&
      employersBillingPageErrorResponse
    ) {
      NotificationManager.error('Failed to retrieve billing data .');
    }
    if (
      downloadBillSuccess &&
      downloadBillName &&
      downloadBillSuccess !== prevProps.downloadBillSuccess &&
      downloadBillName !== prevProps.downloadBillName
    ) {
      saveFileAs(downloadBillSuccess, downloadBillName);
    }
    if (
      downloadBillError !== prevProps.downloadBillError &&
      downloadBillError
    ) {
      NotificationManager.error('Failed to download the bill.');
    }
  }
  toggleDataImportModal = (selectedRow) => {
    this.setState((prevState) => ({
      isImportModalOpen: !prevState.isImportModalOpen
    }));
    this.props.getFileTypeList();
    this.setState({ selectedRow: selectedRow });
  };
  toggleDataHistoryModal = (selectedRow) => {
    this.setState((prevState) => ({
      isHistoryModalOpen: !prevState.isHistoryModalOpen
    }));
    this.setState({ selectedRow });
  };
  toggleDataCarrierFilesModal = (selectedRow) => {
    this.setState((prevState) => ({
      isCarrierFilesModalOpen: !prevState.isCarrierFilesModalOpen
    }));
    this.setState({ selectedRow: selectedRow });
  };
  toggleDataDeleteFilesModal = (selectedRow) => {
    this.setState((prevState) => ({
      isDeleteFilesModalOpen: !prevState.isDeleteFilesModalOpen
    }));
    this.setState({ selectedRow: selectedRow });
  };

  /**
   * Enable Billing Toggle
   */
  switchBillingEnabled = () => {
    const employerId = this.props.match.params.employerId;
    const { id, value } = this.props.configStatus;
    if (!id) {
      this.props.createConfigModule(employerId);
    } else {
      this.props.updateConfigModule(id, employerId, value);
    }
    NotificationManager.success(
      `Billing has been ${value === 'false' ? 'enabled' : 'disabled'}.`
    );
  };

  /*
    Download Billing Files
  */
  downloadBill = (selectedRow) => {
    const { downloadBills } = this.props;
    const { id, billingFileType, files } = selectedRow;
    if (billingFileType === SUMMARY_AND_DETAIL_FILE_TYPE) {
      files.forEach((file) => {
        if (file.type === DETAIL_FILE_TYPE) {
          downloadBills(id, file.type, file.fileName);
        }
        if (file.type === SUMMARY_FILE_TYPE) {
          downloadBills(id, file.type, file.fileName);
        }
      });
    } else {
      files.forEach((file) => {
        if (file.type === DETAIL_FILE_TYPE) {
          downloadBills(id, file.type, file.fileName);
        }
        if (file.type === SUMMARY_FILE_TYPE) {
          downloadBills(id, file.type, file.fileName);
        }
      });
    }
  };
  columnData = [
    {
      name: 'File Name',
      selector: 'fileName',
      cell: (row) => (
        <ul className="without-bullets">
          {row.files.map(({ type, fileName }) => (
            <li className="ellipsis" key={type}>
              {type === DETAIL_FILE_TYPE || type === SUMMARY_FILE_TYPE
                ? fileName
                : ''}
            </li>
          ))}
        </ul>
      ),
      sortable: true,
      grow: 2,
      wrap: true
    },
    {
      name: 'Status',
      selector: 'status',
      cell: (row) => BILLING_FILE_STATUS_DISPLAY_MAP[row.status],
      sortable: true,
      grow: 2,
      wrap: true
    },
    {
      name: 'File Type',
      selector: 'fileType',
      cell: (row) => BILLING_FILE_TYPES_DISPLAY_MAP[row.billingFileType],
      sortable: true,
      grow: 2,
      wrap: true
    },
    {
      name: 'Billing Month',
      selector: 'billingDate',
      cell: (row) =>
        moment(`${row.billingMonth} ${row.year}`, BILLING_MONTH_FORMAT).format(
          BILLING_MONTH_FORMAT
        ),
      sortable: true,
      grow: 2,
      wrap: true
    },
    {
      name: 'Attach Carrier Files?',
      selector: 'carrierFileStatus',
      sortable: false,
      grow: 2,
      wrap: true
    },
    {
      name: 'Actions',
      selector: '',
      cell: (row) => (
        <ActionDropdown
          row={row}
          toggleDataHistoryModal={this.toggleDataHistoryModal}
          toggleDataCarrierFilesModal={this.toggleDataCarrierFilesModal}
          toggleDataDeleteFilesModal={this.toggleDataDeleteFilesModal}
          togglePublishFilesModal={this.togglePublishFilesModal}
          downloadBill={this.downloadBill}
          toggleDataImportModal={this.toggleDataImportModal}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      grow: 0.25
    }
  ];

  checkIfCarrier(row) {
    const { files } = row;
    let str = 'No';
    files.forEach((file) => {
      if (file.type === CARRIER) {
        str = 'Yes';
      }
    });
    return str;
  }

  onClose = () => {
    this.billingList.triggerReload();
    this.setState({
      isImportModalOpen: false,
      isHistoryModalOpen: false,
      isCarrierFilesModalOpen: false,
      isDeleteFilesModalOpen: false
    });
  };

  render() {
    const {
      getEmployersBillingPage,
      employersBillingPageData,
      configStatus,
      employerName,
      logoUrl
    } = this.props;
    const {
      employerId,
      selectedRow,
      isHistoryModalOpen,
      isImportModalOpen,
      isCarrierFilesModalOpen,
      isDeleteFilesModalOpen
    } = this.state;

    const SecuredEmployerConfigToggle = permitIf(
      EmployerConfigToggleV2,
      BILLING_FEATURE_ROLE_MAPPING.common
    );

    const SecuredButton = permitIf(PageActionButton, BILLING_FEATURE_ROLE_MAPPING.common);

    return (
      <ApplicationWrapper>
        <HeaderContainer auto_id="billing" title="Billing" />
        <Row>
          <Col>
            <ContentContainer>
              <SecuredEmployerConfigToggle
                employerId={employerId}
                moduleEnabled={MODULE_BILLING_ENABLED}
                message="Billing"
                config={configStatus}
                switchCallBack={this.switchBillingEnabled}
              />
            </ContentContainer>
          </Col>
          <Col className="page-actions" xs="auto">
            <SecuredButton
              className="upload-button"
              size="lg"
              color="primary"
              disabled={
                configStatus && configStatus.value === 'true' ? false : true
              }
              onClick={this.toggleDataImportModal}
            >
              Import Billing File
            </SecuredButton>
          </Col>
        </Row>
        <div className="page-content billing-module-content" xs="auto">
          {(configStatus ? configStatus.value === 'true' : false) && (
            <ContentContainer>
              <DataTableWithServerPagination
                onRef={(ref) => (this.billingList = ref)}
                columnData={this.columnData}
                fetchData={(pageNumber, rowsPerPage, sortField, query) => {
                  getEmployersBillingPage(
                    pageNumber,
                    rowsPerPage,
                    sortField,
                    query,
                    employerId
                  );
                }}
                pageData={employersBillingPageData}
                defaultSortField={'billingDate'}
                defaultSortDesc={true}
              />
            </ContentContainer>
          )}
        </div>
        <FullscreenModal
          className="billing-import-file"
          isOpen={isImportModalOpen}
          toggle={this.onClose}
          title="Import Billing File"
        >
          <FileUpload
            employerId={employerId}
            billingRow={selectedRow}
            onClose={this.onClose}
            viewImportedData={false}
          />
        </FullscreenModal>
        <FullscreenModal
          className="billing-file-history"
          isOpen={isHistoryModalOpen}
          toggle={this.onClose}
          title="File History"
        >
          <FileHistory
            billingRow={selectedRow}
            employerId={employerId}
            onClose={this.onClose}
            viewImportedData={false}
          />
        </FullscreenModal>
        <FullscreenModal
          className="billing-import-file"
          isOpen={isCarrierFilesModalOpen}
          toggle={this.onClose}
          title="Attach Carrier Files"
        >
          <div className="id-card-logos">
            <img src={logoUrl} alt={employerName} />
          </div>
          <CarrierFiles
            billingRow={selectedRow}
            employerId={employerId}
            employerName={employerName}
            onClose={this.onClose}
            viewImportedData={false}
          />
        </FullscreenModal>
        <FullscreenModal
          className="billing-file-history"
          isOpen={isDeleteFilesModalOpen}
          toggle={this.onClose}
          title="Delete Billing File"
        >
          <FileDelete
            billingRow={selectedRow}
            employerId={employerId}
            onClose={this.onClose}
            viewImportedData={false}
          />
        </FullscreenModal>
      </ApplicationWrapper>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getEmployersBillingPage: (page, size, sort, query, employerId) =>
    dispatch(getEmployersBillingPage(page, size, sort, query, employerId)),
  getFileTypeList: () => dispatch(getFileTypeList()),
  downloadBills: (id, billingFileType, fileName) =>
    dispatch(downloadBill(id, billingFileType, fileName)),
  createConfigModule: (employerId) => dispatch(createConfigModule(employerId)),
  updateConfigModule: (id, employerId, value) =>
    dispatch(updateConfigModule(id, employerId, value)),
  findConfigStatus: (employerId, key) =>
    dispatch(findConfigStatus(employerId, key))
});
const mapStateToProps = (state) => {
  const {
    employersBillingPageData,
    fileTypeList,
    employersBillingPageErrorResponse,
    fileTypeListErrorResponse,
    downloadBillSuccess,
    downloadBillError,
    downloadBillName,
    configStatus
  } = state.billingReducer;

  const { 
    name: employerName, 
    logoUrl 
  } = state.AppBase.contextReducer.employerContext;

  return {
    configStatus,
    employersBillingPageData,
    employersBillingPageErrorResponse,
    fileTypeList,
    fileTypeListErrorResponse,
    downloadBillSuccess,
    downloadBillError,
    downloadBillName,
    employerName,
    logoUrl
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BillingList);
