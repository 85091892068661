/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

import Blink from 'components/Blink';
import { SyncStatus } from 'constants/syncConstants';

import './SyncStatusIcon.scss';

const SyncStatusIcon = (props) => {
  const { syncStatus } = props;

  const syncStatusConfiguration = SyncStatus[syncStatus] || {};
  const { value, color } = syncStatusConfiguration;

  const blink =
    value === SyncStatus.PENDING.value || value === SyncStatus.SYNCING.value;

  return blink ? (
    <Blink fade>
      <span className={`sync-status-icon ${color}`} />
    </Blink>
  ) : (
    <span className={`sync-status-icon ${color}`} />
  );
};

SyncStatusIcon.propTypes = {
  // If not provided or invalid, then show no color.
  syncStatus: PropTypes.oneOf(Object.keys(SyncStatus)).isRequired
};

export default SyncStatusIcon;
