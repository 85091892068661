/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import {
  BENEFIT_KIND_VISION,
  BENEFIT_KIND_DENTAL,
  BENEFIT_KIND_MEDICAL,
  BENCHMARK_PLAN_HSA
} from './constants';

export const redirectBack = (
  currentBenefitKind,
  type,
  employerId,
  benchmarkId,
  brokerId
) => {
  if (type === 'benchmarks') {
    return `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkId}/edit/${currentBenefitKind}`;
  } else if (type === 'publish') {
    return `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkId}/edit/vision/benchmark`;
  } else {
    if (currentBenefitKind === BENEFIT_KIND_MEDICAL) {
      return `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkId}/edit/censusInfo`;
    } else if (currentBenefitKind === BENEFIT_KIND_DENTAL) {
      return `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkId}/edit/medical/benchmark`;
    } else if (currentBenefitKind === BENEFIT_KIND_VISION) {
      return `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkId}/edit/dental/benchmark`;
    }
  }
};

export const isMedicalHSAPlan = (planType, benefitCategory) => {
  return (
    planType === BENCHMARK_PLAN_HSA && benefitCategory === BENEFIT_KIND_MEDICAL
  );
};

export const isInputAllowed = (values, format) => {
  if (format) {
    return true;
  }
  const { floatValue } = values;
  return floatValue === undefined || floatValue < 100000;
};

export const getOverallRatesText = (percentile, colorClassName = false) => {
  if (isValuePresent(percentile)) {
    if (percentile < -5) {
      return colorClassName ? 'green' : 'Below Market';
    } else if (percentile > -5 && percentile < 5) {
      return colorClassName ? 'amber' : 'At Market';
    } else if (percentile > 5) {
      return colorClassName ? 'red' : 'Above Market';
    }
  }
};

export const getPecentileColorClassNames = (percentile) => {
  if (isValuePresent(percentile)) {
    if (percentile >= 45 && percentile <= 55) {
      return 'amber';
    } else if (percentile > 55) {
      return 'green';
    } else {
      return 'red';
    }
  }
};

export const isValuePresent = (value) => {
  return (
    value !== null &&
    value !== undefined &&
    value !== '' &&
    value !== 'null' &&
    value !== 'undefined' &&
    !isNaN(value)
  );
};
