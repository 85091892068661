import axios from 'axios';
import Util, {
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_MULTIPART_FORM
} from '../util/apiUtil';

const baseURL = `${Util.baseApi}/${Util.version}/plans/carriers`;

export default {
  findAll(page, size, sort, orgId) {
    return axios.get(
      `${baseURL}?${Util.getPagingQueryString(page, size, sort)}${
        orgId ? `&organizationId=${orgId}` : ''
      }`
    );
  },

  findByBenefitCategory(benefitCategory,brokerId) {
    return axios.get(`${baseURL}/basic?benefit-category=${benefitCategory}&&organizationId=${brokerId}`);
  },

  createCarrier(carrier) {
    return axios.post(baseURL, [carrier]);
  },

  updateCarrier(carrier) {
    return axios.put(`${baseURL}/${carrier.id}`, carrier);
  },

  uploadLogo(carrierId, file, progressNotifier) {
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      },
      onUploadProgress: progressNotifier
    };
    const formData = new FormData();
    formData.append('file', file, file.filename);
    return axios.post(`${baseURL}/${carrierId}/logo`, formData, config);
  }
};
