/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
export const GET_CALENDARS_IN_PROGRESS = 'GET_CALENDARS_IN_PROGRESS';
export const GET_CALENDARS = 'GET_CALENDARS';
export const GET_CALENDAR_TEMPLATES = 'GET_CALENDAR_TEMPLATES';
export const GET_CALENDAR_TEMPLATES_IN_PROGRESS = 'GET_CALENDAR_TEMPLATES_IN_PROGRESS';
export const GET_CALENDAR_TEMPLATES_ERROR = 'GET_CALENDAR_TEMPLATES_ERROR';
export const GET_CALENDARS_ERROR = 'GET_CALENDARS_ERROR';
export const SAVE_CALENDARS = 'SAVE_CALENDARS';
export const UPDATE_STATUS_IN_PROGRESS = 'UPDATE_STATUS_IN_PROGRESS';
export const SAVE_CALENDARS_ERROR = 'SAVE_CALENDARS_ERROR';
export const COPY_EVENTS_ERROR = 'COPY_EVENTS_ERROR';