import React from 'react';
import _ from 'lodash';
import { FormGroup, FormFeedback, Label, Input } from 'components/Atoms';

const SelectFormGroup = (props) => {
  const {
    labelDisplay,
    inputName,
    inputValue,
    onChange,
    disabled,
    options = [],
    optionsMap,
    placeholder,
    isInvalid,
    feedback,
    invalid,
    hideSelectOption = false,
    hidePlaceholder = false,
    additionalClass
  } = props;
  return (
    <FormGroup>
      <Label for={inputName}>{labelDisplay}</Label>
      <Input
        className={`custom-select ${inputValue ? '' : 'placeholder'} ${
          isInvalid ? 'is-invalid' : ''
        } ${additionalClass ? additionalClass : ''}`}
        type="select"
        id={inputName}
        name={inputName}
        value={inputValue || ''}
        onChange={onChange}
        disabled={disabled}
        invalid={invalid}
      >
        {!hideSelectOption && (
          <option hidden={hidePlaceholder} value="">
            {_.defaultTo(placeholder, 'Select Option')}
          </option>
        )}
        {options.map(
          optionsMap
            ? optionsMap
            : (item, index) => (
                <option key={index} value={item.value}>
                  {item.text}
                </option>
              )
        )}
      </Input>
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
    </FormGroup>
  );
};

export default SelectFormGroup;
