/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FullscreenModal from 'components/FullscreenModal';
import { DataTableWithClientPagination } from 'components/DataTables';
import PageActionButton from 'components/Buttons';

import { generatePath } from 'react-router-dom';
import * as BenchmarkRoutes from '../../../../../routes';

import { permitIf } from 'components/hoc/Permit';
import { BENCHMARKS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import BenchmarkService from 'services/BenchmarkService';

import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from 'components/Atoms';

import './EditBenchmarkModal.scss';

import { Row, Col } from 'components/Atoms';

import _ from 'lodash';

class EditBenchmarkModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      benAdmin: ''
    };
  }

  goToReImportScreen = () => {
    const { selectedImportData } = this.props;

    const { source } = selectedImportData;
    this.setState({ benAdmin: source });
  };

  onSelectBenAdmin = (benAdmin) => {
    this.setState({ benAdmin });
  };

  goToEditBenchmark = (benefitCategoryToBeViewed) => {
    const { match, history } = this.props;
    const { employerId, benchmarkId, brokerId } = match.params;
    let benefitCategory = benefitCategoryToBeViewed.toLowerCase();
    history.push(
      generatePath(BenchmarkRoutes.BENCHMARKS_EDIT_CURRENT_PATH, {
        brokerId,
        employerId,
        benchmarkId,
        benefitCategory
      })
    );
  };

  previewBenchmarkForSelectedType = (planId) => {
    const { benefitCategory, benchmarkId, match } = this.props;
    const { employerId } = match.params;
    const benchmarkPreviewUrl = BenchmarkService.getPreviewBenchmarkUrl(
      benchmarkId,
      benefitCategory,
      employerId,
      planId
    );
    window.open(benchmarkPreviewUrl, '_blank');
  };
  getSelectionDropdown = (benchmark) => {
    const SecuredDropdownItem = permitIf(
      DropdownItem,
      BENCHMARKS_FEATURE_ROLE_MAPPING.common
    );

    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="a" className="cursor-pointer" caret>
          <b>Select</b>
        </DropdownToggle>

        <DropdownMenu>
          <SecuredDropdownItem
            onClick={() => this.goToEditBenchmark(benchmark.benefitCategory)}
          >
            Edit
          </SecuredDropdownItem>
          <DropdownItem
            onClick={() =>
              this.previewBenchmarkForSelectedType(benchmark.planId)
            }
            disabled={benchmark.status === 'INCOMPLETE'}
          >
            Preview
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  columnData = [
    {
      name: <div className="edit-benchmark-header">Plan Type</div>,
      selector: 'planType',
      sortable: false
    },
    {
      name: <div className="edit-benchmark-header">Benchmark</div>,
      selector: 'benchmarkName'
    },
    {
      name: <div className="benchmarks-list-table-heading">Actions</div>,
      selector: 'select',
      cell: (row) => this.getSelectionDropdown(row),
      ignoreRowClick: true,
      allowOverflow: true
    }
  ];

  extractBenchmarkPlan = (benchmark, currentPlanId, benefitCategory) => {
    if (!_.isEmpty(benchmark)) {
      let selectedBenchmark = _.find(benchmark.benchmarks[benefitCategory],
        (benchmark) => benchmark.currentPlan.id === currentPlanId);
      if (selectedBenchmark && !_.isEmpty(selectedBenchmark.benchmarkPlan)) {
        return selectedBenchmark.benchmarkPlan.planName;
      }
    }
    return '-';
  }

  render() {
    const { isOpen, toggle, title, benefitCategory, benchmark, status } = this.props;

    let rowData = [];

    if (benchmark && benefitCategory) {
      let benefitCategoryU = benefitCategory.toUpperCase();
      if (benchmark.currentPlans[benefitCategoryU] && benchmark.currentPlans[benefitCategoryU].length > 0) {
        benchmark.currentPlans[benefitCategoryU].forEach((currentPlan) => {
          let row = {
            planType: currentPlan.planType,
            benchmarkName: this.extractBenchmarkPlan(benchmark, currentPlan.id, benefitCategoryU),
            benefitCategory: currentPlan.benefitCategory,
            planId: currentPlan.id,
            status: status
          }
          rowData.push(row);
        });
      }
    }

    return (
      <FullscreenModal
        isOpen={isOpen}
        toggle={toggle}
        title={title}
        closeText={'close'}
      >
        <Row>
          <Col>
            <DataTableWithClientPagination
              columnData={this.columnData}
              rowData={rowData}
              pagination={false}
              hideSearchBar
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <PageActionButton onClick={() => toggle(title, benefitCategory)}>Close</PageActionButton>
          </Col>
        </Row>
      </FullscreenModal>
    );
  }
}

export default withRouter(
  connect((state) => {
    return {};
  })(EditBenchmarkModal)
);
