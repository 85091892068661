export const MODULE_BENCHMARKS_ENABLED = 'MODULE_BENCHMARKS_ENABLED';

// Plan design sub types - common
export const TYPE_DEDUCTIBLE = 'deductible';
export const COINSURANCE = 'coinsurance';

// Plan design sub types - medical
export const TYPE_OOP_MAX = 'oopMax';
export const TYPE_COPAY = 'copay';
export const TYPE_RX_COST_TIER = 'rxCost';

// Plan design sub types - dental
export const ANNUAL_MAX_BENEFIT = 'annualMaxBenefits';
export const ORTHODONTAL = 'orthodontiaCoinsuarance';
export const ORTHODONTIAL_MAX = 'orthodontiaMaxBenefits';

// Plan design sub types - vision
export const COPAY_EXAM = 'copayExam';
export const COPAY_MATERIAL = 'copayMaterials';
export const FRAME_ALLOWANCE = 'frameAllowance';
export const CONTACT_ALLOWANCE = 'contactAllowance';
export const FREQ_SERVICE = 'freqOfService';

// Plan contributions and rate types
export const TYPE_CONTRIBUTION = "TYPE_CONTRIBUTION";
export const TYPE_RATES = "TYPE_RATES";
export const BENEFIT_KIND_MEDICAL = 'medical'
export const BENEFIT_KIND_DENTAL = 'dental'
export const BENEFIT_KIND_VISION = 'vision'
export const BENCHMARK_PUBLISHED = 'PUBLISHED'
export const TYPE_ENROLLMENTS = 'TYPE_ENROLLMENTS';
export const TYPE_HSA_CONTRIBUTION = 'TYPE_HSA_CONTRIBUTION';

export const BENCHMARK_PLAN_HSA = 'HSA';
export const HSA_AVAILABLE_TYPES = [
  'costMonthlyEmployeeOnly',
  'contributionMonthlyEmployeeOnly',
  'costMonthlyEmployeeAndSpouce',
  'contributionMonthlyEmployeeAndSpouce',
  'costMonthlyEmployeeAndChildren',
  'contributionMonthlyEmployeeAndChildren',
  'costMonthlyEmployeeAndFamily',
  'contributionMonthlyEmployeeAndFamily'
];

export const MEDICAL = 'MEDICAL';
export const DENTAL = 'DENTAL';
export const VISION = 'VISION';

export const BENCHMARK_PLAN_HSA_PLACEHOLDER = 'Total Cost / HSA Cont.';

export const BENCHMARK_TYPE_CURRENT = 'current';
