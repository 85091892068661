/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import { Button, Col, Row, Table } from 'components/Atoms';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import Confirmation from 'components/Confirmation/Confirmation';
import FullscreenModal from 'components/FullscreenModal';
import './ConfigurePlanRatePopUp.scss';

const NEGOTIATED = 'NEGOTIATED';
const BENCHMARK = 'BENCHMARK';
class ConfigurePlanRatesPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBundle: props.selectedBundle,
      applyAll: false,
      isConfirmOpen: false,
      isNegotiatedConfirmOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedBundle !== prevProps.selectedBundle) {
      this.setState({ selectedBundle: this.props.selectedBundle });
    }
  }

  changeApplyAll = (event) => {
    this.setState({ applyAll: event.target.checked });
  };

  saveValues = () => {
    if (this.state.applyAll) {
      this.setState({ isConfirmOpen: true });
    } else {
      this.setState({ isNegotiatedConfirmOpen: true });
    }
  };

  onSaveClick = () => {
    const { id, planContributions } = this.state.selectedBundle;
    let tiers = ['EE', 'EF', 'ES', 'EC'];
    planContributions.map(function(planContribution) {
      planContribution.premiumsUpdated = true;
      if (!planContribution.tierContributionsBenchmark) {
        planContribution.tierContributionsBenchmark = {};
      }
      tiers.map(function(tier) {
        if (!planContribution.tierContributionsBenchmark[tier]) {
          planContribution.tierContributionsBenchmark[tier] = { premium: '0' };
        }
        return tier;
      });
      if (!planContribution.tierContributionsNegotiated) {
        planContribution.tierContributionsNegotiated = {};
      }
      tiers.map(function(tier) {
        if (!planContribution.tierContributionsNegotiated[tier]) {
          planContribution.tierContributionsNegotiated[tier] = { premium: '0' };
        }
        return tier;
      });
      return planContribution;
    });
    this.props.savePlanContributions(
      id,
      planContributions,
      this.state.applyAll
    );
    this.props.onClose();
    this.setState({
      applyAll: false,
      isConfirmOpen: false
    });
  };
  closePopUp = () => {
    this.props.onClose();
    this.setState({
      applyAll: false,
      isConfirmOpen: false,
      isNegotiatedConfirmOpen: false
    });
  };
  onChange = (event, value, planContributionId, type, name) => {
    const newValue = event.value;
    let payload = this.state.selectedBundle.planContributions;
    let bundleId = this.state.selectedBundle.id;
    const index = _.findIndex(payload, { id: planContributionId });
    const contributionType =
      type === BENCHMARK
        ? 'tierContributionsBenchmark'
        : 'tierContributionsNegotiated';
    const key = `${index}.${contributionType}.${name}.premium`;
    _.set(payload, key, newValue);

    this.setState({
      selectedBundle: {
        planContributions: payload,
        id: bundleId
      }
    });
  };

  render() {
    const { isNegotiationPopupOpen, onClose } = this.props;
    const { selectedBundle } = this.state;

    if (selectedBundle && selectedBundle.planContributions) {
      return (
        <FullscreenModal
          isOpen={isNegotiationPopupOpen}
          className="negotiation-popup"
          toggle={() => this.closePopUp()}
          title="Configure Negotiated & Benchmark Rate Percentages"
        >
          <h2 style={{ textAlign: 'center' }}>
            Enter Dollar Amount rates and system will calculate the percentages
          </h2>
          <Row className="mt-4">
            <Col>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="tier-name-cell">
                      <h4>Negotiated Rates</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <AnnualTierRateForm
                    planContributions={selectedBundle.planContributions}
                    onChange={this.onChange}
                    type={NEGOTIATED}
                  />
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Col>
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="tier-name-cell">
                        <h4>Benchmark Rates</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <AnnualTierRateForm
                      planContributions={selectedBundle.planContributions}
                      onChange={this.onChange}
                      type={BENCHMARK}
                    />
                  </tbody>
                </Table>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group text-left">
                <label>
                  <input
                    type="checkbox"
                    className="mt-2 mr-1"
                    checked={this.state.applyAll}
                    onChange={this.changeApplyAll}
                  />
                  Apply these benchmark rates to all bundles
                </label>
              </div>
            </Col>
          </Row>
          <Row className="mt-4 text-center">
            <Col className="text-center">
              <Button
                outline
                color="primary"
                size="lg"
                key={123}
                onClick={this.props.onClose}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="lg"
                onClick={this.saveValues}
                className="ml-2"
              >
                Save
              </Button>
            </Col>
          </Row>
          <Confirmation
            title="Save Changes?"
            message={<>Negotiated rates will be changed. Continue?</>}
            open={this.state.isNegotiatedConfirmOpen}
            confirmBtnText={'Save'}
            onConfirm={() => {
              this.onSaveClick();
            }}
            onClose={() => this.setState({ isNegotiatedConfirmOpen: false })}
          ></Confirmation>
          <Confirmation
            title="Save Changes?"
            message={
              <>Benchmark rates of all the Bundles will be changed. Continue?</>
            }
            open={this.state.isConfirmOpen}
            confirmBtnText={'Save'}
            onConfirm={() => {
              this.onSaveClick();
            }}
            onClose={() => this.setState({ isConfirmOpen: false })}
          ></Confirmation>
        </FullscreenModal>
      );
    } else {
      return (
        <FullscreenModal
          isOpen={isNegotiationPopupOpen}
          className="negotiation-popup"
          toggle={() => onClose()}
          title="Configure Negotiated & Benchmark Rate Percentages"
        ></FullscreenModal>
      );
    }
  }
}
const AnnualTierRateForm = (props) => {
  const { planContributions, type } = props;
  return (
    <>
      <tr>
        <td className="table-cell-padding"></td>
        <td>
          <Row>
            <Col>
              <span className="split-text">EE</span>
            </Col>
          </Row>
        </td>
        <td>
          <Row>
            <Col>
              <span className="split-text">ES</span>
            </Col>
          </Row>
        </td>
        <td>
          <Row>
            <Col>
              <span className="split-text">EC</span>
            </Col>
          </Row>
        </td>
        <td>
          <Row>
            <Col>
              <span className="split-text">EF</span>
            </Col>
          </Row>
        </td>
      </tr>
      {planContributions.map(function(planContribution) {
        let eePremium = 0.0;
        let esPremium = 0.0;
        let ecPremium = 0.0;
        let efPremium = 0.0;
        if (type === BENCHMARK && planContribution.tierContributionsBenchmark) {
          if (planContribution.tierContributionsBenchmark.EE) {
            eePremium = planContribution.tierContributionsBenchmark.EE.premium;
          }
          if (planContribution.tierContributionsBenchmark.ES) {
            esPremium = planContribution.tierContributionsBenchmark.ES.premium;
          }
          if (planContribution.tierContributionsBenchmark.EC) {
            ecPremium = planContribution.tierContributionsBenchmark.EC.premium;
          }
          if (planContribution.tierContributionsBenchmark.EF) {
            efPremium = planContribution.tierContributionsBenchmark.EF.premium;
          }
        } else if (
          type === NEGOTIATED &&
          planContribution.tierContributionsNegotiated
        ) {
          if (planContribution.tierContributionsNegotiated.EE) {
            eePremium = planContribution.tierContributionsNegotiated.EE.premium;
          }
          if (planContribution.tierContributionsNegotiated.ES) {
            esPremium = planContribution.tierContributionsNegotiated.ES.premium;
          }
          if (planContribution.tierContributionsNegotiated.EC) {
            ecPremium = planContribution.tierContributionsNegotiated.EC.premium;
          }
          if (planContribution.tierContributionsNegotiated.EF) {
            efPremium = planContribution.tierContributionsNegotiated.EF.premium;
          }
        }

        return (
          <tr>
            <td className="table-cell-padding">{planContribution.planName}</td>
            <td>
              <Row>
                <Col>
                  <TierRateInput
                    decimalScale={2}
                    fixedDecimalScale={false}
                    value={eePremium}
                    name={'EE'}
                    type={type}
                    planContributionId={planContribution.id}
                    onChangeNumberFormat={props.onChange}
                  />
                </Col>
              </Row>
            </td>
            <td>
              <Row>
                <Col>
                  <TierRateInput
                    decimalScale={2}
                    fixedDecimalScale={false}
                    value={esPremium}
                    name={'ES'}
                    type={type}
                    planContributionId={planContribution.id}
                    onChangeNumberFormat={props.onChange}
                  />
                </Col>
              </Row>
            </td>
            <td>
              <Row>
                <Col>
                  <TierRateInput
                    decimalScale={2}
                    fixedDecimalScale={false}
                    value={ecPremium}
                    name={'EC'}
                    type={type}
                    planContributionId={planContribution.id}
                    onChangeNumberFormat={props.onChange}
                  />
                </Col>
              </Row>
            </td>
            <td>
              <Row>
                <Col>
                  <TierRateInput
                    decimalScale={2}
                    fixedDecimalScale={false}
                    value={efPremium}
                    name={'EF'}
                    type={type}
                    planContributionId={planContribution.id}
                    onChangeNumberFormat={props.onChange}
                  />
                </Col>
              </Row>
            </td>
          </tr>
        );
      })}
    </>
  );
};

const TierRateInput = (props) => {
  const {
    id,
    value,
    decimalScale,
    fixedDecimalScale,
    name,
    type,
    planContributionId
  } = props;
  return (
    <NumberFormat
      className={'form-control input-width input-form-group value-input'}
      type="text"
      id={id}
      value={value}
      placeholder={'$'}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      allowNegative={false}
      onValueChange={(event) =>
        props.onChangeNumberFormat(event, value, planContributionId, type, name)
      }
    />
  );
};

export default ConfigurePlanRatesPopUp;
