/**********************************************************************************************************************
 * Copyright (C) 2019-2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const PriorityType = {
  UNKNOWN: {
    value: 'UNKNOWN',
    label: 'Unknown',
    color: 'unknown',
    hidden: true
  },
  HIGH: { value: 'HIGH', label: 'High', color: 'orange' },
  MEDIUM: { value: 'MEDIUM', label: 'Medium', color: 'yellow' },
  LOW: { value: 'LOW', label: 'Low', color: 'blue' }
};

export const IssueStatus = {
  NEW: { value: 'NEW', label: 'New', color: 'blue', default: true },
  IN_PROGRESS: {
    value: 'IN_PROGRESS',
    label: 'In Progress',
    color: 'yellow',
    default: true
  },
  RESOLVED: { value: 'RESOLVED', label: 'Resolved', color: 'green' },
  BLOCKED: {
    value: 'BLOCKED',
    label: 'Blocked',
    color: 'red',
    default: true
  }
};

export const IssueType = {
  BENEFITS_QUESTION: {
    value: 'BENEFITS_QUESTION',
    label: 'Benefits Question',
    default: true
  },
  BILLING: { value: 'BILLING', label: 'Billing', default: true },
  CARRIER: { value: 'CARRIER', label: 'Carrier', default: true },
  COBRA: { value: 'COBRA', label: 'Cobra', default: true },
  COMPLIANCE: {
    value: 'COMPLIANCE',
    label: 'Compliance',
    default: true
  },
  DEMOGRAPHIC_DATA: {
    value: 'DEMOGRAPHIC_DATA',
    label: 'Demographic Data',
    default: true
  },
  EDI: { value: 'EDI', label: 'EDI', default: true },
  ELIGIBILITY: {
    value: 'ELIGIBILITY',
    label: 'Eligibility',
    default: true
  },
  EMPLOYEE_ISSUE: {
    value: 'EMPLOYEE_ISSUE',
    label: 'Employee Issue',
    hidden: true,
    default: true
  },
  EOI: { value: 'EOI', label: 'EOI', default: true },
  GENERAL: { value: 'GENERAL', label: 'General', default: true },
  ID_CARDS: { value: 'ID_CARDS', label: 'ID Cards', default: true },
  INTEGRATIONS: {
    value: 'INTEGRATIONS',
    label: 'Integrations',
    default: true
  },
  LOGIN_ISSUE: {
    value: 'LOGIN_ISSUE',
    label: 'Login Issue',
    default: true
  },
  PAYROLL: { value: 'PAYROLL', label: 'Payroll', default: true },
  PROVIDER_SEARCH: {
    value: 'PROVIDER_SEARCH',
    label: 'Provider Search',
    default: true
  },
  QLE: { value: 'QLE', label: 'QLE', default: true },
  RENEWAL: { value: 'RENEWAL', label: 'Renewal', default: true },
  RESEARCHING_CLAIM: {
    value: 'RESEARCHING_CLAIM',
    label: 'Researching Claim',
    default: true
  },
  SPAM: {
    value: 'SPAM',
    label: 'SPAM'
  },
  SPENDING_ACCOUNTS: {
    value: 'SPENDING_ACCOUNTS',
    label: 'Spending Accounts',
    default: true
  },
  UNKNOWN: {
    value: 'UNKNOWN',
    label: 'Unknown',
    hidden: true,
    default: true
  },
  URGENT_ENROLLMENT: {
    value: 'URGENT_ENROLLMENT',
    label: 'Urgent Enrollment',
    default: true
  }
};

//Spam type removed when creating issues
export const { SPAM, ...CreateIssueTypes } = IssueType;

export const DaysOpen = {
  LESS_THAN_FIVE: { value: [0, 4], label: 'Less than 5 days' },
  FIVE_TO_TEN: { value: [5, 10], label: '5 - 10 days' },
  ELEVEN_TO_TWENTY: { value: [11, 20], label: '11 - 20 days' },
  TWENTY_ONE_TO_THIRTY: { value: [21, 30], label: '21 - 30 days' },
  GREATER_THAN_THIRTY: { value: [31, 100], label: 'More than 30 days' }
};

export const DESCRIPTION_CHARACTER_LIMIT = 31000;
export const SUBJECT_CHARACHTER_LIMIT = 500;

export const SelectableStatus = {
  NEW: { value: 'NEW', label: 'New', hidden: true },
  IN_PROGRESS: { value: 'IN_PROGRESS', label: 'In Progress', hidden: false },
  RESOLVED: { value: 'RESOLVED', label: 'Resolved', hidden: false },
  BLOCKED: { value: 'BLOCKED', label: 'Blocked', hidden: false }
};

//New option excluded for assigned issues
export const { NEW, ...AssignedIssueStatus } = SelectableStatus;

export const OnErPortalOptions = {
  YES: { value: true, label: 'Yes' },
  NO: { value: false, label: 'No' }
};
export const MODULE_ISSUES_LOG_ACCOUNT_LEVEL =
  'MODULE_ISSUES_LOG_ACCOUNT_LEVEL';

export const TYPE_EXTERNAL_COMMUNICATION = 'ERC';
export const TYPE_INTERNAL_COMMUNICATION = 'IC';
export const TYPE_EE_COMMUNICATION = 'EEC';

export const IssuesLogSelectableOrigin = {
  EE_CONSULT: { value: 'EE_CONSULT', label: 'EE Consult' },
  EE_EMAIL: { value: 'EE_EMAIL', label: 'EE Email' },
  ER_ADMIN: { value: 'ER_ADMIN', label: 'ER Admin' },
  INTERNAL_EE: { value: 'INTERNAL_EE', label: 'Internal EE' },
  INTERNAL_ER: { value: 'INTERNAL_ER', label: 'Internal ER' },
  SUPPORT_CALL: { value: 'SUPPORT_CALL', label: 'Support Call' }
};

export const Source = {
  EE_CONSULT: { value: 'EE_CONSULT', label: 'EE Consult' },
  EE_EMAIL: { value: 'EE_EMAIL', label: 'EE Email' },
  ER_ADMIN: { value: 'ER_ADMIN', label: 'ER Admin' },
  ER_PORTAL: { value: 'ER_PORTAL', label: 'ER Portal' },
  INTERNAL_EE: { value: 'INTERNAL_EE', label: 'Internal EE' },
  INTERNAL_ER: { value: 'INTERNAL_ER', label: 'Internal ER' },
  NATIVE_ISSUES_LOG: { value: 'NATIVE_ISSUES_LOG', label: 'Native Issue' },
  SLACK_BOT: { value: 'SLACK_BOT', label: 'Slackbot' },
  SUPPORT: { value: 'SUPPORT', label: 'Support@' },
  SUPPORT_CALL: { value: 'SUPPORT_CALL', label: 'Support Call' }
};

export const ER_SOURCES = ['ER_ADMIN', 'ER_PORTAL', 'INTERNAL_ER'];
export const EE_SOURCES = [
  'SUPPORT',
  'SLACK_BOT',
  'SUPPORT_CALL',
  'EE_EMAIL',
  'EE_CONSULT',
  'INTERNAL_EE'
];

export const ER_COMMENT = 'ER';
export const INTERNAL_COMMENT = 'INTERNAL';
export const EE_COMMENT = 'EE';

export const SUPPORT_EMAIL_TO_FILTER = 'support@lumity.com';
export const ISSUES_EMAIL_TO_FILTER = 'issues.lumity.com';
