/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import { generatePath } from 'react-router-dom';

import { NavigationTree } from 'routes/NavigationTree';
import { NavigationTreePlatformAdmin } from 'routes/NavigationTreePlatformAdmin';

import { PLATFORM_ADMIN } from '../modules/app-base/constants/loginTypeConstants';

/**
 * A depth-first search is performed on the entire NavigationTree. The navigationContext is generated as subTrees are visited.
 * The returned navigationContext will have the following structure (note only display is required):
 *  [
 *    display: React element or string,
 *    icon?: FontAwesomeIcon to display,
 *    linkTo?: Route that the user will be redirected to on click
 *    subMenu?: Another entry of this list (recursive)
 *  ]
 */
const createNavigationContext = (match, contextObjects, loginType) => {
  const navigationContext = [];
  let isLeafFound = false;
  if (loginType === PLATFORM_ADMIN) {
    isLeafFound = pruneTreeForPath(
      NavigationTreePlatformAdmin(contextObjects),
      match,
      navigationContext
    );
  } else {
    isLeafFound = pruneTreeForPath(
      NavigationTree(contextObjects),
      match,
      navigationContext
    );
  }
  if (!isLeafFound) {
    return null;
  }

  return navigationContext;
};

/**
 * This function is recursive with two potential return values:
 *    if (path of match was found in routes array of the subtree), return true
 *    else return false.
 * @param {Array} tree subTree that is being searched for the match's path
 * @param {Object} match react-router match object containing the Route's path that is being searched for
 * @param {Array} prunedTree The navigationContext that is being built in memory. Affected by recursion order.
 * @param {Boolean} isLeafFound bool indicating whether to expand subTrees. This is set to true when some treeEntry.routes contains the match.param
 */
const pruneTreeForPath = (tree, match, prunedTree, isLeafFound = false) => {
  const { path, params } = match;

  // For each treeEntry of the tree array, check if the route exists in the routes array. Otherwise, expand the submenu.
  // IMPORTANT: Recursion occurs depth-first to allow only one submenu to be expanded for each level.
  for (const treeEntry of tree) {
    const {
      display,
      icon,
      linkTo,
      routes,
      subTrees,
      roles,
      show,
      ownership,
      className
    } = treeEntry;
    let isActive = false;
    // If route is found then end subTree expansion.
    if ((routes || []).includes(path)) {
      isLeafFound = true;
      isActive = true;
    }
    let subMenu;
    // If route matches the subTree prefix, search the subTree again for match.path
    // Skip if we have already found the match.path

    if (subTrees && !isLeafFound) {
      for (const [prefix, subTree] of Object.entries(subTrees)) {
        if (path.startsWith(prefix)) {
          subMenu = [];
          isLeafFound = pruneTreeForPath(subTree, match, subMenu, isLeafFound);
          isActive = isLeafFound;
        }
      }
    }
    prunedTree.push({
      display,
      icon,
      linkTo: generatePathOrRedirectPath(linkTo, params),
      subMenu,
      isActive,
      roles,
      show,
      ownership,
      className,
      routes
    });
  }
  return isLeafFound;
};

/**
 * generatePath will throw an error if not all the params in path are found in the params argument.
 * In that case, return root as the path.
 * TODO: Create a 404 Page and set it as the default page.
 */
const generatePathOrRedirectPath = (path, params) => {
  // If there is no path then do not redirect.
  if (!path) {
    return;
  }

  try {
    return generatePath(path, params);
  } catch (error) {
    return '/';
  }
};

export default createNavigationContext;
