/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import {
  Button,
  Row,
  Col,
  Input,
  CustomInput,
  FormGroup,
  Alert
} from 'components/Atoms';
import Icon from 'components/Icons';
import { TextFormGroup } from 'components/DataForm';

// constants
import { ERROR_MSGS } from 'modules/Integrations/constants';

const BenefitGroupRow = ({
  index,
  lumityBenefitGroups = [],
  lfiBenefitGroup,
  lumityBenefitGroup,
  ignore,
  updateBenefitGroupRow,
  removeBenefitGroupRow,
  fieldErrors
}) => (
  <div className="lfi-benefitGroup-row">
    <Button
      color="link"
      onClick={removeBenefitGroupRow}
      className="remove-icon"
    >
      <Icon icon="minus-circle" />
    </Button>
    <Row>
      <Col className="lfi-benefit-group__col">
        <TextFormGroup
          labelDisplay="LFI Benefit Group"
          inputName="lfiBenefitGroup"
          inputValue={lfiBenefitGroup}
          onChange={updateBenefitGroupRow}
          placeholder="Enter LFI Benefit Group"
          isInvalid={!!(fieldErrors && fieldErrors['lfiBenefitGroup'])}
        />
      </Col>
      <Col className="lumity-benefit-group__col">
        <FormGroup>
          <label>
            <b>Lumity Benefit Group</b>
          </label>
          <Input
            type="select"
            name="lumityBenefitGroup"
            onChange={updateBenefitGroupRow}
            value={lumityBenefitGroup}
            invalid={!!(fieldErrors && fieldErrors['lumityBenefitGroup'])}
            disabled={ignore}
          >
            <option key="select" value="">
              Select Option
            </option>
            {lumityBenefitGroups.map((lumityGroup) => {
              return (
                <option key={lumityGroup} value={lumityGroup}>
                  {lumityGroup}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </Col>
      <Col className="benefit-ignore__col">
        <FormGroup>
          <CustomInput
            type="checkbox"
            label="Ignore"
            className="ignore__checkbox"
            id={`${index}-ignore`}
            inline
            name="ignore"
            checked={Boolean(ignore)}
            onChange={updateBenefitGroupRow}
          />
        </FormGroup>
      </Col>
    </Row>
  </div>
);

export class LFIBenefitGroupConfiguration extends Component {
  static propTypes = {
    bambooHrBenefitGroups: PropTypes.arrayOf(
      PropTypes.shape({
        bambooHrBenefitGroupId: PropTypes.string,
        bambooHrBenefitGroupName: PropTypes.string
      })
    ),
    lumityBenefitGroups: PropTypes.arrayOf(PropTypes.string),
    // Map with keys as a bambooHrBenefitGroupId, and values as object with lumityBenefitGroup and ignore fields
    benefitGroupConfiguration: PropTypes.arrayOf(
      PropTypes.shape({
        lfiBenefitGroup: PropTypes.string,
        lumityBenefitGroup: PropTypes.string,
        ignore: PropTypes.bool
      })
    ),
    // Expect (benefitGroupConfiguration) => any
    onPrevious: PropTypes.func,
    // Expect (benefitGroupConfiguration) => any
    onNext: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      benefitGroupConfiguration: props.benefitGroupConfiguration || [
        {
          lfiBenefitGroup: '',
          lumityBenefitGroup: '',
          ignore: false
        }
      ],
      errors: {},
      errorMsgs: []
    };
  }

  onPrevious = () => {
    const { benefitGroupConfiguration } = this.state;
    const { onPrevious } = this.props;
    onPrevious(benefitGroupConfiguration);
  };

  addError(index, errorFieldName, errors) {
    return {
      ...errors,
      [index]: {
        ...errors[index],
        [errorFieldName]: true
      }
    };
  }

  onNext = () => {
    const { benefitGroupConfiguration } = this.state;
    let lfiBenefitGroups = [];
    let lumityBenefitGroups = [];
    let errors = {};
    let errorMsgs = [];
    let updatedBenefitGroupConfig = benefitGroupConfiguration.filter(
      ({ lfiBenefitGroup, lumityBenefitGroup, ignore }, index) => {
        let filterCheck = true;
        if (
          _.isEmpty(lfiBenefitGroup) &&
          _.isEmpty(lumityBenefitGroup) &&
          !ignore
        ) {
          return false;
        }
        if (_.isEmpty(lfiBenefitGroup)) {
          errors = this.addError(index, 'lfiBenefitGroup', errors);
          errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          filterCheck = false;
        } else {
          if (lfiBenefitGroups.includes(lfiBenefitGroup)) {
            errors = this.addError(index, 'lfiBenefitGroup', errors);
            errorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
            filterCheck = false;
          } else {
            lfiBenefitGroups.push(lfiBenefitGroup);
          }
        }
        if (_.isEmpty(lumityBenefitGroup) && !ignore) {
          errors = this.addError(index, 'lumityBenefitGroup', errors);
          errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          filterCheck = false;
        } else {
          if (lumityBenefitGroups.includes(lumityBenefitGroup)) {
            errors = this.addError(index, 'lumityBenefitGroup', errors);
            errorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
            filterCheck = false;
          } else {
            lumityBenefitGroups.push(lumityBenefitGroup);
          }
        }
        return filterCheck;
      }
    );
    if (_.isEmpty(errors)) {
      // set to null if it is empty array
      if (_.isEmpty(updatedBenefitGroupConfig)) {
        updatedBenefitGroupConfig = null;
      }
      this.props.onNext(updatedBenefitGroupConfig);
    } else {
      this.setState({
        errors,
        errorMsgs: _.uniq(errorMsgs)
      });
    }
  };

  addBenefitGroupRow = () => {
    this.setState({
      benefitGroupConfiguration: [
        ...this.state.benefitGroupConfiguration,
        {
          lfiBenefitGroup: '',
          lumityBenefitGroup: '',
          ignore: false
        }
      ]
    });
  };

  removeBenefitGroupRow = (removeIndex) => (e) => {
    const { benefitGroupConfiguration, errors } = this.state;
    const updatedErrors = { ...errors };
    const updatedBenefitGroupConfig = benefitGroupConfiguration.filter(
      (_benefitGroupConfig, index) => {
        return removeIndex !== index;
      }
    );
    if (updatedErrors[removeIndex]) {
      delete updatedErrors[removeIndex];
    }
    this.setState({
      benefitGroupConfiguration: updatedBenefitGroupConfig,
      errors: updatedErrors
    });
  };

  updateBenefitGroupRow = (updateIndex) => (e) => {
    const { name, value } = e.target;
    const { benefitGroupConfiguration, errors } = this.state;
    const updatedBenefitGroupConfig = [...benefitGroupConfiguration];
    if (e.target.type === 'checkbox') {
      updatedBenefitGroupConfig[updateIndex][name] = e.target.checked;
      if (
        e.target.checked &&
        errors[updateIndex] &&
        errors[updateIndex][['lumityBenefitGroup']]
      ) {
        delete errors[updateIndex]['lumityBenefitGroup'];
      }
    } else {
      updatedBenefitGroupConfig[updateIndex][name] = value;
      if (value && errors[updateIndex] && errors[updateIndex][name]) {
        delete errors[updateIndex][name];
      }
    }
    if (_.isEmpty(errors[updateIndex])) delete errors[updateIndex];
    this.setState({
      benefitGroupConfiguration: updatedBenefitGroupConfig
    });
  };

  renderBenefitGroupRows = () => {
    const { benefitGroupConfiguration, errors } = this.state;
    const { lumityBenefitGroups } = this.props;
    return benefitGroupConfiguration.map(
      ({ lfiBenefitGroup, lumityBenefitGroup, ignore }, index) => {
        return (
          <BenefitGroupRow
            key={index}
            index={index}
            lumityBenefitGroups={lumityBenefitGroups}
            lfiBenefitGroup={lfiBenefitGroup}
            lumityBenefitGroup={lumityBenefitGroup}
            ignore={ignore}
            removeBenefitGroupRow={this.removeBenefitGroupRow(index)}
            updateBenefitGroupRow={this.updateBenefitGroupRow(index)}
            fieldErrors={errors[index]}
          />
        );
      }
    );
  };

  render() {
    const { selectedVendor } = this.props;
    const { errors, errorMsgs } = this.state;
    return (
      <div className="step-container benefit-group-mapping__container">
        <div className="body">
          <p className="subtitle">{selectedVendor.name}</p>
          {!_.isEmpty(errors) &&
            errorMsgs.map((errorMsg, index) => (
              <Alert key={index} color="danger" className="error-alert">
                {errorMsg}
              </Alert>
            ))}
          {this.renderBenefitGroupRows()}
          <Row>
            <Button color="link" onClick={this.addBenefitGroupRow}>
              +Add Benefit Group Mapping
            </Button>
          </Row>
        </div>
        <Row className="action-btns">
          <Col>
            <Button
              className="form-element"
              outline
              color="primary"
              size="lg"
              onClick={this.onPrevious}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              className="form-element"
              color="primary"
              size="lg"
              onClick={this.onNext}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
