import { ADMIN, OPS, BC } from './roleConstants';
import {
  INTERNAL_ADMIN,
  LOCATION_ADMIN,
  PLATFORM_ADMIN
} from './loginTypeConstants';

/**
 * Navigation list is read-only privileged roles list.
 * If there are feature wise role privilegaes we need to add mapping for those features.
 * Other wise we are using common list
 * example: Employer module
 * ADMIN and OPS have Access to Actions in employer list page
 * but ADMIN, BC, OPS have access to actions in employer detail page,
 * ownership field is to pass login type of the user (INTERNAL_ADMIN, PLATFORM_ADMIN, EMPLOYER_ADMIN)
 * If ownership field is undefined, all login types have access to the feature.
 * role mapping:
 * EMPLOYER_FEATURE_ROLE_MAPPING = {
 *  navigation: [ADMIN, OPS, BC]
 *  common: [ADMIN, OPS, BC]
 *  list: [ADMIN, OPS]
 * }
 */

export const ADMINS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN]
};
export const BEN_GUIDE_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS, BC]
};
export const BILLING_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS]
};
export const CALENDER_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS, BC]
};
export const CARRIERS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS, BC]
};
export const DOCUMENTS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS, BC]
};
export const EMPLOYEES_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS, BC],
  changeUsername: [ADMIN],
  changeSsn: [ADMIN]
};
export const EMPLOYER_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS, BC],
  list: [ADMIN, OPS, BC],
  edit: [ADMIN, OPS, BC],
  notification: [ADMIN]
};
export const INSIGHTS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, BC]
};
export const INTEGRATIONS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  setup: [OPS, ADMIN],
  toggleFeature: [OPS, ADMIN],
  syncAll: [OPS, ADMIN],
  syncNow: [OPS, ADMIN],
  dailySync: [OPS, ADMIN],
  editConfig: [OPS, ADMIN],
  downloadErrorReport: [ADMIN, OPS, BC]
};

export const BENADMIN_INTEGRATIONS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC]
};

export const ISSUES_LOG_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, BC]
};
export const MOBILE_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS, BC]
};
export const CHATBOT_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS, BC]
};
export const PLANS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS]
};
export const RENEWALS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, BC]
};
export const SURVEYS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, BC]
};
export const SYNC_STATUS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN]
};
export const BENCHMARKS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS, BC]
};
export const OFFERS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, BC]
};

export const MOBILE_VERSION_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN]
};
export const BENEFITS_CONSULTATION_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, BC, OPS]
};
export const OE_MEETING_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS, BC]
};

export const SSO_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN]
};

export const ORGANIZATION_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN],
  ownership: [PLATFORM_ADMIN]
};

export const PLATFORM_ADMINS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, BC, OPS],
  common: [ADMIN],
  ownership: [PLATFORM_ADMIN]
};

export const LOCATIONS_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN],
  list: [ADMIN, OPS, BC]
};

export const FLOCK_CONFIGURATION_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS]
};

export const CURRENT_PLANS_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS, BC]
};

export const WORKDAY_BENADMIN_CONFIGURATION_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS]
};

export const PLATFORM_CONFIGURATION_FEATURE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS]
};
export const EMPLOYEES_REHIRE_ROLE_MAPPING = {
  navigation: [ADMIN, OPS, BC],
  common: [ADMIN, OPS, BC],
  rehireAction: [ADMIN, OPS]
};