/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
// TODO: Standardize all date formats
export const ID_CARD_DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_OF_BIRTH_FORMAT = 'MM/DD/YYYY';
export const DAILY_SYNC_DATE_FORMAT = 'M/D/YY @ h:mma';
export const DATE_FORMAT_COMMENT = 'MM/DD/YYYY';
export const TIME_FORMAT_COMMENT = 'h:mm A';
export const EXPORT_FILE_NAME_DATE_FORMAT = 'MM-DD-YYYY';
export const DATE_FORMAT_TOOLTIP = 'MM/DD/YY h:mma';
export const DATE_FORMAT_ISSUES_TABLE = 'MM/DD/YYYY h:mm A';

export const COMMON_DATE_FORMAT = 'MM/DD/YYYY';
export const FULL_DATE_FORMAT = 'lll';
