/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import ContentContainer from '../../../../../../containers/ContentContainer/ContentContainer';
import DataTableWithClientPagination from '../../../../../../components/DataTables/DataTable/DataTableWithClientPagination';
import FullscreenModal from 'components/FullscreenModal';
import AdapterService from 'services/AdapterService';

import './BenAdminResponse.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getBenAdminResponses } from '../../../../actions/employerActions';
import Notifications from 'components/Notifications';

class BenAdminResponse extends React.Component {
  componentDidUpdate(prevProps) {
    const { getBenAdminResponses } = this.props;
    if (!prevProps.isOpen && this.props.isOpen) {
      getBenAdminResponses(this.props.employerId);
    }
  }

  columnData = [
    {
      name: 'File Name',
      selector: 'key',
      cell: (row) => row.key.substring(row.key.lastIndexOf('/') + 1),
      sortable: false,
      wrap: true
    },
    {
      name: 'Action',
      cell: (row) => (
        <a
          color="blue"
          href={AdapterService.downloadBenAdminResponse(
            row.key,
            row.key.substring(row.key.lastIndexOf('/') + 1)
          )}
          onClick={() => {
            Notifications.success('Successfully Downloaded');
          }}
        >
          Download
        </a>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      maxWidth: '150px'
    }
  ];
  renderBenAdminHistoryForm = () => {
    const { benAdminResponses, inProgress } = this.props;
    return (
      <div className="hris-form">
        <ContentContainer>
          <DataTableWithClientPagination
            columnData={this.columnData}
            rowData={benAdminResponses}
            hideSearchBar={true}
            paginationPerPage="10"
            paginationRowsPerPageOptions={[10, 30, 50]}
            defaultSortField={'-lastModified'}
            fetching={inProgress}
          />
        </ContentContainer>
      </div>
    );
  };
  render() {
    const { isOpen, toggle } = this.props;
    return (
      <FullscreenModal isOpen={isOpen} toggle={toggle} title="BenAdmin Responses">
        <div className="hris-content">{this.renderBenAdminHistoryForm()}</div>
      </FullscreenModal>
    );
  }
}

export default connect(
  (state) => {
    const { employersReducer } = state.Employers;
    const { benAdminResponseData } = employersReducer;
    return {
      benAdminResponses: benAdminResponseData.data,
      benAdminResponsesError: benAdminResponseData.error,
      inProgress: benAdminResponseData.inProgress
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        getBenAdminResponses
      },
      dispatch
    )
)(BenAdminResponse);
