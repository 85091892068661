import axios from 'axios';
import Util from '../util/apiUtil';

const baseURL = `${Util.baseApi}/${Util.version}/offers`;

export default {
  findPlanContributionsForCurrentBundle(employerId, benefitCategory) {
    return axios.get(
      `${baseURL}/CURRENT/contributions?employerId=${employerId}&category=${benefitCategory}`
    );
  },

  saveProposals(id, bundle, isComplete) {
    return axios.put(
      `${baseURL}/${id}/contributions?isComplete=${isComplete}`,
      bundle
    );
  },
  saveOffer(bundle) {
    return axios.post(`${baseURL}`, bundle);
  },
  getBundleById(id) {
    return axios.get(`${baseURL}/${id}`);
  },
  updateOffer(bundle) {
    return axios.put(`${baseURL}/${bundle.id}`, bundle);
  },
  updatePlanContributions(bundleId, planContributions, applyAll) {
    return axios.put(
      `${baseURL}/${bundleId}/negotiated_contributions?applyToAllBundles=${applyAll}`,
      planContributions
    );
  },
  publishOffers(bundles) {
    return axios.put(`${baseURL}/publish`, bundles);
  }
};
