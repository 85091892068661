import { TextAreaFormGroup } from 'components/DataForm';
import React from 'react';
import { Component } from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { Switch } from '../../../components/Buttons';
class Message extends Component {
  render() {
    const {
      welcomeMessage,
      farewellMessage,
      fallbackOptions,
      onChange,
      bConsultationConfigStatus,
      switchHandler
    } = this.props;

    return (
      <>
        <Row className="message-row">
          <Col sm={2} className="message-label">
            <label className="mt-5 title">Welcome Message</label>
          </Col>
          <Col sm={8} className="message-text">
            <TextAreaFormGroup
              maxLength="350"
              className="text-area"
              inputName="welcomeMessage"
              id="welcomeMessage"
              inputValue={welcomeMessage}
              onChange={onChange}
            />
          </Col>
        </Row>
        <Row className="message-row">
          <Col sm={2} className="message-label">
            <label className="mt-5 title">Farewell Message</label>
          </Col>
          <Col sm={8} className="message-text">
            <TextAreaFormGroup
              maxLength="350"
              className="text-area"
              inputName="farewellMessage"
              inputValue={farewellMessage}
              id="farewellMessage"
              onChange={onChange}
            />
          </Col>
        </Row>
        <Row className="message-row">
          <Col sm={2} className="message-label">
            <label className="title">Fallback Option</label>
          </Col>
          <Col sm={8} className="message-text">
            <Row>
              <Col sm={6}>
                <Row>
                  <Col sm={1}>
                    <Switch
                      onChange={switchHandler}
                      checked={fallbackOptions.lumityConsultCompatible}
                      name="lumityConsultCompatible"
                      disabled={
                        bConsultationConfigStatus &&
                        bConsultationConfigStatus.value === 'false'
                      }
                    />
                  </Col>
                  <Col sm={9} className="consult-lumity-label">
                    Personal Benefits Consultation
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default Message;
