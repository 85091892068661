/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import { IssueList, CreateIssue, ViewIssue } from './index';

const NATIVE_ISSUE_LOG_PATH = '/brokers/:brokerId/issuelog';
const NATIVE_ISSUE_CREATE_PATH = '/brokers/:brokerId/issuelog/create';
const NATIVE_ISSUE_EDIT_PATH = '/brokers/:brokerId/issuelog/:issueId/edit';
const NATIVE_ISSUE_VIEW_PATH = '/brokers/:brokerId/issuelog/:issueId/view';

const nativeIssueRoutes = [
  {
    path: NATIVE_ISSUE_LOG_PATH,
    component: IssueList
  },
  { path: NATIVE_ISSUE_CREATE_PATH, component: CreateIssue },
  { path: NATIVE_ISSUE_EDIT_PATH, component: CreateIssue },
  { path: NATIVE_ISSUE_VIEW_PATH, component: ViewIssue }
];

export default nativeIssueRoutes;
export {
  NATIVE_ISSUE_CREATE_PATH,
  NATIVE_ISSUE_LOG_PATH,
  NATIVE_ISSUE_EDIT_PATH,
  NATIVE_ISSUE_VIEW_PATH
};
