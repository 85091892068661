/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
 import * as OrganisationPages from './index';

const BROKERS_LIST_PATH = '/brokers';
const ROOT_PATH = '/';
const BROKER_CREATE_PATH = '/broker/create';
const BROKER_EDIT_PATH = '/broker/:brokerId/edit';
const BROKER_VIEW_PATH = '/broker/:brokerId/view';

const brokerPathMapping = [
  { path: ROOT_PATH, component: OrganisationPages.OrganisationsList },
  { path: BROKERS_LIST_PATH, component: OrganisationPages.OrganisationsList },
  { path: BROKER_CREATE_PATH, component: OrganisationPages.CreateOrganization },
  { path: BROKER_EDIT_PATH, component: OrganisationPages.CreateOrganization },
  { path: BROKER_VIEW_PATH, component: OrganisationPages.CreateOrganization }
];

export default brokerPathMapping;

export {
  ROOT_PATH,
  BROKERS_LIST_PATH,
  BROKER_CREATE_PATH,
  BROKER_EDIT_PATH,
  BROKER_VIEW_PATH
};
