/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import { Row, Col, Label } from 'components/Atoms';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import FullscreenModal from 'components/FullscreenModal';
import { SelectFormGroup } from 'components/DataForm';
import PageActionButton from 'components/Buttons';
import Loader from 'components/CircularProgress';
import PopulateBenchmarkPlanDetails from './PopulateBenchmarkPlanDetails';

import './PopulatePlanDetailsModal.scss';

import { findBenchmarkPlan } from '../../actions/benchmarkActions';

import {
  TYPE_DEDUCTIBLE,
  TYPE_OOP_MAX,
  TYPE_COPAY,
  COINSURANCE,
  TYPE_RX_COST_TIER,
  ANNUAL_MAX_BENEFIT,
  ORTHODONTAL,
  ORTHODONTIAL_MAX,
  BENEFIT_KIND_DENTAL,
  BENEFIT_KIND_MEDICAL,
  BENEFIT_KIND_VISION,
  COPAY_EXAM,
  FRAME_ALLOWANCE,
  CONTACT_ALLOWANCE,
  COPAY_MATERIAL,
  FREQ_SERVICE
} from '../../constants';

import {
  getMetadata
} from '../../actions/benchmarkActions';
import BenchmarkService from 'services/BenchmarkService';

// Plan design sub types
const SUB_TYPE_SINGLE = 'single';
const SUB_TYPE_FAMILY = 'family';
const SUB_TYPE_PRIMARY = 'primary';
const SUB_TYPE_PREVENTATIVE = 'preventitive';
const SUB_TYPE_BASIC = 'basic';
const SUB_TYPE_SPECIALIST = 'specialist';
const SUB_TYPE_MAJOR = 'major';
const SUB_TYPE_EYE = 'eye';
const SUB_TYPE_FRAMES = 'frames';
const SUB_TYPE_LENSES = 'lenses';

class PopulatePlanDetailsModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      metadata: {},
      selectedBenchmarkPlan: {},
      benchmarkPlanDetails: [],
      selectedEmployer: null,
      selectedCarrier: null,
      selectedPlan: null,
      searchCriteria: {},
      deductibleType: SUB_TYPE_SINGLE,
      oopMaxType: SUB_TYPE_SINGLE,
      copayType: SUB_TYPE_PRIMARY,
      coinsuranceType: SUB_TYPE_PREVENTATIVE,
      tierType: 'tier 1',
      freqOfServices: 'eye'
    };
  }

  componentDidUpdate(prevProps) {
    const { metadata, benchmarkPlans, isOpen, benefitCategory, planType, getMetadata } = this.props;

    if (metadata !== prevProps.metadata) {
      this.setState({
        metadata: { ...metadata }
      });
    }

    if (benchmarkPlans !== prevProps.benchmarkPlans) {
      this.setState({
        benchmarkPlanDetails: benchmarkPlans
      });
    }

    if (isOpen && !prevProps.isOpen) {
      this.searchBenchmarkPlans();
      getMetadata(benefitCategory.toUpperCase(), planType);
    }
  }

  componentDidMount() {
  }

  resetContext = () => {
    this.setState({
      selectedBenchmarkPlan: {},
      benchmarkPlanDetails: [],
      selectedEmployer: null,
      selectedCarrier: null,
      selectedPlan: null,
      searchCriteria: {}
    });
  };

  attachCarriers = (metadata) => {
    let carriers = [];
    if (metadata.carriers) {
      _.forEach(metadata.carriers, function(carrier) {
        carriers.push({
          value: carrier,
          text: carrier
        });
      });
    }
    return carriers;
  };

  attachEmployers = (metadata) => {
    let employers = [];
    if (metadata.employers) {
      let sortedEmployers = _.sortBy(_.toPairs(metadata.employers), 1);
      _.forEach(sortedEmployers, (value) => {
        employers.push({
          value: value[0],
          text: value[1]
        });
      });
    }
    return employers;
  };

  attachPlans = () => {
    let plans = [];
    const { benchmarkPlanDetails } = this.state;
    if (!_.isEmpty(benchmarkPlanDetails)) {
      benchmarkPlanDetails.forEach((benchmarkPlan) => {
        plans.push({
          value: benchmarkPlan.id,
          text: benchmarkPlan.planName
        });
      });
    }
    return plans;
  };

  attachPlanDesignAttribute = (planDesignType, metadata, prefix = '$') => {
    const { benefitCategory, planType } = this.props;
    let selectedAttributes = [];
    if (metadata && metadata.ranges && !_.isEmpty(metadata.ranges)) {
      let planDesignAttributes = _.filter(
        metadata.ranges,
        (range) =>
          range.fieldInfo.field === planDesignType &&
          range.fieldInfo.benefitCategory === benefitCategory.toUpperCase() &&
          range.fieldInfo.planType === planType &&
          range.fieldInfo.subtype ===
            this.getPlanDesignSubType(planDesignType, benefitCategory)
      );
      if (planDesignAttributes && planDesignAttributes.length > 0) {
        planDesignAttributes.forEach((planDesignAttribute) => {
          selectedAttributes.push(
            this.formatDisplayRange(planDesignAttribute, prefix)
          );
        });
      }
    }
    return selectedAttributes;
  };

  formatDisplayRange = (planDesignAttribute, prefix) => {
    const { startRange, endRange } = planDesignAttribute;

    if (endRange !== null) {
      return {
        value: JSON.stringify({ startRange: startRange, endRange: endRange }),
        text: `${prefix === '$' ? prefix : ''}${startRange}${
          prefix === '$' ? '' : prefix
        } - ${prefix === '$' ? prefix : ''}${endRange}${
          prefix === '$' ? '' : prefix
        }`
      };
    } else {
      return {
        value: JSON.stringify({ startRange: startRange, endRange: null }),
        text: `${prefix === '$' ? prefix : ''}${startRange}${
          prefix === '$' ? '' : prefix
        }${prefix === '%' ? '' : '+'}`
      };
    }
  };

  getPlanDesignSubType = (planDesignType, benefitCategory) => {
    switch (planDesignType) {
      case TYPE_DEDUCTIBLE:
        return this.state.deductibleType;
      case TYPE_OOP_MAX:
        return this.state.oopMaxType;
      case TYPE_COPAY:
        return this.state.copayType;
      case COINSURANCE:
        return benefitCategory === BENEFIT_KIND_DENTAL
          ? this.state.coinsuranceType
          : null;
      case TYPE_RX_COST_TIER:
        return this.getRxTierTypeBasedOnSelection(this.state.tierType);
      case FREQ_SERVICE:
        return this.state.freqOfServices;
      default:
        return null;
    }
  };

  switchPlanDesignType = (planDesignType) => {
    const { searchCriteria: currentSearchCriteria = {} } = this.state;
    const searchCriteria = { ...currentSearchCriteria };
    switch (planDesignType) {
      case TYPE_DEDUCTIBLE:
        this.setState((prevState) => ({
          deductibleType:
            prevState.deductibleType === SUB_TYPE_SINGLE
              ? SUB_TYPE_FAMILY
              : SUB_TYPE_SINGLE
        }));
        delete searchCriteria.deductible;
        break;
      case TYPE_OOP_MAX:
        this.setState((prevState) => ({
          oopMaxType:
            prevState.oopMaxType === SUB_TYPE_SINGLE
              ? SUB_TYPE_FAMILY
              : SUB_TYPE_SINGLE
        }));
        delete searchCriteria.oopMax;
        break;
      case TYPE_COPAY:
        this.setState((prevState) => ({
          copayType:
            prevState.copayType === SUB_TYPE_PRIMARY
              ? SUB_TYPE_SPECIALIST
              : SUB_TYPE_PRIMARY
        }));
        delete searchCriteria.copay;
        break;
      case COINSURANCE:
        this.setState((prevState) => ({
          coinsuranceType: this.getCoinsuranceTypeBasedOnSelection(
            prevState.coinsuranceType
          )
        }));

        delete searchCriteria.coinsurance;
        break;
      case TYPE_RX_COST_TIER:
        this.setState((prevState) => ({
          tierType: this.getSpecificRxTierType(prevState.tierType)
        }));
        delete searchCriteria.rxCost;
        delete searchCriteria.orthodontalMax;
        break;
      case FREQ_SERVICE:
        this.setState((prevState) => ({
          freqOfServices: this.getFreServiceBasedOnSelection(
            prevState.freqOfServices
          )
        }));
        delete searchCriteria.freqOfServices;
        break;
      default:
      // Do nothing
    }

    this.setState({ searchCriteria: searchCriteria });
  };

  getFreServiceBasedOnSelection = (type) => {
    if (type === SUB_TYPE_EYE) {
      return SUB_TYPE_FRAMES;
    } else if (type === SUB_TYPE_FRAMES) {
      return SUB_TYPE_LENSES;
    } else {
      return SUB_TYPE_EYE;
    }
  };

  getCoinsuranceTypeBasedOnSelection = (type) => {
    switch (type) {
      case SUB_TYPE_PREVENTATIVE:
        return SUB_TYPE_BASIC;
      case SUB_TYPE_BASIC:
        return SUB_TYPE_MAJOR;
      default:
        return SUB_TYPE_PREVENTATIVE;
    }
  };

  getRxTierTypeBasedOnSelection = (tierType) => {
    if (tierType === 'tier 1') {
      return 't1';
    } else if (tierType === 'tier 2') {
      return 't2';
    } else if (tierType === 'tier 3') {
      return 't3';
    } else if (tierType === 'tier 4') {
      return 't3';
    }
  };

  getSpecificRxTierType = (tierType) => {
    if (tierType === 'tier 1') {
      return 'tier 2';
    } else if (tierType === 'tier 2') {
      return 'tier 3';
    } else if (tierType === 'tier 3') {
      return 'tier 4';
    } else if (tierType === 'tier 4') {
      return 'tier 1';
    }
  };

  downloadPlansFullList = () => {
    const { benefitCategory } = this.props;
    window.location.href = BenchmarkService.getPlansListDownloadUrl(
      benefitCategory
    );
  };

  onChangeEmployerAndCarrier = (event) => {
    const { value, name } = event.target;
    if (value) {
      this.setState(
        (prevState) => ({
          searchCriteria: { ...prevState.searchCriteria, [name]: value }
        }),
        () => {
          this.searchBenchmarkPlans();
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          searchCriteria: { ...prevState.searchCriteria, [name]: undefined }
        }),
        () => {
          this.searchBenchmarkPlans();
        }
      );
    }
  };

  onChangePlanDesignAttribute = (event, planDesignAttributeType) => {
    const { value } = event.target;
    const { benefitCategory } = this.props;
    const { tierType } = this.state;
    switch (planDesignAttributeType) {
      case TYPE_DEDUCTIBLE:
        const { deductibleType } = this.state;
        this.updateSearchCriteria(
          TYPE_DEDUCTIBLE,
          'deductible',
          deductibleType,
          value
        );
        break;
      case TYPE_OOP_MAX:
        const { oopMaxType } = this.state;
        this.updateSearchCriteria(TYPE_OOP_MAX, 'oopMax', oopMaxType, value);
        break;
      case TYPE_COPAY:
        const { copayType } = this.state;
        this.updateSearchCriteria(TYPE_COPAY, 'copay', copayType, value);
        break;
      case COINSURANCE:
        const { coinsuranceType } = this.state;
        this.updateSearchCriteria(
          COINSURANCE,
          'coinsurance',
          benefitCategory === BENEFIT_KIND_MEDICAL ? null : coinsuranceType,
          value
        );
        break;
      case TYPE_RX_COST_TIER:
        this.updateSearchCriteria(
          TYPE_RX_COST_TIER,
          'rxCost',
          this.getRxTierTypeBasedOnSelection(tierType),
          value
        );
        break;
      case ANNUAL_MAX_BENEFIT:
        let typeAnnualMax = 'annualMaxBenefit';
        this.updateSearchCriteria(
          ANNUAL_MAX_BENEFIT,
          typeAnnualMax,
          null,
          value
        );
        break;
      case ORTHODONTAL:
        let typeOrthodontal = 'orthodontal';
        this.updateSearchCriteria(ORTHODONTAL, typeOrthodontal, null, value);
        break;
      case ORTHODONTIAL_MAX:
        let typeOrthodontalMax = 'orthodontalMax';
        this.updateSearchCriteria(
          ORTHODONTIAL_MAX,
          typeOrthodontalMax,
          null,
          value
        );
        break;
      case COPAY_EXAM:
        let typeCopayExam = 'copayExam';
        this.updateSearchCriteria(COPAY_EXAM, typeCopayExam, null, value);
        break;
      case COPAY_MATERIAL:
        let typeCopayMaterial = 'copayMaterial';
        this.updateSearchCriteria(
          COPAY_MATERIAL,
          typeCopayMaterial,
          null,
          value
        );
        break;
      case FRAME_ALLOWANCE:
        let typeFramesAllowance = 'framesAllowance';
        this.updateSearchCriteria(
          FRAME_ALLOWANCE,
          typeFramesAllowance,
          null,
          value
        );
        break;
      case CONTACT_ALLOWANCE:
        let typeContactAllowance = 'contactAllowance';
        this.updateSearchCriteria(
          CONTACT_ALLOWANCE,
          typeContactAllowance,
          null,
          value
        );
        break;
      case FREQ_SERVICE:
        const { freqOfServices } = this.state;
        let typeFreqOfServices = 'freqOfServices';
        this.updateSearchCriteria(
          FREQ_SERVICE,
          typeFreqOfServices,
          freqOfServices,
          value
        );
        break;
      default:
      // Do nothing
    }
  };

  updateSearchCriteria = (
    mainType,
    planDesignTypeKey,
    planDesignType,
    value
  ) => {
    if (value) {
      this.setState(
        (prevState) => ({
          searchCriteria: {
            ...prevState.searchCriteria,
            [planDesignTypeKey]: {
              display: value,
              api: this.buildPlanDesignAttribute(
                value,
                mainType,
                planDesignType
              )
            }
          }
        }),
        () => {
          this.searchBenchmarkPlans();
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          searchCriteria: {
            ...prevState.searchCriteria,
            [planDesignTypeKey]: undefined
          }
        }),
        () => {
          this.searchBenchmarkPlans();
        }
      );
    }
  };

  buildPlanDesignAttribute = (value, mainType, planDesignSubType) => {
    const { startRange, endRange } = JSON.parse(value);

    if (planDesignSubType) {
      let planDesignAttribute = {
        [mainType]: {
          [planDesignSubType]: {
            startRange: startRange,
            endRange: endRange
          }
        }
      };
      return planDesignAttribute;
    }
    let planDesignAttribute = {
      [mainType]: {
        none: {
          startRange: startRange,
          endRange: endRange
        }
      }
    };
    return planDesignAttribute;
  };

  onChangePlan = (event) => {
    const { value } = event.target;
    if (value) {
      const { benchmarkPlanDetails } = this.state;
      const filteredPlan = _.filter(
        benchmarkPlanDetails,
        (plan) => plan.id === value
      );
      if (filteredPlan && filteredPlan.length > 0) {
        const { weights } = this.props;
        filteredPlan[0]['weights'] = weights;
        this.setState({
          selectedBenchmarkPlan: filteredPlan[0],
          selectedPlan: value
        });
      }
    } else {
      this.setState({
        selectedBenchmarkPlan: {},
        selectedPlan: null
      });
    }
  };

  searchBenchmarkPlans = () => {
    const { findBenchmarkPlan, planType, benefitCategory } = this.props;

    // clear values, and set defaults before search
    this.setState(
      (prevState) => ({
        selectedPlan: null,
        selectedBenchmarkPlan: {},
        searchCriteria: {
          ...prevState.searchCriteria,
          planType: planType,
          benefitCategory: benefitCategory.toUpperCase()
        }
      }),
      () => {
        const { searchCriteria } = this.state;
        findBenchmarkPlan(searchCriteria);
      }
    );
  };

  isSubmitEnabled = () => {
    return _.isEmpty(this.state.selectedBenchmarkPlan);
  };

  render() {
    const {
      isOpen,
      toggle,
      selectedImportData,
      metadata,
      onSelectBenchmarkPlan,
      planKey,
      title,
      benefitCategory,
      benchmarkPlansLoading,
      planType
    } = this.props;

    const {
      selectedBenchmarkPlan,
      selectedPlan,
      deductibleType,
      oopMaxType,
      copayType,
      coinsuranceType,
      tierType,
      searchCriteria,
      freqOfServices
    } = this.state;

    return (
      <div>
        <FullscreenModal
          modelClassName="populate-plan-detail-modal"
          isOpen={isOpen}
          toggle={toggle}
          onClosed={this.resetContext}
          title={title}
          closeText={selectedImportData ? 'close' : 'cancel'}
        >
        <Row className="full-plans-list-text">
          <div>
            Available benchmark plans are a curated subset of system plans. See the full list&nbsp;
            <span
              className="benchmark-btn-link similar-plans-view"
              onClick={this.downloadPlansFullList}
            >
              here
            </span>
          </div>
        </Row>
          <div className="populate-plan-detail-selection">
            <Row className="no-row-margin">
              <Col xs={3} className="col-3-populate-plans">
                <SelectFormGroup
                  labelDisplay="Carrier"
                  inputName="carrier"
                  inputValue={searchCriteria.carrier}
                  options={this.attachCarriers(metadata)}
                  onChange={this.onChangeEmployerAndCarrier}
                />
              </Col>
              <Col xs={3} className="col-3-populate-plans">
                <SelectFormGroup
                  labelDisplay="Employer"
                  inputName="employer"
                  inputValue={searchCriteria.employer}
                  options={this.attachEmployers(metadata)}
                  onChange={this.onChangeEmployerAndCarrier}
                />
              </Col>
            </Row>
            <Row>
              <>
                {(benefitCategory === BENEFIT_KIND_MEDICAL ||
                  benefitCategory === BENEFIT_KIND_DENTAL) && (
                  <>
                    <Col>
                      <Label
                        className="populate-plan-category-type"
                        color="link"
                        onClick={() =>
                          this.switchPlanDesignType(TYPE_DEDUCTIBLE)
                        }
                      >
                        {deductibleType}
                      </Label>
                      <SelectFormGroup
                        labelDisplay="Deductible"
                        inputName="deductible"
                        inputValue={
                          searchCriteria.deductible &&
                          searchCriteria.deductible.display
                        }
                        options={this.attachPlanDesignAttribute(
                          TYPE_DEDUCTIBLE,
                          metadata
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(
                            event,
                            TYPE_DEDUCTIBLE
                          );
                        }}
                      />
                    </Col>
                  </>
                )}
              </>
              <>
                {benefitCategory === BENEFIT_KIND_MEDICAL && (
                  <>
                    <Col>
                      <Label
                        className="populate-plan-category-type"
                        color="link"
                        onClick={() => this.switchPlanDesignType(TYPE_OOP_MAX)}
                      >
                        {oopMaxType}
                      </Label>
                      <SelectFormGroup
                        labelDisplay="OOP Max"
                        inputName="oopmax"
                        inputValue={
                          searchCriteria.oopMax && searchCriteria.oopMax.display
                        }
                        options={this.attachPlanDesignAttribute(
                          TYPE_OOP_MAX,
                          metadata
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(event, TYPE_OOP_MAX);
                        }}
                      />
                    </Col>
                    <Col>
                      <Label
                        className="populate-plan-category-type"
                        color="link"
                        onClick={() => this.switchPlanDesignType(TYPE_COPAY)}
                      >
                        {copayType}
                      </Label>
                      <SelectFormGroup
                        labelDisplay="Copay"
                        inputName="copay"
                        inputValue={
                          searchCriteria.copay && searchCriteria.copay.display
                        }
                        options={this.attachPlanDesignAttribute(
                          TYPE_COPAY,
                          metadata
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(event, TYPE_COPAY);
                        }}
                      />
                    </Col>
                    <Col>
                      <SelectFormGroup
                        labelDisplay="Coinsurance"
                        inputName="coinsurance"
                        inputValue={
                          searchCriteria.coinsurance &&
                          searchCriteria.coinsurance.display
                        }
                        options={this.attachPlanDesignAttribute(
                          COINSURANCE,
                          metadata,
                          '%'
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(event, COINSURANCE);
                        }}
                      />
                    </Col>
                    <Col>
                      <Label
                        className="populate-plan-category-type"
                        color="link"
                        onClick={() =>
                          this.switchPlanDesignType(TYPE_RX_COST_TIER)
                        }
                      >
                        {tierType}
                      </Label>
                      <SelectFormGroup
                        labelDisplay="Rx Cost"
                        inputName="rxcost"
                        inputValue={
                          searchCriteria.rxCost && searchCriteria.rxCost.display
                        }
                        options={this.attachPlanDesignAttribute(
                          TYPE_RX_COST_TIER,
                          metadata
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(
                            event,
                            TYPE_RX_COST_TIER
                          );
                        }}
                      />
                    </Col>
                  </>
                )}
              </>
              <>
                {benefitCategory === BENEFIT_KIND_DENTAL && (
                  <>
                    <Col>
                      <SelectFormGroup
                        labelDisplay="Annual Max"
                        inputName="annualMax"
                        inputValue={
                          searchCriteria.annualMaxBenefit &&
                          searchCriteria.annualMaxBenefit.display
                        }
                        options={this.attachPlanDesignAttribute(
                          ANNUAL_MAX_BENEFIT,
                          metadata
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(
                            event,
                            ANNUAL_MAX_BENEFIT
                          );
                        }}
                      />
                    </Col>
                    <Col>
                      <Label
                        className="populate-plan-category-type"
                        color="link"
                        onClick={() => this.switchPlanDesignType(COINSURANCE)}
                      >
                        {coinsuranceType}
                      </Label>
                      <SelectFormGroup
                        labelDisplay="Coinsurance"
                        inputName="coinsurance"
                        inputValue={
                          searchCriteria.coinsurance &&
                          searchCriteria.coinsurance.display
                        }
                        options={this.attachPlanDesignAttribute(
                          COINSURANCE,
                          metadata,
                          '%'
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(event, COINSURANCE);
                        }}
                      />
                    </Col>
                    <Col>
                      <SelectFormGroup
                        labelDisplay="Orthodontia Coinsurance"
                        inputName="orthodontia"
                        inputValue={
                          searchCriteria.orthodontal &&
                          searchCriteria.orthodontal.display
                        }
                        options={this.attachPlanDesignAttribute(
                          ORTHODONTAL,
                          metadata,
                          '%'
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(event, ORTHODONTAL);
                        }}
                      />
                    </Col>
                    <Col>
                      <SelectFormGroup
                        labelDisplay="Orth Max Benefits"
                        inputName="orthMaxBenefits"
                        inputValue={
                          searchCriteria.orthodontalMax &&
                          searchCriteria.orthodontalMax.display
                        }
                        options={this.attachPlanDesignAttribute(
                          ORTHODONTIAL_MAX,
                          metadata
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(
                            event,
                            ORTHODONTIAL_MAX
                          );
                        }}
                      />
                    </Col>
                  </>
                )}
              </>
              <>
                {benefitCategory === BENEFIT_KIND_VISION && (
                  <>
                    <Col>
                      <SelectFormGroup
                        labelDisplay="Copay Exam"
                        inputName="copayExam"
                        inputValue={
                          searchCriteria.copayExam &&
                          searchCriteria.copayExam.display
                        }
                        options={this.attachPlanDesignAttribute(
                          COPAY_EXAM,
                          metadata
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(event, COPAY_EXAM);
                        }}
                      />
                    </Col>
                    <Col>
                      <SelectFormGroup
                        labelDisplay="Copay Material"
                        inputName="copayMaterial"
                        inputValue={
                          searchCriteria.copayMaterial &&
                          searchCriteria.copayMaterial.display
                        }
                        options={this.attachPlanDesignAttribute(
                          COPAY_MATERIAL,
                          metadata
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(
                            event,
                            COPAY_MATERIAL
                          );
                        }}
                      />
                    </Col>
                    <Col>
                      <SelectFormGroup
                        labelDisplay="Frames Allowance"
                        inputName="framesAllowance"
                        inputValue={
                          searchCriteria.framesAllowance &&
                          searchCriteria.framesAllowance.display
                        }
                        options={this.attachPlanDesignAttribute(
                          FRAME_ALLOWANCE,
                          metadata
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(
                            event,
                            FRAME_ALLOWANCE
                          );
                        }}
                      />
                    </Col>
                    <Col>
                      <SelectFormGroup
                        labelDisplay="Contact Allowance"
                        inputName="contactAllowance"
                        inputValue={
                          searchCriteria.contactAllowance &&
                          searchCriteria.contactAllowance.display
                        }
                        options={this.attachPlanDesignAttribute(
                          CONTACT_ALLOWANCE,
                          metadata
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(
                            event,
                            CONTACT_ALLOWANCE
                          );
                        }}
                      />
                    </Col>
                    <Col>
                      <Label
                        className="populate-plan-category-type"
                        color="link"
                        onClick={() => this.switchPlanDesignType(FREQ_SERVICE)}
                      >
                        {freqOfServices}
                      </Label>
                      <SelectFormGroup
                        labelDisplay="Freq. of Services"
                        inputName="freqOfServices"
                        inputValue={
                          searchCriteria.freqOfServices &&
                          searchCriteria.freqOfServices.display
                        }
                        options={this.attachPlanDesignAttribute(
                          FREQ_SERVICE,
                          metadata,
                          ''
                        )}
                        onChange={(event) => {
                          this.onChangePlanDesignAttribute(event, FREQ_SERVICE);
                        }}
                      />
                    </Col>
                  </>
                )}
              </>
            </Row>
          </div>
          {benchmarkPlansLoading ? (
            <Loader />
          ) : (
            <>
              <div className="populate-plan-selection">
                <Row className="no-row-margin">
                  <Col xs={3} className="col-3-populate-plans">
                    <Label
                        className="available-plns-count"
                      >
                        ({this.attachPlans().length} plans are available)
                      </Label>
                    <SelectFormGroup
                      labelDisplay="Plans"
                      inputName="plans"
                      inputValue={selectedPlan}
                      options={this.attachPlans()}
                      onChange={this.onChangePlan}
                    />
                  </Col>
                </Row>
              </div>
              {populateBenchmarkPlanName(selectedBenchmarkPlan)}
              <PopulateBenchmarkPlanDetails
                selectedBenchmarkPlan={selectedBenchmarkPlan}
                benefitCategory={benefitCategory}
                planType={planType}
              />
              <div className="populate-plan-action-buttons">
                <Row className="populate-plan-action-buttons-row">
                  <Col xs={4}>
                    <PageActionButton onClick={() => toggle()} outline>
                      Cancel
                    </PageActionButton>
                  </Col>
                  <Col xs={4}>
                    <PageActionButton
                      onClick={() => {
                        onSelectBenchmarkPlan(selectedBenchmarkPlan, planKey);
                        toggle();
                      }}
                      disabled={this.isSubmitEnabled()}
                    >
                      Select
                    </PageActionButton>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </FullscreenModal>
      </div>
    );
  }
}

const populateBenchmarkPlanName = (selectedBenchmarkPlan) => {
  if (!_.isEmpty(selectedBenchmarkPlan)) {
    return (
      <div className="populate-plan-name">
        <Row>
          <Col>
            <Label className="populate-plan-name-text">
              {`${selectedBenchmarkPlan.employerName} ${selectedBenchmarkPlan.planName}`}
            </Label>
          </Col>
        </Row>
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch) => ({
  findBenchmarkPlan: (searchCriteria) =>
    dispatch(findBenchmarkPlan(searchCriteria)),
  getMetadata: (benefitCategory, planType) => 
    dispatch(getMetadata(benefitCategory, planType))
});

const mapStateToProps = (state) => {
  const {
    metadata,
    benchmarkPlans,
    benchmarkPlansLoading
  } = state.benchmarkReducer;

  return {
    metadata,
    benchmarkPlans,
    benchmarkPlansLoading
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopulatePlanDetailsModal);
