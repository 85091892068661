import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { Button, Table } from 'components/Atoms';
import * as BenchmarkRoutes from '../../../routes';
import { generatePath } from 'react-router-dom';

import './Preview.scss';
import { isNumber } from 'util';
import EditBenchmarkModal from './components/EditBenchmarkModal/EditBenchmarkModal';

export const VALIDATION_PASSED = 'COMPLETE';
export const VALIDATION_SKIPPED = 'SKIPPED';
export const VALIDATION_INCOMPLETE = 'INCOMPLETE';

class ValidationTable extends React.Component {
  constructor(props) {
    super(props);
    const { employerId, benchmarkId } = this.props;

    this.state = {
      employerId: employerId,
      benchmarkId: benchmarkId,
      isEditBenchmarkModalOpen: false,
      title: '',
      benefitCategory: ''
    };
  }

  toggleEditBenchmarkModalOpen = () => {
    this.setState((prevState) => ({
      isEditBenchmarkModalOpen: !prevState.isEditBenchmarkModalOpen
    }));
  };

  setEditModalProperties = (title, benefitCategory) => {
    this.setState({
      title: title,
      benefitCategory: benefitCategory
    });
  };

  getPlanCount(benefitCategory) {
    const { benchmark } = this.props;
    if (benchmark.benchmarks && benchmark.benchmarks[benefitCategory]) {
      let planCount = _.countBy(
        benchmark.benchmarks[benefitCategory],
        (benchmark) => {
          return !_.isEmpty(benchmark.benchmarkPlan);
        }
      );
      return planCount.true;
    }
    return 0;
  }

  getCensusStatus() {
    const { benchmark, benefitCategoryValidationResult } = this.props;
    if (
      benchmark &&
      benchmark.censusInfo &&
      Object.values(benchmark.censusInfo).some((x) => x !== null)
    ) {
      benefitCategoryValidationResult['CENSUS_INFO'] = VALIDATION_PASSED;
      return VALIDATION_PASSED;
    }
  }

  getBenefitCategoryStatus(benefitCategory) {
    const { benchmark, benefitCategoryValidationResult } = this.props;
    if (
      (benchmark &&
        benchmark.currentPlans === undefined &&
        _.isEmpty(benchmark.currentPlans)) ||
      _.isEmpty(benchmark.currentPlans[benefitCategory])
    ) {
      benefitCategoryValidationResult[benefitCategory] = VALIDATION_SKIPPED;
      return VALIDATION_SKIPPED;
    }

    if (
      (benchmark &&
        benchmark.currentPlans &&
        benchmark.currentPlans[benefitCategory] &&
        benchmark.currentPlans[benefitCategory].length !==
          benchmark.benchmarks[benefitCategory].length) ||
      (!_.isEmpty(benchmark.benchmarks[benefitCategory]) &&
        benchmark.benchmarks[benefitCategory].some(
          (b) => b.benchmarkPlan === null
        ))
    ) {
      benefitCategoryValidationResult[benefitCategory] = VALIDATION_INCOMPLETE;
      return VALIDATION_INCOMPLETE;
    } else {
      benefitCategoryValidationResult[benefitCategory] = VALIDATION_PASSED;
      return VALIDATION_PASSED;
    }
  }

  goToCensus = () => {
    const { match, history } = this.props;
    const { employerId, benchmarkId, brokerId } = match.params;
    history.push(
      generatePath(BenchmarkRoutes.BENCHMARKS_EDIT_CENSUS_PATH, {
        brokerId,
        employerId,
        benchmarkId
      })
    );
  };

  render() {
    const {
      isEditBenchmarkModalOpen,
      title,
      benefitCategory,
      benchmarkId
    } = this.state;

    const { benchmark, isReadOnly } = this.props;

    return (
      <div>
        <Table className="validation-table">
          <thead className="validation-table-head">
            <tr>
              <th className="validation-table-head-col wf-175">Status</th>
              <th className="validation-table-head-col">Benchmarks Content</th>
              <th className="validation-table-head-col text-center">
                #Benchmark Plans
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ValidationTableRow
              status={this.getCensusStatus()}
              title="Census Information"
              numberOfPlans={0}
              isReadOnlyView={isReadOnly}
              onClick={this.goToCensus}
              onPropertyChange={this.setEditModalProperties}
              isCensus={true}
            />
            <ValidationTableRow
              status={this.getBenefitCategoryStatus('MEDICAL')}
              title="Plans - Medical"
              numberOfPlans={this.getPlanCount('MEDICAL')}
              isReadOnlyView={
                this.getBenefitCategoryStatus('MEDICAL') ===
                  VALIDATION_SKIPPED || isReadOnly
              }
              onClick={this.toggleEditBenchmarkModalOpen}
              onPropertyChange={this.setEditModalProperties}
              benefitCategory={'medical'}
            />
            <ValidationTableRow
              status={this.getBenefitCategoryStatus('DENTAL')}
              title="Plans - Dental"
              numberOfPlans={this.getPlanCount('DENTAL')}
              isReadOnlyView={
                this.getBenefitCategoryStatus('DENTAL') ===
                  VALIDATION_SKIPPED || isReadOnly
              }
              onClick={this.toggleEditBenchmarkModalOpen}
              onPropertyChange={this.setEditModalProperties}
              benefitCategory={'dental'}
            />
            <ValidationTableRow
              status={this.getBenefitCategoryStatus('VISION')}
              title="Plans - Vision"
              numberOfPlans={this.getPlanCount('VISION')}
              isReadOnlyView={
                this.getBenefitCategoryStatus('VISION') ===
                  VALIDATION_SKIPPED || isReadOnly
              }
              onClick={this.toggleEditBenchmarkModalOpen}
              onPropertyChange={this.setEditModalProperties}
              benefitCategory={'vision'}
            />
          </tbody>
        </Table>
        <EditBenchmarkModal
          isOpen={isEditBenchmarkModalOpen}
          title={title}
          benefitCategory={benefitCategory}
          status={this.getBenefitCategoryStatus(benefitCategory.toUpperCase())}
          benchmark={benchmark}
          toggle={() =>
            this.toggleEditBenchmarkModalOpen(title, benefitCategory)
          }
          benchmarkId={benchmarkId}
        />
      </div>
    );
  }
}

const ValidationTableRow = (props) => {
  const {
    status,
    numberOfPlans,
    onClick,
    isReadOnlyView,
    title,
    benefitCategory,
    onPropertyChange,
    isCensus
  } = props;

  const styleClass =
    status === VALIDATION_INCOMPLETE ? 'validation-error-row' : '';
  return (
    <tr className={styleClass}>
      <td className="validation-table-row wf-175">
        <Status status={status} />
      </td>
      <td className="benchmark-content-text validation-table-row">{title}</td>
      <td className="validation-table-row text-center">
        {isNumber(numberOfPlans) && numberOfPlans !== 0 ? (
          numberOfPlans
        ) : (
          <>&mdash;</>
        )}
      </td>
      <td className="validation-table-row text-right">
        {!isReadOnlyView && !isCensus ? (
          <Button
            color="link"
            onClick={() => {
              onClick();
              onPropertyChange(title, benefitCategory);
            }}
          >
            Edit
          </Button>
        ) : (
          !isReadOnlyView && (
            <Button color="link" onClick={() => onClick()}>
              Edit
            </Button>
          )
        )}
      </td>
    </tr>
  );
};

const Status = (props) => {
  return (
    <>
      {props.status === VALIDATION_SKIPPED && (
        <div className="validation-status disabled">
          <span>SKIPPED</span>
        </div>
      )}
      {props.status === VALIDATION_PASSED && (
        <div className="validation-status passed">
          <span>PASSED</span>
        </div>
      )}
      {props.status === VALIDATION_INCOMPLETE && (
        <div className="validation-status incomplete">
          <span>INCOMPLETE</span>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => {
  const { benchmark } = state.benchmarkReducer;

  return {
    benchmark
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationTable);
