/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
const RETIREMENT_LIST_PATH =
  '/brokers/:brokerId/employers/:employerId/retirement-plans';
const RETIREMENT_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/retirement-plans/create';
const RETIREMENT_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/retirement-plans/:planId/edit';
const RETIREMENT_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/retirement-plans/:planId/view';
  const RETIREMENT_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/retirement-plans/:planId/clone';

export {
  RETIREMENT_LIST_PATH,
  RETIREMENT_CREATE_PATH,
  RETIREMENT_EDIT_PATH,
  RETIREMENT_VIEW_PATH,
  RETIREMENT_CLONE_PATH
};
