import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Icon from 'components/Icons';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'components/Atoms';
import { getPlanYearNameFilter } from '../../../util/planServiceUtil';
import { sortPlanYears } from 'util/PlanUtil';
import './PlanYearFilter.scss';

class PlanYearFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: false,
      selectedItems: []
    };
  }

  componentDidMount() {
    const { selectedPlanYearIds } = this.props;
    if (!_.isEmpty(selectedPlanYearIds)) {
      this.setState({ selectedItems: selectedPlanYearIds });
    }
  }

  updateTypeSelection = (value) => {
    const currentItems = [...this.state.selectedItems];
    const isChecked = !currentItems.includes(value);
    const typeIndex = currentItems.indexOf(value);
    if (!isChecked) currentItems.splice(typeIndex, 1);
    else {
      if (typeIndex === -1) currentItems.push(value);
    }
    this.setState({ selectedItems: currentItems });
    this.props.onFilterUpdate(currentItems);
  };

  showFilterDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isShown: true });
  };

  toggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { isShown } = this.state;
    this.setState({ isShown: !isShown });
  };

  render() {
    const { planYears } = this.props;
    sortPlanYears(planYears);

    const itemsList = planYears.map((planYear) => ({
      value: planYear.id,
      label: getPlanYearNameFilter(planYear)
    }));

    const { isShown, selectedItems } = this.state;
    return (
      <UncontrolledDropdown
        toggle={this.toggle}
        isOpen={isShown}
        auto_id="actions-dropdown"
      >
        <DropdownToggle tag="a" className="cursor-pointer">
          <div>
            Plan Year
            <Icon icon='filter' className="types-filter" size="xs" />
          </div>
        </DropdownToggle>
        {isShown && (
          <DropdownMenu className="plan-year-filter">
            {itemsList.map((type) => {
              const { value, label } = type;
              return (
                <DropdownItem
                  onClick={(a) => {
                    this.updateTypeSelection(value);
                  }}
                  key={value}
                  value={value}
                  className="filter-dropdown"
                >
                  <input
                    type="checkbox"
                    className="check-box filter-option"
                    value={value}
                    checked={
                      !_.isEmpty(selectedItems)
                        ? selectedItems.indexOf(value) !== -1
                        : false
                    }
                    onChange={(event) => this.updateTypeSelection(value)}
                  />
                  {label}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        )}
      </UncontrolledDropdown>
    );
  }
}

export default connect()(PlanYearFilter);
