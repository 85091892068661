/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import EmployerFeatureConfigurationService from 'services/EmployerFeatureConfigurationService';
import {
  GET_INSIGHTS_FILE_HISTORY_ERROR,
  GET_INSIGHTS_FILE_HISTORY_IN_PROGRESS,
  GET_INSIGHTS_FILE_HISTORY_SUCCESS,
  GET_INSIGHTS,
  GET_INSIGHTS_ERROR,
  GET_INSIGHTS_IN_PROGRESS,
  UPDATE_CHART_STATUS,
  UPDATE_CHART_STATUS_ERROR,
  UPDATE_CHART_STATUS_IN_PROGRESS,
  UPLOAD_INSIGHTS_FILE,
  UPLOAD_INSIGHTS_FILE_ERROR,
  UPLOAD_INSIGHTS_FILE_IN_PROGRESS,
  CREATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  UPDATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  FIND_INSIGHTS_ANALYTICS_CONFIG_STATUS,
  FIND_INSIGHTS_CLAIMS_CONFIG_STATUS,
  FIND_INSIGHTS_EMPLOYER_CONFIG_STATUS_ERROR,
  CREATE_MESSAGE_IN_PROGRESS,
  CREATE_MESSAGE_COMPLETED,
  CREATE_MESSAGE_FAILED,
  GET_YEARS_IN_PROGRESS,
  GET_YEARS_SUCCESS,
  GET_YEARS_ERROR,
  GET_MESSAGE_DATA_IN_PROGRESS,
  GET_MESSAGE_DATA_SUCCESS,
  GET_MESSAGE_DATA_ERROR
} from './insights.actions';
import {
  MODULE_ANALYTICS_ENABLED,
  MODULE_CLAIMS_ENABLED,
  UPDATE_SUCCESS_CODE,
  CREATE_SUCCESS_CODE
} from '../constants';

import InsightsService from 'services/InsightsService';
export const getInsightsFeatureSummary = (employerId) => async (dispatch) => {
  dispatch({ type: GET_INSIGHTS_IN_PROGRESS, payload: { loading: true } });
  try {
    const response = await InsightsService.findInsightsFeatureSummary(
      employerId
    );
    dispatch({ type: GET_INSIGHTS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_INSIGHTS_ERROR, payload: error });
  }
};
export const uploadFile = (data) => async (dispatch) => {
  dispatch({
    type: UPLOAD_INSIGHTS_FILE_IN_PROGRESS,
    payload: { loading: true, chartType: data.documentType }
  });
  try {
    const response = await InsightsService.uploadFile(data);
    dispatch({ type: UPLOAD_INSIGHTS_FILE, payload: response.data });
  } catch (error) {
    dispatch({ type: UPLOAD_INSIGHTS_FILE_ERROR, payload: error });
  }
};
export const changeChartStatus = (employerId, data) => async (dispatch) => {
  dispatch({
    type: UPDATE_CHART_STATUS_IN_PROGRESS,
    payload: { loading: true, chartType: data.type }
  });
  try {
    const response = await InsightsService.updateChartStatus(employerId, data);
    dispatch({ type: UPDATE_CHART_STATUS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_CHART_STATUS_ERROR, payload: error });
  }
};
export const getFileHistory = (employerId, chartType) => async (dispatch) => {
  dispatch({
    type: GET_INSIGHTS_FILE_HISTORY_IN_PROGRESS,
    payload: { loading: true, chartType: chartType }
  });
  try {
    const response = await InsightsService.getInsightsFileHistory(
      employerId,
      chartType
    );
    dispatch({
      type: GET_INSIGHTS_FILE_HISTORY_SUCCESS,
      payload: { data: response.data, chartType: chartType }
    });
  } catch (error) {
    dispatch({ type: GET_INSIGHTS_FILE_HISTORY_ERROR, payload: error });
  }
};

export const createConfigModule = (employerId, value, key) => {
  const insightsEnableConfig = {
    employerId: employerId,
    key: key,
    value: String(value)
  };
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.createConfigModule(
        insightsEnableConfig
      );
      const isSuccess = response.status === CREATE_SUCCESS_CODE;
      if (isSuccess) {
        try {
          const response = await EmployerFeatureConfigurationService.findConfigStatus(
            employerId,
            key
          );
          if (response.data.key === MODULE_ANALYTICS_ENABLED) {
            dispatch({
              type: FIND_INSIGHTS_ANALYTICS_CONFIG_STATUS,
              payload: response.data
            });
          }
          if (response.data.key === MODULE_CLAIMS_ENABLED) {
            dispatch({
              type: FIND_INSIGHTS_CLAIMS_CONFIG_STATUS,
              payload: response.data
            });
          }
        } catch (error) {
          dispatch({
            type: FIND_INSIGHTS_EMPLOYER_CONFIG_STATUS_ERROR,
            payload: error
          });
        }
      }
    } catch (error) {
      dispatch({
        type: CREATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const updateConfigModule = (id, employerId, value, key) => {
  return async (dispatch) => {
    const insightsEnableConfig = {
      id: id,
      employerId: employerId,
      key: key,
      value: String(value === 'false')
    };
    try {
      const response = await EmployerFeatureConfigurationService.updateConfigModule(
        insightsEnableConfig
      );
      const isSuccess = response.status === UPDATE_SUCCESS_CODE;
      if (isSuccess) {
        try {
          const response = await EmployerFeatureConfigurationService.findConfigStatus(
            employerId,
            key
          );
          if (response.data.key === MODULE_ANALYTICS_ENABLED) {
            dispatch({
              type: FIND_INSIGHTS_ANALYTICS_CONFIG_STATUS,
              payload: response.data
            });
          }
          if (response.data.key === MODULE_CLAIMS_ENABLED) {
            dispatch({
              type: FIND_INSIGHTS_CLAIMS_CONFIG_STATUS,
              payload: response.data
            });
          }
        } catch (error) {
          dispatch({
            type: FIND_INSIGHTS_EMPLOYER_CONFIG_STATUS_ERROR,
            payload: error
          });
        }
      }
    } catch (error) {
      dispatch({
        type: UPDATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const findConfigStatus = (employerId, key) => {
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.findConfigStatus(
        employerId,
        key
      );
      if (key === MODULE_ANALYTICS_ENABLED) {
        dispatch({
          type: FIND_INSIGHTS_ANALYTICS_CONFIG_STATUS,
          payload: response.data
        });
      }
      if (key === MODULE_CLAIMS_ENABLED) {
        dispatch({
          type: FIND_INSIGHTS_CLAIMS_CONFIG_STATUS,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({
        type: FIND_INSIGHTS_EMPLOYER_CONFIG_STATUS_ERROR,
        payload: error
      });
    }
  };
};

export const saveMessage = (messageData, employerId) => {
  return async (dispatch) => {
    let newMessageData = {
      employerId: employerId,
      year: messageData.year,
      customMessages: {
        MEDICAL: {
          message: messageData.customMessages.MEDICAL
        },
        DENTAL: {
          message: messageData.customMessages.DENTAL
        },
        VISION: {
          message: messageData.customMessages.VISION
        }
      }
    };
    dispatch({ type: CREATE_MESSAGE_IN_PROGRESS });

    try {
      const response = await InsightsService.saveMessage(
        newMessageData,
        employerId
      );
      dispatch({
        type: CREATE_MESSAGE_COMPLETED,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: CREATE_MESSAGE_FAILED,
        payload: error
      });
    }
  };
};

export const getAllYears = () => {
  return async (dispatch) => {
    dispatch({ type: GET_YEARS_IN_PROGRESS });
    try {
      const response = await InsightsService.getAllYears();
      dispatch({
        type: GET_YEARS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: GET_YEARS_ERROR,
        payload: error
      });
    }
  };
};

export const getAllMessages = (employerId, year) => {
  return async (dispatch) => {
    dispatch({ type: GET_MESSAGE_DATA_IN_PROGRESS });
    try {
      const response = await InsightsService.getAllMessages(employerId, year);
      dispatch({
        type: GET_MESSAGE_DATA_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: GET_MESSAGE_DATA_ERROR,
        payload: error
      });
    }
  };
};
