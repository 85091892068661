/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import BillingService from '../../../services/BillingService';
import EmployerFeatureConfigurationService from 'services/EmployerFeatureConfigurationService';

import { MODULE_BILLING_ENABLED } from '../constants';

import {
  GET_EMPLOYERS_BILLING_PAGE,
  GET_EMPLOYERS_BILLING_PAGE_ERROR,
  FILE_TYPE_LIST,
  FILE_TYPE_LIST_ERROR,
  UPLOAD_BILLING_FILE_IN_PROGRESS,
  UPLOAD_BILLING_FILE_SUCCESS,
  UPLOAD_BILLING_FILE_ERROR,
  UPLOAD_BILLING_FILE_PROGRESS_NOTIFICATION,
  SAVE_DETAIL_BILLING_SUCCESS,
  SAVE_DETAIL_BILLING_ERROR,
  SAVE_DETAIL_BILLING_IN_PROGRESS,
  BILLING_HISTORY_SUCCESSS,
  BILLING_HISTORY_ERROR,
  UPLOAD_CARRIER_FILE_IN_PROGRESS,
  UPLOAD_CARRIER_FILE_PROGRESS_NOTIFICATION,
  UPLOAD_CARRIER_FILE_SUCCESS,
  UPLOAD_CARRIER_FILE_ERROR,
  DELETE_BILLING_FILE_IN_PROGRESS,
  DELETE_BILLING_FILE_SUCCESS,
  DELETE_BILLING_FILE_ERROR,
  SAVE_AND_PUBLISH_BILLING_SUCCESS,
  SAVE_AND_PUBLISH_BILLING_ERROR,
  SAVE_AND_PUBLISH_BILLING_IN_PROGRESS,
  GET_BILLING_PREVIEW_DATA_IN_PROGRESS,
  GET_BILLING_PREVIEW_DATA_SUCCESS,
  GET_BILLING_PREVIEW_DATA_ERROR,
  PUBLISH_FROM_GRID_SUCCESS,
  PUBLISH_FROM_GRID_ERROR,
  PUBLISH_FROM_GRID_IN_PROGRESS,
  DOWNLOAD_BILLING_FILE_SUCCESS,
  DOWNLOAD_BILLING_FILE_ERROR,
  DOWNLOAD_BILLING_FILE_IN_PROGRESS,
  DOWNLOAD_HISTORY_BILLING_FILE_IN_PROGRESS,
  DOWNLOAD_HISTORY_BILLING_FILE_SUCCESS,
  DOWNLOAD_HISTORY_BILLING_FILE_ERROR,
  FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES,
  FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  CREATE_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  UPDATE_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR
} from './action.types';
import { createProgressDispatcher } from '../../../util/apiUtil';

import { CREATE_STATUS, UPDATE_STATUS } from 'constants/commonConstants';

export const getEmployersBillingPage = (
  page,
  size,
  sort,
  query,
  employerId
) => {
  return async (dispatch) => {
    try {
      const response = await BillingService.findAll(
        page,
        size,
        sort,
        query,
        employerId
      );
      dispatch({ type: GET_EMPLOYERS_BILLING_PAGE, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_EMPLOYERS_BILLING_PAGE_ERROR, payload: error });
    }
  };
};

/*
  Get File Types
*/
export const getFileTypeList = () => {
  return async (dispatch) => {
    try {
      const response = await BillingService.getFileTypeList();
      dispatch({ type: FILE_TYPE_LIST, payload: response.data });
    } catch (error) {
      dispatch({ type: FILE_TYPE_LIST_ERROR, payload: error });
    }
  };
};

/*
  Upload bill for get validations
*/
export const uploadBillingFile = (
  employerId,
  detailFile,
  summaryFile,
  billingFileType,
  billingMonth,
  apiType
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_BILLING_FILE_IN_PROGRESS });
      const progressDispatcher = createProgressDispatcher(
        dispatch,
        UPLOAD_BILLING_FILE_PROGRESS_NOTIFICATION
      );
      const response = await BillingService.uploadDataFile(
        employerId,
        detailFile,
        summaryFile,
        billingFileType,
        billingMonth,
        progressDispatcher,
        apiType
      );

      dispatch({
        type: UPLOAD_BILLING_FILE_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: UPLOAD_BILLING_FILE_ERROR, payload: error });
    }
  };
};

/*
  Save bill in the draft state
*/
export const saveBillingFileWithoutPublish = (
  employerId,
  detailFile,
  summaryFile,
  fileType,
  billingMonth,
  apiType
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SAVE_DETAIL_BILLING_IN_PROGRESS });
      const progressDispatcher = null;
      const response = await BillingService.uploadDataFile(
        employerId,
        detailFile,
        summaryFile,
        fileType,
        billingMonth,
        progressDispatcher,
        apiType
      );

      dispatch({ type: SAVE_DETAIL_BILLING_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: SAVE_DETAIL_BILLING_ERROR, payload: error });
    }
  };
};

/*
  Get file History
*/
export const getFileHistory = (
  billRecordId,
  employerId,
  billingFileType,
  page,
  size,
  sort,
  query
) => {
  return async (dispatch) => {
    try {
      const response = await BillingService.getFileHistory(
        billRecordId,
        employerId,
        billingFileType,
        page,
        size,
        sort,
        query
      );
      dispatch({ type: BILLING_HISTORY_SUCCESSS, payload: response.data });
    } catch (error) {
      console.error(
        'Could not get all FileHistory for billRecordId ' + billRecordId,
        error
      );
      dispatch({ type: BILLING_HISTORY_ERROR, payload: error });
    }
  };
};

/*
  Upload carrier file
*/
export const uploadCarrierFile = (billRecordId, fileName, fileBlob) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_CARRIER_FILE_IN_PROGRESS });
      const progressDispatcher = createProgressDispatcher(
        dispatch,
        UPLOAD_CARRIER_FILE_PROGRESS_NOTIFICATION
      );
      const response = await BillingService.uploadCarrierFile(
        billRecordId,
        fileName,
        fileBlob,
        progressDispatcher
      );

      dispatch({ type: UPLOAD_CARRIER_FILE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: UPLOAD_CARRIER_FILE_ERROR, payload: error });
    }
  };
};

/*
  Delete billing file
*/
export const deleteBillingFiles = (billRecordId) => async (dispatch) => {
  dispatch({ type: DELETE_BILLING_FILE_IN_PROGRESS, payload: {} });
  try {
    await BillingService.deleteBillingFiles(billRecordId);
    dispatch({
      type: DELETE_BILLING_FILE_SUCCESS,
      payload: { id: billRecordId }
    });
  } catch (error) {
    console.error('Could not Delete Billing Files.', error);
    dispatch({ type: DELETE_BILLING_FILE_ERROR, payload: error });
  }
};

/*
  Save and Publish bill
*/
export const saveAndPublishBillingFile = (
  employerId,
  detailFile,
  summaryFile,
  fileType,
  billingMonth,
  apiType
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SAVE_AND_PUBLISH_BILLING_IN_PROGRESS });
      const progressDispatcher = null;
      const response = await BillingService.uploadDataFile(
        employerId,
        detailFile,
        summaryFile,
        fileType,
        billingMonth,
        progressDispatcher,
        apiType
      );

      dispatch({
        type: SAVE_AND_PUBLISH_BILLING_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: SAVE_AND_PUBLISH_BILLING_ERROR, payload: error });
    }
  };
};

/*
  Preview data from saved Bill
*/
export const getPreviewData = (billingId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_BILLING_PREVIEW_DATA_IN_PROGRESS });
      const response = await BillingService.getPreviewData(billingId);
      dispatch({
        type: GET_BILLING_PREVIEW_DATA_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: GET_BILLING_PREVIEW_DATA_ERROR, payload: error });
    }
  };
};

/*
  Publish data from saved Bill
*/
export const publishFromGridDataFile = (billRecordId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PUBLISH_FROM_GRID_IN_PROGRESS });
      const response = await BillingService.publishFromGridDataFile(
        billRecordId
      );
      dispatch({ type: PUBLISH_FROM_GRID_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: PUBLISH_FROM_GRID_ERROR, payload: error });
    }
  };
};

export const downloadBill = (id, billingFileType, fileName) => {
  return async (dispatch) => {
    dispatch({
      type: DOWNLOAD_BILLING_FILE_IN_PROGRESS,
      payload: { file: null, fileName: null }
    });
    try {
      const response = await BillingService.downloadBill(
        id,
        billingFileType,
        fileName
      );
      dispatch({
        type: DOWNLOAD_BILLING_FILE_SUCCESS,
        payload: { file: response.data, fileName: response.config.fileName }
      });
    } catch (error) {
      dispatch({
        type: DOWNLOAD_BILLING_FILE_ERROR,
        payload: error
      });
    }
  };
};

export const downloadHistoryBill = (id, billingFileType, fileName) => {
  return async (dispatch) => {
    dispatch({
      type: DOWNLOAD_HISTORY_BILLING_FILE_IN_PROGRESS,
      payload: { file: null, fileName: null }
    });
    try {
      const response = await BillingService.downloadHistoryBill(
        id,
        billingFileType,
        fileName
      );
      dispatch({
        type: DOWNLOAD_HISTORY_BILLING_FILE_SUCCESS,
        payload: { file: response.data, fileName: response.config.fileName }
      });
    } catch (error) {
      dispatch({
        type: DOWNLOAD_HISTORY_BILLING_FILE_ERROR,
        payload: error
      });
    }
  };
};

export const findConfigStatus = (employerId, key) => {
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.findConfigStatus(
        employerId,
        key
      );
      dispatch({
        type: FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const createConfigModule = (employerId) => {
  const billingConfig = {
    employerId: employerId,
    key: MODULE_BILLING_ENABLED,
    value: String(true)
  };
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.createConfigModule(
        billingConfig
      );
      const isCreated = response.status === CREATE_STATUS;
      if (isCreated) {
        try {
          const response = await EmployerFeatureConfigurationService.findConfigStatus(
            employerId,
            MODULE_BILLING_ENABLED
          );
          dispatch({
            type: FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES,
            payload: response.data
          });
        } catch (error) {
          dispatch({
            type: FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR,
            payload: error
          });
        }
      }
    } catch (error) {
      dispatch({
        type: CREATE_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const updateConfigModule = (id, employerId, value) => {
  const billingConfig = {
    id: id,
    employerId: employerId,
    key: MODULE_BILLING_ENABLED,
    value: String(value === 'false')
  };
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.updateConfigModule(
        billingConfig
      );
      const isUpdated = response.status === UPDATE_STATUS;
      if (isUpdated) {
        try {
          const response = await EmployerFeatureConfigurationService.findConfigStatus(
            employerId,
            MODULE_BILLING_ENABLED
          );
          dispatch({
            type: FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES,
            payload: response.data
          });
        } catch (error) {
          dispatch({
            type: FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR,
            payload: error
          });
        }
      }
    } catch (error) {
      dispatch({
        type: UPDATE_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};
