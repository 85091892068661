/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import * as _ from 'lodash';
import { Button, Row, Col, Input, FormGroup, Alert } from 'components/Atoms';
import Icon from 'components/Icons';
import { TextFormGroup } from 'components/DataForm';

import '../DialogIntegrationDetails.scss';
import { VENDOR_NAMES } from 'modules/Integrations/constants';

export const CustomFieldRow = ({
  index,
  workdayHrFieldName,
  vendorName,
  vendorFieldList,
  lumityFieldName = '',
  selectedFields,
  removeFieldRow,
  updateCustomField,
  errors,
  fieldsCount
}) => {
  const fieldErrors = errors[index];
  return (
    <div className="custom-field-row">
      <Button color="link" onClick={removeFieldRow} className="remove-icon">
        <Icon icon="minus-circle" />
      </Button>
      <Row>
        <Col>
          <TextFormGroup
            labelDisplay={`${vendorName} Field Name`}
            inputName="workdayHrFieldName"
            inputValue={workdayHrFieldName}
            onChange={updateCustomField}
            placeholder="Enter Custom Field"
            isInvalid={!!(fieldErrors && fieldErrors['workdayHrFieldName'])}
          />
        </Col>
        <Col>
          <FormGroup>
            <label>
              <b>BenAdmin Field</b>
            </label>
            <Input
              type="select"
              name="lumityFieldName"
              value={lumityFieldName}
              onChange={updateCustomField}
              invalid={!!fieldErrors && fieldErrors['lumityFieldName']}
            >
              <option key="select" value="">
                Select Option
              </option>
              {vendorFieldList.map((field) => {
                return (
                  <option
                    key={field.code}
                    value={field.code}
                    disabled={selectedFields.includes(field.code)}
                  >
                    {field.name}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};
export class CustomFieldsMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // To show one row with empty value
      customFields: props.selectedCustomFields || [
        {
          lumityFieldName: '',
          workdayHrFieldName: ''
        }
      ],
      vendorFieldList: props.vendorFieldList || [],
      selectedFields:
        CustomFieldsMapping.getSelectedFields(props.selectedCustomFields) || [],
      errors: []
    };
  }

  static getSelectedFields(selectedCustomFields) {
    if (selectedCustomFields) {
      return selectedCustomFields.map(({ lumityFieldName }) => lumityFieldName);
    }
    return false;
  }

  componentDidMount() {
    this.props.getVendorCustomFields();
  }

  addCustomField = () => {
    this.setState({
      customFields: [
        ...this.state.customFields,
        {
          lumityFieldName: '',
          workdayHrFieldName: ''
        }
      ]
    });
  };

  removeCustomField = (index) => (e) => {
    const { customFields, selectedFields, errors } = this.state;
    const updatedCustomFields = [...customFields];
    const updatedSelectedFields = selectedFields.filter(
      (field) => field !== customFields[index].lumityFieldName
    );
    updatedCustomFields.splice(index, 1);
    if (errors[index]) {
      delete errors[index];
      if (_.isEmpty(errors[index])) delete errors[index];
    }
    if (customFields.length === 1) {
      this.setState({
        customFields: [{}],
        selectedFields: []
      });
      return;
    }
    this.setState({
      customFields: updatedCustomFields,
      selectedFields: updatedSelectedFields
    });
  };

  updateCustomField = (index) => (e) => {
    let { customFields, selectedFields, errors } = this.state;
    let updatedSelectedFields = [...selectedFields];
    const updatedCustomFields = [...customFields];
    const customField = updatedCustomFields[index];
    const { name, value } = e.target;
    if (name === 'lumityFieldName') {
      if (selectedFields.includes(customField.lumityFieldName)) {
        updatedSelectedFields = updatedSelectedFields.filter(
          (field) => field !== customField.lumityFieldName
        );
      }
      if (value !== '') {
        updatedSelectedFields.push(value);
      }
    }
    customField[name] = value;
    if (value && errors[index] && errors[index][name]) {
      delete errors[index][name];
      if (_.isEmpty(errors[index])) delete errors[index];
    }
    this.setState({
      customFields: updatedCustomFields,
      selectedFields: updatedSelectedFields
    });
  };

  onNext = () => {
    const { customFields } = this.state;
    const { selectedVendor } = this.props;
    let customFieldsConfig = [];
    let errors = {};
    if (selectedVendor.name === VENDOR_NAMES.WORKDAY) {
      customFields.forEach(({ lumityFieldName, workdayHrFieldName }, index) => {
        if (_.isEmpty(lumityFieldName) && _.isEmpty(workdayHrFieldName))
          return false;
        if (_.isEmpty(lumityFieldName)) {
          errors = {
            ...errors,
            [index]: {
              ...errors[index],
              lumityFieldName: true
            }
          };
        }
        if (_.isEmpty(workdayHrFieldName)) {
          errors = {
            ...errors,
            [index]: {
              ...errors[index],
              workdayHrFieldName: true
            }
          };
        }
        workdayHrFieldName = _.trim(workdayHrFieldName);
        customFieldsConfig.push({
          workdayHrFieldName,
          lumityFieldName
        });
      });
    }
    if (_.isEmpty(errors)) {
      // set to null if it is empty array
      if (_.isEmpty(customFieldsConfig)) {
        customFieldsConfig = null;
      }
      this.props.onNext(customFieldsConfig);
    } else {
      this.setState({
        errors
      });
    }
  };

  renderCustomFields() {
    const { customFields, selectedFields, errors } = this.state;
    const { selectedVendor, vendorFieldList } = this.props;
    const { name: vendorName } = selectedVendor;
    const customFieldRows = customFields.map(
      ({ workdayHrFieldName = '', lumityFieldName = '' }, index) => {
        return (
          <CustomFieldRow
            key={index}
            index={index}
            workdayHrFieldName={workdayHrFieldName}
            vendorName={vendorName}
            lumityFieldName={lumityFieldName}
            vendorFieldList={vendorFieldList}
            selectedFields={selectedFields}
            removeFieldRow={this.removeCustomField(index)}
            updateCustomField={this.updateCustomField(index)}
            errors={errors}
            fieldsCount={customFields.length}
          />
        );
      }
    );
    return customFieldRows;
  }

  render() {
    const { customFields, selectedVendor, onPrevious } = this.props;
    const { name: vendorName } = selectedVendor;
    const { errors } = this.state;
    return (
      <div className="step-container custom-fields-container">
        <div className="body">
          <p className="subtitle">{vendorName}</p>
          {!_.isEmpty(errors) && (
            <Alert color="danger" className="error-alert">
              Please enter required fields.
            </Alert>
          )}
          {this.renderCustomFields(customFields)}
          <Row>
            <Button color="link" onClick={this.addCustomField}>
              +Add Custom Field
            </Button>
          </Row>
        </div>
        <Row className="action-btns">
          <Col>
            <Button
              className="form-element"
              outline
              color="primary"
              size="lg"
              onClick={() => onPrevious(this.state.customFields)}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              className="form-element"
              color="primary"
              size="lg"
              onClick={this.onNext}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
