/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
const WELLBEING_LIST_PATH = '/brokers/:brokerId/employers/:employerId/wellbeing-plans';

const WELLBEING_MEDITATION_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/wellbeing-plans/meditation/create';
const WELLBEING_MENTAL_HEALTH_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/wellbeing-plans/mental-health/create';
const WELLBEING_WELLNESS_REIMBURSEMENT_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/wellbeing-plans/wellness-reimbursment/create';

const WELLBEING_MEDITATION_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/wellbeing-plans/meditation/:planId/edit';
const WELLBEING_MENTAL_HEALTH_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/wellbeing-plans/mental-health/:planId/edit';
const WELLBEING_WELLNESS_REIMBURSEMENT_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/wellbeing-plans/wellness-reimbursment/:planId/edit';

const WELLBEING_MEDITATION_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/wellbeing-plans/meditation/:planId/view';
const WELLBEING_MENTAL_HEALTH_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/wellbeing-plans/mental-health/:planId/view';
const WELLBEING_WELLNESS_REIMBURSEMENT_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/wellbeing-plans/wellness-reimbursment/:planId/view';

const WELLBEING_MEDITATION_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/wellbeing-plans/meditation/clone';
const WELLBEING_MENTAL_HEALTH_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/wellbeing-plans/mental-health/clone';
const WELLBEING_WELLNESS_REIMBURSEMENT_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/wellbeing-plans/wellness-reimbursment/clone';  

export {
  WELLBEING_LIST_PATH,
  WELLBEING_MEDITATION_CREATE_PATH,
  WELLBEING_MENTAL_HEALTH_CREATE_PATH,
  WELLBEING_WELLNESS_REIMBURSEMENT_CREATE_PATH,
  WELLBEING_MEDITATION_EDIT_PATH,
  WELLBEING_MENTAL_HEALTH_EDIT_PATH,
  WELLBEING_WELLNESS_REIMBURSEMENT_EDIT_PATH,
  WELLBEING_MEDITATION_VIEW_PATH,
  WELLBEING_MENTAL_HEALTH_VIEW_PATH,
  WELLBEING_WELLNESS_REIMBURSEMENT_VIEW_PATH,
  WELLBEING_MEDITATION_CLONE_PATH,
  WELLBEING_MENTAL_HEALTH_CLONE_PATH,
  WELLBEING_WELLNESS_REIMBURSEMENT_CLONE_PATH
};
