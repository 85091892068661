import React from 'react';
import { connect } from 'react-redux';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import { SelectFormGroup } from 'components/DataForm';
import Icon from 'components/Icons';
import SimilarPlansModal from '../../../components/SimilarPlansModal/SimilarPlansModal';
import Loader from 'components/CircularProgress';

import CurrentPlanDetails from '../../../components/CurrentPlanDetails/CurrentPlanDetails';
import PageActionButton from 'components/Buttons';

import {
  TYPE_DEDUCTIBLE,
  TYPE_OOP_MAX,
  TYPE_COPAY,
  TYPE_RX_COST_TIER,
  TYPE_CONTRIBUTION,
  TYPE_RATES,
  TYPE_HSA_CONTRIBUTION,
  BENEFIT_KIND_VISION,
  BENEFIT_KIND_DENTAL,
  BENEFIT_KIND_MEDICAL,
  TYPE_ENROLLMENTS,
  BENCHMARK_TYPE_CURRENT,
  COINSURANCE
} from '../../../constants';
import { redirectBack } from '../../../util';
import {
  saveCurrentMedicalPlans,
  findBenchmark,
  saveBenchmark,
  getMetadata,
  validateBenchmark
} from '../../../actions/benchmarkActions';

import _ from 'lodash';

import './CurrentModeling.scss';

import { Row, Col, Button } from 'components/Atoms';
import Notifications from 'components/Notifications';
import { BENCHMARK_PLAN_HSA } from '../../../constants';
import FormText from 'reactstrap/es/FormText';
class CurrentModeling extends React.Component {
  constructor(props) {
    super(props);

    const { benchmark, match } = this.props;
    const { benefitCategory } = match.params;

    this.state = {
      planTypeValue: '',
      benchmarkData: benchmark,
      currentPlanDetails: [],
      isSimilarPlansModalOpen: false,
      currentPlanTypes: [],
      validations: {},
      metadata: {},
      skipped: false,
      errors: {},
      enableCommonErrors: false, //to avoid showing common error message until click on the 'Save & Continue' button
      isAddModelingEnabled: false,
      benefitTitleCategory: benefitCategory.replace(/(^.)/, (m) =>
        m.toUpperCase()
      )
    };
  }

  componentDidMount() {
    const { match, findBenchmark, getMetadata } = this.props;

    const { benchmarkId } = match.params;

    if (benchmarkId) {
      // Fetches the benchmark
      findBenchmark(benchmarkId);
      // Fetches metadata
      getMetadata();
    }

    this.setState({ skipped: false });
  }

  componentDidUpdate(prevProps) {
    const {
      match,
      benchmark,
      benchmarkSaveResult,
      metadata,
      validateBenchmark
    } = this.props;

    const { employerId, benchmarkId, benefitCategory, brokerId } = match.params;
    const { benchmarkData } = this.state;

    if (benefitCategory !== prevProps.match.params.benefitCategory) {
      if (benefitCategory === BENEFIT_KIND_VISION) {
        this.setState({
          planTypeValue: 'Vision',
          isAddModelingEnabled: true
        });
      } else {
        this.setState({
          planTypeValue: '',
          isAddModelingEnabled: false
        });
      }
      this.setState({
        benefitTitleCategory: benefitCategory.replace(/(^.)/, (m) =>
          m.toUpperCase()
        )
      });
    }

    if (benchmarkSaveResult !== prevProps.benchmarkSaveResult) {
      if (!this.state.skipped) {
        Notifications.success(
          `Current modeling for ${benefitCategory} has been successfully saved`
        );
        this.props.history.push(
          `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkId}/edit/${benefitCategory}/benchmark`
        );
      } else {
        this.setState({ skipped: false });
        if (benefitCategory === BENEFIT_KIND_MEDICAL) {
          this.props.history.push(
            `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkSaveResult.benchmarkId}/edit/dental`
          );
        }
        if (benefitCategory === BENEFIT_KIND_DENTAL) {
          this.props.history.push(
            `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkSaveResult.benchmarkId}/edit/vision`
          );
        }
        if (benefitCategory === BENEFIT_KIND_VISION) {
          this.props.history.push(
            `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkSaveResult.benchmarkId}/edit/benchmark/publish`
          );
        }
      }
    }

    if (metadata !== prevProps.metadata) {
      this.setState({
        metadata: { ...metadata }
      });
    }

    if (benchmark !== prevProps.benchmark) {
      this.setState({
        benchmarkData: { ...benchmark }
      });
      if (benefitCategory === BENEFIT_KIND_VISION) {
        this.setState({
          planTypeValue: 'Vision',
          isAddModelingEnabled: true
        });
      }
    }

    if (benchmarkData !== prevProps.benchmarkData) {
      validateBenchmark(benchmarkData, benefitCategory.toUpperCase());
    }
  }

  attachCurrentPlanTypes = (metadata) => {
    const { benefitCategory } = this.props.match.params;

    let currentPlanTypes = [];

    if (benefitCategory !== BENEFIT_KIND_VISION) {
      if (metadata.planTypes) {
        metadata.planTypes[benefitCategory.toUpperCase()].forEach(
          (planType) => {
            currentPlanTypes.push({
              value: planType,
              text: planType
            });
          }
        );
      }
    } else {
      currentPlanTypes.push({
        value: 'Vision',
        text: 'Vision'
      });
    }

    return currentPlanTypes;
  };

  isDataChanged = () => {
    const { benchmark } = this.props;
    const { benchmarkData } = this.state;

    return !_.isEqual(benchmark, benchmarkData);
  };

  onChangePlanType = (event) => {
    const { value } = event.target;
    if (value) {
      this.setState({
        planTypeValue: value,
        isAddModelingEnabled: true
      });
    } else {
      this.setState({
        planTypeValue: '',
        isAddModelingEnabled: false
      });
    }
  };

  updatePlanName = (planKey, value) => {
    const { benefitCategory } = this.props.match.params;
    const benefitType = benefitCategory.toUpperCase();
    const { benchmarkData = {} } = this.state;
    const { currentPlans = {} } = benchmarkData;
    let plans = currentPlans[benefitType];

    for (let index = 0; index < plans.length; index++) {
      if (index === planKey) {
        plans[planKey].planName = value;
      }
    }
    this.setState((prevState) => ({
      benchmarkData: {
        ...prevState.benchmarkData,
        currentPlans: {
          ...prevState.benchmarkData.currentPlans,
          [benefitType]: plans
        }
      }
    }));
  };

  addPlanType = (event) => {
    const { benefitCategory } = this.props.match.params;
    const { benchmarkData, planTypeValue } = this.state;
    const benefitType = benefitCategory.toUpperCase();
    const existingCurrentPlans = benchmarkData.currentPlans;
    let existingCurrentPlansForBenType = existingCurrentPlans[benefitType];
    if (existingCurrentPlansForBenType) {
      existingCurrentPlansForBenType.push(
        newCurrentPlan(planTypeValue, benefitCategory)
      );
    } else {
      existingCurrentPlansForBenType = [
        newCurrentPlan(planTypeValue, benefitCategory)
      ];
      this.setState((prevState) => ({
        benchmarkData: {
          ...prevState.benchmarkData,
          currentPlans: {
            ...prevState.benchmarkData.currentPlans,
            [benefitType]: existingCurrentPlansForBenType
          }
        }
      }));
    }
    const planType = benefitCategory !== BENEFIT_KIND_VISION ? '' : 'Vision';
    if (planType) {
      this.setState({
        planTypeValue: planType
      });
    } else {
      this.setState({
        planTypeValue: '',
        isAddModelingEnabled: false
      });
    }
  };

  removePlanType = (planKey) => {
    const { benefitCategory } = this.props.match.params;

    const { benchmarkData = {} } = this.state;
    const { benchmarks, currentPlans } = benchmarkData || {};

    // remove plan details from benchmark
    const benefitType = benefitCategory.toUpperCase();

    let benchmarkForBenType = benchmarks[benefitType];

    _.remove(
      benchmarkForBenType,
      ({ currentPlan }) =>
        currentPlan.id === currentPlans[benefitType][planKey].id
    );

    let currentPlansForBenefitType = currentPlans[benefitType];
    currentPlansForBenefitType.splice(planKey, 1);

    this.setState((prevState) => ({
      benchmarkData: {
        ...prevState.benchmarkData,
        benchmarks: {
          ...prevState.benchmarkData.benchmarks,
          [benefitType]: benchmarkForBenType
        },
        currentPlans: {
          ...prevState.benchmarkData.currentPlans,
          [benefitType]: currentPlansForBenefitType
        }
      }
    }));
  };

  updateSinglePlanDetail = (
    name,
    value,
    currentPlan,
    type = null,
    planKey,
    id
  ) => {
    const { planDesign } = currentPlan;
    const { benefitCategory } = this.props.match.params;
    const { benchmarkData } = this.state;
    const { currentPlans } = benchmarkData;
    const benefitType = benefitCategory.toUpperCase();

    if (!type) {
      planDesign[name] = value;
    } else {
      switch (type) {
        case TYPE_DEDUCTIBLE:
          const deductibles = planDesign.deductible;
          _.assign(deductibles, {
            [name]: value
          });
          break;
        case TYPE_OOP_MAX:
          const oopMaxes = planDesign.oopMax;
          _.assign(oopMaxes, {
            [name]: value
          });
          break;
        case TYPE_COPAY:
          const copays = planDesign.copay;
          _.assign(copays, {
            [name]: value
          });
          break;
        case TYPE_RX_COST_TIER:
          const rxCosts = planDesign.rxCost;
          _.assign(rxCosts, {
            [name]: value
          });
          break;
        case TYPE_CONTRIBUTION:
          const contributions = currentPlan.contribution || {};
          _.assign(contributions, {
            [name]: value
          });
          currentPlan.contribution = contributions;
          break;
        case TYPE_HSA_CONTRIBUTION:
          const hsaContribution = currentPlan.hsaContribution || {};
          _.assign(hsaContribution, {
            [name]: value
          });
          currentPlan.hsaContribution = hsaContribution;
          break;
        case TYPE_RATES:
          const rates = currentPlan.rates || {};
          _.assign(rates, {
            [name]: value
          });
          currentPlan.rates = rates;
          break;
        case TYPE_ENROLLMENTS:
          if (currentPlan.enrollments === undefined) {
            currentPlan.enrollments = {};
          }
          const { enrollments } = currentPlan;
          _.assign(enrollments, {
            [name]: value
          });
          break;
        case COINSURANCE:
          const coinsurances = planDesign.coinsurance;
          _.assign(coinsurances, {
            [name]: value
          });
          break;
        default:
          break;
      }
    }

    let currentPlansForBenefitType = currentPlans[benefitType];
    currentPlansForBenefitType[planKey] = currentPlan;
    this.setState((prevState) => ({
      benchmarkData: {
        ...prevState.benchmarkData,
        currentPlans: {
          ...prevState.benchmarkData.currentPlans,
          [benefitType]: currentPlansForBenefitType
        }
      }
    }));
  };

  saveAndNext = async (event) => {
    event.preventDefault();
    const { match, saveBenchmark } = this.props;
    const { employerId, benchmarkId, benefitCategory } = match.params;
    const { benchmarkData } = this.state;
    const curreentPlanValidationResult = await this.validateCurrentPlan();

    if (curreentPlanValidationResult) {
      if (this.isValidateCurrentPlanData()) {
        // set default weights if not exists
        await this.setDefaultWeights();
        this.setState({ ...this.state, enableCommonErrors: false });
        saveBenchmark(
          benchmarkId,
          benchmarkData,
          employerId,
          BENCHMARK_TYPE_CURRENT,
          benefitCategory.toUpperCase()
        );
      } else {
        this.setState({ ...this.state, enableCommonErrors: true });
      }
    } else {
      Notifications.error('Please add at least one plan type for modeling');
    }
  };

  isValidateCurrentPlanData = () => {
    const { benefitCategory } = this.props.match.params;
    const { benchmarkData = {} } = this.state;
    const { currentPlans = {} } = benchmarkData;
    let valid = true;

    if (!_.isEmpty(currentPlans[benefitCategory.toUpperCase()])) {
      currentPlans[benefitCategory.toUpperCase()].forEach((currentPlan) => {
        const {
          enrollments = {},
          hsaContribution = {} || {},
          planType,
          rates = {}
        } = currentPlan;
        const error = {
          enrollments: {
            EC: false,
            EF: false,
            ES: false,
            EE: false
          },
          hsaContribution: {
            EC: false,
            EF: false,
            ES: false,
            EE: false
          },
          rates: {
            EC: false,
            EF: false,
            ES: false,
            EE: false
          }
        };
        Object.keys(error.enrollments).forEach((key) => {
          if (
            !(!_.isEmpty(enrollments) && !isNaN(parseInt(enrollments[key])))
          ) {
            error.enrollments[key] = true;
            valid = false;
          }
        });
        if (planType === BENCHMARK_PLAN_HSA) {
          Object.keys(error.hsaContribution).forEach((key) => {
            if (
              !(
                hsaContribution &&
                hsaContribution[key] !== undefined &&
                hsaContribution[key] >= 0
              )
            ) {
              error.hsaContribution[key] = true;
              valid = false;
            }
          });
          Object.keys(error.rates).forEach((key) => {
            if (!(rates && rates[key] !== undefined && rates[key] >= 0)) {
              error.rates[key] = true;
              valid = false;
            }
          });
        }

        currentPlan.error = error;
      });
    }
    this.setState({ ...this.state, benchmarkData: benchmarkData });
    return valid;
  };

  skipBenefitCategory = () => {
    const { match, saveBenchmark } = this.props;

    const { employerId, benchmarkId, benefitCategory } = match.params;

    const { benchmarkData } = this.state;
    this.setState({ skipped: true });
    saveBenchmark(
      benchmarkId,
      benchmarkData,
      employerId,
      'current',
      benefitCategory.toUpperCase()
    );
    //Add Default Vision which will be automatically select only in vision
    //Others have been handled Seperately.
    this.setState({
      planTypeValue: 'Vision'
    });
  };

  validateCurrentPlan = () => {
    const { benefitCategory } = this.props.match.params;
    const { benchmarkData } = this.state;
    return (
      !_.isEmpty(benchmarkData.currentPlans) &&
      benchmarkData.currentPlans[benefitCategory.toUpperCase()] &&
      benchmarkData.currentPlans[benefitCategory.toUpperCase()].length > 0
    );
  };

  toggleSimilarPlansModal = () => {
    this.setState((prevState) => ({
      isSimilarPlansModalOpen: !prevState.isSimilarPlansModalOpen
    }));
  };

  onSelectBenchmarkPlan = (selectedBenchmarkPlan, planKey) => {
    const { benefitCategory } = this.props.match.params;
    const { benchmarkData } = this.state;
    const { benchmarks, currentPlans } = benchmarkData;

    // remove benchmark plan ID
    selectedBenchmarkPlan.id = undefined;
    selectedBenchmarkPlan.enrollments = {};

    const benefitType = benefitCategory.toUpperCase();
    // remove plan details from benchmark
    let benchmarksForBenefitType = benchmarks[benefitType];
    _.remove(
      benchmarksForBenefitType,
      ({ currentPlan }) =>
        currentPlan.id === currentPlans[benefitType][planKey].id
    );
    if (benchmarksForBenefitType && benchmarksForBenefitType.length === 0) {
      benchmarksForBenefitType = [];
    }
    let currentPlansForBenefitType = currentPlans[benefitType];
    currentPlansForBenefitType[planKey] = selectedBenchmarkPlan;
    this.setState((prevState) => ({
      benchmarkData: {
        ...prevState.benchmarkData,
        benchmarks: {
          ...prevState.benchmarkData.benchmarks,
          [benefitType]: benchmarksForBenefitType
        },
        currentPlans: {
          ...prevState.benchmarkData.currentPlans,
          [benefitType]: currentPlansForBenefitType
        }
      }
    }));
  };

  renderSkip(benchmarkData, benefitCategory, isCreatingBenchmark) {
    if (
      !benchmarkData.currentPlans ||
      !benchmarkData.currentPlans[benefitCategory.toUpperCase()] ||
      benchmarkData.currentPlans[benefitCategory.toUpperCase()].length === 0
    ) {
      return (
        <Row className="mt-1">
          <Col>
            <div
              className={isCreatingBenchmark
                ? "skip-medical-benchmark skip-medical-benchmark-disabled"
                : "skip-medical-benchmark skip-medical-benchmark-enabled"}
              onClick={!isCreatingBenchmark && this.skipBenefitCategory}
            >
              Skip
            </div>
          </Col>
        </Row>
      );
    }
  }

  setDefaultWeights = () => {
    const { benchmarkData, metadata } = this.state;
    const { weights } = metadata;
    const { currentPlans } = benchmarkData;
    let currentPlansForBenefitType = currentPlans || {};
    Object.values(currentPlansForBenefitType).forEach((currentPlans) => {
      currentPlans.forEach((currentPlan) => {
        if (!currentPlan.weights && _.isEmpty(currentPlan.weights)) {
          currentPlan.weights = weights[currentPlan.benefitCategory];
        }
      });
    });
    this.setState((prevState) => ({
      benchmarkData: {
        ...prevState.benchmarkData,
        currentPlans: currentPlansForBenefitType
      }
    }));
  };

  updateWeights = (weights, planKey) => {
    const { benefitCategory } = this.props.match.params;
    const { benchmarkData } = this.state;
    const { currentPlans } = benchmarkData;
    const benefitType = benefitCategory.toUpperCase();
    let currentPlansForBenefitType = currentPlans[benefitType];
    currentPlansForBenefitType[planKey]['weights'] = weights;
    this.setState((prevState) => ({
      benchmarkData: {
        ...prevState.benchmarkData,
        currentPlans: {
          ...prevState.benchmarkData.currentPlans,
          [benefitType]: currentPlansForBenefitType
        }
      }
    }));
  };

  render() {
    const {
      isSimilarPlansModalOpen,
      benchmarkData,
      metadata,
      validations,
      enableCommonErrors,
      isAddModelingEnabled,
      benefitTitleCategory
    } = this.state;
    const { benchmarkName } = benchmarkData;
    const {
      history,
      fetchBenchmarkByIdLoading,
      isCreatingBenchmark,
      match,
      benchmarkDataError
    } = this.props;
    const { benefitCategory, employerId, benchmarkId, brokerId } =
      match.params || {};
    if (!benchmarkData || !metadata || fetchBenchmarkByIdLoading) {
      return <Loader />;
    }
    const showCommonErrorMsg = enableCommonErrors && benchmarkDataError;
    const clusterSize = benchmarkData.cluster
      ? benchmarkData.cluster.clusterEmployers.length
      : 0;

    return (
      <ApplicationWrapper>
        <div className="benchmarks-current-modeling">
          <HeaderContainer
            auto_id="benchmarks"
            title={`${benefitTitleCategory} - Model Current Plans`}
            subtitle={benchmarkName}
            backButtonUnderTittle
            history={history}
            redirectBack={() => {
              this.props.history.push(
                redirectBack(
                  benefitCategory,
                  'current',
                  employerId,
                  benchmarkId,
                  brokerId
                )
              );
            }}
          />
          <Row>
            <Col className="page-content" xs="auto">
              <Row>
                <Col xs={3}>
                  <SelectFormGroup
                    labelDisplay="Plan Type"
                    inputName="planType"
                    inputValue={this.state.planTypeValue}
                    onChange={this.onChangePlanType}
                    options={this.attachCurrentPlanTypes(metadata)}
                  />
                </Col>
                <Col xs={4} className="page-actions action-btn-current-plans">
                  <Button
                    size="lg"
                    color="primary"
                    outline
                    className="btn-primary-benchmark"
                    onClick={this.addPlanType}
                    disabled={!isAddModelingEnabled}
                  >
                    <Icon
                      size="xs"
                      icon="plus"
                      className="icon-margin-right-benchmarks"
                    />
                    Add Modeling
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col className="page-actions" xs="auto">
              <div className="medical-benchmark-select-button-margin">
                <PageActionButton
                  type={isCreatingBenchmark ? 'progress' : ''}
                  onClick={this.saveAndNext}
                >
                  Save & Continue
                </PageActionButton>
                {this.renderSkip(benchmarkData, benefitCategory, isCreatingBenchmark)}
                {showCommonErrorMsg && (
                  <FormText color="invalid">
                    Please fix the validation errors.
                  </FormText>
                )}
              </div>
            </Col>
          </Row>
          <div className="plan-details row similar-plans-contaner">
            <div className="similar-plans float-right">
              <i>
                Currently includes &nbsp;
                <b>{`${clusterSize} similar employers`}</b>
              </i>
              {clusterSize > 0 && (
                <span
                  className="benchmark-btn-link similar-plans-view"
                  onClick={this.toggleSimilarPlansModal}
                >
                  view
                </span>
              )}
            </div>
          </div>
          <div className="header-divider">
            <hr />
          </div>
          {/* Malfunction styling is shown while saving and continue. There for this code snippet hide while saving */}
          {!fetchBenchmarkByIdLoading &&
            !isCreatingBenchmark &&
            getCurrentPlanRenderList(
              benchmarkData.currentPlans,
              this.updateSinglePlanDetail,
              this.updatePlanName,
              this.removePlanType,
              validations,
              this.onSelectBenchmarkPlan,
              benefitCategory,
              metadata,
              this.updateWeights
            )}
          <SimilarPlansModal
            isOpen={isSimilarPlansModalOpen}
            benefitCategory={benefitCategory}
            cluster={
              benchmarkData.cluster
                ? benchmarkData.cluster.clusterEmployers
                : []
            }
            toggle={this.toggleSimilarPlansModal}
          />
        </div>
      </ApplicationWrapper>
    );
  }
}

const getCurrentPlanRenderList = (
  currentPlans,
  updateSinglePlanDetail,
  updatePlanName,
  removePlanType,
  validations,
  onSelectBenchmarkPlan,
  benefitCategory,
  metadata,
  updateWeights
) => {
  let currentPlanTypes = [];
  if (!_.isEmpty(currentPlans)) {
    _.each(currentPlans[benefitCategory.toUpperCase()], function(
      currentPlan,
      key
    ) {
      currentPlanTypes.push(
        <Row className="plan-details" key={key}>
          <Col>
            <CurrentPlanDetails
              plan={currentPlan}
              planKey={key}
              onChange={updateSinglePlanDetail}
              onSelectBenchmarkPlan={onSelectBenchmarkPlan}
              validations={generateEmptyValidations(key, validations)}
              updatePlanName={updatePlanName}
              removePlanType={removePlanType}
              benefitCategory={benefitCategory}
              weights={
                metadata.weights &&
                metadata.weights[benefitCategory.toUpperCase()]
              }
              updateWeights={updateWeights}
            />
          </Col>
        </Row>
      );
    });
    return currentPlanTypes;
  }
};

export const generateEmptyValidations = (planKey, validations) => {
  if (_.isEmpty(validations)) {
    return {
      ...validations,
      [`isDeductibleSingleEmpty_${planKey}`]: false,
      [`isDeductibleFamilyEmpty_${planKey}`]: false,
      [`isOopMaxSingleEmpty_${planKey}`]: false,
      [`isOopMaxFamilyEmpty_${planKey}`]: false,
      [`isCoinsuaranceEmpty_${planKey}`]: false,
      [`isCopaySpecialistEmpty_${planKey}`]: false,
      [`isCopayPrimaryEmpty_${planKey}`]: false,
      [`isRxCostT1Empty_${planKey}`]: false,
      [`isRxCostT2Empty_${planKey}`]: false,
      [`isRxCostT3Empty_${planKey}`]: false,
      [`isRxCostT4Empty_${planKey}`]: false,
      [`isContributionEEEmpty_${planKey}`]: false,
      [`isContributionESEmpty_${planKey}`]: false,
      [`isContributionECEmpty_${planKey}`]: false,
      [`isContributionEFEmpty_${planKey}`]: false,
      [`isRatesEEEmpty_${planKey}`]: false,
      [`isRatesESEmpty_${planKey}`]: false,
      [`isRatesECEmpty_${planKey}`]: false,
      [`isRatesEFEmpty_${planKey}`]: false
    };
  }
  return validations;
};

/**
 * Creates a medical current plan template
 * @param {*} planTypeValue
 */
const newCurrentPlan = (planTypeValue, benefitCategory) => {
  let planDesign = {};
  if (benefitCategory === BENEFIT_KIND_MEDICAL) {
    planDesign = {
      deductible: {},
      oopMax: {},
      coinsurance: null,
      rxCost: {},
      copay: {}
    };
  } else if (benefitCategory === BENEFIT_KIND_DENTAL) {
    planDesign = {
      deductible: {},
      annualMaxBenefits: null,
      coinsurance: {},
      orthodontia: null,
      orthodontiaMaxBenefit: null
    };
  } else if (benefitCategory === BENEFIT_KIND_VISION) {
    planDesign = {
      copayExam: null,
      copayMaterials: null,
      frameAllowance: null,
      contactAllowance: null,
      freqOfService: null
    };
  }
  return {
    id: undefined,
    employerId: null,
    employerName: null,
    planName: planTypeValue,
    planType: planTypeValue,
    benefitCategory: benefitCategory.toUpperCase(),
    planDesign: planDesign,
    contribution: {},
    rates: {},
    hsaContribution: {},
    weights: null,
    enrollments: {}
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveCurrentMedicalPlans: (medicalPlans) =>
    dispatch(saveCurrentMedicalPlans(medicalPlans)),
  findBenchmark: (benchmarkId) => dispatch(findBenchmark(benchmarkId)),
  saveBenchmark: (benchmarkId, benchmark, employerId, step, benefitCategory) =>
    dispatch(
      saveBenchmark(benchmarkId, benchmark, employerId, step, benefitCategory)
    ),
  getMetadata: () => dispatch(getMetadata()),
  validateBenchmark: (benchmarkData, benefitCategory) =>
    dispatch(validateBenchmark(benchmarkData, benefitCategory))
});

const mapStateToProps = (state) => {
  const {
    currentPlanDetails,
    benchmark,
    benchmarkSaveData,
    metadata,
    fetchBenchmarkByIdLoading,
    benchmarkDataError
  } = state.benchmarkReducer;

  return {
    currentPlanDetails,
    benchmark,
    metadata,
    fetchBenchmarkByIdLoading,
    benchmarkSaveResult: benchmarkSaveData.benchmarkSaveResult,
    isCreatingBenchmark: benchmarkSaveData.isCreatingBenchmark,
    benchmarkDataError
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CurrentModeling);
