import React from 'react';
import { connect } from 'react-redux';
import * as BenchmarkRoutes from '../../routes';
import { generatePath } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Col,
  Row
} from 'components/Atoms';
import moment from 'moment';

import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ContentContainer from 'containers/ContentContainer';
import PageActionButton from 'components/Buttons';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import { NotificationManager } from 'react-notifications';
import { DataTableWithClientPagination } from 'components/DataTables';
import { permitIf } from 'components/hoc/Permit';
import { BENCHMARKS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';

import {
  getBenchmarksPage,
  findConfigStatus,
  createConfigModule,
  updateConfigModule,
  cloneBenchmark
} from '../../actions/benchmarkActions';
import { MODULE_BENCHMARKS_ENABLED } from '../../constants';

import './BenchmarksList.scss';
import Loader from '../../../../components/CircularProgress';

class BenchmarksList extends React.Component {
  constructor(props) {
    super(props);

    const { benchmarksPage } = this.props;
    this.state = {
      filteredBenchmarksPage: benchmarksPage
    };
  }

  componentDidMount() {
    const { match, findConfigStatus } = this.props;

    const { employerId } = match.params;

    findConfigStatus(employerId, MODULE_BENCHMARKS_ENABLED);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      clonedBenchmark,
      benchmarkCloningError,
      match,
      history,
      benchmarksPage,
      getBenchmarksPage,
      configStatus
    } = this.props;
    const { employerId, brokerId } = match.params;

    if (benchmarksPage && benchmarksPage !== prevProps.benchmarksPage) {
      this.setState({
        filteredBenchmarksPage: benchmarksPage
      });
    }

    if (
      benchmarkCloningError !== null &&
      prevProps.benchmarkCloningError !== benchmarkCloningError
    ) {
      NotificationManager.error('Error cloning Benchmark.');
    }

    if (
      clonedBenchmark !== null &&
      prevProps.clonedBenchmark !== clonedBenchmark
    ) {
      NotificationManager.success(
        `${clonedBenchmark.originBenchmarkName} Benchmark has been cloned.`
      );
      history.push(
        generatePath(BenchmarkRoutes.BENCHMARKS_EDIT_CENSUS_PATH, {
          brokerId,
          employerId,
          benchmarkId: clonedBenchmark.benchmarkId
        })
      );
    }
    if (configStatus !== prevProps.configStatus) {
      getBenchmarksPage(employerId, '');
    }
  }

  goToCreateBenchmark = () => {
    const { match, history } = this.props;
    history.push(
      generatePath(BenchmarkRoutes.BENCHMARKS_CREATE_PATH, { ...match.params })
    );
  };

  goToEditBenchmark = (benchmarkId) => {
    const { match, history } = this.props;
    const { employerId, brokerId } = match.params;
    history.push(
      generatePath(BenchmarkRoutes.BENCHMARKS_EDIT_CENSUS_PATH, {
        brokerId,
        employerId,
        benchmarkId
      })
    );
  };

  goToCloneBenchmark = (benchmarkId) => {
    this.props.cloneBenchmark(benchmarkId);
  };

  goToViewBenchmark = (benchmarkId) => {
    const { match, history } = this.props;
    const { employerId, brokerId } = match.params;
    history.push(
      generatePath(BenchmarkRoutes.BENCHMARKS_VIEW_CENSUS_PATH, {
        brokerId,
        employerId,
        benchmarkId
      })
    );
  };

  handleSearch = (event) => {
    const { benchmarksPage } = this.props;
    const text = event.target.value;
    let filteredBenchmarksPage = benchmarksPage.filter(({ benchmarkName }) =>
      benchmarkName.toLowerCase().includes(text.toLowerCase())
    );
    this.setState({
      filteredBenchmarksPage: filteredBenchmarksPage
    });
  };

  getActionsDropdown = (benchmark) => {
    const SecuredDropdownItem = permitIf(
      DropdownItem,
      BENCHMARKS_FEATURE_ROLE_MAPPING.common
    );

    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="a" className="cursor-pointer" caret>
          <b>Select</b>
        </DropdownToggle>

        <DropdownMenu>
          {(benchmark.status === null || benchmark.status !== 'PUBLISHED') && (
            <SecuredDropdownItem
              onClick={() => this.goToEditBenchmark(benchmark.benchmarkId)}
            >
              Edit
            </SecuredDropdownItem>
          )}
          <SecuredDropdownItem
            onClick={() => this.goToCloneBenchmark(benchmark.benchmarkId)}
          >
            Clone
          </SecuredDropdownItem>
          {benchmark.status === 'PUBLISHED' && (
            <DropdownItem
              onClick={() => this.goToViewBenchmark(benchmark.benchmarkId)}
            >
              View
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  /**
   * Enable Benchmarks Toggle
   */
  switchBenchmarksEnabled = () => {
    const { match } = this.props;
    const { params } = match;
    const { employerId } = params;
    const { id, value } = this.props.configStatus;
    if (!id) {
      this.props.createConfigModule(employerId);
    } else {
      this.props.updateConfigModule(id, employerId, value);
    }
    NotificationManager.success(
      `Benchmarks feature has been ${
        value === 'false' ? 'enabled' : 'disabled'
      }.`
    );
  };
  columnData = [
    {
      name: <div className="benchmarks-list-table-heading">Name</div>,
      selector: 'name',
      cell: (row) => (
        <div
          className={'cursor-pointer'}
          onClick={() =>
            row.status === null || row.status !== 'PUBLISHED'
              ? this.goToEditBenchmark(row.benchmarkId)
              : row.status === 'PUBLISHED' ? this.goToViewBenchmark(row.benchmarkId) : undefined
            }
        >
          {row.benchmarkName}
        </div>
      )
    },
    {
      name: (
        <div className="benchmarks-list-table-heading">Effective Dates</div>
      ),
      selector: 'date',
      cell: (row) => (
        <div
          className={'cursor-pointer'}
          onClick={() =>
            row.status === null || row.status !== 'PUBLISHED'
              ? this.goToEditBenchmark(row.benchmarkId)
              : row.status === 'PUBLISHED' ? this.goToViewBenchmark(row.benchmarkId) : undefined
          }
        >
          {moment(row.effectiveDateRange.startDate).format('ll') +
            ' - ' +
            moment(row.effectiveDateRange.endDate).format('ll')}
        </div>
      )
    },
    {
      name: <div className="benchmarks-list-table-heading">Status</div>,
      selector: 'status',
      cell: (row) =>
        row.status && (
          <span
            className={'cursor-pointer'}
            onClick={() =>
              row.status === null || row.status !== 'PUBLISHED'
                ? this.goToEditBenchmark(row.benchmarkId)
                : row.status === 'PUBLISHED' ? this.goToViewBenchmark(row.benchmarkId) : undefined
            }
          >
            {row.status.charAt(0).toUpperCase() +
              row.status.substr(1).toLowerCase()}
          </span>
        )
    },
    {
      name: <div className="benchmarks-list-table-heading">Created By</div>,
      selector: 'author',
      cell: (row) =>
        row.createdBy && (
          <span
            className={'cursor-pointer'}
            onClick={() =>
              row.status === null || row.status !== 'PUBLISHED'
                ? this.goToEditBenchmark(row.benchmarkId)
                : row.status === 'PUBLISHED' ? this.goToViewBenchmark(row.benchmarkId) : undefined
            }
          >
            {row.createdBy}
          </span>
        )
    },
    {
      name: <div className="benchmarks-list-table-heading">Last Updated</div>,
      selector: 'update',
      sortable: true,
      cell: (row) => (
        <div
          className={'cursor-pointer'}
          onClick={() =>
            row.status === null || row.status !== 'PUBLISHED'
              ? this.goToEditBenchmark(row.benchmarkId)
              : row.status === 'PUBLISHED' ? this.goToViewBenchmark(row.benchmarkId) : undefined
            }
        >
          {row.lastUpdatedTs ? moment(row.lastUpdatedTs).format('lll') : ''}
        </div>
      )
    },
    {
      name: <div className="benchmarks-list-table-heading">Actions</div>,
      selector: 'actions',
      cell: (row) => this.getActionsDropdown(row),
      ignoreRowClick: true,
      allowOverflow: true
    }
  ];

  render() {
    const SecuredPageActionButton = permitIf(
      PageActionButton,
      BENCHMARKS_FEATURE_ROLE_MAPPING.common
    );
    const { match } = this.props;
    const { params } = match;
    const { employerId } = params;
    const { benchmarksPage, configStatus, benchmarkPageFetching } = this.props;
    const { filteredBenchmarksPage } = this.state;

    return (
      <ApplicationWrapper>
        <HeaderContainer auto_id="benchmarks" title="Benchmarks">
          <Col className="page-actions-list-benchmarks float-right" xs="auto">
            <SecuredPageActionButton
              type="add"
              disabled={
                configStatus && configStatus.value === 'true' ? false : true
              }
              onClick={this.goToCreateBenchmark}
            >
              Add New Benchmark
            </SecuredPageActionButton>
          </Col>
        </HeaderContainer>
        <Row>
          <Col>
            <ContentContainer>
              <EmployerConfigToggleV2
                employerId={employerId}
                message="Benchmarks"
                config={configStatus}
                moduleEnabled={MODULE_BENCHMARKS_ENABLED}
                switchCallBack={this.switchBenchmarksEnabled}
              />
            </ContentContainer>
          </Col>
        </Row>
        <Row className="benchmarks-list-status-gap"></Row>
        <Row>
          <Col>
            {(configStatus ? configStatus.value === 'true' : false) &&
              (benchmarkPageFetching ? (
                <Loader />
              ) : (
                <ContentContainer>
                  {benchmarksPage.length > 0 && (
                    <DataTableWithClientPagination
                      columnData={this.columnData}
                      rowData={filteredBenchmarksPage}
                      pagination={true}
                      handleSearch={this.handleSearch}
                      onRowClicked={(row) =>
                        row.status === null || row.status !== 'PUBLISHED'
                          ? this.goToEditBenchmark(row.benchmarkId)
                          : row.status === 'PUBLISHED' ? this.goToViewBenchmark(row.benchmarkId) : undefined
                      }
                    />
                  )}

                  {benchmarksPage.length === 0 && (
                    <div>
                      Please create a new <strong>benchmark</strong> to proceed
                    </div>
                  )}
                </ContentContainer>
              ))}
          </Col>
        </Row>
      </ApplicationWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    benchmarksPage,
    configStatus,
    clonedBenchmark,
    benchmarkCloningError
  } = state.benchmarkReducer;

  return {
    configStatus: configStatus,
    benchmarksPage: benchmarksPage.data,
    clonedBenchmark,
    benchmarkCloningError,
    benchmarkPageFetching: benchmarksPage.isFetching
  };
};
const mapDispatchToProps = (dispatch) => ({
  getBenchmarksPage: (employerId, query) =>
    dispatch(getBenchmarksPage(employerId, query)),
  createConfigModule: (employerId) => dispatch(createConfigModule(employerId)),
  updateConfigModule: (id, employerId, value) =>
    dispatch(updateConfigModule(id, employerId, value)),
  findConfigStatus: (employerId, key) =>
    dispatch(findConfigStatus(employerId, key)),
  cloneBenchmark: (benchmarkId) => dispatch(cloneBenchmark(benchmarkId))
});

export default connect(mapStateToProps, mapDispatchToProps)(BenchmarksList);
