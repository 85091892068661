/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import OfferService from 'services/OfferService';
import {
  GET_OFFER_PLANS,
  GET_OFFER_DENTAL_PLANS,
  GET_OFFER_VISION_PLANS,
  GET_OFFER_LIFE_PLANS,
  UPLOAD_OFFER_PLANS_IN_PROGRESS,
  UPLOAD_OFFER_PROGRESS_NOTIFICATION,
  UPLOAD_OFFER_PLANS,
  UPLOAD_OFFER_ERROR,
  GET_OFFER_PLANS_ERROR,
  CLEAR_EXIST_MODELING_DATA
} from '../actionTypes/offer.actionsTypes';
import {
  BENEFIT_CATEGORY_DENTAL,
  BENEFIT_CATEGORY_LIFE,
  BENEFIT_CATEGORY_MEDICAL,
  BENEFIT_CATEGORY_VISION
} from '../constants';
import { createProgressDispatcher } from 'util/apiUtil';

export const listOfferPlanByType = (employerId, type) => {
  return async (dispatch) => {
    let response = null;
    try {
      switch (type) {
        case BENEFIT_CATEGORY_MEDICAL:
          response = await OfferService.findMedicalPlans(employerId);
          const plans = response.data;
          dispatch({ type: GET_OFFER_PLANS, payload: plans });
          break;
        case BENEFIT_CATEGORY_DENTAL:
          response = await OfferService.findDentalPlans(employerId);
          const dentalPlans = response.data;
          dispatch({ type: GET_OFFER_DENTAL_PLANS, payload: dentalPlans });
          break;
        case BENEFIT_CATEGORY_VISION:
          response = await OfferService.findVisionPlans(employerId);
          const visionPlans = response.data;
          dispatch({ type: GET_OFFER_VISION_PLANS, payload: visionPlans });
          break;
        case BENEFIT_CATEGORY_LIFE:
          response = await OfferService.findLifePlans(employerId);
          const lifePlans = response.data;
          dispatch({ type: GET_OFFER_LIFE_PLANS, payload: lifePlans });
          break;
        default:
          break;
      }
    } catch (error) {
      dispatch({ type: GET_OFFER_PLANS_ERROR, payload: error });
    }
  };
};
export const listOfferPlanBasicDataByType = (employerId, type) => {
  return async (dispatch) => {
    let response = null;
    try {
      switch (type) {
        case BENEFIT_CATEGORY_MEDICAL:
          response = await OfferService.findMedicalPlanBasicDetails(employerId);
          const plans = response.data;
          dispatch({ type: GET_OFFER_PLANS, payload: plans });
          break;
        case BENEFIT_CATEGORY_DENTAL:
          response = await OfferService.findBasicDentalPlans(employerId);
          const dentalPlans = response.data;
          dispatch({ type: GET_OFFER_DENTAL_PLANS, payload: dentalPlans });
          break;
        case BENEFIT_CATEGORY_VISION:
          response = await OfferService.findBasicVisionPlans(employerId);
          const visionPlans = response.data;
          dispatch({ type: GET_OFFER_VISION_PLANS, payload: visionPlans });
          break;
        case BENEFIT_CATEGORY_LIFE:
          response = await OfferService.findLifePlanBasicDetails(employerId);
          const lifePlans = response.data;
          dispatch({ type: GET_OFFER_LIFE_PLANS, payload: lifePlans });
          break;
        default:
          break;
      }
    } catch (error) {
      dispatch({ type: GET_OFFER_PLANS_ERROR, payload: error });
    }
  };
};

export const uploadOfferPlan = (employerId, fileName, fileBlob) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CLEAR_EXIST_MODELING_DATA });
      dispatch({ type: UPLOAD_OFFER_PLANS_IN_PROGRESS });
      const progressDispatcher = createProgressDispatcher(
        dispatch,
        UPLOAD_OFFER_PROGRESS_NOTIFICATION
      );

      const response = await OfferService.uploadOfferPlans(
        employerId,
        fileName,
        fileBlob,
        progressDispatcher
      );
      dispatch({ type: UPLOAD_OFFER_PLANS, payload: response.data });
    } catch (error) {
      dispatch({ type: UPLOAD_OFFER_ERROR, payload: error.response });
    }
  };
};
