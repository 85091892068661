/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { UncontrolledTooltip } from 'components/Atoms';
import Icon from 'components/Icons';
import { getPlanYearById } from 'util/PlanUtil';
import {
  getPlanYearField,
  getPlanYearRangeWithCurrent
} from '../../../util/planServiceUtil';
import './PlanYearField.scss';

class PlanYearField extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { row, planYears = 'Add Plan', documentId } = this.props;
    return (
      <div className="plan-year-section">
        <div className="plan-year-field">
          <span onClick={this.props.onClick}>
            {getPlanYearField(getPlanYearById(row.planYearId, planYears))}
          </span>
          <div
            id={`_${row.id ? row.id : documentId}`}
            className="plan-year-icon"
          >
            <Icon style={{ color: '#afacac' }} icon={faInfoCircle} />
          </div>
        </div>
        <UncontrolledTooltip
          hideArrow
          placement="right"
          target={`_${row.id ? row.id : documentId}`}
          autohide={true}
        >
          <span className="bold">
            {getPlanYearRangeWithCurrent(
              getPlanYearById(row.planYearId, planYears)
            )}
          </span>
        </UncontrolledTooltip>
      </div>
    );
  }
}
export default connect()(PlanYearField);
