/**********************************************************************************************************************
 * Copyright (C) 2019-2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import {
  UsersList,
  UserDetails,
  AdminRolesList,
  UsersListForRole,
  MobileVersions
} from './index';

export const PLATFORM_ADMINS_USERS_LIST_PATH = '/su/admins/users';
export const PLATFORM_ADMINS_USER_DETAILS_PATH = '/su/admins/users/:loginId';
export const PLATFORM_ADMINS_ROLES_LIST_PATH = '/su/admins/roles';
export const PLATFORM_ADMINS_USERS_LIST_FOR_ROLE_PATH =
  '/su/admins/roles/:roleName';
export const PLATFORM_ADMIN_MOBILE_VERSIONS_PATH = '/su/admins/mobile';

const adminsRoutes = [
  { path: PLATFORM_ADMINS_USERS_LIST_PATH, component: UsersList },
  { path: PLATFORM_ADMINS_USER_DETAILS_PATH, component: UserDetails },
  { path: PLATFORM_ADMINS_ROLES_LIST_PATH, component: AdminRolesList },
  {
    path: PLATFORM_ADMINS_USERS_LIST_FOR_ROLE_PATH,
    component: UsersListForRole
  },
  { path: PLATFORM_ADMIN_MOBILE_VERSIONS_PATH, component: MobileVersions }
];

export default adminsRoutes;
