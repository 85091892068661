/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'components/Atoms';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import NotificationManager from 'components/Notifications';
import {
  getBenGuideById,
  initBenGuide,
  initBenGuideValidations,
  updateBenGuide,
  updateBenGuideById,
  updateBenGuideValidations
} from '../../../actions/benGuidePlanAction';
import Loader from 'components/CircularProgress/Loader';
import TextEditor from 'components/DataForm/TextEditor';
import './perk.scss';
import { BEN_GUIDES_CLONE_PREVIEW_PATH, BEN_GUIDES_EDIT_PREVIEW_PATH } from '../../../routes';
import { generatePath } from 'react-router-dom';

const LazyLinks = React.lazy(() => import('./components/Links'));
const LazyOeVideo = React.lazy(() => import('./components/OeVideo/OeVideo'));
const LazyWhatsNew = React.lazy(() => import('./components/WhatsNew/WhatsNew'));
const LazyEligibilityRules = React.lazy(() =>
  import('./components/Eligibility/RulesQLEvents')
);
const LazyEligibilityWaiverCredits = React.lazy(() =>
  import('./components/Eligibility/WaiverCredits')
);
const LazyResourceDocuments = React.lazy(() =>
  import('./components/ResourceDocuments/ResourceDocuments')
);
const LazyPerksDocuments = React.lazy(() =>
  import('./components/AdditionalPerks/PerksDocuments')
);

class Other extends React.Component {
  static propTypes = {
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      saveAndContinue: false
    };
  }

  componentDidMount() {
    const { match, benGuideObj } = this.props;
    const { benGuideId } = match.params;
    if (benGuideObj === null) {
      const { match } = this.props;
      const { employerId } = match.params;
      this.props.getBenGuideById(benGuideId, employerId);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      benGuideObj,
      updateBenGuideInProgress,
      updateBenGuideError,
      match
    } = this.props;
    const { saveAndContinue } = this.state;
    const { employerId, benGuideId } = match.params;
    const { path } = match;
    if (
      !updateBenGuideInProgress &&
      prevProps.updateBenGuideInProgress &&
      benGuideObj &&
      updateBenGuideInProgress !== prevProps.updateBenGuideInProgress
    ) {
      if (updateBenGuideError) {
        NotificationManager.error(`${benGuideObj.name} could not be saved.`);
      } else {
        NotificationManager.success(
          `${benGuideObj.name} has been saved successfully.`
        );
        if (saveAndContinue) {
          if (path.includes('clone')) {
            this.props.history.push(
              generatePath(BEN_GUIDES_CLONE_PREVIEW_PATH, {
                ...match.params,
                employerId,
                benGuideId
              })
            );
          } else {
            this.props.history.push(
              generatePath(BEN_GUIDES_EDIT_PREVIEW_PATH, {
                ...match.params,
                employerId,
                benGuideId
              })
            );
          }
        }
      }
    }
  }

  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value.toString('html'));
    }
  };

  onWelcomeMessageChange = (value) => {
    this.props.updateBenGuide(this.props.benGuideObj, 'welcomeMessage', value);
  };

  /**
   * save and close.
   *
   * @param event
   * @returns {Promise<void>}
   */
  saveAndClose = (event) => {
    event.preventDefault();
    const { benGuideObj } = this.props;

    this.setState({
      saveAndContinue: false
    });
    this.props.updateBenGuideById(benGuideObj);
  };

  /**
   * save and go to the next screen.
   *
   * @param event
   * @returns {Promise<void>}
   */
  saveAndNext = (event) => {
    event.preventDefault();
    const { benGuideObj, match } = this.props;
    const { path } = match;
    this.setState({
      saveAndContinue: true
    });
    this.props.updateBenGuideById(benGuideObj, path.includes('clone'));
  };

  render() {
    const { match, benGuideObj = {} } = this.props;
    const { benGuideId, type } = match.params;
    const isReadOnlyView = type === 'view';

    if (benGuideObj) {
      const {
        taxAdvantagedAccountPlans = {},
        additionalPerks = {}
      } = benGuideObj;
      const {
        enabled: taxAdvantagedAccountEnabled
      } = taxAdvantagedAccountPlans;

      const { enabled: additionalPerksEnabled } = additionalPerks;

      return (
        <Row className="ben-guide-other">
          <Col className="col-10">
            <ApplicationWrapper>
              <HeaderContainer
                title="BenGuide - Other"
                subtitle={benGuideObj.name}
              />
              <ContentContainer
                header="Homepage - Welcome Message"
                className={`${isReadOnlyView ? 'disable-background' : ''}`}
              >
                <TextEditor
                  onChange={this.onWelcomeMessageChange}
                  inputValue={benGuideObj.welcomeMessage}
                  label="Homepage Welcome Message"
                  limit={500}
                  disabled={isReadOnlyView}
                />
              </ContentContainer>
              <Suspense fallback={<Loader />}>
                <LazyWhatsNew
                  benGuideId={benGuideId}
                  isReadOnlyView={isReadOnlyView}
                />
              </Suspense>
              <Suspense fallback={<Loader />}>
                <LazyOeVideo
                  benGuideId={benGuideId}
                  isReadOnlyView={isReadOnlyView}
                />
              </Suspense>
              {taxAdvantagedAccountEnabled && (
                <Suspense fallback={<Loader />}>
                  <LazyResourceDocuments isReadOnlyView={isReadOnlyView} />
                </Suspense>
              )}
              {additionalPerksEnabled && (
                <Suspense fallback={<Loader />}>
                  <LazyPerksDocuments isReadOnlyView={isReadOnlyView} />
                </Suspense>
              )}
              {taxAdvantagedAccountEnabled && (
                <Suspense fallback={<Loader />}>
                  <LazyLinks isReadOnlyView={isReadOnlyView} />
                </Suspense>
              )}
              <Suspense>
                <LazyEligibilityRules
                  benGuideId={benGuideId}
                  isReadOnlyView={isReadOnlyView}
                />
              </Suspense>
              <Suspense>
                <LazyEligibilityWaiverCredits
                  benGuideId={benGuideId}
                  isReadOnlyView={isReadOnlyView}
                />
              </Suspense>
            </ApplicationWrapper>
          </Col>
          {!isReadOnlyView && (
            <Col className="col-2 mt-5">
              <Button
                auto_id="save-and-continue"
                type="submit"
                color="primary"
                size="lg"
                className="right-side-button"
                onClick={(event) => this.saveAndNext(event)}
              >
                Save & Continue >
              </Button>
              <Button
                auto_id="save-and-close"
                type="submit"
                outline
                color="primary"
                size="lg"
                className="right-side-button mt-2"
                onClick={(event) => this.saveAndClose(event)}
              >
                Save
              </Button>
            </Col>
          )}
        </Row>
      );
    } else {
      return (
        <div className="text-center align-middle">
          <Loader />
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  initBenGuide: (benGuideObj) => dispatch(initBenGuide(benGuideObj)),
  initBenGuideValidations: () => dispatch(initBenGuideValidations()),
  updateBenGuideValidations: (validationObj) =>
    dispatch(updateBenGuideValidations(validationObj)),
  updateBenGuide: (benGuideObj, prop, value) =>
    dispatch(updateBenGuide(benGuideObj, prop, value)),
  getBenGuideById: (benGuideId, employerId) =>
    dispatch(getBenGuideById(benGuideId, employerId)),
  updateBenGuideById: (data, isCloned) =>
    dispatch(updateBenGuideById(data, isCloned))
});

const mapStateToProps = (state) => ({
  ...state.benGuideReducer.benGuideData
});

export default connect(mapStateToProps, mapDispatchToProps)(Other);
