/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import {
  DataTableWithServerPagination,
  ColumnFilter
} from 'components/DataTables';
import { SyncStatusBadge } from 'components/Badges';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from 'components/Atoms';
import BenAdminResponse from '../../../../../Employers/pages/EmployerDetails/containers/BenAdminResponse/BenAdminResponse';
// constants
import { FULL_DATE_FORMAT } from 'constants/dateConstants';
import {
  ADAPTER_NAMES,
  MENU_ACTIONS,
  STATUS_STATES,
  INTEGRATION_TYPES
} from 'modules/Integrations/constants';
import { EMDASH_LITERAL } from 'constants/commonConstants';
// styles
import './BenAdminIntegrationsSyncStatusList.scss';
import {
  BENADMIN_MENU_ACTIONS,
  SYNC_TYPE
} from 'modules/BenAdminIntegrations/constants';
import { EXPRESSION_PATTERN } from '../../../../constants';

export const fromFilters = [
  { value: 'FLOCK', label: 'Flock' },
  { value: 'WORKDAY_BENADMIN', label: 'Workday' }
];

export const statusFilters = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'SYNCING', label: 'Syncing' },
  { value: 'SUCCESS', label: 'Success' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'PARTIAL_SUCCESS', label: 'Partial Success' }
];

const customStyle = {
  headCells: {
    inactiveSortStyle: {
      opacity: 1,
      // Target the sort icon when inactive
      span: {
        opacity: 1
      }
    }
  }
};
class BenAdminIntegrationSyncStatusList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        status: [],
        from: []
      },
      isBenAdminModalOpen: false,
      benadminResponseEmployerId: ''
    };
    this._refreshTimer = setInterval(() => {
      this.reloadList();
    }, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this._refreshTimer);
  }

  updateFilterValues = (selector) => (values) => {
    let filters = { ...this.state.filters };
    filters[selector] = values;
    this.setState({ filters: filters }, () => {
      this.reloadList();
    });
  };

  reloadList = () => {
    this.syncStatusList.triggerReload();
  };

  resetFilters = () => {
    this.setState(
      {
        filters: {
          status: [],
          from: []
        }
      },
      () => this.reloadList()
    );
  };

  getAllBenAdminSyncDetails = (page, size, sort, query, type) => {
    const { filters } = this.state;
    const { getAllSyncDetails, organizationId } = this.props;
    getAllSyncDetails({
      page,
      size,
      sort,
      query,
      filters,
      type,
      organizationId
    });
  };

  renderActionsCell = (row) => {
    const { syncStatus } = row;
    const { benAdminMenuClick } = this.props;

    const failedMenuOptions = [
      {
        label: 'View Sync Details',
        action: BENADMIN_MENU_ACTIONS.VIEW_SYNC_DETAILS
      },
      {
        label: 'Download Employee Sync Details',
        syncType: SYNC_TYPE.EMPLOYEE_SYNC,
        action: BENADMIN_MENU_ACTIONS.DOWNLOAD_EMPLOYEE_SYNC_DETAILS
      },
      {
        label: 'Download Enrollment Sync Details',
        syncType: SYNC_TYPE.ENROLLMENT_SYNC,
        action: BENADMIN_MENU_ACTIONS.DOWNLOAD_ENROLLMENT_SYNC_DETAILS
      }
    ];
    let menuOptions = [];

    if (
      syncStatus === STATUS_STATES.FAILED ||
      syncStatus === STATUS_STATES.PARTIAL_SUCCESS ||
      syncStatus === STATUS_STATES.SUCCESS
    ) {
      menuOptions = [...failedMenuOptions];
    }
    if (
      !(
        syncStatus === STATUS_STATES.SYNCING ||
        syncStatus === STATUS_STATES.PENDING
      ) &&
      row.id !== null
    ) {
      return (
        <UncontrolledDropdown className="actions">
          <DropdownToggle tag="a" className="cursor-pointer" caret>
            Select
          </DropdownToggle>
          <DropdownMenu>
            {row.source === ADAPTER_NAMES.WORKDAY_BENADMIN.code && (
              <DropdownItem
                onClick={() => {
                  this.setState({
                    isBenAdminModalOpen: true,
                    benadminResponseEmployerId: row.employerId
                  });
                }}
              >
                Download BenAdmin Response
              </DropdownItem>
            )}
            {menuOptions.map(({ label, action, syncType }, index) => (
              <DropdownItem
                key={index}
                onClick={() => benAdminMenuClick(action, row, syncType)}
              >
                {label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  };

  formatScheduleTimes = (dailySyncSchedule) => {
    if (dailySyncSchedule) {
      const timeSchedules = dailySyncSchedule
        .substring(4)
        .replace(EXPRESSION_PATTERN.END, '')
        .split(',');
      let schedules = [];
      timeSchedules.forEach((schedule) => {
        schedules.push(
          ('0' + schedule).slice(-2) + EXPRESSION_PATTERN.TIME_FORMAT
        );
      });

      const convertedSchedules = schedules.map((schedule) => {
        const timeUTC = moment.utc(schedule, 'HH.mm');
        return timeUTC.tz('America/Los_Angeles').format('HH.mm');
      });

      if (convertedSchedules && convertedSchedules.length >= 5) {
        return (
          <div
            className="daily-schedules"
            data-toggle="tooltip"
            title={convertedSchedules.join('  ')}
          >
            {convertedSchedules.join(', ')}
          </div>
        );
      } else {
        return <span>{convertedSchedules.join(', ')}</span>;
      }
    }
  };

  renderLastRunTime = (endedTs) => {
    return (
      <div
        data-toggle="tooltip"
        title={moment(endedTs).format(FULL_DATE_FORMAT)}
      >
        {moment(endedTs).format(FULL_DATE_FORMAT)}
      </div>
    );
  };

  toggleBenAdminResponseModal = () => {
    this.setState({
      isBenAdminModalOpen: !this.state.isBenAdminModalOpen
    });
  };

  render() {
    const { data, organizationId } = this.props;
    const { filters } = this.state;
    return (
      <>
        <DataTableWithServerPagination
          onRef={(ref) => (this.syncStatusList = ref)}
          className="list-benadmin-integrations"
          customStyle={customStyle}
          hideSearchBar
          noDataComponent={
            <div className="no-data-reset">
              <p className="message">No Records to Display</p>
              <Button color="link" onClick={this.resetFilters}>
                Reset
              </Button>
            </div>
          }
          columnData={[
            {
              name: 'Employer',
              selector: 'employerName',
              grow: 1.5,
              cell: ({ employerName, employerId }) => {
                return (
                  <Link
                    to={`/brokers/${organizationId}/employers/${employerId}/benadmin-integrations`}
                  >
                    {employerName}
                  </Link>
                );
              }
            },
            {
              name: 'Daily Schedule (PST)',
              selector: 'scheduledTimes',
              grow: 2.5,
              cell: ({ scheduledTimes }) => {
                if (scheduledTimes) {
                  return this.formatScheduleTimes(scheduledTimes);
                }
              }
            },
            {
              name: (
                <ColumnFilter
                  columnHeader="From"
                  filters={fromFilters}
                  selectedFilters={filters.from}
                  onFilter={this.updateFilterValues('from')}
                />
              ),
              selector: 'from',
              sortable: false,
              grow: 1.5,
              cell: ({ source }) =>
                (source && ADAPTER_NAMES[source].name) || '-'
            },
            {
              name: 'To',
              selector: 'to',
              sortable: false,
              grow: 1.5,
              cell: ({ target }) =>
                (target && ADAPTER_NAMES[target].name) || '-'
            },
            {
              name: 'Last Runtime',
              selector: 'endedTs',
              sortable: false,
              grow: 2,
              format: (row) => {
                const { endedTs } = row;
                return endedTs
                  ? this.renderLastRunTime(endedTs)
                  : EMDASH_LITERAL;
              }
            },
            {
              name: (
                <ColumnFilter
                  columnHeader="Status"
                  filters={statusFilters}
                  selectedFilters={filters.status}
                  onFilter={this.updateFilterValues('status')}
                />
              ),
              grow: 3,
              selector: 'status',
              cell: ({ syncStatus }) => {
                return (
                  <div>
                    <SyncStatusBadge syncStatus={syncStatus} />
                  </div>
                );
              }
            },
            {
              name: 'Actions',
              grow: 1,
              ignoreRowClick: true,
              allowOverflow: true,
              cell: (data) => {
                return this.renderActionsCell(data);
              }
            }
          ]}
          fetchData={(
            page,
            size,
            sort,
            query,
            type = INTEGRATION_TYPES.BENADMIN
          ) => this.getAllBenAdminSyncDetails(page, size, sort, query, type)}
          pageData={data}
        />
        <BenAdminResponse
          isOpen={this.state.isBenAdminModalOpen}
          employerId={this.state.benadminResponseEmployerId}
          toggle={this.toggleBenAdminResponseModal}
        />
      </>
    );
  }
}

export default BenAdminIntegrationSyncStatusList;
