/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import { MOBILE_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { permitIf } from 'components/hoc/Permit';
import {
  createConfigModule,
  findConfigStatus,
  getDBGEmployer,
  updateConfigModule,
  updateDBGChatbotConfig
} from '../actions/chatbotConfigActions';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import {
  FEATURE_KEY_DBG_CHATBOT,
  UPDATE_CHATBOT_TYPE_DIGITAL_BENGUIDE
} from '../constants';
import DBGChatBotMessageConfig from './DBGChatBotMessageConfig';
import { FEATURE_KEY_BENEFIT_CONSULTATION } from '../../Mobile/constants';

class DBGChatbotConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      welcomeMessage: '',
      farewellMessage: '',
      customerSupportCompatible: false,
      lumityConsultCompatible: false,
      isSavedChatbotConfig: false,
      showNotification: true
    };
  }

  componentDidMount() {
    const { findConfigStatus, match, getDBGEmployer } = this.props;
    const { employerId } = match.params;
    findConfigStatus(employerId, FEATURE_KEY_DBG_CHATBOT);
    findConfigStatus(employerId, FEATURE_KEY_BENEFIT_CONSULTATION);
    getDBGEmployer(employerId);
  }

  componentDidUpdate = (prevProps) => {
    const {
      employerChatbot,
      employerDBGChatbotConfigLoading,
      dbgChatbotConfigUpdating
    } = this.props;
    const { showNotification, isSavedChatbotConfig } = this.state;
    if (
      employerChatbot &&
      prevProps.employerDBGChatbotConfigLoading &&
      employerDBGChatbotConfigLoading !== prevProps.employerDBGChatbotConfigLoading &&
      !isSavedChatbotConfig
    ) {
      this.updateDBGChatbotConfig();
    }

    if (
      prevProps.dbgChatbotConfigUpdating &&
      dbgChatbotConfigUpdating !== prevProps.dbgChatbotConfigUpdating &&
      showNotification
    ) {
      NotificationManager.success(
        `"Lumity" the Digital Benguide chatbot assistant configurations have been saved.`
      );
    }
  };

  updateDBGChatbotConfig = () => {
    const { chatbotConfigSettings } = this.props.employerChatbot;
    const { digitalBenguideChatbotConfig } = chatbotConfigSettings;
    const {
      welcomeMessage,
      farewellMessage,
      fallbackOptions
    } = digitalBenguideChatbotConfig;
    this.setState({
      welcomeMessage: welcomeMessage,
      farewellMessage: farewellMessage,
      customerSupportCompatible:
        fallbackOptions && fallbackOptions.customerSupportCompatible
          ? fallbackOptions.customerSupportCompatible
          : false,
      lumityConsultCompatible:
        fallbackOptions && fallbackOptions.lumityConsultCompatible
          ? fallbackOptions.lumityConsultCompatible
          : false
    });
  };

  switchChatbotEnabled = () => {
    const {
      match,
      dbgChatbotConfigStatus,
      createConfigModule,
      updateConfigModule,
      employerChatbot
    } = this.props;
    const { employerId } = match.params;
    const { id, value } = dbgChatbotConfigStatus;
    if (!id) {
      createConfigModule(employerId, FEATURE_KEY_DBG_CHATBOT);
    } else {
      updateConfigModule(id, employerId, value, FEATURE_KEY_DBG_CHATBOT);
    }
    if (
      employerChatbot &&
      employerChatbot.chatbotConfigSettings &&
      employerChatbot.chatbotConfigSettings.digitalBenguideChatbotConfig &&
      !employerChatbot.chatbotConfigSettings.digitalBenguideChatbotConfig.welcomeMessage
    ) {
      const fallbackOptions = {
        customerSupportCompatible: true,
        lumityConsultCompatible: false
      };
      const config = {
        fallbackOptions: fallbackOptions,
        chatbotType: UPDATE_CHATBOT_TYPE_DIGITAL_BENGUIDE
      };
      this.setState({ showNotification: false }, () => {
        this.onSaveChatbotConfigs(config);
      });
    }
    NotificationManager.success(
      `"Lumity" the Digital Benguide chatbot assistant has been ${
        value === 'false' ? 'enabled' : 'disabled'
      }.`
    );
  };

  onSaveChatbotConfigs = (config) => {
    const { employerId } = this.props.match.params;
    const { welcomeMessage, farewellMessage, fallbackOptions } = config;
    const {
      customerSupportCompatible,
      lumityConsultCompatible
    } = fallbackOptions;
    this.setState(
      {
        isSavedChatbotConfig: true,
        welcomeMessage,
        farewellMessage,
        customerSupportCompatible,
        lumityConsultCompatible
      },
      () => {
        this.props.updateDBGChatbotConfig(employerId, config);
      }
    );
  };

  render() {
    let {
      welcomeMessage,
      farewellMessage,
      lumityConsultCompatible,
      customerSupportCompatible
    } = this.state;

    const { employerChatbot } = this.props;

    if (employerChatbot !== undefined) {
      const { chatbotConfigSettings } = employerChatbot;
      if (chatbotConfigSettings !== undefined) {
        const { digitalBenguideChatbotConfig } = chatbotConfigSettings;
        if (digitalBenguideChatbotConfig !== undefined) {
          if (!welcomeMessage) {
            welcomeMessage = digitalBenguideChatbotConfig.welcomeMessage;
          }
          if (!farewellMessage) {
            farewellMessage = digitalBenguideChatbotConfig.farewellMessage;
          }
        }
      }
    }

    const { configStatus, dbgChatbotConfigStatus, bConsultationConfigStatus, match } = this.props;
    const { employerId } = match.params;
    const SecuredEmployerConfigToggle = permitIf(
      EmployerConfigToggleV2,
      MOBILE_FEATURE_ROLE_MAPPING.common
    );
    return (
      <ApplicationWrapper>
        <ContentContainer>
          <SecuredEmployerConfigToggle
            employerId={employerId}
            moduleEnabled={FEATURE_KEY_DBG_CHATBOT}
            message={'Digital Benefits Guide'}
            config={dbgChatbotConfigStatus}
            switchCallBack={this.switchChatbotEnabled}
          >
            <hr className='divider' />
            <div className='mt-4'>
              <DBGChatBotMessageConfig
                onSaveChatbotConfigs={this.onSaveChatbotConfigs}
                welcomeMessage={welcomeMessage}
                farewellMessage={farewellMessage}
                lumityConsultCompatible={lumityConsultCompatible}
                customerSupportCompatible={customerSupportCompatible}
                bConsultationConfigStatus={bConsultationConfigStatus}
              />
            </div>
          </SecuredEmployerConfigToggle>
        </ContentContainer>
        {/*)}*/}
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createConfigModule: (employerId, key) =>
    dispatch(createConfigModule(employerId, key)),
  updateConfigModule: (id, employerId, value, key) =>
    dispatch(updateConfigModule(id, employerId, value, key)),
  findConfigStatus: (employerId, key) =>
    dispatch(findConfigStatus(employerId, key)),
  updateDBGChatbotConfig: (employerId, config) =>
    dispatch(updateDBGChatbotConfig(employerId, config)),
  getDBGEmployer: (employerId) => dispatch(getDBGEmployer(employerId))
});

const mapStateToProps = (state) => {
  const {
    employerConfigCreate,
    employerConfigCreateError,
    employerConfigUpdate,
    employerConfigUpdateError,
    configStatus,
    dbgChatbotConfigStatus,
    bConsultationConfigStatus,
    employerChatbot,
    employerDBGChatbotConfigLoading,
    dbgChatbotConfigUpdating
  } = state.chatbotConfigReducer;
  return {
    employerConfigCreate,
    employerConfigCreateError,
    employerConfigUpdate,
    employerConfigUpdateError,
    configStatus,
    dbgChatbotConfigStatus,
    bConsultationConfigStatus,
    employerChatbot,
    employerDBGChatbotConfigLoading,
    dbgChatbotConfigUpdating
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DBGChatbotConfig);
