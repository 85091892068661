/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import OfferService from 'services/OfferService';
import {
  GET_OFFERS,
  GET_DENTAL_OFFERS,
  GET_VISION_OFFERS,
  GET_OFFERS_ERROR,
  GET_LIFE_OFFERS,
  CREATE_OFFER,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_ERROR,
  ADD_PLANS_TO_OFFER_ERROR,
  UPDATE_PLAN_CONTRIBUTIONS,
  UPDATE_PLAN_CONTRIBUTIONS_ERROR,
  GET_MEDICAL_OFFERS_TO_PUBLISH,
  GET_DENTAL_OFFERS_TO_PUBLISH,
  GET_VISION_OFFERS_TO_PUBLISH,
  GET_LIFE_OFFERS_TO_PUBLISH,
  PUBLISH_OFFER_ERROR,
  GET_OFFERS_TO_PUBLISH_ERROR,
  ADD_PLANS_TO_OFFER_IN_PROGRESS,
  ADD_PLANS_TO_OFFER_SUCCESS,
  GET_OFFER_BUNDLE_IN_PROGRESS,
  GET_OFFER_BUNDLE_SUCCESS,
  GET_OFFER_BUNDLE_ERROR,
  GET_CARRIERS_IN_PROGRESS,
  GET_CARRIERS_SUCCESS
} from '../actionTypes/offer.actionsTypes';
import OfferResourceService from 'services/OfferResourceService';
import {
  BENEFIT_KIND_MEDICAL,
  BENEFIT_KIND_DENTAL,
  BENEFIT_KIND_VISION,
  BENEFIT_KIND_LIFE
} from '../constants';
import CarrierService from 'services/CarrierService';

export const listOffersByCategory = (employerId, type) => {
  return async (dispatch) => {
    let response = null;
    try {
      switch (type) {
        case BENEFIT_KIND_MEDICAL:
          response = await OfferService.findMedicalOffers(employerId);
          const plans = response.data;
          dispatch({ type: GET_OFFERS, payload: plans });
          break;
        case BENEFIT_KIND_DENTAL:
          response = await OfferService.findDentalOffers(employerId);
          const dentalPlans = response.data;
          dispatch({ type: GET_DENTAL_OFFERS, payload: dentalPlans });
          break;
        case BENEFIT_KIND_VISION:
          response = await OfferService.findVisionOffers(employerId);
          const visionPlans = response.data;
          dispatch({ type: GET_VISION_OFFERS, payload: visionPlans });
          break;
        case BENEFIT_KIND_LIFE:
          response = await OfferService.findLifeOffers(employerId);
          const lifePlans = response.data;
          dispatch({ type: GET_LIFE_OFFERS, payload: lifePlans });
          break;
        default:
          break;
      }
    } catch (error) {
      dispatch({ type: GET_OFFERS_ERROR, payload: error });
    }
  };
};

const isValidOfferToPublish = (bundle) => {
  let validBundle = false;
  if (bundle.status !== 'PUBLISHED' && bundle.type !== 'CURRENT') {
    validBundle = true;
  }
  return validBundle;
};

const getValidOffers = (bundles) => {
  const bundlesToPublish = [];
  for (let indx in bundles) {
    const bundle = bundles[indx];
    if (isValidOfferToPublish(bundle)) {
      bundlesToPublish.push(bundle);
    }
  }
  return bundlesToPublish;
};

export const listOffersToPublish = (employerId, type) => {
  return async (dispatch) => {
    let response = null;
    try {
      switch (type) {
        case BENEFIT_KIND_MEDICAL:
          response = await OfferService.findMedicalOffers(employerId);
          const medicalOffersToPublish = getValidOffers(response.data);
          dispatch({
            type: GET_MEDICAL_OFFERS_TO_PUBLISH,
            payload: medicalOffersToPublish
          });
          break;
        case BENEFIT_KIND_DENTAL:
          response = await OfferService.findDentalOffers(employerId);
          const dentalOffersToPublish = getValidOffers(response.data);
          dispatch({
            type: GET_DENTAL_OFFERS_TO_PUBLISH,
            payload: dentalOffersToPublish
          });
          break;
        case BENEFIT_KIND_VISION:
          response = await OfferService.findVisionOffers(employerId);
          const visionOffersToPublish = getValidOffers(response.data);
          dispatch({
            type: GET_VISION_OFFERS_TO_PUBLISH,
            payload: visionOffersToPublish
          });
          break;
        case BENEFIT_KIND_LIFE:
          response = await OfferService.findLifeOffers(employerId);
          const lifeOffersToPublish = getValidOffers(response.data);
          dispatch({
            type: GET_LIFE_OFFERS_TO_PUBLISH,
            payload: lifeOffersToPublish
          });
          break;
        default:
          break;
      }
    } catch (error) {
      dispatch({ type: GET_OFFERS_TO_PUBLISH_ERROR, payload: error });
    }
  };
};

/**
 * Publish bundles.
 * @param {*} bundles The list of bundle Ids to be published
 */
export const publishOffers = (bundles) => {
  return async (dispatch) => {
    try {
      await OfferResourceService.publishOffers(bundles);
    } catch (error) {
      dispatch({ type: PUBLISH_OFFER_ERROR, payload: error });
    }
  };
};

export const createOffer = (type, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_OFFER, payload: { data } });
      const response = await OfferResourceService.saveOffer(data);
      const bundle = response.data;
      dispatch({ type: CREATE_OFFER_SUCCESS, payload: { bundle } });
    } catch (e) {
      dispatch({ type: CREATE_OFFER_ERROR, payload: { e } });
    }
  };
};
export const updateOffer = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_OFFER, payload: { data } });
      const response = await OfferResourceService.updateOffer(data);
      const bundle = response.data;
      dispatch({ type: CREATE_OFFER_SUCCESS, payload: { bundle } });
    } catch (e) {
      dispatch({ type: CREATE_OFFER_ERROR, payload: { e } });
    }
  };
};

/**
 * Add list of plans to a given group.
 * @param {*} bundleId The proposal bundle reference where the plans needs to be added.
 * @param category
 * @param {*} plans The plans to be added to the group.
 */
export const addPlansToProposalOffer = (bundleId, category, plans) => {
  return async (dispatch, getState) => {
    let offers = null;
    let filteredBundle = null;

    try {
      dispatch({ type: ADD_PLANS_TO_OFFER_IN_PROGRESS });
      switch (category) {
        case BENEFIT_KIND_MEDICAL:
          offers = getState().offersReducer.OfferReducer.offersToPublish
            .medicalOffersToPublish;
          filteredBundle = offers.filter((group) => group.id === bundleId);
          filteredBundle[0].medicalPlans = plans.length
            ? plans.map((plan) => plan.id)
            : [];
          await OfferService.addPlansToProposalOffer(
            bundleId,
            filteredBundle[0]
          );
          break;
        case BENEFIT_KIND_DENTAL:
          offers = getState().offersReducer.OfferReducer.offersToPublish
            .dentalOffersToPublish;
          filteredBundle = offers.filter((group) => group.id === bundleId);
          filteredBundle[0].dentalPlans =
            plans.length > 0 ? plans.map((plan) => plan.id) : [];
          await OfferService.addPlansToProposalOffer(
            bundleId,
            filteredBundle[0]
          );
          break;
        case BENEFIT_KIND_VISION:
          offers = getState().offersReducer.OfferReducer.offersToPublish
            .visionOffersToPublish;
          filteredBundle = offers.filter((group) => group.id === bundleId);
          filteredBundle[0].visionPlans =
            plans.length > 0 ? plans.map((plan) => plan.id) : [];
          await OfferService.addPlansToProposalOffer(
            bundleId,
            filteredBundle[0]
          );
          break;
        case BENEFIT_KIND_LIFE:
          offers = getState().offersReducer.OfferReducer.offersToPublish
            .lifeOffersToPublish;
          filteredBundle = offers.filter((group) => group.id === bundleId);
          filteredBundle[0].lifePlans =
            plans.length > 0 ? plans.map((plan) => plan.id) : [];
          await OfferService.addPlansToProposalOffer(
            bundleId,
            filteredBundle[0]
          );
          break;
        default:
          break;
      }
      dispatch({ type: ADD_PLANS_TO_OFFER_SUCCESS });
    } catch (error) {
      dispatch({ type: ADD_PLANS_TO_OFFER_ERROR, payload: error });
    }
  };
};

/***
 * Update Plan Contributions
 *
 * @param bundleId
 * @param planContributions
 * @param applyAll
 * @returns {Function}
 */
export const updatePlanContributions = (
  bundleId,
  planContributions,
  applyAll
) => {
  return async (dispatch) => {
    try {
      await OfferResourceService.updatePlanContributions(
        bundleId,
        planContributions,
        applyAll
      );
      dispatch({ type: UPDATE_PLAN_CONTRIBUTIONS });
    } catch (e) {
      dispatch({ type: UPDATE_PLAN_CONTRIBUTIONS_ERROR, payload: { e } });
    }
  };
};

/**
 * get selected proposal bundle by ID
 * @param bundleId
 * @returns {function(...[*]=)}
 */
export const getOfferByID = (bundleId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_OFFER_BUNDLE_IN_PROGRESS });
      const response = await OfferResourceService.getBundleById(bundleId);
      dispatch({ type: GET_OFFER_BUNDLE_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({ type: GET_OFFER_BUNDLE_ERROR, payload: e });
    }
  };
};

export const getCarriers = (category) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CARRIERS_IN_PROGRESS });
      const response = await CarrierService.findByBenefitCategory(category);
      dispatch({ type: GET_CARRIERS_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({ type: GET_CARRIERS_SUCCESS, payload: e });
    }
  };
};
