import * as ActionTypes from '../actionTypes/calendar.actionTypes';
import CalendarType from '../constants';

const ASSOCIATED = 'ASSOCIATED';
const UNASSOCIATED = 'UNASSOCIATED';

export default (
  state = {
    calendarObj: null,
    error: null,
    calendarTemplates: [],
    calendarTemplatesError: null,
    showLoading: false,
    calendarStatusError: undefined,
    copyEventsError: undefined,
    getCalendarsLoading: false,
    calendarTemplatesGetInProgress: false,
    calendarTempType: null,
    tempAssignedQBR: null,
    tempAssignedCompliance: null
  },
  { type, payload, more }
) => {
  /* eslint-disable */
  switch (type) {
    case ActionTypes.GET_CALENDARS:
      return {
        ...state,
        calendarObj: payload,
        calendarStatusError: undefined,
        copyEventsError: undefined,
        getCalendarsLoading: false
      };
    case ActionTypes.GET_CALENDARS_ERROR:
      return {
        ...state,
        error: payload,
        getCalendarsLoading: false
      };
    case ActionTypes.GET_CALENDARS_IN_PROGRESS:
      return {
        ...state,
        getCalendarsLoading: true
      };
    case ActionTypes.GET_CALENDAR_TEMPLATES:
      return {
        ...state,
        calendarTemplates:
          more === UNASSOCIATED ? payload : state.calendarTemplates,
        tempAssignedQBR:
          more === ASSOCIATED && payload[0] && payload[0].templateType === CalendarType.QBR.value
            ? payload
            : state.tempAssignedQBR,
        tempAssignedCompliance:
          more === ASSOCIATED && payload[0] && payload[0].templateType === CalendarType.COMPLIANCE.value
            ? payload
            : state.tempAssignedCompliance,
        copyEventsError: undefined,
        calendarTemplatesGetInProgress: false
      };
    case ActionTypes.GET_CALENDAR_TEMPLATES_ERROR:
      return {
        ...state,
        tempAssignedCompliance:null,
        tempAssignedQBR:null,
        calendarTemplatesError: payload,
        calendarTemplatesGetInProgress: false
      };
    case ActionTypes.SAVE_CALENDARS:
      return {
        ...state,
        showLoading: false,
        calendarStatusError: undefined
      };
    case ActionTypes.UPDATE_STATUS_IN_PROGRESS:
      return {
        ...state,
        showLoading: true,
        calendarStatusError: undefined
      };
    case ActionTypes.SAVE_CALENDARS_ERROR:
      return {
        ...state,
        showLoading: false,
        calendarStatusError: payload
      };
    case ActionTypes.COPY_EVENTS_ERROR:
      return {
        ...state,
        copyEventsError: payload
      };
    case ActionTypes.GET_CALENDAR_TEMPLATES_IN_PROGRESS:
      return {
        ...state,
        calendarTemplatesGetInProgress:true,
      };
    default:
      return {
        ...state
      };
  }
  /* eslint-enable */
};
