/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class FileBrowser extends Component {
  static propTypes = {
    uploaderId: PropTypes.string.isRequired,
    // Expects (event) => any
    onSelect: PropTypes.func.isRequired,
    acceptedFileTypes: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string
    ]).isRequired,
    // Allows parents to get a reference to this component and access its properties
    // Expects (ref) => any, where ref is this constant's ref
    onRef: PropTypes.func,
    hidden: PropTypes.bool
  };

  static defaultProps = {
    onRef: (ref) => {},
    hidden: true
  };

  componentDidMount() {
    // Allows parent to set ref for this component and use its methods, mainly click()
    const { uploaderId, onRef } = this.props;
    onRef && onRef(this.refs[uploaderId]);
  }

  render() {
    const { uploaderId, onSelect, acceptedFileTypes, hidden } = this.props;
    return (
      <input
        type="file"
        id={`${uploaderId}_fileBrowser`}
        ref={uploaderId}
        onInput={(event) => {
          onSelect(event);
          // Reset chosen file
          this.refs[uploaderId].value = null;
        }}
        accept={
          Array.isArray(acceptedFileTypes)
            ? acceptedFileTypes.join(', ')
            : acceptedFileTypes
        }
        hidden={hidden}
      />
    );
  }
}
