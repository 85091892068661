/*
 * Copyright (C) 2019 Lumity, Inc. - All Rights Reserved
 *
 * CONFIDENTIAL
 *
 * All information contained herein is, and remains the property of Lumity, Inc. and its partners, if any.
 * The intellectual and technical concepts contained herein are proprietary to Lumity, Inc. and its partners
 * and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless
 * prior written permission is obtained from Lumity, Inc.
 */
import React from 'react';
import { Button } from 'components/Atoms';
import Icon from 'components/Icons';
import './AddButton.scss';

const AddButton = (props) => {
  const { onClick, title, iconType, classes, disabled, loading } = props;
  return (
    <Button
      color="primary"
      size="lg"
      onClick={onClick}
      className={`${classes ? classes : ''} add-button`}
      disabled={disabled}
    >
      {iconType && (
        <Icon icon={loading ? 'circle-notch' : iconType} spin={loading} />
      )}
      <span className={iconType ? 'ml-2' : ''}>{title}</span>
    </Button>
  );
};

export default AddButton;
