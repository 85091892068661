import React from 'react';
import {
  FormGroup,
  InputGroup,
  Label,
  InputGroupAddon,
  InputGroupText,
  FormFeedback
} from 'components/Atoms';
import NumberFormat from 'react-number-format';

import './FormGroup.scss';

class NumberAddonInputGroup extends React.Component {
  getInputInRange = () => {
    const { min, max, inputValue } = this.props;
    const isMinNumber = typeof min === 'number';
    const isMaxNumber = typeof max === 'number';
    const valueNumber = Number(inputValue);
    if (isNaN(valueNumber)) {
      return true;
    }
    if (isMinNumber && isMaxNumber) {
      return min <= valueNumber && valueNumber <= max;
    } else if (isMinNumber && !isMaxNumber) {
      return min <= valueNumber;
    } else if (!isMinNumber && isMaxNumber) {
      return valueNumber <= max;
    } else {
      return true;
    }
  };

  getFeedbackText = (inputValue) => {
    const { min, max, isEmptyAllowed } = this.props;
    const isMinNumber = typeof min === 'number';
    const isMaxNumber = typeof max === 'number';

    if (!isEmptyAllowed && inputValue === '') {
      return 'This field cannot be empty.';
    } else if (isMinNumber && isMaxNumber) {
      return `Please enter a valid number within ${min} and ${max}.`;
    } else if (isMinNumber && !isMaxNumber) {
      return `Please enter a valid number greater than or equal to ${min}.`;
    } else if (!isMinNumber && isMaxNumber) {
      return `Please enter a valid number less than or equal to ${max}.`;
    } else {
      return 'Please enter a valid number.';
    }
  };

  render() {
    const {
      className,
      labelDisplay,
      inputName,
      inputValue,
      onNumberChange,
      placeholder,
      addonType,
      addonText,
      min,
      allowNegative,
      integerOnly,
      decimalScale,
      fixedDecimalScale,
      maxLength,
      isEmptyAllowed,
      disabled
    } = this.props;

    // TODO: Clean up current uses of allowNegative to use only bool and consolidate with min property
    const resolvedAllowNegative =
      allowNegative === true || allowNegative === 'true' || min < 0;
    const isInvalid =
      !this.getInputInRange() || (inputValue === '' && !isEmptyAllowed);

    return (
      <FormGroup className={`number-addon-input-group ${className || ''}`}>
        {labelDisplay && (
          <Label className="input-label" for={inputName}>
            {labelDisplay}
          </Label>
        )}
        <InputGroup>
          {addonType === 'prepend' && (
            <InputGroupAddon addonType={addonType}>
              <InputGroupText>{addonText}</InputGroupText>
            </InputGroupAddon>
          )}
          <NumberFormat
            className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
            type="text"
            id={inputName}
            value={isNaN(Number.parseFloat(inputValue)) ? '' : inputValue}
            onValueChange={(values) => onNumberChange(inputName, values.value)}
            placeholder={placeholder}
            allowNegative={resolvedAllowNegative}
            decimalScale={integerOnly || !decimalScale ? 0 : decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            maxLength={maxLength}
            disabled={disabled}
          />
          {addonType === 'append' && (
            <InputGroupAddon addonType="append">
              <InputGroupText>{addonText}</InputGroupText>
            </InputGroupAddon>
          )}
        </InputGroup>
        <FormFeedback className={isInvalid ? 'is-invalid' : ''}>
          {this.getFeedbackText(inputValue)}
        </FormFeedback>
      </FormGroup>
    );
  }
}
export default NumberAddonInputGroup;
