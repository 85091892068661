/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import axios from 'axios';
import Util from 'util/apiUtil';

const baseURL = `${Util.baseApi}/${Util.version}/surveys`;

export default {
  /**
   * get all surveys by given query object
   * @param page
   * @param size
   * @param sort
   * @param query
   * @returns {AxiosPromise<any>}
   */
  getSurveys({ employerId, page, size, sort, query }) {
    return axios.get(
      `${baseURL}?includeDraftStatus=true&employerId=${employerId}&${Util.getPagingQueryString(
        page,
        size,
        sort,
        query
      )}`
    );
  },

  getSurveyDetails(surveyId) {
    const params = {};
    return axios.get(`${baseURL}/${surveyId}`, { params: params });
  },
  
  createSurvey(surveyDetails) {
    const params = {};
    return axios.post(`${baseURL}`, surveyDetails, { params: params });
  },

  updateSurvey(surveyDetails) {
    const params = {};
    return axios.put(`${baseURL}/${surveyDetails.id}`, surveyDetails, {
      params: params
    });
  },

  deleteSurvey(employerId, surveyId) {
    return axios.delete(`${baseURL}/${surveyId}`);
  },

  getSurveyParticipants({ surveyId, page, size, sort, query }) {
    return axios.get(
      `${baseURL}/${surveyId}/participants?${Util.getPagingQueryString(
        page,
        size,
        sort,
        query
      )}`
    );
  },
  sendSurvey(surveyId, participantList) {
    return axios.post(
      `${baseURL}/${surveyId}/participants/notifications`,
      participantList || undefined
    );
  },

  publishSurvey(surveyId) {
    return axios.post(`${baseURL}/${surveyId}/status?status=AWAITING_APPROVAL`);
  },

  downloadSurveyResponses(surveyId) {
    return window.open(`${baseURL}/${surveyId}/responses`);
  }
};
