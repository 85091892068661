/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
const LIFE_LIST_PATH = '/brokers/:brokerId/employers/:employerId/life-plans';
const LIFE_BASIC_LIFE_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/basic-life/create';
const LIFE_BASIC_LIFE_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/basic-life/:planId/edit';
const LIFE_BASIC_LIFE_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/basic-life/:planId/view';
const LIFE_BASIC_LIFE_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/basic-life/:planId/clone';
const LIFE_BASIC_ADD_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/basic-add/create';
const LIFE_BASIC_ADD_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/basic-add/:planId/edit';
const LIFE_BASIC_ADD_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/basic-add/:planId/view';
const LIFE_BASIC_ADD_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/basic-add/:planId/clone';
const LIFE_BASIC_LIFE_AND_ADD_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/life-add/create';
const LIFE_BASIC_LIFE_AND_ADD_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/life-add/:planId/edit';
const LIFE_BASIC_LIFE_AND_ADD_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/life-add/:planId/view';
const LIFE_BASIC_LIFE_AND_ADD_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/life-add/:planId/clone';
const LIFE_STD_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/std/create';
const LIFE_STD_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/std/:planId/edit';
const LIFE_STD_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/std/:planId/view';
const LIFE_STD_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/std/:planId/clone';
const LIFE_LTD_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/ltd/create';
const LIFE_LTD_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/ltd/:planId/edit';
const LIFE_LTD_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/ltd/:planId/view';
const LIFE_LTD_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/ltd/:planId/clone';
const LIFE_VOL_LIFE_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/voluntary-life/create';
const LIFE_VOL_LIFE_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/voluntary-life/:planId/edit';
const LIFE_VOL_LIFE_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/voluntary-life/:planId/view';
const LIFE_VOL_LIFE_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/voluntary-life/:planId/clone';
const LIFE_VOL_ADD_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/voluntary-add/create';
const LIFE_VOL_ADD_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/voluntary-add/:planId/edit';
const LIFE_VOL_ADD_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/voluntary-add/:planId/view';
const LIFE_VOL_ADD_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/life-plans/voluntary-add/:planId/clone';

export {
  LIFE_LIST_PATH,
  LIFE_BASIC_LIFE_CREATE_PATH,
  LIFE_BASIC_LIFE_EDIT_PATH,
  LIFE_BASIC_ADD_CREATE_PATH,
  LIFE_BASIC_ADD_EDIT_PATH,
  LIFE_BASIC_LIFE_AND_ADD_CREATE_PATH,
  LIFE_BASIC_LIFE_AND_ADD_EDIT_PATH,
  LIFE_STD_CREATE_PATH,
  LIFE_STD_EDIT_PATH,
  LIFE_LTD_CREATE_PATH,
  LIFE_LTD_EDIT_PATH,
  LIFE_VOL_LIFE_CREATE_PATH,
  LIFE_VOL_LIFE_EDIT_PATH,
  LIFE_VOL_ADD_CREATE_PATH,
  LIFE_VOL_ADD_EDIT_PATH,
  LIFE_BASIC_LIFE_VIEW_PATH,
  LIFE_BASIC_ADD_VIEW_PATH,
  LIFE_BASIC_LIFE_AND_ADD_VIEW_PATH,
  LIFE_STD_VIEW_PATH,
  LIFE_VOL_LIFE_VIEW_PATH,
  LIFE_VOL_ADD_VIEW_PATH,
  LIFE_LTD_VIEW_PATH,
  LIFE_BASIC_LIFE_CLONE_PATH,
  LIFE_BASIC_ADD_CLONE_PATH,
  LIFE_BASIC_LIFE_AND_ADD_CLONE_PATH,
  LIFE_STD_CLONE_PATH,
  LIFE_VOL_LIFE_CLONE_PATH,
  LIFE_VOL_ADD_CLONE_PATH,
  LIFE_LTD_CLONE_PATH,
};
