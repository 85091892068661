/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/documents.actionTypes';

const initialState = {
  documentsStatus: {}
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPLOAD_PLAN_DOCUMENT_IN_PROGRESS:
      return {
        ...state,
        documentsStatus: {
          ...state.documentsStatus,
          [action.documentType]: {
            ...state.documentsStatus[action.documentType],
            isUploading: true,
            error: null
          }
        }
      };
    case ActionTypes.UPLOAD_PLAN_DOCUMENT_COMPLETED:
      return {
        ...state,
        documentsStatus: {
          ...state.documentsStatus,
          [action.documentType]: {
            ...state.documentsStatus[action.documentType],
            isUploading: false,
            error: null
          }
        }
      };
    case ActionTypes.UPLOAD_PLAN_DOCUMENT_FAILED:
      return {
        ...state,
        documentsStatus: {
          ...state.documentsStatus,
          [action.documentType]: {
            ...state.documentsStatus[action.documentType],
            isUploading: false,
            error: action.payload
          }
        }
      };
    case ActionTypes.DELETE_PLAN_DOCUMENT_IN_PROGRESS:
      return {
        ...state,
        documentsStatus: {
          ...state.documentsStatus,
          [action.documentType]: {
            ...state.documentsStatus[action.documentType],
            isDeleting: true,
            error: null
          }
        }
      };
    case ActionTypes.DELETE_PLAN_DOCUMENT_COMPLETED:
      return {
        ...state,
        documentsStatus: {
          ...state.documentsStatus,
          [action.documentType]: {
            ...state.documentsStatus[action.documentType],
            isDeleting: false,
            error: null
          }
        }
      };
    case ActionTypes.DELETE_PLAN_DOCUMENT_FAILED:
      return {
        ...state,
        documentsStatus: {
          ...state.documentsStatus,
          [action.documentType]: {
            ...state.documentsStatus[action.documentType],
            isDeleting: false,
            error: action.payload
          }
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default documentsReducer;
