/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Paginator, {
  DEFAULT_ROWS_PER_PAGE
} from 'components/Paginator/Paginator';
import Searchbar from 'components/Searchbar/Searchbar';
import Loader from 'components/CircularProgress';
import { DEBOUNCE_WAIT_TIME_SEARCH } from 'util/apiUtil';

import DataTable from './DataTable';
import './DataTable.scss';
export default class DataTableWithServerPagination extends Component {
  constructor(props) {
    super(props);

    const { columnData, defaultSortField } = props;

    this.state = {
      start: 0,
      end: 0,
      total: 0,
      totalPages: 0,
      currentPage: 1,
      // Initialized after Paginator's componentDidMount() calls fetchList
      rowData: [],
      sortField:
        defaultSortField || columnData.find((v) => v.selector).selector,
      query: '',
      // TODO: Replace state's fetching with props' isFetching control the prop from redux object.
      fetching: true
    };

    this.debouncedReload = _.debounce(
      this.triggerReload,
      DEBOUNCE_WAIT_TIME_SEARCH
    );
    this.paginatorRef = React.createRef();
  }

  componentDidMount() {
    // Allows parent to set ref for this component and use its methods, mainly triggerReload()
    this.props.onRef && this.props.onRef(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { pageData } = this.props;

    if (pageData !== undefined && pageData !== prevProps.pageData) {
      const { metadata, content } = pageData;
      const { page, start, end, total, totalPages } = metadata;
      this.setState({
        start: start,
        end: end,
        total: total,
        totalPages: totalPages,
        currentPage: page,
        rowData: content,
        fetching: false
      });
    }
  }

  triggerReload = () => {
    this.fetchList(1, DEFAULT_ROWS_PER_PAGE);
    // Reset rowPerPage to default value in paginator
    this.paginatorRef.current.setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
  };
  triggerReloadToSamePage = () => {
    this.fetchList(this.state.currentPage, DEFAULT_ROWS_PER_PAGE);
    // Reset rowPerPage to default value in paginator
    this.paginatorRef.current.setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
  };

  // All fetching is called by Paginator's reloadList, including initialization
  fetchList = (pageNumber, rowsPerPage) => {
    const { fetchData } = this.props;
    const { sortField, query } = this.state;
    fetchData(pageNumber, rowsPerPage, sortField, query);
  };

  toggleSortRows = async (column, sortDirection) => {
    const { sortField } = this.state;
    const { defaultSortDesc, defaultSortField } = this.props;
    let sortCharacter;
    if (defaultSortDesc && column.selector === defaultSortField) {
      sortCharacter = sortDirection === 'asc' ? '-' : '';
    } else {
      // Ascending sort by default.
      sortCharacter = sortDirection === 'desc' ? '-' : '';
    }
    this.setState(
      {
        sortField: sortCharacter + _.defaultTo(column.selector, sortField)
      },
      () => this.triggerReload()
    );
  };

  handleSearch = (event) => {
    event.preventDefault();
    this.setState(
      {
        query: event.target.value
      },
      this.debouncedReload
    );
  };
  resetSearch = () => {
    this.setState(
      {
        query: ''
      },
      this.debouncedReload
    );
  };

  getMultiSelectConfig(selectableRows) {
    if (selectableRows) {
      const { onSelectedRowsChange, clearSelectedRows } = this.props;
      return {
        selectableRows,
        onSelectedRowsChange,
        clearSelectedRows
      };
    }
    return {};
  }

  render() {
    const {
      columnData,
      hideSearchBar,
      onRowClicked,
      defaultSortDesc,
      noDataComponent,
      selectableRows = false,
      customStyle = false,
      persistTableHead = false,
      hasExport,
      onExport,
      pointerOnHover = false,
      conditionalRowStyles = []
    } = this.props;
    const {
      start,
      end,
      total,
      totalPages,
      currentPage,
      rowData,
      sortField,
      fetching,
      query
    } = this.state;
    return (
      <>
        {fetching ? (
          <Loader />
        ) : (
          <>
            {/* Searchbar */}
            {!hideSearchBar && (
              <div>
                <div style={{ float: 'left' }}>
                  <Searchbar value={query} handleSearch={this.handleSearch} />
                </div>
                {hasExport && (
                  <div style={{ float: 'right' }}>
                    <div
                      onClick={() => {
                        onExport(query, sortField);
                      }}
                      className={'export-table-link'}
                    >
                      Export to Excel
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* Table */}
            <DataTable
              pointerOnHover={pointerOnHover}
              columns={columnData}
              data={rowData}
              defaultSortField={sortField}
              onSort={this.toggleSortRows}
              // Sorting is done in the servers so skip local sort
              sortFunction={(rows, field, direction) => {
                return rows;
              }}
              noDataComponent={noDataComponent}
              customStyle={customStyle}
              persistTableHead={persistTableHead}
              onRowClicked={onRowClicked}
              defaultSortAsc={!defaultSortDesc}
              conditionalRowStyles={conditionalRowStyles}
              {...this.getMultiSelectConfig(selectableRows)}
            />
          </>
        )}
        {/* Paginator */}
        <Paginator
          ref={this.paginatorRef}
          start={start}
          end={end}
          total={total}
          totalPages={totalPages}
          currentPage={currentPage}
          reloadList={this.fetchList}
        />
      </>
    );
  }
}

DataTableWithServerPagination.propTypes = {
  // Columns must be in a format specified here: https://www.npmjs.com/package/react-data-table-component#columns
  columnData: PropTypes.array.isRequired,
  // fetchData: (pageNumber, rowsPerPage, sortField, query) => apiResponse
  // Expected to fetch new data given the arguments above
  fetchData: PropTypes.func.isRequired,
  // pageData should be the unpacked result from fetchData
  pageData: PropTypes.shape({
    metadata: PropTypes.shape({
      page: PropTypes.number,
      start: PropTypes.number,
      end: PropTypes.number,
      total: PropTypes.number,
      totalPages: PropTypes.number
    }),
    content: PropTypes.array
  }).isRequired,
  // Defaults to first column's selector from columnData
  defaultSortField: PropTypes.string,
  hideSearchBar: PropTypes.bool,
  // onRowClicked: (rows, index) => void
  onRowClicked: PropTypes.func
};

DataTableWithServerPagination.defaultProps = {
  hideSearchBar: false,
  onRowClicked: (row, index) => {}
};
