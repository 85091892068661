
/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import moment from 'moment';

/**
 * Retun next 2 months + last 12 months with the year
 * [January 2020, December 2020, ...]
 */
export const getBillingMonthList = () => {
  let months = [];
  for (let i = 0; i < 14; i++) {
    months.push(
      moment()
        .subtract(i - 2, 'months')
        .startOf('month')
        .format('MMMM YYYY')
    );
  }
  return months;
};
