import React, { Component } from 'react';

import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import './PercentileSlider.scss';

export function Handle({ handle: { id, value, percent }, getHandleProps }) {
  return (
    <div
      style={{
        left: `${percent}%`,
      }}
      className="triangle-wrapper"
      {...getHandleProps(id)}
    >
      <div className="triangle-up"></div>
      <div className="triangle-up-inner">{value}</div>
    </div>
  );
}

function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`
      }}
      {
        ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
      }
    />
  );
}

class PercentileSlider extends Component {
  render() {
    const { onChange, values = [0, 100] } = this.props;
    return (
      <div className="benchmarks-percentile-slider">
        <Slider
          className="sliderStyle" /* inline styles for the outer div. Can also use className prop. */
          domain={[0, 100]}
          step={1}
          mode={2}
          values={values}
          onChange={onChange}
        >
          <Rail>
            {({ getRailProps }) => (
              <div className="railStyle" {...getRailProps()} />
            )}
          </Rail>

          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    );
  }
}

export default PercentileSlider;
