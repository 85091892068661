import React from 'react';
import _ from 'lodash';
import { FormGroup, Label, Input, FormText } from 'components/Atoms';

class TextAreaFormGroup extends React.Component {
  render() {
    const {
      labelDisplay,
      inputName,
      inputValue,
      onChange,
      disabled,
      placeholder,
      maxLength,
      showRemainingCharacters,
      invalid,
      className,
      rows = 0
    } = this.props;
    return (
      <FormGroup>
        <Label className="input-label" for={inputName}>{labelDisplay}</Label>
        <Input
          type="textarea"
          id={inputName}
          key={inputName}
          name={inputName}
          value={inputValue || ''}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          invalid={invalid}
          className={className}
          rows={rows}
        />
        {_.defaultTo(showRemainingCharacters, true) && maxLength && (
          <FormText className="character-count-text">{`
          Max Character Count: ${maxLength -
            (inputValue ? inputValue.length : 0)}/${maxLength}`}</FormText>
        )}
      </FormGroup>
    );
  }
}

export default TextAreaFormGroup;
