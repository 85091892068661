/**********************************************************************************************************************
 * Copyright (C) 2019-2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/employers.actionTypes';

const initialState = {
  activeEmployersData: {
    employers: [],
    inProgress: false,
    error: null
  },
  issueLogFeatureConfig: {
    data: {},
    loading: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ACTIVE_EMPLOYERS_IN_PROGRESS:
      return {
        ...state,
        activeEmployersData: {
          ...state.activeEmployersData,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.GET_ACTIVE_EMPLOYERS_COMPLETED:
      let employerList = action.payload;
      if (Array.isArray(employerList) && employerList.length > 0) {
        employerList = action.payload.sort((a, b) => {
          let nameA = a['name'].toUpperCase();
          let nameB = b['name'].toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }
      return {
        ...state,
        activeEmployersData: {
          employers: employerList,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.GET_ACTIVE_EMPLOYERS_FAILED:
      return {
        ...state,
        activeEmployersData: {
          ...state.activeEmployersData,
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_ISSUE_LOG_FEATURE_CONFIG_IN_PROGRESS:
      return {
        ...state,
        issueLogFeatureConfig: {
          data: {},
          loading: true,
          error: null
        }
      };
    case ActionTypes.GET_ISSUE_LOG_FEATURE_CONFIG_SUCCESS:
      return {
        ...state,
        issueLogFeatureConfig: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
    case ActionTypes.GET_ISSUE_LOG_FEATURE_CONFIG_FAILED:
      return {
        ...state,
        issueLogFeatureConfig: {
          data: {},
          loading: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
