/*
 * Copyright (C) 2020 Lumity, Inc. - All Rights Reserved
 *
 * CONFIDENTIAL
 *
 * All information contained herein is, and remains the property of Lumity, Inc. and its partners, if any.
 * The intellectual and technical concepts contained herein are proprietary to Lumity, Inc. and its partners
 * and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless
 * prior written permission is obtained from Lumity, Inc.
 */
import React, { Component } from 'react';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import ToggleEnabledUploader from 'components/ToggleEnabledUploader/ToggleEnabledUploader';
import './Insight.scss';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import { connect } from 'react-redux';
import {
  getFileHistory,
  getInsightsFeatureSummary,
  uploadFile,
  findConfigStatus,
  createConfigModule,
  updateConfigModule
} from './../../actions/insightsAction';

import { changeChartStatus } from '../../actions/insightsAction';
import { NotificationManager } from 'react-notifications';
import InsightsService from 'services/InsightsService';
import _ from 'lodash';
import { INSIGHTS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import {
  MODULE_ANALYTICS_ENABLED,
  MODULE_CLAIMS_ENABLED
} from '../../constants';
import MessageIndicator from './components/MessageIndicator/MessageIndicator';
const ANALYTICS = 'COMMON_ANALYTICS';
const LOSS_RATIO = 'LRCB';
const LARGE_CLAIMS = 'LARGE_CLAIMS';
const NETWORK_UTILIZATION = 'NETWORK_UTILIZATION';
const DRUGS_UTILIZATION = 'DRUGS_UTILIZATION';
const SELF_FUNDED_CLAIMS_MEDICAL = 'SELF_FUNDED_CLAIMS_MEDICAL';
const SELF_FUNDED_CLAIMS_OTHERS = 'SELF_FUNDED_CLAIMS_OTHERS';

const CHART_TYPE_DISPLAY_MAP = {
  COMMON_ANALYTICS: 'Analytics',
  LRCB: 'Fully Insured Claims Loss Ratio',
  LARGE_CLAIMS: 'Large Claims',
  NETWORK_UTILIZATION: 'Utilization-Provider-In-Network vs Out of Network',
  DRUGS_UTILIZATION: 'Utilization Drugs',
  SELF_FUNDED_CLAIMS_MEDICAL: 'Self Funded Claims Medical',
  SELF_FUNDED_CLAIMS_OTHERS: 'Self Funded Claims Dental, Vision and STD'
};
class Insights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insightsData: []
    };
  }

  componentDidMount() {
    const { match, getInsightsByEmployer, findConfigStatus } = this.props;
    const { employerId } = match.params;
    getInsightsByEmployer(employerId);
    findConfigStatus(employerId, MODULE_ANALYTICS_ENABLED);
    findConfigStatus(employerId, MODULE_CLAIMS_ENABLED);
  }

  switchsEnabled = (configStatus, key) => {
    const { match } = this.props;
    const { employerId } = match.params;
    const { id, value } = configStatus;

    if (!id) {
      this.props.createConfigModule(employerId, true, key);
    } else {
      this.props.updateConfigModule(id, employerId, value, key);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { match } = this.props;
    const { employerId } = match.params;
    const {
      updatingChartType,
      chartStatusUpdateInProgress,
      fileUploadError,
      insightsData,
      fileUploadInProgress
    } = this.props;
    if (
      updatingChartType &&
      !chartStatusUpdateInProgress &&
      !this.props.chartStatusUpdateError &&
      prevProps.chartStatusUpdateInProgress !== chartStatusUpdateInProgress
    ) {
      this.props.getInsightsByEmployer(employerId);
      NotificationManager.success(
        `${CHART_TYPE_DISPLAY_MAP[updatingChartType]} chart status updated successfully`
      );
    }
    if (
      !fileUploadError &&
      !fileUploadInProgress &&
      prevProps.fileUploadInProgress !== fileUploadInProgress
    ) {
      this.props.getInsightsByEmployer(employerId);

      const response = this.props.uploadedFile;
      const type = response.type;
      this.props.getFileHistory(employerId, type);
      const errorFile = _.find(response.files, { type: 'ERROR_FILE' });
      //show error message when file has a invalid column or data
      if (errorFile !== undefined) {
        NotificationManager.error('Error uploading file');
      } else {
        NotificationManager.success('File uploaded successfully');
      }
    }
    if (
      !fileUploadInProgress &&
      prevProps.fileUploadError !== fileUploadError
    ) {
      const message = fileUploadError.response.data.code;
      if (message !== null) {
        NotificationManager.error(message);
      } else {
        NotificationManager.error('Error uploading file');
      }
      this.props.getInsightsByEmployer(employerId);
    }
    if (insightsData && insightsData !== prevProps.insightsData) {
      if (updatingChartType) {
        const updatedObject = this.getChartTypeData(
          insightsData,
          updatingChartType
        );
        let latestInsights = [...insightsData];
        const updatedInsightIndex = _.findIndex(latestInsights, {
          type: updatedObject.type
        });
        latestInsights.splice(updatedInsightIndex, 1, updatedObject);
        this.setState({
          insightsData: latestInsights
        });
      } else {
        this.setState({ insightsData: insightsData });
      }
    }
  }

  toggleCallback = (type, enabled) => {
    const { changeChartStatus, match } = this.props;
    const { employerId } = match.params;
    const data = {
      type: type,
      enabled: enabled
    };
    changeChartStatus(employerId, data);
  };
  downloadFile = (employerId, documentType, downloadType) => {
    window.location.href = InsightsService.getInsightsFileDownloadUrl(
      employerId,
      documentType,
      downloadType
    );
  };
  downloadHistoryFile = (employerId, fileId) => {
    window.location.href = InsightsService.getHistoryFileDownloadUrl(
      employerId,
      fileId
    );
  };
  getChartTypeData = (insightsData, chartType) => {
    return (
      _.find(insightsData, function(obj) {
        return obj.type === chartType;
      }) || {}
    );
  };

  getFileHistory = (chartType) => {
    const { match } = this.props;
    const { employerId } = match.params;
    this.props.getFileHistory(employerId, chartType);
  };

  render() {
    const {
      uploadFile,
      fileUploadInProgress,
      updatingChartType,
      fileHistoryLoading,
      insightsFileHistory,
      fileHistoryChartType,
      analyticsConfigStatus,
      claimsConfigStatus,
      match
    } = this.props;
    const { insightsData } = this.state;
    const { employerId } = match.params;
    const analyticData = this.getChartTypeData(insightsData, ANALYTICS);
    const largeClaimsData = this.getChartTypeData(insightsData, LARGE_CLAIMS);
    const lossRatioClaimsData = this.getChartTypeData(insightsData, LOSS_RATIO);
    const networkUtilizationData = this.getChartTypeData(
      insightsData,
      NETWORK_UTILIZATION
    );
    const drugsUtilizationData = this.getChartTypeData(
      insightsData,
      DRUGS_UTILIZATION
    );
    const selfFundedClaimsMedicalData = this.getChartTypeData(
      insightsData,
      SELF_FUNDED_CLAIMS_MEDICAL
    );

    const selfFundedClaimsOtherData = this.getChartTypeData(
      insightsData,
      SELF_FUNDED_CLAIMS_OTHERS
    );

    return (
      <ApplicationWrapper>
        <HeaderContainer title="Insights" />
        <ContainerHeader title="Analytics" />
        <div className="insights">
          <ContentContainer>
            <EmployerConfigToggleV2
              toggleId="analytics_toggle_button"
              employerId={employerId}
              moduleEnabled={MODULE_ANALYTICS_ENABLED}
              message="Analytics"
              config={analyticsConfigStatus}
              rolesList={INSIGHTS_FEATURE_ROLE_MAPPING.common}
              switchCallBack={() => {
                this.switchsEnabled(
                  analyticsConfigStatus,
                  MODULE_ANALYTICS_ENABLED
                );
              }}
            >
              <div className="mt-4">
                <ToggleAndUploader
                  toggleId="analytics_upload_toggle_button"
                  uploadId="analytics_upload_button"
                  title="Analytics"
                  config={analyticData}
                  employerId={employerId}
                  chartType={ANALYTICS}
                  confirmCallback={this.toggleCallback}
                  uploadFile={uploadFile}
                  fileUploadInProgress={fileUploadInProgress}
                  updatingChartType={updatingChartType}
                  downloadFile={this.downloadFile}
                  fileHistoryLoading={fileHistoryLoading}
                  insightsFileHistory={insightsFileHistory}
                  fileHistoryChartType={fileHistoryChartType}
                  getFileHistory={this.getFileHistory}
                  downloadHistoryFile={this.downloadHistoryFile}
                />
              </div>
            </EmployerConfigToggleV2>
          </ContentContainer>
          <ContainerHeader title="Claims" />
          <ContentContainer>
            <EmployerConfigToggleV2
              toggleId="claims_toggle_button"
              employerId={employerId}
              moduleEnabled={MODULE_CLAIMS_ENABLED}
              message="Claims"
              config={claimsConfigStatus}
              rolesList={INSIGHTS_FEATURE_ROLE_MAPPING.common}
              switchCallBack={() => {
                this.switchsEnabled(claimsConfigStatus, MODULE_CLAIMS_ENABLED);
              }}
            >
              <div className="mt-4">
                <ToggleAndUploader
                  toggleId="lrcb_toggle_button"
                  uploadId="lrcb_upload_button"
                  title="Fully Insured Claims-Loss Ratio / Claims Breakdown"
                  config={lossRatioClaimsData}
                  employerId={employerId}
                  chartType={LOSS_RATIO}
                  confirmCallback={this.toggleCallback}
                  uploadFile={uploadFile}
                  fileUploadInProgress={fileUploadInProgress}
                  updatingChartType={updatingChartType}
                  downloadFile={this.downloadFile}
                  fileHistoryLoading={fileHistoryLoading}
                  insightsFileHistory={insightsFileHistory}
                  fileHistoryChartType={fileHistoryChartType}
                  getFileHistory={this.getFileHistory}
                  downloadHistoryFile={this.downloadHistoryFile}
                />
                <ToggleAndUploader
                  toggleId="self_funded_claims_medical_toggle_button"
                  uploadId="self_funded_claims_medical_upload_button"
                  title="Self-Funded Claims(Medical)-Breakdown"
                  config={selfFundedClaimsMedicalData}
                  employerId={employerId}
                  chartType={SELF_FUNDED_CLAIMS_MEDICAL}
                  confirmCallback={this.toggleCallback}
                  uploadFile={uploadFile}
                  fileUploadInProgress={fileUploadInProgress}
                  updatingChartType={updatingChartType}
                  downloadFile={this.downloadFile}
                  fileHistoryLoading={fileHistoryLoading}
                  insightsFileHistory={insightsFileHistory}
                  fileHistoryChartType={fileHistoryChartType}
                  getFileHistory={this.getFileHistory}
                  downloadHistoryFile={this.downloadHistoryFile}
                />
                <ToggleAndUploader
                  toggleId="self_funded_claims_other_toggle_button"
                  uploadId="self_funded_claims_other_upload_button"
                  title="Self-Funded Claims(Dental,Vision,STD)-Breakdown"
                  config={selfFundedClaimsOtherData}
                  employerId={employerId}
                  chartType={SELF_FUNDED_CLAIMS_OTHERS}
                  confirmCallback={this.toggleCallback}
                  uploadFile={uploadFile}
                  fileUploadInProgress={fileUploadInProgress}
                  updatingChartType={updatingChartType}
                  downloadFile={this.downloadFile}
                  fileHistoryLoading={fileHistoryLoading}
                  insightsFileHistory={insightsFileHistory}
                  fileHistoryChartType={fileHistoryChartType}
                  getFileHistory={this.getFileHistory}
                  downloadHistoryFile={this.downloadHistoryFile}
                />
                <ToggleAndUploader
                  toggleId="lc_toggle_button"
                  uploadId="lc_upload_button"
                  title="Large Claims"
                  config={largeClaimsData}
                  employerId={employerId}
                  chartType={LARGE_CLAIMS}
                  confirmCallback={this.toggleCallback}
                  uploadFile={uploadFile}
                  fileUploadInProgress={fileUploadInProgress}
                  updatingChartType={updatingChartType}
                  downloadFile={this.downloadFile}
                  fileHistoryLoading={fileHistoryLoading}
                  insightsFileHistory={insightsFileHistory}
                  fileHistoryChartType={fileHistoryChartType}
                  getFileHistory={this.getFileHistory}
                  downloadHistoryFile={this.downloadHistoryFile}
                />
                <ToggleAndUploader
                  toggleId="upin_toggle_button"
                  uploadId="upin_upload_button"
                  title="Utilization-Provider-In-Network vs Out of Network"
                  config={networkUtilizationData}
                  employerId={employerId}
                  chartType={NETWORK_UTILIZATION}
                  confirmCallback={this.toggleCallback}
                  uploadFile={uploadFile}
                  fileUploadInProgress={fileUploadInProgress}
                  updatingChartType={updatingChartType}
                  downloadFile={this.downloadFile}
                  fileHistoryLoading={fileHistoryLoading}
                  insightsFileHistory={insightsFileHistory}
                  fileHistoryChartType={fileHistoryChartType}
                  getFileHistory={this.getFileHistory}
                  downloadHistoryFile={this.downloadHistoryFile}
                />
                <ToggleAndUploader
                  toggleId="ud_toggle_button"
                  uploadId="ud_upload_button"
                  title="Utilization-Common Conditions & Drugs"
                  config={drugsUtilizationData}
                  employerId={employerId}
                  chartType={DRUGS_UTILIZATION}
                  confirmCallback={this.toggleCallback}
                  uploadFile={uploadFile}
                  fileUploadInProgress={fileUploadInProgress}
                  updatingChartType={updatingChartType}
                  downloadFile={this.downloadFile}
                  fileHistoryLoading={fileHistoryLoading}
                  insightsFileHistory={insightsFileHistory}
                  fileHistoryChartType={fileHistoryChartType}
                  getFileHistory={this.getFileHistory}
                  downloadHistoryFile={this.downloadHistoryFile}
                />
              </div>
            </EmployerConfigToggleV2>
          </ContentContainer>
          <ContainerHeader title="Custom Message" />
          <ContentContainer>
            <div className="mt-2">
              <MessageIndicator employerId={employerId} />
            </div>
          </ContentContainer>
        </div>
      </ApplicationWrapper>
    );
  }
}

/**
 * Main toggle sections
 * @param title Title
 * @param enabled Enabled or Disabled
 * @param date Last date of enabled or disabled
 * @param by Last person of enabled or disabled
 * @param toggleId ID
 * @param toggleValue Toggle value
 * @param callback callback
 * @returns {*}
 */
const ContainerHeader = ({ title }) => {
  return <div className="mb-4 insights-header-text">{title}</div>;
};

/**
 * Show toggle button with file uploader.
 *
 * @param toggleId Automation Testing toggle button ID
 * @param id Automation Testing uploader ID
 * @param title Title of the toggle button
 * @param config
 * @param type Toggle Type
 * @param confirmCallback callback function for the confirmation button
 * @param uploadFile
 * @param employerId
 */
const ToggleAndUploader = (props) => {
  const {
    toggleId,
    uploadId,
    title,
    config,
    chartType,
    confirmCallback,
    uploadFile,
    employerId,
    fileUploadInProgress,
    updatingChartType,
    downloadFile,
    fileHistoryLoading,
    insightsFileHistory,
    fileHistoryChartType,
    getFileHistory,
    downloadHistoryFile
  } = props;
  const { enabled = false, files = [] } = config || {};

  return (
    <ToggleEnabledUploader
      toggleId={toggleId}
      uploadId={uploadId}
      title={title}
      files={files}
      status={enabled}
      chartType={chartType}
      confirmCallback={confirmCallback}
      uploadFile={uploadFile}
      employerId={employerId}
      fileUploading={fileUploadInProgress && updatingChartType === chartType}
      downloadFile={downloadFile}
      fileHistoryLoading={fileHistoryLoading}
      insightsFileHistory={insightsFileHistory}
      fileHistoryChartType={fileHistoryChartType}
      getFileHistory={getFileHistory}
      downloadHistoryFile={downloadHistoryFile}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  getInsightsByEmployer: (page, size, sort, query) =>
    dispatch(getInsightsFeatureSummary(page, size, sort, query)),
  uploadFile: (data) => dispatch(uploadFile(data)),
  changeChartStatus: (employerId, data) =>
    dispatch(changeChartStatus(employerId, data)),
  getFileHistory: (employerId, chartType) =>
    dispatch(getFileHistory(employerId, chartType)),
  findConfigStatus: (employerId, key) =>
    dispatch(findConfigStatus(employerId, key)),
  createConfigModule: (employerId, value, MODULE_ANALYTICS_ENABLED) =>
    dispatch(createConfigModule(employerId, value, MODULE_ANALYTICS_ENABLED)),
  updateConfigModule: (id, employerId, value, MODULE_ANALYTICS_ENABLED) =>
    dispatch(
      updateConfigModule(id, employerId, value, MODULE_ANALYTICS_ENABLED)
    )
});

const mapStateToProps = (state) => {
  const {
    insightsConfig,
    updatingChartType,
    fileUpload,
    statusUpdate,
    insightFileHistory,
    analyticsConfigStatus,
    claimsConfigStatus
  } = state.insightsReducer;
  return {
    configFetchingInProgress: insightsConfig.isFetching,
    configFetchingError: insightsConfig.configError,
    insightsData: insightsConfig.insightsData,
    updatingChartType,
    fileUploadInProgress: fileUpload.fileUploading,
    uploadedFile: fileUpload.uploadedFile,
    fileUploadError: fileUpload.fileUploadError,
    chartStatusUpdateInProgress: statusUpdate.chartStatusUpdateInProgress,
    chartStatusUpdateError: statusUpdate.chartStatusUpdateError,
    updatedChart: statusUpdate.updatedChart,
    fileHistoryLoading: insightFileHistory.historyLoading,
    insightsFileHistory: insightFileHistory.fileHistoryData,
    insightsFileHistoryError: insightFileHistory.fileHistoryError,
    fileHistoryChartType: insightFileHistory.chartType,
    analyticsConfigStatus,
    claimsConfigStatus
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Insights);
