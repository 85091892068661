/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/fileExport.actionTypes';

const initialState = {
  downloadIdCardsStatus: {
    inProgress: false,
    error: null
  },
  downloadElectionsStatus: {
    inProgress: false,
    error: null
  },
  checkElectionsStatus:{
    inProgress: false,
    error: null,
    data: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.DOWNLOAD_ID_CARDS_IN_PROGRESS:
      return {
        ...state,
        downloadIdCardsStatus: {
          inProgress: true,
          error: false
        }
      };
    case ActionTypes.DOWNLOAD_ID_CARDS_COMPLETED:
      return {
        ...state,
        downloadIdCardsStatus: {
          inProgress: false,
          error: false
        }
      };
    case ActionTypes.DOWNLOAD_ID_CARDS_FAILED:
      return {
        ...state,
        downloadIdCardsStatus: {
          inProgress: false,
          error: action.payload || 'Something Went Wrong'
        }
      };
    case ActionTypes.DOWNLOAD_ELECTIONS_IN_PROGRESS:
      return {
        ...state,
        downloadElectionsStatus: {
          inProgress: true,
          error: false
        }
      };
    case ActionTypes.DOWNLOAD_ELECTIONS_COMPLETED:
      return {
        ...state,
        downloadElectionsStatus: {
          inProgress: false,
          error: false
        }
      };
    case ActionTypes.DOWNLOAD_ELECTIONS_FAILED:
      return {
        ...state,
        downloadElectionsStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.CHECK_ELECTIONS_IN_PROGRESS:
      return {
        ...state,
        checkElectionsStatus: {
          inProgress: true,
          error: false
        }
      };
    case ActionTypes.CHECK_ELECTIONS_COMPLETED:
      return {
        ...state,
        checkElectionsStatus: {
          data: action.payload,
          inProgress: false,
          error: false
        }
      };
    case ActionTypes.CHECK_ELECTIONS_FAILED:
      return {
        ...state,
        checkElectionsStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
