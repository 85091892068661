/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  formatCurrencyWithDefaultValue,
  formatCount
} from '../../../../../../util/commonUtil';

import icon_dropdown from '../../../../../../assets/images/icon-dropdown.png';
import './SummaryPreview.scss';

const SummaryRow = (props) => {
  const {
    name,
    summary,
    className,
    showCollapseIcon,
    onClickIcon,
    isOpen
  } = props;

  const {
    count,
    totalCurrentVolume,
    totalCurrentPremium,
    totalRetroVolume,
    totalRetroPremium,
    totalVolume,
    totalPremium
  } = summary;

  return (
    <Row className={`summary-row ${className}`}>
      <Col xs="2" className="benefit-cell">
        <Row>
        <Col xs="2">
            {showCollapseIcon && (
              <img
                alt="Lumity"
                src={icon_dropdown}
                className={`collapse-icon ${isOpen ? 'open' : ''}`}
                onClick={onClickIcon}
              />
            )}
          </Col>
          <Col className="pl-1">{name}</Col>
        </Row>
      </Col>
      <Col xs="4" className="header-current">
        <Row>
          <Col xs="2" className="row-value text-center">
            {formatCount(count)}
          </Col>
          <Col xs="5" className="row-value">
            {formatCurrencyWithDefaultValue(totalCurrentVolume, '-')}
          </Col>
          <Col xs="5" className="row-value">
            {formatCurrencyWithDefaultValue(totalCurrentPremium, '-')}
          </Col>
        </Row>
      </Col>
      <Col xs="3" className="header-retro">
        <Row>
          <Col className="row-value">
            {formatCurrencyWithDefaultValue(totalRetroVolume, '-')}
          </Col>
          <Col className="row-value">
            {formatCurrencyWithDefaultValue(totalRetroPremium, '-')}
          </Col>
        </Row>
      </Col>
      <Col xs="3" className="header-total">
        <Row>
          <Col className="row-value">
            {formatCurrencyWithDefaultValue(totalVolume, '-')}
          </Col>
          <Col className="row-value">
            {formatCurrencyWithDefaultValue(totalPremium, '-')}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SummaryRow;
