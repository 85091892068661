/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import { EmployerSource } from 'constants/employerConstants';
import { EMDASH_LITERAL } from 'constants/commonConstants';

import {
  BooleanServiceOption,
  BenefitCoverageOption,
  CostSharingPolicy,
  RxCopayFrequency
} from '../constants/planServiceConstants';
import { ValidateError } from '../constants/messageConstants';

import './PlanServiceUtil.scss';

export const unwrapCustomService = (customService) => {
  return {
    benefitCode: { ...customService.benefitCode },
    serviceValue: { ...customService.serviceValue },
    source: customService.source
  };
};

export const generateEmptyService = (benefitCode) => {
  return {
    benefitCode: benefitCode ? benefitCode : generateEmptyBenefitCode(),
    serviceValue: {
      inNetwork: generateEmptyNetwork()
    },
    source: EmployerSource.PLATFORM.value
  };
};

export const generateEmptyBenefitCode = () => {
  return {
    id: null,
    code: null,
    description: null,
    shortName: null,
    benefitKind: null
  };
};

export const generateEmptyRxTier = () => {
  return {
    inNetwork: generateEmptyNetwork()
  };
};

export const generateEmptyNetwork = () => {
  return {
    benefitCovered: null,
    benefitExcludedFromOOPMax: null,
    costSharingPolicy: null,
    copay: null,
    copayFrequency: null,
    copayPriorToDeductible: null,
    coinsurance: null,
    coinsuranceAfterDeductible: null,
    info: null
  };
};

export const formatDeductible = (
  policy,
  copayPriorToDeductible,
  coinsuranceAfterDeductible
) => {
  const getCopayText = () => {
    switch (copayPriorToDeductible) {
      case BooleanServiceOption.YES.value:
        return 'YES, pay copay prior to deductible';
      case BooleanServiceOption.NO.value:
        return 'NO, pay copay after deductible';
      case BooleanServiceOption.NO_INFO.value:
        return 'NO INFO';
      default:
        return EMDASH_LITERAL;
    }
  };
  const getCoinsuranceText = () => {
    switch (coinsuranceAfterDeductible) {
      case BooleanServiceOption.YES.value:
        return 'YES, pay coinsurance after deductible';
      case BooleanServiceOption.NO.value:
        return 'NO, pay coinsurance before deductible';
      case BooleanServiceOption.NO_INFO.value:
        return 'NO INFO';
      default:
        return EMDASH_LITERAL;
    }
  };
  switch (policy) {
    case CostSharingPolicy.COPAY.value:
      return getCopayText();
    case CostSharingPolicy.OTHER.value:
      return getCopayText();
    case CostSharingPolicy.COINSURANCE.value:
      return getCoinsuranceText();
    case CostSharingPolicy.COMBINED.value:
      return (
        <>
          <div>{getCopayText()}</div>
          <div>{getCoinsuranceText()}</div>
        </>
      );
    default:
      return EMDASH_LITERAL;
  }
};

export const formatServiceCostSharingPolicy = (coverage, policy) => {
  if (
    coverage === BenefitCoverageOption.YES.value ||
    // Synced services have coverage as null
    (!coverage && policy)
  ) {
    return policy ? CostSharingPolicy[policy].label : EMDASH_LITERAL;
  } else {
    return coverage ? BenefitCoverageOption[coverage].label : EMDASH_LITERAL;
  }
};

export const formatCopay = (amount, frequency, enumMap) => {
  if (
    !isNaN(Number.parseInt(amount)) &&
    frequency &&
    frequency !== RxCopayFrequency.NO_INFO.value
  ) {
    return `$${amount} ${(enumMap[frequency] || {}).label.toLowerCase()}`;
  } else if (!isNaN(Number.parseInt(amount))) {
    return `$${amount}`;
  } else {
    return EMDASH_LITERAL;
  }
};

export const formatCoinsurance = (amount) => {
  return !isNaN(Number.parseInt(amount)) ? `${amount}%` : EMDASH_LITERAL;
};

export const formatExcludedOOPMax = (excluded) => {
  return excluded ? BooleanServiceOption[excluded].label : EMDASH_LITERAL;
};

export const formatRxCostSharingPolicy = (policy) => {
  return policy ? CostSharingPolicy[policy].label : EMDASH_LITERAL;
};

export const isCostSharingFormValid = (costSharingForm) => {
  const { costSharingPolicy, copay, coinsurance, info } = costSharingForm;

  const isCopayValid = Number.parseInt(copay) > 0;
  const isCoinsuranceValid =
    Number.parseInt(coinsurance) > 0 && Number.parseInt(coinsurance) <= 100;

  // Verify Cost Sharing Policy fields
  switch (costSharingPolicy) {
    case CostSharingPolicy.COPAY.value:
      return isCopayValid;
    case CostSharingPolicy.COINSURANCE.value:
      return isCoinsuranceValid;
    case CostSharingPolicy.COMBINED.value:
      return isCopayValid && isCoinsuranceValid;
    case CostSharingPolicy.OTHER.value:
      return info;
    // At this point costSharingPolicy = '' or 'Select Option'
    default:
      return false;
  }
};

// Assigns dirty data to clean data according to the dirty data fields.
// Mutates cleanData
export const clearInvalidCostSharingFormFields = (
  cleanData,
  dirtyData,
  isRXData = false
) => {
  const {
    costSharingPolicy,
    copay,
    copayFrequency,
    copayPriorToDeductible,
    coinsurance,
    coinsuranceAfterDeductible,
    info
  } = dirtyData;

  cleanData.costSharingPolicy = costSharingPolicy;

  // Save the cost sharing policy fields accordingly.
  switch (costSharingPolicy) {
    case CostSharingPolicy.COPAY.value:
      cleanData.copay = copay;
      cleanData.copayFrequency = copayFrequency ? copayFrequency : null;
      cleanData.copayPriorToDeductible = copayPriorToDeductible;
      break;
    case CostSharingPolicy.COINSURANCE.value:
      cleanData.coinsurance = coinsurance;
      cleanData.coinsuranceAfterDeductible = coinsuranceAfterDeductible;
      break;
    case CostSharingPolicy.COMBINED.value:
      cleanData.copay = copay;
      cleanData.copayFrequency = copayFrequency ? copayFrequency : null;
      cleanData.copayPriorToDeductible = copayPriorToDeductible;
      cleanData.coinsurance = coinsurance;
      cleanData.coinsuranceAfterDeductible = coinsuranceAfterDeductible;
      break;
    case CostSharingPolicy.OTHER.value:
      cleanData.info = info;
      if (isRXData) {
        cleanData.copayPriorToDeductible = copayPriorToDeductible;
      }
      break;
    default:
      break;
  }
};
export const resetValuesForPlanClone = (plan) => {
  plan.name = "CLONE - " +plan.name;
  plan.createdTs = null;
  plan.lastUpdatedTs = null;
  plan.endDate = null;
  plan.startDate = null;
  plan.states = [];
  plan.documents = {};
  plan.policyNumber = null;
  plan.planCloneDetails = {
    clonedPlanId: plan.id,
    clonedPlanMasterId: plan.masterId,
    clonedPlanRevision: plan.revision
  }
  plan.id = null;
  plan.revision = 0;
  plan.idCardDetails = {
    rxBINumber: '',
    rxPCNumber: '',
    rxGroupNumber: '',
    groupIdNumber: '',
    alphaPrefix: '',
    idCardPhoneNumber:''
  };
  plan.sfcPlanConfigVo = {};
  plan.sfcPlanDataVO = { selfFundedClaimsStatus: false };
  plan.hsaPlan = null;
  const { customServices = [] } = plan;
  plan.customServices = customServices.map((service)=>{
    return {
      benefitCode: service.benefitCode,
      serviceValue: service.serviceValue,
      source: service.source
    }
  })
  return plan;
}

export const getPlanYearField = (planYear) => {
  if (!_.isEmpty(planYear)) {
    return `${planYear.name}`;
  }
  return '-';
};

export const getPlanYearRange = (planYear) => {
  if (!_.isEmpty(planYear)) {
    return `${moment(planYear.startDate).format('ll')} to ${moment(
      planYear.endDate
    ).format('ll')}`;
  }
  return '-';
};

export const getCurrentAndNewPlanYearIds = (planYears) => {
  if (!_.isEmpty(planYears)) {
    const filtered = planYears.reduce(
      (a, o) => ((o.current || o.next) && a.push(o.id), a),
      []
    );
    return filtered;
  }
  return '';
};

export const getPlanYearNameById = (planYearId, planYears) => {
  if (!_.isEmpty(planYearId) && planYears) {
    const planYear = planYears.find(
      (year) => year.id === planYearId
    );
    if(planYear){
     return planYear.name;
    }
  }
  return '-';
};

export const getPlanYearRangeWithCurrent = (planYear) => {
  if (!_.isEmpty(planYear)) {
    return `${moment(planYear.startDate).format('ll')} to ${moment(
      planYear.endDate
    ).format('ll')} ${
      planYear.current ? '- CURRENT' : ''
    }`;
  }
  return '-';
};

export const getPlanYearNameFilter = (planYear) => {
  if (!_.isEmpty(planYear)) {
    return (
      <span style={{ opacity: 1, display: 'flex' }}>
        <div>
          {` ${planYear.name} (${moment(planYear.startDate).format(
            'll'
          )} to ${moment(planYear.endDate).format('ll')})`}
        </div>
        <div className="bold current">{` ${planYear.current ? '- CURRENT' : ''} `}</div>
      </span>
    );
  }
  return '-';
};

export const getValidationMessage = (validatePlanError) => {
  switch (validatePlanError.code) {
    case ValidateError.PLAN_USED_ELECTIONS.code:
      return ValidateError.PLAN_USED_ELECTIONS.message;
    case ValidateError.PLAN_USED_BENGUIDES.code:
      return ValidateError.PLAN_USED_BENGUIDES.message;
    case ValidateError.PLAN_USED_MEDICAL_PLANS.code: 
      return ValidateError.PLAN_USED_MEDICAL_PLANS.message;
    default:
      break;
  }
};