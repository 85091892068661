/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import Loader from 'components/CircularProgress/Loader';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import { listOfferPlanBasicDataByType } from '../../../../actions/offerPlanAction';
import { Button, Col, Row } from 'components/Atoms';
import _ from 'lodash';
import {
  createOffer,
  getOfferByID,
  getCarriers,
  updateOffer
} from '../../../../actions/offerAction';
import NotificationManager from 'components/Notifications';
import {
  BENEFIT_CATEGORY_DENTAL,
  BENEFIT_CATEGORY_LIFE,
  BENEFIT_CATEGORY_MEDICAL,
  BENEFIT_CATEGORY_VISION
} from './../../../../constants';
import '../../OffersList.scss';
import { OFFER_BUNDLE_PATH } from '../../../../routes';
import { generatePath } from 'react-router-dom';

class OfferCreateEdit extends Component {
  state = {
    bundleType: null,
    current: {},
    carriersArray: [],
    currentPlans: [],
    filteredPlans: [],
    rating: null,
    kaiserIncluded: false
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      mode: this.props.match.params.mode,
      bundleCategory: this.props.match.params.bundleCategory
        ? this.props.match.params.bundleCategory.toUpperCase()
        : undefined,
      bundleTypes: [
        { id: 'OTHER', value: 'Normal' },
        { id: 'RENEWAL', value: 'Renewal' }
      ],
      providerRating: [
        { id: '1.0', value: '1.0' },
        { id: '1.5', value: '1.5' },
        { id: '2.0', value: '2' },
        { id: '2.5', value: '2.5' },
        { id: '3.0', value: '3' },
        { id: '3.5', value: '3.5' },
        { id: '4.0', value: '4' },
        { id: '4.5', value: '4.5' },
        { id: '5.0', value: '5' }
      ],
      planList: [],
      isRecommended: false,
      name: '',
      filterCarrierName: '',
      bundleType: '',
      carrierId: '',
      rating: '',
      errors: { name: '', type: '', carrier: '', plans: '' },
      description: '',
      kaiserIncluded: false,
      kaiserPlans: [],
      kaiserCarriers: ['Kaiser Permanente']
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { employerId, bundleId } = params;
    if (bundleId) {
      this.props.getBundleByID(bundleId);
    }
    const { bundleCategory } = this.state;
    this.props.listProposalPlanBasicDataByType(employerId, bundleCategory);
    if (bundleCategory) {
      this.props.getCarriers(bundleCategory);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      getBundleError,
      selectedBundle,
      medicalOfferPlans,
      dentalOfferPlans,
      visionOfferPlans,
      lifeOfferPlans,
      offerError,
      createdBundle,
      bundleCarriersData,
      bundleCarriersFetchingError
    } = this.props;
    const { bundleCategory } = this.state;
    if (
      selectedBundle &&
      !getBundleError &&
      selectedBundle !== prevProps.selectedBundle
    ) {
      this.setState({
        name: selectedBundle.name,
        bundleType: selectedBundle.type,
        bundleCategory: selectedBundle.category,
        filterCarrierName: selectedBundle.carrier,
        carrierId: selectedBundle.carrierId,
        bundle: selectedBundle,
        isRecommended: selectedBundle.recommended,
        rating: selectedBundle.providerRating,
        description: selectedBundle.description,
        kaiserIncluded: selectedBundle.kaiserIncluded
      });
      if (this.state.bundleCategory === BENEFIT_CATEGORY_MEDICAL) {
        let planList = Object.keys(selectedBundle.medicalPlans);
        this.setState({ planList: planList });
      } else if (this.state.bundleCategory === BENEFIT_CATEGORY_DENTAL) {
        let planList = Object.keys(selectedBundle.dentalPlans);
        this.setState({ planList: planList });
      } else if (this.state.bundleCategory === BENEFIT_CATEGORY_VISION) {
        let planList = Object.keys(selectedBundle.visionPlans);
        this.setState({ planList: planList });
      } else if (this.state.bundleCategory === BENEFIT_CATEGORY_LIFE) {
        let planList = Object.keys(selectedBundle.lifePlans);
        this.setState({ planList: planList });
      }
    }
    let allPlans = [];
    if (
      bundleCategory === BENEFIT_CATEGORY_MEDICAL &&
      prevProps.medicalOfferPlans !== medicalOfferPlans
    ) {
      allPlans = medicalOfferPlans;
      let kaiserPlans = _.filter(allPlans, function(o) {
        return (
          isKaiserPlan(o.carrier) &&
          o.bundleTypes !== null &&
          o.bundleTypes.indexOf('CURRENT') === -1
        );
      });
      this.setState({
        allPlans: medicalOfferPlans,
        kaiserPlans: kaiserPlans
      });
    }

    if (
      bundleCategory === BENEFIT_CATEGORY_DENTAL &&
      prevProps.dentalOfferPlans !== dentalOfferPlans
    ) {
      this.setState({ allPlans: dentalOfferPlans });
    }

    if (
      bundleCategory === BENEFIT_CATEGORY_VISION &&
      prevProps.visionOfferPlans !== visionOfferPlans
    ) {
      this.setState({ allPlans: visionOfferPlans });
    }

    if (
      bundleCategory === BENEFIT_CATEGORY_LIFE &&
      prevProps.lifeOfferPlans !== lifeOfferPlans
    ) {
      this.setState({ allPlans: lifeOfferPlans });
    }
    if (bundleCategory && prevState.bundleCategory !== bundleCategory) {
      this.props.getCarriers(bundleCategory);
    }
    if (
      !offerError &&
      createdBundle &&
      createdBundle !== prevProps.createdBundle
    ) {
      const { match } = this.props;
      const { params } = match;
      const { employerId, mode } = params;
      if (mode === 'create') {
        NotificationManager.success('Bundle is saved successfully');
      } else {
        NotificationManager.success('Bundle is Updated successfully');
      }
      this.props.history.push(generatePath(OFFER_BUNDLE_PATH, { ...params }));
    }
    if (offerError && offerError !== prevProps.offerError) {
      NotificationManager.error('Error creating bundle!!');
    }
    if (
      bundleCarriersData &&
      !bundleCarriersFetchingError &&
      prevProps.bundleCarriersData !== bundleCarriersData
    ) {
      const carriers = bundleCarriersData.map((carrier) => {
        const { id, name } = carrier;
        return {
          id,
          name
        };
      });

      const totalCarriers = bundleCarriersData.length;

      this.setState({ carriersArray: carriers, totalCarriers: totalCarriers });
    }
  }

  saveBundle = () => {
    if (!this.validateFields()) {
      NotificationManager.error('Please fix the validation errors.');
      return;
    }
    let data = this.state;
    let bundleObj = {
      name: data.name,
      carrier: data.filterCarrierName,
      carrierId: data.carrierId,
      recommended: data.isRecommended,
      category: data.bundleCategory,
      type: data.bundleType,
      employerId: data.employerId,
      providerRating: data.rating,
      description: data.description,
      kaiserIncluded: data.kaiserIncluded
    };
    if (data.bundleCategory === BENEFIT_CATEGORY_MEDICAL) {
      bundleObj.medicalPlans = data.planList;
      bundleObj.dentalPlans = null;
      bundleObj.visionPlans = null;
      bundleObj.lifePlans = null;
    } else if (data.bundleCategory === BENEFIT_CATEGORY_DENTAL) {
      bundleObj.dentalPlans = data.planList;
      bundleObj.medicalPlans = null;
      bundleObj.visionPlans = null;
      bundleObj.lifePlans = null;
    } else if (data.bundleCategory === BENEFIT_CATEGORY_VISION) {
      bundleObj.visionPlans = data.planList;
      bundleObj.medicalPlans = null;
      bundleObj.dentalPlans = null;
      bundleObj.lifePlans = null;
    } else {
      bundleObj.lifePlans = data.planList;
      bundleObj.medicalPlans = null;
      bundleObj.dentalPlans = null;
      bundleObj.visionPlans = null;
    }

    const updatedObj = { ...data.bundle, ...bundleObj };
    if (this.props.match.params.mode === 'create') {
      this.props.createOffer(data.bundleCategory, updatedObj);
    } else {
      this.props.updateBundle(updatedObj);
    }
  };
  onChange = (e) => {
    const name = e.target.id;
    const value = e.target.value;

    this.setState({
      ...this.state,
      [name]: value,
      errors: { ...this.state.errors, [name]: '' }
    });
  };
  setPlanList = (e) => {
    const value = e.target.value;
    let selectedPlans = this.state.planList;
    if (e.target.checked) {
      selectedPlans.push(value);
    } else {
      selectedPlans.splice(selectedPlans.indexOf(value), 1);
    }

    this.setState({
      planList: selectedPlans,
      errors: { ...this.state.errors, plans: '' }
    });
  };
  validatePlanSelection = () => {
    const {
      bundleCategory,
      kaiserPlans,
      kaiserIncluded,
      filterCarrierName,
      selectedPlanList
    } = this.state;
    if (bundleCategory === BENEFIT_CATEGORY_MEDICAL) {
      if (
        kaiserPlans.length > 0 &&
        !kaiserIncluded &&
        !isKaiserPlan(filterCarrierName)
      ) {
        _.forEach(kaiserPlans, function(value) {
          let kaiserPlanIndex = _.findIndex(selectedPlanList, function(o) {
            return o === value.id;
          });
          if (kaiserPlanIndex > -1) {
            selectedPlanList.splice(kaiserPlanIndex, 1);
          }
        });
      }
      this.setState({ planList: selectedPlanList });
    }
    return selectedPlanList;
  };
  validateFields() {
    let data = this.state;
    let selectedPlanList = this.validatePlanSelection();
    this.setState({
      errors: {
        name: data.name === '' ? 'Enter Bundle Name' : '',
        carrier: data.carrierId === '' ? 'Select Carrier' : '',
        type: data.bundleType === '' ? 'Select Bundle Type' : '',
        plans: selectedPlanList.length === 0 ? 'Select Proposal Plans' : ''
      }
    });
    let bundleObj = {
      name: data.name === '',
      carrier: data.carrierId === '',
      type: data.bundleType === '',
      plans: data.planList.length === 0
    };
    return (
      Object.keys(bundleObj).find((key) => bundleObj[key] === true) ===
      undefined
    );
  }
  getFilteredPlans = (carrierId, kaiserIncluded, allPlans, carrierName) => {
    let filteredPlans = _.filter(allPlans, function(o) {
      return (
        o.carrierId === carrierId &&
        o.bundleTypes !== null &&
        o.bundleTypes.indexOf('CURRENT') === -1
      );
    });
    if (kaiserIncluded) {
      let kaiserPlans = _.filter(allPlans, function(o) {
        return (
          isKaiserPlan(o.carrier) &&
          o.carrier !== carrierName &&
          o.bundleTypes !== null &&
          o.bundleTypes.indexOf('CURRENT') === -1
        );
      });
      filteredPlans = filteredPlans.concat(kaiserPlans);
    }
    this.setState({
      currentPlans: filteredPlans,
      errors: { ...this.state.errors, carrier: '' }
    });
  };
  kaiserOnChange = (e) => {
    this.setState({ kaiserIncluded: e.target.checked });
    const { allPlans, filterCarrierName, carrierId } = this.state;
    if (carrierId !== '') {
      this.getFilteredPlans(
        carrierId,
        e.target.checked,
        allPlans,
        filterCarrierName
      );
    }
  };
  setPlansForCarriers = (event) => {
    let carrierName = event.target.selectedOptions[0].text;
    this.setState({
      filterCarrierName: carrierName,
      carrierId: event.target.value,
      planList: []
    });
    const { allPlans } = this.state;
    this.getFilteredPlans(
      event.target.value,
      this.state.kaiserIncluded,
      allPlans,
      carrierName
    );
  };
  goToBundleScreen = () => {
    const { match } = this.props;
    const { params } = match;
    this.props.history.push(
      generatePath(OFFER_BUNDLE_PATH, {
        ...params
      })
    );
  };
  renderPageActions = () => (
    <>
      <Button
        size="lg"
        color="primary"
        onClick={() => {
          this.saveBundle();
        }}
      >
        Save
      </Button>
      <Button
        outline
        color="primary"
        size="lg"
        className="mt-2"
        onClick={this.goToBundleScreen}
      >
        Cancel
      </Button>
    </>
  );

  renderBundleInfo = () => (
    <ContentContainer header="Bundle Info">
      <Row>
        <Col md="6">
          <Row>
            <Col>
              <div className="form-group text-left">
                <label>Name of Bundle *</label>
                <input
                  name="name"
                  type="text"
                  id="name"
                  onChange={this.onChange}
                  maxLength="100"
                  value={this.state.name}
                  className={`form-control input-h-38 mt-2 ${
                    this.state.errors.name !== '' ? 'is-invalid' : ''
                  }`}
                  placeholder="Bundle Name"
                  disabled={false}
                />
                <div className="invalid-feedback">{this.state.errors.name}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group text-left">
                <label>Bundle Description </label>
                <textarea
                  name="description"
                  id="description"
                  type="text"
                  maxLength="100"
                  rows="5"
                  cols="50"
                  onChange={this.onChange}
                  value={this.state.description}
                  className={`form-control mt-2 ${false ? 'is-invalid' : ''}`}
                  placeholder="Bundle Description"
                  disabled={false}
                />
                <div className="invalid-feedback"></div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Row>
            <Col>
              <div className="form-group text-left">
                <label>Bundle Type *</label>
                <select
                  className={`custom-select mt-2 ${
                    this.state.errors.type !== '' ? 'is-invalid' : ''
                  }`}
                  value={this.state.bundleType}
                  name="bundleType"
                  onChange={(event) =>
                    this.setState({
                      ...this.state,
                      bundleType: event.target.value,
                      errors: { ...this.state.errors, type: '' }
                    })
                  }
                >
                  <option value="">Select Option</option>
                  {this.state.bundleTypes.map((bundleType) => (
                    <option key={bundleType.id} value={bundleType.id}>
                      {bundleType.value}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">{this.state.errors.type}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group text-left">
                <label>Provider Rating </label>
                <select
                  className="custom-select mt-2"
                  value={this.state.rating}
                  name="rating"
                  onChange={(event) =>
                    this.setState({ ...this.state, rating: event.target.value })
                  }
                >
                  <option value="">Select Option</option>
                  {this.state.providerRating.map((rating) => (
                    <option key={rating.id} value={rating.id}>
                      {rating.value}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group text-left">
                <label>
                  <input
                    type="checkbox"
                    id="isRecommended"
                    checked={this.state.isRecommended}
                    value={this.state.isRecommended}
                    onChange={(event) =>
                      this.setState({ isRecommended: event.target.checked })
                    }
                    className="mt-2 mr-1"
                  />
                  Is Bundle Recommended
                </label>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </ContentContainer>
  );

  renderPlanInfo = () => (
    <ContentContainer header="Plans">
      <Row>
        <Col md={6}>
          <div className="form-group text-left">
            <label>1. Select Carrier *</label>
            <select
              className={`custom-select mt-2 ${
                this.state.errors.carrier !== '' ? 'is-invalid' : ''
              }`}
              value={this.state.carrierId}
              name="bundle-create-carrier-select"
              onChange={(event) => this.setPlansForCarriers(event)}
            >
              <option value="">Select Option</option>
              {this.state.carriersArray.map((carrier) => (
                <option key={carrier.id} value={carrier.id}>
                  {carrier.name}
                </option>
              ))}
            </select>

            <div className="invalid-feedback">{this.state.errors.carrier}</div>
          </div>
        </Col>
        <Col md={6}>
          <Row>
            <Col>
              <div className="form-group text-left chk-include-kaiser ">
                {this.state.bundleCategory === BENEFIT_CATEGORY_MEDICAL && (
                  <label>
                    <input
                      type="checkbox"
                      className="mt-2"
                      checked={this.state.kaiserIncluded}
                      value={this.state.kaiserIncluded}
                      onChange={(e) => {
                        this.kaiserOnChange(e);
                      }}
                    />
                    Include Kaiser
                  </label>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <Col>
              <div className="form-group text-left">
                <label>
                  2. To add plans select checkbox, to remove plans unselect
                  checkbox *
                </label>
                <div
                  className={`planListGroup ${
                    this.state.errors.plans !== '' ? 'invalid-selection' : ''
                  }`}
                >
                  {this.state.currentPlans.map((plan) =>
                    this.renderPlanItem(plan)
                  )}
                </div>
                <div className="invalid-selection-feedback">
                  {this.state.errors.plans}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </ContentContainer>
  );

  renderPlanItem = (planObj) => (
    <div className="chckbox-item-container">
      <label className="chckbox-label">
        <input
          type="checkbox"
          checked={this.state.planList.indexOf(planObj.id) !== -1}
          onChange={this.setPlanList}
          value={planObj.id}
        />
        {planObj.carrier} - {planObj.name}
      </label>
    </div>
  );

  render() {
    return (
      <ApplicationWrapper>
        {this.props.createBundleInProgress && (
          <Row>
            <Col className="text-center align-middle">
              <Loader />
            </Col>
          </Row>
        )}
        {!this.props.createBundleInProgress && (
          <Row>
            <Col className="" md={10}>
              <HeaderContainer
                title={`${_.startCase(
                  _.toLower(this.state.mode)
                )} ${_.startCase(_.toLower(this.state.bundleCategory))} Bundle`}
              />
              {this.renderBundleInfo()}
              {this.renderPlanInfo()}
            </Col>
            <Col className="page-actions" md={2}>
              {this.renderPageActions()}
            </Col>
          </Row>
        )}
      </ApplicationWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    medicalOfferPlans,
    dentalOfferPlans,
    visionOfferPlans,
    lifeOfferPlans
  } = state.offerssReducer.offerPlanReducer;
  const {
    createOffer,
    fetchOffer,
    offerCarriers
  } = state.offersReducer.OfferReducer;
  return {
    medicalOfferPlans,
    dentalOfferPlans,
    visionOfferPlans,
    lifeOfferPlans,
    createBundleInProgress: createOffer.loading,
    offerError: createOffer.offerError,
    createdBundle: createOffer.createdBundle,
    getBundleLoading: fetchOffer.loading,
    getBundleError: fetchOffer.error,
    selectedBundle: fetchOffer.bundleData,
    bundleCarriersData: offerCarriers.carriersData,
    bundleCarriersLoading: offerCarriers.loading,
    bundleCarriersFetchingError: offerCarriers.error
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    listProposalPlanBasicDataByType: (employerId, type) =>
      dispatch(listOfferPlanBasicDataByType(employerId, type)),
    createOffer: (type, data) => dispatch(createOffer(type, data)),
    updateBundle: (data) => dispatch(updateOffer(data)),
    getBundleByID: (bundleId) => dispatch(getOfferByID(bundleId)),
    getCarriers: (category) => dispatch(getCarriers(category))
  };
};
const isKaiserPlan = (carrierName) => {
  return carrierName.toLowerCase().includes('kaiser');
};
export default connect(mapStateToProps, mapDispatchToProps)(OfferCreateEdit);
