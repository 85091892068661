/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
export const GET_PLANS_PAGE_IN_PROGRESS = 'GET_PLANS_PAGE_IN_PROGRESS';
export const GET_PLANS_PAGE_COMPLETED = 'GET_PLANS_PAGE_COMPLETED';
export const GET_PLANS_PAGE_FAILED = 'GET_PLANS_PAGE_FAILED';

export const INITIALIZE_PLAN = 'INITIALIZE_PLAN';

export const GET_PLAN_DETAILS_IN_PROGRESS = 'GET_PLAN_DETAILS_IN_PROGRESS';
export const GET_PLAN_DETAILS_COMPLETED = 'GET_PLAN_DETAILS_COMPLETED';
export const GET_PLAN_DETAILS_FAILED = 'GET_PLAN_DETAILS_FAILED';

export const SAVE_PLAN_DETAILS_IN_PROGRESS = 'SAVE_PLAN_DETAILS_IN_PROGRESS';
export const SAVE_PLAN_DETAILS_COMPLETED = 'SAVE_PLAN_DETAILS_COMPLETED';
export const SAVE_PLAN_DETAILS_FAILED = 'SAVE_PLAN_DETAILS_FAILED';

export const SET_PLAN_DOCUMENTS = 'SET_PLAN_DOCUMENTS';

export const GET_SELF_FUNDED_CLAIMS_PLAN_CONFIGURATION_IN_PROGRESS =
  'GET_SELF_FUNDED_CLAIMS_PLAN_CONFIGURATION_IN_PROGRESS';
export const GET_SELF_FUNDED_CLAIMS_PLAN_CONFIGURATION_SUCCESS =
  'GET_SELF_FUNDED_CLAIMS_PLAN_CONFIGURATION_SUCCESS';
export const GET_SELF_FUNDED_CLAIMS_PLAN_CONFIGURATION_FAILED =
  'GET_SELF_FUNDED_CLAIMS_PLAN_CONFIGURATION_FAILED';
export const CLEAR_SELF_FUNDED_CONFIG = 'CLEAR_SELF_FUNDED_CONFIG';

export const GET_ALL_HSA_PLANS_BY_YEAR_IN_PROGRESS =
  'GET_ALL_HSA_PLANS_BY_YEAR_IN_PROGRESS';
export const GET_ALL_HSA_PLANS_BY_YEAR = 'GET_ALL_HSA_PLANS_BY_YEAR';
export const GET_ALL_HSA_PLANS_BY_YEAR_FAILED =
  'GET_ALL_HSA_PLANS_BY_YEAR_FAILED';

  export const DELETE_PLAN_IN_PROGRESS = 'DELETE_PLAN_IN_PROGRESS';
  export const DELETE_PLAN_COMPLETED = 'DELETE_PLAN_COMPLETED';
  export const DELETE_PLAN_FAILED = 'DELETE_PLAN_FAILED';

  export const VALIDATE_DELETE_PLAN_IN_PROGRESS = 'VALIDATE_DELETE_PLAN_IN_PROGRESS';
  export const VALIDATE_DELETE_PLAN_COMPLETED = 'VALIDATE_DELETE_PLAN_COMPLETED';
  export const VALIDATE_DELETE_PLAN_FAILED = 'VALIDATE_DELETE_PLAN_FAILED';
