/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import axios from 'axios';
import Util from '../util/apiUtil';

const baseURL = `${Util.baseApi}/${Util.version}/elections`;
export default {
  /**
   * get all elections for given individual id.
   * @param individualId - individual id.
   * @returns {AxiosPromise<any>}
   */
  findElectionsByIndividualId(individualId, planYearId) {
    return axios.get(
      `${baseURL}?individualId=${individualId}&planYearId=${planYearId}`
    );
  },

  /**
   * Takes a new election object and updates it
   */
  updateElection(payload, sendNotifications) {
    return axios.put(`${baseURL}/individual/?send-notifications=${sendNotifications}`, payload);
  },

  /**
   * get all elections given list of plan year ids.
   */
  getAllElections(employerId, planYearIds) {
    return axios.get(
      `${baseURL}/employers/${employerId}/?planYearIds=${planYearIds}`
    );
  }
};
