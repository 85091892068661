/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';

import Progress from 'reactstrap/lib/Progress';

import './ProgressBar.scss';

const ProgressBar = (props) => {
  const { progress, isVisible } = props;
  return (
    <Progress
      className={`thin-progress-bar ${isVisible ? 'show' : 'hide'}`}
      value={progress}
    />
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isVisible: PropTypes.bool
};

ProgressBar.defaultProps = {
  progress: 0,
  isVisible: false
};

export default ProgressBar;
