import { combineReducers } from 'redux';

import AppBase from 'modules/app-base/reducers';
import Admins from 'modules/Admins/reducers';
import Employers from 'modules/Employers/reducers';
import Locations from 'modules/Locations/reducers';
import Employees from 'modules/Employees/reducers';
import Plans from 'modules/Plans/reducers';
import benGuideReducer from 'modules/BenGuides/reducers/benGuidePlanReducer';
import calendarReducer from 'modules/Calendar/reducers';
import documentReducer from 'modules/DocumentV2/reducers';
import insightsReducer from 'modules/Insights/reducers/insightsReducer';
import calendarTemplateReducer from 'modules/Admins/Calendars/reducers/calendarTemplateReducer';
import billingReducer from 'modules/Billing/reducers/billingReducer';
import issueLogReducer from 'modules/IssueLog/reducers/issueLogReducer';
import renewalsReducer from 'modules/Renewals/reducers/renewalsReducer';
import mobileReducer from 'modules/Mobile/reducers/mobileReducer';
import chatbotConfigReducer from 'modules/Chatbot/reducers/chatbotConfigReducer';
import carrierReducer from 'modules/Carriers/reducers/carrierReducer';
import surveysReducer from 'modules/Surveys/reducers/surveysReducer';
import benchmarkReducer from 'modules/Benchmarks/reducers/benchmarkReducer';
import integrationsReducer from 'modules/Integrations/reducers/integrationsReducer';
import offersReducer from 'modules/Offers/reducers';
import mobileVersionReducer from 'modules/Admins/Mobile/reducers/mobileVersionReducer';
import benefitConsultationReducer from 'modules/BenefitsConsultation/reducers/benefitsConsultationReducer';
import nativeIssueReducer from 'modules/NativeIssueLog/reducers';
import ssoReducer from 'modules/SSO/reducers/ssoReducer';
import organizationReducer from 'modules/Organisations/reducers/organizationReducer';
import benAdminIntegrationsReducer from 'modules/BenAdminIntegrations/reducers/benAdminIntegrationsReducer';
import currentPlansTogglesReducer from 'modules/CurrentPlans/reducers/currentPlansTogglesReducer';

export default combineReducers({
  AppBase,
  Admins,
  Employers,
  Locations,
  Employees,
  Plans,
  calendarReducer,
  carrierReducer,
  benGuideReducer,
  documentReducer,
  insightsReducer,
  calendarTemplateReducer,
  billingReducer,
  issueLogReducer,
  renewalsReducer,
  mobileReducer,
  chatbotConfigReducer,
  surveysReducer,
  benchmarkReducer,
  integrationsReducer,
  offersReducer,
  mobileVersionReducer,
  benefitConsultationReducer,
  ssoReducer,
  nativeIssueReducer,
  organizationReducer,
  benAdminIntegrationsReducer,
  currentPlansTogglesReducer
});
