/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
const SPENDING_LIST_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans';
const SPENDING_HSA_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/hsa/create';
const SPENDING_HSA_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/hsa/:planId/edit';
const SPENDING_MEDICAL_FSA_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/medical-fsa/create';
const SPENDING_MEDICAL_FSA_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/medical-fsa/:planId/edit';
const SPENDING_DCAPFSA_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/dcapfsa/create';
const SPENDING_DCAPFSA_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/dcapfsa/:planId/edit';
const SPENDING_LPFSA_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/lpfsa/create';
const SPENDING_LPFSA_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/lpfsa/:planId/edit';
const SPENDING_HRA_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/hra/create';
const SPENDING_HRA_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/hra/:planId/edit';

const SPENDING_HSA_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/hsa/:planId/view';
const SPENDING_MEDICAL_FSA_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/medical-fsa/:planId/view';
const SPENDING_DCAPFSA_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/dcapfsa/:planId/view';
const SPENDING_LPFSA_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/lpfsa/:planId/view';
const SPENDING_HRA_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/hra/:planId/view';

const SPENDING_HSA_CLONE_BASIC_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/hsa/:planId/clone';
const SPENDING_MEDICAL_FSA_CLONE_BASIC_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/medical-fsa/:planId/clone';
const SPENDING_DCAPFSA_CLONE_BASIC_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/dcapfsa/:planId/clone';
const SPENDING_LPFSA_CLONE_BASIC_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/lpfsa/:planId/clone';
const SPENDING_HRA_CLONE_BASIC_PATH =
  '/brokers/:brokerId/employers/:employerId/tax-advantaged/spending-plans/hra/:planId/clone';
  
export {
  SPENDING_LIST_PATH,
  SPENDING_HSA_CREATE_PATH,
  SPENDING_HSA_EDIT_PATH,
  SPENDING_MEDICAL_FSA_CREATE_PATH,
  SPENDING_MEDICAL_FSA_EDIT_PATH,
  SPENDING_DCAPFSA_CREATE_PATH,
  SPENDING_DCAPFSA_EDIT_PATH,
  SPENDING_LPFSA_CREATE_PATH,
  SPENDING_LPFSA_EDIT_PATH,
  SPENDING_HRA_CREATE_PATH,
  SPENDING_HRA_EDIT_PATH,
  SPENDING_HSA_VIEW_PATH,
  SPENDING_MEDICAL_FSA_VIEW_PATH,
  SPENDING_DCAPFSA_VIEW_PATH,
  SPENDING_LPFSA_VIEW_PATH,
  SPENDING_HRA_VIEW_PATH,
  SPENDING_HSA_CLONE_BASIC_PATH,
  SPENDING_MEDICAL_FSA_CLONE_BASIC_PATH,
  SPENDING_DCAPFSA_CLONE_BASIC_PATH,
  SPENDING_LPFSA_CLONE_BASIC_PATH,
  SPENDING_HRA_CLONE_BASIC_PATH
};
