export default {
  'unsupported.file.type': 'Failed to save the plan - Unsupported file type.',
  'validation.duplicate.exists': 'Failed to save the plan - Plan name exists.'
};

export const planError = {
  ELECTION_EXISTS: {
    code: 'elections.exists.for.plan',
    message: 'Cannot update the plan year as there are elections available for this plan.'
  }
};

export const ValidateError = {
  PLAN_USED_ELECTIONS: {
    code: 'plan.used.elections',
    message: 'cannot be deleted. This plan is either currently used in a benefits guide or associated with employee elections.'
  },
  PLAN_USED_BENGUIDES: {
    code: 'plan.used.benguides',
    message: 'cannot be deleted. This plan is either currently used in a benefits guide or associated with employee elections.'
  },
  PLAN_USED_MEDICAL_PLANS: {
    code: 'plan.used.medical',
    message:
      'cannot be deleted as it is attached to an existing medical plan. Please remove from the medical plan before deleting.'
  }
};