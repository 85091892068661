/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'components/Atoms';
import Icon from 'components/Icons';

import './ConfirmationBox.scss';

/**
 * Component for generate Synced status badge.
 * @param props
 * @returns {*}
 */
const ConfirmationBox = (props) => {
  const {
    isOpen,
    toggle,
    message,
    warningMessage,
    confirmCallback,
    confirmText,
    disabled = false,
    className = '',
    showIcon = true
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered={true}
      size="md"
      className={className}
    >
      <ModalBody>
        <span className="text large">
          {showIcon && (
            <Icon
              icon="exclamation-triangle"
              className="icon-margin-right icon-color"
            />
          )}
          {message}
        </span>
      </ModalBody>
      {warningMessage && (
        <ModalFooter className="warning-message">{warningMessage}</ModalFooter>
      )}
      <ModalFooter className={warningMessage ? 'remove-border-top' : ''}>
        <Button
          color="primary"
          size="lg"
          disabled={disabled}
          onClick={confirmCallback}
        >
          {confirmText}
        </Button>
        <Button
          color="primary"
          size="lg"
          disabled={disabled}
          outline
          onClick={toggle}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationBox;
