import _ from 'lodash';
import { ADMIN, OPS, BC } from './../modules/app-base/constants/roleConstants';

export const DEFAULT_ROLES = [ADMIN, OPS, BC];

export const getIsRolePermitted = (userRoles, rolesMapping) => {
  let isAllowed = false;
  _.forEach(userRoles, function(role) {
    const isRoleIncludes = rolesMapping
      ? rolesMapping.includes(role)
      : DEFAULT_ROLES.includes(role);
    if (isRoleIncludes) {
      isAllowed = isRoleIncludes;
    }
  });
  return isAllowed;
}

export const hasOwnership = (loginType, ownership) => {
  return ownership ? ownership.includes(loginType) : true;
};
