/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import DataTableWithServerPagination from 'components/DataTables';
import { NotificationManager } from 'react-notifications';
import * as ActionTypes from '../../actionTypes/surveys.actionTypes';
import {
  getSurveyFeatureConfig,
  updateSurveyFeatureConfig,
  getSurveys
} from '../../actions/surveysAction';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import { permitIf } from 'components/hoc/Permit';
import AddButton from 'components/Buttons/AddButton';
import {
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'components/Atoms';
import { generatePath, Link } from 'react-router-dom';
import './SurveyHome.scss';
// utils
import { formatDateInPST } from 'util/dateUtil';
// constants
import { SURVEYS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import {
  MODULE_SURVEYS_ENABLED,
  STATUS_INFO_MAP,
  SURVEY_STATUS
} from 'modules/Surveys/constants';
import * as SurveysRoutes from '../../routes';

class SurveyHome extends React.Component {
  constructor(props) {
    super(props);
    const { employerId } = props.match.params;
    this.state = {
      employerId: employerId
    };
    this.props.getSurveyFeatureConfig(employerId);
  }
  componentDidMount() {}
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { requestStatus, serviceError, configStatus } = this.props;
    const { requestStatus: prevRequestStatus } = prevProps;
    if (prevRequestStatus === requestStatus) return;

    if (serviceError) {
      // Generic Error
      NotificationManager.error(serviceError.message);
      return;
    }

    switch (requestStatus) {
      case ActionTypes.UPDATE_SURVEY_FEATURE_CONF_SUCCESS: {
        const value = configStatus.value;
        NotificationManager.success(
          `Feature ${value === 'true' ? 'Enabled' : 'Disabled'} Successfully`
        );
        return;
      }
      default:
        break;
    }
  }
  switchSurveysEnabled = (value) => {
    const { configStatus, updateSurveyFeatureConfig } = this.props;

    const newValue =
      configStatus.value === true || configStatus.value === 'true'
        ? 'false'
        : 'true';
    const updatedConfig = { ...configStatus, value: newValue };
    updateSurveyFeatureConfig(updatedConfig);
  };

  columnData = (employerId) => [
    {
      selector: 'surveyName',
      name: 'Survey Name',
      cell: ({ surveyName, id }) => (
        <Link to={`surveys/${id}`}>
          <h5>{surveyName}</h5>
        </Link>
      ),
      grow: 2,
      maxWidth: '800px',
      wrap: true
    },

    {
      name: 'Status',
      grow: 3,
      cell: ({ status, closeDate, id }) => {
        const { name, color } = STATUS_INFO_MAP[status];
        return (
          <div style={{ color }} className="survey-status">
            <Link to={`surveys/${id}`}>
              <span style={{ color }}>{name}</span>
              {status === SURVEY_STATUS.CLOSED ? (
                <span
                  style={{ color }}
                  className="closed-date"
                >{` ${formatDateInPST(closeDate)}`}</span>
              ) : (
                ''
              )}
            </Link>
          </div>
        );
      }
    },

    {
      name: '# Pending Recipients',
      grow: 1,
      cell: ({ pendingCount }) => pendingCount || '-'
    },

    {
      name: '# Sent Recipients',
      grow: 1,
      cell: ({ notifiedCount }) => notifiedCount || '-'
    },

    {
      name: '# Completed Surveys',
      grow: 1,
      cell: ({ completedCount }) => completedCount || '-'
    },

    {
      name: 'Actions',
      grow: 1,
      cell: ({ id, url }) => {
        return (
          <UncontrolledDropdown className="actions">
            <DropdownToggle tag="a" className="cursor-pointer" caret>
              Select
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <Link to={`surveys/${id}`}>Preview Survey</Link>
              </DropdownItem>
              {url && <DropdownItem>Survey Url</DropdownItem>}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      width: '66px'
    }
  ];

  redirectToSurvey = ({ id: surveyId }) => {
    const { match, history } = this.props;
    history.push(
      generatePath(SurveysRoutes.SURVEYS_DETAILS_PATH, {
        ...match.params,
        surveyId
      })
    );
  };

  render() {
    const employerId = this.state.employerId;
    const { configStatus, surveysList, match } = this.props;
    const { params } = match;
    const { brokerId } = params;
    const SecuredEmployerConfigToggle = permitIf(
      EmployerConfigToggleV2,
      SURVEYS_FEATURE_ROLE_MAPPING.common
    );

    return (
      <ApplicationWrapper>
        <HeaderContainer title="Surveys" />
        <Row>
          <Col className="page-content">
            <ContentContainer>
              <SecuredEmployerConfigToggle
                employerId={employerId}
                moduleEnabled={MODULE_SURVEYS_ENABLED}
                message="Surveys Access"
                config={configStatus}
                switchCallBack={this.switchSurveysEnabled}
              />
            </ContentContainer>
            <ContentContainer>
              <DataTableWithServerPagination
                className="surveys-list"
                onRef={(ref) => (this.surveysList = ref)}
                columnData={this.columnData(employerId)}
                fetchData={(page, size, sort, query) => {
                  this.props.getSurveys({
                    employerId,
                    page,
                    size,
                    sort: '-createdTs',
                    query
                  });
                }}
                pageData={surveysList}
                onRowClicked={this.redirectToSurvey}
                hideSearchBar={false}
              />
            </ContentContainer>
          </Col>
          <Col className="page-actions">
            <AddButton
              onClick={() =>
                this.props.history.push(
                  `/brokers/${brokerId}/employers/${employerId}/surveys/new`
                )
              }
              title="Add New Survey"
              iconType="plus"
            />
          </Col>
        </Row>
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSurveys: (surveyConfig) => dispatch(getSurveys(surveyConfig)),
  getSurveyFeatureConfig: (employerId) =>
    dispatch(getSurveyFeatureConfig(employerId)),
  updateSurveyFeatureConfig: (config, value) =>
    dispatch(updateSurveyFeatureConfig(config, value))
});

const mapStateToProps = (state) => {
  const {
    requestStatus,
    serviceError,
    isLoading,
    configStatus,
    surveysList
  } = state.surveysReducer;
  return {
    requestStatus,
    serviceError,
    isLoading,
    configStatus,
    surveysList
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyHome);
