/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'components/Atoms';
import Icon from 'components/Icons';
import './TwoActionConfirmationBox.scss';

/**
 * Component for showing confirmation box with two action buttons and cancel button.
 * @param props
 * @returns {*}
 */
const TwoActionConfirmationBox = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      centered={true}
      size="md"
    >
      <ModalBody>
        <span className="text large">
          <Icon
            icon="exclamation-triangle"
            className="icon-margin-right icon-color"
          />
          {props.message}
        </span>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" onClick={props.actionOneCallback}>
          {props.actionOneText}
        </Button>{' '}
        <Button color="primary" size="lg" onClick={props.actionTwoCallback}>
          {props.actionTwoText}
        </Button>{' '}
        <Button
          color="primary"
          outline
          size="lg"
          onClick={props.cancelActionCallback}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TwoActionConfirmationBox;
