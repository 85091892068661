/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as SurveysPages from './index';

const SURVEYS_PATH = '/brokers/:brokerId/employers/:employerId/surveys';
const SURVEYS_CREATE_PATH = '/brokers/:brokerId/employers/:employerId/surveys/new';
const SURVEYS_EDIT_PATH = '/brokers/:brokerId/employers/:employerId/surveys/edit/:surveyId';
const SURVEYS_DETAILS_PATH = '/brokers/:brokerId/employers/:employerId/surveys/:surveyId';

const surveysPathMapping = [
  { path: SURVEYS_PATH, component: SurveysPages.SurveyHome },
  { path: SURVEYS_CREATE_PATH, component: SurveysPages.CreateSurvey },
  { path: SURVEYS_EDIT_PATH, component: SurveysPages.CreateSurvey },
  { path: SURVEYS_DETAILS_PATH, component: SurveysPages.SurveyDetails }
];

export default surveysPathMapping;
export {
  SURVEYS_PATH,
  SURVEYS_CREATE_PATH,
  SURVEYS_EDIT_PATH,
  SURVEYS_DETAILS_PATH
};
