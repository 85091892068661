/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import _ from 'lodash';
import BenGuideService from 'services/BenGuideService';
import {
  GET_BENGUIDES_PAGE,
  GET_BENGUIDES_PAGE_ERROR,
  UPDATE_BEN_GUIDE,
  UPDATE_BEN_GUIDE_VALIDATIONS,
  UPLOAD_OE_VIDEO_IN_PROGRESS,
  UPLOAD_OE_VIDEO_PROGRESS_NOTIFICATION,
  UPLOAD_OE_VIDEO_SUCCESS,
  UPLOAD_OE_VIDEO_ERROR,
  GET_BEN_GUIDE_PLANS_IN_PROGRESS,
  GET_BEN_GUIDE_PLANS_SUCCESS,
  GET_BEN_GUIDE_PLANS_ERROR,
  GET_BEN_GUIDE_BY_ID_IN_PROGRESS,
  GET_BEN_GUIDE_BY_ID_SUCCESS,
  GET_BEN_GUIDE_BY_ID_ERROR,
  UPDATE_BEN_GUIDE_IN_PROGRESS,
  UPDATE_BEN_GUIDE_ERROR,
  UPDATE_BEN_GUIDE_SUCCESS,
  UPLOAD_BENGUIDE_LOGO_IN_PROGRESS,
  UPLOAD_BENGUIDE_LOGO_SUCCESS,
  UPLOAD_BENGUIDE_LOGO_ERROR,
  UPLOAD_BENGUIDE_BACKGROUND_IN_PROGRESS,
  UPLOAD_BENGUIDE_BACKGROUND_SUCCESS,
  UPLOAD_BENGUIDE_BACKGROUND_ERROR,
  UPLOAD_BENGUIDE_FAMILY_IMAGE_IN_PROGRESS,
  UPLOAD_BENGUIDE_FAMILY_IMAGE_SUCCESS,
  UPLOAD_BENGUIDE_FAMILY_IMAGE_ERROR,
  UPLOAD_BENGUIDE_TIME_OFF_IMAGE_IN_PROGRESS,
  UPLOAD_BENGUIDE_TIME_OFF_IMAGE_SUCCESS,
  UPLOAD_BENGUIDE_TIME_OFF_IMAGE_ERROR,
  UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_IN_PROGRESS,
  UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_SUCCESS,
  UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_ERROR,
  UPLOAD_BENGUIDE_PERK_IMAGE_IN_PROGRESS,
  UPLOAD_BENGUIDE_PERK_IMAGE_SUCCESS,
  UPLOAD_BENGUIDE_PERK_IMAGE_ERROR,
  GET_BENGUIDE_LOGO_SUCCESS,
  GET_BENGUIDE_BACKGROUND_SUCCESS,
  GET_BENGUIDE_FAMILY_IMAGE_SUCCESS,
  GET_BENGUIDE_TIME_OFF_IMAGE_SUCCESS,
  GET_BENGUIDE_COMMUNITIES_IMAGE_SUCCESS,
  GET_BENGUIDE_PERK_IMAGE_SUCCESS,
  PUBLISH_BENGUIDE_IN_PROGRESS,
  PUBLISH_BENGUIDE_SUCCESS,
  PUBLISH_BENGUIDE_ERROR,
  GET_BENGUIDE_LOGO_IN_PROGRESS,
  GET_BENGUIDE_FAMILY_IMAGE_IN_PROGRESS,
  GET_BENGUIDE_TIME_OFF_IMAGE_IN_PROGRESS,
  GET_BENGUIDE_COMMUNITIES_IMAGE_IN_PROGRESS,
  GET_BENGUIDE_PERK_IMAGE_IN_PROGRESS,
  GET_BENGUIDE_FAMILY_IMAGE_ERROR,
  GET_BENGUIDE_TIME_OFF_IMAGE_ERROR,
  GET_BENGUIDE_COMMUNITIES_IMAGE_ERROR,
  GET_BENGUIDE_PERK_IMAGE_ERROR,
  GET_BENGUIDE_LOGO_ERROR,
  VALIDATE_BEN_GUIDE_NAME_IN_PROGRESS,
  VALIDATE_BEN_GUIDE_NAME_SUCCESS,
  VALIDATE_BEN_GUIDE_NAME_ERROR,
  UPLOAD_PERK_DOC_IN_PROGRESS,
  UPLOAD_PERK_DOC_SUCCESS,
  UPLOAD_PERK_DOC_ERROR,
  UPLOAD_RESOURCE_DOC_IN_PROGRESS,
  UPLOAD_RESOURCE_DOC_SUCCESS,
  UPLOAD_RESOURCE_DOC_ERROR,
  GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNT_IN_PROGRESS,
  GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNT,
  GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ERROR,
  PREVIEW_BENGUIDE_ERROR,
  PREVIEW_BENGUIDE_IN_PROGRESS,
  PREVIEW_BENGUIDE_SUCCESS,
  GET_OE_MEETING_USERS_IN_PROGRESS,
  GET_OE_MEETING_USERS,
  GET_OE_MEETING_USERS_ERROR,
  GET_OE_MEETING_IN_PROGRESS,
  SAVE_OE_MEETING_IN_PROGRESS,
  SAVE_OE_MEETING_ERROR,
  SAVE_OE_MEETING,
  GET_OE_MEETING,
  GET_OE_MEETING_ERROR,
  DELETE_OE_MEETING_IN_PROGRESS,
  DELETE_OE_MEETING,
  DELETE_OE_MEETING_ERROR,
  UPLOAD_OE_MEETING_RECORDING_FAILED,
  UPLOAD_OE_MEETING_RECORDING_IN_PROGRESS,
  UPLOAD_OE_MEETING_PROGRESS_NOTIFICATION,
  UPLOAD_OE_MEETING_RECORDING_SUCCESS,
  CLEAR_OE_MEETING,
  CREATE_OE_RECORDING_UPLOAD_CANCEL_SOURCE,
  OE_RECORDING_UPLOAD_CANCEL_SUCCESS,
  RESET_BEN_GUIDE_PLANS,
  DELETE_BENGUIDE_IN_PROGRESS,
  DELETE_BENGUIDE_COMPLETED,
  DELETE_BENGUIDE_FAILED,
  CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_IN_PROGRESS,
  CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_COMPLETED,
  CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_FAILED,
  CLONE_BEN_GUIDE_IN_PROGRESS,
  CLONE_BEN_GUIDE_SUCCESS,
  CLONE_BEN_GUIDE_ERROR
} from '../actionTypes/benguide.actionTypes';
import { createProgressDispatcher } from 'util/apiUtil';
import MedicalService from 'services/MedicalPlanService';
import DentalService from 'services/DentalPlanService';
import VisionService from 'services/VisionPlanService';
import SavingAccountsService from 'services/SavingAccountsService';
import CommuterPlanService from 'services/CommuterPlanService';
import LifePlanService from 'services/LifePlanService';
import FourOOneKService from 'services/FourOOneKService';
import WorkLifePlanService from 'services/WorkLifePlanService';
import WellbeingPlanService from 'services/WellbeingPlanService';
import AdditionalPerksService from 'services/AdditionalPerkService';
import TelehealthRxDeliveryPlanService from 'services/TelehealthRxDeliveryPlanService';
import moment from 'moment';
import { OE_MEETING_CONFIG, PLAN_NAME_GROUP_SEPARATOR } from './../constants';
import {
  BENEFIT_CATEGORY_COMMUTER,
  BENEFIT_CATEGORY_SAVING,
  BENEFIT_KIND_HSA
} from 'constants/benefitConstants';
import { WorkLife } from '../../../constants/workLifeSubCategory';
import MeetingService from 'services/MeetingService';
import { connect } from 'react-redux';
import axios from 'axios';

export const getBenGuidesPage = (
  employerId,
  selectedPlanYearIds,
  page,
  size,
  sort,
  query
) => {
  return async (dispatch) => {
    try {
      const response = await BenGuideService.findAll(
        employerId,
        selectedPlanYearIds,
        page,
        size,
        sort,
        query
      );
      dispatch({ type: GET_BENGUIDES_PAGE, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_BENGUIDES_PAGE_ERROR, payload: error });
    }
  };
};

export const initBenGuide = (benGuideId, employerId) => async (dispatch) => {
  try {
    const benGuideObj = await constructBenGuideObj(benGuideId, employerId);
    dispatch({
      type: UPDATE_BEN_GUIDE,
      payload: benGuideObj
    });
  } catch (error) {}
};

export const updateBenGuide = (benGuideObj, prop, value) => (dispatch) => {
  let payload = { ...benGuideObj };
  _.set(payload, prop, value);
  dispatch({
    type: UPDATE_BEN_GUIDE,
    payload
  });
};

export const initBenGuideValidations = () => (dispatch) => {
  dispatch({
    type: UPDATE_BEN_GUIDE_VALIDATIONS,
    payload: {
      name: false,
      planYearId: false,
      dateRange: false
    }
  });
};

export const updateBenGuideValidations = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_BEN_GUIDE_VALIDATIONS,
    payload
  });
};

export const uploadOEVideo = (benGuideId, fileBlob, fileName) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_OE_VIDEO_IN_PROGRESS });
      const progressDispatcher = createProgressDispatcher(
        dispatch,
        UPLOAD_OE_VIDEO_PROGRESS_NOTIFICATION
      );
      const response = await BenGuideService.uploadOEVideo(
        benGuideId,
        fileBlob,
        fileName,
        progressDispatcher
      );

      dispatch({
        type: UPLOAD_OE_VIDEO_SUCCESS,
        payload: response.data.reference
      });
    } catch (error) {
      dispatch({ type: UPLOAD_OE_VIDEO_ERROR, payload: error });
    }
  };
};

/**
 * get All the plan names and ids.
 */
export const getAllPlans = (employerId, planYearId) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_BEN_GUIDE_PLANS_IN_PROGRESS });
    try {
      const benGuideObj =
        getState().benGuideReducer.benGuideData.benGuideObj !== null
          ? getState().benGuideReducer.benGuideData.benGuideObj
          : await constructBenGuideObj(null, employerId);
      let response = await MedicalService.findAllBasicPlanInfo(
        employerId,
        planYearId
      );
      let allPlansResponse = await MedicalService.findAllBasicPlanInfoWithArchived(
        employerId,
        planYearId
      );
      let data = response.data;
      let plans = transformData(data);
      let allPlans = transformData(allPlansResponse.data);
      let savedMedicalPlans = benGuideObj.medicalPlans;
      let addedPlans = [];
      let availablePlans = [];
      let medicalPayload = {};
      if (savedMedicalPlans) {
        const medicalPlanDataList = await transformPlanData(
          savedMedicalPlans,
          addedPlans,
          availablePlans,
          plans,
          'medicalPlans',
          benGuideObj
        );
        medicalPayload = {
          medicalAllPlans: plans,
          availableMedicalPlans: medicalPlanDataList[1],
          addedMedicalPlans: medicalPlanDataList[0],
          medicalPlans: plans
        };
      } else {
        medicalPayload = { medicalPlans: plans };
      }
      response = await DentalService.findAllBasicPlanInfo(
        employerId,
        planYearId
      );
      allPlansResponse = await DentalService.findAllBasicPlanInfoWithArchived(
        employerId,
        planYearId
      );

      data = response.data;
      plans = transformData(data);
      allPlans = transformData(allPlansResponse.data);
      const savedDentalPlans = benGuideObj.dentalPlans;
      let dentalPayload = {};
      if (savedDentalPlans) {
        let addedDentalPlans = [];
        let availableDentalPlans = [];
        if (savedDentalPlans) {
          const planDataList = await transformPlanData(
            savedDentalPlans,
            addedDentalPlans,
            availableDentalPlans,
            plans,
            'dentalPlans',
            benGuideObj
          );
          dentalPayload = {
            dentalAllPlans: plans,
            addedDentalPlans: planDataList[0],
            availableDentalPlans: planDataList[1],
            dentalPlans: plans
          };
        }
      } else {
        dentalPayload = { dentalPlans: plans };
      }

      response = await VisionService.findAllBasicPlanInfo(
        employerId,
        planYearId
      );
      allPlansResponse = await VisionService.findAllBasicPlanInfoWithArchived(
        employerId,
        planYearId
      );
      data = response.data;
      const visionPlans = transformData(data);
      const savedVisionPlans = benGuideObj.visionPlans;
      allPlans = transformData(allPlansResponse.data);
      let visionPayload = {};
      if (savedVisionPlans) {
        let addedVisionPlans = [];
        let availableVisionPlans = [];
        const planDataList = await transformPlanData(
          savedVisionPlans,
          addedVisionPlans,
          availableVisionPlans,
          visionPlans,
          'visionPlans',
          benGuideObj
        );
        visionPayload = {
          visionAllPlans: visionPlans,
          addedVisionPlans: planDataList[0],
          availableVisionPlans: planDataList[1],
          visionPlans
        };
      } else {
        visionPayload = { visionPlans };
      }

      const savingsAllPlans = SavingAccountsService.findAllBasicPlanInfo(
        employerId,
        planYearId
      );
      const allSavingsPlans = SavingAccountsService.findAllBasicPlanInfoWithArchived(
        employerId,
        planYearId
      );
      const commuterAllPlans = CommuterPlanService.findAllBasicPlanInfo(
        employerId,
        planYearId
      );
      const allCommuterPlans = CommuterPlanService.findAllBasicPlanInfoWithArchived(
        employerId,
        planYearId
      );
      data = await Promise.all([savingsAllPlans, commuterAllPlans]).then(
        (values) => {
          values[0].data &&
            values[0].data.map((plan) => {
              plan.benefitCategory = BENEFIT_CATEGORY_SAVING;
              return plan;
            });
          values[1].data &&
            values[1].data.map((plan) => {
              plan.benefitCategory = BENEFIT_CATEGORY_COMMUTER;
              return plan;
            });
          return values[0].data.concat(values[1].data);
        }
      );

      allPlansResponse = await Promise.all([
        allSavingsPlans,
        allCommuterPlans
      ]).then((values) => {
        values[0] &&
          values[0].data &&
          values[0].data.map((plan) => {
            plan.benefitCategory = BENEFIT_CATEGORY_SAVING;
            return plan;
          });
        values[1] &&
          values[1].data &&
          values[1].data.map((plan) => {
            plan.benefitCategory = BENEFIT_CATEGORY_COMMUTER;
            return plan;
          });
        return values[0].data.concat(values[1].data);
      });

      const taxAdvantagedPlans = await transformData(data);

      allPlans = await transformData(allPlansResponse);
      const savedTaxAdvantagedPlans = benGuideObj.taxAdvantagedAccountPlans;

      let taxAdvantagedPayload = {};
      if (savedTaxAdvantagedPlans) {
        let addedTaxAdvantagedAccountPlans = [];
        let availableTaxAdvantagedAccountPlans = [];
        const planDataList = await transformPlanData(
          savedTaxAdvantagedPlans,
          addedTaxAdvantagedAccountPlans,
          availableTaxAdvantagedAccountPlans,
          taxAdvantagedPlans,
          'taxAdvantagedAccountPlans',
          benGuideObj
        );

        taxAdvantagedPayload = {
          taxAdvantagedAllPlans: taxAdvantagedPlans,
          availableTaxAdvantagedAccountPlans: planDataList[1],
          addedTaxAdvantagedAccountPlans: planDataList[0],
          taxAdvantagedPlans,
          allTaxAdvantagePlansWithArchived: allPlans
        };
      } else {
        taxAdvantagedPayload = {
          taxAdvantagedPlans,
          allTaxAdvantagePlansWithArchived: allPlans
        };
      }

      response = await LifePlanService.findAllBasicPlanInfo(
        employerId,
        planYearId
      );
      allPlansResponse = await LifePlanService.findAllBasicPlanInfoWithArchived(
        employerId,
        planYearId
      );
      data = response.data;
      let lifePlanPayload = {};
      const lifePlans = transformData(data);
      allPlans = transformData(allPlansResponse.data);
      const savedLifePlans = benGuideObj.insurancePlans;
      if (savedLifePlans) {
        let addedLifePlans = [];
        let availableLifePlans = [];
        const planDataList = await transformPlanData(
          savedLifePlans,
          addedLifePlans,
          availableLifePlans,
          lifePlans,
          'lifePlans',
          benGuideObj
        );
        lifePlanPayload = {
          lifeAllPlans: lifePlans,
          addedLifePlans: planDataList[0],
          availableLifePlans: planDataList[1],
          lifePlans
        };
      } else {
        lifePlanPayload = {
          lifePlans
        };
      }

      response = await FourOOneKService.findAllBasicPlanInfo(
        employerId,
        planYearId
      );
      allPlansResponse = await FourOOneKService.findAllBasicPlanInfoWithArchived(
        employerId,
        planYearId
      );
      data = response.data;
      const fourOOneKPlans = transformData(data);
      allPlans = transformData(allPlansResponse.data);
      const savedFourOOneKPlans = benGuideObj.fourOOneKPlans;
      let fourOOneKPayload = {};
      if (savedFourOOneKPlans) {
        let addedFourOOneKPlans = [];
        let availableFourOOneKPlans = [];
        const planDataList = await transformPlanData(
          savedFourOOneKPlans,
          addedFourOOneKPlans,
          availableFourOOneKPlans,
          fourOOneKPlans,
          'fourOOneKPlans',
          benGuideObj
        );
        fourOOneKPayload = {
          fourOOneKAllPlans: fourOOneKPlans,
          addedFourOOneKPlans: planDataList[0],
          availableFourOOneKPlans: planDataList[1],
          fourOOneKPlans
        };
      } else {
        fourOOneKPayload = {
          fourOOneKPlans
        };
      }

      response = await WorkLifePlanService.findAllBasicPlanInfo(
        employerId,
        planYearId
      );
      allPlansResponse = await WorkLifePlanService.findAllBasicPlanInfoWithArchived(
        employerId,
        planYearId
      );
      data = response.data;
      const workLifePlans = transformData(data);
      allPlans = transformData(allPlansResponse.data);
      const savedWorkLifePlans = benGuideObj.workLifePlans;
      let workLifePayload = {};
      if (savedWorkLifePlans) {
        let addedWorkLifePlans = [];
        let availableWorkLifePlans = [];
        const planDataList = await transformPlanData(
          savedWorkLifePlans,
          addedWorkLifePlans,
          availableWorkLifePlans,
          workLifePlans,
          'workLifePlans',
          benGuideObj
        );
        workLifePayload = {
          workLifeAllPlans: workLifePlans,
          addedWorkLifePlans: planDataList[0],
          availableWorkLifePlans: planDataList[1],
          workLifePlans
        };
      } else {
        workLifePayload = {
          workLifePlans
        };
      }

      response = await WellbeingPlanService.findAllBasicPlanInfo(
        employerId,
        planYearId
      );
      allPlansResponse = await WellbeingPlanService.findAllBasicPlanInfoWithArchived(
        employerId,
        planYearId
      );
      data = response.data;
      const wellbeingPlans = transformData(data);
      allPlans = transformData(allPlansResponse.data);
      const savedWellbeingPlans = benGuideObj.wellbeingPlans;
      let wellbeingPayload = {};
      if (savedWellbeingPlans) {
        let addedWellbeingPlans = [];
        let availableWellbeingPlans = [];
        const planDataList = await transformPlanData(
          savedWellbeingPlans,
          addedWellbeingPlans,
          availableWellbeingPlans,
          wellbeingPlans,
          'wellbeingPlans',
          benGuideObj
        );
        wellbeingPayload = {
          wellbeingAllPlans: wellbeingPlans,
          addedWellbeingPlans: planDataList[0],
          availableWellbeingPlans: planDataList[1],
          wellbeingPlans
        };
      } else {
        wellbeingPayload = {
          wellbeingPlans
        };
      }

      response = await AdditionalPerksService.findAllBasicPlanInfo(
        employerId,
        planYearId
      );
      allPlansResponse = await AdditionalPerksService.findAllBasicPlanInfoWithArchived(
        employerId,
        planYearId
      );
      data = response.data;
      const additionalPerks = transformData(data);
      allPlans = transformData(allPlansResponse.data);
      const savedAdditionalPerks = benGuideObj.additionalPerks;
      let additionalPerksPayload = {};
      if (savedAdditionalPerks) {
        let addedAdditionalPerks = [];
        let availableAdditionalPerks = [];
        const planDataList = await transformPlanData(
          savedAdditionalPerks,
          addedAdditionalPerks,
          availableAdditionalPerks,
          additionalPerks,
          'additionalPerks',
          benGuideObj
        );
        additionalPerksPayload = {
          additionalPerksAll: additionalPerks,
          addedAdditionalPerks: planDataList[0],
          availableAdditionalPerks: planDataList[1],
          additionalPerks
        };
      } else {
        additionalPerksPayload = {
          additionalPerks
        };
      }

      response = await TelehealthRxDeliveryPlanService.findAllBasicTelehealthPlanInfoWithoutArchived(
        employerId,
        planYearId
      );
      allPlansResponse = await TelehealthRxDeliveryPlanService.findAllBasicPlanInfoWithArchived(
        employerId,
        planYearId
      );
      data = response.data;
      const telehealthRxDeliveryPlans = transformData(data);
      allPlans = transformData(allPlansResponse.data);
      const savedTelehealthPlans = benGuideObj.telehealthRxDeliveryPlans;
      let telehealthPayload = {};
      if (savedTelehealthPlans) {
        let addedTelehealthPlans = [];
        let availableTelehealthPlans = [];
        const planDataList = await transformPlanData(
          savedTelehealthPlans,
          addedTelehealthPlans,
          availableTelehealthPlans,
          telehealthRxDeliveryPlans,
          'telehealthRxDeliveryPlans',
          benGuideObj
        );
        telehealthPayload = {
          telehealthAllPlans: telehealthRxDeliveryPlans,
          addedTelehealthPlans: planDataList[0],
          availableTelehealthPlans: planDataList[1],
          telehealthRxDeliveryPlans
        };
      } else {
        telehealthPayload = {
          telehealthRxDeliveryPlans
        };
      }
      dispatch({
        type: GET_BEN_GUIDE_PLANS_SUCCESS,
        payload: {
          ...medicalPayload,
          ...dentalPayload,
          ...visionPayload,
          ...lifePlanPayload,
          ...fourOOneKPayload,
          ...workLifePayload,
          ...wellbeingPayload,
          ...additionalPerksPayload,
          ...taxAdvantagedPayload,
          ...telehealthPayload
        }
      });
    } catch (error) {
      dispatch({ type: GET_BEN_GUIDE_PLANS_ERROR, payload: error });
    }
  };
};
export const transformPlanData = (
  savedPlans,
  addedPlans,
  availablePlans,
  transformedData,
  planType,
  benGuideObj
) => {
  let transformedSavedPlans = transformData(savedPlans.plans);
  transformedSavedPlans.map((p) => {
    const plan = transformedData.find((t) => t.id === p.id);
    if (plan) {
      p.benefitKind = plan.benefitKind;
      p.hsaPlan = plan.hsaPlan;
      p.version = plan.version;
    }
    return p;
  });
  addedPlans = addedPlans.concat(transformedSavedPlans);
  availablePlans = _.difference(transformedData, addedPlans);
  // availablePlans = transformedData;
  updateCurrentAddedPlansInReduxObject(planType, addedPlans, benGuideObj);
  return [addedPlans, availablePlans];
};

/**
 * transform plan data list of plan-id and plan-name.
 */
export const transformData = (data) => {
  const expandedPlans = expandPlansByGroup(data);
  return expandedPlans.map((plan) => {
    const {
      planId,
      group,
      planName,
      revision,
      startDate,
      endDate,
      benefitCategory,
      archived,
      benefitKind,
      hsaPlan
    } = plan;
    return {
      id: planId,
      group: group,
      displayName: `${planName +
        (group ? PLAN_NAME_GROUP_SEPARATOR + group : '') +
        ' (' +
        moment(startDate).format('MMM YYYY') +
        ' - ' +
        moment(endDate).format('MMM YYYY') +
        ')'}`,
      planName: planName,
      version: revision,
      benefitCategory: benefitCategory ? benefitCategory : null,
      archived: archived,
      benefitKind: benefitKind,
      hsaPlan: hsaPlan,
      startDate: startDate
    };
  });
};

/**
 * when editing the BenGuide, existing plans might be updated and revision will be different. So that, update the
 * existing BenGuide with latest plan's revisions.
 *
 * @param planTypes - plan type like 'medical', 'dental'
 * @param addedPlans - added plans.
 * @param benGuideObj
 * @returns {*}
 */
export const updateCurrentAddedPlansInReduxObject = (
  planTypes,
  addedPlans,
  benGuideObj
) => {
  if (addedPlans.length === 0) return;
  const plans = addedPlans.map((plan) => {
    return {
      planId: plan.id,
      planName: plan.planName,
      endDate: plan.endDate,
      startDate: plan.startDate,
      archived: plan.archived,
      group: plan.group,
      revision: plan.version,
      benefitCategory: plan.benefitCategory,
      benefitKind: plan.benefitKind
    };
  });
  const collapsedPlans = [];
  const plansByIdAndGroup = _.groupBy(plans, (plan) =>
    JSON.stringify({
      planId: plan.planId,
      planName: plan.planName,
      endDate: plan.endDate,
      startDate: plan.startDate,
      archived: plan.archived,
      groups: [],
      revision: plan.revision,
      benefitCategory: plan.benefitCategory
    })
  );
  _.forEach(plansByIdAndGroup, (plans, idVersionKey) => {
    const collapsedPlan = _.reduce(
      plans,
      (aggregatedPlan, planForGroup) => {
        aggregatedPlan.groups.push(planForGroup.group);
        return aggregatedPlan;
      },
      JSON.parse(idVersionKey)
    );
    collapsedPlans.push(collapsedPlan);
  });

  if (planTypes === 'lifePlans') {
    planTypes = 'insurancePlans';
  }
  const updatedBGObj = _.set(
    { ...benGuideObj },
    `${planTypes}.plans`,
    collapsedPlans
  );
  const mapDispatchToProps = async (dispatch) => {
    dispatch({ type: UPDATE_BEN_GUIDE, payload: updatedBGObj });
  };
  connect(null, mapDispatchToProps);
};
/**
 * Takes a list of plans and creates a new list by expanding each plan's group attribute to create
 * a plan copy for each group. If no groups exist (likely nonMDV) then performs identity.
 */
export const expandPlansByGroup = (plansList) => {
  const expandedPlans = [];
  plansList.forEach((plan) => {
    if (plan.groups) {
      plan.groups.forEach((group) =>
        expandedPlans.push({ ...plan, groups: undefined, group: group })
      );
    } else {
      expandedPlans.push({ ...plan, groups: undefined, group: null });
    }
  });
  return expandedPlans;
};

/**
 * get ben guide for given id.
 * @param benGuideId - Ben Guide Id.
 * @param employerId
 * @returns {Promise<void>}
 */
export const getBenGuideById = (benGuideId, employerId) => {
  return async (dispatch) => {
    dispatch({ type: GET_BEN_GUIDE_BY_ID_IN_PROGRESS });
    try {
      let data = {};
      if (benGuideId && benGuideId !== 'null') {
        const response = await BenGuideService.findLatestById(benGuideId);
        data = response.data;
      } else {
        data = await constructBenGuideObj(benGuideId, employerId);
      }
      dispatch({ type: GET_BEN_GUIDE_BY_ID_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: GET_BEN_GUIDE_BY_ID_ERROR });
    }
  };
};

export const updateBenGuideById = (payload, isCloned) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_BEN_GUIDE_IN_PROGRESS, payload });
    try {
      await BenGuideService.update(payload, payload.id);
      const response = await BenGuideService.findLatestById(payload.id);
      const { data = {} } = response;

      if (isCloned) {
        dispatch({
          type: UPDATE_BEN_GUIDE_SUCCESS,
          payload: payload
        });
      } else {
        dispatch({
          type: UPDATE_BEN_GUIDE_SUCCESS,
          payload: data
        });
      }
    } catch (e) {
      dispatch({ type: UPDATE_BEN_GUIDE_ERROR, payload: e });
    }
  };
};

export const deleteBenguide = (benGuideId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_BENGUIDE_IN_PROGRESS });
    try {
      await BenGuideService.deleteBenguide(benGuideId);
      dispatch({ type: DELETE_BENGUIDE_COMPLETED });
    } catch (e) {
      dispatch({ type: DELETE_BENGUIDE_FAILED, payload: e });
    }
  };
};

export const checkPublishedVersionExists = (benGuideId) => {
  return async (dispatch) => {
    dispatch({ type: CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_IN_PROGRESS });
    try {
      const response = await BenGuideService.checkPublishedVersionExists(
        benGuideId
      );
      dispatch({
        type: CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_COMPLETED,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_FAILED,
        payload: error
      });
    }
  };
};

export const createBenGuide = (payload, isCloned) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_BEN_GUIDE_IN_PROGRESS, payload });
    try {
      const response = await BenGuideService.create(payload);
      payload.id = response.data.id;
      payload.urlHash = response.data.urlHash;
      payload.styleConfiguration = response.data.styleConfiguration;
      payload.resourceDocuments = response.data.resourceDocuments;
      payload.perkDocuments = response.data.perkDocuments;
      if (isCloned) {
        dispatch({ type: UPDATE_BEN_GUIDE_SUCCESS, payload: payload });
      } else {
        dispatch({ type: UPDATE_BEN_GUIDE_SUCCESS, payload: response.data });
      }
    } catch (e) {
      dispatch({ type: UPDATE_BEN_GUIDE_ERROR, payload: e });
    }
  };
};

export const uploadLogos = (file, benGuideId) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPLOAD_BENGUIDE_LOGO_IN_PROGRESS });
    try {
      const response = await BenGuideService.uploadLogo(file, benGuideId);
      let payload = { ...getState().benGuideReducer.benGuideData.benGuideObj };
      _.set(
        payload,
        'styleConfiguration.logoReference',
        response.data.reference
      );
      const logoRef = await BenGuideService.getLogo(
        benGuideId,
        response.data.reference
      );
      dispatch({
        type: UPLOAD_BENGUIDE_LOGO_SUCCESS,
        payload: { logoRef: logoRef, benGuide: payload }
      });
    } catch (e) {
      dispatch({ type: UPLOAD_BENGUIDE_LOGO_ERROR, payload: e });
    }
  };
};

export const uploadBackground = (file, benGuideId) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPLOAD_BENGUIDE_BACKGROUND_IN_PROGRESS });
    try {
      const response = await BenGuideService.uploadBackground(file, benGuideId);
      let payload = { ...getState().benGuideReducer.benGuideData.benGuideObj };
      _.set(
        payload,
        'styleConfiguration.backgroundReference',
        response.data.reference
      );
      const backgroundRef = await BenGuideService.getBackground(
        benGuideId,
        response.data.reference
      );
      dispatch({
        type: UPLOAD_BENGUIDE_BACKGROUND_SUCCESS,
        payload: backgroundRef
      });
    } catch (e) {
      dispatch({ type: UPLOAD_BENGUIDE_BACKGROUND_ERROR, payload: e });
    }
  };
};
export const getBenGuideLogo = (benGuideId, logoReference) => {
  return async (dispatch) => {
    dispatch({ type: GET_BENGUIDE_LOGO_IN_PROGRESS });
    try {
      let logoRef = null;
      if (logoReference) {
        logoRef = await BenGuideService.getLogo(benGuideId, logoReference);
      }
      dispatch({ type: GET_BENGUIDE_LOGO_SUCCESS, payload: logoRef });
    } catch (e) {
      dispatch({ type: GET_BENGUIDE_LOGO_ERROR, payload: e });
    }
  };
};
export const getBenGuideBackground = (benGuideId, backgroundReference) => {
  return async (dispatch) => {
    try {
      let backgroundRef = null;
      if (backgroundReference) {
        backgroundRef = await BenGuideService.getBackground(
          benGuideId,
          backgroundReference
        );
      }
      dispatch({
        type: GET_BENGUIDE_BACKGROUND_SUCCESS,
        payload: backgroundRef
      });
    } catch (e) {}
  };
};
export const publishBenGuide = (benGuideObj) => {
  return async (dispatch) => {
    dispatch({ type: PUBLISH_BENGUIDE_IN_PROGRESS });
    try {
      await BenGuideService.update(benGuideObj, benGuideObj.id);
      await BenGuideService.publish(benGuideObj.id);
      dispatch({ type: PUBLISH_BENGUIDE_SUCCESS });
    } catch (e) {
      dispatch({ type: PUBLISH_BENGUIDE_ERROR, payload: e });
    }
  };
};
export const previewBenGuide = (benGuideId) => {
  return async (dispatch) => {
    dispatch({ type: PREVIEW_BENGUIDE_IN_PROGRESS });
    try {
      const response = await BenGuideService.preview(benGuideId);
      if (response) {
        const win = window.open(response.data.previewUrl);
        win.focus();
      }
      dispatch({ type: PREVIEW_BENGUIDE_SUCCESS });
    } catch (e) {
      dispatch({ type: PREVIEW_BENGUIDE_ERROR, payload: e });
    }
  };
};

export const validateBenGuideName = (employerId, query, benGuideId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VALIDATE_BEN_GUIDE_NAME_IN_PROGRESS });
      const response = await BenGuideService.search(employerId, query);
      const data = await response.data;
      let alreadyExist = true;
      if (data) {
        if (data.content.length > 0) {
          const benGuide = data.content.find(
            (benguide) => benguide.name.toLowerCase() === query.toLowerCase()
          );
          alreadyExist = !!(benGuide && benGuide.id !== benGuideId);
        } else {
          alreadyExist = false;
        }
      }
      dispatch({
        type: VALIDATE_BEN_GUIDE_NAME_SUCCESS,
        payload: { isValidName: !alreadyExist }
      });
    } catch (e) {
      dispatch({ type: VALIDATE_BEN_GUIDE_NAME_ERROR });
    }
  };
};

export const getBenGuideFamilyImage = (benGuideId, familyImageReference) => {
  return async (dispatch) => {
    dispatch({ type: GET_BENGUIDE_FAMILY_IMAGE_IN_PROGRESS });
    try {
      let familyRef = null;
      if (familyImageReference) {
        familyRef = await BenGuideService.getWorkLifeImage(
          benGuideId,
          familyImageReference,
          WorkLife.FAMILY
        );
      }
      dispatch({
        type: GET_BENGUIDE_FAMILY_IMAGE_SUCCESS,
        payload: familyRef
      });
    } catch (e) {
      dispatch({ type: GET_BENGUIDE_FAMILY_IMAGE_ERROR, payload: e });
    }
  };
};

export const uploadWorkLifeFamilyImage = (file, benGuideId) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPLOAD_BENGUIDE_FAMILY_IMAGE_IN_PROGRESS });
    try {
      const subCategory = WorkLife.FAMILY;
      const response = await BenGuideService.uploadWorkLifeImage(
        file,
        benGuideId,
        subCategory
      );
      let payload = { ...getState().benGuideReducer.benGuideData.benGuideObj };
      _.set(
        payload,
        'styleConfiguration.familyImageReference',
        response.data.reference
      );
      const familyRef = await BenGuideService.getWorkLifeImage(
        benGuideId,
        response.data.reference,
        subCategory
      );
      dispatch({
        type: UPLOAD_BENGUIDE_FAMILY_IMAGE_SUCCESS,
        payload: familyRef
      });
    } catch (e) {
      dispatch({ type: UPLOAD_BENGUIDE_FAMILY_IMAGE_ERROR, payload: e });
    }
  };
};

export const getBenGuideTimeOffImage = (benGuideId, timeOfImageReference) => {
  return async (dispatch) => {
    dispatch({ type: GET_BENGUIDE_TIME_OFF_IMAGE_IN_PROGRESS });
    try {
      let timeOffRef = null;
      if (timeOfImageReference) {
        timeOffRef = await BenGuideService.getWorkLifeImage(
          benGuideId,
          timeOfImageReference,
          WorkLife.TIME_OFF
        );
      }
      dispatch({
        type: GET_BENGUIDE_TIME_OFF_IMAGE_SUCCESS,
        payload: timeOffRef
      });
    } catch (e) {
      dispatch({ type: GET_BENGUIDE_TIME_OFF_IMAGE_ERROR, payload: e });
    }
  };
};

export const uploadWorkLifeTimeOffImage = (file, benGuideId) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPLOAD_BENGUIDE_TIME_OFF_IMAGE_IN_PROGRESS });
    try {
      const subCategory = WorkLife.TIME_OFF;
      const response = await BenGuideService.uploadWorkLifeImage(
        file,
        benGuideId,
        subCategory
      );
      let payload = { ...getState().benGuideReducer.benGuideData.benGuideObj };
      _.set(
        payload,
        'styleConfiguration.timeOfImageReference',
        response.data.reference
      );
      const timeOffRef = await BenGuideService.getWorkLifeImage(
        benGuideId,
        response.data.reference,
        subCategory
      );
      dispatch({
        type: UPLOAD_BENGUIDE_TIME_OFF_IMAGE_SUCCESS,
        payload: timeOffRef
      });
    } catch (e) {
      dispatch({ type: UPLOAD_BENGUIDE_TIME_OFF_IMAGE_ERROR, payload: e });
    }
  };
};

export const getBenGuideCommunitiesImage = (
  benGuideId,
  communitiesImageReference
) => {
  return async (dispatch) => {
    dispatch({ type: GET_BENGUIDE_COMMUNITIES_IMAGE_IN_PROGRESS });
    try {
      let communitiesRef = null;
      if (communitiesImageReference) {
        communitiesRef = await BenGuideService.getWorkLifeImage(
          benGuideId,
          communitiesImageReference,
          WorkLife.COMMUNITIES
        );
      }
      dispatch({
        type: GET_BENGUIDE_COMMUNITIES_IMAGE_SUCCESS,
        payload: communitiesRef
      });
    } catch (e) {
      dispatch({ type: GET_BENGUIDE_COMMUNITIES_IMAGE_ERROR, payload: e });
    }
  };
};

export const uploadWorkLifeCommunitiesImage = (file, benGuideId) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_IN_PROGRESS });
    try {
      const subCategory = WorkLife.COMMUNITIES;
      const response = await BenGuideService.uploadWorkLifeImage(
        file,
        benGuideId,
        subCategory
      );
      let payload = { ...getState().benGuideReducer.benGuideData.benGuideObj };
      _.set(
        payload,
        'styleConfiguration.communitiesImageReference',
        response.data.reference
      );
      const communitiesRef = await BenGuideService.getWorkLifeImage(
        benGuideId,
        response.data.reference,
        subCategory
      );
      dispatch({
        type: UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_SUCCESS,
        payload: communitiesRef
      });
    } catch (e) {
      dispatch({ type: UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_ERROR, payload: e });
    }
  };
};

export const getBenGuideAdditionalPerkImage = (
  benGuideId,
  perksImageReference
) => {
  return async (dispatch) => {
    dispatch({ type: GET_BENGUIDE_PERK_IMAGE_IN_PROGRESS });
    try {
      let perkRef = null;
      if (perksImageReference) {
        perkRef = await BenGuideService.getAdditionalPerkImage(
          benGuideId,
          perksImageReference
        );
      }
      dispatch({
        type: GET_BENGUIDE_PERK_IMAGE_SUCCESS,
        payload: perkRef
      });
    } catch (e) {
      dispatch({ type: GET_BENGUIDE_PERK_IMAGE_ERROR, payload: e });
    }
  };
};

export const uploadAdditionalPerkImage = (file, benGuideId) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPLOAD_BENGUIDE_PERK_IMAGE_IN_PROGRESS });
    try {
      const response = await BenGuideService.uploadAdditionalPerkImage(
        file,
        benGuideId
      );
      let payload = { ...getState().benGuideReducer.benGuideData.benGuideObj };
      _.set(
        payload,
        'styleConfiguration.perksImageReference',
        response.data.reference
      );
      const perkRef = await BenGuideService.getAdditionalPerkImage(
        benGuideId,
        response.data.reference
      );
      dispatch({
        type: UPLOAD_BENGUIDE_PERK_IMAGE_SUCCESS,
        payload: perkRef
      });
    } catch (e) {
      dispatch({ type: UPLOAD_BENGUIDE_PERK_IMAGE_ERROR, payload: e });
    }
  };
};

export const uploadPerkDocument = (fileName, fileBlob, benGuideId) => {
  return async (dispatch) => {
    dispatch({ type: UPLOAD_PERK_DOC_IN_PROGRESS });
    try {
      const response = await BenGuideService.uploadPerkDocument(
        fileName,
        fileBlob,
        benGuideId
      );

      dispatch({
        type: UPLOAD_PERK_DOC_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: UPLOAD_PERK_DOC_ERROR, payload: error });
    }
  };
};

export const uploadResourceDocument = (fileBlob, benGuideId) => {
  return async (dispatch) => {
    dispatch({ type: UPLOAD_RESOURCE_DOC_IN_PROGRESS });
    try {
      const response = await BenGuideService.uploadResourceDocument(
        fileBlob,
        benGuideId
      );

      dispatch({
        type: UPLOAD_RESOURCE_DOC_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: UPLOAD_RESOURCE_DOC_ERROR, payload: error });
    }
  };
};
export const getAvaliableTaxAdvantagedBenefitKinds = (benGuideObj) => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNT_IN_PROGRESS
    });
    try {
      const { taxAdvantagedAccountPlans = {}, hsaPlans = {} } = benGuideObj;
      const {
        plans = [],
        enabled: taxAdvantageAccEnabled
      } = taxAdvantagedAccountPlans;
      const { plans: medicalHSAPlans = [], enabled: hsaEnabled } = hsaPlans;

      const completeSarvingPlansPromises = plans
        .filter((plan) => plan.benefitCategory === BENEFIT_CATEGORY_SAVING)
        .map((savingPlan) => {
          return SavingAccountsService.findById(savingPlan.planId);
        });
      const availableTaxAdvantagedBenefitKinds = (
        await Promise.all(completeSarvingPlansPromises)
      ).map((savingPlan) => savingPlan.data.benefitKind);

      const isCommuteravailable =
        plans.filter(
          (plan) => plan.benefitCategory === BENEFIT_CATEGORY_COMMUTER
        ).length > 0;

      if (isCommuteravailable) {
        availableTaxAdvantagedBenefitKinds.push(BENEFIT_CATEGORY_COMMUTER);
      }
      if (
        !availableTaxAdvantagedBenefitKinds.includes(BENEFIT_KIND_HSA) &&
        medicalHSAPlans.length > 0 &&
        hsaEnabled &&
        taxAdvantageAccEnabled
      ) {
        availableTaxAdvantagedBenefitKinds.push(BENEFIT_KIND_HSA);
      }

      dispatch({
        type: GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNT,
        payload: availableTaxAdvantagedBenefitKinds
      });
    } catch (e) {
      dispatch({
        type: GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ERROR,
        payload: e
      });
    }
  };
};

export const getOEMeetingUsers = () => {
  return async (dispatch) => {
    dispatch({ type: GET_OE_MEETING_USERS_IN_PROGRESS });
    try {
      const response = await MeetingService.getOEMeetingUsers();
      dispatch({
        type: GET_OE_MEETING_USERS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: GET_OE_MEETING_USERS_ERROR, payload: error.response });
    }
  };
};

export const getOeMeeting = (benGuideId) => {
  return async (dispatch) => {
    dispatch({ type: GET_OE_MEETING_IN_PROGRESS });
    try {
      const response = await BenGuideService.getOeMeeting(benGuideId);
      const { data } = response;
      dispatch({
        type: GET_OE_MEETING,
        payload: data
      });
    } catch (error) {
      dispatch({ type: GET_OE_MEETING_ERROR, payload: error });
    }
  };
};

export const saveOeMeeting = (benGuide, oeMeeting) => {
  return async (dispatch) => {
    dispatch({ type: SAVE_OE_MEETING_IN_PROGRESS });
    try {
      const response = await BenGuideService.saveOeMeeting(
        benGuide.id,
        oeMeeting
      );
      const { data } = response;
      dispatch({ type: SAVE_OE_MEETING, payload: data });
    } catch (error) {
      dispatch({ type: SAVE_OE_MEETING_ERROR, payload: error });
    }
  };
};

export const deleteOeMeeting = (benGuide, meetingNumber, type) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_OE_MEETING_IN_PROGRESS });
    try {
      if (type === OE_MEETING_CONFIG.ZOOM.value) {
        const response = await BenGuideService.deleteOeMeeting(
          benGuide.id,
          meetingNumber
        );
        const { data } = response;
        dispatch({ type: DELETE_OE_MEETING, payload: data });
      } else {
        const response = await BenGuideService.deleteCustomOeMeeting(
          benGuide.id
        );
        const { data } = response;
        dispatch({ type: DELETE_OE_MEETING, payload: data });
      }
    } catch (error) {
      dispatch({ type: DELETE_OE_MEETING_ERROR, payload: error });
    }
  };
};

export const clearOEMeeting = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_OE_MEETING });
  };
};

export const uploadOEMeetingRecording = (benGuideId, fileBlob, fileName) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_OE_MEETING_RECORDING_IN_PROGRESS });
      const progressDispatcher = createProgressDispatcher(
        dispatch,
        UPLOAD_OE_MEETING_PROGRESS_NOTIFICATION
      );

      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();
      dispatch({
        type: CREATE_OE_RECORDING_UPLOAD_CANCEL_SOURCE,
        payload: source
      });

      const response = await BenGuideService.uploadOEMeetingRecording(
        benGuideId,
        fileBlob,
        fileName,
        progressDispatcher,
        source
      );
      dispatch({
        type: UPLOAD_OE_MEETING_RECORDING_SUCCESS,
        payload: response.data.reference
      });
    } catch (error) {
      dispatch({ type: UPLOAD_OE_MEETING_RECORDING_FAILED, payload: error });
    }
  };
};

export const cancelOEMeetingRecordingUpload = (source) => {
  return async (dispatch) => {
    source.cancel('cancel.oe.recording.upload');
    dispatch({ type: OE_RECORDING_UPLOAD_CANCEL_SUCCESS });
  };
};

export const cloneBenguide = (benguideId, currentBenguide) => {
  return async (dispatch) => {
    dispatch({ type: CLONE_BEN_GUIDE_IN_PROGRESS });
    try {
      const cloningDBG = await BenGuideService.findLatestById(benguideId);
      if (
        currentBenguide &&
        currentBenguide.cloneDataVO &&
        currentBenguide.id === benguideId
      ) {
        dispatch({ type: CLONE_BEN_GUIDE_SUCCESS, payload: currentBenguide });
      } else {
        const cloneObj = constructBenGuideCloneObj(cloningDBG.data);
        dispatch({ type: CLONE_BEN_GUIDE_SUCCESS, payload: cloneObj });
      }
    } catch (error) {
      dispatch({ type: CLONE_BEN_GUIDE_ERROR, payload: error });
    }
  };
};

const constructBenGuideObj = (benGuideId, employerId) => {
  return {
    id: benGuideId,
    name: '',
    employerId: employerId,
    planYearId: '',
    oePeriod: {
      startDate: undefined,
      endDate: undefined
    },
    medicalPlans: {
      enabled: true,
      plans: []
    },
    dentalPlans: {
      enabled: true,
      plans: []
    },
    visionPlans: {
      enabled: true,
      plans: []
    },
    insurancePlans: {
      enabled: true,
      plans: []
    },
    fourOOneKPlans: {
      enabled: true,
      plans: []
    },
    workLifePlans: {
      enabled: true,
      plans: []
    },
    wellbeingPlans: {
      enabled: true,
      plans: []
    },
    telehealthRxDeliveryPlans: {
      enabled: true,
      plans: []
    },
    additionalPerks: {
      enabled: true,
      plans: []
    },
    taxAdvantagedAccountPlans: {
      enabled: true,
      plans: []
    },
    styleConfiguration: {
      logoReference: undefined,
      backgroundReference: undefined,
      familyImageReference: null,
      timeOfImageReference: null,
      communitiesImageReference: null,
      perksImageReference: null,
      primaryColor: '#',
      secondaryColor: '#',
      homeTextColor: '#',
      mobileHeaderColor: '#'
    },
    perkInfo: {
      enabled: true,
      perks: []
    },
    oeVideo: {
      enabled: true,
      oeVideo: undefined
    },
    oeMeeting: {
      enabled: true,
      oeMeeting: undefined
    },
    whatsNew: {
      enabled: true,
      whatsNew: {
        title: "What's New"
      }
    },
    perksDocs: {
      enabled: true,
      attachements: []
    },
    perkResources: {
      enabled: true,
      attachements: []
    },
    resourceLinks: {
      enabled: true,
      links: []
    },
    perkDocuments: {
      enabled: true,
      documents: []
    },
    resourceDocuments: {
      enabled: true,
      documents: []
    },
    savingsVideos: {
      enabled: true,
      videos: []
    },
    welcomeMessage: undefined,
    revision: 0,
    latest: false,
    password: undefined,
    password2: undefined,
    urlBase: undefined,
    urlHash: undefined,
    urlLabel: undefined,
    secured: {
      enabled: false
    },
    planRecommenderEnabled: false,
    oeDescription: undefined,
    eligibilityRules: {
      enabled: true,
      eligibilityRules: {
        rules: undefined,
        qualifyingLifeEvents: undefined
      }
    },
    eligibilityWaiverCredits: {
      enabled: true,
      eligibilityWaiverCredits: {
        waiverCredits: undefined
      }
    }
  };
};

const constructBenGuideCloneObj = (benGuide) => {
  return {
    id: '',
    name: 'CLONE - ' + benGuide.name,
    employerId: benGuide.employerId,
    planYearId: '',
    oePeriod: {
      startDate: undefined,
      endDate: undefined
    },
    medicalPlans: {
      enabled: benGuide.medicalPlans && benGuide.medicalPlans.enabled,
      plans: []
    },
    dentalPlans: {
      enabled: benGuide.dentalPlans && benGuide.dentalPlans.enabled,
      plans: []
    },
    visionPlans: {
      enabled: benGuide.visionPlans && benGuide.visionPlans.enabled,
      plans: []
    },
    insurancePlans: {
      enabled: benGuide.insurancePlans && benGuide.insurancePlans.enabled,
      plans: []
    },
    fourOOneKPlans: {
      enabled: benGuide.fourOOneKPlans && benGuide.fourOOneKPlans.enabled,
      plans: []
    },
    workLifePlans: {
      enabled: benGuide.workLifePlans && benGuide.workLifePlans.enabled,
      plans: []
    },
    wellbeingPlans: {
      enabled: benGuide.wellbeingPlans && benGuide.wellbeingPlans.enabled,
      plans: []
    },
    telehealthRxDeliveryPlans: {
      enabled:
        benGuide.telehealthRxDeliveryPlans &&
        benGuide.telehealthRxDeliveryPlans.enabled,
      plans: []
    },
    additionalPerks: {
      enabled: benGuide.additionalPerks && benGuide.additionalPerks.enabled,
      plans: []
    },
    taxAdvantagedAccountPlans: {
      enabled:
        benGuide.taxAdvantagedAccountPlans &&
        benGuide.taxAdvantagedAccountPlans.enabled,
      plans: []
    },
    styleConfiguration: {
      logoReference:
        benGuide.styleConfiguration &&
        benGuide.styleConfiguration.logoReference,
      backgroundReference:
        benGuide.styleConfiguration &&
        benGuide.styleConfiguration.backgroundReference,
      familyImageReference:
        benGuide.styleConfiguration &&
        benGuide.styleConfiguration.familyImageReference,
      timeOfImageReference:
        benGuide.styleConfiguration &&
        benGuide.styleConfiguration.timeOfImageReference,
      communitiesImageReference:
        benGuide.styleConfiguration &&
        benGuide.styleConfiguration.communitiesImageReference,
      perksImageReference:
        benGuide.styleConfiguration &&
        benGuide.styleConfiguration.perksImageReference,
      primaryColor:
        benGuide.styleConfiguration && benGuide.styleConfiguration.primaryColor,
      secondaryColor:
        benGuide.styleConfiguration &&
        benGuide.styleConfiguration.secondaryColor,
      homeTextColor:
        benGuide.styleConfiguration &&
        benGuide.styleConfiguration.homeTextColor,
      mobileHeaderColor:
        benGuide.styleConfiguration &&
        benGuide.styleConfiguration.mobileHeaderColor
    },
    perkInfo: {
      enabled: benGuide.perkInfo && benGuide.perkInfo.enabled,
      perks: benGuide.perkInfo && benGuide.perkInfo.perks,
      attachements: benGuide.perkInfo && benGuide.perkInfo.attachments
    },
    oeVideo: {
      enabled: false,
      oeVideo: undefined
    },
    oeMeeting: {
      enabled: false,
      oeMeeting: undefined
    },
    whatsNew: {
      enabled: benGuide.whatsNew && benGuide.whatsNew.enabled,
      whatsNew: {
        title:
          benGuide.whatsNew &&
          benGuide.whatsNew.whatsNew &&
          benGuide.whatsNew.whatsNew.title,
        description:
          benGuide.whatsNew &&
          benGuide.whatsNew.whatsNew &&
          benGuide.whatsNew.whatsNew.description
      }
    },
    perksDocs: {
      enabled: benGuide.perksDocs && benGuide.perksDocs.enabled,
      attachements: benGuide.perksDocs && benGuide.perkDocs.documents
    },
    perkResources: {
      enabled: true,
      attachements: []
    },
    resourceLinks: {
      enabled: benGuide.resourceLinks && benGuide.resourceLinks.enabled,
      links: benGuide.resourceLinks && benGuide.resourceLinks.links
    },
    perkDocuments: {
      enabled: benGuide.perkDocuments && benGuide.perkDocuments.enabled,
      documents: benGuide.perkDocuments && benGuide.perkDocuments.documents
    },
    resourceDocuments: {
      enabled: benGuide.resourceDocuments && benGuide.resourceDocuments.enabled,
      documents:
        benGuide.resourceDocuments && benGuide.resourceDocuments.documents
    },
    savingsVideos: {
      enabled: false,
      videos: []
    },
    welcomeMessage: benGuide.welcomeMessage,
    revision: benGuide.revision,
    latest: benGuide.latest,
    password: undefined,
    password2: undefined,
    urlBase: undefined,
    urlHash: undefined,
    urlLabel: undefined,
    secured: {
      enabled: benGuide.secured && benGuide.secured.enabled
    },
    planRecommenderEnabled: false,
    oeDescription: benGuide.oeDescription,
    eligibilityRules: {
      enabled: benGuide.eligibilityRules && benGuide.eligibilityRules.enabled,
      eligibilityRules: {
        rules:
          benGuide.eligibilityRules &&
          benGuide.eligibilityRules.eligibilityRules &&
          benGuide.eligibilityRules.eligibilityRules.rules,
        qualifyingLifeEvents:
          benGuide.eligibilityRules &&
          benGuide.eligibilityRules.eligibilityRules &&
          benGuide.eligibilityRules.eligibilityRules.qualifyingLifeEvents
      }
    },
    eligibilityWaiverCredits: {
      enabled:
        benGuide.eligibilityWaiverCredits &&
        benGuide.eligibilityWaiverCredits.enabled,
      eligibilityWaiverCredits: {
        waiverCredits:
          benGuide.eligibilityWaiverCredits &&
          benGuide.eligibilityWaiverCredits.eligibilityWaiverCredits &&
          benGuide.eligibilityWaiverCredits.eligibilityWaiverCredits
            .waiverCredits
      }
    }
  };
};

export const resetBenguidePlans = () => (dispatch) => {
  dispatch({
    type: RESET_BEN_GUIDE_PLANS
  });
};
