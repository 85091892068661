/*
 * Copyright (C) 2020 Lumity, Inc. - All Rights Reserved
 *
 * CONFIDENTIAL
 *
 * All information contained herein is, and remains the property of Lumity, Inc. and its partners, if any.
 * The intellectual and technical concepts contained herein are proprietary to Lumity, Inc. and its partners
 * and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless
 * prior written permission is obtained from Lumity, Inc.
 */

export const GET_ALL_MOBILE_VERSIONS_START = 'GET_ALL_MOBILE_VERSIONS_START';
export const GET_ALL_MOBILE_VERSIONS_SUCCESS =
  'GET_ALL_MOBILE_VERSIONS_SUCCESS';
export const GET_ALL_MOBILE_VERSIONS_FAILURE =
  'GET_ALL_MOBILE_VERSIONS_FAILURE';

export const ADD_NEW_MOBILE_VERSION_START = 'ADD_NEW_MOBILE_VERSION_START';
export const ADD_NEW_MOBILE_VERSION_SUCCESS = 'ADD_NEW_MOBILE_VERSION_SUCCESS';
export const ADD_NEW_MOBILE_VERSION_FAILURE = 'ADD_NEW_MOBILE_VERSION_FAILURE';
