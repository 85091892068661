import * as CalendarPages from './index';

const CALENDAR_PATH = '/brokers/:brokerId/employers/:employerId/calendar';

const calendarPathMapping = [
  { path: CALENDAR_PATH, component: CalendarPages.Calendar },
];

export default calendarPathMapping;
export {
  CALENDAR_PATH,
};