export const RequestType = {
  ALL_EMPLOYERS: { value: 'ALL_EMPLOYERS', label: 'All Employers' },
  EMPLOYER: { value: 'EMPLOYER', label: 'Employer' },
  EMPLOYEE: { value: 'EMPLOYEE', label: 'Employee' },
  ELECTION: { value: 'ELECTION', label: 'ID Card' },
  PLAN: { value: 'PLAN', label: 'Plan' },
  FILE_IMPORT: { value: 'FILE_IMPORT', label: 'File Import' }
};

export const ScheduleInterval = {
  ONCE: 'ONCE',
  HOURLY: 'HOURLY',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY'
};

export const SyncStatus = {
  PENDING: { value: 'PENDING', label: 'Pending', color: 'success' },
  SYNCING: { value: 'SYNCING', label: 'Syncing', color: 'success' },
  PARTIAL_SUCCESS: {
    value: 'PARTIAL_SUCCESS',
    label: 'Partial Success',
    color: 'warning'
  },
  SUCCESS: { value: 'SUCCESS', label: 'Success', color: 'success' },
  FAILED: { value: 'FAILED', label: 'Failed', color: 'danger' }
};
