/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import { Tabs, Tab, TabList } from 'react-web-tabs';
import { Row, Col } from 'components/Atoms';

import './TabView.scss';

class TabView extends Component {
  render() {
    const { plans, onTabRender } = this.props;
    return (
      <div className="benchmarks-tab-style">
        <Tabs
          className="tab-view"
          onChange={(planDetails) => {
            onTabRender(planDetails);
          }}
        >
          <TabList className="tab-list">
            <Row>{getTabs(plans)}</Row>
          </TabList>
        </Tabs>
      </div>
    );
  }
}

const getTabs = (plans) => {
  const tabRows = [];
  plans.forEach((plan, index) => {
    if (plan.planId) {
      tabRows.push(
        <Col key={`tab_col_${index}`}>
          <Tab
            className="benchmark-tab"
            key={`tab_col_${index}_${plan.planType}`}
            tabFor={`${index}_${plan.planType}`}
          >
            {plan.planName}
          </Tab>
        </Col>
      );
    }
  });
  return tabRows;
};

export default TabView;
