/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import Loader from 'components/CircularProgress';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import DailySyncSwitch from 'components/DailySyncSwitch';
import { NotificationManager } from 'react-notifications';

import * as ActionTypes from '../actionTypes/integrations.actionTypes';
import {
  startEmployerBenAdminSync,
  getBenAdminIntegrationFeatureConfig,
  createBenAdminIntegrationFeatureConfig,
  updateBenAdminIntegrationFeatureConfig,
  getBenAdminEmployerSyncDetails,
  getLastBenAdminSyncStatus,
  updateBenAdminDailySync,
  getBenAdminSyncErrorFilters,
  getBenAdminSyncErrorList,
  downloadBenAdminSyncErrorReport,
  getBenAdminConfigDetails,
  getBenAdminDailySchedule
} from '../actions/integrationsAction';
import { permitIf } from 'components/hoc/Permit';

import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import PageActionButton from 'components/Buttons/PageActionButton';

import { Col, Row } from 'components/Atoms';

import SyncStatusList from '../components/SyncStatusList';
import ChangeSchedule from '../components/ChangeSchedule';
import DialogErrorDetails from '../components/DialogErrorDetails';

import './EmployerBenAdminIntegrations.scss';

// constants
import { INTEGRATIONS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { VENDOR_IDS, ADAPTER_NAMES } from 'modules/Integrations/constants';
import {
  SYNC_TYPE,
  MODULE_BENADMIN_INTEGRATION_ENABLED,
  BENADMIN_MENU_ACTIONS
} from '../constants';
import { Link } from 'react-router-dom';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Icon from 'components/Icons';

class EmployerIntegrations extends React.Component {
  constructor(props) {
    super(props);
    const {
      match,
      getBenAdminIntegrationFeatureConfig,
      getLastBenAdminSyncStatus
    } = props;
    const { employerId } = match.params;
    this.state = {
      employerId: employerId,
      showErrorDetailsDialog: false,
      selectedSync: null,
      isScheduleModalOpen: false
    };
    this.SyncStatusList = React.createRef();

    getBenAdminIntegrationFeatureConfig(employerId);
    getLastBenAdminSyncStatus(employerId);
  }

  componentWillUnmount() {
    clearInterval(this._refreshTimer);
  }

  componentDidMount() {
    const { employerId } = this.state;
    const { getBenAdminConfigDetails, employerContext } = this.props;
    const { source } = employerContext;
    getBenAdminConfigDetails(source, employerId);
  }

  componentDidUpdate(prevProps) {
    const { requestStatus, serviceError } = this.props;
    const { requestStatus: prevRequestStatus } = prevProps;

    if (prevRequestStatus === requestStatus) return;

    if (serviceError) {
      switch (requestStatus) {
        case ActionTypes.GET_BENADMIN_DAILY_SCHEDULE_ERROR:
        case ActionTypes.GET_BENADMIN_EMPLOYER_SYNC_DETAILS_ERROR:
          return;
        default:
          // Generic Error
          NotificationManager.error(serviceError.message);
          return;
      }
    }

    switch (requestStatus) {
      case ActionTypes.START_EMPLOYER_BENADMIN_SYNC_SUCCESS:
        {
          NotificationManager.success(`Sync started Successfully`);
          this.SyncStatusList.current.reloadList();
        }
        break;
      case ActionTypes.DOWNLOAD_BENADMIN_SYNC_ERROR_REPORT_SUCCESS:
        {
          NotificationManager.success(
            'Sync Error Report has been downloaded successfully'
          );
        }
        break;
      default:
        break;
    }
  }

  switchFeatureConfig = () => {
    const {
      configStatus,
      updateBenAdminIntegrationFeatureConfig,
      createBenAdminIntegrationFeatureConfig
    } = this.props;
    const { employerId } = this.state;
    const { id, value } = configStatus;

    const newValue = value === true || value === 'true' ? 'false' : 'true';
    const updatedConfig = { ...configStatus, value: newValue };

    if (!id) {
      createBenAdminIntegrationFeatureConfig(employerId);
    } else {
      updateBenAdminIntegrationFeatureConfig(employerId, updatedConfig);
    }
    NotificationManager.success(
      `BenAdmin Integration has been ${
        value === 'false' ? 'enabled' : 'disabled'
      }.`
    );
  };

  syncNow = () => {
    const { startEmployerBenAdminSync, employerContext } = this.props;
    const { source } = employerContext;
    const { employerId } = this.state;
    startEmployerBenAdminSync(employerId, { source: source });
  };

  toggleErrorDetailsDialog = () => {
    const { showErrorDetailsDialog } = this.state;
    const data = showErrorDetailsDialog ? null : this.state.selectedSync;
    this.setState({
      showErrorDetailsDialog: !showErrorDetailsDialog,
      selectedSync: data
    });
  };

  handleMenuClick = (action, data) => {
    const { employerId, id: syncDetailsId } = data;
    switch (action) {
      case BENADMIN_MENU_ACTIONS.VIEW_SYNC_DETAILS:
        this.showErrorDetailsDialog(data);
        break;
      case BENADMIN_MENU_ACTIONS.DOWNLOAD_EMPLOYEE_SYNC_DETAILS:
        this.downloadSyncDetailsReport(
          employerId,
          syncDetailsId,
          SYNC_TYPE.EMPLOYEE_SYNC
        );
        break;
      case BENADMIN_MENU_ACTIONS.DOWNLOAD_ENROLLMENT_SYNC_DETAILS:
        this.downloadSyncDetailsReport(
          employerId,
          syncDetailsId,
          SYNC_TYPE.ENROLLMENT_SYNC
        );
        break;
      default:
        break;
    }
  };

  showErrorDetailsDialog = (data) => {
    const { employerId, id: syncDetailsId } = data;
    const { getBenAdminSyncErrorFilters } = this.props;
    let employeeSync = SYNC_TYPE.EMPLOYEE_SYNC;
    let enrollmentSync = SYNC_TYPE.ENROLLMENT_SYNC;
    let employeeSyncConfig = {
      employerId,
      syncDetailsId,
      syncType: employeeSync
    };
    let enrollmentSyncConfig = {
      employerId,
      syncDetailsId,
      syncType: enrollmentSync
    };
    getBenAdminSyncErrorFilters(employeeSyncConfig);
    getBenAdminSyncErrorFilters(enrollmentSyncConfig);
    this.setState({
      showErrorDetailsDialog: true,
      selectedSync: data
    });
  };

  downloadSyncDetailsReport = (employerId, syncDetailsId, syncType) => {
    const { downloadBenAdminSyncErrorReport } = this.props;
    downloadBenAdminSyncErrorReport(employerId, syncDetailsId, syncType);
  };

  downloadFile = (syncDetails) => {
    const { id: syncDetailsId, employerId } = syncDetails;
    this.props.downloadFile(employerId, syncDetailsId);
  };

  toggleChangeScheduleModal = () => {
    const { isScheduleModalOpen, employerId } = this.state;
    const { getBenAdminDailySchedule } = this.props;
    if (!isScheduleModalOpen) {
      getBenAdminDailySchedule(employerId);
    }
    this.setState({
      isScheduleModalOpen: !isScheduleModalOpen
    });
  };

  toggleDailySync = (employerId, enabled) => {
    const { updateBenAdminDailySync } = this.props;
    let config = {
      benAdminEnabled: enabled,
      cronExpression: null
    };
    updateBenAdminDailySync(employerId, config);
  };

  render() {
    const {
      match,
      isLoading,
      requestStatus,
      configStatus,
      employerBenAdminIntegrations,
      dailyBenAdminSyncStatus,
      syncBenAdminEmployeeErrors,
      syncBenAdminEnrollmentErrors,
      benadminSyncEmployeeFilters,
      benadminSyncEnrollmentFilters,
      getBenAdminEmployerSyncDetails,
      getBenAdminSyncErrorList,
      benAdminConfigDetails,
      organization,
      employerContext
    } = this.props;
    const { source } = employerContext;
    const { params } = match;
    const organizationId = organization ? organization.id : params.brokerId;

    const { data } = benAdminConfigDetails;

    const {
      isScheduleModalOpen,
      employerId,
      showErrorDetailsDialog,
      selectedSync
    } = this.state;
    if (
      (isLoading &&
        requestStatus === ActionTypes.GET_EMPLOYER_INTEGRATION_DETAILS) ||
      benAdminConfigDetails.inProgress
    ) {
      return <Loader />;
    }

    const enabled =
      configStatus.value === 'true' || configStatus.value === true;

    let isDailySyncLoading =
      isLoading && requestStatus === ActionTypes.TOGGLE_DAILY_SYNC;

    const {
      enabled: dailySyncEnabled,
      lastScheduleSyncDetail
    } = dailyBenAdminSyncStatus;

    const SecuredEmployerConfigToggle = permitIf(
      EmployerConfigToggleV2,
      INTEGRATIONS_FEATURE_ROLE_MAPPING.toggleFeature
    );

    const SecuredSyncNowButton = permitIf(
      PageActionButton,
      INTEGRATIONS_FEATURE_ROLE_MAPPING.syncNow
    );

    const SecuredScheduleButton = permitIf(
      PageActionButton,
      INTEGRATIONS_FEATURE_ROLE_MAPPING.dailySync
    );

    const SecuredDailySyncToggle = permitIf(
      DailySyncSwitch,
      INTEGRATIONS_FEATURE_ROLE_MAPPING.dailySync
    );

    return (
      <ApplicationWrapper>
        <HeaderContainer title="BenAdmin Integrations" />
        {!(data && data.terminationRule) ? (
          <Row className="configure-flock-info-container">
            <Icon icon={faInfoCircle} className="mr-2" />
            {`Please Configure ${ADAPTER_NAMES[source].name} from here ;`}
            <Link
              to={`/brokers/${organizationId}/employers/${employerId}/employees`}
              className="configure-flock-link"
            >
              {`Employees -> Configure ${ADAPTER_NAMES[source].name}`}
            </Link>
          </Row>
        ) : (
          <>
            <Row className="page-employer-integration">
              <Col className="page-content">
                <ContentContainer>
                  <SecuredEmployerConfigToggle
                    employerId={employerId}
                    moduleEnabled={MODULE_BENADMIN_INTEGRATION_ENABLED}
                    message="BenAdmin"
                    config={configStatus}
                    switchCallBack={this.switchFeatureConfig}
                  />
                </ContentContainer>
                <ContentContainer>
                  <SyncStatusList
                    ref={this.SyncStatusList}
                    data={employerBenAdminIntegrations}
                    employerId={employerId}
                    getBenAdminEmployerSyncDetails={
                      getBenAdminEmployerSyncDetails
                    }
                    menuClick={this.handleMenuClick}
                  />
                </ContentContainer>
              </Col>
              <Col className="page-actions">
                <h5 className="panel-title">Employer Sync</h5>
                <SecuredSyncNowButton
                  onClick={this.syncNow}
                  disabled={!enabled}
                >
                  Sync Now
                </SecuredSyncNowButton>
                {enabled && (
                  <div>
                    <SecuredDailySyncToggle
                      message={`Are you sure you want to ${
                        dailySyncEnabled ? 'disable' : 'enable'
                      } daily syncing?`}
                      enabled={dailySyncEnabled}
                      syncStatus={lastScheduleSyncDetail}
                      inProgress={isDailySyncLoading}
                      toggleEnabled={() => {
                        this.toggleDailySync(employerId, !dailySyncEnabled);
                      }}
                    />
                  </div>
                )}
                <>
                  <div className="hris">
                    <h5 className="panel-title"></h5>
                    <SecuredScheduleButton
                      onClick={this.toggleChangeScheduleModal}
                      type="withCustomIcon"
                      disabled={!enabled}
                      icon={'clock'}
                      outline
                    >
                      Change Schedule
                    </SecuredScheduleButton>
                  </div>
                </>
              </Col>
            </Row>
            <ChangeSchedule
              isOpen={isScheduleModalOpen}
              toggle={this.toggleChangeScheduleModal}
              employerId={employerId}
              isConfiguredBenAdmin={enabled}
            />
            <DialogErrorDetails
              isOpen={showErrorDetailsDialog}
              toggle={this.toggleErrorDetailsDialog}
              syncBenAdminEmployeeErrors={syncBenAdminEmployeeErrors}
              syncBenAdminEnrollmentErrors={syncBenAdminEnrollmentErrors}
              employerId={employerId}
              selectedSync={selectedSync}
              getErrorList={getBenAdminSyncErrorList}
              syncEmployeeDetailsFilters={benadminSyncEmployeeFilters}
              syncEnrollmentDetailsFilters={benadminSyncEnrollmentFilters}
              downloadErrorReport={this.downloadSyncDetailsReport}
            />
          </>
        )}
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startEmployerBenAdminSync: (employerId, config) =>
    dispatch(startEmployerBenAdminSync(employerId, config)),
  getBenAdminIntegrationFeatureConfig: (employerId) =>
    dispatch(getBenAdminIntegrationFeatureConfig(employerId)),
  createBenAdminIntegrationFeatureConfig: (employerId) =>
    dispatch(createBenAdminIntegrationFeatureConfig(employerId)),
  updateBenAdminIntegrationFeatureConfig: (employerId, config) =>
    dispatch(updateBenAdminIntegrationFeatureConfig(employerId, config)),
  getLastBenAdminSyncStatus: (employerId) =>
    dispatch(getLastBenAdminSyncStatus(employerId)),
  getBenAdminEmployerSyncDetails: (config) =>
    dispatch(getBenAdminEmployerSyncDetails(config)),
  updateBenAdminDailySync: (employerId, config) =>
    dispatch(updateBenAdminDailySync(employerId, config)),
  getBenAdminSyncErrorFilters: (config) =>
    dispatch(getBenAdminSyncErrorFilters(config)),
  getBenAdminSyncErrorList: (config) =>
    dispatch(getBenAdminSyncErrorList(config)),
  getBenAdminConfigDetails: (benAdminType, employerId) =>
    dispatch(getBenAdminConfigDetails(benAdminType, employerId)),
  getBenAdminDailySchedule: (employerId) =>
    dispatch(getBenAdminDailySchedule(employerId)),
  downloadBenAdminSyncErrorReport: (employerId, syncDetailsId, syncType) =>
    dispatch(
      downloadBenAdminSyncErrorReport(employerId, syncDetailsId, syncType)
    )
});

const mapStateToProps = (state) => {
  const {
    requestStatus,
    serviceError,
    isLoading,
    configStatus,
    employerBenAdminIntegrations,
    dailyBenAdminSyncStatus,
    syncBenAdminEmployeeErrors,
    syncBenAdminEnrollmentErrors,
    integrationConfigDetails,
    benadminSyncEmployeeFilters,
    benadminSyncEnrollmentFilters,
    fileDownloadError,
    employerBenAdminConfigs,
    benAdminConfigDetails
  } = state.benAdminIntegrationsReducer;
  const { employerContext } = state.AppBase.contextReducer;
  const organization = state.AppBase.contextReducer.organizationContext;
  return {
    requestStatus,
    serviceError,
    isLoading,
    configStatus,
    employerBenAdminIntegrations,
    dailyBenAdminSyncStatus,
    syncBenAdminEmployeeErrors,
    syncBenAdminEnrollmentErrors,
    integrationConfigDetails,
    employerContext,
    benadminSyncEmployeeFilters,
    benadminSyncEnrollmentFilters,
    fileDownloadError,
    employerBenAdminConfigs,
    benAdminConfigDetails,
    organization
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerIntegrations);
