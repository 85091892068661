/*
 * Copyright (C) 2019 Lumity, Inc. - All Rights Reserved
 *
 * CONFIDENTIAL
 *
 * All information contained herein is, and remains the property of Lumity, Inc. and its partners, if any.
 * The intellectual and technical concepts contained herein are proprietary to Lumity, Inc. and its partners
 * and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless
 * prior written permission is obtained from Lumity, Inc.
 */

export const GET_ALL_CALENDAR_TEMPLATES_START =
  'GET_ALL_CALENDAR_TEMPLATES_START';
export const GET_ALL_CALENDAR_TEMPLATES_SUCCESS =
  'GET_ALL_CALENDAR_TEMPLATES_SUCCESS';
export const GET_ALL_CALENDAR_TEMPLATES_ERROR =
  'GET_ALL_CALENDAR_TEMPLATES_ERROR';

export const SAVE_CALENDAR_TEMPLATE_START = 'SAVE_CALENDAR_TEMPLATE_START';
export const SAVE_CALENDAR_TEMPLATE_SUCCESS = 'SAVE_CALENDAR_TEMPLATE_SUCCESS';
export const SAVE_CALENDAR_TEMPLATE_ERROR = 'SAVE_CALENDAR_TEMPLATE_ERROR';
