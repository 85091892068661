/**********************************************************************************************************************
 * Copyright (C) 2019-2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import Notifications from 'components/Notifications';
import { permitIf } from 'components/hoc/Permit';
import { ISSUES_LOG_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';

import './IssueLog.scss';

import {
  findConfigModules,
  createConfigModule,
  updateConfigModule,
  getEmployer,
  switchOffBothAccountAndAnalytics
} from '../../actions/issueFileAction';

import {
  MODULE_ISSUES_LOG_ACCOUNT_LEVEL,
  MODULE_ISSUES_LOG_EMPLOYEE_LEVEL
} from '../../constants';

class IssueLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      salesforceAccountId: '',
      saveDisabled: false,
      initialSalesforceAccountId: ''
    };
  }

  componentDidMount() {
    this.getIntegrationConfig();
    this.getIssueLogConfiguration();
    this.enableSaveButton();
  }

  resetComponent() {
    const { employerRefreshed = {} } = this.props;
    const { integrationConfig = {} } = employerRefreshed;
    const { salesforceAccountId = "" } = integrationConfig;
    this.setState({
      ...this.state, 
      salesforceAccountId: salesforceAccountId,
      saveDisabled: false,
      initialSalesforceAccountId: salesforceAccountId
    });
  }

  enableSaveButton() {
    this.setState({ saveDisabled: false });
  }

  disableSaveButton(){
    this.setState({ saveDisabled: true });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      employerConfigCreate,
      employerConfigCreateError,
      employerConfigUpdate,
      employerConfigUpdateError,
      employer,
      employerRefreshed,
    } = this.props;
    if (employerConfigCreate !== prevProps.employerConfigCreate) {
      if (employerConfigCreate) {
        this.getIssueLogConfiguration();
        this.showSuccessNotification('Issues log configurations have been successfully updated.');
      } else {
        this.showNotification(employerConfigCreateError);
      }
    }

    if (employerConfigUpdate !== prevProps.employerConfigUpdate) {
      if (employerConfigUpdate) {
        this.getIssueLogConfiguration();
        this.showSuccessNotification('Issues log configurations have been successfully updated.');
      } else {
        this.showNotification(employerConfigUpdateError);
      }
    }

    if (employer !== prevProps.employer) {
      this.updateIntegrationConfig();
      this.getIssueLogConfiguration();
    }

    if (employerRefreshed !== prevProps.employerRefreshed) {
      this.resetComponent();
    }
  }

  getIntegrationConfig = () => {
    const { employer } = this.props;
    const { id: employerId } = employer;
    this.props.getEmployer(employerId);
  };

  getIssueLogConfiguration = () => {
    const { employer } = this.props;
    const { id: employerId } = employer;
    this.props.findConfigModules(employerId);
  };

  updateIntegrationConfig = () => {
    const { salesforceAccountId } = this.state;
    this.setState({
      initialSalesforceAccountId: salesforceAccountId,
    });
  };

  switchAccountLevelIssueEnabled = () => {
    const { accountLevelIssueConfig, employeeLevelIssueConfig } = this.props;
    const { id, value } = accountLevelIssueConfig;
    const { employer } = this.props;
    const { id: employerId } = employer;

    if (accountLevelIssueConfig.id === null) {
      this.props.createConfigModule(
        MODULE_ISSUES_LOG_ACCOUNT_LEVEL,
        employerId,
        value
      );
    } else {
      if (value === 'true') {
        if (employeeLevelIssueConfig.value === 'true') {
          const configArray = [
            { id: id, key: MODULE_ISSUES_LOG_ACCOUNT_LEVEL },
            {
              id: employeeLevelIssueConfig.id,
              key: MODULE_ISSUES_LOG_EMPLOYEE_LEVEL
            }
          ];
          this.props.switchOffBothAccountAndAnalytics(
            id,
            employeeLevelIssueConfig.id,
            MODULE_ISSUES_LOG_ACCOUNT_LEVEL,
            MODULE_ISSUES_LOG_EMPLOYEE_LEVEL,
            employerId
          );
        } else {
          this.props.updateConfigModule(
            id,
            MODULE_ISSUES_LOG_ACCOUNT_LEVEL,
            employerId,
            value
          );
        }
      }
      if (value === 'false') {
        this.props.updateConfigModule(
          id,
          MODULE_ISSUES_LOG_ACCOUNT_LEVEL,
          employerId,
          value
        );
      }
    }
  };

  switchEmployeeLevelIssueEnabled = () => {
    const { employeeLevelIssueConfig } = this.props;
    const { id, value } = employeeLevelIssueConfig;
    const { employer } = this.props;
    const { id: employerId } = employer;

    if (employeeLevelIssueConfig.id === null) {
      this.props.createConfigModule(
        MODULE_ISSUES_LOG_EMPLOYEE_LEVEL,
        employerId,
        value
      );
    } else {
      this.props.updateConfigModule(
        id,
        MODULE_ISSUES_LOG_EMPLOYEE_LEVEL,
        employerId,
        value
      );
    }
  };

  showSuccessNotification = (message) => {
    Notifications.success(message);
  };


  showErrorNotification = (message) => {
    Notifications.error(message);
  };

  render() {
    const { accountLevelIssueConfig, employeeLevelIssueConfig } = this.props;
    let isAccLvlIssuesEnabled =
      accountLevelIssueConfig && accountLevelIssueConfig.value === 'true';
    const SecuredEmployerConfigToggle = permitIf(
      EmployerConfigToggleV2,
      ISSUES_LOG_FEATURE_ROLE_MAPPING.common
    );

    return (
      <div className="issue-log">
        <ApplicationWrapper>
          <HeaderContainer title="Issues Log" />
          <ContentContainer>
            <h3>
              <b>Issues Log Access</b>
            </h3>
            <div className="config-rows">
              <hr className="divider" />
              <SecuredEmployerConfigToggle
                message="Account Level Issues "
                config={accountLevelIssueConfig}
                switchCallBack={this.switchAccountLevelIssueEnabled}
              />
              <hr className="divider" />
              <SecuredEmployerConfigToggle
                message="Issues Analytics"
                config={employeeLevelIssueConfig}
                switchCallBack={this.switchEmployeeLevelIssueEnabled}
                disabled={!isAccLvlIssuesEnabled}
              />
            </div>
          </ContentContainer>
        </ApplicationWrapper>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    findConfigModules: (employerId) => dispatch(findConfigModules(employerId)),
    createConfigModule: (configKey, employerId, enableValue) =>
      dispatch(createConfigModule(configKey, employerId, enableValue)),
    updateConfigModule: (configId, configKey, employerId, enableValue) =>
      dispatch(
        updateConfigModule(configId, configKey, employerId, enableValue)
      ),
    switchOffBothAccountAndAnalytics: (
      accLevelIssueConfigId,
      employeeLevelIssueConfigId,
      accLevelKey,
      employeeLevelKey,
      employerId
    ) =>
      dispatch(
        switchOffBothAccountAndAnalytics(
          accLevelIssueConfigId,
          employeeLevelIssueConfigId,
          accLevelKey,
          employeeLevelKey,
          employerId
        )
      ),
    getEmployer: (employerId) => dispatch(getEmployer(employerId))
  };
};

const mapStateToProps = (state) => {
  const { employerContext: employer } = state.AppBase.contextReducer;
  const {
    employerConfigs,
    employerConfigsErrorResponse,
    employerConfigCreate,
    employerConfigCreateError,
    employerConfigUpdate,
    employerConfigUpdateError,
    accountLevelIssueConfig,
    employeeLevelIssueConfig,
    employerRefreshed
  } = state.issueLogReducer;
  return {
    employerConfigs,
    employerConfigsErrorResponse,
    employerConfigCreate,
    employerConfigCreateError,
    employerConfigUpdate,
    employerConfigUpdateError,
    employer,
    accountLevelIssueConfig,
    employeeLevelIssueConfig,
    employerRefreshed
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueLog);
