/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
export const EmployerType = {
  CUSTOMER: { value: 'CUSTOMER', label: 'Customer' },
  PROSPECT: { value: 'PROSPECT', label: 'Prospect' }
};

export const EmployerTypesList = [
  { value: 'CUSTOMER', label: 'Customer' },
  { value: 'PROSPECT', label: 'Prospect' },
  { value: 'ARCHIVED', label: 'Archived' }
];

export const EmployerSourceType = {
  PLATFORM: { value: 'PLATFORM', label: 'Platform' },
  FLOCK: { value: 'FLOCK', label: 'Flock' },
  WORKDAY_BENADMIN: { value: 'WORKDAY_BENADMIN', label: 'Workday' }
};

export const AllEmployerSources = {
  PLATFORM: { value: 'PLATFORM', label: 'Platform' },
  FLOCK: { value: 'FLOCK', label: 'Flock' },
  LUMITY: { value: 'LUMITY', label: 'Lumity' },
  WORKDAY_BENADMIN: { value: 'WORKDAY_BENADMIN', label: 'Workday' }
};

export const EmployerPlatformSource = {
  PLATFORM: { value: 'PLATFORM', label: 'Platform' }
};

export const CustomerType = {
  CUSTOMER: { value: 'CUSTOMER', label: 'Customer' }
};

export const EmployerDocumentType = {
  EMPLOYEE_HANDBOOK: { value: 'EMPLOYEE_HANDBOOK', label: 'Employee Handbook' },
  LEGAL_NOTICES: { value: 'LEGAL_NOTICES', label: 'Legal Notices' },
  CARRIER_CONTACT_SHEET: {
    value: 'CARRIER_CONTACT_SHEET',
    label: 'Carrier Contacts'
  }
};

export const SERVICE_NOTIFICATIONS_ENABLED = 'SERVICE_NOTIFICATIONS_ENABLED';

/** Allowed character list for company alias */
export const VALID_ALIAS_CHARACTERS = [
  ' ',
  '!',
  '"',
  '#',
  '$',
  '%',
  '&',
  "'",
  '(',
  ')',
  '*',
  '+',
  ',',
  '-',
  '.',
  '/',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  ':',
  ';',
  '<',
  '=',
  '>',
  '?',
  '@',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '[',
  ']',
  '\\',
  '^',
  '_',
  '`',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '{',
  '}',
  '~',
  '€',
  '‚',
  'ƒ',
  '„',
  '…',
  '‰',
  'Š',
  '‹',
  'Œ',
  'Ž',
  '‘',
  '’',
  '“',
  '”',
  '–',
  '—',
  '˜',
  'š',
  '›',
  'œ',
  'ž',
  'Ÿ',
  '¡',
  '¢',
  '£',
  '¤',
  '¥',
  '§',
  '¨',
  'ª',
  '«',
  '°',
  '±',
  '´',
  'µ',
  '·',
  '¸',
  'º',
  '»',
  '¿',
  'À',
  'Á',
  'Â',
  'Ã',
  'Ä',
  'Å',
  'Æ',
  'Ç',
  'È',
  'É',
  'Ê',
  'Ë',
  'Ì',
  'Í',
  'Î',
  'Ï',
  'Ð',
  'Ñ',
  'Ò',
  'Ó',
  'Ô',
  'Õ',
  'Ö',
  '×',
  'Ø',
  'Ù',
  'Ú',
  'Û',
  'Ü',
  'Ý',
  'Þ',
  'ß',
  'à',
  'á',
  'â',
  'ã',
  'ä',
  'å',
  'æ',
  'ç',
  'è',
  'é',
  'ê',
  'ë',
  'ì',
  'í',
  'î',
  'ï',
  'ð',
  'ñ',
  'ò',
  'ó',
  'ô',
  'õ',
  'ö',
  '÷',
  'ø',
  'ù',
  'ú',
  'û',
  'ü',
  'ý',
  'þ',
  'ÿ',
  'Ā',
  'ā',
  'Ă',
  'ă',
  'Ą',
  'ą',
  'Ć',
  'ć',
  'Č',
  'č',
  'Ď',
  'Đ',
  'đ',
  'Ē',
  'ē',
  'Ė',
  'ė',
  'Ę',
  'ę',
  'Ě',
  'ě',
  'Ğ',
  'ğ',
  'Ģ',
  'ģ',
  'Ī',
  'ī',
  'Į',
  'į',
  'İ',
  'ı',
  'Ķ',
  'ķ',
  'Ĺ',
  'ĺ',
  'Ļ',
  'ļ',
  'Ľ',
  'ľ',
  'Ł',
  'ł',
  'Ń',
  'ń',
  'Ņ',
  'ņ',
  'Ň',
  'ň',
  'Ō',
  'ō',
  'Ő',
  'ő',
  'Ŗ',
  'ŗ',
  'Ř',
  'ř',
  'Ś',
  'ś',
  'Ş',
  'ş',
  'Š',
  'š',
  'Ţ',
  'ţ',
  'Ť',
  'ť',
  'Ū',
  'ū',
  'Ů',
  'ů',
  'Ű',
  'ű',
  'Ų',
  'ų',
  'Ź',
  'ź',
  'Ż',
  'ż',
  'Ž',
  'ž'
];

export const AllowedIntegrationType = {
  HRIS: 'HRIS',
  DATA_PULLING: 'DATA_PULLING'
};

export const EmployerFilterType = {
  LOCATION: { value: 'LOCATION', label: 'Location' },
  TYPE: { value: 'TYPE', label: 'Employer Type' }
};

export const DUPLICATE_EMPLOYERNAME = 'employer.already.exists';

export const NumberValidate = '^1-\\d{3}-\\d{3}-\\d{4}$';

export const ALIAS_ERROR_CODE = 'An alias is already taken';

export const PlanYearErrorCodes = {
  DUPLICATE_NAME: {
    code: 'validation.duplicate.exists',
    message: 'A plan year with this name already exists.'
  },
  DELETE_WITH_DEPENDENCIES: {
    code: 'plan.year.delete.error.has.dependencies',
    message: 'Cannot delete plan year due to other dependencies.'
  },
  HAS_DOCUMENTS: {
    code: 'plan.year.delete.warning.has.documents',
    message: 'This plan year has documents that will be deleted if removed.'
  }
};

export const INVALID_BENGROUP_CHARACTERS = [
  '/',
  ':',
  '*',
  '?',
  '"',
  '<',
  '>',
  '|',
  '\\'
];