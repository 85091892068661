import React, { Component } from 'react';
import './CustomTooltip.scss';

export default class CustomTooltip extends Component {
  componentDidMount() {
    let plansList = this.props.value.data;
    let height = plansList.length * 20;
    height = height + 'px';
    this.props.reactContainer.className = 'bundle-custom-tooltip';
    this.props.reactContainer.style.height = height;
  }

  render() {
    let plansList = this.props.value.data;
    if (plansList.length > 0) {
      return (
        <div className="bundle-tooltip-content">
          {plansList.map((plan) => (
            <p>
              <span className="bundle-plans-tooltip-text">{plan}</span>
              <br />
            </p>
          ))}
        </div>
      );
    } else {
      return null;
    }
  }
}
