/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const FEATURE_KEY_MOBILE = 'MODULE_MOBILE_ENABLED';
export const FEATURE_KEY_CHATBOT = 'MODULE_CHATBOT_ENABLED';

export const FEATURE_KEY_DBG_CHATBOT = 'MODULE_DBG_CHATBOT_ENABLED';
export const FEATURE_KEY_SLACK_CHATBOT = 'MODULE_SLACK_CHATBOT_ENABLED';

export const FEATURE_KEY_BENEFIT_CONSULTATION = 'SERVICE_BENEFITS_CONSULTATION_ENABLED';

export const CREATE_STATUS = 200;
export const UPDATE_STATUS = 204;

export const CUSTOMER_SUPPORT = 'customerSupportCompatible';
export const LUMITY_CONSULT = 'lumityConsultCompatible';

export const UPDATE_CHATBOT_TYPE_MOBILE = 'MOBILE';
export const UPDATE_CHATBOT_TYPE_DIGITAL_BENGUIDE = 'DIGITAL_BEN_GUIDE';
export const UPDATE_CHATBOT_TYPE_SLACK = 'SLACK';