/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const SAVE_MEDICAL_CURRENT_PLANS = 'SAVE_MEDICAL_CURRENT_PLANS';
export const SAVE_MEDICAL_CURRENT_PLANS_ERROR =
  'SAVE_MEDICAL_CURRENT_PLANS_ERROR';

export const FIND_BENCHMARK_BY_ID_IN_PROGRESS =
  'FIND_BENCHMARK_BY_ID_IN_PROGRESS';
export const FIND_BENCHMARK_BY_ID = 'FIND_BENCHMARK';
export const FIND_BENCHMARK_BY_ID_ERROR = 'FIND_BENCHMARK_ERROR';

export const FIND_PUBLISHED_BENCHMARK_BY_ID = 'FIND_PUBLISHED_BENCHMARK';
export const FIND_PUBLISHED_BENCHMARK_BY_ID_ERROR =
  'FIND_PUBLISHED_BENCHMARK_ERROR';

export const FIND_BENCHMARK_PLAN_IN_PROGRESS =
         'FIND_BENCHMARK_PLAN_IN_PROGRESS';
export const FIND_BENCHMARK_PLAN = 'FIND_BENCHMARK_PLAN';
export const FIND_BENCHMARK_PLAN_ERROR = 'FIND_BENCHMARK_PLAN_ERROR';

export const FIND_CENSUS_BY_ID = 'FIND_CENSUS';
export const FIND_CENSUS_BY_ID_ERROR = 'FIND_CENSUS_ERROR';

export const GET_BENCHMARK_METADATA = 'GET_BENCHMARK_METADATA';
export const GET_BENCHMARK_METADATA_ERROR = 'GET_BENCHMARK_METADATA_ERROR';

export const SAVE_BENCHMARK_BY_ID_IN_PROGRESS =
  'SAVE_BENCHMARK_BY_ID_IN_PROGRESS';
export const SAVE_BENCHMARK_BY_ID = 'SAVE_BENCHMARK_BY_ID';
export const SAVE_BENCHMARK_BY_ID_ERROR = 'SAVE_BENCHMARK_BY_ID_ERROR';

export const CREATE_BENCHMARK_BY_ID_IN_PROGRESS =
  'CREATE_BENCHMARK_BY_ID_IN_PROGRESS';
export const CREATE_BENCHMARK_BY_ID = 'CREATE_BENCHMARK_BY_ID';
export const CREATE_BENCHMARK_BY_ID_ERROR = 'CREATE_BENCHMARK_BY_ID_ERROR';

export const GET_BENCHMARKS_PAGE_IN_PROGRESS =
  'GET_BENCHMARKS_PAGE_IN_PROGRESS';
export const GET_BENCHMARKS_PAGE_COMPLETED = 'GET_BENCHMARKS_PAGE_COMPLETED';
export const GET_BENCHMARKS_PAGE_FAILED = 'GET_BENCHMARKS_PAGE_FAILED';

export const FIND_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES =
  'FIND_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES';
export const FIND_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES_ERROR =
  'FIND_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES_ERROR';
export const CREATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES =
  'CREATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES';
export const CREATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES_ERROR =
  'CREATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES_ERROR';
export const UPDATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES =
  'UPDATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES';
export const UPDATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES_ERROR =
  'UPDATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES_ERROR';

export const CLONE_BENCHMARK = 'CLONE_BENCHMARK';
export const CLONE_BENCHMARK_ERROR = 'CLONE_BENCHMARK_ERROR';

export const VALIDATE_BENCHMARK_DATA = 'VALIDATE_BENCHMARK_DATA';

export const GET_BENCHMARKS_OVERALL_RATES_IN_PROGRESS =
  'GET_BENCHMARKS_OVERALL_RATES_IN_PROGRESS';
export const GET_BENCHMARKS_OVERALL_RATES_SUCCES =
  'GET_BENCHMARKS_OVERALL_RATES_SUCCES';
export const GET_BENCHMARKS_OVERALL_RATES_ERROR =
  'GET_BENCHMARKS_OVERALL_RATES_ERROR';
