/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import { Row, Col, Label } from 'components/Atoms';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import FullscreenModal from 'components/FullscreenModal';
import './ClusterEmployers.scss';

class ClusterEmployers extends Component {
  columnData = [
    {
      name: 'Employer',
      selector: 'username',
      sortable: true
    },
    {
      name: 'Size',
      selector: 'firstName',
      sortable: true
    },
    {
      name: 'Region HQ',
      selector: 'lastName',
      sortable: true
    }
  ];
  render() {
    const { isOpen, toggle } = this.props;
    //TODO: Integrate with backend
    return (
      <FullscreenModal closeText="cancel" isOpen={isOpen} toggle={toggle}>
        <HeaderContainer title="Similar employers"></HeaderContainer>
        <Row className="row-plan-design-benchmark plan-design-container-benchmark">
          <Col>
            <Row className="similar-employers-table-header">
              <Col>
                <Label>Employer</Label>
              </Col>

              <Col>
                <Label>Size</Label>
              </Col>
              <Col>
                <Label>Region (HQ)</Label>
              </Col>
            </Row>
            <Row className="similar-employers-table-line-mark">
              <Col>
                <Label>Booster Fuels</Label>
              </Col>

              <Col>
                <Label>250+</Label>
              </Col>
              <Col>
                <Label>Bay Area</Label>
              </Col>
            </Row>
            <Row className="similar-employers-table-line-mark">
              <Col>
                <Label>Go Fund Me</Label>
              </Col>

              <Col>
                <Label>250+</Label>
              </Col>
              <Col>
                <Label>Bay Area</Label>
              </Col>
            </Row>
            <Row className="similar-employers-table-line-mark">
              <Col>
                <Label>Enjoy</Label>
              </Col>

              <Col>
                <Label>250+</Label>
              </Col>
              <Col>
                <Label>Bay Area</Label>
              </Col>
            </Row>
            <Row className="similar-employers-table-line-mark">
              <Col>
                <Label>Robin Hood</Label>
              </Col>

              <Col>
                <Label>250+</Label>
              </Col>
              <Col>
                <Label>Bay Area</Label>
              </Col>
            </Row>
          </Col>
        </Row>
      </FullscreenModal>
    );
  }
}
export default ClusterEmployers;
