/*
 * Copyright (C) 2020 Lumity, Inc. - All Rights Reserved
 *
 * CONFIDENTIAL
 *
 * All information contained herein is, and remains the property of Lumity, Inc. and its partners, if any.
 * The intellectual and technical concepts contained herein are proprietary to Lumity, Inc. and its partners
 * and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless
 * prior written permission is obtained from Lumity, Inc.
 */
import React, { Component, createRef } from 'react';
import SwitchWithConfirmation from '../Buttons/SwitchWithConfirmation';
import {
  CardHeader,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';
import { Col, Row } from 'components/Atoms';
import AddButton from '../Buttons/AddButton';

import './ToggleEnabledUploader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import DataTableWithClientPagination from '../DataTables/DataTable/DataTableWithClientPagination';
import { INSIGHTS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { permitIf } from 'components/hoc/Permit';
import Loader from '../CircularProgress/Loader';
/**
 * This component includes upload button that enables/disable on the toggle button.
 */
const TABLE_BACKGROUND_COLOR = '#F7F8FA';
class ToggleEnabledUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapseOpen: false,
      tableData: [],
      dataFetching: false
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      fileHistoryLoading,
      insightsFileHistory,
      fileHistoryChartType,
      chartType,
      getFileHistory
    } = this.props;
    const { isCollapseOpen } = this.state;
    if (
      !fileHistoryLoading &&
      fileHistoryChartType &&
      prevProps.insightsFileHistory !== insightsFileHistory &&
      fileHistoryChartType === chartType
    ) {
      this.setState({
        tableData: insightsFileHistory,
        dataFetching: false
      });
    }
    if (
      fileHistoryLoading &&
      fileHistoryChartType &&
      prevProps.fileHistoryLoading !== fileHistoryLoading &&
      fileHistoryChartType === chartType
    ) {
      this.setState({
        dataFetching: true
      });
    }
    if (isCollapseOpen && prevState.isCollapseOpen !== isCollapseOpen) {
      getFileHistory(chartType);
    }
  }

  selectFile = (event) => {
    const { employerId, uploadFile, chartType } = this.props;
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      this.setState({ selectedFileName: fileName });
      const fileBlob = new Blob([selectedFile], { type: selectedFile.type });
      let data = {
        file: fileBlob,
        fileName: fileName,
        employerId: employerId,
        documentType: chartType
      };
      uploadFile(data);
    }
  };
  toggleCollapse = () => {
    this.setState((prevState) => ({
      isCollapseOpen: !prevState.isCollapseOpen
    }));
  };

  getFileActions = (fileId) => {
    const { downloadHistoryFile, employerId } = this.props;
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="cursor-pointer font-weight-bold"
          caret
        >
          Select
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => downloadHistoryFile(employerId, fileId)}>
            Download
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };
  columnData = [
    {
      name: 'File Name',
      selector: 'fileName',
      cell: (row) => <div className="list-item-bold">{row.fileName}</div>
    },
    {
      name: 'Date Uploaded',
      selector: 'lastUpdatedTs',
      cell: (row) => moment(row.lastUpdatedTs).format('MM/DD/YYYY')
    },
    {
      name: 'Last Uploaded By',
      selector: 'lastUpdatedByLoginId',
      cell: (row) => <div>{row.lastUpdatedByLoginId}</div>
    },
    {
      name: 'Actions',
      cell: (row) => this.getFileActions(row.id),
      ignoreRowClick: true,
      allowOverflow: true,
      width: '100px'
    }
  ];
  render() {
    const { isCollapseOpen, tableData, dataFetching } = this.state;
    const {
      toggleId,
      uploadId,
      title,
      status,
      chartType,
      confirmCallback,
      fileUploading,
      downloadFile,
      employerId,
      files
    } = this.props;
    const SecuredSwitchWithConfirmation = permitIf(
      SwitchWithConfirmation,
      INSIGHTS_FEATURE_ROLE_MAPPING.common
    );
    return (
      <CardHeader
        className={`${
          isCollapseOpen
            ? 'chart-upload-content_open'
            : 'chart-upload-content_closed'
        } toggle-enabled-uploader-header`}
      >
        <Row className="toggle-enabled-uploader">
          <Col className="mt-2 mb-5 text-center d-flex" xs={1}>
            <FontAwesomeIcon
              className="text-center file-history-toggle"
              icon={isCollapseOpen ? 'chevron-down' : 'chevron-up'}
              size="lg"
              onClick={this.toggleCollapse}
            />
            <SecuredSwitchWithConfirmation
              id={toggleId !== undefined ? toggleId : 'toggleId'}
              className="mr-2 ml-4 "
              value={status}
              message={`Are you sure you'd like to ${
                status ? 'disable' : 'enable'
              } the ${title}?`}
              confirmText={status ? 'Disable' : 'Enable'}
              confirmCallback={() => confirmCallback(chartType, !status)}
            />
          </Col>
          <Col className="text-left" xs={3}>
            <span className="title d-flex">{title}</span>
            <span
              className="template d-flex"
              onClick={() => {
                downloadFile(employerId, chartType, 'TEMPLATE_FILE');
              }}
            >
              template file
            </span>
          </Col>
          <Col xs={8}>
            <div className="upload-file-container">
              {files.length > 0 &&
                files.map(
                  ({ fileName, fileChangedTs, fileChangedUser, type }) => (
                    <UploadedFile
                      key={`${chartType}_${type}`}
                      downloadFile={downloadFile}
                      chartType={chartType}
                      employerId={employerId}
                      fileName={fileName}
                      fileChangedTs={fileChangedTs}
                      fileChangedUser={fileChangedUser}
                      fileType={type}
                    />
                  )
                )}
              <FileUploader
                uploadId={uploadId}
                selectFile={this.selectFile}
                fileUploading={fileUploading}
                status={status}
              />
            </div>
          </Col>
        </Row>
        <Collapse isOpen={isCollapseOpen}>
          <div className="mx-4">
            {dataFetching ? (
              <Loader />
            ) : (
              <DataTableWithClientPagination
                columnData={this.columnData}
                rowData={tableData}
                hideSearchBar={true}
                defaultSortField={'Date Uploaded'}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 30, 50]}
                className="chart-upload-content_open"
                customStyles={{
                  rows: {
                    style: {
                      backgroundColor: TABLE_BACKGROUND_COLOR
                    }
                  },
                  headCells: {
                    style: {
                      backgroundColor: TABLE_BACKGROUND_COLOR
                    }
                  },
                  cells: {
                    style: {
                      backgroundColor: TABLE_BACKGROUND_COLOR
                    }
                  },
                  noData: {
                    style: {
                      backgroundColor: TABLE_BACKGROUND_COLOR
                    }
                  }
                }}
              />
            )}
          </div>
        </Collapse>
      </CardHeader>
    );
  }
}

const FileUploader = ({ selectFile, fileUploading, status, uploadId }) => {
  let fileUploader = createRef();
  const SecuredAddButton = permitIf(
    AddButton,
    INSIGHTS_FEATURE_ROLE_MAPPING.common
  );
  return (
    <>
      <SecuredAddButton
        uploadId={uploadId}
        onClick={() => fileUploader.click()}
        title="Import File"
        iconType="plus"
        loading={fileUploading}
        disabled={!status}
        classes="float-right"
      />
      <input
        type="file"
        id={uploadId !== null ? uploadId : 'file_uploader'}
        ref={(e) => (fileUploader = e)}
        onInput={(event) => {
          selectFile(event);
          fileUploader.value = null;
        }}
        accept={'.xlsx , .xls'}
        hidden={true}
      />
    </>
  );
};

const UploadedFile = ({
  fileName,
  fileChangedTs,
  fileChangedUser,
  downloadFile,
  chartType,
  fileType,
  employerId
}) => {
  return (
    <div>
      {fileName && (
        <div
          className={`uploaded-file-name ${fileType === 'ERROR_FILE' &&
            'error-file'}`}
        >
          <FontAwesomeIcon
            icon="paperclip"
            className="mr-2 attachment"
            onClick={() => {
              downloadFile(employerId, chartType, fileType);
            }}
          />
          <span
            className="uploaded-file-text file-name"
            onClick={() => {
              downloadFile(employerId, chartType, fileType);
            }}
          >
            {fileName}
          </span>
        </div>
      )}
      {fileChangedTs && fileChangedUser && (
        <div
          className={`uploaded-file-status ${fileType === 'ERROR_FILE' &&
            'error-file'}`}
        >
          <span className="status-info">
            {`Updated ${moment(fileChangedTs).format(
              'lll'
            )} by ${fileChangedUser}`}
          </span>
        </div>
      )}
    </div>
  );
};
export default ToggleEnabledUploader;
