/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import { Row, Col } from 'components/Atoms';
import PropTypes from 'prop-types';

import { BENCHMARKS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import FullscreenModal from 'components/FullscreenModal';
import PageActionButton from 'components/Buttons';
import { permitIf } from 'components/hoc/Permit';

class PublishConfirmationModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
  };

  render() {
    const SecuredPageActionButton = permitIf(
      PageActionButton,
      BENCHMARKS_FEATURE_ROLE_MAPPING.common
    );
    const {
      isOpen,
      toggle,
      onPublishButtonChange,
      onButtonChange,
      publishedBenchmarkName
    } = this.props;
    return (
      <FullscreenModal
        isOpen={isOpen}
        toggle={toggle}
        onClosed={this.resetContext}
        title="Publish to ER Portal"
        closeText={'cancel'}
      >
        <div>
          {getPublishBenchmarkMessageText(publishedBenchmarkName)}
        </div>
        <Row className="remove-plan-action-buttons-row">
          <Col xs={6}>
            <PageActionButton outline onClick={onButtonChange}>
              Cancel
            </PageActionButton>
          </Col>
          <Col xs={6}>
            <SecuredPageActionButton onClick={onPublishButtonChange}>
              Publish
            </SecuredPageActionButton>
          </Col>
        </Row>
      </FullscreenModal>
    );
  }
}

const getPublishBenchmarkMessageText = (publishedBenchmarkName) => {
  if(publishedBenchmarkName) {
    return (
      <span className="text-center">
        <p>
          Currently the benchmark <b> {publishedBenchmarkName} </b> is
          published.
        </p>
        <p>Do you want to publish this benchmark instead?</p>
        <p>This will unpublish the previously published benchmark.</p>
      </span>
    )
  }
  return (
    <span className="text-center">
      <p>Do you want to publish this benchmark?</p>
    </span>
  )
}

export default PublishConfirmationModal;
