/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import {
  CLEAR_EXIST_MODELING_DATA,
  GET_MEDICAL_MODELING_DATA,
  GET_DENTAL_MODELING_DATA,
  GET_VISION_MODELING_DATA,
  SAVING_MODELING_DATA_ERROR,
  SAVED_SUCCESS_MODELING_DATA,
  SAVE_INPROGRESS_MODELING_DATA,
  GET_CURRENT_PLAN_NAMES,
  GET_CURRENT_PLAN_NAMES_ERROR,
  GET_MODELING_DATA_ERROR,
  UPDATE_PROPOSAL_MODELING_FIELD_PENDING,
  SET_EDIT_MODE,
  GET_CURRENT_PLAN_FILE_HISTORY_IN_PROGRESS,
  GET_CURRENT_PLAN_FILE_HISTORY_SUCCESS,
  GET_CURRENT_PLAN_FILE_HISTORY_ERROR
} from '../actionTypes/offer.actionsTypes';

export default (
  state = {
    medicalModelings: null,
    dentalModelings: null,
    visionModelings: null,
    savingError: null,
    savedSuccess: false,
    modelingStep: null,
    editMode: 'P',
    currentPlans: [],
    getCurrentPlansError: null,
    changeFieldLoading: false,
    currentPlanFileHistory: {
      fileHistoryFetching: false,
      fileHistoryData: [],
      fileHistoryError: null
    }
  },
  { type, payload }
) => {
  /* eslint-disable */
  switch (type) {
    case CLEAR_EXIST_MODELING_DATA:
      return {
        ...state,
        medicalModelings: null,
        dentalModelings: null,
        visionModelings: null,
        savingError: null,
        savedSuccess: false
      };
    case GET_MEDICAL_MODELING_DATA:
      return {
        ...state,
        medicalModelings: payload,
        savingError: null,
        savedSuccess: false,
        changeFieldLoading: false
      };
    case GET_DENTAL_MODELING_DATA:
      return {
        ...state,
        dentalModelings: payload,
        savingError: null,
        savedSuccess: false,
        changeFieldLoading: false
      };
    case GET_VISION_MODELING_DATA:
      return {
        ...state,
        visionModelings: payload,
        savingError: null,
        savedSuccess: false,
        changeFieldLoading: false
      };
    case SAVING_MODELING_DATA_ERROR:
      return {
        ...state,
        savingError: payload,
        savedSuccess: false,
        saveInprogress: false
      };
    case SAVE_INPROGRESS_MODELING_DATA:
      return {
        ...state,
        savingError: null,
        savedSuccess: false,
        saveInprogress: true,
        currentPlans: []
      };
    case SAVED_SUCCESS_MODELING_DATA:
      return {
        ...state,
        savingError: null,
        savedSuccess: true,
        saveInprogress: false,
        modelingStep: payload,
        currentPlans: []
      };
    case GET_CURRENT_PLAN_NAMES:
      return {
        ...state,
        currentPlans: payload,
        getCurrentPlansError: null
      };
    case GET_CURRENT_PLAN_NAMES_ERROR:
      return {
        ...state,
        currentPlans: null,
        getCurrentPlansError: payload
      };
    case UPDATE_PROPOSAL_MODELING_FIELD_PENDING:
      return {
        ...state,
        changeFieldLoading: true
      };
    case GET_MODELING_DATA_ERROR:
      return {
        ...state,
        changeFieldLoading: false
      };
    case SET_EDIT_MODE:
      return {
        ...state,
        editMode: payload
      };
    case GET_CURRENT_PLAN_FILE_HISTORY_IN_PROGRESS:
      return {
        ...state,
        currentPlanFileHistory: {
          ...state.currentPlanFileHistory,
          fileHistoryFetching: true,
          fileHistoryError: null
        }
      };
    case GET_CURRENT_PLAN_FILE_HISTORY_SUCCESS:
      return {
        ...state,
        currentPlanFileHistory: {
          ...state.currentPlanFileHistory,
          fileHistoryFetching: false,
          fileHistoryData: payload,
          fileHistoryError: null
        }
      };
    case GET_CURRENT_PLAN_FILE_HISTORY_ERROR:
      return {
        ...state,
        currentPlanFileHistory: {
          ...state.currentPlanFileHistory,
          fileHistoryFetching: false,
          fileHistoryError: null
        }
      };
    default:
      return {
        ...state
      };
  }
  /* eslint-enable */
};
