import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback
} from 'components/Atoms';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { INPUT_DATE_FORMAT_DEFAULT, isValidFormat } from '../../util/dateUtil';
import Icon from 'components/Icons';
import './FormGroup.scss';

export default class DatePickerInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCalendarOpen: false
    };
  }

  static propTypes = {
    labelDisplay: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    inputName: PropTypes.string.isRequired,
    inputValue: PropTypes.string,
    dateFormat: PropTypes.string,
    placeholder: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isInvalid: PropTypes.bool,
    feedback: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
  };

  static defaultProps = {
    labelDisplay: '',
    dateFormat: INPUT_DATE_FORMAT_DEFAULT,
    // placeholder text will default to the given dateFormat
    // Expects (event) => any
    onChange: PropTypes.func.isRequired,
    disabled: false,
    isInvalid: false,
    feedback: 'Please select a valid date'
  };

  toggleCalendarOpen = () => {
    if (!this.props.disabled) {
      this.setState((prevState) => ({
        isCalendarOpen: !prevState.isCalendarOpen
      }));
    }
  };

  preventCalendarDoubleToggle = (event) => {
    const clickedElements = event.path;
    const calendarButtonId = this.getInputGroupAddonTextId();
    const foundCalendarButton = clickedElements.filter(
      (element) => element.id === calendarButtonId
    );
    if (_.isEmpty(foundCalendarButton)) {
      this.toggleCalendarOpen();
    }
    event.stopPropagation();
  };

  getInputGroupAddonTextId = () => {
    return this.props.inputName + 'InputGroupAddon';
  };

  render() {
    const {
      labelDisplay,
      inputName,
      inputValue,
      dateFormat,
      placeholder,
      onChange,
      disabled,
      isInvalid,
      feedback,
      minDate
    } = this.props;
    const { isCalendarOpen } = this.state;

    const resolvedPlaceholder = _.defaultTo(placeholder, dateFormat);

    return (
      <FormGroup className="date-picker">
        {labelDisplay && <Label for={inputName}>{labelDisplay}</Label>}
        <InputGroup>
          <Input
            className={isInvalid ? 'is-invalid' : ''}
            type="text"
            id={inputName}
            name={inputName}
            value={inputValue || ''}
            onChange={onChange}
            disabled={disabled}
            placeholder={resolvedPlaceholder}
          />
          <InputGroupAddon addonType="append">
            {isCalendarOpen && (
              <DatePicker
                selected={
                  isValidFormat(inputValue, dateFormat)
                    ? moment(inputValue, dateFormat).toDate()
                    : null
                }
                // DatePicker and moment use different formats. YYYY and DD will break DatePicker
                dateFormat={dateFormat
                  .replace('DD', 'dd')
                  .replace('YYYY', 'yyyy')}
                showMonthDropdown
                showYearDropdown
                popperPlacement="bottom"
                onChange={(date) => {
                  onChange({
                    target: {
                      name: inputName,
                      value: moment(date).format(dateFormat)
                    }
                  });
                  this.toggleCalendarOpen();
                }}
                customInput={<></>}
                open={isCalendarOpen}
                onClickOutside={this.preventCalendarDoubleToggle}
                minDate={minDate}
              />
            )}
            <InputGroupText
              id={this.getInputGroupAddonTextId()}
              className={isCalendarOpen ? 'active' : ''}
              onClick={this.toggleCalendarOpen}
            >
              <Icon icon="calendar-alt" />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        <FormFeedback className={isInvalid ? 'invalid' : ''}>
          {feedback}
        </FormFeedback>
      </FormGroup>
    );
  }
}
