/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Switch from 'react-switch';

import styleVariables from 'styles/_variables.scss';

export default class SwitchButton extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    // Expects (checked) => any
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    disabled: false
  };

  render() {
    const { className, checked, onChange, disabled, ...rest } = this.props;
    const { appBackgroundColor, primaryButtonColor } = styleVariables;
    return (
      <Switch
        className={className}
        onColor={primaryButtonColor}
        onHandleColor={appBackgroundColor}
        uncheckedIcon={false}
        checkedIcon={false}
        height={21}
        width={35}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
    );
  }
}
