/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Label } from 'components/Atoms';

import FullscreenModal from 'components/FullscreenModal';
import PageActionButton from 'components/Buttons';
import './RemovePlanModal.scss';

class RemovePlanModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
  };

  render() {
    const {
      isOpen,
      toggle,
      onRemovePlanType,
      onButtonChange,
      planKey
    } = this.props;
    return (
      <FullscreenModal
        isOpen={isOpen}
        toggle={toggle}
        onClosed={this.resetContext}
        title="Remove Plan"
        closeText={'cancel'}
      >
        <Row className="remove-plan-action-text-row">
          <Col xs="auto">
            <Label>
              Are you sure you want to remove this plan? This cannot be undone.
            </Label>
          </Col>
        </Row>
        <Row className="remove-plan-action-buttons-row">
          <Col xs={6}>
            <PageActionButton outline onClick={onButtonChange}>
              Cancel
            </PageActionButton>
          </Col>
          <Col xs={6}>
            <PageActionButton
              onClick={() => {
                onRemovePlanType(planKey);
                onButtonChange();
              }}
            >
              Remove Plan
            </PageActionButton>
          </Col>
        </Row>
      </FullscreenModal>
    );
  }
}

export default RemovePlanModal;
