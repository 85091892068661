/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';

import { TimePicker } from 'antd';
import { Row, Col, Label, FormGroup, Button } from 'components/Atoms';
import {
  ButtonFileUploadFormGroup,
  DatePickerInput,
  RadioRowFormGroup,
  SelectFormGroup,
  TextFormGroup
} from 'components/DataForm';
import Loader from 'components/CircularProgress';
import { sanitizeDateInput } from 'util/commonUtil';
import { permitIf } from 'components/hoc/Permit';
import { OE_MEETING_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { isValidUrl } from 'util/urlUtil';
import { INPUT_DATE_FORMAT_DEFAULT } from 'util/dateUtil';
import NotificationManager from 'components/Notifications/Notifications';
import apiUtil from 'util/apiUtil';
import { ATTACHMENT_SIZE_1000 } from 'constants/documentConstants';
import { getReadableFileSizeString } from 'modules/NativeIssueLog/util';
import {
  OE_MEETING_CONFIG,
  OE_MEETING_DATE_FORMAT,
  OE_MEETING_DEFAULT_TIMEZONE,
  OE_MEETING_HOURS_OPTIONS,
  OE_MEETING_MINUTES_OPTIONS,
  TIME_ZONES_ARRAY,
  UPLOADING,
  UPLOAD_COMPLETE,
  UPLOAD_ERROR,
  OE_MEETING_TIME_FORMAT
} from 'modules/BenGuides/constants';
import {
  cancelOEMeetingRecordingUpload,
  deleteOeMeeting,
  getOeMeeting,
  saveOeMeeting,
  uploadOEMeetingRecording
} from 'modules/BenGuides/actions/benGuidePlanAction';
import { isCustomMeetingOngoing } from 'modules/BenGuides/util';

import 'antd/es/time-picker/style/index.css';
import './CustomMeetingConfigForm.scss';

const baseURL = `${apiUtil.baseApi}/${apiUtil.version}`;
const MAX_RECORDING_SIZE = ATTACHMENT_SIZE_1000;

class CustomMeetingConfigForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRecordingOnly: false,
      meetingUrl: '',
      meetingDate: '',
      meetingTime: null,
      hours: 1,
      mins: 0,
      timezone: OE_MEETING_DEFAULT_TIMEZONE,
      fileName: null,
      selectedFile: null,
      recordingReference: '',
      existingMeeting: null
    };
  }

  componentDidMount() {
    const { getOeMeeting, benGuide } = this.props || {};
    const { id } = benGuide || {};
    getOeMeeting(id);
  }

  componentDidUpdate(prevProps) {
    const {
      oeMeeting,
      loading,
      error,
      oeMeetingRecording,
      deleteLoading,
      isOpen
    } = this.props;
    const { status } = oeMeetingRecording || {};
    const { type, id } = oeMeeting || {};

    //Loads existing custom meeting data
    if (
      prevProps.loading &&
      !loading &&
      !error &&
      !_.isEmpty(oeMeeting) &&
      type === OE_MEETING_CONFIG.CUSTOM.value
    ) {
      this.updateOeMeetingDetails(oeMeeting);
    }

    //handle oe meeting recording upload status
    if (status && status !== prevProps.oeMeetingRecording.status) {
      switch (status) {
        case UPLOAD_ERROR:
          NotificationManager.error(
            'Failed to upload the OE Meeting recording'
          );
          break;
        case UPLOAD_COMPLETE:
          this.setState({
            recordingReference: this.props.oeMeetingRecording.fileReference
          });
          NotificationManager.success(
            'OE Meeting recording uploaded successfully'
          );
          break;
        default:
          break;
      }
    }

    if (prevProps.deleteLoading && !deleteLoading && !error) {
      this.buildEmptyOeMeeting();
    }

    if (!prevProps.isOpen && isOpen) {
      this.buildEmptyOeMeeting();
      getOeMeeting(id);
    }
  }

  componentWillUnmount() {
    const { cancelOEMeetingRecordingUpload, oeMeetingRecording } = this.props;
    const { requestSource, status } = oeMeetingRecording || {};
    if (status === UPLOADING) {
      cancelOEMeetingRecordingUpload(requestSource);
    }
  }

  updateOeMeetingDetails = (oeMeeting) => {
    const { durationMinutes, isRecordingOnly, meetingTime, meetingRecording } =
      oeMeeting || {};
    const { fileName, reference } = meetingRecording || {};

    const hours = Math.trunc(durationMinutes / 60);
    const mins = durationMinutes % 60;
    const scheduledTime = moment(meetingTime);
    const meetingDate = scheduledTime.format(INPUT_DATE_FORMAT_DEFAULT);

    const existingMeeting = {
      isRecordingOnly: oeMeeting.isRecordingOnly,
      meetingUrl: oeMeeting.meetingUrl,
      meetingDate: meetingDate,
      meetingTime: scheduledTime,
      timezone: oeMeeting.timezone,
      fileName: fileName,
      recordingReference: reference
    };

    if (isRecordingOnly) {
      this.setState({
        isRecordingOnly: oeMeeting.isRecordingOnly,
        fileName: fileName,
        recordingReference: reference,
        timezone: '',
        hours: 0,
        existingMeeting: existingMeeting
      });
    } else {
      this.setState({
        isRecordingOnly: oeMeeting.isRecordingOnly,
        meetingUrl: oeMeeting.meetingUrl,
        meetingDate: meetingDate,
        meetingTime: scheduledTime,
        timezone: oeMeeting.timezone,
        hours: hours,
        mins: mins,
        fileName: fileName,
        recordingReference: reference,
        existingMeeting: existingMeeting
      });
    }
  };

  buildEmptyOeMeeting = () => {
    this.setState({
      isRecordingOnly: false,
      meetingUrl: '',
      meetingDate: '',
      meetingTime: null,
      hours: 1,
      mins: 0,
      timezone: OE_MEETING_DEFAULT_TIMEZONE,
      fileName: null,
      selectedFile: null,
      recordingReference: '',
      existingMeeting: null
    });
  };

  handleChange = (event) => {
    const { value, name } = event.target || {};

    if (name === 'isRecordingOnly') {
      const recordingOnly = value === 'true';
      this.setState({
        isRecordingOnly: recordingOnly,
        meetingUrl: '',
        meetingDate: '',
        meetingTime: null,
        hours: 1,
        mins: 0,
        timezone: OE_MEETING_DEFAULT_TIMEZONE
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  handleTimeChange = (time) => {
    this.setState({
      meetingTime: time
    });
  };

  isSaveButtonEnabled = () => {
    const { isRecordingOnly, recordingReference, existingMeeting } = this.state;

    const { oeMeetingRecording } = this.props;
    const { status } = oeMeetingRecording || {};
    const {
      recordingReference: savedVideoRef,
      isRecordingOnly: savedRecordingOnly
    } = existingMeeting || {};

    if (isRecordingOnly) {
      return Boolean(
        recordingReference &&
          status !== UPLOADING &&
          (recordingReference !== savedVideoRef || !savedRecordingOnly)
      );
    }

    const validations = this.validateInputs();

    return (
      this.isValidAllInputs(validations) &&
      this.haveValuesChanged() &&
      status !== UPLOADING
    );
  };

  validateInputs = () => {
    const {
      meetingUrl,
      meetingTime,
      meetingDate,
      timezone,
      hours,
      mins,
      isRecordingOnly,
      recordingReference
    } = this.state;

    const invalidInputs = {};

    if (isRecordingOnly) {
      invalidInputs.recordingReference =
        _.trim(recordingReference).length === 0;
      return invalidInputs;
    }

    invalidInputs.meetingUrl =
      _.trim(meetingUrl).length === 0 || !isValidUrl(meetingUrl);
    invalidInputs.meetingTime = _.trim(meetingTime).length === 0;
    invalidInputs.meetingDate = _.trim(meetingDate).length === 0;
    if (parseInt(hours) + parseInt(mins) === 0) {
      invalidInputs.hours = parseInt(hours) === 0;
      invalidInputs.mins = parseInt(mins) === 0;
    }

    if (invalidInputs.meetingDate) {
      invalidInputs.meetingDate = !moment(
        meetingDate,
        INPUT_DATE_FORMAT_DEFAULT,
        true
      ).isValid();
    }

    invalidInputs.timezone = _.trim(timezone).length === 0;
    return invalidInputs;
  };

  isValidAllInputs = (invalidInputs) => {
    let validAllData = true;
    Object.keys(invalidInputs).forEach((key) => {
      validAllData = !invalidInputs[key] && validAllData;
    });
    return validAllData;
  };

  haveValuesChanged = () => {
    const { oeMeeting } = this.props;
    const {
      hours,
      mins,
      existingMeeting,
      isRecordingOnly,
      meetingUrl,
      meetingDate,
      meetingTime,
      timezone,
      fileName,
      recordingReference
    } = this.state;

    //new meeting
    if (_.isEmpty(oeMeeting)) {
      return true;
    }

    const updatedMeeting = {
      isRecordingOnly,
      meetingUrl,
      meetingDate,
      meetingTime,
      timezone,
      fileName,
      recordingReference
    };

    const changed = !_.isEqual(updatedMeeting, existingMeeting);
    const durationChanged =
      parseInt(hours) * 60 + parseInt(mins) !== oeMeeting.durationMinutes;

    return durationChanged || changed;
  };

  onDeleteOeMeeting = () => {
    const { benGuide = {}, onDeleteOeMeeting } = this.props;
    onDeleteOeMeeting(benGuide, '', OE_MEETING_CONFIG.CUSTOM.value);
  };

  saveMeeting = () => {
    const {
      isRecordingOnly,
      meetingUrl,
      meetingDate,
      meetingTime,
      timezone,
      hours,
      mins,
      recordingReference,
      fileName
    } = this.state;
    const { benGuide, saveOeMeeting, oeMeeting } = this.props || {};
    const { id } = oeMeeting || {};
    if (!isRecordingOnly) {
      const formattedTime = meetingTime.format(OE_MEETING_TIME_FORMAT);
      const isoMeetingTime = moment(`${meetingDate} ${formattedTime}`).format(
        OE_MEETING_DATE_FORMAT
      );
      const durationMinutes = parseInt(hours) * 60 + parseInt(mins);
      const oeMeeting = {
        type: OE_MEETING_CONFIG.CUSTOM.value,
        meetingTime: isoMeetingTime,
        meetingUrl,
        timezone,
        hours,
        mins,
        durationMinutes,
        recordingReference,
        isRecordingOnly,
        meetingRecording: {
          fileName: fileName,
          reference: recordingReference
        },
        id
      };
      saveOeMeeting(benGuide, oeMeeting);
    } else {
      const recording = {
        type: OE_MEETING_CONFIG.CUSTOM.value,
        isRecordingOnly: isRecordingOnly,
        meetingRecording: {
          fileName: fileName,
          reference: recordingReference
        },
        id
      };
      saveOeMeeting(benGuide, recording);
    }
  };

  selectRecording = (event) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    const { benGuide } = this.props;
    const { id } = benGuide;

    if (selectedFile) {
      if (selectedFile.type !== 'video/mp4') {
        NotificationManager.error(
          'Unsupported file type for OE Meeting recording'
        );
        return;
      }
      if (selectedFile.size > MAX_RECORDING_SIZE) {
        NotificationManager.error(
          'File size exceeds the maximum limit.',
          `Maximum file size allowed is ${getReadableFileSizeString(
            MAX_RECORDING_SIZE
          )}`
        );
        return;
      }

      const fileBlob = new Blob([selectedFile], { type: selectedFile.type });
      this.setState({
        fileName: selectedFile.name,
        selectedFile: fileBlob
      });
      this.props.uploadOeMeetingRecording(id, fileBlob, selectedFile.name);
    }
  };

  removeRecording = () => {
    this.setState({
      fileName: null,
      recordingReference: '',
      selectedFile: null
    });
  };

  getFileUrl = () => {
    const { recordingReference } = this.state || {};
    const { benGuide } = this.props;

    return recordingReference
      ? `${baseURL}/benefit-guides/${benGuide.id}/${recordingReference}`
      : '';
  };

  render() {
    const {
      meetingUrl,
      meetingDate,
      meetingTime,
      hours,
      mins,
      timezone,
      isRecordingOnly,
      fileName,
      existingMeeting
    } = this.state;

    const {
      oeMeetingRecording,
      loading,
      oeMeeting,
      deleteLoading
    } = this.props;
    const { progress, status } = oeMeetingRecording || {};

    const SecuredButton = permitIf(
      Button,
      OE_MEETING_FEATURE_ROLE_MAPPING.common
    );

    return (
      <div className="custom-meeting-config-container">
        {loading || deleteLoading ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          <>
            <Row>
              <Col>
                <div className="recording-only-radio">
                  <RadioRowFormGroup
                    labelDisplay="Recording Only?"
                    onChange={this.handleChange}
                    inputName="isRecordingOnly"
                    inputValue={isRecordingOnly}
                    options={[
                      {
                        value: false,
                        text: 'No'
                      },
                      {
                        value: true,
                        text: 'Yes'
                      }
                    ]}
                    disabled={isCustomMeetingOngoing(oeMeeting)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextFormGroup
                  labelDisplay="Meeting URL *"
                  inputName="meetingUrl"
                  placeholder=""
                  inputValue={meetingUrl}
                  onChange={this.handleChange}
                  disabled={
                    isRecordingOnly || isCustomMeetingOngoing(oeMeeting)
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div className="meeting-date">
                      <DatePickerInput
                        labelDisplay="Meeting Time *"
                        inputName="meetingDate"
                        inputValue={meetingDate}
                        onChange={(event) => {
                          const { value } = event.target;
                          event.target.value = sanitizeDateInput(value, '-');
                          this.handleChange(event);
                        }}
                        minDate={new Date()}
                        feedback=""
                        disabled={
                          isRecordingOnly || isCustomMeetingOngoing(oeMeeting)
                        }
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="meeting-time">
                      <FormGroup>
                        <Label></Label>
                        <TimePicker
                          value={meetingTime}
                          name="meetingTime"
                          format={OE_MEETING_TIME_FORMAT}
                          onChange={this.handleTimeChange}
                          disabled={
                            isRecordingOnly || isCustomMeetingOngoing(oeMeeting)
                          }
                        />
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col>
                <SelectFormGroup
                  labelDisplay="Time Zone *"
                  inputName="timezone"
                  options={TIME_ZONES_ARRAY}
                  inputValue={timezone}
                  onChange={this.handleChange}
                  disabled={
                    isRecordingOnly || isCustomMeetingOngoing(oeMeeting)
                  }
                  invalid={!timezone && !isRecordingOnly}
                />
              </Col>
            </Row>
            <Row className="duration-wrapper">
              <Col xs={2}>
                <SelectFormGroup
                  labelDisplay="Duration *"
                  inputName="hours"
                  inputValue={hours}
                  options={OE_MEETING_HOURS_OPTIONS}
                  onChange={this.handleChange}
                  hideSelectOption={true}
                  disabled={
                    isRecordingOnly || isCustomMeetingOngoing(oeMeeting)
                  }
                />
              </Col>
              <Col xs="wrap">
                <span>hr</span>
              </Col>
              <Col xs={2}>
                <div className="mins">
                  <SelectFormGroup
                    inputName="mins"
                    inputValue={mins.toString()}
                    options={OE_MEETING_MINUTES_OPTIONS}
                    onChange={this.handleChange}
                    hideSelectOption={true}
                    disabled={
                      isRecordingOnly || isCustomMeetingOngoing(oeMeeting)
                    }
                  />
                </div>
              </Col>
              <Col xs="wrap">
                <span className="min">min</span>
              </Col>
            </Row>
            <Row>
              <Col className="video-upload-col">
                <ButtonFileUploadFormGroup
                  labelDisplay="Meeting Recording (only public after Meeting Time has completed)"
                  id="oe-video"
                  inputName="oeVideo"
                  acceptedFileTypes=".mp4"
                  fileLabel={fileName}
                  fileUrl={this.getFileUrl()}
                  onClickChoose={this.selectRecording}
                  onClickRemove={this.removeRecording}
                  showLoading={oeMeetingRecording.status === UPLOADING}
                  showProgress={oeMeetingRecording.status === UPLOADING}
                  progress={progress}
                  disabled={isCustomMeetingOngoing(oeMeeting)}
                  showRemove
                />
              </Col>
              <Col xs={3} className="file-size">
                {`${getReadableFileSizeString(
                  MAX_RECORDING_SIZE
                )} max file size`}
              </Col>
            </Row>
            <Row>
              <Col>
                <SecuredButton
                  size="lg"
                  className="w-100"
                  color="primary"
                  onClick={this.saveMeeting}
                  disabled={!this.isSaveButtonEnabled()}
                >
                  {_.isEmpty(existingMeeting)
                    ? 'Save Meeting'
                    : 'Update Meeting'}
                </SecuredButton>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col align="center">
                {!_.isEmpty(existingMeeting) && (
                  <SecuredButton
                    onClick={this.onDeleteOeMeeting}
                    size="lg"
                    className="delete-button"
                    color="default"
                    disabled={
                      isCustomMeetingOngoing(oeMeeting) || status === UPLOADING
                    }
                  >
                    Delete Meeting
                  </SecuredButton>
                )}
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getOeMeeting: (benGuideId) => dispatch(getOeMeeting(benGuideId)),
  saveOeMeeting: (benGuide, oeMeeting) =>
    dispatch(saveOeMeeting(benGuide, oeMeeting)),
  deleteOeMeeting: (benGuide, meetingNumber) =>
    dispatch(deleteOeMeeting(benGuide, meetingNumber)),
  uploadOeMeetingRecording: (benGuideId, fileBlob, fileName) =>
    dispatch(uploadOEMeetingRecording(benGuideId, fileBlob, fileName)),
  cancelOEMeetingRecordingUpload: (source) =>
    dispatch(cancelOEMeetingRecordingUpload(source))
});

const mapStateToProps = (state) => {
  const { oeMeeting, oeMeetingRecording } = state.benGuideReducer;
  const { oeMeeting: meetingData, loading, error, deleteLoading } =
    oeMeeting || {};
  return {
    oeMeeting: meetingData,
    loading,
    error,
    oeMeetingRecording,
    deleteLoading
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomMeetingConfigForm);
