/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as IntegrationsPages from './index';

const INTEGRATIONS_HOME = '/brokers/:brokerId/integrations/home';
const CREATE_INTEGRATION = '/brokers/:brokerId/employers/:employerId/integrations/new';
const EDIT_INTEGRATION = '/brokers/:brokerId/employers/:employerId/integrations/:edit';
const EMPLOYER_INTEGRATIONS = '/brokers/:brokerId/employers/:employerId/integrations';

const integrationsPathMapping = [
  { path: INTEGRATIONS_HOME, component: IntegrationsPages.IntegrationHome },
  { path: CREATE_INTEGRATION, component: IntegrationsPages.CreateIntegration },
  { path: EDIT_INTEGRATION, component: IntegrationsPages.CreateIntegration },
  {
    path: EMPLOYER_INTEGRATIONS,
    component: IntegrationsPages.EmployerIntegrations
  }
];

export default integrationsPathMapping;
export {
  INTEGRATIONS_HOME,
  CREATE_INTEGRATION,
  EMPLOYER_INTEGRATIONS,
  EDIT_INTEGRATION
};
