/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { Collapse } from 'reactstrap';

import SummaryRow from './SummaryRow';
import BillStatusSummaryRow from './BillStatusSummaryRow';
import PlanSummaryRow from './PlanSummaryRow';
import {
  ACTIVE_BILL_STATUS_PLAN_TYPES,
  PLAN_TYPE_DISPLAY_MAP
} from '../../../../constants';
import * as _ from 'lodash';

import './SummaryPreview.scss';

class BenefitSummaryRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { summary } = this.props;
    const { isOpen } = this.state;
    const { benefitType, billingStatusSummaries } = summary;

    // Always summary.billingStatusSummaries.lenght > 0
    // and L&D plans only have ACTIVE billing status that we are not showing in the summary table.
    const isActiveOnlyStatus =
      ACTIVE_BILL_STATUS_PLAN_TYPES.includes(benefitType) &&
      billingStatusSummaries.length;

    const planSummaries = !_.isEmpty(billingStatusSummaries)
      ? billingStatusSummaries[0].planSummaries
      : null;

    return (
      <>
        <SummaryRow
          showCollapseIcon={
            isActiveOnlyStatus
              ? !_.isEmpty(planSummaries)
              : !_.isEmpty(billingStatusSummaries)
          }
          name={PLAN_TYPE_DISPLAY_MAP[benefitType]}
          summary={summary}
          className="benefit-row"
          onClickIcon={this.toggle}
          isOpen={isOpen}
        />
        {isActiveOnlyStatus ? (
          <Collapse isOpen={isOpen} className="collapse-summary">
            {planSummaries &&
              planSummaries.map((planSummary) => (
                <PlanSummaryRow
                  key={`${benefitType}_${planSummary.planName}`}
                  summary={planSummary}
                />
              ))}
          </Collapse>
        ) : (
          <Collapse isOpen={isOpen} className="collapse-summary">
            {billingStatusSummaries.map((statusSummary, index) => (
              <BillStatusSummaryRow
                summary={statusSummary}
                key={`group_${index}`}
              />
            ))}
          </Collapse>
        )}
      </>
    );
  }
}
export default BenefitSummaryRow;
