/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from './auth.actions';
import * as ContextActionTypes from './context.actions';

import Util from 'util/apiUtil';
import AuthService from 'services/AuthService';

export const login = (username, password) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOGIN_IN_PROGRESS });
    AuthService.getOAuthToken(username, password).then(
      (response) => {
        // Update context to have current user info.
        // Backend TODO: Add username to whoami response
        AuthService.storeAuthDetails(response.data.expires_in, username);
        dispatch(ping());
      },
      (error) =>
        dispatch({ type: ActionTypes.LOGIN_FAILED, error: error.response })
    );
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOGOUT_IN_PROGRESS });
    AuthService.signOut().then(
      (response) => dispatch({ type: ActionTypes.LOGOUT_COMPLETED }),
      (error) => {
        dispatch({ type: ActionTypes.LOGOUT_FAILED });
        // Update context to have current user info.
        dispatch(ping());
      }
    );
  };
};

export const ping = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.PING_IN_PROGRESS });
    AuthService.whoami().then(
      ({ data }) => {
        const { individualId, organizationId } = data;
        const avatarUrl = `${Util.baseApi}/v1/logins/individuals/${individualId}/avatar`;
        dispatch({
          type: ActionTypes.PING_COMPLETED,
          data: { ...data, avatarUrl }
        });
        dispatch({
          type: ContextActionTypes.SET_ORGANISATION_CONTEXT,
          payload: { id: organizationId }
        });
      },
      (error) => {
        AuthService.signOut().then(
          (response) => dispatch({ type: ActionTypes.LOGOUT_COMPLETED }),
          (error) => {
            dispatch({ type: ActionTypes.LOGOUT_FAILED });
            if (
              window.location.pathname !== '/login' &&
              window.location.pathname !== '/password-reset'
            ) {
              window.location.pathname = '/login';
            }
          }
        );
      }
    );
  };
};

export const requestPasswordReset = (username) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.REQUEST_PASSWORD_RESET_IN_PROGRESS });
    AuthService.requestResetPassword(username).then(
      (response) => {
        dispatch({
          type: ActionTypes.REQUEST_PASSWORD_RESET_COMPLETED
        });
      },
      (error) =>
        dispatch({
          type: ActionTypes.REQUEST_PASSWORD_RESET_FAILED,
          error: error.response
        })
    );
  };
};

export const validatePasswordResetToken = (token) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.PASSWORD_RESET_TOKEN_VALIDATE_IN_PROGRESS });
    AuthService.validateResetPasswordToken(token).then(
      (response) => {
        dispatch({
          type: ActionTypes.PASSWORD_RESET_TOKEN_VALIDATE_COMPLETED,
          data: response.data
        });
      },
      (error) =>
        dispatch({
          type: ActionTypes.PASSWORD_RESET_TOKEN_VALIDATE_FAILED,
          error: error.response
        })
    );
  };
};

export const resetPassword = (token, newPassword) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESET_PASSWORD_IN_PROGRESS });
    AuthService.resetPassword(token, newPassword).then(
      (response) => {
        dispatch({
          type: ActionTypes.RESET_PASSWORD_COMPLETED
        });
      },
      (error) => {
        dispatch({
          type: ActionTypes.RESET_PASSWORD_FAILED,
          data: error.response ? error.response.data : error
        });
      }
    );
  };
};