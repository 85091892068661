/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import { Col, Row, Label, Input, FormGroup, Button, Alert } from 'reactstrap';
import { TimePicker } from 'antd';
import { FormText } from 'components/Atoms';
import {
  SelectFormGroup,
  DatePickerInput,
  TextAreaFormGroup
} from 'components/DataForm';
import { permitIf } from 'components/hoc/Permit';
import { OE_MEETING_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import {
  getOEMeetingUsers,
  getOeMeeting,
  saveOeMeeting,
  deleteOeMeeting
} from '../../../../actions/benGuidePlanAction';
import { connect } from 'react-redux';
import { sanitizeDateInput } from 'util/commonUtil';
import moment from 'moment';
import _ from 'lodash';

import SwitchButton from 'components/Buttons/Switch';

import {
  OE_MEETING_CONFIG,
  OE_MEETING_DATE_FORMAT,
  OE_MEETING_DEFAULT_TIMEZONE,
  OE_MEETING_ERRORS,
  OE_MEETING_HOURS_OPTIONS,
  OE_MEETING_MINUTES_OPTIONS,
  OE_MEETING_STATES,
  OE_MEETING_TIME_FORMAT,
  TIME_ZONES_ARRAY
} from '../../../../constants';
import ConfirmationBox from 'components/DialogBox/ConfirmationBox';
import Icon from 'components/Icons';
import { INPUT_DATE_FORMAT_DEFAULT } from 'util/dateUtil';
import { isEmail } from 'util/commonUtil';
import Loader from 'components/CircularProgress';

import './ZoomMeetingConfig.scss';
import 'antd/es/time-picker/style/index.css';

class ZoomMeetingConfigForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oeMeeting: {},
      reschdule: false,
      copiedJoinLink: false,
      changedData: false
    };
  }

  componentDidMount() {
    const { benGuide, getOeMeeting } = this.props || {};
    const { id } = benGuide || {};
    this.props.getOEMeetingUsers();
    this.clearScreen();
    this.buildEmptyOeMeeting();
    getOeMeeting(id);
  }

  componentDidUpdate(preProps) {
    const {
      isOpen,
      benGuide = {},
      getOeMeeting,
      oeMeeting,
      deleteLoading,
      error,
      deleteInprogress
    } = this.props;
    const { id } = benGuide || {};
    if (!preProps.isOpen && isOpen) {
      this.clearScreen();
      this.buildEmptyOeMeeting();
      getOeMeeting(id);
    }
    if (
      oeMeeting &&
      preProps.oeMeeting !== oeMeeting &&
      oeMeeting.benGuideId === id &&
      oeMeeting.type !== OE_MEETING_CONFIG.CUSTOM.value
    ) {
      this.updateExistingOeMeeting();
    }
    if (preProps.deleteLoading && !deleteLoading && !error) {
      this.buildEmptyOeMeeting();
    }

    if (preProps.deleteInprogress && !deleteInprogress && !error) {
      this.clearScreen();
    }
  }

  clearScreen = () => {
    this.setState({ touchedField: {} });
  };

  buildEmptyOeMeeting = () => {
    this.setState({
      oeMeeting: {
        _hours: 1,
        _mins: 0,
        _meetingTime: null,
        timezone: OE_MEETING_DEFAULT_TIMEZONE,
        alternativeHosts: '',
        panelists: [{ email: '' }]
      }
    });
  };

  updateExistingOeMeeting = () => {
    const { oeMeeting: existingOeMeeting } = this.props;
    const oeMeeting = _.cloneDeep(existingOeMeeting);
    const { durationMinutes = 60 } = oeMeeting;
    const meetingTime = moment(oeMeeting.meetingTime);
    oeMeeting._meetingDate = meetingTime.format(INPUT_DATE_FORMAT_DEFAULT);
    oeMeeting._meetingTime = meetingTime;
    oeMeeting._hours = Math.trunc(durationMinutes / 60);
    oeMeeting._mins = durationMinutes % 60;
    if (!existingOeMeeting) {
      oeMeeting.panelists = [{ email: '' }];
    }
    this.setState({
      oeMeeting: oeMeeting,
      reschdule: false,
      copiedJoinLink: false
    });
  };

  changeValues = (e) => {
    const { oeMeeting, touchedField = {} } = this.state;
    const { target = {} } = e;
    const { name, value } = target;
    oeMeeting[name] = value;
    touchedField[name] = true;

    if (name === 'alternativeHosts' && this.trimedLength(value) === 0) {
      oeMeeting.alternativeHosts = null;
    }
    this.setState({ oeMeeting: oeMeeting, touchedField: touchedField }, () => {
      if (name === '_meetingDate' || name === '_meetingTime') {
        this.updateMeetingTime();
      }
      this.reschduleMeeting();
    });
  };

  saveOeMeeting = () => {
    const { benGuide = {}, saveOeMeeting } = this.props;
    const { oeMeeting = {} } = this.state;
    const { _hours, _mins, enablePassword, panelists = [] } = oeMeeting;
    oeMeeting.durationMinutes = parseInt(_hours) * 60 + parseInt(_mins);
    if (!enablePassword) {
      oeMeeting.password = '';
    }
    const oEPanelists = [];
    panelists.forEach((panelist) => {
      if (this.trimedLength(panelist.email) > 0) {
        oEPanelists.push({ name: panelist.email, email: panelist.email });
      }
    });
    oeMeeting.panelists = oEPanelists;
    oeMeeting.type = OE_MEETING_CONFIG.ZOOM.value;
    saveOeMeeting(benGuide, oeMeeting);
  };

  updateMeetingTime = () => {
    const { oeMeeting = {} } = this.state;
    const { _meetingDate, _meetingTime } = oeMeeting;
    if (_meetingTime) {
      const formattedTime = _meetingTime.format(OE_MEETING_TIME_FORMAT);
      const meetingTime = moment(`${_meetingDate} ${formattedTime}`).format(
        OE_MEETING_DATE_FORMAT
      );
      this.setState({ oeMeeting: { ...oeMeeting, meetingTime: meetingTime } });
    }
  };

  reschduleMeeting = () => {
    this.setState({ reschdule: true });
  };

  startMeeting = () => {
    const { onToggle } = this.props;
    const { oeMeeting = {} } = this.state;
    const { joinUrl = '#' } = oeMeeting || {};
    window.open(joinUrl, '_blank');
    onToggle();
  };

  deleteOeMeeting = () => {
    const { benGuide = {}, onDeleteOeMeeting } = this.props;
    const { oeMeeting = {} } = this.state;
    const { meetingNumber } = oeMeeting;
    onDeleteOeMeeting(benGuide, meetingNumber, OE_MEETING_CONFIG.ZOOM.value);
  };

  copyToClipboard = (text) => {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.setState({ copiedJoinLink: true });
  };

  addPanelist = () => {
    const { oeMeeting = {} } = this.state;
    const { panelists = [{ email: '' }] } = oeMeeting;
    if (panelists && panelists.length < 3) {
      panelists.push({ email: '' });
    }
    this.setState({ oeMeeting: { ...oeMeeting, panelists: panelists } });
  };

  updatePanelistValue = (index, e) => {
    const { oeMeeting, touchedField = {} } = this.state;
    const { panelists = [] } = oeMeeting;
    const { target = {} } = e;
    const { value } = target;
    panelists[index] = { name: value, email: value };
    this.setState(
      { oeMeeting: { ...oeMeeting, panelists: panelists } },
      () => {}
    );
    touchedField[`${index}panelist`] = true;
    this.setState({ oeMeeting: oeMeeting, touchedField: touchedField });
  };

  trimedLength = (element) => {
    if (element) {
      return element.trim().length;
    } else {
      return 0;
    }
  };

  validateInputs = () => {
    const { oeMeeting = {} } = this.state;
    const {
      topic = '',
      agenda = '',
      host = '',
      _meetingDate = '',
      timezone = '',
      enablePassword,
      password = '',
      _meetingTime = '',
      panelists = [],
      _hours,
      _mins
    } = oeMeeting;
    const invalidInputs = {};
    invalidInputs.topic = this.trimedLength(topic) === 0;
    invalidInputs.agenda = this.trimedLength(agenda) === 0;
    invalidInputs.host = this.trimedLength(host) === 0;
    invalidInputs._meetingDate = this.trimedLength(_meetingDate) === 0;
    if (parseInt(_hours) + parseInt(_mins) === 0) {
      invalidInputs._hours = parseInt(_hours) === 0;
      invalidInputs._mins = parseInt(_mins) === 0;
    }
    if (!invalidInputs._meetingDate) {
      invalidInputs._meetingDate = !moment(
        _meetingDate,
        INPUT_DATE_FORMAT_DEFAULT,
        true
      ).isValid();
    }

    invalidInputs.timezone = this.trimedLength(timezone) === 0;
    invalidInputs._meetingTime = !_meetingTime;
    if (enablePassword) {
      invalidInputs.password = this.trimedLength(password) < 4;
    }

    panelists.forEach((panelist, index) => {
      if (this.trimedLength(panelist.email) !== 0) {
        invalidInputs[`${index}panelist`] = !isEmail(panelist.email);
      }
    });
    return invalidInputs;
  };

  isValidAllInputs = (invalidInputs) => {
    let validAllData = true;
    Object.keys(invalidInputs).forEach((key) => {
      validAllData = !invalidInputs[key] && validAllData;
    });
    return validAllData;
  };

  isChangedInputs = () => {
    const { oeMeeting } = this.state;
    const { oeMeeting: originalOeMeeting } = this.props;

    // New Meeting
    if (_.isEmpty(originalOeMeeting)) {
      return true;
    }

    const changedData = !_.isEqual(
      _.omit(oeMeeting, ['_hours', '_meetingDate', '_meetingTime', '_mins']),
      _.omit(originalOeMeeting, [])
    );

    return (
      parseInt(oeMeeting._hours) * 60 + parseInt(oeMeeting._mins) !==
        oeMeeting.durationMinutes || changedData
    );
  };

  toggleDeleteConfirmation = () => {
    const { isDeleteConfirmationOpen } = this.state;
    this.setState({ isDeleteConfirmationOpen: !isDeleteConfirmationOpen });
  };

  getError() {
    const { error = {} } = this.props;
    const errorCode = _.get({ ...error }, 'response.data.code');
    if (errorCode === OE_MEETING_ERRORS.INVALID_SUBSCRIPTION.code) {
      return OE_MEETING_ERRORS.INVALID_SUBSCRIPTION.message;
    }
    return 'Internal Server Error';
  }

  getOeMeetingLinkTextByState = (state) => {
    switch (state) {
      case OE_MEETING_STATES.SCHEDULED.value:
        return 'Update';
      case OE_MEETING_STATES.STARTED.value:
        return 'Update';
      case OE_MEETING_STATES.RECORDING_PROCESSING.value:
        return 'Reschedule';
      case OE_MEETING_STATES.RECORDING_FAILED.value:
        return 'Reschedule';
      case OE_MEETING_STATES.RECORDING_SUCCESS.value:
        return 'Reschedule';
      default:
        return 'Configure';
    }
  };

  handleTimeChange = (time) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        oeMeeting: {
          ...prevState.oeMeeting,
          _meetingTime: time
        },
        touchedField: {
          ...prevState.touchedField,
          _meetingTime: true
        }
      }),
      () => {
        this.updateMeetingTime();
      }
    );
  };

  render() {
    const {
      users,
      oeMeeting: scheduledOeMeeting,
      error,
      loading,
      deleteLoading
    } = this.props;
    const {
      oeMeeting = {},
      copiedJoinLink,
      touchedField = {},
      isDeleteConfirmationOpen
    } = this.state;
    const {
      host = '',
      alternativeHosts = '',
      _meetingDate = '',
      _meetingTime = null,
      _hours = 1,
      _mins = 0,
      timezone,
      joinUrl,
      enablePassword = false,
      password = '',
      panelists,
      topic = '',
      agenda = ''
    } = oeMeeting || {};
    const SecuredButton = permitIf(
      Button,
      OE_MEETING_FEATURE_ROLE_MAPPING.common
    );

    const usersArray = _.orderBy(
      users
        .filter((user) => user.id !== alternativeHosts && user.type !== 1)
        .map((user) => {
          return { value: user.id, text: `${user.firstName} ${user.lastName}` };
        }),
      ['text'],
      ['asc']
    );

    const altUsersArray = _.orderBy(
      users
        .filter((user) => user.id !== host && user.type !== 1)
        .map((user) => {
          return { value: user.id, text: `${user.firstName} ${user.lastName}` };
        }),
      ['text'],
      ['asc']
    );

    const invalidInputs = this.validateInputs();

    const validAllData = this.isValidAllInputs(invalidInputs);

    const changedData = this.isChangedInputs();

    let panelistsWithEmptyField = [{ email: '' }];
    if (panelists && panelists.length > 0) {
      if (
        scheduledOeMeeting &&
        scheduledOeMeeting.state &&
        (scheduledOeMeeting.state === OE_MEETING_STATES.SCHEDULED.value ||
          scheduledOeMeeting.state ===
            OE_MEETING_STATES.RECORDING_SUCCESS.value)
      ) {
        if (panelists[0].email.length > 0) {
          panelistsWithEmptyField.splice(0, panelistsWithEmptyField.length);
        }
        panelistsWithEmptyField = panelistsWithEmptyField.concat(panelists);
      } else {
        panelistsWithEmptyField = panelists;
      }
    }

    const disabledAllFields = Boolean(
      scheduledOeMeeting &&
        scheduledOeMeeting.state &&
        scheduledOeMeeting.state === OE_MEETING_STATES.STARTED.value
    );

    return (
      <div>
        {loading || deleteLoading ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          <>
            <Row>
              <Col>
                {error && (
                  <Alert color="danger" className="error-alert mt-3">
                    {this.getError()}
                  </Alert>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Topic *</Label>
                  <Input
                    value={topic}
                    name="topic"
                    onChange={this.changeValues}
                    invalid={invalidInputs.topic && touchedField.topic}
                    maxLength="200"
                    disabled={disabledAllFields}
                  />
                  <FormText align="right">
                    Max Character Count: {topic ? topic.length : 0}/{200}
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextAreaFormGroup
                  labelDisplay="Description *"
                  inputName="agenda"
                  inputValue={agenda}
                  onChange={this.changeValues}
                  invalid={invalidInputs.agenda && touchedField.agenda}
                  maxLength={2000}
                  disabled={disabledAllFields}
                  className="text-area"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectFormGroup
                  labelDisplay="Main Host *"
                  inputValue={host}
                  inputName="host"
                  options={usersArray}
                  onChange={this.changeValues}
                  invalid={invalidInputs.host && touchedField.host}
                  disabled={disabledAllFields}
                />
              </Col>
              <Col>
                <SelectFormGroup
                  labelDisplay="Alternative Host"
                  inputName="alternativeHosts"
                  options={altUsersArray}
                  inputValue={alternativeHosts}
                  onChange={this.changeValues}
                  invalid={
                    invalidInputs.alternativeHosts &&
                    touchedField.alternativeHosts
                  }
                  disabled={disabledAllFields}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Panelist Email</Label>
                  {panelistsWithEmptyField.map((panelist, index) => (
                    <Input
                      key={index}
                      className="mt-2"
                      type="email"
                      value={panelist.email}
                      onChange={(event) => {
                        this.updatePanelistValue(index, event);
                      }}
                      invalid={
                        invalidInputs[`${index}panelist`] &&
                        touchedField[`${index}panelist`]
                      }
                      disabled={disabledAllFields}
                    />
                  ))}
                </FormGroup>
              </Col>
              <Col>
                {panelistsWithEmptyField.length < 3 && (
                  <button
                    onClick={this.addPanelist}
                    className="add-panelist-link"
                    disabled={disabledAllFields}
                  >
                    + Add Another Panelist Email
                  </button>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div className="meeting-date">
                      <DatePickerInput
                        labelDisplay="Meeting Time *"
                        inputName="_meetingDate"
                        inputValue={_meetingDate}
                        onChange={(event) => {
                          const { value } = event.target;
                          event.target.value = sanitizeDateInput(value, '-');
                          this.changeValues(event);
                        }}
                        minDate={new Date()}
                        isInvalid={
                          invalidInputs._meetingDate &&
                          touchedField._meetingDate
                        }
                        feedback=""
                        disabled={disabledAllFields}
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="meeting-time">
                      <FormGroup>
                        <Label></Label>
                        <TimePicker
                          className={`meeting-time ${
                            invalidInputs._meetingTime &&
                            touchedField._meetingTime
                              ? 'invalid'
                              : ''
                          }`}
                          value={_meetingTime}
                          name="_meetingTime"
                          format={OE_MEETING_TIME_FORMAT}
                          onChange={this.handleTimeChange}
                          disabled={disabledAllFields}
                          popupClassName="oe-meeting-time-popup"
                          getPopupContainer={(trigger) => trigger.parentElement}
                        />
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col>
                <SelectFormGroup
                  labelDisplay="Time Zone *"
                  inputName="timezone"
                  options={TIME_ZONES_ARRAY}
                  inputValue={timezone}
                  onChange={this.changeValues}
                  invalid={invalidInputs.timezone && touchedField.timezone}
                  disabled={disabledAllFields}
                />
              </Col>
            </Row>
            <Row className="duration-wrapper">
              <Col>
                <Row>
                  <Col xs={4}>
                    <SelectFormGroup
                      labelDisplay="Duration *"
                      inputName="_hours"
                      inputValue={_hours}
                      options={OE_MEETING_HOURS_OPTIONS}
                      onChange={this.changeValues}
                      hideSelectOption={true}
                      invalid={invalidInputs._hours && touchedField._hours}
                      disabled={disabledAllFields}
                    />
                  </Col>
                  <Col xs="wrap">
                    <span>hr</span>
                  </Col>
                  <Col xs={4}>
                    <div className="mins">
                      <SelectFormGroup
                        inputName="_mins"
                        inputValue={_mins.toString()}
                        options={OE_MEETING_MINUTES_OPTIONS}
                        onChange={this.changeValues}
                        hideSelectOption={true}
                        invalid={invalidInputs._mins && touchedField._mins}
                        disabled={disabledAllFields}
                      />
                    </div>
                  </Col>
                  <Col xs="wrap">
                    <span className="min">min</span>
                  </Col>
                </Row>
              </Col>

              <Col>
                <FormGroup>
                  <Label>Passcode</Label>{' '}
                  <div className="passcode-switch">
                    <SwitchButton
                      checked={enablePassword || false}
                      name="enablePassword"
                      onChange={(e) => {
                        this.changeValues({
                          target: { name: 'enablePassword', value: e }
                        });
                      }}
                      disabled={disabledAllFields}
                    />
                  </div>
                  <Row>
                    <Col>
                      {enablePassword && (
                        <Input
                          name="password"
                          value={password}
                          onChange={this.changeValues}
                          invalid={
                            invalidInputs.password && touchedField.password
                          }
                          placeholder="Minimum of 4 characters"
                          disabled={disabledAllFields}
                        />
                      )}
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>

            {joinUrl &&
              scheduledOeMeeting &&
              !scheduledOeMeeting.meetingExpiredOrDeleted && (
                <Row>
                  <Col>
                    <div className="join-url-wrapper mt-4">
                      <label>Meeting URL: </label>
                      <a
                        href={joinUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-1"
                      >
                        {joinUrl}
                      </a>
                      <span
                        className="copy-btn btn-primary"
                        onClick={() => {
                          this.copyToClipboard(joinUrl);
                        }}
                      >
                        {copiedJoinLink ? 'Copied' : 'Copy'}
                      </span>
                    </div>
                  </Col>
                </Row>
              )}

            <Row className="mt-5">
              <Col align="right">
                <SecuredButton
                  disabled={!(validAllData && changedData) || disabledAllFields}
                  size="lg"
                  className="w-100"
                  color="primary"
                  onClick={this.saveOeMeeting}
                >
                  {scheduledOeMeeting
                    ? ' Reschedule Meeting'
                    : ' Schedule Meeting'}
                </SecuredButton>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col align="center">
                {scheduledOeMeeting && (
                  <SecuredButton
                    onClick={this.toggleDeleteConfirmation}
                    size="lg"
                    className="delete-button"
                    color="default"
                    disabled={disabledAllFields}
                  >
                    Delete Meeting
                  </SecuredButton>
                )}
              </Col>
            </Row>

            <ConfirmationBox
              message="Are you sure you want to delete this meeting?"
              isOpen={isDeleteConfirmationOpen}
              toggle={this.toggleDeleteConfirmation}
              confirmText={
                <>
                  <Icon icon="trash-alt" className="mr-2" />
                  Yes
                </>
              }
              confirmCallback={() => {
                this.deleteOeMeeting();
                this.toggleDeleteConfirmation();
              }}
            />
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getOEMeetingUsers: () => dispatch(getOEMeetingUsers()),
  getOeMeeting: (benGuideId) => dispatch(getOeMeeting(benGuideId)),
  saveOeMeeting: (benGuide, oeMeeting) =>
    dispatch(saveOeMeeting(benGuide, oeMeeting)),
  deleteOeMeeting: (benGuide, meetingNumber) =>
    dispatch(deleteOeMeeting(benGuide, meetingNumber))
});

const mapStateToProps = (state) => {
  const { oeMeeingUsers, oeMeeting } = state.benGuideReducer;
  return {
    users: oeMeeingUsers.users,
    ...oeMeeting
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoomMeetingConfigForm);
