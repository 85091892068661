/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import { Col, Row, Button } from 'components/Atoms';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import Icon from 'components/Icons';
import NotificationManager from 'components/Notifications';
import SelectFormGroup from 'components/DataForm/SelectFormGroup';
import TextFormGroup from 'components/DataForm/TextFormGroup';
import { permitIf } from 'components/hoc/Permit';
import {
  SERVICE_SSO_ENABLED,
  vendors,
  SSO_VALIDATION_COMPLETED,
  SSO_VALIDATION_FAILED,
  SSO_VALIDATION_INPROGRESS,
  OKTA,
  ONELOGIN
} from '../../constants';
import { SSO_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import {
  findConfigStatus,
  updateConfigModule,
  validateMetaDataUrl,
  saveSSOConfig,
  getSSOConfig
} from '../../actions/ssoAction';
import './SingleSignOn.scss';

class SingleSignOn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ssoConfig: {
        vendor: '',
        metadataUrl: ''
      },
      ssoError: {
        vendorError: '',
        metaDataUrlError: ''
      },
      isMetaDataUrlValid: false,
      isSSOToggleEnabled: false,
      isLabelChanged: false,
      toggleConfig: {
        value: 'false'
      }
    };
  }

  componentDidMount() {
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { employerId } = params;
    const { findConfigStatus, getSSOConfig } = this.props;

    findConfigStatus(employerId, SERVICE_SSO_ENABLED);
    getSSOConfig(employerId);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      validatedMetaDataUrl,
      savedSSOConfig,
      ssoConfiguration
    } = this.props;
    const { ssoConfig = {} } = this.state;

    if (
      ssoConfiguration != null &&
      ssoConfiguration.ssoData !== null &&
      ssoConfiguration.ssoData !== prevProps.ssoConfiguration.ssoData &&
      (ssoConfiguration.getStatus !== prevProps.ssoConfiguration.getStatus ||
        ssoConfiguration.ssoData.vendor !== ssoConfig.vendor)
    ) {
      const { ssoData } = ssoConfiguration;
      ssoConfig.vendor = ssoData.vendor;
      ssoConfig.metadataUrl = ssoData.metadataUrl;
      ssoConfig.idPRelyingPartyId = ssoData.idPRelyingPartyId;

      this.setState({ ssoConfig });
    }

    if (
      validatedMetaDataUrl.validatingStatus !==
        prevProps.validatedMetaDataUrl.validatingStatus &&
      validatedMetaDataUrl.validatingStatus === SSO_VALIDATION_COMPLETED &&
      validatedMetaDataUrl.responseStatus === 200
    ) {
      NotificationManager.success(
        'Validation Successful: Metadata URL has been successfully validated'
      );
      this.setState({ isMetaDataUrlValid: validatedMetaDataUrl.isValidated });
    }

    if (
      validatedMetaDataUrl.validatingStatus !==
        prevProps.validatedMetaDataUrl.validatingStatus &&
      validatedMetaDataUrl.validatingStatus === SSO_VALIDATION_FAILED &&
      validatedMetaDataUrl.errorResponse &&
      validatedMetaDataUrl.errorResponse.response
    ) {
      this.setState({ isMetaDataUrlValid: false });
      if (validatedMetaDataUrl.responseStatus === 406) {
        NotificationManager.error(
          'Validation Failed: Please provide a valid Metadata URL'
        );
      } else if (validatedMetaDataUrl.responseStatus === 500) {
        const { code } = validatedMetaDataUrl.errorResponse.response.data;
        if (code === 'vendor.and.metadataurl.mismatch') {
          const { vendor } = this.state.ssoConfig;
          let vendorString;
          if (vendor === OKTA) {
            vendorString = 'Okta';
          } else if (vendor === ONELOGIN) {
            vendorString = 'OneLogin';
          }
          NotificationManager.error(
            `Validation Failed: Vendor and Metadata URL does not match for ${vendorString}`
          );
        } else {
          NotificationManager.error('Metadata Url validation failed');
        }
      }
    }

    if (
      savedSSOConfig.savedStatus !== prevProps.savedSSOConfig.savedStatus &&
      savedSSOConfig.savedStatus === 'SAVE_SSO_COMPLETED'
    ) {
      NotificationManager.success('SSO configuration saved successfully');
    }

    if (
      savedSSOConfig.savedStatus !== prevProps.savedSSOConfig.savedStatus &&
      savedSSOConfig.savedStatus === 'SAVE_SSO_FAILED'
    ) {
      NotificationManager.error('SSO configuration saving failed');
    }
  }

  toggleSSOEnabled = () => {
    const { isSSOToggleEnabled, toggleConfig } = this.state;
    !isSSOToggleEnabled
      ? (toggleConfig.value = 'true')
      : (toggleConfig.value = 'false');
    this.setState({
      isSSOToggleEnabled: !isSSOToggleEnabled,
      toggleConfig: toggleConfig,
      ssoConfig: {},
      ssoError: {},
      isMetaDataUrlValid: false,
      isLabelChanged: false
    });
  };

  onChangeVendor = (e) => {
    const { value } = e.target;
    const { isMetaDataUrlValid } = this.state;
    this.setState((prevState) => ({
      ssoConfig: { ...prevState.ssoConfig, vendor: value },
      ssoError: { ...prevState.ssoError, vendorError: '' },
      isLabelChanged: isMetaDataUrlValid ? true : false
    }));
  };

  onChangeMetaData = (e) => {
    const { value } = e.target;
    const { isMetaDataUrlValid } = this.state;
    this.setState((prevState) => ({
      ssoConfig: { ...prevState.ssoConfig, metadataUrl: value },
      ssoError: { ...prevState.ssoError, metaDataUrlError: '' },
      isLabelChanged: isMetaDataUrlValid ? true : false
    }));
  };

  validateMetaDataUrl = () => {
    const { vendor, metadataUrl } = this.state.ssoConfig;
    const { ssoError, isMetaDataUrlValid } = this.state;
    if (_.isEmpty(metadataUrl)) {
      ssoError.metaDataUrlError = 'Metadata URL is mandatory';
      this.setState({ ssoError: ssoError, isMetaDataUrlValid: false });
    } else if (_.isEmpty(vendor)) {
      ssoError.vendorError = 'Vendor is mandatory';
      this.setState({ ssoError: ssoError, isMetaDataUrlValid: false });
    } else {
      if (isMetaDataUrlValid) {
        this.setState({ isMetaDataUrlValid: false, isLabelChanged: false });
      }
      this.props.validateMetaDataUrl(metadataUrl, vendor);
    }
  };

  saveConfiguration = () => {
    const { ssoConfig } = this.state;
    const {
      match = {},
      updateConfigModule,
      saveSSOConfig,
      configStatus
    } = this.props;
    const { params = {} } = match;
    const { employerId } = params;

    updateConfigModule(employerId, configStatus.id);
    saveSSOConfig(employerId, ssoConfig);
  };

  render() {
    const {
      ssoConfig,
      ssoError,
      isMetaDataUrlValid,
      isSSOToggleEnabled,
      isLabelChanged,
      toggleConfig
    } = this.state;

    const {
      savedSSOConfig,
      validatedMetaDataUrl,
      ssoConfiguration,
      configStatus,
      match = {}
    } = this.props;

    const { params = {} } = match;
    const { employerId } = params;

    const { isSaved, savedStatus } = savedSSOConfig;
    const { validatingStatus } = validatedMetaDataUrl;
    const { ssoData } = ssoConfiguration;

    const { vendor, metadataUrl } = ssoConfig;
    const { vendorError, metaDataUrlError } = ssoError;

    const SecuredEmployerConfigToggle = permitIf(
      EmployerConfigToggleV2,
      SSO_FEATURE_ROLE_MAPPING.common
    );

    const SecuredButton = permitIf(Button, SSO_FEATURE_ROLE_MAPPING.common);

    return (
      <ApplicationWrapper>
        <HeaderContainer title="Single Sign-On" />
        <Row>
          <Col>
            <ContentContainer className="sso page-content">
              <SecuredEmployerConfigToggle
                employerId={employerId}
                moduleEnabled={SERVICE_SSO_ENABLED}
                message="SSO"
                config={
                  configStatus && configStatus.value === 'true'
                    ? configStatus
                    : toggleConfig
                }
                switchCallBack={this.toggleSSOEnabled}
                disabled={isSaved || ssoData !== null}
              />
            </ContentContainer>
          </Col>
        </Row>
        {(configStatus.value === 'true' || isSSOToggleEnabled) && (
          <div className="sso page-content">
            <ContentContainer>
              <Row>
                <Col>
                  <SelectFormGroup
                    labelDisplay="Vendor *"
                    inputName="vendor"
                    inputValue={vendor}
                    onChange={this.onChangeVendor}
                    disabled={isSaved || ssoData !== null}
                    options={vendors}
                    optionsMap={(type) => (
                      <option key={type} value={type.toUpperCase()}>
                        {type}
                      </option>
                    )}
                    isInvalid={!_.isEmpty(vendorError)}
                    feedback={vendorError}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <TextFormGroup
                    labelDisplay="Metadata URL *"
                    inputName="metaDataUrl"
                    inputValue={metadataUrl}
                    placeholder="Metadata URL *"
                    maxLength="100"
                    isInvalid={!_.isEmpty(metaDataUrlError)}
                    feedback={metaDataUrlError}
                    onChange={this.onChangeMetaData}
                    disabled={isSaved || ssoData !== null}
                  />
                </Col>
                <Col xs="2">
                  <SecuredButton
                    color="secondary"
                    size="xs"
                    className="sso-metadata-validation-button"
                    onClick={this.validateMetaDataUrl}
                    disabled={false}
                  >
                    Validate &nbsp;
                    {(isMetaDataUrlValid || ssoData !== null) &&
                    !isLabelChanged ? (
                      <Icon icon="check" className="mr-2 check" />
                    ) : validatingStatus === SSO_VALIDATION_INPROGRESS ? (
                      <Icon icon="circle-notch" className="mr-2" spin />
                    ) : validatingStatus === SSO_VALIDATION_FAILED ? (
                      <Icon icon="times" className="mr-2 times" />
                    ) : (
                      <></>
                    )}
                  </SecuredButton>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SecuredButton
                    color="primary"
                    size="lg"
                    className="sso-submit-button"
                    disabled={
                      !isMetaDataUrlValid ||
                      isSaved ||
                      ssoData ||
                      isLabelChanged
                    }
                    onClick={this.saveConfiguration}
                  >
                    Save Configuration &nbsp;
                    {isSaved ? (
                      <Icon icon="check" className="mr-2" />
                    ) : savedStatus === 'SAVE_SSO_INPROGRESS' ? (
                      <Icon icon="circle-notch" className="mr-2" spin />
                    ) : (
                      <></>
                    )}
                  </SecuredButton>
                </Col>
              </Row>
            </ContentContainer>
          </div>
        )}
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateConfigModule: (employerId, configId) =>
    dispatch(updateConfigModule(employerId, configId)),
  findConfigStatus: (employerId, key) =>
    dispatch(findConfigStatus(employerId, key)),
  validateMetaDataUrl: (metaDataUrl, vendor) =>
    dispatch(validateMetaDataUrl(metaDataUrl, vendor)),
  saveSSOConfig: (employerId, ssoConfig) =>
    dispatch(saveSSOConfig(employerId, ssoConfig)),
  getSSOConfig: (employerId) => dispatch(getSSOConfig(employerId)),
});

const mapStateToProps = (state) => {
  const {
    configStatus,
    validatedMetaDataUrl,
    savedSSOConfig,
    ssoConfiguration
  } = state.ssoReducer;

  return {
    configStatus,
    validatedMetaDataUrl,
    savedSSOConfig,
    ssoConfiguration
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleSignOn);
