/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as LocationPages from './index';

const ROOT_PATH = '/brokers/:brokerId/';
const LOCATIONS_LIST_PATH = '/brokers/:brokerId/locations';
const LOCATIONS_CREATE_PATH = '/brokers/:brokerId/locations/create';
const LOCATIONS_IMPORT_PATH = '/brokers/:brokerId/locations/import';
const LOCATIONS_EDIT_PATH = '/brokers/:brokerId/locations/:locationId/edit';
const LOCATIONS_VIEW_PATH = '/brokers/:brokerId/locations/:locationId/view';

const employerPathMapping = [
  { path: ROOT_PATH, component: LocationPages.LocationsList },
  { path: LOCATIONS_LIST_PATH, component: LocationPages.LocationsList },
  { path: LOCATIONS_CREATE_PATH, component: LocationPages.CreateLocation },
  { path: LOCATIONS_EDIT_PATH, component: LocationPages.CreateLocation },
  { path: LOCATIONS_VIEW_PATH, component: LocationPages.CreateLocation }
];

export default employerPathMapping;
export {
  ROOT_PATH,
  LOCATIONS_LIST_PATH,
  LOCATIONS_CREATE_PATH,
  LOCATIONS_IMPORT_PATH,
  LOCATIONS_EDIT_PATH,
  LOCATIONS_VIEW_PATH
};
