/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';

import * as AdminsRoutes from 'modules/Admins/routes';
import * as EmployersRoutes from 'modules/Employers/routes';
import * as LocationsRoutes from 'modules/Locations/routes';
import * as EmployeesRoutes from 'modules/Employees/routes';
import * as PlanRoutes from 'modules/Plans/routes';
import * as MobileRoutes from 'modules/Mobile/routes';
import * as ChatbotRoutes from 'modules/Chatbot/routes';
import * as IssueLogRoutes from 'modules/IssueLog/routes';
import * as CalendarRoutes from 'modules/Calendar/routes';
import * as RenewalsRoutes from 'modules/Renewals/routes';
import * as DocumentRoutes from 'modules/DocumentV2/routes';
import * as CarriersRoutes from 'modules/Carriers/routes';
import * as InsightsRoutes from 'modules/Insights/routes';
import * as BenguideRoutes from 'modules/BenGuides/routes';
import * as BenchmarkRoutes from 'modules/Benchmarks/routes';
import * as BillingRoutes from 'modules/Billing/routes';
import * as SurveysRoutes from 'modules/Surveys/routes';
import * as IntegrationsRoutes from 'modules/Integrations/routes';
import * as OfferRoutes from 'modules/Offers/routes';
import * as BenefitsConsultationRoutes from 'modules/BenefitsConsultation/routes';
import * as SSORoutes from 'modules/SSO/routes';
import * as NativeIssueLogRoutes from 'modules/NativeIssueLog/routes';
import * as BenAdminIntegrationsRoutes from 'modules/BenAdminIntegrations/routes';
import * as CurrentPlansRoutes from 'modules/CurrentPlans/routes';

import * as roleMappings from './../modules/app-base/constants/roleFeaturesMap';
import {
  AllowedIntegrationType,
  EmployerSourceType
} from 'modules/Employers/constants';

/**
 * Representation of the fully-expanded sidebar. Accepts contextObjects to set dynamic fields such as employerName.
 *
 * Submenu parameter definitions:
 *    display: Display text in sidebar.
 *    icon: FontAwesomeIcon display to display in sidebar. Generally used for top-level objects but there is no restriction.
 *    routes: All routes that should have this subTree as their leaf. The sidebar will show all subTrees in the path to the leaf.
 *            If no subTree matching a route is found, all subTrees with prefixes that matche the route will be expanded.
 *    linkTo: Route that user should be redirected to by clicking on this display in sidebar.
 *    permissions: List of permissions that allow users to access this submenu. TBD, but should err on stricter side.
 *    subTrees: Map from prefixes to the subTree that should be expanded for routes that match this prefix. This gives recursive structure.
 *              subTree entries should be ordered in decreasing specifity to prevent expansion of wrong subTrees.
 *
 * @param {Object} contextObjects containing employerContext object
 */
export const NavigationTree = (contextObjects) => {
  const { employerContext } = contextObjects;
  const {
    name: employerName = 'N/A',
    integrations = {},
    source
  } = employerContext;
  const [integrationValues = []] = Object.values(integrations || {});
  const [integrationObject = []] = integrationValues || [];
  const { allowedIntegrations = [] } = integrationObject || {};
  const isFlockOrWorkdayBenAdminEmployer =
    source === EmployerSourceType.FLOCK.value ||
    source === EmployerSourceType.WORKDAY_BENADMIN.value;
  return [
    {
      display: 'Admins',
      icon: 'users-cog',
      linkTo: AdminsRoutes.ADMINS_USERS_LIST_PATH,
      routes: [],
      roles: roleMappings.ADMINS_FEATURE_ROLE_MAPPING.navigation,
      subTrees: {
        // Default Submenu
        '/brokers/:brokerId/admins': [
          {
            display: 'Users',
            linkTo: AdminsRoutes.ADMINS_USERS_LIST_PATH,
            routes: [
              AdminsRoutes.ADMINS_USERS_LIST_PATH,
              AdminsRoutes.ADMINS_USER_DETAILS_PATH
            ],
            roles: roleMappings.ADMINS_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'Roles',
            linkTo: AdminsRoutes.ADMINS_ROLES_LIST_PATH,
            routes: [
              AdminsRoutes.ADMINS_ROLES_LIST_PATH,
              AdminsRoutes.ADMINS_USERS_LIST_FOR_ROLE_PATH
            ],
            roles: roleMappings.ADMINS_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'Calendars',
            linkTo: AdminsRoutes.ADMIN_CALENDAR_TEMPLATES_PATH,
            routes: [AdminsRoutes.ADMIN_CALENDAR_TEMPLATES_PATH],
            roles: roleMappings.ADMINS_FEATURE_ROLE_MAPPING.navigation
          }
        ]
      }
    },
    {
      display: 'Locations',
      icon: 'map-marked-alt',
      linkTo: LocationsRoutes.LOCATIONS_LIST_PATH,
      routes: [LocationsRoutes.LOCATIONS_LIST_PATH],
      roles: roleMappings.LOCATIONS_ROLE_MAPPING.navigation,
      subTrees: {
        [LocationsRoutes.LOCATIONS_CREATE_PATH]: [
          {
            display: 'Add Location',
            linkTo: LocationsRoutes.LOCATIONS_CREATE_PATH,
            routes: [LocationsRoutes.LOCATIONS_CREATE_PATH],
            roles: roleMappings.LOCATIONS_ROLE_MAPPING.common
          }
        ],
        [LocationsRoutes.LOCATIONS_EDIT_PATH]: [
          {
            display: 'Edit Location',
            linkTo: LocationsRoutes.LOCATIONS_EDIT_PATH,
            routes: [LocationsRoutes.LOCATIONS_EDIT_PATH],
            roles: roleMappings.LOCATIONS_ROLE_MAPPING.common
          }
        ],
        [LocationsRoutes.LOCATIONS_VIEW_PATH]: [
          {
            display: 'View Location',
            linkTo: LocationsRoutes.LOCATIONS_VIEW_PATH,
            routes: [LocationsRoutes.LOCATIONS_VIEW_PATH],
            roles: roleMappings.LOCATIONS_ROLE_MAPPING.common
          }
        ]
      }
    },
    {
      display: 'Employers',
      icon: 'building',
      linkTo: EmployersRoutes.EMPLOYERS_LIST_PATH,
      routes: [EmployersRoutes.ROOT_PATH, EmployersRoutes.EMPLOYERS_LIST_PATH],
      roles: roleMappings.EMPLOYER_FEATURE_ROLE_MAPPING.navigation,
      subTrees: {
        // Add Employer Submenu
        [EmployersRoutes.EMPLOYERS_CREATE_PATH]: [
          {
            display: 'Add Employer',
            linkTo: EmployersRoutes.EMPLOYERS_CREATE_PATH,
            routes: [EmployersRoutes.EMPLOYERS_CREATE_PATH],
            roles: roleMappings.EMPLOYER_FEATURE_ROLE_MAPPING.common
          }
        ],
        // Default Submenu
        '/brokers/:brokerId/employers/:employerId': [
          {
            display: (
              <div className="nav-text-employer-name">{employerName}</div>
            ),
            roles: roleMappings.EMPLOYER_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'Employer Details',
            linkTo: EmployersRoutes.EMPLOYERS_DETAILS_PATH,
            routes: [
              EmployersRoutes.EMPLOYERS_EDIT_PATH,
              EmployersRoutes.EMPLOYERS_DETAILS_PATH
            ],
            roles: roleMappings.EMPLOYER_FEATURE_ROLE_MAPPING.common
          },
          {
            display: 'Medical',
            linkTo: PlanRoutes.MedicalRoutes.MEDICAL_LIST_PATH,
            routes: [PlanRoutes.MedicalRoutes.MEDICAL_LIST_PATH],
            roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              // Create Medical Plan Submenu
              [PlanRoutes.MedicalRoutes.MEDICAL_CREATE_PATH]: [
                {
                  display: '1 - Basic',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_CREATE_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_CREATE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: (
                    <span className="cursor-disabled">2 - Coverage</span>
                  ),
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: (
                    <span className="cursor-disabled">3 - Services</span>
                  ),
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: <span className="cursor-disabled">4 - Rx</span>,
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: <span className="cursor-disabled">5 - Rates</span>,
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Default Medical Plan Submenu
              [PlanRoutes.MedicalRoutes.MEDICAL_EDIT_PREFIX]: [
                {
                  display: '1 - Basic',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_EDIT_BASIC_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_EDIT_BASIC_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '2 - Coverage',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_EDIT_COVERAGE_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_EDIT_COVERAGE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '3 - Services',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_EDIT_SERVICES_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_EDIT_SERVICES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '4 - Rx',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_EDIT_RX_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_EDIT_RX_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '5 - Rates',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_EDIT_RATES_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_EDIT_RATES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Default Medical Plan Submenu
              [PlanRoutes.MedicalRoutes.MEDICAL_VIEW_PREFIX]: [
                {
                  display: '1 - Basic',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_VIEW_BASIC_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_VIEW_BASIC_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '2 - Coverage',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_VIEW_COVERAGE_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_VIEW_COVERAGE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '3 - Services',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_VIEW_SERVICES_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_VIEW_SERVICES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '4 - Rx',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_VIEW_RX_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_VIEW_RX_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '5 - Rates',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_VIEW_RATES_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_VIEW_RATES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              [PlanRoutes.MedicalRoutes.MEDICAL_CLONE_PREFIX]: [
                {
                  display: '1 - Basic',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_CLONE_BASIC_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_CLONE_BASIC_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '2 - Coverage',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_CLONE_COVERAGE_PATH,
                  routes: [
                    PlanRoutes.MedicalRoutes.MEDICAL_CLONE_COVERAGE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '3 - Services',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_CLONE_SERVICES_PATH,
                  routes: [
                    PlanRoutes.MedicalRoutes.MEDICAL_CLONE_SERVICES_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '4 - Rx',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_CLONE_RX_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_CLONE_RX_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '5 - Rates',
                  linkTo: PlanRoutes.MedicalRoutes.MEDICAL_CLONE_RATES_PATH,
                  routes: [PlanRoutes.MedicalRoutes.MEDICAL_CLONE_RATES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ]
            }
          },
          {
            display: 'Dental',
            linkTo: PlanRoutes.DentalRoutes.DENTAL_LIST_PATH,
            routes: [PlanRoutes.DentalRoutes.DENTAL_LIST_PATH],
            roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              // Create Dental Plan Submenu
              [PlanRoutes.DentalRoutes.DENTAL_CREATE_PATH]: [
                {
                  display: '1 - Basic',
                  linkTo: PlanRoutes.DentalRoutes.DENTAL_CREATE_PATH,
                  routes: [PlanRoutes.DentalRoutes.DENTAL_CREATE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: (
                    <span className="cursor-disabled">2 - Coverage</span>
                  ),
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: (
                    <span className="cursor-disabled">3 - Services</span>
                  ),
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: <span className="cursor-disabled">4 - Rates</span>,
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Default Dental Plan Submenu
              [PlanRoutes.DentalRoutes.DENTAL_EDIT_PREFIX]: [
                {
                  display: '1 - Basic',
                  linkTo: PlanRoutes.DentalRoutes.DENTAL_EDIT_BASIC_PATH,
                  routes: [PlanRoutes.DentalRoutes.DENTAL_EDIT_BASIC_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '2 - Coverage',
                  linkTo: PlanRoutes.DentalRoutes.DENTAL_EDIT_COVERAGE_PATH,
                  routes: [PlanRoutes.DentalRoutes.DENTAL_EDIT_COVERAGE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '3 - Services',
                  linkTo: PlanRoutes.DentalRoutes.DENTAL_EDIT_SERVICES_PATH,
                  routes: [PlanRoutes.DentalRoutes.DENTAL_EDIT_SERVICES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '4 - Rates',
                  linkTo: PlanRoutes.DentalRoutes.DENTAL_EDIT_RATES_PATH,
                  routes: [PlanRoutes.DentalRoutes.DENTAL_EDIT_RATES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Default Dental Plan Submenu
              [PlanRoutes.DentalRoutes.DENTAL_VIEW_PREFIX]: [
                {
                  display: '1 - Basic',
                  linkTo: PlanRoutes.DentalRoutes.DENTAL_VIEW_BASIC_PATH,
                  routes: [PlanRoutes.DentalRoutes.DENTAL_VIEW_BASIC_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '2 - Coverage',
                  linkTo: PlanRoutes.DentalRoutes.DENTAL_VIEW_COVERAGE_PATH,
                  routes: [PlanRoutes.DentalRoutes.DENTAL_VIEW_COVERAGE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '3 - Services',
                  linkTo: PlanRoutes.DentalRoutes.DENTAL_VIEW_SERVICES_PATH,
                  routes: [PlanRoutes.DentalRoutes.DENTAL_VIEW_SERVICES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '4 - Rates',
                  linkTo: PlanRoutes.DentalRoutes.DENTAL_VIEW_RATES_PATH,
                  routes: [PlanRoutes.DentalRoutes.DENTAL_VIEW_RATES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              [PlanRoutes.DentalRoutes.DENTAL_CLONE_PREFIX]: [
                {
                  display: '1 - Basic',
                  linkTo: PlanRoutes.DentalRoutes.DENTAL_CLONE_BASIC_PATH,
                  routes: [PlanRoutes.DentalRoutes.DENTAL_CLONE_BASIC_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '2 - Coverage',
                  linkTo: PlanRoutes.DentalRoutes.DENTAL_CLONE_COVERAGE_PATH,
                  routes: [PlanRoutes.DentalRoutes.DENTAL_CLONE_COVERAGE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '3 - Services',
                  linkTo: PlanRoutes.DentalRoutes.DENTAL_CLONE_SERVICES_PATH,
                  routes: [PlanRoutes.DentalRoutes.DENTAL_CLONE_SERVICES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '4 - Rates',
                  linkTo: PlanRoutes.DentalRoutes.DENTAL_CLONE_RATES_PATH,
                  routes: [PlanRoutes.DentalRoutes.DENTAL_CLONE_RATES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ]
            }
          },
          {
            display: 'Vision',
            linkTo: PlanRoutes.VisionRoutes.VISION_LIST_PATH,
            routes: [PlanRoutes.VisionRoutes.VISION_LIST_PATH],
            roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              // Create Vision Plan Submenu
              [PlanRoutes.VisionRoutes.VISION_CREATE_PATH]: [
                {
                  display: '1 - Basic',
                  linkTo: PlanRoutes.VisionRoutes.VISION_CREATE_PATH,
                  routes: [PlanRoutes.VisionRoutes.VISION_CREATE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: (
                    <span className="cursor-disabled">2 - Services</span>
                  ),
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: <span className="cursor-disabled">3 - Rates</span>,
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Default Vision Plan Submenu
              [PlanRoutes.VisionRoutes.VISION_EDIT_PREFIX]: [
                {
                  display: '1 - Basic',
                  linkTo: PlanRoutes.VisionRoutes.VISION_EDIT_BASIC_PATH,
                  routes: [PlanRoutes.VisionRoutes.VISION_EDIT_BASIC_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '2 - Services',
                  linkTo: PlanRoutes.VisionRoutes.VISION_EDIT_SERVICES_PATH,
                  routes: [PlanRoutes.VisionRoutes.VISION_EDIT_SERVICES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '3 - Rates',
                  linkTo: PlanRoutes.VisionRoutes.VISION_EDIT_RATES_PATH,
                  routes: [PlanRoutes.VisionRoutes.VISION_EDIT_RATES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Default Vision Plan Submenu
              [PlanRoutes.VisionRoutes.VISION_VIEW_PREFIX]: [
                {
                  display: '1 - Basic',
                  linkTo: PlanRoutes.VisionRoutes.VISION_VIEW_BASIC_PATH,
                  routes: [PlanRoutes.VisionRoutes.VISION_VIEW_BASIC_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '2 - Services',
                  linkTo: PlanRoutes.VisionRoutes.VISION_VIEW_SERVICES_PATH,
                  routes: [PlanRoutes.VisionRoutes.VISION_VIEW_SERVICES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '3 - Rates',
                  linkTo: PlanRoutes.VisionRoutes.VISION_VIEW_RATES_PATH,
                  routes: [PlanRoutes.VisionRoutes.VISION_VIEW_RATES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              [PlanRoutes.VisionRoutes.VISION_CLONE_PREFIX]: [
                {
                  display: '1 - Basic',
                  linkTo: PlanRoutes.VisionRoutes.VISION_CLONE_BASIC_PATH,
                  routes: [PlanRoutes.VisionRoutes.VISION_CLONE_BASIC_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '2 - Services',
                  linkTo: PlanRoutes.VisionRoutes.VISION_CLONE_SERVICES_PATH,
                  routes: [PlanRoutes.VisionRoutes.VISION_CLONE_SERVICES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '3 - Rates',
                  linkTo: PlanRoutes.VisionRoutes.VISION_CLONE_RATES_PATH,
                  routes: [PlanRoutes.VisionRoutes.VISION_CLONE_RATES_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ]
            }
          },
          {
            display: 'Tax-Advantaged Accounts',
            linkTo: PlanRoutes.TaxAdvantagedRoutes.TAX_ADVANTAGED_ACCOUNT_PATH,
            routes: [
              PlanRoutes.TaxAdvantagedRoutes.TAX_ADVANTAGED_ACCOUNT_PATH
            ],
            roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              [PlanRoutes.TaxAdvantagedRoutes.TAX_ADVANTAGED_ACCOUNT_PATH]: [
                {
                  display: 'Spending',
                  linkTo: PlanRoutes.SpendingRoutes.SPENDING_LIST_PATH,
                  routes: [PlanRoutes.SpendingRoutes.SPENDING_LIST_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation,
                  subTrees: {
                    // Create HSA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_HSA_CREATE_PATH]: [
                      {
                        display: 'Add HSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes.SPENDING_HSA_CREATE_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes.SPENDING_HSA_CREATE_PATH
                        ],
                        roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                      }
                    ],
                    // Edit HSA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_HSA_EDIT_PATH]: [
                      {
                        display: 'Edit HSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes.SPENDING_HSA_EDIT_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes.SPENDING_HSA_EDIT_PATH
                        ],
                        roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                      }
                    ],
                    // View HSA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_HSA_VIEW_PATH]: [
                      {
                        display: 'View HSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes.SPENDING_HSA_VIEW_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes.SPENDING_HSA_VIEW_PATH
                        ],
                        roles:
                          roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                      }
                    ],
                    //Clone HSA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_HSA_CLONE_BASIC_PATH]: [
                      {
                        display: 'Add HSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes
                            .SPENDING_HSA_CLONE_BASIC_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes
                            .SPENDING_HSA_CLONE_BASIC_PATH
                        ],
                        roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                      }
                    ],
                    // Create Medical FSA Plan Submenu
                    [PlanRoutes.SpendingRoutes
                      .SPENDING_MEDICAL_FSA_CREATE_PATH]: [
                      {
                        display: 'Add Medical FSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes
                            .SPENDING_MEDICAL_FSA_CREATE_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes
                            .SPENDING_MEDICAL_FSA_CREATE_PATH
                        ],
                        roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                      }
                    ],
                    // Edit Medical FSA Plan Submenu
                    [PlanRoutes.SpendingRoutes
                      .SPENDING_MEDICAL_FSA_EDIT_PATH]: [
                      {
                        display: 'Edit Medical FSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes
                            .SPENDING_MEDICAL_FSA_EDIT_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes
                            .SPENDING_MEDICAL_FSA_EDIT_PATH
                        ],
                        roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                      }
                    ],
                    // View Medical FSA Plan Submenu
                    [PlanRoutes.SpendingRoutes
                      .SPENDING_MEDICAL_FSA_VIEW_PATH]: [
                      {
                        display: 'View Medical FSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes
                            .SPENDING_MEDICAL_FSA_VIEW_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes
                            .SPENDING_MEDICAL_FSA_VIEW_PATH
                        ],
                        roles:
                          roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                      }
                    ],
                    // Clone Medical FSA Plan Submenu
                    [PlanRoutes.SpendingRoutes
                      .SPENDING_MEDICAL_FSA_CLONE_BASIC_PATH]: [
                      {
                        display: 'Add Medical FSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes
                            .SPENDING_MEDICAL_FSA_CLONE_BASIC_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes
                            .SPENDING_MEDICAL_FSA_CLONE_BASIC_PATH
                        ],
                        roles:
                          roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                      }
                    ],
                    // Create DCAPFSA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_DCAPFSA_CREATE_PATH]: [
                      {
                        display: 'Add DCAPFSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes
                            .SPENDING_DCAPFSA_CREATE_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes.SPENDING_DCAPFSA_CREATE_PATH
                        ],
                        roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                      }
                    ],
                    // Edit DCAPFSA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_DCAPFSA_EDIT_PATH]: [
                      {
                        display: 'Edit DCAPFSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes.SPENDING_DCAPFSA_EDIT_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes.SPENDING_DCAPFSA_EDIT_PATH
                        ],
                        roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                      }
                    ],
                    // View DCAPFSA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_DCAPFSA_VIEW_PATH]: [
                      {
                        display: 'View DCAPFSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes.SPENDING_DCAPFSA_VIEW_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes.SPENDING_DCAPFSA_VIEW_PATH
                        ],
                        roles:
                          roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                      }
                    ],
                    // Clone DCAPFSA Plan Submenu
                    [PlanRoutes.SpendingRoutes
                      .SPENDING_DCAPFSA_CLONE_BASIC_PATH]: [
                      {
                        display: 'Add DCAPFSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes
                            .SPENDING_DCAPFSA_CLONE_BASIC_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes
                            .SPENDING_DCAPFSA_CLONE_BASIC_PATH
                        ],
                        roles:
                          roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                      }
                    ],
                    // Create Limited Purpose FSA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_LPFSA_CREATE_PATH]: [
                      {
                        display: 'Add Limited FSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes.SPENDING_LPFSA_CREATE_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes.SPENDING_LPFSA_CREATE_PATH
                        ],
                        roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                      }
                    ],
                    // Edit Limited Purpose FSA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_LPFSA_EDIT_PATH]: [
                      {
                        display: 'Edit Limited FSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes.SPENDING_LPFSA_EDIT_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes.SPENDING_LPFSA_EDIT_PATH
                        ],
                        roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                      }
                    ],
                    // View Limited Purpose FSA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_LPFSA_VIEW_PATH]: [
                      {
                        display: 'View Limited FSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes.SPENDING_LPFSA_VIEW_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes.SPENDING_LPFSA_VIEW_PATH
                        ],
                        roles:
                          roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                      }
                    ],
                    // Clone Limited Purpose FSA Plan Submenu
                    [PlanRoutes.SpendingRoutes
                      .SPENDING_LPFSA_CLONE_BASIC_PATH]: [
                      {
                        display: 'Add Limited FSA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes
                            .SPENDING_LPFSA_CLONE_BASIC_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes
                            .SPENDING_LPFSA_CLONE_BASIC_PATH
                        ],
                        roles:
                          roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                      }
                    ],
                    // Create HRA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_HRA_CREATE_PATH]: [
                      {
                        display: 'Add HRA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes.SPENDING_HRA_CREATE_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes.SPENDING_HRA_CREATE_PATH
                        ],
                        roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                      }
                    ],
                    // Edit HRA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_HRA_EDIT_PATH]: [
                      {
                        display: 'Edit HRA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes.SPENDING_HRA_EDIT_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes.SPENDING_HRA_EDIT_PATH
                        ],
                        roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                      }
                    ],
                    // View HRA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_HRA_VIEW_PATH]: [
                      {
                        display: 'View HRA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes.SPENDING_HRA_VIEW_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes.SPENDING_HRA_VIEW_PATH
                        ],
                        roles:
                          roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                      }
                    ],
                    // Clone HRA Plan Submenu
                    [PlanRoutes.SpendingRoutes.SPENDING_HRA_CLONE_BASIC_PATH]: [
                      {
                        display: 'Add HRA Plan',
                        linkTo:
                          PlanRoutes.SpendingRoutes
                            .SPENDING_HRA_CLONE_BASIC_PATH,
                        routes: [
                          PlanRoutes.SpendingRoutes
                            .SPENDING_HRA_CLONE_BASIC_PATH
                        ],
                        roles:
                          roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                      }
                    ]
                  }
                },
                {
                  display: 'Commuter',
                  linkTo: PlanRoutes.CommuterRoutes.COMMUTER_LIST_PATH,
                  routes: [PlanRoutes.CommuterRoutes.COMMUTER_LIST_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation,
                  subTrees: {
                    // Create Commuter Plan Submenu
                    [PlanRoutes.CommuterRoutes.COMMUTER_LIST_PATH]: [
                      {
                        display: 'Add Commuter Plan',
                        linkTo: PlanRoutes.CommuterRoutes.COMMUTER_CREATE_PATH,
                        routes: [
                          PlanRoutes.CommuterRoutes.COMMUTER_CREATE_PATH
                        ],
                        roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                      }
                    ],
                    // Edit Commuter Plan Submenu
                    [PlanRoutes.CommuterRoutes.COMMUTER_EDIT_PATH]: [
                      {
                        display: 'Edit Commuter Plan',
                        linkTo: PlanRoutes.CommuterRoutes.COMMUTER_EDIT_PATH,
                        routes: [PlanRoutes.CommuterRoutes.COMMUTER_EDIT_PATH],
                        roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                      }
                    ],
                    // View Commuter Plan Submenu
                    [PlanRoutes.CommuterRoutes.COMMUTER_VIEW_PATH]: [
                      {
                        display: 'View Commuter Plan',
                        linkTo: PlanRoutes.CommuterRoutes.COMMUTER_VIEW_PATH,
                        routes: [PlanRoutes.CommuterRoutes.COMMUTER_VIEW_PATH],
                        roles:
                          roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                      }
                    ],
                    // Clone Commuter Plan Submenu
                    [PlanRoutes.CommuterRoutes.COMMUTER_CLONE_PATH]: [
                      {
                        display: 'Add Commuter Plan',
                        linkTo: PlanRoutes.CommuterRoutes.COMMUTER_CLONE_PATH,
                        routes: [PlanRoutes.CommuterRoutes.COMMUTER_CLONE_PATH],
                        roles:
                          roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                      }
                    ],
                    // Clone Commuter Plan Submenu
                    [PlanRoutes.CommuterRoutes.COMMUTER_CLONE_PATH]: [
                      {
                        display: 'Add Commuter Plan',
                        linkTo: PlanRoutes.CommuterRoutes.COMMUTER_CLONE_PATH,
                        routes: [PlanRoutes.CommuterRoutes.COMMUTER_CLONE_PATH],
                        roles:
                          roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            display: 'Life & Disability',
            linkTo: PlanRoutes.LifeRoutes.LIFE_LIST_PATH,
            routes: [PlanRoutes.LifeRoutes.LIFE_LIST_PATH],
            roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              // Create Basic Life Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_CREATE_PATH]: [
                {
                  display: 'Add Basic Life Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_CREATE_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_CREATE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Basic Life Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_EDIT_PATH]: [
                {
                  display: 'Edit Basic Life Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_EDIT_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_EDIT_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Basic Life Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_VIEW_PATH]: [
                {
                  display: 'View Basic Life Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_VIEW_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_VIEW_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Basic Life Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_CLONE_PATH]: [
                {
                  display: 'Add Basic Life Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_CLONE_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_CLONE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Create Basic AD&D Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_BASIC_ADD_CREATE_PATH]: [
                {
                  display: 'Add Basic AD&D Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_BASIC_ADD_CREATE_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_BASIC_ADD_CREATE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Basic AD&D Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_BASIC_ADD_EDIT_PATH]: [
                {
                  display: 'Edit Basic AD&D Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_BASIC_ADD_EDIT_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_BASIC_ADD_EDIT_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Basic AD&D Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_BASIC_ADD_VIEW_PATH]: [
                {
                  display: 'View Basic AD&D Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_BASIC_ADD_VIEW_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_BASIC_ADD_VIEW_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Basic AD&D Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_BASIC_ADD_CLONE_PATH]: [
                {
                  display: 'Add Basic AD&D Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_BASIC_ADD_CLONE_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_BASIC_ADD_CLONE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Create Basic Life and AD&D Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_CREATE_PATH]: [
                {
                  display: 'Add Life AD&D Plan',
                  linkTo:
                    PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_CREATE_PATH,
                  routes: [
                    PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Basic Life and AD&D Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_EDIT_PATH]: [
                {
                  display: 'Edit Life AD&D Plan',
                  linkTo:
                    PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_EDIT_PATH,
                  routes: [
                    PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_EDIT_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Basic Life and AD&D Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_VIEW_PATH]: [
                {
                  display: 'View Life AD&D Plan',
                  linkTo:
                    PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_VIEW_PATH,
                  routes: [
                    PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_VIEW_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Basic Life and AD&D Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_CLONE_PATH]: [
                {
                  display: 'Add Life AD&D Plan',
                  linkTo:
                    PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_CLONE_PATH,
                  routes: [
                    PlanRoutes.LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_CLONE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Create STD Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_STD_CREATE_PATH]: [
                {
                  display: 'Add STD Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_STD_CREATE_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_STD_CREATE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit STD Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_STD_EDIT_PATH]: [
                {
                  display: 'Edit STD Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_STD_EDIT_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_STD_EDIT_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View STD Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_STD_VIEW_PATH]: [
                {
                  display: 'View STD Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_STD_VIEW_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_STD_VIEW_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone STD Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_STD_CLONE_PATH]: [
                {
                  display: 'Add STD Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_STD_CLONE_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_STD_CLONE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Create LTD Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_LTD_CREATE_PATH]: [
                {
                  display: 'Add LTD Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_LTD_CREATE_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_LTD_CREATE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit LTD Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_LTD_EDIT_PATH]: [
                {
                  display: 'Edit LTD Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_LTD_EDIT_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_LTD_EDIT_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View LTD Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_LTD_VIEW_PATH]: [
                {
                  display: 'View LTD Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_LTD_VIEW_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_LTD_VIEW_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone LTD Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_LTD_CLONE_PATH]: [
                {
                  display: 'Add LTD Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_LTD_CLONE_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_LTD_CLONE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Create Voluntary Life Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_VOL_LIFE_CREATE_PATH]: [
                {
                  display: 'Add Voluntary Life Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_VOL_LIFE_CREATE_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_VOL_LIFE_CREATE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Voluntary Life Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_VOL_LIFE_EDIT_PATH]: [
                {
                  display: 'Edit Voluntary Life Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_VOL_LIFE_EDIT_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_VOL_LIFE_EDIT_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Voluntary Life Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_VOL_LIFE_VIEW_PATH]: [
                {
                  display: 'View Voluntary Life Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_VOL_LIFE_VIEW_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_VOL_LIFE_VIEW_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Edit Voluntary Life Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_VOL_LIFE_CLONE_PATH]: [
                {
                  display: 'Edit Voluntary Life Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_VOL_LIFE_CLONE_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_VOL_LIFE_CLONE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Create Voluntary AD&D Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_VOL_ADD_CREATE_PATH]: [
                {
                  display: 'Add Voluntary AD&D Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_VOL_ADD_CREATE_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_VOL_ADD_CREATE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Voluntary Life Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_VOL_ADD_EDIT_PATH]: [
                {
                  display: 'Edit Voluntary AD&D Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_VOL_ADD_EDIT_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_VOL_ADD_EDIT_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Voluntary Life Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_VOL_ADD_VIEW_PATH]: [
                {
                  display: 'View Voluntary AD&D Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_VOL_ADD_VIEW_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_VOL_ADD_VIEW_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Voluntary Life Plan Submenu
              [PlanRoutes.LifeRoutes.LIFE_VOL_ADD_CLONE_PATH]: [
                {
                  display: 'Add Voluntary AD&D Plan',
                  linkTo: PlanRoutes.LifeRoutes.LIFE_VOL_ADD_CLONE_PATH,
                  routes: [PlanRoutes.LifeRoutes.LIFE_VOL_ADD_CLONE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ]
            }
          },
          {
            display: 'Retirement',
            linkTo: PlanRoutes.RetirementRoutes.RETIREMENT_LIST_PATH,
            routes: [PlanRoutes.RetirementRoutes.RETIREMENT_LIST_PATH],
            roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              // Create Retirement Plan Submenu
              [PlanRoutes.RetirementRoutes.RETIREMENT_CREATE_PATH]: [
                {
                  display: 'Add Retirement Plan',
                  linkTo: PlanRoutes.RetirementRoutes.RETIREMENT_CREATE_PATH,
                  routes: [PlanRoutes.RetirementRoutes.RETIREMENT_CREATE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Retirement Plan Submenu
              [PlanRoutes.RetirementRoutes.RETIREMENT_EDIT_PATH]: [
                {
                  display: 'Edit Retirement Plan',
                  linkTo: PlanRoutes.RetirementRoutes.RETIREMENT_EDIT_PATH,
                  routes: [PlanRoutes.RetirementRoutes.RETIREMENT_EDIT_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Retirement Plan Submenu
              [PlanRoutes.RetirementRoutes.RETIREMENT_VIEW_PATH]: [
                {
                  display: 'View Retirement Plan',
                  linkTo: PlanRoutes.RetirementRoutes.RETIREMENT_VIEW_PATH,
                  routes: [PlanRoutes.RetirementRoutes.RETIREMENT_VIEW_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Retirement Plan Submenu
              [PlanRoutes.RetirementRoutes.RETIREMENT_CLONE_PATH]: [
                {
                  display: 'Add Retirement Plan',
                  linkTo: PlanRoutes.RetirementRoutes.RETIREMENT_CLONE_PATH,
                  routes: [PlanRoutes.RetirementRoutes.RETIREMENT_CLONE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ]
            }
          },
          {
            display: 'Telehealth/Rx Delivery',
            linkTo:
              PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_LIST_PATH,
            routes: [
              PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_LIST_PATH
            ],
            roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              // Create Telehealth Plan Submenu
              [PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_TELEHEALTH_CREATE_PATH]: [
                {
                  display: 'Add Telehealth',
                  linkTo:
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_TELEHEALTH_CREATE_PATH,
                  routes: [
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_TELEHEALTH_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Telehealth Plan Submenu
              [PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_TELEHEALTH_EDIT_PATH]: [
                {
                  display: 'Edit Telehealth',
                  linkTo:
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_TELEHEALTH_EDIT_PATH,
                  routes: [
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_TELEHEALTH_EDIT_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Telehealth Plan Submenu
              [PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_TELEHEALTH_VIEW_PATH]: [
                {
                  display: 'View Telehealth',
                  linkTo:
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_TELEHEALTH_VIEW_PATH,
                  routes: [
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_TELEHEALTH_VIEW_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Telehealth Plan Submenu
              [PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_TELEHEALTH_CLONE_PATH]: [
                {
                  display: 'Add Telehealth',
                  linkTo:
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_TELEHEALTH_CLONE_PATH,
                  routes: [
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_TELEHEALTH_CLONE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Create Rx Delivery Plan Submenu
              [PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_RX_DELIVERY_CREATE_PATH]: [
                {
                  display: 'Add Rx Delivery',
                  linkTo:
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_DELIVERY_CREATE_PATH,
                  routes: [
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_DELIVERY_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Rx Delivery Plan Submenu
              [PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_RX_DELIVERY_EDIT_PATH]: [
                {
                  display: 'Edit Rx Delivery',
                  linkTo:
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_DELIVERY_EDIT_PATH,
                  routes: [
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_DELIVERY_EDIT_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Rx Delivery Plan Submenu
              [PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_RX_DELIVERY_VIEW_PATH]: [
                {
                  display: 'View Rx Delivery',
                  linkTo:
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_DELIVERY_VIEW_PATH,
                  routes: [
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_DELIVERY_VIEW_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Rx Delivery Plan Submenu
              [PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_RX_DELIVERY_CLONE_PATH]: [
                {
                  display: 'Add Rx Delivery',
                  linkTo:
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_DELIVERY_CLONE_PATH,
                  routes: [
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_DELIVERY_CLONE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Create Rx Coupons Plan Submenu
              [PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_RX_COUPONS_CREATE_PATH]: [
                {
                  display: 'Add Rx Coupons',
                  linkTo:
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_COUPONS_CREATE_PATH,
                  routes: [
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_COUPONS_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Rx Delivery Plan Submenu
              [PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_RX_COUPONS_EDIT_PATH]: [
                {
                  display: 'Edit Rx Coupons',
                  linkTo:
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_COUPONS_EDIT_PATH,
                  routes: [
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_COUPONS_EDIT_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Rx Delivery Plan Submenu
              [PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_RX_COUPONS_VIEW_PATH]: [
                {
                  display: 'View Rx Coupons',
                  linkTo:
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_COUPONS_VIEW_PATH,
                  routes: [
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_COUPONS_VIEW_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Rx Delivery Plan Submenu
              [PlanRoutes.TeleHealthRxDeliveryRoutes
                .TELEHEALTH_RX_DELIVERY_RX_COUPONS_CLONE_PATH]: [
                {
                  display: 'Add Rx Coupons',
                  linkTo:
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_COUPONS_CLONE_PATH,
                  routes: [
                    PlanRoutes.TeleHealthRxDeliveryRoutes
                      .TELEHEALTH_RX_DELIVERY_RX_COUPONS_CLONE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ]
            }
          },
          {
            display: 'Wellbeing',
            linkTo: PlanRoutes.WellbeingRoutes.WELLBEING_LIST_PATH,
            routes: [PlanRoutes.WellbeingRoutes.WELLBEING_LIST_PATH],
            roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              // Create Meditation Plan Submenu
              [PlanRoutes.WellbeingRoutes.WELLBEING_MEDITATION_CREATE_PATH]: [
                {
                  display: 'Add Meditation Plan',
                  linkTo:
                    PlanRoutes.WellbeingRoutes.WELLBEING_MEDITATION_CREATE_PATH,
                  routes: [
                    PlanRoutes.WellbeingRoutes.WELLBEING_MEDITATION_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Meditation Plan Submenu
              [PlanRoutes.WellbeingRoutes.WELLBEING_MEDITATION_EDIT_PATH]: [
                {
                  display: 'Edit Meditation Plan',
                  linkTo:
                    PlanRoutes.WellbeingRoutes.WELLBEING_MEDITATION_EDIT_PATH,
                  routes: [
                    PlanRoutes.WellbeingRoutes.WELLBEING_MEDITATION_EDIT_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Meditation Plan Submenu
              [PlanRoutes.WellbeingRoutes.WELLBEING_MEDITATION_VIEW_PATH]: [
                {
                  display: 'View Meditation Plan',
                  linkTo:
                    PlanRoutes.WellbeingRoutes.WELLBEING_MEDITATION_VIEW_PATH,
                  routes: [
                    PlanRoutes.WellbeingRoutes.WELLBEING_MEDITATION_VIEW_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Meditation Plan Submenu
              [PlanRoutes.WellbeingRoutes.WELLBEING_MEDITATION_CLONE_PATH]: [
                {
                  display: 'Add Meditation Plan',
                  linkTo:
                    PlanRoutes.WellbeingRoutes.WELLBEING_MEDITATION_CLONE_PATH,
                  routes: [
                    PlanRoutes.WellbeingRoutes.WELLBEING_MEDITATION_CLONE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Create mental health Plan Submenu
              [PlanRoutes.WellbeingRoutes
                .WELLBEING_MENTAL_HEALTH_CREATE_PATH]: [
                {
                  display: 'Add Mental Health Plan',
                  linkTo:
                    PlanRoutes.WellbeingRoutes
                      .WELLBEING_MENTAL_HEALTH_CREATE_PATH,
                  routes: [
                    PlanRoutes.WellbeingRoutes
                      .WELLBEING_MENTAL_HEALTH_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit mental health Plan Submenu
              [PlanRoutes.WellbeingRoutes.WELLBEING_MENTAL_HEALTH_EDIT_PATH]: [
                {
                  display: 'Edit Mental Health Plan',
                  linkTo:
                    PlanRoutes.WellbeingRoutes
                      .WELLBEING_MENTAL_HEALTH_EDIT_PATH,
                  routes: [
                    PlanRoutes.WellbeingRoutes.WELLBEING_MENTAL_HEALTH_EDIT_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View mental health Plan Submenu
              [PlanRoutes.WellbeingRoutes.WELLBEING_MENTAL_HEALTH_VIEW_PATH]: [
                {
                  display: 'View Mental Health Plan',
                  linkTo:
                    PlanRoutes.WellbeingRoutes
                      .WELLBEING_MENTAL_HEALTH_VIEW_PATH,
                  routes: [
                    PlanRoutes.WellbeingRoutes.WELLBEING_MENTAL_HEALTH_VIEW_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone mental health Plan Submenu
              [PlanRoutes.WellbeingRoutes.WELLBEING_MENTAL_HEALTH_CLONE_PATH]: [
                {
                  display: 'Add Mental Health Plan',
                  linkTo:
                    PlanRoutes.WellbeingRoutes
                      .WELLBEING_MENTAL_HEALTH_CLONE_PATH,
                  routes: [
                    PlanRoutes.WellbeingRoutes.WELLBEING_MENTAL_HEALTH_CLONE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Create wellness Plan Submenu
              [PlanRoutes.WellbeingRoutes
                .WELLBEING_WELLNESS_REIMBURSEMENT_CREATE_PATH]: [
                {
                  display: 'Add Wellness Reimbursement Plan',
                  linkTo:
                    PlanRoutes.WellbeingRoutes
                      .WELLBEING_WELLNESS_REIMBURSEMENT_CREATE_PATH,
                  routes: [
                    PlanRoutes.WellbeingRoutes
                      .WELLBEING_WELLNESS_REIMBURSEMENT_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit wellness Plan Submenu
              [PlanRoutes.WellbeingRoutes
                .WELLBEING_WELLNESS_REIMBURSEMENT_EDIT_PATH]: [
                {
                  display: 'Edit Wellness Reimbursement Plan',
                  linkTo:
                    PlanRoutes.WellbeingRoutes
                      .WELLBEING_WELLNESS_REIMBURSEMENT_EDIT_PATH,
                  routes: [
                    PlanRoutes.WellbeingRoutes
                      .WELLBEING_WELLNESS_REIMBURSEMENT_EDIT_PATH
                  ]
                }
              ],
              // View wellness Plan Submenu
              [PlanRoutes.WellbeingRoutes
                .WELLBEING_WELLNESS_REIMBURSEMENT_VIEW_PATH]: [
                {
                  display: 'View Wellness Reimbursement Plan',
                  linkTo:
                    PlanRoutes.WellbeingRoutes
                      .WELLBEING_WELLNESS_REIMBURSEMENT_VIEW_PATH,
                  routes: [
                    PlanRoutes.WellbeingRoutes
                      .WELLBEING_WELLNESS_REIMBURSEMENT_VIEW_PATH
                  ]
                }
              ],
              // Clone wellness Plan Submenu
              [PlanRoutes.WellbeingRoutes
                .WELLBEING_WELLNESS_REIMBURSEMENT_CLONE_PATH]: [
                {
                  display: 'Add Wellness Reimbursement Plan',
                  linkTo:
                    PlanRoutes.WellbeingRoutes
                      .WELLBEING_WELLNESS_REIMBURSEMENT_CLONE_PATH,
                  routes: [
                    PlanRoutes.WellbeingRoutes
                      .WELLBEING_WELLNESS_REIMBURSEMENT_CLONE_PATH
                  ]
                }
              ]
            }
          },
          {
            display: 'Work/Life',
            linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_LIST_PATH,
            routes: [PlanRoutes.WorkLifeRoutes.WORK_LIFE_LIST_PATH],
            roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              // Create Backup Childcare Plan Submenu
              [PlanRoutes.WorkLifeRoutes
                .WORK_LIFE_BACKUP_CHILDCARE_CREATE_PATH]: [
                {
                  display: 'Add Backup Childcare',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_BACKUP_CHILDCARE_CREATE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_BACKUP_CHILDCARE_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Backup Childcare Plan Submenu
              [PlanRoutes.WorkLifeRoutes
                .WORK_LIFE_BACKUP_CHILDCARE_EDIT_PATH]: [
                {
                  display: 'Edit Backup Childcare',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_BACKUP_CHILDCARE_EDIT_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_BACKUP_CHILDCARE_EDIT_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Backup Childcare Plan Submenu
              [PlanRoutes.WorkLifeRoutes
                .WORK_LIFE_BACKUP_CHILDCARE_VIEW_PATH]: [
                {
                  display: 'View Backup Childcare',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_BACKUP_CHILDCARE_VIEW_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_BACKUP_CHILDCARE_VIEW_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Backup Childcare Plan Submenu
              [PlanRoutes.WorkLifeRoutes
                .WORK_LIFE_BACKUP_CHILDCARE_CLONE_PATH]: [
                {
                  display: 'Add Backup Childcare',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_BACKUP_CHILDCARE_CLONE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_BACKUP_CHILDCARE_CLONE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Create Bereavement Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_CREATE_PATH]: [
                {
                  display: 'Add Bereavement',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_CREATE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Bereavement Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_EDIT_PATH]: [
                {
                  display: 'Edit Bereavement',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_EDIT_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_EDIT_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Bereavement Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_VIEW_PATH]: [
                {
                  display: 'View Bereavement',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_VIEW_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_VIEW_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Bereavement Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_CLONE_PATH]: [
                {
                  display: 'Add Bereavement',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_CLONE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_CLONE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Create Fertility Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_FERTILITY_CREATE_PATH]: [
                {
                  display: 'Add Fertility',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_FERTILITY_CREATE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_FERTILITY_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Fertility Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_FERTILITY_EDIT_PATH]: [
                {
                  display: 'Edit Fertility',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_FERTILITY_EDIT_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_FERTILITY_EDIT_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Fertility Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_FERTILITY_VIEW_PATH]: [
                {
                  display: 'View Fertility',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_FERTILITY_VIEW_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_FERTILITY_VIEW_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Fertility Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_FERTILITY_CLONE_PATH]: [
                {
                  display: 'Add Fertility',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_FERTILITY_CLONE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_FERTILITY_CLONE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Create Parental Leave Plan Submenu
              [PlanRoutes.WorkLifeRoutes
                .WORK_LIFE_PARENTAL_LEAVE_CREATE_PATH]: [
                {
                  display: 'Add Parental Leave',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_PARENTAL_LEAVE_CREATE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_PARENTAL_LEAVE_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Parental Leave Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_PARENTAL_LEAVE_EDIT_PATH]: [
                {
                  display: 'Edit Parental Leave',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_PARENTAL_LEAVE_EDIT_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_PARENTAL_LEAVE_EDIT_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Parental Leave Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_PARENTAL_LEAVE_VIEW_PATH]: [
                {
                  display: 'View Parental Leave',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_PARENTAL_LEAVE_VIEW_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_PARENTAL_LEAVE_VIEW_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Parental Leave Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_PARENTAL_LEAVE_CLONE_PATH]: [
                {
                  display: 'Add Parental Leave',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_PARENTAL_LEAVE_CLONE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_PARENTAL_LEAVE_CLONE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Create Flexible Working Hours Plan Submenu
              [PlanRoutes.WorkLifeRoutes
                .WORK_LIFE_FLEXIBLE_WORKING_HOURS_CREATE_PATH]: [
                {
                  display: 'Add Flexible Working Hours',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_FLEXIBLE_WORKING_HOURS_CREATE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_FLEXIBLE_WORKING_HOURS_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Flexible Working Hours Plan Submenu
              [PlanRoutes.WorkLifeRoutes
                .WORK_LIFE_FLEXIBLE_WORKING_HOURS_EDIT_PATH]: [
                {
                  display: 'Edit Flexible Working Hours',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_FLEXIBLE_WORKING_HOURS_EDIT_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_FLEXIBLE_WORKING_HOURS_EDIT_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Flexible Working Hours Plan Submenu
              [PlanRoutes.WorkLifeRoutes
                .WORK_LIFE_FLEXIBLE_WORKING_HOURS_VIEW_PATH]: [
                {
                  display: 'View Flexible Working Hours',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_FLEXIBLE_WORKING_HOURS_VIEW_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_FLEXIBLE_WORKING_HOURS_VIEW_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Flexible Working Hours Plan Submenu
              [PlanRoutes.WorkLifeRoutes
                .WORK_LIFE_FLEXIBLE_WORKING_HOURS_CLONE_PATH]: [
                {
                  display: 'Add Flexible Working Hours',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_FLEXIBLE_WORKING_HOURS_CLONE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes
                      .WORK_LIFE_FLEXIBLE_WORKING_HOURS_CLONE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Create Holiday Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_HOLIDAY_CREATE_PATH]: [
                {
                  display: 'Add Holiday',
                  linkTo:
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_HOLIDAY_CREATE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_HOLIDAY_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Holiday Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_HOLIDAY_EDIT_PATH]: [
                {
                  display: 'Edit Holiday',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_HOLIDAY_EDIT_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_HOLIDAY_EDIT_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Holiday Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_HOLIDAY_VIEW_PATH]: [
                {
                  display: 'View Holiday',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_HOLIDAY_VIEW_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_HOLIDAY_VIEW_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Holiday Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_HOLIDAY_CLONE_PATH]: [
                {
                  display: 'Add Holiday',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_HOLIDAY_CLONE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_HOLIDAY_CLONE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Create Paid Time Off Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_PTO_CREATE_PATH]: [
                {
                  display: 'Add Paid Time Off',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_PTO_CREATE_PATH,
                  routes: [PlanRoutes.WorkLifeRoutes.WORK_LIFE_PTO_CREATE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Paid Time Off Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_PTO_EDIT_PATH]: [
                {
                  display: 'Edit Paid Time Off',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_PTO_EDIT_PATH,
                  routes: [PlanRoutes.WorkLifeRoutes.WORK_LIFE_PTO_EDIT_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Paid Time Off Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_PTO_VIEW_PATH]: [
                {
                  display: 'View Paid Time Off',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_PTO_VIEW_PATH,
                  routes: [PlanRoutes.WorkLifeRoutes.WORK_LIFE_PTO_VIEW_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Paid Time Off Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_PTO_CLONE_PATH]: [
                {
                  display: 'Add Paid Time Off',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_PTO_CLONE_PATH,
                  routes: [PlanRoutes.WorkLifeRoutes.WORK_LIFE_PTO_CLONE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Create Sick Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_SICK_CREATE_PATH]: [
                {
                  display: 'Add Sick Plan',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_SICK_CREATE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_SICK_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Sick Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_SICK_EDIT_PATH]: [
                {
                  display: 'Edit Sick Plan',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_SICK_EDIT_PATH,
                  routes: [PlanRoutes.WorkLifeRoutes.WORK_LIFE_SICK_EDIT_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Sick Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_SICK_VIEW_PATH]: [
                {
                  display: 'View Sick Plan',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_SICK_VIEW_PATH,
                  routes: [PlanRoutes.WorkLifeRoutes.WORK_LIFE_SICK_VIEW_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Clone Sick Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_SICK_CLONE_PATH]: [
                {
                  display: 'Add Sick Plan',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_SICK_CLONE_PATH,
                  routes: [PlanRoutes.WorkLifeRoutes.WORK_LIFE_SICK_CLONE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // Create Clubs Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_CLUBS_CREATE_PATH]: [
                {
                  display: 'Add Clubs Plan',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_CLUBS_CREATE_PATH,
                  routes: [
                    PlanRoutes.WorkLifeRoutes.WORK_LIFE_CLUBS_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Clubs Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_CLUBS_EDIT_PATH]: [
                {
                  display: 'Edit Clubs Plan',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_CLUBS_EDIT_PATH,
                  routes: [PlanRoutes.WorkLifeRoutes.WORK_LIFE_CLUBS_EDIT_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View Clubs Plan Submenu
              [PlanRoutes.WorkLifeRoutes.WORK_LIFE_CLUBS_VIEW_PATH]: [
                {
                  display: 'View Clubs Plan',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_CLUBS_VIEW_PATH,
                  routes: [PlanRoutes.WorkLifeRoutes.WORK_LIFE_CLUBS_VIEW_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
               // Clone Clubs Plan Submenu
               [PlanRoutes.WorkLifeRoutes.WORK_LIFE_CLUBS_CLONE_PATH]: [
                {
                  display: 'Add Clubs Plan',
                  linkTo: PlanRoutes.WorkLifeRoutes.WORK_LIFE_CLUBS_CLONE_PATH,
                  routes: [PlanRoutes.WorkLifeRoutes.WORK_LIFE_CLUBS_CLONE_PATH],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ]
            }
          },
          {
            display: 'Additional Perks',
            linkTo: PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_LIST_PATH,
            routes: [PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_LIST_PATH],
            roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              // Create perk Plan Submenu
              [PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_CREATE_PATH]: [
                {
                  display: 'Add Perk',
                  linkTo:
                    PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_CREATE_PATH,
                  routes: [
                    PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_CREATE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit perk Plan Submenu
              [PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_EDIT_PATH]: [
                {
                  display: 'Edit Perk',
                  linkTo:
                    PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_EDIT_PATH,
                  routes: [
                    PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_EDIT_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View perk Plan Submenu
              [PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_VIEW_PATH]: [
                {
                  display: 'View Perk',
                  linkTo:
                    PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_VIEW_PATH,
                  routes: [
                    PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_VIEW_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              [PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_CLONE_PATH]: [
                {
                  display: 'Add Perk',
                  linkTo:
                    PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_CLONE_PATH,
                  routes: [
                    PlanRoutes.AdditionalPerkRoutes.ADDITIONAL_PERK_CLONE_PATH
                  ],
                  roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
            }
          },
          {
            display: <hr />,
            roles: roleMappings.PLANS_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'Employees',
            linkTo: EmployeesRoutes.EMPLOYEES_LIST_PATH,
            routes: [EmployeesRoutes.EMPLOYEES_LIST_PATH],
            roles: roleMappings.EMPLOYEES_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              // Employee Info Submenu
              [EmployeesRoutes.EMPLOYEES_DETAILS_PATH]: [
                {
                  display: 'Employee Info',
                  linkTo: EmployeesRoutes.EMPLOYEES_DETAILS_PATH,
                  routes: [
                    EmployeesRoutes.EMPLOYEES_DETAILS_PATH,
                    EmployeesRoutes.EMPLOYEES_EDIT_PATH,
                    EmployeesRoutes.EMPLOYEES_CHANGE_USERNAME_PATH,
                    EmployeesRoutes.EMPLOYEES_CHANGE_SSN_PATH
                  ],
                  roles: roleMappings.EMPLOYEES_FEATURE_ROLE_MAPPING.common
                }
              ]
            }
          },
          {
            display: 'Roles',
            linkTo: EmployeesRoutes.EMPLOYEES_ROLES_PATH,
            routes: [EmployeesRoutes.EMPLOYEES_ROLES_PATH],
            roles: roleMappings.EMPLOYEES_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: <hr />,
            roles: roleMappings.EMPLOYEES_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'BenGuide',
            linkTo: BenguideRoutes.BEN_GUIDES_PATH,
            routes: [BenguideRoutes.BEN_GUIDES_PATH],
            roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              // Create BenGuide Submenu
              [BenguideRoutes.BEN_GUIDES_CREATE_PATH]: [
                {
                  display: '1 - Plans',
                  linkTo: BenguideRoutes.BEN_GUIDES_CREATE_PATH,
                  routes: [BenguideRoutes.BEN_GUIDES_CREATE_PATH],
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: (
                    <span className="cursor-disabled">2 - Customization</span>
                  ),
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: <span className="cursor-disabled">3 - Other</span>,
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: (
                    <span className="cursor-disabled">
                      {'4 - Preview & Publish'}
                    </span>
                  ),
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View / Edit BenGuide Submenu
              '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/edit': [
                {
                  display: '1 - Plans',
                  linkTo: BenguideRoutes.BEN_GUIDES_EDIT_PLAN_PATH,
                  routes: [BenguideRoutes.BEN_GUIDES_EDIT_PLAN_PATH],
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '2 - Customization',
                  linkTo: BenguideRoutes.BEN_GUIDES_EDIT_CUSTOM_PATH,
                  routes: [BenguideRoutes.BEN_GUIDES_EDIT_CUSTOM_PATH],
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '3 - Other',
                  linkTo: BenguideRoutes.BEN_GUIDES_EDIT_OTHER_PATH,
                  routes: [BenguideRoutes.BEN_GUIDES_EDIT_OTHER_PATH],
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '4 - Preview & Publish',
                  linkTo: BenguideRoutes.BEN_GUIDES_EDIT_PREVIEW_PATH,
                  routes: [BenguideRoutes.BEN_GUIDES_EDIT_PREVIEW_PATH],
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Clone BenGuide Submenu
              '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/clone': [
                {
                  display: '1 - Plans',
                  linkTo: BenguideRoutes.BEN_GUIDES_CLONE_PLAN_PATH,
                  routes: [BenguideRoutes.BEN_GUIDES_CLONE_PLAN_PATH],
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: (
                    <span className="cursor-disabled">2 - Customization</span>
                  ),
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: <span className="cursor-disabled">3 - Other</span>,
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: (
                    <span className="cursor-disabled">
                      {'4 - Preview & Publish'}
                    </span>
                  ),
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.common
                }
              ],
              // View BenGuide Submenu
              '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/:type': [
                {
                  display: '1 - Plans',
                  linkTo: BenguideRoutes.BEN_GUIDES_VIEW_PLAN_PATH,
                  routes: [BenguideRoutes.BEN_GUIDES_VIEW_PLAN_PATH],
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '2 - Customization',
                  linkTo: BenguideRoutes.BEN_GUIDES_VIEW_CUSTOM_PATH,
                  routes: [BenguideRoutes.BEN_GUIDES_VIEW_CUSTOM_PATH],
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '3 - Other',
                  linkTo: BenguideRoutes.BEN_GUIDES_VIEW_OTHER_PATH,
                  routes: [BenguideRoutes.BEN_GUIDES_VIEW_OTHER_PATH],
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '4 - Preview & Publish',
                  linkTo: BenguideRoutes.BEN_GUIDES_VIEW_PREVIEW_PATH,
                  routes: [BenguideRoutes.BEN_GUIDES_VIEW_PREVIEW_PATH],
                  roles: roleMappings.BEN_GUIDE_FEATURE_ROLE_MAPPING.navigation
                }
              ]
            }
          },
          {
            display: 'Benchmarks',
            linkTo: BenchmarkRoutes.BENCHMARKS_LIST_PATH,
            routes: [BenchmarkRoutes.BENCHMARKS_LIST_PATH],
            roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              // Create Benchmarks Submenu
              [BenchmarkRoutes.BENCHMARKS_CREATE_PATH]: [
                {
                  display: '1 - Census Info',
                  linkTo: BenchmarkRoutes.BENCHMARKS_CREATE_PATH,
                  routes: [BenchmarkRoutes.BENCHMARKS_CREATE_PATH],
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: <span className="cursor-disabled">2 - Medical</span>,
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: <span className="cursor-disabled">3 - Dental</span>,
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: <span className="cursor-disabled">4 - Vision</span>,
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: (
                    <span className="cursor-disabled">
                      6 - Preview & Publish
                    </span>
                  ),
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.navigation
                }
              ],
              // View / Edit Benchmarks Submenu
              '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/edit': [
                {
                  display: '1 - Census Info',
                  linkTo: BenchmarkRoutes.BENCHMARKS_EDIT_CENSUS_PATH,
                  routes: [BenchmarkRoutes.BENCHMARKS_EDIT_CENSUS_PATH],
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '2 - Medical',
                  linkTo:
                    '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/edit/medical',
                  routes: [
                    BenchmarkRoutes.BENCHMARKS_EDIT_CURRENT_PATH,
                    BenchmarkRoutes.BENCHMARKS_EDIT_BENCHMARK_PATH
                  ],
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '3 - Dental',
                  linkTo:
                    '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/edit/dental',
                  routes: [
                    BenchmarkRoutes.BENCHMARKS_EDIT_CURRENT_PATH,
                    BenchmarkRoutes.BENCHMARKS_EDIT_BENCHMARK_PATH
                  ],
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '4 - Vision',
                  linkTo:
                    '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/edit/vision',
                  routes: [
                    BenchmarkRoutes.BENCHMARKS_EDIT_CURRENT_PATH,
                    BenchmarkRoutes.BENCHMARKS_EDIT_BENCHMARK_PATH
                  ],
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.common
                },
                {
                  display: '5 - Preview & Publish',
                  linkTo: BenchmarkRoutes.BENCHMARKS_PREVIEW_PATH,
                  routes: [BenchmarkRoutes.BENCHMARKS_PREVIEW_PATH],
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.common
                }
              ],
              //View
              '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/view': [
                {
                  display: '1 - Census Info',
                  linkTo: BenchmarkRoutes.BENCHMARKS_VIEW_CENSUS_PATH,
                  routes: [BenchmarkRoutes.BENCHMARKS_VIEW_CENSUS_PATH],
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '2 - Medical',
                  linkTo:
                    '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/view/medical/benchmark',
                  routes: [BenchmarkRoutes.BENCHMARKS_VIEW_BENCHMARK_PATH],
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '3 - Dental',
                  linkTo:
                    '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/view/dental/benchmark',
                  routes: [BenchmarkRoutes.BENCHMARKS_VIEW_BENCHMARK_PATH],
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '4 - Vision',
                  linkTo:
                    '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/view/vision/benchmark',
                  routes: [BenchmarkRoutes.BENCHMARKS_VIEW_BENCHMARK_PATH],
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.navigation
                },
                {
                  display: '5 - Preview & Publish',
                  linkTo: BenchmarkRoutes.BENCHMARKS_VIEW_PREVIEW_PATH,
                  routes: [BenchmarkRoutes.BENCHMARKS_VIEW_PREVIEW_PATH],
                  roles: roleMappings.BENCHMARKS_FEATURE_ROLE_MAPPING.navigation
                }
              ]
            }
          },
          {
            display: 'Employee ID Cards',
            linkTo: MobileRoutes.MOBILE_PATH,
            routes: [MobileRoutes.MOBILE_PATH],
            roles: roleMappings.MOBILE_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'Chatbot',
            linkTo: ChatbotRoutes.CHATBOT_CONFIG_PATH,
            routes: [ChatbotRoutes.CHATBOT_CONFIG_PATH],
            roles: roleMappings.CHATBOT_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'Current Plans',
            linkTo: CurrentPlansRoutes.CURRENT_PLANS_PATH,
            routes: [CurrentPlansRoutes.CURRENT_PLANS_PATH],
            roles: roleMappings.CURRENT_PLANS_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'Documents',
            linkTo: DocumentRoutes.DOCUMENTS_V2_PATH,
            routes: [DocumentRoutes.DOCUMENTS_V2_PATH],
            roles: roleMappings.DOCUMENTS_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'Billing',
            linkTo: BillingRoutes.BILLING_PATH,
            routes: [BillingRoutes.BILLING_PATH],
            roles: roleMappings.BILLING_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'Calendar',
            linkTo: CalendarRoutes.CALENDAR_PATH,
            routes: [CalendarRoutes.CALENDAR_PATH],
            roles: roleMappings.CALENDER_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'Renewals',
            linkTo: RenewalsRoutes.PROPOSAL_RENEWALS_PATH,
            routes: [RenewalsRoutes.PROPOSAL_RENEWALS_PATH],
            roles: roleMappings.RENEWALS_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'HRIS Integrations',
            linkTo: IntegrationsRoutes.EMPLOYER_INTEGRATIONS,
            routes: [
              IntegrationsRoutes.EMPLOYER_INTEGRATIONS,
              IntegrationsRoutes.CREATE_INTEGRATION,
              IntegrationsRoutes.EDIT_INTEGRATION
            ],
            roles: roleMappings.INTEGRATIONS_FEATURE_ROLE_MAPPING.navigation,
            show: allowedIntegrations.includes(AllowedIntegrationType.HRIS)
          },
          {
            display: 'BenAdmin Integrations',
            linkTo: BenAdminIntegrationsRoutes.EMPLOYER_BENADMIN_INTEGRATIONS,
            routes: [BenAdminIntegrationsRoutes.EMPLOYER_BENADMIN_INTEGRATIONS],
            roles:
              roleMappings.BENADMIN_INTEGRATIONS_FEATURE_ROLE_MAPPING
                .navigation,
            show: isFlockOrWorkdayBenAdminEmployer
          },
          {
            display: 'Issues Log',
            linkTo: IssueLogRoutes.ISSUE_LOG_PATH,
            routes: [IssueLogRoutes.ISSUE_LOG_PATH],
            roles: roleMappings.ISSUES_LOG_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'Insights',
            linkTo: InsightsRoutes.ANALYTICS_AND_INSIGHTS_PATH,
            routes: [InsightsRoutes.ANALYTICS_AND_INSIGHTS_PATH],
            roles: roleMappings.INSIGHTS_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'Surveys',
            linkTo: SurveysRoutes.SURVEYS_PATH,
            routes: [
              SurveysRoutes.SURVEYS_PATH,
              SurveysRoutes.SURVEYS_CREATE_PATH,
              SurveysRoutes.SURVEYS_EDIT_PATH,
              SurveysRoutes.SURVEYS_DETAILS_PATH
            ],
            roles: roleMappings.SURVEYS_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: 'Benefits Consultation',
            linkTo: BenefitsConsultationRoutes.BENEFITS_CONSULTATION_PATH,
            routes: [BenefitsConsultationRoutes.BENEFITS_CONSULTATION_PATH],
            roles:
              roleMappings.BENEFITS_CONSULTATION_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: <hr />,
            roles: roleMappings.OFFERS_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: (
              <>
                <span>Offers</span>
              </>
            ),
            linkTo: OfferRoutes.OFFER_BUNDLE_PATH,
            routes: [OfferRoutes.OFFER_BUNDLE_PATH],
            roles: roleMappings.OFFERS_FEATURE_ROLE_MAPPING.navigation,
            subTrees: {
              // Create Proposal Plans Submenu
              [OfferRoutes.OFFER_PLAN_CREATE_PATH]: [
                {
                  display: 'Upload Data File',
                  linkTo: OfferRoutes.OFFER_PLAN_CREATE_PATH,
                  routes: [OfferRoutes.OFFER_PLAN_CREATE_PATH],
                  roles: roleMappings.OFFERS_FEATURE_ROLE_MAPPING.common
                }
              ],
              // Edit Proposal Plans Submenu
              [OfferRoutes.OFFER_PLAN_EDIT_PATH]: [
                {
                  display: 'Upload Data File',
                  linkTo: OfferRoutes.OFFER_PLAN_EDIT_PATH_STEP_FILE_UPLOAD,
                  routes: [OfferRoutes.OFFER_PLAN_EDIT_PATH_STEP_FILE_UPLOAD],
                  roles: roleMappings.OFFERS_FEATURE_ROLE_MAPPING.common
                }
              ]
            }
          },
          {
            display: (
              <>
                <span>Offer Plans</span>
              </>
            ),
            linkTo: OfferRoutes.OFFER_PLAN_PATH,
            routes: [OfferRoutes.OFFER_PLAN_PATH],
            roles: roleMappings.OFFERS_FEATURE_ROLE_MAPPING.navigation
          },
          {
            display: <hr />
          },
          {
            display: 'SSO',
            linkTo: SSORoutes.SSO_PATH,
            routes: [SSORoutes.SSO_PATH],
            roles: roleMappings.SSO_FEATURE_ROLE_MAPPING.navigation
          }
        ]
      }
    },
    {
      display: 'Issues Log',
      icon: 'exclamation-triangle',
      linkTo: NativeIssueLogRoutes.NATIVE_ISSUE_LOG_PATH,
      routes: [
        NativeIssueLogRoutes.NATIVE_ISSUE_LOG_PATH,
        NativeIssueLogRoutes.NATIVE_ISSUE_CREATE_PATH,
        NativeIssueLogRoutes.NATIVE_ISSUE_EDIT_PATH,
        NativeIssueLogRoutes.NATIVE_ISSUE_VIEW_PATH
      ],
      roles: roleMappings.ISSUES_LOG_FEATURE_ROLE_MAPPING.navigation
    },
    {
      display: 'Integrations',
      icon: 'network-wired',
      linkTo: IntegrationsRoutes.INTEGRATIONS_HOME,
      routes: [IntegrationsRoutes.INTEGRATIONS_HOME],
      roles: roleMappings.ISSUES_LOG_FEATURE_ROLE_MAPPING.navigation
    },
    {
      display: 'Carriers',
      icon: 'user-shield',
      linkTo: CarriersRoutes.CARRIERS_PATH,
      routes: [CarriersRoutes.CARRIERS_PATH],
      roles: roleMappings.CARRIERS_FEATURE_ROLE_MAPPING.navigation
    }
  ];
};
