/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';

import { Col, Row } from 'components/Atoms';
import { TextAreaFormGroup, SelectFormGroup } from 'components/DataForm';
import './MessageIndicator.scss';
import { connect } from 'react-redux';
import Notifications from 'components/Notifications';
import {
  saveMessage,
  getAllYears,
  getAllMessages
} from '../../../../actions/insightsAction';
import { INSIGHTS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { permitIf } from 'components/hoc/Permit';
import PageActionButton from 'components/Buttons';

class MessageIndicator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messageData: {
        year: '',
        customMessages: {
          MEDICAL: '',
          DENTAL: '',
          VISION: ''
        },
        disableFields: {
          MEDICAL: false,
          DENTAL: false,
          VISION: false
        }
      }
    };
  }

  componentDidMount() {
    this.props.getAllYears();
  }

  componentDidUpdate(prevProps, prevState) {
    const { messageData } = this.state;
    const {
      employerId,
      dataSaveInprogress,
      dataSaveError,
      customMessageData,
      isFetchingData,
      yearsLoadingError,
      isLoadingYears
    } = this.props;

    if (
      !isLoadingYears &&
      isLoadingYears !== prevProps.isLoadingYears &&
      yearsLoadingError
    ) {
      Notifications.error('Failed to retrieve data');
    }
    if (
      !dataSaveInprogress &&
      dataSaveInprogress !== prevProps.dataSaveInprogress
    ) {
      if (dataSaveError) {
        Notifications.error('Failed to create the Message.');
      } else {
        Notifications.success('Message has been created.');
      }
    }

    if (prevState.messageData.year !== messageData.year) {
      this.props.getAllMessages(employerId, messageData.year);
    }

    if (customMessageData && customMessageData.length !== 0) {
      let updatedMessageData;
      if (Object.keys(customMessageData.customMessages).length !== 0) {
        updatedMessageData = {
          year: customMessageData.year,
          customMessages: {
            MEDICAL: customMessageData.customMessages.MEDICAL.message,
            DENTAL: customMessageData.customMessages.DENTAL.message,
            VISION: customMessageData.customMessages.VISION.message
          },
          disableFields: {
            MEDICAL: customMessageData.customMessages.MEDICAL.dataAvailable,
            DENTAL: customMessageData.customMessages.DENTAL.dataAvailable,
            VISION: customMessageData.customMessages.VISION.dataAvailable
          }
        };
      } else {
        updatedMessageData = {
          year: messageData.year,
          customMessages: {
            MEDICAL: '',
            DENTAL: '',
            VISION: ''
          },
          disableFields: {
            MEDICAL: false,
            DENTAL: false,
            VISION: false
          }
        };
      }
      if (!isFetchingData && isFetchingData !== prevProps.isFetchingData) {
        this.setState({
          messageData: updatedMessageData
        });
      }
    }
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      messageData: {
        ...prevState.messageData,
        customMessages: {
          ...prevState.messageData.customMessages,
          [name]: value
        }
      }
    }));
  };

  selectYear = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      messageData: {
        ...prevState.messageData,
        [name]: value
      }
    }));
  };

  render() {
    const { allYears, employerId, dataSaveInprogress } = this.props;
    const { messageData } = this.state;
    const SecuredPageActionButton = permitIf(
      PageActionButton,
      INSIGHTS_FEATURE_ROLE_MAPPING.common
    );
    let saveButtonDisable = false;
    if (
      messageData.disableFields.MEDICAL &&
      messageData.disableFields.DENTAL &&
      messageData.disableFields.VISION
    ) {
      saveButtonDisable = true;
    }

    let supportedYears = [];
    if (allYears && allYears.length !== 0) {
      allYears.forEach((years) => {
        supportedYears.push({
          value: years,
          text: years
        });
      });
    }

    return (
      <div className="insight-custom-message">
        <Row>
          <Col xs={3}>
            <SelectFormGroup
              inputName="year"
              inputValue={messageData.year}
              options={supportedYears}
              onChange={this.selectYear}
            />
          </Col>
          <Col sm={4} className="save-btn-container">
            <SecuredPageActionButton
              type={''}
              disabled={
                !messageData.year || dataSaveInprogress || saveButtonDisable
              }
              onClick={() => this.props.saveMessage(messageData, employerId)}
              className="insight-custom-message-save-btn"
            >
              {dataSaveInprogress ? 'Creating' : 'Save'}
            </SecuredPageActionButton>
          </Col>
        </Row>

        <Row className="medical-message-row">
          <Col sm={1}>
            <label className="mt-5 title">Medical</label>
          </Col>
          <Col sm={6}>
            <TextAreaFormGroup
              disabled={messageData.disableFields.MEDICAL}
              maxLength="150"
              className="text-area"
              inputName="MEDICAL"
              id="medicalText"
              onChange={this.onChange}
              inputValue={messageData.customMessages.MEDICAL}
            />
          </Col>
        </Row>
        <Row className="medical-message-row">
          <Col sm={1}>
            <label className="mt-5 title">Dental</label>
          </Col>
          <Col sm={6}>
            <TextAreaFormGroup
              disabled={messageData.disableFields.DENTAL}
              maxLength="150"
              className="text-area"
              inputName="DENTAL"
              id="dentalText"
              onChange={this.onChange}
              inputValue={messageData.customMessages.DENTAL}
            />
          </Col>
        </Row>
        <Row className="medical-message-row">
          <Col xs={1}>
            <label className=" mt-5 ">Vision</label>
          </Col>
          <Col xs={6}>
            <TextAreaFormGroup
              disabled={messageData.disableFields.VISION}
              maxLength="150"
              className="text-area"
              type="textarea"
              inputName="VISION"
              id="visionText"
              onChange={this.onChange}
              inputValue={messageData.customMessages.VISION}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveMessage: (messageData, employerId) =>
    dispatch(saveMessage(messageData, employerId)),
  getAllYears: () => dispatch(getAllYears()),
  getAllMessages: (employerId, year) =>
    dispatch(getAllMessages(employerId, year))
});

const mapStateToProps = (state) => {
  const {
    insightSaveMessage,
    insightYears,
    insightGetMessageData
  } = state.insightsReducer;
  return {
    createdMessage: insightSaveMessage.messageData,
    dataSaveInprogress: insightSaveMessage.isCreating,
    dataSaveError: insightSaveMessage.error,
    allYears: insightYears.years,
    isLoadingYears: insightYears.isLoadingYears,
    yearsLoadingError: insightYears.yearsLoadingError,
    customMessageData: insightGetMessageData.customMessageData,
    isFetchingData: insightGetMessageData.isFetchingData
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageIndicator);
