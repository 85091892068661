/**********************************************************************************************************************
 * Copyright (C) 2022 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ConfirmationBox from 'components/DialogBox/ConfirmationBox';
import './CloneBenguideModal.scss';

export default class CloneBenguideModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    benGuide: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    cloneBenguide: PropTypes.func
  };

  render() {
    const { isOpen, toggle, benGuide = {}, cloneBenguide } = this.props;
    if (benGuide) {
      const { id = '' } = benGuide;
      return (
        <ConfirmationBox
          isOpen={isOpen}
          toggle={toggle}
          message={
            'Are you sure you want to clone this DBG structure? Everything will be cloned except for plans, OE dates, and any OE meetings.'
          }
          confirmText={<>Clone</>}
          confirmCallback={() => cloneBenguide(id)}
          className="clone-benguide-modal-wrapper"
          showIcon={false}
        />
      );
    } else {
      return null;
    }
  }
}
