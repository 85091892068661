/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const GET_BENGUIDES_PAGE = 'GET_BENGUIDES_PAGE';
export const GET_BENGUIDES_PAGE_ERROR = 'GET_BENGUIDES_PAGE_ERROR';

export const UPDATE_BEN_GUIDE = 'UPDATE_BEN_GUIDE';
export const UPDATE_BEN_GUIDE_IN_PROGRESS = 'UPDATE_BEN_GUIDE_IN_PROGRESS';
export const UPDATE_BEN_GUIDE_ERROR = 'UPDATE_BEN_GUIDE_ERROR';
export const UPDATE_BEN_GUIDE_SUCCESS = 'UPDATE_BEN_GUIDE_SUCCESS';
export const UPDATE_BEN_GUIDE_VALIDATIONS = 'UPDATE_BEN_GUIDE_VALIDATIONS';

export const UPLOAD_OE_VIDEO_IN_PROGRESS = 'UPLOAD_OE_VIDEO_IN_PROGRESS';
export const UPLOAD_OE_VIDEO_PROGRESS_NOTIFICATION =
  'UPLOAD_OE_VIDEO_PROGRESS_NOTIFICATION';
export const UPLOAD_OE_VIDEO_SUCCESS = 'UPLOAD_OE_VIDEO_SUCCESS';
export const UPLOAD_OE_VIDEO_ERROR = 'UPLOAD_OE_VIDEO_ERROR';

export const GET_BEN_GUIDE_PLANS_IN_PROGRESS =
  'GET_BEN_GUIDE_PLANS_IN_PROGRESS';
export const GET_BEN_GUIDE_PLANS_SUCCESS = 'GET_BEN_GUIDE_PLANS_SUCCESS';
export const GET_BEN_GUIDE_PLANS_ERROR = 'GET_BEN_GUIDE_PLANS_ERROR';

export const CLONE_BEN_GUIDE_IN_PROGRESS = 'CLONE_BEN_GUIDE_IN_PROGRESS';
export const CLONE_BEN_GUIDE_SUCCESS = 'CLONE_BEN_GUIDE_PLANS_SUCCESS';
export const CLONE_BEN_GUIDE_ERROR = 'CLONE_BEN_GUIDE_PLANS_ERROR';

export const RESET_BEN_GUIDE_PLANS = 'RESET_BEN_GUIDE_PLANS';

export const GET_BEN_GUIDE_DENTAL_PLANS_IN_PROGRESS =
  'GET_BEN_GUIDE_DENTAL_PLANS_IN_PROGRESS';
export const GET_BEN_GUIDE_DENTAL_PLANS_SUCCESS =
  'GET_BEN_GUIDE_DENTAL_PLANS_SUCCESS';
export const GET_BEN_GUIDE_DENTAL_PLANS_ERROR =
  'GET_BEN_GUIDE_DENTAL_PLANS_ERROR';

export const GET_BEN_GUIDE_VISION_PLANS_IN_PROGRESS =
  'GET_BEN_GUIDE_VISION_PLANS_IN_PROGRESS';
export const GET_BEN_GUIDE_VISION_PLANS_SUCCESS =
  'GET_BEN_GUIDE_VISION_PLANS_SUCCESS';
export const GET_BEN_GUIDE_VISION_PLANS_ERROR =
  'GET_BEN_GUIDE_VISION_PLANS_ERROR';

export const GET_BEN_GUIDE_SAVINGS_PLANS_IN_PROGRESS =
  'GET_BEN_GUIDE_SAVINGS_PLANS_IN_PROGRESS';
export const GET_BEN_GUIDE_SAVINGS_PLANS_SUCCESS =
  'GET_BEN_GUIDE_SAVINGS_PLANS_SUCCESS';
export const GET_BEN_GUIDE_SAVINGS_PLANS_ERROR =
  'GET_BEN_GUIDE_SAVINGS_PLANS_ERROR';

export const GET_BEN_GUIDE_COMMUTER_PLANS_IN_PROGRESS =
  'GET_BEN_GUIDE_COMMUTER_PLANS_IN_PROGRESS';
export const GET_BEN_GUIDE_COMMUTER_PLANS_SUCCESS =
  'GET_BEN_GUIDE_COMMUTER_PLANS_SUCCESS';
export const GET_BEN_GUIDE_COMMUTER_PLANS_ERROR =
  'GET_BEN_GUIDE_COMMUTER_PLANS_ERROR';

export const GET_BEN_GUIDE_LIFE_PLANS_IN_PROGRESS =
  'GET_BEN_GUIDE_LIFE_PLANS_IN_PROGRESS';
export const GET_BEN_GUIDE_LIFE_PLANS_SUCCESS =
  'GET_BEN_GUIDE_LIFE_PLANS_SUCCESS';
export const GET_BEN_GUIDE_LIFE_PLANS_ERROR = 'GET_BEN_GUIDE_LIFE_PLANS_ERROR';

export const GET_BEN_GUIDE_FOUR0ONEK_PLANS_IN_PROGRESS =
  'GET_BEN_GUIDE_FOUR0ONEK_PLANS_IN_PROGRESS';
export const GET_BEN_GUIDE_FOUR0ONEK_PLANS_SUCCESS =
  'GET_BEN_GUIDE_FOUR0ONEK_PLANS_SUCCESS';
export const GET_BEN_GUIDE_FOUR0ONEK_PLANS_ERROR =
  'GET_BEN_GUIDE_FOUR0ONEK_PLANS_ERROR';

export const GET_BEN_GUIDE_BY_ID_IN_PROGRESS =
  'GET_BEN_GUIDE_BY_ID_IN_PROGRESS';
export const GET_BEN_GUIDE_BY_ID_SUCCESS = 'GET_BEN_GUIDE_BY_ID_SUCCESS';
export const GET_BEN_GUIDE_BY_ID_ERROR = 'GET_BEN_GUIDE_BY_ID_ERROR';

export const UPLOAD_BENGUIDE_LOGO_IN_PROGRESS =
  'UPLOAD_BENGUIDE_LOGO_IN_PROGRESS';
export const UPLOAD_BENGUIDE_LOGO_SUCCESS = 'UPLOAD_BENGUIDE_LOGO_SUCCESS';
export const UPLOAD_BENGUIDE_LOGO_ERROR = 'UPLOAD_BENGUIDE_LOGO_ERROR';

export const UPLOAD_BENGUIDE_BACKGROUND_IN_PROGRESS =
  'UPLOAD_BENGUIDE_BACKGROUND_IN_PROGRESS';
export const UPLOAD_BENGUIDE_BACKGROUND_SUCCESS =
  'UPLOAD_BENGUIDE_BACKGROUND_SUCCESS';
export const UPLOAD_BENGUIDE_BACKGROUND_ERROR =
  'UPLOAD_BENGUIDE_BACKGROUND_ERROR';

export const UPLOAD_BENGUIDE_FAMILY_IMAGE_IN_PROGRESS =
  'UPLOAD_BENGUIDE_FAMILY_IMAGE_IN_PROGRESS';
export const UPLOAD_BENGUIDE_FAMILY_IMAGE_SUCCESS =
  'UPLOAD_BENGUIDE_FAMILY_IMAGE_SUCCESS';
export const UPLOAD_BENGUIDE_FAMILY_IMAGE_ERROR =
  'UPLOAD_BENGUIDE_FAMILY_IMAGE_ERROR';

export const UPLOAD_BENGUIDE_TIME_OFF_IMAGE_IN_PROGRESS =
  'UPLOAD_BENGUIDE_TIME_OFF_IMAGE_IN_PROGRESS';
export const UPLOAD_BENGUIDE_TIME_OFF_IMAGE_SUCCESS =
  'UPLOAD_BENGUIDE_TIME_OFF_IMAGE_SUCCESS';
export const UPLOAD_BENGUIDE_TIME_OFF_IMAGE_ERROR =
  'UPLOAD_BENGUIDE_TIME_OFF_IMAGE_ERROR';

export const UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_IN_PROGRESS =
  'UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_IN_PROGRESS';
export const UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_SUCCESS =
  'UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_SUCCESS';
export const UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_ERROR =
  'UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_ERROR';

export const UPLOAD_BENGUIDE_PERK_IMAGE_IN_PROGRESS =
  'UPLOAD_BENGUIDE_PERK_IMAGE_IN_PROGRESS';
export const UPLOAD_BENGUIDE_PERK_IMAGE_SUCCESS =
  'UPLOAD_BENGUIDE_PERK_IMAGE_SUCCESS';
export const UPLOAD_BENGUIDE_PERK_IMAGE_ERROR =
  'UPLOAD_BENGUIDE_PERK_IMAGE_ERROR';

export const GET_BENGUIDE_LOGO_SUCCESS = 'GET_BENGUIDE_LOGO_SUCCESS';
export const GET_BENGUIDE_LOGO_IN_PROGRESS = 'GET_BENGUIDE_LOGO_IN_PROGRESS';
export const GET_BENGUIDE_LOGO_ERROR = 'GET_BENGUIDE_LOGO_ERROR';
export const GET_BENGUIDE_BACKGROUND_SUCCESS =
  'GET_BENGUIDE_BACKGROUND_SUCCESS';

export const GET_BENGUIDE_FAMILY_IMAGE_SUCCESS =
  'GET_BENGUIDE_FAMILY_IMAGE_SUCCESS';
export const GET_BENGUIDE_FAMILY_IMAGE_IN_PROGRESS =
  'GET_BENGUIDE_FAMILY_IMAGE_IN_PROGRESS';
export const GET_BENGUIDE_FAMILY_IMAGE_ERROR =
  'GET_BENGUIDE_FAMILY_IMAGE_ERROR';

export const GET_BENGUIDE_TIME_OFF_IMAGE_SUCCESS =
  'GET_BENGUIDE_TIME_OFF_IMAGE_SUCCESS';
export const GET_BENGUIDE_TIME_OFF_IMAGE_IN_PROGRESS =
  'GET_BENGUIDE_TIME_OFF_IMAGE_IN_PROGRESS';
export const GET_BENGUIDE_TIME_OFF_IMAGE_ERROR =
  'GET_BENGUIDE_TIME_OFF_IMAGE_ERROR';

export const GET_BENGUIDE_COMMUNITIES_IMAGE_SUCCESS =
  'GET_BENGUIDE_COMMUNITIES_IMAGE_SUCCESS';
export const GET_BENGUIDE_COMMUNITIES_IMAGE_IN_PROGRESS =
  'GET_BENGUIDE_COMMUNITIES_IMAGE_IN_PROGRESS';
export const GET_BENGUIDE_COMMUNITIES_IMAGE_ERROR =
  'GET_BENGUIDE_COMMUNITIES_IMAGE_ERROR';

export const GET_BENGUIDE_PERK_IMAGE_SUCCESS =
  'GET_BENGUIDE_PERK_IMAGE_SUCCESS';
export const GET_BENGUIDE_PERK_IMAGE_IN_PROGRESS =
  'GET_BENGUIDE_PERK_IMAGE_IN_PROGRESS';
export const GET_BENGUIDE_PERK_IMAGE_ERROR = 'GET_BENGUIDE_PERK_IMAGE_ERROR';

export const PUBLISH_BENGUIDE_IN_PROGRESS = 'PUBLISH_BENGUIDE_IN_PROGRESS';
export const PUBLISH_BENGUIDE_SUCCESS = 'PUBLISH_BENGUIDE_SUCCESS';
export const PUBLISH_BENGUIDE_ERROR = 'PUBLISH_BENGUIDE_ERROR';

export const VALIDATE_BEN_GUIDE_NAME_IN_PROGRESS =
  'VALIDATE_BEN_GUIDE_NAME_IN_PROGRESS';
export const VALIDATE_BEN_GUIDE_NAME_SUCCESS =
  'VALIDATE_BEN_GUIDE_NAME_SUCCESS';
export const VALIDATE_BEN_GUIDE_NAME_ERROR = 'VALIDATE_BEN_GUIDE_NAME_ERROR';

export const UPLOAD_PERK_DOC_IN_PROGRESS = 'UPLOAD_PERK_DOC_IN_PROGRESS';
export const UPLOAD_PERK_DOC_SUCCESS = 'UPLOAD_PERK_DOC_SUCCESS';
export const UPLOAD_PERK_DOC_ERROR = 'UPLOAD_PERK_DOC_ERROR';

export const UPLOAD_RESOURCE_DOC_IN_PROGRESS =
  'UPLOAD_RESOURCE_DOC_IN_PROGRESS';
export const UPLOAD_RESOURCE_DOC_SUCCESS = 'UPLOAD_RESOURCE_DOC_SUCCESS';
export const UPLOAD_RESOURCE_DOC_ERROR = 'UPLOAD_RESOURCE_DOC_ERROR';
export const GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNT_IN_PROGRESS =
  'GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNT_IN_PROGRESS';
export const GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNT =
  'GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNT';
export const GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ERROR =
  'GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ERROR';

export const PREVIEW_BENGUIDE_IN_PROGRESS = 'PREVIEW_BENGUIDE_IN_PROGRESS';
export const PREVIEW_BENGUIDE_SUCCESS = 'PREVIEW_BENGUIDE_SUCCESS';
export const PREVIEW_BENGUIDE_ERROR = 'PREVIEW_BENGUIDE_ERROR';
export const GET_OE_MEETING_USERS_IN_PROGRESS =
  'GET_OE_MEETING_USERS_IN_PROGRESS';
export const GET_OE_MEETING_USERS = 'GET_OE_MEETING_USERS';
export const GET_OE_MEETING_USERS_ERROR = 'GET_OE_MEETING_USERS_ERROR';
export const GET_OE_MEETING = 'GET_OE_MEETING';
export const GET_OE_MEETING_IN_PROGRESS = 'GET_OE_MEETING_IN_PROGRESS';
export const GET_OE_MEETING_ERROR = 'GET_OE_MEETING_ERROR';
export const CLEAR_OE_MEETING = 'CLEAR_OE_MEETING';
export const SAVE_OE_MEETING = 'SAVE_OE_MEETING';
export const SAVE_OE_MEETING_IN_PROGRESS = 'SAVE_OE_MEETING_IN_PROGRESS';
export const SAVE_OE_MEETING_ERROR = 'SAVE_OE_MEETING_ERROR';
export const DELETE_OE_MEETING_IN_PROGRESS = 'DELETE_OE_MEETING_IN_PROGRESS';
export const DELETE_OE_MEETING = 'DELETE_OE_MEETING';
export const DELETE_OE_MEETING_ERROR = 'DELETE_OE_MEETING_ERROR';

export const UPLOAD_OE_MEETING_RECORDING_IN_PROGRESS =
  'UPLOAD_OE_MEETING_RECORDING_IN_PROGRESS';
export const UPLOAD_OE_MEETING_RECORDING_SUCCESS =
  'UPLOAD_OE_MEETING_RECORDING_SUCCESS';
export const UPLOAD_OE_MEETING_RECORDING_FAILED =
  'UPLOAD_OE_MEETING_RECORDING_FAILED';
export const UPLOAD_OE_MEETING_PROGRESS_NOTIFICATION =
  'UPLOAD_OE_MEETING_PROGRESS_NOTIFICATION';

export const CREATE_OE_RECORDING_UPLOAD_CANCEL_SOURCE =
  'CREATE_OE_RECORDING_UPLOAD_CANCEL_SOURCE';
export const OE_RECORDING_UPLOAD_CANCEL_SUCCESS =
  'OE_RECORDING_UPLOAD_CANCEL_SUCCESS';

export const DELETE_BENGUIDE_IN_PROGRESS = 'DELETE_BENGUIDE_IN_PROGRESS';
export const DELETE_BENGUIDE_COMPLETED = 'DELETE_BENGUIDE_COMPLETED';
export const DELETE_BENGUIDE_FAILED = 'DELETE_BENGUIDE_FAILED';

export const CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_IN_PROGRESS =
  'CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_IN_PROGRESS';
export const CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_COMPLETED =
  'CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_COMPLETED';
export const CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_FAILED =
  'CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_FAILED';
