/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
export const MODULE_SURVEYS_ENABLED = 'MODULE_SURVEYS_ENABLED';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY h:mm A';
export const UPDATE_SUCCESS = 204;
export const CREATE_SUCCESS = 200;
export const SURVEY_STATUS = {
  DRAFT: 'DRAFT',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  APPROVED: 'APPROVED',
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
};
export const STATUS_INFO_MAP = {
  [SURVEY_STATUS.DRAFT] : {
    name: 'Draft',
    color: '#F9C747'
  },
  [SURVEY_STATUS.AWAITING_APPROVAL]: {
    name: 'Awaiting Approval',
    color: '#F9C747'
  },
  [SURVEY_STATUS.APPROVED]: {
    name: 'Approved',
    color: '#28B667',
  },
  [SURVEY_STATUS.ACTIVE]: {
    name: 'Active',
    color: '#28B667'
  },
  [SURVEY_STATUS.CLOSED]: {
    name: 'Closed',
    color: '#000000'
  }

}

export const ERROR_CODE_MSG_MAP = {
  'surveys.er.notifications.disabled': 'Employer Notifications Disabled',
  'typeform.form.ids.mismatched':
    'The Survey URL and Survey Results URL do not belong to the same survey',
}