/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/group.actionTypes';

const initialState = {
  createGroupStatus: {
    data: '',
    inProgress: false,
    error: null
  },
  deleteGroupStatus: {
    data: '',
    inProgress: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_GROUP_IN_PROGRESS:
      return {
        ...state,
        createGroupStatus: {
          ...state.createGroupStatus,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.CREATE_GROUP_COMPLETED:
      return {
        ...state,
        createGroupStatus: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.CREATE_GROUP_FAILED:
      return {
        ...state,
        createGroupStatus: {
          ...state.createGroupStatus,
          inProgress: true,
          error: action.payload
        }
      };
    case ActionTypes.DELETE_GROUP_IN_PROGRESS:
      return {
        ...state,
        deleteGroupStatus: {
          ...state.deleteGroupStatus,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.DELETE_GROUP_COMPLETED:
      return {
        ...state,
        deleteGroupStatus: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.DELETE_GROUP_FAILED:
      return {
        ...state,
        deleteGroupStatus: {
          ...state.deleteGroupStatus,
          inProgress: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
