/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Button,
  Row,
  Col,
  Input,
  Table,
  FormGroup,
  FormFeedback
} from 'components/Atoms';

export class PayGroupMapping extends Component {
  static propTypes = {
    bambooHrPayGroups: PropTypes.arrayOf(PropTypes.string),
    payGroupMappings: PropTypes.objectOf(PropTypes.string),
    // Expect (payGroupMappings) => any
    onPrevious: PropTypes.func,
    // Expect (payGroupMappings) => any
    onNext: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      payGroupMappings: props.payGroupMappings || {},
      isEditable: props.isEditable || false,
      errors: {}
    };
  }

  componentDidMount() {
    const { payGroupMappings, isEditable } = this.state;
    if (isEditable && payGroupMappings.length) {
      this.formatBHRPayGroupMappings();
    }
  }

  formatBHRPayGroupMappings = () => {
    const { payGroupMappings } = this.state;
    const { bambooHrPayGroups } = this.props;
    let formattedPayGroups = {};
    let refactoredPayGroups = [];
    
    bambooHrPayGroups && bambooHrPayGroups.map((bhrPayGroup) => {
      payGroupMappings.forEach((payGroup) => {
        if (payGroup.bambooHrPayGroup === bhrPayGroup) {
          refactoredPayGroups.push(payGroup);
        }
      });
      return refactoredPayGroups;
    });

    refactoredPayGroups.forEach((group) => {
      formattedPayGroups[group.bambooHrPayGroup] = group.payGroup;
    });

    this.setState({ payGroupMappings: formattedPayGroups });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { payGroupMappings, errors } = this.state;

    // Update the configs
    const newMappings = { ...payGroupMappings };
    newMappings[name] = value;

    // Clear any errors for the pay group
    const newErrors = { ...errors };
    delete newErrors[name];

    this.setState({
      payGroupMappings: newMappings,
      errors: newErrors
    });
  };

  onPrevious = () => {
    const { payGroupMappings } = this.state;
    const { onPrevious } = this.props;
    if (this.validateFields()) {
      onPrevious(payGroupMappings);
    }
  };

  onNext = () => {
    const { payGroupMappings } = this.state;
    const { onNext } = this.props;
    if (this.validateFields()) {
      onNext(payGroupMappings);
    }
  };

  // Validate each row to see that some input is entered.
  // Return boolean for whether all fields were valid and update error object in state.
  validateFields = () => {
    const { bambooHrPayGroups = [] } = this.props;
    const { payGroupMappings } = this.state;

    const errors = {};
    bambooHrPayGroups.forEach((payGroup) => {
      // Input for the pay group is invalid if it is blank
      if (!payGroupMappings[payGroup]) {
        errors[payGroup] = 'Please enter a valid pay group mapping.';
      }
    });
    this.setState({ errors });

    return _.isEmpty(errors);
  };

  render() {
    const { selectedVendor, bambooHrPayGroups = [] } = this.props;
    const { payGroupMappings, errors } = this.state;
    return (
      <div className="step-container mapping-container">
        <div className="body">
          <p className="subtitle">{selectedVendor.name}</p>
          <Table borderless className="mapping-table">
            <thead>
              <tr>
                <th>BambooHR Pay Schedule</th>
                <th>Flock Pay Group</th>
              </tr>
            </thead>
            <tbody>
              {bambooHrPayGroups.map((payGroup, index) => (
                <tr key={index}>
                  <td className="pay-group-label">{payGroup}</td>
                  <td className="pay-group-label">
                    <FormGroup>
                      <Input
                        type="text"
                        name={payGroup}
                        onChange={this.onChange}
                        value={payGroupMappings[payGroup]}
                        invalid={errors[payGroup]}
                      />
                      {errors[payGroup] && (
                        <FormFeedback>{errors[payGroup]}</FormFeedback>
                      )}
                    </FormGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Row className="action-btns">
          <Col>
            <Button
              className="form-element"
              outline
              color="primary"
              size="lg"
              onClick={this.onPrevious}
              disabled={!_.isEmpty(errors)}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              className="form-element"
              color="primary"
              size="lg"
              onClick={this.onNext}
              disabled={!_.isEmpty(errors)}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
