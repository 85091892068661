/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import {
  FormGroup,
  Label,
  Button,
  Row,
  Col,
  FormFeedback,
  Progress
} from 'components/Atoms';
import Icon from 'components/Icons';

import './FormGroup.scss';
import ConfirmationBox from '../DialogBox/ConfirmationBox/ConfirmationBox';
import FileBrowser from '../DocumentManager/FileBrowser';

// TODO: Remove this component and replace usage with DocumentManager
export default class ButtonFileUploadFormGroup extends React.Component {
  constructor(props) {
    super(props);
    this.clickUploadButton = this.clickUploadButton.bind(this);

    this.state = {
      isConfirmModalOpen: false
    };
  }

  clickUploadButton() {
    this.fileBrowser.click();
  }

  toggleConfirmModal = () => {
    this.setState((prevState) => ({
      isConfirmModalOpen: !prevState.isConfirmModalOpen
    }));
  };

  render() {
    const {
      labelDisplay,
      inputName,
      onClickChoose,
      onClickRemove,
      fileLabel,
      fileUrl,
      acceptedFileTypes,
      feedback,
      disabled,
      showLoading,
      showProgress,
      showRemove,
      progress,
      buttonText,
      hideNoFileMessage
    } = this.props;
    const { isConfirmModalOpen } = this.state;
    const showRemoveFlag = showRemove === undefined ? true : showRemove;
    const showNoFileSelectedMessage = hideNoFileMessage
      ? !hideNoFileMessage
      : true;
    return (
      <>
        <FormGroup>
          <Label for={inputName}>{labelDisplay}</Label>
          <Row className="mt-2">
            <Col md="auto" className="choose-file-column">
              <Button
                id={inputName}
                size="lg"
                onClick={this.clickUploadButton}
                disabled={disabled}
              >
                <Icon icon="plus" />
                &nbsp;{buttonText ? buttonText : 'Choose File'}
              </Button>
              <FileBrowser
                onRef={(ref) => (this.fileBrowser = ref)}
                uploaderId={inputName}
                onSelect={onClickChoose}
                acceptedFileTypes={acceptedFileTypes}
              />
            </Col>
            <Col md="auto" className="mt-2 file-column">
              <Row>
                <Col>
                  <h4 className="file-label" title={fileLabel}>
                    {showLoading ? (
                      <>
                        <Icon icon="circle-notch" spin className="mr-3" />
                        {fileLabel}
                      </>
                    ) : fileLabel ? (
                      <span
                        className={fileUrl ? 'clickable' : ''}
                        onClick={() => {
                          if (fileUrl) {
                            // Handle documents that have only been uploaded locally to client and not to a server.
                            // Refer to this fileblob URL explanation: https://stackoverflow.com/a/16179887
                            // Refer to this for iframe explanation: https://stackoverflow.com/q/45493234
                            if (fileUrl.startsWith('blob')) {
                              const xhr = new XMLHttpRequest();
                              xhr.responseType = 'blob';
                              xhr.onload = () => {
                                const recoveredBlob = xhr.response;
                                const reader = new FileReader();
                                reader.onload = () => {
                                  const blobAsDataUrl = reader.result;
                                  const newWindow = window.open(
                                    '/loading',
                                    fileLabel
                                  );
                                  newWindow.document.write(
                                    `<iframe width='100%' height='100%' src='${blobAsDataUrl}'></iframe>`
                                  );
                                };
                                reader.readAsDataURL(recoveredBlob);
                              };
                              xhr.open('GET', fileUrl);
                              xhr.send();
                            } else {
                              window.open(fileUrl, '_blank');
                            }
                          }
                        }}
                      >
                        <Icon icon="paperclip" className="mr-3" />
                        {fileLabel}
                      </span>
                    ) : (
                      showNoFileSelectedMessage && 'No file selected'
                    )}
                  </h4>
                </Col>
              </Row>
              {showProgress && (
                <Row className="mt-2">
                  <Col md="12">
                    <Progress className="platform" value={progress} />
                  </Col>
                </Row>
              )}
            </Col>
            {showRemoveFlag && (
              <Col md="auto" className="mt-1 remove-file-column">
                <Button
                  id={`${inputName}_remove`}
                  size="sm"
                  color="link"
                  className="remove-link"
                  onClick={this.toggleConfirmModal}
                  hidden={!fileLabel || showLoading || disabled}
                >
                  remove
                </Button>
              </Col>
            )}
          </Row>
          {feedback && <FormFeedback>{feedback}</FormFeedback>}
        </FormGroup>
        <ConfirmationBox
          message={`Are you sure want to remove the document titled ${fileLabel}?`}
          isOpen={isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
          confirmText={
            <>
              <Icon icon="trash-alt" className="mr-2" />
              Remove
            </>
          }
          confirmCallback={() => {
            onClickRemove();
            this.toggleConfirmModal();
          }}
        />
      </>
    );
  }
}
