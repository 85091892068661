import * as BenguidePages from './index';

const BEN_GUIDES_PATH = '/brokers/:brokerId/employers/:employerId/benguides';
const BEN_GUIDES_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/benguides/create/plan';
const BEN_GUIDES_EDIT_PLAN_PATH =
  '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/edit/plan';
const BEN_GUIDES_EDIT_CUSTOM_PATH =
  '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/edit/custom';
const BEN_GUIDES_EDIT_OTHER_PATH =
  '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/edit/other';
const BEN_GUIDES_EDIT_PREVIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/edit/preview';
const BEN_GUIDES_CLONE_PLAN_PATH =
  '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/clone/plan';
const BEN_GUIDES_CLONE_CUSTOM_PATH =
  '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/clone/custom';
const BEN_GUIDES_CLONE_OTHER_PATH =
  '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/clone/other';
const BEN_GUIDES_CLONE_PREVIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/clone/preview';
const BEN_GUIDES_VIEW_PLAN_PATH =
  '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/:type/plan';
const BEN_GUIDES_VIEW_CUSTOM_PATH =
  '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/:type/custom';
const BEN_GUIDES_VIEW_OTHER_PATH =
  '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/:type/other';
const BEN_GUIDES_VIEW_PREVIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/benguides/:benGuideId/:type/preview';
export {
  BEN_GUIDES_CREATE_PATH,
  BEN_GUIDES_EDIT_CUSTOM_PATH,
  BEN_GUIDES_EDIT_OTHER_PATH,
  BEN_GUIDES_EDIT_PLAN_PATH,
  BEN_GUIDES_EDIT_PREVIEW_PATH,
  BEN_GUIDES_CLONE_PLAN_PATH,
  BEN_GUIDES_CLONE_CUSTOM_PATH,
  BEN_GUIDES_CLONE_OTHER_PATH,
  BEN_GUIDES_CLONE_PREVIEW_PATH,
  BEN_GUIDES_PATH,
  BEN_GUIDES_VIEW_PLAN_PATH,
  BEN_GUIDES_VIEW_CUSTOM_PATH,
  BEN_GUIDES_VIEW_OTHER_PATH,
  BEN_GUIDES_VIEW_PREVIEW_PATH
};
const BenguidePathMapping = [
  { path: BEN_GUIDES_CREATE_PATH, component: BenguidePages.CreateBenGuide },
  { path: BEN_GUIDES_EDIT_CUSTOM_PATH, component: BenguidePages.Customization },
  { path: BEN_GUIDES_EDIT_OTHER_PATH, component: BenguidePages.Other },
  { path: BEN_GUIDES_EDIT_PLAN_PATH, component: BenguidePages.CreateBenGuide },
  { path: BEN_GUIDES_EDIT_PREVIEW_PATH, component: BenguidePages.Preview },
  { path: BEN_GUIDES_CLONE_PLAN_PATH, component: BenguidePages.CreateBenGuide },
  { path: BEN_GUIDES_PATH, component: BenguidePages.BenGuideList },
  { path: BEN_GUIDES_VIEW_CUSTOM_PATH, component: BenguidePages.Customization },
  { path: BEN_GUIDES_VIEW_OTHER_PATH, component: BenguidePages.Other },
  { path: BEN_GUIDES_VIEW_PLAN_PATH, component: BenguidePages.CreateBenGuide },
  { path: BEN_GUIDES_VIEW_PREVIEW_PATH, component: BenguidePages.Preview }
];
export default BenguidePathMapping;
