export const isValidUrl = (string) => {
  try {
    return string.match(/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g) != null;
  } catch (_) {
    return false;
  }
};

/**
 * Determines if the admin console is running on production or not by
 * considering the https://admin-console.lumity.com as the
 * production hosted URL
 */
export const isInProduction = () => {
  const host = window.location.host;
  // Ignore localhost
  if (!host.includes('localhost')) {
    // Matches for *(dev, test, stage, demo)-admin-console
    let matchResult = host.match('.+?(?=-admin-console)');
    if (!matchResult) {
      // In prod!
      return true;
    }
  }
  // Not in prod!
  return false;
};

export const getUrlParameter = (name: string) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  let results = regex.exec(window.location.search) || '';
  return results && decodeURIComponent(results[1].replace(/\+/g, ' '));
};
