/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
export const GET_EMPLOYERS_BILLING_PAGE = 'GET_EMPLOYERS_BILLING_PAGE';
export const GET_EMPLOYERS_BILLING_PAGE_ERROR =
  'GET_EMPLOYERS_BILLING_PAGE_ERROR';
export const FILE_TYPE_LIST = 'FILE_TYPE_LIST';
export const UPLOAD_BILLING_FILE_IN_PROGRESS =
  'UPLOAD_BILLING_FILE_IN_PROGRESS';
export const UPLOAD_BILLING_FILE_SUCCESS = 'UPLOAD_BILLING_FILE_SUCCESS';
export const UPLOAD_BILLING_FILE_ERROR = 'UPLOAD_BILLING_FILE_ERROR';
export const UPLOAD_BILLING_FILE_PROGRESS_NOTIFICATION =
  'UPLOAD_BILLING_FILE_PROGRESS_NOTIFICATION';
export const FILE_TYPE_LIST_ERROR = 'FILE_TYPE_LIST_ERROR';
export const SAVE_DETAIL_BILLING_IN_PROGRESS =
  'SAVE_DETAIL_BILLING_IN_PROGRESS';
export const SAVE_DETAIL_BILLING_SUCCESS = 'SAVE_DETAIL_BILLING_SUCCESS';
export const SAVE_DETAIL_BILLING_ERROR = 'SAVE_DETAIL_BILLING_ERROR';
export const BILLING_HISTORY_SUCCESSS = 'BILLING_HISTORY_SUCCESSS';
export const BILLING_HISTORY_ERROR = 'BILLING_HISTORY_ERROR';
export const UPLOAD_CARRIER_FILE_IN_PROGRESS =
  'UPLOAD_CARRIER_FILE_IN_PROGRESS';
export const UPLOAD_CARRIER_FILE_PROGRESS_NOTIFICATION =
  'UPLOAD_CARRIER_FILE_PROGRESS_NOTIFICATION';
export const UPLOAD_CARRIER_FILE_SUCCESS = 'UPLOAD_CARRIER_FILE_SUCCESS';
export const UPLOAD_CARRIER_FILE_ERROR = 'UPLOAD_CARRIER_FILE_ERROR';
export const DELETE_BILLING_FILE_IN_PROGRESS =
  'DELETE_BILLING_FILE_IN_PROGRESS';
export const DELETE_BILLING_FILE_SUCCESS = 'DELETE_BILLING_FILE_SUCCESS';
export const DELETE_BILLING_FILE_ERROR = 'DELETE_BILLING_FILE_ERROR';
export const SAVE_AND_PUBLISH_BILLING_SUCCESS =
  'SAVE_AND_PUBLISH_BILLING_SUCCESS';
export const SAVE_AND_PUBLISH_BILLING_ERROR = 'SAVE_AND_PUBLISH_BILLING_ERROR';
export const SAVE_AND_PUBLISH_BILLING_IN_PROGRESS =
  'SAVE_AND_PUBLISH_BILLING_IN_PROGRESS';

export const GET_BILLING_PREVIEW_DATA_SUCCESS =
  'GET_BILLING_PREVIEW_DATA_SUCCESS';
export const GET_BILLING_PREVIEW_DATA_ERROR = 'GET_BILLING_PREVIEW_DATA_ERROR';
export const GET_BILLING_PREVIEW_DATA_IN_PROGRESS =
  'GET_BILLING_PREVIEW_DATA_IN_PROGRESS';

export const PUBLISH_FROM_GRID_SUCCESS = 'PUBLISH_FROM_GRID_UCCESS';
export const PUBLISH_FROM_GRID_ERROR = 'PUBLISH_FROM_GRID_ERROR';
export const PUBLISH_FROM_GRID_IN_PROGRESS = 'PUBLISH_FROM_GRID_IN_PROGRESS';

export const DOWNLOAD_BILLING_FILE_SUCCESS = 'DOWNLOAD_BILLING_FILE_SUCCESS';
export const DOWNLOAD_BILLING_FILE_ERROR = 'DOWNLOAD_BILLING_FILE_ERROR';
export const DOWNLOAD_BILLING_FILE_IN_PROGRESS =
  'DOWNLOAD_BILLING_FILE_IN_PROGRESS';

export const DOWNLOAD_HISTORY_BILLING_FILE_IN_PROGRESS =
  'DOWNLOAD_HISTORY_BILLING_FILE_IN_PROGRESS';
export const DOWNLOAD_HISTORY_BILLING_FILE_SUCCESS =
  'DOWNLOAD_HISTORY_BILLING_FILE_SUCCESS';
export const DOWNLOAD_HISTORY_BILLING_FILE_ERROR =
  'DOWNLOAD_HISTORY_BILLING_FILE_ERROR';

export const FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES =
  'FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES';
export const FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR =
  'FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR';
export const CREATE_BILLING_EMPLOYER_CONFIGURATION_MODULES =
  'CREATE_BILLING_EMPLOYER_CONFIGURATION_MODULES';
export const CREATE_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR =
  'CREATE_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR';
export const UPDATE_BILLING_EMPLOYER_CONFIGURATION_MODULES =
  'UPDATE_BILLING_EMPLOYER_CONFIGURATION_MODULES';
export const UPDATE_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR =
  'UPDATE_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR';
