/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ConfigureDocument from './components/ConfigureDocument/ConfigureDocument';
import DataTableWithServerPagination from 'components/DataTables';
import moment from 'moment';
import { DOCUMENTS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { permitIf } from 'components/hoc/Permit';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from 'components/Atoms';
import {
  LOGIN_TYPE_INTERNAL,
  LOGIN_TYPE_EXTERNAL
} from '../../../constants/authConstants';
import Icon, { DocumentIcon } from 'components/Icons';
import ConfirmationBox from 'components/DialogBox/ConfirmationBox/ConfirmationBox';
import {
  deleteDocument,
  getDocumentV2EnableStatus,
  getOtherDocuments,
  renameDocument,
  saveFolder,
  uploadFile,
  updateDocumentV2EnableStatus,
  checkDocumentExist,
  clearDocumentCheckExists
} from '../actions/documentActions';
import './DocumentV2List.scss';
import TextFormGroup from 'components/DataForm/TextFormGroup';
import NotificationManager from 'components/Notifications';
import DocumentService from 'services/DocumentService';
import { getReadableFileSizeString } from 'util/documentUtil';
import { DOCUMENT_ICONS } from 'constants/documentConstants';
import AddButton from 'components/Buttons/AddButton';
import TwoActionConfirmationBox from 'components/DialogBox/TwoActionConfirmationBox/TwoActionConfirmationBox';
import FullscreenModal from 'components/FullscreenModal';
import PlanYearFilter from 'modules/Plans/pages/components/PlanYearFilter';
import PlanYearField from 'modules/Plans/pages/components/PlanYearField';
import { getPlanYears } from 'modules/Employers/actions/planYearActions';
import { getCurrentAndNewPlanYearIds } from 'modules/Plans/util/planServiceUtil';
import _ from 'lodash';

const CUSTOM_STYLES = {
  headCells: {
    inactiveSortStyle: {
      opacity: 1
    }
  }
};
class DocumentV2List extends React.Component {
  static defaultProps = {
    maxFileSize: 104858000 // 100MB
  };

  constructor(props) {
    super(props);
    this.fileUploader = null;
    this.state = {
      isRemoveDocumentOpen: false,
      removedDocumentName: '',
      sourceDocumentId: null,
      query: { employerId: this.props.match.params.employerId },
      currentParentFolderId: null,
      isCreateFolderPopupOpen: false,
      isRenameDocumentPopupOpen: false,
      newFolderName: '',
      fileName: '',
      renameDocumentName: '',
      renameFileExtension: '',
      renameDocId: null,
      isDocEnabled: false,
      planYearTitleEditable: false,
      planYearTitle: '',
      isOverwriteConfirmationOpen: false,
      selectedFileName: '',
      uploadData: null,
      documentFeatureEnabled: false,
      isRootDocumentCreated: true,
      isRemovedDocumentFile: false,
      searchQuery: '',
      selectedPlanYearIds: '',
      isPlanYearsValidated: false,
      duplicated: false,
      replaced: false
    };
  }

  componentDidMount() {
    this.checkStatus();
    const { match, getPlanYears } = this.props;
    const { params } = match;
    const { employerId } = params;
    getPlanYears(employerId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.query !== this.state.query && this.documentList) {
      this.documentList.triggerReload();
    }
    if (this.props.planYears !== prevProps.planYears) {
      this.updateTypeFilter(getCurrentAndNewPlanYearIds(this.props.planYears));
    }
    if (
      this.props.isPlanYearFetching !== prevProps.isPlanYearFetching &&
      !this.props.isPlanYearFetching &&
      _.isEmpty(this.props.planYears)
    ) {
      this.setState({ isPlanYearsValidated: true });
    }
    if (
      !this.props.uploadInProgress &&
      this.props.uploadInProgress !== prevProps.uploadInProgress &&
      !this.props.uploadDocumentError &&
      !this.props.documentNameExist &&
      this.state.uploadData === null
    ) {
      this.documentList.triggerReload();
      NotificationManager.success('File uploaded successfully');
    }
    if (this.props.createdFolder !== prevProps.createdFolder) {
      this.documentList.triggerReload();
      this.setState({ isCreateFolderPopupOpen: false, newFolderName: '' });
      if (!this.props.folderCreateError) {
        NotificationManager.success('Folder created successfully');
      } else {
        const { newFolderName } = this.state;
        if (
          this.props.folderCreateError.response &&
          this.props.folderCreateError.response.statusText === 'Conflict'
        ) {
          NotificationManager.error(
            `Can't create duplicate folder ${newFolderName}`
          );
        } else {
          NotificationManager.error(`Unable to create folder ${newFolderName}`);
        }
      }
    }
    if (
      this.props.renamedDocument !== prevProps.renamedDocument &&
      this.props.renamedDocument
    ) {
      this.documentList.triggerReload();
      this.setState({
        isRenameDocumentPopupOpen: false,
        renameDocumentName: '',
        renameDocId: null,
        renameFileExtension: ''
      });
      if (!this.props.renameDocError) {
        NotificationManager.success('Document renamed successfully');
      }
    }
    if (
      this.props.renameDocError !== prevProps.renameDocError &&
      this.props.renameDocError
    ) {
      NotificationManager.error('Unable to rename the Document.');
    }
    if (this.props.deletedDoc !== prevProps.deletedDoc) {
      if (this.props.deletedDoc) {
        NotificationManager.success(
          `Your ${
            this.state.isRemovedDocumentFile ? 'file' : 'folder'
          } was deleted successfully`
        );
      }
      if (this.props.deletedDocError) {
        NotificationManager.error(
          `Unable to delete ${
            this.state.isRemovedDocumentFile ? 'file' : 'folder'
          }`
        );
      }
      this.documentList.triggerReload();
    }
    if (this.props.documentFeatureConfig !== prevProps.documentFeatureConfig) {
      this.setState({
        planYearTitle: this.props.documentFeatureConfig.documentRootTitle,
        documentFeatureEnabled: this.props.documentFeatureConfig.enabled,
        isRootDocumentCreated:
          this.props.documentFeatureConfig.documentRootTitle !== null &&
          this.props.documentFeatureConfig.documentRootTitle !== ''
      });
      if (this.props.documentFeatureConfig.enabled && this.documentList) {
        this.documentList.triggerReload();
      }
    }
    if (this.props.documentNameExist !== prevProps.documentNameExist) {
      this.validateFileUpload();
    }
    if (
      !this.props.uploadInProgress &&
      this.props.uploadInProgress !== prevProps.uploadInProgress &&
      this.props.uploadDocumentError &&
      this.props.uploadDocumentError.message &&
      this.props.uploadDocumentError.message !== "Cannot read properties of null (reading 'file')"
    ) {
      NotificationManager.error('Unable to upload the selected document');
    }
  }

  checkStatus = () => {
    const { employerId } = this.props.match.params;
    this.props.getDocumentV2EnableStatus(employerId);
  };

  openFolder = (document) => {
    if (!document.file) {
      this.setState({
        currentParentFolderId: document.documentId,
        query: {
          employerId: this.props.match.params.employerId,
          documentId: document.documentId
        },
        searchQuery: ''
      });
      this.documentList.resetSearch();
    }
  };

  selectFile = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name;
      this.setState({ selectedFileName: fileName });
      const fileBlob = new Blob([selectedFile], { type: selectedFile.type });

      const { maxFileSize } = this.props;
      if (maxFileSize < fileBlob.size) {
        const fileSizeStr = getReadableFileSizeString(fileBlob.size);
        const maxFileSizeStr = getReadableFileSizeString(maxFileSize);
        NotificationManager.error(
          `File must be small than ${maxFileSizeStr}. This file is ${fileSizeStr}`
        );
        return;
      } else {
        const { currentParentFolderId } = this.state;
        const { employerId } = this.props.match.params;

        let data = {
          employerId: employerId,
          sourceDocumentId: currentParentFolderId,
          file: fileBlob,
          fileName: fileName
        };
        this.setState({ uploadData: data });
        this.props.checkDocumentExist(
          employerId,
          currentParentFolderId,
          fileName
        );
      }
    }
  };

  replaceFile = () => {
    this.setState({ isOverwriteConfirmationOpen: false, replaced: true });
    this.uploadDocument(false);
  };

  duplicateFile = () => {
    this.setState({ isOverwriteConfirmationOpen: false });
    this.uploadDocument(true);
  };

  cancelFileConfirmation = () => {
    this.props.clearDocumentCheckExists(true);
    this.setState({ isOverwriteConfirmationOpen: false, uploadData: null });
  };

  validateFileUpload = () => {
    const fileExist = this.props.documentNameExist;
    if (fileExist === null) {
      return;
    }
    if (fileExist) {
      this.toggleOverwriteConfirmWindow();
    } else {
      this.uploadDocument(false);
    }
  };

  uploadDocument = (duplicate) => {
    const { uploadData } = this.state;
    this.props.uploadFile(
      uploadData,
      duplicate,
      this.documentList.triggerReload
    );
    this.setState({ uploadData: null, duplicated: duplicate });
  };

  getPageActions = () => {
    const { currentParentFolderId, duplicated, replaced } = this.state;
    const { uploadInProgress, documentsPageData, documentNameExist } = this.props;
    const { parentDocument } = documentsPageData;
    const SecuredAddButton = permitIf(
      AddButton,
      DOCUMENTS_FEATURE_ROLE_MAPPING.common
    );
    if (currentParentFolderId) {
      if (
        parentDocument &&
        parentDocument.extendableWithFolder &&
        !parentDocument.extendableWithFile
      ) {
        return (
          <SecuredAddButton
            onClick={() => this.setState({ isCreateFolderPopupOpen: true })}
            title="Add New Folder"
            iconType="plus"
          />
        );
      } else {
        return (
          <div className="doc-action-btn-group">
            {parentDocument && parentDocument.extendableWithFile && (
              <div>
                {' '}
                <div className="upload-file-section">
                  <SecuredAddButton
                    onClick={() => this.clickUploadButton()}
                    title="Upload Document"
                    iconType="plus"
                    loading={
                      uploadInProgress &&
                      (!documentNameExist || duplicated || replaced)
                    }
                    disabled={uploadInProgress}
                  />
                </div>
                <input
                  type="file"
                  id="document_file_browser"
                  ref={(e) => (this.fileUploader = e)}
                  onInput={(event) => {
                    this.selectFile(event);
                    this.fileUploader.value = null;
                  }}
                  accept={
                    '.pdf, .docx , .xlsx , .ppt , .pptx , .doc , .xls, .csv, .jpeg, .jpg, .png, .mp4'
                  }
                  hidden={true}
                />
              </div>
            )}
            {parentDocument && parentDocument.extendableWithFolder && (
              <span
                className="add-new-folder_link"
                onClick={() => this.setState({ isCreateFolderPopupOpen: true })}
              >
                Add New Folder
              </span>
            )}
          </div>
        );
      }
    }
  };
  clickUploadButton() {
    this.props.clearDocumentCheckExists(false);
    this.fileUploader.click();
  }

  deleteDocument = (documentId) => {
    const { deleteDocument } = this.props;
    deleteDocument(documentId);
  };

  openDocumentRemoveConfirmation = (document) => {
    this.setState({
      isRemoveDocumentOpen: true,
      removedDocumentId: document.documentId,
      removedDocumentName: document.name,
      isRemovedDocumentFile: document.file
    });
  };

  toggleRemoveDocumentConfirmation = () => {
    this.setState((prevState) => ({
      isRemoveDocumentOpen: !prevState.isRemoveDocumentOpen
    }));
  };

  getCreateFolderPopup = () => {
    const { newFolderName } = this.state;
    const SecuredAddButton = permitIf(
      Button,
      DOCUMENTS_FEATURE_ROLE_MAPPING.common
    );
    return (
      <FullscreenModal
        isOpen={this.state.isCreateFolderPopupOpen}
        toggle={() =>
          this.setState({ isCreateFolderPopupOpen: false, newFolderName: '' })
        }
        title="Add New Folder"
      >
        <div className="add-folder-pop-up">
          <TextFormGroup
            labelDisplay="Folder Name"
            inputValue={newFolderName}
            onChange={(event) =>
              this.setState({ newFolderName: event.target.value })
            }
          />
          <SecuredAddButton
            className="doc-save-btn"
            size="lg"
            color="primary"
            onClick={() => this.onClickSaveFolder()}
            disabled={newFolderName.trim() === ''}
          >
            Save
          </SecuredAddButton>
        </div>
      </FullscreenModal>
    );
  };
  getRenameDocumentPopup = () => {
    const { renameDocumentName } = this.state;
    return (
      <FullscreenModal
        isOpen={this.state.isRenameDocumentPopupOpen}
        toggle={() => this.setState({ isRenameDocumentPopupOpen: false })}
        title="Rename"
      >
        <div className="add-folder-pop-up">
          <TextFormGroup
            labelDisplay="New Name"
            inputValue={renameDocumentName}
            onChange={(event) =>
              this.setState({ renameDocumentName: event.target.value })
            }
          />
          <Button
            className="doc-save-btn"
            size="lg"
            color="primary"
            onClick={() => this.onClickRename()}
            disabled={renameDocumentName.trim() === ''}
          >
            Save
          </Button>
        </div>
      </FullscreenModal>
    );
  };
  onClickSaveFolder = () => {
    const { newFolderName, currentParentFolderId } = this.state;
    const { employerId } = this.props.match.params;
    const { saveFolder } = this.props;
    let data = {
      employerId: employerId,
      sourceDocumentId: currentParentFolderId,
      folderName: newFolderName
    };
    if (currentParentFolderId) {
      saveFolder(data);
    }
  };

  onClickRename = () => {
    const {
      renameDocumentName,
      renameDocId,
      renameFileExtension,
      currentParentFolderId
    } = this.state;
    const { renameDocument } = this.props;
    let fileName =
      renameFileExtension.trim() === ''
        ? renameDocumentName
        : `${renameDocumentName}.${renameFileExtension}`;
    let data = {
      documentId: renameDocId,
      name: fileName,
      parentDocumentId: currentParentFolderId
    };
    renameDocument(data);
  };

  getRemoveDocumentConfirmation = () => {
    const {
      isRemoveDocumentOpen,
      removedDocumentName,
      removedDocumentId
    } = this.state;
    return (
      <ConfirmationBox
        message={`Are you sure you want to delete ${removedDocumentName}?`}
        isOpen={isRemoveDocumentOpen}
        toggle={() => this.toggleRemoveDocumentConfirmation()}
        confirmText={
          <>
            <Icon icon="trash-alt" className="mr-2" />
            Delete
          </>
        }
        confirmCallback={() => {
          this.deleteDocument(removedDocumentId);
          this.toggleRemoveDocumentConfirmation();
        }}
      />
    );
  };
  showIcon = (row) => {
    return (
      <div
        auto_id="document-name"
        className="row document-name"
        onClick={() => this.openFolder(row)}
      >
        <div className={`col-1 ${!row.file ? 'doc-cursor' : 'file-cursor'}`}>
          {row.file ? (
            <DocumentIcon
              type={this.getFileIcon(row.fileName)}
              className={`doc-icon ${this.getFileIcon(row.fileName)}`}
            />
          ) : (
            <DocumentIcon
              className="doc-icon doc-folder-color"
              theme="filled"
              type={DOCUMENT_ICONS.folder}
            />
          )}
        </div>
        <div className={`col ${!row.file ? 'doc-cursor' : 'file-cursor'}`}>
          <span className="doc-folder-name text bold">{row.name}</span>
        </div>
      </div>
    );
  };

  getFileIcon = (fileName) => {
    let iconUrl = DOCUMENT_ICONS.unknown;
    Object.keys(DOCUMENT_ICONS).forEach(function(iconType) {
      if (
        fileName
          .split('.')
          .pop()
          .toLowerCase() === iconType
      ) {
        iconUrl = DOCUMENT_ICONS[iconType];
      }
    });
    return iconUrl;
  };
  openDocRenamePopup = (row) => {
    let docName = row.name;
    let docFileExt = '';
    if (row.file) {
      docName = row.name
        .split('.')
        .slice(0, -1)
        .join('.');
      docFileExt = row.name.split('.').pop();
    }
    this.setState({
      isRenameDocumentPopupOpen: true,
      renameDocumentName: docName,
      renameFileExtension: docFileExt,
      renameDocId: row.documentId
    });
  };
  downloadFile = async (row) => {
    if (row.file) {
      let documentExist = await DocumentService.findDocumentByDocumentId(
        row.documentId
      );
      if (documentExist.data) {
        window.location.href = DocumentService.getDocumentDownloadUrl(
          row.documentId
        );
      } else {
        NotificationManager.error(
          `Unable to download ${row.fileName} document.`
        );
      }
    }
  };

  toggleOverwriteConfirmWindow = () => {
    this.setState((prevState) => ({
      isOverwriteConfirmationOpen: !prevState.isOverwriteConfirmationOpen
    }));
  };

  updateTypeFilter = (types) => {
    this.setState({ selectedPlanYearIds: types }, () => {
      if (this.documentList) {
        this.documentList.triggerReload();
      }
    });
  };

  columnData = (planYears, documentsPageData, selectedPlanYearIds) => [
    {
      name: 'File Name',
      selector: 'name',
      sortable: true,
      cell: (row) => this.showIcon(row),
      grow: 1.7,
      maxWidth: '800px',
      wrap: true
    },
    {
      name: (
        <PlanYearFilter
          planYears={planYears}
          onFilterUpdate={this.updateTypeFilter}
          pageData={documentsPageData}
          selectedPlanYearIds={selectedPlanYearIds}
        />
      ),
      selector: 'planYear',
      grow: 1,
      wrap: true,
      omit: this.state.currentParentFolderId !== null,
      cell: (row) =>
        row.planYearId ? (
          <PlanYearField
            row={row}
            planYears={planYears || {}}
            documentId={row.documentId}
          />
        ) : (
          '-'
        )
    },
    {
      name: 'Size',
      selector: (row) => (row.file ? 'fileSize' : 'fileCount'),
      cell: (row) =>
        row.file ? getReadableFileSizeString(row.fileSize) : `${row.fileCount} Files`,
      grow: 0.7,
      wrap: true
    },
    {
      name: 'Date Added',
      selector: 'createdTs',
      cell: (row) => moment(row.createdTs).format('ll'),
      grow: 0.7,
      wrap: true
    },
    {
      name: 'Uploaded By',
      selector: 'uploaderLoginType',
      cell: (row) => {
        const { employerName } = this.props;
        switch (row.uploaderLoginType) {
          case LOGIN_TYPE_INTERNAL:
            return 'Lumity';
          case LOGIN_TYPE_EXTERNAL:
            return employerName;
          default:
            return <>&mdash;</>;
        }
      },
      grow: 0.8,
      wrap: true
    },
    {
      name: 'Actions',
      selector: '',
      cell: (row) => {
        if (row.file || row.editable || row.deletable) {
          return (
            <div>
              <UncontrolledDropdown auto_id="actions-dropdown">
                <DropdownToggle tag="a" className="cursor-pointer" caret>
                  Select
                </DropdownToggle>
                <DropdownMenu>
                  {row.file && (
                    <DropdownItem
                      onClick={() => {
                        this.downloadFile(row);
                      }}
                    >
                      Download
                    </DropdownItem>
                  )}
                  {row.editable && (
                    <DropdownItem onClick={() => this.openDocRenamePopup(row)}>
                      Rename
                    </DropdownItem>
                  )}
                  {row.deletable && (
                    <DropdownItem
                      onClick={() => this.openDocumentRemoveConfirmation(row)}
                    >
                      Delete
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          );
        }
      },
      ignoreRowClick: true,
      allowOverflow: true,
      width: '66px'
    }
  ];

  getBreadcrumbs = () => {
    const { documentsPageData } = this.props;
    const { employerId } = this.props.match.params;
    const { searchQuery, selectedPlanYearIds } = this.state;
    return (
      <Breadcrumb>
        <Icon icon="caret-right" className="align-self-center bg-theme-color" />
        {_.isEmpty(searchQuery) && (
          <BreadcrumbItem
            className="ml-1"
            active={true}
            onClick={() =>
              this.setState({
                query: {
                  employerId: employerId
                },
                currentParentFolderId: null
              })
            }
          >
            <Icon icon="home" className="doc-cursor" />
          </BreadcrumbItem>
        )}
        {_.isEmpty(searchQuery) &&
          documentsPageData &&
          documentsPageData.path &&
          documentsPageData.path.map((p, index) => {
            let documentId = null;
            if (documentsPageData.materializedPath.length > 0) {
              let formattedMaterializedPAth = documentsPageData.materializedPath.replace(
                ',',
                ''
              );
              let idArr = formattedMaterializedPAth.split(',');
              documentId = idArr[index];
            }
            return (
              <BreadcrumbItem active key={index}>
                <span
                  className="add-new-folder_link"
                  onClick={() =>
                    this.setState({
                      query: { employerId: employerId, documentId: documentId },
                      currentParentFolderId: documentId
                    })
                  }
                >
                  {p}
                </span>
              </BreadcrumbItem>
            );
          })}
      </Breadcrumb>
    );
  };

  switchDocumentEnabled = async () => {
    const { isRootDocumentCreated } = this.state;
    this.setState((prevState) => ({
      documentFeatureEnabled: !prevState.documentFeatureEnabled
    }));

    const { planYearTitle } = this.state;
    const { employerId } = this.props.match.params;
    const { documentFeatureConfig } = this.props;
    const data = {
      enabled: !documentFeatureConfig.enabled,
      documentRootTitle: planYearTitle
    };
    this.props.updateDocumentV2EnableStatus(employerId, data);
  };

  getDocumentsList = (pageNumber, rowsPerPage, sortField, search) => {
    const { query, selectedPlanYearIds, currentParentFolderId } = this.state;
    const { planYears } = this.props;

    const defaultPlanYearIds = planYears.map((planYear) => planYear.id);
    query.planYearIds = _.isEmpty(selectedPlanYearIds)
      ? _.isEmpty(defaultPlanYearIds)
        ? selectedPlanYearIds
        : defaultPlanYearIds
      : selectedPlanYearIds;
    this.setState({
      searchQuery: search
    });
    if (currentParentFolderId !== null && sortField === 'planYear') {
      sortField = 'name';
    }
    this.props.getOtherDocuments(
      pageNumber,
      rowsPerPage,
      sortField,
      query,
      search || ''
    );
  };

  render() {
    const { documentsPageData, documentFeatureConfig, planYears } = this.props;
    const {
      planYearTitle,
      isOverwriteConfirmationOpen,
      selectedFileName,
      currentParentFolderId,
      documentFeatureEnabled,
      isRootDocumentCreated,
      selectedPlanYearIds,
      isPlanYearsValidated
    } = this.state;
    return (
      <ApplicationWrapper>
        <HeaderContainer auto_id="documents" title="Documents" />
        <div className="document-bc">{this.getBreadcrumbs()}</div>
        <Row>
          <Col className="page-content" xs="auto">
            {!currentParentFolderId && (
              <ConfigureDocument
                switchDocumentEnabled={this.switchDocumentEnabled}
                config={documentFeatureConfig}
                planYearTitle={planYearTitle}
                documentFeatureEnabled={documentFeatureEnabled}
                isRootDocumentCreated={isRootDocumentCreated}
              />
            )}
            {(selectedPlanYearIds !== '' || isPlanYearsValidated) && (
              <div className="document-list-custom-styling">
                <ContentContainer>
                  <DataTableWithServerPagination
                    onRef={(ref) => (this.documentList = ref)}
                    columnData={this.columnData(
                      planYears,
                      documentsPageData,
                      selectedPlanYearIds
                    )}
                    fetchData={(pageNumber, rowsPerPage, sortField, search) => {
                      this.getDocumentsList(
                        pageNumber,
                        rowsPerPage,
                        sortField,
                        search
                      );
                    }}
                    pageData={documentsPageData}
                    onRowClicked={(row) => this.openFolder(row)}
                    hideSearchBar={false}
                    customStyle={CUSTOM_STYLES}
                    persistTableHead={true}
                    defaultSortField="planYear"
                  />
                </ContentContainer>
              </div>
            )}
          </Col>
          <Col className="page-actions" xs="auto">
            {this.getPageActions()}
          </Col>
        </Row>
        {this.getRemoveDocumentConfirmation()}
        {this.getCreateFolderPopup()}
        {this.getRenameDocumentPopup()}
        <TwoActionConfirmationBox
          isOpen={isOverwriteConfirmationOpen}
          toggle={this.toggleOverwriteConfirmWindow}
          message={`${selectedFileName} already exists. Do you want to duplicate or replace the file?`}
          actionOneText={'Duplicate'}
          actionTwoText={'Replace'}
          actionOneCallback={this.duplicateFile}
          actionTwoCallback={this.replaceFile}
          cancelActionCallback={this.cancelFileConfirmation}
        />
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getOtherDocuments: (page, size, sort, query, search) =>
    dispatch(getOtherDocuments(page, size, sort, query, search)),
  saveFolder: (data) => dispatch(saveFolder(data)),
  uploadFile: (data, duplicate) => dispatch(uploadFile(data, duplicate)),
  deleteDocument: (documentId, callBack) =>
    dispatch(deleteDocument(documentId)),
  renameDocument: (data) => dispatch(renameDocument(data)),
  getDocumentV2EnableStatus: (employerId) =>
    dispatch(getDocumentV2EnableStatus(employerId)),
  updateDocumentV2EnableStatus: (employerId, data) =>
    dispatch(updateDocumentV2EnableStatus(employerId, data)),
  checkDocumentExist: (employerId, parentDocumentId, documentName) =>
    dispatch(checkDocumentExist(employerId, parentDocumentId, documentName)),
  clearDocumentCheckExists: (isCleared) => dispatch(clearDocumentCheckExists(isCleared)),
  getPlanYears: (employerId) => dispatch(getPlanYears(employerId))
});

const mapStateToProps = (state) => {
  const {
    documentsPageData,
    documentFeatureConfig,
    documentNameExist,
    uploadDocument,
    renameDocument,
    createFolder,
    deleteDocument
  } = state.documentReducer.documentReducer;
  const { name: employerName } = state.AppBase.contextReducer.employerContext;
  const { planYears } = state.Employers.planYearReducer;
  return {
    documentsPageData,
    uploadInProgress: uploadDocument.inProgress,
    uploadDocumentError: uploadDocument.error,
    uploadedDocument: uploadDocument.document,
    createdFolder: createFolder.folder,
    folderCreateError: createFolder.error,
    renamedDocument: renameDocument.document,
    renameDocError: renameDocument.error,
    deletedDoc: deleteDocument.document,
    deletedDocError: deleteDocument.error,
    documentFeatureConfig,
    documentNameExist,
    employerName,
    planYears: planYears.data,
    isPlanYearFetching: planYears.isFetching
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentV2List);
