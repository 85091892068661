import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Atoms';
import Icon from 'components/Icons';

import './RemoveRowButton.scss';

const RemoveRowButton = (props) => {
  const { className, children, icon, onClick, disabled } = props;
  return (
    <Button
      color="link"
      disabled={disabled}
      className={`remove-row-button ${className || ''}`}
    >
      <Icon
        icon={icon}
        className={`remove-row-button-contents ${
          children ? 'icon-margin-right' : ''
        }`}
        onClick={!disabled ? onClick : null}
        disabled={disabled}
      />
      {children && (
        <div
          onClick={!disabled ? onClick : null}
          className="remove-row-button-contents"
        >
          {children}
        </div>
      )}
    </Button>
  );
};

RemoveRowButton.propTypes = {
  icon: PropTypes.string,
  // Expects event function
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

RemoveRowButton.defaultProps = {
  icon: 'minus-circle',
  onClick: () => {},
  disabled: false
};

export default RemoveRowButton;
