/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';

import './AgGrid.scss';

/**
 *  Grid properties are overrideable by simply passing via props. They will get overridden when spread with ...rest.
 *    - Documentation: https://www.ag-grid.com/javascript-grid-properties/
 *  Column properties should be set via columnDefs.
 *    - Documentation: https://www.ag-grid.com/javascript-grid-column-properties/
 */
export default class AgGrid extends PureComponent {
  static propTypes = {
    // Control the height of the entire table. By default height is null which means the table grows with row content.
    // Table shows white space or scrolls with data to the fixed height if given.
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    headerHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // Toggle whether table should adjust columns to fit. True by default.
    sizeColumnsToFit: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    height: null,
    headerHeight: 50,
    rowHeight: 50,
    sizeColumnsToFit: true
  };

  /**
   *  Directly set grid properties in reaction to events using API calls.
   *    - Introduction: https://www.ag-grid.com/react-grid-apis/
   *    - API list: https://www.ag-grid.com/javascript-grid-api/
   *    - Event list: https://www.ag-grid.com/javascript-grid-events/
   */
  onGridReady = (params) => {
    const { sizeColumnsToFit } = this.props;
    sizeColumnsToFit && params.api.sizeColumnsToFit();
  };

  render() {
    const { className, height, ...rest } = this.props;
    return (
      <div
        className={`ag-theme-material lumity-ag-theme ${className}`}
        style={{ height, width: '100%' }}
      >
        <AgGridReact
          domLayout={height ? 'normal' : 'autoHeight'}
          suppressCellSelection
          suppressMovableColumns
          pagination
          paginationPageSize={10}
          defaultColDef={defaultColDef}
          onGridReady={this.onGridReady}
          {...rest}
        />
      </div>
    );
  }
}

const defaultColDef = {
  resizable: true
};

const AG_GRID_TIMESTAMP_FORMAT = 'MM-DD-YYYY HH:mm';
const AG_GRID_DATE_FORMAT = 'MM-DD-YYYY';
export const DateValueFormatter = (props, includeTime = false) => {
  const { value } = props;

  const convertedDate = moment(value);
  if (!convertedDate.isValid) {
    return '';
  } else {
    return convertedDate.format(
      includeTime ? AG_GRID_TIMESTAMP_FORMAT : AG_GRID_DATE_FORMAT
    );
  }
};
