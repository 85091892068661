/*
 * Copyright (C) 2019 Lumity, Inc. - All Rights Reserved
 *
 * CONFIDENTIAL
 *
 * All information contained herein is, and remains the property of Lumity, Inc. and its partners, if any.
 * The intellectual and technical concepts contained herein are proprietary to Lumity, Inc. and its partners
 * and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless
 * prior written permission is obtained from Lumity, Inc.
 */

//configs
import Util, {
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_MULTIPART_FORM
} from '../util/apiUtil';
import axios from 'axios';
const baseURL = `${Util.baseApi}/v1/analytics`;

export default {
  /**
   * Function to get all insights configs
   * @param employerId
   * @returns {AxiosPromise<any>}
   */
  findInsightsFeatureSummary(employerId) {
    return axios.get(`${baseURL}/employers/${employerId}/features`);
  },
  /**
   * upload Insights file
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  uploadFile(payload) {
    const formData = new FormData();
    formData.append('file', payload.file, payload.fileName);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };
    return axios.post(
      `${baseURL}/employers/${payload.employerId}/parse?&documentType=${payload.documentType}`,
      formData,
      config
    );
  },
  /**
   * Change status of selected chart
   * @param employerId
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  updateChartStatus(employerId, payload) {
    return axios.put(`${baseURL}/employers/${employerId}/features`, payload);
  },

  /**
   *
   * @param employerId
   * @param documentType
   * @param downloadType
   * @returns {string}
   */
  getInsightsFileDownloadUrl(employerId, documentType, downloadType) {
    return `${baseURL}/employers/${employerId}/download?documentType=${documentType}&downloadType=${downloadType}`;
  },

  /**
   * get file history for given insights files
   * @param employerId
   * @param documentType
   * @returns {AxiosPromise<any>}
   */
  getInsightsFileHistory(employerId, documentType) {
    return axios.get(
      `${baseURL}/employers/${employerId}/file-history?documentType=${documentType}`
    );
  },

  /**
   * get histroy file download URL
   * @param employerId
   * @param fileId
   * @returns {string}
   */
  getHistoryFileDownloadUrl(employerId, fileId) {
    return `${baseURL}/employers/${employerId}/file-history/${fileId}/download`;
  },

  /**
   * get all file uploaded years
   * @param employerId
   * @param chartType
   * @returns {AxiosPromise<any>}
   */
  getAllYears() {
    return axios.get(`${baseURL}/employers/years/message`);
  },

  /**
   * save messages
   * @param employerId
   * @param messageData
   * @returns {AxiosPromise<any>}
   */
  saveMessage(messageData, employerId) {
    return axios.post(
      `${baseURL}/employers/${employerId}/message`,
      messageData
    );
  },

  /**
   * get saved messages
   * @param employerId
   * @param year
   * @returns {AxiosPromise<any>}
   */
  getAllMessages(employerId, year) {
    return axios.get(`${baseURL}/employers/${employerId}/message?year=${year}`);
  },

  /**
   * get plans which data already have.
   * @param employerId
   * @param year
   * @returns {AxiosPromise<any>}
   */
  getPlans(employerId, year) {
    return axios.get(
      `${baseURL}/employers/${employerId}/largeClaims/message?year=${year}`
    );
  }
};
