/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/user.actionTypes';

const initialState = {
  adminsList: {
    data: [],
    isFetching: false,
    error: null
  },
  adminUserBasicInfo: {
    data: {
      username: '',
      firstName: '',
      lastName: ''
    },
    isFetching: false,
    isUpdating: false,
    error: null
  },
  adminUserPublicProfile: {
    data: {
      contactEmail: '',
      contactPhone: '',
      title: ''
    },
    isFetching: false,
    isUpdating: false,
    error: null
  },
  createAdminUserStatus: {
    inProgress: false,
    error: null
  },
  archiveAdminUser: {
    inProgress: false,
    archiveError: null
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ADMIN_USER_LIST_IN_PROGRESS:
      return {
        ...state,
        adminsList: {
          ...state.adminsList,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_ADMIN_USER_LIST_COMPLETED:
      return {
        ...state,
        adminsList: {
          ...state.adminsList,
          isFetching: false,
          data: action.data
        }
      };
    case ActionTypes.GET_ADMIN_USER_LIST_FAILED:
      return {
        ...state,
        adminsList: {
          ...state.adminsList,
          isFetching: false,
          error: action.error
        }
      };
    case ActionTypes.GET_ADMIN_USER_IN_PROGRESS:
      return {
        ...state,
        adminUserBasicInfo: {
          ...state.adminUserBasicInfo,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_ADMIN_USER_COMPLETED:
      return {
        ...state,
        adminUserBasicInfo: {
          ...state.adminUserBasicInfo,
          isFetching: false,
          data: action.data
        }
      };
    case ActionTypes.GET_ADMIN_USER_FAILED:
      return {
        ...state,
        adminUserBasicInfo: {
          ...state.adminUserBasicInfo,
          isFetching: false,
          error: action.error
        }
      };
    case ActionTypes.UPDATE_ADMIN_USER_IN_PROGRESS:
      return {
        ...state,
        adminUserBasicInfo: {
          ...state.adminUserBasicInfo,
          isUpdating: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_ADMIN_USER_COMPLETED:
      return {
        ...state,
        adminUserBasicInfo: {
          ...state.adminUserBasicInfo,
          isUpdating: false,
          data: action.data
        }
      };
    case ActionTypes.UPDATE_ADMIN_USER_FAILED:
      return {
        ...state,
        adminUserBasicInfo: {
          ...state.adminUserBasicInfo,
          isUpdating: false,
          error: action.error
        }
      };
    case ActionTypes.GET_PUBLIC_ADMIN_PROFILE_IN_PROGRESS:
      return {
        ...state,
        adminUserPublicProfile: {
          ...state.adminUserPublicProfile,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_PUBLIC_ADMIN_PROFILE_COMPLETED:
      return {
        ...state,
        adminUserPublicProfile: {
          ...state.adminUserPublicProfile,
          isFetching: false,
          data: action.data
        }
      };
    case ActionTypes.GET_PUBLIC_ADMIN_PROFILE_FAILED:
      return {
        ...state,
        adminUserPublicProfile: {
          ...state.adminUserPublicProfile,
          isFetching: false,
          error: action.error
        }
      };
    case ActionTypes.UPDATE_PUBLIC_ADMIN_PROFILE_IN_PROGRESS:
      return {
        ...state,
        adminUserPublicProfile: {
          ...state.adminUserPublicProfile,
          isUpdating: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_PUBLIC_ADMIN_PROFILE_COMPLETED:
      return {
        ...state,
        adminUserPublicProfile: {
          ...state.adminUserPublicProfile,
          isUpdating: false,
          data: action.data
        }
      };
    case ActionTypes.UPDATE_PUBLIC_ADMIN_PROFILE_FAILED:
      return {
        ...state,
        adminUserPublicProfile: {
          ...state.adminUserPublicProfile,
          isUpdating: false,
          error: action.error
        }
      };
    case ActionTypes.CREATE_ADMIN_USER_IN_PROGRESS:
      return {
        ...state,
        createAdminUserStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.CREATE_ADMIN_USER_COMPLETED:
      return {
        ...state,
        createAdminUserStatus: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.CREATE_ADMIN_USER_FAILED:
      return {
        ...state,
        createAdminUserStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.ARCHIVE_ADMIN_USER_IN_PROGRESS:
        return {
          ...state,
          archiveAdminUser: {
            inProgress: true,
            archiveError: null
          }
      };
    case ActionTypes.ARCHIVE_ADMIN_USER_COMPLETED:
        return {
          ...state,
          archiveAdminUser: {
            inProgress: false,
            archiveError: null
          }
      };
    case ActionTypes.ARCHIVE_ADMIN_USER_FAILED:
        return {
          ...state,
          archiveAdminUser: {
            inProgress: false,
            archiveError: action.payload
          }
      };  
    default:
      return {
        ...state
      };
  }
};

export default userReducer;
