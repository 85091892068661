/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import * as _ from 'lodash';
import {
  Button,
  Row,
  Col,
  Input,
  Alert,
  FormGroup,
  Form
} from 'components/Atoms';
import Icon from 'components/Icons';
import { VENDOR_NAMES, ERROR_MSGS } from 'modules/Integrations/constants';

import '../DialogIntegrationDetails.scss';
import { WORKDAY_BENEFIT_GROUP } from './BenefitGroupMapping';

const lumityEmploymentStatus = [
  { name: 'Full Time - Active', value: 'FULL_TIME - ACTIVE' },
  { name: 'Full Time - Terminated', value: 'FULL_TIME - TERMINATED' },
  {
    name: 'Full Time - Leave of Absence',
    value: 'FULL_TIME - LEAVE_OF_ABSENCE'
  },
  { name: 'Part Time - Active', value: 'PART_TIME - ACTIVE' },
  { name: 'Part Time - Terminated', value: 'PART_TIME - TERMINATED' },
  {
    name: 'Part Time - Leave of Absence',
    value: 'PART_TIME - LEAVE_OF_ABSENCE'
  },
  { name: 'Intern - Active', value: 'INTERN - ACTIVE' },
  { name: 'Intern - Terminated', value: 'INTERN - TERMINATED' },
  { name: 'Intern - Leave of Absence', value: 'INTERN - LEAVE_OF_ABSENCE' }
];

export const WORKDAY_EMPLOYER_STATUS = 'workdayEmploymentType';

export const EmploymentStatusRow = ({
  errors,
  workdayEmploymentType,
  workdayHrEmploymentStatus,
  employmentStatusType,
  employmentType,
  employmentStatusMappings,
  updateStatusMapping,
  removeFieldRow,
  index
}) => {
  const fieldErrors = errors[index];
  const selectedEmploymentStatusType = employmentStatusMappings.map(
    (empTypeStatus, index) =>
      `${empTypeStatus.employmentType} - ${empTypeStatus.employmentStatus}`
  );
  return (
    <div>
      <Row>
        <Button
          color="link"
          onClick={removeFieldRow}
          className="remove-group-icon"
        >
          <Icon icon="minus-circle" />
        </Button>
        <Col>
          <FormGroup>
            <Input
              type="text"
              name="workdayEmploymentType"
              onChange={updateStatusMapping}
              value={workdayEmploymentType}
              invalid={!!(fieldErrors && fieldErrors[WORKDAY_EMPLOYER_STATUS])}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Input
              type="text"
              name="workdayHrEmploymentStatus"
              onChange={updateStatusMapping}
              value={workdayHrEmploymentStatus}
              invalid={
                !!(fieldErrors && fieldErrors['workdayHrEmploymentStatus'])
              }
            />
          </FormGroup>
        </Col>
        <Col>
          <Input
            type="select"
            name="employmentStatusType"
            value={employmentStatusType}
            onChange={updateStatusMapping}
            invalid={!!fieldErrors && fieldErrors['employmentStatusType']}
          >
            <option key="select" value="">
              Select Option
            </option>
            {lumityEmploymentStatus.map((field) => {
              return (
                <option
                  key={field.value}
                  value={field.value}
                  disabled={selectedEmploymentStatusType.includes(field.value)}
                >
                  {field.name}
                </option>
              );
            })}
          </Input>
        </Col>
      </Row>
    </div>
  );
};
export class EmploymentStatusConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      employmentStatusMappings:
        props.selectedEmploymentStatusMappings &&
        props.selectedEmploymentStatusMappings.length
          ? props.selectedEmploymentStatusMappings
          : [
              {
                workdayEmploymentType: '',
                workdayHrEmploymentStatus: '',
                employmentType: '',
                employmentStatus: '',
                employmentStatusType: ''
              }
            ]
    };
  }

  updateStatusMapping = (index) => (e) => {
    let { employmentStatusMappings, errors } = this.state;
    const updatedEmploymentStatus = [...employmentStatusMappings];
    const employmentStatus = updatedEmploymentStatus[index];
    const { name, value } = e.target;

    if (name === 'employmentStatusType') {
      const empStatusAndType = value.split(' - ');
      employmentStatus['employmentType'] = empStatusAndType[0];
      employmentStatus['employmentStatus'] = empStatusAndType[1];
    } else {
      employmentStatus[name] = value;
    }

    if (value && errors[index] && errors[index][name]) {
      if (errors[index].workdayEmploymentTypeStatus) {
        delete errors[index][WORKDAY_EMPLOYER_STATUS];
        delete errors[index]['workdayHrEmploymentStatus'];
        delete errors[index]['workdayEmploymentTypeStatus'];
      } else {
        delete errors[index][name];
      }
      if (_.isEmpty(errors[index])) delete errors[index];
    }
    this.setState({
      employmentStatusMappings: updatedEmploymentStatus
    });
  };

  addError(index, errorFieldName, errors) {
    errors = {
      ...errors,
      [index]: {
        ...errors[index],
        [errorFieldName]: true
      }
    };
    return errors;
  }

  onSubmit = () => {
    const { employmentStatusMappings } = this.state;
    const { selectedVendor, onNext } = this.props;
    let employmentStatusMappingsConfig = [];
    let workdayEmploymentTypes = [];
    let errors = {};
    let errorMsgs = [];
    if (selectedVendor.name === VENDOR_NAMES.WORKDAY) {
      employmentStatusMappings.forEach(
        (
          {
            workdayEmploymentType,
            workdayHrEmploymentStatus,
            employmentStatus,
            employmentType
          },
          index
        ) => {
          if (
            _.isEmpty(workdayEmploymentType) &&
            _.isEmpty(workdayHrEmploymentStatus) &&
            _.isEmpty(employmentStatus) &&
            _.isEmpty(employmentType)
          )
            return false;
          if (_.isEmpty(workdayEmploymentType)) {
            errors = this.addError(index, WORKDAY_EMPLOYER_STATUS, errors);
            errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          }
          if (_.isEmpty(workdayHrEmploymentStatus)) {
            errors = this.addError(index, 'workdayHrEmploymentStatus', errors);
            errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          } else {
            let employmentData = workdayEmploymentType.concat(
              ' - ',
              workdayHrEmploymentStatus
            );
            if (workdayEmploymentTypes.includes(employmentData)) {
              errors = this.addError(
                index,
                'workdayEmploymentTypeStatus',
                errors
              );
              errors = this.addError(index, WORKDAY_EMPLOYER_STATUS, errors);
              errors = this.addError(
                index,
                'workdayHrEmploymentStatus',
                errors
              );
              errorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
            } else {
              workdayEmploymentTypes.push(employmentData);
            }
          }

          if (_.isEmpty(employmentStatus) || _.isEmpty(employmentType)) {
            errors = this.addError(index, 'employmentStatusType', errors);
            errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          }

          workdayEmploymentType = _.trim(workdayEmploymentType);
          workdayHrEmploymentStatus = _.trim(workdayHrEmploymentStatus);
          employmentStatusMappingsConfig.push({
            workdayEmploymentType,
            workdayHrEmploymentStatus,
            employmentStatus,
            employmentType
          });
        }
      );
    }
    if (_.isEmpty(errors)) {
      // set to null if it is empty array
      if (_.isEmpty(employmentStatusMappingsConfig)) {
        employmentStatusMappingsConfig = null;
      }
      onNext(employmentStatusMappingsConfig);
    } else {
      this.setState({
        errors,
        errorMsgs: _.uniq(errorMsgs)
      });
    }
  };

  removeFieldRow = (index) => (e) => {
    const { employmentStatusMappings, errors } = this.state;
    const updatedEmploymentStatus = [...employmentStatusMappings];

    updatedEmploymentStatus.splice(index, 1);
    if (errors[index]) {
      delete errors[index];
      if (_.isEmpty(errors[index])) delete errors[index];
    }
    this.setState({
      employmentStatusMappings: updatedEmploymentStatus
    });
  };

  renderEmploymentStatusMapping = () => {
    const {
      lumityEmploymentStatuses = [],
      lumityEmploymentTypes = [],
      selectedVendor
    } = this.props;
    const { employmentStatusMappings, errors } = this.state;

    return (
      <Form>
        <Row className="group-sub-row">
          <Col className="group-sub-headers">{selectedVendor.name} Type</Col>
          <Col className="group-sub-headers">{selectedVendor.name} Status</Col>
          <Col className="group-sub-headers">Employment Type - Status</Col>
        </Row>
        {employmentStatusMappings.map((empTypeStatus, index) => {
          return (
            <EmploymentStatusRow
              index={index}
              workdayEmploymentType={empTypeStatus.workdayEmploymentType}
              workdayHrEmploymentStatus={
                empTypeStatus.workdayHrEmploymentStatus
              }
              employmentStatusType={`${empTypeStatus.employmentType} - ${empTypeStatus.employmentStatus}`}
              employmentType={empTypeStatus.employmentType}
              employmentStatusOptions={lumityEmploymentStatuses}
              employmentTypeOptions={lumityEmploymentTypes}
              updateStatusMapping={this.updateStatusMapping(index)}
              employmentStatusMappings={employmentStatusMappings}
              removeFieldRow={this.removeFieldRow(index)}
              errors={errors}
            />
          );
        })}
      </Form>
    );
  };

  onPrevious = () => {
    const { employmentStatusMappings } = this.state;
    const { selectedVendor, onPrevious } = this.props; 
    let employmentStatusMappingsConfig = [];
    let workdayEmploymentTypes = [];
    let errors = {};
    let errorMsgs = [];
    if (selectedVendor.name === VENDOR_NAMES.WORKDAY) {
      employmentStatusMappings.forEach(
        (
          {
            workdayEmploymentType,
            workdayHrEmploymentStatus,
            employmentStatus,
            employmentType
          },
          index
        ) => {
          if (
            _.isEmpty(workdayEmploymentType) &&
            _.isEmpty(workdayHrEmploymentStatus) &&
            _.isEmpty(employmentStatus) &&
            _.isEmpty(employmentType)
          )
            return false;
          if (_.isEmpty(workdayEmploymentType)) {
            errors = this.addError(index, WORKDAY_EMPLOYER_STATUS, errors);
            errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          }
          if (_.isEmpty(workdayHrEmploymentStatus)) {
            errors = this.addError(index, 'workdayHrEmploymentStatus', errors);
            errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          } else {
            let employmentData = workdayEmploymentType.concat(
              ' - ',
              workdayHrEmploymentStatus
            );
            if (workdayEmploymentTypes.includes(employmentData)) {
              errors = this.addError(
                index,
                'workdayEmploymentTypeStatus',
                errors
              );
              errors = this.addError(index, WORKDAY_EMPLOYER_STATUS, errors);
              errors = this.addError(
                index,
                'workdayHrEmploymentStatus',
                errors
              );
              errorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
            } else {
              workdayEmploymentTypes.push(employmentData);
            }
          }

          if (_.isEmpty(employmentStatus) || _.isEmpty(employmentType)) {
            errors = this.addError(index, 'employmentStatusType', errors);
            errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          }

          workdayEmploymentType = _.trim(workdayEmploymentType);
          workdayHrEmploymentStatus = _.trim(workdayHrEmploymentStatus);
          employmentStatusMappingsConfig.push({
            workdayEmploymentType,
            workdayHrEmploymentStatus,
            employmentStatus,
            employmentType
          });
        }
      );
    }
    if (_.isEmpty(errors)) {
      // set to null if it is empty array
      if (_.isEmpty(employmentStatusMappingsConfig)) {
        employmentStatusMappingsConfig = null;
      }
      onPrevious(employmentStatusMappings);
    } else {
      this.setState({
        errors,
        errorMsgs: _.uniq(errorMsgs)
      });
    }
  };

  addMoreConfigurations = () => {
    this.setState({
      employmentStatusMappings: [
        ...this.state.employmentStatusMappings,
        {
          workdayEmploymentType: '',
          workdayHrEmploymentStatus: '',
          employmentStatus: ''
        }
      ]
    });
  };

  render() {
    const { selectedVendor } = this.props;
    const { errors, errorMsgs } = this.state;
    return (
      <div className="step-container mapping-container">
        <div className="body margin-bottom-30">
          <p className="subtitle">{selectedVendor.name}</p>
          {!_.isEmpty(errors) &&
            errorMsgs.map((errorMsg, index) => (
              <Alert key={index} color="danger" className="error-alert">
                {errorMsg}
              </Alert>
            ))}
          {this.renderEmploymentStatusMapping()}
          <Row>
            <Button color="link" onClick={this.addMoreConfigurations}>
              +Add More
            </Button>
          </Row>
        </div>
        <Row className="action-btns">
          <Col>
            <Button
              className="form-element"
              outline
              color="primary"
              size="lg"
              onClick={this.onPrevious}
              disabled={!_.isEmpty(errors)}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              className="form-element"
              color="primary"
              size="lg"
              onClick={this.onSubmit}
              disabled={!_.isEmpty(errors)}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
