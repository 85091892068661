/**********************************************************************************************************************
 * Copyright (C) 2019-2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/assignee.actionTypes';

const initialState = {
  assigneeData: {
    assigneeList: [],
    inProgress: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ASSIGNEES_IN_PROGRESS:
      return {
        ...state,
        assigneeData: {
          ...state.assigneeData,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.GET_ASSIGNEES_COMPLETED:
      let assigneeList = action.payload;
      if (Array.isArray(assigneeList) && assigneeList.length > 0) {
        assigneeList = action.payload.sort((a, b) => {
          let nameA = a['firstName'].toUpperCase();
          let nameB = b['firstName'].toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }
      return {
        ...state,
        assigneeData: {
          assigneeList: assigneeList,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.GET_ASSIGNEES_FAILED:
      return {
        ...state,
        assigneeData: {
          ...state.assigneeData,
          inProgress: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
