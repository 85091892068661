/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/benefitsConsultation.actionTypes';

const initialState = {
  configStatus: {
    id: null,
    value: 'false',
    lastUpdatedTs: null
  },
  requestStatus: '',
  serviceError: '',
  isLoading: false
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BENEFITS_CONSULTATION_EMPLOYER:
    case ActionTypes.UPDATE_BENEFITS_CONSULTATION_CONFIG:
      return {
        ...state,
        isLoading: true,
        serviceError: '',
        requestStatus: action.type
      };
    case ActionTypes.GET_BENEFITS_CONSULTATION_EMPLOYER_SUCCESS:
    case ActionTypes.UPDATE_BENEFITS_CONSULTATION_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        configStatus: action.payload
      };
    case ActionTypes.GET_BENEFITS_CONSULTATION_EMPLOYER_ERROR:
    case ActionTypes.UPDATE_BENEFITS_CONSULTATION_CONFIG_ERROR:
      return {
        ...state,
        isLoading: false,
        serviceError: action.payload,
        requestStatus: action.type
      };
    default:
      return {
        ...state
      };
  }
};
