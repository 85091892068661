/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
const WORK_LIFE_LIST_PATH = '/brokers/:brokerId/employers/:employerId/work-life-plans';
const WORK_LIFE_BACKUP_CHILDCARE_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/backup-childcare/create';
const WORK_LIFE_BACKUP_CHILDCARE_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/backup-childcare/:planId/edit';
const WORK_LIFE_BACKUP_CHILDCARE_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/backup-childcare/:planId/view';
const WORK_LIFE_BACKUP_CHILDCARE_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/backup-childcare/:planId/clone';  
const WORK_LIFE_BEREAVEMENT_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/bereavement/create';
const WORK_LIFE_BEREAVEMENT_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/bereavement/:planId/edit';
const WORK_LIFE_BEREAVEMENT_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/bereavement/:planId/view';
const WORK_LIFE_BEREAVEMENT_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/bereavement/:planId/clone';
const WORK_LIFE_FERTILITY_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/fertility/create';
const WORK_LIFE_FERTILITY_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/fertility/:planId/edit';
const WORK_LIFE_FERTILITY_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/fertility/:planId/view';
const WORK_LIFE_FERTILITY_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/fertility/:planId/clone';  
const WORK_LIFE_PARENTAL_LEAVE_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/parental-leave/create';
const WORK_LIFE_PARENTAL_LEAVE_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/parental-leave/:planId/edit';
const WORK_LIFE_PARENTAL_LEAVE_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/parental-leave/:planId/view';
const WORK_LIFE_PARENTAL_LEAVE_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/parental-leave/:planId/clone';  
const WORK_LIFE_FLEXIBLE_WORKING_HOURS_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/flexible-working-hours/create';
const WORK_LIFE_FLEXIBLE_WORKING_HOURS_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/flexible-working-hours/:planId/edit';
const WORK_LIFE_FLEXIBLE_WORKING_HOURS_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/flexible-working-hours/:planId/view';
const WORK_LIFE_FLEXIBLE_WORKING_HOURS_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/flexible-working-hours/:planId/clone';  
const WORK_LIFE_HOLIDAY_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/holiday/create';
const WORK_LIFE_HOLIDAY_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/holiday/:planId/edit';
const WORK_LIFE_HOLIDAY_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/holiday/:planId/view';
const WORK_LIFE_HOLIDAY_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/holiday/:planId/clone';  
const WORK_LIFE_PTO_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/paid-time-off/create';
const WORK_LIFE_PTO_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/paid-time-off/:planId/edit';
const WORK_LIFE_PTO_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/paid-time-off/:planId/view';
const WORK_LIFE_PTO_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/paid-time-off/:planId/clone';  
const WORK_LIFE_SICK_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/sick/create';
const WORK_LIFE_SICK_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/sick/:planId/edit';
const WORK_LIFE_SICK_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/sick/:planId/view';
const WORK_LIFE_SICK_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/sick/:planId/clone';  
const WORK_LIFE_CLUBS_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/clubs/create';
const WORK_LIFE_CLUBS_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/clubs/:planId/edit';
const WORK_LIFE_CLUBS_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/clubs/:planId/view';
const WORK_LIFE_CLUBS_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/work-life-plans/clubs/:planId/clone';  

export {
  WORK_LIFE_LIST_PATH,
  WORK_LIFE_BACKUP_CHILDCARE_CREATE_PATH,
  WORK_LIFE_BACKUP_CHILDCARE_EDIT_PATH,
  WORK_LIFE_BACKUP_CHILDCARE_VIEW_PATH,
  WORK_LIFE_BACKUP_CHILDCARE_CLONE_PATH,
  WORK_LIFE_BEREAVEMENT_CREATE_PATH,
  WORK_LIFE_BEREAVEMENT_EDIT_PATH,
  WORK_LIFE_BEREAVEMENT_VIEW_PATH,
  WORK_LIFE_BEREAVEMENT_CLONE_PATH,
  WORK_LIFE_FERTILITY_CREATE_PATH,
  WORK_LIFE_FERTILITY_EDIT_PATH,
  WORK_LIFE_FERTILITY_VIEW_PATH,
  WORK_LIFE_FERTILITY_CLONE_PATH,
  WORK_LIFE_PARENTAL_LEAVE_CREATE_PATH,
  WORK_LIFE_PARENTAL_LEAVE_EDIT_PATH,
  WORK_LIFE_PARENTAL_LEAVE_VIEW_PATH,
  WORK_LIFE_PARENTAL_LEAVE_CLONE_PATH,
  WORK_LIFE_FLEXIBLE_WORKING_HOURS_CREATE_PATH,
  WORK_LIFE_FLEXIBLE_WORKING_HOURS_EDIT_PATH,
  WORK_LIFE_FLEXIBLE_WORKING_HOURS_VIEW_PATH,
  WORK_LIFE_FLEXIBLE_WORKING_HOURS_CLONE_PATH,
  WORK_LIFE_HOLIDAY_CREATE_PATH,
  WORK_LIFE_HOLIDAY_EDIT_PATH,
  WORK_LIFE_HOLIDAY_VIEW_PATH,
  WORK_LIFE_HOLIDAY_CLONE_PATH,
  WORK_LIFE_PTO_CREATE_PATH,
  WORK_LIFE_PTO_EDIT_PATH,
  WORK_LIFE_PTO_VIEW_PATH,
  WORK_LIFE_PTO_CLONE_PATH,
  WORK_LIFE_SICK_CREATE_PATH,
  WORK_LIFE_SICK_EDIT_PATH,
  WORK_LIFE_SICK_VIEW_PATH,
  WORK_LIFE_SICK_CLONE_PATH,
  WORK_LIFE_CLUBS_CREATE_PATH,
  WORK_LIFE_CLUBS_EDIT_PATH,
  WORK_LIFE_CLUBS_VIEW_PATH,
  WORK_LIFE_CLUBS_CLONE_PATH
};
