/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';

import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import NotificationManager from 'components/Notifications';
import { permitIf } from 'components/hoc/Permit';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';

import * as ActionTypes from '../actionTypes/benefitsConsultation.actionTypes';
import {
  updateBenefitConsultationConfigStatus,
  getBenefitConsultationConfigStatus
} from '../actions/benefitsConsultationAction';

import {
  SERVICE_BENEFITS_CONSULTATION_ENABLED,
  BENEFIT_CONSULTATION_DISABLE_WARNING
} from '../constants';
import { BENEFITS_CONSULTATION_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';

import './BenefitsConsultation.scss';
import {
  updateChatbotFallbackOption,
  getEmployer
} from 'modules/Mobile/actions/mobileActions';
import {
  UPDATE_CHATBOT_TYPE_MOBILE,
  UPDATE_CHATBOT_TYPE_SLACK
} from '../../Chatbot/constants';

class BenefitsConsultation extends React.Component {
  constructor(props) {
    super(props);
    const { employerId } = props.match.params;
    props.getBenefitConsultationConfigStatus(employerId);
  }

  componentDidMount() {
    const { match, getEmployer } = this.props;
    const { employerId } = match.params;
    getEmployer(employerId);
  }

  componentDidUpdate(prevProps) {
    const { requestStatus, serviceError, configStatus } = this.props;
    const { requestStatus: prevRequestStatus } = prevProps;
    if (prevRequestStatus === requestStatus) return;

    if (serviceError) {
      // Generic Error
      NotificationManager.error(serviceError.message);
      return;
    }

    switch (requestStatus) {
      case ActionTypes.UPDATE_BENEFITS_CONSULTATION_CONFIG_SUCCESS: {
        const value = configStatus.value;
        NotificationManager.success(
          `Feature ${value === 'true' ? 'Enabled' : 'Disabled'} Successfully`
        );
        return;
      }
      default:
        break;
    }
  }

  switchEnabled = () => {
    const {
      configStatus,
      updateBenefitConsultationConfigStatus,
      employerChatbot
    } = this.props;

    const newValue =
      configStatus.value === true || configStatus.value === 'true'
        ? 'false'
        : 'true';
    const updatedConfig = { ...configStatus, value: newValue };
    updateBenefitConsultationConfigStatus(updatedConfig);
    if (
      employerChatbot &&
      employerChatbot.chatbotConfigSettings.mobileChatbotConfig
    ) {
      this.onSaveChatbotConfigs();
    }
  };

  onSaveChatbotConfigs = () => {
    const { updateChatbotFallbackOption, employerChatbot, match } = this.props;
    const { employerId } = match.params;

    const fallbkOptions = {
      customerSupportCompatible: false,
      lumityConsultCompatible: false
    };
    const config = {
      fallbackOptions: fallbkOptions
    };
    updateChatbotFallbackOption(employerId, config);
  };

  render() {
    const employerId = this.props.match.params.employerId;
    const { configStatus } = this.props;
    const SecuredEmployerConfigToggle = permitIf(
      EmployerConfigToggleV2,
      BENEFITS_CONSULTATION_FEATURE_ROLE_MAPPING.common
    );

    return (
      <ApplicationWrapper>
        <HeaderContainer title="Benefits Consultation" />
        <ContentContainer>
          <SecuredEmployerConfigToggle
            employerId={employerId}
            moduleEnabled={SERVICE_BENEFITS_CONSULTATION_ENABLED}
            message="Benefits Consultation"
            warningMessage={
              configStatus && configStatus.value === 'true'
                ? BENEFIT_CONSULTATION_DISABLE_WARNING
                : ''
            }
            config={configStatus}
            switchCallBack={this.switchEnabled}
          />
        </ContentContainer>
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBenefitConsultationConfigStatus: (employerId) =>
    dispatch(getBenefitConsultationConfigStatus(employerId)),
  updateBenefitConsultationConfigStatus: (id, employerId, value) =>
    dispatch(updateBenefitConsultationConfigStatus(id, employerId, value)),
  updateChatbotFallbackOption: (employerId, config) =>
    dispatch(updateChatbotFallbackOption(employerId, config)),
  getEmployer: (employerId) => dispatch(getEmployer(employerId))
});

const mapStateToProps = (state) => {
  const {
    requestStatus,
    serviceError,
    isLoading,
    configStatus
  } = state.benefitConsultationReducer;
  const { employerChatbot } = state.mobileReducer;
  return {
    requestStatus,
    serviceError,
    isLoading,
    configStatus,
    employerChatbot
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenefitsConsultation);
