/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIGURATIONIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
export const GET_CURRENT_PLANS_FEATURE_CONFIGURATION =
  'GET_CURRENT_PLANS_FEATURE_CONFIGURATION';
export const GET_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS =
  'GET_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS';
export const GET_CURRENT_PLANS_FEATURE_CONFIGURATION_ERROR =
  'GET_CURRENT_PLANS_FEATURE_CONFIGURATION_ERROR';

export const CREATE_CURRENT_PLANS_FEATURE_CONFIGURATION =
  'CREATE_CURRENT_PLANS_FEATURE_CONFIGURATION';
export const CREATE_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS =
  'CREATE_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS';
export const CREATE_CURRENT_PLANS_FEATURE_CONFIGURATION_ERROR =
  'CREATE_CURRENT_PLANS_FEATURE_CONFIGURATION_ERROR';

export const UPDATE_CURRENT_PLANS_FEATURE_CONFIGURATION =
  'UPDATE_CURRENT_PLANS_FEATURE_CONFIGURATION';
export const UPDATE_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS =
  'UPDATE_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS';
export const UPDATE_CURRENT_PLANS_FEATURE_CONFIGURATION_ERROR =
  'UPDATE_CURRENT_PLANS_FEATURE_CONFIGURATION_ERROR';

export const GET_BENEFIT_GROUPS_CONFIGURATION =
  'GET_BENEFIT_GROUPS_CONFIGURATION';
export const GET_BENEFIT_GROUPS_CONFIGURATION_SUCCESS =
  'GET_BENEFIT_GROUPS_CONFIGURATION_SUCCESS';
export const GET_BENEFIT_GROUPS_CONFIGURATION_ERROR =
  'GET_BENEFIT_GROUPS_CONFIGURATION_ERROR';

export const CREATE_BENEFIT_GROUPS_CONFIGURATION =
  'CREATE_BENEFIT_GROUPS_CONFIGURATION';
export const CREATE_BENEFIT_GROUPS_CONFIGURATION_SUCCESS =
  'CREATE_BENEFIT_GROUPS_CONFIGURATION_SUCCESS';
export const CREATE_BENEFIT_GROUPS_CONFIGURATION_ERROR =
  'CREATE_BENEFIT_GROUPS_CONFIGURATION_ERROR';

export const UPDATE_BENEFIT_GROUPS_CONFIGURATION =
  'UPDATE_BENEFIT_GROUPS_CONFIGURATION';
export const UPDATE_BENEFIT_GROUPS_CONFIGURATION_SUCCESS =
  'UPDATE_BENEFIT_GROUPS_CONFIGURATION_SUCCESS';
export const UPDATE_BENEFIT_GROUPS_CONFIGURATION_ERROR =
  'UPDATE_BENEFIT_GROUPS_CONFIGURATION_ERROR';

export const GET_BENEFIT_GROUP_CONFIGURATIONS_LIST =
  'GET_BENEFIT_GROUP_CONFIGURATIONS_LIST';
export const GET_BENEFIT_GROUP_CONFIGURATIONS_LIST_SUCCESS =
  'GET_BENEFIT_GROUP_CONFIGURATIONS_LIST_SUCCESS';
export const GET_BENEFIT_GROUP_CONFIGURATIONS_LIST_ERROR =
  'GET_BENEFIT_GROUP_CONFIGURATIONS_LIST_ERROR';
