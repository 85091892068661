/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { Row, Col } from 'components/Atoms';

import { TextFormGroup, DatePickerInput } from 'components/DataForm';
import { DATE_FORMAT } from '../../../../constants';
// helpers

import { formatDateInPST } from 'util/dateUtil';

const SurveyInfo = (props) => {
  const { surveyName, closeDate, surveyUrl, reportUrl } = props.data || {};
  return (
    <>
      <Row>
        <Col>
          <TextFormGroup
            labelDisplay="Survey Name"
            inputName="surveyName"
            inputValue={surveyName}
            disabled
            placeholder="Enter Survey Name"
          />
        </Col>
        <Col>
          <DatePickerInput
            labelDisplay=<span>
              <span>Scheduled Close Date</span>
              <span className="label-info">
                (Must match with Typefrom close date,Closes at 5pm PST)
              </span>
            </span>
            inputName="closeDate"
            inputValue={formatDateInPST(closeDate)}
            dateFormat={DATE_FORMAT}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col className="url-input">
          <TextFormGroup
            disabled
            labelDisplay="Typeform URL"
            inputName="surveyUrl"
            inputValue={surveyUrl}
            placeholder="Enter Survey URL"
          />
          <a className="typeform-link" href={surveyUrl} target="_new">
            Visit URL
          </a>
        </Col>
        <Col className="url-input">
          <TextFormGroup
            disabled
            labelDisplay="Summary Results URL"
            inputName="reportUrl"
            inputValue={reportUrl}
            placeholder="Enter Survey Results URL"
          />
          <a className="typeform-link" href={reportUrl} target="_new">
            Visit URL
          </a>
        </Col>
      </Row>
    </>
  );
};

export default SurveyInfo;
