import React from 'react';

import Switch from './Switch';
import ConfirmationBox from '../DialogBox/ConfirmationBox/ConfirmationBox';
export default class SwitchWithConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmWindowOpen: false
    };
  }

  toggleConfirmWindow = () => {
    this.setState((prevState) => ({
      isConfirmWindowOpen: !prevState.isConfirmWindowOpen
    }));
  };

  render() {
    const {
      toggleId,
      id,
      className,
      value,
      message,
      warningMessage,
      confirmText,
      confirmCallback,
      disabled,
    } = this.props;
    const { isConfirmWindowOpen } = this.state;
    return (
      <>
        <Switch
          id={id !== null ? id : toggleId}
          className={className}
          onChange={this.toggleConfirmWindow}
          checked={Boolean(value)}
          disabled={disabled}
        />
        <ConfirmationBox
          id={id + 'Confirmation'}
          message={message}
          warningMessage={warningMessage}
          isOpen={isConfirmWindowOpen}
          toggle={this.toggleConfirmWindow}
          confirmText={confirmText}
          confirmCallback={() => {
            confirmCallback();
            this.toggleConfirmWindow();
          }}
        />
      </>
    );
  }
}
