/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/organization.actionTypes';

const initialState = {
  orgList: {
    data: [],
    inProgress: false,
    error: null
  },
  organisationPage: {
    data: {
      content: [],
      metadata: {}
    },
    isFetching: false,
    error: null
  },
  createOrganisationStatus: {
    inProgress: false,
    error: null
  },
  updateOrganisationStatus: {
    data: {},
    isUpdating: false,
    updateError: null
  },
  organization: {
    data: {},
    isFetching: false,
    error: null
  },
  deleteOrganizationStatus: {
    data: {},
    isDeleting: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ORGANISATIONS_LIST_IN_PROGRESS:
      return {
        ...state,
        orgList: {
          ...state.orgList,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_ORGANISATIONS_COMPLETED:
      return {
        ...state,
        orgList: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_ORGANISATIONS_LIST_FAILED:
      return {
        ...state,
        orgList: {
          ...state.orgList,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_ORGANISATIONS_PAGE_IN_PROGRESS:
      return {
        ...state,
        organisationPage: {
          ...state.organisationPage,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_ORGANISATIONS_PAGE_COMPLETED:
      return {
        ...state,
        organisationPage: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_ORGANISATIONS_PAGE_FAILED:
      return {
        ...state,
        organisationPage: {
          ...state.organisationPage,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.CREATE_ORGANISATION_IN_PROGRESS:
      return {
        ...state,
        createOrganisationStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.CREATE_ORGANISATION_COMPLETED:
      return {
        ...state,
        createOrganisationStatus: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.CREATE_ORGANISATION_FAILED:
      return {
        ...state,
        createOrganisationStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.UPDATE_ORGANISATION_IN_PROGRESS:
      return {
        ...state,
        updateOrganisationStatus: {
          ...state.updateOrganisationStatus,
          isUpdating: true,
          updateError: null
        }
      };
    case ActionTypes.UPDATE_ORGANISATION_COMPLETED:
      return {
        ...state,
        updateOrganisationStatus: {
          data: action.payload,
          isUpdating: false,
          updateError: null
        }
      };
    case ActionTypes.UPDATE_ORGANISATION_FAILED:
      return {
        ...state,
        updateOrganisationStatus: {
          ...state.updateOrganisationStatus,
          isUpdating: false,
          updateError: action.payload
        }
      };
    case ActionTypes.GET_ORGANISATION_IN_PROGRESS:
      return {
        ...state,
        organization: {
          ...state.organization,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_ORGANISATION_COMPLETED:
      return {
        ...state,
        organization: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_ORGANISATION_FAILED:
      return {
        ...state,
        organization: {
          ...state.organization,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.DELETE_ORGANIZATION_IN_PROGRESS:
      return {
        ...state,
        deleteOrganizationStatus: {
          ...state.deleteOrganizationStatus,
          isDeleting: true,
          error: null
        }
      };
    case ActionTypes.DELETE_ORGANIZATION_COMPLETED:
      return {
        ...state,
        deleteOrganizationStatus: {
          ...state.deleteOrganizationStatus,
          isDeleting: false,
          data: action.payload
        }
      };
    case ActionTypes.DELETE_ORGANIZATION_FAILED:
      return {
        ...state,
        deleteOrganizationStatus: {
          ...state.deleteOrganizationStatus,
          isDeleting: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
