/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'components/Atoms';
import './DialogSendSurvey.scss';

/**
 * Component for generate Synced status badge.
 * @param props
 * @returns {*}
 */
const DialogSendSurvey = (props) => {
  const {
    isOpen,
    toggle,
    userCount,
    confirmCallBack,
    selectedParticipant
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      wrapClassName="dialog-send-survey"
      className="dialog-send-survey__dialog"
      contentClassName="dialog-send-survey__content"
    >
      <ModalHeader className="modal-header" toggle={toggle}>
        Confirm Survey Send
      </ModalHeader>
      <ModalBody className="modal-body">
        <p className="subtitle">
          {selectedParticipant
            ? `You are about to re-send  this survey to ${selectedParticipant} .Please confirm below.`
            : `You are about to send  this survey to ${userCount} employee recipients. Please confirm below.`}
        </p>
      </ModalBody>
      <ModalFooter className="modal-footer">
        <Button color="primary" size="lg" outline onClick={toggle}>
          Cancel
        </Button>
        <Button
          color="primary"
          size="lg"
          onClick={() => confirmCallBack(selectedParticipant)}
        >
          {selectedParticipant ? 'Send Survey' : `Send Survey(${userCount})`}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DialogSendSurvey;
