/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  FormText
} from 'components/Atoms';

import { Switch } from 'components/Buttons';

import ContentContainer from 'containers/ContentContainer/ContentContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import NotificationManager from 'components/Notifications';
import {
  getBenGuideById,
  initBenGuide,
  initBenGuideValidations,
  previewBenGuide,
  publishBenGuide,
  updateBenGuide,
  updateBenGuideById,
  updateBenGuideValidations,
  getAllPlans
} from '../../../actions/benGuidePlanAction';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ValidationTable, {
  VALIDATION_PASSED,
  VALIDATION_INCOMPLETE,
  VALIDATION_DISABLED
} from './ValidationTable';
import Loader from 'components/CircularProgress/Loader';
import './Preview.scss';
import { isValidDateRange } from 'util/dateUtil';
import { BENGUIDE_PLAN_YEAR_MISMATCH } from 'modules/BenGuides/constants';
import { BenefitCategory } from 'constants/benefitConstants';

class Preview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorsAndValidationsDataArr: [],
      validationStatuses: {}
    };

    // Initialize benguide plan validation content
    this.props.initBenGuideValidations();
  }

  componentDidMount() {
    const { benGuideObj } = this.props.benGuideData;
    const { match } = this.props;
    const { benGuideId } = match.params;

    if (benGuideId && benGuideObj) {
      this.initializeConfigs();
    } else {
      if (benGuideId) {
        const { match } = this.props;
        const { employerId } = match.params;
        this.props.getBenGuideById(benGuideId, employerId);
      } else {
        //redirect to the benguides //TODO
      }
    }
  }

  initializeConfigs = () => {
    this.updatePassword2();
  };

  updatePassword2 = async () => {
    const { secured } = this.props.benGuideData.benGuideObj;
    const { password } = secured;

    await this.props.updateBenGuide(
      this.props.benGuideData.benGuideObj,
      'secured.password2',
      password
    );
    this.updateValidationObject();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      benGuideData,
      benGuidePublishError,
      benGuidePreviewError,
      benGuidePublishInProgress,
      benGuidePreviewInProgress
    } = this.props;
    const { benGuideObj } = benGuideData;
    if (benGuideObj && benGuideObj !== prevProps.benGuideData.benGuideObj) {
      this.updateValidationObject();
    }
    const { updateBenGuideInProgress, error } = benGuideData;

    if (
      prevProps.benGuideData.updateBenGuideInProgress &&
      !updateBenGuideInProgress
    ) {
      if (error) {
        NotificationManager.error(`${benGuideObj.name} could not be saved.`);
      } else {
        this.initializeConfigs();
        NotificationManager.success(
          `${benGuideObj.name} has been saved successfully.`
        );
      }
    }

    if (prevProps.benGuidePublishInProgress && !benGuidePublishInProgress) {
      if (benGuidePublishError) {
        if (
          benGuidePublishError.response &&
          benGuidePublishError.response.data &&
          benGuidePublishError.response.data.code ==
            BENGUIDE_PLAN_YEAR_MISMATCH &&
          benGuidePublishError.response.data.details
        ) {
          NotificationManager.error(
            this.createPublishErrorMessage(
              benGuidePublishError.response.data.details
            )
          );
        } else {
          NotificationManager.error(
            `${benGuideObj.name} could not be published.`
          );
        }
      } else {
        NotificationManager.success(
          `${benGuideObj.name} has been published successfully.`
        );
      }
    }

    if (prevProps.benGuidePreviewInProgress && !benGuidePreviewInProgress) {
      if (
        benGuidePreviewError &&
        benGuidePreviewError.response &&
        benGuidePreviewError.response.data &&
        benGuidePreviewError.response.data.code ==
          BENGUIDE_PLAN_YEAR_MISMATCH &&
        benGuidePreviewError.response.data.details
      ) {
        NotificationManager.error(
          this.createPublishErrorMessage(
            benGuidePreviewError.response.data.details
          )
        );
      }
    }

    if (
      !benGuideData.loading &&
      benGuideData.loading !== prevProps.benGuideData.loading
    ) {
      this.initializeConfigs();
    }

    if (benGuideObj && !prevProps.benGuideData.benGuideObj) {
      const { employerId } = benGuideObj;
      this.props.getAllPlans(employerId);
    }
  }

  createPublishErrorMessage = (errorResponseDetails) => {
    const benefitCategories = Object.keys(BenefitCategory).map(
      (key) => BenefitCategory[key].value
    );
    const mismatchingBenefitCategories = _.pick(
      errorResponseDetails,
      benefitCategories
    );
    const mismatchingBenefitCategoriesToString = Object.entries(
      mismatchingBenefitCategories
    )
      .map(
        (benefitCategory) =>
          `${benefitCategory[1].length} ${
            BenefitCategory[benefitCategory[0]].label
          } ${benefitCategory[1].length == 1 ? 'plan' : 'plans'}`
      )
      .reduce((accumulator, nextValue) => (accumulator += ', ' + nextValue));
    return (
      "Benguide's plan year disputes with the plan years of the following plan types. " +
      mismatchingBenefitCategoriesToString +
      '.'
    );
  };

  handleChange = async (event) => {
    const { name, value } = event.target;

    if (name === 'urlLabel') {
      await this.props.updateBenGuide(
        this.props.benGuideData.benGuideObj,
        name,
        // Strip all special characters besides - and _
        value.replace(/[^0-9a-z\-_]/gi, '')
      );
    } else {
      await this.props.updateBenGuide(
        this.props.benGuideData.benGuideObj,
        name,
        value
      );
    }
    this.updateValidationObject();
  };

  isBasicFormValidated = () => {
    const { benGuideObj } = this.props.benGuideData;
    const { secured } = benGuideObj;

    const validationObj = {
      password: secured.password !== secured.password2 ? true : false
    };

    this.props.updateBenGuideValidations(validationObj);
    return (
      Object.keys(validationObj).find((key) => validationObj[key] === true) ===
      undefined
    );
  };

  /**
   * save and close.
   *
   * @param event
   * @returns {Promise<void>}
   */
  saveAndClose = (event) => {
    event.preventDefault();

    if (!this.isBasicFormValidated()) {
      return;
    }
    const { benGuideObj } = this.props.benGuideData;
    this.save(benGuideObj);
  };

  /**
   * save the benGuide.
   *
   * @param payload - benGuide Obj.
   * @returns {Promise<void>}
   */
  save = (payload) => {
    const { match } = this.props;
    const { path } = match;
    if (!this.isBasicFormValidated()) {
      return;
    }
    !payload.perkInfo.enabled && (payload.perkInfo.perks = []);
    this.props.updateBenGuideById(payload, path.includes('clone'));
  };

  /**
   * publish the ben guide.
   * @type {{}}
   */
  publish = (event) => {
    event.preventDefault();
    const { benGuideObj } = this.props.benGuideData;
    this.props.publishBenGuide(benGuideObj);
  };

  /**
   * Preview the ben guide.
   * @type {{}}
   */
  preview = async (event) => {
    event.preventDefault();
    const { benGuideObj } = this.props.benGuideData;
    this.props.previewBenGuide(benGuideObj.id);
  };

  updateValidationObject = () => {
    const { benGuideObj } = this.props.benGuideData;

    // ** Plan page sections ** //
    // Name must not be only spaces, and the planYearId shouldn't be empty
    const { planYearId, name } = benGuideObj;
    const benguideInformationStatus =
      _.trim(name) && planYearId ? VALIDATION_PASSED : VALIDATION_INCOMPLETE;
    // All plan types must be either disabled or have at least one plan included.
    const { medicalPlans } = benGuideObj;
    const medicalPlanStatus = !medicalPlans.enabled
      ? VALIDATION_DISABLED
      : medicalPlans.plans.length > 0
      ? VALIDATION_PASSED
      : VALIDATION_INCOMPLETE;

    const { dentalPlans } = benGuideObj;
    const dentalPlanStatus = !dentalPlans.enabled
      ? VALIDATION_DISABLED
      : dentalPlans.plans.length > 0
      ? VALIDATION_PASSED
      : VALIDATION_INCOMPLETE;

    const { visionPlans } = benGuideObj;
    const visionPlanStatus = !visionPlans.enabled
      ? VALIDATION_DISABLED
      : visionPlans.plans.length > 0
      ? VALIDATION_PASSED
      : VALIDATION_INCOMPLETE;

    const { insurancePlans } = benGuideObj;
    const insurancePlanStatus = !insurancePlans.enabled
      ? VALIDATION_DISABLED
      : insurancePlans.plans.length > 0
      ? VALIDATION_PASSED
      : VALIDATION_INCOMPLETE;

    const { fourOOneKPlans } = benGuideObj;
    const fourOOneKPlanStatus = !fourOOneKPlans.enabled
      ? VALIDATION_DISABLED
      : fourOOneKPlans.plans.length > 0
      ? VALIDATION_PASSED
      : VALIDATION_INCOMPLETE;

    const { workLifePlans } = benGuideObj;
    const workLifePlanStatus = !workLifePlans.enabled
      ? VALIDATION_DISABLED
      : workLifePlans.plans.length > 0
      ? VALIDATION_PASSED
      : VALIDATION_INCOMPLETE;

    const { wellbeingPlans } = benGuideObj;
    const wellbeingPlanStatus = !wellbeingPlans.enabled
      ? VALIDATION_DISABLED
      : wellbeingPlans.plans.length > 0
      ? VALIDATION_PASSED
      : VALIDATION_INCOMPLETE;

    const { taxAdvantagedAccountPlans, hsaPlans = {} } = benGuideObj;
    const { plans: medicalHSAPlans = [] } = hsaPlans;
    const taxAdvantagedAccPlanStatus = !taxAdvantagedAccountPlans.enabled
      ? VALIDATION_DISABLED
      : taxAdvantagedAccountPlans.plans.length > 0 || medicalHSAPlans.length > 0
      ? VALIDATION_PASSED
      : VALIDATION_INCOMPLETE;

    const { additionalPerks } = benGuideObj;
    const additionalPerksPlanStatus = !additionalPerks.enabled
      ? VALIDATION_DISABLED
      : additionalPerks.plans.length > 0
      ? VALIDATION_PASSED
      : VALIDATION_INCOMPLETE;

    const { telehealthRxDeliveryPlans } = benGuideObj;
    const telehealthRxDeliveryPlanStatus = !telehealthRxDeliveryPlans.enabled
      ? VALIDATION_DISABLED
      : telehealthRxDeliveryPlans.plans.length > 0
      ? VALIDATION_PASSED
      : VALIDATION_INCOMPLETE;

    // ** Customization page sections ** //
    // All customization fields must be non-null.
    const { styleConfiguration } = benGuideObj;
    const otherConfigs = [
      'familyImageReference',
      'timeOfImageReference',
      'communitiesImageReference',
      'perksImageReference'
    ];
    let allowedConfigs = Object.keys(styleConfiguration)
      .filter((key) => !otherConfigs.includes(key))
      .reduce((obj, key) => {
        obj[key] = styleConfiguration[key];
        return obj;
      }, {});

    const styleValues = Object.values(allowedConfigs);
    let customizationStatus = VALIDATION_PASSED;
    for (const value of styleValues) {
      if (!value || value === '#') {
        customizationStatus = VALIDATION_INCOMPLETE;
        break;
      }
    }
    let workLifeImageConfigs = [
      'familyImageReference',
      'timeOfImageReference',
      'communitiesImageReference'
    ];

    let workLifeStyleConfigs = Object.keys(styleConfiguration)
      .filter((key) => workLifeImageConfigs.includes(key))
      .reduce((obj, key) => {
        obj[key] = styleConfiguration[key];
        return obj;
      }, {});

    const workLifeBannerStyleValues = Object.values(workLifeStyleConfigs);
    let workLifeBannerStatus = VALIDATION_DISABLED;
    if (
      workLifePlanStatus !== VALIDATION_DISABLED &&
      !_.isEmpty(workLifePlans.plans)
    ) {
      workLifeBannerStatus = VALIDATION_PASSED;
      for (const value of workLifeBannerStyleValues) {
        if (!value) {
          workLifeBannerStatus = VALIDATION_INCOMPLETE;
          break;
        }
      }
    }

    let perkImageConfigs = ['perksImageReference'];

    let perkStyleConfigs = Object.keys(styleConfiguration)
      .filter((key) => perkImageConfigs.includes(key))
      .reduce((obj, key) => {
        obj[key] = styleConfiguration[key];
        return obj;
      }, {});

    const perkBannerStyleValues = Object.values(perkStyleConfigs);
    let perkBannerStatus = VALIDATION_DISABLED;
    if (
      additionalPerksPlanStatus !== VALIDATION_DISABLED &&
      !_.isEmpty(additionalPerks.plans)
    ) {
      perkBannerStatus = VALIDATION_PASSED;
      for (const value of perkBannerStyleValues) {
        if (!value) {
          perkBannerStatus = VALIDATION_INCOMPLETE;
          break;
        }
      }
    }

    // ** Other page sections ** //
    const {
      welcomeMessage,
      resourceLinks,
      oeVideo,
      whatsNew,
      perkDocuments,
      resourceDocuments,
      eligibilityRules,
      eligibilityWaiverCredits
    } = benGuideObj;
    // Welcome message needs to be non-spaces.
    const homepageStatus = _.trim(welcomeMessage)
      ? VALIDATION_PASSED
      : VALIDATION_INCOMPLETE;

    let oeVideoStatus = VALIDATION_DISABLED;
    if (oeVideo && oeVideo.enabled) {
      oeVideoStatus =
        oeVideo.oeVideo && oeVideo.oeVideo.fileName && oeVideo.oeVideo.reference
          ? VALIDATION_PASSED
          : VALIDATION_INCOMPLETE;
    }

    let whatsNewStatus = VALIDATION_DISABLED;
    if (whatsNew && whatsNew.enabled) {
      whatsNewStatus =
        whatsNew.whatsNew &&
        _.trim(whatsNew.whatsNew.description) &&
        _.trim(whatsNew.whatsNew.title)
          ? VALIDATION_PASSED
          : VALIDATION_INCOMPLETE;
    }

    // Resource Links must either be disabled or contain at least one link with all fields present.
    let resourceLinksStatus = VALIDATION_PASSED;
    if (resourceLinks.enabled && taxAdvantagedAccountPlans.enabled) {
      if (resourceLinks.links.length > 0) {
        for (const link of resourceLinks.links) {
          if (!_.trim(link.name) || !_.trim(link.url) || !_.trim(link.target)) {
            resourceLinksStatus = VALIDATION_INCOMPLETE;
            break;
          }
        }
      } else {
        resourceLinksStatus = VALIDATION_INCOMPLETE;
      }
    } else {
      resourceLinksStatus = VALIDATION_DISABLED;
    }

    // Resource Documents must either be disabled or contain at least one document with all fields present.
    let resourceDocumentsStatus = VALIDATION_PASSED;
    if (resourceDocuments.enabled && taxAdvantagedAccountPlans.enabled) {
      if (resourceDocuments.documents.length > 0) {
        for (const document of resourceDocuments.documents) {
          if (
            !_.trim(document.name) ||
            !_.trim(document.fileName) ||
            !_.trim(document.reference) ||
            !_.trim(document.target)
          ) {
            resourceDocumentsStatus = VALIDATION_INCOMPLETE;
            break;
          }
        }
      } else {
        resourceDocumentsStatus = VALIDATION_INCOMPLETE;
      }
    } else {
      resourceDocumentsStatus = VALIDATION_DISABLED;
    }

    // Perk Documents must either be disabled or contain at least one document with all fields present.
    let perkDocumentsStatus = VALIDATION_PASSED;
    if (perkDocuments.enabled && additionalPerks.enabled) {
      if (perkDocuments.documents.length > 0) {
        for (const document of perkDocuments.documents) {
          if (
            !_.trim(document.title) ||
            !_.trim(document.fileName) ||
            !_.trim(document.reference)
          ) {
            perkDocumentsStatus = VALIDATION_INCOMPLETE;
            break;
          }
        }
      } else {
        perkDocumentsStatus = VALIDATION_INCOMPLETE;
      }
    } else {
      perkDocumentsStatus = VALIDATION_DISABLED;
    }

    let rulesQLEventsStatus = VALIDATION_DISABLED;
    if (eligibilityRules && eligibilityRules.enabled) {
      rulesQLEventsStatus =
        eligibilityRules.eligibilityRules &&
        _.trim(eligibilityRules.eligibilityRules.rules) &&
        _.trim(eligibilityRules.eligibilityRules.qualifyingLifeEvents)
          ? VALIDATION_PASSED
          : VALIDATION_INCOMPLETE;
    }

    let waiverCreditsStatus = VALIDATION_DISABLED;
    if (eligibilityWaiverCredits && eligibilityWaiverCredits.enabled) {
      waiverCreditsStatus =
        eligibilityWaiverCredits.eligibilityWaiverCredits &&
        _.trim(eligibilityWaiverCredits.eligibilityWaiverCredits.waiverCredits)
          ? VALIDATION_PASSED
          : VALIDATION_INCOMPLETE;
    }

    // ** Preview page sections ** //
    const { urlLabel, secured } = benGuideObj;
    const { password, password2 } = secured;
    // Passwords must be non-spaces and must match.
    const passwordProtectionStatus = secured.enabled
      ? _.trim(password) && _.trim(password2) && password === password2
        ? VALIDATION_PASSED
        : VALIDATION_INCOMPLETE
      : VALIDATION_DISABLED;
    // Shareable Link must be non-spaces.
    const shareableLinkStatus =
      _.trim(urlLabel) !== '' ? VALIDATION_PASSED : VALIDATION_INCOMPLETE;

    this.setState({
      validationStatuses: {
        benguideInformationStatus: benguideInformationStatus,
        medicalPlanStatus: medicalPlanStatus,
        dentalPlanStatus: dentalPlanStatus,
        visionPlanStatus: visionPlanStatus,
        insurancePlanStatus: insurancePlanStatus,
        fourOOneKPlanStatus: fourOOneKPlanStatus,
        workLifePlanStatus: workLifePlanStatus,
        wellbeingPlanStatus: wellbeingPlanStatus,
        taxAdvantagedAccPlanStatus: taxAdvantagedAccPlanStatus,
        additionalPerksPlanStatus: additionalPerksPlanStatus,
        customizationStatus: customizationStatus,
        workLifeBannerStatus: workLifeBannerStatus,
        perkBannerStatus: perkBannerStatus,
        homepageStatus: homepageStatus,
        oeVideoStatus: oeVideoStatus,
        whatsNewStatus: whatsNewStatus,
        resourceLinksStatus: resourceLinksStatus,
        resourceDocumentsStatus: resourceDocumentsStatus,
        perkDocumentsStatus: perkDocumentsStatus,
        passwordProtectionStatus: passwordProtectionStatus,
        shareableLinkStatus: shareableLinkStatus,
        rulesQLEventsStatus: rulesQLEventsStatus,
        waiverCreditsStatus: waiverCreditsStatus,
        telehealthRxDeliveryPlanStatus: telehealthRxDeliveryPlanStatus
      }
    });
  };

  isValid = () => {
    let bannerStatus = ['workLifeBannerStatus', 'perkBannerStatus'];
    let customizationBannerStatus = Object.keys(this.state.validationStatuses)
      .filter((key) => !bannerStatus.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.state.validationStatuses[key];
        return obj;
      }, {});

    const collapsedStatuses = _.uniq(Object.values(customizationBannerStatus));

    return !collapsedStatuses.includes(VALIDATION_INCOMPLETE);
  };

  switchHandler = (checked) => {
    this.props.updateBenGuide(
      this.props.benGuideData.benGuideObj,
      'secured.enabled',
      checked
    );
    if (!checked) {
      this.props.updateBenGuide(
        this.props.benGuideData.benGuideObj,
        'secured.password',
        ''
      );
      this.props.updateBenGuide(
        this.props.benGuideData.benGuideObj,
        'secured.password2',
        ''
      );
    }
    this.updateValidationObject();
  };

  render() {
    const { validationStatuses } = this.state;
    const { benGuideErrorObj, match } = this.props;
    const { type, brokerId } = match.params;
    const isReadOnlyView = type === 'view';
    const { benGuideObj } = this.props.benGuideData;
    const validBenGuide = this.isValid();
    const securedEnabled =
      benGuideObj && benGuideObj.secured && benGuideObj.secured.enabled;
    const styleClass =
      securedEnabled && !isReadOnlyView ? '' : 'disable-background';
    let content = null;
    const { isProcessedPlans } = this.props.benGuidePlans;
    if (benGuideObj && isProcessedPlans) {
      content = (
        <Row className="ben-guide-preview">
          <Col className="col-10">
            <ApplicationWrapper>
              <HeaderContainer
                title="BenGuide - Preview & Publish"
                subtitle={benGuideObj.name}
              />
              <ContentContainer
                header="Errors & Validation"
                className={`validation-table-container ${
                  isReadOnlyView ? 'disable-background' : ''
                }`}
              >
                <div className="d-flex-1 justify-content-center">
                  <ValidationTable
                    brokerId={brokerId}
                    data={benGuideObj}
                    history={this.props.history}
                    validationStatuses={validationStatuses}
                    isReadOnlyView={isReadOnlyView}
                  />
                </div>
              </ContentContainer>
              <ContentContainer
                className={styleClass}
                header="Password Protection"
                id="PasswordProtection"
                pageActions={
                  <div id="PasswordProtection">
                    <Switch
                      uncheckedIcon={securedEnabled}
                      checkedIcon={false}
                      onChange={this.switchHandler}
                      checked={securedEnabled}
                      id="password-switch"
                      disabled={isReadOnlyView}
                    />
                  </div>
                }
              >
                <div className="mt-3">
                  <Row>
                    <Col xs="6">
                      <PasswordInput
                        label={'Password Protection*'}
                        value={benGuideObj.secured.password}
                        id={'password'}
                        name={'secured.password'}
                        styleClass={`form-control mt-2 ${
                          benGuideErrorObj && benGuideErrorObj.password
                            ? 'is-invalid'
                            : ''
                        }`}
                        onChange={this.handleChange}
                        disabled={!securedEnabled || isReadOnlyView}
                      />
                    </Col>
                    <Col xs="6">
                      <PasswordInput
                        label={'Re-Enter Password*'}
                        value={benGuideObj.secured.password2}
                        id={'password2'}
                        name={'secured.password2'}
                        styleClass={`form-control mt-2 ${
                          benGuideErrorObj && benGuideErrorObj.password
                            ? 'is-invalid'
                            : ''
                        }`}
                        onChange={this.handleChange}
                        disabled={!securedEnabled || isReadOnlyView}
                      />
                    </Col>
                  </Row>
                </div>
              </ContentContainer>
              <ContentContainer
                header="Sharable Link*"
                id="ShareableLink"
                className={isReadOnlyView ? 'disable-background' : ''}
              >
                <div className="mt-3" id="ShareableLink">
                  <h3>Public URL</h3>
                  <Row>
                    <Col md="6">
                      <Row>
                        <Col>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              {`${benGuideObj.urlBase}/${benGuideObj.urlHash}/`}
                            </InputGroupAddon>
                            <Input
                              name="urlLabel"
                              value={benGuideObj.urlLabel}
                              onChange={this.handleChange}
                              maxLength="20"
                              disabled={isReadOnlyView}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="ml-3">
                          <FormText color="muted" className="mt-2">
                            URL Character Count:{' '}
                            {benGuideObj.urlLabel
                              ? benGuideObj.urlLabel.length
                              : 0}
                            /20
                          </FormText>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="3">
                      {!isReadOnlyView && (
                        <CopyToClipboard
                          text={`${benGuideObj.urlBase}/${benGuideObj.urlHash}/${benGuideObj.urlLabel}`}
                          onCopy={() => {
                            NotificationManager.success('Copied Public URL.');
                          }}
                        >
                          <Button
                            auto_id="copy-public-url"
                            className="copyURLButton"
                            color="primary"
                            size="sm"
                            outline
                          >
                            Copy Public URL
                          </Button>
                        </CopyToClipboard>
                      )}
                    </Col>
                    <Col md="3" />
                  </Row>
                </div>
              </ContentContainer>
            </ApplicationWrapper>
          </Col>
          {!isReadOnlyView && (
            <Col className="col-2">
              <Row className="mt-5">
                <Col>
                  <Button
                    auto_id="save-and-close"
                    type="submit"
                    color="primary"
                    size="lg"
                    className="right-side-button"
                    onClick={this.saveAndClose}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  {!validBenGuide}
                  <Button
                    type="submit"
                    disabled={!validBenGuide}
                    color="primary"
                    size="lg"
                    className="right-side-button"
                    onClick={this.preview}
                  >
                    Preview
                  </Button>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  {!validBenGuide}
                  <Button
                    type="submit"
                    disabled={!validBenGuide}
                    color="primary"
                    size="lg"
                    className="right-side-button"
                    onClick={this.publish}
                  >
                    Publish
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      );
    } else {
      content = (
        <Row>
          <Col className="text-center align-middle">
            <Loader />
          </Col>
        </Row>
      );
    }

    return content;
  }
}

const PasswordInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="name">{props.label}</label>
      <Input
        type="text"
        name={props.name}
        className={props.styleClass}
        id={props.id}
        onChange={props.onChange}
        value={props.value}
        maxLength="20"
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
      <div className="invalid-feedback">Passwords do not match</div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  initBenGuide: (benGuideObj) => dispatch(initBenGuide(benGuideObj)),
  initBenGuideValidations: () => dispatch(initBenGuideValidations()),
  updateBenGuideValidations: (validationObj) =>
    dispatch(updateBenGuideValidations(validationObj)),
  updateBenGuide: (benGuideObj, prop, value) =>
    dispatch(updateBenGuide(benGuideObj, prop, value)),
  getBenGuideById: (benGuideId, employerId) =>
    dispatch(getBenGuideById(benGuideId, employerId)),
  updateBenGuideById: (data, isCloned) =>
    dispatch(updateBenGuideById(data, isCloned)),
  publishBenGuide: (data) => dispatch(publishBenGuide(data)),
  previewBenGuide: (benGuideId) => dispatch(previewBenGuide(benGuideId)),
  getAllPlans: (employerId) => dispatch(getAllPlans(employerId))
});

const mapStateToProps = (state) => ({
  benGuideData: state.benGuideReducer.benGuideData,
  benGuideErrorObj: state.benGuideReducer.benGuideErrorObj,
  benGuidePublish: state.benGuideReducer.publishBenGuide.published,
  benGuidePublishError: state.benGuideReducer.publishBenGuide.error,
  benGuidePreviewError: state.benGuideReducer.benguidePreview.error,
  benGuidePublishInProgress: state.benGuideReducer.publishBenGuide.loading,
  benGuidePreviewInProgress: state.benGuideReducer.benguidePreview.loading,
  benGuidePlans: state.benGuideReducer.benGuidePlans
});

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
