/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
export const CREATE_PLAN_YEAR_IN_PROGRESS = 'CREATE_PLAN_YEAR_IN_PROGRESS';
export const CREATE_PLAN_YEAR_COMPLETED = 'CREATE_PLAN_YEAR_COMPLETED';
export const CREATE_PLAN_YEAR_FAILED = 'CREATE_PLAN_YEAR_FAILED';

export const GET_PLAN_YEAR_IN_PROGRESS = 'GET_PLAN_YEAR_IN_PROGRESS';
export const GET_PLAN_YEAR_COMPLETED = 'GET_PLAN_YEAR_COMPLETED';
export const GET_PLAN_YEAR_FAILED = 'GET_PLAN_YEAR_FAILED';

export const UPDATE_PLAN_YEAR_IN_PROGRESS = 'UPDATE_PLAN_YEAR_IN_PROGRESS';
export const UPDATE_PLAN_YEAR_COMPLETED = 'UPDATE_PLAN_YEAR_COMPLETED';
export const UPDATE_PLAN_YEAR_FAILED = 'UPDATE_PLAN_YEAR_FAILED';

export const DELETE_VALIDATE_PLAN_YEAR_IN_PROGRESS = 'DELETE_VALIDATE_PLAN_YEAR_IN_PROGRESS';
export const DELETE_VALIDATE_PLAN_YEAR_COMPLETED = 'DELETE_VALIDATE_PLAN_YEAR_COMPLETED';
export const DELETE_VALIDATE_PLAN_YEAR_FAILED = 'DELETE_VALIDATE_PLAN_YEAR_FAILED';

export const DELETE_PLAN_YEAR_IN_PROGRESS = 'DELETE_PLAN_YEAR_IN_PROGRESS';
export const DELETE_PLAN_YEAR_COMPLETED = 'DELETE_PLAN_YEAR_COMPLETED';
export const DELETE_PLAN_YEAR_FAILED = 'DELETE_PLAN_YEAR_FAILED';

export const CHANGE_CURRENT_PLAN_YEAR_IN_PROGRESS =
  'CHANGE_CURRENT_PLAN_YEAR_IN_PROGRESS';
export const CHANGE_CURRENT_PLAN_YEAR_COMPLETED =
  'CHANGE_CURRENT_PLAN_YEAR_COMPLETED';
export const CHANGE_CURRENT_PLAN_YEAR_FAILED =
  'CHANGE_CURRENT_PLAN_YEAR_FAILED';

export const GET_PLAN_YEAR_BY_TYPE_IN_PROGRESS =
  'GET_PLAN_YEAR_BY_TYPE_IN_PROGRESS';
export const GET_PLAN_YEAR_BY_TYPE_COMPLETED =
  'GET_PLAN_YEAR_BY_TYPE_COMPLETED';
export const GET_PLAN_YEAR_BY_TYPE_FAILED = 'GET_PLAN_YEAR_BY_TYPE_FAILED ';

export const GET_ATTACHED_PLANS_COUNT_IN_PROGRESS =
  'GET_ATTACHED_PLANS_COUNT_IN_PROGRESS';
export const GET_ATTACHED_PLANS_COUNT_SUCCESS =
  'GET_ATTACHED_PLANS_COUNT_SUCCESS';
export const GET_ATTACHED_PLANS_COUNT_FAILED =
  'GET_ATTACHED_PLANS_COUNT_FAILED';

export const GET_ATTACHED_BEN_GUIDE_COUNT_IN_PROGRESS =
  'GET_ATTACHED_BEN_GUIDE_COUNT_IN_PROGRESS';
export const GET_ATTACHED_BEN_GUIDE_COUNT_SUCCESS =
  'GET_ATTACHED_BEN_GUIDE_COUNT_SUCCESS';
export const GET_ATTACHED_BEN_GUIDE_COUNT_FAILED =
  'GET_ATTACHED_BEN_GUIDE_COUNT_FAILED';

export const GET_ATTACHED_ELECTIONS_COUNT_IN_PROGRESS =
  'GET_ATTACHED_ELECTIONS_COUNT_IN_PROGRESS';
export const GET_ATTACHED_ELECTIONS_COUNT_SUCCESS =
  'GET_ATTACHED_ELECTIONS_COUNT_SUCCESS';
export const GET_ATTACHED_ELECTIONS_COUNT_FAILED =
  'GET_ATTACHED_ELECTIONS_COUNT_FAILED';

//Migration related
export const GET_POTENTIAL_PLAN_YEARS_IN_PROGRESS =
  'GET_POTENTIAL_PLAN_YEARS_IN_PROGRESS';
export const GET_POTENTIAL_PLAN_YEARS_COMPLETED =
  'GET_POTENTIAL_PLAN_YEARS_COMPLETED';
export const GET_POTENTIAL_PLAN_YEARS_FAILED =
  'GET_POTENTIAL_PLAN_YEARS_FAILED';

export const GET_ASSOCIATED_PLANS_COUNT_IN_PROGRESS =
  'GET_ASSOCIATED_PLANS_COUNT_IN_PROGRESS';
export const GET_ASSOCIATED_PLANS_COUNT_COMPLETED =
  'GET_ASSOCIATED_PLANS_COUNT_COMPLETED';
export const GET_ASSOCIATED_PLANS_COUNT_FAILED =
  'GET_ASSOCIATED_PLANS_COUNT_FAILED';

export const GET_ASSOCIATED_ELECTIONS_COUNT_IN_PROGRESS =
  'GET_ASSOCIATED_ELECTIONS_COUNT_IN_PROGRESS';
export const GET_ASSOCIATED_ELECTIONS_COUNT_COMPLETED =
  'GET_ASSOCIATED_ELECTIONS_COUNT_COMPLETED';
export const GET_ASSOCIATED_ELECTIONS_COUNT_FAILED =
  'GET_ASSOCIATED_ELECTIONS_COUNT_FAILED';

export const GET_ASSOCIATED_BENGUIDES_IN_PROGRESS =
  'GET_ASSOCIATED_BENGUIDES_IN_PROGRESS';
export const GET_ASSOCIATED_BENGUIDES_COMPLETED =
  'GET_ASSOCIATED_BENGUIDES_COMPLETED';
export const GET_ASSOCIATED_BENGUIDES_FAILED =
  'GET_ASSOCIATED_BENGUIDES_FAILED';

export const PLAN_YEAR_MIGRATION_FOR_PLANS_IN_PROGRESS =
  'PLAN_YEAR_MIGRATION_FOR_PLANS_IN_PROGRESS';
export const PLAN_YEAR_MIGRATION_FOR_PLANS_COMPLETED =
  'PLAN_YEAR_MIGRATION_FOR_PLANS_COMPLETED';
export const PLAN_YEAR_MIGRATION_FOR_PLANS_FAILED =
  'PLAN_YEAR_MIGRATION_FOR_PLANS_FAILED';

export const PLAN_YEAR_MIGRATION_FOR_ELECTIONS_IN_PROGRESS =
  'PLAN_YEAR_MIGRATION_FOR_ELECTIONS_IN_PROGRESS';
export const PLAN_YEAR_MIGRATION_FOR_ELECTIONS_COMPLETED =
  'PLAN_YEAR_MIGRATION_FOR_ELECTIONS_COMPLETED';
export const PLAN_YEAR_MIGRATION_FOR_ELECTIONS_FAILED =
  'PLAN_YEAR_MIGRATION_FOR_ELECTIONS_FAILED';

export const PLAN_YEAR_MIGRATION_FOR_BENGUIDES_IN_PROGRESS =
  'PLAN_YEAR_MIGRATION_FOR_BENGUIDES_IN_PROGRESS';
export const PLAN_YEAR_MIGRATION_FOR_BENGUIDES_COMPLETED =
  'PLAN_YEAR_MIGRATION_FOR_BENGUIDES_COMPLETED';
export const PLAN_YEAR_MIGRATION_FOR_BENGUIDES_FAILED =
  'PLAN_YEAR_MIGRATION_FOR_BENGUIDES_FAILED';

export const PLAN_YEAR_MIGRATION_ASSOCIATE_BENEFIT_GROUP_IN_PROGRESS =
  'PLAN_YEAR_MIGRATION_ASSOCIATE_BENEFIT_GROUP_IN_PROGRESS';
export const PLAN_YEAR_MIGRATION_ASSOCIATE_BENEFIT_GROUP_COMPLETED =
  'PLAN_YEAR_MIGRATION_ASSOCIATE_BENEFIT_GROUP_COMPLETED';
export const PLAN_YEAR_MIGRATION_ASSOCIATE_BENEFIT_GROUP_FAILED =
  'PLAN_YEAR_MIGRATION_ASSOCIATE_BENEFIT_GROUP_FAILED';
