/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import * as _ from 'lodash';
import {
  Button,
  Row,
  Col,
  Input,
  Table,
  Alert,
  FormGroup,
  Form
} from 'components/Atoms';

import './DialogIntegrationDetails.scss';

export const EmploymentStatusRow = ({
  errors,
  mappingLabel,
  statusMapping,
  employmentStatusOptions,
  employmentTypeOptions,
  updateStatusMapping
}) => {
  const fieldErrors = errors[mappingLabel];
  return (
    <tr>
      <td className="employment-mapping-label">{mappingLabel}</td>
      <td className="employment-status">
        <FormGroup>
          <Input
            type="select"
            name="employmentStatus"
            onChange={updateStatusMapping}
            value={statusMapping ? statusMapping['employmentStatus'] : ''}
            invalid={!!(fieldErrors && fieldErrors['employmentStatus'])}
          >
            <option key="select" value="">
              Select Option
            </option>
            {employmentStatusOptions.map((status) => {
              return (
                <option key={status} value={status}>
                  {status}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </td>
      <td className="employment-type">
        <FormGroup>
          <Input
            type="select"
            name="employmentType"
            onChange={updateStatusMapping}
            value={statusMapping ? statusMapping['employmentType'] : ''}
            invalid={!!(fieldErrors && fieldErrors['employmentType'])}
          >
            <option key="select" value="">
              Select Option
            </option>
            {employmentTypeOptions.map((type) => {
              return (
                <option key={type} value={type}>
                  {type}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </td>
    </tr>
  );
};
export class EmploymentStatusMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employmentStatusMappings:
        props.selectedEmploymentStatusMappings ||
        EmploymentStatusMapping.employmentStatusMappings(
          props.bambooHrEmploymentStatuses
        ),
      errors: {},
      isEditable: props.isEditable || false
    };
  }

  componentDidMount() {
    const { employmentStatusMappings, isEditable } = this.state;
    if (isEditable && employmentStatusMappings.length) {
      this.formatBHREmpStatusMappings();
    }
  }

  formatBHREmpStatusMappings = () => {
    const { employmentStatusMappings } = this.state;
    const { bambooHrEmploymentStatuses = [] } = this.props;

    let formattedEmpStatus = {};
    let oldEmpStatus = [];
    let newBHRStatus = [];

    const refactoredStatusMappings = [];
    bambooHrEmploymentStatuses.map(bambooEmp => {
      employmentStatusMappings.forEach(empStatus => {
        if(empStatus.bambooHrEmploymentStatus === bambooEmp){
          refactoredStatusMappings.push(empStatus);
        }
      })
      return refactoredStatusMappings;
    });

    refactoredStatusMappings.forEach((status) => {
      oldEmpStatus.push(status.bambooHrEmploymentStatus);
      formattedEmpStatus = {
        ...formattedEmpStatus,
        [status.bambooHrEmploymentStatus]: {
          employmentStatus: status.employmentStatus,
          employmentType: status.employmentType
        }
      };
    });

    //Get newly added employment status from bamboo
    newBHRStatus = bambooHrEmploymentStatuses.filter(
      (status) => !oldEmpStatus.includes(status)
    );
    if (newBHRStatus.length) {
      let newlyAddedStatus = {};
      newBHRStatus.forEach((bambooEmpStatus) => {
        newlyAddedStatus['employmentStatus'] = '';
        newlyAddedStatus['employmentType'] = '';

        formattedEmpStatus[bambooEmpStatus] = newlyAddedStatus;
      });
    }

    this.setState({ employmentStatusMappings: formattedEmpStatus });
  };

  updateStatusMapping = (status, e) => {
    const { name, value } = e.target;
    const { employmentStatusMappings, errors } = this.state;
    let updateEmploymentStatusMappings = {
      ...employmentStatusMappings
    };

    const updatedErrors = { ...errors };
    let updatedStatus = {
      ...updateEmploymentStatusMappings,
      [status]: { ...updateEmploymentStatusMappings[status], [name]: value }
    };

    // clear the errors if validation pass
    if (value && updatedErrors[status] && updatedErrors[status][name]) {
      delete updatedErrors[status][name];
      if (_.isEmpty(errors[status])) {
        delete updatedErrors[status];
      }
    }
    this.setState({
      employmentStatusMappings: updatedStatus,
      errors: updatedErrors
    });
  };

  onSubmit = () => {
    let errors = {};
    const { employmentStatusMappings } = this.state;
    const employmentStatusMappingsConfig = Object.keys(
      employmentStatusMappings
    ).map((vendorStatus) => {
      Object.keys(employmentStatusMappings[vendorStatus]).forEach((type) => {
        if (!employmentStatusMappings[vendorStatus][type])
          errors = {
            ...errors,
            [vendorStatus]: {
              ...(errors[vendorStatus] ? errors[vendorStatus] : {}),
              [type]: 'please select one'
            }
          };
      });
      return {
        bambooHrEmploymentStatus: vendorStatus,
        ...employmentStatusMappings[vendorStatus]
      };
    });
    if (_.isEmpty(errors)) {
      this.props.onNext(employmentStatusMappingsConfig);
    } else {
      this.setState({
        errors
      });
    }
  };

  renderEmploymentStatusMapping = () => {
    const {
      bambooHrEmploymentStatuses = [],
      lumityEmploymentStatuses = [],
      lumityEmploymentTypes = []
    } = this.props;
    const { employmentStatusMappings, errors } = this.state;
    return (
      <Form>
        <Table borderless className="mapping-table">
          <thead>
            <tr>
              <th>BambooHR Employment Status</th>
              <th>Lumity Employment Status</th>
              <th>Lumity Employment Type</th>
            </tr>
          </thead>
          <tbody>
            {bambooHrEmploymentStatuses.map((bHRStatus) => {
              return (
                <EmploymentStatusRow
                  key={bHRStatus}
                  mappingLabel={bHRStatus}
                  statusMapping={
                    !employmentStatusMappings.length
                      ? employmentStatusMappings[bHRStatus]
                      : ''
                  }
                  employmentStatusOptions={lumityEmploymentStatuses}
                  employmentTypeOptions={lumityEmploymentTypes}
                  updateStatusMapping={(e) =>
                    this.updateStatusMapping(bHRStatus, e)
                  }
                  errors={errors}
                />
              );
            })}
          </tbody>
        </Table>
      </Form>
    );
  };

  static employmentStatusMappings(bambooHrEmploymentStatuses) {
    const employmentStatusMappings = {};
    bambooHrEmploymentStatuses &&
      bambooHrEmploymentStatuses.forEach((bambooHrEmploymentStatus) => {
        employmentStatusMappings[bambooHrEmploymentStatus] = {
          employmentStatus: '',
          employmentType: ''
        };
      });
    return employmentStatusMappings;
  }

  onPrevious = () => {
    const { employmentStatusMappings } = this.state;
    this.props.onPrevious(employmentStatusMappings);
  };

  render() {
    const { selectedVendor } = this.props;
    const { errors } = this.state;
    return (
      <div className="step-container mapping-container">
        <div className="body">
          <p className="subtitle">{selectedVendor.name}</p>
          {!_.isEmpty(errors) && (
            <Alert color="danger" className="error-alert">
              Please enter required fields.
            </Alert>
          )}
          {this.renderEmploymentStatusMapping()}
        </div>
        <Row className="action-btns">
          <Col>
            <Button
              className="form-element"
              outline
              color="primary"
              size="lg"
              onClick={this.onPrevious}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              className="form-element"
              color="primary"
              size="lg"
              onClick={this.onSubmit}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
