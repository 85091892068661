/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import './FileHistory.scss';
import { NotificationManager } from 'react-notifications';
import ContentContainer from '../../../../../../containers/ContentContainer/ContentContainer';
import DataTableWithServerPagination from '../../../../../../components/DataTables';
import {
  getFileHistory,
  downloadHistoryBill
} from '../../../../actions/billingAction';
import moment from 'moment';
import { Button } from 'reactstrap';
import {
  BILLING_MONTH_FORMAT,
  SUMMARY_AND_DETAIL_FILE_TYPE,
  SUMMARY_FILE_TYPE,
  DETAIL_FILE_TYPE
} from '../../../../constants';
import { saveFileAs } from 'util/documentUtil';

class FileHistory extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    const {
      downloadHistoryBillSuccess,
      downloadHistoryBillError,
      downloadHistoryBillName
    } = this.props;
    if (
      downloadHistoryBillSuccess &&
      downloadHistoryBillName &&
      downloadHistoryBillSuccess !== prevProps.downloadHistoryBillSuccess &&
      downloadHistoryBillName !== prevProps.downloadHistoryBillName
    ) {
      console.log(downloadHistoryBillName);
      saveFileAs(downloadHistoryBillSuccess, downloadHistoryBillName);
    }
    if (
      downloadHistoryBillError !== prevProps.downloadHistoryBillError &&
      downloadHistoryBillError
    ) {
      NotificationManager.error('Failed to download the bill.');
    }
  }
  columnData = [
    {
      name: 'File Name',
      selector: 'files',
      cell: (row) => (
        <ul className="file-history-without-bullets">
          {row.files.map(({ type, fileName }) => (
            <li className="file-history-ellipsis" key={type}>
              {fileName}
            </li>
          ))}
        </ul>
      ),
      sortable: false,
      wrap: true
    },
    {
      name: 'Uploaded By',
      selector: 'createdByLoginId',
      sortable: false,
      wrap: true
    },
    {
      name: 'Uploaded Date',
      selector: 'createdTs',
      cell: (row) => {
        return moment(row.createdTs).format('MM/DD/YYYY hh:mm A');
      },
      sortable: true,
      wrap: true
    },
    {
      name: 'Action',
      cell: (row) => (
        <Button color="gray" onClick={() => this.downloadBill(row)}>
          Download
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true
    }
  ];
  /*
    Download History Billing Files
  */
  downloadBill = (selectedRow) => {
    const { downloadHistoryBills } = this.props;
    const { id, fileType, files } = selectedRow;
    if (fileType === SUMMARY_AND_DETAIL_FILE_TYPE) {
      files.forEach((file) => {
        if (file.type === DETAIL_FILE_TYPE) {
          downloadHistoryBills(id, file.type, file.fileName);
        }
        if (file.type === SUMMARY_FILE_TYPE) {
          downloadHistoryBills(id, file.type, file.fileName);
        }
      });
    } else {
      files.forEach((file) => {
        if (file.type === DETAIL_FILE_TYPE) {
          downloadHistoryBills(id, file.type, file.fileName);
        }
        if (file.type === SUMMARY_FILE_TYPE) {
          downloadHistoryBills(id, file.type, file.fileName);
        }
      });
    }
  };
  renderBillingHistoryForm = () => {
    const { fileHistoryData, getFileHistory, employerId } = this.props;
    const { id, billingFileType } = this.props.billingRow;
    return (
      <div className="file-history-form">
        <ContentContainer>
          <DataTableWithServerPagination
            onRef={(ref) => (this.FileHistory = ref)}
            columnData={this.columnData}
            fetchData={(pageNumber, rowsPerPage, sortField, query) =>
              getFileHistory(
                id,
                employerId,
                billingFileType,
                pageNumber,
                rowsPerPage,
                sortField,
                query
              )
            }
            pageData={fileHistoryData}
            defaultSortField="createdTs"
            hideSearchBar={true}
            defaultSortDesc={true}
          />
        </ContentContainer>
      </div>
    );
  };
  render() {
    const { billingMonth, year } = this.props.billingRow;
    const monthAndYear = `${billingMonth} ${year}`;
    return (
      <div className="file-history-content">
        <div className="text-center file-history">
          {moment(monthAndYear, BILLING_MONTH_FORMAT).format(
            BILLING_MONTH_FORMAT
          )}
        </div>
        {this.renderBillingHistoryForm()}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getFileHistory: (
    billRecordId,
    employerId,
    billingFileType,
    page,
    size,
    sort,
    query
  ) =>
    dispatch(
      getFileHistory(
        billRecordId,
        employerId,
        billingFileType,
        page,
        size,
        sort,
        query
      )
    ),
  downloadHistoryBills: (id, billingFileType, fileName) =>
    dispatch(downloadHistoryBill(id, billingFileType, fileName))
});
const mapStateToProps = (state) => {
  const {
    fileHistoryData,
    downloadHistoryBillSuccess,
    downloadHistoryBillError,
    downloadHistoryBillName
  } = state.billingReducer;
  return {
    fileHistoryData,
    downloadHistoryBillSuccess,
    downloadHistoryBillError,
    downloadHistoryBillName
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FileHistory);
