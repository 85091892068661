/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import { Login, PasswordReset } from './index';

export const LOGIN_PATH = '/login';
export const PASSWORD_RESET_REQUEST_PATH = '/password-reset';

const appBaseRoutes = [
  {
    path: LOGIN_PATH,
    component: Login
  },
  {
    path: PASSWORD_RESET_REQUEST_PATH,
    component: PasswordReset
  }
];

export default appBaseRoutes;
