/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import { MOBILE_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { permitIf } from 'components/hoc/Permit';
import {
  createConfigModule,
  updateConfigModule,
  findConfigStatus,
  updateChatbotConfig,
  getEmployer
} from '../actions/mobileActions';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import {
  FEATURE_KEY_MOBILE,
  FEATURE_KEY_CHATBOT,
  FEATURE_KEY_MODULE_DBG_ID_CARD_ENABLED,
  FEATURE_KEY_MODULE_ER_PORTAL_ID_CARD_ENABLED
} from '../constants';

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      welcomeMessage: '',
      farewellMessage: '',
      customerSupportCompatible: false,
      lumityConsultCompatible: false,
      isSavedChatbotConfig: false,
      showNotification: true
    };
  }

  componentDidMount() {
    const { findConfigStatus, match, getEmployer } = this.props;
    const { employerId } = match.params;
    findConfigStatus(employerId, FEATURE_KEY_MOBILE);
    findConfigStatus(employerId, FEATURE_KEY_MODULE_DBG_ID_CARD_ENABLED);
    findConfigStatus(employerId, FEATURE_KEY_MODULE_ER_PORTAL_ID_CARD_ENABLED);
    getEmployer(employerId);
  }

  componentDidUpdate = (prevProps) => {
    const {
      employerChatbot,
      employerChatbotConfigLoading,
      chatbotConfigUpdating
    } = this.props;
    const { showNotification, isSavedChatbotConfig } = this.state;
    if (
      employerChatbot &&
      prevProps.employerChatbotConfigLoading &&
      employerChatbotConfigLoading !== prevProps.employerChatbotConfigLoading &&
      !isSavedChatbotConfig
    ) {
      this.updateChatbotConfig();
    }

    if (
      prevProps.chatbotConfigUpdating &&
      chatbotConfigUpdating !== prevProps.chatbotConfigUpdating &&
      showNotification
    ) {
      NotificationManager.success(
        `"Lumity" the chatbot assistant configurations have been saved.`
      );
    }
  };

  updateChatbotConfig = () => {
    const { chatbotConfigSettings } = this.props.employerChatbot;
    const { mobileChatbotConfig } = chatbotConfigSettings;
    const { welcomeMessage, farewellMessage, fallbackOptions } = mobileChatbotConfig;
    this.setState({
      welcomeMessage: welcomeMessage,
      farewellMessage: farewellMessage,
      customerSupportCompatible:
        fallbackOptions && fallbackOptions.customerSupportCompatible
          ? fallbackOptions.customerSupportCompatible
          : false,
      lumityConsultCompatible:
        fallbackOptions && fallbackOptions.lumityConsultCompatible
          ? fallbackOptions.lumityConsultCompatible
          : false
    });
  };

  switchMobileEnabled = (config, key) => {
    const { match, createConfigModule, updateConfigModule } = this.props;
    const { employerId } = match.params;
    const { id, value } = config;
    if (!id) {
      createConfigModule(employerId, key);
    } else {
      updateConfigModule(id, employerId, value, key);
    }
    switch (key) {
      case FEATURE_KEY_MOBILE:
        NotificationManager.success(
          `Mobile ID Cards has been ${
            value === 'false' ? 'enabled' : 'disabled'
          }.`
        );
        break;
      case FEATURE_KEY_MODULE_DBG_ID_CARD_ENABLED:
        NotificationManager.success(
          `Digital Benefit Guide ID Cards has been ${
            value === 'false' ? 'enabled' : 'disabled'
          }.`
        );
        break;
      case FEATURE_KEY_MODULE_ER_PORTAL_ID_CARD_ENABLED:
        NotificationManager.success(
          `ER Portal ID Cards has been ${
            value === 'false' ? 'enabled' : 'disabled'
          }.`
        );
        break;
    }
  };

  switchChatbotEnabled = () => {
    const {
      match,
      chatbotConfigStatus,
      createConfigModule,
      updateConfigModule,
      employerChatbot
    } = this.props;
    const { employerId } = match.params;
    const { id, value } = chatbotConfigStatus;
    if (!id) {
      createConfigModule(employerId, FEATURE_KEY_CHATBOT);
    } else {
      updateConfigModule(id, employerId, value, FEATURE_KEY_CHATBOT);
    }
    if (
      employerChatbot &&
      employerChatbot.chatbotConfigSettings &&
      employerChatbot.chatbotConfigSettings.mobileChatbotConfig &&
      !employerChatbot.chatbotConfigSettings.mobileChatbotConfig.welcomeMessage
    ) {
      const fallbackOptions = {
        customerSupportCompatible: true,
        lumityConsultCompatible: false
      };
      const config = {
        fallbackOptions: fallbackOptions
      };
      this.setState({ showNotification: false }, () => {
        this.onSaveChatbotConfigs(config);
      });
    }
    NotificationManager.success(
      `"Lumity" the chatbot assistant has been ${
        value === 'false' ? 'enabled' : 'disabled'
      }.`
    );
  };

  onSaveChatbotConfigs = (config) => {
    const { employerId } = this.props.match.params;
    const { welcomeMessage, farewellMessage, fallbackOptions } = config;
    const {
      customerSupportCompatible,
      lumityConsultCompatible
    } = fallbackOptions;
    this.setState(
      {
        isSavedChatbotConfig: true,
        welcomeMessage,
        farewellMessage,
        customerSupportCompatible,
        lumityConsultCompatible
      },
      () => {
        this.props.updateChatbotConfig(employerId, config);
      }
    );
  };

  render() {
    const {
      configStatus,
      dbgIdCardConfigStatus,
      erPortalIdCardConfigStatus,
      match
    } = this.props;
    const SecuredEmployerConfigToggle = permitIf(
      EmployerConfigToggleV2,
      MOBILE_FEATURE_ROLE_MAPPING.common
    );
    return (
      <ApplicationWrapper>
        <HeaderContainer title="Employee ID Cards" />
        <ContentContainer>
          <SecuredEmployerConfigToggle
            employerId={this.props.match.params.employerId}
            moduleEnabled={FEATURE_KEY_MOBILE}
            message={'Mobile ID Cards'}
            config={configStatus}
            switchCallBack={() =>
              this.switchMobileEnabled(configStatus, FEATURE_KEY_MOBILE)
            }
          ></SecuredEmployerConfigToggle>
        </ContentContainer>
        <ContentContainer>
          <SecuredEmployerConfigToggle
            employerId={this.props.match.params.employerId}
            moduleEnabled={FEATURE_KEY_MODULE_DBG_ID_CARD_ENABLED}
            message={'Digital Benefits Guide ID Cards'}
            config={dbgIdCardConfigStatus}
            switchCallBack={() =>
              this.switchMobileEnabled(
                dbgIdCardConfigStatus,
                FEATURE_KEY_MODULE_DBG_ID_CARD_ENABLED
              )
            }
          ></SecuredEmployerConfigToggle>
        </ContentContainer>
        <ContentContainer>
          <SecuredEmployerConfigToggle
            employerId={this.props.match.params.employerId}
            moduleEnabled={FEATURE_KEY_MODULE_ER_PORTAL_ID_CARD_ENABLED}
            message={'ER Portal ID Cards'}
            config={erPortalIdCardConfigStatus}
            switchCallBack={() =>
              this.switchMobileEnabled(
                erPortalIdCardConfigStatus,
                FEATURE_KEY_MODULE_ER_PORTAL_ID_CARD_ENABLED
              )
            }
          ></SecuredEmployerConfigToggle>
        </ContentContainer>
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createConfigModule: (employerId, key) =>
    dispatch(createConfigModule(employerId, key)),
  updateConfigModule: (id, employerId, value, key) =>
    dispatch(updateConfigModule(id, employerId, value, key)),
  findConfigStatus: (employerId, key) =>
    dispatch(findConfigStatus(employerId, key)),
  updateChatbotConfig: (employerId, config) =>
    dispatch(updateChatbotConfig(employerId, config)),
  getEmployer: (employerId) => dispatch(getEmployer(employerId))
});

const mapStateToProps = (state) => {
  const {
    employerConfigCreate,
    employerConfigCreateError,
    employerConfigUpdate,
    employerConfigUpdateError,
    configStatus,
    dbgIdCardConfigStatus,
    erPortalIdCardConfigStatus,
    chatbotConfigStatus,
    bConsultationConfigStatus,
    employerChatbot,
    employerChatbotConfigLoading,
    chatbotConfigUpdating
  } = state.mobileReducer;
  return {
    employerConfigCreate,
    employerConfigCreateError,
    employerConfigUpdate,
    employerConfigUpdateError,
    configStatus,
    dbgIdCardConfigStatus,
    erPortalIdCardConfigStatus,
    chatbotConfigStatus,
    bConsultationConfigStatus,
    employerChatbot,
    employerChatbotConfigLoading,
    chatbotConfigUpdating
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mobile);
