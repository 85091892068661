/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import _ from 'lodash';

import ContentContainer from 'containers/ContentContainer/ContentContainer';
import ActionMenu from '../../../Offers/components/ActionMenu/ActionMenu';
import { Label } from 'components/Atoms';
import { AgGrid } from 'components/DataTables';
import CustomDataFilter from '../CustomDataFilter/CustomDataFilter';
import FullscreenModal from 'components/FullscreenModal';
import OfferPlanDetails from '../OfferPlanDetails/OfferPlanDetails';
import { formatCurrency } from 'util/commonUtil';

import './OffersPlanTable.scss';
import OfferLifePlanDetails from '../OfferPlanDetails/OfferLifePlanDetails';
const TOOLTIP_LENGTH = 15;

class OffersPlanDataTable extends Component {
  getTierContributionFromResponse(responseObj, tierFilter) {
    const pc = responseObj.planContribution;
    return pc &&
      pc.tierContributionsDefault &&
      pc.tierContributionsDefault[tierFilter] !== undefined
      ? '$' + pc.tierContributionsDefault[tierFilter].premium
      : 0;
  }

  getDeductibleValueFromResponse(responseObj, fieldSubType) {
    const deductible = responseObj.deductibles
      ? responseObj.deductibles.filter(
          (deductible) =>
            deductible.fieldSubType === fieldSubType &&
            deductible.fieldType === 'In Network'
        )
      : null;
    return deductible && deductible.length > 0 && deductible[0].value !== null
      ? deductible[0].value
      : '';
  }

  getOOPValueFromResponse(responseObj) {
    const oopMax1 =
      'oops' in responseObj && responseObj.oops.length > 0
        ? responseObj.oops[0].value
        : '';
    const oopMax2 =
      'oops' in responseObj && responseObj.oops.length > 1
        ? ' / ' + responseObj.oops[1].value
        : '';
    return oopMax1 + oopMax2;
  }

  getMonthlyBenefitMax(responseObj) {
    return responseObj.monthlyBenefitMax;
  }

  getPlanGroup(responseObj) {
    if (responseObj.groupNames && responseObj.groupNames.length > 0) {
      return responseObj.groupNames[0];
    } else {
      return '';
    }
  }

  getBundleType(responseObj) {
    if (responseObj.bundleTypes && responseObj.bundleTypes.length > 0) {
      return responseObj.bundleTypes[0];
    } else {
      return '';
    }
  }

  disableCurrentPlanSelection(responseObj) {
    if (
      !_.isEmpty(responseObj.data) &&
      responseObj.data.offerTypes[0] === 'CURRENT'
    ) {
      return 'disable-checkbox';
    }
  }

  constructor(props) {
    super(props);

    let columnDefs = [];

    if (props.type === 'MEDICAL') {
      columnDefs = [
        {
          headerName: 'Select',
          field: 'select',
          minWidth: 60,
          checkboxSelection: true,
          pinned: 'left',
          cellClass: this.disableCurrentPlanSelection,
          suppressMovable: true
        },
        {
          headerName: 'Carrier',
          field: 'carrier',
          minWidth: 160,
          sortable: true,
          filter: 'nameFilter',
          headerClass: 'carrier-header-style ',
          pinned: 'left',
          suppressMovable: true,
          tooltipValueGetter: function(params) {
            let carrier = params.data.carrier;
            if (carrier.length > TOOLTIP_LENGTH) {
              return carrier;
            }
          }
        },
        {
          headerName: 'Plan Name',
          field: 'name',
          minWidth: 180,
          sortable: true,
          pinned: 'left',
          filter: 'nameFilter',
          cellRendererFramework: planNameRenderer,
          suppressMovable: true,
          cellRendererParams: {
            onClick: this.openPlanDetailPopup
          }
        },
        {
          headerName: 'Plan Type',
          field: 'planType',
          minWidth: 100,
          sortable: true,
          pinned: 'left',
          filter: 'nameFilter',
          suppressMovable: true
        },
        {
          headerName: 'Deductible',
          field: 'deductibles',
          minWidth: 150,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          valueGetter: (params) =>
            this.getDeductibleValueFromResponse(params.data, 'Single') +
            ' / ' +
            this.getDeductibleValueFromResponse(params.data, 'Family')
        },
        {
          headerName: 'OOP Max',
          minWidth: 150,
          sortable: true,
          valueGetter: (params) => this.getOOPValueFromResponse(params.data),
          filter: 'nameFilter',
          suppressMovable: true
        },
        {
          headerName: 'Total Impact vs. Current',
          field: 'total',
          cellRendererFramework: TotalImpactVsCurrent,
          minWidth: 140,
          sortable: false,
          suppressMovable: true,
          cellClass: 'impact-cell'
        },
        {
          headerName: 'EE Rate',
          minWidth: 85,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          comparator: this.rateComparator,
          valueGetter: (params) =>
            this.getTierContributionFromResponse(params.data, 'EE')
        },
        {
          headerName: 'ES Rate',
          minWidth: 86,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          comparator: this.rateComparator,
          valueGetter: (params) =>
            this.getTierContributionFromResponse(params.data, 'ES')
        },
        {
          headerName: 'EC Rate',
          minWidth: 86,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          comparator: this.rateComparator,
          valueGetter: (params) =>
            this.getTierContributionFromResponse(params.data, 'EC')
        },
        {
          headerName: 'EF Rate',
          minWidth: 85,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          comparator: this.rateComparator,
          valueGetter: (params) =>
            this.getTierContributionFromResponse(params.data, 'EF')
        },
        {
          headerName: 'Plan Group',
          field: 'planGroup',
          minWidth: 150,
          sortable: true,
          valueGetter: (params) => this.getPlanGroup(params.data),
          filter: 'nameFilter',
          suppressMovable: true
        },
        {
          headerName: 'Bundle Type',
          field: 'bundleType',
          minWidth: 110,
          sortable: true,
          valueGetter: (params) => this.getBundleType(params.data),
          cellClass: 'itc-container',
          suppressMovable: true,
          filter: 'nameFilter'
        },
        {
          headerName: 'Actions',
          field: 'actions',
          minWidth: 70,
          cellRendererFramework: showActionsMenu,
          cellClass: 'select-dropdown-cell',
          suppressMovable: true,
          cellRendererParams: {
            onClick: this.openPlanDetailPopup
          }
        }
      ];
    } else if (props.type === 'DENTAL') {
      columnDefs = [
        {
          headerName: 'Select',
          field: 'select',
          minWidth: 60,
          checkboxSelection: true,
          pinned: 'left',
          cellClass: this.disableCurrentPlanSelection,
          suppressMovable: true
        },
        {
          headerName: 'Carrier',
          field: 'carrier',
          minWidth: 160,
          sortable: true,
          filter: 'nameFilter',
          headerClass: 'carrier-header-style ',
          suppressMovable: true,
          pinned: 'left',
          tooltipValueGetter: function(params) {
            let carrier = params.data.carrier;
            if (carrier.length > TOOLTIP_LENGTH) {
              return carrier;
            }
          }
        },
        {
          headerName: 'Plan Name',
          field: 'name',
          minWidth: 180,
          sortable: true,
          pinned: 'left',
          filter: 'nameFilter',
          cellRendererFramework: planNameRenderer,
          suppressMovable: true,
          cellRendererParams: {
            onClick: this.openPlanDetailPopup
          }
        },
        {
          headerName: 'Deductible',
          field: 'deductibles',
          minWidth: 150,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          valueGetter: (params) =>
            this.getDeductibleValueFromResponse(params.data, 'Single') +
            ' / ' +
            this.getDeductibleValueFromResponse(params.data, 'Family')
        },
        {
          headerName: 'EE Rate',
          minWidth: 85,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          comparator: this.rateComparator,
          valueGetter: (params) =>
            this.getTierContributionFromResponse(params.data, 'EE')
        },
        {
          headerName: 'ES Rate',
          minWidth: 86,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          comparator: this.rateComparator,
          valueGetter: (params) =>
            this.getTierContributionFromResponse(params.data, 'ES')
        },
        {
          headerName: 'EC Rate',
          minWidth: 86,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          comparator: this.rateComparator,
          valueGetter: (params) =>
            this.getTierContributionFromResponse(params.data, 'EC')
        },
        {
          headerName: 'EF Rate',
          minWidth: 85,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          comparator: this.rateComparator,
          valueGetter: (params) =>
            this.getTierContributionFromResponse(params.data, 'EF')
        },
        {
          headerName: 'Plan Group',
          field: 'planGroup',
          minWidth: 150,
          sortable: true,
          valueGetter: (params) => this.getPlanGroup(params.data),
          suppressMovable: true,
          filter: 'nameFilter'
        },
        {
          headerName: 'Bundle Type',
          field: 'bundleType',
          minWidth: 120,
          sortable: true,
          valueGetter: (params) => this.getBundleType(params.data),
          cellClass: 'itc-container',
          suppressMovable: true,
          filter: 'nameFilter'
        },
        {
          headerName: 'Actions',
          field: 'actions',
          minWidth: 70,
          cellRendererFramework: showActionsMenu,
          cellClass: 'select-dropdown-cell',
          suppressMovable: true,
          cellRendererParams: {
            onClick: this.openPlanDetailPopup
          }
        }
      ];
    } else if (props.type === 'VISION') {
      columnDefs = [
        {
          headerName: 'Select',
          field: 'select',
          minWidth: 60,
          checkboxSelection: true,
          pinned: 'left',
          cellClass: this.disableCurrentPlanSelection,
          suppressMovable: true
        },
        {
          headerName: 'Carrier',
          field: 'carrier',
          minWidth: 160,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          headerClass: 'carrier-header-style ',
          pinned: 'left',
          tooltipValueGetter: function(params) {
            let carrier = params.data.carrier;
            if (carrier.length > TOOLTIP_LENGTH) {
              return carrier;
            }
          }
        },
        {
          headerName: 'Plan Name',
          field: 'name',
          minWidth: 180,
          sortable: true,
          pinned: 'left',
          filter: 'nameFilter',
          cellRendererFramework: planNameRenderer,
          suppressMovable: true,
          cellRendererParams: {
            onClick: this.openPlanDetailPopup
          }
        },
        {
          headerName: 'EE Rate',
          minWidth: 85,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          comparator: this.rateComparator,
          valueGetter: (params) =>
            this.getTierContributionFromResponse(params.data, 'EE')
        },
        {
          headerName: 'ES Rate',
          minWidth: 86,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          comparator: this.rateComparator,
          valueGetter: (params) =>
            this.getTierContributionFromResponse(params.data, 'ES')
        },
        {
          headerName: 'EC Rate',
          minWidth: 86,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          comparator: this.rateComparator,
          valueGetter: (params) =>
            this.getTierContributionFromResponse(params.data, 'EC')
        },
        {
          headerName: 'EF Rate',
          minWidth: 85,
          sortable: true,
          filter: 'nameFilter',
          suppressMovable: true,
          comparator: this.rateComparator,
          valueGetter: (params) =>
            this.getTierContributionFromResponse(params.data, 'EF')
        },
        {
          headerName: 'Plan Group',
          field: 'planGroup',
          minWidth: 150,
          sortable: true,
          valueGetter: (params) => this.getPlanGroup(params.data),
          suppressMovable: true,
          filter: 'nameFilter'
        },
        {
          headerName: 'Bundle Type',
          field: 'bundleType',
          minWidth: 120,
          sortable: true,
          valueGetter: (params) => this.getBundleType(params.data),
          cellClass: 'itc-container',
          suppressMovable: true,
          filter: 'nameFilter'
        },
        {
          headerName: 'Actions',
          field: 'actions',
          minWidth: 70,
          cellRendererFramework: showActionsMenu,
          cellClass: 'select-dropdown-cell',
          suppressMovable: true,
          cellRendererParams: {
            onClick: this.openPlanDetailPopup
          }
        }
      ];
    } else if (props.type === 'LIFE') {
      columnDefs = [
        {
          headerName: 'Select',
          field: 'select',
          maxWidth: 60,
          checkboxSelection: true,
          pinned: 'left',
          cellClass: this.disableCurrentPlanSelection,
          suppressMovable: true
        },
        {
          headerName: 'Carrier',
          field: 'carrier',
          suppressMovable: true,
          minWidth: 160,
          sortable: true,
          filter: 'nameFilter',
          headerClass: 'carrier-header-style ',
          pinned: 'left',
          tooltipValueGetter: function(params) {
            let carrier = params.data.carrier;
            if (carrier.length > TOOLTIP_LENGTH) {
              return carrier;
            }
          }
        },
        {
          headerName: 'Plan Name',
          field: 'name',
          minWidth: 180,
          sortable: true,
          pinned: 'left',
          filter: 'nameFilter',
          cellRendererFramework: planNameRenderer,
          suppressMovable: true,
          cellRendererParams: {
            onClick: this.openLifePlanDetailPopup
          }
        },
        {
          headerName: 'Monthly Benefit Max',
          minWidth: 120,
          sortable: true,
          suppressMovable: true,
          valueGetter: (params) => this.getMonthlyBenefitMax(params.data)
        },
        {
          headerName: 'Plan Group',
          field: 'planGroup',
          minWidth: 150,
          sortable: true,
          suppressMovable: true,
          valueGetter: (params) => this.getPlanGroup(params.data),
          filter: 'nameFilter'
        },
        {
          headerName: 'Bundle Type',
          field: 'bundleType',
          minWidth: 120,
          sortable: true,
          suppressMovable: true,
          cellClass: 'itc-container',
          valueGetter: (params) => this.getBundleType(params.data),
          filter: 'nameFilter'
        },
        {
          headerName: 'Actions',
          field: 'actions',
          minWidth: 70,
          cellRendererFramework: showActionsMenu,
          suppressMovable: true,
          cellClass: 'select-dropdown-cell',
          cellRendererParams: {
            onClick: this.openLifePlanDetailPopup
          }
        }
      ];
    }

    this.state = {
      type: props.type,
      employerId: props.employerId,
      medicalOfferPlans: [],
      dentalOfferPlans: [],
      visionOfferPlans: [],
      lifeOfferPlans: [],
      columnDefs: columnDefs,
      isPlanDetailsPopupOpen: false,
      selectedRows: this.props.selectedRows,
      selectedPlan: null,
      isLifePlanDetailsPopupOpen: false
    };
  }
  rateComparator = (val1, val2, nodeA, nodeB, isInverted) => {
    val1 = val1.substring(1);
    val2 = val2.substring(1);
    return (
      parseFloat(val1.replace(',', '')) - parseFloat(val2.replace(',', ''))
    );
  };
  componentDidUpdate(prevProps) {
    const { type, plans } = this.props;
    if (type === 'MEDICAL' && prevProps.plans !== plans) {
      setTimeout(() => this.api.setRowData(plans), 0);
    }
    if (type === 'DENTAL' && prevProps.plans !== plans) {
      setTimeout(() => this.api.setRowData(plans), 0);
    }
    if (type === 'VISION' && prevProps.plans !== plans) {
      setTimeout(() => this.api.setRowData(plans), 0);
    }
    if (type === 'LIFE' && prevProps.plans !== plans) {
      setTimeout(() => this.api.setRowData(plans), 0);
    }
  }

  onGridReady(param) {
    this.api = param.api;

    this.props.getProposalPlans(this.state.employerId, this.state.type);
  }

  onGridSizeChanged(params) {
    const height = document.getElementsByTagName('html')[0].offsetHeight - 359;
    document.getElementsByClassName(
      'proposals-table-size'
    )[0].style.height = `${height}px`;
    params.api.sizeColumnsToFit();
  }

  openPlanDetailPopup = (event, planData) => {
    this.setState({
      selectedPlan: planData,
      isPlanDetailsPopupOpen: true
    });
  };
  openLifePlanDetailPopup = (event, planData) => {
    this.setState({
      selectedPlan: planData,
      isLifePlanDetailsPopupOpen: true
    });
  };
  getPlanTitle = () => {
    const { selectedPlan } = this.state;
    let title = '';
    if (selectedPlan) {
      const { carrier, name } = this.state.selectedPlan;
      title = carrier && name ? `${carrier} ${name} Details` : title;
    }
    return title;
  };

  onSelectionChanged() {
    var selectedRows = this.api.getSelectedRows();
    this.props.updateSelectedRows(selectedRows);
  }

  render() {
    return (
      <ContentContainer className="proposal-plans">
        <div className="ag-theme-balham proposals-table-size proposal-plan-table">
          <AgGrid
            columnDefs={this.state.columnDefs}
            rowHeight="50"
            onGridReady={(param) => this.onGridReady(param)}
            onGridSizeChanged={this.onGridSizeChanged.bind(this)}
            rowSelection="multiple"
            suppressCellSelection={true}
            suppressRowClickSelection={true}
            suppressDragLeaveHidesColumns={true}
            paginationAutoPageSize={true}
            onSelectionChanged={this.onSelectionChanged.bind(this)}
            frameworkComponents={{ nameFilter: CustomDataFilter }}
            enableBrowserTooltips={true}
            height={600}
          ></AgGrid>
          <FullscreenModal
            isOpen={this.state.isPlanDetailsPopupOpen}
            toggle={() =>
              this.setState({
                selectedPlan: null,
                isPlanDetailsPopupOpen: false
              })
            }
            className=""
            title={this.getPlanTitle()}
          >
            {this.state.selectedPlan && (
              <OfferPlanDetails
                data={this.state.selectedPlan}
                type={this.state.type}
              />
            )}
          </FullscreenModal>
          <FullscreenModal
            isOpen={this.state.isLifePlanDetailsPopupOpen}
            toggle={() =>
              this.setState({
                selectedPlan: null,
                isLifePlanDetailsPopupOpen: false
              })
            }
            className=""
            title={this.getPlanTitle()}
          >
            {this.state.selectedPlan && (
              <OfferLifePlanDetails
                data={this.state.selectedPlan}
                type={this.state.type}
              />
            )}
          </FullscreenModal>
        </div>
      </ContentContainer>
    );
  }
}

const showActionsMenu = (props) => {
  return (
    <div>
      <ActionMenu
        ItemsList={[
          {
            action: 'Preview Plan Details',
            onClick: (event) => {
              props.onClick(event, props.data, props.type);
            }
          }
        ]}
      />
    </div>
  );
};

const planNameRenderer = (props) => {
  return (
    <span
      className="proposal-plan-name"
      onClick={(event) => props.onClick(event, props.data, props.type)}
    >
      {props.value}
    </span>
  );
};

const TotalImpactVsCurrent = (props) => {
  const { data } = props;
  const { id, comparisons } = data;
  if (comparisons) {
    return (
      <div className="impact-cell-style" key={`impact-cell_${id}`}>
        <Label>
          <strong>
            {comparisons.totalImpact > 0 ? '+' : ''}
            {formatCurrency(comparisons.totalImpact)}
          </strong>
        </Label>
        <br />
        <Label className="itc-container">
          {comparisons.totalImpactPercent > 0 ? '+' : ''}
          {comparisons.totalImpactPercent}%
        </Label>
      </div>
    );
  } else {
    return '';
  }
};

export default OffersPlanDataTable;
