/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { ErrorBoundary } from 'modules/app-base';

import {
  setEmployerContext,
  setNavigationContext,
  setOrganisationContext
} from 'modules/app-base/actions/contextActions';
import createNavigationContext from 'routes/navigationTreeUtil';
import EmployerService from 'services/EmployerService';
import IntegrationService from 'services/IntegrationsService';
import Loader from 'components/CircularProgress';
import { getIsRolePermitted } from 'util/roleUtil';
import OrganisationService from 'services/OrganizationService';
import {
  LOGIN_PATH,
  PASSWORD_RESET_REQUEST_PATH
} from 'modules/app-base/routes';

class PrivateRoute extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      navigationContextAvailable: false
    };
    const { computedMatch } = this.props;
    this.setContextObjects(computedMatch);
  }

  async componentDidMount() {
    const { computedMatch } = this.props;
    await this.setContextObjects(computedMatch);
  }

  componentDidUpdate(prevProps) {
    const {
      computedMatch,
      location,
      employerContext,
      navigationContext
    } = this.props;

    // Trigger navigation re-render on page change.
    if (
      location.pathname !== prevProps.location.pathname ||
      employerContext !== prevProps.employerContext
    ) {
      this.setContextObjects(computedMatch);
    }
    if (navigationContext !== prevProps.navigationContext) {
      this.setState({
        navigationContextAvailable: true
      });
    }
  }

  // Need to use match as an argument because match does not exist in props when mounting.
  setContextObjects = async (match) => {
    const { setNavigationContext, appBootupInfo } = this.props;
    const { type: loginType } = appBootupInfo || {};
    const employerContext = await this.setEmployerContext(match);
    const organizationContext = await this.setOrgContext(match);

    const navigationContext = createNavigationContext(
      match,
      {
        employerContext,
        organizationContext
      },
      loginType
    );
    setNavigationContext(navigationContext);
  };

  setOrgContext = async (match) => {
    const { organizationContext, setOrganisationContext } = this.props;
    const currentOrgId = match.params.brokerId;
    let newOrgContext = organizationContext ? organizationContext : {};

    if (currentOrgId && currentOrgId !== newOrgContext.id) {
      const org = await OrganisationService.findById(currentOrgId)
        .then((response) => response.data)
        .catch((error) => {
          console.error(
            'Unable to find the specified Organization. Redirecting user...'
          );
          window.location.href = window.location.origin;
        });
      newOrgContext = org;
      setOrganisationContext(org);
    }
    return newOrgContext;
  };

  setEmployerContext = async (match) => {
    const { employerContext, setEmployerContext } = this.props;
    let newEmployerContext = employerContext,
      employerIntegrationDetails;

    const currentEmployerId = match.params.employerId;
    const contextEmployerId = newEmployerContext.id;
    if (
      currentEmployerId &&
      (!newEmployerContext.integrations ||
        !newEmployerContext.integrations[currentEmployerId])
    ) {
      try {
        const integrationDetails = await IntegrationService.getEmployerIntegrationDetails(
          currentEmployerId
        );
        employerIntegrationDetails =
          integrationDetails && integrationDetails.data;
        newEmployerContext.integrations = {
          [currentEmployerId]: employerIntegrationDetails
        };
      } catch (error) {
        console.error('Unable to get employer integration details.');
      }
    }

    if (currentEmployerId && currentEmployerId !== contextEmployerId) {
      const employerObject = await EmployerService.findById(currentEmployerId)
        .then((response) => response.data)
        // In case of error redirect to home page.
        // TODO: Replace with 404 page.
        .catch((error) => {
          console.error(
            'Unable to find the specified employer. Redirecting user...'
          );
          window.location.href = window.location.origin;
          // history.push('/');
        });
      newEmployerContext = {
        ...employerObject,
        [currentEmployerId]: employerIntegrationDetails
      };
      setEmployerContext(newEmployerContext);
    }
    return newEmployerContext;
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(routeProps) => {
          // Destructure within the anonymous function so that most recent appBootupInfo and navigationContext are returned.
          const {
            appBootupInfo,
            employerContext,
            navigationContext,
            organizationContext
          } = this.props;
          return (
            <>
              {routeProps.match.url === LOGIN_PATH ||
              routeProps.match.url === PASSWORD_RESET_REQUEST_PATH ? (
                <Component {...routeProps} />
              ) : organizationContext === null ? (
                <Loader />
              ) : this.state.navigationContextAvailable ? (
                isOrgContextValid(routeProps.match, organizationContext) ? (
                  isAuthenticated(
                    navigationContext,
                    appBootupInfo,
                    routeProps.match.url,
                    routeProps.match.path
                  ) ? (
                    // Don't show any details for a employer's page until context is valid.
                    isEmployerContextValid(
                      routeProps.match,
                      employerContext
                    ) ? (
                      <Component {...routeProps} />
                    ) : (
                      <Loader />
                    )
                  ) : (
                    <ErrorBoundary appBootupInfo={appBootupInfo} />
                  )
                ) : (
                  <Loader />
                )
              ) : (
                <Loader />
              )}
            </>
          );
        }}
      />
    );
  }
}

const isAuthenticated = (navigationContext, appBootupInfo, url, path) => {
  const { organizationId } = appBootupInfo || {};
  if (url === LOGIN_PATH || url === PASSWORD_RESET_REQUEST_PATH) {
    return true;
  }
  if (appBootupInfo) {
    if (organizationId && url === `/brokers/${organizationId}`) {
      return true;
    } else if (!organizationId && url === '/') {
      return true;
    } else {
      if (navigationContext) {
        const context = getContextByUrl(navigationContext, url, path);
        if (context) {
          return getIsRolePermitted(appBootupInfo.roles, context.roles);
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }
};

const getContextByUrl = (contextList, url, path) => {
  let navContext = null;
  for (let i = 0; i < contextList.length; i++) {
    const context = contextList[i];
    if (context.linkTo === url) {
      return context;
    }
    if (context.subMenu) {
      navContext = getContextByUrl(context.subMenu, url, path);
    } else if (context.routes && context.routes.includes(path)) {
      return context;
    }
  }
  return navContext;
};

const isEmployerContextValid = (match, employer) => {
  const { employerId } = match.params;
  if (employerId) {
    return employer && employer.id === employerId;
  } else {
    // employerId does not exist in path, so no need to validate.
    return true;
  }
};

const isOrgContextValid = (match, org) => {
  const { brokerId } = match.params;
  if (brokerId) {
    return org && org.id === brokerId;
  } else {
    // brokerId does not exist in path, so no need to validate.
    return true;
  }
};

export default connect(
  (state) => {
    const { authReducer, contextReducer } = state.AppBase;

    const { appBootupInfo } = authReducer;
    const {
      employerContext,
      navigationContext,
      organizationContext
    } = contextReducer;
    return {
      appBootupInfo,
      employerContext,
      navigationContext,
      organizationContext
    };
  },
  (dispatch) =>
    bindActionCreators(
      { setEmployerContext, setNavigationContext, setOrganisationContext },
      dispatch
    )
)(PrivateRoute);
