/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/idCards.actionTypes';

const initialState = {
  idCards: {
    data: [],
    isFetching: false,
    error: null
  },
  updateIdCardStatus: {
    inProgress: false,
    error: null
  },
  alphaPrefix: {
    data: '',
    isFetching: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ID_CARDS_IN_PROGRESS:
      return {
        ...state,
        idCards: {
          data: [],
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_ID_CARDS_COMPLETED:
      return {
        ...state,
        idCards: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_ID_CARDS_FAILED:
      return {
        ...state,
        idCards: {
          data: [],
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.UPDATE_ID_CARD_IN_PROGRESS:
      return {
        ...state,
        updateIdCardStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_ID_CARD_COMPLETED:
      // Update the single ID card by individualId and benefitKind.
      const newIdCards = [...state.idCards.data];
      const editedIndividualId = action.payload.individualId;
      const editedBenefitKind = action.payload.benefitKind;

      const oldIndividual = newIdCards.find(
        (individual) => individual.individualId === editedIndividualId
      );
      const oldIdCardIndex = oldIndividual.idCards.findIndex(
        (idCard) => idCard.benefitKind === editedBenefitKind
      );
      oldIndividual.idCards[oldIdCardIndex] = action.payload;

      return {
        ...state,
        idCards: {
          ...state.idCards,
          data: newIdCards
        },
        updateIdCardStatus: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.UPDATE_ID_CARD_FAILED:
      return {
        ...state,
        updateIdCardStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_ALPHA_PREFIX_IN_PROGRESS:
      return {
        ...state,
        alphaPrefix: {
          data: '',
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_ALPHA_PREFIX_COMPLETED:
      return {
        ...state,
        alphaPrefix: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_ALPHA_PREFIX_FAILED:
      return {
        ...state,
        alphaPrefix: {
          data: '',
          isFetching: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
