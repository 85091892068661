/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { withRouter } from 'react-router-dom';
import FullscreenModal from 'components/FullscreenModal';
import PageActionButton from 'components/Buttons';
import { findComputedOverallRate } from '../../../../../actions/benchmarkActions';
import _ from 'lodash';

import './SavingsOverrideModal.scss';

import { Row, Col, Input } from 'components/Atoms';
import { MEDICAL, DENTAL, VISION } from 'modules/Benchmarks/constants';

class SavingOverrideModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const { summaryInfo } = props.benchmark;
    this.state = {
      summaryInfo: this.cloneSummaryInfo(summaryInfo),
      autoCalculate: true,
      overallRates: {
        overall: '',
        medical: ''
      }
    };
  }

  cloneSummaryInfo = (summaryInfo) => {
    if (summaryInfo) {
      const clonedSummaryInfo = {
        overall: {
          ...summaryInfo.overall
        }
      };
      clonedSummaryInfo.benefitCategorySummary = {};
      Object.keys(summaryInfo.benefitCategorySummary).forEach((key) => {
        clonedSummaryInfo.benefitCategorySummary[key] = {
          ...summaryInfo.benefitCategorySummary[key]
        };
      });
      return clonedSummaryInfo;
    }
    return {};
  };

  componentDidUpdate(prevProps) {
    const { benchmark, rates } = this.props;
    if (
      prevProps.benchmark &&
      benchmark.summaryInfo !== prevProps.benchmark.summaryInfo
    ) {
      this.getOrResetSavingsVaules();
    }
    if (prevProps.rates !== this.props.rates) {
      this.setState({
        summaryInfo: this.cloneSummaryInfo(benchmark.summaryInfo),
        overallRates: rates
      });
    }
  }

  getOrResetSavingsVaules = () => {
    const { benchmark } = this.props;
    const {
      benefitCategorySummary = {},
      overall = {}
    } = benchmark.summaryInfo;
    const { MEDICAL = {}, DENTAL = {}, VISION = {} } = _.cloneDeep(
      benefitCategorySummary
    );
    const { rateMarketPercentage: rateMarketPercentageMedial = 0 } = MEDICAL;
    const { rateMarketPercentage: rateMarketPercentageDental = 0 } = DENTAL;
    const { rateMarketPercentage: rateMarketPercentageVision = 0 } = VISION;
    const { rateMarketPercentage } = overall;
    this.setState({
      summaryInfo: this.cloneSummaryInfo(benchmark.summaryInfo),
      overallRates: {
        overall: rateMarketPercentage,
        medical: rateMarketPercentageMedial,
        dental: rateMarketPercentageDental,
        vision: rateMarketPercentageVision
      }
    });
  }

  getLabel = (benefitCategory) => `${benefitCategory} Savings`;

  getSummaryInfo = (summaryInfo, category, overall) =>
    summaryInfo && overall
      ? { ...summaryInfo.overall }
      : summaryInfo && summaryInfo.benefitCategorySummary
      ? { ...summaryInfo.benefitCategorySummary[category] }
      : {};

  onChanged = (fieldUpdated, summaryInfo, fullSummaryInfo) => {
    if (!summaryInfo[fieldUpdated.key]) {
      summaryInfo[fieldUpdated.key] = {};
    }

    summaryInfo[fieldUpdated.key][fieldUpdated.subKey] = fieldUpdated.value;
    this.setState({ fullSummaryInfo });
  };

  triggerOverallSavingsChange = (subKey, value) => {
    let { summaryInfo } = this.state;
    if (!summaryInfo) {
      summaryInfo = {};
    }

    this.onChanged(
      {
        key: 'overall',
        subKey,
        value
      },
      summaryInfo
    );
  };

  triggerBenefitCategorySavingsChange = (key, subKey, value) => {
    let { summaryInfo } = this.state;
    if (!summaryInfo) {
      summaryInfo = {};
    }
    if (!summaryInfo.benefitCategorySummary)
      summaryInfo.benefitCategorySummary = {};
    this.onChanged(
      {
        key,
        subKey,
        value
      },
      summaryInfo.benefitCategorySummary,
      summaryInfo
    );
  };

  getSavingsModel = (summaryInfo, getLabel) => [
    {
      label: getLabel('Overall Benchmark'),
      savingsInputProps: {
        value: this.getSummaryInfo(summaryInfo, false, true),
        onChangeSavings: (value) =>
          this.triggerOverallSavingsChange('savings', value),
        onChangePercentage: (value) =>
          this.triggerOverallSavingsChange('savingsPercentage', value)
      }
    },
    {
      label: getLabel('Medical'),
      savingsInputProps: {
        value: this.getSummaryInfo(summaryInfo, MEDICAL),
        onChangeSavings: (value) =>
          this.triggerBenefitCategorySavingsChange(MEDICAL, 'savings', value),
        onChangePercentage: (value) =>
          this.triggerBenefitCategorySavingsChange(
            MEDICAL,
            'savingsPercentage',
            value
          )
      }
    },
    {
      label: getLabel('Dental'),
      savingsInputProps: {
        value: this.getSummaryInfo(summaryInfo, DENTAL),
        onChangeSavings: (value) =>
          this.triggerBenefitCategorySavingsChange(DENTAL, 'savings', value),
        onChangePercentage: (value) =>
          this.triggerBenefitCategorySavingsChange(
            DENTAL,
            'savingsPercentage',
            value
          )
      }
    },
    {
      label: getLabel('Vision'),
      savingsInputProps: {
        value: this.getSummaryInfo(summaryInfo, VISION),
        onChangeSavings: (value) =>
          this.triggerBenefitCategorySavingsChange(VISION, 'savings', value),
        onChangePercentage: (value) =>
          this.triggerBenefitCategorySavingsChange(
            VISION,
            'savingsPercentage',
            value
          )
      }
    }
  ];

  renderSavingsInputs = (savingsModels) =>
    savingsModels.map((savingsModel) => (
      <>
        <Row>
          <Col className="savings-modal-label">{savingsModel.label}</Col>
        </Row>
        <Row>
          <Col>
            <SavingsInput {...savingsModel.savingsInputProps}></SavingsInput>
          </Col>
        </Row>
      </>
    ));

  onConfirm = () => {
    const { summaryInfo, overallRates } = this.state;
    _.set(
      summaryInfo,
      'overall.rateMarketPercentage',
      this.formatFloat(overallRates.overall)
    );
    _.set(
      summaryInfo,
      'benefitCategorySummary.MEDICAL.rateMarketPercentage',
      this.formatFloat(overallRates.medical)
    );
    const { onConfirm, benchmark, toggle } = this.props;
    const { benchmarkId, employerId } = benchmark;
    benchmark.summaryInfo = summaryInfo;
    onConfirm(benchmarkId, benchmark, employerId);
    toggle();
  };

  formatFloat = (value) => {
    return parseFloat(value).toFixed(2);
  };

  toggle = () => {
    const { benchmark, toggle } = this.props;
    const { summaryInfo } = benchmark;
    this.setState({ summaryInfo: this.cloneSummaryInfo(summaryInfo) });
    toggle();
  };

  toggleAutoCalculate = () => {
    const { autoCalculate } = this.state;
    this.setState({
      autoCalculate: !autoCalculate
    });
  };

  changeSummaryOveridings = (event) => {
    const { target } = event;
    const { name, value } = target;
    const { autoCalculate, overallRates } = this.state;
    const updatedOverallRates = { ...overallRates };
    const needComputeType = name === 'medical' ? 'overall' : 'medical';
    updatedOverallRates[name] = parseFloat(value);
    this.setState({ overallRates: updatedOverallRates }, () => {
      if (autoCalculate) {
        updatedOverallRates[needComputeType] = null;
        if (!isNaN(parseFloat(value))) {
          this.props.findComputedOverallRate(updatedOverallRates);
        } else {
          this.setState({ overallRates: updatedOverallRates });
        }
      }
    });
  };

  getSummaryOverridings = () => {
    const { autoCalculate, overallRates = {} } = this.state;
    const { overall, medical } = overallRates;
    return (
      <Row className="summary-overriding-wrapper">
        <Col>
          <Row>
            <Col className="savings-modal-label">Market Comparison</Col>
          </Row>
          <Row className="mt-3">
            <Col xl={6}>
              <Row className="ml-0 mr-2">
                <Col xl={2} className="overriding-title p-1">
                  Overall
                </Col>
                <Col xl={4}>
                  <NumberFormat
                    className="form-control"
                    suffix="%"
                    value={overall}
                    name="overall"
                    decimalScale={2}
                    onChange={_.debounce(this.changeSummaryOveridings, 500)}
                  />
                </Col>
                <Col xl={2} className="overriding-title">
                  Medical
                </Col>
                <Col xl={4}>
                  <NumberFormat
                    className="form-control"
                    suffix="%"
                    value={medical}
                    name="medical"
                    decimalScale={2}
                    onChange={_.debounce(this.changeSummaryOveridings, 500)}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="overriding-auto-calculate">
              <Input
                type="checkbox"
                checked={autoCalculate}
                onChange={this.toggleAutoCalculate}
              />
              <span>Auto Calculate</span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  render() {
    const {
      getLabel,
      getSavingsModel,
      renderSavingsInputs,
      onConfirm,
      toggle,
      getSummaryOverridings,
      getOrResetSavingsVaules
    } = this;
    const { isOpen } = this.props;
    const { summaryInfo } = this.state;
    const colSplit = [
      {
        // Split Even
        filter: (indx) => indx % 2 === 0
      },
      {
        // Split Odd
        filter: (indx) => indx % 2 === 1
      }
    ];

    return (
      <FullscreenModal
        isOpen={isOpen}
        toggle={toggle}
        title={'Summary Page'}
        closeText={'close'}
        onClosed={getOrResetSavingsVaules}
      >
        <Row className="mt-5">
          {colSplit.map((col, indx) => (
            <Col className={indx === 0 ? 'mr-5' : ''}>
              {renderSavingsInputs(
                getSavingsModel(summaryInfo, getLabel).filter((_el, indx) =>
                  col.filter(indx)
                )
              )}
            </Col>
          ))}
        </Row>
        {getSummaryOverridings()}
        <Row className="mt-5">
          <Col xs={6}>
            <PageActionButton onClick={() => { toggle(); getOrResetSavingsVaules()} } outline>
              Cancel
            </PageActionButton>
          </Col>
          <Col xs={6}>
            <PageActionButton
              onClick={() => onConfirm()}
              disabled={this.props.isCalculatingRates}
            >
              Confirm
            </PageActionButton>
          </Col>
        </Row>
      </FullscreenModal>
    );
  }
}

const SavingsInput = (props) => {
  const { value, onChangeSavings, onChangePercentage } = props;
  const { savings, savingsPercentage } = value;

  return (
    <Row>
      <Col xs={8}>
        <NumberFormat
          className="form-control mt-3 mb-3"
          value={savings}
          onValueChange={(e) => onChangeSavings(parseFloat(e.value))}
          thousandSeparator={true}
          prefix="$"
        ></NumberFormat>
      </Col>
      <Col xs={4}>
        <NumberFormat
          className="form-control mt-3 mb-3"
          value={savingsPercentage}
          suffix="%"
          onValueChange={(e) => onChangePercentage(parseFloat(e.value))}
        ></NumberFormat>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const { benchmarkReducer } = state;
  const { benchmarkOverallRates } = benchmarkReducer;
  const { rates, inProgress } = benchmarkOverallRates;
  return { rates, isCalculatingRates: inProgress };
};
const mapDispatchToProps = (dispatch) => ({
  findComputedOverallRate: (nonComputeoverallRates) =>
    dispatch(findComputedOverallRate(nonComputeoverallRates))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SavingOverrideModal)
);
