/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import OfferService from 'services/OfferService';
import OfferResourceService from 'services/OfferResourceService';
import {
  SAVING_MODELING_DATA_ERROR,
  SAVED_SUCCESS_MODELING_DATA,
  SAVE_INPROGRESS_MODELING_DATA,
  SET_EDIT_MODE,
  GET_CURRENT_PLAN_FILE_HISTORY_IN_PROGRESS,
  GET_CURRENT_PLAN_FILE_HISTORY_SUCCESS,
  GET_CURRENT_PLAN_FILE_HISTORY_ERROR,
  DOWNLOAD_FILE_HISTORY_IN_PROGRESS,
  DOWNLOAD_FILE_HISTORY_SUCCESS,
  DOWNLOAD_FILE_HISTORY_ERROR
} from '../actionTypes/offer.actionsTypes';

export const saveOfferModel = (bundleId, bundle, isComplete, type) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SAVE_INPROGRESS_MODELING_DATA });
      const response = await OfferResourceService.saveProposals(
        bundleId,
        bundle,
        isComplete
      );
      if (response.status === 204) {
        dispatch({ type: SAVED_SUCCESS_MODELING_DATA, payload: type });
      }
    } catch (error) {
      dispatch({ type: SAVING_MODELING_DATA_ERROR, payload: error });
    }
  };
};

export const getCurrentPlansFileHistory = (employerId) => {
  return async (dispatch) => {
    dispatch({ type: GET_CURRENT_PLAN_FILE_HISTORY_IN_PROGRESS, payload: {} });
    try {
      const response = await OfferService.getPlanFileHistory(employerId);
      dispatch({
        type: GET_CURRENT_PLAN_FILE_HISTORY_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: GET_CURRENT_PLAN_FILE_HISTORY_ERROR, payload: error });
    }
  };
};

export const downloadHistoryFile = (employerId, fileId) => {
  return async (dispatch) => {
    dispatch({ type: DOWNLOAD_FILE_HISTORY_IN_PROGRESS, payload: {} });
    try {
      const response = await OfferService.downloadHistoryFile(
        employerId,
        fileId
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOAD_FILE_HISTORY_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: DOWNLOAD_FILE_HISTORY_ERROR, payload: error });
    }
  };
};

export const updateEditMode = (editMode) => {
  return { type: SET_EDIT_MODE, payload: editMode };
};
