import React from 'react';
import { connect } from 'react-redux';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import { Row, Col, Label } from 'components/Atoms';
import Loader from 'components/CircularProgress';

import { SelectFormGroup } from 'components/DataForm';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import {
  TextFormGroup,
  StartEndDatePicker,
  TagFormGroup
} from 'components/DataForm';
import {
  findBenchmark,
  findCensus,
  saveBenchmark,
  createBenchmark,
  getMetadata
} from '../../../actions/benchmarkActions';
import _ from 'lodash';
import { isValidDateRange, isValidFormat } from 'util/dateUtil';
import Notifications from 'components/Notifications';
import PageActionButton from 'components/Buttons';
import regions from './Regions';
import { trimAll } from 'util/commonUtil';

import './CensusInfo.scss';

class CensusInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveAndContinue: false,
      formData: generateEmptyForm(),
      validations: {
        startDate: '',
        endDate: ''
      },
      errors: generateErrors(),
      errorMessage: generateErrors(),
      empty: generateEmpty(),
      dateRange: '',
      syncedFields: [],
      industryList: [],
      typed: {
        male: false,
        female: false
      },
      regionOtherValue: ''
    };
  }

  componentDidMount() {
    const { match, findBenchmark, findCensus, getMetadata } = this.props;

    const { benchmarkId, employerId } = match.params;

    if (benchmarkId) {
      findBenchmark(benchmarkId);
    }
    if (employerId && !benchmarkId) {
      findCensus(employerId);
    }
    getMetadata();

    //Temprory fix for PLAT-7728 - scenario -3
    document
      .getElementsByClassName('ReactTags__selected')[0]
      .addEventListener('click', () => {
        document.getElementsByClassName('ReactTags__tagInputField')[0].focus();
      });
  }

  componentDidUpdate(previousProps) {
    const {
      match,
      benchmark,
      census,
      metadata,
      benchmarkSaveResult,
      benchmarkSaveError
    } = this.props;

    const { employerId, brokerId } = match.params;

    const { formData } = this.state;
    formData.employerId = employerId;

    if (previousProps.benchmark !== benchmark) {
      this.setState({
        formData: {
          ...benchmark,
          formData
        }
      });
      //Temprory fix for PLAT-7728 - scenario -3
      document
        .getElementsByClassName('ReactTags__selected')[0]
        .addEventListener('click', () => {
          document
            .getElementsByClassName('ReactTags__tagInputField')[0]
            .focus();
        });
    }

    if (previousProps.benchmark.benchmarkId !== benchmark.benchmarkId) {
      const { censusInfo = {} } = benchmark;
      const { mfRatio } = censusInfo;
      const { male = '', female = '' } = mfRatio;
      this.setState({
        typed: {
          male: male !== '',
          female: female !== ''
        }
      });
    }
    if (previousProps.metadata !== metadata) {
      let industryList = metadata.industries;
      this.setState({
        industryList: industryList
      });
    }

    if (previousProps.census !== census) {
      let formData = { ...this.state.formData };
      formData.censusInfo = census;
      this.setState({
        formData
      });
    }

    const { saveAndContinue } = this.state;

    if (benchmarkSaveResult !== previousProps.benchmarkSaveResult) {
      if (saveAndContinue) {
        Notifications.success('Census information has been successfully saved');
        this.props.history.push(
          `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkSaveResult.benchmarkId}/edit/medical`
        );
      }
    }

    if (
      benchmarkSaveError &&
      benchmarkSaveError !== previousProps.benchmarkSaveError
    ) {
      if (
        benchmarkSaveError.response &&
        benchmarkSaveError.response.data &&
        benchmarkSaveError.response.data.code
      ) {
        Notifications.error(benchmarkSaveError.response.data.code);
      } else {
        Notifications.error('Failed to save census information');
      }
    }
  }

  saveAndNext = async (event) => {
    let findError = true;
    const { errors } = this.state;
    const { censusInfo } = errors;
    Object.keys(censusInfo).forEach((i) => {
      if (censusInfo[i] === true) {
        findError = false;
      }
    });

    const formEmpty = this.validateformData();
    const censusInfoEmpty = this.validateCensusInfo();
    const validRegionOther = this.validateRegionOther();
    if (
      findError === true &&
      formEmpty === false &&
      validRegionOther &&
      !censusInfoEmpty
    ) {
      this.setState({
        saveAndContinue: true
      });
      const { match } = this.props;
      const { employerId, benchmarkId } = match.params;
      let formData = { ...this.state.formData };
      this.save(benchmarkId, trimAll(formData), employerId);
    }
  };

  next = () => {
    const { match } = this.props;
    const { benchmarkId, employerId, brokerId } = match.params;
    this.props.history.push(
      `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkId}/view/medical/benchmark`
    );
  };

  validateCensusInfo = () => {
    let formEmpty = false;
    const { formData = {}, empty = {}, errorMessage = {} } = this.state;
    const { censusInfo = {} } = formData;
    const { mfRatio = {} } = censusInfo;
    Object.keys(censusInfo).forEach((key) => {
      if (
        key !== 'regionOther' &&
        key !== 'mfRatio' &&
        censusInfo[key].length === 0
      ) {
        formEmpty = true;
        errorMessage.censusInfo[key] = 'Please fill in this field';
        empty[key] = false;
        this.setState({
          empty,
          errorMessage
        });
      }
    });
    formEmpty = mfRatio.male + mfRatio.female !== 100 || formEmpty;
    return formEmpty;
  };

  validateformData = () => {
    let formEmpty = false;
    const {
      formData = {},
      empty,
      errorMessage = {},
      validations = {},
      dateRange
    } = this.state;
    const { censusInfo: errorCensusInfo = {} } = errorMessage;
    const { censusInfo = {}, benchmarkName, effectiveDateRange } = formData;
    const { startDate = '', endDate = '' } = effectiveDateRange;
    const { mfRatio = {} } = censusInfo;
    const { male, female } = mfRatio;

    if (benchmarkName.trim().length === 0) {
      formEmpty = true;
      errorCensusInfo.benchmarkName = 'Please fill in this field';
      empty.benchmarkName = false;
      this.setState({
        empty,
        errorMessage
      });
    }
    if (Number(male) + Number(female) !== 100 && male !== '' && female !== '') {
      formEmpty = true;
      errorCensusInfo.mfRatio = 'M/F ratio has to sum upto 100';
      errorCensusInfo.male = '';
      errorCensusInfo.female = '';
      empty.mfRatio = false;
      empty.male = false;
      empty.female = false;
      this.setState({
        empty,
        errorMessage
      });
    }
    if (male === '') {
      formEmpty = true;
      errorCensusInfo.male = 'Please fill in this field';
      empty.male = false;
      this.setState({
        empty,
        errorMessage
      });
    }
    if (female === '') {
      formEmpty = true;
      errorCensusInfo.female = 'Please fill in this field';
      empty.female = false;
      this.setState({
        empty,
        errorMessage
      });
    }
    if (startDate.length === 0 || endDate.length === 0) {
      validations.startDate = true;
      validations.endDate = true;

      formEmpty = true;
      this.setState({
        validations
      });
    }
    if (
      validations.startDate === true ||
      validations.endDate === true ||
      dateRange === true
    ) {
      formEmpty = true;
    }
    return formEmpty;
  };
  validateRegionOther = () => {
    const { errors, errorMessage, regionOtherValue } = this.state;
    let isValidRegion = false;
    if (regionOtherValue) {
      regions.forEach((region) => {
        if (regionOtherValue === region.id) {
          isValidRegion = true;
        }
      });

      errors.censusInfo.regionOther = !isValidRegion;
      errorMessage.censusInfo.regionOther = !isValidRegion
        ? 'Please enter a valid region other value'
        : '';
    } else {
      isValidRegion = true;
      errors.censusInfo.regionOther = false;
      errorMessage.censusInfo.regionOther = '';
    }
    this.setState({
      errorMessage,
      errors
    });
    return isValidRegion;
  };
  setRegionOtherTypedValue = (value) => {
    this.setState({
      regionOtherValue: value
    });
  };

  save = (benchmarkId, benchmarkObj, employerId) => {
    if (benchmarkId) {
      this.props.saveBenchmark(
        benchmarkId,
        benchmarkObj,
        employerId,
        'censusInfo'
      );
    } else {
      this.props.createBenchmark(benchmarkObj, employerId);
    }
  };

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));

    const { errors, empty, errorMessage } = this.state;
    errors.censusInfo.benchmarkName = value.trim().length === 0;
    errorMessage.censusInfo.benchmarkName =
      'Please enter a valid benchmark name';
    empty.benchmarkName = true;

    this.setState({
      errors,
      empty,
      errorMessage
    });
  };

  onMaleFemaleFocusOut = (e) => {
    const {
      formData,
      errors = {},
      empty,
      errorMessage = {},
      typed = {}
    } = this.state;
    const { censusInfo } = formData;
    const { name, value } = e.target;
    empty.male = true;
    empty.female = true;
    empty.mfRatio = true;

    if (value.trim() === '') {
      censusInfo.mfRatio[name] = 0;
      typed[name] = true;
      if (name === 'male') {
        censusInfo.mfRatio.female = 100;
      } else {
        censusInfo.mfRatio.male = 100;
      }
    }
    formData.censusInfo = censusInfo;
    this.setState({
      formData
    });
    this.setState({
      errors,
      empty,
      errorMessage
    });
  };

  onMaleFemaleChange = (e) => {
    const {
      formData,
      errors = {},
      empty,
      errorMessage = {},
      typed = {}
    } = this.state;
    const { censusInfo } = formData;
    const { name, value } = e.target;
    const inputVal = value && value.trim() !== '' ? parseInt(value) : '';
    empty.male = true;
    empty.female = true;
    empty.mfRatio = true;

    if (0 <= value && value <= 100) {
      censusInfo.mfRatio[name] = inputVal;
      typed[name] = true;
      if (name === 'male') {
        censusInfo.mfRatio.female = 100 - inputVal;
      } else {
        censusInfo.mfRatio.male = 100 - inputVal;
      }
    }

    formData.censusInfo = censusInfo;
    this.setState({
      formData
    });
    this.setState({
      errors,
      empty,
      errorMessage
    });
  };

  censusInfoChange = (e) => {
    const { formData, errors, empty, errorMessage } = this.state;
    const { censusInfo } = formData;
    const { name, value } = e.target;
    censusInfo[name] = value;
    formData.censusInfo = censusInfo;
    this.setState({
      formData
    });
    switch (name) {
      case 'employerSize':
        errors.censusInfo.employerSize = !RegExp(/^[1-9][0-9]*$/).test(value);
        errorMessage.censusInfo.employerSize = 'Please enter a valid size';
        empty.employerSize = true;
        break;
      case 'regionHQ':
        errors.censusInfo.regionHQ = value.trim().length === 0;
        errorMessage.censusInfo.regionHQ = 'Please enter a valid Region HQ';
        empty.regionHQ = true;
        break;
      case 'avgEmployerAge':
        errors.censusInfo.avgEmployerAge = !RegExp(
          /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/
        ).test(value);
        errorMessage.censusInfo.avgEmployerAge =
          'Please enter a valid average age';
        empty.avgEmployerAge = true;
        break;
      case 'industry':
        errors.censusInfo.industry = value.length === 0;
        errorMessage.censusInfo.industry = 'Please fill in this field';
        empty.industry = true;
        break;
      default:
        break;
    }
    this.setState({
      errors,
      empty,
      errorMessage
    });
  };

  dateChange = (e) => {
    const { formData, validations } = this.state;
    const { effectiveDateRange } = formData;

    let validEffectiveDateRange = effectiveDateRange;
    const { value, name } = e.target;
    validEffectiveDateRange[name] = value;
    formData.effectiveDateRange = validEffectiveDateRange;
    const { startDate, endDate } = validEffectiveDateRange;
    const dateRangeIsValid = !isValidDateRange(startDate, endDate);
    validations.startDate = startDate.length === 0 || !isValidFormat(startDate);
    validations.endDate = endDate.length === 0 || !isValidFormat(startDate);
    this.setState({
      formData,
      dateRange: dateRangeIsValid,
      validations
    });
  };

  deleteOtherRegion = (clickedIndex) => {
    const { formData = {} } = this.state;
    const { censusInfo = {} } = formData;
    const { regionOther = '' } = censusInfo;
    const tagedRegions = regionOther.split(',').map((region) => {
      return { id: region, text: region };
    });
    let updatedRegions = '';
    tagedRegions
      .filter((tag, index) => index !== clickedIndex)
      .forEach(
        (region, index) =>
          (updatedRegions = `${updatedRegions}${index !== 0 ? ',' : ''}${
            region.id
          }`)
      );
    const processEventData = {
      target: { name: 'regionOther', value: updatedRegions }
    };
    this.censusInfoChange(processEventData);
    this.setRegionOtherTypedValue('');
  };

  addOtherRegion = (tag) => {
    const { formData = {}, errors, errorMessage } = this.state;
    const { censusInfo = {} } = formData;
    const { regionOther = '' } = censusInfo;

    let existingRegions = [];
    regions.map((region) => {
      return existingRegions.push(region.id);
    });
    this.setRegionOtherTypedValue('');
    if (existingRegions.includes(tag.id)) {
      const processEventData = {
        target: {
          name: 'regionOther',
          value: `${regionOther}${regionOther.length > 0 ? ', ' : ''}${tag.id}`
        }
      };

      this.censusInfoChange(processEventData);
    }
    errors.censusInfo.regionOther = false;
    errorMessage.censusInfo.regionOther = '';
    this.setState({
      errorMessage,
      errors
    });
  };

  render() {
    const { history, match, isCreatingBenchmark, isLoading } = this.props;
    const {
      formData,
      errorMessage,
      validations,
      errors,
      dateRange,
      empty,
      industryList
    } = this.state;
    const {
      benchmarkName,
      effectiveDateRange,
      syncedFields,
      censusInfo
    } = formData;
    const { employerId, brokerId } = match.params;

    const isReadOnly = match.path.includes('/view/');

    const { mfRatio, regionOther = '' } = censusInfo;
    const { male = '', female = '' } = mfRatio;

    const tagedRegions = regionOther
      .split(',')
      .filter((region) => region.length > 0)
      .map((region) => ({ id: region, text: region }));

    let otherRegions = regions.map((region) => {
      return { id: region.id, text: region.id };
    });
    const selectedRegions = regionOther.split(',').map((item) => item.trim());
    otherRegions = otherRegions.filter((reg) => {
      return !selectedRegions.includes(reg.id);
    });

    return (
      <ApplicationWrapper>
        <HeaderContainer
          auto_id="benchmarks"
          title="Benchmarks"
          history={history}
          backButtonUnderTittle
          redirectBack={() => {
            this.props.history.push(
              `/brokers/${brokerId}/employers/${employerId}/benchmarks`
            );
          }}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Col className="page-content census-info" xs="auto">
                <ContentContainer>
                  <Row>
                    <Col>
                      <TextFormGroup
                        labelDisplay="Name *"
                        inputName="benchmarkName"
                        inputValue={benchmarkName}
                        placeholder=""
                        maxLength="100"
                        isInvalid={
                          errors.censusInfo.benchmarkName ||
                          !empty.benchmarkName
                        }
                        feedback={errorMessage.censusInfo.benchmarkName}
                        onChange={this.onChange}
                        disabled={isReadOnly}
                      />
                    </Col>
                    <Col>
                      <StartEndDatePicker
                        startName="startDate"
                        endName="endDate"
                        startValue={effectiveDateRange.startDate}
                        endValue={effectiveDateRange.endDate}
                        onChange={this.dateChange}
                        disabledFields={_.intersection(syncedFields, [
                          'startDate',
                          'endDate'
                        ])}
                        disabled={isReadOnly}
                        validations={{
                          isStartDateInvalid: validations.startDate,
                          isEndDateInvalid: validations.endDate,
                          isRangeInvalid: dateRange
                        }}
                      />
                    </Col>
                  </Row>
                </ContentContainer>
                <Row>
                  <Col>
                    <h3>Census Information</h3>
                  </Col>
                </Row>
                <Row>&nbsp;</Row>
                <ContentContainer>
                  <Row>
                    <Col>
                      <TextFormGroup
                        labelDisplay="Size *"
                        inputName="employerSize"
                        inputValue={censusInfo.employerSize}
                        isInvalid={
                          errors.censusInfo.employerSize || !empty.employerSize
                        }
                        feedback={errorMessage.censusInfo.employerSize}
                        placeholder=""
                        maxLength="100"
                        onChange={this.censusInfoChange}
                        disabled={isReadOnly}
                      />
                    </Col>
                    <Col>
                      <TextFormGroup
                        labelDisplay="Region HQ *"
                        inputName="regionHQ"
                        inputValue={censusInfo.regionHQ}
                        placeholder=""
                        feedback={errorMessage.censusInfo.regionHQ}
                        isInvalid={
                          errors.censusInfo.regionHQ || !empty.regionHQ
                        }
                        maxLength="100"
                        onChange={this.censusInfoChange}
                        disabled={isReadOnly}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <TagFormGroup
                        labelDisplay="Region Other"
                        suggestions={otherRegions}
                        tags={tagedRegions}
                        onDelete={this.deleteOtherRegion}
                        onAdd={this.addOtherRegion}
                        isInvalid={
                          errors.censusInfo.regionOther || !empty.regionOther
                        }
                        feedback={errorMessage.censusInfo.regionOther}
                        handleInputChange={this.setRegionOtherTypedValue}
                        allowDragDrop={false}
                        readOnly={isReadOnly}
                      />
                    </Col>
                    <Col>
                      <TextFormGroup
                        labelDisplay="Average Employee Age *"
                        inputName="avgEmployerAge"
                        placeholder=""
                        inputValue={censusInfo.avgEmployerAge}
                        isInvalid={
                          errors.censusInfo.avgEmployerAge ||
                          !empty.avgEmployerAge
                        }
                        feedback={errorMessage.censusInfo.avgEmployerAge}
                        maxLength="100"
                        onChange={this.censusInfoChange}
                        disabled={isReadOnly}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SelectFormGroup
                        labelDisplay="Industry *"
                        inputName="industry"
                        inputValue={censusInfo.industry}
                        isInvalid={
                          errors.censusInfo.industry || !empty.industry
                        }
                        feedback={errorMessage.censusInfo.industry}
                        options={industryList.map((industry) => {
                          return { text: industry, value: industry };
                        })}
                        onChange={this.censusInfoChange}
                        disabled={isReadOnly}
                      />
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <Label className="male-female-header">
                            Male/Female Ratio *
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="male-Wrapper">
                          <TextFormGroup
                            className="male-field"
                            inputName="male"
                            inputValue={male.toString()}
                            placeholder="Male"
                            feedback={errorMessage.censusInfo.male}
                            isInvalid={errors.censusInfo.male || !empty.male}
                            maxLength={3}
                            onChange={this.onMaleFemaleChange}
                            onBlur={this.onMaleFemaleFocusOut}
                            disabled={isReadOnly}
                          />
                        </Col>
                        <Col className="female-Wrapper">
                          <TextFormGroup
                            className="female-field"
                            labelDisplay=" "
                            inputName="female"
                            inputValue={female.toString()}
                            placeholder="Female"
                            feedback={errorMessage.censusInfo.female}
                            isInvalid={
                              errors.censusInfo.female || !empty.female
                            }
                            maxLength={3}
                            onChange={this.onMaleFemaleChange}
                            disabled={isReadOnly}
                            onBlur={this.onMaleFemaleFocusOut}
                          />
                        </Col>
                        <Col></Col>
                      </Row>
                      {!empty.mfRatio && (
                        <Row>
                          <Col className="invalid-wrapper">
                            <Label className="invalid">
                              {errorMessage.censusInfo.mfRatio}
                            </Label>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </ContentContainer>
              </Col>
              <Col className="page-actions" xs="auto">
                {isReadOnly ? (
                  <PageActionButton outline onClick={this.next}>
                    Next
                  </PageActionButton>
                ) : (
                  <PageActionButton
                    type={isCreatingBenchmark ? 'progress' : ''}
                    onClick={this.saveAndNext}
                  >
                    Save & Continue
                  </PageActionButton>
                )}
              </Col>
            </Row>
          </>
        )}
      </ApplicationWrapper>
    );
  }
}
const generateEmptyForm = () => {
  return {
    benchmarkName: '',
    employerId: '',
    benchmarkId: '',
    effectiveDateRange: {
      startDate: '',
      endDate: ''
    },

    censusInfo: {
      employerSize: '',
      regionHQ: '',
      regionOther: '',
      avgEmployerAge: '',
      industry: [],
      mfRatio: {
        male: '',
        female: ''
      }
    }
  };
};

const generateErrors = () => {
  return {
    censusInfo: {
      benchmarkName: '',
      employerSize: '',
      regionHQ: '',
      regionOther: '',
      avgEmployerAge: '',
      industry: '',
      male: '',
      female: '',
      mfRatio: ''
    }
  };
};

const generateEmpty = () => {
  return {
    benchmarkName: 'false',
    employerSize: 'false',
    regionHQ: 'false',
    regionOther: 'false',
    avgEmployerAge: 'false',
    industry: 'false',
    male: 'false',
    female: 'false',
    mfRatio: 'false'
  };
};

const mapDispatchToProps = (dispatch) => ({
  findBenchmark: (benchmarkId) => dispatch(findBenchmark(benchmarkId)),
  findCensus: (employerId) => dispatch(findCensus(employerId)),
  saveBenchmark: (benchmarkId, benchmarkObj, employerId, step) =>
    dispatch(saveBenchmark(benchmarkId, benchmarkObj, employerId, step)),
  createBenchmark: (benchmarkObj, employerId) =>
    dispatch(createBenchmark(benchmarkObj, employerId)),
  getMetadata: () => dispatch(getMetadata())
});

const mapStateToProps = (state) => {
  const {
    benchmark,
    census,
    metadata,
    benchmarkSaveData,
    fetchBenchmarkByIdLoading
  } = state.benchmarkReducer;

  return {
    benchmark,
    census,
    metadata,
    benchmarkSaveResult: benchmarkSaveData.benchmarkSaveResult,
    isCreatingBenchmark: benchmarkSaveData.isCreatingBenchmark,
    benchmarkSaveError: benchmarkSaveData.saveBenchmarkError,
    isLoading: fetchBenchmarkByIdLoading
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CensusInfo);
