/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { Table } from 'components/Atoms';
import './OfferPlanDetails.scss';
import _ from 'lodash';

const OfferLifePlanDetails = (props) => {
  const { name } = props.data;
  let content = (
    <>
      <Table className="table plan-table">
        <thead>
          <tr>
            <th className="remove-top-border"></th>
            <th
              className="add-left-border plan-detail-header-border"
              colSpan="2"
            >
              {name}
            </th>
          </tr>
        </thead>
        {getTableContent(props.data)}
      </Table>
    </>
  );
  return content;
};
const getTableContent = (data) => {
  if (data.insuranceType === 'BASIC_LIFE') {
    const {
      guaranteedIssue,
      lifeAndAddBenefit,
      lifeAndAddMaximum,
      rateGuarantee,
      ratingUnit
    } = data;
    return (
      <tbody>
        <tr key={guaranteedIssue}>
          <td>Guaranteed Issue</td>
          <td>{defaultValue(guaranteedIssue)}</td>
        </tr>
        <tr key={lifeAndAddBenefit}>
          <td>Life & Add Benefit</td>
          <td>{defaultValue(lifeAndAddBenefit)}</td>
        </tr>
        <tr key={lifeAndAddMaximum}>
          <td>Life & Add Maximum</td>
          <td>{defaultValue(lifeAndAddMaximum)}</td>
        </tr>
        <tr key={rateGuarantee}>
          <td>Rate Guarantee</td>
          <td>{defaultValue(rateGuarantee)}</td>
        </tr>
        <tr key={ratingUnit}>
          <td>Rating Unit</td>
          <td>{defaultValue(ratingUnit)}</td>
        </tr>
        {getUnknownFields(data.unknownFields)}
      </tbody>
    );
  } else if (data.insuranceType === 'LTD' || data.insuranceType === 'STD') {
    const {
      benefitDuration,
      eliminationPeriod,
      maxCoveredSalary,
      rateGuarantee,
      ratingUnit,
      weeklyBenefitMax,
      volume,
      benefitLevel,
      monthlyBenefitMax
    } = data;
    return (
      <tbody>
        <tr>
          <td>Benefit Duration</td>
          <td>{defaultValue(benefitDuration)}</td>
        </tr>
        <tr>
          <td>Elimination Period</td>
          <td>{defaultValue(eliminationPeriod)}</td>
        </tr>
        <tr>
          <td>Maximum Covered Annual Salary</td>
          <td>{getMaxCoveredSalary(maxCoveredSalary, 'Annual')}</td>
        </tr>
        <tr>
          <td>Maximum Covered Monthly Salary</td>
          <td>{getMaxCoveredSalary(maxCoveredSalary, 'Monthly')}</td>
        </tr>
        <tr>
          <td>Rate Guarantee</td>
          <td>{defaultValue(rateGuarantee)}</td>
        </tr>
        <tr>
          <td>Rating Unit</td>
          <td>{defaultValue(ratingUnit)}</td>
        </tr>
        <tr>
          <td>Weekly Benefit Max</td>
          <td>{defaultValue(weeklyBenefitMax)}</td>
        </tr>
        <tr>
          <td>Monthly Benefit Max</td>
          <td>{defaultValue(monthlyBenefitMax)}</td>
        </tr>
        <tr>
          <td>Volume</td>
          <td>{defaultValue(volume)}</td>
        </tr>
        <tr>
          <td>Benefit Level</td>
          <td>{defaultValue(benefitLevel)}</td>
        </tr>
        {getUnknownFields(data.unknownFields)}
      </tbody>
    );
  } else if (data.insuranceType === 'VOLUNTARY_LIFE') {
    const {
      guaranteedIssue,
      infantCoverage,
      lifeAndAddBenefit,
      lifeAndAddMaximum,
      rateGuarantee
    } = data;
    return (
      <tbody>
        <tr>
          <td>Guaranteed Issue</td>
          <td>{defaultValue(guaranteedIssue)}</td>
        </tr>
        <tr>
          <td>Infant Coverage</td>
          <td>{defaultValue(infantCoverage)}</td>
        </tr>
        <tr>
          <td>Life & Add Benefit</td>
          <td>{defaultValue(lifeAndAddBenefit)}</td>
        </tr>
        <tr>
          <td>Life & Add Maximum</td>
          <td>{defaultValue(lifeAndAddMaximum)}</td>
        </tr>
        <tr>
          <td>Rate Guarantee</td>
          <td>{defaultValue(rateGuarantee)}</td>
        </tr>
        {getUnknownFields(data.unknownFields)}
      </tbody>
    );
  }
};
const getMaxCoveredSalary = (maxCoveredSalaryList, type) => {
  let maxCoveredSalary = _.find(maxCoveredSalaryList, function(o) {
    return o.fieldType === type;
  });
  return maxCoveredSalaryList
    ? maxCoveredSalary
      ? defaultValue(maxCoveredSalary.value)
      : defaultValue(maxCoveredSalary)
    : defaultValue(maxCoveredSalaryList);
};
const getUnknownFields = (unknownFields) => {
  let arr = [];
  if (unknownFields.length > 0) {
    arr.push(
      <thead className="title-background">
        <tr>
          <th>Unknown Fields</th>
          <th></th>
        </tr>
        {unknownFields.map((field) => {
          return Object.keys(field).forEach(function(key) {
            return (
              <tr>
                <td>{key}</td>
                <td>{defaultValue(field[key])}</td>
              </tr>
            );
          });
        })}
      </thead>
    );
  }
  return arr;
};
const defaultValue = (value) => {
  return value ? value : <>&mdash;</>;
};
export default OfferLifePlanDetails;
