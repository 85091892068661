/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/benchmark.actionTypes';
import BenchmarkService from 'services/BenchmarkService';
import EmployerFeatureConfigurationService from 'services/EmployerFeatureConfigurationService';
import { CREATE_STATUS, UPDATE_STATUS } from 'constants/commonConstants';
import { MODULE_BENCHMARKS_ENABLED, BENCHMARK_PLAN_HSA } from '../constants';
import _ from 'lodash';

export const saveCurrentMedicalPlans = (medicalPlans) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.SAVE_MEDICAL_CURRENT_PLANS,
        payload: medicalPlans
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SAVE_MEDICAL_CURRENT_PLANS_ERROR,
        payload: error
      });
    }
  };
};

export const findBenchmark = (benchmarkId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.FIND_BENCHMARK_BY_ID_IN_PROGRESS
      });
      const response = await BenchmarkService.findBenchmarkById(benchmarkId);
      dispatch({
        type: ActionTypes.FIND_BENCHMARK_BY_ID,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.FIND_BENCHMARK_BY_ID_ERROR,
        payload: error
      });
    }
  };
};

export const findPublishedBenchmark = (employerId) => {
  return async (dispatch) => {
    try {
      const response = await BenchmarkService.getPublishedBenchmark(employerId);
      dispatch({
        type: ActionTypes.FIND_PUBLISHED_BENCHMARK_BY_ID,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.FIND_PUBLISHED_BENCHMARK_BY_ID_ERROR,
        payload: error
      });
    }
  };
};

export const findCensus = (employerId) => {
  return async (dispatch) => {
    try {
      const response = await BenchmarkService.findCensusById(employerId);
      dispatch({
        type: ActionTypes.FIND_CENSUS_BY_ID,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.FIND_CENSUS_BY_ID_ERROR,
        payload: error
      });
    }
  };
};

export const getMetadata = (benefitCategory = null, planType = null) => {
  return async (dispatch) => {
    try {
      const response = await BenchmarkService.getMetadata(benefitCategory, planType);
      dispatch({
        type: ActionTypes.GET_BENCHMARK_METADATA,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BENCHMARK_METADATA_ERROR,
        payload: error
      });
    }
  };
};

export const saveBenchmark = (
  benchmarkId,
  benchmark,
  employerId,
  step,
  benefitCategory
) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SAVE_BENCHMARK_BY_ID_IN_PROGRESS });
    try {
      const response = await BenchmarkService.updateBenchmark(
        benchmarkId,
        benchmark,
        employerId,
        step,
        benefitCategory
      );
      dispatch({
        type: ActionTypes.SAVE_BENCHMARK_BY_ID,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SAVE_BENCHMARK_BY_ID_ERROR,
        payload: error
      });
    }
  };
};

export const createBenchmark = (benchmark, employerId) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CREATE_BENCHMARK_BY_ID_IN_PROGRESS });
    try {
      const response = await BenchmarkService.createBenchmark(
        benchmark,
        employerId
      );
      dispatch({
        type: ActionTypes.CREATE_BENCHMARK_BY_ID,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CREATE_BENCHMARK_BY_ID_ERROR,
        payload: error
      });
    }
  };
};

export const getBenchmarksPage = (employerId, query) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GET_BENCHMARKS_PAGE_IN_PROGRESS });
      const response = await BenchmarkService.findByEmployerId(
        employerId,
        query
      );
      dispatch({
        type: ActionTypes.GET_BENCHMARKS_PAGE_COMPLETED,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BENCHMARKS_PAGE_FAILED,
        payload: error.response
      });
    }
  };
};

export const findConfigStatus = (employerId, key) => {
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.findConfigStatus(
        employerId,
        key
      );
      dispatch({
        type: ActionTypes.FIND_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.FIND_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const createConfigModule = (employerId) => {
  const benchmarksConfig = {
    employerId: employerId,
    key: MODULE_BENCHMARKS_ENABLED,
    value: String(true)
  };
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.createConfigModule(
        benchmarksConfig
      );
      const isCreated = response.status === CREATE_STATUS;
      if (isCreated) {
        try {
          const response = await EmployerFeatureConfigurationService.findConfigStatus(
            employerId,
            MODULE_BENCHMARKS_ENABLED
          );
          dispatch({
            type: ActionTypes.FIND_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES,
            payload: response.data
          });
        } catch (error) {
          dispatch({
            type:
              ActionTypes.FIND_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES_ERROR,
            payload: error
          });
        }
      }
    } catch (error) {
      dispatch({
        type:
          ActionTypes.CREATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const updateConfigModule = (id, employerId, value) => {
  const benchmarksConfig = {
    id: id,
    employerId: employerId,
    key: MODULE_BENCHMARKS_ENABLED,
    value: String(value === 'false')
  };
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.updateConfigModule(
        benchmarksConfig
      );
      if (response.status === UPDATE_STATUS) {
        try {
          const response = await EmployerFeatureConfigurationService.findConfigStatus(
            employerId,
            MODULE_BENCHMARKS_ENABLED
          );
          dispatch({
            type: ActionTypes.FIND_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES,
            payload: response.data
          });
        } catch (error) {
          dispatch({
            type:
              ActionTypes.FIND_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES_ERROR,
            payload: error
          });
        }
      }
    } catch (error) {
      dispatch({
        type:
          ActionTypes.UPDATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const findBenchmarkPlan = (searchCriteria) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.FIND_BENCHMARK_PLAN_IN_PROGRESS
      });
      const response = await BenchmarkService.findBenchmarkPlan(searchCriteria);
      dispatch({
        type: ActionTypes.FIND_BENCHMARK_PLAN,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.FIND_BENCHMARK_PLAN_ERROR,
        payload: error
      });
    }
  };
};

export const cloneBenchmark = (benchmarkId) => {
  return async (dispatch) => {
    try {
      const response = await BenchmarkService.cloneBenchmark(benchmarkId);
      dispatch({
        type: ActionTypes.CLONE_BENCHMARK,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CLONE_BENCHMARK_ERROR,
        payload: error
      });
    }
  };
};

export const validateBenchmark = (benchmarkData, benefitCategory) => {
  return async (dispatch) => {
    const { currentPlans = {} } = _.clone(benchmarkData);
    let valid = false;
    if (!_.isEmpty(currentPlans[benefitCategory.toUpperCase()])) {
      valid = true;
      currentPlans[benefitCategory.toUpperCase()].forEach((currentPlan) => {
        const {
          enrollments = {},
          hsaContribution = {} || {},
          rates = {}
        } = currentPlan;
        const keys = {
          EE: 0,
          ES: 1,
          EC: 2,
          EF: 3
        };
        Object.keys(keys).forEach((key) => {
          if (
            !(!_.isEmpty(enrollments) && !isNaN(parseInt(enrollments[key])))
          ) {
            valid = false;
          }
        });

        if (currentPlan.planType === BENCHMARK_PLAN_HSA) {
          Object.keys(keys).forEach((key) => {
            if (
              !(
                !_.isEmpty(hsaContribution) &&
                !isNaN(parseInt(hsaContribution[key]))
              )
            ) {
              valid = false;
            }
          });
          Object.keys(keys).forEach((key) => {
            if (!(!_.isEmpty(rates) && !isNaN(parseInt(rates[key])))) {
              valid = false;
            }
          });
        }
      });
    }
    dispatch({
      type: ActionTypes.VALIDATE_BENCHMARK_DATA,
      payload: !valid
    });
  };
};

export const findComputedOverallRate = (nonComputeoverallRates) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GET_BENCHMARKS_OVERALL_RATES_IN_PROGRESS });
      const response = await BenchmarkService.getBenchmarkOverallRates(
        nonComputeoverallRates
      );
      dispatch({
        type: ActionTypes.GET_BENCHMARKS_OVERALL_RATES_SUCCES,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BENCHMARKS_OVERALL_RATES_ERROR,
        payload: error
      });
    }
  };
};
