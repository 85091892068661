import React from 'react';
import _ from 'lodash';
import {
  FormGroup,
  FormText,
  FormFeedback,
  Label,
  Input
} from 'components/Atoms';
import './FormGroup.scss';

const TextFormGroup = (props) => {
  const {
    className,
    labelDisplay,
    inputName,
    inputValue,
    onChange,
    disabled,
    placeholder,
    maxLength,
    showRemainingCharacters,
    isAscendingMaxCount = false,
    isInvalid,
    feedback,
    specification,
    onPaste,
    onBlur
  } = props;

  let formatMaxCount = 0;
  if (isAscendingMaxCount) {
    formatMaxCount = inputValue ? inputValue.length : 0;
  } else {
    formatMaxCount = maxLength - (inputValue ? inputValue.length : 0);
  }
 
  return (
    <FormGroup className={className || ''}>
      <Label for={inputName}>{labelDisplay}</Label>
      <Input
        className={isInvalid ? 'is-invalid' : ''}
        type="text"
        id={inputName}
        name={inputName}
        value={inputValue || ''}
        onChange={onChange}
        disabled={disabled}
        placeholder={_.defaultTo(placeholder, labelDisplay)}
        maxLength={maxLength}
        onPaste={onPaste}
        onBlur={(e) => {
          onBlur && onBlur(e);
        }}
      />
      {_.defaultTo(showRemainingCharacters, false) && maxLength && (
        <FormText className="character-count-text">{`
        Max Character Count: ${formatMaxCount}/${maxLength}`}</FormText>
      )}
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
      <FormText color="muted" className="mt-2">
        {specification}
      </FormText>
    </FormGroup>
  );
};

export default TextFormGroup;
