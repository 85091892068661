/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import {
  GET_OFFER_PLANS,
  GET_OFFER_DENTAL_PLANS,
  GET_OFFER_VISION_PLANS,
  GET_OFFER_LIFE_PLANS,
  UPLOAD_OFFER_PLANS_IN_PROGRESS,
  UPLOAD_OFFER_PROGRESS_NOTIFICATION,
  UPLOAD_OFFER_PLANS,
  UPLOAD_OFFER_ERROR,
  GET_OFFER_PLANS_ERROR
} from '../actionTypes/offer.actionsTypes';

export default (
  state = {
    medicalOfferPlans: null,
    dentalOfferPlans: null,
    visionOfferPlans: null,
    lifeOfferPlans: null,
    uploadedOfferPlan: null,
    uploadingStatus: 'NOT_STARTED',
    errorResponse: null,
    progress: 0
  },
  { type, payload }
) => {
  /* eslint-disable */
  switch (type) {
    case GET_OFFER_PLANS:
      return {
        ...state,
        medicalOfferPlans: payload,
        progress: 0
      };
    case GET_OFFER_DENTAL_PLANS:
      return {
        ...state,
        dentalOfferPlans: payload
      };
    case GET_OFFER_VISION_PLANS:
      return {
        ...state,
        visionOfferPlans: payload
      };
    case GET_OFFER_LIFE_PLANS:
      return {
        ...state,
        lifeOfferPlans: payload
      };

    case UPLOAD_OFFER_PLANS:
      return {
        ...state,
        uploadedOfferPlan: payload,
        uploadingStatus: 'UPLOAD_COMPLETE',
        progress: 100
      };
    case UPLOAD_OFFER_PROGRESS_NOTIFICATION:
      return {
        ...state,
        progress: payload
      };
    case UPLOAD_OFFER_PLANS_IN_PROGRESS:
      return {
        ...state,
        uploadingStatus: 'UPLOADING',
        uploadedOfferPlan: null,
        errorResponse: null,
        progress: 0
      };
    case UPLOAD_OFFER_ERROR:
      return {
        ...state,
        uploadingStatus: 'UPLOAD_ERROR',
        uploadedOfferPlan: null,
        errorResponse: payload.data ? payload.data : payload,
        progress: 0
      };
    case GET_OFFER_PLANS_ERROR:
      return {
        ...state,
        errorResponse: payload
      };

    default:
      return {
        ...state
      };
  }
  /* eslint-enable */
};
