/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { Row, Col } from 'reactstrap';

import BenefitSummaryRow from './BenefitSummaryRow';
import SummaryRow from './SummaryRow';
import './SummaryPreview.scss';

import { formatCurrencyWithDefaultValue } from 'util/commonUtil';

class SummaryPreviewTable extends React.Component {
  render() {
    const { summary } = this.props;

    let totalDue = 0;
    summary.forEach((value) => {
      totalDue += value.totalPremium;
    });

    return (
      <>
        {summary.map((carrier) => (
          <CarrierSummaryTable key={carrier.carrierName} summary={carrier} />
        ))}
        <Card>
          <Row className="total-bill-amount">
            <Col className="total-bill-label">Total Due</Col>
            <Col className="text-right">
              <span className="total-bill-value">
                {formatCurrencyWithDefaultValue(totalDue, '-')}
              </span>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}

const CarrierSummaryTable = (props) => {
  const { summary } = props;
  const { benefitTypeAmountVo, carrierUrl, carrierName } = summary;

  const benefitTypes = benefitTypeAmountVo
    ? Object.keys(benefitTypeAmountVo)
    : [];

  return (
    <Card>
      <SummaryHeaderRow carrier={carrierName} carrierUrl={carrierUrl} />
      {benefitTypes.map((type) => (
        <BenefitSummaryRow
          key={type}
          summary={benefitTypeAmountVo[type]}
          benTypeCount={benefitTypes.length}
        />
      ))}
      <SummaryRow
        name="SUB TOTAL"
        summary={summary}
        className="sub-total-row"
      />
    </Card>
  );
};

const SummaryHeaderRow = (props) => {
  const { carrier } = props;
  const { carrierUrl } = props;
  return (
    <div className="header-row">
      <Row className="main-header-row">
        <Col xs="2">
          <img src={carrierUrl} width="113px" alt={carrier} />
        </Col>
        <Col xs="4" className="header-current text-center">
          CURRENT
        </Col>
        <Col xs="3" className="header-retro text-center">
          RETROACTIVITY
        </Col>
        <Col xs="3" className="header-total text-center">
          TOTAL
        </Col>
      </Row>
      <Row className="sub-header-row">
        <Col xs="2">Carrier/Benefit</Col>
        <Col xs="4" className="header-current">
          <Row>
            <Col xs="2" className="text-center">
              Count
            </Col>
            <Col xs="5" className="text-right">
              Volume
            </Col>
            <Col xs="5" className="text-right">
              Premium
            </Col>
          </Row>
        </Col>
        <Col xs="3" className="header-retro">
          <Row>
            <Col className="text-right">Volume</Col>
            <Col className="text-right">Premium</Col>
          </Row>
        </Col>
        <Col xs="3" className="header-total">
          <Row>
            <Col className="text-right">Invoiced-Volume</Col>
            <Col className="text-right">Invoiced-Premium</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const Card = (props) => {
  return (
    <Row className="billing-summary-card">
      <Col>{props.children}</Col>
    </Row>
  );
};

export default SummaryPreviewTable;
