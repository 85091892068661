import React from 'react';
import {
  Button,
  Table
} from 'components/Atoms';

import './Preview.scss';
import { isNumber } from 'util';

export const VALIDATION_PASSED = 'COMPLETE';
export const VALIDATION_DISABLED = 'DISABLED';
export const VALIDATION_INCOMPLETE = 'INCOMPLETE';

class ValidationTable extends React.Component {
  render() {
    const { data, validationStatuses, brokerId, isReadOnlyView } = this.props;
    const {
      employerId,
      id,
      medicalPlans,
      dentalPlans,
      visionPlans,
      insurancePlans,
      fourOOneKPlans,
      taxAdvantagedAccountPlans,
      wellbeingPlans,
      workLifePlans,
      additionalPerks,
      telehealthRxDeliveryPlans,
      hsaPlans = {}
    } = data;

    const noOfMedicalPlans = medicalPlans.plans.length;
    const noOfDentalPlans = dentalPlans.plans.length;
    const noOfVisionPlans = visionPlans.plans.length;
    const noOfinsurancePlans = insurancePlans.plans.length;
    const noOfFourOOneKPlans = fourOOneKPlans.plans.length;
    const noOfWorkLifePlans = workLifePlans.plans.length;
    const noOfWellbeingPlans = wellbeingPlans.plans.length;
    const noOfTaxAdvantagedAccPlans = taxAdvantagedAccountPlans.plans.length;
    const noOfTaggedHSAPlans =
      hsaPlans && hsaPlans.plans ? hsaPlans.plans.length : 0;
    const noOfPerkPlans = additionalPerks.plans.length;
    const noOfTelehealthRxDeliveryPlans =
      telehealthRxDeliveryPlans.plans.length;
    const totalAddedTaxAdvantagePlans =
      noOfTaxAdvantagedAccPlans + noOfTaggedHSAPlans;
    const {
      benguideInformationStatus,
      medicalPlanStatus,
      dentalPlanStatus,
      visionPlanStatus,
      workLifePlanStatus,
      wellbeingPlanStatus,
      taxAdvantagedAccPlanStatus,
      additionalPerksPlanStatus,
      insurancePlanStatus,
      customizationStatus,
      workLifeBannerStatus,
      perkBannerStatus,
      homepageStatus,
      resourceLinksStatus,
      resourceDocumentsStatus,
      perkDocumentsStatus,
      passwordProtectionStatus,
      shareableLinkStatus,
      oeVideoStatus,
      fourOOneKPlanStatus,
      whatsNewStatus,
      rulesQLEventsStatus,
      waiverCreditsStatus,
      telehealthRxDeliveryPlanStatus
    } = validationStatuses;

    return(
      <Table className="validation-table">
        <thead className="validation-table-head">
          <tr>
            <th className="validation-table-head-col">Status</th>
            <th className="validation-table-head-col">BenGuide Content</th>
            <th className="validation-table-head-col text-center"># Plans</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ValidationTableRow
            status={benguideInformationStatus}
            name="Plans - BenGuide Information"
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/plan#BenGuideInformation`);}} />
          <ValidationTableRow
            status={medicalPlanStatus}
            name="Plans - Medical Plans"
            numberOfPlans={noOfMedicalPlans}
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/plan#MedicalPlans`);}}  />
          <ValidationTableRow
            status={dentalPlanStatus}
            name="Plans - Dental Plans"
            numberOfPlans={noOfDentalPlans}
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/plan#DentalPlans`);}}  />
          <ValidationTableRow
            status={visionPlanStatus}
            name="Plans - Vision Plans"
            numberOfPlans={noOfVisionPlans}
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/plan#VisionPlans`);}}  />
          <ValidationTableRow
            status={insurancePlanStatus}
            name="Plans - Life &amp; Disability Plans"
            numberOfPlans={noOfinsurancePlans}
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/plan#LifeDisablilityPlans`);}}  />
          <ValidationTableRow
            status={fourOOneKPlanStatus}
            name="Plans - 401K Plans"
            numberOfPlans={noOfFourOOneKPlans}
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/plan#401KPlans`);}}  />
          <ValidationTableRow
            status={telehealthRxDeliveryPlanStatus}
            name="Plans - Telehealth/Rx Delivery Plans"
            numberOfPlans={noOfTelehealthRxDeliveryPlans}
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/employers/${employerId}/benguides/${id}/edit/plan#telehealthRxDeliveryPlans`);}}  />  
          <ValidationTableRow
            status={workLifePlanStatus}
            name="Plans - Work/Life Plans"
            numberOfPlans={noOfWorkLifePlans}
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/plan#WorkLifePlans`);}}  />
          <ValidationTableRow
            status={wellbeingPlanStatus}
            name="Plans - Wellbeing Plans"
            numberOfPlans={noOfWellbeingPlans}
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/plan#WellbeingPlans`);}}  />
          <ValidationTableRow
            status={taxAdvantagedAccPlanStatus}
            name="Plans - Tax Advantaged Account Plans"
            numberOfPlans={totalAddedTaxAdvantagePlans}
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/plan#TaxAdvantagedAccountPlans`);}}  />
          <ValidationTableRow
            status={additionalPerksPlanStatus}
            name="Plans - Additional Perks Plans"
            numberOfPlans={noOfPerkPlans}
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/plan#AdditionalPerksPlans`);}}  />
          <ValidationTableRow
            status={customizationStatus}
            name="Customization - Design"
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/custom`);}}  />
           <ValidationTableRow
            status={workLifeBannerStatus}
            name="Customization - Work/Life Photos"
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/custom`);}}  />
             <ValidationTableRow
            status={perkBannerStatus}
            name="Customization - Additional Perks Photo"
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/custom`);}}  />
          <ValidationTableRow
            status={homepageStatus}
            name="Other - Homepage"
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/other#Homepage`);}}  />
          <ValidationTableRow
            status={oeVideoStatus}
            name="Other - OE Video"
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/other#oeVideo`);}}  />
          <ValidationTableRow
            status={whatsNewStatus}
            name="Other - What's New"
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/other#whatsNew`);}}  />
          {
            taxAdvantagedAccPlanStatus !== VALIDATION_DISABLED && (
              <ValidationTableRow
                status={resourceDocumentsStatus}
                name="Other - Tax Advantaged Accounts Documents"
                isReadOnlyView={isReadOnlyView}
                onClick={() => { this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/other#ResourceDocuments`); }} />
            )
          }
          {
            additionalPerksPlanStatus !== VALIDATION_DISABLED && (
              <ValidationTableRow
                status={perkDocumentsStatus}
                name="Other - Additional Perks Documents"
                isReadOnlyView={isReadOnlyView}
                onClick={() => { this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/other#PerkDocuments`); }} />
            )
          }
          {
            taxAdvantagedAccPlanStatus !== VALIDATION_DISABLED && (
              <ValidationTableRow
                status={resourceLinksStatus}
                name="Other - Resource Links"
                isReadOnlyView={isReadOnlyView}
                onClick={() => { this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/other#ResourceLinks`); }} />
            )
          }
          <ValidationTableRow
            status={rulesQLEventsStatus}
            name="Other - Rules & Qualifying Life Events"
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/other#eligibilityRules`);}}  />

          <ValidationTableRow
            status={waiverCreditsStatus}
            name="Other - Waiver Credits"
            isReadOnlyView={isReadOnlyView}
            onClick={() => {this.props.history.push(`/brokers/${brokerId}/employers/${employerId}/benguides/${id}/edit/other#waiverCredits`);}}  />

          <ValidationTableRow
            status={passwordProtectionStatus}
            name="Preview - Password Protection"
            isReadOnlyView={isReadOnlyView}
            onClick={() => document.getElementById('PasswordProtection').scrollIntoView()} />
          <ValidationTableRow
            status={shareableLinkStatus}
            name="Preview - Shareable Link"
            isReadOnlyView={isReadOnlyView}
            onClick={() => document.getElementById('ShareableLink').scrollIntoView()} />
        </tbody>
      </Table>
    );
  }

};

const ValidationTableRow = (props) => {
  const styleClass = props.status === VALIDATION_INCOMPLETE ? 'validation-error-row' : '';
  return(
    <tr className={styleClass}>
      <td className="validation-table-row"><Status status={props.status}/></td>
      <td className="benGuide-content-text validation-table-row">{props.name}</td>
      <td className="validation-table-row text-center">{isNumber(props.numberOfPlans) ? props.numberOfPlans : <>&mdash;</>}</td>
      {!props.isReadOnlyView && (
        <td className="validation-table-row">
          <Button color="link" onClick={props.onClick}>
            Edit
          </Button>
        </td>
      )}
    </tr>
  );
};

const Status = (props) => {
  return(
      <>
        {
          props.status === VALIDATION_DISABLED &&
          <div className="validation-status disabled"><span>DISABLED</span></div>
        }
        {
          props.status === VALIDATION_PASSED &&
          <div className="validation-status passed"><span>COMPLETE</span></div>
        }
        {
          props.status === VALIDATION_INCOMPLETE &&
          <div className="validation-status incomplete"><span>INCOMPLETE</span></div>
        }
      </>
  );
};

export default ValidationTable;