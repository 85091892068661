/**********************************************************************************************************************
 * Copyright (C) 2019-2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const CREATE_ISSUE_IN_PROGRESS = 'CREATE_ISSUE_IN_PROGRESS';
export const CREATE_ISSUE_COMPLETED = 'CREATE_ISSUE_COMPLETED';
export const CREATE_ISSUE_FAILED = 'CREATE_ISSUE_FAILED';

export const UPDATE_ISSUE_IN_PROGRESS = 'UPDATE_ISSUE_IN_PROGRESS';
export const UPDATE_ISSUE_COMPLETED = 'UPDATE_ISSUE_COMPLETED';
export const UPDATE_ISSUE_FAILED = 'UPDATE_ISSUE_FAILED';

export const GET_ISSUES_PAGE_IN_PROGRESS = 'GET_ISSUES_PAGE_IN_PROGRESS';
export const GET_ISSUES_PAGE_COMPLETED = 'GET_ISSUES_PAGE_COMPLETED';
export const GET_ISSUES_PAGE_FAILED = 'GET_ISSUES_PAGE_FAILED';

export const UPDATE_ISSUE_FORM = 'UPDATE_ISSUE_FORM';

export const ADD_ISSUE_ATTACHMENT = 'ADD_ISSUE_ATTACHMENT';
export const REMOVE_ISSUE_ATTACHMENT = 'REMOVE_ISSUE_ATTACHMENT';

export const INITIALIZE_CREATE_ISSUE_PAGE = 'INITIALIZE_CREATE_ISSUE_PAGE';
export const GET_ISSUE_DETAILS_IN_PROGRESS = 'GET_ISSUE_DETAILS_IN_PROGRESS';
export const GET_ISSUE_DETAILS_COMPLETED = 'GET_ISSUE_DETAILS_COMPLETED';
export const GET_ISSUE_DETAILS_FAILED = 'GET_ISSUE_DETAILS_FAILED';

export const GET_ER_ADMINS_IN_PROGRESS = 'GET_ER_ADMINS_IN_PROGRESS';
export const GET_ER_ADMINS_COMPLETED = 'GET_ER_ADMINS_COMPLETED';
export const GET_ER_ADMINS_FAILED = 'GET_ER_ADMINS_FAILED';

export const TOGGLE_WATCHER_OPT_IN_PROGRESS = 'TOGGLE_WATCHER_OPT_IN_PROGRESS';
export const TOGGLE_WATCHER_OPT_COMPLETED = 'TOGGLE_WATCHER_OPT_COMPLETED';
export const TOGGLE_WATCHER_OPT_FAILED = 'TOGGLE_WATCHER_OPT_FAILED';

export const CREATE_ISSUE_COMMENT_IN_PROGRESS =
  'CREATE_ISSUE_COMMENT_IN_PROGRESS';
export const CREATE_ISSUE_COMMENT_COMPLETED = 'CREATE_ISSUE_COMMENT_COMPLETED';
export const CREATE_ISSUE_COMMENT_FAILED = 'CREATE_ISSUE_COMMENT_FAILED';

export const UPDATE_ISSUE_FILTER = 'UPDATE_ISSUE_FILTER';
export const CLEAR_ALL_ISSUE_FILTERS = 'CLEAR_ALL_ISSUE_FILTER';

export const RESOLVE_ISSUE_IN_PROGRESS = 'RESOLVE_ISSUE_IN_PROGRESS';
export const RESOLVE_ISSUE_COMPLETED = 'RESOLVE_ISSUE_COMPLETED';
export const RESOLVE_ISSUE_FAILED = 'RESOLVE_ISSUE_FAILED';

export const REOPEN_ISSUE_IN_PROGRESS = 'REOPEN_ISSUE_IN_PROGRESS';
export const REOPEN_ISSUE_COMPLETED = 'REOPEN_ISSUE_COMPLETED';
export const REOPEN_ISSUE_FAILED = 'REOPEN_ISSUE_FAILED';

export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY';

export const GET_ORIGINAL_COMMENT_EMAIL_IN_PROGRESS =
  'GET_ORIGINAL_COMMENT_EMAIL_IN_PROGRESS';
export const GET_ORIGINAL_COMMENT_EMAIL_COMPLETED =
  'GET_ORIGINAL_COMMENT_EMAIL_COMPLETED';
export const GET_ORIGINAL_COMMENT_EMAIL_FAILED =
  'GET_ORIGINAL_COMMENT_EMAIL_FAILED';
