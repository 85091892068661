/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { Row, Col, Label } from 'components/Atoms';
import './CurrentBenchmarkDetails.scss';

import {
  TYPE_DEDUCTIBLE,
  TYPE_OOP_MAX,
  TYPE_COPAY,
  TYPE_RX_COST_TIER,
  TYPE_CONTRIBUTION,
  TYPE_RATES,
  BENEFIT_KIND_MEDICAL,
  BENEFIT_KIND_DENTAL,
  BENEFIT_KIND_VISION,
  BENCHMARK_PLAN_HSA,
  HSA_AVAILABLE_TYPES,
  COINSURANCE
} from '../../../../../constants';
import { isMedicalHSAPlan } from '../../../../../util';

import ModelingSplitInput from '../../../../../components/ModelingSplitInput/ModelingSplitInput';
import ModelingInput from '../../../../../components/ModelingInput/ModelingInput';
import BenchmarkDetailTableHeader from './BenchmarkDetailTableHeader';

import _ from 'lodash';
const inputSuffix = '%';
class CurrentPlanDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      beforeEditBenchmark: null
    };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  switchToEditMode = () => {
    const { benchmarkPlan = {} } = this.props;
    this.setState((prevState) => ({
      isEditMode: !prevState.isEditMode,
      beforeEditBenchmark: _.cloneDeep(benchmarkPlan)
    }));
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.benchmarkPlan !== prevProps.benchmarkPlan) {
      this.setState({
        isEditMode: false
      });
    }
  }

  render() {
    const {
      benchmarkPlan = {},
      currentPlan,
      onChange,
      validations,
      benefitCategory,
      saveEditedValues,
      cancelEditMode,
      unSelectPlanAsBenchmarkPlan,
      isReadOnly
    } = this.props;

    const benchmarkPlanDesign = benchmarkPlan.planDesign;
    const currentPlanDesign = currentPlan.planDesign;

    const { isBenchmarkPlan, selectPlanAsBenchmark } = this.props;
    const { isEditMode } = this.state;

    const getBenchmarkTotalCosts = (rates, key) => {
      return isNaN(rates[key]) ? '' : rates[key];
    };

    return (
      <div className="current-benchmark-modeling">
        <Row className="benchmark-details-selection">
          {!isReadOnly && (
            <Col xs={2} className="benchmark-plan-design-plan-name-container">
              <div className="medical-benchmark">
                <Label className="container">
                  <input
                    type="radio"
                    checked={isBenchmarkPlan}
                    name="radio"
                    onClick={(e) => {
                      !isBenchmarkPlan
                        ? selectPlanAsBenchmark(benchmarkPlan)
                        : console.log('Not an other plan');
                    }}
                    onChange={() => {}}
                  ></input>
                  <span className="checkmark"></span>
                </Label>
              </div>
            </Col>
          )}
          <Col xs={10} className="benchmark-plan-name">
            {isBenchmarkPlan && (
              <Label className="benchmark-medical-name-coloured">
                Benchmark &nbsp;
              </Label>
            )}

            <Label className="benchmark-medical-name">
              {isBenchmarkPlan && '-'} {benchmarkPlan.employerName}{' '}
              {benchmarkPlan.planName} ({benchmarkPlan.percentile}th percentile)
              &nbsp;
            </Label>
            {isBenchmarkPlan && !isReadOnly ? (
              <Label
                className="benchmark-btn-link"
                onClick={() => {
                  // this.switchToEditMode();
                  if (isEditMode) {
                    this.switchToEditMode();
                    cancelEditMode(benchmarkPlan);
                  }
                  if (unSelectPlanAsBenchmarkPlan) {
                    unSelectPlanAsBenchmarkPlan(benchmarkPlan);
                  }
                }}
              >
                Clear Selection
              </Label>
            ) : (
              ''
            )}
          </Col>
          <Col xs={2} className="btn-edit-benchmark">
            {isBenchmarkPlan && !isReadOnly ? (
              <div className="edit-select-medical">
                <div
                  className="benchmark-btn-link"
                  onClick={() => {
                    this.switchToEditMode();
                    saveEditedValues(isEditMode);
                  }}
                >
                  {isEditMode ? 'save' : 'edit'}
                </div>
                {isEditMode && !isReadOnly && (
                  <div
                    className="benchmark-btn-link cancel-btn"
                    onClick={() => {
                      this.switchToEditMode();
                      cancelEditMode(this.state.beforeEditBenchmark);
                    }}
                  >
                    cancel
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row
          className={`row-plan-design-benchmark plan-design-container-benchmark ${
            isBenchmarkPlan ? 'selected-benchmark-plan' : ''
          }`}
        >
          <Col className="benchmark-plan-design-section">
            <Row>
              <Col>
                <BenchmarkDetailTableHeader title="Plan Design" />
                {benefitCategory === BENEFIT_KIND_MEDICAL && (
                  <>
                    <ModelingInput
                      name="single"
                      id="deductSingle"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.deductible.single}
                      value={benchmarkPlanDesign.deductible.single}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Deductible - Single"
                      inValid={
                        validations && validations.isDeductibleSingleEmpty
                      }
                      type={TYPE_DEDUCTIBLE}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="family"
                      id="deductFamily"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.deductible.family}
                      value={benchmarkPlanDesign.deductible.family}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Deductible - Family"
                      inValid={
                        validations && validations.isDeductibleFamilyEmpty
                      }
                      type={TYPE_DEDUCTIBLE}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="single"
                      id="oopMaxSingle"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.oopMax.single}
                      value={benchmarkPlanDesign.oopMax.single}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="OOP Max - Single"
                      inValid={validations && validations.isOopMaxSingleEmpty}
                      type={TYPE_OOP_MAX}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="family"
                      id="oopMaxFamily"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.oopMax.family}
                      value={benchmarkPlanDesign.oopMax.family}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="OOP Max - Family"
                      inValid={validations && validations.isOopMaxFamilyEmpty}
                      type={TYPE_OOP_MAX}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="primary"
                      id="copayPrimary"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.copay.primary}
                      value={benchmarkPlanDesign.copay.primary}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Copay Primary"
                      inValid={validations && validations.isCopayPrimaryEmpty}
                      type={TYPE_COPAY}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="specialist"
                      id="copaySpecialist"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.copay.specialist}
                      value={benchmarkPlanDesign.copay.specialist}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Copay Specialist"
                      inValid={
                        validations && validations.isCopaySpecialistEmpty
                      }
                      type={TYPE_COPAY}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="coinsurance"
                      id="coinsurance"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.coinsurance}
                      value={benchmarkPlanDesign.coinsurance}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Coinsuarance"
                      inValid={validations && validations.isCoinsuaranceEmpty}
                      suffix={'%'}
                      prefix={''}
                      isBenchMarkValue
                      isTextValue={true}
                      inputValue={benchmarkPlanDesign.coinsurance}
                    />
                    <ModelingInput
                      name="t1"
                      id="rxCostT1"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.rxCost.t1}
                      value={benchmarkPlanDesign.rxCost.t1}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="RX Cost - Tier 1"
                      inValid={validations && validations.isRxCostT1Empty}
                      isBenchMarkValue
                      type={TYPE_RX_COST_TIER}
                    />
                    <ModelingInput
                      name="t2"
                      id="rxCostT2"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.rxCost.t2}
                      value={benchmarkPlanDesign.rxCost.t2}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="RX Cost - Tier 2"
                      inValid={validations && validations.isRxCostT2Empty}
                      isBenchMarkValue
                      type={TYPE_RX_COST_TIER}
                    />
                    <ModelingInput
                      name="t3"
                      id="rxCostT3"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.rxCost.t3}
                      value={benchmarkPlanDesign.rxCost.t3}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="RX Cost - Tier 3"
                      inValid={validations && validations.isRxCostT3Empty}
                      isBenchMarkValue
                      type={TYPE_RX_COST_TIER}
                    />
                    <ModelingInput
                      name="t4"
                      id="rxCostT4"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.rxCost.t4}
                      value={benchmarkPlanDesign.rxCost.t4}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="RX Cost - Tier 4"
                      inValid={validations && validations.isRxCostT4Empty}
                      isBenchMarkValue
                      type={TYPE_RX_COST_TIER}
                    />
                  </>
                )}
                {benefitCategory === BENEFIT_KIND_DENTAL && (
                  <>
                    <ModelingInput
                      name="single"
                      id="deductSingle"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.deductible.single}
                      value={benchmarkPlanDesign.deductible.single}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Deductible - Single"
                      inValid={
                        validations && validations.isDeductibleSingleEmpty
                      }
                      type={TYPE_DEDUCTIBLE}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="family"
                      id="deductFamily"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.deductible.family}
                      value={benchmarkPlanDesign.deductible.family}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Deductible - Family"
                      inValid={
                        validations && validations.isDeductibleFamilyEmpty
                      }
                      type={TYPE_DEDUCTIBLE}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="annualMaxBenefits"
                      id="annualMaxBenefits"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.annualMaxBenefits}
                      value={benchmarkPlanDesign.annualMaxBenefits}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Annual Max Benefits"
                      inValid={validations && validations.annualMaxBenefits}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="preventitive"
                      id="coinsurancePreventitive"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.coinsurance.preventitive}
                      value={benchmarkPlanDesign.coinsurance.preventitive}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      suffix={inputSuffix}
                      prefix={''}
                      title="Coinsurance - Preventitive"
                      inValid={
                        validations && validations.coinsurancePreventitive
                      }
                      isBenchMarkValue
                      isTextValue
                      type={COINSURANCE}
                    />
                    <ModelingInput
                      name="basic"
                      id="coinsuranceBasic"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.coinsurance.basic}
                      value={benchmarkPlanDesign.coinsurance.basic}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      suffix={inputSuffix}
                      prefix={''}
                      title="Coinsurance - Basic"
                      inValid={validations && validations.coinsuranceBasic}
                      isBenchMarkValue
                      isTextValue
                      type={COINSURANCE}
                    />
                    <ModelingInput
                      name="major"
                      id="coinsuranceMajor"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.coinsurance.major}
                      value={benchmarkPlanDesign.coinsurance.major}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Coinsurance - Major"
                      inValid={validations && validations.coinsuranceMajor}
                      suffix={inputSuffix}
                      prefix={''}
                      isTextValue
                      isBenchMarkValue
                      type={COINSURANCE}
                    />
                    <ModelingInput
                      name="orthodontiaCoinsuarance"
                      id="orthodontiaCoinsuarance"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.orthodontiaCoinsuarance}
                      value={benchmarkPlanDesign.orthodontiaCoinsuarance}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Orthodontia Coinsurance"
                      inValid={validations && validations.orthodontiaCoinsuarance}
                      suffix={inputSuffix}
                      prefix={''}
                      isTextValue
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="orthodontiaMaxBenefits"
                      id="orthodontiaMaxBenefits"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.orthodontiaMaxBenefits}
                      value={benchmarkPlanDesign.orthodontiaMaxBenefits}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Orthodontia Max Benefits"
                      inValid={validations && validations.orthodontiaMaxBenefits}
                      isBenchMarkValue
                    />
                  </>
                )}
                {benefitCategory === BENEFIT_KIND_VISION && (
                  <>
                    <ModelingInput
                      name="copayExam"
                      id="copayExam"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.copayExam}
                      value={benchmarkPlanDesign.copayExam}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Copay - Exam"
                      inValid={validations && validations.copayExam}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="copayMaterials"
                      id="copayMaterials"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.copayMaterials}
                      value={benchmarkPlanDesign.copayMaterials}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Copay - Materials"
                      inValid={validations && validations.copayMaterials}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="frameAllowance"
                      id="frameAllowance"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.frameAllowance}
                      value={benchmarkPlanDesign.frameAllowance}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Frame Allowance"
                      inValid={validations && validations.frameAllowance}
                      prefix={'Up to $'}
                      isTextValue={true}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="contactAllowance"
                      id="contactAllowance"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.contactAllowance}
                      value={benchmarkPlanDesign.contactAllowance}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Contact Allowance"
                      inValid={validations && validations.contactAllowance}
                      isTextValue={true}
                      prefix={'Up to $'}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="freqOfService"
                      id="freqOfService"
                      plan={benchmarkPlan}
                      currentValue={currentPlanDesign.freqOfService}
                      value={benchmarkPlanDesign.freqOfService}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Freq. of Services"
                      inValid={validations && validations.freqOfService}
                      isTextValue={true}
                      format="## / ## / ##"
                      isBenchMarkValue
                    />
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col className="benchmark-plan-cost-section">
            <BenchmarkDetailTableHeader title="Employee Cost - Monthly" />
            <ModelingInput
              name="EE"
              id="totalEmployeeOnly"
              plan={benchmarkPlan}
              currentValue={currentPlan.contribution.EE}
              value={benchmarkPlan.contribution.EE}
              isEditMode={isEditMode}
              onChange={onChange}
              onEventChange={onEventChange}
              title="Employee Only"
              inValid={validations && validations.isContributionEEEmpty}
              isBenchMarkValue
              type={TYPE_CONTRIBUTION}
            />
            <ModelingInput
              name="ES"
              id="totalEmployeeAndSpouse"
              plan={benchmarkPlan}
              currentValue={currentPlan.contribution.ES}
              value={benchmarkPlan.contribution.ES}
              isEditMode={isEditMode}
              onChange={onChange}
              onEventChange={onEventChange}
              title="Employee & Spouse"
              inValid={validations && validations.isContributionESEmpty}
              type={TYPE_CONTRIBUTION}
              isBenchMarkValue
            />
            <ModelingInput
              name="EC"
              id="totalEmployeeAndChildren"
              plan={benchmarkPlan}
              currentValue={currentPlan.contribution.EC}
              value={benchmarkPlan.contribution.EC}
              isEditMode={isEditMode}
              onChange={onChange}
              onEventChange={onEventChange}
              title="Employee & Child(ren)"
              inValid={validations && validations.isContributionECEmpty}
              type={TYPE_CONTRIBUTION}
              isBenchMarkValue
            />
            <ModelingInput
              name="EF"
              id="totalEmployeeAndFamily"
              plan={benchmarkPlan}
              currentValue={currentPlan.contribution.EF}
              value={benchmarkPlan.contribution.EF}
              isEditMode={isEditMode}
              onChange={onChange}
              onEventChange={onEventChange}
              title="Employee & Family"
              inValid={validations && validations.isContributionEFEmpty}
              type={TYPE_CONTRIBUTION}
              isBenchMarkValue
            />
            <Row className="total-cost-header-container">
              <Col>
                <BenchmarkDetailTableHeader
                  isHsa={
                    benefitCategory === BENEFIT_KIND_MEDICAL &&
                    benchmarkPlan.planType === BENCHMARK_PLAN_HSA
                  }
                  title={`Total Cost - Monthly ${
                    benefitCategory === BENEFIT_KIND_MEDICAL &&
                    benchmarkPlan.planType === BENCHMARK_PLAN_HSA
                      ? ' / HSA Contribution'
                      : ''
                  } `}
                />
                {benefitCategory === BENEFIT_KIND_MEDICAL &&
                benchmarkPlan.planType === BENCHMARK_PLAN_HSA ? (
                  <>
                    <ModelingSplitInput
                      name="EE"
                      leftId="costMonthlyEmployeeOnly"
                      rightId="contributionMonthlyEmployeeOnly"
                      plan={benchmarkPlan}
                      leftValue={benchmarkPlan.rates['EE']}
                      rightValue={
                        benchmarkPlan.hsaContribution &&
                        benchmarkPlan.hsaContribution['EE']
                      }
                      currentValue={{
                        rates: currentPlan.rates['EE'],
                        contributions:
                          currentPlan.hsaContribution &&
                          currentPlan.hsaContribution['EE']
                      }}
                      editMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Employee Only"
                      inValid={validations && validations.isRatesEEEmpty}
                      isBenchMarkValue
                    />
                    <ModelingSplitInput
                      name="ES"
                      leftId="costMonthlyEmployeeAndSpouce"
                      rightId="contributionMonthlyEmployeeAndSpouce"
                      plan={benchmarkPlan}
                      leftValue={benchmarkPlan.rates['ES']}
                      rightValue={
                        benchmarkPlan.hsaContribution &&
                        benchmarkPlan.hsaContribution['ES']
                      }
                      currentValue={{
                        rates: currentPlan.rates['ES'],
                        contributions:
                          currentPlan.hsaContribution &&
                          currentPlan.hsaContribution['ES']
                      }}
                      editMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Employee & Spouse / D.P."
                      inValid={validations && validations.isRatesESEmpty}
                      isBenchMarkValue
                    />
                    <ModelingSplitInput
                      name="EC"
                      leftId="costMonthlyEmployeeAndChildren"
                      rightId="contributionMonthlyEmployeeAndChildren"
                      plan={benchmarkPlan}
                      leftValue={benchmarkPlan.rates['EC']}
                      rightValue={
                        benchmarkPlan.hsaContribution &&
                        benchmarkPlan.hsaContribution['EC']
                      }
                      currentValue={{
                        rates: currentPlan.rates['EC'],
                        contributions:
                          currentPlan.hsaContribution &&
                          currentPlan.hsaContribution['EC']
                      }}
                      editMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Employee & Child(ren)"
                      inValid={validations && validations.isRatesECEmpty}
                      isBenchMarkValue
                    />
                    <ModelingSplitInput
                      name="EF"
                      leftId="costMonthlyEmployeeAndFamily"
                      rightId="contributionMonthlyEmployeeAndFamily"
                      plan={benchmarkPlan}
                      leftValue={benchmarkPlan.rates['EF']}
                      rightValue={
                        benchmarkPlan.hsaContribution &&
                        benchmarkPlan.hsaContribution['EF']
                      }
                      currentValue={{
                        rates: currentPlan.rates['EF'],
                        contributions:
                          currentPlan.hsaContribution &&
                          currentPlan.hsaContribution['EF']
                      }}
                      editMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Employee & Family"
                      inValid={validations && validations.isRatesEFEmpty}
                      isBenchMarkValue
                    />
                  </>
                ) : (
                  <>
                    <ModelingInput
                      name="EE"
                      id="totalMonthlyEmployeeOnly"
                      plan={benchmarkPlan}
                      currentValue={currentPlan.rates['EE']}
                      value={getBenchmarkTotalCosts(benchmarkPlan.rates, 'EE')}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Employee Only"
                      inValid={validations && validations.isRatesEEEmpty}
                      type={TYPE_RATES}
                      isBenchMarkValue
                      isTextFormat={isMedicalHSAPlan(
                        benchmarkPlan.planType,
                        benefitCategory
                      )}
                    />
                    <ModelingInput
                      name="ES"
                      id="totalMonthlyEmployeeAndSpouce"
                      plan={benchmarkPlan}
                      currentValue={currentPlan.rates['ES']}
                      value={getBenchmarkTotalCosts(benchmarkPlan.rates, 'ES')}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Employee & Spouse / D.P."
                      inValid={validations && validations.isRatesESEmpty}
                      type={TYPE_RATES}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="EC"
                      id="totalMonthlyEmployeeAndChildren"
                      plan={benchmarkPlan}
                      currentValue={currentPlan.rates['EC']}
                      value={getBenchmarkTotalCosts(benchmarkPlan.rates, 'EC')}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Employee & Child(ren)"
                      inValid={validations && validations.isRatesECEmpty}
                      type={TYPE_RATES}
                      isBenchMarkValue
                    />
                    <ModelingInput
                      name="EF"
                      id="totalMonthlyEmployeeAndFamily"
                      plan={benchmarkPlan}
                      currentValue={currentPlan.rates['EF']}
                      value={getBenchmarkTotalCosts(benchmarkPlan.rates, 'EF')}
                      isEditMode={isEditMode}
                      onChange={onChange}
                      onEventChange={onEventChange}
                      title="Employee & Family"
                      inValid={validations && validations.isRatesEFEmpty}
                      type={TYPE_RATES}
                      isBenchMarkValue
                    />
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const convertToCurrency = (stringValue) => {
  if (!_.isEmpty(stringValue)) {
    if (stringValue.includes('%')) {
      stringValue = stringValue.replace(/[^0-9.]/g, '');
      return parseFloat(stringValue);
    } else {
      stringValue = stringValue.replace(/[^0-9.]/g, '');
      return parseFloat(stringValue).toFixed(2);
    }
  }
};

const onEventChange = (event, onChange, currentPlan, type = undefined) => {
  const { name, value, id } = event.target;
  onChange(
    _.includes(HSA_AVAILABLE_TYPES, id) &&
      currentPlan.planType === BENCHMARK_PLAN_HSA
      ? name.split('_')[0]
      : name,
    _.includes(nonCurrencyTypes, name) || _.includes(nonCurrencyIds, id)
      ? value
      : convertToCurrency(value),
    currentPlan,
    type,
    id
  );
};
const nonCurrencyTypes = ['freqOfService'];
const nonCurrencyIds = [
  'enrollmentEmployeeOnly',
  'enrollmentEmployeeAndSpouse',
  'enrollmentEmployeeAndChildren',
  'enrollmentEmployeeAndFamily'
];

export default CurrentPlanDetails;
