/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import { generatePath } from 'react-router-dom';
import { connect } from 'react-redux';

import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import OffersPlanDataTable from './components/OffersPlanDataTable/OffersPlanDataTable';
import TabView from 'components/TabView/TabView';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row
} from 'components/Atoms';
import { listOfferPlanByType } from '../../actions/offerPlanAction';
import {
  listOffersToPublish,
  addPlansToProposalOffer
} from '../../actions/offerAction';
import NotificationManager from 'components/Notifications';
import Confirmation from 'components/Confirmation/Confirmation';
import { OFFER_PLAN_CREATE_PATH } from '../../routes';
import { permitIf } from 'components/hoc/Permit';
import { OFFERS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';

class OfferPlans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bundleDropdownOpen: false,
      medicalOfferPlans: [],
      dentalOfferPlans: [],
      visionOfferPlans: [],
      lifeOfferPlans: [],
      selectedRows: [],
      category: 'MEDICAL',
      groups: [],
      bundles: [],
      planToMove: {},
      isConfirmOpen: false,
      moveToGroup: {},
      ediAllDropdownOpen: false
    };
  }

  toggleBundle = () => {
    this.setState((prevState) => ({
      bundleDropdownOpen: !prevState.bundleDropdownOpen
    }));
  };
  toggleEditAll = () => {
    this.setState((prevState) => ({
      ediAllDropdownOpen: !prevState.ediAllDropdownOpen
    }));
  };

  togglePlan = () => {
    this.setState((prevState) => ({
      planDropdownOpen: !prevState.planDropdownOpen
    }));
  };

  goToUploadDataFile = () => {
    const { match } = this.props;
    const { params } = match;
    this.props.history.push(
      generatePath(OFFER_PLAN_CREATE_PATH, { ...params })
    );
  };

  componentDidMount() {
    // loads plan groups dropdown data as there's no tab click initially
    this.fetchDataForCategory('MEDICAL');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      addPlanToBundleInProgress,
      addPlanToBundleSuccess,
      addPlanToBundleError
    } = this.props;
    if (
      !addPlanToBundleError &&
      addPlanToBundleSuccess &&
      !addPlanToBundleInProgress &&
      addPlanToBundleSuccess !== prevProps.addPlanToBundleSuccess
    ) {
      NotificationManager.success('Selected plans are added to the offer');
    }
    if (
      addPlanToBundleError &&
      addPlanToBundleError !== prevProps.addPlanToBundleError
    ) {
      const { response } = addPlanToBundleError;
      if (response && response.data && response.data.message) {
        NotificationManager.error(response.data.message);
      } else {
        NotificationManager.error('Error adding selected plans to the offer');
      }
    }
  }

  /**
   * get medical plans for given employer.
   * @param event
   * @param employerId
   * @returns {Promise<void>}
   */
  getProposalPlans = (employerId, type) => {
    if (this.state.category === type) {
      this.props.listProposalPlanByType(employerId, type);
    }
  };
  getProposalPlanGroups = () => {};

  fetchDataForCategory = (category) => {
    const { match } = this.props;
    const { params } = match;
    const { employerId } = params;
    this.props.listProposalPlanByType(employerId, category);
    this.props.listOffersToPublish(employerId, category);
    this.setState({ category: category, groups: this.state.groups });
  };

  savePlanToPlanGroup = async () => {};

  addToOffer = (bundleId) => {
    const { selectedRows } = this.state;
    if (selectedRows.length > 0) {
      this.props.addPlansToProposalOffer(
        bundleId,
        this.state.category,
        this.state.selectedRows
      );
    } else {
      NotificationManager.warning('Select plans to add a bundle');
    }
  };

  updateSelectedRows = (selectedRows) => {
    this.setState({ selectedRows: selectedRows });
  };

  render() {
    const SecuredDropdownToggle = permitIf(
      DropdownToggle,
      OFFERS_FEATURE_ROLE_MAPPING.common
    );
    const { bundleDropdownOpen, category } = this.state;
    const {
      medicalOfferPlans,
      dentalOfferPlans,
      visionOfferPlans,
      lifeOfferPlans,
      medicalOffersToPublish,
      dentalOffersToPublish,
      visionOffersToPublish,
      lifeOffersToPublish,
      match
    } = this.props;
    const { params } = match;
    const { employerId } = params;

    const medicalContent = (
      <OffersPlanDataTable
        type="MEDICAL"
        employerId={employerId}
        plans={medicalOfferPlans}
        getProposalPlans={this.getProposalPlans}
        updateSelectedRows={this.updateSelectedRows}
        getProposalPlanGroupByCategory={this.getProposalPlanGroups}
      />
    );
    const dentalContent = (
      <OffersPlanDataTable
        type="DENTAL"
        employerId={employerId}
        plans={dentalOfferPlans}
        getProposalPlans={this.getProposalPlans}
        updateSelectedRows={this.updateSelectedRows}
        getProposalPlanGroupByCategory={this.getProposalPlanGroups}
      />
    );
    const visionContent = (
      <OffersPlanDataTable
        type="VISION"
        employerId={employerId}
        plans={visionOfferPlans}
        getProposalPlans={this.getProposalPlans}
        updateSelectedRows={this.updateSelectedRows}
        getProposalPlanGroupByCategory={this.getProposalPlanGroups}
      />
    );
    const lifeContent = (
      <OffersPlanDataTable
        type="LIFE"
        employerId={employerId}
        plans={lifeOfferPlans}
        getProposalPlans={this.getProposalPlans}
        updateSelectedRows={this.updateSelectedRows}
        getProposalPlanGroupByCategory={this.getProposalPlanGroups}
      />
    );

    const pageActions = () => (
      <Row className="float-right">
        <div key={2} className="mr-2">
          <Dropdown
            isOpen={bundleDropdownOpen}
            toggle={this.toggleBundle}
            size="lg"
          >
            <SecuredDropdownToggle caret color="primary">
              Add Selected to Offer
            </SecuredDropdownToggle>
            <DropdownMenu
              modifiers={{
                computeStyle: {
                  gpuAcceleration: false
                }
              }}
            >
              {category === 'MEDICAL' && medicalOffersToPublish.length > 0 && (
                <>
                  {medicalOffersToPublish.map((bundle) => (
                    <DropdownItem onClick={() => this.addToOffer(bundle.id)}>
                      {bundle.name}
                    </DropdownItem>
                  ))}
                </>
              )}
              {category === 'DENTAL' && dentalOffersToPublish.length > 0 && (
                <>
                  {dentalOffersToPublish.map((bundle) => (
                    <DropdownItem onClick={() => this.addToOffer(bundle.id)}>
                      {bundle.name}
                    </DropdownItem>
                  ))}
                </>
              )}
              {category === 'VISION' && visionOffersToPublish.length > 0 && (
                <>
                  {visionOffersToPublish.map((bundle) => (
                    <DropdownItem onClick={() => this.addToOffer(bundle.id)}>
                      {bundle.name}
                    </DropdownItem>
                  ))}
                </>
              )}
              {category === 'LIFE' && lifeOffersToPublish.length > 0 && (
                <>
                  {lifeOffersToPublish.map((bundle) => (
                    <DropdownItem onClick={() => this.addToOffer(bundle.id)}>
                      {bundle.name}
                    </DropdownItem>
                  ))}
                </>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      </Row>
    );

    return (
      <ApplicationWrapper>
        <HeaderContainer title="Offer Plans" actions={pageActions()} />
        <TabView
          onTabClick={(category) => this.fetchDataForCategory(category)}
          medicalContent={medicalContent}
          dentalContent={dentalContent}
          visionContent={visionContent}
          lifeContent={lifeContent}
        />
        <Confirmation
          title="Add Plan(s) to Plan Group?"
          message={
            <>
              Are you sure you want to add the selected plan(s) to the{' '}
              <strong>{this.state.moveToGroup.groupName}</strong> ?
            </>
          }
          open={this.state.isConfirmOpen}
          confirmBtnText={'Add Plan(s)'}
          onConfirm={() => {
            this.savePlanToPlanGroup();
          }}
          onClose={() => this.setState({ isConfirmOpen: false })}
        />
      </ApplicationWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    medicalOfferPlans,
    dentalOfferPlans,
    visionOfferPlans,
    lifeOfferPlans
  } = state.offersReducer.OfferPlanReducer;
  const { offersToPublish, addPlanToOffer } = state.offersReducer.OfferReducer;
  return {
    medicalOfferPlans,
    dentalOfferPlans,
    visionOfferPlans,
    lifeOfferPlans,
    medicalOffersToPublish: offersToPublish.medicalOffersToPublish,
    dentalOffersToPublish: offersToPublish.dentalOffersToPublish,
    visionOffersToPublish: offersToPublish.visionOffersToPublish,
    lifeOffersToPublish: offersToPublish.lifeOffersToPublish,
    addPlanToBundleInProgress: addPlanToOffer.inProgress,
    addPlanToBundleSuccess: addPlanToOffer.isAddSuccess,
    addPlanToBundleError: addPlanToOffer.addToOfferError
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    listProposalPlanByType: (employerId, type) =>
      dispatch(listOfferPlanByType(employerId, type)),
    listOffersToPublish: (employerId, category) =>
      dispatch(listOffersToPublish(employerId, category)),
    addPlansToProposalOffer: (bundleId, category, plans) =>
      dispatch(addPlansToProposalOffer(bundleId, category, plans))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferPlans);
