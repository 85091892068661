/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import { CHATBOT_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { permitIf } from 'components/hoc/Permit';
import {
  createConfigModule,
  findConfigStatus,
  getEmployer,
  updateChatbotConfig,
  updateConfigModule
} from '../actions/chatbotConfigActions';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import ChatbotConfigMessage from './MobileChatbotConfigMessage';
import {
  FEATURE_KEY_CHATBOT,
  FEATURE_KEY_MOBILE,
  UPDATE_CHATBOT_TYPE_MOBILE
} from '../constants';
import { FEATURE_KEY_BENEFIT_CONSULTATION } from '../../Mobile/constants';

class MobileChatbotConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      welcomeMessage: '',
      farewellMessage: '',
      customerSupportCompatible: false,
      lumityConsultCompatible: false,
      isSavedChatbotConfig: false,
      showNotification: true,
      employerChatbot : {
        chatbotConfigSettings: {
          mobileChatbotConfig: {
            welcomeMessage: '',
            farewellMessage: '',
          }
        }
      }
    };
  }

  componentDidMount() {
    const { findConfigStatus, match, getEmployer } = this.props;
    const { employerId } = match.params;
    findConfigStatus(employerId, FEATURE_KEY_CHATBOT);
    findConfigStatus(employerId, FEATURE_KEY_BENEFIT_CONSULTATION);
    getEmployer(employerId);
  }

  componentDidUpdate = (prevProps) => {
    const {
      employerChatbot,
      employerChatbotConfigLoading,
      chatbotConfigUpdating
    } = this.props;
    const { showNotification, isSavedChatbotConfig } = this.state;
    if (
      employerChatbot &&
      prevProps.employerChatbotConfigLoading &&
      employerChatbotConfigLoading !== prevProps.employerChatbotConfigLoading &&
      !isSavedChatbotConfig
    ) {
      this.updateChatbotConfig();
    }

    if (
      prevProps.chatbotConfigUpdating &&
      chatbotConfigUpdating !== prevProps.chatbotConfigUpdating &&
      showNotification
    ) {
      NotificationManager.success(
        `"Lumity" the Mobile chatbot assistant configurations have been saved.`
      );
    }
  };

  updateChatbotConfig = () => {
    const { chatbotConfigSettings } = this.props.employerChatbot;
    const { mobileChatbotConfig } = chatbotConfigSettings;
    const { welcomeMessage, farewellMessage, fallbackOptions } = mobileChatbotConfig;
    this.setState({
      welcomeMessage: welcomeMessage,
      farewellMessage: farewellMessage,
      customerSupportCompatible:
        fallbackOptions && fallbackOptions.customerSupportCompatible
          ? fallbackOptions.customerSupportCompatible
          : false,
      lumityConsultCompatible:
        fallbackOptions && fallbackOptions.lumityConsultCompatible
          ? fallbackOptions.lumityConsultCompatible
          : false
    });
  };

  switchMobileEnabled = () => {
    const {
      match,
      configStatus,
      createConfigModule,
      updateConfigModule
    } = this.props;
    const { employerId } = match.params;
    const { id, value } = configStatus;
    if (!id) {
      createConfigModule(employerId, FEATURE_KEY_MOBILE);
    } else {
      updateConfigModule(id, employerId, value, FEATURE_KEY_MOBILE);
    }
    NotificationManager.success(
      `Mobile App has been ${value === 'false' ? 'enabled' : 'disabled'}.`
    );
  };

  switchChatbotEnabled = () => {
    const {
      match,
      chatbotConfigStatus,
      createConfigModule,
      updateConfigModule,
      employerChatbot
    } = this.props;
    const { employerId } = match.params;
    const { id, value } = chatbotConfigStatus;
    if (!id) {
      createConfigModule(employerId, FEATURE_KEY_CHATBOT);
    } else {
      updateConfigModule(id, employerId, value, FEATURE_KEY_CHATBOT);
    }
    const { chatbotConfigSettings } = employerChatbot;
    if (
      employerChatbot &&
      employerChatbot.chatbotConfigSettings &&
      employerChatbot.chatbotConfigSettings.mobileChatbotConfig &&
      !employerChatbot.chatbotConfigSettings.mobileChatbotConfig.welcomeMessage
    ) {
      const fallbackOptions = {
        customerSupportCompatible: true,
        lumityConsultCompatible: false
      };
      const config = {
        fallbackOptions: fallbackOptions,
        chatbotType: UPDATE_CHATBOT_TYPE_MOBILE
      };
      this.setState({ showNotification: false }, () => {
        this.onSaveChatbotConfigs(config);
      });
    }
    NotificationManager.success(
      `"Lumity" the Mobile chatbot assistant has been ${
        value === 'false' ? 'enabled' : 'disabled'
      }.`
    );
  };

  onSaveChatbotConfigs = (config) => {
    const { employerId } = this.props.match.params;
    const { welcomeMessage, farewellMessage, fallbackOptions } = config;
    const {
      customerSupportCompatible,
      lumityConsultCompatible
    } = fallbackOptions;
    this.setState(
      {
        isSavedChatbotConfig: true,
        welcomeMessage,
        farewellMessage,
        customerSupportCompatible,
        lumityConsultCompatible
      },
      () => {
        this.props.updateChatbotConfig(employerId, config);
      }
    );
  };

  render() {
    let {
      welcomeMessage,
      farewellMessage,
      lumityConsultCompatible,
      customerSupportCompatible
    } = this.state;

    const { employerChatbot } = this.props;
    if (employerChatbot !== undefined) {
      const { chatbotConfigSettings } = employerChatbot;
      if (chatbotConfigSettings !== undefined) {
        const { mobileChatbotConfig } = chatbotConfigSettings;
        if (mobileChatbotConfig !== undefined) {
          if (!welcomeMessage) {
            welcomeMessage = mobileChatbotConfig.welcomeMessage;
          }
          if (!farewellMessage) {
            farewellMessage = mobileChatbotConfig.farewellMessage;
          }
        }
      }
    }

    const { chatbotConfigStatus, bConsultationConfigStatus, match } = this.props;
    const { employerId } = match.params;
    const SecuredEmployerConfigToggle = permitIf(
      EmployerConfigToggleV2,
      CHATBOT_FEATURE_ROLE_MAPPING.common
    );
    return (
      <ApplicationWrapper>
        <ContentContainer>
          <SecuredEmployerConfigToggle
            employerId={employerId}
            moduleEnabled={FEATURE_KEY_CHATBOT}
            message={'Mobile'}
            config={chatbotConfigStatus}
            switchCallBack={this.switchChatbotEnabled}>
            <hr className='divider' />
            <div className='mt-4'>
              <ChatbotConfigMessage
                onSaveChatbotConfigs={this.onSaveChatbotConfigs}
                welcomeMessage={welcomeMessage}
                farewellMessage={farewellMessage}
                lumityConsultCompatible={lumityConsultCompatible}
                customerSupportCompatible={customerSupportCompatible}
                employerId={employerId}
              />
            </div>
          </SecuredEmployerConfigToggle>
        </ContentContainer>
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createConfigModule: (employerId, key) =>
    dispatch(createConfigModule(employerId, key)),
  updateConfigModule: (id, employerId, value, key) =>
    dispatch(updateConfigModule(id, employerId, value, key)),
  findConfigStatus: (employerId, key) =>
    dispatch(findConfigStatus(employerId, key)),
  updateChatbotConfig: (employerId, config) =>
    dispatch(updateChatbotConfig(employerId, config)),
  getEmployer: (employerId) => dispatch(getEmployer(employerId))
});

const mapStateToProps = (state) => {
  const {
    employerConfigCreate,
    employerConfigCreateError,
    employerConfigUpdate,
    employerConfigUpdateError,
    configStatus,
    chatbotConfigStatus,
    bConsultationConfigStatus,
    employerChatbot,
    employerChatbotConfigLoading,
    chatbotConfigUpdating
  } = state.chatbotConfigReducer;
  return {
    employerConfigCreate,
    employerConfigCreateError,
    employerConfigUpdate,
    employerConfigUpdateError,
    configStatus,
    chatbotConfigStatus,
    bConsultationConfigStatus,
    employerChatbot,
    employerChatbotConfigLoading,
    chatbotConfigUpdating
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileChatbotConfig);
