/**********************************************************************************************************************
 * Copyright (C) 2019 - 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import {
  Label,
  CustomInput,
  FormText,
  Row,
  Col,
  Button
} from 'components/Atoms';
import Icon from 'components/Icons';
import defaultImage from 'assets/images/logo-placeholder.png';
import './ImageUploader.scss';

const ImageUploader = (props) => {
  const {
    imageReference,
    imageUploading,
    header,
    isReadOnlyView,
    label,
    onChange,
    removeFile
  } = props;

  return (
    <div>
      <Label for={`${label}`}>
        <b>{header}</b>
      </Label>
      <Row>
        <Col xs="6" md="6">
          <CustomInput
            type="file"
            id={`${label}`}
            className={`mb-3 ${isReadOnlyView ? 'disable-background' : ''}`}
            onChange={onChange}
            onClick={(event) => {
              event.currentTarget.value = null;
            }}
            label={label}
            disabled={isReadOnlyView}
            accept="image/png, image/jpeg"
          />
        </Col>
        <Col xs="6" md="6">
          <Row>
            <Col xs="8" md="8">
              {imageUploading ? (
                <Icon icon="sync" spin={true} size="2x" />
              ) : (
                <img
                  alt={`${imageReference}`}
                  className="customization-thumb"
                  src={imageReference || defaultImage}
                />
              )}
              <FormText color="muted">
                Background Spec Size: 1000px X 320px
              </FormText>
            </Col>
            {imageReference && !isReadOnlyView && (
              <Col xs="4" md="4">
                <Button
                  color="link"
                  className="remove-file-button"
                  onClick={removeFile}
                >
                  remove
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

ImageUploader.propTypes = {
  imageReference: PropTypes.string,
  imageUploading: PropTypes.bool,
  header: PropTypes.string,
  isReadOnlyView: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  removeFile: PropTypes.func
};

export default ImageUploader;
