import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Atoms';
import Icon from 'components/Icons';

import './AddRowButton.scss';

const AddRowButton = (props) => {
  const { className, children, onClick, disabled, icon } = props;
  return (
    <Button
      color="link"
      disabled={disabled}
      className={`add-row-button ${className || ''}`}
    >
      <Icon
        icon={icon || 'plus'}
        className={`add-row-button-contents ${
          children ? 'icon-margin-right' : ''
        }`}
        onClick={!disabled ? onClick : null}
        disabled={disabled}
      />
      {children && (
        <div
          onClick={!disabled ? onClick : null}
          className="add-row-button-contents"
        >
          {children}
        </div>
      )}
    </Button>
  );
};

AddRowButton.propTypes = {
  // Expects event function
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

AddRowButton.defaultProps = {
  onClick: () => {},
  disabled: false
};

export default AddRowButton;
