/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import { permitIf } from 'components/hoc/Permit';
import {
  getCurrentPlansFeatureConfig,
  createCurrentPlansFeatureConfig,
  updateCurrentPlansFeatureConfig,
  getBenefitGroupConfig,
  createBenefitGroupConfig,
  updateBenefitGroupConfig,
  getBenefitGroupConfigList
} from '../../actions/currentPlansTogglesAction';
import * as ActionTypes from '../../actionTypes/currentPlansToggles.actionTypes';

import { Col, Row } from 'components/Atoms';
import { CURRENT_PLANS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { MODULE_CURRENT_PLANS_ENABLED } from 'modules/CurrentPlans/constants';
import { NotificationManager } from 'react-notifications';
import DataTable from 'components/DataTables/DataTable/DataTable';
import Switch from 'components/Buttons/Switch';
import './CurrentPlansToggles.scss';
import _ from 'lodash';
class CurrentPlansToggles extends React.Component {
  constructor(props) {
    super(props);
    const { employerId } = props.match.params;
    this.state = {
      benefitGroupTogglesDetails: []
    };

    this.props.getCurrentPlansFeatureConfig(employerId);
  }

  componentDidMount(prevProps) {
    const { employerId } = this.props.match.params;
    this.initializeEmployerGroupsList(employerId).then(() => {
      const { benefitGroupConfigList } = this.props;
      if (!_.isEmpty(benefitGroupConfigList)) {
        this.setState((prevState) => ({
          ...prevState,
          benefitGroupTogglesDetails: benefitGroupConfigList
        }));
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      requestStatus,
      serviceError,
      configStatus,
      benefitGroupConfig,
      benefitGroupConfigList
    } = this.props;

    const { requestStatus: prevRequestStatus } = prevProps;

    if (
      benefitGroupConfig.id !== null &&
      prevProps.benefitGroupConfig !== benefitGroupConfig
    ) {
      let newBenefitGroupConfig = benefitGroupConfig;
      let newBenefitGroupTogglesDetails = benefitGroupConfigList;

      let foundIndex = benefitGroupConfigList.findIndex(
        (group) =>
          group.benefitGroupName === newBenefitGroupConfig.benefitGroupName
      );
      if (foundIndex !== -1) {
        newBenefitGroupTogglesDetails[foundIndex] = newBenefitGroupConfig;
        this.setState((prevState) => ({
          ...prevState,
          benefitGroupTogglesDetails: newBenefitGroupTogglesDetails
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          benefitGroupTogglesDetails: [
            ...prevState.benefitGroupTogglesDetails,
            benefitGroupConfig
          ]
        }));
      }
      switch (requestStatus) {
        case ActionTypes.UPDATE_BENEFIT_GROUPS_CONFIGURATION_SUCCESS: {
          const enabled = benefitGroupConfig.enabled;
          const name = benefitGroupConfig.benefitGroupName;
          NotificationManager.success(
            `Benefit Group "${name}" ${
              enabled ? 'Enabled' : 'Disabled'
            } Successfully`
          );
          return;
        }
        case ActionTypes.CREATE_BENEFIT_GROUPS_CONFIGURATION_SUCCESS: {
          const enabled = benefitGroupConfig.enabled;
          const name = benefitGroupConfig.benefitGroupName;
          NotificationManager.success(
            `Benefit Group "${name}" ${
              enabled ? 'Enabled' : 'Disabled'
            } Successfully`
          );
          return;
        }
        default:
          break;
      }
    }

    if (prevRequestStatus === requestStatus) return;

    if (serviceError) {
      // Generic Error
      NotificationManager.error(serviceError.message);
      return;
    }

    switch (requestStatus) {
      case ActionTypes.UPDATE_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS: {
        const value = configStatus.value;
        NotificationManager.success(
          `Feature ${value === 'true' ? 'Enabled' : 'Disabled'} Successfully`
        );
        return;
      }
      case ActionTypes.CREATE_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS: {
        const value = configStatus.value;
        NotificationManager.success(
          `Feature ${value === 'true' ? 'Enabled' : 'Disabled'} Successfully`
        );
        return;
      }
      default:
        break;
    }
  }

  initializeEmployerGroupsList = async (employerId) => {
    const { getBenefitGroupConfigList } = this.props;
    await getBenefitGroupConfigList(employerId);
  };

  switchCurrentPlansEnabled = () => {
    const {
      configStatus,
      updateCurrentPlansFeatureConfig,
      createCurrentPlansFeatureConfig,
      match
    } = this.props;
    const { employerId } = match.params;
    const newValue =
      configStatus.value === true || configStatus.value === 'true'
        ? 'false'
        : 'true';

    if (!configStatus.id) {
      createCurrentPlansFeatureConfig(
        MODULE_CURRENT_PLANS_ENABLED,
        employerId,
        newValue
      );
    } else {
      const updatedConfig = { ...configStatus, value: newValue };
      updateCurrentPlansFeatureConfig(updatedConfig);
    }
  };

  switchBenefitGroupEnabled = (e, group) => {
    const {
      updateBenefitGroupConfig,
      createBenefitGroupConfig,
      match
    } = this.props;
    const { employerId } = match.params;
    const { benefitGroupTogglesDetails } = this.state;

    const groupConfiguration = benefitGroupTogglesDetails.find(
      (config) => config.benefitGroupName === group
    );
    const newValue = e === true ? true : false;

    if (groupConfiguration.id === null) {
      createBenefitGroupConfig(
        employerId,
        groupConfiguration.benefitGroupName,
        newValue
      );
    } else {
      const updatedConfig = { ...groupConfiguration, enabled: newValue };
      updateBenefitGroupConfig(updatedConfig);
    }
  };

  render() {
    const { benefitGroupTogglesDetails } = this.state;
    const employerId = this.state.employerId;
    const { configStatus } = this.props;
    const SecuredEmployerConfigToggle = permitIf(
      EmployerConfigToggleV2,
      CURRENT_PLANS_FEATURE_ROLE_MAPPING.common
    );
    let tableTogglesDisabled = true;
    if (configStatus.id != null && configStatus.value === 'true') {
      tableTogglesDisabled = false;
    }

    let rowData = [];

    if (benefitGroupTogglesDetails.length !== 0) {
      rowData =
        benefitGroupTogglesDetails &&
        benefitGroupTogglesDetails.map((group) => {
          return {
            benefitGroupName: group.benefitGroupName,
            enabled: group.enabled
          };
        });
    }

    const columnData = [
      {
        name: <span className="table-header">Benefit Group Name</span>,
        selector: 'benefitGroupName',
        sortable: false,
        cell: (row) => <b>{row.benefitGroupName}</b>,
        grow: 5,
        left: true
      },
      {
        selector: 'enabled',
        sortable: false,
        cell: (row) => (
          <>
            <Switch
              id={row.id}
              className="table-toggle"
              onChange={(e) =>
                this.switchBenefitGroupEnabled(e, row.benefitGroupName)
              }
              checked={row.enabled !== undefined && row.enabled}
              disabled={tableTogglesDisabled}
            />
          </>
        ),
        right: true,
        maxWidth: '50px'
      }
    ];

    return (
      <ApplicationWrapper>
        <HeaderContainer title="Current Plans" />
        <Row>
          <Col className="page-content">
            <ContentContainer>
              <SecuredEmployerConfigToggle
                employerId={employerId}
                moduleEnabled={MODULE_CURRENT_PLANS_ENABLED}
                message="Current Plans"
                config={configStatus}
                switchCallBack={this.switchCurrentPlansEnabled}
              />
            </ContentContainer>
            <ContentContainer>
              <div className="current-plans-toggles">
                <DataTable columns={columnData} data={rowData} />
              </div>
            </ContentContainer>
          </Col>
        </Row>
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCurrentPlansFeatureConfig: (employerId) =>
    dispatch(getCurrentPlansFeatureConfig(employerId)),
  getBenefitGroupConfigList: (employerId) =>
    dispatch(getBenefitGroupConfigList(employerId)),
  createCurrentPlansFeatureConfig: (configKey, employerId, enableValue) =>
    dispatch(
      createCurrentPlansFeatureConfig(configKey, employerId, enableValue)
    ),
  updateCurrentPlansFeatureConfig: (config, value) =>
    dispatch(updateCurrentPlansFeatureConfig(config, value)),
  getBenefitGroupConfig: (employerId, benefitGroupName) =>
    dispatch(getBenefitGroupConfig(employerId, benefitGroupName)),
  createBenefitGroupConfig: (employerId, benefitGroupName, enabled) =>
    dispatch(createBenefitGroupConfig(employerId, benefitGroupName, enabled)),
  updateBenefitGroupConfig: (config) =>
    dispatch(updateBenefitGroupConfig(config))
});

const mapStateToProps = (state) => {
  const {
    requestStatus,
    serviceError,
    isLoading,
    configStatus,
    benefitGroupConfig,
    benefitGroupConfigList
  } = state.currentPlansTogglesReducer;

  return {
    requestStatus,
    serviceError,
    isLoading,
    configStatus,
    benefitGroupConfig,
    benefitGroupConfigList
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentPlansToggles);
