/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import ContentContainer from '../../../../../../containers/ContentContainer/ContentContainer';
import DataTableWithClientPagination from '../../../../../../components/DataTables/DataTable/DataTableWithClientPagination';
import FullscreenModal from 'components/FullscreenModal';
import AdapterService from 'services/AdapterService';

import './HRISResponse.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getHRISResponses } from '../../../../actions/employerActions';
import Notifications from 'components/Notifications';

class HRISResponse extends React.Component {
  componentDidUpdate(prevProps) {
    const { getHRISResponses } = this.props;
    if (!prevProps.isOpen && this.props.isOpen) {
      getHRISResponses(this.props.employerId);
    }
  }

  columnData = [
    {
      name: 'File Name',
      selector: 'key',
      cell: (row) => row.key.substring(row.key.lastIndexOf('/') + 1),
      sortable: false,
      wrap: true
    },
    {
      name: 'Action',
      cell: (row) => (
        <a
          color="blue"
          href={AdapterService.downloadHRISResponse(
            row.key,
            row.key.substring(row.key.lastIndexOf('/') + 1)
          )}
          onClick={() => {
            Notifications.success('Successfully Downloaded');
          }}
        >
          Download
        </a>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      maxWidth: '150px'
    }
  ];
  renderBillingHistoryForm = () => {
    const { hrisResponses, inProgress } = this.props;
    return (
      <div className="hris-form">
        <ContentContainer>
          <DataTableWithClientPagination
            columnData={this.columnData}
            rowData={hrisResponses}
            hideSearchBar={true}
            paginationPerPage="10"
            paginationRowsPerPageOptions={[10, 30, 50]}
            defaultSortField={'-lastModified'}
            fetching={inProgress}
          />
        </ContentContainer>
      </div>
    );
  };
  render() {
    const { isOpen, toggle } = this.props;
    return (
      <FullscreenModal isOpen={isOpen} toggle={toggle} title="HRIS Responses">
        <div className="hris-content">{this.renderBillingHistoryForm()}</div>
      </FullscreenModal>
    );
  }
}

export default connect(
  (state) => {
    const { employersReducer } = state.Employers;
    const { hrisResponsesData } = employersReducer;
    return {
      hrisResponses: hrisResponsesData.data,
      hrisResponsesError: hrisResponsesData.error,
      inProgress: hrisResponsesData.inProgress
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        getHRISResponses
      },
      dispatch
    )
)(HRISResponse);
