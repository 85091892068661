/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
// import * as OfferPages from './index';
import * as OfferPages from './index';

const OFFER_PLAN_PATH = '/brokers/:brokerId/employers/:employerId/offer-plans';
const OFFER_PLAN_CREATE_PATH = '/brokers/:brokerId/employers/:employerId/offer-plan/create';
const OFFER_PLAN_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/offer-plan/:offerId/edit';
const OFFER_PLAN_EDIT_PATH_STEP_FILE_UPLOAD =
  '/brokers/:brokerId/employers/:employerId/offer-plan/:offerId/edit/upload-data-file';
const OFFER_BUNDLE_PATH = '/brokers/:brokerId/employers/:employerId/offers';
const OFFER_BUNDLE_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/bundle/:bundleCategory(medical|dental|vision|life)/:mode(create)';
const OFFER_BUNDLE_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/bundle/:bundleId/:mode(edit)';
const OFFER_PLAN_GROUPS_PATH = '/brokers/:brokerId/employers/:employerId/plan-groups';

const offersPathMapping = [
  { path: OFFER_PLAN_PATH, component: OfferPages.OfferPlans },
  { path: OFFER_PLAN_CREATE_PATH, component: OfferPages.OfferUpload },
  {
    path: OFFER_BUNDLE_CREATE_PATH,
    component: OfferPages.OfferCreateEdit
  },
  {
    path: OFFER_BUNDLE_EDIT_PATH,
    component: OfferPages.OfferCreateEdit
  },
  { path: OFFER_BUNDLE_PATH, component: OfferPages.OfferList },
  {
    path: OFFER_PLAN_EDIT_PATH_STEP_FILE_UPLOAD,
    component: OfferPages.OfferUpload
  }
];
export default offersPathMapping;
export {
  OFFER_PLAN_PATH,
  OFFER_BUNDLE_CREATE_PATH,
  OFFER_BUNDLE_EDIT_PATH,
  OFFER_BUNDLE_PATH,
  OFFER_PLAN_CREATE_PATH,
  OFFER_PLAN_EDIT_PATH,
  OFFER_PLAN_EDIT_PATH_STEP_FILE_UPLOAD,
  OFFER_PLAN_GROUPS_PATH
};
