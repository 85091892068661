export const SERVICE_SSO_ENABLED = 'SERVICE_SSO_ENABLED';

export const vendors = ['Okta', 'OneLogin'];

export const OKTA = 'OKTA';
export const ONELOGIN = 'ONELOGIN';

export const SSO_VALIDATION_INPROGRESS = 'SSO_VALIDATION_INPROGRESS';
export const SSO_VALIDATION_FAILED = 'SSO_VALIDATION_FAILED';
export const SSO_VALIDATION_COMPLETED = 'SSO_VALIDATION_COMPLETED';
