/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
export const GET_SURVEY_FEATURE_CONF = 'GET_SURVEY_FEATURE_CONF';
export const GET_SURVEY_FEATURE_CONF_SUCCESS =
  'GET_SURVEY_FEATURE_CONF_SUCCESS';
export const GET_SURVEY_FEATURE_CONF_ERROR = 'GET_SURVEY_FEATURE_CONF_ERROR';

export const UPDATE_SURVEY_FEATURE_CONF = 'UPDATE_SURVEY_FEATURE_CONF';
export const UPDATE_SURVEY_FEATURE_CONF_SUCCESS =
  'UPDATE_SURVEY_FEATURE_CONF_SUCCESS';
export const UPDATE_SURVEY_FEATURE_CONF_ERROR =
  'UPDATE_SURVEY_FEATURE_CONF_ERROR';

export const GET_SURVEY_LIST = 'GET_SURVEY_LIST';
export const GET_SURVEY_LIST_SUCCESS = 'GET_SURVEY_LIST_SUCCESS';
export const GET_SURVEY_LIST_ERROR = 'GET_SURVEY_LIST_ERROR';

export const CREATE_SURVEY = 'CREATE_SURVEY';
export const CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS';
export const CREATE_SURVEY_ERROR = 'CREATE_SURVEY_ERROR';

export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const UPDATE_SURVEY_SUCCESS = 'UPDATE_SURVEY_SUCCESS';
export const UPDATE_SURVEY_ERROR = 'UPDATE_SURVEY_ERROR';

export const DELETE_SURVEY = 'DELETE_SURVEY';
export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS';
export const DELETE_SURVEY_ERROR = 'DELETE_SURVEY_ERROR';

export const GET_SURVEY_DETAILS = 'GET_SURVEY_DETAILS';
export const GET_SURVEY_DETAILS_SUCCESS = 'GET_SURVEY_DETAILS_SUCCESS';
export const GET_SURVEY_DETAILS_ERROR = 'GET_SURVEY_DETAILS_ERROR';

export const GET_SURVEY_PARTICIPANTS = 'GET_SURVEY_PARTICIPANTS';
export const GET_SURVEY_PARTICIPANTS_SUCCESS =
  'GET_SURVEY_PARTICIPANTS_SUCCESS';
export const GET_SURVEY_PARTICIPANTS_ERROR = ' GET_SURVEY_PARTICIPANTS_ERROR';

export const PUBLISH_SURVEY = 'PUBLISH_SURVEY';
export const PUBLISH_SURVEY_SUCCESS = 'PUBLISH_SURVEY_SUCCESS';
export const PUBLISH_SURVEY_ERROR = 'PUBLISH_SURVEY_ERROR';

export const SEND_SURVEY = 'SEND_SURVEY';
export const SEND_SURVEY_SUCCESS = 'SEND_SURVEY_SUCCESS';
export const SEND_SURVEY_ERROR = 'SEND_SURVEY_ERROR';

export const DOWNLOAD_SURVEY_RESPONSES = 'DOWNLOAD_SURVEY_RESPONSES';
export const DOWNLOAD_SURVEY_RESPONSES_SUCCESS =
  'DOWNLOAD_SURVEY_RESPONSES_SUCCESS';
export const DOWNLOAD_SURVEY_RESPONSES_ERROR =
  ' DOWNLOAD_SURVEY_RESPONSES_ERROR';
