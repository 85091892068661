/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
const TELEHEALTH_RX_DELIVERY_LIST_PATH =
  '/brokers/:brokerId/employers/:employerId/telehealth-rx-delivery-plans';

const TELEHEALTH_RX_DELIVERY_TELEHEALTH_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/telehealth-rx-delivery-plans/telehealth/create';

const TELEHEALTH_RX_DELIVERY_TELEHEALTH_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/telehealth-rx-delivery-plans/telehealth/:planId/edit';
 
const TELEHEALTH_RX_DELIVERY_TELEHEALTH_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/telehealth-rx-delivery-plans/telehealth/:planId/view';

const TELEHEALTH_RX_DELIVERY_TELEHEALTH_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/telehealth-rx-delivery-plans/telehealth/:planId/clone';  

const TELEHEALTH_RX_DELIVERY_RX_DELIVERY_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/telehealth-rx-delivery-plans/rx-delivery/create';

const TELEHEALTH_RX_DELIVERY_RX_DELIVERY_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/telehealth-rx-delivery-plans/rx-delivery/:planId/edit';

const TELEHEALTH_RX_DELIVERY_RX_DELIVERY_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/telehealth-rx-delivery-plans/rx-delivery/:planId/view';

const TELEHEALTH_RX_DELIVERY_RX_DELIVERY_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/telehealth-rx-delivery-plans/rx-delivery/:planId/clone';  

const TELEHEALTH_RX_DELIVERY_RX_COUPONS_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/telehealth-rx-delivery-plans/rx-coupons/create';

const TELEHEALTH_RX_DELIVERY_RX_COUPONS_EDIT_PATH =
  '/brokers/:brokerId/employers/:employerId/telehealth-rx-delivery-plans/rx-coupons/:planId/edit';

const TELEHEALTH_RX_DELIVERY_RX_COUPONS_VIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/telehealth-rx-delivery-plans/rx-coupons/:planId/view';

const TELEHEALTH_RX_DELIVERY_RX_COUPONS_CLONE_PATH =
  '/brokers/:brokerId/employers/:employerId/telehealth-rx-delivery-plans/rx-coupons/:planId/clone';  

export {
  TELEHEALTH_RX_DELIVERY_LIST_PATH,
  TELEHEALTH_RX_DELIVERY_TELEHEALTH_CREATE_PATH,
  TELEHEALTH_RX_DELIVERY_TELEHEALTH_EDIT_PATH,
  TELEHEALTH_RX_DELIVERY_TELEHEALTH_VIEW_PATH,
  TELEHEALTH_RX_DELIVERY_TELEHEALTH_CLONE_PATH,
  TELEHEALTH_RX_DELIVERY_RX_DELIVERY_CREATE_PATH,
  TELEHEALTH_RX_DELIVERY_RX_DELIVERY_EDIT_PATH,
  TELEHEALTH_RX_DELIVERY_RX_DELIVERY_VIEW_PATH,
  TELEHEALTH_RX_DELIVERY_RX_DELIVERY_CLONE_PATH,
  TELEHEALTH_RX_DELIVERY_RX_COUPONS_CREATE_PATH,
  TELEHEALTH_RX_DELIVERY_RX_COUPONS_EDIT_PATH,
  TELEHEALTH_RX_DELIVERY_RX_COUPONS_VIEW_PATH,
  TELEHEALTH_RX_DELIVERY_RX_COUPONS_CLONE_PATH
};
