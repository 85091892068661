import React from 'react';
import LoaderIcon from 'react-loader-spinner';

import './Loader.scss';

const Loader = (props) => {
  const { className } = props;
  return (
    <div className={`text-center ${className || ''}`}>
      <LoaderIcon color="#ccc" height="100" width="250" />
      <span className="loading-text">Loading....</span>
    </div>
  );
};

export default Loader;
