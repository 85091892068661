/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_ROW_PER_PAGE_OPTIONS
} from 'components/Paginator/Paginator';
import Searchbar from 'components/Searchbar/Searchbar';
import Loader from 'components/CircularProgress';

import DataTable from './DataTable';

export default class DataTableWithClientPagination extends Component {
  static propTypes = {
    // Columns must be in a format specified here: https://www.npmjs.com/package/react-data-table-component#columns
    columnData: PropTypes.array.isRequired,
    rowData: PropTypes.array,
    // Defaults to first column's selector from columnData
    defaultSortField: PropTypes.string,
    hideSearchBar: PropTypes.bool,
    // Expects (rows, index) => any
    onRowClicked: PropTypes.func,
    // Expects (event) => any
    handleSearch: PropTypes.func,
    //Set page row size
    paginationPerPage: PropTypes.string,
    //Set pagination page options
    paginationRowsPerPageOptions: PropTypes.array
  };

  static defaultProps = {
    hideSearchBar: false,
    onRowClicked: (row, index) => {},
    handleSearch: (event) => {}
  };

  constructor(props) {
    super(props);

    const { columnData, rowData, defaultSortField } = props;

    this.state = {
      // TODO: Replace state's fetching with props' isFetching control the prop from redux object.
      fetching: _.isEmpty(rowData),
      sortField: defaultSortField || columnData.find((v) => v.selector).selector
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { rowData } = this.props;
    if (rowData !== prevProps.rowData && prevState.fetching) {
      this.setState({
        fetching: false
      });
    }
  }

  render() {
    const {
      columnData,
      rowData,
      hideSearchBar,
      onRowClicked,
      handleSearch,
      paginationPerPage,
      paginationRowsPerPageOptions,
      className,
      customStyles,
      fetching: fetchingProps, //Enabled loading based on the props
      persistTableHead = false
    } = this.props;
    const { fetching, sortField } = this.state;
    return (
      <>
        {fetching || fetchingProps ? ( 
          <Loader />
        ) : (
          <>
            {/* Searchbar */}
            {!hideSearchBar && <Searchbar handleSearch={handleSearch} />}
            {/* Table */}
            <DataTable
              columns={columnData}
              data={rowData}
              defaultSortField={sortField}
              onRowClicked={onRowClicked}
              pagination
              paginationPerPage={
                paginationPerPage ? paginationPerPage : DEFAULT_ROWS_PER_PAGE
              }
              paginationRowsPerPageOptions={
                paginationRowsPerPageOptions
                  ? paginationRowsPerPageOptions
                  : DEFAULT_ROW_PER_PAGE_OPTIONS
              }
              className={className}
              customStyles={customStyles}
              persistTableHead={persistTableHead}
            />
          </>
        )}
      </>
    );
  }
}
