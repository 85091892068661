import moment from 'moment';
import momentTz from 'moment-timezone';
import _ from 'lodash';

export const INPUT_DATE_FORMAT_DEFAULT = 'YYYY-MM-DD';
export const MMDDYYYY_SLASHES_FORMAT = 'MM/DD/YYYY';

export const isValidFormat = (date, format) => {
  // true indicates input must strictly match format including delimiters
  return moment(
    date,
    _.defaultTo(format, INPUT_DATE_FORMAT_DEFAULT),
    true
  ).isValid();
};

export const isValidDateRange = (startDate, endDate, format) => {
  const parsedStart = moment(
    startDate,
    _.defaultTo(format, INPUT_DATE_FORMAT_DEFAULT)
  );
  const parsedEnd = moment(
    endDate,
    _.defaultTo(format, INPUT_DATE_FORMAT_DEFAULT)
  );
  return parsedStart.isBefore(parsedEnd);
};

export const getCurrentUnixTime = () => {
  return Math.floor(new Date().getTime() / 1000);
};

export const formatDate = (date, format) => {
  return moment(date).format(format);
};

export const formatDateInPST = (date, format = MMDDYYYY_SLASHES_FORMAT) => {
  return momentTz.tz(date, 'America/Los_Angeles').format(format);
};

export const isBetweenDatesInclusive = (date, startDate, endDate, format) => {
  const parsedDate = moment(
    date,
    _.defaultTo(format, INPUT_DATE_FORMAT_DEFAULT)
  );
  const parsedStart = moment(
    startDate,
    _.defaultTo(format, INPUT_DATE_FORMAT_DEFAULT)
  );
  const parsedEnd = moment(
    endDate,
    _.defaultTo(format, INPUT_DATE_FORMAT_DEFAULT)
  );
  const inBetween = parsedDate.isBetween(parsedStart, parsedEnd, 'days', '[]');
  return inBetween;
};
