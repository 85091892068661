/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import moment from 'moment';
import {
  DataTableWithServerPagination,
  ColumnFilter
} from 'components/DataTables';
import { SyncStatusBadge } from 'components/Badges';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from 'components/Atoms';
import BenAdminResponse from 'modules/Employers/pages/EmployerDetails/containers/BenAdminResponse/BenAdminResponse';

// constants
import { FULL_DATE_FORMAT } from 'constants/dateConstants';
import { STATUS_STATES } from 'modules/Integrations/constants';
import { EMDASH_LITERAL } from 'constants/commonConstants';

import './SyncStatusList.scss';
import {
  BENADMIN_MENU_ACTIONS,
  ADAPTER_NAMES,
  SYNC_TYPE
} from 'modules/BenAdminIntegrations/constants';

export const statusFilters = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'SYNCING', label: 'Syncing' },
  { value: 'SUCCESS', label: 'Success' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'PARTIAL_SUCCESS', label: 'Partial Success' }
];

const customStyles = {
  headCells: {
    inactiveSortStyle: {
      opacity: 1
      // Target the sort icon when inactive
    }
  }
};

class SyncStatusList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        status: []
      },
      isHrisResponseModalOpen: false,
      hrisResponseEmployerId: '',
      isBenAdminModalOpen: false,
      benAdminResponseEmployerId: ''
    };
  }

  componentWillUnmount() {
    clearInterval(this._refreshTimer);
  }

  componentDidMount() {
    this._refreshTimer = setInterval(() => {
      this.reloadList();
    }, 60 * 1000);
  }

  updateFilterValues = (selector) => (values) => {
    let filters = { ...this.state.filters };
    filters[selector] = values;
    this.setState({ filters: filters }, () => {
      this.reloadList();
    });
  };

  reloadList = () => {
    this.syncStatusList.triggerReload();
  };

  resetFilters = () => {
    this.setState(
      {
        filters: {
          status: []
        }
      },
      () => this.reloadList()
    );
  };

  getBenAdminEmployerSyncDetails = (page, size, sort, query) => {
    const { filters } = this.state;
    const { getBenAdminEmployerSyncDetails, employerId } = this.props;
    const benAdminType = ADAPTER_NAMES.FLOCK.code;
    getBenAdminEmployerSyncDetails({
      employerId,
      benAdminType,
      page,
      size,
      sort,
      query,
      filters
    });
  };

  renderActionsCell = (row) => {
    const { syncStatus } = row;
    const { menuClick } = this.props;
    const failedMenuOptions = [
      {
        label: 'View Sync Details',
        action: BENADMIN_MENU_ACTIONS.VIEW_SYNC_DETAILS
      },
      {
        label: 'Download Employee Sync Details',
        syncType: SYNC_TYPE.EMPLOYEE_SYNC,
        action: BENADMIN_MENU_ACTIONS.DOWNLOAD_EMPLOYEE_SYNC_DETAILS
      },
      {
        label: 'Download Enrollment Sync Details',
        syncType: SYNC_TYPE.ENROLLMENT_SYNC,
        action: BENADMIN_MENU_ACTIONS.DOWNLOAD_ENROLLMENT_SYNC_DETAILS
      }
    ];
    let menuOptions = [];

    if (
      syncStatus === STATUS_STATES.FAILED ||
      syncStatus === STATUS_STATES.PARTIAL_SUCCESS ||
      syncStatus === STATUS_STATES.SUCCESS
    ) {
      menuOptions = [...failedMenuOptions];
    }
    if (
      !(
        syncStatus === STATUS_STATES.SYNCING ||
        syncStatus === STATUS_STATES.PENDING
      )
    ) {
      return (
        <UncontrolledDropdown className="actions">
          <DropdownToggle tag="a" className="cursor-pointer" caret>
            Select
          </DropdownToggle>
          <DropdownMenu>
            {row.source === ADAPTER_NAMES.WORKDAY_BENADMIN.code && <DropdownItem 
              onClick={() => {
                this.setState({
                  isBenAdminModalOpen: true,
                  benAdminResponseEmployerId: row.employerId
                });
              }}
              >
              Download BenAdmin Response
            </DropdownItem>}
            {menuOptions.map(({ label, action }, index) => (
              <DropdownItem key={index} onClick={() => menuClick(action, row)}>
                {label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  };

  toggleHrisResponseModal = () => {
    this.setState({
      isHrisResponseModalOpen: !this.state.isHrisResponseModalOpen
    });
  };

  showSyncedTime = (endedTs) => {
    return (
      <div
        data-toggle="tooltip"
        title={moment(endedTs).format(FULL_DATE_FORMAT)}
      >
        {moment(endedTs).format(FULL_DATE_FORMAT)}
      </div>
    );
  };

  millisecondsToMinutesSeconds = (ms) => {
    let duration = moment.duration(ms, 'milliseconds');
    let fromMinutes = Math.floor(duration.asMinutes());
    let fromSeconds = Math.floor(duration.asSeconds() - fromMinutes * 60);

    return Math.floor(duration.asSeconds()) >= 60
      ? (fromMinutes <= 9 ? '0' + fromMinutes : fromMinutes) +
          ':' +
          (fromSeconds <= 9 ? '0' + fromSeconds : fromSeconds)
      : '00:' + (fromSeconds <= 9 ? '0' + fromSeconds : fromSeconds);
  };

  renderSyncDuration(ms, syncStatus) {
    if (
      syncStatus === STATUS_STATES.SYNCING ||
      syncStatus === STATUS_STATES.PENDING
    ) {
      return EMDASH_LITERAL;
    } else {
      return this.millisecondsToMinutesSeconds(ms);
    }
  }

  toggleBenAdminResponseModal = () => {
    this.setState({
      isBenAdminModalOpen: !this.state.isBenAdminModalOpen
    });
  };

  render() {
    const { data } = this.props;
    const { filters } = this.state;

    return (
      <>
        <DataTableWithServerPagination
          onRef={(ref) => (this.syncStatusList = ref)}
          className="list-employer-integrations"
          customStyle={customStyles}
          hideSearchBar
          noDataComponent={
            <div className="no-data-reset">
              <p className="message">No Records to Display</p>
              <Button color="link" onClick={this.resetFilters}>
                Reset
              </Button>
            </div>
          }
          columnData={[
            {
              name: 'From',
              selector: 'source',
              sortable: false,
              grow: 1.5,
              cell: ({ source }) => (source && ADAPTER_NAMES[source].name) || '-'
            },
            {
              name: 'To',
              selector: 'target',
              sortable: false,
              grow: 1.5,
              cell: ({ target }) => ADAPTER_NAMES[target].name || '-'
            },
            {
              name: 'Synced Time',
              selector: 'endedTs',
              sortable: false,
              grow: 2,
              format: (row) => {
                const { endedTs } = row;
                return endedTs ? this.showSyncedTime(endedTs) : EMDASH_LITERAL;
              }
            },
            {
              name: 'Sync Duration (mm:ss)',
              selector: 'durationMs',
              sortable: false,
              grow: 2,
              cell: ({ durationMs, syncStatus }) => {
                return this.renderSyncDuration(durationMs, syncStatus);
              }
            },
            {
              name: (
                <ColumnFilter
                  columnHeader="Status"
                  filters={statusFilters}
                  selectedFilters={filters.status}
                  onFilter={this.updateFilterValues('status')}
                />
              ),
              grow: 3,
              cell: ({ syncStatus }) => {
                return (
                  <div>
                    <SyncStatusBadge syncStatus={syncStatus} />
                  </div>
                );
              }
            },
            {
              name: 'Actions',
              grow: 1,
              ignoreRowClick: true,
              allowOverflow: true,
              cell: (row) => {
                return this.renderActionsCell(row);
              }
            }
          ]}
          fetchData={(page, size, sort, query) =>
            this.getBenAdminEmployerSyncDetails(page, size, sort, query)
          }
          defaultSortField="-createdTs"
          pageData={data !== undefined ? data : { content: [], metadata: {} }}
        />
        <BenAdminResponse
          isOpen={this.state.isBenAdminModalOpen}
          employerId={this.state.benAdminResponseEmployerId}
          toggle={this.toggleBenAdminResponseModal}
        />
      </>
    );
  }
}

export default SyncStatusList;
