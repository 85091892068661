/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { SyncStatusIcon } from 'components/Icons';
import SwitchWithConfirmation from 'components/Buttons/SwitchWithConfirmation';
import { SyncStatus } from 'constants/syncConstants';

import './DailySyncSwitch.scss';

const DAILY_SYNC_DATE_FORMAT = 'M/D/YY @ h:mma';

export default class DailySyncSwitch extends PureComponent {
  static propTypes = {
    message: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
    syncStatus: PropTypes.shape({
      lastSyncedTs: PropTypes.string,
      lastSyncStatus: PropTypes.oneOf(Object.keys(SyncStatus))
    }),
    inProgress: PropTypes.bool
  };

  render() {
    const {
      message,
      enabled,
      disabled,
      syncStatus,
      inProgress,
      toggleEnabled
    } = this.props;

    const { lastSyncStatus, lastSyncedTs } = syncStatus || {};

    return (
      <div className="daily-sync-switch-container">
        <div className="daily-sync-switch">
          <h5>Daily Sync</h5>
          <SwitchWithConfirmation
            value={enabled}
            message={message}
            confirmText={enabled ? 'Disable' : 'Enable'}
            confirmCallback={toggleEnabled}
            disabled={inProgress || disabled}
          />
        </div>
        {syncStatus && (
          <div className="daily-sync-status">
            <SyncStatusIcon syncStatus={lastSyncStatus} />
            <span>
              {lastSyncedTs && (
                <div>
                  {moment(lastSyncedTs)
                    .local()
                    .format(DAILY_SYNC_DATE_FORMAT)}
                </div>
              )}
              <div>
                <b>{getStatusText(lastSyncStatus)}</b>
              </div>
            </span>
          </div>
        )}
      </div>
    );
  }
}

// DailySyncSwitch renders status text differently, so do not use SyncStatus.label
const getStatusText = (syncStatus) => {
  switch (syncStatus) {
    case SyncStatus.PENDING.value:
      return 'pending';
    case SyncStatus.SYNCING.value:
      return 'syncing';
    case SyncStatus.SUCCESS.value:
      return 'synced';
    case SyncStatus.PARTIAL_SUCCESS.value:
      return 'partial';
    default:
      return 'failed';
  }
};
