export const EmployerSource = {
  PLATFORM: { value: 'PLATFORM', label: 'Platform' },
  LUMITY: { value: 'LUMITY', label: 'Lumity' }
};

export const FlockBenAdminURL = 'https://lumity.helloflock.com';

export const PLAN_YEAR_TYPES = {
  CURRENT: 'CURRENT',
  NEXT: 'NEXT',
  LAST: 'LAST'
};
