/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import moment from 'moment';
import {
  DataTableWithServerPagination,
  ColumnFilter
} from 'components/DataTables';
import { permitIf } from 'components/hoc/Permit';
import { DAILY_SYNC_DATE_FORMAT } from 'constants/dateConstants';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'components/Atoms';
// constants
import { INTEGRATIONS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { ERROR_CODE_LABEL_MAP, VENDORS } from 'modules/Integrations/constants';
import { EMDASH_LITERAL } from 'constants/commonConstants';
// styles
import './DialogErrorDetails.scss';

const customStyles = {
  headCells: {
    inactiveSortStyle: {
      opacity: 1
      // Target the sort icon when inactive
    }
  }
};

class DialogErrorDetails extends React.Component {
  static defaultFilterState = {
    filters: {
      code: [],
      type: [],
      source: []
    }
  };
  constructor(props) {
    super(props);
    this.state = {
      ...DialogErrorDetails.defaultFilterState
    };
  }

  componentDidUpdate(prevProps) {
    const { excludeTerminatedEmployees, isOpen } = this.props;
    const {
      excludeTerminatedEmployees: prevExcludeTerminatedEmployees
    } = prevProps;
    if (
      prevExcludeTerminatedEmployees !== excludeTerminatedEmployees &&
      isOpen
    ) {
      this.reloadList();
    }
  }

  updateFilterValues = (selector) => (values) => {
    let filters = { ...this.state.filters };
    filters[selector] = values;
    this.setState({ filters: filters }, () => {
      this.reloadList();
    });
  };

  getErrorList = (page, size, sort, query) => {
    let { filters } = this.state;
    const {
      getErrorList,
      selectedSync,
      employerId,
      excludeTerminatedEmployees
    } = this.props;
    getErrorList({
      syncDetailsId: selectedSync.id,
      employerId,
      page,
      size,
      sort,
      query,
      filters,
      excludeTerminatedEmployees
    });
  };

  reloadList = () => {
    this.errorList.triggerReload();
  };

  resetFilters = () => {
    this.setState(
      {
        ...DialogErrorDetails.defaultFilterState
      },
      () => this.reloadList()
    );
  };

  toggle = () => {
    this.setState({
      ...DialogErrorDetails.defaultFilterState
    });
    this.props.toggle();
  };

  render() {
    const {
      isOpen,
      data,
      selectedSync,
      syncErrorFilters,
      downloadErrorReport,
      excludeTerminatedEmployees,
      updateTerminatedEmployeeSelection
    } = this.props;

    const { filters } = this.state;
    const { employerName = '', endedTs, source: vendor } = selectedSync || {};

    const { errorCode = [], errorType = [], source = [] } =
      syncErrorFilters || {};
    const mappedSources = source.map((src) => {
      return {
        label: VENDORS[src] || src,
        value: src
      };
    });

    let errorCodeFilters = errorCode.map((code) => {
      return {
        label: ERROR_CODE_LABEL_MAP[code] || code,
        value: code
      };
    });

    errorCodeFilters.sort((a, b) => a.label.localeCompare(b.label));

    const SecuredDownloadButton = permitIf(
      Button,
      INTEGRATIONS_FEATURE_ROLE_MAPPING.downloadErrorReport
    );

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.toggle}
        backdrop="static"
        keyboard={false}
        wrapClassName="dialog-hris-error-details"
        className="dialog-hris-error-details__dialog"
        contentClassName="dialog-hris-error-details__content"
        unmountOnClose={true}
      >
        <ModalHeader className="modal-header" toggle={this.toggle}>
          <label>{`${VENDORS[vendor] || vendor} Sync Errors`}</label>
          <p className="text small">
            {endedTs &&
              `${employerName} - ${moment(endedTs).format(
                DAILY_SYNC_DATE_FORMAT
              )}`}
          </p>
        </ModalHeader>
        <ModalBody className="modal-body">
          <DataTableWithServerPagination
            onRef={(ref) => (this.errorList = ref)}
            className="list-hr-integrations"
            customStyle={customStyles}
            hideSearchBar
            noDataComponent={
              <div className="no-data-reset">
                <p className="message">No Records to Display</p>
                <Button color="link" onClick={this.resetFilters}>
                  Reset
                </Button>
              </div>
            }
            columnData={[
              {
                name: 'Employee/System',
                sortable: true,
                grow: 1,
                selector: 'label',
                cell: ({ label }) => {
                  return <span className="error-label">{label}</span>;
                }
              },
              {
                name: 'HRIS Integration ID',
                selector: 'sourceId',
                sortable: true,
                grow: 1.5,
                cell: ({ sourceId }) => {
                  return sourceId ? sourceId : EMDASH_LITERAL;
                }
              },
              {
                name: (
                  <ColumnFilter
                    columnHeader="Type"
                    filters={errorType}
                    selectedFilters={filters.type}
                    onFilter={this.updateFilterValues('type')}
                  />
                ),
                selector: 'errorType',
                sortable: false,
                grow: 1
              },
              {
                name: (
                  <ColumnFilter
                    columnHeader="Source"
                    filters={mappedSources}
                    selectedFilters={filters.source}
                    onFilter={this.updateFilterValues('source')}
                  />
                ),
                grow: 1,
                selector: 'source',
                cell: ({ source }) => {
                  return VENDORS[source] || source;
                }
              },
              {
                name: (
                  <ColumnFilter
                    columnHeader="Error"
                    filters={errorCodeFilters}
                    selectedFilters={filters.code}
                    onFilter={this.updateFilterValues('code')}
                  />
                ),
                grow: 2,
                selector: 'readableError',
                cell: ({ readableError }) => {
                  return (
                    <span className="error-details">
                      {ERROR_CODE_LABEL_MAP[readableError] || readableError}
                    </span>
                  );
                }
              }
            ]}
            fetchData={(page, size, sort, query) => {
              return this.getErrorList(page, size, sort, query);
            }}
            pageData={data}
          />
        </ModalBody>
        <ModalFooter className="modal-footer">
          <div className="buttons-wrapper">
            <Button
              className="form-element close-button"
              color=""
              size="lg"
              onClick={this.toggle}
            >
              Close
            </Button>
            <SecuredDownloadButton
              className="form-element download-button"
              color="primary"
              size="lg"
              onClick={() => downloadErrorReport(selectedSync)}
            >
              Download Data Error Report
            </SecuredDownloadButton>
          </div>
          <div className="options-wrapper">
            <input
              type="checkbox"
              className="check-box"
              onChange={(event) =>
                updateTerminatedEmployeeSelection(event.target.checked)
              }
            />
            <span className="label"> Include Terminated Employees </span>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DialogErrorDetails;
