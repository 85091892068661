/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
export const GET_EMPLOYERS_PAGE_IN_PROGRESS = 'GET_EMPLOYERS_PAGE_IN_PROGRESS';
export const GET_EMPLOYERS_PAGE_COMPLETED = 'GET_EMPLOYERS_PAGE_COMPLETED';
export const GET_EMPLOYERS_PAGE_FAILED = 'GET_EMPLOYERS_PAGE_FAILED';

export const UPDATE_EMPLOYER_IN_PROGRESS = 'UPDATE_EMPLOYER_IN_PROGRESS';
export const UPDATE_EMPLOYER_COMPLETED = 'UPDATE_EMPLOYER_COMPLETED';
export const UPDATE_EMPLOYER_FAILED = 'UPDATE_EMPLOYER_FAILED';

export const ARCHIVE_EMPLOYER_IN_PROGRESS = 'ARCHIVE_EMPLOYER_IN_PROGRESS';
export const ARCHIVE_EMPLOYER_COMPLETED = 'ARCHIVE_EMPLOYER_COMPLETED';
export const ARCHIVE_EMPLOYER_FAILED = 'ARCHIVE_EMPLOYER_FAILED';

export const DELETE_EMPLOYER_IN_PROGRESS = 'DELETE_EMPLOYER_IN_PROGRESS';
export const DELETE_EMPLOYER_COMPLETED = 'DELETE_EMPLOYER_COMPLETED';
export const DELETE_EMPLOYER_FAILED = 'DELETE_EMPLOYER_FAILED';

export const CREATE_EMPLOYER_IN_PROGRESS = 'CREATE_EMPLOYER_IN_PROGRESS';
export const CREATE_EMPLOYER_COMPLETED = 'CREATE_EMPLOYER_COMPLETED';
export const CREATE_EMPLOYER_FAILED = 'CREATE_EMPLOYER_FAILED';

export const PREVIEW_EMPLOYER_IN_PROGRESS = 'PREVIEW_EMPLOYER_IN_PROGRESS';
export const PREVIEW_EMPLOYER_COMPLETED = 'PREVIEW_EMPLOYER_COMPLETED';
export const PREVIEW_EMPLOYER_FAILED = 'PREVIEW_EMPLOYER_FAILED';

export const IMPORT_EMPLOYER_IN_PROGRESS = 'IMPORT_EMPLOYER_IN_PROGRESS';
export const IMPORT_EMPLOYER_COMPLETED = 'IMPORT_EMPLOYER_COMPLETED';
export const IMPORT_EMPLOYER_FAILED = 'IMPORT_EMPLOYER_FAILED';

export const GET_EMPLOYER_NOTIFICATION_CONFIG_IN_PROGRESS =
  'GET_EMPLOYER_NOTIFICATION_CONFIG_IN_PROGRESS';
export const GET_EMPLOYER_NOTIFICATION_CONFIG_COMPLETED =
  'GET_EMPLOYER_NOTIFICATION_CONFIG_COMPLETED';
export const GET_EMPLOYER_NOTIFICATION_CONFIG_FAILED =
  'GET_EMPLOYER_NOTIFICATION_CONFIG_FAILED';

export const UPDATE_EMPLOYER_NOTIFICATION_CONFIG_IN_PROGRESS =
  'UPDATE_EMPLOYER_NOTIFICATION_CONFIG_IN_PROGRESS';
export const UPDATE_EMPLOYER_NOTIFICATION_CONFIG_COMPLETED =
  'UPDATE_EMPLOYER_NOTIFICATION_CONFIG_COMPLETED';
export const UPDATE_EMPLOYER_NOTIFICATION_CONFIG_FAILED =
  'UPDATE_EMPLOYER_NOTIFICATION_CONFIG_FAILED';

export const GET_EMPLOYER_HRIS_RESPONSE_IN_PROGRESS =
  'GET_EMPLOYER_HRIS_RESPONSE_IN_PROGRESS';
export const GET_EMPLOYER_HRIS_RESPONSE_COMPLETED =
  'GET_EMPLOYER_HRIS_RESPONSE_COMPLETED';
export const GET_EMPLOYER_HRIS_RESPONSE_FAILED =
  'GET_EMPLOYER_HRIS_RESPONSE_FAILED';

export const GET_EMPLOYER_BEN_ADMIN_RESPONSE_IN_PROGRESS =
  'GET_EMPLOYER_BEN_ADMIN_RESPONSE_IN_PROGRESS';
export const GET_EMPLOYER_BEN_ADMIN_RESPONSE_COMPLETED =
  'GET_EMPLOYER_BEN_ADMIN_RESPONSE_COMPLETED';
export const GET_EMPLOYER_BEN_ADMIN_RESPONSE_FAILED =
  'GET_EMPLOYER_BEN_ADMIN_RESPONSE_FAILED';  

export const DOWNLOAD_HRIS_RESPONSE_IN_PROGRESS =
  'DOWNLOAD_HRIS_RESPONSE_IN_PROGRESS';
export const DOWNLOAD_HRIS_RESPONSE_SUCCESS = 'DOWNLOAD_HRIS_RESPONSE_SUCCESS';
export const DOWNLOAD_HRIS_RESPONSE_ERROR = 'DOWNLOAD_HRIS_RESPONSE_ERROR';
export const DOWNLOAD_HRIS_RESPONSE_FILE_NAME =
  'DOWNLOAD_HRIS_RESPONSE_FILE_NAME';

export const GET_EMPLOYER_IN_PROGRESS = 'GET_EMPLOYER_IN_PROGRESS';
export const GET_EMPLOYER_COMPLETED = 'GET_EMPLOYER_COMPLETED';
export const GET_EMPLOYER_FAILED = 'GET_EMPLOYER_FAILED';

export const GET_EMPLOYER_INDUSTRIES_IN_PROGRESS = 'GET_EMPLOYER_INDUSTRIES_IN_PROGRESS';
export const GET_EMPLOYER_INDUSTRIES_COMPLETED = 'GET_EMPLOYER_INDUSTRIES_COMPLETED';
export const GET_EMPLOYER_INDUSTRIES_FAILED = 'GET_EMPLOYER_INDUSTRIES_FAILED';
