// Note: The variable REACT_APP_API_BASE gets set via the .env files that we have in the code base root.
// At the build time, the package.json file has multiple build variants (ex. yarn build:development) which sets
// REACT_APP_ENV variable which then loads relevant .env file (ex. .env.development) that sets the environment
// specific environment variables.
//
// In case of local builds which does not specify this, the default would be to point to the development environment as
// defined in .env.development file. Developers should override this by creating a local .env.development.local file
// as explained in the ReadMe.md.
const baseApi = window._serverConfig.apiUrl || process.env.REACT_APP_API_BASE;
const erPortal =
  window._serverConfig.erPortalUrl || process.env.REACT_APP_ER_PORTAL_BASE;
const version = 'v1';

const getPagingQueryString = (page, size, sort, query, type) => {
  const queryStrings = [];
  if (page) {
    queryStrings.push(`_page=${page}`);
  }
  if (size) {
    queryStrings.push(`_size=${size}`);
  }
  if (sort) {
    queryStrings.push(`_sort=${sort}`);
  }
  if (query) {
    queryStrings.push(`search-key=${encodeURIComponent(query)}`);
  }
  if (type) {
    queryStrings.push(`type=${type}`);
  }
  return queryStrings.join('&');
};

const serializeQuery = (queryObj) => {
  var str = [];
  for (var p in queryObj)
    if (queryObj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(queryObj[p]));
    }
  return str.join('&');
};
export const CONTENT_TYPE_HEADER = 'Content-Type';
export const CONTENT_TYPE_MULTIPART_FORM = 'multipart/form-data';

// listenerCallback = (percentCompleted) => any
export const progressNotifier = (listenerCallback) => {
  return (progressEvent) => {
    const { loaded, total } = progressEvent;
    const percentCompleted = Math.round((loaded * 100) / total);
    return listenerCallback(percentCompleted);
  };
};

export const createProgressDispatcher = (dispatch, dispatchType) => {
  return progressNotifier((percentCompleted) =>
    dispatch({
      type: dispatchType,
      payload: percentCompleted
    })
  );
};

// Defines how long debounce functions should wait for search functions, in milliseconds
export const DEBOUNCE_WAIT_TIME_SEARCH = 250;

export default {
  baseApi,
  version,
  getPagingQueryString,
  serializeQuery,
  erPortal
};
