/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import { Row, Col, Label } from 'components/Atoms';
import _ from 'lodash';

import PlanDetailsLine, { DETAIL_PRESENTAGE, UP_TO, TEXT } from './PlanDetailsLine';

import {
  BENEFIT_KIND_DENTAL,
  BENEFIT_KIND_MEDICAL,
  BENEFIT_KIND_VISION,
  BENCHMARK_PLAN_HSA
} from '../../constants';
import './PopulatePlanDetailsModal.scss';

class PopulateBenchmarkPlanDetails extends Component {
  render() {
    const { selectedBenchmarkPlan, benefitCategory, planType } = this.props;

    if (!_.isEmpty(selectedBenchmarkPlan)) {
      const {
        coinsurance,
        deductible,
        oopMax,
        copay,
        rxCost,
        annualMaxBenefits,
        orthodontiaCoinsuarance,
        orthodontiaMaxBenefits,
        copayExam,
        copayMaterials,
        frameAllowance,
        contactAllowance,
        freqOfService
      } = selectedBenchmarkPlan.planDesign;

      const { EE, ES, EC, EF } = selectedBenchmarkPlan.contribution || {};

      const { EE: EE_RATES, ES: ES_RATES, EC: EC_RATES, EF: EF_RATES } =
        selectedBenchmarkPlan.rates || {};

      const { EE: EE_HSA, ES: ES_HSA, EC: EC_HSA, EF: EF_HSA } =
        selectedBenchmarkPlan.hsaContribution || {};

      const { preventitive, basic, major } = selectedBenchmarkPlan.planDesign.coinsurance || {};
      const isHsaType =
        benefitCategory === BENEFIT_KIND_MEDICAL &&
        planType === BENCHMARK_PLAN_HSA;

      return (
        <div className="populate-plan-outer-container">
          <Row className="populate-plan-container">
            <Col className="page-content populate-plan-design-section">
              <Row>
                <Col className="line-item-label section">
                  <Label className="plan-attr-lable-title">Plan Design</Label>
                </Col>
              </Row>
              {benefitCategory === BENEFIT_KIND_MEDICAL && (
                <>
                  <PlanDetailsLine
                    lable="Deductible - Single"
                    value={deductible.single}
                  />
                  <PlanDetailsLine
                    lable="Deductible - Family"
                    value={deductible.family}
                  />
                  <PlanDetailsLine
                    lable="OOP Max Single"
                    value={oopMax.single}
                  />
                  <PlanDetailsLine
                    lable="OOP Max Family"
                    value={oopMax.family}
                  />
                  <PlanDetailsLine
                    lable="Copay Primary"
                    value={copay.primary}
                  />
                  <PlanDetailsLine
                    lable="Copay Specialist"
                    value={copay.specialist}
                  />
                  <PlanDetailsLine
                    lable="Coinsuarance"
                    value={coinsurance}
                    type={DETAIL_PRESENTAGE}
                  />
                  <PlanDetailsLine lable="RX Cost - Tier 1" value={rxCost.t1} />
                  <PlanDetailsLine lable="RX Cost - Tier 2" value={rxCost.t2} />
                  <PlanDetailsLine lable="RX Cost - Tier 3" value={rxCost.t3} />
                  <PlanDetailsLine lable="RX Cost - Tier 4" value={rxCost.t4} />
                </>
              )}
              {benefitCategory === BENEFIT_KIND_VISION && (
                <>
                  <PlanDetailsLine lable="Copay Exam" value={copayExam} />
                  <PlanDetailsLine
                    lable="Copay Materials"
                    value={copayMaterials}
                  />
                  <PlanDetailsLine
                    lable="Frame Allowance"
                    value={frameAllowance}
                    type={UP_TO}
                  />
                  <PlanDetailsLine
                    lable="Contact Allowance"
                    value={contactAllowance}
                    type={UP_TO}
                  />
                  <PlanDetailsLine
                    lable="Freq. of Services"
                    value={freqOfService}
                    type={TEXT}
                  />
                </>
              )}
              {benefitCategory === BENEFIT_KIND_DENTAL && (
                <>
                  <PlanDetailsLine
                    lable="Deductible - Single"
                    value={deductible.single}
                  />
                  <PlanDetailsLine
                    lable="Deductible - Family"
                    value={deductible.family}
                  />
                  <PlanDetailsLine
                    lable="Annual Max Benefits"
                    value={annualMaxBenefits}
                  />
                  <PlanDetailsLine
                    lable="Coinsurance - Preventitive"
                    value={preventitive}
                    type={DETAIL_PRESENTAGE}
                  />
                  <PlanDetailsLine
                    lable="Coinsurance - Basic"
                    value={basic}
                    type={DETAIL_PRESENTAGE}
                  />
                  <PlanDetailsLine
                    lable="Coinsurance - Major"
                    value={major}
                    type={DETAIL_PRESENTAGE}
                  />
                  <PlanDetailsLine
                    lable="Orthodontia Coinsurance"
                    value={orthodontiaCoinsuarance}
                    type={DETAIL_PRESENTAGE}
                  />
                  <PlanDetailsLine
                    lable="Orthodontia Max Benefits"
                    value={orthodontiaMaxBenefits}
                  />
                </>
              )}
            </Col>
            <Col className="current-plan-section-seperator">
              <Row>
                <Col className="line-item-label section">
                  <Label className="plan-attr-lable-title">
                    Employee Cost - Monthly
                  </Label>
                </Col>
              </Row>
              <PlanDetailsLine lable="Employee Only" value={EE} />
              <PlanDetailsLine lable="Employee & Spouse" value={ES} />
              <PlanDetailsLine lable="Employee & Child(ren)" value={EC} />
              <PlanDetailsLine lable="Employee & Family" value={EF} />
              <Row className="current-plan-section-seperator-lower">
                <Col className="line-item-label section">
                  <Label className="plan-attr-lable-title">
                    {isHsaType
                      ? 'Total Cost - Monthly / HSA Contribution'
                      : 'Total Cost - Monthly'}
                  </Label>
                </Col>
              </Row>
              <PlanDetailsLine
                lable="Employee Only"
                value={isHsaType ? `${EE_RATES}/${EE_HSA}` : EE_RATES}
                splitValue={isHsaType}
              />
              <PlanDetailsLine
                lable="Employee & Spouse"
                value={isHsaType ? `${ES_RATES}/${ES_HSA}` : ES_RATES}
                splitValue={isHsaType}
              />
              <PlanDetailsLine
                lable="Employee & Child(ren)"
                value={isHsaType ? `${EC_RATES}/${EC_HSA}` : EC_RATES}
                splitValue={isHsaType}
              />
              <PlanDetailsLine
                lable="Employee & Family"
                value={isHsaType ? `${EF_RATES}/${EF_HSA}` : EF_RATES}
                splitValue={isHsaType}
              />
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div className="no-selected-plan-container">
          <div className="no-plan-msg">
            Please filter a plan to view plan data.
          </div>
        </div>
      );
    }
  }
}

export default PopulateBenchmarkPlanDetails;
