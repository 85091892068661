/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import moment from 'moment';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';

import { Modal, Row, ModalHeader, ModalBody } from 'components/Atoms';
import { getUrlParameter } from 'util/urlUtil';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';

// constants
import { VENDORS } from 'modules/Integrations/constants';
import { DAILY_SYNC_DATE_FORMAT } from 'constants/dateConstants';

// styles
import './DialogErrorDetails.scss';
import DialogEmployeeErrorDetails from './DialogEmployeeErrorDetails';
import DialogEnrollmentErrorDetails from './DialogEnrollmentErrorDetails';

class DialogErrorDetails extends React.Component {
  static defaultFilterState = {
    filters: {
      code: [],
      type: [],
      source: []
    }
  };

  constructor(props) {
    super(props);
    let selectedTab = getUrlParameter('to');

    const tabSelection =
      ['employees_sync_errors', 'enrollments_sync_errors'].indexOf(
        selectedTab
      ) === -1
        ? 'employees_sync_errors'
        : selectedTab;

    this.state = {
      activeTab: tabSelection,
      showErrorDetailsDialog: false,
      selectedSync: null,
      ...DialogErrorDetails.defaultFilterState
    };
  }

  setActiveTab = (tabId) => {
    this.setState({ activeTab: tabId });
  };

  updateFilterValues = (selector) => (values) => {
    let filters = { ...this.state.filters };
    filters[selector] = values;
    this.setState({ filters: filters }, () => {
      this.reloadList();
    });
  };

  getErrorList = (page, size, sort, query) => {
    let { filters } = this.state;
    const { getErrorList, selectedSync, employerId } = this.props;
    getErrorList({
      syncDetailsId: selectedSync.id,
      employerId,
      page,
      size,
      sort,
      query,
      filters
    });
  };

  reloadList = () => {
    this.errorList.triggerReload();
  };

  resetFilters = () => {
    this.setState(
      {
        ...DialogErrorDetails.defaultFilterState
      },
      () => this.reloadList()
    );
  };

  toggle = () => {
    this.setState({
      ...DialogErrorDetails.defaultFilterState
    });
    this.props.toggle();
  };

  resetActiveTab = () => {
    this.setState({ activeTab: 'employees_sync_errors' });
  };

  render() {
    const {
      isOpen,
      syncBenAdminEmployeeErrors,
      syncBenAdminEnrollmentErrors,
      downloadErrorReport,
      selectedSync,
      syncEmployeeDetailsFilters,
      syncEnrollmentDetailsFilters,
      employerId,
      getErrorList
    } = this.props;

    const { activeTab } = this.state;
    const { employerName = '', endedTs, source: vendor } = selectedSync || {};

    const employeeErrorCount =
      syncBenAdminEmployeeErrors && syncBenAdminEmployeeErrors.metadata
        ? syncBenAdminEmployeeErrors.metadata.total
        : 0;
    const enrollmentErrorCount =
      syncBenAdminEnrollmentErrors && syncBenAdminEnrollmentErrors.metadata
        ? syncBenAdminEnrollmentErrors.metadata.total
        : 0;

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.toggle}
        backdrop="static"
        keyboard={false}
        wrapClassName="dialog-error-details"
        className="dialog-error-details__dialog"
        contentClassName="dialog-error-details__content"
        unmountOnClose={true}
        onClosed={this.resetActiveTab}
      >
        <ModalHeader className="modal-header" toggle={this.toggle}>
          <label className="sync-error-header">{`${VENDORS[vendor] ||
            vendor} Sync Details`}</label>
          <p className="text small">
            {endedTs &&
              `${employerName} - ${moment(endedTs).format(
                DAILY_SYNC_DATE_FORMAT
              )}`}
          </p>
        </ModalHeader>
        <ModalBody className="modal-body">
          <ApplicationWrapper>
            <Row className="page-integration-home">
              <Tabs
                className="tab-view"
                defaultTab={activeTab}
                onChange={(tabId) => this.setActiveTab(tabId)}
              >
                <TabList className="tab-list">
                  <Tab tabFor="employees_sync_errors">
                    Employee Sync Details ({employeeErrorCount})
                  </Tab>
                  <Tab tabFor="enrollments_sync_errors">
                    Enrollment Sync Details ({enrollmentErrorCount})
                  </Tab>
                </TabList>
                <TabPanel tabId="employees_sync_errors">
                  <ContentContainer>
                    <DialogEmployeeErrorDetails
                      data={syncBenAdminEmployeeErrors}
                      employerId={employerId}
                      selectedSync={selectedSync}
                      getErrorList={getErrorList}
                      syncErrorFilters={syncEmployeeDetailsFilters}
                      errorCount={employeeErrorCount}
                      toggle={this.toggle}
                      downloadErrorReport={downloadErrorReport}
                    />
                  </ContentContainer>
                </TabPanel>
                <TabPanel tabId="enrollments_sync_errors">
                  <ContentContainer>
                    <DialogEnrollmentErrorDetails
                      data={syncBenAdminEnrollmentErrors}
                      employerId={employerId}
                      selectedSync={selectedSync}
                      getErrorList={getErrorList}
                      syncErrorFilters={syncEnrollmentDetailsFilters}
                      errorCount={enrollmentErrorCount}
                      toggle={this.toggle}
                      downloadErrorReport={downloadErrorReport}
                    />
                  </ContentContainer>
                </TabPanel>
              </Tabs>
            </Row>
          </ApplicationWrapper>
        </ModalBody>
      </Modal>
    );
  }
}

export default DialogErrorDetails;
