/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FullscreenModal from 'components/FullscreenModal';
import PageActionButton from 'components/Buttons';
import Notifications from 'components/Notifications';
import Icon from 'components/Icons';
import './DeleteBenguideModal.scss';

export default class DeleteBenguideModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    benGuide: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    // Expects (benGuideId) => any
    deleteBenguide: PropTypes.func.isRequired,
    inProgress: PropTypes.bool,
    error: PropTypes.object,
    reloadList: PropTypes.func
  };

  componentDidUpdate(prevProps) {
    const { inProgress, error, toggle, benGuide = {}, reloadList } = this.props;
    const { name } = benGuide;

    if (!inProgress && inProgress !== prevProps.inProgress) {
      if (error) {
        Notifications.error(`Failed to delete ${name}.`);
      } else {
        Notifications.success(`Successfully deleted ${name}.`);
        toggle();
        reloadList();
      }
    }
  }

  render() {
    const {
      isOpen,
      toggle,
      benGuide = {},
      deleteBenguide,
      inProgress,
      hasPublishedVersion
    } = this.props;
    if (benGuide) {
      const { name = '', id = '' } = benGuide;
      return (
        <FullscreenModal
          title="Delete BenGuide"
          closeText="cancel"
          isOpen={isOpen}
          toggle={toggle}
          inProgress={inProgress}
        >
          <div className="delete-benguide-modal-wrapper">
            {hasPublishedVersion && (
              <div className="warning">
                Warning: This is a published guide that is available to
                employees
              </div>
            )}
            Are you sure you want to delete {name}?
            <div className="para-spacing">
              Deleting this will remove all of this guide's information. Plan
              information will not be touched, but the guide's URL and all other
              components will be cleared.
            </div>
            <div className="page-actions-row spacing">
              <PageActionButton
                outline
                onClick={toggle}
                className="modal-button"
                disabled={inProgress}
              >
                Cancel
              </PageActionButton>
              <PageActionButton
                onClick={() => deleteBenguide(id)}
                className="modal-button"
                disabled={inProgress}
              >
                {inProgress ? (
                  <>
                    <Icon icon="circle-notch" className="mr-2" />
                    Deleting...
                  </>
                ) : (
                  <>Yes - Delete BenGuide</>
                )}
              </PageActionButton>
            </div>
          </div>
        </FullscreenModal>
      );
    } else {
      return null;
    }
  }
}
