import * as BenchmarkPages from './index';

const BENCHMARKS_LIST_PATH = '/brokers/:brokerId/employers/:employerId/benchmarks';
const BENCHMARKS_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/benchmarks/create/censusInfo';
const BENCHMARKS_CREATE_LANDING_PATH =
  '/brokers/:brokerId/employers/:employerId/benchmarks/create/landing';
const BENCHMARKS_EDIT_CENSUS_PATH =
  '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/edit/censusInfo';
const BENCHMARKS_EDIT_CURRENT_PATH =
  '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/edit/:benefitCategory';
const BENCHMARKS_EDIT_BENCHMARK_PATH =
  '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/edit/:benefitCategory/benchmark';
const BENCHMARKS_CREATE_CURRENT_PATH =
  '/brokers/:brokerId/employers/:employerId/benchmarks/create/:benefitCategory';
const BENCHMARKS_PREVIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/edit/benchmark/publish';
const BENCHMARKS_VIEW_CENSUS_PATH =
  '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/view/censusInfo';
const BENCHMARKS_VIEW_CURRENT_PATH =
  '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/view/:benefitCategory';
const BENCHMARKS_VIEW_BENCHMARK_PATH =
  '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/view/:benefitCategory/benchmark';
const BENCHMARKS_VIEW_PREVIEW_PATH =
  '/brokers/:brokerId/employers/:employerId/benchmarks/:benchmarkId/view/benchmark/publish';

export {
  BENCHMARKS_CREATE_LANDING_PATH,
  BENCHMARKS_LIST_PATH,
  BENCHMARKS_CREATE_PATH,
  BENCHMARKS_EDIT_CENSUS_PATH,
  BENCHMARKS_EDIT_CURRENT_PATH,
  BENCHMARKS_CREATE_CURRENT_PATH,
  BENCHMARKS_EDIT_BENCHMARK_PATH,
  BENCHMARKS_PREVIEW_PATH,
  BENCHMARKS_VIEW_CENSUS_PATH,
  BENCHMARKS_VIEW_CURRENT_PATH,
  BENCHMARKS_VIEW_BENCHMARK_PATH,
  BENCHMARKS_VIEW_PREVIEW_PATH
};
const BenchmarkPathMapping = [
  {
    path: BENCHMARKS_CREATE_LANDING_PATH,
    component: BenchmarkPages.CreateBenchmark
  },
  { path: BENCHMARKS_EDIT_CENSUS_PATH, component: BenchmarkPages.CensusInfo },
  { path: BENCHMARKS_CREATE_PATH, component: BenchmarkPages.CensusInfo },
  {
    path: BENCHMARKS_EDIT_CURRENT_PATH,
    component: BenchmarkPages.CurrentModeling
  },
  {
    path: BENCHMARKS_CREATE_CURRENT_PATH,
    component: BenchmarkPages.CurrentModeling
  },
  {
    path: BENCHMARKS_EDIT_BENCHMARK_PATH,
    component: BenchmarkPages.BenchmarkModeling
  },

  { path: BENCHMARKS_PREVIEW_PATH, component: BenchmarkPages.Preview },
  { path: BENCHMARKS_LIST_PATH, component: BenchmarkPages.BenchmarksList },
  { path: BENCHMARKS_VIEW_CENSUS_PATH, component: BenchmarkPages.CensusInfo },
  {
    path: BENCHMARKS_VIEW_CURRENT_PATH,
    component: BenchmarkPages.CurrentModeling
  },
  {
    path: BENCHMARKS_VIEW_BENCHMARK_PATH,
    component: BenchmarkPages.BenchmarkModeling
  },
  { path: BENCHMARKS_VIEW_PREVIEW_PATH, component: BenchmarkPages.Preview },
];

export default BenchmarkPathMapping;
