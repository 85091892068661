import React from 'react';
import _ from 'lodash';
import { FormGroup, Label, CustomInput, FormFeedback } from 'components/Atoms';

const CheckboxRowFormGroup = (props) => {
  const {
    labelDisplay,
    onChange,
    disabled,
    disabledFields,
    options,
    optionsMap,
    isInvalid,
    feedback
  } = props;
  return (
    <FormGroup>
      <Label>{labelDisplay}</Label>
      <div
        className={`checkbox-row form-control ${isInvalid ? 'is-invalid' : ''}`}
      >
        {options.map(
          optionsMap ||
            ((item) => (
              <CustomInput
                type="checkbox"
                inline
                key={item.name}
                id={item.name}
                label={item.text}
                name={item.name}
                checked={item.value}
                onChange={onChange}
                disabled={
                  disabled ||
                  _.defaultTo(disabledFields, []).includes(item.name)
                }
              />
            ))
        )}
      </div>
      {feedback && (
        <FormFeedback className={isInvalid ? 'is-invalid' : ''}>
          {feedback}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

export default CheckboxRowFormGroup;
