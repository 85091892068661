/**********************************************************************************************************************
 * Copyright (C) 2019-2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/nativeIssue.actionTypes';
import { IssueStatus, IssueType } from '../constants';
import {
  generateEmptyIssueData,
  getDefaultValueForFilter,
  getDefaultValueForFilterLable,
  mapLabelToValue,
  setDefaultValuesForFilterReset
} from '../util';

const initialState = {
  issueListPage: {
    searchQuery: '',
    data: {
      content: [],
      metadata: {}
    },
    isFetching: false,
    error: null
  },
  createIssueStatus: {
    data: {},
    inProgress: false,
    error: null
  },
  createIssuePage: {
    issueData: generateEmptyIssueData(),
    isFetching: false,
    error: null
  },
  createIssueCommentStatus: {
    commentType: null,
    inProgress: false,
    error: null
  },
  issueDetails: {
    data: {},
    caseComments: [],
    inProgress: false,
    error: null
  },
  selectedWatchers: [],
  erAdminsList: {
    data: [],
    inProgress: false,
    error: null
  },
  filters: {
    priority: [],
    status: getDefaultValueForFilter(IssueStatus),
    assignee: [],
    employer: [],
    daysOpen: [],
    lastUpdatedTS: [],
    onErPortal: [],
    issueType: getDefaultValueForFilterLable(IssueType),
    Origin: []
  },
  resolveReopenStatus: {
    inProgress: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ISSUES_PAGE_IN_PROGRESS:
      return {
        ...state,
        issueListPage: {
          ...state.issueListPage,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_ISSUES_PAGE_COMPLETED:
      return {
        ...state,
        issueListPage: {
          ...state.issueListPage,
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_ISSUES_PAGE_FAILED:
      return {
        ...state,
        issueListPage: {
          ...state.issueListPage,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.INITIALIZE_CREATE_ISSUE_PAGE:
      return {
        ...state,
        createIssuePage: {
          ...state.createIssuePage,
          issueData: generateEmptyIssueData()
        }
      };
    case ActionTypes.CREATE_ISSUE_IN_PROGRESS:
      return {
        ...state,
        createIssueStatus: {
          data: {},
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.CREATE_ISSUE_COMPLETED:
      return {
        ...state,
        createIssueStatus: {
          data: action.payload,
          inProgress: false,
          error: null
        },
        createIssuePage: {
          ...state.createIssuePage,
          issueData: generateEmptyIssueData()
        }
      };
    case ActionTypes.CREATE_ISSUE_FAILED:
      return {
        ...state,
        createIssueStatus: {
          data: {},
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.UPDATE_ISSUE_FORM:
      const { name, value } = action.payload;
      return {
        ...state,
        createIssuePage: {
          ...state.createIssuePage,
          issueData: {
            ...state.createIssuePage.issueData,
            [name]: value
          }
        }
      };
    case ActionTypes.ADD_ISSUE_ATTACHMENT:
      return {
        ...state,
        createIssuePage: {
          ...state.createIssuePage,
          issueData: {
            ...state.createIssuePage.issueData,
            attachments: [
              ...state.createIssuePage.issueData.attachments,
              ...action.payload
            ]
          }
        }
      };
    case ActionTypes.REMOVE_ISSUE_ATTACHMENT:
      const { index } = action.payload;
      const updatedAttachments = [
        ...state.createIssuePage.issueData.attachments
      ];
      updatedAttachments.splice(index, 1);
      return {
        ...state,
        createIssuePage: {
          ...state.createIssuePage,
          issueData: {
            ...state.createIssuePage.issueData,
            attachments: updatedAttachments
          }
        }
      };
    case ActionTypes.GET_ISSUE_DETAILS_IN_PROGRESS:
      return {
        ...state,
        issueDetails: {
          data: {},
          caseComments: [],
          inProgress: true,
          error: null
        },
        createIssuePage: {
          ...state.createIssuePage,
          isFetching: true,
          error: null,
          issueData: {
            ...state.createIssuePage.issueData,
            employer: action.payload
          }
        }
      };
    case ActionTypes.UPDATE_ISSUE_IN_PROGRESS:
      return {
        ...state,
        createIssueStatus: {
          data: {},
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_ISSUE_COMPLETED:
      return {
        ...state,
        createIssueStatus: {
          data: action.payload,
          inProgress: false,
          error: null
        },
        createIssuePage: {
          ...state.createIssuePage,
          issueData: generateEmptyIssueData()
        }
      };
    case ActionTypes.UPDATE_ISSUE_FAILED:
      return {
        ...state,
        createIssueStatus: {
          data: {},
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_ISSUE_DETAILS_COMPLETED:
      const {
        customerDescription,
        priority,
        status,
        subject,
        type,
        watchers,
        attachments,
        assigneeId,
        assigneeName,
        id,
        generatedId,
        caseId,
        onErPortal,
        contactEmail,
        origin
      } = action.payload;
      return {
        ...state,
        issueDetails: {
          data: action.payload,
          caseComments: action.payload.caseComments,
          inProgress: false,
          error: null
        },
        selectedWatchers: action.payload.watchers,
        createIssuePage: {
          issueData: {
            ...state.createIssuePage.issueData,
            description: customerDescription,
            priority: priority,
            subject: subject ? subject : '',
            issueType: type,
            uploadedAttachments: attachments,
            status: mapLabelToValue(status, IssueStatus),
            watchers: watchers,
            assignee: assigneeId,
            assigneeName: assigneeName,
            id: id,
            generatedId: generatedId,
            caseId: caseId,
            onErPortal: onErPortal,
            contactEmail: contactEmail,
            origin: origin
          },
          selectedWatchers: action.payload.watchers,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_ISSUE_DETAILS_FAILED:
      return {
        ...state,
        issueDetails: {
          data: {},
          caseComments: [],
          inProgress: false,
          error: action.payload
        },
        createIssuePage: {
          ...state.createIssuePage,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_ER_ADMINS_IN_PROGRESS:
      return {
        ...state,
        erAdminsList: {
          data: [],
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.GET_ER_ADMINS_COMPLETED:
      return {
        ...state,
        erAdminsList: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.GET_ER_ADMINS_FAILED:
      return {
        ...state,
        erAdminsList: {
          data: [],
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.TOGGLE_WATCHER_OPT_IN_PROGRESS:
      return {
        ...state
      };
    case ActionTypes.TOGGLE_WATCHER_OPT_COMPLETED:
      return {
        ...state,
        selectedWatchers: action.payload.watchers
      };
    case ActionTypes.TOGGLE_WATCHER_OPT_FAILED:
      return {
        ...state
      };

    case ActionTypes.CREATE_ISSUE_COMMENT_IN_PROGRESS:
      return {
        ...state,
        createIssueCommentStatus: {
          commentType: action.payload.commentType,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.CREATE_ISSUE_COMMENT_COMPLETED:
      const {
        createdDate: lastModifiedDate,
        createdByName: lastModifiedBy
      } = action.payload;

      return {
        ...state,
        createIssueCommentStatus: {
          commentType: null,
          inProgress: false,
          error: null
        },
        issueDetails: {
          ...state.issueDetails,
          data: {
            ...state.issueDetails.data,
            lastModifiedDate: lastModifiedDate,
            lastModifiedBy: lastModifiedBy
          },
          inProgress: false,
          caseComments: [action.payload, ...state.issueDetails.caseComments]
        }
      };
    case ActionTypes.CREATE_ISSUE_COMMENT_FAILED:
      return {
        ...state,
        createIssueCommentStatus: {
          inProgress: false,
          error: action.payload,
          commentType: null
        }
      };
    case ActionTypes.UPDATE_ISSUE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: setDefaultValuesForFilterReset(
            action.payload.name,
            IssueStatus,
            IssueType,
            action.payload.selected,
            action.payload.isReset
          )
        }
      };
    case ActionTypes.CLEAR_ALL_ISSUE_FILTERS:
      return {
        ...state,
        issueListPage: {
          ...state.issueListPage,
          searchQuery: ''
        },
        filters: {
          ...initialState.filters
        }
      };
    case ActionTypes.RESOLVE_ISSUE_IN_PROGRESS:
      return {
        ...state,
        resolveReopenStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.RESOLVE_ISSUE_COMPLETED:
      return {
        ...state,
        resolveReopenStatus: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.RESOLVE_ISSUE_FAILED:
      return {
        ...state,
        resolveReopenStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.REOPEN_ISSUE_IN_PROGRESS:
      return {
        ...state,
        resolveReopenStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.REOPEN_ISSUE_COMPLETED:
      return {
        ...state,
        resolveReopenStatus: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.REOPEN_ISSUE_FAILED:
      return {
        ...state,
        resolveReopenStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.UPDATE_SEARCH_QUERY:
      return {
        ...state,
        issueListPage: {
          ...state.issueListPage,
          searchQuery: action.payload
        }
      };
    case ActionTypes.GET_ORIGINAL_COMMENT_EMAIL_IN_PROGRESS:
      return {
        ...state,
        originalEmail: {
          ...state.originalEmail,
          isLoading: true,
          email: {},
          error: null
        }
      };
    case ActionTypes.GET_ORIGINAL_COMMENT_EMAIL_COMPLETED:
      return {
        ...state,
        originalEmail: {
          ...state.originalEmail,
          isLoading: false,
          email: action.payload,
          error: null
        }
      };
    case ActionTypes.GET_ORIGINAL_COMMENT_EMAIL_FAILED:
      return {
        ...state,
        originalEmail: {
          ...state.originalEmail,
          isLoading: false,
          email: {},
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
