/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import { generatePath } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import OffersDataTable from './components/OffersDataTable/OffersDataTable';
import TabView from 'components/TabView/TabView';
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row
} from 'components/Atoms';
import NotificationManager from 'components/Notifications';

import {
  listOffersByCategory,
  listOffersToPublish,
  publishOffers,
  updatePlanContributions
} from '../../actions/offerAction';
import { findConfigStatus } from '../../../Renewals/actions/renewalsAction';
import {
  BENEFIT_KIND_DENTAL,
  BENEFIT_KIND_LIFE,
  BENEFIT_KIND_MEDICAL,
  BENEFIT_KIND_VISION
} from './../../constants';
import { MODULE_RENEWALS_ENABLED } from '../../../Renewals/constants';

import './OffersList.scss';
import Util from 'util/apiUtil';
import { OFFER_BUNDLE_CREATE_PATH, OFFER_PLAN_CREATE_PATH } from '../../routes';
import { permitIf } from 'components/hoc/Permit';
import { OFFERS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import DialogFileHistory from './components/DialogFileHistory';

class OffersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      planGroupDropdownOpen: false,
      bundleDropdownOpen: false,
      planDropdownOpen: false,
      category: BENEFIT_KIND_MEDICAL,
      selectedBundlesToPublish: [],
      diabledUploadButton: false,
      selectedTab: BENEFIT_KIND_MEDICAL,
      showFileHistoryDialog: false
    };
  }

  componentDidMount() {
    // Fetch data for all benefit category to show the publish button with all DRAFT offers.
    this.fetchDraftOffers();

    const { match } = this.props;
    const { params } = match;
    const { employerId } = params;
    this.props.getProposalFeatureStatus(employerId, MODULE_RENEWALS_ENABLED);
  }

  fetchDraftOffers() {
    this.fetchDataForCategory(BENEFIT_KIND_MEDICAL);
    this.fetchDataForCategory(BENEFIT_KIND_DENTAL);
    this.fetchDataForCategory(BENEFIT_KIND_VISION);
    this.fetchDataForCategory(BENEFIT_KIND_LIFE);
  }

  componentDidUpdate(prevProps) {
    const { proposalsConfigStatus } = this.props;
    if (
      proposalsConfigStatus !== prevProps.proposalsConfigStatus &&
      proposalsConfigStatus.value === 'true'
    ) {
      this.setState({ diabledUploadButton: true });
    }
  }

  togglePlanGroup = () => {
    this.setState((prevState) => ({
      planGroupDropdownOpen: !prevState.planGroupDropdownOpen
    }));
  };

  toggleBundle = () => {
    this.setState((prevState) => ({
      bundleDropdownOpen: !prevState.bundleDropdownOpen
    }));
  };

  getOffers = (employerId, category) => {
    if (category === this.state.category) {
      this.props.listOffersByCategory(employerId, category);
    }
  };

  fetchDataForCategory = (category) => {
    const { match } = this.props;
    const { params } = match;
    const { employerId } = params;
    this.props.listOffersByCategory(employerId, category);
    this.props.listOffersToPublish(employerId, category);
    this.setState({ selectedBundlesToPublish: [], category: category });
  };

  publish = (event, bundleId) => {
    let { selectedBundlesToPublish } = this.state;
    if (event.target.checked) {
      selectedBundlesToPublish.push(bundleId);
    } else {
      selectedBundlesToPublish = selectedBundlesToPublish.filter(
        (bundle) => bundle !== bundleId
      );
    }
    this.setState({ selectedBundlesToPublish: selectedBundlesToPublish });
  };

  publishAll = (event, offers) => {
    let { selectedBundlesToPublish } = this.state;
    if(event.target.checked){
      offers.map((offer, index) => {
        selectedBundlesToPublish[index] = offer.id;
      })
    } else {
      selectedBundlesToPublish = [];
    }
    this.setState({ selectedBundlesToPublish: selectedBundlesToPublish })
  }

  publishSingleOffer = async (bundleId) => {
    const { category, selectedBundlesToPublish } = this.state;
    const { match } = this.props;
    const { params } = match;
    const { employerId } = params;
    selectedBundlesToPublish.push(bundleId);
    await this.props.publishOffers(this.state.selectedBundlesToPublish);
    NotificationManager.success('Selected Bundle is published');
    this.setState({ selectedBundlesToPublish: [] });
    this.props.listOffersToPublish(employerId, category);
    this.props.listOffersByCategory(employerId, category);
  };

  publishOffers = async () => {
    const { category, selectedBundlesToPublish } = this.state;
    if (selectedBundlesToPublish.length > 0) {
      const { match } = this.props;
      const { params } = match;
      const { employerId } = params;
      await this.props.publishOffers(this.state.selectedBundlesToPublish);
      NotificationManager.success('Selected Bundles are published');
      this.toggleBundle();
      this.setState({ selectedBundlesToPublish: [] });
      this.props.listOffersToPublish(employerId, category);
      this.props.listOffersByCategory(employerId, category);

      this.fetchDraftOffers();
    }
  };

  savePlanContributions = async (bundleId, planContributions, applyAll) => {
    const { category } = this.state;
    const { match } = this.props;
    const { params } = match;
    const { employerId } = params;
    await this.props.updatePlanContributions(
      bundleId,
      planContributions,
      applyAll
    );
    this.props.listOffersByCategory(employerId, category);
  };

  redirectToOfferPreview = (offerId, benefitKind) => {
    const { match } = this.props;
    const { params } = match;
    const { employerId } = params;
    window.open(
      `${
        Util.erPortal
      }/renewals/offers/${benefitKind.toLowerCase()}/${offerId}?employerId=${employerId}`,
      '_blank'
    );
  };
  redirectToOffers = () => {
    const { match } = this.props;
    const { params } = match;
    const { employerId } = params;
    const { selectedTab = '' } = this.state;
    window.open(
      `${
        Util.erPortal
      }/renewals/offers/${selectedTab.toLowerCase()}?employerId=${employerId}`,
      '_blank'
    );
  };
  sortOffers = (offerList = []) => {
    let order = ['CURRENT', 'RENEWAL', 'OTHER'];
    let secOrder = [true, false];
    let sortedBundleList = [];
    sortedBundleList = offerList.sort(function(a, b) {
      return (
        order.indexOf(a.type) - order.indexOf(b.type) ||
        secOrder.indexOf(a.recommended) - secOrder.indexOf(b.recommended)
      );
    });
    return sortedBundleList;
  };
  redirectToCreateBundle = (bundleType) => {
    const { match } = this.props;
    const { params } = match;
    const { employerId, brokerId } = params;
    this.props.history.push(
      generatePath(OFFER_BUNDLE_CREATE_PATH, {
        brokerId,
        employerId: employerId,
        bundleCategory: bundleType,
        mode: 'create'
      })
    );
  };

  goToUploadDataFile = () => {
    const { match } = this.props;
    const { params } = match;
    this.props.history.push(
      generatePath(OFFER_PLAN_CREATE_PATH, { ...params })
    );
  };

  showFileHistoryDialog = () => {
    this.setState({
      showFileHistoryDialog: true
    });
  };

  toggleFileHistoryDialog = () => {
    const { showFileHistoryDialog } = this.state;
    this.setState({
      showFileHistoryDialog: !showFileHistoryDialog
    });
  };

  render() {
    const SecuredDropdownToggle = permitIf(
      DropdownToggle,
      OFFERS_FEATURE_ROLE_MAPPING.common
    );
    const SecuredButton = permitIf(Button, OFFERS_FEATURE_ROLE_MAPPING.common);

    const {
      bundleDropdownOpen,
      diabledUploadButton,
      showFileHistoryDialog,
      selectedBundlesToPublish
    } = this.state;
    const {
      medicalOffers,
      dentalOffers,
      visionOffers,
      lifeOffers,
      medicalOffersToPublish,
      dentalOffersToPublish,
      visionOffersToPublish,
      lifeOffersToPublish,
      match
    } = this.props;
    const { params } = match;
    const { employerId } = params;
    let sortedMedicalOffers = this.sortOffers(medicalOffers);
    let sortedDentalOffers = this.sortOffers(dentalOffers);
    let sortedVisionOffers = this.sortOffers(visionOffers);
    let sortedLifeOffers = this.sortOffers(lifeOffers);

    let allOffers = [];
    allOffers.push(...medicalOffersToPublish);
    allOffers.push(...dentalOffersToPublish);
    allOffers.push(...visionOffersToPublish);
    allOffers.push(...lifeOffersToPublish);

    const medicalContent = (
      <OffersDataTable
        type={BENEFIT_KIND_MEDICAL}
        employerId={employerId}
        offers={sortedMedicalOffers}
        getOffers={this.getOffers}
        savePlanContributions={this.savePlanContributions}
        publishSingleOffer={this.publishSingleOffer}
        redirectToOfferPreview={(offerId) =>
          this.redirectToOfferPreview(offerId, BENEFIT_KIND_MEDICAL)
        }
      />
    );
    const dentalContent = (
      <OffersDataTable
        type={BENEFIT_KIND_DENTAL}
        employerId={employerId}
        offers={sortedDentalOffers}
        getOffers={this.getOffers}
        savePlanContributions={this.savePlanContributions}
        publishSingleOffer={this.publishSingleOffer}
        redirectToOfferPreview={(offerId) =>
          this.redirectToOfferPreview(offerId, BENEFIT_KIND_DENTAL)
        }
      />
    );
    const visionContent = (
      <OffersDataTable
        type={BENEFIT_KIND_VISION}
        employerId={employerId}
        offers={sortedVisionOffers}
        getOffers={this.getOffers}
        savePlanContributions={this.savePlanContributions}
        publishSingleOffer={this.publishSingleOffer}
        redirectToOfferPreview={(offerId) =>
          this.redirectToOfferPreview(offerId, BENEFIT_KIND_VISION)
        }
      />
    );
    const lifeContent = (
      <OffersDataTable
        type={BENEFIT_KIND_LIFE}
        employerId={employerId}
        offers={sortedLifeOffers}
        getOffers={this.getOffers}
        savePlanContributions={this.savePlanContributions}
        publishSingleOffer={this.publishSingleOffer}
        redirectToOfferPreview={(offerId) =>
          this.redirectToOfferPreview(offerId, BENEFIT_KIND_LIFE)
        }
      />
    );
    const pageActions = () => (
      <Row className="float-right proposal-bundles">
        <div key={2} className="mr-3">
          <Button color="primary" size="lg" onClick={this.redirectToOffers}>
            Preview Carriers & Offers Screen
          </Button>
        </div>
        <div key={3} className="mr-3">
          <Dropdown
            isOpen={bundleDropdownOpen}
            toggle={this.toggleBundle}
            size="lg"
          >
            <SecuredDropdownToggle caret color="primary">
              Publish Offers
            </SecuredDropdownToggle>
            <DropdownMenu
              className="bundleDropdown"
              modifiers={{
                computeStyle: {
                  gpuAcceleration: false
                }
              }}
            >
              {
                (!_.isEmpty(medicalOffersToPublish) ||
                 !_.isEmpty(dentalOffersToPublish) ||
                 !_.isEmpty(visionOffersToPublish) ||
                 !_.isEmpty(lifeOffersToPublish)) &&
                <AllOfferToPublishCheckBox
                  title="Select All"
                  offers={allOffers}
                  publish={this.publishAll}
                  selectedBundlesToPublish={selectedBundlesToPublish}
                >
                  <hr />
                </AllOfferToPublishCheckBox>
              }
              {medicalOffersToPublish.length > 0 && (
                <OfferToPublishCheckBox
                  key="medicalOffersToPublish"
                  title="Medical"
                  offers={medicalOffersToPublish}
                  publish={this.publish}
                  selectedBundlesToPublish={selectedBundlesToPublish}
                >
                  <hr />
                </OfferToPublishCheckBox>
              )}
              {dentalOffersToPublish.length > 0 && (
                <OfferToPublishCheckBox
                  key="dentalOffersToPublish"
                  title="Dental"
                  offers={dentalOffersToPublish}
                  publish={this.publish}
                  selectedBundlesToPublish={selectedBundlesToPublish}
                >
                  <hr />
                </OfferToPublishCheckBox>
              )}
              {visionOffersToPublish.length > 0 && (
                <OfferToPublishCheckBox
                  key="visionOffersToPublish"
                  title="Vision"
                  offers={visionOffersToPublish}
                  publish={this.publish}
                  selectedBundlesToPublish={selectedBundlesToPublish}
                >
                  <hr />
                </OfferToPublishCheckBox>
              )}
              {lifeOffersToPublish.length > 0 && (
                <OfferToPublishCheckBox
                  key="lifeOffersToPublish"
                  title="Life"
                  offers={lifeOffersToPublish}
                  publish={this.publish}
                  selectedBundlesToPublish={selectedBundlesToPublish}
                />
              )}
              <div className="dropdown-btn-container">
                <Button
                  onClick={this.publishOffers}
                  color="primary"
                  disabled={
                    _.isEmpty(medicalOffersToPublish) &&
                    _.isEmpty(dentalOffersToPublish) &&
                    _.isEmpty(visionOffersToPublish) &&
                    _.isEmpty(lifeOffersToPublish)
                  }
                >
                  Publish
                </Button>
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>

        <div key={4} className="mr-3">
          <SecuredButton
            color="primary"
            size="lg"
            onClick={(event) => this.goToUploadDataFile(event)}
            disabled={diabledUploadButton}
          >
            {`${_.isEmpty(medicalOffers) ? 'Upload' : 'Reupload'}`} Data File
          </SecuredButton>
        </div>
      </Row>
    );

    return (
      <ApplicationWrapper>
        <HeaderContainer title="Offers" actions={pageActions()} />
        <Row className="float-right">
          {diabledUploadButton && (
            <div key={4}>
              <SecuredButton
                color="link"
                className="file-history-link"
                onClick={(event) => this.showFileHistoryDialog(event)}
              >
                View File History
              </SecuredButton>
            </div>
          )}
        </Row>
        <TabView
          onTabClick={(category) => {
            this.setState({ selectedTab: category });
            this.fetchDataForCategory(category);
          }}
          medicalContent={medicalContent}
          dentalContent={dentalContent}
          visionContent={visionContent}
          lifeContent={lifeContent}
        />
        <DialogFileHistory
          isOpen={showFileHistoryDialog}
          employerId={employerId}
          toggle={this.toggleFileHistoryDialog}
        />
      </ApplicationWrapper>
    );
  }
}

const OfferToPublishCheckBox = ({ title, offers, publish, children, selectedBundlesToPublish }) => {
  return (
    <>
      <div className="text bold">{title}</div>
      {offers.map((offer) => (
        <React.Fragment key={offer.id}>
          <div>
            <input
              type="checkbox"
              onClick={(event) => publish(event, offer.id)}
              checked={selectedBundlesToPublish.includes(offer.id) }
            />
            <span className="dropdown-item-text">{offer.name}</span>
          </div>
        </React.Fragment>
      ))}
      {children && children}
    </>
  );
};

const AllOfferToPublishCheckBox = ({ title, offers, publish, children, selectedBundlesToPublish }) => {
  return (
    <>
      <div>
        <input
          type="checkbox"
          onClick={(event) => publish(event, offers)}
          checked={selectedBundlesToPublish.length === offers.length}
        />
        <span className="text bold dropdown-item-text">{title}</span>
      </div>
      {children && children}
    </>
  );
};

const mapStateToProps = (state) => {
  const { offers, offersToPublish } = state.offersReducer.OfferReducer;
  const { configStatus } = state.renewalsReducer;

  return {
    medicalOffers: offers.medicalOffers,
    dentalOffers: offers.dentalOffers,
    visionOffers: offers.visionOffers,
    lifeOffers: offers.lifeOffers,
    medicalOffersToPublish: offersToPublish.medicalOffersToPublish,
    dentalOffersToPublish: offersToPublish.dentalOffersToPublish,
    visionOffersToPublish: offersToPublish.visionOffersToPublish,
    lifeOffersToPublish: offersToPublish.lifeOffersToPublish,
    proposalsConfigStatus: configStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listOffersByCategory: (employerId, category) =>
      dispatch(listOffersByCategory(employerId, category)),
    listOffersToPublish: (employerId, category) =>
      dispatch(listOffersToPublish(employerId, category)),
    publishOffers: (bundles) => dispatch(publishOffers(bundles)),
    updatePlanContributions: (bundleId, planContributions, applyAll) =>
      dispatch(updatePlanContributions(bundleId, planContributions, applyAll)),
    getProposalFeatureStatus: (employerId, key) =>
      dispatch(findConfigStatus(employerId, key))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersList);
