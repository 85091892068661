/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import {
  MedicalPages,
  DentalPages,
  VisionPages,
  SpendingPages,
  CommuterPages,
  LifePages,
  RetirementPages,
  WellbeingPages,
  WorkLifePages,
  AdditionalPerkPages,
  TaxAdvantagedAccountPages,
  TeleHealthRxDeliveryPages
} from '..';
import * as MedicalRoutes from './medicalRoutes';
import * as DentalRoutes from './dentalRoutes';
import * as VisionRoutes from './visionRoutes';
import * as SpendingRoutes from './spendingRoutes';
import * as CommuterRoutes from './commuterRoutes';
import * as LifeRoutes from './lifeRoutes';
import * as RetirementRoutes from './retirementRoutes';
import * as WellbeingRoutes from './wellbeingRoutes';
import * as WorkLifeRoutes from './workLifeRoutes';
import * as AdditionalPerkRoutes from './additionalPerkRoutes';
import * as TaxAdvantagedRoutes from './taxAdvantagedRouters';
import * as TeleHealthRxDeliveryRoutes from './teleHealthRxDeliveryRoutes';

/** Routes and route mappings are defined separately to avoid circular dependencies. **/
const medicalRouteMapping = [
  {
    path: MedicalRoutes.MEDICAL_LIST_PATH,
    component: MedicalPages.MedicalPlansList
  },
  {
    path: MedicalRoutes.MEDICAL_CREATE_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_EDIT_BASIC_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_EDIT_COVERAGE_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_EDIT_SERVICES_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_EDIT_RX_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_EDIT_RATES_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_VIEW_BASIC_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_VIEW_COVERAGE_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_VIEW_SERVICES_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_VIEW_RX_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_VIEW_RATES_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_CLONE_BASIC_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_CLONE_COVERAGE_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_CLONE_SERVICES_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_CLONE_RX_PATH,
    component: MedicalPages.MedicalPlanStepper
  },
  {
    path: MedicalRoutes.MEDICAL_CLONE_RATES_PATH,
    component: MedicalPages.MedicalPlanStepper
  }
];

const dentalRouteMapping = [
  {
    path: DentalRoutes.DENTAL_LIST_PATH,
    component: DentalPages.DentalPlansList
  },
  {
    path: DentalRoutes.DENTAL_CREATE_PATH,
    component: DentalPages.DentalPlanStepper
  },
  {
    path: DentalRoutes.DENTAL_EDIT_BASIC_PATH,
    component: DentalPages.DentalPlanStepper
  },
  {
    path: DentalRoutes.DENTAL_EDIT_COVERAGE_PATH,
    component: DentalPages.DentalPlanStepper
  },
  {
    path: DentalRoutes.DENTAL_EDIT_SERVICES_PATH,
    component: DentalPages.DentalPlanStepper
  },
  {
    path: DentalRoutes.DENTAL_EDIT_RATES_PATH,
    component: DentalPages.DentalPlanStepper
  },
  {
    path: DentalRoutes.DENTAL_VIEW_BASIC_PATH,
    component: DentalPages.DentalPlanStepper
  },
  {
    path: DentalRoutes.DENTAL_VIEW_COVERAGE_PATH,
    component: DentalPages.DentalPlanStepper
  },
  {
    path: DentalRoutes.DENTAL_VIEW_SERVICES_PATH,
    component: DentalPages.DentalPlanStepper
  },
  {
    path: DentalRoutes.DENTAL_VIEW_RATES_PATH,
    component: DentalPages.DentalPlanStepper
  },
  {
    path: DentalRoutes.DENTAL_CLONE_BASIC_PATH,
    component: DentalPages.DentalPlanStepper
  },
  {
    path: DentalRoutes.DENTAL_CLONE_COVERAGE_PATH,
    component: DentalPages.DentalPlanStepper
  },
  {
    path: DentalRoutes.DENTAL_CLONE_SERVICES_PATH,
    component: DentalPages.DentalPlanStepper
  },
  {
    path: DentalRoutes.DENTAL_CLONE_RATES_PATH,
    component: DentalPages.DentalPlanStepper
  }
];

const visionRouteMapping = [
  {
    path: VisionRoutes.VISION_LIST_PATH,
    component: VisionPages.VisionPlansList
  },
  {
    path: VisionRoutes.VISION_CREATE_PATH,
    component: VisionPages.VisionPlanStepper
  },
  {
    path: VisionRoutes.VISION_EDIT_BASIC_PATH,
    component: VisionPages.VisionPlanStepper
  },
  {
    path: VisionRoutes.VISION_EDIT_SERVICES_PATH,
    component: VisionPages.VisionPlanStepper
  },
  {
    path: VisionRoutes.VISION_EDIT_RATES_PATH,
    component: VisionPages.VisionPlanStepper
  },
  {
    path: VisionRoutes.VISION_VIEW_BASIC_PATH,
    component: VisionPages.VisionPlanStepper
  },
  {
    path: VisionRoutes.VISION_VIEW_SERVICES_PATH,
    component: VisionPages.VisionPlanStepper
  },
  {
    path: VisionRoutes.VISION_VIEW_RATES_PATH,
    component: VisionPages.VisionPlanStepper
  },
  {
    path: VisionRoutes.VISION_CLONE_BASIC_PATH,
    component: VisionPages.VisionPlanStepper
  },
  {
    path: VisionRoutes.VISION_CLONE_SERVICES_PATH,
    component: VisionPages.VisionPlanStepper
  },
  {
    path: VisionRoutes.VISION_CLONE_RATES_PATH,
    component: VisionPages.VisionPlanStepper
  }
];

const spendingRouteMapping = [
  {
    path: SpendingRoutes.SPENDING_LIST_PATH,
    component: SpendingPages.SpendingPlansList
  },
  {
    path: SpendingRoutes.SPENDING_HSA_CREATE_PATH,
    component: SpendingPages.HSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_HSA_EDIT_PATH,
    component: SpendingPages.HSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_HSA_CLONE_BASIC_PATH,
    component: SpendingPages.HSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_MEDICAL_FSA_CREATE_PATH,
    component: SpendingPages.FSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_MEDICAL_FSA_EDIT_PATH,
    component: SpendingPages.FSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_MEDICAL_FSA_CLONE_BASIC_PATH,
    component: SpendingPages.FSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_LPFSA_CREATE_PATH,
    component: SpendingPages.LimitedFSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_LPFSA_EDIT_PATH,
    component: SpendingPages.LimitedFSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_LPFSA_CLONE_BASIC_PATH,
    component: SpendingPages.LimitedFSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_DCAPFSA_CREATE_PATH,
    component: SpendingPages.DCAPFSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_DCAPFSA_EDIT_PATH,
    component: SpendingPages.DCAPFSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_DCAPFSA_CLONE_BASIC_PATH,
    component: SpendingPages.DCAPFSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_HRA_CREATE_PATH,
    component: SpendingPages.HRAPlan
  },
  {
    path: SpendingRoutes.SPENDING_HRA_EDIT_PATH,
    component: SpendingPages.HRAPlan
  },
  {
    path: SpendingRoutes.SPENDING_HRA_CLONE_BASIC_PATH,
    component: SpendingPages.HRAPlan
  },
  {
    path: SpendingRoutes.SPENDING_HSA_VIEW_PATH,
    component: SpendingPages.HSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_MEDICAL_FSA_VIEW_PATH,
    component: SpendingPages.FSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_LPFSA_VIEW_PATH,
    component: SpendingPages.LimitedFSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_DCAPFSA_VIEW_PATH,
    component: SpendingPages.DCAPFSAPlan
  },
  {
    path: SpendingRoutes.SPENDING_HRA_VIEW_PATH,
    component: SpendingPages.HRAPlan
  }
];

const commuterRouteMapping = [
  {
    path: CommuterRoutes.COMMUTER_LIST_PATH,
    component: CommuterPages.CommuterPlansList
  },
  {
    path: CommuterRoutes.COMMUTER_CREATE_PATH,
    component: CommuterPages.CommuterPlan
  },
  {
    path: CommuterRoutes.COMMUTER_EDIT_PATH,
    component: CommuterPages.CommuterPlan
  },
  {
    path: CommuterRoutes.COMMUTER_VIEW_PATH,
    component: CommuterPages.CommuterPlan
  },
  {
    path: CommuterRoutes.COMMUTER_CLONE_PATH,
    component: CommuterPages.CommuterPlan
  }
];

const lifeRouteMapping = [
  {
    path: LifeRoutes.LIFE_LIST_PATH,
    component: LifePages.LifeAndDisabilityPlansList
  },
  {
    path: LifeRoutes.LIFE_BASIC_LIFE_CREATE_PATH,
    component: LifePages.BasicLifePlan
  },
  {
    path: LifeRoutes.LIFE_BASIC_LIFE_EDIT_PATH,
    component: LifePages.BasicLifePlan
  },
  {
    path: LifeRoutes.LIFE_BASIC_LIFE_VIEW_PATH,
    component: LifePages.BasicLifePlan
  },
  {
    path: LifeRoutes.LIFE_BASIC_LIFE_CLONE_PATH,
    component: LifePages.BasicLifePlan
  },
  {
    path: LifeRoutes.LIFE_BASIC_ADD_CREATE_PATH,
    component: LifePages.BasicADDPlan
  },
  {
    path: LifeRoutes.LIFE_BASIC_ADD_EDIT_PATH,
    component: LifePages.BasicADDPlan
  },
  {
    path: LifeRoutes.LIFE_BASIC_ADD_VIEW_PATH,
    component: LifePages.BasicADDPlan
  },
  {
    path: LifeRoutes.LIFE_BASIC_ADD_CLONE_PATH,
    component: LifePages.BasicADDPlan
  },
  {
    path: LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_CREATE_PATH,
    component: LifePages.BasicLifeAndADDPlan
  },
  {
    path: LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_EDIT_PATH,
    component: LifePages.BasicLifeAndADDPlan
  },
  {
    path: LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_VIEW_PATH,
    component: LifePages.BasicLifeAndADDPlan
  },
  {
    path: LifeRoutes.LIFE_BASIC_LIFE_AND_ADD_CLONE_PATH,
    component: LifePages.BasicLifeAndADDPlan
  },
  { path: LifeRoutes.LIFE_STD_CREATE_PATH, component: LifePages.STDPlan },
  { path: LifeRoutes.LIFE_STD_EDIT_PATH, component: LifePages.STDPlan },
  { path: LifeRoutes.LIFE_STD_VIEW_PATH, component: LifePages.STDPlan },
  { path: LifeRoutes.LIFE_STD_CLONE_PATH, component: LifePages.STDPlan },
  { path: LifeRoutes.LIFE_LTD_CREATE_PATH, component: LifePages.LTDPlan },
  { path: LifeRoutes.LIFE_LTD_EDIT_PATH, component: LifePages.LTDPlan },
  { path: LifeRoutes.LIFE_LTD_VIEW_PATH, component: LifePages.LTDPlan },
  { path: LifeRoutes.LIFE_LTD_CLONE_PATH, component: LifePages.LTDPlan },
  {
    path: LifeRoutes.LIFE_VOL_LIFE_CREATE_PATH,
    component: LifePages.VoluntaryLifePlan
  },
  {
    path: LifeRoutes.LIFE_VOL_LIFE_EDIT_PATH,
    component: LifePages.VoluntaryLifePlan
  },
  {
    path: LifeRoutes.LIFE_VOL_LIFE_VIEW_PATH,
    component: LifePages.VoluntaryLifePlan
  },
  {
    path: LifeRoutes.LIFE_VOL_LIFE_CLONE_PATH,
    component: LifePages.VoluntaryLifePlan
  },
  {
    path: LifeRoutes.LIFE_VOL_ADD_CREATE_PATH,
    component: LifePages.VoluntaryADDPlan
  },
  {
    path: LifeRoutes.LIFE_VOL_ADD_EDIT_PATH,
    component: LifePages.VoluntaryADDPlan
  },
  {
    path: LifeRoutes.LIFE_VOL_ADD_VIEW_PATH,
    component: LifePages.VoluntaryADDPlan
  },
  {
    path: LifeRoutes.LIFE_VOL_ADD_CLONE_PATH,
    component: LifePages.VoluntaryADDPlan
  }
];

const retirementRouteMapping = [
  {
    path: RetirementRoutes.RETIREMENT_LIST_PATH,
    component: RetirementPages.RetirementPlansList
  },
  {
    path: RetirementRoutes.RETIREMENT_CREATE_PATH,
    component: RetirementPages.RetirementPlan
  },
  {
    path: RetirementRoutes.RETIREMENT_EDIT_PATH,
    component: RetirementPages.RetirementPlan
  },
  {
    path: RetirementRoutes.RETIREMENT_VIEW_PATH,
    component: RetirementPages.RetirementPlan
  },
  {
    path: RetirementRoutes.RETIREMENT_CLONE_PATH,
    component: RetirementPages.RetirementPlan
  }
];

const teleHealthRxDeliveryMapping = [
  {
    path: TeleHealthRxDeliveryRoutes.TELEHEALTH_RX_DELIVERY_LIST_PATH,
    component: TeleHealthRxDeliveryPages.TeleHealthRxDeliveryPlansList
  },
  {
    path:
      TeleHealthRxDeliveryRoutes.TELEHEALTH_RX_DELIVERY_TELEHEALTH_CREATE_PATH,
    component: TeleHealthRxDeliveryPages.TelehealthPlan
  },
  {
    path:
      TeleHealthRxDeliveryRoutes.TELEHEALTH_RX_DELIVERY_TELEHEALTH_EDIT_PATH,
    component: TeleHealthRxDeliveryPages.TelehealthPlan
  },
  {
    path:
      TeleHealthRxDeliveryRoutes.TELEHEALTH_RX_DELIVERY_TELEHEALTH_VIEW_PATH,
    component: TeleHealthRxDeliveryPages.TelehealthPlan
  },
  {
    path:
      TeleHealthRxDeliveryRoutes.TELEHEALTH_RX_DELIVERY_TELEHEALTH_CLONE_PATH,
    component: TeleHealthRxDeliveryPages.TelehealthPlan
  },
  {
    path:
      TeleHealthRxDeliveryRoutes.TELEHEALTH_RX_DELIVERY_RX_DELIVERY_CREATE_PATH,
    component: TeleHealthRxDeliveryPages.RxDeliveryPlan
  },
  {
    path:
      TeleHealthRxDeliveryRoutes.TELEHEALTH_RX_DELIVERY_RX_DELIVERY_EDIT_PATH,
    component: TeleHealthRxDeliveryPages.RxDeliveryPlan
  },
  {
    path:
      TeleHealthRxDeliveryRoutes.TELEHEALTH_RX_DELIVERY_RX_DELIVERY_VIEW_PATH,
    component: TeleHealthRxDeliveryPages.RxDeliveryPlan
  },
  {
    path:
      TeleHealthRxDeliveryRoutes.TELEHEALTH_RX_DELIVERY_RX_DELIVERY_CLONE_PATH,
    component: TeleHealthRxDeliveryPages.RxDeliveryPlan
  },
  {
    path:
      TeleHealthRxDeliveryRoutes.TELEHEALTH_RX_DELIVERY_RX_COUPONS_CREATE_PATH,
    component: TeleHealthRxDeliveryPages.RxCouponsPlan
  },
  {
    path:
      TeleHealthRxDeliveryRoutes.TELEHEALTH_RX_DELIVERY_RX_COUPONS_EDIT_PATH,
    component: TeleHealthRxDeliveryPages.RxCouponsPlan
  },
  {
    path:
      TeleHealthRxDeliveryRoutes.TELEHEALTH_RX_DELIVERY_RX_COUPONS_VIEW_PATH,
    component: TeleHealthRxDeliveryPages.RxCouponsPlan
  },
  {
    path:
      TeleHealthRxDeliveryRoutes.TELEHEALTH_RX_DELIVERY_RX_COUPONS_CLONE_PATH,
    component: TeleHealthRxDeliveryPages.RxCouponsPlan
  }
];

const wellbeingRouteMapping = [
  {
    path: WellbeingRoutes.WELLBEING_LIST_PATH,
    component: WellbeingPages.WellbeingPlansList
  },
  {
    path: WellbeingRoutes.WELLBEING_MEDITATION_CREATE_PATH,
    component: WellbeingPages.MeditationPlan
  },
  {
    path: WellbeingRoutes.WELLBEING_MEDITATION_EDIT_PATH,
    component: WellbeingPages.MeditationPlan
  },
  {
    path: WellbeingRoutes.WELLBEING_MEDITATION_CLONE_PATH,
    component: WellbeingPages.MeditationPlan
  },
  {
    path: WellbeingRoutes.WELLBEING_MEDITATION_VIEW_PATH,
    component: WellbeingPages.MeditationPlan
  },
  {
    path: WellbeingRoutes.WELLBEING_MEDITATION_CLONE_PATH,
    component: WellbeingPages.MeditationPlan
  },
  {
    path: WellbeingRoutes.WELLBEING_MENTAL_HEALTH_CREATE_PATH,
    component: WellbeingPages.MentalHealthPlan
  },
  {
    path: WellbeingRoutes.WELLBEING_MENTAL_HEALTH_EDIT_PATH,
    component: WellbeingPages.MentalHealthPlan
  },
  {
    path: WellbeingRoutes.WELLBEING_MENTAL_HEALTH_VIEW_PATH,
    component: WellbeingPages.MentalHealthPlan
  },
  {
    path: WellbeingRoutes.WELLBEING_MENTAL_HEALTH_CLONE_PATH,
    component: WellbeingPages.MentalHealthPlan
  },
  {
    path: WellbeingRoutes.WELLBEING_WELLNESS_REIMBURSEMENT_CREATE_PATH,
    component: WellbeingPages.WellnessReimbursementhPlan
  },
  {
    path: WellbeingRoutes.WELLBEING_WELLNESS_REIMBURSEMENT_EDIT_PATH,
    component: WellbeingPages.WellnessReimbursementhPlan
  },
  {
    path: WellbeingRoutes.WELLBEING_WELLNESS_REIMBURSEMENT_VIEW_PATH,
    component: WellbeingPages.WellnessReimbursementhPlan
  },
  {
    path: WellbeingRoutes.WELLBEING_WELLNESS_REIMBURSEMENT_CLONE_PATH,
    component: WellbeingPages.WellnessReimbursementhPlan
  }
];

const workLifeRouteMapping = [
  {
    path: WorkLifeRoutes.WORK_LIFE_LIST_PATH,
    component: WorkLifePages.WorkLifePlansList
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_BACKUP_CHILDCARE_CREATE_PATH,
    component: WorkLifePages.BackupChildcarePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_BACKUP_CHILDCARE_EDIT_PATH,
    component: WorkLifePages.BackupChildcarePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_BACKUP_CHILDCARE_VIEW_PATH,
    component: WorkLifePages.BackupChildcarePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_BACKUP_CHILDCARE_CLONE_PATH,
    component: WorkLifePages.BackupChildcarePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_CREATE_PATH,
    component: WorkLifePages.BereavementWorkLifePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_EDIT_PATH,
    component: WorkLifePages.BereavementWorkLifePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_VIEW_PATH,
    component: WorkLifePages.BereavementWorkLifePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_BEREAVEMENT_CLONE_PATH,
    component: WorkLifePages.BereavementWorkLifePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_FERTILITY_CREATE_PATH,
    component: WorkLifePages.FertilityWorkLifePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_FERTILITY_EDIT_PATH,
    component: WorkLifePages.FertilityWorkLifePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_FERTILITY_VIEW_PATH,
    component: WorkLifePages.FertilityWorkLifePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_FERTILITY_CLONE_PATH,
    component: WorkLifePages.FertilityWorkLifePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_PARENTAL_LEAVE_CREATE_PATH,
    component: WorkLifePages.ParentalLeavePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_PARENTAL_LEAVE_EDIT_PATH,
    component: WorkLifePages.ParentalLeavePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_PARENTAL_LEAVE_VIEW_PATH,
    component: WorkLifePages.ParentalLeavePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_PARENTAL_LEAVE_CLONE_PATH,
    component: WorkLifePages.ParentalLeavePlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_FLEXIBLE_WORKING_HOURS_CREATE_PATH,
    component: WorkLifePages.FlexibleWorkingHoursPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_FLEXIBLE_WORKING_HOURS_EDIT_PATH,
    component: WorkLifePages.FlexibleWorkingHoursPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_FLEXIBLE_WORKING_HOURS_VIEW_PATH,
    component: WorkLifePages.FlexibleWorkingHoursPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_FLEXIBLE_WORKING_HOURS_CLONE_PATH,
    component: WorkLifePages.FlexibleWorkingHoursPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_HOLIDAY_CREATE_PATH,
    component: WorkLifePages.HolidayPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_HOLIDAY_EDIT_PATH,
    component: WorkLifePages.HolidayPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_HOLIDAY_VIEW_PATH,
    component: WorkLifePages.HolidayPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_HOLIDAY_CLONE_PATH,
    component: WorkLifePages.HolidayPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_PTO_CREATE_PATH,
    component: WorkLifePages.PaidTimeOffPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_PTO_EDIT_PATH,
    component: WorkLifePages.PaidTimeOffPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_PTO_VIEW_PATH,
    component: WorkLifePages.PaidTimeOffPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_PTO_CLONE_PATH,
    component: WorkLifePages.PaidTimeOffPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_SICK_CREATE_PATH,
    component: WorkLifePages.SickPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_SICK_EDIT_PATH,
    component: WorkLifePages.SickPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_SICK_VIEW_PATH,
    component: WorkLifePages.SickPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_SICK_CLONE_PATH,
    component: WorkLifePages.SickPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_CLUBS_CREATE_PATH,
    component: WorkLifePages.ClubsPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_CLUBS_EDIT_PATH,
    component: WorkLifePages.ClubsPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_CLUBS_VIEW_PATH,
    component: WorkLifePages.ClubsPlan
  },
  {
    path: WorkLifeRoutes.WORK_LIFE_CLUBS_CLONE_PATH,
    component: WorkLifePages.ClubsPlan
  }
];

const additionalPerkRouteMapping = [
  {
    path: AdditionalPerkRoutes.ADDITIONAL_PERK_LIST_PATH,
    component: AdditionalPerkPages.AdditionalPerkPlanList
  },
  {
    path: AdditionalPerkRoutes.ADDITIONAL_PERK_CREATE_PATH,
    component: AdditionalPerkPages.AdditionalPerkPlan
  },
  {
    path: AdditionalPerkRoutes.ADDITIONAL_PERK_EDIT_PATH,
    component: AdditionalPerkPages.AdditionalPerkPlan
  },
  {
    path: AdditionalPerkRoutes.ADDITIONAL_PERK_VIEW_PATH,
    component: AdditionalPerkPages.AdditionalPerkPlan
  },
  {
    path: AdditionalPerkRoutes.ADDITIONAL_PERK_CLONE_PATH,
    component: AdditionalPerkPages.AdditionalPerkPlan
  }
];
const taxAdtantagedMapping = [
  {
    path: TaxAdvantagedRoutes.TAX_ADVANTAGED_ACCOUNT_PATH,
    component: TaxAdvantagedAccountPages.AdvantagedAccount
  }
];

export default [
  ...medicalRouteMapping,
  ...dentalRouteMapping,
  ...visionRouteMapping,
  ...spendingRouteMapping,
  ...commuterRouteMapping,
  ...lifeRouteMapping,
  ...retirementRouteMapping,
  ...wellbeingRouteMapping,
  ...workLifeRouteMapping,
  ...additionalPerkRouteMapping,
  ...taxAdtantagedMapping,
  ...teleHealthRxDeliveryMapping
];
export {
  MedicalRoutes,
  DentalRoutes,
  VisionRoutes,
  SpendingRoutes,
  CommuterRoutes,
  LifeRoutes,
  RetirementRoutes,
  WellbeingRoutes,
  WorkLifeRoutes,
  AdditionalPerkRoutes,
  TaxAdvantagedRoutes,
  TeleHealthRxDeliveryRoutes
};
