/**********************************************************************************************************************
 * Copyright (C) 2019-2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import * as _ from 'lodash';

import * as SuAdminsRoutes from 'modules/Admins/suRoutes';
import * as BrokerRoutes from 'modules/Organisations/routes';

import * as roleMappings from '../modules/app-base/constants/roleFeaturesMap';

import { NavigationTree } from 'routes/NavigationTree';

export const NavigationTreePlatformAdmin = (contextObjects) => {
  const { organizationContext } = contextObjects;
  const { name: orgName = 'N/A', id: orgId } = organizationContext || {};

  return [
    {
      display: 'Admins',
      linkTo: SuAdminsRoutes.PLATFORM_ADMINS_USERS_LIST_PATH,
      routes: [],
      roles: roleMappings.PLATFORM_ADMINS_FEATURE_ROLE_MAPPING.navigation,
      ownership: roleMappings.PLATFORM_ADMINS_FEATURE_ROLE_MAPPING.ownership,
      className: 'nav-0-item',
      subTrees: {
        '/su/admins': [
          {
            display: 'Users',
            linkTo: SuAdminsRoutes.PLATFORM_ADMINS_USERS_LIST_PATH,
            routes: [
              SuAdminsRoutes.PLATFORM_ADMINS_USERS_LIST_PATH,
              SuAdminsRoutes.PLATFORM_ADMINS_USER_DETAILS_PATH
            ],
            roles: roleMappings.PLATFORM_ADMINS_FEATURE_ROLE_MAPPING.navigation,
            ownership:
              roleMappings.PLATFORM_ADMINS_FEATURE_ROLE_MAPPING.ownership
          },
          {
            display: 'Roles',
            linkTo: SuAdminsRoutes.PLATFORM_ADMINS_ROLES_LIST_PATH,
            routes: [
              SuAdminsRoutes.PLATFORM_ADMINS_ROLES_LIST_PATH,
              SuAdminsRoutes.PLATFORM_ADMINS_USERS_LIST_FOR_ROLE_PATH
            ],
            roles: roleMappings.PLATFORM_ADMINS_FEATURE_ROLE_MAPPING.navigation,
            ownership:
              roleMappings.PLATFORM_ADMINS_FEATURE_ROLE_MAPPING.ownership
          },
          {
            display: 'Mobile',
            linkTo: SuAdminsRoutes.PLATFORM_ADMIN_MOBILE_VERSIONS_PATH,
            routes: [SuAdminsRoutes.PLATFORM_ADMIN_MOBILE_VERSIONS_PATH],
            roles: roleMappings.PLATFORM_ADMINS_FEATURE_ROLE_MAPPING.navigation,
            ownership:
              roleMappings.PLATFORM_ADMINS_FEATURE_ROLE_MAPPING.ownership
          }
        ]
      }
    },
    {
      display: 'Brokers',
      linkTo: BrokerRoutes.BROKERS_LIST_PATH,
      routes: [BrokerRoutes.ROOT_PATH, BrokerRoutes.BROKERS_LIST_PATH],
      roles: roleMappings.ORGANIZATION_FEATURE_ROLE_MAPPING.navigation,
      ownership: roleMappings.ORGANIZATION_FEATURE_ROLE_MAPPING.ownership,
      className: 'nav-0-item',
      subTrees: {
        [BrokerRoutes.BROKER_CREATE_PATH]: [
          {
            display: 'Add Broker',
            linkTo: BrokerRoutes.BROKER_CREATE_PATH,
            routes: [BrokerRoutes.BROKER_CREATE_PATH],
            roles: roleMappings.ORGANIZATION_FEATURE_ROLE_MAPPING.common
          }
        ],
        [BrokerRoutes.BROKER_EDIT_PATH]: [
          {
            display: 'Edit Broker',
            linkTo: BrokerRoutes.BROKER_EDIT_PATH,
            routes: [BrokerRoutes.BROKER_EDIT_PATH],
            roles: roleMappings.ORGANIZATION_FEATURE_ROLE_MAPPING.common
          }
        ],
        [BrokerRoutes.BROKER_VIEW_PATH]: [
          {
            display: 'View Broker',
            linkTo: BrokerRoutes.BROKER_VIEW_PATH,
            routes: [BrokerRoutes.BROKER_VIEW_PATH],
            roles: roleMappings.ORGANIZATION_FEATURE_ROLE_MAPPING.common
          }
        ],
        '/brokers/:brokerId': [
          {
            display: <div className="nav-devider"></div>,
            roles: roleMappings.ORGANIZATION_FEATURE_ROLE_MAPPING.navigation,
            ownership: roleMappings.ORGANIZATION_FEATURE_ROLE_MAPPING.ownership,
            className: 'nav-0-devider'
          },
          {
            display: <div className="nav-text-broker-name">{orgName}</div>,
            roles: roleMappings.PLATFORM_ADMINS_FEATURE_ROLE_MAPPING.navigation,
            ownership:
              roleMappings.PLATFORM_ADMINS_FEATURE_ROLE_MAPPING.ownership,
            className: 'nav-0-item'
          }
        ].concat(NavigationTree(contextObjects))
      }
    }
  ];
};
