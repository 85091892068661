/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const GET_ALL_HRIS_SYNC_DETAILS = 'GET_ALL_HRIS_SYNC_DETAILS';
export const GET_ALL_HRIS_SYNC_DETAILS_SUCCESS =
  'GET_ALL_HRIS_SYNC_DETAILS_SUCCESS';
export const GET_ALL_HRIS_SYNC_DETAILS_ERROR =
  'GET_ALL_HRIS_SYNC_DETAILS_ERROR';

export const GET_ALL_BENADMIN_SYNC_DETAILS = 'GET_ALL_BENADMIN_SYNC_DETAILS';
export const GET_ALL_BENADMIN_SYNC_DETAILS_SUCCESS =
  'GET_ALL_BENADMIN_SYNC_DETAILS_SUCCESS';
export const GET_ALL_BENADMIN_SYNC_DETAILS_ERROR =
  'GET_ALL_BENADMIN_SYNC_DETAILS_ERROR';

export const START_ALL_SYNC = 'START_ALL_SYNC';
export const START_ALL_SYNC_SUCCESS = 'START_ALL_SYNC_SUCCESS';
export const START_ALL_SYNC_ERROR = 'START_ALL_SYNC_ERROR';

export const START_ALL_BENADMIN_SYNC = 'START_ALL_BENADMIN_SYNC';
export const START_ALL_BENADMIN_SYNC_SUCCESS =
  'START_ALL_BENADMIN_SYNC_SUCCESS';
export const START_ALL_BENADMIN_SYNC_ERROR = 'START_ALL_BENADMIN_SYNC_ERROR';

export const GET_EMPLOYER_INTEGRATION_DETAILS =
  'GET_EMPLOYER_INTEGRATION_DETAILS';
export const GET_EMPLOYER_INTEGRATION_DETAILS_SUCCESS =
  'GET_EMPLOYER_INTEGRATION_DETAILS_SUCCESS';
export const GET_EMPLOYER_INTEGRATION_DETAILS_ERROR =
  'GET_EMPLOYER_INTEGRATION_DETAILS_ERROR';

export const GET_BAMBOO_HR_HRIS_FIELDS = 'GET_BAMBOO_HR_HRIS_FIELDS';
export const GET_BAMBOO_HR_HRIS_FIELDS_SUCCESS =
  'GET_BAMBOO_HR_HRIS_FIELDS_SUCCESS';
export const GET_BAMBOO_HR_HRIS_FIELDS_ERROR =
  'GET_BAMBOO_HR_HRIS_FIELDS_ERROR';

export const GET_BHR_SETUP_DETAILS = 'GET_BHR_SETUP_DETAILS';
export const GET_BHR_SETUP_DETAILS_SUCCESS = 'GET_BHR_SETUP_DETAILS_SUCCESS';
export const GET_BHR_SETUP_DETAILS_ERROR = 'GET_BHR_SETUP_DETAILS_ERROR';

export const GET_INTEGRATIONS_FEATURE_CONF = 'GET_INTEGRATIONS_FEATURE_CONF';
export const GET_INTEGRATIONS_FEATURE_CONF_SUCCESS =
  'GET_INTEGRATIONS_FEATURE_CONF_SUCCESS';
export const GET_INTEGRATIONS_FEATURE_CONF_ERROR =
  'GET_INTEGRATIONS_FEATURE_CONF_ERROR';

export const UPDATE_INTEGRATIONS_FEATURE_CONF =
  'UPDATE_INTEGRATIONS_FEATURE_CONF';
export const UPDATE_INTEGRATIONS_FEATURE_CONF_SUCCESS =
  'UPDATE_INTEGRATIONS_FEATURE_CONF_SUCCESS';
export const UPDATE_INTEGRATIONS_FEATURE_CONF_ERROR =
  'UPDATE_INTEGRATIONS_FEATURE_CONF_ERROR';

export const GET_EMPLOYER_SYNC_DETAILS = 'GET_EMPLOYER_SYNC_DETAILS';
export const GET_EMPLOYER_SYNC_DETAILS_SUCCESS =
  'GET_EMPLOYER_SYNC_DETAILS_SUCCESS';
export const GET_EMPLOYER_SYNC_DETAILS_ERROR =
  'GET_EMPLOYER_SYNC_DETAILS_ERROR';

export const SAVE_BAMBOO_HRIS_CONFIG = 'SAVE_BAMBOO_HRIS_CONFIG';
export const SAVE_BAMBOO_HRIS_CONFIG_SUCCESS =
  'SAVE_BAMBOO_HRIS_CONFIG_SUCCESS';
export const SAVE_BAMBOO_HRIS_CONFIG_ERROR = 'SAVE_BAMBOO_HRIS_CONFIG_ERROR';

export const UPDATE_BAMBOO_HRIS_CONFIG = 'UPDATE_BAMBOO_HRIS_CONFIG';
export const UPDATE_BAMBOO_HRIS_CONFIG_SUCCESS =
  'UPDATE_BAMBOO_HRIS_CONFIG_SUCCESS';
export const UPDATE_BAMBOO_HRIS_CONFIG_ERROR =
  'UPDATE_BAMBOO_HRIS_CONFIG_ERROR';

export const DELETE_BAMBOO_HRIS_CONFIG = 'DELETE_BAMBOO_HRIS_CONFIG';
export const DELETE_BAMBOO_HRIS_CONFIG_SUCCESS =
  'DELETE_BAMBOO_HRIS_CONFIG_SUCCESS';
export const DELETE_BAMBOO_HRIS_CONFIG_ERROR =
  'DELETE_BAMBOO_HRIS_CONFIG_ERROR';

export const GET_BAMBOO_HR_CONFIG_DETAILS = 'GET_BAMBOO_HR_CONFIG_DETAILS';
export const GET_BAMBOO_HR_CONFIG_DETAILS_SUCCESS =
  'GET_BAMBOO_HR_CONFIG_DETAILS_SUCCESS';
export const GET_BAMBOO_HR_CONFIG_DETAILS_ERROR =
  'GET_BAMBOO_HR_CONFIG_DETAILS_ERROR';

export const START_EMPLOYER_SYNC = 'START_EMPLOYER_SYNC';
export const START_EMPLOYER_SYNC_SUCCESS = 'START_EMPLOYER_SYNC_SUCCESS';
export const START_EMPLOYER_SYNC_ERROR = 'START_EMPLOYER_SYNC_DETAILS_ERROR';

export const GET_LAST_SYNC_STATUS = 'GET_LAST_SYNC_STATUS';
export const GET_LAST_SYNC_STATUS_SUCCESS = 'GET_LAST_SYNC_STATUS_SUCCESS';
export const GET_LAST_SYNC_STATUS_ERROR = 'GET_LAST_SYNC_STATUS_DETAILS_ERROR';

export const TOGGLE_DAILY_SYNC = 'TOGGLE_DAILY_SYNC';
export const TOGGLE_DAILY_SYNC_SUCCESS = 'TOGGLE_DAILY_SYNC_SUCCESS';
export const TOGGLE_DAILY_SYNC_ERROR = 'TOGGLE_DAILY_SYNC_ERROR';

export const GET_SYNC_ERROR_FILERS = 'GET_SYNC_ERROR_FILERS';
export const GET_SYNC_ERROR_FILERS_SUCCESS = 'GET_SYNC_ERROR_FILERS_SUCCESS';
export const GET_SYNC_ERROR_FILERS_ERROR = 'GET_SYNC_ERROR_FILERS_ERROR';

export const GET_ERROR_LIST = 'GET_ERROR_LIST';
export const GET_ERROR_LIST_SUCCESS = 'GET_ERROR_LIST_SUCCESS';
export const GET_ERROR_LIST_ERROR = 'GET_ERROR_LIST_ERROR';

export const DOWNLOAD_ERROR_REPORT = 'DOWNLOAD_ERROR_REPORT';
export const DOWNLOAD_ERROR_REPORT_SUCCESS = 'DOWNLOAD_ERROR_REPORT_SUCCESS';
export const DOWNLOAD_ERROR_REPORT_ERROR = 'DOWNLOAD_ERROR_REPORT_ERROR';

// File Import Action Constants
export const GET_LFI_SETUP_DETAILS = 'GET_LFI_SETUP_DETAILS';
export const GET_LFI_SETUP_DETAILS_SUCCESS = 'GET_LFI_SETUP_DETAILS_SUCCESS';
export const GET_LFI_SETUP_DETAILS_ERROR = 'GET_LFI_SETUP_DETAILS_ERROR';

export const SAVE_LFI_HRIS_CONFIG = 'SAVE_LFI_HRIS_CONFIG';
export const SAVE_LFI_HRIS_CONFIG_SUCCESS = 'SAVE_LFI_HRIS_CONFIG_SUCCESS';
export const SAVE_LFI_HRIS_CONFIG_ERROR = 'SAVE_LFI_HRIS_CONFIG_ERROR';

// Workday Action Constants
export const GET_WORKDAY_HRIS_FIELDS = 'GET_WORKDAY_HRIS_FIELDS';
export const GET_WORKDAY_HRIS_FIELDS_SUCCESS =
  'GET_WORKDAY_HRIS_FIELDS_SUCCESS';
export const GET_WORKDAY_HRIS_FIELDS_ERROR = 'GET_WORKDAY_HRIS_FIELDS_ERROR';

export const VALIDATE_WORKDAY_CONFIGS = 'VALIDATE_WORKDAY_CONFIGS';
export const VALIDATE_WORKDAY_CONFIGS_SUCCESS =
  'VALIDATE_WORKDAY_CONFIGS_SUCCESS';
export const VALIDATE_WORKDAY_CONFIGS_ERROR = 'VALIDATE_WORKDAY_CONFIGS_ERROR';

export const SAVE_WORKDAY_HRIS_CONFIG = 'SAVE_WORKDAY_HRIS_CONFIG';
export const SAVE_WORKDAY_HRIS_CONFIG_SUCCESS =
  'SAVE_WORKDAY_HRIS_CONFIG_SUCCESS';
export const SAVE_WORKDAY_HRIS_CONFIG_ERROR = 'SAVE_WORKDAY_HRIS_CONFIG_ERROR';

export const UPDATE_WORKDAY_HRIS_CONFIG = 'UPDATE_WORKDAY_HRIS_CONFIG';
export const UPDATE_WORKDAY_HRIS_CONFIG_SUCCESS =
  'UPDATE_WORKDAY_HRIS_CONFIG_SUCCESS';
export const UPDATE_WORKDAY_HRIS_CONFIG_ERROR =
  'UPDATE_WORKDAY_HRIS_CONFIG_ERROR';

export const GET_INTEGRATION_CONFIG_DETAILS = 'GET_INTEGRATION_CONFIG_DETAILS';
export const GET_INTEGRATION_CONFIG_DETAILS_SUCCESS =
  'GET_INTEGRATION_CONFIG_DETAILS_SUCCESS';
export const GET_INTEGRATION_CONFIG_DETAILS_ERROR =
  'GET_INTEGRATION_CONFIG_DETAILS_ERROR';

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_ERROR = 'DOWNLOAD_FILE_ERROR';

export const GET_EMPLOYER_HRIS_FIXED_ERRORS_IN_PROGRESS =
  'GET_EMPLOYER_HRIS_FIXED_ERRORS_IN_PROGRESS';
export const GET_EMPLOYER_HRIS_FIXED_ERRORS_SUCCESS =
  'GET_EMPLOYER_HRIS_FIXED_ERRORS_SUCCESS';
export const GET_EMPLOYER_HRIS_FIXED_ERRORS_ERROR =
  'GET_EMPLOYER_HRIS_FIXED_ERRORS_ERROR';

export const FIX_INVALID_MAPPINGS_SUCCESS = 'FIX_INVALID_MAPPINGS_SUCCESS';
export const FIX_INVALID_MAPPINGS_IN_PROGRESS =
  'FIX_INVALID_MAPPINGS_IN_PROGRESS';
export const FIX_INVALID_MAPPINGS_ERROR = 'FIX_INVALID_MAPPINGS_ERROR';

export const GET_EMPLOYER_BEN_ADMIN_DETAILS = 'GET_EMPLOYER_BEN_ADMIN_DETAILS';
export const GET_EMPLOYER_BEN_ADMIN_DETAILS_SUCCESS =
  'GET_EMPLOYER_BEN_ADMIN_DETAILS_SUCCESS';
export const GET_EMPLOYER_BEN_ADMIN_DETAILS_ERROR =
  'GET_EMPLOYER_BEN_ADMIN_DETAILS_ERROR';
  
export const DOWNLOAD_CONFIG_FILE = 'DOWNLOAD_CONFIG_FILE';
export const DOWNLOAD_CONFIG_FILE_SUCCESS = 'DOWNLOAD_CONFIG_FILE_SUCCESS';
export const DOWNLOAD_CONFIG_FILE_ERROR = 'DOWNLOAD_CONFIG_FILE_ERROR';

export const SAVE_FLOCK_BEN_ADMIN_CONFIG = 'SAVE_FLOCK_BEN_ADMIN_CONFIG';
export const SAVE_FLOCK_BEN_ADMIN_CONFIG_SUCCESS =
  'SAVE_FLOCK_BEN_ADMIN_CONFIG_SUCCESS';
export const SAVE_FLOCK_BEN_ADMIN_CONFIG_ERROR =
  'SAVE_FLOCK_BEN_ADMIN_CONFIG_ERROR';

export const GET_BEN_ADMIN_CONFIG_DETAILS = 'GET_BEN_ADMIN_CONFIG_DETAILS';
export const GET_BEN_ADMIN_CONFIG_DETAILS_SUCCESS =
  'GET_BEN_ADMIN_CONFIG_DETAILS_SUCCESS';
export const GET_BEN_ADMIN_CONFIG_DETAILS_ERROR =
  'GET_BEN_ADMIN_CONFIG_DETAILS_ERROR';

export const SAVE_WORKDAY_BEN_ADMIN_CONFIG = 'SAVE_WORKDAY_BEN_ADMIN_CONFIG';
export const SAVE_WORKDAY_BEN_ADMIN_CONFIG_SUCCESS =
  'SAVE_WORKDAY_BEN_ADMIN_CONFIG_SUCCESS';
export const SAVE_WORKDAY_BEN_ADMIN_CONFIG_ERROR =
  'SAVE_WORKDAY_BEN_ADMIN_CONFIG_ERROR';
  
export const SAVE_PLATFORM_BEN_ADMIN_CONFIG = 'SAVE_PLATFORM_BEN_ADMIN_CONFIG';
export const SAVE_PLATFORM_BEN_ADMIN_CONFIG_SUCCESS =
  'SAVE_PLATFORM_BEN_ADMIN_CONFIG_SUCCESS';
export const SAVE_PLATFORM_BEN_ADMIN_CONFIG_ERROR =
  'SAVE_PLATFORM_BEN_ADMIN_CONFIG_ERROR';