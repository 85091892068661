/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';

const UsersList = React.lazy(() => import('./pages/UsersList'));
const UsersListForRole = React.lazy(() => import('./pages/UsersListForRole'));
const UserDetails = React.lazy(() => import('./pages/UserDetails'));
const AdminRolesList = React.lazy(() => import('./pages/AdminRolesList'));
const CalendarTemplates = React.lazy(() =>
  import('./Calendars/pages/Calendars')
);
const MobileVersions = React.lazy(() => import('./Mobile/pages/Mobile'));

export {
  UsersList,
  UserDetails,
  AdminRolesList,
  UsersListForRole,
  CalendarTemplates,
  MobileVersions
};
