/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { Multiselect } from 'multiselect-react-dropdown';
import moment from 'moment-timezone';

import PageActionButton from 'components/Buttons';
import { Col, Row } from 'components/Atoms';
import FullscreenModal from 'components/FullscreenModal';
import { SCHEDULE_TIME_SLOTS, EXPRESSION_PATTERN } from '../../constants';

import {
  getBenAdminDailySchedule,
  updateBenAdminDailySync
} from '../../actions/integrationsAction';
import * as ActionTypes from '../../actionTypes/integrations.actionTypes';
import './ChangeSchedule.scss';
import Loader from 'components/CircularProgress';

export class ChangeSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaved: false,
      timeSlots: SCHEDULE_TIME_SLOTS,
      selectedTimeSlots: []
    };
  }

  componentDidUpdate(prevProps) {
    const { requestStatus, toggle, isOpen } = this.props;

    const { requestStatus: prevRequestStatus } = prevProps;
    if (prevRequestStatus !== requestStatus) {
      switch (requestStatus) {
        case ActionTypes.GET_BENADMIN_DAILY_SCHEDULE_SUCCESS:
          this.getDailyScheduleTimes();
          break;
        case ActionTypes.UPDATE_BENADMIN_DAILY_SCHEDULE_SUCCESS:
          if (isOpen) {
            NotificationManager.success(
              `Current daily schedule has been updated Successfully`
            );
            toggle();
          }
          break;
        default:
          break;
      }
    }
  }

  changeSchedule = () => {
    const {
      updateBenAdminDailySync,
      employerId,
      dailyBenAdminSyncStatus
    } = this.props;

    let cronExpression = this.generateCronExpression();
    let config = {
      benAdminEnabled: dailyBenAdminSyncStatus.enabled
        ? dailyBenAdminSyncStatus.enabled
        : false,
      cronExpression
    };
    updateBenAdminDailySync(employerId, config);
  };

  getDailyScheduleTimes = () => {
    const { dailySyncSchedule } = this.props;
    const { data } = dailySyncSchedule;
    const timeSchedules =
      data &&
      data.cronExpression
        .substring(4)
        .replace(EXPRESSION_PATTERN.END, '')
        .split(',');
    let schedules = [];
    timeSchedules.forEach((schedule) => {
      schedules.push(
        ('0' + schedule).slice(-2) + EXPRESSION_PATTERN.TIME_FORMAT
      );
    });

    const convertedSchedules = schedules.map((shedule) => {
      const timeUTC = moment.utc(shedule, 'HH.mm');
      return timeUTC.tz('America/Los_Angeles').format('HH.mm');
    });
    this.setState({ selectedTimeSlots: convertedSchedules });
  };

  generateCronExpression = () => {
    const { selectedTimeSlots } = this.state;

    const convertedSelectedTimeSlots = selectedTimeSlots.map((shedule) => {
      const timePST = moment.tz(shedule, 'HH.mm', 'America/Los_Angeles');
      return moment.utc(timePST).format('HH.mm');
    });

    let schedules = [];
    let cronExpression = '';
    convertedSelectedTimeSlots.sort().map((schedule) => {
      schedules.push(Number(schedule).toString());
    });
    cronExpression = EXPRESSION_PATTERN.START.concat(
      schedules.join(',')
    ).concat(EXPRESSION_PATTERN.END);
    return cronExpression;
  };

  onSelectSlot = (selectedList) => {
    this.setState({ selectedTimeSlots: selectedList });
  };

  render() {
    const {
      isOpen,
      toggle,
      dailySyncSchedule,
      isLoading,
      requestStatus,
      isUpdatingDailySchedule
    } = this.props;
    const { timeSlots, selectedTimeSlots } = this.state;

    if (
      (isLoading &&
        requestStatus === ActionTypes.GET_BENADMIN_DAILY_SCHEDULE) ||
      dailySyncSchedule.inProgress
    ) {
      return <Loader />;
    }

    return (
      <>
        <FullscreenModal
          isOpen={isOpen}
          toggle={toggle}
          title={'Change Schedule'}
          closeText={'close'}
        >
          <>
            <Row>
              <Col>
                <div className="form-group">
                  <label>Current Daily Schedule</label>
                  <label className="utc-label">(PST Time Zone)</label>
                  <Multiselect
                    options={timeSlots}
                    isObject={false}
                    selectedValues={selectedTimeSlots}
                    onSelect={this.onSelectSlot}
                    onRemove={this.onSelectSlot}
                    placeholder={'Select schedule time'}
                    hidePlaceholder={true}
                  />
                </div>
              </Col>
            </Row>
            <div className="page-actions-row">
              <PageActionButton outline onClick={toggle}>
                Cancel
              </PageActionButton>
              <PageActionButton
                onClick={this.changeSchedule}
                disabled={!selectedTimeSlots.length}
                type={
                  isUpdatingDailySchedule && !dailySyncSchedule.inProgress ? 'progress' : ''
                }
              >
                Change Daily Sync Schedule
              </PageActionButton>
            </div>
          </>
        </FullscreenModal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateBenAdminDailySync: (employerId, config) =>
    dispatch(updateBenAdminDailySync(employerId, config)),
  getBenAdminDailySchedule: (employerId) =>
    dispatch(getBenAdminDailySchedule(employerId))
});
const mapStateToProps = (state) => {
  const {
    serviceError,
    isLoading,
    requestStatus,
    dailySyncSchedule,
    dailyBenAdminSyncStatus,
    isUpdatingDailySchedule
  } = state.benAdminIntegrationsReducer;
  return {
    serviceError,
    isLoading,
    requestStatus,
    dailySyncSchedule,
    dailyBenAdminSyncStatus,
    isUpdatingDailySchedule
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSchedule);
