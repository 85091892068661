/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/Atoms';

import FullscreenModal from 'components/FullscreenModal';
import PageActionButton from 'components/Buttons';
import { TextFormGroup } from 'components/DataForm';

import _ from 'lodash';

import './RenamePlanModal.scss';

class RenamePlanModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { 
      currentValue: ''
    };
  }

  componentDidMount() {
    const { planName } = this.props;
    if (planName) {
      this.setState({ currentValue: planName });
    }
  }

  componentDidUpdate(prevProps) {
    const { planName } = this.props;
    if (planName !== prevProps.planName) {
      this.setState({ currentValue: planName });
    }
  }

  onChange = (e) => {
    this.setState({ currentValue: e.target.value });
  };

  resetContext = () => {
    const { planName } = this.props;
    this.setState({ currentValue: planName });
  };

  render() {
    const { isOpen, toggle, onButtonChange, feedback, planKey } = this.props;
    const { currentValue = '' } = this.state;

    return (
      <FullscreenModal
        isOpen={isOpen}
        toggle={toggle}
        onClosed={this.resetContext}
        title="Rename Plan"
        closeText={'cancel'}
      >
        <Row className="rename-plan-action-text-row">
          <Col xs={10}>
            <TextFormGroup
              labelDisplay="Plan Name"
              inputName="planName"
              maxLength="100"
              onChange={this.onChange}
              inputValue={currentValue}
              isInvalid={_.isEmpty(currentValue.trim())}
              feedback={feedback}
            />
          </Col>
        </Row>
        <Row className="rename-plan-action-buttons-row">
          <Col xs={5}>
            <PageActionButton onClick={toggle} outline>
              Cancel
            </PageActionButton>
          </Col>
          <Col xs={5}>
            <PageActionButton
              disabled={_.isEmpty(currentValue.trim())}
              onClick={() => onButtonChange(planKey, currentValue)}
            >
              Rename
            </PageActionButton>
          </Col>
        </Row>
      </FullscreenModal>
    );
  }
}

export default RenamePlanModal;
