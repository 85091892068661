/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
const DENTAL_LIST_PATH =
  '/brokers/:brokerId/employers/:employerId/dental-plans';
const DENTAL_CREATE_PATH =
  '/brokers/:brokerId/employers/:employerId/dental-plans/create';
const DENTAL_EDIT_PREFIX =
  '/brokers/:brokerId/employers/:employerId/dental-plans/:planId/edit';
const DENTAL_EDIT_BASIC_PATH = DENTAL_EDIT_PREFIX + '/basic';
const DENTAL_EDIT_COVERAGE_PATH = DENTAL_EDIT_PREFIX + '/coverage';
const DENTAL_EDIT_SERVICES_PATH = DENTAL_EDIT_PREFIX + '/services';
const DENTAL_EDIT_RATES_PATH = DENTAL_EDIT_PREFIX + '/rates';
const DENTAL_VIEW_PREFIX =
  '/brokers/:brokerId/employers/:employerId/dental-plans/:planId/view';
const DENTAL_VIEW_BASIC_PATH = DENTAL_VIEW_PREFIX + '/basic';
const DENTAL_VIEW_COVERAGE_PATH = DENTAL_VIEW_PREFIX + '/coverage';
const DENTAL_VIEW_SERVICES_PATH = DENTAL_VIEW_PREFIX + '/services';
const DENTAL_VIEW_RATES_PATH = DENTAL_VIEW_PREFIX + '/rates';

const DENTAL_CLONE_PREFIX =
  '/brokers/:brokerId/employers/:employerId/dental-plans/:planId/clone';
const DENTAL_CLONE_BASIC_PATH = DENTAL_CLONE_PREFIX + '/basic';
const DENTAL_CLONE_COVERAGE_PATH = DENTAL_CLONE_PREFIX + '/coverage';
const DENTAL_CLONE_SERVICES_PATH = DENTAL_CLONE_PREFIX + '/services';
const DENTAL_CLONE_RATES_PATH = DENTAL_CLONE_PREFIX + '/rates';

export {
  DENTAL_LIST_PATH,
  DENTAL_CREATE_PATH,
  DENTAL_EDIT_PREFIX,
  DENTAL_EDIT_BASIC_PATH,
  DENTAL_EDIT_COVERAGE_PATH,
  DENTAL_EDIT_SERVICES_PATH,
  DENTAL_EDIT_RATES_PATH,
  DENTAL_VIEW_PREFIX,
  DENTAL_VIEW_BASIC_PATH,
  DENTAL_VIEW_COVERAGE_PATH,
  DENTAL_VIEW_SERVICES_PATH,
  DENTAL_VIEW_RATES_PATH,
  DENTAL_CLONE_BASIC_PATH,
  DENTAL_CLONE_COVERAGE_PATH,
  DENTAL_CLONE_RATES_PATH,
  DENTAL_CLONE_SERVICES_PATH,
  DENTAL_CLONE_PREFIX
};
