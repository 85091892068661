import React, { PureComponent } from 'react';

import Icon from 'components/Icons';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'components/Atoms';
import lumityLogo from 'assets/images/logo-lumity.png';
import placeholderAvatar from 'assets/images/avatar-placeholder.png';
import { LOGGED_IN_USER_EMAIL } from 'constants/authConstants';
import { getAvatarOrDefault } from 'util/commonUtil';
import { isInProduction } from 'util/urlUtil';

import BannerProduction from './BannerProduction';
import './Header.scss';
import BannerArchivedEmployer from './BannerArchivedEmployer';
import placeholderLogoReference from 'assets/images/logo-placeholder.png';

class Header extends PureComponent {
  render() {
    const {
      appBootupInfo = {},
      logout,
      isArchived,
      organizationLogo
    } = this.props;
    const { avatarUrl, firstName, lastName, organizationId } = appBootupInfo;

    const avatar = getAvatarOrDefault(avatarUrl);
    const username = localStorage.getItem(LOGGED_IN_USER_EMAIL);

    return (
      <div className="app-header-wrapper">
        <div className="app-header">
          <img
            alt="Lumity Logo"
            src={
              organizationId
                ? organizationLogo
                  ? organizationLogo
                  : placeholderLogoReference
                : lumityLogo
            }
            height="30px"
          />
          <ul className="header__actions">
            <li>
              <Icon icon="bell" />
            </li>
            <li>
              <Icon icon="comment-alt" />
            </li>
            <li className="user-nav">
              <UncontrolledDropdown>
                <DropdownToggle className="user-thumbnail">
                  <img
                    className="avatar-image"
                    src={avatar}
                    alt="Avatar"
                    onError={replaceAvatarOnError}
                  />
                </DropdownToggle>
                <DropdownMenu right>
                  {/* TODO: Merge the item contents with TeamMembers component */}
                  <DropdownItem className="user-profile" header>
                    <img
                      className="avatar-image"
                      src={avatar}
                      alt="Avatar"
                      onError={replaceAvatarOnError}
                    />
                    <div className="user-detail">
                      <div>{`${firstName} ${lastName}`}</div>
                      <small>{username}</small>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <span className="text-muted" onClick={logout}>
                      <Icon icon="sign-out-alt" className="mr-2" />
                      Logout
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </li>
          </ul>
        </div>
        {isInProduction() && <BannerProduction />}
        {isArchived && <BannerArchivedEmployer />}
      </div>
    );
  }
}

const replaceAvatarOnError = (event) => {
  event.target.onerror = null;
  event.target.src = placeholderAvatar;
};

export default Header;
