/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Alert } from 'components/Atoms';
import {
  TextFormGroup,
  DatePickerInput,
  RadioRowFormGroup,
  NumberAddonInputGroup
} from 'components/DataForm';
import { sanitizeDateInput } from 'util/commonUtil';
import { isValidFormat } from 'util/dateUtil';
import { COMMON_DATE_FORMAT } from 'constants/dateConstants';

// actions
import * as ActionTypes from 'modules/Integrations/actionTypes/integrations.actionTypes';
import { getLFISetupDetails } from 'modules/Integrations/actions/integrationsAction';

import '../DialogIntegrationDetails.scss';
import { LFI_CONFIG_ERROR_LABEL_CODE_MAP } from 'modules/Integrations/constants';

class LFIVendorConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorConfiguration: props.vendorConfiguration || {
        lfiFolderName: '',
        syncSalary: true,
        salaryOverride: '',
        partTimeBenefitsEligible: true,
        ignoreTerminatedEmployeesCutoffDate: '',
        ignoreTerminatedEmployees: false
      },
      isCutoffDateInvalid: false,
      error: ''
    };
  }

  onChange = (event) => {
    let { name, value } = event.target;
    const { isCutoffDateInvalid, vendorConfiguration } = this.state;
    let vendorConfigurationDetails = { ...vendorConfiguration };
    let isCutOffDateInvalidFlag = isCutoffDateInvalid;
    switch (name) {
      case 'syncSalary': {
        value = value === 'true';
        vendorConfigurationDetails.salaryOverride = '';
        break;
      }
      case 'partTimeBenefitsEligible': {
        value = value === 'true';
        break;
      }
      case 'ignoreTerminatedEmployees': {
        value = value === 'true';
        if (!value) {
          vendorConfigurationDetails.ignoreTerminatedEmployeesCutoffDate = '';
          if (isCutoffDateInvalid) {
            isCutOffDateInvalidFlag = false;
          }
        }
        break;
      }
      case 'ignoreTerminatedEmployeesCutoffDate': {
        if (isCutoffDateInvalid) {
          isCutOffDateInvalidFlag = false;
        }
        break;
      }
      default:
        break;
    }
    vendorConfigurationDetails[name] = value;
    this.setState({
      error: '',
      vendorConfiguration: vendorConfigurationDetails,
      isCutoffDateInvalid: isCutOffDateInvalidFlag
    });
  };

  hasRequiredInputs() {
    let hasRequiredInputs = true;
    const { vendorConfiguration } = this.state;
    const requiredInputs = ['lfiFolderName'];

    for (let requiredInput of requiredInputs) {
      if (!vendorConfiguration || !vendorConfiguration[requiredInput]) {
        hasRequiredInputs = false;
        break;
      }
    }
    // Handle Salary Override differently, only required if Sync Salary is false
    if (
      !vendorConfiguration.syncSalary &&
      !vendorConfiguration.salaryOverride
    ) {
      hasRequiredInputs = false;
    }
    return hasRequiredInputs;
  }

  getLFISetupDetails = () => {
    const { employerId } = this.props;
    const { vendorConfiguration } = this.state;
    const { ignoreTerminatedEmployeesCutoffDate } = vendorConfiguration;
    if (
      ignoreTerminatedEmployeesCutoffDate &&
      !isValidFormat(ignoreTerminatedEmployeesCutoffDate, COMMON_DATE_FORMAT)
    ) {
      this.setState({
        isCutoffDateInvalid: true
      });
    } else { 
      this.props.getLFISetupDetails(employerId);
    }
  };

  componentDidUpdate(prevProps) {
    const { requestStatus, onNext, serviceError } = this.props;
    const { vendorConfiguration } = this.state;
    if (requestStatus === prevProps.requestStatus) return;
    switch (requestStatus) {
      case ActionTypes.GET_LFI_SETUP_DETAILS_SUCCESS: {
        onNext(vendorConfiguration);
        break;
      }
      case ActionTypes.GET_LFI_SETUP_DETAILS_ERROR: {
        const errorCode =
          serviceError.response &&
          serviceError.response.data &&
          serviceError.response.data.code;
        this.setState({
          error:
            LFI_CONFIG_ERROR_LABEL_CODE_MAP[errorCode] ||
            'Invalid Configuration'
        });
        break;
      }
      default:
        break;
    }
  }

  render() {
    const { selectedVendor, onPrevious } = this.props;
    const { error, isCutoffDateInvalid } = this.state;
    const vendorName = selectedVendor.name;
    const {
      lfiFolderName = '',
      syncSalary = true,
      salaryOverride = '',
      partTimeBenefitsEligible = true,
      ignoreTerminatedEmployeesCutoffDate = '',
      ignoreTerminatedEmployees = false
    } = this.state.vendorConfiguration || {};
    return (
      <div className="step-container vendor-configuration">
        <div className="body">
          <p className="subtitle">{vendorName}</p>
          {error && (
            <Alert color="danger" className="error-alert">
              {error}
            </Alert>
          )}
          <TextFormGroup
            labelDisplay={`${vendorName} Folder Name`}
            inputName="lfiFolderName"
            inputValue={lfiFolderName}
            onChange={this.onChange}
            placeholder="Enter Employer Name"
          />
          <Row>
            <Col>
              <RadioRowFormGroup
                labelDisplay="Sync Salary"
                className="min-input-height"
                onChange={this.onChange}
                inputName="syncSalary"
                inputValue={syncSalary}
                options={[
                  {
                    value: true,
                    text: 'Yes'
                  },
                  {
                    value: false,
                    text: 'No'
                  }
                ]}
              />
            </Col>
            <Col>
              <NumberAddonInputGroup
                labelDisplay="Salary Override"
                onNumberChange={(name, value) =>
                  this.onChange({ target: { name, value } })
                }
                inputName="salaryOverride"
                inputValue={salaryOverride}
                addonType="prepend"
                addonText="$"
                min={0}
                decimalScale={2}
                fixedDecimalScale={2}
                isEmptyAllowed={true}
                disabled={syncSalary}
              />
            </Col>
          </Row>
          <RadioRowFormGroup
            labelDisplay="Part-Time Eligible"
            className="min-input-height"
            onChange={this.onChange}
            inputName="partTimeBenefitsEligible"
            inputValue={partTimeBenefitsEligible}
            options={[
              {
                value: true,
                text: 'Yes'
              },
              {
                value: false,
                text: 'No'
              }
            ]}
          />
          <Row>
            <Col>
              <RadioRowFormGroup
                labelDisplay="Ignore Terminated Employees"
                className="min-input-height"
                onChange={this.onChange}
                inputName="ignoreTerminatedEmployees"
                inputValue={ignoreTerminatedEmployees}
                options={[
                  {
                    value: true,
                    text: 'Yes'
                  },
                  {
                    value: false,
                    text: 'No'
                  }
                ]}
              />
            </Col>
            <Col>
              <DatePickerInput
                labelDisplay="Ignore Terminated Employees Cutoff Date"
                inputName="ignoreTerminatedEmployeesCutoffDate"
                inputValue={ignoreTerminatedEmployeesCutoffDate}
                dateFormat={COMMON_DATE_FORMAT}
                onChange={(event) => {
                  const { value } = event.target;
                  event.target.value = sanitizeDateInput(value, '/');
                  this.onChange(event);
                }}
                isInvalid={isCutoffDateInvalid}
                feedback={`Please enter a valid date in the format ${COMMON_DATE_FORMAT}`}
                disabled={!ignoreTerminatedEmployees}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs="6">
            <Button
              className="form-element"
              outline
              color="primary"
              size="lg"
              onClick={() => onPrevious(this.state.vendorConfiguration)}
            >
              Previous
            </Button>
          </Col>
          <Col xs="6">
            <Button
              className="form-element"
              color="primary"
              size="lg"
              disabled={!this.hasRequiredInputs()}
              onClick={this.getLFISetupDetails}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(
  ({ integrationsReducer }) => {
    const { requestStatus, serviceError } = integrationsReducer;
    return {
      requestStatus,
      serviceError
    };
  },
  {
    getLFISetupDetails
  }
)(LFIVendorConfiguration);
