import React, { Component } from 'react';
import { Button } from 'components/Atoms';
import Icon from 'components/Icons';
import _ from 'lodash';

// styles
import './ColumnFilter.scss';

export class ColumnFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      selectedValues: props.selectedFilters || []
    };
    this.wrapperRef = React.createRef();
  }

  onSelection = (filterValue) => (e) => {
    const { selectedValues } = this.state;
    let updatedValues;
    if (e.target.checked) {
      updatedValues = [...selectedValues, filterValue];
    } else {
      updatedValues = selectedValues.filter(
        (selectedValue) => filterValue !== selectedValue
      );
    }
    this.setState({
      selectedValues: updatedValues
    });
  };

  onFilter = (e) => {
    const { selectedValues } = this.state;
    this.setState({ show: false });
    this.props.onFilter(selectedValues);
  };

  resetValues = () => {
    this.setState({
      selectedValues: [],
      show: false
    });
    this.props.onFilter([]);
  };

  toggleFilterMenu = (e) => {
    this.setState({ show: !this.state.show });
  };

  hideFilterMenu = () => {
    this.setState({ show: false });

    //Reset filter
    const { selectedValues } = this.state;
    const { selectedFilters } = this.props;
    if (
      !_.isEqual((selectedValues || []).sort(), (selectedFilters || []).sort())
    ) {
      this.setState({ selectedValues: selectedFilters });
    }
  };

  handleClickOutside = (e) => {
    if (this.wrapperRef && this.wrapperRef.current.contains(e.target)) return;
    this.hideFilterMenu();
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { filters, columnHeader, headerlessIcon = false } = this.props;
    const { show, selectedValues } = this.state;
    return (
      <div className="filter-heading-container" ref={this.wrapperRef}>
        <div>
          {columnHeader}
          <Icon
            icon="filter"
            size="sm"
            onClick={this.toggleFilterMenu}
            className={
              headerlessIcon ? 'headerless-filter-icon' : 'filter-icon'
            }
          />
        </div>
        {show && (
          <div
            className={
              headerlessIcon
                ? 'headerless-filter-popover-container'
                : 'filter-popover-container'
            }
          >
            {filters.map((filterValue) => {
              let value, label;
              if (typeof filterValue === 'string') {
                value = filterValue;
                label = filterValue;
              } else {
                label = filterValue.label;
                value = filterValue.value;
              }

              return (
                <label key={label} className="filter-row">
                  <input
                    type="checkbox"
                    className="filter-option"
                    value={value}
                    checked={selectedValues.includes(value)}
                    onChange={this.onSelection(value)}
                  />
                  {label}
                </label>
              );
            })}
            <div className="actions-container">
              <Button color="link" onClick={this.onFilter}>
                OK
              </Button>
              <Button color="link" onClick={this.resetValues}>
                Reset
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
