/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import _ from 'lodash';
import OrganizationService from 'services/OrganizationService';
import OrganisationService from 'services/OrganizationService';
import * as ActionTypes from '../actionTypes/organization.actionTypes';

export const getOrganisationList = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_ORGANISATIONS_LIST_IN_PROGRESS });
    OrganisationService.findAllOrganisations()
      .then((response) =>
        dispatch({
          type: ActionTypes.GET_ORGANISATIONS_COMPLETED,
          payload: response.data
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_ORGANISATIONS_LIST_FAILED,
          payload: error.response
        })
      );
  };
};

export const getOrganisationPage = (page, size, sort, query) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_ORGANISATIONS_PAGE_IN_PROGRESS });
    await OrganisationService.findAll(page, size, sort, query)
      .then((response) =>
        dispatch({
          type: ActionTypes.GET_ORGANISATIONS_PAGE_COMPLETED,
          payload: response.data
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_ORGANISATIONS_PAGE_FAILED,
          payload: error.response
        })
      );
  };
};

export const createOrganisation = (formData, logo) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.CREATE_ORGANISATION_IN_PROGRESS });
    try {
      const org = (
        await OrganisationService.createOrganisation({
          ...formData
        })
      ).data.id;

      if (logo) {
        await OrganisationService.uploadLogo(org, logo);
      }

      dispatch({
        type: ActionTypes.CREATE_ORGANISATION_COMPLETED
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CREATE_ORGANISATION_FAILED,
        payload: error.response
      });
    }
  };
};

export const updateOrganisation = (oldBroker, newBroker, newLogo) => {
  const organizationId = oldBroker.id;
  return async (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_ORGANISATION_IN_PROGRESS });
    try {
      if (!_.isEqual(oldBroker, newBroker)) {
        await OrganisationService.updateOrganisation(organizationId, newBroker);
      }

      if (newLogo) {
        newBroker.logoUrl = (
          await OrganisationService.uploadLogo(organizationId, newLogo)
        ).data;
      }
      dispatch({
        type: ActionTypes.UPDATE_ORGANISATION_COMPLETED,
        payload: newBroker
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_ORGANISATION_FAILED,
        payload: error.response
      });
    }
  };
};

export const getOrganizationById = (organizationId) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_ORGANISATION_IN_PROGRESS });
    await OrganisationService.findById(organizationId)
      .then((response) =>
        dispatch({
          type: ActionTypes.GET_ORGANISATION_COMPLETED,
          payload: response.data
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_ORGANISATION_FAILED,
          payload: error.response
        })
      );
  };
};

export const deleteOrganization = (organizationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.DELETE_ORGANIZATION_IN_PROGRESS });
      const response = await OrganizationService.deleteOrganization(
        organizationId
      );
      dispatch({
        type: ActionTypes.DELETE_ORGANIZATION_COMPLETED,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.DELETE_ORGANIZATION_FAILED,
        payload: error.response
      });
    }
  };
};
