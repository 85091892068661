/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const GET_CARRIERS_PAGE_IN_PROGRESS = 'GET_CARRIERS_PAGE_IN_PROGRESS';
export const GET_CARRIERS_PAGE_COMPLETED = 'GET_CARRIERS_PAGE_COMPLETED';
export const GET_CARRIERS_PAGE_FAILED = 'GET_CARRIERS_PAGE_FAILED';

export const UPSERT_CARRIER_IN_PROGRESS = 'UPSERT_CARRIER_IN_PROGRESS';
export const UPSERT_CARRIER_COMPLETED = 'UPSERT_CARRIER_COMPLETED';
export const UPSERT_CARRIER_FAILED = 'UPSERT_CARRIER_FAILED';

export const UPLOAD_CARRIER_LOGO_IN_PROGRESS =
  'UPLOAD_CARRIER_LOGO_IN_PROGRESS';
export const UPLOAD_CARRIER_LOGO_PROGRESS = 'UPLOAD_CARRIER_LOGO_PROGRESS';
export const UPLOAD_CARRIER_LOGO_COMPLETED = 'UPLOAD_CARRIER_LOGO_COMPLETED';
export const UPLOAD_CARRIER_LOGO_FAILED = 'UPLOAD_CARRIER_LOGO_FAILED';
