/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/integrations.actionTypes';

const initialState = {
  requestStatus: '',
  serviceError: '',
  configStatus: {
    id: null,
    value: 'false',
    lastUpdatedTs: null
  },
  dailySyncSchedule: {
    inProgress: false,
    data: null
  },
  dailyBenAdminSyncStatus: {},
  syncBenAdminEmployeeErrors: {},
  syncBenAdminEnrollmentErrors: {},
  benadminSyncEmployeeFilters: {},
  benadminSyncEnrollmentFilters: {},
  syncJobFlock: {
    inProgress: false,
    error: null,
    data: null
  },
  benAdminConfigDetails: {
    inProgress: false,
    data: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_BENADMIN_INTEGRATIONS_FEATURE_CONF:
    case ActionTypes.UPDATE_BENADMIN_INTEGRATIONS_FEATURE_CONF:
    case ActionTypes.GET_BENADMIN_INTEGRATIONS_FEATURE_CONF:
    case ActionTypes.DOWNLOAD_BENADMIN_SYNC_ERROR_REPORT:
    case ActionTypes.GET_BENADMIN_EMPLOYER_SYNC_DETAILS:
    case ActionTypes.GET_BENADMIN_SYNC_ERROR_FILTERS:
    case ActionTypes.GET_LAST_BENADMIN_SYNC_STATUS:
    case ActionTypes.GET_BENADMIN_SYNC_ERROR_LIST:
    case ActionTypes.START_EMPLOYER_BENADMIN_SYNC:
      return {
        ...state,
        isLoading: true,
        serviceError: '',
        requestStatus: action.type
      };

    case ActionTypes.UPDATE_BENADMIN_DAILY_SCHEDULE:
      return {
        ...state,
        isUpdatingDailySchedule: true,
        serviceError: '',
        requestStatus: action.type
      };

    case ActionTypes.GET_BEN_ADMIN_CONFIG_DETAILS:
      return {
        ...state,
        isLoading: true,
        benAdminConfigDetails: {
          inProgress: true,
          data: null
        },
        serviceError: '',
        requestStatus: action.type
      };

    case ActionTypes.GET_BENADMIN_DAILY_SCHEDULE:
      return {
        ...state,
        isLoading: true,
        serviceError: '',
        requestStatus: action.type,
        dailySyncSchedule: {
          inProgress: true,
          data: null
        }
      };

    case ActionTypes.CREATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_ERROR:
    case ActionTypes.UPDATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_ERROR:
    case ActionTypes.GET_BENADMIN_INTEGRATIONS_FEATURE_CONF_ERROR:
    case ActionTypes.DOWNLOAD_BENADMIN_SYNC_ERROR_REPORT_ERROR:
    case ActionTypes.GET_BENADMIN_EMPLOYER_SYNC_DETAILS_ERROR:
    case ActionTypes.GET_BENADMIN_SYNC_ERROR_FILTERS_ERROR:
    case ActionTypes.GET_LAST_BENADMIN_SYNC_STATUS_ERROR:
    case ActionTypes.GET_BENADMIN_SYNC_ERROR_LIST_ERROR:
    case ActionTypes.START_EMPLOYER_BENADMIN_SYNC_ERROR:
      return {
        ...state,
        isLoading: false,
        requestStatus: action.type,
        serviceError: action.payload
      };

    case ActionTypes.UPDATE_BENADMIN_DAILY_SCHEDULE_ERROR:
      return {
        ...state,
        isUpdatingDailySchedule: false,
        requestStatus: action.type,
        serviceError: action.payload
      };

    case ActionTypes.GET_BEN_ADMIN_CONFIG_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        requestStatus: action.type,
        serviceError: action.payload,
        benAdminConfigDetails: {
          inProgress: false,
          data: null
        }
      };

    case ActionTypes.GET_BENADMIN_DAILY_SCHEDULE_ERROR:
      return {
        ...state,
        isLoading: false,
        requestStatus: action.type,
        serviceError: action.payload,
        dailySyncSchedule: {
          inProgress: false,
          data: null
        }
      };

    case ActionTypes.START_EMPLOYER_BENADMIN_SYNC_SUCCESS:
    case ActionTypes.DOWNLOAD_BENADMIN_SYNC_ERROR_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type
      };
    }

    case ActionTypes.GET_BENADMIN_INTEGRATIONS_FEATURE_CONF_SUCCESS:
    case ActionTypes.CREATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_SUCCESS:
    case ActionTypes.UPDATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        configStatus: action.payload
      };
    }

    case ActionTypes.GET_BENADMIN_EMPLOYER_SYNC_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        employerBenAdminIntegrations: action.payload
      };
    }

    case ActionTypes.GET_BENADMIN_DAILY_SCHEDULE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        dailySyncSchedule: {
          inProgress: false,
          data: action.payload
        }
      };
    }

    case ActionTypes.UPDATE_BENADMIN_DAILY_SCHEDULE_SUCCESS: {
      return {
        ...state,
        isUpdatingDailySchedule: false,
        serviceError: '',
        requestStatus: action.type,
        dailyBenAdminSyncStatus: {
          ...state.dailyBenAdminSyncStatus,
          enabled: action.payload.benAdminEnabled
        }
      };
    }

    case ActionTypes.GET_BENADMIN_EMPLOYEE_SYNC_ERROR_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        syncBenAdminEmployeeErrors: action.payload
      };
    }

    case ActionTypes.GET_BENADMIN_ENROLLMENT_SYNC_ERROR_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        syncBenAdminEnrollmentErrors: action.payload
      };
    }

    case ActionTypes.GET_BENADMIN_EMPLOYEE_SYNC_FILTERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        benadminSyncEmployeeFilters: action.payload
      };
    }

    case ActionTypes.GET_BENADMIN_ENROLLMENT_SYNC_FILTERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        benadminSyncEnrollmentFilters: action.payload
      };
    }

    case ActionTypes.GET_LAST_BENADMIN_SYNC_STATUS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        dailyBenAdminSyncStatus: action.payload
      };
    }

    case ActionTypes.GET_BEN_ADMIN_CONFIG_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        inProgress: false,
        serviceError: '',
        benAdminConfigDetails: {
          inProgress: false,
          data: action.payload
        },
        requestStatus: action.type
      };
    }

    default:
      return {
        ...state
      };
  }
};
