/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/employer.actionTypes';

const initialState = {
  employersPage: {
    data: {
      content: [],
      metadata: {}
    },
    isFetching: false,
    error: null
  },
  updateEmployerStatus: {
    data: {},
    isUpdating: false,
    error: null
  },
  deleteEmployerStatus: {
    inProgress: false,
    error: null
  },
  archiveEmployerStatus: {
    data: {},
    inProgress: false,
    error: null
  },
  createEmployerStatus: {
    inProgress: false,
    createError: null
  },
  previewEmployerStatus: {
    data: null,
    inProgress: false,
    error: null
  },
  importEmployerStatus: {
    inProgress: false,
    error: null
  },
  notificationSettingsData: {
    config: { id: null, value: 'false' },
    loading: false,
    error: null
  },
  notificationUpdate: {
    inProgress: false,
    success: false,
    error: null
  },
  hrisResponsesData: {
    data: [],
    inProgress: false,
    error: null
  },
  benAdminResponseData: {
    data: [],
    inProgress: false,
    error: null
  },
  employer: {
    data: null,
    inProgress: false,
    error: null
  },
  industries: {
    data: [],
    inProgress: false,
    error: null
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_EMPLOYERS_PAGE_IN_PROGRESS:
      return {
        ...state,
        employersPage: {
          ...state.employersPage,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_EMPLOYERS_PAGE_COMPLETED:
      return {
        ...state,
        employersPage: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_EMPLOYERS_PAGE_FAILED:
      return {
        ...state,
        employersPage: {
          ...state.employersPage,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.UPDATE_EMPLOYER_IN_PROGRESS:
      return {
        ...state,
        updateEmployerStatus: {
          ...state.updateEmployerStatus,
          isUpdating: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_EMPLOYER_COMPLETED:
      return {
        ...state,
        updateEmployerStatus: {
          data: action.payload,
          isUpdating: false,
          error: null
        }
      };
    case ActionTypes.UPDATE_EMPLOYER_FAILED:
      return {
        ...state,
        updateEmployerStatus: {
          ...state.updateEmployerStatus,
          isUpdating: false,
          error: action.payload
        }
      };
    case ActionTypes.DELETE_EMPLOYER_IN_PROGRESS:
      return {
        ...state,
        deleteEmployerStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.DELETE_EMPLOYER_COMPLETED:
      return {
        ...state,
        deleteEmployerStatus: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.DELETE_EMPLOYER_FAILED:
      return {
        ...state,
        deleteEmployerStatus: {
          inProgress: false,
          error: action.payload
        }
      };
      case ActionTypes.ARCHIVE_EMPLOYER_IN_PROGRESS:
      return {
        ...state,
        archiveEmployerStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.ARCHIVE_EMPLOYER_COMPLETED:
      return {
        ...state,
        archiveEmployerStatus: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.ARCHIVE_EMPLOYER_FAILED:
      return {
        ...state,
        archiveEmployerStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.CREATE_EMPLOYER_IN_PROGRESS:
      return {
        ...state,
        createEmployerStatus: {
          inProgress: true,
          createError: null
        }
      };
    case ActionTypes.CREATE_EMPLOYER_COMPLETED:
      return {
        ...state,
        createEmployerStatus: {
          inProgress: false,
          createError: null
        }
      };
    case ActionTypes.CREATE_EMPLOYER_FAILED:
      return {
        ...state,
        createEmployerStatus: {
          inProgress: false,
          createError: action.payload
        }
      };
    case ActionTypes.PREVIEW_EMPLOYER_IN_PROGRESS:
      return {
        ...state,
        previewEmployerStatus: {
          data: null,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.PREVIEW_EMPLOYER_COMPLETED:
      return {
        ...state,
        previewEmployerStatus: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.PREVIEW_EMPLOYER_FAILED:
      return {
        ...state,
        previewEmployerStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.IMPORT_EMPLOYER_IN_PROGRESS:
      return {
        ...state,
        importEmployerStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.IMPORT_EMPLOYER_COMPLETED:
      return {
        ...state,
        importEmployerStatus: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.IMPORT_EMPLOYER_FAILED:
      return {
        ...state,
        importEmployerStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_EMPLOYER_NOTIFICATION_CONFIG_IN_PROGRESS:
      return {
        ...state,
        notificationSettingsData: {
          ...state.notificationSettingsData,
          loading: true,
          error: null
        }
      };
    case ActionTypes.GET_EMPLOYER_NOTIFICATION_CONFIG_COMPLETED:
      return {
        ...state,
        notificationSettingsData: {
          ...state.notificationSettingsData,
          loading: false,
          error: null,
          config: action.payload || { id: null, value: 'false' }
        }
      };
    case ActionTypes.GET_EMPLOYER_NOTIFICATION_CONFIG_FAILED:
      return {
        ...state,
        notificationSettingsData: {
          ...state.notificationSettingsData,
          loading: false,
          error: action.payload
        }
      };
    case ActionTypes.UPDATE_EMPLOYER_NOTIFICATION_CONFIG_IN_PROGRESS:
      return {
        ...state,
        notificationUpdate: {
          ...state.notificationUpdate,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_EMPLOYER_NOTIFICATION_CONFIG_COMPLETED:
      return {
        ...state,
        notificationUpdate: {
          ...state.notificationUpdate,
          inProgress: false,
          error: null,
          success: action.payload
        }
      };
    case ActionTypes.UPDATE_EMPLOYER_NOTIFICATION_CONFIG_FAILED:
      return {
        ...state,
        notificationUpdate: {
          ...state.notificationUpdate,
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_EMPLOYER_HRIS_RESPONSE_IN_PROGRESS:
      return {
        ...state,
        hrisResponsesData: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.GET_EMPLOYER_HRIS_RESPONSE_COMPLETED:
      return {
        ...state,
        hrisResponsesData: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.GET_EMPLOYER_HRIS_RESPONSE_FAILED:
      return {
        ...state,
        hrisResponsesData: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_EMPLOYER_BEN_ADMIN_RESPONSE_IN_PROGRESS:
      return {
        ...state,
        benAdminResponseData: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.GET_EMPLOYER_BEN_ADMIN_RESPONSE_COMPLETED:
      return {
        ...state,
        benAdminResponseData: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };  
    case ActionTypes.GET_EMPLOYER_BEN_ADMIN_RESPONSE_FAILED:
      return {
        ...state,
        benAdminResponseData: {
        inProgress: false,
        error: action.payload
        }
      };    
    case ActionTypes.GET_EMPLOYER_IN_PROGRESS:
      return {
        ...state,
        employer: {
          inProgress: true,
          error: null,
          data: null
        }
      };
    case ActionTypes.GET_EMPLOYER_COMPLETED:
      return {
        ...state,
        employer: {
          inProgress: false,
          error: null,
          data: action.payload
        }
      };
    case ActionTypes.GET_EMPLOYER_FAILED:
      return {
        ...state,
        employer: {
          inProgress: false,
          error: action.payload,
          data: null
        }
      };
      case ActionTypes.GET_EMPLOYER_INDUSTRIES_IN_PROGRESS:
        return {
          ...state,
          industries: {
            inProgress: true,
            error: null,
            data: null
          }
        };
      case ActionTypes.GET_EMPLOYER_INDUSTRIES_COMPLETED:
        return {
          ...state,
          industries: {
            inProgress: false,
            error: null,
            data: action.payload
          }
        };
      case ActionTypes.GET_EMPLOYER_INDUSTRIES_FAILED:
        return {
          ...state,
          industries: {
            inProgress: false,
            error: action.payload,
            data: null
          }
        };  
    default:
      return {
        ...state
      };
  }
};
