/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Row, Col } from 'components/Atoms';
import { NotificationManager } from 'react-notifications';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import {
  getBambooHRHRISFields,
  getBHRSetupDetails,
  saveBambooHrisConfig,
  updateBambooHrisConfig,
  deleteBambooHrisConfig,
  saveLFIConfig,
  getWorkdayHRISFields,
  saveWorkdayConfig,
  updateWorkdayConfig
} from '../../actions/integrationsAction';
import { permitIf } from 'components/hoc/Permit';
import { INTEGRATIONS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { getPlanYearByType } from 'modules/Employers/actions/planYearActions';
import * as ActionTypes from '../../actionTypes/integrations.actionTypes';
import PageActionButton from 'components/Buttons/PageActionButton';
import DialogIntegrationDetails from './components/DialogIntegrationDetails/DialogIntegrationDetails';
import './CreateIntegration.scss';
import {
  BAMBOO_HR_CONFIG_ERROR_LABEL_CODE_MAP,
  VENDOR_NAMES,
  WORKDAY_DATE_FORMAT,
  BAMBOO_HR_SAVE_TYPE,
  LFI_CONFIG_ERROR_LABEL_CODE_MAP
} from 'modules/Integrations/constants';
import { PLAN_YEAR_TYPES } from 'constants/employerConstants';

class CreateIntegration extends React.Component {
  constructor(props) {
    super(props);
    const { employerId } = this.props.match.params;
    this.employerId = employerId;
    this.state = {
      isEditable: false,
      bShowAddDialog: false
    };
  }

  componentDidMount() {
    const {
      integrationConfigDetails,
      history,
      bambooConfigDetails,
      match,
      getPlanYearByType
    } = this.props;
    const { params } = match;
    const { location } = history;
    const { state } = location;

    let selectedVendor = {
      name: VENDOR_NAMES.WORKDAY
    };

    let screenNo = _.defaultTo(state && state.step, 2);
    getPlanYearByType(this.employerId, PLAN_YEAR_TYPES.CURRENT);

    if (
      integrationConfigDetails !== null &&
      params.edit &&
      state &&
      state.vendorType === VENDOR_NAMES.WORKDAY
    ) {
      this.setState({
        bShowAddDialog: true,
        selectedVendor: selectedVendor,
        step: screenNo,
        isEditable: true
      });
    }

    if (
      bambooConfigDetails !== null &&
      params.edit &&
      state &&
      state.vendorType === VENDOR_NAMES.BAMBOO_HR
    ) {
      this.setState({
        bShowAddDialog: true,
        selectedVendor: { name: VENDOR_NAMES.BAMBOO_HR },
        step: screenNo,
        isEditable: true
      });
    }

    if (params.edit && !integrationConfigDetails && !bambooConfigDetails) {
      this.props.history.push(
        `/brokers/${params.brokerId}/employers/${this.employerId}/integrations`
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { requestStatus, serviceError, match } = this.props;
    const { params } = match;
    const { brokerId } = params;
    const {
      requestStatus: prevRequestStatus,
      serviceError: prevServiceError
    } = prevProps;

    if (serviceError && serviceError !== prevServiceError) {
      const errorCode =
        serviceError.response &&
        serviceError.response.data &&
        serviceError.response.data.code;
      if (
        BAMBOO_HR_CONFIG_ERROR_LABEL_CODE_MAP[errorCode] ||
        LFI_CONFIG_ERROR_LABEL_CODE_MAP[errorCode]
      ) {
        NotificationManager.error('Invalid Configuration');
      } else {
        // Generic Error
        NotificationManager.error(serviceError.message);
      }
    }

    if (prevRequestStatus !== requestStatus) {
      switch (requestStatus) {
        case ActionTypes.SAVE_BAMBOO_HRIS_CONFIG_SUCCESS:
        case ActionTypes.SAVE_LFI_HRIS_CONFIG_SUCCESS:
        case ActionTypes.SAVE_WORKDAY_HRIS_CONFIG_SUCCESS: {
          NotificationManager.success(
            `Configuration has been saved Successfully`
          );
          this.setState(
            {
              bShowAddDialog: !this.state.bShowAddDialog
            },
            () => {
              this.props.history.push(
                `/brokers/${brokerId}/employers/${this.employerId}/integrations`
              );
              return;
            }
          );
          return;
        }

        case ActionTypes.UPDATE_WORKDAY_HRIS_CONFIG_SUCCESS:
          NotificationManager.success(
            `Configuration has been updated Successfully`
          );
          this.setState(
            {
              bShowAddDialog: !this.state.bShowAddDialog
            },
            () => {
              this.props.history.push(
                `/brokers/${brokerId}/employers/${this.employerId}/integrations`
              );
              return;
            }
          );
          return;

        default:
          break;
      }
    }
  }

  toggleAddDialog = (isEditable = false) => {
    const { match } = this.props;
    const { params } = match;
    const { brokerId } = params;
    if (isEditable) {
      this.setState(
        {
          bShowAddDialog: !this.state.bShowAddDialog
        },
        () => {
          this.props.history.push(
            `/brokers/${brokerId}/employers/${this.employerId}/integrations`
          );
          return;
        }
      );
    } else {
      this.setState({
        bShowAddDialog: !this.state.bShowAddDialog
      });
    }
  };

  showAddDialog = () => {
    this.setState({
      bShowAddDialog: true
    });
  };

  saveBambooHrConfiguration = (config, saveType=BAMBOO_HR_SAVE_TYPE.MAPPINGS_CONFIG) => {
    if (config.ignoreTerminatedEmployeesCutoffDate) {
      config.ignoreTerminatedEmployeesCutoffDate = moment(
        config.ignoreTerminatedEmployeesCutoffDate
      ).format('YYYY-MM-DD');
    }
    this.props.saveBambooHrisConfig(this.employerId, config, saveType);
  };

  saveLFIConfiguration = (config) => {
    this.props.saveLFIConfig(this.employerId, config);
  };

  saveWorkdayConfiguration = (config, isEditable = false) => {
    if (config.ignoreTerminatedEmployeesCutoffDate) {
      config.ignoreTerminatedEmployeesCutoffDate = moment(
        config.ignoreTerminatedEmployeesCutoffDate
      ).format(WORKDAY_DATE_FORMAT);
    }

    if (isEditable) {
      this.props.updateWorkdayConfig(this.employerId, config);
    } else {
      this.props.saveWorkdayConfig(this.employerId, config);
    }
  };

  getVendorInfo = (selectedVendor) => {
    switch (selectedVendor) {
      case VENDOR_NAMES.BAMBOO_HR:
        return {
          vendorCustomFieldList: this.props.bambooHrisConfigFields,
          getVendorCustomFields: this.props.getBambooHRHRISFields,
          vendorSetupDetails: this.props.bambooHrSetupDetails,
          getVendorSetupDetails: this.props.getBHRSetupDetails,
          saveConfiguration: this.saveBambooHrConfiguration
        };
      case VENDOR_NAMES.GFI:
        return {
          vendorSetupDetails: this.props.vendorSetupDetails,
          getVendorSetupDetails: () => {},
          saveConfiguration: this.saveLFIConfiguration
        };
      case VENDOR_NAMES.WORKDAY:
        return {
          vendorCustomFieldList: this.props.workdayConfigFields,
          getVendorCustomFields: this.props.getWorkdayHRISFields,
          vendorSetupDetails: this.props.bambooHrSetupDetails,
          saveConfiguration: this.saveWorkdayConfiguration
        };
      default:
        break;
    }
  };

  render() {
    const {
      currentPlanYear,
      requestStatus,
      integrationConfigDetails,
      bambooConfigDetails,
      history,
      getBHRSetupDetails,
      match
    } = this.props;
    const { bShowAddDialog, step, selectedVendor } = this.state;
    const benefitGroups = currentPlanYear ? currentPlanYear.benefitGroups : [];

    const SecuredSetupButton = permitIf(
      PageActionButton,
      INTEGRATIONS_FEATURE_ROLE_MAPPING.setup
    );
    return (
      <ApplicationWrapper>
        <HeaderContainer title="Integrations" />
        <Row className="page-create-integration">
          <Col className="page-content">
            <ContentContainer className="content">
              <SecuredSetupButton
                style={{ padding: '10px 30px', margin: 'auto' }}
                onClick={this.showAddDialog}
              >
                Add HRIS Integration
              </SecuredSetupButton>
            </ContentContainer>
          </Col>
          <Col className="page-actions"></Col>
        </Row>
        <DialogIntegrationDetails
          employerId={this.employerId}
          isOpen={bShowAddDialog}
          toggle={this.toggleAddDialog}
          requestStatus={requestStatus}
          employerBenefitGroups={benefitGroups}
          getVendorInfo={this.getVendorInfo}
          step={_.defaultTo(step, 1)}
          selectedVendor={_.defaultTo(selectedVendor, null)}
          integrationConfigDetails={integrationConfigDetails}
          bambooConfigDetails={bambooConfigDetails}
          getBHRSetupDetails={getBHRSetupDetails}
          history={history}
          match={match}
        />
      </ApplicationWrapper>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getWorkdayHRISFields: () => dispatch(getWorkdayHRISFields()),
  getBambooHRHRISFields: () => dispatch(getBambooHRHRISFields()),
  getBHRSetupDetails: (config) => dispatch(getBHRSetupDetails(config)),
  saveBambooHrisConfig: (employerId, config, saveType) =>
    dispatch(saveBambooHrisConfig(employerId, config, saveType)),
  updateBambooHrisConfig: (employerId, config) =>
    dispatch(updateBambooHrisConfig(employerId, config)),
  deleteBambooHrisConfig: (employerId, config) =>
    dispatch(deleteBambooHrisConfig(employerId, config)),
  saveLFIConfig: (employerId, config) =>
    dispatch(saveLFIConfig(employerId, config)),
  saveWorkdayConfig: (employerId, config) =>
    dispatch(saveWorkdayConfig(employerId, config)),
  updateWorkdayConfig: (employerId, config) =>
    dispatch(updateWorkdayConfig(employerId, config)),
  getPlanYearByType: (employerId, planYearType) =>
    dispatch(getPlanYearByType(employerId, planYearType))
});
const mapStateToProps = (state) => {
  const {
    requestStatus,
    serviceError,
    isLoading,
    bambooHrisConfigFields,
    bambooHrSetupDetails,
    vendorSetupDetails,
    workdayConfigFields,
    integrationConfigDetails,
    bambooConfigDetails
  } = state.integrationsReducer;
  const { planYearByType } = state.Employers.planYearReducer;
  return {
    requestStatus,
    isLoading,
    serviceError,
    bambooHrisConfigFields,
    bambooHrSetupDetails,
    vendorSetupDetails,
    workdayConfigFields,
    integrationConfigDetails,
    bambooConfigDetails,
    currentPlanYear: planYearByType.data[0]
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateIntegration);
