/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import axios from 'axios';
import Util from '../util/apiUtil';

//configs
const baseURL = `${Util.baseApi}/${Util.version}/employers`;

export default {
  updateEmployerRenewalPeriodConfig(employerId, renewalPeriodConfig) {
    return axios.put(`${baseURL}/${employerId}/offers`, renewalPeriodConfig);
  },
  updateChatbotConfig(employerId, chatbotConfig) {
    return axios.put(`${baseURL}/${employerId}/chatbot-config`, chatbotConfig);
  },
  updateChatbotFallbackOption(employerId, chatbotConfig) {
    return axios.put(
      `${baseURL}/${employerId}/chatbot-fallback`,
      chatbotConfig
    );
  }
};
