/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import { UsaStates } from 'usa-states';
import SelectFormGroup from './SelectFormGroup';
import { ENDASH_LITERAL } from '../../constants/commonConstants';

export const USA_STATE_FIELD_TYPE_NAME = 'name';
export const USA_STATE_FIELD_TYPE_ABBREVIATION = 'abbreviation';

const UsaStateSelector = (props) => {
  const {
    labelDisplay,
    inputName,
    inputValue,
    onChange,
    placeholder,
    displayType,
    valueType,
    includeTerritories,
    disabled,
    isInvalid
  } = props;

  const stateOptions = new UsaStates({
    includeTerritories: includeTerritories
  }).format({
    text: displayType,
    value: valueType
  });

  return (
    <SelectFormGroup
      labelDisplay={labelDisplay}
      inputName={inputName}
      inputValue={inputValue || ''}
      onChange={onChange}
      options={stateOptions}
      placeholder={placeholder}
      disabled={disabled}
      isInvalid={isInvalid}
    />
  );
};

UsaStateSelector.propTypes = {
  labelDisplay: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  inputName: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  // Expects (event) => any
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  displayType: PropTypes.oneOf([
    USA_STATE_FIELD_TYPE_NAME,
    USA_STATE_FIELD_TYPE_ABBREVIATION
  ]),
  valueType: PropTypes.oneOf([
    USA_STATE_FIELD_TYPE_NAME,
    USA_STATE_FIELD_TYPE_ABBREVIATION
  ]),
  includeTerritories: PropTypes.bool
};

UsaStateSelector.defaultProps = {
  labelDisplay: 'State',
  placeholder: `${ENDASH_LITERAL} ${ENDASH_LITERAL}`,
  displayType: USA_STATE_FIELD_TYPE_ABBREVIATION,
  valueType: USA_STATE_FIELD_TYPE_ABBREVIATION,
  includeTerritories: true
};

export default UsaStateSelector;
