/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const GET_OFFER_PLANS = 'GET_OFFER_PLANS';
export const GET_OFFER_DENTAL_PLANS = 'GET_OFFER_DENTAL_PLANS';
export const GET_OFFER_VISION_PLANS = 'GET_OFFER_VISION_PLANS';
export const GET_OFFER_LIFE_PLANS = 'GET_OFFER_LIFE_PLANS';
export const UPLOAD_OFFER_PLANS_IN_PROGRESS = 'UPLOAD_OFFER_PLANS_IN_PROGRESS';
export const UPLOAD_OFFER_PROGRESS_NOTIFICATION =
  'UPLOAD_OFFER_PROGRESS_NOTIFICATION';
export const UPLOAD_OFFER_PLANS = 'UPLOAD_OFFER_PLANS';
export const UPLOAD_OFFER_ERROR = 'UPLOAD_OFFER_ERROR';
export const GET_OFFER_PLANS_ERROR = 'GET_OFFER_PLANS_ERROR';

export const CLEAR_EXIST_MODELING_DATA = 'CLEAR_EXIST_MODELING_DATA';
export const GET_MEDICAL_MODELING_DATA = 'GET_MEDICAL_MODELING_DATA';
export const GET_DENTAL_MODELING_DATA = 'GET_DENTAL_MODELING_DATA';
export const GET_VISION_MODELING_DATA = 'GET_VISION_MODELING_DATA';
export const GET_MODELING_DATA_ERROR = 'GET_MODELING_DATA_ERROR';
export const SAVING_MODELING_DATA_ERROR = 'SAVING_MODELING_DATA_ERROR';
export const SAVE_INPROGRESS_MODELING_DATA = 'SAVE_INPROGRESS_MODELING_DATA';
export const SAVED_SUCCESS_MODELING_DATA = 'SAVED_SUCCESS_MODELING_DATA';
export const UPDATE_PROPOSAL_MODELING_FIELD_PENDING =
  'UPDATE_PROPOSAL_MODELING_FIELD_PENDING';

export const UPDATE_PLAN_CONTRIBUTIONS = 'UPDATE_PLAN_CONTRIBUTIONS';
export const UPDATE_PLAN_CONTRIBUTIONS_ERROR = 'UPDATE_PLAN_CONTRIBUTIONS_ERROR';

export const GET_OFFERS = 'GET_OFFERS';
export const GET_DENTAL_OFFERS = 'GET_DENTAL_OFFERS';
export const GET_VISION_OFFERS = 'GET_VISION_OFFERS';
export const GET_LIFE_OFFERS = 'GET_LIFE_OFFERS';
export const GET_OFFERS_ERROR = 'GET_OFFERS_ERROR';
export const GET_OFFERS_TO_PUBLISH_ERROR = 'GET_OFFERS_TO_PUBLISH_ERROR';
export const CREATE_OFFER = 'CREATE_OFFER';
export const CREATE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS';
export const CREATE_OFFER_ERROR = 'CREATE_OFFER_ERROR';
export const ADD_PLANS_TO_OFFER_ERROR = 'ADD_PLANS_TO_OFFER_ERROR';
export const ADD_PLANS_TO_OFFER_SUCCESS = 'ADD_PLANS_TO_OFFER_SUCCESS';
export const ADD_PLANS_TO_OFFER_IN_PROGRESS = 'ADD_PLANS_TO_OFFER_IN_PROGRESS';
export const GET_MEDICAL_OFFERS_TO_PUBLISH = 'GET_MEDICAL_OFFERS_TO_PUBLISH';
export const GET_DENTAL_OFFERS_TO_PUBLISH = 'GET_DENTAL_OFFERS_TO_PUBLISH';
export const GET_VISION_OFFERS_TO_PUBLISH = 'GET_VISION_OFFERS_TO_PUBLISH';
export const GET_LIFE_OFFERS_TO_PUBLISH = 'GET_LIFE_OFFERS_TO_PUBLISH';
export const PUBLISH_OFFER_ERROR = 'PUBLISH_OFFER_ERROR';

export const GET_CURRENT_PLAN_NAMES = 'GET_CURRENT_PLAN_NAMES';
export const GET_CURRENT_PLAN_NAMES_ERROR = 'GET_CURRENT_PLAN_NAMES_ERROR';

export const SET_EDIT_MODE = 'SET_EDIT_MODE';

export const GET_OFFER_BUNDLE_IN_PROGRESS = 'GET_OFFER_BUNDLE_IN_PROGRESS';
export const GET_OFFER_BUNDLE_SUCCESS = 'GET_OFFER_BUNDLE_SUCCESS';
export const GET_OFFER_BUNDLE_ERROR = 'GET_OFFER_BUNDLE_ERROR';

export const GET_CARRIERS_IN_PROGRESS = 'GET_CARRIERS_IN_PROGRESS';
export const GET_CARRIERS_SUCCESS = 'GET_CARRIERS_SUCCESS';
export const GET_CARRIERS_ERROR = 'GET_CARRIERS_ERROR';

export const GET_CURRENT_PLAN_FILE_HISTORY_IN_PROGRESS =
  'GET_CURRENT_PLAN_FILE_HISTORY_IN_PROGRESS';
export const GET_CURRENT_PLAN_FILE_HISTORY_SUCCESS =
  'GET_CURRENT_PLAN_FILE_HISTORY_SUCCESS';
export const GET_CURRENT_PLAN_FILE_HISTORY_ERROR =
  'GET_CURRENT_PLAN_FILE_HISTORY_ERROR';

export const DOWNLOAD_FILE_HISTORY_IN_PROGRESS =
  'DOWNLOAD_FILE_HISTORY_IN_PROGRESS';
export const DOWNLOAD_FILE_HISTORY_SUCCESS = 'DOWNLOAD_FILE_HISTORY_SUCCESS';
export const DOWNLOAD_FILE_HISTORY_ERROR = 'DOWNLOAD_FILE_HISTORY_ERROR';
