import React from 'react';
import { Col, Row, Label } from 'components/Atoms';
import Icon from 'components/Icons';

import './HeaderContainer.scss';

const HeaderContainer = (props) => {
  const {
    actions,
    title,
    subtitle,
    history,
    backButtonUnderTittle,
    backMsg,
    redirectBack,
    rightSideLabel
  } = props;
  return (
    <div className="mb-3">
      <Row className="mb-2">
        {!props.children && (
          <Col xs={actions ? '4' : '12'}>
            <h1 className="header">{title}</h1>
            {backButtonUnderTittle && (
              <Label
                className="header-back-button"
                onClick={() =>
                  redirectBack ? redirectBack() : history.goBack()
                }
              >
                <Icon
                  size="xs"
                  icon="chevron-left"
                  className="back-icon-margin-right"
                />
                {backMsg ? <>{backMsg}</> : <>Back</>}
              </Label>
            )}
          </Col>
        )}
        {props.children && rightSideLabel && (
          <>
            <Col xs={actions ? '2' : '6'}>
              <h1 className="header">{title}</h1>
            </Col>
            <Col xs={actions ? '2' : '6'}>{props.children}</Col>
          </>
        )}
        {props.children && !rightSideLabel && (
          <>
            <Col xs={actions ? '2' : '8'}>
              <h1 className="header">{title}</h1>
            </Col>
            <Col xs={actions ? '2' : '4'}>{props.children}</Col>
          </>
        )}
        {actions && (
          <Col xs="8" className="text-right">
            {actions}
          </Col>
        )}
      </Row>
      {subtitle && (
        <Row>
          <Col xs="auto">
            <h2 muted className="subtitle">
              {subtitle}
            </h2>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default HeaderContainer;
