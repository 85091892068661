import React from 'react';
import { connect } from 'react-redux';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import TabView from './components/TabView/TabView';
import PercentileSlider from './components/PercentileSlider/PercentileSlider';
import CurrentBenchmarkDetails from './components/CurrentBenchmarkDetails/CurrentBenchmarkDetails';
import ClusterEmployers from './components/ClusterEmployers/ClusterEmployers';
import { Row, Col, Button } from 'components/Atoms';
import './BenchmarkModeling.scss';
import PopulatePlanDetailsModal from '../../../components/PopulatePlanDetailsModal/PopulatePlanDetailsModal';
import SimilarPlansModal from '../../../components/SimilarPlansModal/SimilarPlansModal';
import Loader from 'components/CircularProgress';
import PageActionButton from 'components/Buttons';
import { Fade } from 'reactstrap';
import Summary from './components/Summary/Summary';

import _ from 'lodash';

import {
  findBenchmark,
  saveBenchmark,
  getMetadata
} from '../../../actions/benchmarkActions';

import {
  TYPE_DEDUCTIBLE,
  TYPE_OOP_MAX,
  TYPE_COPAY,
  TYPE_RX_COST_TIER,
  TYPE_CONTRIBUTION,
  TYPE_RATES,
  BENEFIT_KIND_MEDICAL,
  BENEFIT_KIND_DENTAL,
  BENEFIT_KIND_VISION,
  TYPE_HSA_CONTRIBUTION,
  COINSURANCE
} from '../../../constants';
import { redirectBack } from '../../../util';
import Notifications from 'components/Notifications';

class MedicalBenchmark extends React.Component {
  constructor(props) {
    super(props);

    const { benchmark } = this.props;

    this.state = {
      isAddModalOpen: false,
      currentPlanDetails: [],
      planTypes: [],
      isOtherPlansModalOpen: false,
      benchmarkData: benchmark,
      selectedBenchmarkId: '',
      percentileRange: [],
      validations: {},
      selectedCurrentPlanId: null,
      isBenchmarkPlanEdited: false,
      selectedPlanTabType: null,
      otherPlansInRange: 0,
      totalOtherPlans: 0,
      selectBenchmarkPlan: false,
      fade: true
    };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  toggleOtherPlansModal = () => {
    this.setState((prevState) => ({
      isOtherPlansModalOpen: !prevState.isOtherPlansModalOpen
    }));
  };

  toggleAddModal = () => {
    this.setState((prevState) => ({
      isAddModalOpen: !prevState.isAddModalOpen
    }));
  };

  componentDidMount() {
    const { match, findBenchmark, getMetadata } = this.props;

    const { benchmarkId } = match.params;

    if (benchmarkId) {
      // Fetches the benchmark
      findBenchmark(benchmarkId);
      // Fetches metadata
      getMetadata();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      benchmark,
      benchmarkSaveResult,
      match,
      findBenchmark,
      metadata,
      benchmarkSaveError
    } = this.props;
    const { benefitCategory, employerId, brokerId } = match.params;
    const { selectBenchmarkPlan, benchmarkData } = this.state;

    if (benchmarkSaveResult !== prevProps.benchmarkSaveResult) {
      if (selectBenchmarkPlan) {
        this.setState({
          selectBenchmarkPlan: false
        });
        findBenchmark(benchmarkSaveResult.benchmarkId);
        return;
      }
      if (!this.state.isBenchmarkPlanEdited) {
        if (benefitCategory === BENEFIT_KIND_MEDICAL) {
          this.props.history.push(
            `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkSaveResult.benchmarkId}/edit/dental`
          );
        }
        if (benefitCategory === BENEFIT_KIND_DENTAL) {
          this.props.history.push(
            `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkSaveResult.benchmarkId}/edit/vision`
          );
        }
        if (benefitCategory === BENEFIT_KIND_VISION) {
          this.props.history.push(
            `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkSaveResult.benchmarkId}/edit/benchmark/publish`
          );
        }
      } else {
        if (benchmarkSaveResult.benchmarkId) {
          // Fetches the benchmark
          findBenchmark(benchmarkSaveResult.benchmarkId);
        }
        Notifications.success('Benchmark details has been saved successfully');
      }
      this.onUpdateFilteredPlans(true);
    }

    if (prevState.benchmarkData !== this.state.benchmarkData) {
      this.onUpdateFilteredPlans(true);
    }

    if (
      benefitCategory !== prevProps.match.params.benefitCategory &&
      match.path.includes('/view/')
    ) {
      const currentPlans =
        benchmarkData.currentPlans[benefitCategory.toUpperCase()];
      if (currentPlans && currentPlans.length > 0) {
        this.setState({
          selectedCurrentPlanId: currentPlans[0].id,
          selectedPlanTabType: currentPlans[0].planType
        });
      }
    }

    if (metadata !== prevProps.metadata) {
      this.setState({
        metadata: { ...metadata }
      });
    }

    if (benchmark !== prevProps.benchmark) {
      this.setState({
        benchmarkData: { ...benchmark }
      });
      if (benchmark && !_.isEmpty(benchmark)) {
        document.getElementById('benchmark-plan') &&
          document
            .getElementById('benchmark-plan')
            .scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }

    if (
      benchmarkSaveError &&
      benchmarkSaveError !== prevProps.benchmarkSaveError
    ) {
      if (
        benchmarkSaveError.response &&
        benchmarkSaveError.response.data &&
        benchmarkSaveError.response.data.code
      ) {
        Notifications.error(benchmarkSaveError.response.data.code);
      } else {
        Notifications.error('Failed to save benchmark details');
      }
    }
  }

  makeSelectedPlanAsBenchmarkPlan = (selectedBenchmarkPlan, planKey) => {
    this.setState(
      {
        fade: false,
        selectedKey: planKey
      },
      () => {
        this.setSelectedPlanAsBenchmarkPlan(selectedBenchmarkPlan);
      }
    );
  };

  setSelectedPlanAsBenchmarkPlan = (selectedBenchmarkPlan) => {
    const { benchmarkData, selectedCurrentPlanId } = this.state;

    const {
      benefitCategory,
      employerId,
      benchmarkId
    } = this.props.match.params;

    benchmarkData.benchmarks[benefitCategory.toUpperCase()].forEach(
      (benchmarkPlanDetails) => {
        if (
          !benchmarkPlanDetails.benchmarkPlan ||
          benchmarkPlanDetails.benchmarkPlan.id !== selectedBenchmarkPlan.id
        ) {
          if (
            benchmarkPlanDetails.otherPlans &&
            benchmarkPlanDetails.currentPlan.id === selectedCurrentPlanId
          ) {
            benchmarkPlanDetails.otherPlans.forEach((otherPlan) => {
              if (otherPlan.id === selectedBenchmarkPlan.id) {
                let toBeRemovedPlan = _.filter(
                  benchmarkPlanDetails.otherPlans,
                  { id: selectedBenchmarkPlan.id }
                );

                // Remove selected benchmark plan from other plans
                _.remove(benchmarkPlanDetails.otherPlans, {
                  id: selectedBenchmarkPlan.id
                });

                // Add the current benchmark plans to other plans
                if (benchmarkPlanDetails.benchmarkPlan) {
                  benchmarkPlanDetails.otherPlans.push(
                    benchmarkPlanDetails.benchmarkPlan
                  );
                }

                // Replace current benchmark with selected benchmark plan
                benchmarkPlanDetails.benchmarkPlan = toBeRemovedPlan[0];
              }
            });
          }
        }
      }
    );

    this.setState(
      (prevState) => ({
        benchmarkData: {
          ...prevState.benchmarkData
        },
        fade: true,
        selectBenchmarkPlan: true
      }),
      () => {
        this.onUpdateFilteredPlans(true);
        this.props.saveBenchmark(
          benchmarkId,
          benchmarkData,
          employerId,
          'benchmark-plans',
          benefitCategory.toUpperCase()
        );

        document
          .getElementById('benchmark-plan')
          .scrollIntoView({ behavior: 'smooth', block: 'center' });
        Notifications.success(
          `Successfully selected benchmark plan - ${selectedBenchmarkPlan.planName}`
        );
      }
    );
  };

  updateSinglePlanDetail = (name, value, benchmarkPlan, type = undefined) => {
    const planDesign = benchmarkPlan.planDesign;

    if (type === undefined) {
      planDesign[name] = value;
    } else {
      switch (type) {
        case TYPE_DEDUCTIBLE:
          const deductibles = planDesign.deductible;
          _.assign(deductibles, {
            [name]: value
          });
          break;
        case TYPE_OOP_MAX:
          const oopMaxes = planDesign.oopMax;
          _.assign(oopMaxes, {
            [name]: value
          });
          break;
        case TYPE_COPAY:
          const copays = planDesign.copay;
          _.assign(copays, {
            [name]: value
          });
          break;
        case TYPE_RX_COST_TIER:
          const rxCosts = planDesign.rxCost;
          _.assign(rxCosts, {
            [name]: value
          });
          break;
        case TYPE_CONTRIBUTION:
          const contributions = benchmarkPlan.contribution || {};
          _.assign(contributions, {
            [name]: value
          });
          benchmarkPlan.contribution = contributions;
          break;
        case TYPE_RATES:
          const rates = benchmarkPlan.rates || {};
          _.assign(rates, {
            [name]: value
          });
          benchmarkPlan.rates = rates;
          break;
        case TYPE_HSA_CONTRIBUTION:
          const hsaContribution = benchmarkPlan.hsaContribution || {};
          _.assign(hsaContribution, {
            [name]: value
          });
          benchmarkPlan.hsaContribution = hsaContribution;
          break;
        case COINSURANCE:
          const coinsurances = planDesign.coinsurance;
          _.assign(coinsurances, {
            [name]: value
          });
          break;
        default:
        //TODO
      }
    }

    this.setState((prevState) => ({
      benchmarkData: {
        ...prevState.benchmarkData
      }
    }));
  };

  otherBenchmarkDataValidations = async (benefitCategory) => {
    this.state.benchmarkData.benchmarks[benefitCategory.toUpperCase()].forEach(
      (planItem) => {
        const plan = planItem.benchmarkPlan;
        Object.keys(plan.planDesign).forEach((key) => {
          if (!plan.planDesign[key]) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                [key]: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                [key]: false
              }
            }));
          }
        });
        if (benefitCategory === BENEFIT_KIND_DENTAL) {
          if (!_.isEmpty(plan.planDesign.deductible)) {
            // Deductible
            if (!plan.planDesign.deductible['single']) {
              this.setState((prevState) => ({
                validations: {
                  ...prevState.validations,
                  isDeductibleSingleEmpty: true
                }
              }));
            } else {
              this.setState((prevState) => ({
                validations: {
                  ...prevState.validations,
                  isDeductibleSingleEmpty: false
                }
              }));
            }
            if (!plan.planDesign.deductible['family']) {
              this.setState((prevState) => ({
                validations: {
                  ...prevState.validations,
                  isDeductibleFamilyEmpty: true
                }
              }));
            } else {
              this.setState((prevState) => ({
                validations: {
                  ...prevState.validations,
                  isDeductibleFamilyEmpty: false
                }
              }));
            }
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isDeductibleSingleEmpty: true,
                isDeductibleFamilyEmpty: true
              }
            }));
          }
        }
      }
    );
    await this.validateRatesAndContributions(benefitCategory);
  };

  saveEditedValues = (isEditMode) => {
    if (isEditMode) {
      const { match, saveBenchmark } = this.props;

      const { employerId, benchmarkId, benefitCategory } = match.params;
      const { benchmarkData } = this.state;

      saveBenchmark(
        benchmarkId,
        benchmarkData,
        employerId,
        'benchmark-plans',
        benefitCategory.toUpperCase()
      );

      this.setState({
        isBenchmarkPlanEdited: true
      });
    }
  };

  cancelEditMode = (beforeEditBenchmark) => {
    const { benchmarkData, selectedCurrentPlanId } = this.state;
    const { benchmarks } = benchmarkData;
    const currentPlans = benchmarks[beforeEditBenchmark.benefitCategory];
    benchmarkData.benchmarks[
      beforeEditBenchmark.benefitCategory
    ] = currentPlans.map((plans) => {
      const { currentPlan } = plans;
      if (currentPlan.id === selectedCurrentPlanId) {
        plans.benchmarkPlan = beforeEditBenchmark;
      }
      return plans;
    });
    this.setState({ benchmarkData: benchmarkData });
  };

  unSelectPlanAsBenchmarkPlan = (selectedBenchmarkPlan) => {
    const { benchmarkData, selectedCurrentPlanId } = this.state;

    const {
      benefitCategory,
      employerId,
      benchmarkId
    } = this.props.match.params;
    benchmarkData.benchmarks[benefitCategory.toUpperCase()].forEach(
      (benchmarkPlanDetails) => {
        if (
          benchmarkPlanDetails.benchmarkPlan &&
          benchmarkPlanDetails.benchmarkPlan.id === selectedBenchmarkPlan.id
        ) {
          if (
            benchmarkPlanDetails.otherPlans &&
            benchmarkPlanDetails.currentPlan.id === selectedCurrentPlanId
          ) {
            let toBeRemovedPlan = _.filter(benchmarkPlanDetails.benchmarkPlan, {
              id: selectedBenchmarkPlan.id
            });

            // Remove selected benchmark plan from benchmark plans
            _.remove(benchmarkPlanDetails.benchmarkPlan, {
              id: selectedBenchmarkPlan.id
            });

            // Add the current benchmark plans to other plans
            benchmarkPlanDetails.otherPlans.push(
              benchmarkPlanDetails.benchmarkPlan
            );
            benchmarkPlanDetails.benchmarkPlan = toBeRemovedPlan[0];
          }
        }
      }
    );

    this.setState(
      (prevState) => ({
        benchmarkData: {
          ...prevState.benchmarkData
        },
        selectBenchmarkPlan: true
      }),
      () => {
        this.onUpdateFilteredPlans(true);
        this.props.saveBenchmark(
          benchmarkId,
          benchmarkData,
          employerId,
          'benchmark-plans',
          benefitCategory.toUpperCase()
        );

        Notifications.success(
          `Successfully unselected benchmark plan - ${selectedBenchmarkPlan.planName}`
        );
      }
    );
  };

  validateRatesAndContributions = async (benefitCategory) => {
    this.state.benchmarkData.benchmarks[benefitCategory.toUpperCase()].forEach(
      (planItem) => {
        const plan = planItem.benchmarkPlan;
        if (
          (plan.contribution && !_.isEmpty(plan.contribution)) ||
          (plan.rates && !_.isEmpty(plan.rates))
        ) {
          // Contribution
          if (!plan.contribution['EE']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isContributionEEEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isContributionEEEmpty: false
              }
            }));
          }
          if (!plan.contribution['ES']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isContributionESEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isContributionESEmpty: false
              }
            }));
          }
          if (!plan.contribution['EC']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isContributionECEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isContributionECEmpty: false
              }
            }));
          }
          if (!plan.contribution['EF']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isContributionEFEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isContributionEFEmpty: false
              }
            }));
          }
          // Rates
          if (!plan.rates['EE']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRatesEEEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRatesEEEmpty: false
              }
            }));
          }
          if (!plan.rates['ES']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRatesESEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRatesESEmpty: false
              }
            }));
          }
          if (!plan.rates['EC']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRatesECEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRatesECEmpty: false
              }
            }));
          }
          if (!plan.rates['EF']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRatesEFEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRatesEFEmpty: false
              }
            }));
          }
        } else {
          this.setState((prevState) => ({
            validations: {
              ...prevState.validations,
              isContributionEEEmpty: true,
              isContributionESEmpty: true,
              isContributionECEmpty: true,
              isContributionEFEmpty: true,
              isRatesEEEmpty: true,
              isRatesESEmpty: true,
              isRatesECEmpty: true,
              isRatesEFEmpty: true
            }
          }));
        }
      }
    );
  };

  validateMedicalBenchmarkData = async (benefitCategory) => {
    this.state.benchmarkData.benchmarks[benefitCategory.toUpperCase()].forEach(
      (planItem) => {
        const plan = planItem.benchmarkPlan;
        if (
          (plan && !_.isEmpty(plan.planDesign.deductible)) ||
          !_.isEmpty(plan.planDesign.oopMax) ||
          !_.isEmpty(plan.planDesign.copay) ||
          plan.planDesign['coinsurance'] ||
          !_.isEmpty(plan.planDesign.rxCost)
        ) {
          // Deductible
          if (
            plan &&
            plan.planDesign &&
            !plan.planDesign.deductible['single']
          ) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isDeductibleSingleEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isDeductibleSingleEmpty: false
              }
            }));
          }
          if (
            plan &&
            plan.planDesign &&
            !plan.planDesign.deductible['family']
          ) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isDeductibleFamilyEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isDeductibleFamilyEmpty: false
              }
            }));
          }
          // OOP Max
          if (plan && plan.planDesign && !plan.planDesign.oopMax['single']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isOopMaxSingleEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isOopMaxSingleEmpty: false
              }
            }));
          }
          if (plan && plan.planDesign && !plan.planDesign.oopMax['family']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isOopMaxFamilyEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isOopMaxFamilyEmpty: false
              }
            }));
          }
          // Copay
          if (plan && plan.planDesign && !plan.planDesign.copay['primary']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isCopayPrimaryEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isCopayPrimaryEmpty: false
              }
            }));
          }
          if (plan && plan.planDesign && !plan.planDesign.copay['specialist']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isCopaySpecialistEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isCopaySpecialistEmpty: false
              }
            }));
          }
          // Coinsurance
          if (plan && plan.planDesign && !plan.planDesign['coinsurance']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isCoinsuaranceEmpty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isCoinsuaranceEmpty: false
              }
            }));
          }
          // Rx Tiers
          if (plan && plan.planDesign && !plan.planDesign.rxCost['t1']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRxCostT1Empty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRxCostT1Empty: false
              }
            }));
          }
          if (plan && plan.planDesign && !plan.planDesign.rxCost['t2']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRxCostT2Empty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRxCostT2Empty: false
              }
            }));
          }
          if (plan && plan.planDesign && !plan.planDesign.rxCost['t3']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRxCostT3Empty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRxCostT3Empty: false
              }
            }));
          }
          if (plan && plan.planDesign && !plan.planDesign.rxCost['t4']) {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRxCostT4Empty: true
              }
            }));
          } else {
            this.setState((prevState) => ({
              validations: {
                ...prevState.validations,
                isRxCostT4Empty: false
              }
            }));
          }
        } else {
          this.setState((prevState) => ({
            validations: {
              ...prevState.validations,
              isDeductibleSingleEmpty: true,
              isDeductibleFamilyEmpty: true,
              isOopMaxSingleEmpty: true,
              isOopMaxFamilyEmpty: true,
              isCoinsuaranceEmpty: true,
              isCopayPrimaryEmpty: true,
              isCopaySpecialistEmpty: true,
              isRxCostT1Empty: true,
              isRxCostT2Empty: true,
              isRxCostT3Empty: true,
              isRxCostT4Empty: true
            }
          }));
        }
      }
    );
    await this.validateRatesAndContributions(benefitCategory);
  };

  saveAndNext = async (event) => {
    event.preventDefault();

    const { match, saveBenchmark } = this.props;

    const { employerId, benchmarkId, benefitCategory } = match.params;

    const { benchmarkData } = this.state;

    this.setState({
      isBenchmarkPlanEdited: false,
      fade: false
    });

    saveBenchmark(
      benchmarkId,
      benchmarkData,
      employerId,
      'benchmark-plans',
      benefitCategory.toUpperCase()
    );
  };

  percentileOnChange = (values) => {
    this.setState({
      percentileRange: values
    });
    this.onUpdateFilteredPlans();
  };

  onUpdateFilteredPlans = (setDefault = false) => {
    const {
      benchmarkData,
      selectedCurrentPlanId,
      percentileRange
    } = this.state;
    const { match } = this.props;
    const { benefitCategory } = match.params;
    let plansInRange = 0;
    let totalOtherPlans = 0;
    if (
      benchmarkData.benchmarks &&
      benchmarkData.benchmarks[benefitCategory.toUpperCase()] &&
      benchmarkData.benchmarks[benefitCategory.toUpperCase()].length > 0
    ) {
      let filteredOtherPlans = _.find(
        benchmarkData.benchmarks[benefitCategory.toUpperCase()],
        (plan) => {
          return (
            plan.currentPlan && plan.currentPlan.id === selectedCurrentPlanId
          );
        }
      );
      filteredOtherPlans = filteredOtherPlans
        ? filteredOtherPlans.otherPlans
        : undefined;
      if (filteredOtherPlans) {
        totalOtherPlans = filteredOtherPlans.length;
        plansInRange = _.filter(filteredOtherPlans, (otherPlan) => {
          const { percentile } = otherPlan || {};
          return _.every([
            _.inRange(percentile, percentileRange[0], percentileRange[1]) || percentile === percentileRange[1]
          ]);
        });
      }
    }
    this.setState({
      otherPlansInRange:
        setDefault && percentileRange.length === 0
          ? totalOtherPlans
          : plansInRange ? plansInRange.length : totalOtherPlans,
      totalOtherPlans: totalOtherPlans
    });
  };

  toggleSimilarPlansModal = () => {
    this.setState((prevState) => ({
      isSimilarPlansModalOpen: !prevState.isSimilarPlansModalOpen
    }));
  };

  onSelectBenchmarkPlan = (selectedBenchmarkPlan) => {
    const { benchmarkData, selectedCurrentPlanId } = this.state;
    const { match } = this.props;
    const { benefitCategory } = match.params;
    if (
      benchmarkData.benchmarks[benefitCategory.toUpperCase()] &&
      benchmarkData.benchmarks[benefitCategory.toUpperCase()].length > 0
    ) {
      let filteredBenchmark = _.find(
        benchmarkData.benchmarks[benefitCategory.toUpperCase()],
        ({ currentPlan }) => currentPlan.id === selectedCurrentPlanId
      );

      if (filteredBenchmark) {
        if (filteredBenchmark && !_.isEmpty(filteredBenchmark)) {
          filteredBenchmark.otherPlans.push(selectedBenchmarkPlan);
        }
        _.merge(
          benchmarkData.benchmarks[benefitCategory.toUpperCase()],
          filteredBenchmark
        );
      } else {
        this.generateBenchmarkForEmptyCluster(
          benchmarkData,
          selectedCurrentPlanId,
          benefitCategory,
          selectedBenchmarkPlan
        );
      }
    } else {
      this.generateBenchmarkForEmptyCluster(
        benchmarkData,
        selectedCurrentPlanId,
        benefitCategory,
        selectedBenchmarkPlan
      );
    }

    this.setSelectedPlanAsBenchmarkPlan(selectedBenchmarkPlan);
  };

  generateBenchmarkForEmptyCluster = (
    benchmarkData,
    selectedCurrentPlanId,
    benefitCategory,
    selectedBenchmarkPlan
  ) => {
    const filteredCurrentPlan = benchmarkData.currentPlans[
      benefitCategory.toUpperCase()
    ].find((currentPlan) => {
      return currentPlan.id === selectedCurrentPlanId;
    });

    let benchmarkObj = {
      currentPlan: filteredCurrentPlan,
      benchmarkPlan: undefined,
      otherPlans: [selectedBenchmarkPlan]
    };

    if (benchmarkData.benchmarks[benefitCategory.toUpperCase()]) {
      benchmarkData.benchmarks[benefitCategory.toUpperCase()].push(
        benchmarkObj
      );
    } else {
      benchmarkData.benchmarks[benefitCategory.toUpperCase()] = [
        {
          currentPlan: filteredCurrentPlan,
          benchmarkPlan: null,
          otherPlans: [selectedBenchmarkPlan]
        }
      ];
    }
  };

  onTabClick = (tabType) => {
    this.setState({
      selectedCurrentPlanId: tabType.planId,
      selectedPlanTabType: tabType.planType
    });
  };

  onTabRender = (planDetails) => {
    const { benchmarkData } = this.state;
    const { match } = this.props;
    const { benefitCategory } = match.params;
    let currentPlans =
      benchmarkData.currentPlans[benefitCategory.toUpperCase()];
    const planDetailsSplit = planDetails.split('_');
    if (currentPlans && currentPlans.length > 0 && planDetails) {
      this.setState(
        {
          selectedCurrentPlanId: currentPlans[planDetailsSplit[0]].id,
          selectedPlanTabType: planDetailsSplit[1]
        },
        () => {
          this.onUpdateFilteredPlans(true);
        }
      );
      this.percentileOnChange([0, 100]);
    }
  };

  next = () => {
    const { match } = this.props;
    const { benefitCategory, employerId, benchmarkId, brokerId } = match.params;
    if (benefitCategory === BENEFIT_KIND_MEDICAL) {
      this.props.history.push(
        `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkId}/view/dental/benchmark`
      );
    }
    if (benefitCategory === BENEFIT_KIND_DENTAL) {
      this.props.history.push(
        `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkId}/view/vision/benchmark`
      );
    }
    if (benefitCategory === BENEFIT_KIND_VISION) {
      this.props.history.push(
        `/brokers/${brokerId}/employers/${employerId}/benchmarks/${benchmarkId}/view/benchmark/publish`
      );
    }
  };

  render() {
    const {
      isAddModalOpen,
      benchmarkData,
      isOtherPlansModalOpen,
      percentileRange,
      isSimilarPlansModalOpen,
      validations,
      selectedCurrentPlanId,
      selectedPlanTabType,
      otherPlansInRange,
      totalOtherPlans,
      fade,
      selectBenchmarkPlan
    } = this.state;

    const {
      benefitCategory,
      benchmarkId,
      employerId,
      brokerId
    } = this.props.match.params;
    const { history, isCreatingBenchmark, match } = this.props;

    const isReadOnly = match.path.includes('/view/');

    const { summaryInfo, benchmarkName } = benchmarkData;

    if (!benchmarkData) {
      return <Loader />;
    }

    const categortytitle = benefitCategory.replace(/(^.)/, (m) =>
      m.toUpperCase()
    );
    const title = !isReadOnly
      ? `${categortytitle} - Select Benchmarks`
      : categortytitle;

    return (
      <ApplicationWrapper>
        <HeaderContainer
          auto_id="benchmarks"
          title={title}
          subtitle={benchmarkName}
          backButtonUnderTittle={!isReadOnly}
          history={history}
          redirectBack={() => {
            this.props.history.push(
              redirectBack(
                benefitCategory,
                'benchmarks',
                employerId,
                benchmarkId,
                brokerId
              )
            );
          }}
        />
        <Row>
          <Col className="page-content" xs="auto">
            <Row>
              <Col>
                {getPlanTabs(
                  benchmarkData.currentPlans,
                  benefitCategory,
                  this.onTabRender,
                  benchmarkData.benchmarks,
                  isReadOnly
                )}
              </Col>
            </Row>
            {!isReadOnly && (
              <>
                <Row className="select-medical-percentile-spacing">
                  <Col xs="6">
                    Percentile Ranges of Available Plans{' '}
                    {`(${otherPlansInRange} out of ${totalOtherPlans})`}
                  </Col>
                  <Col xs={6} className="similar-plans-contaner">
                    <div className="similar-plans float-right">
                      <i>
                        Currently includes &nbsp;
                        <b>
                          {`${
                            benchmarkData.cluster &&
                            benchmarkData.cluster.clusterEmployers
                              ? benchmarkData.cluster.clusterEmployers.length
                              : 0
                          } similar employers`}
                        </b>
                        &nbsp;
                      </i>
                      <span
                        className="benchmark-btn-link similar-plans-view ml-2"
                        onClick={this.toggleSimilarPlansModal}
                      >
                        view
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PercentileSlider
                      values={percentileRange}
                      onChange={this.percentileOnChange}
                    ></PercentileSlider>
                  </Col>
                </Row>
                <Row>
                  <ClusterEmployers
                    isOpen={isAddModalOpen}
                    toggle={this.toggleAddModal}
                  ></ClusterEmployers>
                </Row>
              </>
            )}
            {/* Malfunction styling is shown while saving and continue. There for this code snippet hide while saving */}
            <Fade
              in={fade}
              appear={true}
              style={{ transition: 'opacity 500ms ease-out' }}
              timeout={500}
            >
              {fade &&
                getBenchmarkPlan(
                  benchmarkData.benchmarks,
                  this.updateSinglePlanDetail,
                  validations,
                  benefitCategory,
                  selectedCurrentPlanId,
                  this.saveEditedValues,
                  this.cancelEditMode,
                  this.unSelectPlanAsBenchmarkPlan,
                  isReadOnly
                )}
            </Fade>
            {/* Malfunction styling is shown while saving and continue. There for this code snippet hide while saving */}
            {fade &&
              !isReadOnly &&
              getOtherPlans(
                benchmarkData.benchmarks,
                this.makeSelectedPlanAsBenchmarkPlan,
                percentileRange,
                benefitCategory,
                selectedCurrentPlanId
              )}
            {!isReadOnly && (
              <Row>
                <Col></Col>
                <Col className="btn-primary-populate-plans-cintainer-bmk">
                  <div className="add-manual-benchmark-profile-margin">
                    <Button
                      size="md"
                      color="primary"
                      outline
                      className="btn-primary-populate-plans"
                      onClick={this.toggleOtherPlansModal}
                    >
                      <div className="select-medical-add-benchmark">
                        + &nbsp; Add Manual Benchmark Profile
                      </div>
                    </Button>
                  </div>
                </Col>

                <Col></Col>
              </Row>
            )}
          </Col>
          <Col className="page-actions" xs="auto">
            {!isReadOnly ? (
              <PageActionButton
                type={
                  isCreatingBenchmark && !selectBenchmarkPlan ? 'progress' : ''
                }
                onClick={this.saveAndNext}
              >
                Save & Continue
              </PageActionButton>
            ) : (
              <PageActionButton outline onClick={this.next}>
                Next
              </PageActionButton>
            )}
            {!_.isEmpty(
              summaryInfo &&
                summaryInfo.benefitCategorySummary[
                  benefitCategory.toUpperCase()
                ]
            ) && (
              <Summary
                benefitCategory={benefitCategory}
                summaryInfo={
                  summaryInfo.benefitCategorySummary[
                    benefitCategory.toUpperCase()
                  ]
                }
              />
            )}
          </Col>
        </Row>
        <PopulatePlanDetailsModal
          title={'Add Manual Benchmark'}
          onSelectBenchmarkPlan={this.onSelectBenchmarkPlan}
          isOpen={isOtherPlansModalOpen}
          toggle={this.toggleOtherPlansModal}
          benefitCategory={benefitCategory}
          planType={selectedPlanTabType}
        />
        <SimilarPlansModal
          isOpen={isSimilarPlansModalOpen}
          benefitCategory={benefitCategory}
          cluster={
            benchmarkData.cluster ? benchmarkData.cluster.clusterEmployers : []
          }
          toggle={this.toggleSimilarPlansModal}
        />
      </ApplicationWrapper>
    );
  }
}

const getBenchmarkPlan = (
  benchmarks,
  updateSinglePlanDetail,
  validations,
  benefitCategory,
  selectedCurrentPlanId,
  saveEditedValues,
  cancelEditMode,
  unSelectPlanAsBenchmarkPlan,
  isReadOnly
) => {
  const NoPlansMessage = () => (
    <Row>
      <Col className="mt-5 text-center no-plan-added-message">{`No ${benefitCategory.replace(
        /(^.)/,
        (m) => m.toUpperCase()
      )} Plans Added`}</Col>
    </Row>
  );

  let plans = {};
  if (!_.isEmpty(benchmarks) && benchmarks[benefitCategory.toUpperCase()]) {
    benchmarks[benefitCategory.toUpperCase()].forEach((plan) => {
      if (
        plan.benchmarkPlan &&
        plan.currentPlan &&
        plan.currentPlan.id === selectedCurrentPlanId
      ) {
        plans['benchmarkPlan'] = plan.benchmarkPlan;
        plans['currentPlan'] = plan.currentPlan;
      }
    });

    if (!_.isEmpty(plans)) {
      return (
        <div id="benchmark-plan">
          <Row className="benchmark-plan-details">
            <Col>
              <CurrentBenchmarkDetails
                ref={'selectedBenchmark'}
                isBenchmarkPlan={true}
                benchmarkPlan={plans.benchmarkPlan}
                currentPlan={plans.currentPlan}
                onChange={updateSinglePlanDetail}
                validations={generateEmptyValidations(validations)}
                benefitCategory={benefitCategory}
                saveEditedValues={saveEditedValues}
                cancelEditMode={cancelEditMode}
                unSelectPlanAsBenchmarkPlan={unSelectPlanAsBenchmarkPlan}
                isReadOnly={isReadOnly}
              />
            </Col>
          </Row>
        </div>
      );
    }
    if (_.isEmpty(plans) && isReadOnly) {
      return <NoPlansMessage />;
    }
  } else if (isReadOnly) {
    return <NoPlansMessage />;
  }
};

const getOtherPlans = (
  benchmarks,
  makeSelectedPlanAsBenchmarkPlan,
  percentileRange,
  benefitCategory,
  selectedCurrentPlanId
) => {
  let otherPlans = [];
  let currentPlan = {};
  if (!_.isEmpty(benchmarks) && benchmarks[benefitCategory.toUpperCase()]) {
    benchmarks[benefitCategory.toUpperCase()].forEach((plan) => {
      if (plan.currentPlan && plan.currentPlan.id === selectedCurrentPlanId) {
        currentPlan = plan.currentPlan;
        if (plan.otherPlans) {
          plan.otherPlans.forEach((otherPlan) => {
            otherPlans.push(otherPlan);
          });
        }
      }
    });
  }

  if (otherPlans) {
    otherPlans.sort((a, b) => b.percentile - a.percentile);
    let otherPlanCards = [];
    otherPlans.forEach((otherPlan, key) => {
      if (percentileRange && percentileRange.length > 0) {
        if (
          _.inRange(
            otherPlan.percentile,
            percentileRange[0],
            percentileRange[1]
          )
          || otherPlan.percentile === percentileRange[1]
        ) {
          otherPlanCards.push(
            <Row className="benchmark-plan-details" key={key}>
              <Col>
                <CurrentBenchmarkDetails
                  planKey={key}
                  benchmarkPlan={otherPlan}
                  currentPlan={currentPlan}
                  selectPlanAsBenchmark={makeSelectedPlanAsBenchmarkPlan}
                  benefitCategory={benefitCategory}
                />
              </Col>
            </Row>
          );
        }
      } else {
        otherPlanCards.push(
          <Row className="benchmark-plan-details" key={key}>
            <Col>
              <CurrentBenchmarkDetails
                planKey={key}
                benchmarkPlan={otherPlan}
                currentPlan={currentPlan}
                selectPlanAsBenchmark={makeSelectedPlanAsBenchmarkPlan}
                benefitCategory={benefitCategory}
              />
            </Col>
          </Row>
        );
      }
    });

    return otherPlanCards;
  }
};

export const generateEmptyValidations = (validations) => {
  if (_.isEmpty(validations)) {
    return {
      ...validations,
      isDeductibleSingleEmpty: false,
      isDeductibleFamilyEmpty: false,
      isOopMaxSingleEmpty: false,
      isOopMaxFamilyEmpty: false,
      isCoinsuaranceEmpty: false,
      isCopaySpecialistEmpty: false,
      isCopayPrimaryEmpty: false,
      isRxCostT1Empty: false,
      isRxCostT2Empty: false,
      isRxCostT3Empty: false,
      isRxCostT4Empty: false,
      isContributionEEEmpty: false,
      isContributionESEmpty: false,
      isContributionECEmpty: false,
      isContributionEFEmpty: false,
      isRatesEEEmpty: false,
      isRatesESEmpty: false,
      isRatesECEmpty: false,
      isRatesEFEmpty: false
    };
  }
  return validations;
};

const getPlanTabs = (
  currentPlans,
  benefitCategory,
  onTabRender,
  benchmarks,
  isReadOnly
) => {
  if (
    isReadOnly &&
    !_.isEmpty(benchmarks) &&
    _.isEmpty(benchmarks[benefitCategory.toUpperCase()])
  ) {
    return null;
  }
  if (currentPlans && !_.isEmpty(currentPlans)) {
    let planTypeTabs = [];
    if (
      benefitCategory &&
      currentPlans[benefitCategory.toUpperCase()] &&
      currentPlans[benefitCategory.toUpperCase()].length > 0
    ) {
      currentPlans[benefitCategory.toUpperCase()].forEach((plan) => {
        planTypeTabs.push({
          planType: plan.planType,
          planId: plan.id,
          planName: plan.planName
        });
      });
      return (
        <TabView
          onTabRender={onTabRender}
          plans={planTypeTabs}
          key={`${benefitCategory}_tab`}
        ></TabView>
      );
    }
  }
};

const mapDispatchToProps = (dispatch) => ({
  findBenchmark: (benchmarkId) => dispatch(findBenchmark(benchmarkId)),
  saveBenchmark: (benchmarkId, benchmark, employerId, step, benefitCategory) =>
    dispatch(
      saveBenchmark(benchmarkId, benchmark, employerId, step, benefitCategory)
    ),
  getMetadata: () => dispatch(getMetadata())
});

const mapStateToProps = (state) => {
  const { benchmark, metadata, benchmarkSaveData } = state.benchmarkReducer;

  return {
    benchmark,
    metadata,
    benchmarkSaveResult: benchmarkSaveData.benchmarkSaveResult,
    isCreatingBenchmark: benchmarkSaveData.isCreatingBenchmark,
    benchmarkSaveError: benchmarkSaveData.saveBenchmarkError
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalBenchmark);
