import React from 'react';
import { Form, Label, Input, FormGroup } from 'components/Atoms';
import PropTypes from 'prop-types';

const RadioRowFormGroup = (props) => {
  const {
    className = '',
    labelDisplay,
    onChange,
    disabled,
    inputName,
    inputValue,
    options,
    optionsMap
  } = props;
  return (
    <FormGroup>
      {labelDisplay && <Label>{labelDisplay}</Label>}
      {/** Need to wrap all the radio options with Form so they do not collide with other radio options */}
      <Form inline className={`radio-row ${className}`}>
        {options.map(
          optionsMap ||
            ((item) => (
              <Label key={item.value} check>
                <Input
                  type="radio"
                  name={inputName}
                  value={item.value}
                  checked={inputValue === item.value}
                  onChange={onChange}
                  disabled={disabled}
                />
                {item.text}
              </Label>
            ))
        )}
      </Form>
    </FormGroup>
  );
};

RadioRowFormGroup.propTypes = {
  labelDisplay: PropTypes.any,
  // Expects (event) => void
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  inputName: PropTypes.string,
  inputValue: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      text: PropTypes.string
    })
  ),
  optionsMap: PropTypes.func
};

export default RadioRowFormGroup;
