/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import axios from 'axios';
import moment from 'moment';

import Util, {
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_MULTIPART_FORM
} from '../util/apiUtil';
import {
  BILLING_FILE_PUBLISHED,
  BILLING_FILE_DRAFT,
  BILLING_FILE_VALIDATE,
  BILLING_MONTH_FORMAT
} from '../modules/Billing/constants';
//configs
const baseURL = `${Util.baseApi}/${Util.version}/billing`;

export default {
  /**
   * get billing
   *
   * @returns {AxiosPromise<any>}
   */
  findAll(page, size, sort, query, employerId) {
    return axios.get(
      `${baseURL}/employers/${employerId}/records?${Util.getPagingQueryString(
        page,
        size,
        sort,
        query
      )}`
    );
  },
  getBillingDownloadUrl(documentId) {
    return `${baseURL}/${documentId}`;
  },
  getFileTypeList() {
    return axios.get(`${baseURL}/files/file-types`);
  },
  /**
   * validate/Save/Publish data files
   *
   * @param {*} employerId
   * @param {*} detailFile
   * @param {*} summaryFile
   * @param {*} billingFileType
   * @param {*} billingMonth
   * @param {*} progressNotifier
   */
  uploadDataFile(
    employerId,
    detailFile,
    summaryFile,
    billingFileType,
    billingMonth,
    progressNotifier,
    apiType
  ) {
    const formData = new FormData();
    if (detailFile.fileBlob) {
      formData.append(
        detailFile.file,
        detailFile.fileBlob,
        detailFile.uploadFileName
      );
    }
    if (summaryFile.fileBlob) {
      formData.append(
        summaryFile.file,
        summaryFile.fileBlob,
        summaryFile.uploadFileName
      );
    }

    formData.set('type', billingFileType);
    formData.set(
      'month',
      moment(billingMonth, BILLING_MONTH_FORMAT).format('M')
    );
    formData.set(
      'year',
      moment(billingMonth, BILLING_MONTH_FORMAT).format('Y')
    );
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      },
      onUploadProgress: progressNotifier
    };
    switch (apiType) {
      case BILLING_FILE_VALIDATE:
        return axios.post(
          `${baseURL}/files/employers/${employerId}/preview`,
          formData,
          config
        );
      case BILLING_FILE_DRAFT:
        formData.set('status', 'DRAFT');
        return axios.post(
          `${baseURL}/files/employers/${employerId}`,
          formData,
          config
        );
      case BILLING_FILE_PUBLISHED:
        formData.set('status', 'PUBLISHED');

        return axios.post(
          `${baseURL}/files/employers/${employerId}`,
          formData,
          config
        );
      default:
        break;
    }
  },
  /**
   * Get History files
   *
   * @param {*} billRecordId
   * @param {*} billingFileType
   * @param {*} employerId
   * @param {*} page
   * @param {*} size
   * @param {*} sort
   * @param {*} query
   */
  getFileHistory(
    billRecordId,
    employerId,
    billingFileType,
    page,
    size,
    sort,
    query
  ) {
    return axios.get(
      `${baseURL}/employers/${employerId}/file-history?${Util.getPagingQueryString(
        page,
        size,
        sort,
        query
      )}&billRecordId=${billRecordId}&billingFileType=${billingFileType}`
    );
  },
  /**
   * Upload Carrier files
   *
   * @param {*} billRecordId
   * @param {*} fileName
   * @param {*} fileBlob
   * @param {*} progressNotifier
   */
  uploadCarrierFile(billRecordId, fileName, fileBlob, progressNotifier) {
    const formData = new FormData();
    formData.append('file', fileBlob, fileName);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      },
      onUploadProgress: progressNotifier
    };

    return axios.post(
      `${baseURL}/${billRecordId}/files/carrier-file/upload`,
      formData,
      config
    );
  },
  /**
   * get billing history download url
   * @param recordId
   * @param employerId
   * @param billingFileType
   * @returns {file}
   */
  getBillingHistoryDownloadUrl(recordId, employerId, billingFileType) {
    return `${baseURL}/files/employers/${employerId}/file-history/download?type=${billingFileType}&recordId=${recordId}`;
  },

  /**
   * delete billing File by id
   * @param id
   * @returns {AxiosPromise}
   */
  deleteBillingFiles(id) {
    return axios.delete(`${baseURL}/records/${id}`);
  },
  /**
   * Get Preview Data
   *
   * @param {*} employerId
   * @param {*} year
   * @param {*} billingMonth
   */
  getPreviewData(billingId) {
    return axios.get(`${baseURL}/files/${billingId}/summary`);
  },
  /**
   * Publish from Grid
   *
   * @param {*} billingId
   */
  publishFromGridDataFile(billingId) {
    return axios.put(`${baseURL}/${billingId}/publish`);
  },
  /**
   * get bill download url
   * @param recordId
   * @param billingFileType
   * @param fileName
   * @returns {file}
   */
  downloadBill(recordId, billingFileType, fileName) {
    const config = {
      responseType: 'blob',
      fileName: fileName
    };
    return axios.get(
      `${baseURL}/${recordId}/files/download?type=${billingFileType}`,
      config
    );
  },
  /**
   * get history bill download url
   * @param recordId
   * @param billingFileType
   * @param fileName
   * @returns {file}
   */
  downloadHistoryBill(recordId, billingFileType, fileName) {
    const config = {
      responseType: 'blob',
      fileName: fileName
    };
    return axios.get(
      `${baseURL}/${recordId}/files/history/download?type=${billingFileType}`,
      config
    );
  }
};
