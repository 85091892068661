export const DEFAULT_OE_DESCRIPTION = `<p>This is your <u>once-a-year</u> chance to enroll in or make changes to your 
employer-sponsored health plan:</p><p>&nbsp;</p><ul><li>You must elect or decline benefits</li><li>You may add or 
drop dependents</li><li>You may set contributions to tax-advantaged accounts</li></ul><p>&nbsp;</p><p>You cannot make
changes outside of open enrollment unless you have a qualifying life event (QLE).</p>`;

export const DEFAULT_RULES = `<p>Any active, regular, full-time employee working a minimum of 30 hours per week is eligible 
for all benefits. Benefits are effective on your date of hire.</p><p>&nbsp;</p><p>Others eligible for benefits include:
</p><ul><li>Your legal spouse or domestic partner,</li><li>Your unmarried dependent children up until age 26 
(including legally adopted and stepchildren), and/or</li><li>Any dependent child who reaches the limiting age and is 
incapable of self-support because of a mental or physical disability.</li></ul>`;

export const DEFAULT_WAIVER_CREDITS = `<p>Are you already covered on a parent’s or partner’s health plan?</p><ul><li>A waiver 
reimbursement is available to those with other group or government sponsored coverage</li><li>You have the option to 
decline medical coverage.</li><li>You will receive a medical waiver reimbursement equivalent to $150/month!</li><li>You
will still receive the waiver even if you sign up for Dental and Vision.&nbsp;</li></ul>`;

export const DEFAULT_QLE_DETAILS = `<p>A change in circumstance—like starting a new job, getting married or divorced, having or 
adopting a baby, or losing health insurance coverage—makes you eligible for a special enrollment period (outside of 
    open enrollment).</p><p>&nbsp;</p><p><strong>Experiencing a QLE is the exception to the once-a-year rule about 
    making health and benefits changes. You have up to 30 days after the qualifying life event to submit a QLE request 
    to your benefits administrator.</strong></p><p>&nbsp;</p><p>The insurance carrier will require:</p><p><br></p><ol>
    <li>Supporting documentation</li><li>The date the change occurred</li></ol>`;
