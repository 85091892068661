import React from 'react';
import { FormGroup, FormText } from 'components/Atoms';
import FileFormGroup from './FileFormGroup';

const ImageFormGroupWithPreview = (props) => {
  const {
    labelDisplay,
    inputName,
    onChange,
    fileLabel,
    previewImage,
    placeholderImage,
    imageClassName,
    altText,
    acceptedFileTypes,
    isInvalid,
    feedback,
    specification
  } = props;
  return (
    <FormGroup>
      <FileFormGroup
        labelDisplay={labelDisplay}
        inputName={inputName}
        onChange={onChange}
        fileLabel={fileLabel}
        acceptedFileTypes={acceptedFileTypes}
        isInvalid={isInvalid}
        feedback={feedback}
      />
      <img
        alt={altText}
        className={imageClassName}
        src={previewImage || placeholderImage}
        onError={(event) => {
          event.target.onError = null;
          event.target.src = placeholderImage;
        }}
      />
      <FormText color="muted" className="mt-2">
        {specification}
      </FormText>
    </FormGroup>
  );
};
export default ImageFormGroupWithPreview;
