/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import {
  MODULE_RENEWALS_ENABLED,
  DATE_FORMAT,
  MODULE_OFFERS_ENABLED
} from '../constants';
import { sanitizeDateInput } from 'util/commonUtil';
import { isValidFormat } from 'util/dateUtil';
import { DatePickerInput } from 'components/DataForm';
import AddButton from 'components/Buttons/AddButton';
import NotificationManager from 'components/Notifications';
import {
  getEmployer,
  createConfigModule,
  updateConfigModule,
  findConfigStatus,
  updateEmployerRenewalPeriodConfig
} from '../actions/renewalsAction';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import { RENEWALS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { permitIf } from 'components/hoc/Permit';
import './Renewals.scss';
class Renewals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeDate: '',
      isOffersEnabled: props.offersConfigStatus.value,
      isRenewalPeriodInvalid: false
    };
  }
  componentDidMount() {
    const employerId = this.props.match.params.employerId;
    const { findConfigStatus, getEmployer } = this.props;
    findConfigStatus(employerId, MODULE_RENEWALS_ENABLED);
    findConfigStatus(employerId, MODULE_OFFERS_ENABLED);
    getEmployer(employerId);
  }

  componentDidUpdate(prevProps) {
    const {
      employerRenewal,
      offersConfigStatus,
      employerRenewalLoading,
      renewalPeriodConfigLoading,
      employerOffersConfigsUpdate,
      employerOffersConfigsUpdateError
    } = this.props;
    if (
      employerRenewal &&
      prevProps.employerRenewalLoading &&
      employerRenewalLoading !== prevProps.employerRenewalLoading
    ) {
      this.updateCloseDate();
    }
    if (offersConfigStatus !== prevProps.offersConfigStatus) {
      this.updateOffersConfigurations();
    }
    if (
      renewalPeriodConfigLoading !== prevProps.renewalPeriodConfigLoading &&
      !renewalPeriodConfigLoading
    ) {
      if (employerOffersConfigsUpdate) {
        NotificationManager.success(
          'Offers close date has been saved successfully.'
        );
      }
      if (employerOffersConfigsUpdateError) {
        NotificationManager.error('Offer close date could not be saved.');
      }
    }
  }

  switchRenewalsEnabled = () => {
    const employerId = this.props.match.params.employerId;
    const { id, value } = this.props.configStatus;

    if (!id) {
      this.props.createConfigModule(employerId, true, MODULE_RENEWALS_ENABLED);
    } else {
      this.props.updateConfigModule(
        id,
        employerId,
        value,
        MODULE_RENEWALS_ENABLED
      );
    }
  };

  updateCloseDate = () => {
    this.setState({
      closeDate: this.props.employerRenewal.renewalPeriodConfig
        ? this.props.employerRenewal.renewalPeriodConfig.offerCloseDate
        : ''
    });
  };

  updateOffersConfigurations = () => {
    this.setState({ isOffersEnabled: this.props.offersConfigStatus.value });
  };

  onChange = (event) => {
    const { value } = event.target;
    this.setState({ closeDate: value }, () => {
      this.validateRenewalPeriod();
    });
  };

  onSaveOfferConfig = () => {
    const { closeDate } = this.state;
    const { employerId } = this.props.match.params;
    const data = {
      offerCloseDate: closeDate
    };
    this.props.updateEmployerRenewalPeriodConfig(employerId, data);
  };

  validateRenewalPeriod = () => {
    const { closeDate } = this.state;
    this.setState({
      isRenewalPeriodInvalid:
        closeDate && !isValidFormat(closeDate, DATE_FORMAT)
    });
  };

  switchOffersEnabled = () => {
    const { match, offersConfigStatus, createConfigModule, updateConfigModule } = this.props;
    const { employerId } = match.params;
    const { id, value } = offersConfigStatus;
    if (!id) {
      createConfigModule(employerId, true, MODULE_OFFERS_ENABLED);
    } else {
      updateConfigModule(id, employerId, value, MODULE_OFFERS_ENABLED);
    }
    this.setState((prevState) => ({
      isOffersEnabled: !prevState.isOffersEnabled
    }));
  };

  render() {
    const employerId = this.props.match.params.employerId;
    const { configStatus, offersConfigStatus } = this.props;
    const { isOffersEnabled, closeDate, isRenewalPeriodInvalid } = this.state;
    const SecuredEmployerConfigToggle = permitIf(
      EmployerConfigToggleV2,
      RENEWALS_FEATURE_ROLE_MAPPING.common
    );
    const SecuredAddButton = permitIf(
      AddButton,
      RENEWALS_FEATURE_ROLE_MAPPING.common
    );

    return (
      <ApplicationWrapper>
        <HeaderContainer title="Renewals" />
        <ContentContainer>
          <SecuredEmployerConfigToggle
            employerId={employerId}
            moduleEnabled={MODULE_RENEWALS_ENABLED}
            message="Offers Access"
            config={offersConfigStatus}
            switchCallBack={this.switchOffersEnabled}
          />
          {isOffersEnabled && JSON.parse(isOffersEnabled) && (
            <div className="mt-2 mx-5 pl-3">
              <div className="mt-2">
                <div className="w-25 pull-left mr-4 offers-close-date-wrapper">
                  <DatePickerInput
                    labelDisplay={'Offers Close Date'}
                    inputName="closeDate"
                    inputValue={closeDate}
                    dateFormat={DATE_FORMAT}
                    onChange={(event) => {
                      const { value } = event.target;
                      event.target.value = sanitizeDateInput(value, '-');
                      this.onChange(event);
                    }}
                    isInvalid={isRenewalPeriodInvalid}
                    feedback={`Please enter a valid date in the format ${DATE_FORMAT}`}
                  />
                </div>

                <div className="save-configs">
                  <SecuredAddButton
                    classes="btn-lg"
                    onClick={this.onSaveOfferConfig}
                    title={'Save'}
                    color="primary"
                    disabled={
                      closeDate === null ||
                      closeDate.trim() === '' ||
                      isRenewalPeriodInvalid
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </ContentContainer>
        <ContentContainer>
          <SecuredEmployerConfigToggle
            employerId={employerId}
            moduleEnabled={MODULE_RENEWALS_ENABLED}
            message="Proposals Access"
            config={configStatus}
            switchCallBack={this.switchRenewalsEnabled}
          />
        </ContentContainer>
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createConfigModule: (employerId, value, key) =>
    dispatch(createConfigModule(employerId, value, key)),
  updateConfigModule: (id, employerId, value, key) =>
    dispatch(updateConfigModule(id, employerId, value, key)),
  updateEmployerRenewalPeriodConfig: (id, data) =>
    dispatch(updateEmployerRenewalPeriodConfig(id, data)),
  findConfigStatus: (employerId, key) =>
    dispatch(findConfigStatus(employerId, key)),
  getEmployer: (id) => dispatch(getEmployer(id))
});

const mapStateToProps = (state) => {
  const {
    employerConfigCreate,
    employerConfigCreateError,
    employerConfigUpdate,
    employerConfigUpdateError,
    configStatus,
    offersConfigStatus,
    employerRenewal,
    employerRenewalLoading,
    employerOffersConfigsUpdate,
    employerOffersConfigsUpdateError,
    renewalPeriodConfigLoading
  } = state.renewalsReducer;
  return {
    employerConfigCreate,
    employerConfigCreateError,
    employerConfigUpdate,
    employerConfigUpdateError,
    configStatus,
    offersConfigStatus,
    employerRenewal,
    employerRenewalLoading,
    employerOffersConfigsUpdate,
    employerOffersConfigsUpdateError,
    renewalPeriodConfigLoading
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Renewals);
