/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import moment from 'moment';
import DataTableWithServerPagination from 'components/DataTables';
import { DATE_TIME_FORMAT } from '../../../../constants';
import './RecipientList.scss';
class RecipientList extends React.Component {
  handleRowSelect = (state) => {
    this.props.setSelectedParticipants(state);
  };

  clearSelectedRows = (state) => {};

  render() {
    const {
      getSurveyParticipants,
      data,
      surveyId,
      showSendConfirmation
    } = this.props;
    return (
      <DataTableWithServerPagination
        columnData={[
          {
            name: 'Email Address',
            selector: 'email',
            sortable: false,
            grow: 1.5,
            wrap: false
          },
          {
            name: 'Date Submitted to Lumity',
            selector: 'submittedDate',
            sortable: false,
            grow: 1.5,
            wrap: false,
            format: (row) => {
              const { submittedDate } = row;
              return moment(submittedDate).format(DATE_TIME_FORMAT);
            }
          },
          {
            name: 'Date Sent to Employee',
            selector: 'notifiedDate',
            sortable: false,
            grow: 1.5,
            wrap: false,
            format: (row) => {
              const { notifiedDate } = row;
              return notifiedDate
                ? moment(notifiedDate).format(DATE_TIME_FORMAT)
                : '--';
            }
          },
          {
            sortable: false,
            wrap: false,
            cell: (row) => {
              const { notifiedDate, email } = row;
              if (!notifiedDate) return null;
              return (
                <span
                  className="btn-send-again"
                  onClick={() => showSendConfirmation(email)}
                >
                  Re Send
                </span>
              );
            }
          }
        ]}
        fetchData={(page, size, sort, query) =>
          getSurveyParticipants({ surveyId, page, size, sort, query })
        }
        pageData={data}
        // selectableRows
        // onSelectedRowsChange={this.handleRowSelect}
        // clearSelectedRows={this.clearSelectedRows}
      />
    );
  }
}

export default RecipientList;
