/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCaretRight,
  faPlus,
  faCheck,
  faTimes,
  faCircle,
  faCircleNotch,
  faMinusCircle,
  faHome,
  faSearch,
  faExclamationTriangle,
  faTrashAlt,
  faUsers,
  faUsersCog,
  faUserShield,
  faUserFriends,
  faSync,
  faSyncAlt,
  faBuilding,
  faBell,
  faCommentAlt,
  faFileAlt,
  faFileInvoice,
  faListUl,
  faPaperclip,
  faLink,
  faMobileAlt,
  faCalendarAlt,
  faChartBar,
  faChartArea,
  faSignOutAlt,
  faPen,
  faNotesMedical,
  faStethoscope,
  faTooth,
  faGlasses,
  faStarOfLife,
  faPiggyBank,
  faBusAlt,
  faEye,
  faKey,
  faFilter,
  faNetworkWired,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faInfoCircle,
  faMapMarkedAlt,
  faCogs,
  faClock,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import 'font-awesome/css/font-awesome.min.css';

library.add(
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCaretRight,
  faPlus,
  faCheck,
  faTimes,
  faCircle,
  faCircleNotch,
  faMinusCircle,
  faHome,
  faSearch,
  faExclamationTriangle,
  faTrashAlt,
  faUsers,
  faUsersCog,
  faUserShield,
  faUserFriends,
  faSync,
  faSyncAlt,
  faBuilding,
  faBell,
  faCommentAlt,
  faFileAlt,
  faFileInvoice,
  faListUl,
  faPaperclip,
  faLink,
  faMobileAlt,
  faCalendarAlt,
  faChartBar,
  faChartArea,
  faSignOutAlt,
  faPen,
  faNotesMedical,
  faStethoscope,
  faTooth,
  faGlasses,
  faStarOfLife,
  faPiggyBank,
  faBusAlt,
  faEye,
  faKey,
  faFilter,
  faNetworkWired,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faInfoCircle,
  faMapMarkedAlt,
  faCogs,
  faClock,
  faDownload
);

class Icon extends PureComponent {
  render() {
    return <FontAwesomeIcon {...this.props} />;
  }
}

export default Icon;
