export const FIND_SSO_EMPLOYER_CONFIGURATION_MODULES =
  'FIND_SSO_EMPLOYER_CONFIGURATION_MODULES';
export const FIND_SSO_EMPLOYER_CONFIGURATION_MODULES_ERROR =
  'FIND_SSO_EMPLOYER_CONFIGURATION_MODULES_ERROR';
export const CREATE_SSO_EMPLOYER_CONFIGURATION_MODULES =
  'CREATE_SSO_EMPLOYER_CONFIGURATION_MODULES';
export const CREATE_SSO_EMPLOYER_CONFIGURATION_MODULES_ERROR =
  'CREATE_SSO_EMPLOYER_CONFIGURATION_MODULES_ERROR';
export const VALIDATE_SSO_URL = 'VALIDATE_SSO_URL';
export const VALIDATE_SSO_URL_INPROGRESS = 'VALIDATE_SSO_URL_INPROGRESS';
export const VALIDATE_SSO_URL_ERROR = 'VALIDATE_SSO_URL_ERROR';
export const SAVE_SSO_CONFIGURATION = 'SAVE_SSO_CONFIGURATION';
export const SAVE_SSO_CONFIGURATION_INPROGRESS =
  'SAVE_SSO_CONFIGURATION_INPROGRESS';
export const SAVE_SSO_CONFIGURATION_ERROR = 'SAVE_SSO_CONFIGURATION_ERROR';
export const GET_SSO_CONFIGURATION = 'GET_SSO_CONFIGURATION';
export const GET_SSO_CONFIGURATION_INPROGRESS =
  'GET_SSO_CONFIGURATION_INPROGRESS';
export const GET_SSO_CONFIGURATION_ERROR = 'GET_SSO_CONFIGURATION_ERROR';
