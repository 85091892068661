/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import {
  CREATE_DOCUMENT,
  CREATE_DOCUMENT_ERROR,
  CREATE_FOLDER,
  CREATE_FOLDER_ERROR,
  CREATE_FOLDER_IN_PROGRESS,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_ERROR,
  GET_DOCUMENT_PAGE,
  GET_DOCUMENT_PAGE_ERROR,
  RENAME_DOCUMENT,
  RENAME_DOCUMENT_ERROR,
  RENAME_DOCUMENT_IN_PROGRESS,
  UPLOAD_FILE_IN_PROGRESS,
  GET_DOCUMENT_ENABLE_STATUS,
  GET_DOCUMENT_ENABLE_STATUS_ERROR,
  DELETE_DOCUMENT_IN_PROGRESS,
  GET_DOCUMENT_NAME_EXIST_OR_NOT
} from '../actionTypes/document.actionTypes';
import DocumentService from '../../../services/DocumentService';

export const getOtherDocuments = (page, size, sort, query, search) => async (
  dispatch
) => {
  try {
    const response = await DocumentService.findByDocumentId(
      page,
      size,
      sort,
      query,
      search
    );
    dispatch({ type: GET_DOCUMENT_PAGE, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_DOCUMENT_PAGE_ERROR, payload: error });
  }
};

export const saveFolder = (data) => async (dispatch) => {
  dispatch({ type: CREATE_FOLDER_IN_PROGRESS, payload: {} });
  try {
    const response = await DocumentService.createFolder(data);
    dispatch({ type: CREATE_FOLDER, payload: response.data });
  } catch (error) {
    dispatch({ type: CREATE_FOLDER_ERROR, payload: error });
  }
};

export const uploadFile = (data, duplicate) => async (dispatch) => {
  dispatch({ type: UPLOAD_FILE_IN_PROGRESS, payload: null });
  try {
    const response = await DocumentService.uploadFile(data, duplicate);
    dispatch({ type: GET_DOCUMENT_NAME_EXIST_OR_NOT, payload: null });
    dispatch({ type: CREATE_DOCUMENT, payload: response.data });
  } catch (error) {
    dispatch({ type: CREATE_DOCUMENT_ERROR, payload: error });
    dispatch({ type: GET_DOCUMENT_NAME_EXIST_OR_NOT, payload: null });
  }
};

export const deleteDocument = (documentId) => async (dispatch) => {
  dispatch({ type: DELETE_DOCUMENT_IN_PROGRESS, payload: {} });
  try {
    await DocumentService.deleteDocument(documentId);
    dispatch({ type: DELETE_DOCUMENT, payload: { id: documentId } });
  } catch (error) {
    dispatch({ type: DELETE_DOCUMENT_ERROR, payload: error });
  }
};

export const renameDocument = (data) => async (dispatch) => {
  dispatch({ type: RENAME_DOCUMENT_IN_PROGRESS, payload: {} });
  try {
    const response = await DocumentService.renameDocument(
      data.documentId,
      data.name,
      data.parentDocumentId
    );
    dispatch({ type: RENAME_DOCUMENT, payload: response.data });
  } catch (error) {
    dispatch({ type: RENAME_DOCUMENT_ERROR, payload: error });
  }
};

export const getDocumentV2EnableStatus = (employerId) => async (dispatch) => {
  try {
    const response = await DocumentService.checkStatus(employerId);
    dispatch({ type: GET_DOCUMENT_ENABLE_STATUS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_DOCUMENT_ENABLE_STATUS_ERROR, payload: error });
  }
};

export const updateDocumentV2EnableStatus = (employerId, data) => async (
  dispatch
) => {
  try {
    await DocumentService.updateStatus(employerId, data);
    try {
      const responseStatus = await DocumentService.checkStatus(employerId);
      dispatch({
        type: GET_DOCUMENT_ENABLE_STATUS,
        payload: responseStatus.data
      });
    } catch (error) {
      dispatch({ type: GET_DOCUMENT_ENABLE_STATUS_ERROR, payload: error });
    }
  } catch (error) {
    dispatch({ type: GET_DOCUMENT_ENABLE_STATUS_ERROR, payload: error });
  }
};

export const checkDocumentExist = (
  employerId,
  parentDocumentId,
  documentName
) => async (dispatch) => {
  try {
    const response = await DocumentService.checkDocumentExist(
      employerId,
      parentDocumentId,
      documentName
    );
    dispatch({
      type: GET_DOCUMENT_NAME_EXIST_OR_NOT,
      payload: response.data.documentExist
    });
  } catch (error) {
    dispatch({ type: GET_DOCUMENT_NAME_EXIST_OR_NOT, payload: false });
  }
};

export const clearDocumentCheckExists = (isCleared) => async (dispatch) => {
  dispatch({ type: GET_DOCUMENT_NAME_EXIST_OR_NOT, payload: isCleared });
};
