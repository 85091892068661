/*
 * Copyright (C) 2019 Lumity, Inc. - All Rights Reserved
 *
 * CONFIDENTIAL
 *
 * All information contained herein is, and remains the property of Lumity, Inc. and its partners, if any.
 * The intellectual and technical concepts contained herein are proprietary to Lumity, Inc. and its partners
 * and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless
 * prior written permission is obtained from Lumity, Inc.
 */

import {
  GET_ALL_CALENDAR_TEMPLATES_ERROR,
  GET_ALL_CALENDAR_TEMPLATES_START,
  GET_ALL_CALENDAR_TEMPLATES_SUCCESS,
  SAVE_CALENDAR_TEMPLATE_ERROR,
  SAVE_CALENDAR_TEMPLATE_START,
  SAVE_CALENDAR_TEMPLATE_SUCCESS
} from '../actions/calendarsTemplates.action';

export default (
  state = {
    calendarTemplatesData: {
      isLoading: false,
      templates: {
        content: null,
        metadata: null
      },
      calendarTemplatesError: null
    },
    saveTemplate: {
      inProgress: false,
      template: null,
      templateSaveError: null
    }
  },
  { type, payload }
) => {
  switch (type) {
    case GET_ALL_CALENDAR_TEMPLATES_START:
      return {
        ...state,
        calendarTemplatesData: {
          ...state.calendarTemplatesData,
          ...{
            isLoading: true,
            calendarTemplatesError: null
          }
        }
      };
    case GET_ALL_CALENDAR_TEMPLATES_SUCCESS:
      return {
        ...state,
        calendarTemplatesData: {
          isLoading: false,
          templates: payload,
          calendarTemplatesError: null
        }
      };
    case GET_ALL_CALENDAR_TEMPLATES_ERROR:
      return {
        ...state,
        calendarTemplatesData: {
          isLoading: false,
          calendarTemplatesError: payload
        }
      };
    case SAVE_CALENDAR_TEMPLATE_START:
      return {
        ...state,
        saveTemplate: {
          inProgress: true,
          template: null,
          templateSaveError: null
        }
      }
    case SAVE_CALENDAR_TEMPLATE_SUCCESS:
      return {
        ...state,
        saveTemplate: {
          inProgress: false,
          template: { saved: true },
          templateSaveError: null
        }
      }
    case SAVE_CALENDAR_TEMPLATE_ERROR:
      return {
        ...state,
        saveTemplate: {
          inProgress: false,
          template: null,
          templateSaveError: payload
        }
      }
    default:
      return {
        ...state
      };
  }
};
