/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import EmployerService from 'services/EmployerService';
import PlanService from 'services/PlanService';
import ElectionService from 'services/ElectionService';
import BenGuideService from 'services/BenGuideService';
import * as ActionTypes from '../actionTypes/planYear.actionTypes';

export const getPlanYears = (employerId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_PLAN_YEAR_IN_PROGRESS });
    EmployerService.findAllPlanYears(employerId)
      .then((response) => {
        dispatch({
          type: ActionTypes.GET_PLAN_YEAR_COMPLETED,
          payload: response.data
        });
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_PLAN_YEAR_FAILED,
          payload: error.response
        })
      );
  };
};

export const createPlanYear = (payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CREATE_PLAN_YEAR_IN_PROGRESS });
    EmployerService.createPlanYear(payload.employerId, payload)
      .then((response) => {
        dispatch({
          type: ActionTypes.CREATE_PLAN_YEAR_COMPLETED,
          payload: response.data
        });
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.CREATE_PLAN_YEAR_FAILED,
          payload: error.response
        })
      );
  };
};

export const updatePlanYear = (employerId, planYearId, selectedPlanYear) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_PLAN_YEAR_IN_PROGRESS });
    EmployerService.updatePlanYear(employerId, planYearId, selectedPlanYear)
      .then(() =>
        dispatch({
          type: ActionTypes.UPDATE_PLAN_YEAR_COMPLETED,
          payload: { ...selectedPlanYear, id: planYearId }
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.UPDATE_PLAN_YEAR_FAILED,
          payload: error.response
        })
      );
  };
};

export const deletePlanYear = (employerId, selectedPlanYear) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.DELETE_PLAN_YEAR_IN_PROGRESS });
    EmployerService.deletePlanYear(employerId, selectedPlanYear.id)
      .then(() =>
        dispatch({
          type: ActionTypes.DELETE_PLAN_YEAR_COMPLETED,
          payload: selectedPlanYear
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.DELETE_PLAN_YEAR_FAILED,
          payload: error.response
        })
      );
  };
};

export const validatePlanYearDeletion = (
  employerId,
  selectedPlanYear,
  validate
) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.DELETE_VALIDATE_PLAN_YEAR_IN_PROGRESS });
    EmployerService.deletePlanYear(employerId, selectedPlanYear.id, validate)
      .then(() =>
        dispatch({
          type: ActionTypes.DELETE_VALIDATE_PLAN_YEAR_COMPLETED,
          payload: { valid: true, planYear: selectedPlanYear }
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.DELETE_VALIDATE_PLAN_YEAR_FAILED,
          payload: {
            error: error.response,
            data: { planYear: selectedPlanYear }
          }
        })
      );
  };
};

export const changeCurrentPlanYear = (employerId, planYearId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CHANGE_CURRENT_PLAN_YEAR_IN_PROGRESS });
    EmployerService.changeCurrentPlanYear(employerId, planYearId)
      .then((response) =>
        dispatch({
          type: ActionTypes.CHANGE_CURRENT_PLAN_YEAR_COMPLETED,
          payload: response.data
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.CHANGE_CURRENT_PLAN_YEAR_FAILED,
          payload: error.response
        })
      );
  };
};

export const getPlanYearByType = (employerId, planYearType) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_PLAN_YEAR_BY_TYPE_IN_PROGRESS });
    EmployerService.getPlanYearByType(employerId, planYearType)
      .then((response) => {
        dispatch({
          type: ActionTypes.GET_PLAN_YEAR_BY_TYPE_COMPLETED,
          payload: response.data
        });
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_PLAN_YEAR_BY_TYPE_FAILED,
          payload: error.response
        })
      );
  };
};

export const getAttachedPlansCount = (employerId, planYearIds) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_ATTACHED_PLANS_COUNT_IN_PROGRESS });
    PlanService.getAllNonArchivedPlans(employerId, planYearIds)
      .then((response) => {
        const totalNumberOfPlans = Object.values(response.data).reduce(
          (accumulator, currentValue) => accumulator + currentValue.length,
          0
        );
        dispatch({
          type: ActionTypes.GET_ATTACHED_PLANS_COUNT_SUCCESS,
          payload: totalNumberOfPlans
        });
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_ATTACHED_PLANS_COUNT_FAILED,
          payload: error.response
        })
      );
  };
};

//TODO: Update this action to call an benGuide count API without page data
export const getAttachedBenGuidesCount = (
  employerId,
  selectedPlanYearIds,
  page,
  size,
  sort,
  query
) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_ATTACHED_BEN_GUIDE_COUNT_IN_PROGRESS });
    BenGuideService.findAll(
      employerId,
      selectedPlanYearIds,
      page,
      size,
      sort,
      query
    )
      .then((response) =>
        dispatch({
          type: ActionTypes.GET_ATTACHED_BEN_GUIDE_COUNT_SUCCESS,
          payload: response.data.content.length
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_ATTACHED_BEN_GUIDE_COUNT_FAILED,
          payload: error.response
        })
      );
  };
};

export const getAttachedElectionsCount = (employerId, planYearIds) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_ATTACHED_ELECTIONS_COUNT_IN_PROGRESS });
    ElectionService.getAllElections(employerId, planYearIds)
      .then((response) =>
        dispatch({
          type: ActionTypes.GET_ATTACHED_ELECTIONS_COUNT_SUCCESS,
          payload: response.data.length
        })
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_ATTACHED_PLANS_COUNT_FAILED,
          payload: error.response
        })
      );
  };
};

//Plan year migration related
export const getPotentialPlanYears = (employerId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_POTENTIAL_PLAN_YEARS_IN_PROGRESS });
    EmployerService.getPotentialPlanYears(employerId)
      .then((response) => {
        dispatch({
          type: ActionTypes.GET_POTENTIAL_PLAN_YEARS_COMPLETED,
          payload: response.data
        });
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_POTENTIAL_PLAN_YEARS_FAILED,
          payload: error.response
        })
      );
  };
};

export const getAssociatedPlanCount = (employerId, masterIdList) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_ASSOCIATED_PLANS_COUNT_IN_PROGRESS });
    EmployerService.getAssociatedPlanCount(employerId, masterIdList)
      .then((response) => {
        dispatch({
          type: ActionTypes.GET_ASSOCIATED_PLANS_COUNT_COMPLETED,
          payload: response.data
        });
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_ASSOCIATED_PLANS_COUNT_FAILED,
          payload: error.response
        })
      );
  };
};

export const getAssociatedElectionCount = (employerId, masterIdList) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_ASSOCIATED_ELECTIONS_COUNT_IN_PROGRESS });
    EmployerService.getAssociatedElectionCount(employerId, masterIdList)
      .then((response) => {
        dispatch({
          type: ActionTypes.GET_ASSOCIATED_ELECTIONS_COUNT_COMPLETED,
          payload: response.data
        });
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_ASSOCIATED_ELECTIONS_COUNT_FAILED,
          payload: error.response
        })
      );
  };
};

export const getAssociatedBenguides = (employerId, planYearId) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_ASSOCIATED_BENGUIDES_IN_PROGRESS });
    EmployerService.getAssociatedBenguides(employerId, planYearId)
      .then((response) => {
        dispatch({
          type: ActionTypes.GET_ASSOCIATED_BENGUIDES_COMPLETED,
          payload: response.data
        });
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.GET_ASSOCIATED_BENGUIDES_FAILED,
          payload: error.response
        })
      );
  };
};

export const associatePlanYearWithPlans = (employerId, planYearId, payload) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.PLAN_YEAR_MIGRATION_FOR_PLANS_IN_PROGRESS });
    EmployerService.associatePlanYearWithPlans(employerId, planYearId, payload)
      .then((response) => {
        dispatch({
          type: ActionTypes.PLAN_YEAR_MIGRATION_FOR_PLANS_COMPLETED,
          payload: response.data
        });
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.PLAN_YEAR_MIGRATION_FOR_PLANS_FAILED,
          payload: error.response
        })
      );
  };
};

export const associatePlanYearWithElections = (
  employerId,
  planYearId,
  payload
) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.PLAN_YEAR_MIGRATION_FOR_ELECTIONS_IN_PROGRESS
    });
    EmployerService.associatePlanYearWithElections(
      employerId,
      planYearId,
      payload
    )
      .then((response) => {
        dispatch({
          type: ActionTypes.PLAN_YEAR_MIGRATION_FOR_ELECTIONS_COMPLETED,
          payload: response.data
        });
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.PLAN_YEAR_MIGRATION_FOR_ELECTIONS_FAILED,
          payload: error.response
        })
      );
  };
};

export const associatePlanYearWithBenguides = (
  employerId,
  planYearId,
  benguideIdList
) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.PLAN_YEAR_MIGRATION_FOR_BENGUIDES_IN_PROGRESS
    });
    EmployerService.associatePlanYearWithBenguides(
      employerId,
      planYearId,
      benguideIdList
    )
      .then((response) => {
        dispatch({
          type: ActionTypes.PLAN_YEAR_MIGRATION_FOR_BENGUIDES_COMPLETED,
          payload: response.data
        });
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.PLAN_YEAR_MIGRATION_FOR_BENGUIDES_FAILED,
          payload: error.response
        })
      );
  };
};

export const associateBenefitGroupsForPlanYear = (employerId, planYearId) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.PLAN_YEAR_MIGRATION_ASSOCIATE_BENEFIT_GROUP_IN_PROGRESS
    });
    EmployerService.associateBenefitGroupsForPlanYear(employerId, planYearId)
      .then((response) => {
        dispatch({
          type:
            ActionTypes.PLAN_YEAR_MIGRATION_ASSOCIATE_BENEFIT_GROUP_COMPLETED,
          payload: response.data
        });
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.PLAN_YEAR_MIGRATION_ASSOCIATE_BENEFIT_GROUP_FAILED,
          payload: error.response
        })
      );
  };
};
