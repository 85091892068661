/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import DataTable from 'react-data-table-component';

import './DataTable.scss';

// Just pass any props to the underlying DataTable component
const LumityDataTable = (props) => {
  const {
    className = '',
    customStyle,
    persistTableHead = false,
    conditionalRowStyles = [],
    ...rest
  } = props;
  return (
    <DataTable
      className={`react-data-table-component ${className}`}
      customStyles={customStyle ? customStyle : customStyles}
      conditionalRowStyles={conditionalRowStyles}
      noHeader
      highlightOnHover
      responsive
      persistTableHead={persistTableHead}
      {...rest}
    />
  );
};

// Documentation for custom styling: https://www.npmjs.com/package/react-data-table-component#overidding-styling-using-css-in-js
const customStyles = {
  headCells: {
    inactiveSortStyle: {
      opacity: 0.7,
      // Target the sort icon when inactive
      span: {
        opacity: 0.25
      }
    }
  }
};

export default LumityDataTable;
