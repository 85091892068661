/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actions/context.actions';
import * as IntegrationActionTypes from 'modules/Integrations/actionTypes/integrations.actionTypes';
import {
  ADAPTER_NAMES,
  INTEGRATION_TYPES
} from 'modules/Integrations/constants';

const initialState = {
  employerContext: {},
  organizationContext: null,
  navigationContext: null
};

const contextReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_EMPLOYER_CONTEXT:
      return {
        ...state,
        employerContext: action.payload
      };
    case ActionTypes.SET_NAVIGATION_CONTEXT:
      return {
        ...state,
        navigationContext: action.payload
      };
    case IntegrationActionTypes.SAVE_BAMBOO_HRIS_CONFIG_SUCCESS:
      return {
        ...state,
        employerContext: {
          ...state.employerContext,
          integrations: {
            ...state.employerContext.integrations,
            [state.employerContext.id]: [
              ...state.employerContext.integrations[state.employerContext.id],
              {
                employerId: state.employerContext.id,
                type: INTEGRATION_TYPES.HRIS,
                vendor: ADAPTER_NAMES.BAMBOO_HR.code
              }
            ]
          }
        }
      };
    case IntegrationActionTypes.SAVE_LFI_HRIS_CONFIG_SUCCESS:
      return {
        ...state,
        employerContext: {
          ...state.employerContext,
          integrations: {
            ...state.employerContext.integrations,
            [state.employerContext.id]: [
              ...state.employerContext.integrations[state.employerContext.id],
              {
                employerId: state.employerContext.id,
                type: INTEGRATION_TYPES.HRIS,
                vendor: ADAPTER_NAMES.GENERIC_FILE_IMPORT.code
              }
            ]
          }
        }
      };
    case ActionTypes.SET_ORGANISATION_CONTEXT:
      return {
        ...state,
        organizationContext: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default contextReducer;
