/*
 * Copyright (C) 2019 Lumity, Inc. - All Rights Reserved
 *
 * CONFIDENTIAL
 *
 * All information contained herein is, and remains the property of Lumity, Inc. and its partners, if any.
 * The intellectual and technical concepts contained herein are proprietary to Lumity, Inc. and its partners
 * and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless
 * prior written permission is obtained from Lumity, Inc.
 */

export const GET_INSIGHTS = 'GET_INSIGHTS';
export const GET_INSIGHTS_IN_PROGRESS = 'GET_INSIGHTS_IN_PROGRESS';
export const GET_INSIGHTS_ERROR = 'GET_INSIGHTS_ERROR';
export const UPLOAD_INSIGHTS_FILE = 'UPLOAD_INSIGHTS_FILE';
export const UPLOAD_INSIGHTS_FILE_IN_PROGRESS =
  'UPLOAD_INSIGHTS_FILE_IN_PROGRESS';
export const UPLOAD_INSIGHTS_FILE_ERROR = 'UPLOAD_INSIGHTS_FILE_ERROR';
export const UPDATE_CHART_STATUS = 'UPDATE_CHART_STATUS';
export const UPDATE_CHART_STATUS_IN_PROGRESS =
  'UPDATE_CHART_STATUS_IN_PROGRESS';
export const UPDATE_CHART_STATUS_ERROR = 'UPDATE_CHART_STATUS_ERROR';
export const GET_INSIGHTS_FILE_HISTORY_SUCCESS =
  'GET_INSIGHTS_FILE_HISTORY_SUCCESS';
export const GET_INSIGHTS_FILE_HISTORY_IN_PROGRESS =
  'GET_INSIGHTS_FILE_HISTORY_IN_PROGRESS';
export const GET_INSIGHTS_FILE_HISTORY_ERROR =
  'GET_INSIGHTS_FILE_HISTORY_ERROR';

export const CREATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES =
  'CREATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES';
export const CREATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES_ERROR =
  'CREATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES_ERROR';
export const UPDATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES =
  'UPDATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES';
export const UPDATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES_ERROR =
  'UPDATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES_ERROR';

export const FIND_INSIGHTS_ANALYTICS_CONFIG_STATUS =
  'FIND_INSIGHTS_ANALYTICS_CONFIG_STATUS';
export const FIND_INSIGHTS_CLAIMS_CONFIG_STATUS =
  'FIND_INSIGHTS_CLAIMS_CONFIG_STATUS';
export const FIND_INSIGHTS_EMPLOYER_CONFIG_STATUS_ERROR =
  'FIND_INSIGHTS_EMPLOYER_CONFIG_STATUS_ERROR';

export const CREATE_MESSAGE_IN_PROGRESS = 'CREATE_MESSAGE_IN_PROGRESS';
export const CREATE_MESSAGE_COMPLETED = 'CREATE_MESSAGE_COMPLETED';
export const CREATE_MESSAGE_FAILED = 'CREATE_MESSAGE_FAILED';
export const GET_YEARS_IN_PROGRESS = 'GET_YEARS_IN_PROGRESS';
export const GET_YEARS_SUCCESS = 'GET_YEARS_SUCCESS';
export const GET_YEARS_ERROR = 'GET_YEARS_ERROR';

export const GET_MESSAGE_DATA_IN_PROGRESS = 'GET_MESSAGE_DATA_IN_PROGRESS';
export const GET_MESSAGE_DATA_SUCCESS = 'GET_MESSAGE_DATA_SUCCESS';
export const GET_MESSAGE_DATA_ERROR = 'GET_MESSAGE_DATA_ERROR';
