/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const FIND_MOBILE_EMPLOYER_CONFIGURATION_MODULES =
  'FIND_MOBILE_EMPLOYER_CONFIGURATION_MODULES';
export const FIND_DBG_ID_CARD_CONFIGURATION_MODULES =
  'FIND_DBG_ID_CARD_CONFIGURATION_MODULES';
export const FIND_ER_PORTAL_ID_CARD_CONFIGURATION_MODULES =
  'FIND_ER_PORTAL_ID_CARD_CONFIGURATION_MODULES';
export const FIND_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR =
  'FIND_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR';
export const CREATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES =
  'CREATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES';
export const CREATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR =
  'CREATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR';
export const UPDATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES =
  'UPDATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES';
export const UPDATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR =
  'UPDATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR';
export const FIND_CHATBOT_EMPLOYER_CONFIGURATION_MODULES =
  'FIND_CHATBOT_EMPLOYER_CONFIGURATION_MODULES';
export const FIND_CONSULTATION_CONFIGURATION_MODULES =
  'FIND_CONSULTATION_CONFIGURATION_MODULES';
export const UPDATE_EMPLOYER_CHATBOT_CONFIG = 'UPDATE_EMPLOYER_CHATBOT_CONFIG';
export const UPDATE_EMPLOYER_CHATBOT_CONFIG_IN_PROGRESS =
  'UPDATE_EMPLOYER_CHATBOT_CONFIG_IN_PROGRESS';
export const UPDATE_EMPLOYER_CHATBOT_CONFIG_ERROR =
  'UPDATE_EMPLOYER_CHATBOT_CONFIG_ERROR';
export const GET_CHATBOT_EMPLOYER_SUCCESS = 'GET_CHATBOT_EMPLOYER_SUCCESS';
export const GET_CHATBOT_EMPLOYER_IN_PROGRESS =
  'GET_CHATBOT_EMPLOYER_IN_PROGRESS';
export const GET_CHATBOT_EMPLOYER_ERROR = 'GET_CHATBOT_EMPLOYER_ERROR';
