/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { BILLING_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { permitIf } from 'components/hoc/Permit';
import {
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle
} from 'reactstrap';
import './ActionDropdown.scss';
class ActionDropdown extends React.Component {
  render() {
    const { row } = this.props;
    const options = [
      {
        label: 'Download File',
        value: 'DOWNLOAD'
      },
      {
        label: 'Preview & Publish',
        value: 'PUBLISH'
      },
      {
        label: 'Attach Carrier Files',
        value: 'ATTACH_CARRIER'
      },
      {
        label: 'File History',
        value: 'FILE_HISTORY'
      },
      {
        label: 'Delete',
        value: 'DELETE'
      }
    ];
    const SecuredDropdownItem = permitIf(
      DropdownItem,
      BILLING_FEATURE_ROLE_MAPPING.common
    );

    return (
      <UncontrolledDropdown auto_id="actions-dropdown">
        <div className="action-dropdown-toggle">
          <DropdownToggle tag="a" className="nav-link" caret>
            <b>Select</b>
          </DropdownToggle>
        </div>
        <div className="action-dropdown-menu">
          <DropdownMenu>
            {options.map(({ value, label }) => {
              if (value === 'ATTACH_CARRIER' || value === 'DELETE') {
                return (
                  <SecuredDropdownItem
                    key={value}
                    onClick={() => {
                      this.fileActionHandler(row, value);
                    }}
                  >
                    {label}
                  </SecuredDropdownItem>
                );
              } else {
                return (
                  <DropdownItem
                    key={value}
                    onClick={() => {
                      this.fileActionHandler(row, value);
                    }}
                  >
                    {label}
                  </DropdownItem>
                );
              }
            })}
          </DropdownMenu>
        </div>
      </UncontrolledDropdown>
    );
  }
  fileActionHandler = (row, operation) => {
    switch (operation) {
      case 'FILE_HISTORY':
        this.props.toggleDataHistoryModal(row);
        break;
      case 'ATTACH_CARRIER':
        this.props.toggleDataCarrierFilesModal(row);
        break;
      case 'DELETE':
        this.props.toggleDataDeleteFilesModal(row);
        break;
      case 'PUBLISH':
        this.props.toggleDataImportModal(row);
        break;
      case 'DOWNLOAD':
        this.props.downloadBill(row);
        break;
      default:
        break;
    }
  };
}

export default ActionDropdown;
