/*
 * Copyright (C) 2020 Lumity, Inc. - All Rights Reserved
 *
 * CONFIDENTIAL
 *
 * All information contained herein is, and remains the property of Lumity, Inc. and its partners, if any.
 * The intellectual and technical concepts contained herein are proprietary to Lumity, Inc. and its partners
 * and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless
 * prior written permission is obtained from Lumity, Inc.
 */
import React, { Component } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';
import moment from 'moment';
import DataTable from 'components/DataTables/DataTable/DataTableWithClientPagination';
import { connect } from 'react-redux';
import { getCurrentPlansFileHistory } from '../../../../actions/offerModelingAction';
import OfferService from 'services/OfferService';

import { OFFER_DATE_FORMAT } from './../../../../constants';
/**
 * This component includes upload button that enables/disable on the toggle button.
 */
class CurrentPlanFileHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredFileHistory: []
    };
  }
  componentDidMount() {
    this.props.getCurrentPlansFileHistory(this.props.employerId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { fileHistoryData } = this.props;
    if (fileHistoryData && fileHistoryData !== prevProps.fileHistoryData) {
      this.setState({
        filteredFileHistory: fileHistoryData
      });
    }
  }

  downloadFile = (employerId, fileId) => {
    console.log(employerId, fileId);
    window.location.href = OfferService.downloadHistoryFile(employerId, fileId);
  };

  getFileActions = (fileId) => {
    const { employerId } = this.props;
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="cursor-pointer font-weight-bold"
          caret
        >
          Select
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => this.downloadFile(employerId, fileId)}>
            Download
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };
  columnData = [
    {
      name: 'File Name',
      selector: 'fileName',
      cell: (row) => <div className="list-item-bold">{row.fileName}</div>
    },
    {
      name: 'Uploaded By',
      selector: 'uploadedBy'
    },
    {
      name: 'Date Uploaded',
      selector: 'uploadedAt',
      cell: (row) => moment(row.uploadedAt).format(OFFER_DATE_FORMAT)
    },
    {
      name: 'Actions',
      cell: (row) => this.getFileActions(row.id),
      ignoreRowClick: true,
      allowOverflow: true,
      width: '66px'
    }
  ];
  handleSearch = (event) => {
    const { fileHistoryData } = this.props;
    const text = event.target.value;
    const filteredFileHistory = fileHistoryData.filter(({ fileName }) =>
      fileName.includes(text)
    );
    this.setState({
      filteredFileHistory: filteredFileHistory
    });
  };
  render() {
    const { filteredFileHistory } = this.state;
    return (
      <div>
        <DataTable
          columnData={this.columnData}
          rowData={filteredFileHistory}
          noHeader
          highlightOnHover
          responsive
          handleSearch={this.handleSearch}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 30, 50, 100]}
          defaultSortField={'-uploadedAt'}
        />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getCurrentPlansFileHistory: (employerId) =>
    dispatch(getCurrentPlansFileHistory(employerId))
});

const mapStateToProps = (state) => {
  const {
    fileHistoryFetching,
    fileHistoryData,
    fileHistoryError
  } = state.offersReducer.OfferModelingReducer.currentPlanFileHistory;
  return {
    fileHistoryFetching,
    fileHistoryData,
    fileHistoryError
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentPlanFileHistory);
