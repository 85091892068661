import * as React from 'react';
import moment from 'moment';

import { Col, Row } from 'components/Atoms';
import Icon from 'components/Icons';
import {
  getOverallRatesText,
  getPecentileColorClassNames,
  isValuePresent
} from '../../../../../util';

import './Summary.scss';

const Summary = ({ benefitCategory, summaryInfo }) => {
  const {
    rateMarketPercentage,
    planDesignPercentile,
    contributionPercentile
  } = summaryInfo;
  const benCategoryDisplayText =
    benefitCategory.charAt(0).toUpperCase() + benefitCategory.slice(1);
  const currentLocaleData = moment.localeData();
  return (
    <div className="benchmark-plan-summary">
      <div className="title">{benCategoryDisplayText} Benchmark</div>
      <div className="devider"></div>
      <SummaryRow
        title="Plan Design"
        value={
          isValuePresent(planDesignPercentile)
            ? currentLocaleData.ordinal(planDesignPercentile)
            : 'N/A'
        }
        label="Percentile"
        colorClassName={getPecentileColorClassNames(planDesignPercentile)}
      />
      <SummaryRow
        title="Contributions"
        value={
          isValuePresent(contributionPercentile)
            ? currentLocaleData.ordinal(contributionPercentile)
            : 'N/A'
        }
        label="Percentile"
        colorClassName={getPecentileColorClassNames(contributionPercentile)}
      />
      <SummaryRow
        title="Rates"
        value={
          isValuePresent(rateMarketPercentage)
            ? `${
                rateMarketPercentage < 0
                  ? rateMarketPercentage
                  : `+${rateMarketPercentage}`
              }%`
            : 'N/A'
        }
        label={getOverallRatesText(rateMarketPercentage)}
        colorClassName={getOverallRatesText(rateMarketPercentage, true)}
      />
    </div>
  );
};

const SummaryRow = ({ title, value, label, colorClassName }) => {
  return (
    <div className="benefit-summary-row">
      <Row>
        <Col className="sub-header">{title}</Col>
      </Row>
      <Row>
        <Col>
          <Icon
            size="xs"
            icon="circle"
            className={`circle-icon ${colorClassName}`}
          />
          <span className="value">{value}</span>
          <span>{label}</span>
        </Col>
      </Row>
    </div>
  );
};

export default Summary;
