/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icons';
import { Input } from 'components/Atoms';

import './Searchbar.scss';

const Searchbar = (props) => {
  const { handleSearch ,value} = props;
  return (
    <div className="searchbar">
      <Icon className="search-icon" icon="search" size="lg" />
      <Input
        auto_id="search-input"
        placeholder="Search..."
        onChange={handleSearch}
        value={value}
      />
    </div>
  );
};

Searchbar.propTypes = {
  // Expects (event) => any
  handleSearch: PropTypes.func
};

Searchbar.defaultProps = {
  handleSearch: (event) => {}
};

export default Searchbar;
