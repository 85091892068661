/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const PER_TIER = 'PER_TIER';
export const BASE_PLAN_BUY_UP = 'BASE_PLAN_BUY_UP';

/**
 * BENEFIT_KIND_{kind} should match the BenefitKind enum from Platform's responses.
 */
export const BENEFIT_KIND_MEDICAL = 'MEDICAL';
export const BENEFIT_KIND_DENTAL = 'DENTAL';
export const BENEFIT_KIND_VISION = 'VISION';
export const BENEFIT_KIND_BASIC_LIFE = 'BASIC_LIFE';
export const BENEFIT_KIND_VOLUNTARY_LIFE = 'VOLUNTARY_LIFE';
export const BENEFIT_KIND_LTD = 'LTD';
export const BENEFIT_KIND_STD = 'STD';
export const BENEFIT_KIND_BASIC_ADD = 'BASIC_ADD';
export const BENEFIT_KIND_VOLUNTARY_ADD = 'VOLUNTARY_ADD';
export const BENEFIT_KIND_HSA = 'HSA';
export const BENEFIT_KIND_FSA = 'FSA';
export const BENEFIT_KIND_LIMITED_FSA = 'LIMITED_FSA';
export const BENEFIT_KIND_DCAP_FSA = 'DCAP_FSA';
export const BENEFIT_KIND_HRA = 'HRA';
export const BENEFIT_KIND_TRANSIT = 'TRANSIT';
export const BENEFIT_KIND_PARKING = 'PARKING';
export const BENEFIT_KIND_BIKING = 'BIKING';
export const BENEFIT_KIND_PAYOUT = 'PAYOUT';
export const BENEFIT_KIND_RETIREMENT_401K = 'RETIREMENT_401K';

export const BENEFIT_KIND_SAVING = 'SAVINGS';
export const BENEFIT_KIND_LIFE = 'LIFE';
export const BENEFIT_KIND_COMMUTER = 'COMMUTER';

// Benefit Category
export const BENEFIT_CATEGORY_MEDICAL  = 'MEDICAL';
export const BENEFIT_CATEGORY_DENTAL   = 'DENTAL';
export const BENEFIT_CATEGORY_VISION   = 'VISION';
export const BENEFIT_CATEGORY_SAVING   = 'SAVING';
export const BENEFIT_CATEGORY_LIFE     = 'LIFE';
export const BENEFIT_CATEGORY_COMMUTER = 'COMMUTER';
export const BENEFIT_CATEGORY_RETIREMENT = 'RETIREMENT';

const BENEFIT_CATEGORY_DISPLAY_MAP = {};
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_MEDICAL]  = 'Medical';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_DENTAL]   = 'Dental';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_VISION]   = 'Vision';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_SAVING]  = 'Spending';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_LIFE]     = 'Life';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_COMMUTER] = 'Commuter';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_RETIREMENT] = 'Retirement';

export const BENEFIT_KIND_DISPLAY_MAP = {};
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_MEDICAL] = 'Medical';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_DENTAL] = 'Dental';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_VISION] = 'Vision';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_BASIC_LIFE] = 'Basic Life';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_LIFE] = 'Voluntary Life';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_LTD] = 'LTD';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_STD] = 'STD';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_BASIC_ADD] = 'Basic ADD';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_ADD] = 'Voluntary ADD';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_HSA] = 'HSA';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_FSA] = 'FSA';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_LIMITED_FSA] = 'Limited FSA';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_DCAP_FSA] = 'DCAP FSA';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_HRA] = 'HRA';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_TRANSIT] = 'Transit';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_PARKING] = 'Parking';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_BIKING] = 'Biking';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_PAYOUT] = 'Payout';

export default BENEFIT_CATEGORY_DISPLAY_MAP;

const ERROR_CODE_OFFER_UPLOAD_NOT_ALLOWED = 'offers.file.upload.not.allowed'
export const MESSAGE_CONSTANTS = {};
MESSAGE_CONSTANTS[ERROR_CODE_OFFER_UPLOAD_NOT_ALLOWED] =
  'There are proposals available for this employer. Offers upload is not supported at this point.';
export const OFFER_DATE_FORMAT = 'MM/DD/YYYY hh:mm A';
