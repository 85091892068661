/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import axios from 'axios';
import Util, {
  CONTENT_TYPE_MULTIPART_FORM,
  CONTENT_TYPE_HEADER
} from '../util/apiUtil';

//configs
const baseURL = `${Util.baseApi}/${Util.version}/benefit-guides`;

export default {
  /**
   * create new ben guide.
   *
   * @param payload - Ben Guide Payload
   * @returns {AxiosPromise<any>}
   */
  create(payload) {
    return axios.post(`${baseURL}`, payload);
  },

  /**
   * search all benguides for given employer ID and search query.
   * @param employerId - Employer ID.
   * @param query - Search Query.
   */
  search(employerId, query, page, size) {
    const params = {
      employerId: employerId,
      _page: page,
      _size: size
    };
    if (query) {
      params['query'] = encodeURIComponent(query);
    }

    return axios.get(baseURL, { params: params });
  },

  /**
   * search all benguides for given employer ID and search query.
   * @param employerId - Employer ID.
   * @param query - Search Query.
   */
  findAll(employerId, selectedPlanYearIds, page, size, sort, query) {
    return axios.get(
      `${baseURL}?employerId=${employerId}&query=${encodeURIComponent(
        query
      )}&${Util.getPagingQueryString(
        page,
        size,
        sort
      )}&planYearIds=${selectedPlanYearIds}`
    );
  },

  /**
   * update existing ben guide.
   *
   * @param payload - Ben Guide Payload
   * @returns {AxiosPromise<any>}
   */
  update(payload, id) {
    return axios.put(`${baseURL}/${id}`, payload);
  },

  /**
   * publish the ben guide.
   *
   * @param payload - Ben Guide Publish
   * @returns {AxiosPromise<any>}
   */
  publish(id) {
    return axios.post(`${baseURL}/${id}/publish`);
  },

  /**
   * publish the ben guide.
   *
   * @param payload - Ben Guide Publish
   * @returns {AxiosPromise<any>}
   */
  preview(id) {
    return axios.post(`${baseURL}/${id}/previews`);
  },

  /**
   * Upload logo file
   *
   * @param {*} payload
   * @param {*} id
   */
  uploadLogo(payload, id) {
    const formData = new FormData();
    formData.append('file', payload);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };
    return axios.post(`${baseURL}/${id}/logos`, formData, config);
  },

  /**
   * Upload background image
   *
   * @param {*} payload
   * @param {*} id
   */
  uploadBackground(payload, id) {
    const formData = new FormData();
    formData.append('file', payload);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };
    return axios.post(`${baseURL}/${id}/backgrounds`, formData, config);
  },

  /**
   * Upload Perk Document
   *
   * @param {*} payload
   * @param {*} id
   */
  uploadPerkDocument(filename, payload, benGuideId) {
    const formData = new FormData();
    formData.append('file', payload);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };
    return axios.post(
      `${baseURL}/${benGuideId}/perk-documents`,
      formData,
      config
    );
  },

  /**
   * Upload Resource Document
   *
   * @param {*} payload
   * @param {*} id
   */
  uploadResourceDocument(payload, benGuideId) {
    const formData = new FormData();
    formData.append('file', payload);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };
    return axios.post(
      `${baseURL}/${benGuideId}/resource-documents`,
      formData,
      config
    );
  },

  /**
   * Upload Savings Video
   *
   * @param {*} payload
   * @param {*} id
   */
  uploadSavingsVideo(filename, payload, benGuideId) {
    const formData = new FormData();
    formData.append('file', payload);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };
    return axios.post(
      `${baseURL}/${benGuideId}/savings-videos`,
      formData,
      config
    );
  },

  /**
   * Get Logo file
   *
   * @param {*} id
   * @param {*} reference
   */
  getLogo(id, reference) {
    return `${baseURL}/${id}/logos/${reference}`;
  },

  /**
   * Get background file
   *
   * @param {*} id
   * @param {*} reference
   */
  getBackground(id, reference) {
    return `${baseURL}/${id}/backgrounds/${reference}/thumb`;
  },

  /**
   * Get work-life file
   *
   * @param {*} id
   * @param {*} reference
   * @param {*} subCategory
   */
  getWorkLifeImage(id, reference, subCategory) {
    return `${baseURL}/${id}/work-life-plans/images/${reference}/thumb?subCategory=${subCategory}`;
  },

  /**
   * Get additional perks file
   *
   * @param {*} id
   * @param {*} reference
   */
  getAdditionalPerkImage(id, reference) {
    return `${baseURL}/${id}/perk-images/${reference}/thumb`;
  },

  findLatestById(id) {
    return axios.get(`${baseURL}/${id}`);
  },

  /**
   * Upload OE Video
   *
   * @param {*} payload
   * @param {*} id
   */
  uploadOEVideo(benGuideId, fileBlob, fileName, progressNotifier) {
    const formData = new FormData();
    formData.append('file', fileBlob, fileName);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      },
      onUploadProgress: progressNotifier
    };
    return axios.post(`${baseURL}/${benGuideId}/oe-videos`, formData, config);
  },

  /**
   * Upload work-life images
   *
   * @param {*} payload
   * @param {*} id
   * @param {*} subCategory
   */
  uploadWorkLifeImage(payload, id, subCategory) {
    const formData = new FormData();
    formData.append('file', payload);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };
    return axios.post(
      `${baseURL}/${id}/work-life-plans/images?subCategory=${subCategory}`,
      formData,
      config
    );
  },

  /**
   * Upload additional perk images
   *
   * @param {*} payload
   * @param {*} id
   */
  uploadAdditionalPerkImage(payload, id) {
    const formData = new FormData();
    formData.append('file', payload);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };
    return axios.post(`${baseURL}/${id}/perk-images`, formData, config);
  },

  getOeMeeting(benGuideId) {
    return axios.get(`${baseURL}/${benGuideId}/oe-meetings`);
  },

  saveOeMeeting(benGuideId, oeMeeting) {
    return axios.post(`${baseURL}/${benGuideId}/oe-meetings`, oeMeeting);
  },

  deleteOeMeeting(benGuideId, meetingNumber) {
    return axios.delete(
      `${baseURL}/${benGuideId}/oe-meetings/${meetingNumber}`
    );
  },

  deleteCustomOeMeeting(benGuideId) {
    return axios.delete(`${baseURL}/${benGuideId}/oe-meetings`);
  },

  uploadOEMeetingRecording(
    benGuideId,
    fileBlob,
    fileName,
    progressNotifier,
    requestSource
  ) {
    const formData = new FormData();
    formData.append('file', fileBlob, fileName);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      },
      onUploadProgress: progressNotifier,
      cancelToken: requestSource.token
    };
    return axios.post(
      `${baseURL}/${benGuideId}/custom-meeting-recordings`,
      formData,
      config
    );
  },

  /**
   * Delete Benefit Guide and all its versions
   * @param {*} benGuideId
   */
  deleteBenguide(benGuideId) {
    return axios.delete(`${baseURL}/${benGuideId}`);
  },

  /**
   * Check if Benefit Guide has any published versions
   *
   * @param {*} benGuideId
   */
  checkPublishedVersionExists(benGuideId) {
    return axios.get(`${baseURL}/${benGuideId}/has-published-versions`);
  }
};
