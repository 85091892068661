/**********************************************************************************************************************
 * Copyright (C) 2019-2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import axios from 'axios';
import Util, {
  CONTENT_TYPE_MULTIPART_FORM,
  CONTENT_TYPE_HEADER
} from '../util/apiUtil';

//configs
const baseURL = `${Util.baseApi}/${Util.version}/organizations`;

export default {
  findAllOrganisations() {
    return axios.get(`${baseURL}/list`);
  },

  findById(id) {
    return axios.get(`${baseURL}/${id}`);
  },

  findAll(page, size, sort ,query) {
    const params = {
      _page: page,
      _size: size,
      _sort: sort
    };
    if (query) {
      params['query'] = encodeURIComponent(query);
    }
    return axios.get(baseURL, { params: params });
  },
  
  createOrganisation(payload) {
    return axios.post(`${baseURL}`, payload);
  },

  findAllOrganisationsWithPage(page,
    size,
    sort,
    query,
  ) {
    const params = {
      _page: page,
      _size: size,
      _sort: sort
    };
    if (query) {
      params['query'] = encodeURIComponent(query);
    }
    return axios.get(baseURL, { params: params });
  },

  updateOrganisation(id, payload) {
    return axios.put(`${baseURL}/${id}`, payload);
  },

  uploadLogo(organisationId, file) {
    const formData = new FormData();
    formData.append('file', file, file.filename);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };
    return axios.post(`${baseURL}/${organisationId}/logo`, formData, config);
  },

  deleteOrganization(organizationId) {
    return axios.delete(`${baseURL}/${organizationId}`);
  }
};



