/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import axios from 'axios';
import Util, {
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_MULTIPART_FORM
} from '../util/apiUtil';

//configs
const baseURL = `${Util.baseApi}/${Util.version}/offer-proposals`;
const medicalBaseURL = `${Util.baseApi}/${Util.version}/medical-offer-plans`;
const dentalBaseURL = `${Util.baseApi}/${Util.version}/dental-offer-plans`;
const visionBaseURL = `${Util.baseApi}/${Util.version}/vision-offer-plans`;
const lifeBaseURL = `${Util.baseApi}/${Util.version}/life-offer-plans`;
const bundlesBaseURL = `${Util.baseApi}/${Util.version}/offers`;

export default {
  /**
   * Upload proposal sheet.
   *
   * @param id - plan id
   * @param benefitKind - Benefitkind
   * @param documentType - Doc Type
   * @param fileBlob - file
   * @returns {AxiosPromise<any>}
   */
  uploadOfferPlans(employerId, fileName, fileBlob, progressNotifier) {
    const formData = new FormData();
    formData.append('file', fileBlob, fileName);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      },
      onUploadProgress: progressNotifier
    };
    return axios.post(
      `${baseURL}/parse?employerId=${employerId}`,
      formData,
      config
    );
  },

  /**
   * get all medical proposal plans
   *
   * @returns {AxiosPromise<any>}
   */
  findMedicalPlans(employerId) {
    return axios.get(`${medicalBaseURL}?employerId=${employerId}`);
  },
  /**
   * find basic details of medical proposal plans
   * @param employerId
   * @returns {AxiosPromise<any>}
   */
  findMedicalPlanBasicDetails(employerId) {
    return axios.get(`${medicalBaseURL}/basic?employerId=${employerId}`);
  },
  /**
   * get all dental proposal plans
   *
   * @returns {AxiosPromise<any>}
   */
  findDentalPlans(employerId) {
    return axios.get(`${dentalBaseURL}?employerId=${employerId}`);
  },
  /**
   * finds basic details of dental proposal plans
   * @param employerId
   * @returns {AxiosPromise<any>}
   */
  findBasicDentalPlans(employerId) {
    return axios.get(`${dentalBaseURL}/basic?employerId=${employerId}`);
  },
  /**
   * get all vision proposal plans
   *
   * @returns {AxiosPromise<any>}
   */
  findVisionPlans(employerId) {
    return axios.get(`${visionBaseURL}?employerId=${employerId}`);
  },
  /**
   * find basic details of vision proposal plans
   * @param employerId
   * @returns {AxiosPromise<any>}
   */
  findBasicVisionPlans(employerId) {
    return axios.get(`${visionBaseURL}/basic?employerId=${employerId}`);
  },
  /**
   * get all life proposal plans
   *
   * @returns {AxiosPromise<any>}
   */
  findLifePlans(employerId) {
    return axios.get(`${lifeBaseURL}?employerId=${employerId}`);
  },
  /**
   * get basic life plan details
   * @param employerId
   * @returns {AxiosPromise<any>}
   */
  findLifePlanBasicDetails(employerId) {
    return axios.get(`${lifeBaseURL}/basic?employerId=${employerId}`);
  },
  /**
   * get all medical proposal bundles
   *
   * @returns {AxiosPromise<any>}
   */
  findMedicalOffers(employerId) {
    return axios.get(
      `${bundlesBaseURL}?employerId=${employerId}&categories=MEDICAL`
    );
  },
  /**
   * get all dental proposal bundles
   *
   * @returns {AxiosPromise<any>}
   */
  findDentalOffers(employerId) {
    return axios.get(
      `${bundlesBaseURL}?employerId=${employerId}&categories=DENTAL`
    );
  },
  /**
   * get all vision proposal bundles
   *
   * @returns {AxiosPromise<any>}
   */
  findVisionOffers(employerId) {
    return axios.get(
      `${bundlesBaseURL}?employerId=${employerId}&categories=VISION`
    );
  },
  /**
   * get all life & disability proposal bundles
   *
   * @returns {AxiosPromise<any>}
   */
  findLifeOffers(employerId) {
    return axios.get(
      `${bundlesBaseURL}?employerId=${employerId}&categories=LIFE`
    );
  },

  /**
   * calculate contributions for the proposal plans.
   *
   * @param planId
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  calculateContributions(bundleId, planId, payload) {
    return axios.post(
      `${bundlesBaseURL}/${bundleId}/plans/${planId}/contributions`,
      payload
    );
  },

  /**
   * calculate contributions for the proposal plans (base Buy-up).
   *
   * @param planId
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  calculateContributionsBaseBuyUp(bundleId, payload) {
    return axios.post(`${bundlesBaseURL}/${bundleId}/calculations`, payload);
  },

  /**
   * Get current plans names and IDs by Employer ID and Category.
   *
   * @param employerId
   * @param category
   * @returns {AxiosPromise<any>}
   */
  getCurrentPlans(employerId, category) {
    return axios.get(
      `${bundlesBaseURL}/current_plans?employerId=${employerId}&category=${category}`
    );
  },

  /**
   * Add plans to proposal bundle.
   * @param {*} bundleId The id of the plan group.
   * @param {*} planIds the proposal plan ids which need to be added.
   */
  addPlansToProposalOffer(bundleId, planIds) {
    return axios.put(`${bundlesBaseURL}/${bundleId}/plans`, planIds);
  },

  /**
   * get current plan modeling file history for employer
   * @param employerId
   * @returns {AxiosPromise<any>}
   */
  getPlanFileHistory(employerId) {
    return axios.get(`${baseURL}/employers/${employerId}/file-history`);
  },

  /**
   * download history file
   * @param employerId
   * @param fileId
   * @returns String
   */
  downloadHistoryFile(employerId, fileId) {
    return `${baseURL}/employers/${employerId}/file-history/${fileId}/download`;
  }
};
