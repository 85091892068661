/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import PrivateRoute from './PrivateRoute';
import AppBaseRoutes from 'modules/app-base/routes';
import AdminsRoutes from 'modules/Admins/routes';
import SuAdminsRoutes from 'modules/Admins/suRoutes';
import EmployersRoutes from 'modules/Employers/routes';
import LocationsRoutes from 'modules/Locations/routes';
import EmployeesRoutes from 'modules/Employees/routes';
import PlansRoutes from 'modules/Plans/routes';
import IssueLogRoutes from 'modules/IssueLog/routes';
import RenewalsRoutes from 'modules/Renewals/routes';
import MobileRoutes from 'modules/Mobile/routes';
import ChatbotRoutes from 'modules/Chatbot/routes';
import DocumentRoutes from 'modules/DocumentV2/routes';
import CarrierRoutes from 'modules/Carriers/routes';
import InsightsRoutes from 'modules/Insights/routes';
import BenguideRoutes from 'modules/BenGuides/routes';
import BenchmarkRoutes from 'modules/Benchmarks/routes';
import CalendarRoutes from 'modules/Calendar/routes';
import BillingRoutes from 'modules/Billing/routes';
import SurveysRoutes from 'modules/Surveys/routes';
import IntegrationsRoutes from 'modules/Integrations/routes';
import OffersRoutes from 'modules/Offers/routes';
import BenfefitsConsultationRoutes from 'modules/BenefitsConsultation/routes';
import SSORoutes from 'modules/SSO/routes';
import NativeIssueLogRoutes from 'modules/NativeIssueLog/routes';
import OrganisationRoutes from 'modules/Organisations/routes';
import BenAdminIntegrationsRoutes from 'modules/BenAdminIntegrations/routes';
import CurrentPlansRoutes from 'modules/CurrentPlans/routes';

const rootRoutes = [
  ...AppBaseRoutes,
  ...AdminsRoutes,
  ...SuAdminsRoutes,
  ...EmployersRoutes,
  ...LocationsRoutes,
  ...EmployeesRoutes,
  ...PlansRoutes,
  ...IssueLogRoutes,
  ...RenewalsRoutes,
  ...MobileRoutes,
  ...ChatbotRoutes,
  ...DocumentRoutes,
  ...CarrierRoutes,
  ...InsightsRoutes,
  ...MobileRoutes,
  ...BenguideRoutes,
  ...BenchmarkRoutes,
  ...CalendarRoutes,
  ...BillingRoutes,
  ...SurveysRoutes,
  ...IntegrationsRoutes,
  ...OffersRoutes,
  ...BenfefitsConsultationRoutes,
  ...SSORoutes,
  ...NativeIssueLogRoutes,
  ...OrganisationRoutes,
  ...BenAdminIntegrationsRoutes,
  ...CurrentPlansRoutes
];

export default rootRoutes;
export { PrivateRoute };
