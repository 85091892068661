/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';

import './TabView.scss';

class TabView extends Component {
  tabClicked(tabType) {
    if (this.props.onTabClick instanceof Function) {
      this.props.onTabClick(tabType);
    }
  }

  render() {
    const {
      medicalContent,
      dentalContent,
      visionContent,
      lifeContent
    } = this.props;

    return (
      <Tabs className="tab-view" defaultTab="medical">
        <TabList className="tab-list">
          <Tab
            tabFor="medical"
            onClick={() => this.tabClicked('MEDICAL')}
            className="plan-tab"
          >
            Medical
          </Tab>
          <Tab
            tabFor="dental"
            onClick={() => this.tabClicked('DENTAL')}
            className="plan-tab"
          >
            Dental
          </Tab>
          <Tab
            tabFor="vision"
            onClick={() => this.tabClicked('VISION')}
            className="plan-tab"
          >
            Vision
          </Tab>
          <Tab
            tabFor="life"
            onClick={() => this.tabClicked('LIFE')}
            className="plan-tab"
          >
            Life & Disability
          </Tab>
        </TabList>
        <TabPanel tabId="medical">{medicalContent}</TabPanel>
        <TabPanel tabId="dental">{dentalContent}</TabPanel>
        <TabPanel tabId="vision">{visionContent}</TabPanel>
        <TabPanel tabId="life">{lifeContent}</TabPanel>
      </Tabs>
    );
  }
}
export default TabView;
