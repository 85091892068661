/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import EmployerFeatureConfigurationService from 'services/EmployerFeatureConfigurationService';
import {
  UPDATE_BENEFITS_CONSULTATION_CONFIG,
  UPDATE_BENEFITS_CONSULTATION_CONFIG_SUCCESS,
  UPDATE_BENEFITS_CONSULTATION_CONFIG_ERROR,
  GET_BENEFITS_CONSULTATION_EMPLOYER_SUCCESS,
  GET_BENEFITS_CONSULTATION_EMPLOYER_ERROR,
  GET_BENEFITS_CONSULTATION_EMPLOYER
} from '../actionTypes/benefitsConsultation.actionTypes';
import { SERVICE_BENEFITS_CONSULTATION_ENABLED } from '../constants';

export const updateBenefitConsultationConfigStatus = (config) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_BENEFITS_CONSULTATION_CONFIG });
    try {
      let response = await EmployerFeatureConfigurationService.updateConfigModule(
        config
      );
      response = await EmployerFeatureConfigurationService.findConfigStatus(
        config.employerId,
        SERVICE_BENEFITS_CONSULTATION_ENABLED
      );

      dispatch({
        type: UPDATE_BENEFITS_CONSULTATION_CONFIG_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: UPDATE_BENEFITS_CONSULTATION_CONFIG_ERROR,
        payload: error
      });
    }
  };
};

export const getBenefitConsultationConfigStatus = (employerId) => {
  return async (dispatch) => {
    dispatch({ type: GET_BENEFITS_CONSULTATION_EMPLOYER });
    try {
      let response = await EmployerFeatureConfigurationService.findConfigStatus(
        employerId,
        SERVICE_BENEFITS_CONSULTATION_ENABLED
      );
      if (!response.data) {
        // if doesn't exists create one
        const featureConfig = {
          employerId: employerId,
          key: SERVICE_BENEFITS_CONSULTATION_ENABLED,
          value: String(false)
        };
        response = await EmployerFeatureConfigurationService.createConfigModule(
          featureConfig
        );
      }
      dispatch({
        type: GET_BENEFITS_CONSULTATION_EMPLOYER_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: GET_BENEFITS_CONSULTATION_EMPLOYER_ERROR,
        payload: error
      });
    }
  };
};
