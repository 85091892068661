/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import CurrentPlanFileHistory from '../CurrentPlanFileHistory';
import Util from 'util/apiUtil';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col
} from 'components/Atoms';
import './styles.scss';

class DialogFileHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggle = () => {
    const { toggle } = this.props;
    toggle();
  };

  render() {
    const { isOpen, employerId } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.toggle}
        backdrop="static"
        keyboard={false}
        wrapClassName="dialog-file-history"
        className="dialog-file-history__dialog"
        contentClassName="dialog-file-history__content"
        unmountOnClose={true}
      >
        <ModalHeader className="modal-header" toggle={this.toggle}>
          <h2>File History</h2>
        </ModalHeader>
        <ModalBody className="modal-body">
          <Row>
            <Col md={6} className="download-raw-template">
              <a
                href={`${Util.baseApi}/${Util.version}/offer-proposals/document/template`}
              >
                Download Raw Template
              </a>
            </Col>
          </Row>
          <CurrentPlanFileHistory employerId={employerId} />
        </ModalBody>
      </Modal>
    );
  }
}

export default DialogFileHistory;
