export const EXPIRE_TIME = 'expire_time'; // expire key for local storage.
export const LOGGED_IN_USER_EMAIL = 'logged_user'; // expire key for local storage.

export const LOGIN_TYPE_INTERNAL = 'INTERNAL_ADMIN';
export const LOGIN_TYPE_EXTERNAL = 'EXTERNAL_EMPLOYEE';

export const LoginStatus = {
  ACTIVE: { value: 'ACTIVE', label: 'Active' },
  INACTIVE: { value: 'INACTIVE', label: 'Inactive' },
  LOCKED: { value: 'LOCKED', label: 'Locked' },
  DEFAULT: { value: '', label: 'N/A' }
};
