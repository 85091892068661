/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import {
  UsersList,
  UserDetails,
  AdminRolesList,
  UsersListForRole,
  CalendarTemplates
} from './index';

export const ADMINS_USERS_LIST_PATH = '/brokers/:brokerId/admins/users';
export const ADMINS_USER_DETAILS_PATH = '/brokers/:brokerId/admins/users/:loginId';
export const ADMINS_ROLES_LIST_PATH = '/brokers/:brokerId/admins/roles';
export const ADMINS_USERS_LIST_FOR_ROLE_PATH = '/brokers/:brokerId/admins/roles/:roleName';
export const ADMIN_CALENDAR_TEMPLATES_PATH = '/brokers/:brokerId/admins/calendars';
export const ADMIN_MOBILE_VERSIONS_PATH = '/brokers/:brokerId/admins/mobile';

const adminsRoutes = [
  { path: ADMINS_USERS_LIST_PATH, component: UsersList },
  { path: ADMINS_USER_DETAILS_PATH, component: UserDetails },
  { path: ADMINS_ROLES_LIST_PATH, component: AdminRolesList },
  { path: ADMINS_USERS_LIST_FOR_ROLE_PATH, component: UsersListForRole },
  { path: ADMIN_CALENDAR_TEMPLATES_PATH, component: CalendarTemplates }
];

export default adminsRoutes;
