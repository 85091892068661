/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import { NotificationManager } from 'react-notifications';
import * as ActionTypes from 'modules/Integrations/actionTypes/integrations.actionTypes';
import {
  startAllSync,
  startAllBenAdminSync,
  getAllHrisSyncDetails,
  getAllBenAdminSyncDetails,
  getSyncErrorFilers,
  getErrorList,
  downloadErrorReport,
  downloadConfigFile,
  downloadFile
} from 'modules/Integrations/actions/integrationsAction';
import {
  downloadBenAdminSyncErrorReport,
  getBenAdminSyncErrorList,
  getBenAdminSyncErrorFilters
} from '../../../BenAdminIntegrations/actions/integrationsAction';
import { permitIf } from 'components/hoc/Permit';
import { INTEGRATIONS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';

import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import PageActionButton from 'components/Buttons/PageActionButton';
import { Col, Row } from 'components/Atoms';
import { getUrlParameter } from 'util/urlUtil';
import DialogErrorDetails from 'modules/Integrations/components/DialogErrorDetails';
import BenAdminDialogErrorDetails from 'modules/BenAdminIntegrations/components/DialogErrorDetails';

import HRIntegrationSyncStatusList from './components/HRIntegrationSyncStatusList';
import BenAdminIntegrationSyncStatusList from './components/BenAdminIntegrationsSyncStatusList';
// constants
import { MENU_ACTIONS } from 'modules/Integrations/constants';
import {
  BENADMIN_MENU_ACTIONS,
  SYNC_TYPE
} from 'modules/BenAdminIntegrations/constants';
import './IntegrationHome.scss';

class IntegrationHome extends React.Component {
  constructor(props) {
    super(props);
    let selectedTab = getUrlParameter('to');

    const tabSelection =
      ['hr_integrations', 'benAmin_integrations'].indexOf(selectedTab) === -1
        ? 'hr_integrations'
        : selectedTab;

    this.state = {
      selectedSync: null,
      activeTab: tabSelection,
      bShowErrorDetailsDialog: false,
      showBenadminSyncDetailsDialog: false,
      excludeTerminatedEmployees: true
    };

    this.HRIntegrationSyncStatusList = React.createRef();
    this.BenAdminIntegrationSyncStatusList = React.createRef();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { requestStatus, serviceError, configStatus } = this.props;
    const { requestStatus: prevRequestStatus } = prevProps;
    if (prevRequestStatus === requestStatus) return;

    if (serviceError) {
      // Generic Error
      NotificationManager.error(serviceError.message);
      return;
    }

    switch (requestStatus) {
      case ActionTypes.GET_INTEGRATIONS_FEATURE_CONF_SUCCESS: {
        const value = configStatus.value;
        NotificationManager.success(
          `Feature ${value === 'true' ? 'Enabled' : 'Disabled'} Successfully`
        );
        return;
      }
      case ActionTypes.START_ALL_SYNC_SUCCESS: {
        NotificationManager.success(`Sync started Successfully`);
        this.HRIntegrationSyncStatusList.current.reloadList();
        return;
      }
      case ActionTypes.START_ALL_BENADMIN_SYNC_SUCCESS: {
        NotificationManager.success(`Sync started Successfully`);
        this.BenAdminIntegrationSyncStatusList.current.reloadList();
        return;
      }
      case ActionTypes.DOWNLOAD_ERROR_REPORT_SUCCESS: {
        NotificationManager.success(
          `Error Report File downloaded successfully.`
        );
        return;
      }
      default:
        break;
    }
  }

  setActiveTab = (tabId) => {
    this.setState({ activeTab: tabId });
  };

  syncAll = () => {
    const { match } = this.props;
    const { params } = match;
    this.props.startAllSync({}, params.brokerId);
  };

  syncAllBenadmin = () => {
    const { match, startAllBenAdminSync } = this.props;
    const { params } = match;
    startAllBenAdminSync({}, params.brokerId);
  };

  downloadFile = (syncDetails) => {
    const { id: syncDetailsId, employerId } = syncDetails;
    this.props.downloadFile(employerId, syncDetailsId);
  };

  downloadConfigFile = (syncDetails) => {
    const { downloadConfigFile } = this.props;
    const { id: syncDetailsId, employerId } = syncDetails;
    downloadConfigFile(employerId, syncDetailsId);
  };

  handleMenuClick = (action, data) => {
    switch (action) {
      case 'VIEW':
        this.showErrorDetailsDialog(data);
        break;
      case 'DOWNLOAD':
        this.downloadErrorReport(data);
        break;
      case MENU_ACTIONS.DOWNLOAD_FILE:
        this.downloadFile(data);
        break;
      case MENU_ACTIONS.DOWNLOAD_CONFIG_FILE:
        this.downloadConfigFile(data);
        break;
      default:
        break;
    }
  };

  handleBenAdminMenuClick = (action, data, syncType) => {
    const { employerId, id: syncDetailsId } = data;
    const { downloadBenAdminSyncErrorReport } = this.props;
    switch (action) {
      case BENADMIN_MENU_ACTIONS.VIEW_SYNC_DETAILS:
        this.showBenAdminSyncDetailsDialog(data);
        break;
      case BENADMIN_MENU_ACTIONS.DOWNLOAD_EMPLOYEE_SYNC_DETAILS:
      case BENADMIN_MENU_ACTIONS.DOWNLOAD_ENROLLMENT_SYNC_DETAILS:
        downloadBenAdminSyncErrorReport(employerId, syncDetailsId, syncType);
        break;
      default:
        break;
    }
  };

  toggleErrorDetailsDialog = () => {
    const data = this.state.bShowErrorDetailsDialog
      ? null
      : this.state.selectedSync;
    this.setState({
      bShowErrorDetailsDialog: !this.state.bShowErrorDetailsDialog,
      selectedSync: data,
      excludeTerminatedEmployees: true
    });
  };

  toggleBenadminSyncDetailsDialog = () => {
    const { showBenadminSyncDetailsDialog } = this.state;
    const data = showBenadminSyncDetailsDialog ? null : this.state.selectedSync;
    this.setState({
      showBenadminSyncDetailsDialog: !showBenadminSyncDetailsDialog,
      selectedSync: data
    });
  };

  showErrorDetailsDialog = (data) => {
    const { employerId, id: syncDetailsId } = data;
    const { excludeTerminatedEmployees } = this.state;
    this.props.getSyncErrorFilers(
      employerId,
      syncDetailsId,
      excludeTerminatedEmployees
    );
    this.setState({
      bShowErrorDetailsDialog: true,
      selectedSync: data
    });
  };

  showBenAdminSyncDetailsDialog = (data) => {
    const { employerId, id: syncDetailsId } = data;
    const { getBenAdminSyncErrorFilters } = this.props;
    let employeeSync = SYNC_TYPE.EMPLOYEE_SYNC;
    let enrollmentSync = SYNC_TYPE.ENROLLMENT_SYNC;
    let employeeSyncConfig = {
      employerId,
      syncDetailsId,
      syncType: employeeSync
    };
    let enrollmentSyncConfig = {
      employerId,
      syncDetailsId,
      syncType: enrollmentSync
    };
    getBenAdminSyncErrorFilters(employeeSyncConfig);
    getBenAdminSyncErrorFilters(enrollmentSyncConfig);
    this.setState({
      showBenadminSyncDetailsDialog: true,
      selectedSync: data
    });
  };

  downloadErrorReport = (syncDetails) => {
    const { excludeTerminatedEmployees } = this.state;
    const { id: syncDetailsId, employerId } = syncDetails;
    this.props.downloadErrorReport(
      employerId,
      syncDetailsId,
      excludeTerminatedEmployees
    );
  };

  downloadSyncDetailsReport = (employerId, syncDetailsId, syncType) => {
    const { downloadBenAdminSyncErrorReport } = this.props;
    downloadBenAdminSyncErrorReport(employerId, syncDetailsId, syncType);
  };

  updateTerminatedEmployeeSelection = (includeTerminatedEmployees) => {
    if (includeTerminatedEmployees) {
      this.setState({ excludeTerminatedEmployees: false });
      this.updateFilters(!includeTerminatedEmployees);
    } else {
      this.setState({ excludeTerminatedEmployees: true });
      this.updateFilters(!includeTerminatedEmployees);
    }
  };

  updateFilters = (excludeTerminatedEmployees) => {
    const { selectedSync } = this.state;
    const { employerId, id: syncDetailsId } = selectedSync;
    this.props.getSyncErrorFilers(
      employerId,
      syncDetailsId,
      excludeTerminatedEmployees
    );
  };

  render() {
    const {
      hrIntegrations,
      benAdminIntegrations,
      getAllHrisSyncDetails,
      getAllBenAdminSyncDetails,
      syncErrors,
      syncErrorFilters,
      getErrorList,
      organization,
      match,
      getBenAdminSyncErrorList,
      benadminSyncEmployeeFilters,
      benadminSyncEnrollmentFilters,
      syncBenAdminEmployeeErrors,
      syncBenAdminEnrollmentErrors
    } = this.props;
    const {
      activeTab,
      bShowErrorDetailsDialog,
      selectedSync,
      showBenadminSyncDetailsDialog,
      excludeTerminatedEmployees
    } = this.state;
    const { params } = match;
    const organizationId = organization ? organization.id : params.brokerId;

    const SecuredSyncButton = permitIf(
      PageActionButton,
      INTEGRATIONS_FEATURE_ROLE_MAPPING.syncAll
    );

    return (
      <ApplicationWrapper>
        <HeaderContainer title="Integrations" />
        <Row className="page-integration-home">
          <Col className="page-content">
            <Tabs
              className="tab-view"
              defaultTab={activeTab}
              onChange={(tabId) => this.setActiveTab(tabId)}
            >
              <TabList className="tab-list">
                <Tab tabFor="hr_integrations">HR Integrations</Tab>
                <Tab tabFor="benAdmin_integrations">BenAdmin Integrations</Tab>
              </TabList>
              <TabPanel tabId="hr_integrations">
                <ContentContainer>
                  <HRIntegrationSyncStatusList
                    ref={this.HRIntegrationSyncStatusList}
                    data={hrIntegrations}
                    getAllSyncDetails={getAllHrisSyncDetails}
                    menuClick={this.handleMenuClick}
                    organizationId={organizationId}
                  />
                </ContentContainer>
              </TabPanel>
              <TabPanel tabId="benAdmin_integrations">
                <ContentContainer>
                  <BenAdminIntegrationSyncStatusList
                    ref={this.BenAdminIntegrationSyncStatusList}
                    data={benAdminIntegrations}
                    getAllSyncDetails={getAllBenAdminSyncDetails}
                    benAdminMenuClick={this.handleBenAdminMenuClick}
                    organizationId={organizationId}
                  />
                </ContentContainer>
              </TabPanel>
            </Tabs>
          </Col>
          <Col className="page-actions">
            {activeTab === 'hr_integrations' ? (
              <SecuredSyncButton onClick={this.syncAll}>
                Sync All ( HRIS )
              </SecuredSyncButton>
            ) : (
              <SecuredSyncButton onClick={this.syncAllBenadmin}>
                Sync All ( BenAdmin )
              </SecuredSyncButton>
            )}
          </Col>
        </Row>
        <DialogErrorDetails
          isOpen={bShowErrorDetailsDialog}
          toggle={this.toggleErrorDetailsDialog}
          data={syncErrors}
          employerId={selectedSync ? selectedSync.employerId : ''}
          selectedSync={selectedSync}
          getErrorList={getErrorList}
          syncErrorFilters={syncErrorFilters}
          downloadErrorReport={this.downloadErrorReport}
          excludeTerminatedEmployees={excludeTerminatedEmployees}
          updateTerminatedEmployeeSelection={this.updateTerminatedEmployeeSelection}
        />

        <BenAdminDialogErrorDetails
          isOpen={showBenadminSyncDetailsDialog}
          toggle={this.toggleBenadminSyncDetailsDialog}
          syncBenAdminEmployeeErrors={syncBenAdminEmployeeErrors}
          syncBenAdminEnrollmentErrors={syncBenAdminEnrollmentErrors}
          employerId={selectedSync ? selectedSync.employerId : ''}
          selectedSync={selectedSync}
          getErrorList={getBenAdminSyncErrorList}
          syncEmployeeDetailsFilters={benadminSyncEmployeeFilters}
          syncEnrollmentDetailsFilters={benadminSyncEnrollmentFilters}
          downloadErrorReport={this.downloadSyncDetailsReport}
        />
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startAllSync: (config, organizationId) =>
    dispatch(startAllSync(config, organizationId)),
  startAllBenAdminSync: (config, organizationId) => dispatch(startAllBenAdminSync(config, organizationId)),
  getAllHrisSyncDetails: (config) => dispatch(getAllHrisSyncDetails(config)),
  getAllBenAdminSyncDetails: (config) =>
    dispatch(getAllBenAdminSyncDetails(config)),
  getSyncErrorFilers: (employerId, syncDetailsId, excludeTerminatedEmployees) =>
    dispatch(getSyncErrorFilers(employerId, syncDetailsId, excludeTerminatedEmployees)),
  getErrorList: (config) => dispatch(getErrorList(config)),
  downloadErrorReport: (employerId, syncDetailsId, excludeTerminatedEmployees) =>
    dispatch(downloadErrorReport(employerId, syncDetailsId, excludeTerminatedEmployees)),
  downloadFile: (employerId, syncDetailsId) =>
    dispatch(downloadFile(employerId, syncDetailsId)),
  downloadConfigFile: (employerId, syncDetailsId) =>
    dispatch(downloadConfigFile(employerId, syncDetailsId)),
  getBenAdminSyncErrorFilters: (config) =>
    dispatch(getBenAdminSyncErrorFilters(config)),
  getBenAdminSyncErrorList: (config) =>
    dispatch(getBenAdminSyncErrorList(config)),
  downloadBenAdminSyncErrorReport: (employerId, syncDetailsId, syncType) =>
    dispatch(
      downloadBenAdminSyncErrorReport(employerId, syncDetailsId, syncType)
    )
});

const mapStateToProps = (state) => {
  const {
    requestStatus,
    serviceError,
    isLoading,
    configStatus,
    hrIntegrations,
    benAdminIntegrations,
    syncErrorFilters,
    syncErrors
  } = state.integrationsReducer;
  const organization = state.AppBase.contextReducer.organizationContext;
  const {
    syncBenAdminEmployeeErrors,
    syncBenAdminEnrollmentErrors,
    benadminSyncEnrollmentFilters,
    benadminSyncEmployeeFilters
  } = state.benAdminIntegrationsReducer;
  return {
    requestStatus,
    serviceError,
    isLoading,
    configStatus,
    hrIntegrations,
    benAdminIntegrations,
    syncErrorFilters,
    syncErrors,
    organization,
    syncBenAdminEmployeeErrors,
    syncBenAdminEnrollmentErrors,
    benadminSyncEnrollmentFilters,
    benadminSyncEmployeeFilters
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationHome);
