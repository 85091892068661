/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import FullscreenModal from 'components/FullscreenModal';

import MDVWeightingsModal from './MDVWeightings/MDVWeightings';

import './AdjustWeightingsModal.scss';
class AdjustWeightingsModal extends Component {
  displayBenefitTypeModal = () => {
    const {
      benefitType: simpleBenefitType,
      totalWeighting,
      toggle,
      weights,
      updateWeights,
      planKey
    } = this.props;
    const benefitType = simpleBenefitType.toUpperCase();

    return (
      <MDVWeightingsModal
        benefitType={benefitType}
        totalWeighting={totalWeighting}
        closeModal={toggle}
        weights={weights}
        updateWeights={updateWeights}
        toggle={toggle}
        planKey={planKey}
      />
    );
  };
  
  render() {
    const { isOpen, toggle, totalWeighting } = this.props;
    return (
      <FullscreenModal
        isOpen={isOpen}
        toggle={toggle}
        onClosed={this.resetContext}
        title="Adjust Weightings"
        subTitle={`Plan design weightings must be equal to ${totalWeighting}.`}
        closeText={'cancel'}
      >
        {this.displayBenefitTypeModal()}
      </FullscreenModal>
    );
  }
}
export default AdjustWeightingsModal;
