/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, Row, Col, Alert } from 'components/Atoms';
import { TextFormGroup } from 'components/DataForm';
import Icon from 'components/Icons';

// styles
import './LFIConfiguration.scss';

// constants
import { ERROR_MSGS } from 'modules/Integrations/constants';

export const PayGroupRow = ({
  lfiPayGroup,
  lumityPayGroup,
  updatePayGroup,
  removePayGroup,
  fieldErrors
}) => {
  return (
    <div className="lfi-payGroup-field-row">
      <Button color="link" onClick={removePayGroup} className="remove-icon">
        <Icon icon="minus-circle" />
      </Button>
      <Row>
        <Col>
          <TextFormGroup
            labelDisplay="LFI Pay Schedule"
            inputName="lfiPayGroup"
            inputValue={lfiPayGroup}
            onChange={updatePayGroup}
            placeholder="Enter LFI Pay Schedule"
            isInvalid={!!(fieldErrors && fieldErrors['lfiPayGroup'])}
          />
        </Col>
        <Col>
          <TextFormGroup
            labelDisplay="Lumity Pay Group"
            inputName="lumityPayGroup"
            inputValue={lumityPayGroup}
            onChange={updatePayGroup}
            placeholder="Enter Lumity Pay Group"
            isInvalid={!!(fieldErrors && fieldErrors['lumityPayGroup'])}
          />
        </Col>
      </Row>
    </div>
  );
};

export class LFIPayGroupMapping extends Component {
  static propTypes = {
    bambooHrPayGroups: PropTypes.arrayOf(PropTypes.string),
    payGroupMappings: PropTypes.arrayOf(
      PropTypes.shape({
        lfiPayGroup: PropTypes.string,
        lumityPayGroup: PropTypes.string
      })
    ),
    // Expect (payGroupMappings) => any
    onPrevious: PropTypes.func,
    // Expect (payGroupMappings) => any
    onNext: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      payGroupMappings: props.payGroupMappings || [
        {
          lfiPayGroup: '',
          lumityPayGroup: ''
        }
      ],
      errors: {},
      errorMsgs: []
    };
  }

  onPrevious = () => {
    const { payGroupMappings } = this.state;
    const { onPrevious } = this.props;
    onPrevious(payGroupMappings);
  };

  addError(index, errorFieldName, errors) {
    errors = {
      ...errors,
      [index]: {
        ...errors[index],
        [errorFieldName]: true
      }
    };
    return errors;
  }

  onNext = () => {
    const { payGroupMappings } = this.state;
    let lfiPayGroups = [];
    let lumityPayGroups = [];
    let errors = {};
    let errorMsgs = [];
    let updatedPayGroupMappings = payGroupMappings.filter(
      ({ lfiPayGroup, lumityPayGroup }, index) => {
        if (_.isEmpty(lfiPayGroup) && _.isEmpty(lumityPayGroup)) return false;
        if (_.isEmpty(lfiPayGroup)) {
          errors = this.addError(index, 'lfiPayGroup', errors);
          errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          return false;
        } else {
          if (lfiPayGroups.includes(lfiPayGroup)) {
            errors = this.addError(index, 'lfiPayGroup', errors);
            errorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
          } else {
            lfiPayGroups.push(lfiPayGroup);
          }
        }
        if (_.isEmpty(lumityPayGroup)) {
          errors = this.addError(index, 'lumityPayGroup', errors);
          errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          return false;
        } else {
          if (lumityPayGroups.includes(lumityPayGroup)) {
            errors = this.addError(index, 'lumityPayGroup', errors);
            errorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
          } else {
            lumityPayGroups.push(lumityPayGroup);
          }
        }

        return true;
      }
    );
    if (_.isEmpty(errors)) {
      // set to null if it is empty array
      if (_.isEmpty(updatedPayGroupMappings)) {
        updatedPayGroupMappings = null;
      }
      this.props.onNext(updatedPayGroupMappings);
    } else {
      this.setState({
        errors,
        errorMsgs: _.uniq(errorMsgs)
      });
    }
  };

  addPayGroupRow = () => {
    this.setState({
      payGroupMappings: [
        ...this.state.payGroupMappings,
        {
          lfiPayGroup: '',
          lumityPayGroup: ''
        }
      ]
    });
  };

  removePayGroupRow = (removeIndex) => (e) => {
    const updatedErrors = { ...this.state.errors };
    const payGroupMappings = [...this.state.payGroupMappings];
    const updatedPayGroupMappings = payGroupMappings.filter(
      (_mapping, index) => {
        return removeIndex !== index;
      }
    );
    if (updatedErrors[removeIndex]) {
      delete updatedErrors[removeIndex];
    }
    this.setState({
      payGroupMappings: updatedPayGroupMappings,
      errors: updatedErrors
    });
  };

  updatePayGroup = (updateIndex) => (e) => {
    const { name, value } = e.target;
    const { errors } = this.state;
    const updatedPayGroupMappings = [...this.state.payGroupMappings];
    updatedPayGroupMappings[updateIndex][name] = value;
    if (value && errors[updateIndex] && errors[updateIndex][name]) {
      delete errors[updateIndex][name];
      if (_.isEmpty(errors[updateIndex])) delete errors[updateIndex];
    }
    this.setState({
      payGroupMappings: updatedPayGroupMappings
    });
  };

  renderPayGroups(payGroupMappings) {
    const { errors } = this.state;
    return payGroupMappings.map(({ lfiPayGroup, lumityPayGroup }, index) => {
      return (
        <PayGroupRow
          key={index}
          lfiPayGroup={lfiPayGroup}
          lumityPayGroup={lumityPayGroup}
          removePayGroup={this.removePayGroupRow(index)}
          updatePayGroup={this.updatePayGroup(index)}
          fieldErrors={errors[index]}
        />
      );
    });
  }

  render() {
    const { selectedVendor } = this.props;
    const { payGroupMappings, errors, errorMsgs } = this.state;
    return (
      <div className="step-container mapping-container paygroup-mapping__container">
        <div className="body">
          <p className="subtitle">{selectedVendor.name}</p>
          {!_.isEmpty(errors) &&
            errorMsgs.map((errorMsg, index) => (
              <Alert key={index} color="danger" className="error-alert">
                {errorMsg}
              </Alert>
            ))}
          {this.renderPayGroups(payGroupMappings)}
          <Row>
            <Button color="link" onClick={this.addPayGroupRow}>
              +Add Pay Group Mapping
            </Button>
          </Row>
        </div>
        <Row className="action-btns">
          <Col>
            <Button
              className="form-element"
              outline
              color="primary"
              size="lg"
              onClick={this.onPrevious}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              className="form-element"
              color="primary"
              size="lg"
              onClick={this.onNext}
              disabled={!_.isEmpty(errors)}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
