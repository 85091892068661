/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { getIsRolePermitted, hasOwnership } from 'util/roleUtil.js';

import Icon from 'components/Icons';

import './Sidebar.scss';
import { EmployerSource } from 'constants/employerConstants';
import { EmployerSourceType } from 'modules/Employers/constants';

class Sidebar extends PureComponent {
  render() {
    const { navigationContext, userRoles, employer, loginType } = this.props;
    return (
      <div className="side-nav">
        <SubMenu
          menuItems={navigationContext}
          userRoles={userRoles}
          nestLevel={0}
          employer={employer}
          loginType={loginType}
        />
      </div>
    );
  }
}

const SubMenu = (props) => {
  const { menuItems, nestLevel, userRoles, employer, loginType } = props;
  return (
    <ul className={`nest-level-${nestLevel}`}>
      {(menuItems || []).map((menuItem, index) => {
        const {
          icon,
          display,
          linkTo,
          subMenu,
          isActive,
          roles,
          show = true,
          ownership,
          className
        } = menuItem;
        if (
          hasOwnership(loginType, ownership) &&
          getIsRolePermitted(userRoles, roles) &&
          show
        ) {
          return (
            <li key={index} className={isActive ? 'active-submenu' : ''}>
              <div className={`nav-item ${className ? className : ''}`}>
                {linkTo ? (
                  display !== 'SSO' ||
                  (employer &&
                    employer.source === EmployerSource.PLATFORM.value ||
                    employer.source === EmployerSourceType.FLOCK.value ||
                    employer.source === EmployerSourceType.WORKDAY_BENADMIN.value) ? (
                    <>
                      {icon && (
                        <NavLink to={linkTo} className="nav-text-icon">
                          <Icon className="nav-icon" icon={icon} />
                        </NavLink>
                      )}
                      <NavLink to={linkTo}>{display}</NavLink>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <>
                    {icon && <Icon icon={icon} />}
                    {display}
                  </>
                )}
              </div>
              {subMenu && (
                <SubMenu
                  key={`sub-menu-${index}`}
                  menuItems={subMenu}
                  userRoles={userRoles}
                  nestLevel={loginType === 'PLATFORM_ADMIN' ? nestLevel + 1: nestLevel + 2}
                  employer={employer}
                  loginType={loginType}
                />
              )}
            </li>
          );
        } else {
          return null;
        }
      })}
    </ul>
  );
};

export default Sidebar;
