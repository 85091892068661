/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const GET_DOCUMENT_PAGE = 'GET_DOCUMENT_PAGE';
export const GET_DOCUMENT_PAGE_ERROR = 'GET_DOCUMENT_PAGE_ERROR';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const CREATE_DOCUMENT_ERROR = 'CREATE_DOCUMENT_ERROR';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const CREATE_FOLDER_IN_PROGRESS = 'CREATE_FOLDER_IN_PROGRESS';
export const CREATE_FOLDER_ERROR = 'CREATE_FOLDER_ERROR';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_IN_PROGRESS = 'DELETE_DOCUMENT_IN_PROGRESS';
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR';
export const RENAME_DOCUMENT = 'RENAME_DOCUMENT';
export const RENAME_DOCUMENT_IN_PROGRESS = 'RENAME_DOCUMENT_IN_PROGRESS';
export const RENAME_DOCUMENT_ERROR = 'RENAME_DOCUMENT_ERROR';
export const UPLOAD_FILE_IN_PROGRESS = 'DOCUMENT_UPLOAD_FILE_IN_PROGRESS';
export const GET_DOCUMENT_ENABLE_STATUS = 'GET_DOCUMENT_ENABLE_STATUS';
export const GET_DOCUMENT_ENABLE_STATUS_ERROR =
  'GET_DOCUMENT_ENABLE_STATUS_ERROR';
export const GET_DOCUMENT_NAME_EXIST_OR_NOT = 'GET_DOCUMENT_NAME_EXIST_OR_NOT';
