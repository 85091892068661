/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
export const UPLOAD_DOCUMENT_IN_PROGRESS = 'UPLOAD_DOCUMENT_IN_PROGRESS';
export const UPLOAD_DOCUMENT_COMPLETED = 'UPLOAD_DOCUMENT_COMPLETED';
export const UPLOAD_DOCUMENT_FAILED = 'UPLOAD_DOCUMENT_FAILED';

export const DELETE_DOCUMENT_IN_PROGRESS = 'DELETE_DOCUMENT_IN_PROGRESS';
export const DELETE_DOCUMENT_COMPLETED = 'DELETE_DOCUMENT_COMPLETED';
export const DELETE_DOCUMENT_FAILED = 'DELETE_DOCUMENT_FAILED';

export const UPDATE_ADDITIONAL_DOCUMENTS_IN_PROGRESS = 'UPDATE_ADDITIONAL_DOCUMENTS_IN_PROGRESS';
export const UPDATE_ADDITIONAL_DOCUMENTS_COMPLETED = 'UPDATE_ADDITIONAL_DOCUMENTS_COMPLETED';
export const UPDATE_ADDITIONAL_DOCUMENTS_FAILED = 'UPDATE_ADDITIONAL_DOCUMENTS_FAILED';

export const GET_DOCUMENTS_IN_PGOGRESS = 'GET_DOCUMENTS_IN_PROGRESS';
export const GET_DOCUMENTS_COMPLETED = 'GET_DOCUMENTS_COMPLETED';
export const GET_DOCUMENTS_FAILED = 'GET_DOCUMENTS_FAILED';
