/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import { MOBILE_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { permitIf } from 'components/hoc/Permit';
import {
  createConfigModule,
  findConfigStatus,
  getSlackEmployer,
  updateConfigModule,
  updateSlackChatbotConfig
} from '../actions/chatbotConfigActions';
import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import {
  FEATURE_KEY_SLACK_CHATBOT,
  UPDATE_CHATBOT_TYPE_SLACK
} from '../constants';
import SlackChatBotMessageConfig from './SlackChatBotMessageConfig';
import { FEATURE_KEY_BENEFIT_CONSULTATION } from '../../Mobile/constants';

class SlackChatbotConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      welcomeMessage: '',
      farewellMessage: '',
      slackTeamId:'',
      slackAuthorization:'',
      customerSupportCompatible: false,
      lumityConsultCompatible: false,
      isSavedChatbotConfig: false,
      showNotification: true
    };
  }

  componentDidMount() {
    const { findConfigStatus, match, getSlackEmployer } = this.props;
    const { employerId } = match.params;
    findConfigStatus(employerId, FEATURE_KEY_SLACK_CHATBOT);
    findConfigStatus(employerId, FEATURE_KEY_BENEFIT_CONSULTATION);
    getSlackEmployer(employerId);
  }

  componentDidUpdate = (prevProps) => {
    const {
      employerChatbot,
      employerSlackChatbotConfigLoading,
      slackChatbotConfigUpdating
    } = this.props;
    const { showNotification, isSavedChatbotConfig } = this.state;
    if (
      employerChatbot &&
      prevProps.employerSlackChatbotConfigLoading &&
      employerSlackChatbotConfigLoading !== prevProps.employerSlackChatbotConfigLoading &&
      !isSavedChatbotConfig
    ) {
      this.updateSlackChatbotConfig();
    }

    if (
      prevProps.slackChatbotConfigUpdating &&
      slackChatbotConfigUpdating !== prevProps.slackChatbotConfigUpdating &&
      showNotification
    ) {
      NotificationManager.success(
        `"Lumity" the Slack chatbot assistant configurations have been saved.`
      );
    }
  };

  updateSlackChatbotConfig = () => {
    const { chatbotConfigSettings } = this.props.employerChatbot;
    const { slackChatbotConfig } = chatbotConfigSettings;

    const {
      welcomeMessage,
      farewellMessage,
      fallbackOptions,
      slackTeamId,
      slackAuthorization,
      slackAuthorizationUsername
    } = slackChatbotConfig;

    this.setState({
      welcomeMessage: welcomeMessage,
      farewellMessage: farewellMessage,
      slackTeamId: slackTeamId ? slackTeamId : '-',
      slackAuthorization: slackAuthorization ? slackAuthorization : '-',
      slackAuthorizationUsername: slackAuthorizationUsername || '-',
      customerSupportCompatible:
        fallbackOptions && fallbackOptions.customerSupportCompatible
          ? fallbackOptions.customerSupportCompatible
          : false,
      lumityConsultCompatible:
        fallbackOptions && fallbackOptions.lumityConsultCompatible
          ? fallbackOptions.lumityConsultCompatible
          : false
    });
  };

  switchChatbotEnabled = () => {
    const {
      match,
      slackChatbotConfigStatus,
      createConfigModule,
      updateConfigModule,
      employerChatbot
    } = this.props;
    const { employerId } = match.params;
    const { id, value } = slackChatbotConfigStatus;
    if (!id) {
      createConfigModule(employerId, FEATURE_KEY_SLACK_CHATBOT);
    } else {
      updateConfigModule(id, employerId, value, FEATURE_KEY_SLACK_CHATBOT);
    }
    if (
      employerChatbot &&
      employerChatbot.chatbotConfigSettings &&
      employerChatbot.chatbotConfigSettings.slackChatbotConfig &&
      !employerChatbot.chatbotConfigSettings.slackChatbotConfig.welcomeMessage
    ) {
      const fallbackOptions = {
        customerSupportCompatible: true,
        lumityConsultCompatible: false
      };
      const config = {
        fallbackOptions: fallbackOptions,
        chatbotType: UPDATE_CHATBOT_TYPE_SLACK
      };
      this.setState({ showNotification: false }, () => {
        this.onSaveChatbotConfigs(config);
      });
    }
    NotificationManager.success(
      `"Lumity" the Slack chatbot assistant has been ${
        value === 'false' ? 'enabled' : 'disabled'
      }.`
    );
  };

  onSaveChatbotConfigs = (config) => {
    const { employerId } = this.props.match.params;
    const { welcomeMessage, farewellMessage, fallbackOptions } = config;
    const {
      customerSupportCompatible,
      lumityConsultCompatible
    } = fallbackOptions;
    this.setState(
      {
        isSavedChatbotConfig: true,
        welcomeMessage,
        farewellMessage,
        customerSupportCompatible,
        lumityConsultCompatible
      },
      () => {
        this.props.updateSlackChatbotConfig(employerId, config);
      }
    );
  };

  render() {
    let {
      welcomeMessage,
      farewellMessage,
      slackTeamId,
      slackAuthorization,
      slackAuthorizationUsername,
      lumityConsultCompatible,
      customerSupportCompatible
    } = this.state;

    const { employerChatbot } = this.props;

    if(employerChatbot !== undefined) {
      const { chatbotConfigSettings } = employerChatbot;
      if (chatbotConfigSettings !== undefined) {
        const { slackChatbotConfig } = chatbotConfigSettings;
        if (slackChatbotConfig !== undefined) {
          if (!welcomeMessage) {
            welcomeMessage = slackChatbotConfig.welcomeMessage;
          }
          if (!farewellMessage) {
            farewellMessage = slackChatbotConfig.farewellMessage;
          }
          if (!slackTeamId) {
            slackTeamId = slackChatbotConfig.slackTeamId;
          }
          if (!slackAuthorization) {
            slackAuthorization = slackChatbotConfig.slackAuthorization;
          }
          if (!slackAuthorizationUsername) {
            slackAuthorizationUsername = slackChatbotConfig.slackAuthorizationUsername;
          }
        }
      }
    }

    const { slackChatbotConfigStatus, bConsultationConfigStatus, match } = this.props;
    const { employerId } = match.params;
    const SecuredEmployerConfigToggle = permitIf(
      EmployerConfigToggleV2,
      MOBILE_FEATURE_ROLE_MAPPING.common
    );
    return (
      <ApplicationWrapper>
        <ContentContainer>
          <SecuredEmployerConfigToggle
            employerId={employerId}
            moduleEnabled={FEATURE_KEY_SLACK_CHATBOT}
            message={'Slack'}
            config={slackChatbotConfigStatus}
            switchCallBack={this.switchChatbotEnabled}
          >
            <hr className="divider" />
            <div className="mt-4">
              <SlackChatBotMessageConfig
                onSaveChatbotConfigs={this.onSaveChatbotConfigs}
                welcomeMessage={welcomeMessage}
                farewellMessage={farewellMessage}
                slackTeamId={slackTeamId}
                slackAuthorization={slackAuthorization}
                slackAuthorizationUsername={slackAuthorizationUsername}
                lumityConsultCompatible={lumityConsultCompatible}
                customerSupportCompatible={customerSupportCompatible}
                bConsultationConfigStatus={bConsultationConfigStatus}
              />
            </div>
          </SecuredEmployerConfigToggle>
        </ContentContainer>
        {/*)}*/}
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createConfigModule: (employerId, key) =>
    dispatch(createConfigModule(employerId, key)),
  updateConfigModule: (id, employerId, value, key) =>
    dispatch(updateConfigModule(id, employerId, value, key)),
  findConfigStatus: (employerId, key) =>
    dispatch(findConfigStatus(employerId, key)),
  updateSlackChatbotConfig: (employerId, config) =>
    dispatch(updateSlackChatbotConfig(employerId, config)),
  getSlackEmployer: (employerId) => dispatch(getSlackEmployer(employerId))
});

const mapStateToProps = (state) => {
  const {
    employerConfigCreate,
    employerConfigCreateError,
    employerConfigUpdate,
    employerConfigUpdateError,
    configStatus,
    slackChatbotConfigStatus,
    bConsultationConfigStatus,
    employerChatbot,
    employerSlackChatbotConfigLoading,
    slackChatbotConfigUpdating
  } = state.chatbotConfigReducer;
  return {
    employerConfigCreate,
    employerConfigCreateError,
    employerConfigUpdate,
    employerConfigUpdateError,
    configStatus,
    slackChatbotConfigStatus,
    bConsultationConfigStatus,
    employerChatbot,
    employerSlackChatbotConfigLoading,
    slackChatbotConfigUpdating
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SlackChatbotConfig);
