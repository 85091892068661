/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import EmployerFeatureConfigurationService from 'services/EmployerFeatureConfigurationService';
import IntegrationConfigurationService from 'services/IntegrationConfigurationService';
import EmployerService from 'services/EmployerService';
import {
  CREATE_RENEWAL_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  UPDATE_RENEWAL_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  FIND_OFFRES_CONFIG_STATUS,
  UPDATE_EMPLOYER_OFFERS_CONFIGS_IN_PROGRESS,
  UPDATE_EMPLOYER_OFFERS_CONFIGS,
  UPDATE_EMPLOYER_OFFERS_CONFIGS_ERROR,
  FIND_RENEWAL_CONFIG_STATUS,
  FIND_RENEWAL_CONFIG_STATUS_ERROR,
  GET_RENEWAL_EMPLOYER_SUCCESS,
  GET_RENEWAL_EMPLOYER_IN_PROGRESS,
  GET_RENEWAL_EMPLOYER_ERROR
} from '../actionTypes/renewals.actionTypes';
import {
  MODULE_RENEWALS_ENABLED,
  MODULE_OFFERS_ENABLED,
  UPDATE_SUCCESS,
  CREATE_SUCCESS
} from '../constants';

export const createConfigModule = (employerId, value, key) => {
  const renewalsEnableConfig = {
    employerId: employerId,
    key: key,
    value: String(value)
  };
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.createConfigModule(
        renewalsEnableConfig
      );
      const isSuccess = response.status === CREATE_SUCCESS;
      if (isSuccess) {
        try {
          const response = await EmployerFeatureConfigurationService.findConfigStatus(
            employerId,
            key
          );
          if(key === MODULE_RENEWALS_ENABLED){
            dispatch({
              type: FIND_RENEWAL_CONFIG_STATUS,
              payload: response.data
            });
          }
          if(key === MODULE_OFFERS_ENABLED){
            dispatch({
              type: FIND_OFFRES_CONFIG_STATUS,
              payload: response.data
            });
          }
        } catch (error) {
          dispatch({ type: FIND_RENEWAL_CONFIG_STATUS_ERROR, payload: error });
        }
      }
    } catch (error) {
      dispatch({
        type: CREATE_RENEWAL_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const updateConfigModule = (id, employerId, value, key) => {
  return async (dispatch) => {
    const renewalsEnableConfig = {
      id: id,
      employerId: employerId,
      key: key,
      value: String(value === 'false')
    };
    try {
      const response = await EmployerFeatureConfigurationService.updateConfigModule(
        renewalsEnableConfig
      );
      const isSuccess = response.status === UPDATE_SUCCESS;
      if (isSuccess) {
        try {
          const response = await EmployerFeatureConfigurationService.findConfigStatus(
            employerId,
            key
          );
          if (key === MODULE_RENEWALS_ENABLED) {
            dispatch({
              type: FIND_RENEWAL_CONFIG_STATUS,
              payload: response.data
            });
          }
          if (key === MODULE_OFFERS_ENABLED) {
            dispatch({
              type: FIND_OFFRES_CONFIG_STATUS,
              payload: response.data
            });
          }
        } catch (error) {
          dispatch({ type: FIND_RENEWAL_CONFIG_STATUS_ERROR, payload: error });
        }
      }
    } catch (error) {
      dispatch({
        type: UPDATE_RENEWAL_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const findConfigStatus = (employerId, key) => {
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.findConfigStatus(
        employerId,
        key
      );
      if (key === MODULE_RENEWALS_ENABLED) {
        dispatch({ type: FIND_RENEWAL_CONFIG_STATUS, payload: response.data });
      }
      if (key === MODULE_OFFERS_ENABLED) {
        dispatch({ type: FIND_OFFRES_CONFIG_STATUS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: FIND_RENEWAL_CONFIG_STATUS_ERROR, payload: error });
    }
  };
};

export const updateEmployerRenewalPeriodConfig = (employerId, config) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_EMPLOYER_OFFERS_CONFIGS_IN_PROGRESS });
    try {
      const response = await IntegrationConfigurationService.updateEmployerRenewalPeriodConfig(
        employerId,
        config
      );
      const isSuccess = response.status === UPDATE_SUCCESS;
      dispatch({ type: UPDATE_EMPLOYER_OFFERS_CONFIGS, payload: isSuccess });
    } catch (error) {
      dispatch({ type: UPDATE_EMPLOYER_OFFERS_CONFIGS_ERROR, payload: error });
    }
  };
};

export const getEmployer = (employerId) => {
  return async (dispatch) => {
    dispatch({ type: GET_RENEWAL_EMPLOYER_IN_PROGRESS });
    try {
      const response = await EmployerService.findById(employerId);
      dispatch({
        type: GET_RENEWAL_EMPLOYER_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: GET_RENEWAL_EMPLOYER_ERROR, payload: error });
    }
  };
};
