/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import SSOService from '../../../services/SSOService';
import EmployerService from '../../../services/EmployerService';
import EmployerFeatureConfigurationService from '../../../services/EmployerFeatureConfigurationService';
import { UPDATE_STATUS } from 'constants/commonConstants';
import {
  FIND_SSO_EMPLOYER_CONFIGURATION_MODULES,
  FIND_SSO_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  CREATE_SSO_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  VALIDATE_SSO_URL,
  VALIDATE_SSO_URL_INPROGRESS,
  VALIDATE_SSO_URL_ERROR,
  SAVE_SSO_CONFIGURATION,
  SAVE_SSO_CONFIGURATION_INPROGRESS,
  SAVE_SSO_CONFIGURATION_ERROR,
  GET_SSO_CONFIGURATION,
  GET_SSO_CONFIGURATION_ERROR
} from './actionTypes';

import { SERVICE_SSO_ENABLED } from '../constants';

export const findConfigStatus = (employerId, key) => {
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.findConfigStatus(
        employerId,
        key
      );
      dispatch({
        type: FIND_SSO_EMPLOYER_CONFIGURATION_MODULES,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FIND_SSO_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const updateConfigModule = (employerId, configId) => {
  const SSOConfig = {
    employerId: employerId,
    key: SERVICE_SSO_ENABLED,
    value: String(true),
    id: configId
  };
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.updateConfigModule(
        SSOConfig
      );
      const isUpdated = response.status === UPDATE_STATUS;
      if (isUpdated) {
        try {
          const response = await EmployerFeatureConfigurationService.findConfigStatus(
            employerId,
            SERVICE_SSO_ENABLED
          );
          dispatch({
            type: FIND_SSO_EMPLOYER_CONFIGURATION_MODULES,
            payload: response.data
          });
        } catch (error) {
          dispatch({
            type: FIND_SSO_EMPLOYER_CONFIGURATION_MODULES_ERROR,
            payload: error
          });
        }
      }
    } catch (error) {
      dispatch({
        type: CREATE_SSO_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const validateMetaDataUrl = (metadataUrl, vendor) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VALIDATE_SSO_URL_INPROGRESS });

      const response = await SSOService.validateMetadataUrl(
        metadataUrl,
        vendor
      );
      dispatch({
        type: VALIDATE_SSO_URL,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: VALIDATE_SSO_URL_ERROR,
        payload: error
      });
    }
  };
};

export const saveSSOConfig = (employerId, ssoConfig) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SAVE_SSO_CONFIGURATION_INPROGRESS });

      const response = await EmployerService.saveSSOConfig(
        employerId,
        ssoConfig
      );
      dispatch({
        type: SAVE_SSO_CONFIGURATION,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: SAVE_SSO_CONFIGURATION_ERROR,
        payload: error
      });
    }
  };
};

export const getSSOConfig = (employerId) => {
  return async (dispatch) => {
    try {
      const response = await EmployerService.findById(employerId);
      dispatch({
        type: GET_SSO_CONFIGURATION,
        payload: response.data.ssoConfig
      });
    } catch (error) {
      dispatch({
        type: GET_SSO_CONFIGURATION_ERROR,
        payload: error
      });
    }
  };
};
