/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/plan.actionTypes';

const initialState = {
  plansPage: {
    data: {
      content: [],
      metadata: {}
    },
    isFetching: false,
    error: null
  },
  planDetails: {
    plan: null,
    syncedFields: null,
    isFetching: false,
    isSaving: false,
    fetchError: null,
    saveError: null
  },
  selfFundedClaimPlanConfiguration: {
    data: null,
    error: null,
    isLoading: false
  },
  allHSAPlans: {
    data: [],
    error: null,
    isLoading: false
  },
  deletePlanStatus: {
    deleteData: {},
    isDeleting: false,
    deleteError: null
  },
  validatePlanStatus: {
    validateData: {},
    isValidating: false,
    validateError: null
  }
};

const planReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PLANS_PAGE_IN_PROGRESS:
      return {
        ...state,
        plansPage: {
          ...state.plansPage,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_PLANS_PAGE_COMPLETED:
      return {
        ...state,
        plansPage: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_PLANS_PAGE_FAILED:
      return {
        ...state,
        plansPage: {
          // Intentionally invalidate old content.
          data: {
            content: [],
            metadata: {}
          },
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.INITIALIZE_PLAN:
      return {
        ...state,
        planDetails: {
          ...state.planDetails,
          plan: action.plan,
          syncedFields: action.syncedFields
        }
      };
    case ActionTypes.GET_PLAN_DETAILS_IN_PROGRESS:
      return {
        ...state,
        planDetails: {
          ...state.planDetails,
          plan: null,
          syncedFields: null,
          isFetching: true,
          fetchError: null
        }
      };
    case ActionTypes.GET_PLAN_DETAILS_COMPLETED:
      return {
        ...state,
        planDetails: {
          ...state.planDetails,
          plan: action.plan,
          syncedFields: action.syncedFields,
          isFetching: false,
          fetchError: null
        }
      };
    case ActionTypes.GET_PLAN_DETAILS_FAILED:
      return {
        ...state,
        planDetails: {
          ...state.planDetails,
          plan: null,
          syncedFields: null,
          isFetching: false,
          fetchError: action.error
        }
      };
    case ActionTypes.SAVE_PLAN_DETAILS_IN_PROGRESS:
      return {
        ...state,
        planDetails: {
          ...state.planDetails,
          isSaving: true,
          saveError: null
        }
      };
    case ActionTypes.SAVE_PLAN_DETAILS_COMPLETED:
      return {
        ...state,
        planDetails: {
          ...state.planDetails,
          plan: action.payload,
          isSaving: false,
          saveError: null
        }
      };
    case ActionTypes.SAVE_PLAN_DETAILS_FAILED:
      return {
        ...state,
        planDetails: {
          ...state.planDetails,
          isSaving: false,
          saveError: action.payload
        }
      };
    case ActionTypes.SET_PLAN_DOCUMENTS:
      return {
        ...state,
        planDetails: {
          ...state.planDetails,
          plan: {
            ...state.planDetails.plan,
            documents: action.payload
          }
        }
      };
    case ActionTypes.GET_SELF_FUNDED_CLAIMS_PLAN_CONFIGURATION_IN_PROGRESS:
      return {
        ...state,
        selfFundedClaimPlanConfiguration: {
          ...state.selfFundedClaimPlanConfiguration,
          error: null,
          isLoading: true
        }
      };
    case ActionTypes.GET_SELF_FUNDED_CLAIMS_PLAN_CONFIGURATION_SUCCESS:
      return {
        ...state,
        selfFundedClaimPlanConfiguration: {
          data: action.payload.data || {},
          error: null,
          isLoading: false
        }
      };
    case ActionTypes.GET_SELF_FUNDED_CLAIMS_PLAN_CONFIGURATION_FAILED:
      return {
        ...state,
        selfFundedClaimPlanConfiguration: {
          data: {},
          error: action.payload.data,
          isLoading: false
        }
      };
    case ActionTypes.CLEAR_SELF_FUNDED_CONFIG:
      return {
        ...state,
        selfFundedClaimPlanConfiguration: {
          data: {},
          error: null,
          isLoading: false
        }
      };
    case ActionTypes.GET_ALL_HSA_PLANS_BY_YEAR_IN_PROGRESS:
      return {
        ...state,
        allHSAPlans: {
          data: [],
          error: null,
          isLoading: true
        }
      };
    case ActionTypes.GET_ALL_HSA_PLANS_BY_YEAR:
      return {
        ...state,
        allHSAPlans: {
          data: action.payload.data || [],
          error: null,
          isLoading: false
        }
      };
    case ActionTypes.GET_ALL_HSA_PLANS_BY_YEAR_FAILED:
      return {
        ...state,
        allHSAPlans: {
          deleteData: [],
          error: action.payload.data,
          isLoading: false
        }
      };
    case ActionTypes.DELETE_PLAN_IN_PROGRESS:
      return {
        ...state,
        deletePlanStatus: {
          ...state.deletePlanStatus,
          isDeleting: true,
          deleteError: null
        }
      };
    case ActionTypes.DELETE_PLAN_COMPLETED:
      return {
        ...state,
        deletePlanStatus: {
          ...state.deletePlanStatus,
          isDeleting: false,
          data: action.payload
        }
    };
    case ActionTypes.DELETE_PLAN_FAILED:
      return {
        ...state,
        deletePlanStatus: {
          ...state.deletePlanStatus,
          isDeleting: false,
          deleteError: action.payload
        }
      };
    case ActionTypes.VALIDATE_DELETE_PLAN_IN_PROGRESS:
      return {
        ...state,
        validatePlanStatus: {
          ...state.validatePlanStatus,
          isValidating: true,
          validateError: null
       }
      };
    case ActionTypes.VALIDATE_DELETE_PLAN_COMPLETED:
      return {
        ...state,
        validatePlanStatus: {
          ...state.validatePlanStatus,
          isValidating: false,
          data: action.payload
        }
      };
    case ActionTypes.VALIDATE_DELETE_PLAN_FAILED:      
      return {
        ...state,
        validatePlanStatus: {
          ...state.validatePlanStatus,
          isValidating: false,
          validateError: action.payload
        }
        
      };
    default:
      return {
        ...state
      };
  }
};

export default planReducer;
