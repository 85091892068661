/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import './Blink.scss';

const Blink = (props) => {
  const { children, fade, speed } = props;
  return (
    <span className={`blink-wrapper ${fade ? 'fade' : 'blink'} ${speed}`}>
      {children}
    </span>
  );
};

Blink.propTypes = {
  children: PropTypes.node,
  speed: PropTypes.oneOf(['fast', 'slow', 'normal']),
  fade: PropTypes.bool
};

Blink.defaultProps = {
  speed: 'normal',
  fade: false
};

export default Blink;
