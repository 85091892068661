/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import {
  GET_INSIGHTS_FILE_HISTORY_ERROR,
  GET_INSIGHTS_FILE_HISTORY_IN_PROGRESS,
  GET_INSIGHTS_FILE_HISTORY_SUCCESS,
  GET_INSIGHTS,
  GET_INSIGHTS_ERROR,
  GET_INSIGHTS_IN_PROGRESS,
  UPDATE_CHART_STATUS,
  UPDATE_CHART_STATUS_ERROR,
  UPDATE_CHART_STATUS_IN_PROGRESS,
  UPLOAD_INSIGHTS_FILE,
  UPLOAD_INSIGHTS_FILE_ERROR,
  UPLOAD_INSIGHTS_FILE_IN_PROGRESS,
  CREATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES,
  UPDATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES,
  CREATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  UPDATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  FIND_INSIGHTS_ANALYTICS_CONFIG_STATUS,
  FIND_INSIGHTS_CLAIMS_CONFIG_STATUS,
  FIND_INSIGHTS_EMPLOYER_CONFIG_STATUS_ERROR,
  CREATE_MESSAGE_IN_PROGRESS,
  CREATE_MESSAGE_COMPLETED,
  CREATE_MESSAGE_FAILED,
  GET_YEARS_IN_PROGRESS,
  GET_YEARS_SUCCESS,
  GET_YEARS_ERROR,
  GET_MESSAGE_DATA_IN_PROGRESS,
  GET_MESSAGE_DATA_SUCCESS,
  GET_MESSAGE_DATA_ERROR
} from '../actions/insights.actions';

export default (
  state = {
    insightsConfig: {
      insightsData: [],
      isFetching: false,
      configError: undefined
    },
    updatingChartType: undefined,
    fileUpload: {
      fileUploading: false,
      uploadedFile: undefined,
      fileUploadError: undefined
    },
    statusUpdate: {
      chartStatusUpdateInProgress: false,
      chartStatusUpdateError: undefined,
      updatedChart: undefined
    },
    insightFileHistory: {
      historyLoading: false,
      fileHistoryData: [],
      chartType: null,
      fileHistoryError: null
    },
    insightSaveMessage: {
      messageData: {
        year: '',
        medicalPlan: '',
        dentalPlan: '',
        visionPlan: ''
      },
      isCreating: false,
      error: null
    },
    insightYears: {
      years: [],
      isLoadingYears: false,
      yearsLoadingError: null
    },
    insightGetMessageData: {
      customMessageData: [],
      isFetchingData: false,
      error: null
    },
    analyticsConfigStatus: { id: null, value: 'false', lastUpdatedTs: null },
    claimsConfigStatus: { id: null, value: 'false', lastUpdatedTs: null }
  },
  { type, payload }
) => {
  switch (type) {
    case GET_INSIGHTS_IN_PROGRESS:
      return {
        ...state,
        insightsConfig: {
          isFetching: payload,
          configError: undefined
        }
      };
    case GET_INSIGHTS:
      return {
        ...state,
        insightsConfig: {
          insightsData: payload,
          isFetching: false
        }
      };
    case GET_INSIGHTS_ERROR:
      return {
        ...state,
        insightsConfig: {
          configError: payload,
          isFetching: false,
          insightsData: []
        }
      };
    case UPLOAD_INSIGHTS_FILE_IN_PROGRESS:
      return {
        ...state,
        fileUpload: {
          fileUploading: payload.loading,
          fileUploadError: undefined
        },
        updatingChartType: payload.chartType
      };
    case UPLOAD_INSIGHTS_FILE:
      return {
        ...state,
        fileUpload: {
          fileUploading: false,
          uploadedFile: payload
        }
      };
    case UPLOAD_INSIGHTS_FILE_ERROR:
      return {
        ...state,
        fileUpload: {
          fileUploading: false,
          fileUploadError: payload
        }
      };
    case UPDATE_CHART_STATUS_IN_PROGRESS:
      return {
        ...state,
        statusUpdate: {
          chartStatusUpdateInProgress: payload.loading
        },
        updatingChartType: payload.chartType
      };
    case UPDATE_CHART_STATUS:
      return {
        ...state,
        statusUpdate: {
          chartStatusUpdateInProgress: false,
          updatedChart: payload
        }
      };
    case UPDATE_CHART_STATUS_ERROR:
      return {
        ...state,
        statusUpdate: {
          chartStatusUpdateInProgress: false,
          chartStatusUpdateError: payload
        }
      };
    case GET_INSIGHTS_FILE_HISTORY_IN_PROGRESS:
      return {
        ...state,
        insightFileHistory: {
          historyLoading: true,
          fileHistoryData: [],
          chartType: payload.chartType,
          fileHistoryError: null
        }
      };
    case GET_INSIGHTS_FILE_HISTORY_SUCCESS:
      return {
        ...state,
        insightFileHistory: {
          historyLoading: false,
          fileHistoryData: payload.data,
          chartType: payload.chartType,
          fileHistoryError: null
        }
      };
    case GET_INSIGHTS_FILE_HISTORY_ERROR:
      return {
        ...state,
        insightFileHistory: {
          historyLoading: false,
          fileHistoryData: [],
          chartType: null,
          fileHistoryError: payload
        }
      };
    case FIND_INSIGHTS_ANALYTICS_CONFIG_STATUS:
      return {
        ...state,
        analyticsConfigStatus: payload
      };
    case FIND_INSIGHTS_CLAIMS_CONFIG_STATUS:
      return {
        ...state,
        claimsConfigStatus: payload
      };

    case FIND_INSIGHTS_EMPLOYER_CONFIG_STATUS_ERROR:
      return {
        ...state,
        employerConfigsErrorResponse: payload
      };
    case CREATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        // Re-assiging the state value to trigger state change at component level
        // since it's always 204 from the backend
        employerConfigCreate: Object.assign(payload)
      };
    case CREATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES_ERROR:
      return {
        ...state,
        employerConfigCreateError: payload
      };
    case UPDATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        // Re-assiging the state value to trigger state change at component level
        // since it's always 204 from the backend
        employerConfigUpdate: Object.assign(payload)
      };
    case UPDATE_INSIGHTS_EMPLOYER_CONFIGURATION_MODULES_ERROR:
      return {
        ...state,
        employerConfigUpdateError: payload
      };
    case CREATE_MESSAGE_IN_PROGRESS:
      return {
        ...state,
        insightSaveMessage: {
          isCreating: true,
          error: null
        }
      };
    case CREATE_MESSAGE_COMPLETED:
      return {
        ...state,
        insightSaveMessage: {
          messageData: payload,
          isCreating: false,
          error: null
        }
      };
    case CREATE_MESSAGE_FAILED:
      return {
        ...state,
        insightSaveMessage: {
          messageData: {},
          isCreating: false,
          error: payload
        }
      };
    case GET_YEARS_IN_PROGRESS:
      return {
        ...state,
        insightYears: {
          isCreating: true,
          error: null
        }
      };
    case GET_YEARS_SUCCESS:
      return {
        ...state,
        insightYears: {
          years: payload,
          isLoadingYears: false
        }
      };
    case GET_YEARS_ERROR:
      return {
        ...state,
        insightYears: {
          yearsLoadingError: payload,
          years: [],
          isLoadingYears: false
        }
      };
    case GET_MESSAGE_DATA_IN_PROGRESS:
      return {
        ...state,
        insightGetMessageData: {
          isFetchingData: true,
          error: null
        }
      };
    case GET_MESSAGE_DATA_SUCCESS:
      return {
        ...state,
        insightGetMessageData: {
          customMessageData: payload,
          isFetchingData: false
        }
      };
    case GET_MESSAGE_DATA_ERROR:
      return {
        ...state,
        insightGetMessageData: {
          customMessageData: [],
          isFetchingData: false,
          error: payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
