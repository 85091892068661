/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import { Row, Col } from 'components/Atoms';
import Loader from 'components/CircularProgress';
import { TextFormGroup, DatePickerInput } from 'components/DataForm';
import { sanitizeDateInput } from 'util/commonUtil';
import { NotificationManager } from 'react-notifications';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import { DATE_FORMAT, ERROR_CODE_MSG_MAP } from '../../constants';
import {
  createSurvey,
  updateSurvey,
  getSurveyDetails,
  publishSurvey
} from '../../actions/surveysAction';
import * as ActionTypes from '../../actionTypes/surveys.actionTypes';
import AddButton from 'components/Buttons/AddButton';
import PageActionButton from 'components/Buttons/PageActionButton';

import { formatDateInPST } from 'util/dateUtil';

import './CreateSurvey.scss';

class CreateSurvey extends React.Component {
  constructor(props) {
    super(props);
    const { employerId, surveyId } = this.props.match.params;
    this.employerId = employerId;
    this.surveyId = surveyId;
    this.state = {
      surveyForm: {
        id: '',
        surveyName: '',
        closeDate: '',
        surveyUrl: '',
        reportUrl: '',
        status: ''
      },
      formError: false,
      pageMode: surveyId ? 'EDIT' : 'CREATE'
    };
  }

  componentDidMount() {
    const surveyId = this.surveyId;
    if (surveyId) {
      this.props.getSurveyDetails(surveyId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      requestStatus,
      serviceError,
      surveyDetails,
      history,
      match
    } = this.props;
    const { requestStatus: prevRequestStatus } = prevProps;
    const { brokerId } = match.params;

    if (prevRequestStatus === requestStatus) return;

    if (serviceError) {
      const errorCode = serviceError.response.data.code;
      NotificationManager.error(
        ERROR_CODE_MSG_MAP[errorCode] || serviceError.message
      );
      return;
    }
    switch (requestStatus) {
      case ActionTypes.GET_SURVEY_DETAILS_SUCCESS: {
        const {
          id = '',
          surveyName = '',
          closeDate = '',
          surveyUrl = '',
          reportUrl = '',
          status = ''
        } = surveyDetails;
        const pstCloseDate = formatDateInPST(closeDate);
        const form = {
          id,
          surveyName,
          closeDate: pstCloseDate,
          surveyUrl,
          reportUrl,
          status
        };
        this.setState({ surveyForm: form, formError: false });
        return;
      }
      case ActionTypes.CREATE_SURVEY_SUCCESS: {
        const { employerId, id } = surveyDetails;
        NotificationManager.success('Survey Created Successfully');
        history.push(
          `/brokers/${brokerId}/employers/${employerId}/surveys/${id}`
        );
        return;
      }
      case ActionTypes.UPDATE_SURVEY_SUCCESS: {
        const { employerId, id } = surveyDetails;
        NotificationManager.success('Survey Updated Successfully');
        history.push(
          `/brokers/${brokerId}/employers/${employerId}/surveys/${id}`
        );
        return;
      }
      case ActionTypes.PUBLISH_SURVEY_SUCCESS: {
        NotificationManager.success('Survey Sent to Employer Successfully');
        return;
      }
      default:
        break;
    }
  }

  createSurvey = () => {
    const { surveyForm, pageMode } = this.state;
    const employerId = this.employerId;
    let isValid = true;
    let mandatoryFields = ['surveyName', 'closeDate', 'surveyUrl', 'reportUrl'];
    mandatoryFields.forEach((element) => {
      if (!surveyForm[element] && element !== 'id') {
        isValid = false;
        return false;
      }
    });
    if (!isValid) {
      this.setState({ formError: true });
      return;
    }

    surveyForm.employerId = employerId;
    surveyForm.source = 'TYPEORM';
    const formattedCloseDate = moment(surveyForm.closeDate).format('YYYY-MM-DD');
    surveyForm.closeDate= `${formattedCloseDate}T17:00-07:00`;
    if (pageMode === 'EDIT') {
      this.props.updateSurvey(surveyForm);
    } else {
      this.props.createSurvey(surveyForm);
    }
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { surveyForm } = this.state;
    const updatedForm = { ...surveyForm };
    updatedForm[name] = value;
    this.setState({ surveyForm: updatedForm, formError: false });
  };

  render() {
    const { isLoading, publishSurvey } = this.props;
    const { surveyForm, formError, pageMode } = this.state;
    const {
      id,
      surveyName,
      closeDate,
      surveyUrl,
      reportUrl,
      status
    } = surveyForm;

    if (isLoading) {
      return <Loader />;
    }
    const isDumpEdit =
      pageMode === 'EDIT' &&
      status !== 'DRAFT' &&
      status !== 'AWAITING_APPROVAL';
    return (
      <ApplicationWrapper>
        <HeaderContainer
          title={pageMode === 'EDIT' ? 'Edit Survey' : 'New Survey'}
        />
        <Row className="page-create-survey">
          <Col className="page-content">
            <Tabs className="tab-view" defaultTab="medical">
              <TabList className="tab-list">
                <Tab tabFor="medical">Survey Information</Tab>
              </TabList>
              <TabPanel tabId="medical">
                <ContentContainer>
                  <Row>
                    <Col>
                      <TextFormGroup
                        labelDisplay="Survey Name"
                        inputName="surveyName"
                        inputValue={surveyName}
                        onChange={this.onChange}
                        placeholder="Enter Survey Name"
                      />
                    </Col>
                    <Col>
                      <DatePickerInput
                        labelDisplay={
                          <span>
                            <span>Scheduled Close Date</span>
                            <span className="label-info">
                              (must match Typeform Close Date, Closes at 5pm
                              PST)
                            </span>
                          </span>
                        }
                        inputName="closeDate"
                        inputValue={
                          closeDate
                        }
                        dateFormat={DATE_FORMAT}
                        onChange={(event) => {
                          const { value } = event.target;
                          event.target.value = sanitizeDateInput(value, '/');
                          this.onChange(event);
                        }}
                        feedback={`Please enter a valid date in the format ${DATE_FORMAT}`}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="url-input">
                      <TextFormGroup
                        labelDisplay="Typeform URL"
                        inputName="surveyUrl"
                        inputValue={surveyUrl}
                        onChange={this.onChange}
                        placeholder="Enter Survey URL"
                        disabled={isDumpEdit}
                      />
                      <a
                        className="typeform-link"
                        href={surveyUrl}
                        target="_new"
                      >
                        Visit URL
                      </a>
                    </Col>
                    <Col className="url-input">
                      <TextFormGroup
                        labelDisplay="Summary Results URL"
                        inputName="reportUrl"
                        inputValue={reportUrl}
                        onChange={this.onChange}
                        placeholder="Enter Survey Results URL"
                        disabled={isDumpEdit}
                      />
                      <a
                        className="typeform-link"
                        href={reportUrl}
                        target="_new"
                      >
                        Visit URL
                      </a>
                    </Col>
                  </Row>
                  {formError && (
                    <p className="error">All Fields are mandatory</p>
                  )}
                </ContentContainer>
              </TabPanel>
            </Tabs>
          </Col>
          <Col className="page-actions">
            <AddButton onClick={this.createSurvey} title="Save" />`
            <PageActionButton
              outline
              disabled={status !== 'DRAFT'}
              onClick={() => {
                publishSurvey(id);
              }}
            >
              Publish & Notify ER Admin
            </PageActionButton>
          </Col>
        </Row>
      </ApplicationWrapper>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  createSurvey: (employerId, value) =>
    dispatch(createSurvey(employerId, value)),
  updateSurvey: (id, employerId, value, lastUpdatedTs) =>
    dispatch(updateSurvey(id, employerId, value, lastUpdatedTs)),
  getSurveyDetails: (id, employerId, value, lastUpdatedTs) =>
    dispatch(getSurveyDetails(id, employerId, value, lastUpdatedTs)),
  publishSurvey: (id, employerId, value, lastUpdatedTs) =>
    dispatch(publishSurvey(id, employerId, value, lastUpdatedTs))
});
const mapStateToProps = (state) => {
  const {
    surveyDetails,
    requestStatus,
    serviceError,
    isLoading
  } = state.surveysReducer;
  return {
    surveyDetails,
    requestStatus,
    isLoading,
    serviceError
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSurvey);
