/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import {
  DataTableWithServerPagination,
  ColumnFilter
} from 'components/DataTables';
import { permitIf } from 'components/hoc/Permit';

import { Button, ModalFooter } from 'components/Atoms';
// constants
import { INTEGRATIONS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import {
  BENADMIN_ERROR_CODE_LABEL_MAP,
  STATUS_STATES,
  VENDORS
} from 'modules/Integrations/constants';
import { SYNC_TYPE, SYNC_STATUS_FILTER } from '../../constants';
import { EMDASH_LITERAL } from 'constants/commonConstants';
// styles
import './DialogErrorDetails.scss';
import PageActionButton from 'components/Buttons';

const customStyles = {
  headCells: {
    inactiveSortStyle: {
      opacity: 1
      // Target the sort icon when inactive
    }
  }
};

const conditionalRowStyles = [
  {
    when: (row) => row.status === STATUS_STATES.SUCCESS,
    style: {
      borderLeftStyle: 'solid',
      borderLeftColor: '#7fb97fe6'
    }
  },
  {
    when: (row) => row.status === STATUS_STATES.PARTIAL_SUCCESS,
    style: {
      borderLeftStyle: 'solid',
      borderLeftColor: '#ec8a359c'
    }
  },
  {
    when: (row) => row.status === STATUS_STATES.FAILED,
    style: {
      borderLeftStyle: 'solid',
      borderLeftColor: '#ff000080'
    }
  }
];

class DialogEmployeeErrorDetails extends React.Component {
  static defaultFilterState = {
    filters: {
      code: [],
      type: [],
      source: [],
      syncStatus: []
    }
  };
  constructor(props) {
    super(props);
    this.state = {
      ...DialogEmployeeErrorDetails.defaultFilterState
    };
  }

  updateFilterValues = (selector) => (values) => {
    let filters = { ...this.state.filters };
    filters[selector] = values;
    this.setState({ filters: filters }, () => {
      this.reloadList();
    });
  };

  getErrorList = (page, size, sort, query) => {
    let { filters } = this.state;
    let syncType = SYNC_TYPE.EMPLOYEE_SYNC;
    const { getErrorList, selectedSync, employerId } = this.props;
    getErrorList({
      syncDetailsId: selectedSync.id,
      employerId,
      page,
      size,
      sort,
      query,
      syncType,
      filters
    });
  };

  reloadList = () => {
    this.errorList.triggerReload();
  };

  resetFilters = () => {
    this.setState(
      {
        ...DialogEmployeeErrorDetails.defaultFilterState
      },
      () => this.reloadList()
    );
  };

  toggle = () => {
    const { toggle } = this.props;
    this.setState({
      ...DialogEmployeeErrorDetails.defaultFilterState
    });
    toggle();
  };

  downloadEmployeeErrorReport = () => {
    const { downloadErrorReport, employerId, selectedSync } = this.props;
    const { id: syncDetailsId } = selectedSync || {};
    downloadErrorReport(employerId, syncDetailsId, SYNC_TYPE.EMPLOYEE_SYNC);
  };

  render() {
    const { data, errorCount, syncErrorFilters } = this.props;
    const { filters } = this.state;

    const { errorCode = [], errorType = [], source = [], syncStatus = [] } =
      syncErrorFilters || {};
    const mappedSources = source.map((src) => {
      return {
        label: VENDORS[src] || src,
        value: src
      };
    });

    let mappedSyncStatuses = syncStatus.map((status) => {
      return {
        label: SYNC_STATUS_FILTER[status] || status,
        value: status
      };
    });

    let errorCodeFilters = errorCode.map((code) => {
      return {
        label: BENADMIN_ERROR_CODE_LABEL_MAP[code] || code,
        value: code
      };
    });

    errorCodeFilters.sort((a, b) => a.label.localeCompare(b.label));

    const SecuredDownloadButton = permitIf(
      PageActionButton,
      INTEGRATIONS_FEATURE_ROLE_MAPPING.downloadErrorReport
    );

    return (
      <>
        <DataTableWithServerPagination
          onRef={(ref) => (this.errorList = ref)}
          className="list-hr-integrations"
          customStyle={customStyles}
          hideSearchBar
          noDataComponent={
            <div className="no-data-reset no-data-sync-errors ">
              <p className="message">No Records to Display</p>
              <Button color="link" onClick={this.resetFilters}>
                Reset
              </Button>
            </div>
          }
          columnData={[
            {
              name: (
                <ColumnFilter
                  columnHeader=""
                  filters={mappedSyncStatuses}
                  selectedFilters={filters.syncStatus}
                  onFilter={this.updateFilterValues('syncStatus')}
                  headerlessIcon={true}
                />
              ),
              selector: 'syncStatus',
              sortable: false,
              width: '10px'
            },
            {
              name: 'Employee/System',
              sortable: true,
              grow: 1,
              selector: 'label',
              cell: ({ label }) => {
                return <span className="error-label">{label}</span>;
              }
            },
            {
              name: 'BenAdmin ID',
              selector: 'sourceId',
              sortable: true,
              grow: 1.5,
              cell: ({ sourceId }) => {
                return sourceId ? sourceId : EMDASH_LITERAL;
              }
            },
            {
              name: (
                <ColumnFilter
                  columnHeader="Type"
                  filters={errorType}
                  selectedFilters={filters.type}
                  onFilter={this.updateFilterValues('type')}
                />
              ),
              selector: 'errorType',
              sortable: false,
              grow: 1
            },
            {
              name: (
                <ColumnFilter
                  columnHeader="Source"
                  filters={mappedSources}
                  selectedFilters={filters.source}
                  onFilter={this.updateFilterValues('source')}
                />
              ),
              grow: 1,
              selector: 'source',
              cell: ({ source }) => {
                return VENDORS[source] || source;
              }
            },
            {
              name: (
                <ColumnFilter
                  columnHeader="Description"
                  filters={errorCodeFilters}
                  selectedFilters={filters.code}
                  onFilter={this.updateFilterValues('code')}
                />
              ),
              grow: 2,
              selector: 'readableError',
              cell: ({ readableError }) => {
                return (
                  <span className="error-details">
                    {BENADMIN_ERROR_CODE_LABEL_MAP[readableError] || readableError}
                  </span>
                );
              }
            }
          ]}
          fetchData={(page, size, sort, query) => {
            return this.getErrorList(page, size, sort, query);
          }}
          conditionalRowStyles={conditionalRowStyles}
          pageData={data}
        />
        <ModalFooter className="modal-footer">
          <Button
            className="form-element"
            color=""
            size="lg"
            onClick={this.toggle}
          >
            Close
          </Button>
          <SecuredDownloadButton
            type="withCustomIcon"
            icon="download"
            onClick={this.downloadEmployeeErrorReport}
            disabled={!errorCount}
          >
            Download Employee Sync Details
          </SecuredDownloadButton>
        </ModalFooter>
      </>
    );
  }
}

export default DialogEmployeeErrorDetails;
