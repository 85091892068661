/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import { BenefitCategory } from 'constants/benefitConstants';

/** Predefined services codes **/
export const DEFAULT_SERVICE_CODES = {
  [BenefitCategory.MEDICAL.value]: [
    1, // Primary Care Visit
    2, // Specialist Visit
    13, //  Urgent Care
    16, //  Ambulance
    15, //  Emergency Room
    77, //  Lab & X-Ray
    17, //  Inpatient Hospital
    5, //  Outpatient Surgery
    34, //  Chiropractic Care
    40, //  Acupuncture
    24 //  Mental Health
  ],
  [BenefitCategory.DENTAL.value]: [
    38, //  Preventive Care
    53, //  Basic Dental Care
    55, //  Major Dental Care
    54 //  Orthodontia
  ],
  [BenefitCategory.VISION.value]: [
    12, //  Routine Eye Exam
    70, //  Materials
    71, //  Frames
    72, //  Lenses (Single)
    73, //  Lenses (Bi)
    74 //  Lenses (Tri)
  ]
};

export const RxTier = {
  TIER_1: { value: 'TIER_1', label: 'Tier 1' },
  TIER_1A: { value: 'TIER_1A', label: 'Tier 1a' },
  TIER_1B: { value: 'TIER_1B', label: 'Tier 1b' },
  TIER_2: { value: 'TIER_2', label: 'Tier 2' },
  TIER_2A: { value: 'TIER_2A', label: 'Tier 2a' },
  TIER_2B: { value: 'TIER_2B', label: 'Tier 2b' },
  TIER_3: { value: 'TIER_3', label: 'Tier 3' },
  TIER_4: { value: 'TIER_4', label: 'Tier 4' }
};

export const BooleanServiceOption = {
  YES: { value: 'YES', label: 'Yes' },
  NO: { value: 'NO', label: 'No' },
  NO_INFO: { value: 'NO_INFO', label: 'No Info' }
};

export const BenefitCoverageOption = {
  YES: { value: 'YES', label: 'Yes' },
  NO: { value: 'NO', label: 'Not Covered' },
  NO_INFO: { value: 'NO_INFO', label: 'No Info' }
};

export const CostSharingPolicy = {
  COPAY: { value: 'COPAY', label: 'Copay', optionLabel: 'Copay Only' },
  COINSURANCE: {
    value: 'COINSURANCE',
    label: 'Coinsurance',
    optionLabel: 'Coinsurance Only'
  },
  COMBINED: { value: 'COMBINED', label: 'Combined', optionLabel: 'Combined' },
  OTHER: { value: 'OTHER', label: 'Other', optionLabel: 'Other' }
};

export const CopayFrequency = {
  PER_DAY: { value: 'PER_DAY', label: 'Per Day' },
  PER_VISIT: { value: 'PER_VISIT', label: 'Per Visit' },
  PER_ADMISSION: { value: 'PER_ADMISSION', label: 'Per Admission' },
  PER_TRIP: { value: 'PER_TRIP', label: 'Per Trip' },
  PER_PROCEDURE: { value: 'PER_PROCEDURE', label: 'Per Procedure' },
  PER_WEEK: { value: 'PER_WEEK', label: 'Per Week' },
  PER_MONTH: { value: 'PER_MONTH', label: 'Per Month' },
  PER_YEAR: { value: 'PER_YEAR', label: 'Per Year' },
  NO_INFO: { value: 'NO_INFO', label: 'No Info' }
};

export const RxCopayFrequency = {
  ...CopayFrequency,
  PER_SCRIPT: { value: 'PER_SCRIPT', label: 'Per Script' },
  PER_ALLOWANCE: { value: 'PER_ALLOWANCE', label: 'Per Allowance' }
};

export const LifeBenefitOptions = {
  FLAT_AMOUNT: { value: 'FLAT_AMOUNT', label: 'Flat Amount' },
  MULTIPLIER_OF_SALARY: {
    value: 'MULTIPLIER_OF_SALARY',
    label: 'Multiplier of Salary'
  },
  MULTIPLIER_OF_SALARY_AND_FLAT_AMOUNT: {
    value: 'MULTIPLIER_OF_SALARY_AND_FLAT_AMOUNT',
    label: 'Multiplier of Salary + Flat Amount'
  }
};

export const CREATE = 'create';
export const EDIT = '/edit';
export const CLONE = 'clone';
export const VIEW = 'view';

export const CUSTOM_STYLES = {
  headCells: {
    inactiveSortStyle: {
      opacity: 1,
      // Target the sort icon when inactive
      span: {
        opacity: 0.25
      }
    }
  }
};