/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Alert } from 'components/Atoms';
import {
  TextFormGroup,
  PasswordFormGroup,
  DatePickerInput,
  RadioRowFormGroup,
  NumberAddonInputGroup
} from 'components/DataForm';
import Icon from 'components/Icons';
import { sanitizeDateInput } from 'util/commonUtil';
import { isValidFormat } from 'util/dateUtil';

// actions
import * as ActionTypes from 'modules/Integrations/actionTypes/integrations.actionTypes';
import {
  validateWorkdayConfigs,
  saveWorkdayConfig
} from 'modules/Integrations/actions/integrationsAction';

import '../DialogIntegrationDetails.scss';
import {
  VENDOR_NAMES,
  WORKDAY_DATE_FORMAT
} from 'modules/Integrations/constants';

class WorkdayVendorConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorConfiguration: props.vendorConfiguration || {
        workdayHrUrl: '',
        workdayHrUsername: '',
        workdayHrPassword: '',
        syncSalary: true,
        salaryOverride: '',
        partTimeBenefitsEligible: true,
        ignoreTerminatedEmployeesCutoffDate: '',
        ignoreTerminatedEmployees: true
      },
      isCutoffDateInvalid: false,
      isValidWorkdayConfig: false,
      isValidatingConfigs: false,
      saveAndFinish: false,
      warningStyle: false,
      error: ''
    };
  }

  onChange = (event) => {
    let { name, value } = event.target;
    const { isCutoffDateInvalid, vendorConfiguration } = this.state;
    let vendorConfigurationDetails = { ...vendorConfiguration };
    let isCutOffDateInvalidFlag = isCutoffDateInvalid;
    switch (name) {
      case 'syncSalary': {
        value = value === 'true';
        vendorConfigurationDetails.salaryOverride = '';
        break;
      }
      case 'partTimeBenefitsEligible': {
        value = value === 'true';
        break;
      }
      case 'ignoreTerminatedEmployees': {
        value = value === 'true';
        if (!value) {
          vendorConfigurationDetails.ignoreTerminatedEmployeesCutoffDate = '';
          if (isCutoffDateInvalid) {
            isCutOffDateInvalidFlag = false;
          }
        }
        break;
      }
      case 'ignoreTerminatedEmployeesCutoffDate': {
        if (isCutoffDateInvalid) {
          isCutOffDateInvalidFlag = false;
        }
        break;
      }
      default:
        break;
    }
    vendorConfigurationDetails[name] = value;
    this.setState({
      error: '',
      vendorConfiguration: vendorConfigurationDetails,
      isCutoffDateInvalid: isCutOffDateInvalidFlag
    });
  };

  hasRequiredInputs() {
    let hasRequiredInputs = true;
    const { vendorConfiguration } = this.state;
    const requiredInputs = [
      'workdayHrUrl',
      'workdayHrUsername',
      'workdayHrPassword'
    ];

    for (let requiredInput of requiredInputs) {
      if (!vendorConfiguration || !vendorConfiguration[requiredInput]) {
        hasRequiredInputs = false;
        break;
      }
    }
    // Handle Salary Override differently, only required if Sync Salary is false
    if (
      !vendorConfiguration.syncSalary &&
      !vendorConfiguration.salaryOverride
    ) {
      hasRequiredInputs = false;
    }
    return hasRequiredInputs;
  }

  validateVendorConfiguration = () => {
    const { employerId } = this.props;
    const { vendorConfiguration } = this.state;
    const { ignoreTerminatedEmployeesCutoffDate } = vendorConfiguration;
    if (
      ignoreTerminatedEmployeesCutoffDate &&
      !isValidFormat(ignoreTerminatedEmployeesCutoffDate, WORKDAY_DATE_FORMAT)
    ) {
      this.setState({
        isCutoffDateInvalid: true
      });
    } else {
      this.setState({ isValidatingConfigs: true });
      this.props.validateWorkdayConfigs(employerId, {
        ...vendorConfiguration
      });
    }
  };

  saveAndCloseModal = () => {
    this.setState({ saveAndFinish: true }, () => {
      this.validateVendorConfiguration();
    });
  };

  componentDidMount() {
    const { vendorConfiguration } = this.state;
    const {
      workdayHrUrl = '',
      workdayHrUsername = '',
      workdayHrPassword = '',
      syncSalary = true,
      salaryOverride = '',
      partTimeBenefitsEligible = true,
      ignoreTerminatedEmployeesCutoffDate = '',
      ignoreTerminatedEmployees = true
    } = vendorConfiguration || {};

    this.setState({
      vendorConfiguration: {
        workdayHrUrl: workdayHrUrl,
        workdayHrUsername: workdayHrUsername,
        workdayHrPassword: workdayHrPassword,
        syncSalary: syncSalary,
        salaryOverride: salaryOverride,
        partTimeBenefitsEligible: partTimeBenefitsEligible,
        ignoreTerminatedEmployeesCutoffDate: ignoreTerminatedEmployeesCutoffDate,
        ignoreTerminatedEmployees: ignoreTerminatedEmployees
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { requestStatus, onNext, saveAndCloseConfigs, isEditable } = this.props;
    const { vendorConfiguration, saveAndFinish } = this.state;
    if (requestStatus === prevProps.requestStatus) return;
    switch (requestStatus) {
      case ActionTypes.VALIDATE_WORKDAY_CONFIGS:
        this.setState({
          warningStyle: true,
          error: 'Validating Configurations',
          isValidatingConfigs: true
        });
        break;
      case ActionTypes.VALIDATE_WORKDAY_CONFIGS_SUCCESS:
        switch (saveAndFinish) {
          case true:
            this.setState(
              { isValidatingConfigs: true, isValidWorkdayConfig: true },
              () => saveAndCloseConfigs(isEditable, vendorConfiguration)
            );
            break;

          case false: {
            this.setState(
              { isValidatingConfigs: true, isValidWorkdayConfig: true },
              () => onNext(vendorConfiguration)
            );
            break;
          }
          default:
            break;
        }
        break;
      case ActionTypes.VALIDATE_WORKDAY_CONFIGS_ERROR: {
        this.setState({
          warningStyle: false,
          error: 'Invalid Configuration',
          isValidatingConfigs: false,
          saveAndFinish: false
        });
        break;
      }
      default:
        break;
    }
  }

  render() {
    const { selectedVendor, onPrevious, isEditable } = this.props;
    const {
      error,
      isCutoffDateInvalid,
      vendorConfiguration,
      isValidatingConfigs,
      saveAndFinish,
      warningStyle
    } = this.state;
    const vendorName = selectedVendor.name;
    const {
      workdayHrUrl = '',
      workdayHrUsername = '',
      workdayHrPassword = '',
      syncSalary = true,
      salaryOverride = '',
      partTimeBenefitsEligible = true,
      ignoreTerminatedEmployeesCutoffDate = '',
      ignoreTerminatedEmployees = true
    } = vendorConfiguration || {};

    return (
      <div className="step-container vendor-configuration">
        <div className="body">
          <p className="subtitle">{vendorName}</p>
          {error && (
            <Alert
              color={warningStyle ? 'warning' : 'danger'}
              className="error-alert"
            >
              {error}
            </Alert>
          )}
          <div>
            <TextFormGroup
              labelDisplay={'URL *'}
              inputName="workdayHrUrl"
              inputValue={workdayHrUrl}
              onChange={this.onChange}
              placeholder="Enter URL"
            />
            <TextFormGroup
              labelDisplay={'Username *'}
              inputName="workdayHrUsername"
              inputValue={workdayHrUsername}
              onChange={this.onChange}
              placeholder="Enter Username"
            />
            <PasswordFormGroup
              labelDisplay={'Password *'}
              inputName="workdayHrPassword"
              inputValue={workdayHrPassword}
              onChange={this.onChange}
              placeholder="Enter Password"
            />
          </div>

          <Row>
            <Col>
              <RadioRowFormGroup
                labelDisplay="Sync Salary"
                className="min-input-height"
                onChange={this.onChange}
                inputName="syncSalary"
                inputValue={syncSalary}
                options={[
                  {
                    value: true,
                    text: 'Yes'
                  },
                  {
                    value: false,
                    text: 'No'
                  }
                ]}
              />
            </Col>
            <Col>
              <NumberAddonInputGroup
                labelDisplay="Salary Override"
                onNumberChange={(name, value) =>
                  this.onChange({ target: { name, value } })
                }
                inputName="salaryOverride"
                inputValue={salaryOverride}
                addonType="prepend"
                addonText="$"
                min={0}
                decimalScale={2}
                fixedDecimalScale={2}
                isEmptyAllowed={true}
                disabled={syncSalary}
              />
            </Col>
          </Row>
          <RadioRowFormGroup
            labelDisplay="Sync Part-Time Eligible"
            className="min-input-height"
            onChange={this.onChange}
            inputName="partTimeBenefitsEligible"
            inputValue={partTimeBenefitsEligible}
            options={[
              {
                value: true,
                text: 'Yes'
              },
              {
                value: false,
                text: 'No'
              }
            ]}
          />
          <Row>
            <Col>
              <RadioRowFormGroup
                labelDisplay="Sync Terminated Employees"
                className="min-input-height"
                onChange={this.onChange}
                inputName="ignoreTerminatedEmployees"
                inputValue={ignoreTerminatedEmployees}
                options={[
                  {
                    value: true,
                    text: 'Yes'
                  },
                  {
                    value: false,
                    text: 'No'
                  }
                ]}
              />
            </Col>
            <Col>
              <DatePickerInput
                labelDisplay="Sync Terminated Employees After"
                inputName="ignoreTerminatedEmployeesCutoffDate"
                inputValue={ignoreTerminatedEmployeesCutoffDate}
                dateFormat={WORKDAY_DATE_FORMAT}
                onChange={(event) => {
                  const { value } = event.target;
                  event.target.value = sanitizeDateInput(value, '-');
                  this.onChange(event);
                }}
                isInvalid={isCutoffDateInvalid}
                feedback={`Please enter a valid date in the format ${WORKDAY_DATE_FORMAT}`}
                disabled={!ignoreTerminatedEmployees}
              />
            </Col>
          </Row>
        </div>
        <Row>
          {!isEditable && (
            <Col>
              <Button
                className="form-element"
                outline
                color="primary"
                size="lg"
                onClick={() => onPrevious(vendorConfiguration)}
                disabled={isEditable}
              >
                Previous
              </Button>
            </Col>
          )}
          {vendorName === VENDOR_NAMES.WORKDAY && isEditable && (
            <Col>
              <Button
                className="form-element"
                color="primary"
                size="lg"
                disabled={
                  !this.hasRequiredInputs() ||
                  (isValidatingConfigs && saveAndFinish)
                }
                onClick={this.saveAndCloseModal}
              >
                {vendorName === 'Workday' &&
                isValidatingConfigs &&
                saveAndFinish ? (
                  <span>
                    <Icon
                      icon="circle-notch"
                      className="icon-margin-right"
                      spin
                    />
                  </span>
                ) : (
                  ''
                )}
                Finish
              </Button>
            </Col>
          )}
          <Col>
            <Button
              className="form-element"
              color="primary"
              size="lg"
              disabled={!this.hasRequiredInputs() || isValidatingConfigs}
              onClick={this.validateVendorConfiguration}
            >
              {vendorName === VENDOR_NAMES.WORKDAY &&
              isValidatingConfigs &&
              !saveAndFinish ? (
                <span className="loading-icon">
                  <Icon
                    icon="circle-notch"
                    className="icon-margin-right"
                    spin
                  />
                </span>
              ) : (
                ''
              )}
              Mappings
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(
  ({ integrationsReducer }) => {
    const {
      requestStatus,
      serviceError,
      workdayConfigs,
      integrationConfigDetails
    } = integrationsReducer;
    return {
      requestStatus,
      serviceError,
      workdayConfigs,
      integrationConfigDetails
    };
  },
  {
    validateWorkdayConfigs,
    saveWorkdayConfig
  }
)(WorkdayVendorConfiguration);
