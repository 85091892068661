/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import _ from 'lodash';

import { Row, Col, Button, Label } from 'components/Atoms';
import PopulatePlanDetailsModal from '../PopulatePlanDetailsModal/PopulatePlanDetailsModal';
import RemovePlanModal from '../RemovePlanModal/RemovePlanModal';
import RenamePlanModal from '../RenamePlanModal/RenamePlanModal';
import AdjustWeightingsModal from '../AdjustWeightingsModal/AdjustWeightingsModal';
import ModelingSplitInput from '../ModelingSplitInput/ModelingSplitInput';
import ModelingInput from '../ModelingInput/ModelingInput';

import './CurrentPlanDetails.scss';

import {
  TYPE_DEDUCTIBLE,
  TYPE_OOP_MAX,
  TYPE_COPAY,
  TYPE_RX_COST_TIER,
  TYPE_CONTRIBUTION,
  TYPE_RATES,
  TYPE_ENROLLMENTS,
  BENEFIT_KIND_VISION,
  BENEFIT_KIND_DENTAL,
  BENEFIT_KIND_MEDICAL,
  BENCHMARK_PLAN_HSA,
  HSA_AVAILABLE_TYPES,
  BENCHMARK_PLAN_HSA_PLACEHOLDER,
  COINSURANCE
} from '../../constants';
import { isMedicalHSAPlan } from '../../util';
class CurrentPlanDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employer: '',
      carrier: '',
      feedback: 'Please enter a Plan Name',
      isOtherPlansModalOpen: false,
      isRemovePlanModalOpen: false,
      isRenamePlanModalOpen: false,
      isAdjustWeightingsModalOpen: false,
      inputPrefix: '$',
      inputSuffix: '%'
    };
  }

  toggleOtherPlansModal = () => {
    this.setState((prevState) => ({
      isOtherPlansModalOpen: !prevState.isOtherPlansModalOpen
    }));
  };

  toggleRemovePlanModal = () => {
    this.setState((prevState) => ({
      isRemovePlanModalOpen: !prevState.isRemovePlanModalOpen
    }));
  };

  toggleRenamePlanModal = () => {
    this.setState((prevState) => ({
      isRenamePlanModalOpen: !prevState.isRenamePlanModalOpen
    }));
  };

  toggleAdjustWeightingsModal = () => {
    this.setState((prevState) => ({
      isAdjustWeightingsModalOpen: !prevState.isAdjustWeightingsModalOpen
    }));
  };

  onClickRenameButton = (planKey, value) => {
    const { updatePlanName } = this.props;

    if (_.isEmpty(value)) {
      this.setState({ feedback: 'Please fill in this field' });
    } else {
      updatePlanName(planKey, value);
      this.toggleRenamePlanModal();
    }
  };

  onClickRemoveButton = () => {
    this.toggleRemovePlanModal();
  };

  formatHSAVAlues = (value) => {
    return parseFloat(value).toFixed(2);
  }

  render() {
    const {
      plan,
      planKey,
      onChange,
      validations,
      onSelectBenchmarkPlan,
      removePlanType,
      benefitCategory,
      weights,
      updateWeights
    } = this.props;

    const {
      isOtherPlansModalOpen,
      isRemovePlanModalOpen,
      isRenamePlanModalOpen,
      isAdjustWeightingsModalOpen,
      feedback,
      inputSuffix
    } = this.state;

    const { planType, planDesign = {}, planName } = plan;
    const {
      coinsurance,
      deductible,
      oopMax,
      copay,
      rxCost,
      annualMaxBenefits,
      orthodontiaCoinsuarance,
      orthodontiaMaxBenefits,
      copayExam,
      copayMaterials,
      frameAllowance,
      contactAllowance,
      freqOfService
    } = planDesign;

    const { preventitive, basic, major } = planDesign.coinsurance || {};

    const contribution = plan.contribution || {};
    const rates = plan.rates || {};
    const hsaContribution = plan.hsaContribution || {};
    const inputProps = {
      planKey,
      validations,
      onEventChange,
      onChange,
      plan
    };

    const getTotalCosts = (rates, key) => {
      return isNaN(rates[key]) ? '' : parseFloat(rates[key]).toFixed(2);
    };

    const { error = {} } = plan;
    error.rates = error.rates || {};
    error.hsaContribution = error.hsaContribution || {};

    if (!_.isEmpty(rates) && !_.isEmpty(error)) {
      if (!Number.isNaN(rates.EE) && rates.EE !== undefined) {
        error.rates.EE = false;
      }
      if (!Number.isNaN(rates.EC) && rates.EC !== undefined) {
        error.rates.EC = false;
      }
      if (!Number.isNaN(rates.ES) && rates.ES !== undefined) {
        error.rates.ES = false;
      }
      if (!Number.isNaN(rates.EF) && rates.EF !== undefined) {
        error.rates.EF = false;
      }
    }

    if (!_.isEmpty(hsaContribution) && !_.isEmpty(error)) {
      if (
        !Number.isNaN(hsaContribution.EE) &&
        hsaContribution.EE !== undefined
      ) {
        error.hsaContribution.EE = false;
      }
      if (
        !Number.isNaN(hsaContribution.EC) &&
        hsaContribution.EC !== undefined
      ) {
        error.hsaContribution.EC = false;
      }
      if (
        !Number.isNaN(hsaContribution.ES) &&
        hsaContribution.ES !== undefined
      ) {
        error.hsaContribution.ES = false;
      }
      if (
        !Number.isNaN(hsaContribution.EF) &&
        hsaContribution.EF !== undefined
      ) {
        error.hsaContribution.EF = false;
      }
    }

    let hsaContributionError = false;
    Object.values(error.hsaContribution || {}).forEach(
      (value) => (hsaContributionError = hsaContributionError || value)
    );
    Object.values(error.rates || {}).forEach(
      (value) => (hsaContributionError = hsaContributionError || value)
    );

    return (
      <div className="current-plan-details">
        <Row className="plan-actions">
          <Col className="plan-design-plan-name-container">
            <Row>
              <Col xs="auto" className="current-plan-design-plan-name">
                <Label className="plan-design-plan-name-font">Current</Label>
              </Col>
              <Col className="plan-type-continer">
                <Label className="plan-design-plan-type-font">
                  {`-  ${planName}`}
                </Label>
              </Col>
              <Col
                xs="auto"
                className="btn-primary-populate-plans-cintainer float-right"
              >
                <Label
                  className="benchmark-btn-remove-plan "
                  onClick={this.toggleRemovePlanModal}
                >
                  remove plan
                </Label>
                <Label className="benchmark-btn-seperator">|</Label>
                <Label
                  className="benchmark-btn-rename-plan"
                  onClick={this.toggleRenamePlanModal}
                >
                  rename plan
                </Label>
                <Button
                  size="md"
                  color="primary"
                  outline
                  className="btn-primary-populate-plans float-right"
                  onClick={this.toggleOtherPlansModal}
                >
                  Populate from Other Plans
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="row-plan-design plan-design-container">
          <Row>
            <Col className="page-content plan-design-section">
              <Row>
                <Col className="line-item-label">
                  <Label className="plan-attr-lable-title">Plan Design</Label>
                </Col>
              </Row>
              <>
                {benefitCategory === BENEFIT_KIND_MEDICAL && (
                  <>
                    <ModelingInput
                      {...inputProps}
                      title="Deductible - Single"
                      value={parseFloat(deductible.single).toFixed(2)}
                      id="deductSingle"
                      name="single"
                      type={TYPE_DEDUCTIBLE}
                      inValid={
                        validations[`isDeductibleSingleEmpty_${planKey}`]
                      }
                    />

                    <ModelingInput
                      {...inputProps}
                      title="Deductible - Family"
                      value={parseFloat(deductible.family).toFixed(2)}
                      id="deductFamily"
                      name="family"
                      type={TYPE_DEDUCTIBLE}
                      inValid={
                        validations[`isDeductibleFamilyEmpty_${planKey}`]
                      }
                    />
                    <ModelingInput
                      {...inputProps}
                      title="OOP Max Single"
                      value={parseFloat(oopMax.single).toFixed(2)}
                      id="oopMaxSingle"
                      name="single"
                      type={TYPE_OOP_MAX}
                      inValid={validations[`isOopMaxSingleEmpty_${planKey}`]}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="OOP Max Family"
                      value={parseFloat(oopMax.family).toFixed(2)}
                      id="oopMaxFamily"
                      name="family"
                      type={TYPE_OOP_MAX}
                      inValid={validations[`isOopMaxFamilyEmpty_${planKey}`]}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Copay Primary"
                      value={parseFloat(copay.primary).toFixed(2)}
                      id="copayPrimary"
                      name="primary"
                      type={TYPE_COPAY}
                      inValid={validations[`isCopayPrimaryEmpty_${planKey}`]}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Copay Specialist"
                      value={parseFloat(copay.specialist).toFixed(2)}
                      id="copaySpecialist"
                      name="specialist"
                      type={TYPE_COPAY}
                      inValid={validations[`isCopaySpecialistEmpty_${planKey}`]}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Coinsuarance"
                      value={coinsurance}
                      id="coinsurance"
                      name="coinsurance"
                      prefix={''}
                      suffix={inputSuffix}
                      inValid={validations[`isCoinsuaranceEmpty_${planKey}`]}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="RX Cost - Tier 1"
                      value={parseFloat(rxCost.t1).toFixed(2)}
                      id="rxCostT1"
                      name="t1"
                      inValid={validations[`isRxCostT1Empty_${planKey}`]}
                      type={TYPE_RX_COST_TIER}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="RX Cost - Tier 2"
                      value={parseFloat(rxCost.t2).toFixed(2)}
                      id="rxCostT2"
                      name="t2"
                      inValid={validations[`isRxCostT2Empty_${planKey}`]}
                      type={TYPE_RX_COST_TIER}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="RX Cost - Tier 3"
                      value={parseFloat(rxCost.t3).toFixed(2)}
                      id="rxCostT3"
                      name="t3"
                      inValid={validations[`isRxCostT3Empty_${planKey}`]}
                      type={TYPE_RX_COST_TIER}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="RX Cost - Tier 4"
                      value={parseFloat(rxCost.t4).toFixed(2)}
                      id="rxCostT4"
                      name="t4"
                      inValid={validations[`isRxCostT4Empty_${planKey}`]}
                      type={TYPE_RX_COST_TIER}
                    />
                  </>
                )}
              </>
              <>
                {benefitCategory === BENEFIT_KIND_DENTAL && (
                  <>
                    <ModelingInput
                      {...inputProps}
                      title="Deductible - Single"
                      value={parseFloat(deductible.single).toFixed(2)}
                      id="deductSingle"
                      name="single"
                      inValid={
                        validations[`isDeductibleSingleEmpty_${planKey}`]
                      }
                      type={TYPE_DEDUCTIBLE}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Deductible - Family"
                      value={parseFloat(deductible.family).toFixed(2)}
                      id="deductFamily"
                      name="family"
                      inValid={
                        validations[`isDeductibleFamilyEmpty_${planKey}`]
                      }
                      type={TYPE_DEDUCTIBLE}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Annual Max Benefits"
                      value={parseFloat(annualMaxBenefits).toFixed(2)}
                      id="annualMaxBenefits"
                      name="annualMaxBenefits"
                      inValid={validations[`annualMaxBenefits_${planKey}`]}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Coinsurance-Preventitive"
                      value={preventitive}
                      id="coinsurancePreventitive"
                      name="preventitive"
                      suffix={inputSuffix}
                      prefix={''}
                      validation="coinsurancePreventitive"
                      inValid={validations[`isCoinsuaranceEmpty_${planKey}`]}
                      type={COINSURANCE}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Coinsurance-Basic"
                      value={basic}
                      id="coinsuranceBasic"
                      name="basic"
                      suffix={inputSuffix}
                      prefix={''}
                      inValid={validations[`coinsuranceBasic_${planKey}`]}
                      type={COINSURANCE}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Coinsurance-Major"
                      value={major}
                      id="coinsuranceMajor"
                      name="major"
                      suffix={inputSuffix}
                      prefix={''}
                      inValid={validations[`coinsuranceMajor_${planKey}`]}
                      type={COINSURANCE}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Orthodontia Coinsurance"
                      value={orthodontiaCoinsuarance}
                      id="orthodontiaCoinsuarance"
                      name="orthodontiaCoinsuarance"
                      suffix={inputSuffix}
                      prefix={''}
                      inValid={validations[`orthodontia_${planKey}`]}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Orthodontia Max Benefits"
                      value={parseFloat(orthodontiaMaxBenefits).toFixed(2)}
                      id="orthodontiaMaxBenefits"
                      name="orthodontiaMaxBenefits"
                      inValid={validations[`orthodontiaMaxBenefits_${planKey}`]}
                    />
                  </>
                )}
              </>
              <>
                {benefitCategory === BENEFIT_KIND_VISION && (
                  <>
                    <ModelingInput
                      {...inputProps}
                      title="Copay Exam"
                      value={parseFloat(copayExam).toFixed(2)}
                      id="copayExam"
                      name="copayExam"
                      inValid={validations[`copayExam_${planKey}`]}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Copay Materials"
                      value={parseFloat(copayMaterials).toFixed(2)}
                      id="copayMaterials"
                      name="copayMaterials"
                      inValid={validations[`copayMaterials_${planKey}`]}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Frame Allowance"
                      value={parseFloat(frameAllowance).toFixed(2)}
                      id="frameAllowance"
                      name="frameAllowance"
                      prefix="Up to $"
                      inValid={validations[`frameAllowance_${planKey}`]}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Contact Allowance"
                      value={parseFloat(contactAllowance).toFixed(2)}
                      id="contactAllowance"
                      name="contactAllowance"
                      prefix="Up to $"
                      inValid={validations[`contactAllowance_${planKey}`]}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Freq. of Services"
                      value={freqOfService}
                      id="freqOfService"
                      name="freqOfService"
                      inValid={validations[`contactAllowance_${planKey}`]}
                      format="## / ## / ##"
                    />
                  </>
                )}
              </>
            </Col>
            <Col className="page-content current-plan-section-seperator">
              <div>
                <Row>
                  <Col className="line-item-label">
                    <Label className="plan-attr-lable-title">
                      Employee Cost - Monthly
                    </Label>
                  </Col>
                </Row>
                <ModelingInput
                  {...inputProps}
                  title="Employee Only"
                  value={parseFloat(contribution.EE).toFixed(2)}
                  id="totalEmployeeOnly"
                  name="EE"
                  inValid={validations[`isContributionEEEmpty_${planKey}`]}
                  type={TYPE_CONTRIBUTION}
                />
                <ModelingInput
                  {...inputProps}
                  title="Employee & Spouse"
                  value={parseFloat(contribution.ES).toFixed(2)}
                  id="totalEmployeeAndSpouse"
                  name="ES"
                  inValid={validations[`isContributionESEmpty_${planKey}`]}
                  type={TYPE_CONTRIBUTION}
                />
                <ModelingInput
                  {...inputProps}
                  title="Employee & Child(ren)"
                  value={parseFloat(contribution.EC).toFixed(2)}
                  id="totalEmployeeAndChildren"
                  name="EC"
                  inValid={validations[`isContributionECEmpty_${planKey}`]}
                  type={TYPE_CONTRIBUTION}
                />
                <ModelingInput
                  {...inputProps}
                  title="Employee & Family"
                  value={parseFloat(contribution.EF).toFixed(2)}
                  id="totalEmployeeAndFamily"
                  name="EF"
                  inValid={validations[`isContributionEFEmpty_${planKey}`]}
                  type={TYPE_CONTRIBUTION}
                />
              </div>
              <div className="current-plan-section-seperator-lower">
                <Row>
                  <Col className="line-item-label">
                    <Label className="plan-attr-lable-title">
                      {`Total Cost - Monthly ${
                        isMedicalHSAPlan(planType, benefitCategory)
                          ? '/ HSA Contribution'
                          : ''
                      }`}
                    </Label>
                  </Col>
                </Row>
                {!isMedicalHSAPlan(planType, benefitCategory) && (
                  <>
                    <ModelingInput
                      {...inputProps}
                      title="Employee Only"
                      value={getTotalCosts(rates, 'EE')}
                      id="totalMonthlyEmployeeOnly"
                      name="EE"
                      type={TYPE_RATES}
                      placeholder={
                        isMedicalHSAPlan(planType, benefitCategory)
                          ? BENCHMARK_PLAN_HSA_PLACEHOLDER
                          : ''
                      }
                      inValid={validations[`isRatesEEEmpty_${planKey}`]}
                      onEventChange={(event, onChange, plan, type, planKey) => {
                        inputProps.onEventChange(
                          event,
                          onChange,
                          plan,
                          type,
                          planKey
                        );
                      }}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Employee & Spouse / D.P."
                      value={getTotalCosts(rates, 'ES')}
                      id="totalMonthlyEmployeeAndSpouce"
                      name="ES"
                      type={TYPE_RATES}
                      placeholder={
                        isMedicalHSAPlan(planType, benefitCategory)
                          ? BENCHMARK_PLAN_HSA_PLACEHOLDER
                          : ''
                      }
                      inValid={validations[`isRatesESEmpty_${planKey}`]}
                      onEventChange={(event, onChange, plan, type, planKey) => {
                        inputProps.onEventChange(
                          event,
                          onChange,
                          plan,
                          type,
                          planKey
                        );
                      }}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Employee & Child(ren)"
                      value={getTotalCosts(rates, 'EC')}
                      id="totalMonthlyEmployeeAndChildren"
                      name="EC"
                      type={TYPE_RATES}
                      placeholder={
                        isMedicalHSAPlan(planType, benefitCategory)
                          ? BENCHMARK_PLAN_HSA_PLACEHOLDER
                          : ''
                      }
                      inValid={validations[`isRatesECEmpty_${planKey}`]}
                      onEventChange={(event, onChange, plan, type, planKey) => {
                        inputProps.onEventChange(
                          event,
                          onChange,
                          plan,
                          type,
                          planKey
                        );
                      }}
                    />
                    <ModelingInput
                      {...inputProps}
                      title="Employee & Family"
                      value={getTotalCosts(rates, 'EF')}
                      id="totalMonthlyEmployeeAndFamily"
                      name="EF"
                      type={TYPE_RATES}
                      placeholder={
                        isMedicalHSAPlan(planType, benefitCategory)
                          ? BENCHMARK_PLAN_HSA_PLACEHOLDER
                          : ''
                      }
                      inValid={validations[`isRatesEFEmpty_${planKey}`]}
                      onEventChange={(event, onChange, plan, type, planKey) => {
                        inputProps.onEventChange(
                          event,
                          onChange,
                          plan,
                          type,
                          planKey
                        );
                      }}
                    />
                  </>
                )}
                {planType === BENCHMARK_PLAN_HSA &&
                  benefitCategory === BENEFIT_KIND_MEDICAL && (
                    <>
                      <ModelingSplitInput
                        {...inputProps}
                        title="Employee Only"
                        leftValue={this.formatHSAVAlues(rates['EE'])}
                        rightValue={this.formatHSAVAlues(hsaContribution['EE'])}
                        leftId="costMonthlyEmployeeOnly"
                        rightId="contributionMonthlyEmployeeOnly"
                        name="EE"
                        inValid={validations[`isRatesEEEmpty_${planKey}`]}
                        inValidLeft={error.rates.EE}
                        inValidRight={error.hsaContribution.EE}
                        onEventChange={(
                          event,
                          onChange,
                          plan,
                          type,
                          planKey
                        ) => {
                          inputProps.onEventChange(
                            event,
                            onChange,
                            plan,
                            type,
                            planKey
                          );
                        }}
                      />
                      <ModelingSplitInput
                        {...inputProps}
                        title="Employee & Spouse / D.P."
                        leftValue={this.formatHSAVAlues(rates['ES'])}
                        rightValue={this.formatHSAVAlues(hsaContribution['ES'])}
                        leftId="costMonthlyEmployeeAndSpouce"
                        rightId="contributionMonthlyEmployeeAndSpouce"
                        name="ES"
                        inValid={validations[`isRatesESEmpty_${planKey}`]}
                        inValidLeft={error.rates.ES}
                        inValidRight={error.hsaContribution.ES}
                        onEventChange={(
                          event,
                          onChange,
                          plan,
                          type,
                          planKey
                        ) => {
                          inputProps.onEventChange(
                            event,
                            onChange,
                            plan,
                            type,
                            planKey
                          );
                        }}
                      />
                      <ModelingSplitInput
                        {...inputProps}
                        title="Employee & Child(ren)"
                        leftValue={this.formatHSAVAlues(rates['EC'])}
                        rightValue={this.formatHSAVAlues(hsaContribution['EC'])}
                        leftId="costMonthlyEmployeeAndChildren"
                        rightId="contributionMonthlyEmployeeAndChildren"
                        name="EC"
                        inValid={validations[`isRatesECEmpty_${planKey}`]}
                        inValidLeft={error.rates.EC}
                        inValidRight={error.hsaContribution.EC}
                        onEventChange={(
                          event,
                          onChange,
                          plan,
                          type,
                          planKey
                        ) => {
                          inputProps.onEventChange(
                            event,
                            onChange,
                            plan,
                            type,
                            planKey
                          );
                        }}
                      />
                      <ModelingSplitInput
                        {...inputProps}
                        title="Employee & Family"
                        leftValue={this.formatHSAVAlues(rates['EF'])}
                        rightValue={this.formatHSAVAlues(hsaContribution['EF'])}
                        leftId="costMonthlyEmployeeAndFamily"
                        rightId="contributionMonthlyEmployeeAndFamily"
                        name="EF"
                        inValid={validations[`isRatesEFEmpty_${planKey}`]}
                        inValidLeft={error.rates.EF}
                        inValidRight={error.hsaContribution.EF}
                        onEventChange={(
                          event,
                          onChange,
                          plan,
                          type,
                          planKey
                        ) => {
                          inputProps.onEventChange(
                            event,
                            onChange,
                            plan,
                            type,
                            planKey
                          );
                        }}
                      />
                      <Row>
                        <Col className="line-item-label">
                          {hsaContributionError && (
                            <Label className="plan-attr-lable-title-error">
                              All fields require HSA Contribution values
                            </Label>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
              </div>
              {this.getNoOfEnrollments(inputProps)}
            </Col>
          </Row>
          <Row>
            <Label
              className="adjust-weightings"
              onClick={this.toggleAdjustWeightingsModal}
            >
              adjust weightings
            </Label>
          </Row>
        </div>
        <PopulatePlanDetailsModal
          title="Populate Plan Details"
          onSelectBenchmarkPlan={onSelectBenchmarkPlan}
          isOpen={isOtherPlansModalOpen}
          toggle={this.toggleOtherPlansModal}
          planKey={planKey}
          planType={planType}
          weights={!_.isEmpty(plan.weights) ? plan.weights : weights}
          benefitCategory={benefitCategory}
        />
        <RemovePlanModal
          isOpen={isRemovePlanModalOpen}
          toggle={this.toggleRemovePlanModal}
          onRemovePlanType={removePlanType}
          onButtonChange={this.onClickRemoveButton}
          planKey={planKey}
        />
        <RenamePlanModal
          isOpen={isRenamePlanModalOpen}
          toggle={this.toggleRenamePlanModal}
          onButtonChange={this.onClickRenameButton}
          planName={planName}
          planKey={planKey}
          feedback={feedback}
        />
        <AdjustWeightingsModal
          isOpen={isAdjustWeightingsModalOpen}
          toggle={this.toggleAdjustWeightingsModal}
          benefitType={benefitCategory}
          totalWeighting={getTotalWeightsBasedBenCategory(weights)}
          weights={!_.isEmpty(plan.weights) ? plan.weights : weights}
          updateWeights={updateWeights}
          planKey={planKey}
        />
      </div>
    );
  }

  getNoOfEnrollments(inputProps) {
    const { plan = {} } = this.props;
    const { enrollments = {}, error = {} } = plan;
    const { EE = '', ES = '', EC = '', EF = '' } = enrollments;
    //update enrollment errors
    if (!_.isEmpty(enrollments) && !_.isEmpty(error)) {
      if (
        !Number.isNaN(enrollments.EE) &&
        enrollments.EE !== undefined &&
        enrollments.EE.length > 0
      ) {
        error.enrollments.EE = false;
      }
      if (
        !Number.isNaN(enrollments.EC) &&
        enrollments.EC !== undefined &&
        enrollments.EC.length > 0
      ) {
        error.enrollments.EC = false;
      }
      if (
        !Number.isNaN(enrollments.ES) &&
        enrollments.ES !== undefined &&
        enrollments.ES.length > 0
      ) {
        error.enrollments.ES = false;
      }
      if (
        !Number.isNaN(enrollments.EF) &&
        enrollments.EF !== undefined &&
        enrollments.EF.length > 0
      ) {
        error.enrollments.EF = false;
      }
    }
    const { enrollments: enrollmentsErrors = {} } = error;
    let hasEnrollmentError = false;
    Object.values(enrollmentsErrors).forEach(
      (value) => (hasEnrollmentError = hasEnrollmentError || value)
    );
    return (
      <div className="current-plan-section-seperator-lower enrolments">
        <Row>
          <Col className="line-item-label">
            <Label className="plan-attr-lable-title">No. of Enrollments</Label>
          </Col>
        </Row>
        <ModelingInput
          {...inputProps}
          title="Employee Only *"
          value={EE}
          id="enrollmentEmployeeOnly"
          name="EE"
          type={TYPE_ENROLLMENTS}
          decimalScale={0}
          inValid={enrollmentsErrors.EE}
          prefix={''}
        />
        <ModelingInput
          {...inputProps}
          title="Employee & Spouse *"
          value={ES}
          id="enrollmentEmployeeAndSpouse"
          name="ES"
          type={TYPE_ENROLLMENTS}
          decimalScale={0}
          inValid={enrollmentsErrors.ES}
          prefix={''}
        />
        <ModelingInput
          {...inputProps}
          title="Employee & Child(ren) *"
          value={EC}
          id="enrollmentEmployeeAndChildren"
          name="EC"
          type={TYPE_ENROLLMENTS}
          decimalScale={0}
          inValid={enrollmentsErrors.EC}
          prefix={''}
        />
        <ModelingInput
          {...inputProps}
          title="Employee & Family *"
          value={EF}
          id="enrollmentEmployeeAndFamily"
          name="EF"
          type={TYPE_ENROLLMENTS}
          decimalScale={0}
          inValid={enrollmentsErrors.EF}
          prefix={''}
        />
        {hasEnrollmentError && (
          <Row>
            <Col>
              <Label className="enrollment-error">
                All tiers require number of enrollments value
              </Label>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const getTotalWeightsBasedBenCategory = (weights) => {
  let totalWeightsSum = _.sum(_.values(weights));
  return totalWeightsSum;
};

const convertToCurrency = (stringValue) => {
  if (stringValue) {
    if (stringValue.includes('%')) {
      stringValue = stringValue.replace(/[^0-9.]/g, '');
      return parseFloat(stringValue);
    } else {
      stringValue = stringValue.replace(/[^0-9.]/g, '');
      return parseFloat(stringValue).toFixed(2);
    }
  }
};

const onEventChange = (
  event,
  onChange,
  currentPlan,
  type = undefined,
  planKey
) => {
  const { name, value, id } = event.target;
  onChange(
    _.includes(HSA_AVAILABLE_TYPES, id) &&
      currentPlan.planType === BENCHMARK_PLAN_HSA
      ? name.split('_')[0]
      : name,
    _.includes(nonCurrencyTypes, name) || _.includes(nonCurrencyIds, id)
      ? value
      : convertToCurrency(value),
    currentPlan,
    type,
    planKey,
    id
  );
};
const nonCurrencyTypes = ['freqOfService'];
const nonCurrencyIds = [
  'enrollmentEmployeeOnly',
  'enrollmentEmployeeAndSpouse',
  'enrollmentEmployeeAndChildren',
  'enrollmentEmployeeAndFamily'
];

export default CurrentPlanDetails;
