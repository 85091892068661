/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import Loader from 'components/CircularProgress';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import DailySyncSwitch from 'components/DailySyncSwitch';
import { Button } from 'components/Atoms';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import { SyncStatusIcon } from 'components/Icons';

import * as ActionTypes from 'modules/Integrations/actionTypes/integrations.actionTypes';
import {
  getEmployerIntegrationDetails,
  getIntegrationFeatureConfig,
  updateIntegrationFeatureConfig,
  getEmployerSyncDetails,
  startEmployerSync,
  getLastSyncStatus,
  toggleDailySync,
  getSyncErrorFilers,
  getErrorList,
  downloadErrorReport,
  getIntegrationConfigDetails,
  getBambooHRConfigDetails,
  downloadFile,
  getHrisIntegrationFixedErrorsByEmployerId,
  fixInvalidMappings,
  getEmployerBenAdminDetails,
  downloadConfigFile
} from 'modules/Integrations/actions/integrationsAction';
import { permitIf } from 'components/hoc/Permit';
import { INTEGRATIONS_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { VENDOR_IDS, VENDOR_NAMES } from 'modules/Integrations/constants';

import EmployerConfigToggleV2 from 'components/EmployerConfigToggleV2/EmployerConfigToggleV2';
import PageActionButton from 'components/Buttons/PageActionButton';

import { Col, Row, UncontrolledTooltip } from 'components/Atoms';
import Icon from 'components/Icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import SyncStatusList from './components/SyncStatusList';
import DialogErrorDetails from 'modules/Integrations/components/DialogErrorDetails';
import * as IntegrationRoutes from '../../routes';

import './EmployerIntegrations.scss';

// constants
import {
  MODULE_HRIS_INTEGRATION_ENABLED,
  INTEGRATION_TYPES,
  MENU_ACTIONS,
  SYNC_FLOCK_IDS_DATE_FORMAT,
  getStatusText
} from 'modules/Integrations/constants';
import HRISResponse from 'modules/Employers/pages/EmployerDetails/containers/HRISResponse';

class EmployerIntegrations extends React.Component {
  constructor(props) {
    super(props);
    const {
      match,
      getIntegrationFeatureConfig,
      getLastSyncStatus,
      getEmployerIntegrationDetails,
      getEmployerBenAdminDetails
    } = props;
    const { employerId } = match.params;
    this.state = {
      employerId: employerId,
      bShowErrorDetailsDialog: false,
      selectedSync: null,
      step: 0,
      excludeTerminatedEmployees: true
    };
    this.SyncStatusList = React.createRef();

    getIntegrationFeatureConfig(employerId);
    getLastSyncStatus(employerId);
    getEmployerIntegrationDetails(employerId, 'HRIS');
    getEmployerBenAdminDetails(employerId, 'BEN_ADMIN');
  }

  componentDidMount() {
    const { match, getHrisIntegrationFixedErrorsByEmployerId } = this.props;
    const { employerId } = match.params;
    getHrisIntegrationFixedErrorsByEmployerId(employerId);
    this.getHrisIntegrationFixedErrors();
  }

  componentWillUnmount() {
    clearInterval(this._refreshTimer);
  }

  getHrisIntegrationFixedErrors() {
    const { match, getHrisIntegrationFixedErrorsByEmployerId } = this.props;
    const { employerId } = match.params;
    this._refreshTimer = setInterval(() => {
      getHrisIntegrationFixedErrorsByEmployerId(employerId);
    }, 60 * 1000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      requestStatus,
      serviceError,
      employerHRISConfigs,
      history,
      dailySyncStatus,
      configStatus,
      match,
      fileDownloadError,
      integrationFixedErrors
    } = this.props;
    const { step } = this.state;
    const { params } = match;
    const { brokerId } = params;
    const {
      requestStatus: prevRequestStatus,
      fileDownloadError: prevfileDownloadError
    } = prevProps;

    if (!prevfileDownloadError && fileDownloadError) {
      if (fileDownloadError.response.status === 404) {
        NotificationManager.error('Decrypted file not found');
      } else {
        NotificationManager.error('Unable to download the file');
      }
      return;
    }

    if (prevRequestStatus === requestStatus) return;

    if (serviceError) {
      // Generic Error
      NotificationManager.error(serviceError.message);
      return;
    }

    switch (requestStatus) {
      case ActionTypes.GET_EMPLOYER_INTEGRATION_DETAILS_SUCCESS: {
        if (employerHRISConfigs && employerHRISConfigs.length === 0) {
          history.push(
            `/brokers/${brokerId}/employers/${this.state.employerId}/integrations/new`
          );
        }
        return;
      }
      case ActionTypes.TOGGLE_DAILY_SYNC_SUCCESS: {
        const value = dailySyncStatus.enabled;
        NotificationManager.success(
          `Daily Sync ${
            value === 'true' || value === true ? 'Enabled' : 'Disabled'
          } Successfully.`
        );
        return;
      }

      case ActionTypes.UPDATE_INTEGRATIONS_FEATURE_CONF_SUCCESS: {
        const value = configStatus.value;
        NotificationManager.success(
          `Feature ${value === 'true' ? 'Enabled' : 'Disabled'} Successfully.`
        );
        return;
      }
      case ActionTypes.START_EMPLOYER_SYNC_SUCCESS: {
        NotificationManager.success(`Sync started Successfully`);
        this.SyncStatusList.current.reloadList();
        return;
      }
      case ActionTypes.DOWNLOAD_ERROR_REPORT_SUCCESS: {
        NotificationManager.success(
          `Error Report File downloaded successfully.`
        );
        return;
      }
      case ActionTypes.GET_INTEGRATION_CONFIG_DETAILS_SUCCESS: {
        const newParams = {
          ...match.params,
          edit: 'edit'
        };
        let vendorType = VENDOR_NAMES.WORKDAY;
        history.push(
          generatePath(IntegrationRoutes.EDIT_INTEGRATION, newParams),
          { step: step, vendorType: vendorType }
        );
        break;
      }
      case ActionTypes.GET_BAMBOO_HR_CONFIG_DETAILS_SUCCESS: {
        const newParams = {
          ...match.params,
          edit: 'edit'
        };
        let vendorType = VENDOR_NAMES.BAMBOO_HR;
        history.push(
          generatePath(IntegrationRoutes.EDIT_INTEGRATION, newParams),
          { step: step, vendorType: vendorType }
        );
        break;
      }
      default:
        break;
    }

    if (
      integrationFixedErrors &&
      (integrationFixedErrors.status === 'SUCCESS' ||
        integrationFixedErrors.status === 'FAILED')
    ) {
      clearInterval(this._refreshTimer);
    }
  }
  switchFeatureConfig = (value) => {
    const { configStatus, updateIntegrationFeatureConfig } = this.props;

    const newValue =
      configStatus.value === true || configStatus.value === 'true'
        ? 'false'
        : 'true';
    const updatedConfig = { ...configStatus, value: newValue };
    updateIntegrationFeatureConfig(updatedConfig);
  };

  syncNow = () => {
    const { employerContext, employerHRISConfigs } = this.props;
    const { employerId } = this.state;
    const employerIntegrationConfig = _.get(
      employerContext,
      `integrations.${employerId}`
    );
    const hrisVendor = _.find(employerHRISConfigs, (config) => config.vendor);
    const hrisIntegrationDetails = employerIntegrationConfig.find(
      ({ type }) => type === INTEGRATION_TYPES.HRIS
    );
    this.props.startEmployerSync(employerId, {
      source: hrisIntegrationDetails
        ? hrisIntegrationDetails.vendor
        : hrisVendor.vendor
    });
  };

  toggleErrorDetailsDialog = () => {
    const data = this.state.bShowErrorDetailsDialog
      ? null
      : this.state.selectedSync;
    this.setState({
      bShowErrorDetailsDialog: !this.state.bShowErrorDetailsDialog,
      selectedSync: data,
      excludeTerminatedEmployees: true
    });
  };

  handleMenuClick = (action, data) => {
    switch (action) {
      case MENU_ACTIONS.VIEW:
        this.showErrorDetailsDialog(data);
        break;
      case MENU_ACTIONS.DOWNLOAD:
        this.downloadErrorReport(data);
        break;
      case MENU_ACTIONS.DOWNLOAD_FILE:
        this.downloadFile(data);
        break;
      case MENU_ACTIONS.DOWNLOAD_CONFIG_FILE:
        this.downloadConfigFile(data);
        break;
      default:
        break;
    }
  };

  showErrorDetailsDialog = (data) => {
    const { employerId, id: syncDetailsId } = data;
    const { excludeTerminatedEmployees } = this.state;
    this.props.getSyncErrorFilers(
      employerId,
      syncDetailsId,
      excludeTerminatedEmployees
    );
    this.setState({
      bShowErrorDetailsDialog: true,
      selectedSync: data
    });
  };

  downloadErrorReport = (syncDetails) => {
    const { id: syncDetailsId, employerId } = syncDetails;
    const { excludeTerminatedEmployees } = this.state;
    this.props.downloadErrorReport(
      employerId,
      syncDetailsId,
      excludeTerminatedEmployees
    );
  };

  downloadFile = (syncDetails) => {
    const { id: syncDetailsId, employerId } = syncDetails;
    this.props.downloadFile(employerId, syncDetailsId);
  };

  downloadConfigFile = (syncDetails) => {
    const { id: syncDetailsId, employerId } = syncDetails;
    this.props.downloadConfigFile(employerId, syncDetailsId);
  };

  editIntegrationConfig = () => {
    const {
      match,
      getIntegrationConfigDetails,
      getBambooHRConfigDetails,
      employerHRISConfigs
    } = this.props;
    const vendor =
      employerHRISConfigs &&
      employerHRISConfigs[0] &&
      employerHRISConfigs[0].vendor
        ? employerHRISConfigs[0].vendor
        : '';
    const { employerId } = match.params;
    this.setState({ step: 2 });
    if (vendor === VENDOR_IDS.WORKDAY) {
      getIntegrationConfigDetails(employerId, 'HRIS');
    } else if (vendor === VENDOR_IDS.BAMBOO_HR) {
      getBambooHRConfigDetails(employerId);
    }
  };

  editWorkdayFieldMappings = () => {
    const {
      match,
      getIntegrationConfigDetails,
      getBambooHRConfigDetails,
      employerHRISConfigs
    } = this.props;

    const { employerId } = match.params;
    const vendor =
      employerHRISConfigs &&
      employerHRISConfigs[0] &&
      employerHRISConfigs[0].vendor
        ? employerHRISConfigs[0].vendor
        : '';

    this.setState({ step: 3 });
    if (vendor === VENDOR_IDS.WORKDAY) {
      getIntegrationConfigDetails(employerId, 'HRIS');
    } else if (vendor === VENDOR_IDS.BAMBOO_HR) {
      getBambooHRConfigDetails(employerId);
    }
  };

  openHrisResponse = () => {
    this.setState({ isHrisResponseModalOpen: !this.isHrisResponseModalOpen });
  };

  toggleHrisResponseModal = () => {
    this.setState({
      isHrisResponseModalOpen: !this.state.isHrisResponseModalOpen
    });
  };

  fixInvalidMappings = () => {
    const {
      match,
      fixInvalidMappings,
      getHrisIntegrationFixedErrorsByEmployerId
    } = this.props;
    const { employerId } = match.params;
    clearInterval(this._refreshTimer); //remove existing executer
    this.getHrisIntegrationFixedErrors();
    fixInvalidMappings(employerId);
    setTimeout(() => getHrisIntegrationFixedErrorsByEmployerId(employerId), 0);
  };

  updateTerminatedEmployeeSelection = (includeTerminatedEmployees) => {
    if (includeTerminatedEmployees) {
      this.setState({ excludeTerminatedEmployees: false });
      this.updateFilters(!includeTerminatedEmployees);
    } else {
      this.setState({ excludeTerminatedEmployees: true });
      this.updateFilters(!includeTerminatedEmployees);
    }
  };

  updateFilters = (excludeTerminatedEmployees) => {
    const { selectedSync } = this.state;
    const { employerId, id: syncDetailsId } = selectedSync;
    this.props.getSyncErrorFilers(
      employerId,
      syncDetailsId,
      excludeTerminatedEmployees
    );
  };

  render() {
    const {
      isLoading,
      requestStatus,
      configStatus,
      employerIntegrations,
      dailySyncStatus,
      toggleDailySync,
      syncErrors,
      syncErrorFilters,
      getEmployerSyncDetails,
      getErrorList,
      employerHRISConfigs,
      integrationFixedErrors,
      employerBenAdminConfigs
    } = this.props;

    if (
      isLoading &&
      requestStatus === ActionTypes.GET_EMPLOYER_INTEGRATION_DETAILS
    ) {
      return <Loader />;
    }
    const {
      employerId,
      bShowErrorDetailsDialog,
      selectedSync,
      excludeTerminatedEmployees
    } = this.state;

    const enabled =
      configStatus.value === 'true' || configStatus.value === true;

    let isDailySyncLoading =
      isLoading && requestStatus === ActionTypes.TOGGLE_DAILY_SYNC;

    const {
      enabled: dailySyncEnabled,
      lastScheduleSyncDetail
    } = dailySyncStatus;

    const SecuredEmployerConfigToggle = permitIf(
      EmployerConfigToggleV2,
      INTEGRATIONS_FEATURE_ROLE_MAPPING.toggleFeature
    );

    const SecuredSyncNowButton = permitIf(
      PageActionButton,
      INTEGRATIONS_FEATURE_ROLE_MAPPING.syncNow
    );

    const SecuredDownloadButton = permitIf(
      PageActionButton,
      INTEGRATIONS_FEATURE_ROLE_MAPPING.downloadErrorReport
    );

    const SecuredDailySyncToggle = permitIf(
      DailySyncSwitch,
      INTEGRATIONS_FEATURE_ROLE_MAPPING.dailySync
    );

    const SecuredEditButton = permitIf(
      Button,
      INTEGRATIONS_FEATURE_ROLE_MAPPING.editConfig
    );
    let isHRIS =
      !_.isEmpty(employerHRISConfigs) &&
      (employerHRISConfigs[0].vendor === VENDOR_IDS.BAMBOO_HR ||
        employerHRISConfigs[0].vendor === VENDOR_IDS.WORKDAY);
    let isBenAdmin =
      !_.isEmpty(employerBenAdminConfigs) &&
      (employerBenAdminConfigs[0].type === VENDOR_IDS.BEN_ADMIN ||
        employerBenAdminConfigs[0].vendor === VENDOR_IDS.FLOCK);
    return (
      <ApplicationWrapper>
        <HeaderContainer title="HRIS Integrations" />
        <Row className="page-employer-integration">
          <Col className="page-content">
            <ContentContainer>
              <SecuredEmployerConfigToggle
                employerId={employerId}
                moduleEnabled={MODULE_HRIS_INTEGRATION_ENABLED}
                message="HRIS"
                config={configStatus}
                switchCallBack={this.switchFeatureConfig}
              />
            </ContentContainer>
            <ContentContainer>
              <SyncStatusList
                ref={this.SyncStatusList}
                data={employerIntegrations}
                employerId={employerId}
                getEmployerSyncDetails={getEmployerSyncDetails}
                menuClick={this.handleMenuClick}
              />
            </ContentContainer>
          </Col>
          <Col className="page-actions">
            <h5 className="panel-title">Employer Sync</h5>
            <SecuredSyncNowButton onClick={this.syncNow} disabled={!enabled}>
              Sync Now
            </SecuredSyncNowButton>
            {enabled && (
              <div>
                <SecuredDailySyncToggle
                  message={`Are you sure you want to ${
                    dailySyncEnabled ? 'disable' : 'enable'
                  } daily syncing?`}
                  enabled={dailySyncEnabled}
                  syncStatus={lastScheduleSyncDetail}
                  inProgress={isDailySyncLoading}
                  toggleEnabled={() => {
                    toggleDailySync(employerId, !dailySyncEnabled);
                  }}
                />
                {employerHRISConfigs &&
                  employerHRISConfigs[0] &&
                  (employerHRISConfigs[0].vendor === VENDOR_IDS.WORKDAY ||
                    employerHRISConfigs[0].vendor === VENDOR_IDS.BAMBOO_HR) && (
                    <div>
                      <SecuredEditButton
                        color="primary"
                        size="lg"
                        className="form-element"
                        outline
                        onClick={this.editIntegrationConfig}
                      >
                        Edit Configurations
                      </SecuredEditButton>

                      <SecuredEditButton
                        className="form-element edit-config-button"
                        outline
                        color="primary"
                        size="lg"
                        onClick={this.editWorkdayFieldMappings}
                      >
                        Edit Mappings
                      </SecuredEditButton>
                    </div>
                  )}
              </div>
            )}
            <>
              <div className="hris">
                <SecuredDownloadButton
                  onClick={this.toggleHrisResponseModal}
                  disabled={!isHRIS}
                >
                  Download HRIS Responses
                </SecuredDownloadButton>
              </div>
            </>
            {isBenAdmin && (
              <>
                <div className="fix-integration-errors">
                  <SecuredSyncNowButton
                    onClick={this.fixInvalidMappings}
                    disabled={
                      integrationFixedErrors != null &&
                      integrationFixedErrors.status === 'SYNCING'
                    }
                  >
                    Update Employee IDs
                  </SecuredSyncNowButton>
                  {integrationFixedErrors != null &&
                    integrationFixedErrors.syncedAt &&
                    integrationFixedErrors.status && (
                      <div className="sync-flock-ids">
                        <SyncStatusIcon
                          syncStatus={integrationFixedErrors.status}
                        />
                        <span>
                          <div>
                            {moment(integrationFixedErrors.syncedAt)
                              .local()
                              .format(SYNC_FLOCK_IDS_DATE_FORMAT)}
                          </div>
                          <div className="flock-sync-status">
                            <b>
                              {getStatusText(integrationFixedErrors.status)}
                            </b>
                            {integrationFixedErrors.status !== 'SYNCING' && (
                              <>
                                <div id="flock-info" className="flock-info">
                                  <Icon icon={faInfoCircle} />
                                </div>
                                <UncontrolledTooltip
                                  hideArrow
                                  placement="right"
                                  target="flock-info"
                                  autohide={false}
                                >
                                  <span className="bold">
                                    Updated Employees:
                                  </span>{' '}
                                  {integrationFixedErrors.mappedEmployeesCount !==
                                  null
                                    ? integrationFixedErrors.mappedEmployeesCount
                                    : 'N/A'}
                                  <br />
                                  <span className="bold">
                                    Update Failed Employees:
                                  </span>{' '}
                                  {integrationFixedErrors.mappedFailedEmployeesCount !==
                                  null
                                    ? integrationFixedErrors.mappedFailedEmployeesCount
                                    : 'N/A'}
                                </UncontrolledTooltip>
                              </>
                            )}
                          </div>
                        </span>
                      </div>
                    )}
                </div>
              </>
            )}
          </Col>
        </Row>
        <DialogErrorDetails
          isOpen={bShowErrorDetailsDialog}
          toggle={this.toggleErrorDetailsDialog}
          data={syncErrors}
          employerId={employerId}
          selectedSync={selectedSync}
          getErrorList={getErrorList}
          syncErrorFilters={syncErrorFilters}
          downloadErrorReport={this.downloadErrorReport}
          excludeTerminatedEmployees={excludeTerminatedEmployees}
          updateTerminatedEmployeeSelection={this.updateTerminatedEmployeeSelection}
        />
        <HRISResponse
          isOpen={this.state.isHrisResponseModalOpen}
          employerId={employerId}
          toggle={this.toggleHrisResponseModal}
        />
      </ApplicationWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getEmployerIntegrationDetails: (employerId, type) =>
    dispatch(getEmployerIntegrationDetails(employerId, type)),
  getEmployerSyncDetails: (config) => dispatch(getEmployerSyncDetails(config)),
  getIntegrationFeatureConfig: (employerId) =>
    dispatch(getIntegrationFeatureConfig(employerId)),
  updateIntegrationFeatureConfig: (config, value) =>
    dispatch(updateIntegrationFeatureConfig(config, value)),
  startEmployerSync: (employerId, config) =>
    dispatch(startEmployerSync(employerId, config)),
  getLastSyncStatus: (employerId) => dispatch(getLastSyncStatus(employerId)),
  toggleDailySync: (employerId, config) =>
    dispatch(toggleDailySync(employerId, config)),
  getSyncErrorFilers: (employerId, syncDetailsId, excludeTerminatedEmployees) =>
    dispatch(getSyncErrorFilers(employerId, syncDetailsId, excludeTerminatedEmployees)),
  getErrorList: (config) => dispatch(getErrorList(config)),
  downloadErrorReport: (employerId, syncDetailsId, excludeTerminatedEmployees) =>
    dispatch(downloadErrorReport(employerId, syncDetailsId, excludeTerminatedEmployees)),
  getIntegrationConfigDetails: (employerId, type) =>
    dispatch(getIntegrationConfigDetails(employerId, type)),
  getBambooHRConfigDetails: (employerId) =>
    dispatch(getBambooHRConfigDetails(employerId)),
  downloadFile: (employerId, syncDetailsId) =>
    dispatch(downloadFile(employerId, syncDetailsId)),
  getHrisIntegrationFixedErrorsByEmployerId: (employerId) =>
    dispatch(getHrisIntegrationFixedErrorsByEmployerId(employerId)),
  fixInvalidMappings: (employerId) => dispatch(fixInvalidMappings(employerId)),
  getEmployerBenAdminDetails: (employerId, type) =>
    dispatch(getEmployerBenAdminDetails(employerId, type)),
  downloadConfigFile: (employerId, syncDetailsId) =>
    dispatch(downloadConfigFile(employerId, syncDetailsId))
});

const mapStateToProps = (state) => {
  const {
    requestStatus,
    serviceError,
    isLoading,
    configStatus,
    employerHRISConfigs,
    employerIntegrations,
    dailySyncStatus,
    syncErrorFilters,
    syncErrors,
    integrationConfigDetails,
    bambooConfigDetails,
    fileDownloadError,
    integrationFixedErrors,
    syncJobFlock,
    employerBenAdminConfigs
  } = state.integrationsReducer;
  const { employerContext } = state.AppBase.contextReducer;
  return {
    requestStatus,
    serviceError,
    isLoading,
    configStatus,
    employerHRISConfigs,
    employerIntegrations,
    dailySyncStatus,
    syncErrorFilters,
    syncErrors,
    integrationConfigDetails,
    bambooConfigDetails,
    employerContext,
    fileDownloadError,
    integrationFixedErrors: integrationFixedErrors.data,
    integrationFixedErrorsLoading: integrationFixedErrors.inProgress,
    integrationFixedErrorsError: integrationFixedErrors.error,
    syncJobFlock: syncJobFlock.data,
    syncJobFlockProcessing: syncJobFlock.inProgress,
    syncJobFlockError: syncJobFlock.error,
    employerBenAdminConfigs
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerIntegrations);
