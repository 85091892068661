/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import * as _ from 'lodash';
import { Button, Row, Col, Alert } from 'components/Atoms';
import Icon from 'components/Icons';
import { TextFormGroup } from 'components/DataForm';

import '../DialogIntegrationDetails.scss';

// constants
import { ERROR_MSGS } from 'modules/Integrations/constants';

export const CustomFieldRow = ({
  index,
  lumityField,
  vendorName,
  lfiCustomFieldName = '',
  removeFieldRow,
  updateCustomField,
  errors,
  fieldsCount
}) => {
  const fieldErrors = errors[index];
  return (
    <div className="custom-field-row">
      <Button color="link" onClick={removeFieldRow} className="remove-icon">
        <Icon icon="minus-circle" />
      </Button>
      <Row>
        <Col>
          <TextFormGroup
            labelDisplay="Lumity Custom Field"
            inputName="lumityField"
            inputValue={lumityField}
            onChange={updateCustomField}
            placeholder="Enter Custom Field"
            isInvalid={!!(fieldErrors && fieldErrors['lumityField'])}
          />
        </Col>
        <Col>
          <TextFormGroup
            labelDisplay={`${vendorName} Custom Field`}
            inputName="lfiCustomFieldName"
            inputValue={lfiCustomFieldName}
            onChange={updateCustomField}
            placeholder="Enter Vendor Custom Field"
            isInvalid={!!(fieldErrors && fieldErrors['lfiCustomFieldName'])}
          />
        </Col>
      </Row>
    </div>
  );
};
export class LFICustomFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // To show one row with empty value
      customFields: props.selectedCustomFields || [
        {
          lfiCustomFieldName: '',
          lumityField: ''
        }
      ],
      errors: {},
      errorMsgs: []
    };
  }

  addCustomField = () => {
    this.setState({
      customFields: [
        ...this.state.customFields,
        {
          lfiCustomFieldName: '',
          lumityField: ''
        }
      ]
    });
  };

  removeCustomField = (index) => (e) => {
    const { customFields, errors } = this.state;
    const updatedCustomFields = [...customFields];
    const updatedErrors = { ...errors };
    updatedCustomFields.splice(index, 1);
    if (updatedErrors[index]) {
      delete updatedErrors[index];
    }
    if (customFields.length === 1) {
      this.setState({
        customFields: [{}],
        selectedFields: []
      });
      return;
    }
    this.setState({
      customFields: updatedCustomFields,
      errors: updatedErrors
    });
  };

  updateCustomField = (index) => (e) => {
    let { customFields, errors } = this.state;
    const updatedCustomFields = [...customFields];
    const customField = updatedCustomFields[index];
    const { name, value } = e.target;
    customField[name] = value;
    if (value && errors[index] && errors[index][name]) {
      delete errors[index][name];
      if (_.isEmpty(errors[index])) delete errors[index];
    }
    this.setState({
      customFields: updatedCustomFields
    });
  };

  addError(index, errorFieldName, errors) {
    errors = {
      ...errors,
      [index]: {
        ...errors[index],
        [errorFieldName]: true
      }
    };
    return errors;
  }

  onNext = () => {
    const { customFields } = this.state;
    let customFieldsConfig = [];
    let lfiCustomFields = [];
    let lumityFields = [];
    let errors = {};
    let errorMsgs = [];
    customFields.forEach(({ lfiCustomFieldName, lumityField }, index) => {
      if (_.isEmpty(lfiCustomFieldName) && _.isEmpty(lumityField)) return false;
      if (_.isEmpty(lfiCustomFieldName)) {
        errors = this.addError(index, 'lfiCustomFieldName', errors);
        errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
      } else {
        if (lfiCustomFields.includes(lfiCustomFieldName)) {
          errors = this.addError(index, 'lfiCustomFieldName', errors);
          errorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
        } else {
          lfiCustomFields.push(lfiCustomFieldName);
        }
      }
      if (_.isEmpty(lumityField)) {
        errors = this.addError(index, 'lumityField', errors);
        errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
      } else {
        if (lumityFields.includes(lumityField)) {
          errors = this.addError(index, 'lumityField', errors);
          errorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
        } else {
          lumityFields.push(lumityField);
        }
      }

      customFieldsConfig.push({
        lumityField,
        lfiCustomFieldName
      });
    });
    if (_.isEmpty(errors)) {
      // set to null if it is empty array
      if (_.isEmpty(customFieldsConfig)) {
        customFieldsConfig = null;
      }
      this.props.onNext(customFieldsConfig);
    } else {
      this.setState({
        errors,
        errorMsgs: _.uniq(errorMsgs)
      });
    }
  };

  renderCustomFields() {
    const { customFields, errors } = this.state;
    const { selectedVendor } = this.props;
    const { name: vendorName } = selectedVendor;
    const customFieldRows = customFields.map(
      ({ lumityField = '', lfiCustomFieldName = '' }, index) => {
        return (
          <CustomFieldRow
            key={index}
            index={index}
            lumityField={lumityField}
            vendorName={vendorName}
            lfiCustomFieldName={lfiCustomFieldName}
            removeFieldRow={this.removeCustomField(index)}
            updateCustomField={this.updateCustomField(index)}
            errors={errors}
            fieldsCount={customFields.length}
          />
        );
      }
    );
    return customFieldRows;
  }

  render() {
    const { customFields, selectedVendor, onPrevious } = this.props;
    const { name: vendorName } = selectedVendor;
    const { errors, errorMsgs } = this.state;
    return (
      <div className="step-container custom-fields-container">
        <div className="body">
          <p className="subtitle">{vendorName}</p>
          {!_.isEmpty(errors) &&
            errorMsgs.map((errorMsg, index) => (
              <Alert key={index} color="danger" className="error-alert">
                {errorMsg}
              </Alert>
            ))}
          {this.renderCustomFields(customFields)}
          <Row>
            <Button color="link" onClick={this.addCustomField}>
              +Add Custom Field
            </Button>
          </Row>
        </div>
        <Row className="action-btns">
          <Col>
            <Button
              className="form-element"
              outline
              color="primary"
              size="lg"
              onClick={() => onPrevious(this.state.customFields)}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              className="form-element"
              color="primary"
              size="lg"
              onClick={this.onNext}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
