/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/currentPlansToggles.actionTypes';

const initialState = {
  configStatus: {
    id: null,
    value: 'false',
    lastUpdatedTs: null
  },
  requestStatus: '',
  serviceError: '',
  benefitGroupConfig: {
    id: null,
    benefitGroupName: '',
    enabled: 'false'
  },
  benefitGroupConfigError: null,
  benefitGroupLoading: null,
  benefitGroupConfigList: [],
  benefitGroupConfiglistError: null,
  benefitGroupConfigListLoading: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CURRENT_PLANS_FEATURE_CONFIGURATION:
    case ActionTypes.CREATE_CURRENT_PLANS_FEATURE_CONFIGURATION:
    case ActionTypes.UPDATE_CURRENT_PLANS_FEATURE_CONFIGURATION:
      return {
        ...state,
        isLoading: true,
        serviceError: '',
        requestStatus: action.type
      };

    case ActionTypes.GET_CURRENT_PLANS_FEATURE_CONFIGURATION_ERROR:
    case ActionTypes.CREATE_CURRENT_PLANS_FEATURE_CONFIGURATION_ERROR:
    case ActionTypes.UPDATE_CURRENT_PLANS_FEATURE_CONFIGURATION_ERROR:
      return {
        ...state,
        isLoading: false,
        requestStatus: action.type,
        serviceError: action.payload
      };

    case ActionTypes.GET_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS:
    case ActionTypes.CREATE_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS:   
    case ActionTypes.UPDATE_CURRENT_PLANS_FEATURE_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        configStatus: action.payload
      };
    }
    case ActionTypes.GET_BENEFIT_GROUPS_CONFIGURATION:
    case ActionTypes.CREATE_BENEFIT_GROUPS_CONFIGURATION:
    case ActionTypes.UPDATE_BENEFIT_GROUPS_CONFIGURATION:
      return {
        ...state,
        benefitGroupConfigError: '',
        benefitGroupLoading: true
      };

    case ActionTypes.GET_BENEFIT_GROUPS_CONFIGURATION_ERROR:
    case ActionTypes.CREATE_BENEFIT_GROUPS_CONFIGURATION_ERROR:
    case ActionTypes.UPDATE_BENEFIT_GROUPS_CONFIGURATION_ERROR:
      return {
        ...state,
        benefitGroupConfigError: action.payload,
        benefitGroupLoading: false,
      };

    case ActionTypes.GET_BENEFIT_GROUPS_CONFIGURATION_SUCCESS:
    case ActionTypes.CREATE_BENEFIT_GROUPS_CONFIGURATION_SUCCESS:
    case ActionTypes.UPDATE_BENEFIT_GROUPS_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        benefitGroupLoading: false,
        benefitGroupConfigError: '',
        benefitGroupConfig: action.payload,
        requestStatus: action.type
      };
    }
    case ActionTypes.GET_BENEFIT_GROUP_CONFIGURATIONS_LIST:
      return {
        ...state,
        benefitGroupConfigListError: '',
        benefitGroupConfigListLoading: true
      };
      case ActionTypes.GET_BENEFIT_GROUP_CONFIGURATIONS_LIST_ERROR:
        return {
          ...state,
          benefitGroupConfigListError: action.payload,
          benefitGroupconfigListLoading: false
        };
        case ActionTypes.GET_BENEFIT_GROUP_CONFIGURATIONS_LIST_SUCCESS: {
          return {
            ...state,
            benefitGroupconfigListLoading: false,
            benefitGroupConfigListError: '',
            benefitGroupConfigList: action.payload
          };
        }

    default:
      return {
        ...state
      };
  }
};

