/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Button, Row, Col, Input, FormGroup, Alert } from 'components/Atoms';
import Icon from 'components/Icons';
import { TextFormGroup } from 'components/DataForm';

import './DialogIntegrationDetails.scss';
import {
  BAMBOO_HR_CONFIG_ERROR_LABEL_CODE_MAP,
  VENDORS
} from 'modules/Integrations/constants';

// actions
import * as ActionTypes from 'modules/Integrations/actionTypes/integrations.actionTypes';
import { getBHRSetupDetails } from 'modules/Integrations/actions/integrationsAction';

export const CustomFieldRow = ({
  index,
  customFieldValue,
  vendorName,
  vendorFieldList,
  vendorField = '',
  selectedFields,
  removeFieldRow,
  updateCustomField,
  errors,
  fieldsCount
}) => {
  const fieldErrors = errors[index];
  return (
    <div className="custom-field-row">
      <Button color="link" onClick={removeFieldRow} className="remove-icon">
        <Icon icon="minus-circle" />
      </Button>
      <Row>
        <Col>
          <TextFormGroup
            labelDisplay={`${vendorName} Custom Field`}
            inputName="customFieldValue"
            inputValue={customFieldValue}
            onChange={updateCustomField}
            placeholder="Enter Custom Field"
            isInvalid={!!(fieldErrors && fieldErrors['customFieldValue'])}
          />
        </Col>
        <Col>
          <FormGroup>
            <label>
              <b>{vendorName} Field</b>
            </label>
            <Input
              type="select"
              name="vendorField"
              value={vendorField}
              onChange={updateCustomField}
              invalid={!!fieldErrors && fieldErrors['vendorField']}
            >
              <option key="select" value="">
                Select Option
              </option>
              {vendorFieldList.map((field) => {
                return (
                  <option
                    key={field}
                    value={field}
                    disabled={selectedFields.includes(field)}
                  >
                    {field}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};
class CustomFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // To show one row with empty value
      customFields: props.selectedCustomFields || [
        {
          vendorField: '',
          customFieldValue: ''
        }
      ],
      vendorFieldList: props.vendorFieldList || [],
      selectedFields:
        CustomFields.getSelectedFields(props.selectedCustomFields) || [],
      errors: [],
      onNextCall: false
    };
  }

  static getSelectedFields(selectedCustomFields) {
    if (selectedCustomFields) {
      return selectedCustomFields.map(({ vendorField }) => vendorField);
    }
    return false;
  }

  componentDidMount() {
    this.props.getVendorCustomFields();
  }

  componentDidUpdate(prevProps) {
    const { requestStatus, onNext, serviceError } = this.props;
    const { customFields, onNextCall } = this.state;
    if (requestStatus === prevProps.requestStatus) return;
    switch (requestStatus) {
      case ActionTypes.GET_BHR_SETUP_DETAILS_SUCCESS: {
        if (onNextCall) {
          onNext(customFields);
        }
        break;
      }
      case ActionTypes.GET_BHR_SETUP_DETAILS_ERROR: {
        const errorCode =
          serviceError.response &&
          serviceError.response.data &&
          serviceError.response.data.code;
        this.setState({
          error:
            BAMBOO_HR_CONFIG_ERROR_LABEL_CODE_MAP[errorCode] ||
            'Invalid Configuration'
        });
        break;
      }
      default:
        break;
    }
  }

  addCustomField = () => {
    this.setState({
      customFields: [
        ...this.state.customFields,
        {
          vendorField: '',
          customFieldValue: ''
        }
      ]
    });
  };

  removeCustomField = (index) => (e) => {
    const { customFields, selectedFields, errors } = this.state;
    const updatedCustomFields = [...customFields];
    const updatedSelectedFields = selectedFields.filter(
      (field) => field !== customFields[index].vendorField
    );
    if (customFields.length === 1) {
      this.setState({
        customFields: [{}],
        selectedFields: []
      });
      return;
    }
    updatedCustomFields.splice(index, 1);
    if (errors[index]) {
      delete errors[index];
      if (_.isEmpty(errors[index])) delete errors[index];
    }
    this.setState({
      customFields: updatedCustomFields,
      selectedFields: updatedSelectedFields
    });
  };

  updateCustomField = (index) => (e) => {
    let { customFields, selectedFields, errors } = this.state;
    let updatedSelectedFields = [...selectedFields];
    const updatedCustomFields = [...customFields];
    const customField = updatedCustomFields[index];
    const { name, value } = e.target;
    if (name === 'vendorField') {
      if (selectedFields.includes(customField.vendorField)) {
        updatedSelectedFields = updatedSelectedFields.filter(
          (field) => field !== customField.vendorField
        );
      }
      if (value !== '') {
        updatedSelectedFields.push(value);
      }
    }
    customField[name] = value;
    if (value && errors[index] && errors[index][name]) {
      delete errors[index][name];
      if (_.isEmpty(errors[index])) delete errors[index];
    }
    this.setState({
      customFields: updatedCustomFields,
      selectedFields: updatedSelectedFields
    });
  };

  onNext = () => {
    const { customFields } = this.state;
    const {
      selectedVendor,
      getBHRSetupDetails,
      vendorConfiguration
    } = this.props;
    let customFieldsConfig = [];
    let errors = {};
    if (selectedVendor.name === VENDORS.BAMBOO_HR) {
      customFields.forEach(({ vendorField, customFieldValue }, index) => {
        if (_.isEmpty(vendorField) && _.isEmpty(customFieldValue)) return false;
        if (_.isEmpty(vendorField)) {
          errors = {
            ...errors,
            [index]: {
              ...errors[index],
              vendorField: true
            }
          };
        }
        if (_.isEmpty(customFieldValue)) {
          errors = {
            ...errors,
            [index]: {
              ...errors[index],
              customFieldValue: true
            }
          };
        }

        let trimmedCustomFieldValue = _.trim(customFieldValue);
        customFieldsConfig.push({
          customFieldValue: trimmedCustomFieldValue,
          vendorField
        });
      });
    }
    if (_.isEmpty(errors)) {
      // set to null if it is empty array
      if (_.isEmpty(customFieldsConfig)) {
        customFieldsConfig = null;
      }
      this.setState({ onNextCall: true });
      getBHRSetupDetails({
        ...vendorConfiguration,
        customFields:
          (customFieldsConfig &&
            customFieldsConfig.map(({ customFieldValue, vendorField }) => ({
              bambooHrCustomFieldName: customFieldValue,
              bambooHrField: vendorField
            }))) ||
          []
      });
    } else {
      this.setState({
        errors
      });
    }
  };

  renderCustomFields() {
    const { customFields, selectedFields, errors } = this.state;
    const { selectedVendor, vendorFieldList } = this.props;
    const { name: vendorName } = selectedVendor;
    const customFieldRows = customFields.map(
      ({ customFieldValue = '', vendorField = '' }, index) => {
        return (
          <CustomFieldRow
            key={index}
            index={index}
            customFieldValue={customFieldValue}
            vendorName={vendorName}
            vendorField={vendorField}
            vendorFieldList={vendorFieldList}
            selectedFields={selectedFields}
            removeFieldRow={this.removeCustomField(index)}
            updateCustomField={this.updateCustomField(index)}
            errors={errors}
            fieldsCount={customFields.length}
          />
        );
      }
    );
    return customFieldRows;
  }

  render() {
    const { customFields, selectedVendor, onPrevious } = this.props;
    const { name: vendorName } = selectedVendor;
    const { errors } = this.state;
    return (
      <div className="step-container custom-fields-container">
        <div className="body">
          <p className="subtitle">{vendorName}</p>
          {!_.isEmpty(errors) && (
            <Alert color="danger" className="error-alert">
              Please enter required fields.
            </Alert>
          )}
          {this.renderCustomFields(customFields)}
          <Row>
            <Button color="link" onClick={this.addCustomField}>
              +Add Custom Field
            </Button>
          </Row>
        </div>
        <Row className="action-btns">
          <Col>
            <Button
              className="form-element"
              outline
              color="primary"
              size="lg"
              onClick={() => onPrevious(this.state.customFields)}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              className="form-element"
              color="primary"
              size="lg"
              onClick={this.onNext}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(
  ({ integrationsReducer }) => {
    const { requestStatus, serviceError } = integrationsReducer;
    return {
      requestStatus,
      serviceError
    };
  },
  {
    getBHRSetupDetails
  }
)(CustomFields);
