/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as EmployeePages from './index';

const EMPLOYEES_ROLES_PATH = '/brokers/:brokerId/employers/:employerId/roles';
const EMPLOYEES_LIST_PATH = '/brokers/:brokerId/employers/:employerId/employees';
const EMPLOYEES_DETAILS_PATH = '/brokers/:brokerId/employers/:employerId/employees/:employeeId';
const EMPLOYEES_EDIT_PATH = '/brokers/:brokerId/employers/:employerId/employees/:employeeId/edit';
const EMPLOYEES_CHANGE_USERNAME_PATH = '/brokers/:brokerId/employers/:employerId/employees/:employeeId/change-username';
const EMPLOYEES_CHANGE_SSN_PATH = '/brokers/:brokerId/employers/:employerId/employees/:employeeId/change-ssn';

const employeePathMapping = [
  { path: EMPLOYEES_ROLES_PATH, component: EmployeePages.EmployeeRolesList },
  { path: EMPLOYEES_LIST_PATH, component: EmployeePages.EmployeesList },
  { path: EMPLOYEES_DETAILS_PATH, component: EmployeePages.EmployeeInfo },
  { path: EMPLOYEES_EDIT_PATH, component: EmployeePages.EditEmployee },
  { path: EMPLOYEES_CHANGE_USERNAME_PATH, component: EmployeePages.EmployeeChangeUsername },
  { path: EMPLOYEES_CHANGE_SSN_PATH, component: EmployeePages.ChangeEmployeeSsn }
];

export default employeePathMapping;
export {
  EMPLOYEES_ROLES_PATH,
  EMPLOYEES_LIST_PATH,
  EMPLOYEES_DETAILS_PATH,
  EMPLOYEES_EDIT_PATH,
  EMPLOYEES_CHANGE_USERNAME_PATH,
  EMPLOYEES_CHANGE_SSN_PATH
};
