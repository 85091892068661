/**********************************************************************************************************************
 * Copyright (C) 2019-2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import NativeIssueService from 'services/NativeIssueService';
import * as ActionTypes from '../actionTypes/nativeIssue.actionTypes';

export const createIssue = (employerId, issueData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.CREATE_ISSUE_IN_PROGRESS });
      const response = await NativeIssueService.createCase(
        employerId,
        issueData
      );
      dispatch({
        type: ActionTypes.CREATE_ISSUE_COMPLETED,
        payload: { employerId: employerId, caseId: response.data.caseId }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CREATE_ISSUE_FAILED,
        payload: error.response
      });
    }
  };
};

export const updateIssueForm = (name, value) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_ISSUE_FORM,
      payload: { name: name, value: value }
    });
  };
};

export const addAttachment = (attachments) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_ISSUE_ATTACHMENT,
      payload: attachments
    });
  };
};

export const removeAttachment = (index, attachment) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.REMOVE_ISSUE_ATTACHMENT,
      payload: { index: index, attchement: attachment }
    });
  };
};

export const updateIssue = (employerId, issue) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.UPDATE_ISSUE_IN_PROGRESS
      });
      const response = await NativeIssueService.updateCase(employerId, issue);
      dispatch({
        type: ActionTypes.UPDATE_ISSUE_COMPLETED,
        payload: { employerId: employerId, caseId: response.data.caseId }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_ISSUE_FAILED,
        payload: error.response
      });
    }
  };
};

export const getFilteredIssueList = (
  page,
  size,
  sort,
  query,
  filters,
  orgId
) => {
  return async (dispatch) => {
    try {
      const tempFilters = [];
      Object.keys(filters).forEach((key) => {
        if (filters[key].length > 0) {
          tempFilters.push({ name: key, values: filters[key] });
        }
      });
      dispatch({ type: ActionTypes.GET_ISSUES_PAGE_IN_PROGRESS });
      const response = await NativeIssueService.filter(
        page,
        size,
        sort,
        query,
        tempFilters,
        orgId
      );
      dispatch({
        type: ActionTypes.GET_ISSUES_PAGE_COMPLETED,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_ISSUES_PAGE_FAILED,
        payload: error.response
      });
    }
  };
};

export const initializeCreateIssuePage = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.INITIALIZE_CREATE_ISSUE_PAGE });
  };
};

export const getERAdmins = (employerId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GET_ER_ADMINS_IN_PROGRESS });
      const response = await NativeIssueService.getErAdmins(employerId);
      dispatch({
        type: ActionTypes.GET_ER_ADMINS_COMPLETED,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_ER_ADMINS_FAILED,
        payload: error
      });
    }
  };
};

export const getIssueDetails = (employerId, issueId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_ISSUE_DETAILS_IN_PROGRESS,
        payload: employerId
      });
      const response = await NativeIssueService.getIssueById(
        employerId,
        issueId
      );
      dispatch({
        type: ActionTypes.GET_ISSUE_DETAILS_COMPLETED,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_ISSUE_DETAILS_FAILED,
        payload: error
      });
    }
  };
};

export const toggleWatcherOpt = (watcherId, issueId, isOptIn) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.TOGGLE_WATCHER_OPT_IN_PROGRESS });
      const response = await NativeIssueService.updateWatcherOpt(
        watcherId,
        issueId,
        isOptIn
      );
      dispatch({
        type: ActionTypes.TOGGLE_WATCHER_OPT_COMPLETED,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.TOGGLE_WATCHER_OPT_FAILED,
        payload: error.response
      });
    }
  };
};

export const createIssueComment = (employerId, comment, issueId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.CREATE_ISSUE_COMMENT_IN_PROGRESS,
        payload: comment
      });
      const response = await NativeIssueService.createCaseComment(
        employerId,
        comment,
        issueId
      );
      dispatch({
        type: ActionTypes.CREATE_ISSUE_COMMENT_COMPLETED,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CREATE_ISSUE_COMMENT_FAILED,
        payload: error
      });
    }
  };
};

export const handleFilterUpdate = (selected = [], name) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_ISSUE_FILTER,
      payload: { name: name, isReset: false, selected: selected }
    });
  };
};

export const clearFilter = (name, isReset) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_ISSUE_FILTER,
      payload: { name: name, isReset: isReset, selected: [] }
    });
  };
};

export const clearAllFilters = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_ALL_ISSUE_FILTERS
    });
  };
};

export const resolveIssue = (caseId, employerId, comment) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.RESOLVE_ISSUE_IN_PROGRESS });
      await NativeIssueService.resolveCase(caseId, employerId, comment);
      dispatch({ type: ActionTypes.RESOLVE_ISSUE_COMPLETED });
    } catch (error) {
      dispatch({
        type: ActionTypes.RESOLVE_ISSUE_FAILED,
        payload: error.response
      });
    }
  };
};

export const reopenIssue = (caseId, employerId, comment) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.REOPEN_ISSUE_IN_PROGRESS });
      await NativeIssueService.reopenCase(caseId, employerId, comment);
      dispatch({
        type: ActionTypes.REOPEN_ISSUE_COMPLETED
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.REOPEN_ISSUE_FAILED,
        payload: error.response
      });
    }
  };
};

export const updateSearchQuery = (text) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_SEARCH_QUERY, payload: text });
  };
};

export const getOriginalEmail = (caseId, commentId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_ORIGINAL_COMMENT_EMAIL_IN_PROGRESS
      });
      const response = await NativeIssueService.getOriginalEmail(
        caseId,
        commentId
      );
      dispatch({
        type: ActionTypes.GET_ORIGINAL_COMMENT_EMAIL_COMPLETED,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_ORIGINAL_COMMENT_EMAIL_FAILED,
        payload: error
      });
    }
  };
};
