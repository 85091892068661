/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import axios from 'axios';
import Util from '../util/apiUtil';

//configs
const baseURL = `${Util.baseApi}/${Util.version}/plans/insurances`;

export default {
  /**
   * get all life plans
   *
   * @returns {AxiosPromise<any>}
   */
  findAll(employerId, selectedPlanYearIds, page, size, sort, query) {
    return axios.get(
      `${baseURL}/roster?employer-id=${employerId}&plan-year-id=${selectedPlanYearIds}&${Util.getPagingQueryString(
        page,
        size,
        sort,
        query
      )}`
    );
  },

  /**
   * get all basic plan info
   *
   * @param employerId
   * @returns {AxiosPromise<any>}
   */
  findAllBasicPlanInfo(employerId, planYearId) {
    return axios.get(
      `${baseURL}/basic?employerId=${employerId}&planYearId=${planYearId}`
    );
  },

  /**
   * get all basic plan info inclduing archived plans
   *
   * @param employerId
   * @returns {AxiosPromise<any>}
   */
  findAllBasicPlanInfoWithArchived(employerId, planYearId) {
    return axios.get(
      `${baseURL}/basic?employerId=${employerId}&withArchived=true&planYearId=${planYearId}`
    );
  },

  /**
   * find plan by id.
   *
   * @param id - plan id.
   * @param benefitKind - benefit kind
   * @returns {AxiosPromise<any>}
   */
  findById(benefitKind, id) {
    const params = { benefitKind: benefitKind };
    return axios.get(`${baseURL}/${id}`, { params: params });
  },

  /**
   * create new life plan.
   *
   * @param payload - Plans Payload
   * @returns {AxiosPromise<any>}
   */
  create(payload) {
    return axios.post(`${baseURL}`, payload);
  },

  /**
   * update existing life plan.
   *
   * @param payload - Plans Payload
   * @returns {AxiosPromise<any>}
   */
  update(id, payload) {
    return axios.put(`${baseURL}/${id}`, payload);
  },

  /**
   * search all life plans for given employer ID and search query.
   * @param employerId - Employer ID.
   * @param query - Search Query.
   */
  search(employerId, query) {
    const params = {
      'employer-id': employerId
    };
    if (query) {
      params['name'] = encodeURIComponent(query);
    }

    return axios.get(baseURL, { params: params });
  },

  /**
   * delete existing plan.
   *
   * @param employerId 
   * @returns {AxiosPromise<any>}
   */
   delete(employerId, planMasterId, benefitKind) {
    return axios.delete(
      `${baseURL}/employers/${employerId}/plan?planMasterId=${planMasterId}&benefitKind=${benefitKind}`
    );
  },

  /**
   * validate before delete existing plan.
   *
   * @param employerId 
   * @returns {AxiosPromise<any>}
   */
  validate(employerId, planMasterId, benefitKind) {
    return axios.get(
      `${baseURL}/employers/${employerId}/plan/delete-validate?planMasterId=${planMasterId}&benefitKind=${benefitKind}`
    );
  }
};
