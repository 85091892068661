/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalHeader, ModalBody } from 'components/Atoms';

import './FullscreenModal.scss';

// TODO: Delete Popup in components/Popup once all pages have been refactored.
export default class FullscreenModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    allowKeyboardEscape: PropTypes.bool,
    closeText: PropTypes.string,
    modelClassName: PropTypes.string
  };

  static defaultProps = {
    title: '',
    subTitle: '',
    allowKeyboardEscape: false,
    closeText: 'close',
    modelClassName: 'fs-modal',
    isOpen: false
  };

  render() {
    const {
      className = '',
      children,
      isOpen,
      toggle,
      title,
      subTitle,
      allowKeyboardEscape,
      closeText,
      modelClassName,
      inProgress = false,
      ...rest
    } = this.props;

    return (
      <Modal
        modalClassName={`fs-modal ${modelClassName}`}
        className={className}
        backdrop="static"
        isOpen={isOpen}
        toggle={toggle}
        keyboard={allowKeyboardEscape}
        {...rest}
      >
        <ModalHeader
          close={
            <CloseButton
              closeText={closeText}
              onClick={toggle}
              inProgress={inProgress}
            />
          }
        >
          <div className="title">{title}</div>
          {subTitle && <div className="sub-title">{subTitle}</div>}
        </ModalHeader>
        <div className="scroll-wrapper">
          <ModalBody>{children}</ModalBody>
        </div>
      </Modal>
    );
  }
}

const CloseButton = (props) => {
  const { closeText, onClick, inProgress } = props;
  return (
    <span
      className={
        inProgress ? 'fs-modal-close-button disabled' : 'fs-modal-close-button'
      }
      onClick={inProgress ? undefined : onClick}
    >
      &times;
      <div className="fs-modal-close-button-text">{closeText}</div>
    </span>
  );
};
