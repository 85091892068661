/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import {
  createBenGuide,
  getAllPlans,
  getBenGuideById,
  initBenGuide,
  initBenGuideValidations,
  updateBenGuide,
  updateBenGuideById,
  updateBenGuideValidations,
  validateBenGuideName,
  getAvaliableTaxAdvantagedBenefitKinds,
  resetBenguidePlans,
  cloneBenguide
} from '../../actions/benGuidePlanAction';
import { getPlanYears } from 'modules/Employers/actions/planYearActions';
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from 'components/Atoms';
import NotificationManager from 'components/Notifications';

import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import { isValidDateRange } from 'util/dateUtil';
import _ from 'lodash';
import {
  BENEFIT_KIND_DENTAL,
  BENEFIT_KIND_LIFE,
  BENEFIT_KIND_MEDICAL,
  BENEFIT_KIND_RETIREMENT_401K,
  BENEFIT_KIND_VISION,
  BENEFIT_KIND_WORK_LIFE,
  BENEFIT_KIND_WELLBEING,
  BENEFIT_KIND_ADDITIONAL_PERK,
  BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNTS,
  BENEFIT_KIND_FSA,
  BENEFIT_KIND_TELEHEALTH
} from './../../../../constants/benefitConstants';
import StartEndDatePicker from 'components/DataForm/StartEndDatePicker';
import Loader from 'components/CircularProgress/Loader';
import TextEditor from 'components/DataForm/TextEditor';
import { EmptyPlans, MESSAGE_CODES } from '../../constants';
import { DEFAULT_OE_DESCRIPTION } from './../../../../constants/eligibilityContentDefaults';
import { SelectFormGroup } from 'components/DataForm';
import { getCurentPlanYearId, getPlanYearNameWithCurrent } from 'util/PlanUtil';
import ConfirmationBox from 'components/DialogBox/ConfirmationBox';

const LazyPlanSelector = React.lazy(() =>
  import('./PlanSelector/PlanSelector')
);

class CreateBenGuide extends React.Component {
  constructor(props) {
    super(props);

    // Initialize ben guide
    const { match } = this.props;
    const { path } = match;

    this.props.initBenGuide(
      match.params.benGuideId || null,
      match.params.employerId
    );

    // Initialize ben guide validation content
    this.props.initBenGuideValidations();

    this.state = {
      isChangeBenGuideName: false,
      isChangeBenGuidePlanYear: false,
      planValidations: {},
      saveAndContinue: false,
      oeDescription: '',
      isOpenPlanYearConfirmationBox: false,
      selectedPlanYearId: null
    };
  }

  componentDidMount() {
    const {
      match,
      getPlanYears,
      getBenGuideById,
      resetBenguidePlans,
      cloneBenguide,
      benGuideObj
    } = this.props;

    const { employerId, benGuideId, type } = match.params;
    const { path } = match;
    getPlanYears(employerId);

    if (benGuideId && path && (path.includes('edit') || type === 'view')) {
      //edit mode
      getBenGuideById(benGuideId, employerId);
    } else if (benGuideId && path && path.includes('clone')) {
      // clone
      cloneBenguide(benGuideId, benGuideObj);
      this.setState({
        oeDescription: DEFAULT_OE_DESCRIPTION,
        isChangeBenGuideName: true
      });
    } else {
      //create mode
      resetBenguidePlans();
      this.setState({ oeDescription: DEFAULT_OE_DESCRIPTION });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      benGuideObj,
      match,
      updateBenGuideInProgress,
      updateBenGuideError,
      benGuideLoading,
      availableTaxAdvantagedBenefitKinds,
      planYears,
      isFetchingPlanYears,
      getAllPlans
    } = this.props;
    const { saveAndContinue } = this.state;
    const { employerId, brokerId, benGuideId } = match.params;
    const { path } = match;

    if (prevProps.benGuideObj && benGuideObj && prevProps.benGuideObj.name !== benGuideObj.name) {
      this.props.validateBenGuideName(employerId, benGuideObj.name, benGuideObj.id);
    }
    if (
      !updateBenGuideInProgress &&
      prevProps.updateBenGuideInProgress &&
      benGuideObj &&
      updateBenGuideInProgress !== prevProps.updateBenGuideInProgress
    ) {
      if (updateBenGuideError) {
        if (
          updateBenGuideError.response &&
          updateBenGuideError.response.data &&
          updateBenGuideError.response.data.code &&
          MESSAGE_CODES[updateBenGuideError.response.data.code]
        ) {
          NotificationManager.error(
            MESSAGE_CODES[updateBenGuideError.response.data.code]
          );
        } else {
          NotificationManager.error(
            'Unable to save benguide. Please fix the validation errors.'
          );
        }
        this.updateValidationsFromError(updateBenGuideError.response.data);
      } else {
        if (benGuideObj.id) {
          NotificationManager.success(
            `${benGuideObj.name} has been ${
              benGuideObj.id ? 'updated' : 'created'
            } successfully.`
          );
        }
        if (
          benGuideObj.taxAdvantagedAccountPlans &&
          benGuideObj.taxAdvantagedAccountPlans.enabled &&
          prevProps.benGuideObj &&
          !_.isEmpty(
            prevProps.benGuideObj.taxAdvantagedAccountPlans,
            benGuideObj.taxAdvantagedAccountPlans
          )
        ) {
          this.props.getAvaliableTaxAdvantagedBenefitKinds(benGuideObj);
        } else {
          if (saveAndContinue) {
            if (path.includes('clone')) {
              this.props.history.push(
                `/brokers/${brokerId}/employers/${employerId}/benguides/${benGuideObj.id}/clone/custom`
              );
            } else {
              this.props.history.push(
                `/brokers/${brokerId}/employers/${employerId}/benguides/${benGuideObj.id}/edit/custom`
              );
            }
          }
        }
        this.setState({ planValidations: {} });
      }
    }

    if (
      !availableTaxAdvantagedBenefitKinds.loading &&
      prevProps.availableTaxAdvantagedBenefitKinds.loading !==
        availableTaxAdvantagedBenefitKinds.loading
    ) {
      this.updateTaxAdvantagedResources();
    }

    if (
      benGuideId &&
      !benGuideLoading &&
      benGuideLoading !== prevProps.benGuideLoading &&
      path &&
      !path.includes('clone')
    ) {
      //edit mode
      this.setState({ oeDescription: benGuideObj.oeDescription });
      getAllPlans(employerId, benGuideObj.planYearId);
    }

    if (
      !benGuideLoading &&
      benGuideLoading !== prevProps.benGuideLoading &&
      path &&
      path.includes('clone')
    ) {
      //edit mode
      this.setState({ oeDescription: benGuideObj.oeDescription });
      getAllPlans(employerId, benGuideObj.planYearId);
    }
  }

  updateTaxAdvantagedResources = () => {
    const {
      availableTaxAdvantagedBenefitKinds,
      benGuideObj,
      match
    } = this.props;
    const { benefitKinds } = availableTaxAdvantagedBenefitKinds;
    const { resourceDocuments, resourceLinks } = benGuideObj;
    const { employerId, brokerId } = match.params;
    const { path } = match;
    const { saveAndContinue } = this.state;

    let documents = [];
    let links = [];
    let filteredLinks = [];
    let filteredDocs = [];
    if (benefitKinds) {
      let availableBenefitKinds = benefitKinds.map((kind) => {
        if (kind.includes(BENEFIT_KIND_FSA)) {
          return BENEFIT_KIND_FSA;
        }
        return kind;
      });

      availableBenefitKinds = _.uniq(availableBenefitKinds);

      if (resourceDocuments.enabled) {
        availableBenefitKinds.map((kind) => {
          documents =
            resourceDocuments.documents &&
            resourceDocuments.documents.filter((doc) => doc.target === kind);
          filteredDocs = filteredDocs.concat(documents);
          return filteredDocs;
        });
      }

      if (resourceLinks.enabled) {
        availableBenefitKinds.map((kind) => {
          links =
            resourceLinks.links &&
            resourceLinks.links.filter((link) => link.target === kind);
          filteredLinks = filteredLinks.concat(links);
          return filteredLinks;
        });
      }

      this.updateResourceDocuments(filteredDocs);
      this.updateResourceLinks(filteredLinks);
    } else {
      this.updateResourceDocuments(filteredDocs);
      this.updateResourceLinks(filteredLinks);
    }
    if (saveAndContinue) {
      if (path.includes('clone')) {
        this.props.history.push(
          `/brokers/${brokerId}/employers/${employerId}/benguides/${benGuideObj.id}/clone/custom`
        );
      } else {
        this.props.history.push(
          `/brokers/${brokerId}/employers/${employerId}/benguides/${benGuideObj.id}/edit/custom`
        );
      }
    }
  };

  updateResourceDocuments = (documents) => {
    this.props.updateBenGuide(
      this.props.benGuideObj,
      'resourceDocuments.documents',
      documents
    );
  };

  updateResourceLinks = (links) => {
    this.props.updateBenGuide(
      this.props.benGuideObj,
      'resourceLinks.links',
      links
    );
  };

  getPlanYearById = (planYearId) => {
    const { planYears } = this.props;
    return (
      planYears.length > 0 &&
      planYears.find((planYear) => planYear.id == planYearId)
    );
  };

  handleChange = (event) => {
    const { match, getAllPlans, updateBenGuide, benGuideObj } = this.props;
    const {
      medicalPlans,
      dentalPlans,
      visionPlans,
      insurancePlans,
      fourOOneKPlans,
      taxAdvantagedAccountPlans,
      telehealthRxDeliveryPlans,
      additionalPerks,
      wellbeingPlans,
      workLifePlans
    } = benGuideObj;
    const { isChangeBenGuidePlanYear } = this.state;
    const { employerId } = match.params;
    const target = event.target;
    const name = target.name;
    if (name === 'name') {
      this.checkBenGuideName(target.value);
    }
    if (name === 'planYearId') {
      if (
        !(
          _.isEmpty(medicalPlans.plans) &&
          _.isEmpty(dentalPlans.plans) &&
          _.isEmpty(visionPlans.plans) &&
          _.isEmpty(insurancePlans.plans) &&
          _.isEmpty(fourOOneKPlans.plans) &&
          _.isEmpty(taxAdvantagedAccountPlans.plans) &&
          _.isEmpty(telehealthRxDeliveryPlans.plans) &&
          _.isEmpty(additionalPerks.plans) &&
          _.isEmpty(wellbeingPlans.plans) &&
          _.isEmpty(workLifePlans.plans)
        )
      ) {
        if (target.value === '') {
          return;
        } else {
          this.setState({
            selectedPlanYearId: target.value
          });
          this.togglePlanYearConfirmationBox();
          return;
        }
      }

      if (!isChangeBenGuidePlanYear) {
        this.setState({
          isChangeBenGuidePlanYear: true
        });
      }
      if (target.value === '') {
        return;
      } else {
        getAllPlans(employerId, target.value);
      }
    }

    updateBenGuide(benGuideObj, name, target.value);
  };

  proceedWithPlanYearChange = (event) => {
    this.togglePlanYearConfirmationBox();
    const { match, getAllPlans, updateBenGuide, benGuideObj } = this.props;
    const { isChangeBenGuidePlanYear, selectedPlanYearId } = this.state;
    const { employerId } = match.params;
    if (!isChangeBenGuidePlanYear) {
      this.setState({
        isChangeBenGuidePlanYear: true
      });
    }
    if (benGuideObj) {
      this.clearAddedPlans();
    }
    getAllPlans(employerId, selectedPlanYearId);
    updateBenGuide(benGuideObj, 'planYearId', selectedPlanYearId);
  };

  clearAddedPlans = () => {
    const {
      medicalPlans = {},
      dentalPlans = {},
      visionPlans = {},
      insurancePlans = {},
      fourOOneKPlans = {},
      taxAdvantagedAccountPlans = {},
      telehealthRxDeliveryPlans = {},
      additionalPerks = {},
      wellbeingPlans = {},
      workLifePlans = {},
      hsaPlans = {},
      planRecommenderConfig = {}
    } = this.props.benGuideObj;

    medicalPlans.plans = [];
    dentalPlans.plans = [];
    visionPlans.plans = [];
    insurancePlans.plans = [];
    fourOOneKPlans.plans = [];
    taxAdvantagedAccountPlans.plans = [];
    telehealthRxDeliveryPlans.plans = [];
    additionalPerks.plans = [];
    wellbeingPlans.plans = [];
    workLifePlans.plans = [];
    hsaPlans.plans = [];
    if (planRecommenderConfig) {
      planRecommenderConfig.medicalEnabled = false;
    }
  };

  checkBenGuideName = (query) => {
    const { match } = this.props;
    const { employerId } = match.params;
    const trimmedQuery = query.trim();
    this.setState({
      isChangeBenGuideName: true
    });

    //exclude current name
    const { name, id } = this.props.benGuideObj;
    if (query === name) {
      return;
    }
    if (trimmedQuery.length > 0) {
      this.props.validateBenGuideName(employerId, trimmedQuery, id);
    }
  };

  isValidOEPeriod = () => {
    const { benGuideObj } = this.props;
    let oeDateRange = false;
    if (
      benGuideObj.oePeriod &&
      ((benGuideObj.oePeriod.startDate && benGuideObj.oePeriod.endDate) ||
        benGuideObj.oePeriod.startDate ||
        benGuideObj.oePeriod.endDate)
    ) {
      oeDateRange = !isValidDateRange(
        benGuideObj.oePeriod && benGuideObj.oePeriod.startDate,
        benGuideObj.oePeriod && benGuideObj.oePeriod.endDate
      );
    }
    return oeDateRange;
  };

  /**
   * validate benguide name and plan year
   * @returns {boolean}
   */
  isBasicFormValidated = () => {
    const { benGuideObj } = this.props;
    const { isChangeBenGuideName, isChangeBenGuidePlanYear } = this.state;
    if (!isChangeBenGuidePlanYear) {
      this.setState({
        isChangeBenGuidePlanYear: true
      });
    }
    const validationObj = {
      name: _.trim(benGuideObj.name) === '',
      planYearId: _.trim(benGuideObj.planYearId) === '',
      oeDateRange: this.isValidOEPeriod(),
      nameAlreadyExist: isChangeBenGuideName
        ? !this.props.isValidBenGuideName
        : false
    };

    this.props.updateBenGuideValidations(validationObj);
    return _.isUndefined(
      Object.keys(validationObj).find((key) => validationObj[key] === true)
    );
  };

  /**
   * save and close.
   *
   * @param event
   * @returns {Promise<void>}
   */
  saveAndStay = (event) => {
    event.preventDefault();

    if (!this.isBasicFormValidated()) {
      return;
    }
    this.setState({
      saveAndContinue: false
    });
    const { benGuideObj } = this.props;
    this.save(benGuideObj);
  };

  /**
   * save and go to the next screen.
   *
   * @param event
   * @returns {Promise<void>}
   */
  saveAndNext = async (event) => {
    event.preventDefault();

    if (!this.isBasicFormValidated()) {
      return;
    }
    this.setState({
      saveAndContinue: true
    });
    const { benGuideObj } = this.props;
    this.save(benGuideObj);
  };

  /**
   * save the benGuide.
   *
   * @param payload - benGuide Obj.
   * @returns {Promise<void>}
   */
  save = (payload) => {
    const { oeDescription } = this.state;
    const { match } = this.props;
    const { path } = match;
    const { benGuideId } = match.params;
    if (!payload.oeDescription) {
      payload.oeDescription = oeDescription;
    }
    payload.name = payload.name.trim();
    if (payload.id) {
      this.props.updateBenGuideById(payload, path.includes('clone'));
    } else {
      if (path.includes('clone')) {
        let cloneDataVO = {
          originalId: benGuideId
        };
        payload.cloneDataVO = cloneDataVO;
      }
      this.props.createBenGuide(payload, path.includes('clone'));
    }
  };

  updateValidationsFromError = (errorData) => {
    const { code, details } = errorData;
    switch (code) {
      case 'benguide.mixed.rates.in.groups':
        this.setState({
          planValidations: JSON.parse(details.field)
        });
        return;
      default:
        this.setState({
          planValidations: {}
        });
    }
  };

  onChangeOEDescription = (text) => {
    this.setState({ oeDescription: text }, () => {
      this.props.updateBenGuide(this.props.benGuideObj, 'oeDescription', text);
    });
  };

  getOptions() {
    const { match, benGuideObj, planYears } = this.props;
    const { benGuideId } = match.params;

    if (planYears.length > 0) {
      //edit mode
      if (benGuideId && benGuideObj.planYearId.length > 0) {
        const selectedPlanYear = this.getPlanYearById(benGuideObj.planYearId);
        let options = planYears.filter(
          (planYear) => planYear.next || planYear.current
        );
        if (!_.some(options, selectedPlanYear)) {
          options.unshift(selectedPlanYear);
        }
        return options;
      }
      //create mode
      else {
        return planYears.filter(
          (planYear) => planYear.next || planYear.current
        );
      }
    } else {
      return [];
    }
  }

  togglePlanYearConfirmationBox = () => {
    this.setState((prevState) => ({
      isOpenPlanYearConfirmationBox: !prevState.isOpenPlanYearConfirmationBox
    }));
  };

  render() {
    const { benGuideObj, benGuideErrorObj, match } = this.props;
    const { type, benGuideId } = match.params;
    const { path } = match;
    const isReadOnlyView = type === 'view';
    const {
      medicalPlans,
      dentalPlans,
      visionPlans,
      lifePlans,
      workLifePlans,
      wellbeingPlans,
      additionalPerks,
      taxAdvantagedPlans,
      addedMedicalPlans,
      addedDentalPlans,
      addedVisionPlans,
      addedLifePlans,
      addedWorkLifePlans,
      addedWellbeingPlans,
      addedAdditionalPerks,
      addedTaxAdvantagedAccountPlans,
      medicalAllPlans,
      dentalAllPlans,
      visionAllPlans,
      lifeAllPlans,
      workLifeAllPlans,
      wellbeingAllPlans,
      additionalPerksAll,
      taxAdvantagedAllPlans,
      fourOOneKAllPlans,
      fourOOneKPlans,
      addedFourOOneKPlans,
      isProcessedPlans,
      telehealthRxDeliveryPlans,
      addedTelehealthPlans,
      planYears,
      updateBenGuideInProgress
    } = this.props;
    const {
      planValidations,
      oeDescription,
      isOpenPlanYearConfirmationBox,
      isChangeBenGuidePlanYear
    } = this.state;
    let plans = [];
    if (isProcessedPlans) {
      plans = [
        {
          id: 'MedicalPlans',
          header: 'Medical Plans',
          icon: 'notes-medical',
          allPlans: medicalAllPlans,
          availablePlans: medicalPlans,
          addedPlans: addedMedicalPlans,
          type: BENEFIT_KIND_MEDICAL,
          isProcessed: isProcessedPlans
        },
        {
          id: 'DentalPlans',
          header: 'Dental Plans',
          icon: 'tooth',
          allPlans: dentalAllPlans,
          availablePlans: dentalPlans,
          addedPlans: addedDentalPlans,
          type: BENEFIT_KIND_DENTAL,
          isProcessed: isProcessedPlans
        },
        {
          id: 'VisionPlans',
          header: 'Vision Plans',
          icon: 'glasses',
          allPlans: visionAllPlans,
          availablePlans: visionPlans,
          addedPlans: addedVisionPlans,
          type: BENEFIT_KIND_VISION,
          isProcessed: isProcessedPlans
        },
        {
          id: 'TaxAdvantagedAccountPlans',
          header: 'Tax Advantaged Account Plans',
          icon: 'bus-alt',
          allPlans: taxAdvantagedAllPlans,
          availablePlans: taxAdvantagedPlans,
          addedPlans: addedTaxAdvantagedAccountPlans,
          type: BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNTS,
          isProcessed: isProcessedPlans
        },
        {
          id: 'LifeDisabilityPlans',
          header: 'Life & Disability Plans',
          icon: 'star-of-life',
          allPlans: lifeAllPlans,
          availablePlans: lifePlans,
          addedPlans: addedLifePlans,
          type: BENEFIT_KIND_LIFE,
          isProcessed: isProcessedPlans
        },
        {
          id: 'WellbeingPlans',
          header: 'Wellbeing',
          icon: 'star-of-life',
          allPlans: wellbeingAllPlans,
          availablePlans: wellbeingPlans,
          addedPlans: addedWellbeingPlans,
          type: BENEFIT_KIND_WELLBEING,
          isProcessed: isProcessedPlans
        },
        {
          id: '401KPlans',
          header: '401(k) Plans',
          icon: 'bus-alt',
          allPlans: fourOOneKAllPlans,
          availablePlans: fourOOneKPlans,
          addedPlans: addedFourOOneKPlans,
          type: BENEFIT_KIND_RETIREMENT_401K,
          isProcessed: isProcessedPlans
        },
        {
          id: 'TelehealthPlans',
          header: 'Telehealth/Rx Delivery',
          icon: 'star-of-life',
          allPlans: telehealthRxDeliveryPlans,
          availablePlans: telehealthRxDeliveryPlans,
          addedPlans: addedTelehealthPlans,
          //Here, only this was passed since same object is being used for (BENEFIT_KIND_RX_DELIVERY, BENEFIT_KIND_RX_COUPONS) as well,
          //because we can only pass one type in the switcher planSelector.
          type: BENEFIT_KIND_TELEHEALTH,
          isProcessed: isProcessedPlans
        },
        {
          id: 'WorkLifePlans',
          header: 'Work/Life',
          icon: 'bus-alt',
          allPlans: workLifeAllPlans,
          availablePlans: workLifePlans,
          addedPlans: addedWorkLifePlans,
          type: BENEFIT_KIND_WORK_LIFE,
          isProcessed: isProcessedPlans
        },
        {
          id: 'AdditionalPerksPlans',
          header: 'Additional Perks',
          icon: 'bus-alt',
          allPlans: additionalPerksAll,
          availablePlans: additionalPerks,
          addedPlans: addedAdditionalPerks,
          type: BENEFIT_KIND_ADDITIONAL_PERK,
          isProcessed: isProcessedPlans
        }
      ];
    }
    if (benGuideObj && benGuideErrorObj) {
      const { isChangeBenGuideName, isChangeBenGuidePlanYear } = this.state;
      const { isValidBenGuideName: nameAlreadyNotExists } = this.props;

      const isBenguideNameEmpty = benGuideObj.name.trim().length === 0;
      const isValidBenGuideName = !isBenguideNameEmpty && nameAlreadyNotExists;
      const isPlanYearIdNotSelected = benGuideObj.planYearId.length === 0;

      const benGuideNameExistErrorMessage = isValidBenGuideName
        ? 'BenGuide name is available'
        : 'Please specify a BenGuide name or BenGuide name already taken';

      return (
        <Row>
          <Col md="10">
            <ApplicationWrapper>
              <HeaderContainer title="BenGuide - Plans" />
              <ContentContainer
                header="BenGuide Information"
                id="BenGuideInformation"
              >
                <div className="mt-3">
                  <Row>
                    <Col className="col-6">
                      <FormGroup>
                        <Label for="name">BenGuide Name (Internal) *</Label>
                        <Input
                          type="text"
                          name="name"
                          onChange={this.handleChange}
                          value={benGuideObj.name}
                          maxLength="100"
                          valid={
                            isChangeBenGuideName ? isValidBenGuideName : false
                          }
                          invalid={
                            isChangeBenGuideName ? !isValidBenGuideName : false
                          }
                          className={`mt-2 ${
                            benGuideErrorObj.name ? 'is-invalid' : ''
                          }`}
                          placeholder="Ben Guide Name"
                          disabled={isReadOnlyView}
                        />
                        <FormFeedback valid={isValidBenGuideName}>
                          {benGuideNameExistErrorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col className="col-6">
                      <SelectFormGroup
                        additionalClass="mt-2"
                        labelDisplay="Plan Year *"
                        inputName="planYearId"
                        inputValue={benGuideObj.planYearId}
                        onChange={this.handleChange}
                        options={this.getOptions()}
                        optionsMap={
                          !_.isEmpty(planYears)
                            ? (planYear, index) => {
                                return (
                                  <option key={index} value={planYear.id}>
                                    {getPlanYearNameWithCurrent(planYear)}
                                  </option>
                                );
                              }
                            : null
                        }
                        disabled={isReadOnlyView}
                        hideSelectOption={false}
                        invalid={
                          isChangeBenGuidePlanYear
                            ? isPlanYearIdNotSelected
                            : false
                        }
                      />
                    </Col>
                  </Row>
                  <hr />

                  <Row>
                    <Col className="mt-2 col-6">
                      <TextEditor
                        onChange={(value) => {
                          this.onChangeOEDescription(value);
                        }}
                        inputValue={oeDescription}
                        label="Open Enrollment Description"
                        disabled={isReadOnlyView}
                        limit={1500}
                      />
                    </Col>

                    <Col className="col-6">
                      <FormGroup>
                        <Label className="mt-2">Open Enrollment Dates</Label>
                        <StartEndDatePicker
                          startName="oePeriod.startDate"
                          endName="oePeriod.endDate"
                          startValue={
                            benGuideObj.oePeriod &&
                            benGuideObj.oePeriod.startDate
                              ? benGuideObj.oePeriod.startDate
                              : ''
                          }
                          endValue={
                            benGuideObj.oePeriod && benGuideObj.oePeriod.endDate
                              ? benGuideObj.oePeriod.endDate
                              : ''
                          }
                          onChange={this.handleChange}
                          validations={{
                            isRangeInvalid: benGuideErrorObj.oeDateRange
                          }}
                          disabled={isReadOnlyView}
                          startLabel=""
                          endLabel=""
                          autoChange={false}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </ContentContainer>
              {isProcessedPlans && benGuideObj.planYearId ? (
                plans.map((plan) => {
                  if (plan.isProcessed) {
                    return (
                      <div key={plan.header}>
                        <Suspense fallback={<Loader />}>
                          <LazyPlanSelector
                            id={plan.id}
                            header={plan.header}
                            icon={plan.icon}
                            allPlans={plan.allPlans}
                            availablePlans={plan.availablePlans}
                            addedPlans={plan.addedPlans}
                            type={plan.type}
                            planValidations={planValidations[plan.type]}
                            isReadOnlyView={isReadOnlyView}
                            planYears={planYears}
                          />
                        </Suspense>
                      </div>
                    );
                  } else {
                    return <React.Fragment key={plan.header}></React.Fragment>;
                  }
                })
              ) : !benGuideObj.planYearId &&
                (!benGuideId || path.includes('clone')) ? (
                EmptyPlans.map((plan) => {
                  if (plan.isProcessed) {
                    return (
                      <div key={plan.header}>
                        <Suspense fallback={<Loader />}>
                          <LazyPlanSelector
                            id={plan.id}
                            header={plan.header}
                            icon={plan.icon}
                            allPlans={plan.allPlans}
                            availablePlans={plan.availablePlans}
                            addedPlans={plan.addedPlans}
                            type={plan.type}
                            planValidations={planValidations[plan.type]}
                            isReadOnlyView={isReadOnlyView}
                            planYears={planYears}
                          />
                        </Suspense>
                      </div>
                    );
                  } else {
                    return <React.Fragment key={plan.header}></React.Fragment>;
                  }
                })
              ) : (
                <div className="mt-5 text-center">
                  <Loader />
                </div>
              )}
            </ApplicationWrapper>
          </Col>
          {!isReadOnlyView && (
            <Col md="2" className="mt-5">
              <Button
                auto_id="save-and-continue"
                type="submit"
                color="primary"
                size="lg"
                className="right-side-button"
                onClick={this.saveAndNext}
                disabled={updateBenGuideInProgress}
              >
                Save & Continue >
              </Button>
              <Button
                auto_id="save-and-close"
                type="submit"
                outline
                color="primary"
                size="lg"
                className="right-side-button mt-2"
                onClick={this.saveAndStay}
                disabled={updateBenGuideInProgress}
              >
                Save
              </Button>
            </Col>
          )}
          <ConfirmationBox
            isOpen={isOpenPlanYearConfirmationBox}
            toggle={this.togglePlanYearConfirmationBox}
            message="Are you sure you want to change the plan year? All added plans will be removed."
            confirmText="OK"
            confirmCallback={this.proceedWithPlanYearChange}
          />
        </Row>
      );
    } else {
      return (
        <div className="mt-5 text-center">
          <Loader />
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  initBenGuide: (benGuideId, employerId, planYearId) =>
    dispatch(initBenGuide(benGuideId, employerId, planYearId)),
  initBenGuideValidations: () => dispatch(initBenGuideValidations()),
  updateBenGuideValidations: (validationObj) =>
    dispatch(updateBenGuideValidations(validationObj)),
  updateBenGuide: (benGuideObj, prop, value) =>
    dispatch(updateBenGuide(benGuideObj, prop, value)),
  getAllPlans: (employerId, planYearId) =>
    dispatch(getAllPlans(employerId, planYearId)),
  getBenGuideById: (benGuideId, employerId) =>
    dispatch(getBenGuideById(benGuideId, employerId)),
  updateBenGuideById: (data, isCloned) =>
    dispatch(updateBenGuideById(data, isCloned)),
  createBenGuide: (data, isCloned) => dispatch(createBenGuide(data, isCloned)),
  validateBenGuideName: (employerId, query, benGuideId) =>
    dispatch(validateBenGuideName(employerId, query, benGuideId)),
  getAvaliableTaxAdvantagedBenefitKinds: (taxAdvantagedAccountPlans) =>
    dispatch(getAvaliableTaxAdvantagedBenefitKinds(taxAdvantagedAccountPlans)),
  getPlanYears: (employerId) => dispatch(getPlanYears(employerId)),
  resetBenguidePlans: () => dispatch(resetBenguidePlans()),
  cloneBenguide: (id, currentBenguide) => dispatch(cloneBenguide(id, currentBenguide))
});

const mapStateToProps = (state) => {
  const {
    benGuideData,
    benGuideErrorObj,
    benGuidePlans,
    benGuideName,
    availableTaxAdvantagedBenefitKinds
  } = state.benGuideReducer;
  const {
    medicalPlans,
    medicalAllPlans,
    addedMedicalPlans,
    isProcessedPlans,
    dentalAllPlans,
    dentalPlans,
    addedDentalPlans,
    visionAllPlans,
    visionPlans,
    addedVisionPlans,
    savingsAccounts,
    savingsAllAccounts,
    addedSavingsAccounts,
    commuterAllPlans,
    commuterPlans,
    addedCommuterPlans,
    lifeAllPlans,
    lifePlans,
    addedLifePlans,
    fourOOneKAllPlans,
    fourOOneKPlans,
    addedFourOOneKPlans,
    workLifePlans,
    workLifeAllPlans,
    addedWorkLifePlans,
    wellbeingPlans,
    wellbeingAllPlans,
    addedWellbeingPlans,
    additionalPerks,
    additionalPerksAll,
    addedAdditionalPerks,
    taxAdvantagedPlans,
    taxAdvantagedAllPlans,
    addedTaxAdvantagedAccountPlans,
    telehealthRxDeliveryPlans,
    addedTelehealthPlans
  } = benGuidePlans;
  const { planYears } = state.Employers.planYearReducer;
  return {
    benGuideObj: benGuideData.benGuideObj,
    updateBenGuideInProgress: benGuideData.updateBenGuideInProgress,
    updateBenGuideError: benGuideData.error,
    benGuideLoading: benGuideData.loading,
    benGuideErrorObj,
    medicalPlans,
    dentalPlans,
    visionPlans,
    savingsAccounts,
    commuterPlans,
    lifePlans,
    addedMedicalPlans,
    addedDentalPlans,
    addedVisionPlans,
    addedSavingsAccounts,
    addedCommuterPlans,
    addedLifePlans,
    medicalAllPlans,
    dentalAllPlans,
    visionAllPlans,
    savingsAllAccounts,
    commuterAllPlans,
    lifeAllPlans,
    fourOOneKAllPlans,
    fourOOneKPlans,
    addedFourOOneKPlans,
    workLifePlans,
    workLifeAllPlans,
    addedWorkLifePlans,
    wellbeingPlans,
    wellbeingAllPlans,
    addedWellbeingPlans,
    additionalPerks,
    additionalPerksAll,
    addedAdditionalPerks,
    taxAdvantagedPlans,
    taxAdvantagedAllPlans,
    addedTaxAdvantagedAccountPlans,
    benGuidePlans,
    isValidBenGuideName: benGuideName.isValidName,
    isProcessedPlans,
    availableTaxAdvantagedBenefitKinds,
    telehealthRxDeliveryPlans,
    addedTelehealthPlans,
    planYears: planYears.data,
    isFetchingPlanYears: planYears.isFetching
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBenGuide);
