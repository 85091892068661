/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { connect } from 'react-redux';
import reactCSS from 'reactcss';
import {
  Row,
  Col,
  Label,
  FormText,
  Button,
  CustomInput
} from 'components/Atoms';
import Icon from 'components/Icons';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import {
  getBenGuideBackground,
  getBenGuideById,
  getBenGuideLogo,
  getBenGuideFamilyImage,
  getBenGuideTimeOffImage,
  getBenGuideCommunitiesImage,
  getBenGuideAdditionalPerkImage,
  initBenGuide,
  initBenGuideValidations,
  updateBenGuide,
  updateBenGuideById,
  updateBenGuideValidations,
  uploadBackground,
  uploadLogos,
  uploadWorkLifeFamilyImage,
  uploadWorkLifeTimeOffImage,
  uploadWorkLifeCommunitiesImage,
  uploadAdditionalPerkImage
} from '../../../actions/benGuidePlanAction';
import NotificationManager from 'components/Notifications';
import logoImage from 'assets/images/logo-placeholder.png';
import backgroundImage from 'assets/images/benguide-background-placeholder.png';
import ColorPicker from './components/ColorPicker';
import ImageUploader from './components/ImageUploader';
import hexToRgb from 'hex-to-rgba';
import Loader from 'components/CircularProgress/Loader';
import './Customization.scss';
import { BEN_GUIDES_CLONE_OTHER_PATH, BEN_GUIDES_EDIT_OTHER_PATH } from '../../../routes';
import { WorkLife } from '../../../../../constants/workLifeSubCategory';
import { BENEFIT_KIND_ADDITIONAL_PERK } from '../../../../../constants/benefitConstants';
import { generatePath } from 'react-router-dom';

class Customization extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayPrimaryColorPicker: false,
      displaySecondaryColorPicker: false,
      displayTextColorPicker: false,
      displayMobileHeaderColorPicker: false,
      primaryColor: {
        r: '240',
        g: '240',
        b: '240',
        a: '200'
      },
      secondaryColor: {
        r: '240',
        g: '240',
        b: '240',
        a: '200'
      },
      textColor: {
        r: '240',
        g: '240',
        b: '240',
        a: '200'
      },
      mobileHeaderColor: {
        r: '240',
        g: '240',
        b: '240',
        a: '200'
      },
      primaryHex: '#',
      secondaryHex: '#',
      textHex: '#',
      mobileHeaderHex: '#',
      logoFileName: 'Choose file',
      backgroundFileName: 'Choose file',
      familyFileName: 'Choose file',
      timeOffFileName: 'Choose file',
      communityFileName: 'Choose file',
      additionalPerkFileName: 'Choose file',
      isUploadingLogo: false,
      isUploadingBackground: false,
      validPrimaryColor: true,
      validSecondaryColor: true,
      validTextColor: true,
      validMobileHeaderColor: true,
      saveAndContinue: false
    };

    this.onLogoUpload = this.onLogoUpload.bind(this);
  }

  componentDidMount() {
    const { benGuideObj } = this.props;
    if (benGuideObj === null) {
      const { match } = this.props;
      const { employerId, benGuideId } = match.params;
      this.props.getBenGuideById(benGuideId, employerId);
    } else {
      this.initializeColorConfig();
    }
  }
  componentDidUpdate(prevProps) {
    const {
      benGuideObj,
      updateBenGuideInProgress,
      updateBenGuideError,
      match,
      benGuideFamilyError,
      benGuideTimeOffError,
      benGuideCommunitiesError,
      benGuidePerkError,
      benGuideBackgroundError,
      benGuideLogoError
    } = this.props;
    const { saveAndContinue } = this.state;
    const { employerId, benGuideId } = match.params;
    const { path } = match;
    if (
      !updateBenGuideInProgress &&
      prevProps.updateBenGuideInProgress &&
      benGuideObj &&
      updateBenGuideInProgress !== prevProps.updateBenGuideInProgress
    ) {
      if (updateBenGuideError) {
        NotificationManager.error(`${benGuideObj.name} could not be saved.`);
      } else {
        NotificationManager.success(
          `${benGuideObj.name} has been saved successfully.`
        );
        this.setState({ planValidations: {} });
        if (saveAndContinue) {
          if (path.includes('clone')) {
            this.props.history.push(
              generatePath(BEN_GUIDES_CLONE_OTHER_PATH, {
                ...match.params,
                employerId,
                benGuideId
              })
            );
          } else {
            this.props.history.push(
              generatePath(BEN_GUIDES_EDIT_OTHER_PATH, {
                ...match.params,
                employerId,
                benGuideId
              })
            );
          }
        }
      }
    }
    if (
      prevProps.benGuideBackgroundError !== benGuideBackgroundError &&
      benGuideBackgroundError !== null
    ) {
      NotificationManager.error('Failed to upload the background image.');
    }
    if (
      prevProps.benGuideLogoError !== benGuideLogoError &&
      benGuideLogoError !== null
    ) {
      NotificationManager.error('Failed to upload the logo.');
    }
    if (
      prevProps.benGuideFamilyError !== benGuideFamilyError &&
      benGuideFamilyError !== null
    ) {
      NotificationManager.error('Failed to upload the Family image.');
    }
    if (
      prevProps.benGuideTimeOffError !== benGuideTimeOffError &&
      benGuideTimeOffError !== null
    ) {
      NotificationManager.error('Failed to upload the Time off image.');
    }
    if (
      prevProps.benGuideCommunitiesError !== benGuideCommunitiesError &&
      benGuideCommunitiesError !== null
    ) {
      NotificationManager.error('Failed to upload the Communities image.');
    }
    if (
      prevProps.benGuidePerkError !== benGuidePerkError &&
      benGuidePerkError !== null
    ) {
      NotificationManager.error('Failed to upload the Additional perks image.');
    }
    if (benGuideObj && benGuideObj !== prevProps.benGuideObj) {
      this.initializeColorConfig();
    }
  }

  /**
   * Initialize the color config
   */
  initializeColorConfig = () => {
    const { benGuideObj, match } = this.props;
    const { params } = match;
    if (benGuideObj) {
      const { styleConfiguration } = benGuideObj;
      this.props.getBenGuideLogo(
        params.benGuideId,
        styleConfiguration.logoReference
      );

      this.props.getBenGuideBackground(
        params.benGuideId,
        styleConfiguration.backgroundReference
      );

      this.props.getBenGuideFamilyImage(
        params.benGuideId,
        styleConfiguration.familyImageReference
      );

      this.props.getBenGuideTimeOffImage(
        params.benGuideId,
        styleConfiguration.timeOfImageReference
      );

      this.props.getBenGuideCommunitiesImage(
        params.benGuideId,
        styleConfiguration.communitiesImageReference
      );

      this.props.getBenGuideAdditionalPerkImage(
        params.benGuideId,
        styleConfiguration.perksImageReference
      );

      if (
        styleConfiguration.primaryColor &&
        styleConfiguration.primaryColor.length > 1
      ) {
        this.setState({
          primaryColor: this.findHexToRgb(styleConfiguration.primaryColor)
        });
      }

      if (
        styleConfiguration.secondaryColor &&
        styleConfiguration.secondaryColor.length > 1
      ) {
        this.setState({
          secondaryColor: this.findHexToRgb(styleConfiguration.secondaryColor)
        });
      }

      if (
        styleConfiguration.homeTextColor &&
        styleConfiguration.homeTextColor.length > 1
      ) {
        this.setState({
          textColor: this.findHexToRgb(styleConfiguration.homeTextColor)
        });
      }

      if (
        styleConfiguration.mobileHeaderColor &&
        styleConfiguration.mobileHeaderColor.length > 1
      ) {
        this.setState({
          mobileHeaderColor: this.findHexToRgb(
            styleConfiguration.mobileHeaderColor
          )
        });
      }
    }
  };

  findHexToRgb = (hex) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: 100
        }
      : null;
  };

  handlePrimaryClick = () => {
    this.setState({
      displayPrimaryColorPicker: !this.state.displayPrimaryColorPicker,
      displaySecondaryColorPicker: false,
      displayTextColorPicker: false,
      displayMobileHeaderColorPicker: false
    });
  };

  handleSecondaryClick = () => {
    this.setState({
      displaySecondaryColorPicker: !this.state.displaySecondaryColorPicker,
      displayPrimaryColorPicker: false,
      displayTextColorPicker: false,
      displayMobileHeaderColorPicker: false
    });
  };

  handleTextClick = () => {
    this.setState({
      displayTextColorPicker: !this.state.displayTextColorPicker,
      displayPrimaryColorPicker: false,
      displaySecondaryColorPicker: false,
      displayMobileHeaderColorPicker: false
    });
  };

  handleMobileHeaderClick = () => {
    this.setState({
      displayMobileHeaderColorPicker: !this.state
        .displayMobileHeaderColorPicker,
      displayPrimaryColorPicker: false,
      displaySecondaryColorPicker: false,
      displayTextColorPicker: false
    });
  };

  handleClose = () => {
    this.setState({
      displayPrimaryColorPicker: false,
      displaySecondaryColorPicker: false,
      displayTextColorPicker: false,
      displayMobileHeaderColorPicker: false
    });
  };

  handlePrimaryChange = (color) => {
    this.props.updateBenGuide(
      this.props.benGuideObj,
      'styleConfiguration.primaryColor',
      color.hex
    );
    this.setState({ primaryColor: color.rgb, primaryHex: color.hex });
  };

  handleSecondaryChange = (color) => {
    this.props.updateBenGuide(
      this.props.benGuideObj,
      'styleConfiguration.secondaryColor',
      color.hex
    );
    this.setState({ secondaryColor: color.rgb, secondaryHex: color.hex });
  };

  handleTextChange = (color) => {
    this.props.updateBenGuide(
      this.props.benGuideObj,
      'styleConfiguration.homeTextColor',
      color.hex
    );
    this.setState({ textColor: color.rgb, textHex: color.hex });
  };

  handleMobileHeaderChange = (color) => {
    this.props.updateBenGuide(
      this.props.benGuideObj,
      'styleConfiguration.mobileHeaderColor',
      color.hex
    );
    this.setState({ mobileHeaderColor: color.rgb, mobileHeaderHex: color.hex });
  };

  /**
   * handle color picker input
   * @param event
   */
  handleColorInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (this.validateHex(value) && (value.length === 1 || value.length === 7)) {
      this.props.updateBenGuide(this.props.benGuideObj, name, value);

      const rgba = this.hexToRgba(value);

      if (name === 'styleConfiguration.primaryColor') {
        this.setState({ primaryColor: rgba, primaryHex: value });
      } else if (name === 'styleConfiguration.secondaryColor') {
        this.setState({ secondaryColor: rgba, secondaryHex: value });
      } else if (name === 'styleConfiguration.homeTextColor') {
        this.setState({ textColor: rgba, textHex: value });
      } else if (name === 'styleConfiguration.mobileHeaderColor') {
        this.setState({ mobileHeaderColor: rgba, mobileHeaderHex: value });
      }
    }
  };

  /**
   * hex to rgba
   * @param hex
   */
  hexToRgba = (hex) => {
    const rgbaStr = hexToRgb(hex);
    const rgbArr = rgbaStr
      .substring(5, rgbaStr.length - 2)
      .replace(/ /g, '')
      .split(',');
    const rgba = {
      r: parseInt(rgbArr[0]),
      g: parseInt(rgbArr[1]),
      b: parseInt(rgbArr[2]),
      a: 1
    };
    return rgba;
  };

  /**
   * validate the hex color
   * @param color
   * @returns {boolean}
   */
  validateHex = (color) => {
    const regex = /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i;
    return regex.test(color);
  };

  validateColors = () => {
    const { primaryHex, secondaryHex, textHex, mobileHeaderHex } = this.state;

    const isValidPrimaryColor =
      primaryHex === '#' ? true : this.validateHex(primaryHex);
    this.setState({ validPrimaryColor: isValidPrimaryColor });

    const isValidSecondaryColor =
      secondaryHex === '#' ? true : this.validateHex(secondaryHex);
    this.setState({ validSecondaryColor: isValidSecondaryColor });

    const isValidTextColor = textHex === '#' ? true : this.validateHex(textHex);
    this.setState({ validTextColor: isValidTextColor });

    const isValidMobileHeaderColor =
      mobileHeaderHex === '#' ? true : this.validateHex(mobileHeaderHex);
    this.setState({ validMobileHeaderColor: isValidMobileHeaderColor });

    return (
      isValidPrimaryColor &&
      isValidSecondaryColor &&
      isValidTextColor &&
      isValidMobileHeaderColor
    );
  };

  /**
   * save and close.
   *
   * @param event
   * @returns {Promise<void>}
   */
  saveAndClose = (event) => {
    event.preventDefault();
    if (!this.validateColors()) {
      return;
    }
    this.setState({
      saveAndContinue: false
    });
    const { benGuideObj } = this.props;
    this.save(benGuideObj);
  };

  /**
   * save and go to the next screen.
   *
   * @param event
   * @returns {Promise<void>}
   */
  saveAndNext = (event) => {
    event.preventDefault();
    if (!this.validateColors()) {
      return;
    }
    this.setState({
      saveAndContinue: true
    });
    const { benGuideObj } = this.props;
    this.save(benGuideObj);
  };

  /**
   * save the benGuide.
   *
   * @param payload - benGuide Obj.
   * @returns {Promise<void>}
   */
  save = (payload) => {
    const { match } = this.props;
    const { params, path } = match;
    this.props.updateBenGuideById(payload, path.includes('clone'));
  };

  getDefaultStyle = (type) => {
    return {
      width: '24px',
      height: '24px',
      borderRadius: '2px',
      background: `rgba(
        ${this.state[type].r !== null ? this.state[type].r : '240'},
        ${this.state[type].g !== null ? this.state[type].g : '240'},
        ${this.state[type].b !== null ? this.state[type].b : '240'},
        ${this.state[type].a !== null ? this.state[type].a : '200'})`
    };
  };
  colorPickerStyles = () =>
    reactCSS({
      default: {
        primaryColor: {
          ...this.getDefaultStyle('primaryColor'),
          borderRight: 'none'
        },
        secondaryColor: {
          ...this.getDefaultStyle('secondaryColor')
        },
        textColor: {
          ...this.getDefaultStyle('textColor')
        },
        mobileHeaderColor: {
          ...this.getDefaultStyle('mobileHeaderColor')
        }
      }
    });

  onLogoUpload = (e) => {
    const { match } = this.props;
    const { params } = match;
    this.setState({
      logoFileName: e.target.files[0].name
    });
    this.props.uploadLogos(e.target.files[0], params.benGuideId);
  };

  onBackgroundUpload = (e) => {
    const { match } = this.props;
    const { params } = match;
    this.setState({
      backgroundFileName: e.target.files[0].name
    });
    this.props.uploadBackground(e.target.files[0], params.benGuideId);
  };

  onFamilyUpload = (e) => {
    const { match } = this.props;
    const { params } = match;
    this.setState({
      familyFileName: e.target.files[0].name
    });
    this.props.uploadWorkLifeFamilyImage(e.target.files[0], params.benGuideId);
  };

  onTimeOffUpload = (e) => {
    const { match } = this.props;
    const { params } = match;
    this.setState({
      timeOffFileName: e.target.files[0].name
    });
    this.props.uploadWorkLifeTimeOffImage(e.target.files[0], params.benGuideId);
  };

  onCommunitiesUpload = (e) => {
    const { match } = this.props;
    const { params } = match;
    this.setState({
      communityFileName: e.target.files[0].name
    });
    this.props.uploadWorkLifeCommunitiesImage(
      e.target.files[0],
      params.benGuideId
    );
  };

  onAdditionalPerkUpload = (e) => {
    const { match } = this.props;
    const { params } = match;
    this.setState({
      additionalPerkFileName: e.target.files[0].name
    });
    this.props.uploadAdditionalPerkImage(e.target.files[0], params.benGuideId);
  };

  removeFile = (type) => {
    this.changeFile(type);
  };

  changeFile = (type) => {
    switch (type) {
      case WorkLife.FAMILY:
        this.setState({
          familyFileName: 'Choose file'
        });
        //Remove the file from redux store
        this.props.updateBenGuide(
          this.props.benGuideObj,
          'styleConfiguration.familyImageReference'
        );
        break;
      case WorkLife.TIME_OFF:
        this.setState({
          timeOffFileName: 'Choose file'
        });
        //Remove the file from redux store
        this.props.updateBenGuide(
          this.props.benGuideObj,
          'styleConfiguration.timeOfImageReference'
        );
        break;
      case WorkLife.COMMUNITIES:
        this.setState({
          communityFileName: 'Choose file'
        });
        //Remove the file from redux store
        this.props.updateBenGuide(
          this.props.benGuideObj,
          'styleConfiguration.communitiesImageReference'
        );
        break;
      case BENEFIT_KIND_ADDITIONAL_PERK:
        this.setState({
          additionalPerkFileName: 'Choose file'
        });
        //Remove the file from redux store
        this.props.updateBenGuide(
          this.props.benGuideObj,
          'styleConfiguration.perksImageReference'
        );
        break;
      default:
        break;
    }
  };

  render() {
    const {
      benGuideObj,
      benGuideLogoUploading,
      benGuideLogoReference,
      benGuideBackgroundUploading,
      benGuideBackgroundReference,
      benGuideFamilyReference,
      benGuideFamilyUploading,
      benGuideTimeOffReference,
      benGuideTimeOffUploading,
      benGuideCommunitiesReference,
      benGuideCommunitiesUploading,
      benGuidePerkUploading,
      benGuidePerkReference,
      match
    } = this.props;
    const {
      familyFileName,
      timeOffFileName,
      communityFileName,
      additionalPerkFileName,
      backgroundFileName,
      logoFileName
    } = this.state;
    const { type } = match.params;
    const isReadOnlyView = type === 'view';

    if (benGuideObj) {
      return (
        <Row className="customization">
          <Col className="col-10">
            <ApplicationWrapper>
              <HeaderContainer
                title="BenGuide - Customization"
                subtitle={benGuideObj.name}
              />
              <ContentContainer header="Design">
                <Row>
                  <Col xs="6">
                    <div>
                      <Label for="exampleCustomFileBrowser">Company Logo</Label>
                      <CustomInput
                        type="file"
                        id="exampleCustomFileBrowser"
                        className={`mb-3 ${
                          isReadOnlyView ? 'disable-background' : ''
                        }`}
                        onChange={this.onLogoUpload}
                        label={logoFileName}
                        disabled={isReadOnlyView}
                        accept="image/png, image/jpeg"
                      />
                      {benGuideLogoUploading ? (
                        <Icon icon="sync" spin={true} size="2x" />
                      ) : (
                        <img
                          alt="logo"
                          className="company-logo-thumb"
                          src={benGuideLogoReference || logoImage}
                        />
                      )}
                      <FormText color="muted">
                        Logo Spec Size: 220px X 80px
                      </FormText>
                    </div>
                    <div className="mt-3" auto_id="color-picker">
                      <h3 className="mt-5">Color Scheme</h3>
                      <div className="mt-3 ml-0">
                        <ColorPicker
                          name="styleConfiguration.primaryColor"
                          label="Primary Color"
                          styleColor={this.colorPickerStyles().primaryColor}
                          textColor={
                            this.validateHex(this.state.primaryColor)
                              ? this.state.primaryColor
                              : '#'
                          }
                          hex={benGuideObj.styleConfiguration.primaryColor}
                          placeholder={
                            benGuideObj.styleConfiguration.primaryColor
                          }
                          displayColorPicker={
                            this.state.displayPrimaryColorPicker
                          }
                          handleClick={this.handlePrimaryClick}
                          handleClose={this.handleClose}
                          handleChange={this.handlePrimaryChange}
                          handleInputChange={this.handleColorInput}
                          disabled={isReadOnlyView}
                        />
                      </div>
                      <div className="mt-3">
                        <ColorPicker
                          name="styleConfiguration.secondaryColor"
                          label="Secondary Color"
                          styleColor={this.colorPickerStyles().secondaryColor}
                          textColor={
                            this.validateHex(this.state.secondaryColor)
                              ? this.state.secondaryColor
                              : '#'
                          }
                          hex={benGuideObj.styleConfiguration.secondaryColor}
                          placeholder={
                            benGuideObj.styleConfiguration.secondaryColor
                          }
                          displayColorPicker={
                            this.state.displaySecondaryColorPicker
                          }
                          handleClick={this.handleSecondaryClick}
                          handleClose={this.handleClose}
                          handleChange={this.handleSecondaryChange}
                          handleInputChange={this.handleColorInput}
                          disabled={isReadOnlyView}
                        />
                      </div>
                      <div className="mt-3">
                        <ColorPicker
                          name="styleConfiguration.homeTextColor"
                          label="Home Text Color"
                          styleColor={this.colorPickerStyles().textColor}
                          textColor={
                            this.validateHex(this.state.textColor)
                              ? this.state.textColor
                              : '#'
                          }
                          hex={benGuideObj.styleConfiguration.homeTextColor}
                          placeholder={
                            benGuideObj.styleConfiguration.homeTextColor
                          }
                          displayColorPicker={this.state.displayTextColorPicker}
                          handleClick={this.handleTextClick}
                          handleClose={this.handleClose}
                          handleChange={this.handleTextChange}
                          handleInputChange={this.handleColorInput}
                          disabled={isReadOnlyView}
                        />
                      </div>
                      <div className="mt-3 mb-5">
                        <ColorPicker
                          name="styleConfiguration.mobileHeaderColor"
                          label="Mobile Header Color"
                          styleColor={
                            this.colorPickerStyles().mobileHeaderColor
                          }
                          textColor={
                            this.validateHex(this.state.mobileHeaderColor)
                              ? this.state.mobileHeaderColor
                              : '#'
                          }
                          hex={benGuideObj.styleConfiguration.mobileHeaderColor}
                          placeholder={
                            benGuideObj.styleConfiguration.mobileHeaderColor
                          }
                          displayColorPicker={
                            this.state.displayMobileHeaderColorPicker
                          }
                          handleClick={this.handleMobileHeaderClick}
                          handleClose={this.handleClose}
                          handleChange={this.handleMobileHeaderChange}
                          handleInputChange={this.handleColorInput}
                          disabled={isReadOnlyView}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="6">
                    <Label for="exampleCustomFileBrowser">
                      Homepage Background Image
                    </Label>
                    <CustomInput
                      type="file"
                      id="exampleCustomFileBrowser"
                      onChange={this.onBackgroundUpload}
                      label={backgroundFileName}
                      className="mb-3"
                      disabled={isReadOnlyView}
                      accept="image/png, image/jpeg"
                    />
                    {benGuideBackgroundUploading ? (
                      <Icon icon="sync" spin={true} size="2x" />
                    ) : (
                      <img
                        alt={`${benGuideBackgroundReference}`}
                        src={benGuideBackgroundReference || backgroundImage}
                      />
                    )}
                    <FormText color="muted">
                      Background Spec Size: 1440px X 1046px
                    </FormText>
                  </Col>
                </Row>
              </ContentContainer>

              <ContentContainer header="Work/Life Photos">
                <ImageUploader
                  header={'Family - Hero Image'}
                  isReadOnlyView={isReadOnlyView}
                  label={familyFileName}
                  onChange={this.onFamilyUpload}
                  imageReference={benGuideFamilyReference}
                  imageUploading={benGuideFamilyUploading}
                  removeFile={() => this.removeFile(WorkLife.FAMILY)}
                />
                <ImageUploader
                  header={'Time Off - Hero Image '}
                  isReadOnlyView={isReadOnlyView}
                  label={timeOffFileName}
                  onChange={this.onTimeOffUpload}
                  imageReference={benGuideTimeOffReference}
                  imageUploading={benGuideTimeOffUploading}
                  removeFile={() => this.removeFile(WorkLife.TIME_OFF)}
                />
                <ImageUploader
                  header={'Communities - Hero Image'}
                  isReadOnlyView={isReadOnlyView}
                  label={communityFileName}
                  onChange={this.onCommunitiesUpload}
                  imageReference={benGuideCommunitiesReference}
                  imageUploading={benGuideCommunitiesUploading}
                  removeFile={() => this.removeFile(WorkLife.COMMUNITIES)}
                />
              </ContentContainer>

              <ContentContainer header="Additional Perks Photo">
                <ImageUploader
                  header={'Additional Perks - Hero Image'}
                  isReadOnlyView={isReadOnlyView}
                  label={additionalPerkFileName}
                  onChange={this.onAdditionalPerkUpload}
                  imageReference={benGuidePerkReference}
                  imageUploading={benGuidePerkUploading}
                  removeFile={() =>
                    this.removeFile(BENEFIT_KIND_ADDITIONAL_PERK)
                  }
                />
              </ContentContainer>
            </ApplicationWrapper>
          </Col>
          {!isReadOnlyView && (
            <Col className="col-2 mt-5">
              <Button
                type="submit"
                color="primary"
                size="lg"
                className="right-side-button"
                onClick={this.saveAndNext}
              >
                Save & Continue >
              </Button>
              <Button
                type="submit"
                outline
                color="primary"
                size="lg"
                className="right-side-button mt-2"
                onClick={this.saveAndClose}
              >
                Save
              </Button>
            </Col>
          )}
        </Row>
      );
    } else {
      return (
        <div className="text-center align-middle">
          <Loader />
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  initBenGuide: (benGuideObj) => dispatch(initBenGuide(benGuideObj)),
  initBenGuideValidations: () => dispatch(initBenGuideValidations()),
  updateBenGuideValidations: (validationObj) =>
    dispatch(updateBenGuideValidations(validationObj)),
  updateBenGuide: (benGuideObj, prop, value) =>
    dispatch(updateBenGuide(benGuideObj, prop, value)),
  getBenGuideById: (benGuideId, employerId) =>
    dispatch(getBenGuideById(benGuideId, employerId)),
  updateBenGuideById: (data, isCloned) =>
    dispatch(updateBenGuideById(data, isCloned)),
  uploadLogos: (file, benGuideId) => dispatch(uploadLogos(file, benGuideId)),
  uploadBackground: (file, benGuideId) =>
    dispatch(uploadBackground(file, benGuideId)),
  uploadWorkLifeFamilyImage: (file, benGuideId) =>
    dispatch(uploadWorkLifeFamilyImage(file, benGuideId)),
  uploadWorkLifeTimeOffImage: (file, benGuideId) =>
    dispatch(uploadWorkLifeTimeOffImage(file, benGuideId)),
  uploadWorkLifeCommunitiesImage: (file, benGuideId) =>
    dispatch(uploadWorkLifeCommunitiesImage(file, benGuideId)),
  uploadAdditionalPerkImage: (file, benGuideId) =>
    dispatch(uploadAdditionalPerkImage(file, benGuideId)),
  getBenGuideLogo: (benGuideId, logoReference) =>
    dispatch(getBenGuideLogo(benGuideId, logoReference)),
  getBenGuideBackground: (benGuideId, backgroundReference) =>
    dispatch(getBenGuideBackground(benGuideId, backgroundReference)),
  getBenGuideFamilyImage: (benGuideId, familyImageReference) =>
    dispatch(getBenGuideFamilyImage(benGuideId, familyImageReference)),
  getBenGuideTimeOffImage: (benGuideId, timeOfImageReference) =>
    dispatch(getBenGuideTimeOffImage(benGuideId, timeOfImageReference)),
  getBenGuideCommunitiesImage: (benGuideId, communitiesImageReference) =>
    dispatch(
      getBenGuideCommunitiesImage(benGuideId, communitiesImageReference)
    ),
  getBenGuideAdditionalPerkImage: (benGuideId, perksImageReference) =>
    dispatch(getBenGuideAdditionalPerkImage(benGuideId, perksImageReference))
});

const mapStateToProps = (state) => {
  const {
    benGuideData,
    benGuideBackground,
    benGuideLogo,
    benGuideFamilyImage,
    benGuideTimeOffImage,
    benGuideCommunitiesImage,
    benGuidePerkImage
  } = state.benGuideReducer;
  return {
    benGuideObj: benGuideData.benGuideObj,
    updateBenGuideInProgress: benGuideData.updateBenGuideInProgress,
    updateBenGuideError: benGuideData.error,
    benGuideLogoUploading: benGuideLogo.loading,
    benGuideLogoReference: benGuideLogo.logoReference,
    benGuideLogoError: benGuideLogo.error,
    benGuideBackgroundUploading: benGuideBackground.loading,
    benGuideBackgroundReference: benGuideBackground.backgroundRef,
    benGuideBackgroundError: benGuideBackground.error,
    benGuideFamilyUploading: benGuideFamilyImage.loading,
    benGuideFamilyReference: benGuideFamilyImage.familyImageReference,
    benGuideFamilyError: benGuideFamilyImage.error,
    benGuideTimeOffUploading: benGuideTimeOffImage.loading,
    benGuideTimeOffReference: benGuideTimeOffImage.timeOfImageReference,
    benGuideTimeOffError: benGuideTimeOffImage.error,
    benGuideCommunitiesUploading: benGuideCommunitiesImage.loading,
    benGuideCommunitiesReference:
      benGuideCommunitiesImage.communitiesImageReference,
    benGuideCommunitiesError: benGuideCommunitiesImage.error,
    benGuidePerkUploading: benGuidePerkImage.loading,
    benGuidePerkReference: benGuidePerkImage.perksImageReference,
    benGuidePerkError: benGuidePerkImage.error
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Customization);
