/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import EmployerFeatureConfigurationService from 'services/EmployerFeatureConfigurationService';
import IntegrationsService from 'services/IntegrationsService';
import * as ActionTypes from '../actionTypes/integrations.actionTypes';
import {
  MODULE_BENADMIN_INTEGRATION_ENABLED,
  CREATE_SUCCESS,
  UPDATE_SUCCESS,
  SYNC_TYPE
} from '../constants';

export const getAllSyncDetails = (requestConfig) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GET_ALL_SYNC_DETAILS, payload: null });
      const response = await IntegrationsService.getAllSyncDetails(
        requestConfig
      );
      dispatch({
        type: ActionTypes.GET_ALL_SYNC_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_ALL_SYNC_DETAILS_ERROR,
        payload: error
      });
    }
  };
};

export const getBenAdminIntegrationFeatureConfig = (employerId) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_BENADMIN_INTEGRATIONS_FEATURE_CONF });
    try {
      let response;
      response = await EmployerFeatureConfigurationService.findConfigStatus(
        employerId,
        MODULE_BENADMIN_INTEGRATION_ENABLED
      );
      dispatch({
        type: ActionTypes.GET_BENADMIN_INTEGRATIONS_FEATURE_CONF_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BENADMIN_INTEGRATIONS_FEATURE_CONF_ERROR,
        payload: error
      });
    }
  };
};

export const createBenAdminIntegrationFeatureConfig = (employerId) => {
  const benAdminConfig = {
    employerId: employerId,
    key: MODULE_BENADMIN_INTEGRATION_ENABLED,
    value: String(true)
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.CREATE_BENADMIN_INTEGRATIONS_FEATURE_CONF });
      let response;
      response = await EmployerFeatureConfigurationService.createConfigModule(
        benAdminConfig
      );
      if (response.status === CREATE_SUCCESS) {
        response = await EmployerFeatureConfigurationService.findConfigStatus(
          employerId,
          MODULE_BENADMIN_INTEGRATION_ENABLED
        );
        dispatch({
          type: ActionTypes.CREATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_SUCCESS,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.CREATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_ERROR,
        payload: error
      });
    }
  };
};

export const updateBenAdminIntegrationFeatureConfig = (employerId, config) => {
  const benAdminConfig = {
    id: config.id,
    employerId: employerId,
    key: MODULE_BENADMIN_INTEGRATION_ENABLED,
    value: String(config.value)
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.UPDATE_BENADMIN_INTEGRATIONS_FEATURE_CONF });
      let response;
      response = await EmployerFeatureConfigurationService.updateConfigModule(
        benAdminConfig
      );

      if (response.status === UPDATE_SUCCESS) {
        response = await EmployerFeatureConfigurationService.findConfigStatus(
          employerId,
          MODULE_BENADMIN_INTEGRATION_ENABLED
        );

        dispatch({
          type: ActionTypes.UPDATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_SUCCESS,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_BENADMIN_INTEGRATIONS_FEATURE_CONF_ERROR,
        payload: error
      });
    }
  };
};

export const getBenAdminEmployerSyncDetails = (requestConfig) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_BENADMIN_EMPLOYER_SYNC_DETAILS,
        payload: null
      });
      const response = await IntegrationsService.getBenAdminEmployerSyncDetails(
        requestConfig
      );
      dispatch({
        type: ActionTypes.GET_BENADMIN_EMPLOYER_SYNC_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BENADMIN_EMPLOYER_SYNC_DETAILS_ERROR,
        payload: error
      });
    }
  };
};

export const startEmployerBenAdminSync = (employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.START_EMPLOYER_BENADMIN_SYNC,
        payload: null
      });
      const response = await IntegrationsService.startEmployerBenAdminSync(
        employerId,
        config
      );
      dispatch({
        type: ActionTypes.START_EMPLOYER_BENADMIN_SYNC_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.START_EMPLOYER_BENADMIN_SYNC_ERROR,
        payload: error
      });
    }
  };
};

export const getLastBenAdminSyncStatus = (employerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_LAST_BENADMIN_SYNC_STATUS,
        payload: employerId
      });
      const response = await IntegrationsService.getLastBenAdminSyncStatus(
        employerId
      );
      dispatch({
        type: ActionTypes.GET_LAST_BENADMIN_SYNC_STATUS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_LAST_BENADMIN_SYNC_STATUS_ERROR,
        payload: error
      });
    }
  };
};

export const toggleDailySync = (employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.TOGGLE_DAILY_SYNC,
        payload: null
      });
      await IntegrationsService.toggleDailySync(employerId, config);
      dispatch({
        type: ActionTypes.TOGGLE_DAILY_SYNC_SUCCESS,
        payload: config
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.TOGGLE_DAILY_SYNC_ERROR,
        payload: error
      });
    }
  };
};

export const updateBenAdminDailySync = (employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.UPDATE_BENADMIN_DAILY_SCHEDULE,
        payload: null
      });
      await IntegrationsService.updateBenAdminDailySync(employerId, config);
      dispatch({
        type: ActionTypes.UPDATE_BENADMIN_DAILY_SCHEDULE_SUCCESS,
        payload: config
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_BENADMIN_DAILY_SCHEDULE_ERROR,
        payload: error
      });
    }
  };
};

export const getBenAdminDailySchedule = (employerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_BENADMIN_DAILY_SCHEDULE,
        payload: employerId
      });
      const response = await IntegrationsService.getBenAdminDailySchedule(
        employerId
      );
      dispatch({
        type: ActionTypes.GET_BENADMIN_DAILY_SCHEDULE_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BENADMIN_DAILY_SCHEDULE_ERROR,
        payload: error
      });
    }
  };
};

export const getBenAdminSyncErrorFilters = (config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_BENADMIN_SYNC_ERROR_FILTERS,
        payload: null
      });
      const response = await IntegrationsService.getBenAdminSyncErrorFilters(
        config
      );
      if (config.syncType === SYNC_TYPE.EMPLOYEE_SYNC) {
        dispatch({
          type: ActionTypes.GET_BENADMIN_EMPLOYEE_SYNC_FILTERS_SUCCESS,
          payload: response.data
        });
      } else if (config.syncType === SYNC_TYPE.ENROLLMENT_SYNC) {
        dispatch({
          type: ActionTypes.GET_BENADMIN_ENROLLMENT_SYNC_FILTERS_SUCCESS,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BENADMIN_SYNC_ERROR_FILTERS_ERROR,
        payload: error
      });
    }
  };
};

export const getBenAdminSyncErrorList = (requestConfig) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_BENADMIN_SYNC_ERROR_LIST,
        payload: null
      });
      const response = await IntegrationsService.getBenAdminSyncErrorList(
        requestConfig
      );

      if (requestConfig.syncType === SYNC_TYPE.EMPLOYEE_SYNC) {
        dispatch({
          type: ActionTypes.GET_BENADMIN_EMPLOYEE_SYNC_ERROR_LIST_SUCCESS,
          payload: response.data
        });
      } else if (requestConfig.syncType === SYNC_TYPE.ENROLLMENT_SYNC) {
        dispatch({
          type: ActionTypes.GET_BENADMIN_ENROLLMENT_SYNC_ERROR_LIST_SUCCESS,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BENADMIN_SYNC_ERROR_LIST_ERROR,
        payload: error
      });
    }
  };
};

export const downloadBenAdminSyncErrorReport = (
  employerId,
  syncDetailsId,
  syncType
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.DOWNLOAD_BENADMIN_SYNC_ERROR_REPORT,
        payload: null
      });
      const response = await IntegrationsService.downloadBenAdminSyncErrorReport(
        employerId,
        syncDetailsId,
        syncType
      );
      dispatch({
        type: ActionTypes.DOWNLOAD_BENADMIN_SYNC_ERROR_REPORT_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.DOWNLOAD_BENADMIN_SYNC_ERROR_REPORT_ERROR,
        payload: error
      });
    }
  };
};

export const getBenAdminConfigDetails = (benAdminType, employerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_BEN_ADMIN_CONFIG_DETAILS,
        payload: null
      });
      const response = await IntegrationsService.getBenAdminConfigDetails(benAdminType, 
        employerId
      );
      dispatch({
        type: ActionTypes.GET_BEN_ADMIN_CONFIG_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BEN_ADMIN_CONFIG_DETAILS_ERROR,
        payload: error
      });
    }
  };
};
