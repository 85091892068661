import React, { Component } from 'react';
import { Label, Input } from 'components/Atoms';
import Icon from 'components/Icons';

import './Paginator.scss';

export const DEFAULT_ROWS_PER_PAGE = 30;
export const DEFAULT_ROW_PER_PAGE_OPTIONS = [30, 50, 100];

class Paginator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      currentPage: 1
    };
  }

  componentDidMount() {
    this.selectRows(this.state.rowsPerPage);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPage !== this.state.currentPage) {
      this.setState({
        currentPage: nextProps.currentPage
      });
    }
  }

  clickNext = () => {
    const { reloadList, totalPages } = this.props;
    const { currentPage, rowsPerPage } = this.state;
    if (this.state.currentPage < totalPages) {
      const newCurrentPage = currentPage + 1;
      this.setState({ currentPage: newCurrentPage });
      reloadList(newCurrentPage, rowsPerPage);
    }
  };

  clickPrevious = () => {
    const { reloadList } = this.props;
    const { currentPage, rowsPerPage } = this.state;
    if (this.state.currentPage > 1) {
      const newCurrentPage = currentPage - 1;
      this.setState({ currentPage: newCurrentPage });
      reloadList(newCurrentPage, rowsPerPage);
    }
  };

  clickFirst = () => {
    const { reloadList } = this.props;
    const { currentPage, rowsPerPage } = this.state;
    if (currentPage !== 1) {
      this.setState({ currentPage: 1 });
      reloadList(1, rowsPerPage);
    }
  };

  clickLast = () => {
    const { reloadList, totalPages } = this.props;
    const { currentPage, rowsPerPage } = this.state;
    if (currentPage !== totalPages) {
      this.setState({ currentPage: totalPages });
      reloadList(totalPages, rowsPerPage);
    }
  };

  selectRows = (rowsPerPage) => {
    const { reloadList } = this.props;
    this.setState({ currentPage: 1, rowsPerPage: rowsPerPage });
    reloadList(1, rowsPerPage);
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState({rowsPerPage});
  }

  render() {
    const { start, end, total } = this.props;
    const { rowsPerPage } = this.state;

    return (
      <div className="paginator">
        <div className="rows-per-page-selector">
          <Label>Rows per page:</Label>
          <Input
            type="select"
            name="rowsPerPage"
            value={rowsPerPage}
            onChange={(event) => this.selectRows(event.target.value)}
          >
            {DEFAULT_ROW_PER_PAGE_OPTIONS.map((rowsPerPage) => (
              <option key={rowsPerPage}>{rowsPerPage}</option>
            ))}
          </Input>
        </div>
        <Label>
          {Math.min(start, end) || 0} - {end || 0} of {total || 0}
        </Label>
        <Icon
          icon="angle-double-left"
          cursor="pointer"
          onClick={this.clickFirst}
        />
        <Icon
          icon="chevron-left"
          cursor="pointer"
          onClick={this.clickPrevious}
        />
        <Icon icon="chevron-right" cursor="pointer" onClick={this.clickNext} />
        <Icon
          icon="angle-double-right"
          cursor="pointer"
          onClick={this.clickLast}
        />
      </div>
    );
  }
}
export default Paginator;
