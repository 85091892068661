/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/integrations.actionTypes';

const initialState = {
  requestStatus: '',
  serviceError: '',
  configStatus: {
    id: null,
    value: 'false',
    lastUpdatedTs: null
  },
  bambooHRisConfig: null,
  hrIntegrations: {},
  benAdminIntegrations: {},
  employerIntegrations: {},
  dailySyncStatus: {},
  employerHRISConfigs: null,
  bambooHrisConfigFields: [],
  bambooHrSetupDetails: {},
  vendorSetupDetails: {},
  syncErrors: {},
  syncErrorFilters: {},
  benAdminConfigDetails: {},
  workdayConfigFields: [],
  workdayConfigs: [],
  integrationConfigDetails: null,
  fileDownloadError: false,
  integrationFixedErrors: {
    inProgress: false,
    error: null,
    data: null
  },
  syncJobFlock: {
    inProgress: false,
    error: null,
    data: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_EMPLOYER_INTEGRATION_DETAILS:
    case ActionTypes.GET_BAMBOO_HR_HRIS_FIELDS:
    case ActionTypes.GET_BHR_SETUP_DETAILS:
    case ActionTypes.GET_INTEGRATIONS_FEATURE_CONF:
    case ActionTypes.UPDATE_INTEGRATIONS_FEATURE_CONF:
    case ActionTypes.GET_EMPLOYER_SYNC_DETAILS:
    case ActionTypes.GET_ALL_HRIS_SYNC_DETAILS:
    case ActionTypes.GET_ALL_BENADMIN_SYNC_DETAILS:
    case ActionTypes.SAVE_BAMBOO_HRIS_CONFIG:
    case ActionTypes.UPDATE_BAMBOO_HRIS_CONFIG:
    case ActionTypes.DELETE_BAMBOO_HRIS_CONFIG:
    case ActionTypes.START_EMPLOYER_SYNC:
    case ActionTypes.START_ALL_SYNC:
    case ActionTypes.START_ALL_BENADMIN_SYNC:
    case ActionTypes.GET_LAST_SYNC_STATUS:
    case ActionTypes.TOGGLE_DAILY_SYNC:
    case ActionTypes.GET_SYNC_ERROR_FILERS:
    case ActionTypes.GET_ERROR_LIST:
    case ActionTypes.GET_LFI_SETUP_DETAILS:
    case ActionTypes.SAVE_LFI_HRIS_CONFIG:
    case ActionTypes.GET_WORKDAY_HRIS_FIELDS:
    case ActionTypes.SAVE_WORKDAY_HRIS_CONFIG:
    case ActionTypes.SAVE_FLOCK_BEN_ADMIN_CONFIG:
    case ActionTypes.VALIDATE_WORKDAY_CONFIGS:
    case ActionTypes.GET_INTEGRATION_CONFIG_DETAILS:
    case ActionTypes.GET_BAMBOO_HR_CONFIG_DETAILS:
    case ActionTypes.GET_EMPLOYER_BEN_ADMIN_DETAILS:
    case ActionTypes.GET_BEN_ADMIN_CONFIG_DETAILS:
    case ActionTypes.SAVE_WORKDAY_BEN_ADMIN_CONFIG:
    case ActionTypes.SAVE_PLATFORM_BEN_ADMIN_CONFIG:
      return {
        ...state,
        isLoading: true,
        serviceError: '',
        requestStatus: action.type
      };
    case ActionTypes.DOWNLOAD_CONFIG_FILE:
    case ActionTypes.DOWNLOAD_ERROR_REPORT: {
      return {
        ...state,
        serviceError: '',
        requestStatus: action.type
      };
    }
    case ActionTypes.GET_EMPLOYER_INTEGRATION_DETAILS_ERROR:
    case ActionTypes.GET_BAMBOO_HR_HRIS_FIELDS_ERROR:
    case ActionTypes.GET_BHR_SETUP_DETAILS_ERROR:
    case ActionTypes.GET_INTEGRATIONS_FEATURE_CONF_ERROR:
    case ActionTypes.UPDATE_INTEGRATIONS_FEATURE_CONF_ERROR:
    case ActionTypes.GET_EMPLOYER_SYNC_DETAILS_ERROR:
    case ActionTypes.GET_ALL_HRIS_SYNC_DETAILS_ERROR:
    case ActionTypes.GET_ALL_BENADMIN_SYNC_DETAILS_ERROR:
    case ActionTypes.SAVE_BAMBOO_HRIS_CONFIG_ERROR:
    case ActionTypes.UPDATE_BAMBOO_HRIS_CONFIG_ERROR:
    case ActionTypes.DELETE_BAMBOO_HRIS_CONFIG_ERROR:
    case ActionTypes.START_EMPLOYER_SYNC_ERROR:
    case ActionTypes.START_ALL_SYNC_ERROR:
    case ActionTypes.START_ALL_BENADMIN_SYNC_ERROR:
    case ActionTypes.TOGGLE_DAILY_SYNC_ERROR:
    case ActionTypes.GET_SYNC_ERROR_FILERS_ERROR:
    case ActionTypes.GET_ERROR_LIST_ERROR:
    case ActionTypes.DOWNLOAD_ERROR_REPORT_ERROR:
    case ActionTypes.DOWNLOAD_CONFIG_FILE_ERROR:
    case ActionTypes.GET_LAST_SYNC_STATUS_ERROR:
    case ActionTypes.GET_LFI_SETUP_DETAILS_ERROR:
    case ActionTypes.SAVE_LFI_HRIS_CONFIG_ERROR:
    case ActionTypes.GET_WORKDAY_HRIS_FIELDS_ERROR:
    case ActionTypes.SAVE_WORKDAY_HRIS_CONFIG_ERROR:
    case ActionTypes.SAVE_FLOCK_BEN_ADMIN_CONFIG_ERROR:
    case ActionTypes.VALIDATE_WORKDAY_CONFIGS_ERROR:
    case ActionTypes.GET_INTEGRATION_CONFIG_DETAILS_ERROR:
    case ActionTypes.GET_BAMBOO_HR_CONFIG_DETAILS_ERROR:
    case ActionTypes.GET_EMPLOYER_BEN_ADMIN_DETAILS_ERROR:
    case ActionTypes.SAVE_WORKDAY_BEN_ADMIN_CONFIG_ERROR:
    case ActionTypes.SAVE_PLATFORM_BEN_ADMIN_CONFIG_ERROR:
      return {
        ...state,
        isLoading: false,
        requestStatus: action.type,
        serviceError: action.payload
      };

    case ActionTypes.GET_EMPLOYER_INTEGRATION_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        employerHRISConfigs: action.payload
      };
    }

    case ActionTypes.GET_BAMBOO_HR_HRIS_FIELDS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        bambooHrisConfigFields: action.payload
      };
    }
    case ActionTypes.GET_BHR_SETUP_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        bambooHrSetupDetails: action.payload
      };
    }

    case ActionTypes.GET_LFI_SETUP_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        vendorSetupDetails: action.payload
      };
    }

    case ActionTypes.GET_INTEGRATIONS_FEATURE_CONF_SUCCESS:
    case ActionTypes.UPDATE_INTEGRATIONS_FEATURE_CONF_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        configStatus: action.payload
      };
    }

    case ActionTypes.UPDATE_BAMBOO_HRIS_CONFIG_SUCCESS:
    case ActionTypes.SAVE_BAMBOO_HRIS_CONFIG_SUCCESS:
    case ActionTypes.DELETE_BAMBOO_HRIS_CONFIG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        bambooHRisConfig: action.payload
      };
    }
    case ActionTypes.SAVE_LFI_HRIS_CONFIG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        lfiHRISConfig: action.payload
      };
    }
    case ActionTypes.GET_ALL_HRIS_SYNC_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        hrIntegrations: action.payload
      };
    }
    case ActionTypes.GET_ALL_BENADMIN_SYNC_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        benAdminIntegrations: action.payload
      };
    }
    case ActionTypes.GET_SYNC_ERROR_FILERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        syncErrorFilters: action.payload
      };
    }

    case ActionTypes.GET_ERROR_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        syncErrors: action.payload
      };
    }
    case ActionTypes.DOWNLOAD_CONFIG_FILE_SUCCESS:
    case ActionTypes.DOWNLOAD_ERROR_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type
      };
    }

    case ActionTypes.GET_EMPLOYER_SYNC_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        employerIntegrations: action.payload
      };
    }
    case ActionTypes.GET_LAST_SYNC_STATUS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        dailySyncStatus: action.payload
      };
    }

    case ActionTypes.TOGGLE_DAILY_SYNC_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        dailySyncStatus: { ...state.dailySyncStatus, enabled: action.payload }
      };
    }
    case ActionTypes.START_EMPLOYER_SYNC_SUCCESS:
    case ActionTypes.START_ALL_SYNC_SUCCESS:
    case ActionTypes.START_ALL_BENADMIN_SYNC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type
      };

    case ActionTypes.SAVE_WORKDAY_HRIS_CONFIG_SUCCESS:
    case ActionTypes.UPDATE_WORKDAY_HRIS_CONFIG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        workdayConfigs: action.payload
      };
    }

    case ActionTypes.VALIDATE_WORKDAY_CONFIGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type
      };
    }

    case ActionTypes.SAVE_FLOCK_BEN_ADMIN_CONFIG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        flockBenAdminConfig: action.payload
      };
    }

    case ActionTypes.SAVE_WORKDAY_BEN_ADMIN_CONFIG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        workdayBenAdminConfig: action.payload
      };
    }

    case ActionTypes.SAVE_PLATFORM_BEN_ADMIN_CONFIG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        platformBenAdminConfig: action.payload
      };
    }

    case ActionTypes.GET_BEN_ADMIN_CONFIG_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        benAdminConfigDetails: action.payload,
        requestStatus: action.type
      };
    }

    case ActionTypes.GET_BEN_ADMIN_CONFIG_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        requestStatus: action.type,
        serviceError: action.payload,
        benAdminConfigDetails: null
      };

    case ActionTypes.GET_INTEGRATION_CONFIG_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        integrationConfigDetails: action.payload,
        requestStatus: action.type
      };
    }

    case ActionTypes.GET_BAMBOO_HR_CONFIG_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        bambooConfigDetails: action.payload,
        requestStatus: action.type
      };
    }

    case ActionTypes.GET_WORKDAY_HRIS_FIELDS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        workdayConfigFields: action.payload
      };
    }

    case ActionTypes.DOWNLOAD_FILE: {
      return {
        ...state,
        fileDownloadError: false
      };
    }

    case ActionTypes.DOWNLOAD_FILE_ERROR: {
      return {
        ...state,
        fileDownloadError: action.payload
      };
    }

    case ActionTypes.GET_EMPLOYER_HRIS_FIXED_ERRORS_SUCCESS:
      return {
        ...state,
        integrationFixedErrors: {
          data: action.payload,
          error: null,
          inProgress: false
        }
      };
    case ActionTypes.GET_EMPLOYER_HRIS_FIXED_ERRORS_IN_PROGRESS:
      return {
        ...state,
        integrationFixedErrors: {
          ...state.integrationFixedErrors,
          error: null,
          inProgress: true
        }
      };
    case ActionTypes.GET_EMPLOYER_HRIS_FIXED_ERRORS_ERROR:
      return {
        ...state,
        integrationFixedErrors: {
          error: action.payload,
          inProgress: false
        }
      };
    case ActionTypes.FIX_INVALID_MAPPINGS_SUCCESS:
      return {
        ...state,
        syncJobFlock: {
          data: action.payload,
          error: null,
          inProgress: false
        }
      };
    case ActionTypes.FIX_INVALID_MAPPINGS_IN_PROGRESS:
      return {
        ...state,
        syncJobFlock: {
          error: null,
          inProgress: true
        }
      };
    case ActionTypes.FIX_INVALID_MAPPINGS_ERROR:
      return {
        ...state,
        syncJobFlock: {
          error: action.payload,
          inProgress: false
        }
      };
    case ActionTypes.GET_EMPLOYER_BEN_ADMIN_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        employerBenAdminConfigs: action.payload
      };
    }
    default:
      return {
        ...state
      };
  }
};
