/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/surveys.actionTypes';

const initialState = {
  configStatus: {
    id: null,
    value: 'false',
    lastUpdatedTs: null
  },
  surveysList: [],
  surveysListError: null,
  surveyDetails: null,
  surveyParticipants: null,
  requestStatus: '',
  serviceError: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SURVEY_FEATURE_CONF:
    case ActionTypes.UPDATE_SURVEY_FEATURE_CONF:
    case ActionTypes.GET_SURVEY_LIST:
    case ActionTypes.CREATE_SURVEY:
    case ActionTypes.UPDATE_SURVEY:
    case ActionTypes.DELETE_SURVEY:
    case ActionTypes.GET_SURVEY_DETAILS:
    case ActionTypes.SEND_SURVEY:
    case ActionTypes.PUBLISH_SURVEY:
      return {
        ...state,
        isLoading: true,
        serviceError: '',
        requestStatus: action.type
      };

    case ActionTypes.GET_SURVEY_PARTICIPANTS: {
      return {
        ...state,
        serviceError: '',
        requestStatus: action.type
      };
    }
    case ActionTypes.GET_SURVEY_FEATURE_CONF_ERROR:
    case ActionTypes.UPDATE_SURVEY_FEATURE_CONF_ERROR:
    case ActionTypes.GET_SURVEY_LIST_ERROR:
    case ActionTypes.CREATE_SURVEY_ERROR:
    case ActionTypes.UPDATE_SURVEY_ERROR:
    case ActionTypes.DELETE_SURVEY_ERROR:
    case ActionTypes.GET_SURVEY_DETAILS_ERROR:
    case ActionTypes.GET_SURVEY_PARTICIPANTS_ERROR:
    case ActionTypes.SEND_SURVEY_ERROR:
    case ActionTypes.PUBLISH_SURVEY_ERROR:
      return {
        ...state,
        isLoading: false,
        requestStatus: action.type,
        serviceError: action.payload
      };

    case ActionTypes.GET_SURVEY_FEATURE_CONF_SUCCESS:
    case ActionTypes.UPDATE_SURVEY_FEATURE_CONF_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        configStatus: action.payload
      };
    }

    case ActionTypes.DELETE_SURVEY_SUCCESS:
    case ActionTypes.PUBLISH_SURVEY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type
      };
    }
    case ActionTypes.GET_SURVEY_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        surveysList: action.payload
      };
    }

    case ActionTypes.CREATE_SURVEY_SUCCESS:
    case ActionTypes.UPDATE_SURVEY_SUCCESS:
    case ActionTypes.GET_SURVEY_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        surveyDetails: action.payload
      };
    }

    case ActionTypes.GET_SURVEY_PARTICIPANTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type,
        surveyParticipants: action.payload
      };
    }

    case ActionTypes.SEND_SURVEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        serviceError: '',
        requestStatus: action.type
      };

    default:
      return {
        ...state
      };
  }
};
