import momentTz from 'moment-timezone';
import _ from 'lodash';

/**
 * Takes a custom meeting object and returns true if meeting has started
 *
 * @param {*} oeMeeting custom meeting object with meeting details
 * @returns {Boolean} returns true if meeting has started
 */
export const isCustomMeetingOngoing = (oeMeeting) => {
  const { meetingTime, timezone, durationMinutes } = oeMeeting || {};

  if (!_.isEmpty(oeMeeting)) {
    const meetingStartTime = momentTz.tz(`${meetingTime}`, timezone).local();
    const meetingEndTime = momentTz
      .tz(`${meetingTime}`, timezone)
      .local()
      .add(durationMinutes, 'minutes');
    const currentTime = momentTz();

    return (
      currentTime.isAfter(meetingStartTime) &&
      currentTime.isBefore(meetingEndTime)
    );
  }
  return false;
};
