/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import EmployerFeatureConfigurationService from 'services/EmployerFeatureConfigurationService';
import IntegrationsService from 'services/IntegrationsService';
import moment from 'moment';
import * as ActionTypes from '../actionTypes/integrations.actionTypes';
import { MODULE_HRIS_INTEGRATION_ENABLED } from '../constants';

export const getAllHrisSyncDetails = (requestConfig) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GET_ALL_HRIS_SYNC_DETAILS, payload: null });
      const response = await IntegrationsService.getAllSyncDetails(
        requestConfig
      );
      dispatch({
        type: ActionTypes.GET_ALL_HRIS_SYNC_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_ALL_HRIS_SYNC_DETAILS_ERROR,
        payload: error
      });
    }
  };
};

export const getAllBenAdminSyncDetails = (requestConfig) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_ALL_BENADMIN_SYNC_DETAILS,
        payload: null
      });
      const response = await IntegrationsService.getAllBenAdminSyncDetails(
        requestConfig
      );
      dispatch({
        type: ActionTypes.GET_ALL_BENADMIN_SYNC_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_ALL_BENADMIN_SYNC_DETAILS_ERROR,
        payload: error
      });
    }
  };
};

export const startAllSync = (requestConfig, organizationId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.START_ALL_SYNC,
        payload: requestConfig
      });
      const response = await IntegrationsService.startAllSync(
        requestConfig,
        organizationId
      );
      dispatch({
        type: ActionTypes.START_ALL_SYNC_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.START_ALL_SYNC_ERROR,
        payload: error
      });
    }
  };
};

export const startAllBenAdminSync = (requestConfig, organizationId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.START_ALL_BENADMIN_SYNC,
        payload: requestConfig
      });
      const response = await IntegrationsService.startAllBenAdminSync(
        requestConfig,
        organizationId
      );
      dispatch({
        type: ActionTypes.START_ALL_BENADMIN_SYNC_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.START_ALL_BENADMIN_SYNC_ERROR,
        payload: error
      });
    }
  };
};

export const getEmployerIntegrationDetails = (employerId, type) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_EMPLOYER_INTEGRATION_DETAILS,
        payload: null
      });
      const response = await IntegrationsService.getEmployerIntegrationDetails(
        employerId,
        type
      );
      dispatch({
        type: ActionTypes.GET_EMPLOYER_INTEGRATION_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_EMPLOYER_INTEGRATION_DETAILS_ERROR,
        payload: error
      });
    }
  };
};

export const getBambooHRHRISFields = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_BAMBOO_HR_HRIS_FIELDS,
        payload: null
      });
      const response = await IntegrationsService.getBambooHRHRISFields();
      dispatch({
        type: ActionTypes.GET_BAMBOO_HR_HRIS_FIELDS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BAMBOO_HR_HRIS_FIELDS_ERROR,
        payload: error
      });
    }
  };
};

export const getBHRSetupDetails = (config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_BHR_SETUP_DETAILS,
        payload: null
      });
      if (config.ignoreTerminatedEmployeesCutoffDate) {
        config.ignoreTerminatedEmployeesCutoffDate = moment(
          config.ignoreTerminatedEmployeesCutoffDate
        ).format('YYYY-MM-DD');
      }
      const response = await IntegrationsService.getBHRSetupDetails(config);
      dispatch({
        type: ActionTypes.GET_BHR_SETUP_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BHR_SETUP_DETAILS_ERROR,
        payload: error
      });
    }
  };
};

export const getIntegrationFeatureConfig = (employerId) => {
  //get feature cong
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_INTEGRATIONS_FEATURE_CONF });
    try {
      let response;
      response = await EmployerFeatureConfigurationService.findConfigStatus(
        employerId,
        MODULE_HRIS_INTEGRATION_ENABLED
      );
      dispatch({
        type: ActionTypes.GET_INTEGRATIONS_FEATURE_CONF_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_INTEGRATIONS_FEATURE_CONF_ERROR,
        payload: error
      });
    }
  };
};

export const updateIntegrationFeatureConfig = (config) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.UPDATE_INTEGRATIONS_FEATURE_CONF });
      let response;
      response = await EmployerFeatureConfigurationService.updateConfigModule(
        config
      );
      response = await EmployerFeatureConfigurationService.findConfigStatus(
        config.employerId,
        MODULE_HRIS_INTEGRATION_ENABLED
      );

      dispatch({
        type: ActionTypes.UPDATE_INTEGRATIONS_FEATURE_CONF_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_INTEGRATIONS_FEATURE_CONF_ERROR,
        payload: error
      });
    }
  };
};

export const getEmployerSyncDetails = (requestConfig) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GET_EMPLOYER_SYNC_DETAILS, payload: null });
      const response = await IntegrationsService.getEmployerSyncDetails(
        requestConfig
      );
      dispatch({
        type: ActionTypes.GET_EMPLOYER_SYNC_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_EMPLOYER_SYNC_DETAILS_ERROR,
        payload: error
      });
    }
  };
};

export const saveBambooHrisConfig = (employerId, config, saveType) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.SAVE_BAMBOO_HRIS_CONFIG,
        payload: null
      });
      const response = await IntegrationsService.saveBambooHrisConfig(
        employerId,
        config,
        saveType
      );
      dispatch({
        type: ActionTypes.SAVE_BAMBOO_HRIS_CONFIG_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SAVE_BAMBOO_HRIS_CONFIG_ERROR,
        payload: error
      });
    }
  };
};

export const saveLFIConfig = (employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.SAVE_LFI_HRIS_CONFIG,
        payload: null
      });
      const response = await IntegrationsService.saveLFIHrisConfig(
        employerId,
        config
      );
      dispatch({
        type: ActionTypes.SAVE_LFI_HRIS_CONFIG_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SAVE_LFI_HRIS_CONFIG_ERROR,
        payload: error
      });
    }
  };
};

export const updateBambooHrisConfig = (employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.UPDATE_BAMBOO_HRIS_CONFIG,
        payload: null
      });
      const response = await IntegrationsService.updateBambooHrisConfig(
        employerId,
        config
      );

      dispatch({
        type: ActionTypes.UPDATE_BAMBOO_HRIS_CONFIG_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_BAMBOO_HRIS_CONFIG_ERROR,
        payload: error
      });
    }
  };
};

export const deleteBambooHrisConfig = (employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.DELETE_BAMBOO_HRIS_CONFIG,
        payload: null
      });
      const response = await IntegrationsService.deleteBambooHrisConfig(
        employerId,
        config
      );
      dispatch({
        type: ActionTypes.DELETE_BAMBOO_HRIS_CONFIG_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.DELETE_BAMBOO_HRIS_CONFIG_ERROR,
        payload: error
      });
    }
  };
};

export const startEmployerSync = (employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.START_EMPLOYER_SYNC,
        payload: null
      });
      const response = await IntegrationsService.startEmployerSync(
        employerId,
        config
      );
      dispatch({
        type: ActionTypes.START_EMPLOYER_SYNC_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.START_EMPLOYER_SYNC_ERROR,
        payload: error
      });
    }
  };
};

export const getLastSyncStatus = (employerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_LAST_SYNC_STATUS,
        payload: employerId
      });
      const response = await IntegrationsService.getLastSyncStatus(employerId);
      dispatch({
        type: ActionTypes.GET_LAST_SYNC_STATUS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_LAST_SYNC_STATUS_ERROR,
        payload: error
      });
    }
  };
};

export const toggleDailySync = (employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.TOGGLE_DAILY_SYNC,
        payload: null
      });
      await IntegrationsService.toggleDailySync(employerId, config);
      dispatch({
        type: ActionTypes.TOGGLE_DAILY_SYNC_SUCCESS,
        payload: config
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.TOGGLE_DAILY_SYNC_ERROR,
        payload: error
      });
    }
  };
};

export const getSyncErrorFilers = (
  employerId,
  syncDetailsId,
  excludeTerminatedEmployees
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_SYNC_ERROR_FILERS,
        payload: null
      });
      const response = await IntegrationsService.getSyncErrorFilers(
        employerId,
        syncDetailsId,
        excludeTerminatedEmployees
      );
      dispatch({
        type: ActionTypes.GET_SYNC_ERROR_FILERS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_SYNC_ERROR_FILERS_ERROR,
        payload: error
      });
    }
  };
};

export const getErrorList = (requestConfig) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_ERROR_LIST,
        payload: null
      });
      const response = await IntegrationsService.getErrorList(requestConfig);

      dispatch({
        type: ActionTypes.GET_ERROR_LIST_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_ERROR_LIST_ERROR,
        payload: error
      });
    }
  };
};

export const downloadErrorReport = (
  employerId,
  syncDetailsId,
  excludeTerminatedEmployees
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.DOWNLOAD_ERROR_REPORT,
        payload: null
      });
      const response = await IntegrationsService.downloadErrorReport(
        employerId,
        syncDetailsId,
        excludeTerminatedEmployees
      );
      dispatch({
        type: ActionTypes.DOWNLOAD_ERROR_REPORT_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.DOWNLOAD_ERROR_REPORT_ERROR,
        payload: error
      });
    }
  };
};

export const downloadFile = (employerId, syncDetailsId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.DOWNLOAD_FILE,
        payload: null
      });
      const response = await IntegrationsService.downloadFile(
        employerId,
        syncDetailsId
      );
      dispatch({
        type: ActionTypes.DOWNLOAD_FILE_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.DOWNLOAD_FILE_ERROR,
        payload: error
      });
    }
  };
};

export const getLFISetupDetails = (employerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_LFI_SETUP_DETAILS,
        payload: null
      });
      const response = await IntegrationsService.getLFISetupDetails(employerId);
      dispatch({
        type: ActionTypes.GET_LFI_SETUP_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_LFI_SETUP_DETAILS_ERROR,
        payload: error
      });
    }
  };
};

export const getWorkdayHRISFields = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_WORKDAY_HRIS_FIELDS,
        payload: null
      });
      const response = await IntegrationsService.getWorkdayHRISFields();
      dispatch({
        type: ActionTypes.GET_WORKDAY_HRIS_FIELDS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_WORKDAY_HRIS_FIELDS_ERROR,
        payload: error
      });
    }
  };
};

export const validateWorkdayConfigs = (employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.VALIDATE_WORKDAY_CONFIGS,
        payload: null
      });
      const response = await IntegrationsService.validateWorkdayConfigs(
        employerId,
        config
      );
      if (response.status === 200) {
        dispatch({
          type: ActionTypes.VALIDATE_WORKDAY_CONFIGS_SUCCESS,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.VALIDATE_WORKDAY_CONFIGS_ERROR,
        payload: error
      });
    }
  };
};

export const saveWorkdayConfig = (employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.SAVE_WORKDAY_HRIS_CONFIG,
        payload: null
      });
      const response = await IntegrationsService.saveWorkdayConfig(
        employerId,
        config
      );
      dispatch({
        type: ActionTypes.SAVE_WORKDAY_HRIS_CONFIG_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SAVE_WORKDAY_HRIS_CONFIG_ERROR,
        payload: error
      });
    }
  };
};

export const updateWorkdayConfig = (employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.UPDATE_WORKDAY_HRIS_CONFIG,
        payload: null
      });
      const response = await IntegrationsService.updateWorkdayConfig(
        employerId,
        config
      );
      dispatch({
        type: ActionTypes.UPDATE_WORKDAY_HRIS_CONFIG_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_WORKDAY_HRIS_CONFIG_ERROR,
        payload: error
      });
    }
  };
};

export const getIntegrationConfigDetails = (employerId, type) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_INTEGRATION_CONFIG_DETAILS,
        payload: null
      });
      const response = await IntegrationsService.getIntegrationConfigDetails(
        employerId,
        type
      );
      dispatch({
        type: ActionTypes.GET_INTEGRATION_CONFIG_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_INTEGRATION_CONFIG_DETAILS_ERROR,
        payload: error
      });
    }
  };
};

export const getBambooHRConfigDetails = (employerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_BAMBOO_HR_CONFIG_DETAILS,
        payload: null
      });
      const response = await IntegrationsService.getBambooHRConfigDetails(
        employerId
      );
      dispatch({
        type: ActionTypes.GET_BAMBOO_HR_CONFIG_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BAMBOO_HR_CONFIG_DETAILS_ERROR,
        payload: error
      });
    }
  };
};

export const getHrisIntegrationFixedErrorsByEmployerId = (employerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_EMPLOYER_HRIS_FIXED_ERRORS_IN_PROGRESS
      });
      const response = await IntegrationsService.getHrisIntegrationFixedErrorsByEmployerId(
        employerId
      );
      dispatch({
        type: ActionTypes.GET_EMPLOYER_HRIS_FIXED_ERRORS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_EMPLOYER_HRIS_FIXED_ERRORS_ERROR,
        payload: error.response
      });
    }
  };
};

export const fixInvalidMappings = (employerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.FIX_INVALID_MAPPINGS_IN_PROGRESS
      });
      const response = await IntegrationsService.fixInvalidMappings(employerId);
      dispatch({
        type: ActionTypes.FIX_INVALID_MAPPINGS_SUCCESS,
        payload: response.status
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.FIX_INVALID_MAPPINGS_ERROR,
        payload: error.response
      });
    }
  };
};

export const getEmployerBenAdminDetails = (employerId, type) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_EMPLOYER_BEN_ADMIN_DETAILS,
        payload: null
      });
      const response = await IntegrationsService.getEmployerIntegrationDetails(
        employerId,
        type
      );
      dispatch({
        type: ActionTypes.GET_EMPLOYER_BEN_ADMIN_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_EMPLOYER_BEN_ADMIN_DETAILS_ERROR,
        payload: error
      });
    }
  };
};

export const downloadConfigFile = (employerId, syncDetailsId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.DOWNLOAD_CONFIG_FILE,
        payload: null
      });
      const response = await IntegrationsService.downloadConfigFile(
        employerId,
        syncDetailsId
      );
      dispatch({
        type: ActionTypes.DOWNLOAD_CONFIG_FILE_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.DOWNLOAD_CONFIG_FILE_ERROR,
        payload: error
      });
    }
  };
};

export const saveBenAdminConfig = (benAdminType, employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.SAVE_FLOCK_BEN_ADMIN_CONFIG,
        payload: null
      });
      const response = await IntegrationsService.saveBenAdminConfig(
        benAdminType,
        employerId,
        config
      );
      dispatch({
        type: ActionTypes.SAVE_FLOCK_BEN_ADMIN_CONFIG_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SAVE_FLOCK_BEN_ADMIN_CONFIG_ERROR,
        payload: error
      });
    }
  };
};

export const savePlatformConfig = (benAdminType, employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.SAVE_PLATFORM_BEN_ADMIN_CONFIG,
        payload: null
      });
      const response = await IntegrationsService.saveBenAdminConfig(
        benAdminType,
        employerId,
        config
      );
      dispatch({
        type: ActionTypes.SAVE_PLATFORM_BEN_ADMIN_CONFIG_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SAVE_PLATFORM_BEN_ADMIN_CONFIG_ERROR,
        payload: error
      });
    }
  };
};

export const saveWorkdayBenAdminConfig = (benAdminType, employerId, config) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.SAVE_WORKDAY_BEN_ADMIN_CONFIG,
        payload: null
      });
      const response = await IntegrationsService.saveBenAdminConfig(
        benAdminType,
        employerId,
        config
      );
      dispatch({
        type: ActionTypes.SAVE_WORKDAY_BEN_ADMIN_CONFIG_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SAVE_WORKDAY_BEN_ADMIN_CONFIG_ERROR,
        payload: error
      });
    }
  };
};

export const getBenAdminConfigDetails = (benAdminType, employerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_BEN_ADMIN_CONFIG_DETAILS,
        payload: null
      });
      const response = await IntegrationsService.getBenAdminConfigDetails(benAdminType,
        employerId
      );
      dispatch({
        type: ActionTypes.GET_BEN_ADMIN_CONFIG_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_BEN_ADMIN_CONFIG_DETAILS_ERROR,
        payload: error
      });
    }
  };
};
