/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import {
  BENEFIT_KIND_BASIC_LIFE,
  BENEFIT_KIND_BASIC_STD,
  BENEFIT_KIND_BASIC_LTD,
  BENEFIT_KIND_BASIC_LIFE_ADD,
  BENEFIT_KIND_VOLUNTARY_LIFE,
  BENEFIT_KIND_VOLUNTARY_LTD,
  BENEFIT_KIND_VOLUNTARY_STD,
  BENEFIT_KIND_DISPLAY_MAP
} from '../../constants/benefitConstants';

export const MODULE_BILLING_ENABLED = 'MODULE_BILLING_ENABLED';

/**
 * BILLING_FILE_TYPES_DISPLAY_MAP should match the billing file types enum from billing service
 */
export const DETAIL_FILE_TYPE = 'DETAIL';
export const SUMMARY_FILE_TYPE = 'SUMMARY';
export const SUMMARY_AND_DETAIL_FILE_TYPE = 'SUMMARY_AND_DETAIL';
export const CARRIER = 'CARRIER';

export const BILLING_FILE_TYPES_DISPLAY_MAP = {};
BILLING_FILE_TYPES_DISPLAY_MAP[DETAIL_FILE_TYPE] = 'Detailed';
BILLING_FILE_TYPES_DISPLAY_MAP[SUMMARY_FILE_TYPE] = 'Summary';
BILLING_FILE_TYPES_DISPLAY_MAP[SUMMARY_AND_DETAIL_FILE_TYPE] =
  'Summary and Detail';
/**
 * BILLING_FILE_STATUS_DISPLAY_MAP should match the billing file status enum from billing service
 */
export const BILLING_FILE_PUBLISHED = 'PUBLISHED';
export const BILLING_FILE_DRAFT = 'DRAFT';
export const BILLING_FILE_VALIDATE = 'VALIDATE';
export const BILLING_FILE_STATUS_DISPLAY_MAP = {};
BILLING_FILE_STATUS_DISPLAY_MAP[BILLING_FILE_PUBLISHED] = 'Published';
BILLING_FILE_STATUS_DISPLAY_MAP[BILLING_FILE_DRAFT] = 'Draft';

// These benefit types display text are different in billing module
export const BENEFIT_KIND_VOLUNTARY_AD_AND_D = 'VOLUNTARY_ADD';
export const BENEFIT_KIND_BASIC_AD_AND_D = 'BASIC_ADD';
export const BENEFIT_KIND_FML = 'FML';
export const BENEFIT_KIND_ADA = 'ADA';
export const BENEFIT_KIND_IDI = 'IDI';
export const BENEFIT_KIND_LUMITY = 'LUMITY';
export const BENEFIT_KIND_ACCIDENT = 'ACCIDENT';
export const BENEFIT_KIND_VOLUNTARY_HOSPITAL_INDEMNITY =
  'VOLUNTARY_HOSPITAL_INDEMNITY';
export const BENEFIT_KIND_VOLUNTARY_CRITICAL_ILLNESS =
  'VOLUNTARY_CRITICAL_ILLNESS';
export const BENEFIT_KIND_VOLUNTARY_LIFE_EMPLOYEE = 'VOLUNTARY_LIFE_EMPLOYEE';
export const BENEFIT_KIND_VOLUNTARY_LIFE_SPOUSE = 'VOLUNTARY_LIFE_SPOUSE';
export const BENEFIT_KIND_VOLUNTARY_LIFE_CHILD = 'VOLUNTARY_LIFE_CHILD';
export const BENEFIT_KIND_VOLUNTARY_ADD_EMPLOYEE = 'VOLUNTARY_ADD_EMPLOYEE';
export const BENEFIT_KIND_VOLUNTARY_ADD_SPOUSE = 'VOLUNTARY_ADD_SPOUSE';
export const BENEFIT_KIND_VOLUNTARY_ADD_CHILD = 'VOLUNTARY_ADD_CHILD';
export const BENEFIT_KIND_NY_DBL = 'NY_DBL';
export const BENEFIT_KIND_NY_PFL = 'NY_PFL';
export const BENEFIT_KIND_HI_TDI = 'HI_TDI';
export const BENEFIT_KIND_VOLUNTARY_LIFE_ADD = 'VOLUNTARY_LIFE_ADD';
export const BENEFIT_KIND_VOLUNTARY_LIFE_ADD_EMPLOYEE =
  'VOLUNTARY_LIFE_ADD_EMPLOYEE';
export const BENEFIT_KIND_VOLUNTARY_LIFE_ADD_SPOUSE =
  'VOLUNTARY_LIFE_ADD_SPOUSE';
export const BENEFIT_KIND_VOLUNTARY_LIFE_ADD_CHILD = 'VOLUNTARY_LIFE_ADD_CHILD';
export const BENEFIT_KIND_STATE_DISABILITY = 'STATE_DISABILITY';
export const BENEFIT_KIND_DISCOUNT = 'DISCOUNT';

export const ACTIVE_BILL_STATUS_PLAN_TYPES = [
  BENEFIT_KIND_BASIC_LIFE,
  BENEFIT_KIND_BASIC_LIFE_ADD,
  BENEFIT_KIND_BASIC_LTD,
  BENEFIT_KIND_BASIC_STD,
  BENEFIT_KIND_VOLUNTARY_LIFE,
  BENEFIT_KIND_VOLUNTARY_LTD,
  BENEFIT_KIND_VOLUNTARY_STD,
  BENEFIT_KIND_VOLUNTARY_AD_AND_D,
  BENEFIT_KIND_BASIC_AD_AND_D,
  BENEFIT_KIND_FML,
  BENEFIT_KIND_ADA,
  BENEFIT_KIND_IDI,
  BENEFIT_KIND_LUMITY,
  BENEFIT_KIND_ACCIDENT,
  BENEFIT_KIND_VOLUNTARY_HOSPITAL_INDEMNITY,
  BENEFIT_KIND_VOLUNTARY_CRITICAL_ILLNESS,
  BENEFIT_KIND_VOLUNTARY_LIFE_EMPLOYEE,
  BENEFIT_KIND_VOLUNTARY_LIFE_SPOUSE,
  BENEFIT_KIND_VOLUNTARY_LIFE_CHILD,
  BENEFIT_KIND_VOLUNTARY_ADD_EMPLOYEE,
  BENEFIT_KIND_VOLUNTARY_ADD_SPOUSE,
  BENEFIT_KIND_VOLUNTARY_ADD_CHILD,
  BENEFIT_KIND_NY_DBL,
  BENEFIT_KIND_NY_PFL,
  BENEFIT_KIND_HI_TDI,
  BENEFIT_KIND_NY_PFL,
  BENEFIT_KIND_VOLUNTARY_LIFE_ADD,
  BENEFIT_KIND_VOLUNTARY_LIFE_ADD_EMPLOYEE,
  BENEFIT_KIND_VOLUNTARY_LIFE_ADD_SPOUSE,
  BENEFIT_KIND_VOLUNTARY_LIFE_ADD_CHILD,
  BENEFIT_KIND_STATE_DISABILITY,
  BENEFIT_KIND_DISCOUNT
];

export const PLAN_TYPE_DISPLAY_MAP = { ...BENEFIT_KIND_DISPLAY_MAP };
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_AD_AND_D] = 'Voluntary AD&D';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_BASIC_AD_AND_D] = 'Basic AD&D';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_FML] = 'FML';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_ADA] = 'ADA';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_IDI] = 'IDI';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_LUMITY] = 'Lumity';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_ACCIDENT] = 'Accident';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_HOSPITAL_INDEMNITY] =
  'Voluntary Hospital Indemnity';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_CRITICAL_ILLNESS] =
  'Voluntary Critical Illness';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_LIFE_EMPLOYEE] =
  'Voluntary Life - Employee';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_LIFE_SPOUSE] =
  'Voluntary Life - Spouse';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_LIFE_CHILD] =
  'Voluntary Life - Child';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_ADD_EMPLOYEE] =
  'Voluntary AD&D - Employee';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_ADD_SPOUSE] =
  'Voluntary AD&D - Spouse';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_ADD_CHILD] =
  'Voluntary AD&D - Child';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_NY_DBL] = 'NY DBL';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_NY_PFL] = 'NY PFL';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_HI_TDI] = 'HI TDI';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_LIFE_ADD] =
  'Voluntary Life and AD&D';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_LIFE_ADD_EMPLOYEE] =
  'Voluntary Life and AD&D - Employee';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_LIFE_ADD_SPOUSE] =
  'Voluntary Life and AD&D - Spouse';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_LIFE_ADD_CHILD] =
  'Voluntary Life and AD&D - Child';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_STATE_DISABILITY] = 'State Disability';
PLAN_TYPE_DISPLAY_MAP[BENEFIT_KIND_DISCOUNT] = 'Discount';

export const BILLING_STATUS_ACTIVE = 'ACTIVE';
export const BILLING_STATUS_COBRA = 'COBRA';
export const BILLING_STATUS_DISPLAY_MAP = {};
BILLING_STATUS_DISPLAY_MAP[BILLING_STATUS_ACTIVE] = 'Active';
BILLING_STATUS_DISPLAY_MAP[BILLING_STATUS_COBRA] = 'Cobra';
export const XLSX = '.xlsx';
export const BILLING_MONTH_FORMAT = 'MMMM YYYY';
