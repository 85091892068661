import React from 'react';
import { Popover, PopoverHeader, PopoverBody, Button } from 'components/Atoms';

import './ConfirmationPopover.scss';
import { OE_MEETING_CONFIG } from 'modules/BenGuides/constants';

const ConfirmationPopover = (props) => {
  const {
    placement,
    isOpen,
    target,
    toggle,
    onConfirm,
    onDecline,
    meetingConfig,
    isToggleEnabled,
    ...rest
  } = props;

  const unSelectConfig =
    meetingConfig === OE_MEETING_CONFIG.ZOOM.value
      ? OE_MEETING_CONFIG.CUSTOM.value
      : OE_MEETING_CONFIG.ZOOM.value;

  return (
    <Popover
      placement="left"
      target={target}
      isOpen={isOpen}
      toggle={toggle}
      {...rest}
    >
      <PopoverHeader>CHANGE OE VIDEO CONFIGURATION</PopoverHeader>
      <PopoverBody>
        {isToggleEnabled ? (
          <div className="popover-message text">
            {`Are you sure you want to switch off ${OE_MEETING_CONFIG[meetingConfig].name}
            configuration? This will reset any previously saved settings`}
          </div>
        ) : (
          <div className="popover-message text">
            {`Are you sure you want to switch to
          ${OE_MEETING_CONFIG[meetingConfig].name}
          configuration? Any settings for your
          ${OE_MEETING_CONFIG[unSelectConfig].name}
          configuration will be overwritten`}
          </div>
        )}
        <div className="popover-buttons">
          <Button onClick={onConfirm} color="link">
            Yes, Continue
          </Button>
          <Button onClick={onDecline} color="link">
            Nevermind
          </Button>
        </div>
      </PopoverBody>
    </Popover>
  );
};

export default ConfirmationPopover;
