import React from 'react';
import ReactQuill from 'react-quill';

import { FormGroup, Label, FormText } from 'components/Atoms';

import './TextEditor.scss';

class TextEditor extends React.Component {
  static modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean']
    ]
  };

  static formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
    'link'
  ];

  constructor(props) {
    super(props);
    this.textEditorRef = React.createRef();
  }

  componentDidMount() {
    this.forceUpdate();
  }

  checkCharacterCount = (event) => {
    const { limit } = this.props;
    if (this.getCharacterTextLength() >= limit && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  getCharacterTextLength = () => {
    // As per https://quilljs.com/docs/api/#gettext getLength() will return 1 even when blank
    if (this.textEditorRef.current) {
      return this.textEditorRef.current.getEditor().getLength() - 1;
    }
    return 0;
  };

  onFocusText = (event) => {
    const { limit } = this.props;
    if (this.getCharacterTextLength() >= limit && event.key !== 'Backspace') {
      let text = this.textEditorRef.getEditor().getText();
      text = text.substring(0, limit);
      this.textEditorRef.getEditor().setText(text);
    }
  };

  // Checks to see if the input is meant to be empty by comparing to <p><br></p>.
  // See https://github.com/quilljs/quill/issues/1328
  sanitizeEmptyInput = (value) => {
    const { onChange } = this.props;
    if (value === '<p><br></p>') {
      return onChange('');
    } else {
      return onChange(value);
    }
  };

  // TODO: Add inputName attribute and modify onChange from (value) => any to (name, value) => any
  render() {
    const { className, inputValue, label, limit, disabled, placeholder } = this.props;
    return (
      <FormGroup className={`text-editor ${className || ''}`}>
        <Label>{label}</Label>
        <ReactQuill
          style={disabled ? { backgroundColor: '#E9ECEF' } : null}
          value={inputValue}
          onKeyDown={this.checkCharacterCount}
          onFocus={this.onFocusText}
          onChange={this.sanitizeEmptyInput}
          modules={TextEditor.modules}
          formats={TextEditor.formats}
          ref={this.textEditorRef}
          readOnly={disabled}
          placeholder={placeholder}
        />
        {limit && (
          <FormText className="character-count-text">
            {`Max Character Count: ${this.getCharacterTextLength()}/${limit}`}
          </FormText>
        )}
      </FormGroup>
    );
  }
}

export default TextEditor;
