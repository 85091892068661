/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import axios from 'axios';
import Util, {
  CONTENT_TYPE_MULTIPART_FORM,
  CONTENT_TYPE_HEADER
} from '../util/apiUtil';
import { EmployerTypesList } from 'modules/Employers/constants';

//configs
const baseURL = `${Util.baseApi}/${Util.version}/employers`;
const planURL = `${Util.baseApi}/${Util.version}/plans`;
const electionURL = `${Util.baseApi}/${Util.version}/elections`;
const benguideURL = `${Util.baseApi}/${Util.version}/benefit-guides`;

export default {
  /**
   * get all employers
   *
   * @returns {AxiosPromise<any>}
   */
  findAll(
    page,
    size,
    sort,
    query,
    types = EmployerTypesList[0].value,
    locations,
    archived,
    organizationId
  ) {
    const params = {
      types: types,
      locations,
      archived: archived,
      _page: page,
      _size: size,
      _sort: sort
    };
    if (query) {
      params['query'] = encodeURIComponent(query);
    }
    params['organizationId'] = organizationId;
    return axios.get(baseURL, { params: params });
  },

  /**
   * get employer details for given employer Id.
   *
   * @param string
   * @returns {AxiosPromise<any>}
   */
  findById(id) {
    return axios.get(`${baseURL}/${id}`);
  },

  /**
   * create new employer.
   *
   * @param payload - Employer payload
   * @returns {AxiosPromise<any>}
   */
  create(payload) {
    return axios.post(`${baseURL}`, payload);
  },

  /**
   * update existing employer.
   *
   * @param id - Employer Id.
   * @param payload - Employer payload
   * @returns {AxiosPromise<any>}
   */
  update(id, payload) {
    return axios.put(`${baseURL}/${id}`, payload);
  },

  uploadLogo(employerId, file) {
    const formData = new FormData();
    formData.append('file', file, file.filename);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };
    return axios.post(`${baseURL}/${employerId}/logo`, formData, config);
  },

  uploadResource(employerId, documentType, fileName, fileBlob) {
    const formData = new FormData();
    formData.append('file', fileBlob, fileName);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };
    return axios.post(
      `${baseURL}/${employerId}/documents?documentType=${documentType}`,
      formData,
      config
    );
  },

  deleteResource(employerId, documentType) {
    return axios.delete(
      `${baseURL}/${employerId}/documents?documentType=${documentType}`
    );
  },

  updateAdditionalDocuments(employerId, additionalDocuments) {
    const formData = new FormData();
    // Using a JSON object wrapper to prevent sending a JSON array.
    const documentDetails = { unwrappedList: [] };

    additionalDocuments.forEach((document) => {
      const { title, id, fileName, file } = document;

      // Extract all the metadata for each document.
      documentDetails.unwrappedList.push({
        title: title,
        id: id,
        fileName: fileName
      });

      // Appending multiple files under the same parameter creates a list.
      if (file) {
        formData.append('documents', file, fileName);
      } else {
        // Need to upload *something* even if no file exists, to preserve order.
        formData.append('documents', new Blob(), '');
      }
    });

    // Send the JSON object as a file to be received properly on backend.
    formData.append(
      'document-details',
      new Blob([JSON.stringify(documentDetails)], { type: 'application/json' })
    );

    // Purposefully leaving out ContentType header so that backend knows to read
    // document-details as a JSON object instead of multipart file.
    return axios.post(`${baseURL}/${employerId}/additionalDocuments`, formData);
  },

  createGroup(employerId, benefitGroup, planYearId) {
    return axios.post(
      `${baseURL}/${employerId}/plan-years/${planYearId}/groups`,
      benefitGroup
    );
  },

  deleteGroup(employerId, benefitGroup, planYearId) {
    return axios.delete(
      `${baseURL}/${employerId}/plan-years/${planYearId}/groups?group=${encodeURIComponent(
        benefitGroup
      )}`
    );
  },

  findAllPlanYears(employerId) {
    return axios.get(`${baseURL}/${employerId}/plan-years`);
  },

  createPlanYear(employerId, payload) {
    return axios.post(`${baseURL}/${employerId}/plan-years`, payload);
  },

  updatePlanYear(employerId, planYearId, selectedPlanYear) {
    return axios.put(
      `${baseURL}/${employerId}/plan-years/${planYearId}`,
      selectedPlanYear
    );
  },

  deletePlanYear(employerId, planYearId, validate) {
    return axios.delete(`${baseURL}/${employerId}/plan-years/${planYearId}`, {
      params: { validate }
    });
  },

  changeCurrentPlanYear(employerId, planYearId) {
    return axios.post(
      `${baseURL}/${employerId}/plan-years/current/${planYearId}`
    );
  },

  getPlanYearByType(employerId, planYearType) {
    return axios.get(
      `${baseURL}/${employerId}/plan-years?planYearType=${planYearType}`
    );
  },

  //Plan Year Migration related APIs
  getPotentialPlanYears(employerId) {
    return axios.get(
      `${planURL}/potential-plan-years?employerId=${employerId}`
    );
  },

  getAssociatedPlanCount(employerId, masterIdList) {
    return axios.post(
      `${planURL}/count?employerId=${employerId}`,
      masterIdList
    );
  },

  getAssociatedElectionCount(employerId, masterIdList) {
    return axios.post(
      `${electionURL}/count?employerId=${employerId}`,
      masterIdList
    );
  },

  getAssociatedBenguides(employerId, planYearId) {
    return axios.get(
      `${benguideURL}/${planYearId}/count?employerId=${employerId}`
    );
  },

  associatePlanYearWithPlans(employerId, planYearId, payload) {
    return axios.put(
      `${planURL}/plan-years/${planYearId}?employerId=${employerId}`,
      payload
    );
  },

  associatePlanYearWithElections(employerId, planYearId, payload) {
    return axios.put(
      `${electionURL}/plan-years/${planYearId}?employerId=${employerId}`,
      payload
    );
  },

  associatePlanYearWithBenguides(employerId, planYearId, benguideIdList) {
    return axios.put(
      `${benguideURL}/plan-years/${planYearId}?employerId=${employerId}`,
      benguideIdList
    );
  },

  deleteEmployer(employerId) {
    return axios.delete(`${baseURL}/${employerId}`);
  },

  archiveEmployer(employerId) {
    return axios.post(`${baseURL}/${employerId}/archive`);
  },

  getSupportTeam(employerId) {
    return axios.get(`${baseURL}/${employerId}/team`);
  },

  updateSupportTeam(employerId, team) {
    return axios.put(`${baseURL}/${employerId}/team`, team);
  },

  saveSSOConfig(employerId, ssoConfig) {
    return axios.put(`${baseURL}/${employerId}/sso-config`, ssoConfig);
  },

  findAllByEmployerType(type, archived, orgId) {
    const params = {
      employerTypes: type,
      organizationId: orgId
    };
    return axios.get(`${baseURL}/list?archived=${archived}`, {
      params: params
    });
  },

  getEmployerIndustries() {
    return axios.get(`${baseURL}/industries`);
  },

  associateBenefitGroupsForPlanYear(employerId, planYearId) {
    const params = {
      employerId,
      planYearId
    };
    return axios.put(`${baseURL}/plan-years/benefit-groups`, null, { params });
  }
};
