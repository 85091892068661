/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
const COMMON = [
  '.pdf',
  '.csv',
  '.xlsx',
  '.png',
  '.jpeg',
  '.jpg',
  '.xls',
  '.pptx',
  '.ppt',
  '.docx',
  '.doc'
];

export const DOCUMENT_ICONS = {
  doc: 'file-word',
  docx: 'file-word',
  folder: 'folder',
  lock: 'lock',
  pdf: 'file-pdf',
  pptx: 'file-ppt',
  ppt: 'file-ppt',
  xls: 'file-excel',
  xlsx: 'file-excel',
  csv: 'file-text',
  jpeg: 'file-jpg',
  jpg: 'file-jpg',
  png: 'file-image',
  mp4: 'video-camera',
  unknown: 'file-unknown'
};

export const FileExtension = {
  PDF: '.pdf',
  PNG: '.png',
  OFFICE_OPEN_XML_SPREADSHEET: '.xlsx'
};

export const ATTACHMENT_SIZE_1000 = 1048576000; //1000MB
export const ATTACHMENT_SIZE_250 = 262144000; // 250MB
export const ATTACHMENT_SIZE_100 = 104857600; // 100MB
export const ATTACHMENT_SIZE_50 = 52428800; // 50MB

export const FILE_TYPE_SUPPORT = {
  DOCUMENTS: [...COMMON, '.mp4'],
  ISSUE_COMMENT: [...COMMON, '.zip']
};
