/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const GET_ADMIN_USER_LIST_IN_PROGRESS = 'GET_ADMIN_USER_LIST_IN_PROGRESS';
export const GET_ADMIN_USER_LIST_COMPLETED = 'GET_ADMIN_USER_LIST_COMPLETED';
export const GET_ADMIN_USER_LIST_FAILED = 'GET_ADMIN_USER_LIST_FAILED';

export const GET_ADMIN_USER_IN_PROGRESS = 'GET_ADMIN_USER_IN_PROGRESS';
export const GET_ADMIN_USER_COMPLETED = 'GET_ADMIN_USER_COMPLETED';
export const GET_ADMIN_USER_FAILED = 'GET_ADMIN_USER_FAILED';

export const UPDATE_ADMIN_USER_IN_PROGRESS = 'UPDATE_ADMIN_USER_IN_PROGRESS';
export const UPDATE_ADMIN_USER_COMPLETED = 'UPDATE_ADMIN_USER_COMPLETED';
export const UPDATE_ADMIN_USER_FAILED = 'UPDATE_ADMIN_USER_FAILED';

export const GET_PUBLIC_ADMIN_PROFILE_IN_PROGRESS = 'GET_PUBLIC_ADMIN_PROFILE_IN_PROGRESS';
export const GET_PUBLIC_ADMIN_PROFILE_COMPLETED = 'GET_PUBLIC_ADMIN_PROFILE_COMPLETED';
export const GET_PUBLIC_ADMIN_PROFILE_FAILED = 'GET_PUBLIC_ADMIN_PROFILE_FAILED';

export const UPDATE_PUBLIC_ADMIN_PROFILE_IN_PROGRESS = 'UPDATE_PUBLIC_ADMIN_PROFILE_IN_PROGRESS';
export const UPDATE_PUBLIC_ADMIN_PROFILE_COMPLETED = 'UPDATE_PUBLIC_ADMIN_PROFILE_COMPLETED';
export const UPDATE_PUBLIC_ADMIN_PROFILE_FAILED = 'UPDATE_PUBLIC_ADMIN_PROFILE_FAILED';

export const CREATE_ADMIN_USER_IN_PROGRESS = 'CREATE_ADMIN_USER_IN_PROGRESS';
export const CREATE_ADMIN_USER_COMPLETED = 'CREATE_ADMIN_USER_COMPLETED';
export const CREATE_ADMIN_USER_FAILED = 'CREATE_ADMIN_USER_FAILED';

export const ARCHIVE_ADMIN_USER_IN_PROGRESS = 'ARCHIVE_ADMIN_USER_IN_PROGRESS';
export const ARCHIVE_ADMIN_USER_COMPLETED = 'ARCHIVE_ADMIN_USER_COMPLETED';
export const ARCHIVE_ADMIN_USER_FAILED = 'ARCHIVE_ADMIN_USER_FAILED';
