/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';

import { Col, Row } from 'components/Atoms';
import './ChatbotConfigMessage.scss';
import { permitIf } from 'components/hoc/Permit';
import AddButton from 'components/Buttons/AddButton';
import { LUMITY_CONSULT, UPDATE_CHATBOT_TYPE_SLACK } from '../constants';
import { MOBILE_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import Message from './Messages';

class SlackChatBotMessageConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      welcomeMessage: props.welcomeMessage,
      farewellMessage: props.farewellMessage,
      slackTeamId: props.slackTeamId,
      slackAuthorization: props.slackAuthorization,
      slackAuthorizationUsername: props.slackAuthorizationUsername,
      fallbackOptions: {
        customerSupportCompatible: true,
        lumityConsultCompatible: props.lumityConsultCompatible
      },
      isDisabled: true
    };
  }

  componentDidMount() {
    const { bConsultationConfigStatus } = this.props;
    const { fallbackOptions } = this.state;
    if (
      bConsultationConfigStatus &&
      bConsultationConfigStatus.value === 'false' &&
      fallbackOptions.lumityConsultCompatible
    ) {
      const { fallbackOptions } = this.state;
      let fallbkOptions = {
        customerSupportCompatible: fallbackOptions.customerSupportCompatible,
        lumityConsultCompatible: false
      };
      this.setState({ fallbackOptions: fallbkOptions }, () => {
        this.onSaveChatbotConfigs();
      });
    }
  }

  onSaveChatbotConfigs = () => {
    const {
      onSaveChatbotConfigs,
      slackTeamId,
      slackAuthorization
    } = this.props;
    const { welcomeMessage, farewellMessage, fallbackOptions } = this.state;
    const config = {
      slackTeamId: (slackTeamId != null && slackTeamId.trim() !== '-' ? slackTeamId : null),
      slackAuthorization: (slackAuthorization != null && slackAuthorization.trim() !== '-' ? slackAuthorization : null),
      welcomeMessage: welcomeMessage,
      farewellMessage: farewellMessage,
      fallbackOptions: fallbackOptions,
      chatbotType: UPDATE_CHATBOT_TYPE_SLACK
    };
    onSaveChatbotConfigs(config);
  };

  onChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    this.setState({ [name]: value }, () => {
      this.checkDisability();
    });
  };

  switchHandler = (checked) => {
    const { fallbackOptions } = this.state;
    fallbackOptions[LUMITY_CONSULT] = checked;
    this.setState({ fallbackOptions }, () => {
      this.checkDisability();
    });
  };

  checkDisability = () => {
    let isDisabled;
    const { welcomeMessage, farewellMessage, fallbackOptions } = this.state;
    const wMessage = this.props.welcomeMessage;
    const fMessage = this.props.farewellMessage;
    const lumityConsultation = this.props.lumityConsultCompatible;
    isDisabled =
      wMessage === welcomeMessage &&
      fMessage === farewellMessage &&
      fallbackOptions &&
      fallbackOptions.lumityConsultCompatible === lumityConsultation;
    this.setState({ isDisabled });
  };

  render() {
    const {
      welcomeMessage,
      farewellMessage,
      fallbackOptions,
      slackTeamId,
      slackAuthorization,
      slackAuthorizationUsername,
      isDisabled
    } = this.state;
    const { bConsultationConfigStatus } = this.props;
    const SecuredAddButton = permitIf(
      AddButton,
      MOBILE_FEATURE_ROLE_MAPPING.common
    );

    return (
      <div className="chatbot-message-container">
        <Message
          {...this.state}
          bConsultationConfigStatus={bConsultationConfigStatus}
          onChange={this.onChange}
          switchHandler={this.switchHandler}
        />
        <Row className="message-row">
          <Col sm={2} className="message-label">
            <label className="title">Team ID</label>
          </Col>
          <Col sm={10} className="message-text">
            <label className="title">{slackTeamId}</label>
          </Col>
        </Row>

        <Row className="message-row">
          <Col sm={2} className="message-label">
            <label className="title">Authorization</label>
          </Col>
          <Col sm={10} className="message-text">
            <label className="title">{slackAuthorizationUsername}</label>
          </Col>
        </Row>
        <Row className="save-chatbot-btn">
          <SecuredAddButton
            classes="btn-lg"
            onClick={this.onSaveChatbotConfigs}
            title={'Save'}
            color="primary"
            disabled={
              (welcomeMessage == null || welcomeMessage.trim() === '') ||
              (farewellMessage == null || farewellMessage.trim() === '') ||
              (!fallbackOptions.customerSupportCompatible &&
                !fallbackOptions.lumityConsultCompatible) ||
              isDisabled
            }
          />
        </Row>
      </div>
    );
  }
}

export default SlackChatBotMessageConfig;
