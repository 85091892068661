/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { connect } from 'react-redux';
import Icon from 'components/Icons';
import {
  Row,
  Col,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'components/Atoms';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import NotificationManager from 'components/Notifications';

import moment from 'moment';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import ApplicationWrapper from 'containers/ApplicationWrapper/ApplicationWrapper';
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import './List.scss';
import DataTableWithServerPagination from 'components/DataTables';
import {
  getBenGuidesPage,
  previewBenGuide,
  deleteBenguide,
  checkPublishedVersionExists
} from '../../actions/benGuidePlanAction';
import { generatePath } from 'react-router-dom';
import {
  BEN_GUIDES_CLONE_PLAN_PATH,
  BEN_GUIDES_CREATE_PATH,
  BEN_GUIDES_EDIT_PLAN_PATH,
  BEN_GUIDES_VIEW_PLAN_PATH
} from '../../routes';
import { BENGUIDE_PUBLISHED, OE_MEETING_CONFIG } from '../../constants';
import { BEN_GUIDE_FEATURE_ROLE_MAPPING } from 'modules/app-base/constants/roleFeaturesMap';
import { permitIf } from 'components/hoc/Permit';
import { getIsRolePermitted } from 'util/roleUtil';
import ScheduleMeeting from './components/ScheduleMeeting/ScheduleMeeting';
import { OE_MEETING_STATES } from 'modules/BenGuides/constants';
import momentTz from 'moment-timezone';
import _ from 'lodash';
import PlanYearFilter from 'modules/Plans/pages/components/PlanYearFilter';
import PlanYearField from 'modules/Plans/pages/components/PlanYearField';
import { getPlanYears } from 'modules/Employers/actions/planYearActions';
import 'modules/Plans/pages/PlanPage.scss';
import { CUSTOM_STYLES } from 'modules/Plans/constants/planServiceConstants';
import { getCurrentAndNewPlanYearIds } from 'modules/Plans/util/planServiceUtil';
import DeleteBenguideModal from './components/DeleteBenguideModal/DeleteBenguideModal';
import CloneBenguideModal from './components/CloneBenguideModal/CloneBenguideModal';
class BenGuideList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMeetingPopupOpen: false,
      selectedBenGuide: null,
      planYears: [],
      selectedPlanYearIds: '',
      isPlanYearsValidated: false,
      isDeleteBengModalOpen: false,
      isCloneModalOpen: false
    };

    this.benguideListTable = React.createRef();
  }

  componentDidMount() {
    const { match, getPlanYears } = this.props;
    const { employerId } = match.params;
    getPlanYears(employerId);
  }

  componentDidUpdate(prevProps) {
    const { benguidePreview, isFetchingPlanYears, planYears } = this.props;
    if (
      benguidePreview.error &&
      prevProps.benguidePreview.error !== benguidePreview.error
    ) {
      NotificationManager.error('Incomplete benguide.');
    }

    if (
      !isFetchingPlanYears &&
      isFetchingPlanYears !== prevProps.isFetchingPlanYears &&
      planYears.length > 0
    ) {
      this.setState({ planYears: planYears });
      this.updateTypeFilter(getCurrentAndNewPlanYearIds(planYears));
    }
    if (
      isFetchingPlanYears !== prevProps.isFetchingPlanYears &&
      !isFetchingPlanYears &&
      _.isEmpty(planYears)
    ) {
      this.setState({ isPlanYearsValidated: true });
    }
  }

  create = () => {
    const { match } = this.props;
    this.props.history.push(
      generatePath(BEN_GUIDES_CREATE_PATH, { ...match.params })
    );
  };

  editBenGuide = (event, benGuide) => {
    event.preventDefault();
    const { match, appBootupInfo } = this.props;
    const { employerId } = match.params;
    const { roles } = appBootupInfo;

    if (getIsRolePermitted(roles, BEN_GUIDE_FEATURE_ROLE_MAPPING.common)) {
      if (employerId && benGuide) {
        this.props.history.push(
          generatePath(BEN_GUIDES_EDIT_PLAN_PATH, {
            ...match.params,
            benGuideId: benGuide.id,
            type: 'edit'
          })
        );
      }
    }
  };

  clone = (id) => {
    const { match } = this.props;
    const { employerId } = match.params;
    if (employerId && id) {
      this.props.history.push(
        generatePath(BEN_GUIDES_CLONE_PLAN_PATH, {
          ...match.params,
          benGuideId: id,
          type: 'clone'
        })
      );
    }
  };

  viewBenGuide = (event, benGuide) => {
    event.preventDefault();
    const { match } = this.props;
    const { employerId } = match.params;
    if (employerId && benGuide) {
      this.props.history.push(
        generatePath(BEN_GUIDES_VIEW_PLAN_PATH, {
          ...match.params,
          benGuideId: benGuide.id,
          type: 'view'
        })
      );
    }
  };

  previewBenguide = (event, benguide) => {
    event.preventDefault();
    this.props.previewBenGuide(benguide.id);
  };

  getPageActions = () => {
    const SecuredButton = permitIf(
      Button,
      BEN_GUIDE_FEATURE_ROLE_MAPPING.common
    );
    return (
      <div key={1} className="float-right">
        <Row>
          <Col>
            <SecuredButton color="primary" onClick={this.create} size="lg">
              <Icon icon="plus" className="mr-2" />
              Add New BenGuide
            </SecuredButton>
          </Col>
        </Row>
      </div>
    );
  };

  toggleMeetingPopup = () => {
    const { isMeetingPopupOpen } = this.state;
    this.setState({ isMeetingPopupOpen: !isMeetingPopupOpen });

    if (isMeetingPopupOpen) {
      const { current } = this.benguideListTable;
      current.triggerReloadToSamePage();
    }
  };

  toggleDeleteBengPopup = () => {
    this.setState((prevState) => ({
      isDeleteBengModalOpen: !prevState.isDeleteBengModalOpen
    }));
  };

  toggleCloneBengPopup = () => {
    this.setState((prevState) => ({
      isCloneModalOpen: !prevState.isCloneModalOpen
    }));
  };

  getOeMeetingLinkText = (oeMeeting) => {
    const { state, type } = oeMeeting || {};

    if (!type || type === OE_MEETING_CONFIG.ZOOM.value) {
      switch (state) {
        case OE_MEETING_STATES.SCHEDULED.value:
          return 'Update';
        case OE_MEETING_STATES.STARTED.value:
          return 'Update';
        case OE_MEETING_STATES.RECORDING_PROCESSING.value:
          return 'Reschedule';
        case OE_MEETING_STATES.RECORDING_FAILED.value:
          return 'Reschedule';
        case OE_MEETING_STATES.RECORDING_SUCCESS.value:
          return 'Reschedule';
        default:
          return 'Configure';
      }
    } else {
      return this.getCustomOeMeetingState(oeMeeting);
    }
  };

  getCustomOeMeetingState = (oeMeeting) => {
    const { meetingTime, timezone, durationMinutes } = oeMeeting || {};

    if (!_.isEmpty(oeMeeting)) {
      const currentTime = momentTz();
      const meetingEndTime = momentTz
        .tz(`${meetingTime}`, timezone)
        .local()
        .add(durationMinutes, 'minutes');
      return currentTime.isAfter(meetingEndTime) ? 'Reschedule' : 'Update';
    }
    return 'Configure';
  };

  updateTypeFilter = (types) => {
    this.setState({ selectedPlanYearIds: types }, () => {
      this.reloadList();
    });
  };

  reloadList = () => {
    const { current } = this.benguideListTable;
    current.triggerReloadToSamePage();
  };

  getColumns = (planYears, benGuidesPageData, selectedPlanYearIds) => {
    return [
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        format: (row) => {
          return (
            <span
              onClick={(event) => this.editBenGuide(event, row)}
              className="plan-list-name"
            >
              {row.name}
            </span>
          );
        },
        grow: 1.5,
        wrap: true
      },
      {
        name: (
          <PlanYearFilter
            planYears={planYears}
            onFilterUpdate={this.updateTypeFilter}
            pageData={benGuidesPageData}
            selectedPlanYearIds={selectedPlanYearIds}
          />
        ),
        selector: 'planYearId',
        grow: 1.5,
        wrap: true,
        cell: (row) =>
          row.planYearId ? (
            <PlanYearField
              row={row}
              planYears={planYears || {}}
              onClick={(event) => this.editBenGuide(event, row)}
            />
          ) : (
            '-'
          )
      },
      {
        name: 'Status',
        selector: 'status',
        format: (row) => {
          return (
            <span>
              {row.status.charAt(0).toUpperCase() +
                row.status.substr(1).toLowerCase()}
            </span>
          );
        },
        grow: 0.5,
        wrap: true
      },
      {
        name: 'OE Meeting Configured?',
        selector: 'status',
        format: (row) => {
          const { oeMeeting = {} } = row;
          const { state } = oeMeeting || {};
          return (
            <div className="configure-link-wrapper">
              {state ? 'Yes' : 'No'}

              <div
                className="configure-link"
                onClick={(event) => {
                  this.setState({ selectedBenGuide: row }, () => {
                    this.toggleMeetingPopup(row.id);
                  });
                }}
              >
                {this.getOeMeetingLinkText(oeMeeting)}
              </div>
            </div>
          );
        }
      },
      {
        name: 'Last Updated',
        selector: 'createdTs',
        format: (row) => {
          return row.createdTs ? moment(row.createdTs).format('lll') : '';
        },
        grow: 0.75,
        wrap: true
      },
      {
        name: 'Actions',
        cell: (row) => {
          const SecuredDropdownItem = permitIf(
            DropdownItem,
            BEN_GUIDE_FEATURE_ROLE_MAPPING.common
          );
          return (
            <div>
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="cursor-pointer" caret>
                  Select
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(event) => this.viewBenGuide(event, row)}
                  >
                    View
                  </DropdownItem>
                  <SecuredDropdownItem
                    onClick={(event) => this.editBenGuide(event, row)}
                  >
                    Edit
                  </SecuredDropdownItem>
                  {row && row.status !== BENGUIDE_PUBLISHED && (
                    <DropdownItem
                      onClick={() => {
                        this.setState({ selectedBenGuide: row }, () => {
                          this.props.checkPublishedVersionExists(row.id);
                          this.toggleDeleteBengPopup();
                        });
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}

                  <DropdownItem
                    onClick={(event) => this.previewBenguide(event, row)}
                  >
                    Preview
                  </DropdownItem>
                  {row && row.status === BENGUIDE_PUBLISHED && (
                    <CopyToClipboard
                      text={`${row.urlBase}/${row.urlHash}/${row.urlLabel}`}
                      onCopy={() => {
                        NotificationManager.success('Copied Public URL.');
                      }}
                    >
                      <DropdownItem>Copy Public URL</DropdownItem>
                    </CopyToClipboard>
                  )}
                  <DropdownItem
                    onClick={() => {
                      this.setState({ selectedBenGuide: row }, () => {
                        this.toggleCloneBengPopup();
                      });
                    }}
                  >
                    Clone
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          );
        },
        ignoreRowClick: true,
        allowOverflow: true,
        width: '66px'
      }
    ];
  };

  render() {
    const {
      getBenGuidesPage,
      benGuidesPageData,
      match,
      planYears,
      deleteBenguide,
      deleteBenguideInProgress,
      deleteBenguideError,
      hasBenguidePublishedVersion
    } = this.props;
    const { employerId } = match.params;
    const {
      isMeetingPopupOpen,
      selectedBenGuide,
      selectedPlanYearIds,
      isPlanYearsValidated,
      isDeleteBengModalOpen,
      isCloneModalOpen
    } = this.state;
    if (selectedPlanYearIds !== '' || isPlanYearsValidated) {
      return (
        <ApplicationWrapper>
          <HeaderContainer title="BenGuide" actions={this.getPageActions()} />
          <div className="plan-list">
            <ContentContainer>
              <DataTableWithServerPagination
                ref={this.benguideListTable}
                columnData={this.getColumns(
                  planYears,
                  benGuidesPageData,
                  selectedPlanYearIds
                )}
                fetchData={(pageNumber, rowsPerPage, sortField, query) =>
                  getBenGuidesPage(
                    employerId,
                    selectedPlanYearIds,
                    pageNumber,
                    rowsPerPage,
                    sortField,
                    query
                  )
                }
                pageData={benGuidesPageData}
                onRowClicked={(row, event) => this.editBenGuide(event, row)}
                customStyle={CUSTOM_STYLES}
                persistTableHead={true}
              />
              <ScheduleMeeting
                isOpen={isMeetingPopupOpen}
                onToggle={this.toggleMeetingPopup}
                benGuide={selectedBenGuide}
              />
              <DeleteBenguideModal
                isOpen={isDeleteBengModalOpen}
                toggle={this.toggleDeleteBengPopup}
                benGuide={selectedBenGuide}
                deleteBenguide={deleteBenguide}
                inProgress={deleteBenguideInProgress}
                error={deleteBenguideError}
                reloadList={this.reloadList}
                hasPublishedVersion={hasBenguidePublishedVersion}
              />
              <CloneBenguideModal
                isOpen={isCloneModalOpen}
                toggle={this.toggleCloneBengPopup}
                benGuide={selectedBenGuide}
                cloneBenguide={this.clone}
              />
            </ContentContainer>
          </div>
        </ApplicationWrapper>
      );
    } else {
      return <></>;
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBenGuidesPage: (
    employerId,
    selectedPlanYearIds,
    page,
    size,
    sort,
    query
  ) =>
    dispatch(
      getBenGuidesPage(employerId, selectedPlanYearIds, page, size, sort, query)
    ),
  previewBenGuide: (benGuideId) => dispatch(previewBenGuide(benGuideId)),
  getPlanYears: (employerId) => dispatch(getPlanYears(employerId)),
  deleteBenguide: (benGuideId) => dispatch(deleteBenguide(benGuideId)),
  checkPublishedVersionExists: (benGuideId) =>
    dispatch(checkPublishedVersionExists(benGuideId)),
});

const mapStateToProps = (state) => {
  const {
    benGuidesPageData,
    benGuidesPageErrorResponse,
    benguidePreview,
    deleteBenguideStatus,
    checkBenguidePublishedVersionExists
  } = state.benGuideReducer;
  const { appBootupInfo } = state.AppBase.authReducer;
  const { planYears } = state.Employers.planYearReducer;
  return {
    benGuidesPageData,
    benGuidesPageErrorResponse,
    appBootupInfo,
    benguidePreview,
    planYears: planYears.data,
    isFetchingPlanYears: planYears.isFetching,
    deleteBenguideInProgress: deleteBenguideStatus.inProgress,
    deleteBenguideError: deleteBenguideStatus.error,
    hasBenguidePublishedVersion:
      checkBenguidePublishedVersionExists.hasPublishedVersion
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BenGuideList);
