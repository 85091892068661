/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
 import _ from 'lodash';
 import moment from 'moment';

export const getCurentAndFuturePlans = (planYearId, planYears, savedPlanYearId) => {
    if (!_.isEmpty(planYearId)) {
      const planYearList = planYears.filter(
        (planYear) =>
          planYear.current === true ||
          planYear.next === true ||
          planYear.id === savedPlanYearId //We allow previous too for saved plans
      );
      return planYearList;
    } else {
      const planYearList = planYears.filter(
        (planYear) => planYear.current === true || planYear.next === true
      );
      return planYearList;
    }
  };

  export const getCurentPlanYearId = (planYears) => {
    if (!_.isEmpty(planYears)) {
      const defaultPlanYear = planYears.filter(
        (planYear) => planYear.current === true
      );
      return defaultPlanYear[0].id;
    }
    return '';
  };

  export const getPlanYearName = (planYear) => {
    if (!_.isEmpty(planYear)) {
      return ` ${planYear.name} (${moment(planYear.startDate).format(
        'll'
      )} to ${moment(planYear.endDate).format('ll')}) `;
    }
    return '-';
  };

  export const getPlanYearById = (planYearId, planYears) => {
    if (!_.isEmpty(planYearId)) {
      const planYear = planYears.filter((planYear) => planYear.id === planYearId);
      return planYear[0];
    }
    return {};
  };

  export const getPlanYearNameWithCurrent = (planYear) => {
    if (!_.isEmpty(planYear)) {
      return ` ${planYear.name} (${moment(planYear.startDate).format(
        'll'
      )} to ${moment(planYear.endDate).format('ll')}) ${
        planYear.current ? '- CURRENT' : ''
      } `;
    }
    return '-';
  };

  export const sortPlanYears = (planYears) => {
    if (!_.isEmpty(planYears)) {
      planYears.sort(function(a, b) {
        let c = new Date(a.startDate);
        let d = new Date(b.startDate);
        return c - d;
      });
    }
  }