import React, { Component } from 'react';
import { findConfigStatus } from '../actions/chatbotConfigActions';
import { connect } from 'react-redux';
import { FEATURE_KEY_MOBILE } from '../constants';
import DBGChatbotConfig from '../components/DBGChatbotConfig';
import SlackChatbotConfig from '../components/SlackChatbotConfig';
import MobileChatbotConfig from '../components/MobileChatbotConfig';
import HeaderContainer from '../../../containers/HeaderContainer';

class ChatbotConfig extends Component {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    const { findConfigStatus, match } = this.props;
    const { employerId } = match.params;
    findConfigStatus(employerId, FEATURE_KEY_MOBILE);
  }

  renderChatbotConfigurations = () => {
    const { ...props } = this.props;
    const { configStatus } = this.props;
    if (
      configStatus.key === FEATURE_KEY_MOBILE &&
      configStatus.value === 'true'
    ) {
      return (
        <>
          <HeaderContainer title="Chatbot" />
          <MobileChatbotConfig {...props} />
          <br />
          <br />
          <DBGChatbotConfig {...props} />
          <br />
          <br />
          <SlackChatbotConfig {...props} />
        </>
      );
    } else {
      return (
        <>
          <HeaderContainer title="Chatbot" />
          <DBGChatbotConfig {...props} />
          <br />
          <br />
          <SlackChatbotConfig {...props} />
        </>
      );
    }
  };

  render() {
    return this.renderChatbotConfigurations();
  }
}

const mapDispatchToProps = (dispatch) => ({
  findConfigStatus: (employerId, key) =>
    dispatch(findConfigStatus(employerId, key))
});

const mapStateToProps = (state) => {
  const { configStatus } = state.chatbotConfigReducer;
  return { configStatus };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatbotConfig);
