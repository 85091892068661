import React from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'components/Atoms';
import { permitIf } from 'components/hoc/Permit';

const ActionMenu = (props) => {
  const { ItemsList } = props;
  return (
    <UncontrolledDropdown auto_id="actions-dropdown">
      <DropdownToggle tag="a" className="nav-link" caret>
        Select
      </DropdownToggle>
      <DropdownMenu
        modifiers={{
          computeStyle: {
            gpuAcceleration: false
          }
        }}
      >
        {ItemsList.map((actionObj) => {
          if (actionObj.roleMapping) {
            const SecuredDropdownItem = permitIf(
              DropdownItem,
              actionObj.roleMapping
            );
            return (
              <SecuredDropdownItem
                key={actionObj.action}
                onClick={actionObj.onClick}
              >
                {actionObj.action}
              </SecuredDropdownItem>
            );
          } else {
            return (
              <DropdownItem key={actionObj.action} onClick={actionObj.onClick}>
                {actionObj.action}
              </DropdownItem>
            );
          }
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ActionMenu;
