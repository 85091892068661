/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import NumberFormat from 'react-number-format';

import { Row, Col, Label } from 'components/Atoms';
import { TYPE_RATES, TYPE_HSA_CONTRIBUTION } from '../../constants';

import { formatCurrency } from 'util/commonUtil';
import { isInputAllowed } from '../../util';
import './ModelingSplitInput.scss';

const ModelingSplitInput = (props) => {
  const {
    planKey = '',
    title,
    onEventChange,
    onChange,
    plan,
    leftValue = '',
    rightValue = '',
    leftType = TYPE_RATES,
    rightType = TYPE_HSA_CONTRIBUTION,
    leftId,
    rightId,
    name,
    prefix = '$',
    leftPlaceholder,
    rightPlaceholder,
    editMode,
    isBenchMarkValue = false,
    currentValue,
    decimalScale = 2,
    inValid,
    inValidLeft = false,
    inValidRight = false,
    inputType = 'text'
  } = props;

  const leftValuePresent =
    leftValue !== undefined && leftValue !== null && leftValue !== '';

  const rightValuePresent =
    rightValue !== undefined && rightValue !== null && rightValue !== '';

  return (
    <Row className="modeling-input">
      <Col className="current-plan-detail-line-inner">
        <Row>
          <Col xs="3" className="pr-0">
            <Label className="plan-attr-lable">{title}</Label>
          </Col>
          {!editMode && isBenchMarkValue ? (
            <Col xs="5 plan-attr-lable-text">
              <Label className="plan-attr-lable-select-medical">
                {leftValuePresent || rightValuePresent
                  ? `${leftValuePresent ? formatCurrency(leftValue) : '-'} / ${
                      rightValuePresent ? formatCurrency(rightValue) : '-'
                    }`
                  : '-'}
              </Label>
            </Col>
          ) : (
            <Col
              xs={isBenchMarkValue && 5}
              className="plan-detail-input-wrapper"
            >
              <NumberFormat
                prefix={prefix}
                type={inputType}
                className={
                  inValid || inValidLeft
                    ? `line-item-input ${
                        isBenchMarkValue ? 'benchmark ' : ''
                      }split left is-invalid`
                    : `line-item-input ${
                        isBenchMarkValue ? 'benchmark ' : ''
                      }split left`
                }
                id={leftId}
                name={`${name}_left`}
                value={leftValue}
                allowNegative={false}
                onBlur={(event) => {
                  onEventChange(event, onChange, plan, leftType, planKey);
                }}
                placeholder={leftPlaceholder}
                decimalScale={decimalScale}
                isAllowed={isInputAllowed}
              />
              <NumberFormat
                prefix={prefix}
                className={
                  inValid || inValidRight
                    ? `line-item-input ${
                        isBenchMarkValue ? 'benchmark ' : ''
                      }split right is-invalid`
                    : `line-item-input ${
                        isBenchMarkValue ? 'benchmark ' : ''
                      }split right`
                }
                id={rightId}
                name={`${name}_right`}
                value={rightValue}
                allowNegative={false}
                onBlur={(event) => {
                  onEventChange(event, onChange, plan, rightType, planKey);
                }}
                placeholder={rightPlaceholder}
                decimalScale={decimalScale}
                isAllowed={isInputAllowed}
              />
            </Col>
          )}
          {isBenchMarkValue && (
            <Col xs="4" className="text-right pl-0">
              <Label className="plan-attr-lable-current">
                {`${
                  omitNulls(currentValue.rates) !== undefined
                    ? formatCurrency(currentValue.rates)
                    : ''
                } ${
                  omitNulls(currentValue.contributions) !== undefined
                    ? `/ ${formatCurrency(currentValue.contributions)}`
                    : ''
                }`}
              </Label>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

const omitNulls = (stringValue) => {
  if (stringValue === null || stringValue === 'null' || stringValue === '') {
    return undefined;
  }
  return stringValue;
};

export default ModelingSplitInput;
