/**********************************************************************************************************************
 * Copyright (C) 2019-2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/issueLog.actionTypes';

const initialState = {
  accountLevelIssueConfig: { id: null, value: 'false' },
  employeeLevelIssueConfig: { id: null, value: 'false' },
  employerConfigCreate : false,
  employerConfigUpdate: false,
  employerIntegrationUpdate: false

};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FIND_EMPLOYER_CONFIGURATION_ISSUE_LOG_ACCOUNT_LEVEL:
      return {
        ...state,
        accountLevelIssueConfig: action.payload
      };
    case ActionTypes.FIND_EMPLOYER_CONFIGURATION_ISSUE_LOG_EMPLOYEE_LEVEL:
      return {
        ...state,
        employeeLevelIssueConfig: action.payload
      };
    case ActionTypes.FIND_EMPLOYER_CONFIGURATION_MODULES_ERROR:
      return {
        ...state,
        employerConfigsErrorResponse: action.payload
      };
    case ActionTypes.CREATE_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        // Re-assiging the state value to trigger state change at component level
        // since it's always 204 from the backend
        employerConfigCreate: Object.assign(action.payload)
      };
    case ActionTypes.CREATE_EMPLOYER_CONFIGURATION_MODULES_ERROR:
      return {
        ...state,
        employerConfigCreateError: action.payload
      };
    case ActionTypes.UPDATE_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        // Re-assiging the state value to trigger state change at component level
        // since it's always 204 from the backend
        employerConfigUpdate: Object.assign(action.payload)
      };
    case ActionTypes.UPDATE_EMPLOYER_CONFIGURATION_MODULES_ERROR:
      return {
        ...state,
        employerConfigUpdateError: action.payload
      };
    case ActionTypes.GET_ISSUE_LOG_EMPLOYER:
      return {
        ...state,
        employerRefreshed: action.payload
      };
    case ActionTypes.GET_ISSUE_LOG_EMPLOYER_ERROR:
      return {
        ...state,
        employerErrorResponse: action.payload
      };
    default:
      return {
        ...state
      };
  }
};
