/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import axios from 'axios';
import Util from '../util/apiUtil';

const baseURL = `${Util.baseApi}/${Util.version}/benchmarks`;

export default {
  /**
   * search all benchmarks for given employer ID and search query.
   * @param employerId - Employer ID.
   * @param query - Search Query.
   */
  findByEmployerId(employerId, query) {
    return axios.get(`${baseURL}/?employerId=${employerId}`);
  },

  /**
   * Returns an existing benchmark by its id
   * @param {*} benchmarkId
   */
  findBenchmarkById(benchmarkId) {
    return axios.get(`${baseURL}/${benchmarkId}`);
  },

  /**
   * Returns existing census info by employer id
   * @param {*} employerId
   */
  findCensusById(employerId) {
    return axios.get(`${baseURL}/clusters/${employerId}/census`);
  },

  /**
   * update existing benchmark.
   *
   * @param benchmarkId - Benchmark Id.
   * @param payload - Benchmark payload
   * @returns {AxiosPromise<any>}
   */
  updateBenchmark(benchmarkId, payload, employerId, step, benefitCategory) {
    const stepPath = step || '';
    return axios.put(
      `${baseURL}/${benchmarkId}/${stepPath}?employerId=${employerId}`,
      payload,
      {
        params: {
          benefitCategory
        }
      }
    );
  },

  /**
   * create new benchmark.
   *
   * @param payload - Benchmark Payload
   * @param employerId - Employer ID
   * @returns {AxiosPromise<any>}
   */
  createBenchmark(payload, employerId) {
    return axios.post(`${baseURL}/?employerId=${employerId}`, payload);
  },

  /**
   * Returns published benchmark by employerId
   */
  getPublishedBenchmark(employerId) {
    return axios.get(`${baseURL}/published?employerId=${employerId}`);
  },

  /**
   *
   * @param benefitType
   */
  getPlansListDownloadUrl(benefitType) {
    return `${baseURL}/plans-list/${benefitType}`;
  },

  /**
   * Returns a list of industries
   */
  getMetadata(benefitCategory = null, planType = null) {
    return axios.get(`${baseURL}/meta`, {
      params: {
        benefitCategory: benefitCategory && benefitCategory,
        planType: planType && planType
      }
    });
  },

  /**
   * Find benchmark plan based on the selected params
   * @param {*} employer
   * @param {*} carrier
   */
  findBenchmarkPlan(searchCriteria) {
    const {
      planType,
      benefitCategory,
      employer,
      carrier,
      deductible,
      oopMax,
      copay,
      rxCost,
      coinsurance,
      annualMaxBenefit,
      orthodontal,
      orthodontalMax,
      copayExam,
      copayMaterial,
      framesAllowance,
      contactAllowance,
      freqOfServices
    } = searchCriteria;

    return axios.get(`${baseURL}/plans/${benefitCategory}`, {
      params: {
        planType: planType,
        employer: employer,
        carrier: carrier,
        deductible: deductible && deductible.api,
        oopMax: oopMax && oopMax.api,
        copay: copay && copay.api,
        rxCost: rxCost && rxCost.api,
        coinsurance: coinsurance && coinsurance.api,
        annualMaxBenefit: annualMaxBenefit && annualMaxBenefit.api,
        orthodontal: orthodontal && orthodontal.api,
        orthodontalMax: orthodontalMax && orthodontalMax.api,
        copayExam: copayExam && copayExam.api,
        copayMaterial: copayMaterial && copayMaterial.api,
        framesAllowance: framesAllowance && framesAllowance.api,
        contactAllowance: contactAllowance && contactAllowance.api,
        freqOfServices: freqOfServices && freqOfServices.api
      }
    });
  },

  /**
   * Clone existing benchmark
   * @param benchmarkId
   * @returns {AxiosPromise<any>}
   */
  cloneBenchmark(benchmarkId) {
    return axios.post(`${baseURL}/${benchmarkId}/clone`);
  },
  /**
   * get preview url to benchmark in ERP
   * @param benchmarkId
   * @param benefitType
   * @param employerId
   * @param planId
   * @returns {string}
   */
  getPreviewBenchmarkUrl(benchmarkId, benefitType, employerId, planId) {
    let url = `${Util.erPortal}/strategy/benchmarks/${benchmarkId}/benefitKind/${benefitType}?employerId=${employerId}`;
    if (planId) {
      url = `${url}&planId=${planId}`;
    }
    return url;
  },

  getBenchmarkOverallRates(nonComputedRates) {
    return axios.get(`${baseURL}/market-rates/`, {
      params: {
        ...nonComputedRates
      }
    });
  }
};
