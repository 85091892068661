/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { PureComponent, Suspense } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import { NotificationContainer } from 'components/Notifications';
import Loader from 'components/CircularProgress/Loader';

import {
  LOGIN_PATH,
  PASSWORD_RESET_REQUEST_PATH
} from 'modules/app-base/routes';
import { ping, logout } from 'modules/app-base/actions/authActions';
import { clearAllFilters } from 'modules/NativeIssueLog/actions/nativeIssueActions';

import { PLATFORM_ADMIN } from 'modules/app-base/constants/loginTypeConstants';
import { getOrganizationById } from 'modules/Organisations/actions/organization.actions';
import { findEmployerById } from 'modules/Employers/actions/employerActions';

import Header from './Header';
import Sidebar from './Sidebar';
import './LayoutWrapper.scss';

class LayoutWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { landingURL: '/' };
  }

  componentDidMount() {
    const { ping } = this.props;
    this.configureInterceptor();
    ping();
  }

  componentDidUpdate(prevProps) {
    const {
      appBootupInfo,
      getOrganizationById,
      history,
      employer,
      findEmployerById
    } = this.props;
    const { organizationId, type } = appBootupInfo ? appBootupInfo : {};
    const { location } = history;
    if (appBootupInfo !== prevProps.appBootupInfo) {
      if (organizationId) {
        getOrganizationById(organizationId);
        if (location.pathname === '/') {
          history.push(`/brokers/${organizationId}/employers`);
        }
      } else {
        if (location.pathname === '/') {
          history.push('/brokers');
        }
      }
      // This is a workaround to support navigating to an issue log from an old email.
      if (
        location.pathname.startsWith('/issuelog') &&
        location.search.includes('employerId')
      ) {
        const urlParams = new URLSearchParams(location.search);
        const employerId = urlParams.get('employerId');
        findEmployerById(employerId);
      }
      // This is a workaround to support navigating to an Survey from an old email.
      if (
        location.pathname.startsWith('/employers') &&
        location.pathname.includes('/surveys/')
      ) {
        const strArray = location.pathname.split('/');
        findEmployerById(strArray[2]);
      }
    }
    if (
      employer &&
      employer !== prevProps.employer &&
      employer.data &&
      (type === PLATFORM_ADMIN ||
        organizationId === employer.data.organizationId)
    ) {
      history.push(
        `/brokers/${employer.data.organizationId}${location.pathname}${location.search}`
      );
    }
  }

  configureInterceptor = () => {
    // Enables cookies for cross-domain requests
    axios.defaults.withCredentials = true;
    axios.interceptors.response.use(
      (response) => {
        const { history, location } = this.props;
        const { landingURL } = this.state;

        const { config, data } = response;
        const { url, method } = config;

        const { pathname, search = '', hash = '' } = location;
        const redirectURL = `${pathname}${search}${hash}`;

        if (url.indexOf('oauth/token') >= 0) {
          const requestMethod = method.toLowerCase();
          // Redirect to previous page on successful login
          if (requestMethod === 'delete') {
            if (pathname === PASSWORD_RESET_REQUEST_PATH) {
              history.push(redirectURL);
            } else {
              history.push(LOGIN_PATH);
            }
          }
        }

        if (url.indexOf('whoami') >= 0) {
          if (redirectURL !== LOGIN_PATH) {
            history.push(redirectURL);
          } else {
            history.push(landingURL);
          }
          this.setState({ landingURL: '/' });
        }
        return response;
      },
      (error) => {
        // Destructuring happens here because location needs to be fetched WHEN the error occurs.
        const { location, history, ping } = this.props;
        const { pathname, search = '', hash = '' } = location;
        const redirectURL = `${pathname}${search}${hash}`;
        if (error.response.status === 401) {
          if (location.pathname === PASSWORD_RESET_REQUEST_PATH) {
            history.push(redirectURL);
          } else if (location.pathname !== LOGIN_PATH) {
            const { pathname, search = '', hash = '' } = location;
            const redirectURL = `${pathname}${search}${hash}`;
            this.setState({ landingURL: redirectURL }, () => {
              // ping to clear appBootupInfo
              ping();
              history.push(LOGIN_PATH);
            });
          }
        }
        return Promise.reject(error);
      }
    );
  };

  handleLogout = () => {
    const { logout, clearAllFilters } = this.props;
    clearAllFilters().then(() => logout());
  };

  render() {
    const {
      children,
      location,
      history,
      inProgress,
      appBootupInfo,
      navigationContext,
      employerContext,
      organization
    } = this.props;
    const { landingURL } = this.state;
    let employerArchived = false;
    const { logoUrl } = organization.data;
    if (navigationContext !== null) {
      const employerContextIsActive = navigationContext.find(
        (nav) => nav.display === 'Employers' || nav.display === 'Brokers'
      );
      if (
        employerContextIsActive &&
        employerContextIsActive !== null &&
        location !== null
      ) {
        employerArchived =
          location.pathname !== '/employers' &&
          location.pathname !== '/' &&
          employerContextIsActive.isActive &&
          employerContext.archived;
      }
    }
    const suspendedChildren = (
      <Suspense fallback={<Loader />}>{children}</Suspense>
    );
    if (
      location.pathname === LOGIN_PATH ||
      location.pathname === PASSWORD_RESET_REQUEST_PATH
    ) {
      if (appBootupInfo) {
        history.push(landingURL);
        return <Loader />;
      }
      return <main className="ext-main-container">{suspendedChildren}</main>;
    }

    if (inProgress || !appBootupInfo) {
      return <Loader />;
    }

    return (
      <>
        <Header
          appBootupInfo={appBootupInfo}
          logout={this.handleLogout}
          isArchived={employerArchived}
          organizationLogo={logoUrl}
        />
        <Sidebar
          navigationContext={navigationContext}
          userRoles={appBootupInfo.roles}
          employer={employerContext}
          loginType={appBootupInfo.type}
        />
        <NotificationContainer />
        <main className="app-main-container">{suspendedChildren}</main>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      ...state.AppBase.authReducer,
      ...state.AppBase.contextReducer,
      ...state.organizationReducer,
      employer: state.Employers.employersReducer.employer
    }),
    (dispatch) =>
      bindActionCreators(
        {
          ping,
          logout,
          clearAllFilters,
          getOrganizationById,
          findEmployerById
        },
        dispatch
      )
  )(LayoutWrapper)
);
