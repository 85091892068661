/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/carrier.actionTypes';

export default (
  state = {
    carriersPage: {
      data: { content: null, metadata: null },
      inProgress: false,
      error: null
    },
    upsertCarrier: {
      data: null,
      inProgress: false,
      error: null
    },
    logoUpload: {
      progress: 0,
      inProgress: false,
      error: null
    }
  },
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.GET_CARRIERS_PAGE_IN_PROGRESS:
      return {
        ...state,
        carriersPage: {
          ...state.carriersPage,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.GET_CARRIERS_PAGE_COMPLETED:
      return {
        ...state,
        carriersPage: {
          ...state.carriersPage,
          data: payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.GET_CARRIERS_PAGE_FAILED:
      return {
        ...state,
        carriersPage: {
          ...state.carriersPage,
          inProgress: false,
          error: payload
        }
      };
    case ActionTypes.UPSERT_CARRIER_IN_PROGRESS:
      return {
        ...state,
        upsertCarrier: {
          ...state.upsertCarrier,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.UPSERT_CARRIER_COMPLETED:
      return {
        ...state,
        upsertCarrier: {
          ...state.upsertCarrier,
          data: payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.UPSERT_CARRIER_FAILED:
      return {
        ...state,
        upsertCarrier: {
          ...state.upsertCarrier,
          inProgress: false,
          error: payload
        }
      };
    case ActionTypes.UPLOAD_CARRIER_LOGO_IN_PROGRESS:
      return {
        ...state,
        logoUpload: {
          ...state.logoUpload,
          progress: 0,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.UPLOAD_CARRIER_LOGO_PROGRESS:
      return {
        ...state,
        logoUpload: {
          ...state.logoUpload,
          progress: payload
        }
      };
    case ActionTypes.UPLOAD_CARRIER_LOGO_COMPLETED:
      return {
        ...state,
        logoUpload: {
          ...state.logoUpload,
          progress: 0,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.UPLOAD_CARRIER_LOGO_FAILED:
      return {
        ...state,
        logoUpload: {
          ...state.logoUpload,
          inProgress: false,
          error: payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
