import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Row, Col } from 'components/Atoms';
import DatePickerInput from './DatePickerInput';
import { isValidFormat, INPUT_DATE_FORMAT_DEFAULT } from '../../util/dateUtil';

const StartEndDatePicker = (props) => {
  const {
    startLabel,
    endLabel,
    startName,
    endName,
    startValue = '',
    endValue = '',
    dateFormat,
    onChange,
    disabled,
    disabledFields,
    validations,
    automaticOffset,
    autoChange = true
  } = props;

  const { isStartDateInvalid, isEndDateInvalid, isRangeInvalid } = _.defaultTo(
    validations,
    {}
  );

  // Sets endDate to one year after startDate
  const onChangeStartDate = (event) => {
    onChange(event);
    const { name, value } = event.target;
    if (name === startName && autoChange) {
      if (isValidFormat(value)) {
        const parsedStart = moment(value, INPUT_DATE_FORMAT_DEFAULT);
        const offsetEnd = parsedStart
          .add(_.defaultTo(automaticOffset, { years: 1 }))
          .subtract(1, 'days');
        onChange({
          target: {
            name: endName,
            value: offsetEnd.format(INPUT_DATE_FORMAT_DEFAULT)
          }
        });
      }
    }
  };

  return (
    <>
      <Row>
        <Col xs="6">
          <DatePickerInput
            labelDisplay={_.defaultTo(startLabel, 'Start Date *')}
            inputName={startName}
            inputValue={startValue}
            dateFormat={dateFormat}
            onChange={onChangeStartDate}
            disabled={
              disabled || _.defaultTo(disabledFields, []).includes('startDate')
            }
            isInvalid={
              !(
                disabled ||
                _.defaultTo(disabledFields, []).includes('startDate')
              ) &&
              (isStartDateInvalid || isRangeInvalid)
            }
            feedback={
              isStartDateInvalid
                ? 'Please select a valid start date'
                : 'Please select a valid date range'
            }
          />
        </Col>
        <Col xs="6">
          <DatePickerInput
            labelDisplay={_.defaultTo(endLabel, 'End Date *')}
            inputName={endName}
            inputValue={endValue}
            dateFormat={dateFormat}
            onChange={onChange}
            disabled={
              disabled || _.defaultTo(disabledFields, []).includes('endDate')
            }
            isInvalid={
              !(
                disabled ||
                _.defaultTo(disabledFields, []).includes('endDate')
              ) &&
              (isEndDateInvalid || isRangeInvalid)
            }
            feedback={
              isEndDateInvalid
                ? 'Please select a valid end date'
                : 'Please select a valid date range'
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default StartEndDatePicker;
