/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import axios from 'axios';
import Util, {
  CONTENT_TYPE_MULTIPART_FORM,
  CONTENT_TYPE_HEADER
} from '../util/apiUtil';
import { buildDocumentSearchQuery } from '../util/documentUtil';

//configs
const baseURL = `${Util.baseApi}/v2/documents`;

export default {
  /**
   * get all documents by given query object
   * @param page
   * @param size
   * @param sort
   * @param query
   * @returns {AxiosPromise<any>}
   */
  findByDocumentId(page, size, sort, data, search) {
    return axios.get(
      `${baseURL}?${Util.getPagingQueryString(
        page,
        size,
        sort
      )}&${buildDocumentSearchQuery(data)}&query=${search}`
    );
  },

  /**
   * create folder for given employer and parent document
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  createFolder(payload) {
    return axios.post(
      `${baseURL}?employerId=${payload.employerId}&parentDocumentId=${
        payload.sourceDocumentId
      }&folderName=${encodeURIComponent(payload.folderName)}`
    );
  },

  /**
   * upload file for given employer and parent folder
   * @param payload
   * @param duplicate
   * @returns {AxiosPromise<any>}
   */
  uploadFile(payload, duplicate) {
    const formData = new FormData();
    formData.append('file', payload.file, payload.fileName);
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };
    return axios.post(
      `${baseURL}?employerId=${payload.employerId}&parentDocumentId=${payload.sourceDocumentId}&isDuplicate=${duplicate}`,
      formData,
      config
    );
  },

  /**
   * delete document by id
   * @param id
   * @returns {AxiosPromise}
   */
  deleteDocument(id) {
    return axios.delete(`${baseURL}/${id}`);
  },

  /**
   * Rename given document
   * @param id
   * @param name
   * @param parentDocumentId
   * @returns {AxiosPromise<any>}
   */
  renameDocument(id, name, parentDocumentId) {
    let paramStr = '';
    if (parentDocumentId) {
      paramStr = `&parentDocumentId=${parentDocumentId}`;
    }
    return axios.put(
      `${baseURL}/${id}?name=${encodeURIComponent(name)}${paramStr}`
    );
  },

  /**
   * Download file
   * @param id
   * @returns {AxiosPromise<any>}
   */
  downloadDocument(id) {
    return axios.get(`${baseURL}/${id}`);
  },

  /**
   * get file download url
   * @param documentId
   * @returns {string}
   */
  getDocumentDownloadUrl(documentId) {
    return `${baseURL}/${documentId}`;
  },

  /**
   * Check if document V2 feature is enabled
   * @param employerId
   * @returns {AxiosPromise<any>}
   */
  checkStatus(employerId) {
    return axios.get(`${baseURL}/status?employerId=${employerId}`);
  },

  updateStatus(employerId, payload) {
    return axios.put(`${baseURL}/status?employerId=${employerId}`, payload);
  },

  updateRootName(employerId, payload) {
    return axios.put(`${baseURL}/title?employerId=${employerId}`, payload);
  },

  /**
   * Check given document name already exist or not.
   * @param employerId
   * @param parentDocumentId
   * @param documentName
   * @returns {AxiosPromise<any>}
   */
  checkDocumentExist(employerId, parentDocumentId, documentName) {
    return axios.get(
      `${baseURL}/validate?employerId=${employerId}&parentDocumentId=${parentDocumentId}&name=${documentName}`
    );
  },

  /**
   * Check given document already exist or not.
   * @param documentId
   * @returns {AxiosPromise<any>}
   */
  findDocumentByDocumentId(documentId) {
    return axios.get(`${baseURL}/${documentId}/validate`);
  },

  getDocs(employerId, rootFolderName) {
    return axios.get(`${baseURL}/files`, {
      params: { employerId, rootFolderName }
    });
  }
};
