/**********************************************************************************************************************
 * Copyright (C) 2019-2021 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import _ from 'lodash';
import { generatePath } from 'react-router-dom';
import * as NativeIssueRoutes from './routes';

export const getDaysBetween = (date1, date2) => {
  const ONE_DAY = 1000 * 60 * 60 * 24;
  return Math.round(Math.abs((date1 - date2) / ONE_DAY));
};

export const getReadableFileSizeString = (size) => {
  if (size === 0) return '0 B';
  const logFileSize = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, logFileSize)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][logFileSize]
  );
};

export const getAssigneeValueList = (assigneeList, assigneeId, assigneeName) => {
  let assignees = assigneeList.map((assignee) => ({
    text: assignee.firstName + ' ' + assignee.lastName,
    value: assignee.assigneeIndividualId
  }));
  
  let selectedAssignee = assignees.filter((assignee) => assignee.assigneeIndividualId === assigneeId);
  if(_.isEmpty(selectedAssignee) && assigneeId !== null){
    let item = {
      text: assigneeName,
      value: assigneeId
    };
    assignees.push(item);
  }

  return assignees;
};

export const generateEmptyIssueData = () => {
  return {
    employer: '',
    subject: '',
    priority: '',
    issueType: '',
    assignee: '',
    assigneeName: '',
    description: '',
    attachments: [],
    status: '',
    watchers: [],
    uploadedAttachments: {},
    generatedId: '',
    onErPortal: true,
    contactEmail: '',
    origin: ''
  };
};

//Used to map label to matching values of constants
export const mapLabelToValue = (label, map) => {
  let value = null;
  Object.keys(map).forEach((key) => {
    if (map[key].label === label) {
      value = map[key].value;
    }
  });
  return value;
};
export const sorter = (collection, key) => {
  return collection.sort(function(a, b) {
    return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
  });
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

//Converts enums to required format for multi-select
export const convertToLabelledOptions = (enums) => {
  return Object.values(enums).map((config) => ({
    label: config.label,
    value: config.value,
    hidden: config.hidden
  }));
};

export const convertToLabelledOptionsValue = (enums) => {
  return Object.values(enums).map((config) => ({
    label: config.label,
    value: config.label,
    hidden: config.hidden
  }));
};

//converts enums to only use label instead of value
export const convertEnumToOptionsWithLabel = (enums) => {
  return Object.values(enums).map((config) => ({
    value: config.label,
    text: config.label,
    hidden: Boolean(config.hidden)
  }));
};

export const trimDescription = (text) => {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = text;
  return _.trim(tmp.textContent || tmp.innerText || '');
};

export const mapValueToLabel = (value, map) => {
  let label = null;
  Object.keys(map).forEach((key) => {
    if (map[key].value === value) {
      label = map[key].label;
    }
  });
  return label;
};

export const setDefaultValuesForFilterReset = (filterName, statusList, typeList, selectedOptions, isReset) => {
  if(filterName === 'status' && isReset && _.isEmpty(selectedOptions)){
    return getDefaultValueForFilter(statusList);
  } else if(filterName === 'issueType' && isReset  && _.isEmpty(selectedOptions)){
    return getDefaultValueForFilterLable(typeList);
  } else {
    return selectedOptions;
  }
}

export const getDefaultValueForFilter = (obj) => {
  let filterList = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key].default) {
      filterList.push(obj[key].value);
    }
  });
  return filterList;
};

export const getDefaultValueForFilterLable = (obj) => {
  let filterList = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key].default) {
      filterList.push(obj[key].label);
    }
  });
  return filterList;
};

export const generateEditPath = (params, employerId) => {
  if (employerId) {
    return generatePath(
      `${NativeIssueRoutes.NATIVE_ISSUE_EDIT_PATH}?employerId=${employerId}`,
      {
        ...params
      }
    );
  }
  return generatePath(`${NativeIssueRoutes.NATIVE_ISSUE_EDIT_PATH}`, {
    ...params
  });
};

export const generateViewPath = (params, employerId) => {
  if (employerId) {
    return generatePath(
      `${NativeIssueRoutes.NATIVE_ISSUE_VIEW_PATH}?employerId=${employerId}`,
      {
        ...params
      }
    );
  }
  return generatePath(`${NativeIssueRoutes.NATIVE_ISSUE_VIEW_PATH}`, {
    ...params
  });
};
