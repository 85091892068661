/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/employees.actionTypes';
import * as RolesActionTypes from '../actionTypes/roles.actionTypes';
import _ from 'lodash';

const initialState = {
  employeesPage: {
    data: {
      content: [],
      metadata: {}
    },
    isFetching: false,
    error: null
  },
  createEmployeeStatus: {
    inProgress: false,
    error: null
  },
  deleteEmployeeStatus: {
    inProgress: false,
    error: null
  },
  inviteEmployeeStatus: {
    inProgress: false,
    error: null
  },
  employeeDetails: {
    data: null,
    isFetching: false,
    isUpdating: false,
    error: null
  },
  dependentsDetailsStatus: {
    error: null
  },
  deleteDependentStatus: {
    inProgress: false,
    error: null
  },
  updateDependentStatus: {
    inProgress: false,
    error: null
  },
  notificationConfig: {
    data: null,
    isFetching: false,
    error: null
  },
  updateUsernameStatus: {
    inProgress: false,
    error: null
  },
  rehireEmployeeStatus: {
    inProgress: false,
    error: null
  },
  updateSsnStatus: {
    isUpdating: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_EMPLOYEES_PAGE_IN_PROGRESS:
      return {
        ...state,
        employeesPage: {
          ...state.employeesPage,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_EMPLOYEES_PAGE_COMPLETED:
      return {
        ...state,
        employeesPage: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_EMPLOYEES_PAGE_FAILED:
      return {
        ...state,
        employeesPage: {
          ...state.employeesPage,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.CREATE_EMPLOYEE_IN_PROGRESS:
      return {
        ...state,
        createEmployeeStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.CREATE_EMPLOYEE_COMPLETED:
      return {
        ...state,
        createEmployeeStatus: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.CREATE_EMPLOYEE_FAILED:
      return {
        ...state,
        createEmployeeStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.DELETE_EMPLOYEE_IN_PROGRESS:
      return {
        ...state,
        deleteEmployeeStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.DELETE_EMPLOYEE_COMPLETED:
      return {
        ...state,
        deleteEmployeeStatus: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.DELETE_EMPLOYEE_FAILED:
      return {
        ...state,
        deleteEmployeeStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.INVITE_EMPLOYEE_IN_PROGRESS:
      return {
        ...state,
        inviteEmployeeStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.INVITE_EMPLOYEE_COMPLETED:
      return {
        ...state,
        inviteEmployeeStatus: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.INVITE_EMPLOYEE_FAILED:
      return {
        ...state,
        inviteEmployeeStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_EMPLOYEE_DETAILS_IN_PROGRESS:
      return {
        ...state,
        employeeDetails: {
          ...state.employeeDetails,
          data: null,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_EMPLOYEE_DETAILS_COMPLETED:
      return {
        ...state,
        employeeDetails: {
          ...state.employeeDetails,
          isFetching: false,
          data: action.payload
        }
      };
    case ActionTypes.GET_EMPLOYEE_DETAILS_FAILED:
      return {
        ...state,
        employeeDetails: {
          ...state.employeeDetails,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_DEPENDENTS_DETAILS_IN_PROGRESS:
      return {
        ...state,
        dependentsDetailsStatus: {
          ...state.dependentsDetailsStatus,
          error: null
        }
      };
    case ActionTypes.GET_DEPENDENTS_DETAILS_FAILED:
      return {
        ...state,
        dependentsDetailsStatus: {
          ...state.dependentsDetailsStatus,
          error: action.payload
        }
      };
    case ActionTypes.UPDATE_EMPLOYEE_DETAILS_IN_PROGRESS:
      return {
        ...state,
        employeeDetails: {
          ...state.employeeDetails,
          isUpdating: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_EMPLOYEE_DETAILS_COMPLETED:
      return {
        ...state,
        employeeDetails: {
          ...state.employeeDetails,
          data: {
            ...state.employeeDetails.data,
            individual: action.payload
          },
          isUpdating: false,
          error: null
        }
      };
    case ActionTypes.UPDATE_EMPLOYEE_DETAILS_FAILED:
      return {
        ...state,
        employeeDetails: {
          ...state.employeeDetails,
          isUpdating: false,
          error: action.payload
        }
      };
    case RolesActionTypes.UPDATE_EMPLOYEE_USER_ROLES_COMPLETED:
      const newRoles = action.payload.roles;
      const currentEmployeeDetails = { ...state.employeeDetails.data };
      // Only update employee context's roles if employeeIds match
      if (action.payload.employeeId === currentEmployeeDetails.employeeId) {
        currentEmployeeDetails.individual.roles = newRoles;
        return {
          ...state,
          employeeDetails: {
            ...state.employeeDetails,
            data: currentEmployeeDetails
          }
        };
      } else {
        return {
          ...state
        };
      }
    case ActionTypes.CHANGE_USERNAME_IN_PROGRESS:
      return {
        ...state,
        updateUsernameStatus: {
          isUpdating: true,
          error: null
        }
      };
    case ActionTypes.CHANGE_USERNAME_COMPLETED:
      return {
        ...state,
        updateUsernameStatus: {
          isUpdating: false,
          error: null
        }
      };
    case ActionTypes.CHANGE_USERNAME_FAILED:
      return {
        ...state,
        updateUsernameStatus: {
          isUpdating: false,
          error: action.payload
        }
      };
    case ActionTypes.DELETE_DEPENDENT_IN_PROGRESS:
      return {
        ...state,
        deleteDependentStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.DELETE_DEPENDENT_COMPLETED:
      return {
        ...state,
        // Update the context's list of dependents
        employeeDetails: {
          ...state.employeeDetails,
          data: action.payload
        },
        deleteDependentStatus: {
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.DELETE_DEPENDENT_FAILED:
      return {
        ...state,
        deleteDependentStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.UPDATE_DEPENDENT_IN_PROGRESS:
      return {
        ...state,
        updateDependentStatus: {
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_DEPENDENT_COMPLETED:
      const dependent = action.payload;
      const { dependents } = state.employeeDetails.data;

      var index = _.findIndex(dependents, { id: dependent.id });
      dependents.splice(index, 1, dependent);
      let employeeDetails = { ...state.employeeDetails };
      _.set(employeeDetails, 'data.dependents', dependents);

      return {
        ...state,
        updateDependentStatus: {
          inProgress: false,
          error: null
        },
        employeeDetails: employeeDetails
      };
    case ActionTypes.UPDATE_DEPENDENT_FAILED:
      return {
        ...state,
        updateDependentStatus: {
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_EMPLOYER_NOTIFICATION_CONFIG_IN_PROGRESS:
      return {
        ...state,
        notificationConfig: {
          ...state.notificationConfig,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_EMPLOYER_NOTIFICATION_CONFIG_COMPLETED:
      return {
        ...state,
        notificationConfig: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_EMPLOYER_NOTIFICATION_CONFIG_FAILED:
      return {
        ...state,
        notificationConfig: {
          ...state.notificationConfig,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.REHIRE_EMPLOYEE_COMPLETED:
      return {
        ...state,
        rehireEmployeeStatus: {
          error: null,
          inProgress: false
        }
      };
    case ActionTypes.REHIRE_EMPLOYEE_IN_PROGRESS:
      return {
        ...state,
        rehireEmployeeStatus: {
          error: null,
          inProgress: true
        }
      };
    case ActionTypes.REHIRE_EMPLOYEE_FAILED:
      return {
        ...state,
        rehireEmployeeStatus: {
          error: action.payload,
          inProgress: false
        }
      };
    case ActionTypes.CHANGE_SSN_IN_PROGRESS:
      return {
        ...state,
        updateSsnStatus: {
          isUpdating: true,
          error: null
        }
      };
    case ActionTypes.CHANGE_SSN_COMPLETED:
      return {
        ...state,
        updateSsnStatus: {
          isUpdating: false,
          error: null
        }
      };
    case ActionTypes.CHANGE_SSN_FAILED:
      return {
        ...state,
        updateSsnStatus: {
          isUpdating: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
