/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { SketchPicker } from 'react-color';
import { Label, Input, InputGroup, InputGroupAddon } from 'components/Atoms';
import './ColorPicker.scss';

const ColorPicker = ({
  disabled,
  label,
  handleClick,
  handleClose,
  styleColor,
  displayColorPicker,
  textColor,
  name,
  Hex,
  placeholder,
  handleInputChange,
  handleChange
}) => {
  return (
    <div className="ben-guide-color-picker">
      <Label>{label}</Label>
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <div
            className={`swatch ${disabled ? 'disable-background' : ''}`}
            onClick={!disabled && handleClick}
          >
            <div style={styleColor} />
          </div>
          {displayColorPicker ? (
            <div className="popover">
              <div className="cover" onClick={handleClose} />
              <SketchPicker color={textColor} onChange={handleChange}/>
            </div>
          ) : null}
        </InputGroupAddon>
        <Input
          name={name}
          className="textbox"
          value={Hex}
          onClick={handleClick}
          placeholder={placeholder}
          onChange={handleInputChange}
          disabled={disabled}
        />
      </InputGroup>
    </div>
  );
};

export default ColorPicker;
