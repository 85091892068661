/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import { SyncStatus } from 'constants/syncConstants';

export const MODULE_HRIS_INTEGRATION_ENABLED =
  'MODULE_HRIS_INTEGRATION_ENABLED';

export const WORKDAY_DATE_FORMAT = 'YYYY-MM-DD';

export const INTEGRATION_STATUS = {
  SYNCING: {
    name: 'Syncing',
    color: '#F9C747'
  },
  SUCCESS: {
    name: 'Success',
    color: '#28B667'
  },

  PARTIAL_SUCCESS: {
    name: 'Partial Success',
    color: '#000000'
  },
  FAILED: {
    name: 'Failed',
    color: '#000000'
  },
  PENDING: {
    name: 'Pending',
    color: '#000000'
  }
};

export const ADAPTER_NAMES = {
  BAMBOO_HR: {
    name: 'BambooHR',
    code: 'BAMBOO_HR'
  },
  GENERIC_FILE_IMPORT: {
    name: 'Generic File Import',
    code: 'GENERIC_FILE_IMPORT'
  },
  FLOCK: {
    name: 'Flock',
    code: 'FLOCK'
  },
  WORKDAY: {
    name: 'Workday',
    code: 'WORKDAY'
  },
  PLATFORM: {
    name: 'Platform',
    code: 'PLATFORM'
  },
  WORKDAY_BENADMIN: {
    name: 'Workday',
    code: 'WORKDAY_BENADMIN'
  },
};

export const MAX_TERMINATION_DAYS_COUNT = 100;

export const INTEGRATION_TYPES = {
  HRIS: 'HRIS',
  BENADMIN: 'BEN_ADMIN'
};

export const BAMBOO_HR_SAVE_TYPE = {
  MAPPINGS_CONFIG: 'mappings-config',
  BASIC_CONFIG: 'basic-config'
};

export const VENDORS = {
  BAMBOO_HR: 'BambooHR',
  LUMITY: 'Lumity',
  FLOCK: 'Flock',
  WORKDAY: 'Workday',
  GENERIC_FILE_IMPORT: 'Generic File Import',
  PLATFORM: 'Platform',
  WORKDAY_BENADMIN: 'Workday'
};

export const ERROR_CODE_LABEL_MAP = {
  'missing.source.id': 'No source ID',
  'missing.employment.status': 'No employment status',
  'missing.first.name': 'No first name',
  'missing.last.name': 'No last name',
  'missing.gender': 'No gender',
  'missing.dob':
    'DOB cannot be blank and age must be between 14-100 years (inclusive)',
  'missing.work.email': 'No work email',
  'missing.hire.date': 'No hire date',
  'missing.benefit.group': 'No benefit group',
  'missing.pay.group': 'No pay group',
  'missing.employment.type': 'No employment type',
  'missing.salary.amount': 'No salary amount',
  'missing.salary.type': 'No salary type',
  'missing.pay.schedule': 'No pay schedule',
  'missing.salary': 'No salary',
  'missing.marital.status': 'No marital status',
  'invalid.ssn': 'Invalid SSN',
  'invalid.salary': 'Invalid salary',
  'invalid.pay.rate': 'Invalid pay rate',
  'invalid.marital.status': 'Invalid marital status',
  'invalid.mobile.phone': 'Invalid mobile phone',
  'invalid.hire.date': 'Hire date is mandatory and in MM/DD/YYYY',
  'invalid.home.phone': 'Invalid home phone',
  'invalid.work.phone': 'Invalid work phone',
  'invalid.personal.phone': 'Invalid personal phone',
  'invalid.dob.min.14.years.old': 'Age must be at least 14 years old',
  'invalid.salary.effective.date':
    'Salary effective date is mandatory and in MM/DD/YYYY',
  'invalid.rehire.date':
    'Rehire date must be populated and in MM/DD/YYYY format',
  'invalid.termination.date':
    'Termination date must be populated and in MM/DD/YYYY format',
  'unmapped.benefit.group': 'Unmapped benefit group',
  'unmapped.pay.group': 'Unmapped pay group',
  'unmapped.employment.status': 'Unmapped employment status',
  'unmapped.employment.type': 'Unmapped employment type',
  'unmapped.gender': 'Unmapped gender',
  'unmapped.marital.status': 'Unmapped maritial status',
  'unmapped.salary.type': 'Unmapped salary type',
  'unmapped.pay.schedule': 'Unmapped pay schedule',
  'bamboohr.unsupported.pay.per': 'Unsupported BambooHr PayPer',
  'bamboohr.unsupported.pay.type': 'Unsupported BambooHr PayType',
  'workday.unsupported.salary.frequency': 'Invalid salary frequency',
  'workday.invalid.salary': 'Salary cannot be blank',
  'workday.unsupported.pay.type': 'Invalid salary type',
  'workday.missing.pay.type': 'Salary type is mandatory',
  'workday.missing.gender': 'No gender / Invalid gender',
  'missing.ssn': 'No SSN',
  'invalid.gender': 'Invalid gender',
  'ben.admin.ssn.taken': 'SSN taken in Ben Admin',
  'flock.api.error': 'Flock API error',
  'internal.error': 'Internal error',
  'integration.disabled': 'Integration disabled',
  'integration.sync.timeout': 'Sync request failed due to timeout',
  'flock.http.server.failed': 'Flock HTTP server failed',
  'flock.http.call.failed': 'Flock connection failure',
  'missing.pay.rate.effective.date': 'No pay rate effective date',
  'missing.address1': 'No Address 1',
  'missing.city': 'No City',
  'missing.zip.code': 'No Zip Code',
  'missing.state': 'No State',
  'missing.job.title': 'No Job Title',
  'missing.department': 'No Department',
  'missing.location': 'No Location',
  'invalid.department': 'Invalid Department',
  'invalid.location': 'Invalid Location',
  'generic.file.import.decryption.failed':
    'Could not decrypt the file, please make sure file is properly encrypted',
  'generic.file.import.company.id.validation.failed':
    'Company code from import file does not match with configured company code',
  'invalid.employee.age': 'Age must be in between 14 - 100 years (inclusive)',
  'adapter.file.import.file.already.processed': 'File already processed',
  'missing.manager.first.name': 'Missing manager first name',
  'missing.manager.last.name': 'Missing manager last name',
  'missing.manager.email': 'Missing manager email',
  'adapter.file.import.file.not.found': 'Lumity file not found',
  'adapter.file.import.invalid.date.format': 'Invalid date format',
  'adapter.file.import.invalid.file.type': 'Invalid file type',
  'invalid.pay.group': 'Invalid pay group',
  'workdayhr.error.reading.file.content': 'Unable to read Workday content',
  'adapter.file.import.private.key.not.found': 'Private key not found',
  'hris.connection.failure': 'HRIS connection failure',
  'missing.termination.type': 'Missing termination type',
  'invalid.benefit.group': 'Invalid Benefit Group',
  'adapter.file.import.ben.admin.termination.date': 'Can only import active employees into next plan year with elections',
  'dependent.creation.failed': 'Failed to Create Dependent',
  'unknown.failure': 'Unknown'
};

export const BENADMIN_ERROR_CODE_LABEL_MAP = {
  'missing.source.id': 'No source ID',
  'missing.employment.status': 'Missing Employment Status',
  'missing.first.name': 'No first name',
  'missing.last.name': 'No last name',
  'missing.gender': 'Missing Gender',
  'missing.dob': 'Missing Date of Birth',
  'missing.work.email': 'Missing Work Email',
  'missing.hire.date': 'No hire date',
  'missing.benefit.group': 'Missing Benefit Group',
  'missing.pay.group': 'No pay group',
  'missing.employment.type': 'No employment type',
  'missing.salary.amount': 'No salary amount',
  'missing.salary.type': 'No salary type',
  'missing.pay.schedule': 'No pay schedule',
  'missing.salary': 'No salary',
  'invalid.salary': 'Invalid salary',
  'invalid.marital.status': 'Invalid marital status',
  'unmapped.benefit.group': 'Unmapped benefit group',
  'unmapped.pay.group': 'Unmapped pay group',
  'unmapped.employment.status': 'Unmapped employment status',
  'unmapped.employment.type': 'Unmapped employment type',
  'unmapped.gender': 'Unmapped gender',
  'unmapped.marital.status': 'Unmapped maritial status',
  'unmapped.salary.type': 'Unmapped salary type',
  'unmapped.pay.schedule': 'Unmapped pay schedule',
  'invalid.gender': 'Invalid Gender',
  'flock.api.error': 'Flock API error',
  'internal.error': 'Internal error',
  'integration.disabled': 'Integration disabled',
  'integration.sync.timeout': 'Sync request failed due to timeout',
  'flock.http.server.failed': 'Flock HTTP server failed',
  'flock.http.call.failed': 'Flock connection failure',
  'missing.pay.rate.effective.date': 'No pay rate effective date',
  'invalid.pay.group': 'Invalid pay group',
  'missing.termination.type': 'Missing termination type',
  'missing.offboard.date': 'Missing Offboard Date',
  'invalid.benefit.group': 'Invalid Benefit Group',
  'created.new.employee': 'Created New Employee',
  'employee.not.found.to.sync.enrollment': 'Employee Not Found',
  'created.new.employee.enrollments': 'Created New Employee Enrollments',
  'created.new.dependent.enrollments': 'Created New Dependent Enrollments',
  'failed.create.new.employee': 'Failed to Create New Employee',
  'no.plan.id.in.enrollments': 'No Plan ID / No Plan Name',
  'no.plan.name.id.in.enrollments': 'Blank Plan Name',
  'no.valid.plan.name.id.in.platform': 'Not Found Plan',
  'invalid.plan.name.id.in.enrollments': 'Invalid Plan Name',
  'invalid.tier.in.enrollments': 'Invalid Tier',
  'invalid.plan.year': 'Invalid Plan Year',
  'invalid.ee.contribution.in.enrollments': 'Invalid EE Contribution',
  'invalid.er.contribution.in.enrollments': 'Invalid ER Contribution',
  'invalid.effective.date.in.enrollments': 'Invalid Enrollment Effective Data',
  'no.plan.details': 'No Flock Plan Details',
  'no.plan.year': 'Plan Year Not Found',
  'employee.terminate.request': 'Request to Terminate',
  'employee.rehire.reactivate': 'Rehire / Reactivate',
  'employee.delete.request': 'Delete Employee',
  'plan.not.found': 'Plan Not Found',
  'invalid.us.state': 'Invalid US State',
  'dependent.creation.failed': 'Failed to Create Dependent',
  'unknown.failure': 'Unknown'
};

export const BAMBOO_HR_CONFIG_ERROR_LABEL_CODE_MAP = {
  'bamboohr.config.employer.name.invalid': 'Invalid BambooHR Employer Name',
  'bamboohr.config.api.key.invalid': 'Invalid BambooHR API Key'
};

export const LFI_CONFIG_ERROR_LABEL_CODE_MAP = {
  'lfi.config.employer.name.invalid': 'Invalid Employer Name',
  'adapter.plan.year.search.failed': 'Please Configure Benefit Groups for the Current Plan Year'
};

export const VENDOR_NAMES = {
  BAMBOO_HR: 'BambooHR',
  GFI: 'Generic File Import',
  WORKDAY: 'Workday'
};
export const VENDOR_IDS = {
  BAMBOO_HR: 'BAMBOO_HR',
  GFI: 'GFI',
  WORKDAY: 'WORKDAY',
  FLOCK: 'FLOCK',
  BEN_ADMIN: 'BEN_ADMIN'
};
export const VENDORS_LIST = [
  { name: [VENDOR_NAMES.BAMBOO_HR], value: VENDOR_IDS.BAMBOO_HR },
  { name: [VENDOR_NAMES.GFI], value: VENDOR_IDS.GFI },
  { name: [VENDOR_NAMES.WORKDAY], value: VENDOR_IDS.WORKDAY }
];

export const ERROR_MSGS = {
  REQUIRED_FIELDS: 'Please enter required fields.',
  DUPLICATES_EXIST: 'Duplicate fields found.'
};

export const MENU_ACTIONS = {
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  DOWNLOAD: 'DOWNLOAD',
  DOWNLOAD_CONFIG_FILE: 'DOWNLOAD_CONFIG_FILE',
  VIEW: 'VIEW'
};

export const STATUS_STATES = {
  FAILED: 'FAILED',
  PARTIAL_SUCCESS: 'PARTIAL_SUCCESS',
  PENDING: 'PENDING',
  SYNCING: 'SYNCING',
  SUCCESS: 'SUCCESS'
};

export const SYNC_FLOCK_IDS_DATE_FORMAT = 'M/D/YY @ h:mma';

export const getStatusText = (syncStatus) => {
  switch (syncStatus) {
    case SyncStatus.SYNCING.value:
      return 'updating';
    case SyncStatus.SUCCESS.value:
      return 'updated';
    case SyncStatus.FAILED.value:
      return 'failed';
    default:
      return 'failed';
  }
};

export const EXPRESSION_PATTERN = {
  TIME_FORMAT: '.00 ',
  START: '0 0',
  END: ' * * ?'
};
