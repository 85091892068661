import axios from 'axios';
import Util from '../util/apiUtil';
import _ from 'lodash';

const baseURL = `${Util.baseApi}/${Util.version}/communications/issues`;

export default {
  createCase(employerId, issue) {
    const { files } = issue;
    delete issue.files;

    const formData = new FormData();
    Object.keys(issue).forEach((key) => {
      formData.append(key, issue[key]);
    });

    files.forEach((file) => {
      formData.append('files', file);
    });

    return axios({
      method: 'post',
      url: baseURL,
      data: formData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: {
        logType: null,
        employerId: employerId,
        isPlatform: true
      }
    });
  },

  findIssueAssignees(orgId, archived) {
    return axios.get(`${baseURL}/assignees?organizationId=${orgId}&archived=${archived}`);
  },

  updateCase(employerId, issue) {
    delete issue.files;
    const formData = new FormData();
    Object.keys(issue).forEach((key) => {
      formData.append(key, issue[key]);
    });
    return axios({
      method: 'put',
      url: baseURL,
      data: formData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: {
        employerId: employerId
      }
    });
  },

  filter(page, size, sort, query, filters, orgId) {
    const params = {
      _page: page,
      _size: size,
      _sort: sort
    };
    if (query && !_.isEmpty(query)) {
      params['searchKey'] = encodeURIComponent(query);
    }
    params['organizationId'] = orgId;
    return axios.post(`${baseURL}/filter`, filters, { params: params });
  },

  /**
   * find an issue by its id and employer id
   * @param employerId - Employer ID.
   * @param issueId - Issue ID.
   */
  getIssueById(employerId, issueId) {
    const params = {
      platform: true
    };
    const employerIdParam = employerId ? employerId : '';
    return axios.get(`${baseURL}/${issueId}?employerId=${employerIdParam}`, {
      params: params
    });
  },

  /**
   * get all ER Admins from employer id
   * @param employerId - Employer ID.
   */
  getErAdmins(employerId) {
    return axios.get(`${baseURL}/${employerId}/eradmins`);
  },

  updateWatcherOpt(watcherId, issueId, isOptIn) {
    return axios.put(
      `${baseURL}/${issueId}/watchers/${watcherId}?optIn=${isOptIn}`
    );
  },

  createCaseComment(employerId, comment, issueId) {
    const { files } = comment;
    delete comment.files;

    const formData = new FormData();
    Object.keys(comment).forEach((key) => {
      formData.append(key, comment[key]);
    });

    files.forEach((file) => {
      formData.append('files', file);
    });

    return axios({
      method: 'post',
      url: `${baseURL}/${issueId}/comments`,
      data: formData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: {
        employerId: employerId,
        isPlatform: true
      }
    });
  },

  /**
   * export issue list table data
   * @param filters - Table filters
   * @param query - Filters by search query
   */
  export(filters, sortField, query, zone, orgId) {
    const params = { _sort: sortField };
    if (query && !_.isEmpty(query)) {
      params['searchKey'] = encodeURIComponent(query);
    }
    params['zone'] = zone;
    params['organizationId'] = orgId;

    return axios.post(`${baseURL}/export`, filters, {
      params: params,
      responseType: 'blob'
    });
  },

  resolveCase(caseId, employerId, comment) {
    return axios({
      method: 'put',
      url: `${baseURL}/${caseId}/resolve`,
      params: {
        employerId: employerId,
        platform: true
      },
      data: comment
    });
  },

  reopenCase(caseId, employerId, comment) {
    return axios({
      method: 'post',
      url: `${baseURL}/${caseId}/reopen`,
      params: {
        employerId: employerId,
        platform: true
      },
      data: comment
    });
  },
  getOriginalEmail(caseId, commentId) {
    return axios.get(`${baseURL}/${caseId}/comments/${commentId}/email`);
  }
};
