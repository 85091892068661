/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { Button, Row } from 'components/Atoms';
import { Input } from 'reactstrap';
import './DialogIntegrationDetails.scss';
class VendorSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVendor: props.selectedVendor || {
        name: ''
      }
    };
  }
  onSelectvendor = (e) => {
    this.setState({
      selectedVendor: {
        name: e.target.value
      }
    });
  };
  render() {
    const { vendorList, setSelection } = this.props;
    const { selectedVendor } = this.state;
    return (
      <div className="step-container vendor-selector">
        <div className="body">
          <Row>
            <label>
              <b>HRIS Vendor</b>
            </label>
            <Input
              type="select"
              value={selectedVendor.name}
              name="selectedVendor"
              className="select"
              onChange={this.onSelectvendor}
            >
              <option key="select" value="">
                Select Option
              </option>
              {vendorList.map((item) => {
                const { name } = item;
                return (
                  <option key={name} value={name}>
                    {name}
                  </option>
                );
              })}
            </Input>
          </Row>
        </div>
        <Row>
          <Button
            className="form-element"
            color="primary"
            size="lg"
            disabled={!(selectedVendor && selectedVendor.name)}
            onClick={() => setSelection(this.state.selectedVendor)}
          >
            Next
          </Button>
        </Row>
      </div>
    );
  }
}

export default VendorSelector;
