/**********************************************************************************************************************
 * Copyright (C) 2021 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/planYear.actionTypes';

const initialState = {
  planYears: {
    data: [],
    isFetching: false,
    error: null
  },
  createPlanYearStatus: {
    data: {},
    inProgress: false,
    error: null
  },
  updatePlanYearStatus: {
    data: {},
    inProgress: false,
    error: null
  },
  deletePlanYearStatus: {
    data: {},
    inProgress: false,
    error: null
  },
  validatePlanYearDeletionStatus: {
    data: {},
    inProgress: false,
    error: null
  },
  changeCurrentPlanYearStatus: {
    data: {},
    inProgress: false,
    error: null
  },
  planYearByType: {
    data: [],
    isFetching: false,
    error: null
  },
  attachedPlansCount: {
    data: null,
    error: null,
    isLoading: false
  },
  attachedBenGuidesCount: {
    data: null,
    error: null,
    isLoading: false
  },
  attachedElectionsCount: {
    data: null,
    error: null,
    isLoading: false
  },
  potentialPlanYears: {
    data: {
      plansForUniquePeriods: []
    },
    isFetching: false,
    error: null
  },
  associatedPlanCount: {
    data: null,
    isFetching: false,
    error: null
  },
  associatedElectionCount: {
    data: null,
    isFetching: false,
    error: null
  },
  associatedBenguides: {
    data: null,
    isFetching: false,
    error: null
  },
  planYearsMigratedPlans: {
    data: null,
    isFetching: false,
    error: null
  },
  planYearsMigratedElections: {
    data: null,
    isFetching: false,
    error: null
  },
  planYearsMigratedBenguides: {
    data: null,
    isFetching: false,
    error: null
  },
  associateBenefitGroupDetails: {
    data: null,
    inProgress: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PLAN_YEAR_IN_PROGRESS:
      return {
        ...state,
        planYears: {
          ...state.planYears,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_PLAN_YEAR_COMPLETED:
      return {
        ...state,
        planYears: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_PLAN_YEAR_FAILED:
      return {
        ...state,
        planYears: {
          ...state.planYears,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.CREATE_PLAN_YEAR_IN_PROGRESS:
      return {
        ...state,
        createPlanYearStatus: {
          ...state.createPlanYearStatus,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.CREATE_PLAN_YEAR_COMPLETED:
      return {
        ...state,
        createPlanYearStatus: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.CREATE_PLAN_YEAR_FAILED:
      return {
        ...state,
        createPlanYearStatus: {
          ...state.createPlanYearStatus,
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.UPDATE_PLAN_YEAR_IN_PROGRESS:
      return {
        ...state,
        updatePlanYearStatus: {
          ...state.updatePlanYearStatus,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_PLAN_YEAR_COMPLETED:
      return {
        ...state,
        updatePlanYearStatus: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.UPDATE_PLAN_YEAR_FAILED:
      return {
        ...state,
        updatePlanYearStatus: {
          ...state.updatePlanYearStatus,
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.DELETE_PLAN_YEAR_IN_PROGRESS:
      return {
        ...state,
        deletePlanYearStatus: {
          ...state.deletePlanYearStatus,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.DELETE_PLAN_YEAR_COMPLETED:
      return {
        ...state,
        deletePlanYearStatus: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.DELETE_PLAN_YEAR_FAILED:
      return {
        ...state,
        deletePlanYearStatus: {
          ...state.deletePlanYearStatus,
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.DELETE_VALIDATE_PLAN_YEAR_IN_PROGRESS:
      return {
        ...state,
        validatePlanYearDeletionStatus: {
          ...state.validatePlanYearDeletionStatus,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.DELETE_VALIDATE_PLAN_YEAR_COMPLETED:
      return {
        ...state,
        validatePlanYearDeletionStatus: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.DELETE_VALIDATE_PLAN_YEAR_FAILED:
      return {
        ...state,
        validatePlanYearDeletionStatus: {
          ...state.validatePlanYearDeletionStatus,
          data: action.payload.data,
          inProgress: false,
          error: action.payload.error
        }
      };
    case ActionTypes.CHANGE_CURRENT_PLAN_YEAR_IN_PROGRESS:
      return {
        ...state,
        changeCurrentPlanYearStatus: {
          ...state.changeCurrentPlanYearStatus,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.CHANGE_CURRENT_PLAN_YEAR_COMPLETED:
      return {
        ...state,
        changeCurrentPlanYearStatus: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.CHANGE_CURRENT_PLAN_YEAR_FAILED:
      return {
        ...state,
        changeCurrentPlanYearStatus: {
          ...state.changeCurrentPlanYearStatus,
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_PLAN_YEAR_BY_TYPE_IN_PROGRESS:
      return {
        ...state,
        planYearByType: {
          ...state.planYearByType,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_PLAN_YEAR_BY_TYPE_COMPLETED:
      return {
        ...state,
        planYearByType: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_PLAN_YEAR_BY_TYPE_FAILED:
      return {
        ...state,
        planYearByType: {
          ...state.planYearByType,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_ATTACHED_PLANS_COUNT_IN_PROGRESS:
      return {
        ...state,
        attachedPlansCount: {
          data: null,
          error: null,
          isLoading: true
        }
      };
    case ActionTypes.GET_ATTACHED_PLANS_COUNT_SUCCESS:
      return {
        ...state,
        attachedPlansCount: {
          data: action.payload,
          error: null,
          isLoading: false
        }
      };
    case ActionTypes.GET_ATTACHED_PLANS_COUNT_FAILED:
      return {
        ...state,
        attachedPlansCount: {
          data: null,
          error: action.payload,
          isLoading: false
        }
      };
    case ActionTypes.GET_ATTACHED_BEN_GUIDE_COUNT_IN_PROGRESS:
      return {
        ...state,
        attachedBenGuidesCount: {
          data: null,
          error: null,
          isLoading: true
        }
      };
    case ActionTypes.GET_ATTACHED_BEN_GUIDE_COUNT_SUCCESS:
      return {
        ...state,
        attachedBenGuidesCount: {
          data: action.payload,
          error: null,
          isLoading: false
        }
      };
    case ActionTypes.GET_ATTACHED_BEN_GUIDE_COUNT_FAILED:
      return {
        ...state,
        attachedBenGuidesCount: {
          data: null,
          error: action.payload,
          isLoading: false
        }
      };
    case ActionTypes.GET_ATTACHED_ELECTIONS_COUNT_IN_PROGRESS:
      return {
        ...state,
        attachedElectionsCount: {
          data: null,
          error: null,
          isLoading: true
        }
      };
    case ActionTypes.GET_ATTACHED_ELECTIONS_COUNT_SUCCESS:
      return {
        ...state,
        attachedElectionsCount: {
          data: action.payload,
          error: null,
          isLoading: false
        }
      };
    case ActionTypes.GET_ATTACHED_ELECTIONS_COUNT_FAILED:
      return {
        ...state,
        attachedBenGuidesCount: {
          data: null,
          error: action.payload,
          isLoading: false
        }
      };
    case ActionTypes.GET_POTENTIAL_PLAN_YEARS_IN_PROGRESS:
      return {
        ...state,
        potentialPlanYears: {
          ...state.potentialPlanYears,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_POTENTIAL_PLAN_YEARS_COMPLETED:
      return {
        ...state,
        potentialPlanYears: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_POTENTIAL_PLAN_YEARS_FAILED:
      return {
        ...state,
        potentialPlanYears: {
          ...state.potentialPlanYears,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_ASSOCIATED_PLANS_COUNT_IN_PROGRESS:
      return {
        ...state,
        associatedPlanCount: {
          ...state.associatedPlanCount,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_ASSOCIATED_PLANS_COUNT_COMPLETED:
      return {
        ...state,
        associatedPlanCount: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_ASSOCIATED_PLANS_COUNT_FAILED:
      return {
        ...state,
        associatedPlanCount: {
          ...state.associatedPlanCount,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_ASSOCIATED_ELECTIONS_COUNT_IN_PROGRESS:
      return {
        ...state,
        associatedElectionCount: {
          ...state.associatedElectionCount,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_ASSOCIATED_ELECTIONS_COUNT_COMPLETED:
      return {
        ...state,
        associatedElectionCount: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_ASSOCIATED_ELECTIONS_COUNT_FAILED:
      return {
        ...state,
        associatedElectionCount: {
          ...state.associatedElectionCount,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_ASSOCIATED_BENGUIDES_IN_PROGRESS:
      return {
        ...state,
        associatedBenguides: {
          ...state.associatedBenguides,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_ASSOCIATED_BENGUIDES_COMPLETED:
      return {
        ...state,
        associatedBenguides: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.GET_ASSOCIATED_BENGUIDES_FAILED:
      return {
        ...state,
        associatedBenguides: {
          ...state.associatedBenguides,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.PLAN_YEAR_MIGRATION_FOR_PLANS_IN_PROGRESS:
      return {
        ...state,
        planYearsMigratedPlans: {
          ...state.planYearsMigratedPlans,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.PLAN_YEAR_MIGRATION_FOR_PLANS_COMPLETED:
      return {
        ...state,
        planYearsMigratedPlans: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.PLAN_YEAR_MIGRATION_FOR_PLANS_FAILED:
      return {
        ...state,
        planYearsMigratedPlans: {
          ...state.planYearsMigratedPlans,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.PLAN_YEAR_MIGRATION_FOR_ELECTIONS_IN_PROGRESS:
      return {
        ...state,
        planYearsMigratedElections: {
          ...state.planYearsMigratedElections,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.PLAN_YEAR_MIGRATION_FOR_ELECTIONS_COMPLETED:
      return {
        ...state,
        planYearsMigratedElections: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.PLAN_YEAR_MIGRATION_FOR_ELECTIONS_FAILED:
      return {
        ...state,
        planYearsMigratedElections: {
          ...state.planYearsMigratedElections,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.PLAN_YEAR_MIGRATION_FOR_BENGUIDES_IN_PROGRESS:
      return {
        ...state,
        planYearsMigratedBenguides: {
          ...state.planYearsMigratedBenguides,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.PLAN_YEAR_MIGRATION_FOR_BENGUIDES_COMPLETED:
      return {
        ...state,
        planYearsMigratedBenguides: {
          data: action.payload,
          isFetching: false,
          error: null
        }
      };
    case ActionTypes.PLAN_YEAR_MIGRATION_FOR_BENGUIDES_FAILED:
      return {
        ...state,
        planYearsMigratedBenguides: {
          ...state.planYearsMigratedBenguides,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.PLAN_YEAR_MIGRATION_ASSOCIATE_BENEFIT_GROUP_IN_PROGRESS:
      return {
        ...state,
        associateBenefitGroupDetails: {
          ...state.associateBenefitGroupDetails,
          inProgress: true,
          error: action.payload
        }
      };
    case ActionTypes.PLAN_YEAR_MIGRATION_ASSOCIATE_BENEFIT_GROUP_COMPLETED:
      return {
        ...state,
        associateBenefitGroupDetails: {
          ...state.associateBenefitGroupDetails,
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.PLAN_YEAR_MIGRATION_ASSOCIATE_BENEFIT_GROUP_FAILED:
      return {
        ...state,
        associateBenefitGroupDetails: {
          ...state.associateBenefitGroupDetails,
          inProgress: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
