/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import EmployerFeatureConfigurationService from 'services/EmployerFeatureConfigurationService';
import EmployerService from 'services/EmployerService';
import IntegrationConfigurationService from 'services/IntegrationConfigurationService';

import {
  FIND_CHATBOT_EMPLOYER_CONFIGURATION_MODULES,
  FIND_MOBILE_EMPLOYER_CONFIGURATION_MODULES,
  FIND_CONSULTATION_CONFIGURATION_MODULES,
  FIND_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  CREATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  UPDATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  UPDATE_EMPLOYER_CHATBOT_CONFIG,
  UPDATE_EMPLOYER_CHATBOT_CONFIG_IN_PROGRESS,
  UPDATE_EMPLOYER_CHATBOT_CONFIG_ERROR,
  GET_CHATBOT_EMPLOYER_IN_PROGRESS,
  GET_CHATBOT_EMPLOYER_SUCCESS,
  GET_CHATBOT_EMPLOYER_ERROR,
  FIND_DBG_ID_CARD_CONFIGURATION_MODULES,
  FIND_ER_PORTAL_ID_CARD_CONFIGURATION_MODULES
} from '../actionTypes/mobile.actionTypes';

import {
  FEATURE_KEY_MOBILE,
  CREATE_STATUS,
  UPDATE_STATUS,
  FEATURE_KEY_CHATBOT,
  FEATURE_KEY_BENEFIT_CONSULTATION,
  FEATURE_KEY_MODULE_DBG_ID_CARD_ENABLED,
  FEATURE_KEY_MODULE_ER_PORTAL_ID_CARD_ENABLED
} from '../constants';

export const findConfigStatus = (employerId, key) => {
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.findConfigStatus(
        employerId,
        key
      );
      if (key === FEATURE_KEY_MOBILE) {
        dispatch({
          type: FIND_MOBILE_EMPLOYER_CONFIGURATION_MODULES,
          payload: response.data
        });
      }
      if (key === FEATURE_KEY_MODULE_DBG_ID_CARD_ENABLED) {
        dispatch({
          type: FIND_DBG_ID_CARD_CONFIGURATION_MODULES,
          payload: response.data
        });
      }
      if (key === FEATURE_KEY_MODULE_ER_PORTAL_ID_CARD_ENABLED) {
        dispatch({
          type: FIND_ER_PORTAL_ID_CARD_CONFIGURATION_MODULES,
          payload: response.data
        });
      }
      if (key === FEATURE_KEY_CHATBOT) {
        dispatch({
          type: FIND_CHATBOT_EMPLOYER_CONFIGURATION_MODULES,
          payload: response.data
        });
      }
      if (key === FEATURE_KEY_BENEFIT_CONSULTATION) {
        dispatch({
          type: FIND_CONSULTATION_CONFIGURATION_MODULES,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({
        type: FIND_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const createConfigModule = (employerId, key) => {
  const mobileConfig = {
    employerId: employerId,
    key: key,
    value: String(true)
  };
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.createConfigModule(
        mobileConfig
      );
      let isCreated = response.status === CREATE_STATUS;
      if (isCreated) {
        try {
          const response = await EmployerFeatureConfigurationService.findConfigStatus(
            employerId,
            key
          );
          if (key === FEATURE_KEY_MOBILE) {
            dispatch({
              type: FIND_MOBILE_EMPLOYER_CONFIGURATION_MODULES,
              payload: response.data
            });
          }
          if (key === FEATURE_KEY_MODULE_DBG_ID_CARD_ENABLED) {
            dispatch({
              type: FIND_DBG_ID_CARD_CONFIGURATION_MODULES,
              payload: response.data
            });
          }
          if (key === FEATURE_KEY_MODULE_ER_PORTAL_ID_CARD_ENABLED) {
            dispatch({
              type: FIND_ER_PORTAL_ID_CARD_CONFIGURATION_MODULES,
              payload: response.data
            });
          }
          if (key === FEATURE_KEY_CHATBOT) {
            dispatch({
              type: FIND_CHATBOT_EMPLOYER_CONFIGURATION_MODULES,
              payload: response.data
            });
          }
        } catch (error) {
          dispatch({
            type: FIND_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR,
            payload: error
          });
        }
      }
    } catch (error) {
      dispatch({
        type: CREATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const updateConfigModule = (id, employerId, value, key) => {
  const mobileConfig = {
    id: id,
    employerId: employerId,
    key: key,
    value: String(value === 'false')
  };
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.updateConfigModule(
        mobileConfig
      );
      let isUpdated = response.status === UPDATE_STATUS;
      if (isUpdated) {
        try {
          const response = await EmployerFeatureConfigurationService.findConfigStatus(
            employerId,
            key
          );
          if (key === FEATURE_KEY_MOBILE) {
            dispatch({
              type: FIND_MOBILE_EMPLOYER_CONFIGURATION_MODULES,
              payload: response.data
            });
          }
          if (key === FEATURE_KEY_MODULE_DBG_ID_CARD_ENABLED) {
            dispatch({
              type: FIND_DBG_ID_CARD_CONFIGURATION_MODULES,
              payload: response.data
            });
          }
          if (key === FEATURE_KEY_MODULE_ER_PORTAL_ID_CARD_ENABLED) {
            dispatch({
              type: FIND_ER_PORTAL_ID_CARD_CONFIGURATION_MODULES,
              payload: response.data
            });
          }
          if (key === FEATURE_KEY_CHATBOT) {
            dispatch({
              type: FIND_CHATBOT_EMPLOYER_CONFIGURATION_MODULES,
              payload: response.data
            });
          }
        } catch (error) {
          dispatch({
            type: FIND_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR,
            payload: error
          });
        }
      }
    } catch (error) {
      dispatch({
        type: UPDATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const updateChatbotConfig = (employerId, config) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_EMPLOYER_CHATBOT_CONFIG_IN_PROGRESS });
    try {
      const response = await IntegrationConfigurationService.updateChatbotConfig(
        employerId,
        config
      );
      const isSuccess = response.status === UPDATE_STATUS;
      dispatch({ type: UPDATE_EMPLOYER_CHATBOT_CONFIG, payload: isSuccess });
    } catch (error) {
      dispatch({ type: UPDATE_EMPLOYER_CHATBOT_CONFIG_ERROR, payload: error });
    }
  };
};

export const getEmployer = (employerId) => {
  return async (dispatch) => {
    dispatch({ type: GET_CHATBOT_EMPLOYER_IN_PROGRESS });
    try {
      const response = await EmployerService.findById(employerId);
      dispatch({
        type: GET_CHATBOT_EMPLOYER_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: GET_CHATBOT_EMPLOYER_ERROR, payload: error });
    }
  };
};

export const updateChatbotFallbackOption = (employerId, config) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_EMPLOYER_CHATBOT_CONFIG_IN_PROGRESS });
    try {
      const response = await IntegrationConfigurationService.updateChatbotFallbackOption(
        employerId,
        config
      );
      const isSuccess = response.status === UPDATE_STATUS;
      dispatch({ type: UPDATE_EMPLOYER_CHATBOT_CONFIG, payload: isSuccess });
    } catch (error) {
      dispatch({ type: UPDATE_EMPLOYER_CHATBOT_CONFIG_ERROR, payload: error });
    }
  };
};
