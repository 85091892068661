/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Button,
  Row,
  Col,
  Input,
  FormGroup,
  FormFeedback,
  Alert
} from 'components/Atoms';

import Icon from 'components/Icons';
import { VENDOR_NAMES, ERROR_MSGS } from 'modules/Integrations/constants';

export const WORKDAY_BENEFIT_GROUP = 'workdayBenefitGroup'

const BenefitGroupRow = ({
  index,
  lumityBenefitGroups = [],
  updateBenefitGroup,
  removeFieldRow,
  workdayBenefitGroup,
  benefitGroup = '',
  error,
  errors
}) => {
  const fieldErrors = errors[index];
  return (
    <Row>
      <Col md="1">
        <Button
          color="link"
          onClick={removeFieldRow}
          className="remove-group-icon"
        >
          <Icon icon="minus-circle" />
        </Button>
      </Col>
      <Col className="workday-text-box">
        <FormGroup>
          <Input
            type="text"
            name="workdayBenefitGroup"
            onChange={updateBenefitGroup}
            value={workdayBenefitGroup}
            invalid={fieldErrors && fieldErrors[WORKDAY_BENEFIT_GROUP]}
          />
          {error && <FormFeedback>{error}</FormFeedback>}
        </FormGroup>
      </Col>
      <Col>
        <Input
          type="select"
          name="benefitGroup"
          value={benefitGroup}
          onChange={updateBenefitGroup}
          invalid={!!fieldErrors && fieldErrors['benefitGroup']}
        >
          <option key="select" value="">
            Select Option
          </option>
          {lumityBenefitGroups.map((field) => {
            return (
              <option key={field} value={field}>
                {field}
              </option>
            );
          })}
        </Input>
      </Col>
    </Row>
  );
};

export class BenefitGroupMapping extends Component {
  static propTypes = {
    lumityBenefitGroups: PropTypes.arrayOf(PropTypes.string),
    // Map values as a object with workdayBenefitGroup and lumityBenefitGroup
    benefitGroupConfiguration: PropTypes.objectOf(
      PropTypes.shape({
        benefitGroup: PropTypes.string,
        workdayBenefitGroup: PropTypes.string
      })
    ),
    // Expect (benefitGroupConfiguration) => any
    onPrevious: PropTypes.func,
    // Expect (benefitGroupConfiguration) => any
    onNext: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      ignoredBenefitGroups: '',
      errors: [],
      errorMsgs: [],
      benefitGroupConfiguration:
        props.benefitGroupConfiguration &&
        props.benefitGroupConfiguration.length
          ? props.benefitGroupConfiguration
          : [
              {
                workdayBenefitGroup: '',
                benefitGroup: ''
              }
            ]
    };
  }

  updateBenefitGroup = (index) => (e) => {
    let { benefitGroupConfiguration, errors } = this.state;
    const updatedBenefitGroups = [...benefitGroupConfiguration];
    const benefitGroup = updatedBenefitGroups[index];
    const { name, value } = e.target;

    benefitGroup[name] = value;
    if (value && errors[index] && errors[index][name]) {
      delete errors[index][name];
      if (_.isEmpty(errors[index])) delete errors[index];
    }
    this.setState({
      benefitGroupConfiguration: updatedBenefitGroups
    });
  };

  removeFieldRow = (index) => (e) => {
    const { benefitGroupConfiguration, errors } = this.state;
    const updatedBenefitGroups = [...benefitGroupConfiguration];

    updatedBenefitGroups.splice(index, 1);
    if (errors[index]) {
      delete errors[index];
      if (_.isEmpty(errors[index])) delete errors[index];
    }
    this.setState({
      benefitGroupConfiguration: updatedBenefitGroups
    });
  };

  onPrevious = () => {
    const { benefitGroupConfiguration } = this.state;
    const { selectedVendor, onPrevious } = this.props;
    let benefitGroupMappingsConfig = [];
    let workdayBenefitGroups = [];
    let errors = {};
    let errorMsgs = [];
    if (selectedVendor.name === VENDOR_NAMES.WORKDAY) {
      benefitGroupConfiguration.forEach(
        ({ workdayBenefitGroup, benefitGroup }, index) => {
          if (_.isEmpty(workdayBenefitGroup) && _.isEmpty(benefitGroup))
            return false;
          if (_.isEmpty(workdayBenefitGroup)) {
            errors = this.addError(index, WORKDAY_BENEFIT_GROUP, errors);
            errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
            return false;
          } else {
            if (workdayBenefitGroups.includes(workdayBenefitGroup)) {
              errors = this.addError(index, WORKDAY_BENEFIT_GROUP, errors);
              errorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
            } else {
              workdayBenefitGroups.push(workdayBenefitGroup);
            }
          }
          if (_.isEmpty(benefitGroup)) {
            errors = this.addError(index, 'benefitGroup', errors);
            errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          }
          workdayBenefitGroup = _.trim(workdayBenefitGroup);
          benefitGroupMappingsConfig.push({
            workdayBenefitGroup,
            benefitGroup
          });
        }
      );
    }
    if (_.isEmpty(errors)) {
      // set to null if it is empty array
      if (_.isEmpty(benefitGroupMappingsConfig)) {
        benefitGroupMappingsConfig = null;
      }
      onPrevious(benefitGroupConfiguration);
    } else {
      this.setState({
        errors,
        errorMsgs: _.uniq(errorMsgs)
      });
    }
  };

  addError(index, errorFieldName, errors) {
    errors = {
      ...errors,
      [index]: {
        ...errors[index],
        [errorFieldName]: true
      }
    };
    return errors;
  }

  onNext = () => {
    const { benefitGroupConfiguration } = this.state;
    const { selectedVendor, onNext } = this.props;
    let benefitGroupMappingsConfig = [];
    let workdayBenefitGroups = [];
    let errors = {};
    let errorMsgs = [];
    if (selectedVendor.name === VENDOR_NAMES.WORKDAY) {
      benefitGroupConfiguration.forEach(
        ({ workdayBenefitGroup, benefitGroup }, index) => {
          if (_.isEmpty(workdayBenefitGroup) && _.isEmpty(benefitGroup))
            return false;
          if (_.isEmpty(workdayBenefitGroup)) {
            errors = this.addError(index, WORKDAY_BENEFIT_GROUP, errors);
            errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
            return false;
          } else {
            if (workdayBenefitGroups.includes(workdayBenefitGroup)) {
              errors = this.addError(index, WORKDAY_BENEFIT_GROUP, errors);
              errorMsgs.push(ERROR_MSGS.DUPLICATES_EXIST);
            } else {
              workdayBenefitGroups.push(workdayBenefitGroup);
            }
          }
          if (_.isEmpty(benefitGroup)) {
            errors = this.addError(index, 'benefitGroup', errors);
            errorMsgs.push(ERROR_MSGS.REQUIRED_FIELDS);
          }
          workdayBenefitGroup = _.trim(workdayBenefitGroup);
          benefitGroupMappingsConfig.push({
            workdayBenefitGroup,
            benefitGroup
          });
        }
      );
    }
    if (_.isEmpty(errors)) {
      // set to null if it is empty array
      if (_.isEmpty(benefitGroupMappingsConfig)) {
        benefitGroupMappingsConfig = null;
      }
      onNext(benefitGroupMappingsConfig);
    } else {
      this.setState({
        errors,
        errorMsgs: _.uniq(errorMsgs)
      });
    }
  };

  // Validate each row to see that either an option is selected or "Ignore" is checked.
  // Return boolean for whether all fields were valid and update error object in state.
  validateFields = () => {
    const { bambooHrBenefitGroups = [] } = this.props;
    const { benefitGroupConfiguration } = this.state;

    const errors = {};
    bambooHrBenefitGroups.forEach(({ bambooHrBenefitGroupId: groupId }) => {
      const { lumityBenefitGroup, ignore } =
        benefitGroupConfiguration[groupId] || {};
      // Input for the benefit group is invalid if no Group selected and ignore not selected
      if (!lumityBenefitGroup && !ignore) {
        errors[groupId] = 'Please select a Lumity Benefit Group.';
      }
    });
    this.setState({ errors });

    return _.isEmpty(errors);
  };

  addMoreConfigurations = () => {
    this.setState({
      benefitGroupConfiguration: [
        ...this.state.benefitGroupConfiguration,
        {
          workdayBenefitGroup: '',
          benefitGroup: ''
        }
      ]
    });
  };

  render() {
    const { selectedVendor, lumityBenefitGroups } = this.props;

    const { errors, benefitGroupConfiguration, errorMsgs } = this.state;
    return (
      <div className="step-container mapping-container">
        <div className="body margin-bottom-30">
          <p className="subtitle">{selectedVendor.name}</p>
          {!_.isEmpty(errors) &&
            errorMsgs.map((errorMsg, index) => (
              <Alert key={index} color="danger" className="error-alert">
                {errorMsg}
              </Alert>
            ))}
          <div>
            <Row className="group-sub-row">
              <Col className="group-sub-headers">
                {selectedVendor.name} Benefit Group
              </Col>
              <Col className="group-sub-headers">Lumity Benefit Group</Col>
            </Row>
            {benefitGroupConfiguration.map((benefitGroup, index) => (
              <BenefitGroupRow
                index={index}
                lumityBenefitGroups={lumityBenefitGroups}
                benefitGroup={benefitGroup.benefitGroup}
                workdayBenefitGroup={benefitGroup.workdayBenefitGroup}
                updateBenefitGroup={this.updateBenefitGroup(index)}
                removeFieldRow={this.removeFieldRow(index)}
                errors={errors}
              />
            ))}
          </div>
          <Row>
            <Button color="link" onClick={this.addMoreConfigurations}>
              +Add More
            </Button>
          </Row>
        </div>
        <Row className="action-btns">
          <Col>
            <Button
              className="form-element"
              outline
              color="primary"
              size="lg"
              onClick={this.onPrevious}
              disabled={!_.isEmpty(errors)}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              className="form-element"
              color="primary"
              size="lg"
              onClick={this.onNext}
              disabled={!_.isEmpty(errors)}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
