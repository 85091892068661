/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import EmployerFeatureConfigurationService from 'services/EmployerFeatureConfigurationService';
import SurveysService from 'services/SurveysService';
import * as ActionTypes from '../actionTypes/surveys.actionTypes';
import { MODULE_SURVEYS_ENABLED } from '../constants';

export const getSurveyFeatureConfig = (employerId) => {
  //get feature cong
  return async (dispatch) => {
    dispatch({ type: ActionTypes.GET_SURVEY_FEATURE_CONF });
    try {
      let response;
      response = await EmployerFeatureConfigurationService.findConfigStatus(
        employerId,
        MODULE_SURVEYS_ENABLED
      );
      if (!response.data) {
        // if doesn't exists create one
        const featureConfig = {
          employerId: employerId,
          key: MODULE_SURVEYS_ENABLED,
          value: String(false)
        };
        response = await EmployerFeatureConfigurationService.createConfigModule(
          featureConfig
        );
      }
      dispatch({
        type: ActionTypes.GET_SURVEY_FEATURE_CONF_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_SURVEY_FEATURE_CONF_ERROR,
        payload: error
      });
    }
  };
};

export const updateSurveyFeatureConfig = (config) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.UPDATE_SURVEY_FEATURE_CONF });
      let response;
      response = await EmployerFeatureConfigurationService.updateConfigModule(
        config
      );
      response = await EmployerFeatureConfigurationService.findConfigStatus(
        config.employerId,
        MODULE_SURVEYS_ENABLED
      );

      dispatch({
        type: ActionTypes.UPDATE_SURVEY_FEATURE_CONF_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_SURVEY_FEATURE_CONF_ERROR,
        payload: error
      });
    }
  };
};

export const getSurveys = (surveyConfig) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GET_SURVEY_LIST, payload: null });
      const response = await SurveysService.getSurveys(surveyConfig);
      dispatch({
        type: ActionTypes.GET_SURVEY_LIST_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: ActionTypes.GET_SURVEY_LIST_ERROR, payload: error });
    }
  };
};

export const createSurvey = (surveyDetails) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.CREATE_SURVEY, payload: surveyDetails });
      const response = await SurveysService.createSurvey(surveyDetails);
      dispatch({
        type: ActionTypes.CREATE_SURVEY_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: ActionTypes.CREATE_SURVEY_ERROR, payload: error });
    }
  };
};

export const updateSurvey = (surveyDetails) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.UPDATE_SURVEY, payload: surveyDetails });
      const response = await SurveysService.updateSurvey(surveyDetails);

      dispatch({
        type: ActionTypes.UPDATE_SURVEY_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: ActionTypes.UPDATE_SURVEY_ERROR, payload: error });
    }
  };
};

export const deleteSurvey = (surveyId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.DELETE_SURVEY, payload: surveyId });
      const response = await SurveysService.deleteSurvey(surveyId);
      dispatch({
        type: ActionTypes.DELETE_SURVEY_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: ActionTypes.DELETE_SURVEY_ERROR, payload: error });
    }
  };
};

export const getSurveyDetails = (surveyId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GET_SURVEY_DETAILS, payload: surveyId });
      const response = await SurveysService.getSurveyDetails(surveyId);
      dispatch({
        type: ActionTypes.GET_SURVEY_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: ActionTypes.GET_SURVEY_DETAILS_ERROR, payload: error });
    }
  };
};

export const getSurveyParticipants = (requestConfig) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_SURVEY_PARTICIPANTS,
        payload: requestConfig
      });
      const response = await SurveysService.getSurveyParticipants(
        requestConfig
      );
      dispatch({
        type: ActionTypes.GET_SURVEY_PARTICIPANTS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_SURVEY_PARTICIPANTS_ERROR,
        payload: error
      });
    }
  };
};

export const sendSurvey = (surveyId, participantList = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.SEND_SURVEY,
        payload: { surveyId, participantList }
      });
      const response = await SurveysService.sendSurvey(
        surveyId,
        participantList
      );
      dispatch({
        type: ActionTypes.SEND_SURVEY_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: ActionTypes.SEND_SURVEY_ERROR, payload: error });
    }
  };
};

export const publishSurvey = (surveyId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.PUBLISH_SURVEY,
        payload: { surveyId }
      });
      const response = await SurveysService.publishSurvey(surveyId);
      dispatch({
        type: ActionTypes.PUBLISH_SURVEY_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: ActionTypes.PUBLISH_SURVEY_ERROR, payload: error });
    }
  };
};

export const downloadSurveyResponses = (surveyId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.DOWNLOAD_SURVEY_RESPONSES
      });
      SurveysService.downloadSurveyResponses(surveyId);
    } catch (error) {
      dispatch({
        type: ActionTypes.DOWNLOAD_SURVEY_RESPONSES_ERROR,
        payload: error
      });
    }
  };
};
