/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';

const MedicalPages = {
  MedicalPlansList: React.lazy(() => import('./pages/MedicalPlansList')),
  MedicalPlanStepper: React.lazy(() => import('./pages/MedicalPlanStepper'))
};

const DentalPages = {
  DentalPlansList: React.lazy(() => import('./pages/DentalPlansList')),
  DentalPlanStepper: React.lazy(() => import('./pages/DentalPlanStepper'))
};

const VisionPages = {
  VisionPlansList: React.lazy(() => import('./pages/VisionPlansList')),
  VisionPlanStepper: React.lazy(() => import('./pages/VisionPlanStepper'))
};

const SpendingPages = {
  SpendingPlansList: React.lazy(() => import('./pages/SpendingPlansList')),
  HSAPlan: React.lazy(() =>
    import('./pages/SpendingPlan').then((_) => ({
      default: _.HSAPlan
    }))
  ),
  FSAPlan: React.lazy(() =>
    import('./pages/SpendingPlan').then((_) => ({
      default: _.FSAPlan
    }))
  ),
  LimitedFSAPlan: React.lazy(() =>
    import('./pages/SpendingPlan').then((_) => ({
      default: _.LimitedFSAPlan
    }))
  ),
  DCAPFSAPlan: React.lazy(() =>
    import('./pages/SpendingPlan').then((_) => ({
      default: _.DCAPFSAPlan
    }))
  ),
  HRAPlan: React.lazy(() =>
    import('./pages/SpendingPlan').then((_) => ({
      default: _.HRAPlan
    }))
  )
};

const CommuterPages = {
  CommuterPlansList: React.lazy(() => import('./pages/CommuterPlansList')),
  CommuterPlan: React.lazy(() => import('./pages/CommuterPlan'))
};

const LifePages = {
  LifeAndDisabilityPlansList: React.lazy(() =>
    import('./pages/LifeAndDisabilityPlansList')
  ),
  BasicLifePlan: React.lazy(() =>
    import('./pages/LifeAndDisabilityPlan').then((_) => ({
      default: _.BasicLifePlan
    }))
  ),
  VoluntaryLifePlan: React.lazy(() =>
    import('./pages/LifeAndDisabilityPlan').then((_) => ({
      default: _.VoluntaryLifePlan
    }))
  ),
  LTDPlan: React.lazy(() =>
    import('./pages/LifeAndDisabilityPlan').then((_) => ({
      default: _.LTDPlan
    }))
  ),
  STDPlan: React.lazy(() =>
    import('./pages/LifeAndDisabilityPlan').then((_) => ({
      default: _.STDPlan
    }))
  ),
  BasicADDPlan: React.lazy(() =>
    import('./pages/LifeAndDisabilityPlan').then((_) => ({
      default: _.BasicADDPlan
    }))
  ),
  VoluntaryADDPlan: React.lazy(() =>
    import('./pages/LifeAndDisabilityPlan').then((_) => ({
      default: _.VoluntaryADDPlan
    }))
  ),
  BasicLifeAndADDPlan: React.lazy(() =>
    import('./pages/LifeAndDisabilityPlan').then((_) => ({
      default: _.BasicLifeAndADDPlan
    }))
  )
};

const RetirementPages = {
  RetirementPlansList: React.lazy(() => import('./pages/RetirementPlansList')),
  RetirementPlan: React.lazy(() => import('./pages/RetirementPlan'))
};

const TeleHealthRxDeliveryPages = {
  TeleHealthRxDeliveryPlansList: React.lazy(() =>
    import('./pages/TeleHealthRxDeliveryPlansList')
  ),
  TelehealthPlan: React.lazy(() =>
    import('./pages/TeleHealthRxDeliveryPlan').then((_) => ({
      default: _.TeleHealthTeleHealthRxDeliveryPlan
    }))
  ),
  RxDeliveryPlan: React.lazy(() =>
    import('./pages/TeleHealthRxDeliveryPlan').then((_) => ({
      default: _.RxDeliveryTeleHealthRxDeliveryPlan
    }))
  ),
  RxCouponsPlan: React.lazy(() =>
    import('./pages/TeleHealthRxDeliveryPlan').then((_) => ({
      default: _.RxCouponsTeleHealthRxDeliveryPlan
    }))
  )
};

const WellbeingPages = {
  WellbeingPlansList: React.lazy(() => import('./pages/WellbeingPlansList')),
  MeditationPlan: React.lazy(() =>
    import('./pages/WellbeingPlan').then((_) => ({
      default: _.MeditationWelbeingPlan
    }))
  ),
  MentalHealthPlan: React.lazy(() =>
    import('./pages/WellbeingPlan').then((_) => ({
      default: _.MentalHealthWelbeingPlan
    }))
  ),
  WellnessReimbursementhPlan: React.lazy(() =>
    import('./pages/WellbeingPlan').then((_) => ({
      default: _.WellnessReimbursementhWelbeingPlan
    }))
  )
};

const WorkLifePages = {
  WorkLifePlansList: React.lazy(() => import('./pages/WorkLifePlansList')),
  BackupChildcarePlan: React.lazy(() =>
    import('./pages/WorkLifePlan').then((_) => ({
      default: _.BackupChildcarePlan
    }))
  ),
  BereavementWorkLifePlan: React.lazy(() =>
    import('./pages/WorkLifePlan').then((_) => ({
      default: _.BereavementWorkLifePlan
    }))
  ),
  FertilityWorkLifePlan: React.lazy(() =>
    import('./pages/WorkLifePlan').then((_) => ({
      default: _.FertilityWorkLifePlan
    }))
  ),
  ParentalLeavePlan: React.lazy(() =>
    import('./pages/WorkLifePlan').then((_) => ({
      default: _.ParentalLeavePlan
    }))
  ),
  FlexibleWorkingHoursPlan: React.lazy(() =>
    import('./pages/WorkLifePlan').then((_) => ({
      default: _.FlexibleWorkingHoursPlan
    }))
  ),
  PaidTimeOffPlan: React.lazy(() =>
    import('./pages/WorkLifePlan').then((_) => ({
      default: _.PaidTimeOffPlan
    }))
  ),
  HolidayPlan: React.lazy(() =>
    import('./pages/WorkLifePlan').then((_) => ({
      default: _.HolidayPlan
    }))
  ),
  SickPlan: React.lazy(() =>
    import('./pages/WorkLifePlan').then((_) => ({
      default: _.SickPlan
    }))
  ),
  ClubsPlan: React.lazy(() =>
    import('./pages/WorkLifePlan').then((_) => ({
      default: _.ClubsPlan
    }))
  )
};
const AdditionalPerkPages = {
  AdditionalPerkPlanList: React.lazy(() =>
    import('./pages/AdditionalPerkPlansList')
  ),
  AdditionalPerkPlan: React.lazy(() => import('./pages/AdditionalPerkPlan'))
};
const TaxAdvantagedAccountPages = {
  AdvantagedAccount: React.lazy(() =>
    import('./pages/TaxAdvantagedAccountPlans')
  )
};

export {
  MedicalPages,
  DentalPages,
  VisionPages,
  SpendingPages,
  CommuterPages,
  LifePages,
  RetirementPages,
  WellbeingPages,
  WorkLifePages,
  AdditionalPerkPages,
  TaxAdvantagedAccountPages,
  TeleHealthRxDeliveryPages
};
