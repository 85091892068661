import {
  GET_BEN_GUIDE_BY_ID_ERROR,
  GET_BEN_GUIDE_BY_ID_IN_PROGRESS,
  GET_BEN_GUIDE_BY_ID_SUCCESS,
  GET_BEN_GUIDE_PLANS_ERROR,
  GET_BEN_GUIDE_PLANS_IN_PROGRESS,
  GET_BEN_GUIDE_PLANS_SUCCESS,
  GET_BENGUIDE_BACKGROUND_SUCCESS,
  GET_BENGUIDE_LOGO_ERROR,
  GET_BENGUIDE_LOGO_IN_PROGRESS,
  GET_BENGUIDE_LOGO_SUCCESS,
  GET_BENGUIDES_PAGE,
  GET_BENGUIDES_PAGE_ERROR,
  PUBLISH_BENGUIDE_ERROR,
  PUBLISH_BENGUIDE_IN_PROGRESS,
  PUBLISH_BENGUIDE_SUCCESS,
  UPDATE_BEN_GUIDE,
  UPDATE_BEN_GUIDE_ERROR,
  UPDATE_BEN_GUIDE_IN_PROGRESS,
  UPDATE_BEN_GUIDE_SUCCESS,
  UPDATE_BEN_GUIDE_VALIDATIONS,
  UPLOAD_BENGUIDE_BACKGROUND_ERROR,
  UPLOAD_BENGUIDE_BACKGROUND_IN_PROGRESS,
  UPLOAD_BENGUIDE_BACKGROUND_SUCCESS,
  UPLOAD_BENGUIDE_LOGO_ERROR,
  UPLOAD_BENGUIDE_LOGO_IN_PROGRESS,
  UPLOAD_BENGUIDE_LOGO_SUCCESS,
  UPLOAD_OE_VIDEO_ERROR,
  UPLOAD_OE_VIDEO_IN_PROGRESS,
  UPLOAD_OE_VIDEO_PROGRESS_NOTIFICATION,
  UPLOAD_OE_VIDEO_SUCCESS,
  VALIDATE_BEN_GUIDE_NAME_SUCCESS,
  UPLOAD_BENGUIDE_FAMILY_IMAGE_IN_PROGRESS,
  UPLOAD_BENGUIDE_FAMILY_IMAGE_SUCCESS,
  UPLOAD_BENGUIDE_FAMILY_IMAGE_ERROR,
  GET_BENGUIDE_FAMILY_IMAGE_SUCCESS,
  UPLOAD_BENGUIDE_TIME_OFF_IMAGE_IN_PROGRESS,
  UPLOAD_BENGUIDE_TIME_OFF_IMAGE_SUCCESS,
  UPLOAD_BENGUIDE_TIME_OFF_IMAGE_ERROR,
  GET_BENGUIDE_TIME_OFF_IMAGE_SUCCESS,
  UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_IN_PROGRESS,
  UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_SUCCESS,
  UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_ERROR,
  GET_BENGUIDE_COMMUNITIES_IMAGE_SUCCESS,
  UPLOAD_BENGUIDE_PERK_IMAGE_IN_PROGRESS,
  UPLOAD_BENGUIDE_PERK_IMAGE_SUCCESS,
  UPLOAD_BENGUIDE_PERK_IMAGE_ERROR,
  GET_BENGUIDE_PERK_IMAGE_SUCCESS,
  GET_BENGUIDE_FAMILY_IMAGE_IN_PROGRESS,
  GET_BENGUIDE_TIME_OFF_IMAGE_IN_PROGRESS,
  GET_BENGUIDE_COMMUNITIES_IMAGE_IN_PROGRESS,
  GET_BENGUIDE_PERK_IMAGE_IN_PROGRESS,
  GET_BENGUIDE_FAMILY_IMAGE_ERROR,
  GET_BENGUIDE_TIME_OFF_IMAGE_ERROR,
  GET_BENGUIDE_COMMUNITIES_IMAGE_ERROR,
  GET_BENGUIDE_PERK_IMAGE_ERROR,
  UPLOAD_PERK_DOC_IN_PROGRESS,
  UPLOAD_PERK_DOC_SUCCESS,
  UPLOAD_PERK_DOC_ERROR,
  UPLOAD_RESOURCE_DOC_IN_PROGRESS,
  UPLOAD_RESOURCE_DOC_SUCCESS,
  UPLOAD_RESOURCE_DOC_ERROR,
  GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNT_IN_PROGRESS,
  GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNT,
  GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ERROR,
  PREVIEW_BENGUIDE_IN_PROGRESS,
  PREVIEW_BENGUIDE_ERROR,
  GET_OE_MEETING_USERS_IN_PROGRESS,
  GET_OE_MEETING_USERS,
  GET_OE_MEETING_USERS_ERROR,
  GET_OE_MEETING_IN_PROGRESS,
  GET_OE_MEETING,
  SAVE_OE_MEETING_IN_PROGRESS,
  SAVE_OE_MEETING,
  SAVE_OE_MEETING_ERROR,
  DELETE_OE_MEETING_IN_PROGRESS,
  DELETE_OE_MEETING,
  DELETE_OE_MEETING_ERROR,
  UPLOAD_OE_MEETING_RECORDING_IN_PROGRESS,
  UPLOAD_OE_MEETING_RECORDING_SUCCESS,
  UPLOAD_OE_MEETING_RECORDING_FAILED,
  UPLOAD_OE_MEETING_PROGRESS_NOTIFICATION,
  CLEAR_OE_MEETING,
  CREATE_OE_RECORDING_UPLOAD_CANCEL_SOURCE,
  OE_RECORDING_UPLOAD_CANCEL_SUCCESS,
  RESET_BEN_GUIDE_PLANS,
  DELETE_BENGUIDE_IN_PROGRESS,
  DELETE_BENGUIDE_COMPLETED,
  DELETE_BENGUIDE_FAILED,
  CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_IN_PROGRESS,
  CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_COMPLETED,
  CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_FAILED,
  CLONE_BEN_GUIDE_SUCCESS,
  CLONE_BEN_GUIDE_ERROR,
  CLONE_BEN_GUIDE_IN_PROGRESS
} from '../actionTypes/benguide.actionTypes';
import {
  NOT_STARTED,
  UPLOADING,
  UPLOAD_COMPLETE,
  UPLOAD_ERROR
} from './../constants';

export default (
  state = {
    benGuidesPageData: {
      content: undefined,
      metadata: undefined
    },
    benGuidesPageErrorResponse: undefined,
    benGuideData: {
      benGuideObj: null,
      loading: false,
      error: null,
      updateBenGuideInProgress: false
    },
    benGuideErrorObj: null,
    oeVideoUploadingStatus: NOT_STARTED,
    oeVideoProgress: 0,
    errorResponse: null,
    oeVideoFileReference: null,
    perkDocuments: [],
    perkDocUploadingStatus: NOT_STARTED,
    resourceDocuments: [],
    resourceDocUploadingStatus: NOT_STARTED,
    benGuidePlans: {
      loading: false,
      medicalAllPlans: [],
      availableMedicalPlans: [],
      addedMedicalPlans: [],
      medicalPlans: [],
      isProcessedMedicalPlans: false
    },
    benGuideLogo: {
      loading: false,
      error: null,
      logoReference: null,
      type: null
    },
    benGuideBackground: {
      loading: false,
      error: null,
      backgroundRef: null
    },
    publishBenGuide: {
      loading: false,
      error: null,
      published: false
    },
    benGuideName: {
      isValidName: true
    },
    benGuideFamilyImage: {
      loading: false,
      error: null,
      familyImageReference: null
    },
    benGuideTimeOffImage: {
      loading: false,
      error: null,
      timeOfImageReference: null
    },
    benGuideCommunitiesImage: {
      loading: false,
      error: null,
      communitiesImageReference: null
    },
    benGuidePerkImage: {
      loading: false,
      error: null,
      perksImageReference: null
    },
    availableTaxAdvantagedBenefitKinds: {
      loading: false,
      benefitKinds: [],
      error: null
    },
    benguidePreview: {
      loading: false,
      error: null
    },
    oeMeeingUsers: {
      loading: false,
      users: [],
      error: null
    },
    oeMeeting: {
      loading: false,
      oeMeeting: null,
      error: null
    },
    oeMeetingRecording: {
      status: NOT_STARTED,
      progress: 0,
      error: null,
      fileReference: null,
      requestSource: null
    },
    deleteBenguideStatus: {
      inProgress: false,
      error: null
    },
    checkBenguidePublishedVersionExists: {
      hasPublishedVersion: null,
      inProgress: false,
      error: null
    }
  },
  { type, payload }
) => {
  /* eslint-disable */
  switch (type) {
    case GET_BENGUIDES_PAGE:
      return {
        ...state,
        benGuidesPageData: payload
      };
    case GET_BENGUIDES_PAGE_ERROR:
      return {
        ...state,
        benGuidesPageErrorResponse: payload
      };
    case UPDATE_BEN_GUIDE:
      return {
        ...state,
        benGuideData: {
          ...state.benGuideData,
          benGuideObj: payload
        }
      };
    case CLONE_BEN_GUIDE_IN_PROGRESS:
      return {
        ...state,
        benGuideData: {
          loading: true,
          isProcessedPlans: false,
        }
      }; 

    case CLONE_BEN_GUIDE_SUCCESS:
      return {
        ...state,
        benGuideData: {
          loading: false,
          isProcessedPlans: true,
          benGuideObj: payload
        }
      }; 
    case CLONE_BEN_GUIDE_ERROR:
      return {
        ...state,
        benGuideData: {
          loading: false,
          isProcessedPlans: false,
          error: payload
        }
      }    
    case UPDATE_BEN_GUIDE_VALIDATIONS:
      return {
        ...state,
        benGuideErrorObj: payload
      };
    case UPLOAD_OE_VIDEO_IN_PROGRESS:
      return {
        ...state,
        oeVideoProgress: 0,
        oeVideoUploadingStatus: UPLOADING
      };
    case UPLOAD_OE_VIDEO_SUCCESS:
      return {
        ...state,
        oeVideoProgress: 0,
        oeVideoUploadingStatus: UPLOAD_COMPLETE,
        oeVideoFileReference: payload
      };
    case UPLOAD_OE_VIDEO_ERROR:
      return {
        ...state,
        oeVideoProgress: 0,
        oeVideoUploadingStatus: UPLOAD_ERROR,
        errorResponse: payload
      };
    case UPLOAD_OE_VIDEO_PROGRESS_NOTIFICATION:
      return {
        ...state,
        oeVideoProgress: payload
      };
    case GET_BEN_GUIDE_PLANS_IN_PROGRESS:
      return {
        ...state,
        benGuidePlans: {
          ...state.benGuidePlans,
          loading: true,
          isProcessedPlans: false
        }
      };
    case GET_BEN_GUIDE_PLANS_SUCCESS:
      return {
        ...state,
        benGuidePlans: {
          loading: false,
          isProcessedPlans: true,
          ...payload
        }
      };
    case GET_BEN_GUIDE_PLANS_ERROR:
      return {
        ...state,
        benGuidePlans: {
          ...state.benGuidePlans,
          loading: false,
          isProcessedPlans: false
        }
      };
    case RESET_BEN_GUIDE_PLANS:
      return {
        ...state,
        benGuidePlans: {
          loading: false,
          medicalAllPlans: [],
          availableMedicalPlans: [],
          addedMedicalPlans: [],
          medicalPlans: [],
          isProcessedMedicalPlans: false
        }
      };
    case GET_BEN_GUIDE_BY_ID_IN_PROGRESS:
      return {
        ...state,
        benGuideData: {
          ...state.benGuideData,
          benGuideObj: null,
          loading: true,
          error: null
        }
      };
    case GET_BEN_GUIDE_BY_ID_SUCCESS:
      return {
        ...state,
        benGuideData: {
          ...state.benGuideData,
          benGuideObj: payload,
          loading: false,
          error: null
        }
      };
    case GET_BEN_GUIDE_BY_ID_ERROR:
      return {
        ...state,
        benGuideData: {
          ...state.benGuideData,
          loading: false,
          error: payload.response.data
        }
      };
    case UPDATE_BEN_GUIDE_IN_PROGRESS:
      return {
        ...state,
        benGuideData: {
          ...state.benGuideData,
          benGuideObj: payload,
          error: null,
          updateBenGuideInProgress: true
        }
      };
    case UPDATE_BEN_GUIDE_SUCCESS:
      return {
        ...state,
        benGuideData: {
          ...state.benGuideData,
          benGuideObj: payload,
          error: null,
          updateBenGuideInProgress: false
        }
      };
    case UPDATE_BEN_GUIDE_ERROR:
      return {
        ...state,
        benGuideData: {
          ...state.benGuideData,
          loading: false,
          error: payload,
          updateBenGuideInProgress: false
        }
      };
    case UPLOAD_BENGUIDE_LOGO_IN_PROGRESS:
      return {
        ...state,
        benGuideLogo: {
          ...state.benGuideLogo,
          loading: true
        }
      };
    case UPLOAD_BENGUIDE_LOGO_SUCCESS:
      return {
        ...state,
        benGuideLogo: {
          loading: false,
          error: null,
          logoReference: payload.logoRef
        },
        benGuideData: {
          ...state.benGuideData,
          benGuideObj: payload.benGuide
        }
      };
    case GET_BENGUIDE_LOGO_SUCCESS:
      return {
        ...state,
        benGuideLogo: {
          loading: false,
          error: null,
          logoReference: payload
        }
      };
    case GET_BENGUIDE_BACKGROUND_SUCCESS:
      return {
        ...state,
        benGuideBackground: {
          loading: false,
          error: null,
          backgroundRef: payload
        }
      };
    case UPLOAD_BENGUIDE_LOGO_ERROR:
      return {
        ...state,
        benGuideLogo: {
          ...state.benGuideLogo,
          loading: false,
          error: payload
        }
      };
    case UPLOAD_BENGUIDE_BACKGROUND_IN_PROGRESS:
      return {
        ...state,
        benGuideBackground: {
          ...state.benGuideBackground,
          loading: true
        }
      };
    case UPLOAD_BENGUIDE_BACKGROUND_SUCCESS:
      return {
        ...state,
        benGuideBackground: {
          loading: false,
          error: null,
          backgroundRef: payload
        }
      };
    case UPLOAD_BENGUIDE_BACKGROUND_ERROR:
      return {
        ...state,
        benGuideBackground: {
          ...state.benGuideBackground,
          loading: false,
          error: payload
        }
      };
    case PUBLISH_BENGUIDE_IN_PROGRESS:
      return {
        ...state,
        publishBenGuide: {
          ...state.publishBenGuide,
          loading: true
        }
      };
    case PUBLISH_BENGUIDE_SUCCESS:
      return {
        ...state,
        publishBenGuide: {
          loading: false,
          error: null,
          published: true
        }
      };
    case PUBLISH_BENGUIDE_ERROR:
      return {
        ...state,
        publishBenGuide: {
          ...state.publishBenGuide,
          loading: false,
          error: payload
        }
      };
    case GET_BENGUIDE_LOGO_IN_PROGRESS:
      return {
        ...state,
        benGuideLogo: {
          loading: true,
          error: null,
          logoReference: null
        }
      };
    case GET_BENGUIDE_LOGO_ERROR:
      return {
        ...state,
        benGuideLogo: {
          loading: true,
          error: payload,
          logoReference: null
        }
      };
    case VALIDATE_BEN_GUIDE_NAME_SUCCESS:
      return {
        ...state,
        benGuideName: {
          isValidName: payload.isValidName
        }
      };
    case UPLOAD_BENGUIDE_FAMILY_IMAGE_IN_PROGRESS:
      return {
        ...state,
        benGuideFamilyImage: {
          ...state.benGuideFamilyImage,
          loading: true
        }
      };
    case UPLOAD_BENGUIDE_FAMILY_IMAGE_SUCCESS:
      return {
        ...state,
        benGuideFamilyImage: {
          loading: false,
          error: null,
          familyImageReference: payload
        }
      };
    case UPLOAD_BENGUIDE_FAMILY_IMAGE_ERROR:
      return {
        ...state,
        benGuideFamilyImage: {
          ...state.benGuideFamilyImage,
          loading: false,
          error: payload
        }
      };
    case GET_BENGUIDE_FAMILY_IMAGE_SUCCESS:
      return {
        ...state,
        benGuideFamilyImage: {
          loading: false,
          error: null,
          familyImageReference: payload
        }
      };
    case UPLOAD_BENGUIDE_TIME_OFF_IMAGE_IN_PROGRESS:
      return {
        ...state,
        benGuideTimeOffImage: {
          ...state.benGuideTimeOffImage,
          loading: true
        }
      };
    case UPLOAD_BENGUIDE_TIME_OFF_IMAGE_SUCCESS:
      return {
        ...state,
        benGuideTimeOffImage: {
          loading: false,
          error: null,
          timeOfImageReference: payload
        }
      };
    case UPLOAD_BENGUIDE_TIME_OFF_IMAGE_ERROR:
      return {
        ...state,
        benGuideTimeOffImage: {
          ...state.benGuideTimeOffImage,
          loading: false,
          error: payload
        }
      };
    case GET_BENGUIDE_TIME_OFF_IMAGE_SUCCESS:
      return {
        ...state,
        benGuideTimeOffImage: {
          loading: false,
          error: null,
          timeOfImageReference: payload
        }
      };
    case UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_IN_PROGRESS:
      return {
        ...state,
        benGuideCommunitiesImage: {
          ...state.benGuideCommunitiesImage,
          loading: true
        }
      };
    case UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_SUCCESS:
      return {
        ...state,
        benGuideCommunitiesImage: {
          loading: false,
          error: null,
          communitiesImageReference: payload
        }
      };
    case UPLOAD_BENGUIDE_COMMUNITIES_IMAGE_ERROR:
      return {
        ...state,
        benGuideCommunitiesImage: {
          ...state.benGuideCommunitiesImage,
          loading: false,
          error: payload
        }
      };
    case GET_BENGUIDE_COMMUNITIES_IMAGE_SUCCESS:
      return {
        ...state,
        benGuideCommunitiesImage: {
          loading: false,
          error: null,
          communitiesImageReference: payload
        }
      };
    case UPLOAD_BENGUIDE_PERK_IMAGE_IN_PROGRESS:
      return {
        ...state,
        benGuidePerkImage: {
          ...state.benGuidePerkImage,
          loading: true
        }
      };
    case UPLOAD_BENGUIDE_PERK_IMAGE_SUCCESS:
      return {
        ...state,
        benGuidePerkImage: {
          loading: false,
          error: null,
          perksImageReference: payload
        }
      };
    case UPLOAD_BENGUIDE_PERK_IMAGE_ERROR:
      return {
        ...state,
        benGuidePerkImage: {
          ...state.benGuidePerkImage,
          loading: false,
          error: payload
        }
      };
    case GET_BENGUIDE_PERK_IMAGE_SUCCESS:
      return {
        ...state,
        benGuidePerkImage: {
          loading: false,
          error: null,
          perksImageReference: payload
        }
      };
    case GET_BENGUIDE_FAMILY_IMAGE_IN_PROGRESS:
      return {
        ...state,
        benGuideFamilyImage: {
          loading: true,
          error: null,
          familyImageReference: null
        }
      };
    case GET_BENGUIDE_FAMILY_IMAGE_ERROR:
      return {
        ...state,
        benGuideFamilyImage: {
          loading: true,
          error: payload,
          familyImageReference: null
        }
      };
    case GET_BENGUIDE_TIME_OFF_IMAGE_IN_PROGRESS:
      return {
        ...state,
        benGuideTimeOffImage: {
          loading: true,
          error: null,
          timeOfImageReference: null
        }
      };
    case GET_BENGUIDE_TIME_OFF_IMAGE_ERROR:
      return {
        ...state,
        benGuideTimeOffImage: {
          loading: true,
          error: payload,
          timeOfImageReference: null
        }
      };
    case GET_BENGUIDE_COMMUNITIES_IMAGE_IN_PROGRESS:
      return {
        ...state,
        benGuideCommunitiesImage: {
          loading: true,
          error: null,
          communitiesImageReference: null
        }
      };
    case GET_BENGUIDE_COMMUNITIES_IMAGE_ERROR:
      return {
        ...state,
        benGuideCommunitiesImage: {
          loading: true,
          error: payload,
          communitiesImageReference: null
        }
      };
    case GET_BENGUIDE_PERK_IMAGE_IN_PROGRESS:
      return {
        ...state,
        benGuidePerkImage: {
          loading: true,
          error: null,
          perksImageReference: null
        }
      };
    case GET_BENGUIDE_PERK_IMAGE_ERROR:
      return {
        ...state,
        benGuidePerkImage: {
          loading: true,
          error: payload,
          perksImageReference: null
        }
      };
    case UPLOAD_PERK_DOC_IN_PROGRESS:
      return {
        ...state,
        perkDocUploadingStatus: UPLOADING
      };
    case UPLOAD_PERK_DOC_SUCCESS:
      return {
        ...state,
        perkDocUploadingStatus: UPLOAD_COMPLETE,
        perkDocuments: payload
      };
    case UPLOAD_PERK_DOC_ERROR:
      return {
        ...state,
        perkDocUploadingStatus: UPLOAD_ERROR,
        errorResponse: payload
      };
    case UPLOAD_RESOURCE_DOC_IN_PROGRESS:
      return {
        ...state,
        resourceDocUploadingStatus: UPLOADING
      };
    case UPLOAD_RESOURCE_DOC_SUCCESS:
      return {
        ...state,
        resourceDocUploadingStatus: UPLOAD_COMPLETE,
        resourceDocuments: payload
      };
    case UPLOAD_RESOURCE_DOC_ERROR:
      return {
        ...state,
        resourceDocUploadingStatus: UPLOAD_ERROR,
        errorResponse: payload
      };
    case GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNT_IN_PROGRESS:
      return {
        ...state,
        availableTaxAdvantagedBenefitKinds: {
          loading: true,
          benefitKinds: [],
          error: null
        }
      };
    case GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNT:
      return {
        ...state,
        availableTaxAdvantagedBenefitKinds: {
          loading: false,
          benefitKinds: payload,
          error: null
        }
      };
    case GET_AVAILABLE_BENEFIT_KIND_TAX_ADVANTAGED_ERROR:
      return {
        ...state,
        availableTaxAdvantagedBenefitKinds: {
          loading: false,
          benefitKinds: payload,
          error: payload
        }
      };
    case PREVIEW_BENGUIDE_IN_PROGRESS:
      return {
        ...state,
        benguidePreview: {
          loading: true,
          error: null
        }
      };
    case PREVIEW_BENGUIDE_ERROR:
      return {
        ...state,
        benguidePreview: {
          loading: false,
          error: payload
        }
      };
    case GET_OE_MEETING_USERS_IN_PROGRESS:
      return {
        ...state,
        oeMeeingUsers: {
          loading: true,
          users: [],
          error: null
        }
      };
    case GET_OE_MEETING_USERS:
      return {
        ...state,
        oeMeeingUsers: {
          loading: false,
          users: payload.users,
          error: null
        }
      };
    case GET_OE_MEETING_USERS_ERROR:
      return {
        ...state,
        oeMeeingUsers: {
          loading: false,
          users: [],
          error: payload
        }
      };
    case GET_OE_MEETING_IN_PROGRESS:
      return {
        ...state,
        oeMeeting: {
          ...state.oeMeeting,
          loading: true,
          oeMeeting: null,
          error: null
        }
      };
    case GET_OE_MEETING:
      return {
        ...state,
        oeMeeting: {
          ...state.oeMeeting,
          loading: false,
          oeMeeting: payload,
          error: null
        }
      };
    case GET_OE_MEETING_USERS_ERROR:
      return {
        ...state,
        oeMeeting: {
          loading: true,
          error: payload
        }
      };
    case SAVE_OE_MEETING_IN_PROGRESS:
      return {
        ...state,
        oeMeeting: {
          ...state.oeMeeting,
          loading: true,
          saveInprogress: true
        }
      };
    case SAVE_OE_MEETING:
      return {
        ...state,
        oeMeeting: {
          loading: false,
          saveInprogress: false,
          oeMeeting: payload,
          error: null
        }
      };
    case SAVE_OE_MEETING_ERROR:
      return {
        ...state,
        oeMeeting: {
          loading: false,
          saveInprogress: false,
          error: payload
        }
      };
    case DELETE_OE_MEETING_IN_PROGRESS:
      return {
        ...state,
        oeMeeting: {
          loading: true,
          deleteLoading: true,
          deleteInprogress: true
        }
      };
    case DELETE_OE_MEETING:
      return {
        ...state,
        oeMeeting: {
          loading: false,
          deleteLoading: false,
          error: null,
          deleteInprogress: false
        }
      };
    case DELETE_OE_MEETING_ERROR:
      return {
        ...state,
        oeMeeting: {
          loading: false,
          deleteLoading: false,
          error: payload,
          deleteInprogress: false
        }
      };
    case UPLOAD_OE_MEETING_RECORDING_IN_PROGRESS:
      return {
        ...state,
        oeMeetingRecording: {
          ...state.oeMeetingRecording,
          progress: 0,
          status: UPLOADING
        }
      };
    case UPLOAD_OE_MEETING_RECORDING_SUCCESS:
      return {
        ...state,
        oeMeetingRecording: {
          ...state.oeMeetingRecording,
          progress: 0,
          status: UPLOAD_COMPLETE,
          fileReference: payload,
          requestSource: null
        }
      };
    case UPLOAD_OE_MEETING_RECORDING_FAILED:
      return {
        ...state,
        oeMeetingRecording: {
          ...state.oeMeetingRecording,
          progress: 0,
          status: UPLOAD_ERROR,
          error: payload,
          requestSource: null
        }
      };
    case UPLOAD_OE_MEETING_PROGRESS_NOTIFICATION:
      return {
        ...state,
        oeMeetingRecording: {
          ...state.oeMeetingRecording,
          progress: payload
        }
      };
    case CREATE_OE_RECORDING_UPLOAD_CANCEL_SOURCE:
      return {
        ...state,
        oeMeetingRecording: {
          ...state.oeMeetingRecording,
          requestSource: payload
        }
      };
    case OE_RECORDING_UPLOAD_CANCEL_SUCCESS:
      return {
        ...state,
        oeMeetingRecording: {
          ...state.oeMeetingRecording,
          requestSource: null
        }
      };
    case CLEAR_OE_MEETING:
      return {
        ...state,
        oeMeeting: {
          loading: false,
          oeMeeting: null,
          error: null
        }
      };
    case DELETE_BENGUIDE_IN_PROGRESS:
      return {
        ...state,
        deleteBenguideStatus: {
          ...state.deleteBenguideStatus,
          inProgress: true,
          error: null
        }
      };
    case DELETE_BENGUIDE_COMPLETED:
      return {
        ...state,
        deleteBenguideStatus: {
          ...state.deleteBenguideStatus,
          inProgress: false,
          error: null
        }
      };
    case DELETE_BENGUIDE_FAILED:
      return {
        ...state,
        deleteBenguideStatus: {
          ...state.deleteBenguideStatus,
          inProgress: false,
          error: payload
        }
      };
      case CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_IN_PROGRESS:
        return {
          ...state,
          checkBenguidePublishedVersionExists: {
            ...state.checkBenguidePublishedVersionExists,
            hasPublishedVersion: null,
            inProgress: true,
            error: null
          }
        };
      case CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_COMPLETED:
        return {
          ...state,
          checkBenguidePublishedVersionExists: {
            ...state.checkBenguidePublishedVersionExists,
            hasPublishedVersion: payload,
            inProgress: false,
            error: null
          }
        };
      case CHECK_BENGUIDE_HAS_PUBLISHED_VERSION_FAILED:
        return {
          ...state,
          checkBenguidePublishedVersionExists: {
            hasPublishedVersion: null,
            inProgress: false,
            error: payload
          }
        };
    default:
      return {
        ...state
      };
  }
  /* eslint-enable */
};
