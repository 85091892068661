/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

export const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT_IN_PROGRESS = 'LOGOUT_IN_PROGRESS';
export const LOGOUT_COMPLETED = 'LOGOUT_COMPLETED';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const PING_IN_PROGRESS = 'PING_IN_PROGRESS';
export const PING_COMPLETED = 'PING_COMPLETED';
export const PING_FAILED = 'PING_FAILED';

export const REQUEST_PASSWORD_RESET_IN_PROGRESS =
  'REQUEST_PASSWORD_RESET_IN_PROGRESS';
export const REQUEST_PASSWORD_RESET_COMPLETED =
  'REQUEST_PASSWORD_RESET_COMPLETED';
export const REQUEST_PASSWORD_RESET_FAILED = 'REQUEST_PASSWORD_RESET_FAILED';

export const PASSWORD_RESET_TOKEN_VALIDATE_IN_PROGRESS =
  'PASSWORD_RESET_TOKEN_VALIDATE_IN_PROGRESS';
export const PASSWORD_RESET_TOKEN_VALIDATE_COMPLETED =
  'PASSWORD_RESET_TOKEN_VALIDATE_COMPLETED';
export const PASSWORD_RESET_TOKEN_VALIDATE_FAILED =
  'PASSWORD_RESET_TOKEN_VALIDATE_FAILED';

export const RESET_PASSWORD_IN_PROGRESS = 'RESET_PASSWORD_IN_PROGRESS';
export const RESET_PASSWORD_COMPLETED = 'RESET_PASSWORD_COMPLETED';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
