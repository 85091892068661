/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Button,
  Row,
  Col,
  Input,
  CustomInput,
  Table,
  FormGroup,
  FormFeedback
} from 'components/Atoms';

const BenefitGroupRow = ({
  lumityBenefitGroups = [],
  groupName,
  groupConfig,
  onChange,
  error
}) => (
  <tr>
    <td className="benefit-mapping-label">{groupName}</td>
    <td className="benefit-group">
      <FormGroup>
        <Input
          type="select"
          name="lumityBenefitGroup"
          onChange={onChange}
          value={groupConfig.lumityBenefitGroup}
          invalid={error}
          disabled={groupConfig.ignore}
        >
          <option key="select" value="">
            Select Option
          </option>
          {lumityBenefitGroups.map((lumityGroup) => {
            return (
              <option key={lumityGroup} value={lumityGroup}>
                {lumityGroup}
              </option>
            );
          })}
        </Input>
        {error && <FormFeedback>{error}</FormFeedback>}
      </FormGroup>
    </td>
    <td className="benefit-ignore">
      <FormGroup>
        <CustomInput
          type="checkbox"
          id={`${groupName}.ignore`}
          inline
          name="ignore"
          checked={groupConfig.ignore}
          onChange={onChange}
        />
      </FormGroup>
    </td>
  </tr>
);

export class BenefitGroupConfiguration extends Component {
  static propTypes = {
    bambooHrBenefitGroups: PropTypes.arrayOf(
      PropTypes.shape({
        bambooHrBenefitGroupId: PropTypes.string,
        bambooHrBenefitGroupName: PropTypes.string
      })
    ),
    lumityBenefitGroups: PropTypes.arrayOf(PropTypes.string),
    // Map with keys as a bambooHrBenefitGroupId, and values as object with lumityBenefitGroup and ignore fields
    benefitGroupConfiguration: PropTypes.objectOf(
      PropTypes.shape({
        lumityBenefitGroup: PropTypes.string,
        ignore: PropTypes.bool
      })
    ),
    // Expect (benefitGroupConfiguration) => any
    onPrevious: PropTypes.func,
    // Expect (benefitGroupConfiguration) => any
    onNext: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      benefitGroupConfiguration: props.benefitGroupConfiguration || {},
      errors: {}
    };
  }

  onChangeConfig = (groupId, event) => {
    const { name, type, value, checked } = event.target;
    const { benefitGroupConfiguration, errors } = this.state;

    // Update the configs
    let newConfigs = { ...benefitGroupConfiguration };
    newConfigs[groupId] = newConfigs[groupId] || {};
    if (type === 'checkbox') {
      newConfigs = { ...newConfigs, [groupId]: { [name]: checked } };
    } else {
      newConfigs = {
        ...newConfigs,
        [groupId]: { [name]: value, ['ignore']: false }
      };
    }

    // Clear any errors for the group
    const newErrors = { ...errors };
    delete newErrors[groupId];

    this.setState({
      benefitGroupConfiguration: newConfigs,
      errors: newErrors
    });
  };

  onPrevious = () => {
    const { benefitGroupConfiguration } = this.state;
    const { onPrevious } = this.props;
    if (this.validateFields()){
      onPrevious(benefitGroupConfiguration);
    }
  };

  onNext = () => {
    const { benefitGroupConfiguration } = this.state;
    const { onNext } = this.props;
    if (this.validateFields()) {
      onNext(benefitGroupConfiguration);
    }
  };

  // Validate each row to see that either an option is selected or "Ignore" is checked.
  // Return boolean for whether all fields were valid and update error object in state.
  validateFields = () => {
    const { bambooHrBenefitGroups = [] } = this.props;
    const { benefitGroupConfiguration } = this.state;

    const errors = {};
    bambooHrBenefitGroups.forEach(({ bambooHrBenefitGroupId: groupId }) => {
      const { lumityBenefitGroup, ignore } =
        benefitGroupConfiguration[groupId] || {};
      // Input for the benefit group is invalid if no Group selected and ignore not selected
      if (!lumityBenefitGroup && !ignore) {
        errors[groupId] =
          'Please select a Lumity Benefit Group or mark as ignored.';
      }
    });
    this.setState({ errors });

    return _.isEmpty(errors);
  };

  render() {
    const {
      selectedVendor,
      bambooHrBenefitGroups = [],
      lumityBenefitGroups
    } = this.props;
    const { benefitGroupConfiguration, errors } = this.state;
    return (
      <div className="step-container mapping-container">
        <div className="body">
          <p className="subtitle">{selectedVendor.name}</p>
          <Table borderless className="mapping-table">
            <thead>
              <tr>
                <th>BambooHr Benefit Group</th>
                <th>Lumity Benefit Group</th>
                <th>Ignore</th>
              </tr>
            </thead>
            <tbody>
              {bambooHrBenefitGroups.map(
                ({
                  bambooHrBenefitGroupId: groupId,
                  bambooHrBenefitGroupName: groupName
                }) => {
                  return (
                    <BenefitGroupRow
                      lumityBenefitGroups={lumityBenefitGroups}
                      groupName={groupName}
                      groupConfig={benefitGroupConfiguration[groupId] || {}}
                      onChange={(e) => {
                        this.onChangeConfig(groupId, e);
                      }}
                      error={errors[groupId]}
                    />
                  );
                }
              )}
            </tbody>
          </Table>
        </div>
        <Row className="action-btns">
          <Col>
            <Button
              className="form-element"
              outline
              color="primary"
              size="lg"
              onClick={this.onPrevious}
              disabled={!_.isEmpty(errors)}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              className="form-element"
              color="primary"
              size="lg"
              onClick={this.onNext}
              disabled={!_.isEmpty(errors)}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
