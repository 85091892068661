/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import {
  GET_EMPLOYERS_BILLING_PAGE,
  GET_EMPLOYERS_BILLING_PAGE_ERROR,
  FILE_TYPE_LIST,
  FILE_TYPE_LIST_ERROR,
  UPLOAD_BILLING_FILE_IN_PROGRESS,
  UPLOAD_BILLING_FILE_SUCCESS,
  UPLOAD_BILLING_FILE_ERROR,
  UPLOAD_BILLING_FILE_PROGRESS_NOTIFICATION,
  SAVE_DETAIL_BILLING_SUCCESS,
  SAVE_DETAIL_BILLING_ERROR,
  SAVE_DETAIL_BILLING_IN_PROGRESS,
  BILLING_HISTORY_SUCCESSS,
  BILLING_HISTORY_ERROR,
  UPLOAD_CARRIER_FILE_IN_PROGRESS,
  UPLOAD_CARRIER_FILE_PROGRESS_NOTIFICATION,
  UPLOAD_CARRIER_FILE_SUCCESS,
  UPLOAD_CARRIER_FILE_ERROR,
  DELETE_BILLING_FILE_SUCCESS,
  DELETE_BILLING_FILE_ERROR,
  DELETE_BILLING_FILE_IN_PROGRESS,
  SAVE_AND_PUBLISH_BILLING_SUCCESS,
  SAVE_AND_PUBLISH_BILLING_ERROR,
  SAVE_AND_PUBLISH_BILLING_IN_PROGRESS,
  GET_BILLING_PREVIEW_DATA_ERROR,
  GET_BILLING_PREVIEW_DATA_IN_PROGRESS,
  GET_BILLING_PREVIEW_DATA_SUCCESS,
  PUBLISH_FROM_GRID_SUCCESS,
  PUBLISH_FROM_GRID_ERROR,
  PUBLISH_FROM_GRID_IN_PROGRESS,
  DOWNLOAD_BILLING_FILE_SUCCESS,
  DOWNLOAD_BILLING_FILE_ERROR,
  DOWNLOAD_BILLING_FILE_IN_PROGRESS,
  DOWNLOAD_HISTORY_BILLING_FILE_IN_PROGRESS,
  DOWNLOAD_HISTORY_BILLING_FILE_SUCCESS,
  DOWNLOAD_HISTORY_BILLING_FILE_ERROR,
  FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES,
  CREATE_BILLING_EMPLOYER_CONFIGURATION_MODULES,
  CREATE_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  UPDATE_BILLING_EMPLOYER_CONFIGURATION_MODULES,
  UPDATE_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR
} from '../actions/action.types';
import { DETAIL_FILE_TYPE, SUMMARY_FILE_TYPE, CARRIER } from '../constants';

export default (
  state = {
    modifiedContent: {
      employersBillingPageData: null,
      fileNames: []
    },
    billingPreview: {
      uploadingStatus: 'NOT_STARTED',
      filePreviewResult: null,
      progress: 0,
      errorResponse: null
    },
    billingSave: {
      savingStatus: 'NOT_STARTED',
      errorResponse: null,
      savedBillingRecord: null
    },
    billingSaveAndPublish: {
      savingStatus: 'NOT_STARTED',
      errorResponse: null,
      savedBillingRecord: null
    },
    carrierFilesStatus: {
      uploadingStatus: 'NOT_STARTED',
      filePreviewResult: null,
      progress: 0,
      errorResponse: null
    },
    employersBillingPageErrorResponse: null,
    fileTypeList: null,
    fileTypeListErrorResponse: null,
    fileHistoryData: null,
    fileHistoryDataErrorResponse: null,
    previewData: null,
    previewDataError: null,
    fromGridData: {
      savingStatus: 'NOT_STARTED',
      errorResponse: null,
      savedBillingRecord: null
    },
    downloadBillSuccess: null,
    downloadBillError: null,
    downloadBillName: null,
    downloadHistoryBillSuccess: null,
    downloadHistoryBillName: null,
    downloadHistoryBillError: null,
    configStatus: { id: null, value: 'false', lastUpdatedTs: null }
  },
  { type, payload }
) => {
  /* eslint-disable */
  switch (type) {
    case GET_EMPLOYERS_BILLING_PAGE:
      const modifiedPayload = filteremployerData(payload);
      return {
        ...state,
        employersBillingPageData: modifiedPayload
      };
    case GET_EMPLOYERS_BILLING_PAGE_ERROR:
      return {
        ...state,
        employersBillingPageErrorResponse: payload
      };
    case FILE_TYPE_LIST:
      return {
        ...state,
        fileTypeList: payload
      };
    case FILE_TYPE_LIST_ERROR:
      return {
        ...state,
        fileTypeListErrorResponse: payload
      };
    case UPLOAD_BILLING_FILE_IN_PROGRESS:
      return {
        ...state,
        billingPreview: {
          uploadingStatus: 'UPLOADING',
          filePreviewResult: null,
          progress: 0,
          errorResponse: null
        }
      };
    case UPLOAD_BILLING_FILE_SUCCESS:
      return {
        ...state,
        billingPreview: {
          uploadingStatus: 'UPLOAD_COMPLETE',
          filePreviewResult: payload,
          progress: 0,
          errorResponse: null
        }
      };
    case UPLOAD_BILLING_FILE_ERROR:
      return {
        ...state,
        billingPreview: {
          uploadingStatus: 'UPLOAD_ERROR',
          filePreviewResult: null,
          progress: 0,
          errorResponse: payload
        }
      };
    case UPLOAD_BILLING_FILE_PROGRESS_NOTIFICATION:
      return {
        ...state,
        billingPreview: {
          uploadingStatus: 'NOT_STARTED',
          filePreviewResult: null,
          progress: payload,
          errorResponse: null
        }
      };
    case SAVE_DETAIL_BILLING_IN_PROGRESS:
      return {
        ...state,
        billingSave: {
          savingStatus: 'SAVING',
          errorResponse: null,
          savedBillingRecord: null
        }
      };
    case SAVE_DETAIL_BILLING_SUCCESS:
      return {
        ...state,
        billingSave: {
          savingStatus: 'SAVE_COMPLETED',
          errorResponse: null,
          savedBillingRecord: payload
        }
      };
    case SAVE_DETAIL_BILLING_ERROR:
      return {
        ...state,
        billingSave: {
          savingStatus: 'SAVE_ERROR',
          errorResponse: payload,
          savedBillingRecord: null
        }
      };
    case BILLING_HISTORY_SUCCESSS:
      return {
        ...state,
        fileHistoryData: payload
      };
    case BILLING_HISTORY_ERROR:
      return {
        ...state,
        fileHistoryDataErrorResponse: payload
      };
    case UPLOAD_CARRIER_FILE_IN_PROGRESS:
      return {
        ...state,
        carrierFilesStatus: {
          uploadingStatus: 'UPLOADING',
          progress: 100,
          errorResponse: null
        }
      };
    case UPLOAD_CARRIER_FILE_PROGRESS_NOTIFICATION:
      return {
        ...state,
        carrierFilesStatus: {
          uploadingStatus: 'NOT_STARTED',
          progress: payload,
          errorResponse: null
        }
      };
    case UPLOAD_CARRIER_FILE_SUCCESS:
      return {
        ...state,
        carrierFilesStatus: {
          uploadingStatus: 'UPLOAD_COMPLETE',
          progress: 0,
          errorResponse: null
        }
      };
    case UPLOAD_CARRIER_FILE_ERROR:
      return {
        ...state,
        carrierFilesStatusbillingPreview: {
          uploadingStatus: 'UPLOAD_ERROR',
          progress: 0,
          errorResponse: payload
        }
      };
    case DELETE_BILLING_FILE_SUCCESS:
      return {
        ...state,
        deleteBillingFilesStatus: payload.id
      };
    case DELETE_BILLING_FILE_IN_PROGRESS:
      return {
        ...state,
        deleteBillingFilesStatusError: null
      };
    case DELETE_BILLING_FILE_ERROR:
      return {
        ...state,
        deleteBillingFilesStatusError: payload
      };
    case SAVE_AND_PUBLISH_BILLING_IN_PROGRESS:
      return {
        ...state,
        billingSaveAndPublish: {
          savingStatus: 'SAVING',
          errorResponse: null,
          savedBillingRecord: null
        }
      };
    case SAVE_AND_PUBLISH_BILLING_SUCCESS:
      return {
        ...state,
        billingSaveAndPublish: {
          savingStatus: 'SAVE_COMPLETED',
          errorResponse: null,
          savedBillingRecord: payload
        }
      };
    case SAVE_AND_PUBLISH_BILLING_ERROR:
      return {
        ...state,
        billingSaveAndPublish: {
          savingStatus: 'SAVE_ERROR',
          errorResponse: payload,
          savedBillingRecord: null
        }
      };
    case GET_BILLING_PREVIEW_DATA_IN_PROGRESS:
      return {
        ...state,
        previewData: null
      };
    case GET_BILLING_PREVIEW_DATA_SUCCESS:
      return {
        ...state,
        previewData: payload
      };
    case GET_BILLING_PREVIEW_DATA_ERROR:
      return {
        ...state,
        previewDataError: payload
      };
    case PUBLISH_FROM_GRID_SUCCESS:
      return {
        ...state,
        fromGridData: {
          savingStatus: 'SAVE_COMPLETED',
          errorResponse: null,
          savedBillingRecord: payload
        }
      };
    case PUBLISH_FROM_GRID_ERROR:
      return {
        ...state,
        fromGridData: {
          savingStatus: 'SAVE_ERROR',
          errorResponse: payload,
          savedBillingRecord: null
        }      
      };
    case PUBLISH_FROM_GRID_IN_PROGRESS:
      return {
        ...state,
        fromGridData: {
          savingStatus: 'SAVING',
          errorResponse: null,
          savedBillingRecord: null
        }
      };
    case DOWNLOAD_BILLING_FILE_SUCCESS:
      return {
        ...state,
        downloadBillSuccess: payload.file,
        downloadBillName: payload.fileName
      };
    case DOWNLOAD_BILLING_FILE_IN_PROGRESS:
      return {
        ...state,
        downloadBillSuccess: payload.file,
        downloadBillName: payload.fileName
      };
    case DOWNLOAD_BILLING_FILE_ERROR:
      return {
        ...state,
        downloadBillError: payload
      };
    case DOWNLOAD_HISTORY_BILLING_FILE_SUCCESS:
      return {
        ...state,
        downloadHistoryBillSuccess: payload.file,
        downloadHistoryBillName: payload.fileName
      };
    case DOWNLOAD_HISTORY_BILLING_FILE_IN_PROGRESS:
      return {
        ...state,
        downloadHistoryBillSuccess: payload.file,
        downloadHistoryBillName: payload.fileName
      };
    case DOWNLOAD_HISTORY_BILLING_FILE_ERROR:
      return {
        ...state,
        downloadHistoryBillError: payload
      };
      case FIND_BILLING_EMPLOYER_CONFIGURATION_MODULES:
        return {
          ...state,
          configStatus: payload
        };
      case CREATE_BILLING_EMPLOYER_CONFIGURATION_MODULES:
        return {
          ...state,
          // Re-assiging the state value to trigger state change at component level
          // since it's always 204 from the backend
          employerConfigCreate: Object.assign(payload)
        };
      case CREATE_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR:
        return {
          ...state,
          employerConfigCreateError: payload
        };
      case UPDATE_BILLING_EMPLOYER_CONFIGURATION_MODULES:
        return {
          ...state,
          // Re-assiging the state value to trigger state change at component level
          // since it's always 204 from the backend
          employerConfigUpdate: Object.assign(payload)
        };
      case UPDATE_BILLING_EMPLOYER_CONFIGURATION_MODULES_ERROR:
        return {
          ...state,
          employerConfigUpdateError: payload
        };  
    default:
      return {
        ...state
      };
  }
  /* eslint-enable */
  function filteremployerData(payload) {
    let modContet = [];
    for (let i = 0; i < payload.content.length; i++) {
      let carrierFileStatus = 'No';
      let carrierFileName = null;
      let modFiles = [];
      for (let x = 0; x < payload.content[i].files.length; x++) {
        if (
          payload.content[i].files[x].type === DETAIL_FILE_TYPE ||
          payload.content[i].files[x].type === SUMMARY_FILE_TYPE
        ) {
          modFiles[x] = payload.content[i].files[x];
        } else if (payload.content[i].files[x].type === CARRIER) {
          carrierFileStatus = 'Yes';
          carrierFileName = payload.content[i].files[x].fileName;
        }
      }
      modContet.push({
        id: payload.content[i].id,
        employerId: payload.content[i].employerId,
        files: modFiles,
        status: payload.content[i].status,
        billingFileType: payload.content[i].billingFileType,
        billingMonth: payload.content[i].billingMonth,
        year: payload.content[i].year,
        carrierFileStatus: carrierFileStatus,
        carrierFileName: carrierFileName
      });
    }
    return {
      content: modContet,
      metadata: payload.metadata
    };
  }
};
