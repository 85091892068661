/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React, { Component } from 'react';
import { Row, Col, Label } from 'components/Atoms';
import PageActionButton from 'components/Buttons';
import NumberFormat from 'react-number-format';
import {
  BENEFIT_KIND_MEDICAL,
  BENEFIT_KIND_DENTAL,
  BENEFIT_KIND_VISION
} from '../../../../../constants/benefitConstants';

class MDVWeightingsModal extends Component {
  constructor(props) {
    super(props);

    const { benefitType, weights } = this.props;
    this.state = {
      saveAndContinue: false,
      formData: weights || generateEmptyForm(benefitType),
      validations: generateValidations(benefitType),
      displaySumErrorMessage: false,
      displayNotNullMessage: false
    };
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));
  };

  save = async () => {
    const { formData = {}, validations = [] } = this.state;
    const { totalWeighting, updateWeights, planKey, toggle } = this.props;
    let sum = 0;
    let isDataInValid = false;
    await Object.keys(formData).forEach((i) => {
      sum = sum + Number(formData[i]);
      if (formData[i] === '') {
        validations[i] = false;
        this.setState({
          validations,
          displayNotNullMessage: true
        });
        isDataInValid = true;
      } else {
        validations[i] = true;
        this.setState({
          validations,
          displayNotNullMessage: false
        });
      }
    });
    if (sum !== totalWeighting) {
      this.setState({
        displaySumErrorMessage: true
      });
      isDataInValid = true;
    }
    if (sum === totalWeighting) {
      this.setState({
        displaySumErrorMessage: false
      });
      isDataInValid = false;
    }
    if (!isDataInValid) {
      updateWeights(formData, planKey);
      toggle();
    }
  };

  displaySumErrorMessage = () => {
    const totalWeighting = this.props.totalWeighting;

    return (
      <div className="error-msg-adjust-weightings">
        Plan weightings must be equal to {totalWeighting}.
      </div>
    );
  };

  displayValidationMessage = () => {
    return (
      <div className="error-msg-adjust-weightings">
        Please fill in all the fields.
      </div>
    );
  };

  render() {
    const { totalWeighting, closeModal, benefitType } = this.props;

    const {
      formData,
      validations,
      displaySumErrorMessage,
      displayNotNullMessage
    } = this.state;

    if (benefitType === BENEFIT_KIND_MEDICAL) {
      return (
        <div>
          <Row className="medical-weightings-row-gap">
            <WeightsModelingInput
              title="Deductible - Single"
              isValid={validations.deductibleSingle}
              value={formData.deductibleSingle}
              id="deductibleSingle"
              name="deductibleSingle"
              onChange={this.onChange}
            />
            <WeightsModelingInput
              title="Deductible - Family"
              isValid={validations.deductibleFamily}
              value={formData.deductibleFamily}
              id="deductibleFamily"
              name="deductibleFamily"
              onChange={this.onChange}
            />
            <WeightsModelingInput
              title="OOP Max - Single"
              isValid={validations.oopMaxSingle}
              value={formData.oopMaxSingle}
              id="oopMaxSingle"
              name="oopMaxSingle"
              onChange={this.onChange}
            />
            <WeightsModelingInput
              title="OOP Max - Family"
              isValid={validations.oopMaxFamily}
              value={formData.oopMaxFamily}
              id="oopMaxFamily"
              name="oopMaxFamily"
              onChange={this.onChange}
            />
          </Row>
          <Row className="medical-weightings-second-row-gap">
            <WeightsModelingInput
              title="Copay - Primary"
              isValid={validations.copayPrimary}
              value={formData.copayPrimary}
              id="copayPrimary"
              name="copayPrimary"
              onChange={this.onChange}
            />
            <WeightsModelingInput
              title="Copay - Specialist"
              isValid={validations.copaySpecialist}
              value={formData.copaySpecialist}
              id="copaySpecialist"
              name="copaySpecialist"
              onChange={this.onChange}
            />
            <WeightsModelingInput
              title="Coinsurance"
              isValid={validations.coinsurance}
              value={formData.coinsurance}
              id="coinsurance"
              name="coinsurance"
              onChange={this.onChange}
            />
            <WeightsModelingInput
              title="Rx Cost - 1"
              isValid={validations.rxT1}
              value={formData.rxT1}
              id="rxCost1"
              name="rxT1"
              onChange={this.onChange}
            />
          </Row>
          <Row className="medical-weightings-second-row-gap">
            <WeightsModelingInput
              title="Rx Cost - 2"
              isValid={validations.rxT2}
              value={formData.rxT2}
              id="rxCost2"
              name="rxT2"
              onChange={this.onChange}
              colXs={3}
            />
            <WeightsModelingInput
              title="Rx Cost - 3"
              isValid={validations.rxT3}
              value={formData.rxT3}
              id="rxCost3"
              name="rxT3"
              onChange={this.onChange}
              colXs={3}
            />
            <WeightsModelingInput
              title="Rx Cost - 4"
              isValid={validations.rxT4}
              value={formData.rxT4}
              id="rxCost4"
              name="rxT4"
              onChange={this.onChange}
              colXs={3}
            >
              <Label className="medical-weightings-header">
                <div className="medical-weightings-score">
                  /{totalWeighting}
                </div>
              </Label>
            </WeightsModelingInput>
          </Row>
          {displaySumErrorMessage === true && (
            <Row>{this.displaySumErrorMessage()}</Row>
          )}
          {displaySumErrorMessage === false &&
            displayNotNullMessage === true && (
              <Row>{this.displayValidationMessage()}</Row>
            )}
          <Row className="adjust-weighting-action-buttons-row">
            <Col xs={6}>
              <PageActionButton onClick={closeModal} outline>
                Cancel
              </PageActionButton>
            </Col>
            <Col xs={6}>
              <PageActionButton onClick={this.save}>Save</PageActionButton>
            </Col>
          </Row>
        </div>
      );
    } else if (benefitType === BENEFIT_KIND_DENTAL) {
      return (
        <div>
          <Row className="medical-weightings-row-gap">
            <WeightsModelingInput
              title="Deductible - Single"
              isValid={validations.deductibleSingle}
              value={formData.deductibleSingle}
              id="deductibleSingle"
              name="deductibleSingle"
              onChange={this.onChange}
              colXs={3}
            />
            <WeightsModelingInput
              title="Deductible - Family"
              isValid={validations.deductibleFamily}
              value={formData.deductibleFamily}
              id="deductibleFamily"
              name="deductibleFamily"
              onChange={this.onChange}
              colXs={3}
            />
            <WeightsModelingInput
              title="Coinsurance (preventitive)"
              isValid={validations.coinsurancePreventitive}
              value={formData.coinsurancePreventitive}
              id="coinsurancePreventitive"
              name="coinsurancePreventitive"
              onChange={this.onChange}
              colXs={3}
            />
          </Row>
          <Row className="medical-weightings-second-row-gap">
            <WeightsModelingInput
              title="Coinsurance (basic)"
              isValid={validations.coinsuranceBasic}
              value={formData.coinsuranceBasic}
              id="coinsuranceBasic"
              name="coinsuranceBasic"
              onChange={this.onChange}
            />
            <WeightsModelingInput
              title="Coinsurance (major)"
              isValid={validations.coinsuranceMajor}
              value={formData.coinsuranceMajor}
              id="coinsuranceMajor"
              name="coinsuranceMajor"
              onChange={this.onChange}
            />
            <WeightsModelingInput
              title="Orthodontia Coinsurance"
              isValid={validations.orthodontiaCoinsuarance}
              value={formData.orthodontiaCoinsuarance}
              id="orthodontiaCoinsuarance"
              name="orthodontiaCoinsuarance"
              onChange={this.onChange}
            />
            <WeightsModelingInput
              title="Orthodontia Max Benefits"
              isValid={validations.orthodontiaMaxBenefits}
              value={formData.orthodontiaMaxBenefits}
              id="orthodontiaMaxBenefits"
              name="orthodontiaMaxBenefits"
              onChange={this.onChange}
            >
              <Label className="medical-weightings-header">
                <div className="medical-weightings-score">
                  /{totalWeighting}
                </div>
              </Label>
            </WeightsModelingInput>
          </Row>
          <Row className="medical-weightings-second-row-gap"></Row>
          {displaySumErrorMessage === true && (
            <Row>{this.displaySumErrorMessage()}</Row>
          )}
          {displaySumErrorMessage === false &&
            displayNotNullMessage === true && (
              <Row>{this.displayValidationMessage()}</Row>
            )}
          <Row className="adjust-weighting-action-buttons-row">
            <Col xs={6}>
              <PageActionButton onClick={closeModal} outline>
                Cancel
              </PageActionButton>
            </Col>
            <Col xs={6}>
              <PageActionButton onClick={this.save}>Save</PageActionButton>
            </Col>
          </Row>
        </div>
      );
    } else if (benefitType === BENEFIT_KIND_VISION) {
      return (
        <div>
          <Row className="medical-weightings-row-gap">
            <WeightsModelingInput
              title="Copay - Frame"
              isValid={validations.frame}
              value={formData.frame}
              id="frame"
              name="frame"
              onChange={this.onChange}
              colXs={3}
            />
            <WeightsModelingInput
              title="Copay - Contacts"
              isValid={validations.contacts}
              value={formData.contacts}
              id="contacts"
              name="contacts"
              onChange={this.onChange}
              colXs={3}
            />
            <WeightsModelingInput
              title="Copay - Exam"
              isValid={validations.exams}
              value={formData.exams}
              id="exams"
              name="exams"
              onChange={this.onChange}
              colXs={3}
            >
              <Label className="medical-weightings-header">
                <div className="medical-weightings-score">
                  /{totalWeighting}
                </div>
              </Label>
            </WeightsModelingInput>
          </Row>

          <Row className="medical-weightings-second-row-gap"></Row>
          {displaySumErrorMessage === true && (
            <Row>{this.displaySumErrorMessage()}</Row>
          )}
          {displaySumErrorMessage === false &&
            displayNotNullMessage === true && (
              <Row>{this.displayValidationMessage()}</Row>
            )}
          <Row className="adjust-weighting-action-buttons-row">
            <Col xs={6}>
              <PageActionButton onClick={closeModal} outline>
                Cancel
              </PageActionButton>
            </Col>
            <Col xs={6}>
              <PageActionButton onClick={this.save}>Save</PageActionButton>
            </Col>
          </Row>
        </div>
      );
    } else {
      return <></>;
    }
  }
}
const WeightsModelingInput = (props) => {
  const { title, isValid, value, onChange, id, name, colXs, children } = props;
  return (
    <Col xs={colXs ? colXs : ''}>
      <Row>
        <Label className="medical-weightings-header">{title}</Label>
      </Row>
      <Row>
        <NumberFormat
          type="text"
          className={
            isValid
              ? 'medical-weightings-text-box'
              : 'medical-weightings-text-box-invalid'
          }
          id={id}
          name={name}
          value={value}
          onBlur={onChange}
          allowNegative={true}
          decimalScale={2}
        />
        {children}
      </Row>
    </Col>
  );
};
const generateEmptyForm = (benefitType) => {
  if (benefitType === BENEFIT_KIND_MEDICAL) {
    return {
      deductibleSingle: '',
      deductibleFamily: '',
      oopMaxSingle: '',
      oopMaxFamily: '',
      copayPrimary: '',
      copaySpecialist: '',
      coinsurance: '',
      rxT1: '',
      rxT2: '',
      rxT3: '',
      rxT4: '',
    };
  } else if (benefitType === BENEFIT_KIND_DENTAL) {
    return {
      orthodontiaMax: '',
      calendarMax: '',
      deductibleSingle: '',
      deductibleFamily: '',
      orthodontiaCoinsurance: ''
    };
  } else if (benefitType === BENEFIT_KIND_VISION) {
    return {
      frame: '',
      contacts: '',
      exams: ''
    };
  }
};

const generateValidations = (benefitType) => {
  if (benefitType === BENEFIT_KIND_MEDICAL) {
    return {
      deductibleSingle: true,
      deductibleFamily: true,
      oopMaxSingle: true,
      oopMaxFamily: true,
      copayPrimary: true,
      copaySpecialist: true,
      coinsurance: true,
      rxT1: true,
      rxT2: true,
      rxT3: true,
      rxT4: true,
    };
  } else if (benefitType === BENEFIT_KIND_DENTAL) {
    return {
      deductibleSingle: true,
      deductibleFamily: true,
      orthodontiaMaxBenefits: true,
      coinsuranceBasic: true,
      coinsurancePreventitive: true,
      coinsuranceMajor: true,
      orthodontiaCoinsuarance: true
    };
  } else if (benefitType === BENEFIT_KIND_VISION) {
    return {
      frame: true,
      contacts: true,
      exams: true
    };
  }
};
export default MDVWeightingsModal;
