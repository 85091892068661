/**
 * BENEFIT_KIND_{kind} should match the BenefitKind enum from Platform's responses.
 */
export const BENEFIT_KIND_MEDICAL = 'MEDICAL';
export const BENEFIT_KIND_DENTAL = 'DENTAL';
export const BENEFIT_KIND_VISION = 'VISION';
export const BENEFIT_KIND_BASIC_LIFE = 'BASIC_LIFE';
export const BENEFIT_KIND_VOLUNTARY_LIFE = 'VOLUNTARY_LIFE';
export const BENEFIT_KIND_LTD = 'LTD';
export const BENEFIT_KIND_STD = 'STD';
export const BENEFIT_KIND_BASIC_ADD = 'BASIC_ADD';
export const BENEFIT_KIND_VOLUNTARY_ADD = 'VOLUNTARY_ADD';
export const BENEFIT_KIND_HSA = 'HSA';
export const BENEFIT_KIND_FSA = 'FSA';
export const BENEFIT_KIND_LIMITED_FSA = 'LIMITED_FSA';
export const BENEFIT_KIND_DCAP_FSA = 'DCAP_FSA';
export const BENEFIT_KIND_HRA = 'HRA';
export const BENEFIT_KIND_TRANSIT = 'TRANSIT';
export const BENEFIT_KIND_PARKING = 'PARKING';
export const BENEFIT_KIND_BIKING = 'BIKING';
export const BENEFIT_KIND_PAYOUT = 'PAYOUT';
export const BENEFIT_KIND_RETIREMENT_401K = 'RETIREMENT_401K';
export const BENEFIT_KIND_BASIC_STD = 'BASIC_STD';
export const BENEFIT_KIND_BASIC_LTD = 'BASIC_LTD';
export const BENEFIT_KIND_BASIC_LIFE_ADD = 'BASIC_LIFE_ADD';
export const BENEFIT_KIND_VOLUNTARY_LTD = 'VOLUNTARY_LTD';
export const BENEFIT_KIND_VOLUNTARY_STD = 'VOLUNTARY_STD';

export const BENEFIT_KIND_SAVING = 'SAVINGS';
export const BENEFIT_KIND_LIFE = 'LIFE';
export const BENEFIT_KIND_COMMUTER = 'COMMUTER';
export const BENEFIT_KIND_MEDITATION = 'MEDITATION';
export const BENEFIT_KIND_MENTAL_HEALTH = 'MENTAL_HEALTH';
export const BENEFIT_KIND_WELLNESS_REIMBURSEMENT = 'WELLNESS_REIMBURSEMENT';
export const BENEFIT_KIND_ADDITIONAL_PERK = 'ADDITIONAL_PERK';
export const BENEFIT_KIND_WORK_LIFE = 'WORK_LIFE';
export const BENEFIT_KIND_WELLBEING = 'WELLBEING';
export const BENEFIT_KIND_TAX_ADVANTAGED_ACCOUNTS = 'TAX_ADVANTAGED_ACCOUNTS';
export const BENEFIT_KIND_TELEHEALTH = 'TELEHEALTH';
export const BENEFIT_KIND_RX_DELIVERY = 'RX_DELIVERY';
export const BENEFIT_KIND_RX_COUPONS = 'RX_COUPONS';

// Benefit Category
export const BENEFIT_CATEGORY_MEDICAL = 'MEDICAL';
export const BENEFIT_CATEGORY_DENTAL = 'DENTAL';
export const BENEFIT_CATEGORY_VISION = 'VISION';
export const BENEFIT_CATEGORY_SAVING = 'SAVING';
export const BENEFIT_CATEGORY_LIFE = 'LIFE';
export const BENEFIT_CATEGORY_COMMUTER = 'COMMUTER';
export const BENEFIT_CATEGORY_RETIREMENT = 'RETIREMENT';
export const BENEFIT_CATEGORY_WELLBEING = 'WELLBEING';
export const BENEFIT_CATEGORY_ADDITIONAL_PERK = 'ADDITIONAL_PERK';
export const BENEFIT_CATEGORY_WORK_LIFE = 'WORK_LIFE';
export const BENEFIT_CATEGORY_TELEHEALTH = 'TELEHEALTH';
export const BENEFIT_CATEGORY_RX_DELIVERY = 'RX_DELIVERY';
export const BENEFIT_CATEGORY_RX_COUPONS = 'RX_COUPONS';

export const BENEFIT_CATEGORY_DISPLAY_MAP = {};
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_MEDICAL] = 'Medical';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_DENTAL] = 'Dental';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_VISION] = 'Vision';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_SAVING] = 'Spending';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_LIFE] = 'Life';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_COMMUTER] = 'Commuter';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_RETIREMENT] = 'Retirement';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_WELLBEING] = 'Wellbeing';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_TELEHEALTH] = 'Telehealth';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_RX_DELIVERY] = 'Rx Delivery';
BENEFIT_CATEGORY_DISPLAY_MAP[BENEFIT_CATEGORY_RX_COUPONS] = 'Rx Coupons';

export const BENEFIT_KIND_DISPLAY_MAP = {};
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_MEDICAL] = 'Medical';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_DENTAL] = 'Dental';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_VISION] = 'Vision';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_BASIC_LIFE] = 'Basic Life';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_LIFE] = 'Voluntary Life';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_LTD] = 'LTD';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_STD] = 'STD';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_BASIC_ADD] = 'Basic ADD';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_ADD] = 'Voluntary ADD';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_HSA] = 'HSA';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_FSA] = 'FSA';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_LIMITED_FSA] = 'Limited FSA';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_DCAP_FSA] = 'DCAP FSA';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_HRA] = 'HRA';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_TRANSIT] = 'Transit';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_PARKING] = 'Parking';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_BIKING] = 'Biking';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_PAYOUT] = 'Payout';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_BASIC_STD] = 'Basic STD';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_BASIC_LTD] = 'Basic LTD';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_BASIC_LIFE_ADD] = 'Basic Life & AD&D';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_LTD] = 'Voluntary LTD';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_VOLUNTARY_STD] = 'Voluntary STD';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_MEDITATION] = 'Meditation';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_MENTAL_HEALTH] = 'Mental Health';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_WELLNESS_REIMBURSEMENT] =
  'Wellness Reimbursement';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_ADDITIONAL_PERK] = 'Additional Perk';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_TELEHEALTH] = 'Telehealth';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_RX_DELIVERY] = 'Rx Delivery';
BENEFIT_KIND_DISPLAY_MAP[BENEFIT_KIND_RX_COUPONS] = 'Rx Coupons';

export default BENEFIT_CATEGORY_DISPLAY_MAP;

// These should match platform's BenefitCategory.java
export const BenefitCategory = {
  MEDICAL: { value: 'MEDICAL', label: 'Medical' },
  DENTAL: { value: 'DENTAL', label: 'Dental' },
  VISION: { value: 'VISION', label: 'Vision' },
  LIFE: { value: 'LIFE', label: 'Life & Disability' },
  SAVING: { value: 'SAVING', label: 'Spending' },
  COMMUTER: { value: 'COMMUTER', label: 'Commuter' },
  RETIREMENT: { value: 'RETIREMENT', label: 'Retirement' },
  WELLBEING: { value: 'WELLBEING', label: 'Wellbeing' },
  WORK_LIFE: { value: 'WORK_LIFE', label: 'Worklife' },
  ADDITIONAL_PERK: { value: 'ADDITIONAL_PERK', label: 'Additional Perk' },
  TELEHEALTH: { value: 'TELEHEALTH', label: 'Telehealth' },
  RX_DELIVERY: { value: 'RX_DELIVERY', label: 'Rx Delivery' },
  RX_COUPONS: { value: 'RX_COUPONS', label: 'Rx Coupons' }
};

// These should match platform's BenefitKind.java
export const BenefitKind = {
  MEDICAL: {
    value: 'MEDICAL',
    label: 'Medical',
    category: BenefitCategory.MEDICAL
  },
  DENTAL: {
    value: 'DENTAL',
    label: 'Dental',
    category: BenefitCategory.DENTAL
  },
  VISION: {
    value: 'VISION',
    label: 'Vision',
    category: BenefitCategory.VISION
  },
  BASIC_LIFE: {
    value: 'BASIC_LIFE',
    label: 'Basic Life',
    category: BenefitCategory.LIFE
  },
  VOLUNTARY_LIFE: {
    value: 'VOLUNTARY_LIFE',
    label: 'Voluntary Life',
    category: BenefitCategory.LIFE
  },
  LTD: {
    value: 'LTD',
    label: 'Long Term Disability',
    category: BenefitCategory.LIFE
  },
  STD: {
    value: 'STD',
    label: 'Short Term Disability',
    category: BenefitCategory.LIFE
  },
  BASIC_ADD: {
    value: 'BASIC_ADD',
    label: 'Basic AD&D',
    category: BenefitCategory.LIFE
  },
  VOLUNTARY_ADD: {
    value: 'VOLUNTARY_ADD',
    label: 'Voluntary AD&D',
    category: BenefitCategory.LIFE
  },
  BASIC_LIFE_AND_ADD: {
    value: 'BASIC_LIFE_AND_ADD',
    label: 'Basic Life and AD&D',
    category: BenefitCategory.LIFE
  },
  HSA: {
    value: 'HSA',
    label: 'Health Savings Account',
    category: BenefitCategory.SAVING
  },
  FSA: {
    value: 'FSA',
    label: 'Medical FSA',
    category: BenefitCategory.SAVING
  },
  LIMITED_FSA: {
    value: 'LIMITED_FSA',
    label: 'Limited Purpose FSA',
    category: BenefitCategory.SAVING
  },
  DCAP_FSA: {
    value: 'DCAP_FSA',
    label: 'Dependent Care FSA',
    category: BenefitCategory.SAVING
  },
  HRA: {
    value: 'HRA',
    label: 'Health Reimbursement Account',
    category: BenefitCategory.SAVING
  },
  TRANSIT: {
    value: 'TRANSIT',
    label: 'Transit',
    category: BenefitCategory.COMMUTER
  },
  PARKING: {
    value: 'PARKING',
    label: 'Parking',
    category: BenefitCategory.COMMUTER
  },
  BIKING: {
    value: 'BIKING',
    label: 'Biking',
    category: BenefitCategory.COMMUTER
  },
  RETIREMENT_401K: {
    value: 'RETIREMENT_401K',
    label: '401K',
    category: BenefitCategory.RETIREMENT
  },
  // Due to backend usage of Commuter's benefit kinds, we must create a dummy kind
  COMMUTER: {
    value: 'COMMUTER',
    label: 'Commuter',
    category: BenefitCategory.COMMUTER
  },
  MEDITATION: {
    value: 'MEDITATION',
    label: 'Meditation',
    category: BenefitCategory.WELLBEING
  },
  MENTAL_HEALTH: {
    value: 'MENTAL_HEALTH',
    label: 'Mental Health',
    category: BenefitCategory.WELLBEING
  },
  WELLNESS_REIMBURSEMENT: {
    value: 'WELLNESS_REIMBURSEMENT',
    label: 'Wellness Reimbursement',
    category: BenefitCategory.WELLBEING
  },
  BACKUP_CHILDCARE: {
    value: 'BACKUP_CHILDCARE',
    label: 'Family - Backup Childcare',
    category: BenefitCategory.WORK_LIFE
  },
  BEREAVEMENT: {
    value: 'BEREAVEMENT',
    label: 'Family - Bereavement',
    category: BenefitCategory.WORK_LIFE
  },
  FERTILITY: {
    value: 'FERTILITY',
    label: 'Family - Fertility',
    category: BenefitCategory.WORK_LIFE
  },
  PARENTAL_LEAVE: {
    value: 'PARENTAL_LEAVE',
    label: 'Family - Parental Leave',
    category: BenefitCategory.WORK_LIFE
  },
  FLEXIBLE_WORKING_HOURS: {
    value: 'FLEXIBLE_WORKING_HOURS',
    label: 'Time Off - Flexible Working Hours',
    category: BenefitCategory.WORK_LIFE
  },
  HOLIDAY: {
    value: 'HOLIDAY',
    label: 'Time Off - Holiday',
    category: BenefitCategory.WORK_LIFE
  },
  PAID_TIME_OFF: {
    value: 'PAID_TIME_OFF',
    label: 'Time Off - Paid Time Off',
    category: BenefitCategory.WORK_LIFE
  },
  SICK: {
    value: 'SICK',
    label: 'Time Off - Sick',
    category: BenefitCategory.WORK_LIFE
  },
  CLUBS: {
    value: 'CLUBS',
    label: 'Communities - Clubs',
    category: BenefitCategory.WORK_LIFE
  },
  ADDITIONAL_PERK: {
    value: 'ADDITIONAL_PERK',
    label: 'Additional Perk',
    category: BenefitCategory.ADDITIONAL_PERK
  },
  TELEHEALTH: {
    value: 'TELEHEALTH',
    label: 'Telehealth',
    category: BenefitCategory.TELEHEALTH
  },
  RX_DELIVERY: {
    value: 'RX_DELIVERY',
    label: 'Rx Delivery',
    category: BenefitCategory.RX_DELIVERY
  },
  RX_COUPONS: {
    value: 'RX_COUPONS',
    label: 'Rx Coupons',
    category: BenefitCategory.RX_COUPONS
  }
};
