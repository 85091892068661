/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';

import { FormGroup, FormFeedback, Label, Input } from 'components/Atoms';

export const PasswordFormGroup = (props) => {
  const {
    className,
    labelDisplay,
    inputName,
    inputValue,
    onChange,
    disabled,
    placeholder,
    isInvalid,
    feedback
  } = props;

  return (
    <FormGroup className={className || ''}>
      <Label for={inputName}>{labelDisplay}</Label>
      <Input
        className={isInvalid ? 'is-invalid' : ''}
        type="password"
        name={inputName}
        value={inputValue}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder || labelDisplay}
        // Disables autofill
        autoComplete="new-password"
      />
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
    </FormGroup>
  );
};

export default PasswordFormGroup;
