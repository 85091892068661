/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
export const GET_EMPLOYEES_PAGE_IN_PROGRESS = 'GET_EMPLOYEES_PAGE_IN_PROGRESS';
export const GET_EMPLOYEES_PAGE_COMPLETED = 'GET_EMPLOYEES_PAGE_COMPLETED';
export const GET_EMPLOYEES_PAGE_FAILED = 'GET_EMPLOYEES_PAGE_FAILED';

export const CREATE_EMPLOYEE_IN_PROGRESS = 'CREATE_EMPLOYEE_IN_PROGRESS';
export const CREATE_EMPLOYEE_COMPLETED = 'CREATE_EMPLOYEE_COMPLETED';
export const CREATE_EMPLOYEE_FAILED = 'CREATE_EMPLOYEE_FAILED';

export const DELETE_EMPLOYEE_IN_PROGRESS = 'DELETE_EMPLOYEE_IN_PROGRESS';
export const DELETE_EMPLOYEE_COMPLETED = 'DELETE_EMPLOYEE_COMPLETED';
export const DELETE_EMPLOYEE_FAILED = 'DELETE_EMPLOYEE_FAILED';

export const INVITE_EMPLOYEE_IN_PROGRESS = 'INVITE_EMPLOYEE_IN_PROGRESS';
export const INVITE_EMPLOYEE_COMPLETED = 'INVITE_EMPLOYEE_COMPLETED';
export const INVITE_EMPLOYEE_FAILED = 'INVITE_EMPLOYEE_FAILED';

export const GET_EMPLOYEE_DETAILS_IN_PROGRESS = 'GET_EMPLOYEE_DETAILS_IN_PROGRESS';
export const GET_EMPLOYEE_DETAILS_COMPLETED = 'GET_EMPLOYEE_DETAILS_COMPLETED';
export const GET_EMPLOYEE_DETAILS_FAILED = 'GET_EMPLOYEE_DETAILS_FAILED';

export const GET_DEPENDENTS_DETAILS_IN_PROGRESS = 'GET_DEPENDENTS_DETAILS_IN_PROGRESS';
export const GET_DEPENDENTS_DETAILS_FAILED = 'GET_DEPENDENTS_DETAILS_FAILED';

export const UPDATE_EMPLOYEE_DETAILS_IN_PROGRESS = 'UPDATE_EMPLOYEE_DETAILS_IN_PROGRESS';
export const UPDATE_EMPLOYEE_DETAILS_COMPLETED = 'UPDATE_EMPLOYEE_DETAILS_COMPLETED';
export const UPDATE_EMPLOYEE_DETAILS_FAILED = 'UPDATE_EMPLOYEE_DETAILS_FAILED';

export const CHANGE_USERNAME_IN_PROGRESS = 'CHANGE_USERNAME_IN_PROGRESS';
export const CHANGE_USERNAME_COMPLETED = 'CHANGE_USERNAME_COMPLETED';
export const CHANGE_USERNAME_FAILED = 'CHANGE_USERNAME_FAILED';

export const DELETE_DEPENDENT_IN_PROGRESS = 'DELETE_DEPENDENT_IN_PROGRESS';
export const DELETE_DEPENDENT_COMPLETED = 'DELETE_DEPENDENT_COMPLETED';
export const DELETE_DEPENDENT_FAILED = 'DELETE_DEPENDENT_FAILED';

export const UPDATE_DEPENDENT_IN_PROGRESS = 'UPDATE_DEPENDENT_IN_PROGRESS';
export const UPDATE_DEPENDENT_COMPLETED = 'UPDATE_DEPENDENT_COMPLETED';
export const UPDATE_DEPENDENT_FAILED = 'UPDATE_DEPENDENT_FAILED';

export const CHANGE_SSN_IN_PROGRESS = 'CHANGE_SSN_IN_PROGRESS';
export const CHANGE_SSN_COMPLETED = 'CHANGE_SSN_COMPLETED';
export const CHANGE_SSN_FAILED = 'CHANGE_SSN_FAILED';

export const GET_EMPLOYER_NOTIFICATION_CONFIG_IN_PROGRESS =
  'GET_EMPLOYER_NOTIFICATION_CONFIG_IN_PROGRESS';
export const GET_EMPLOYER_NOTIFICATION_CONFIG_COMPLETED =
  'GET_EMPLOYER_NOTIFICATION_CONFIG_COMPLETED';
export const GET_EMPLOYER_NOTIFICATION_CONFIG_FAILED =
  'GET_EMPLOYER_NOTIFICATION_CONFIG_FAILED';

export const UPDATE_EMPLOYER_NOTIFICATION_CONFIG_IN_PROGRESS =
  'UPDATE_EMPLOYER_NOTIFICATION_CONFIG_IN_PROGRESS';
export const UPDATE_EMPLOYER_NOTIFICATION_CONFIG_COMPLETED =
  'UPDATE_EMPLOYER_NOTIFICATION_CONFIG_COMPLETED';
export const UPDATE_EMPLOYER_NOTIFICATION_CONFIG_FAILED =
  'UPDATE_EMPLOYER_NOTIFICATION_CONFIG_FAILED';

export const REHIRE_EMPLOYEE_IN_PROGRESS = 'REHIRE_EMPLOYEE_IN_PROGRESS';
export const REHIRE_EMPLOYEE_COMPLETED = 'REHIRE_EMPLOYEE_COMPLETED';
export const REHIRE_EMPLOYEE_FAILED = 'REHIRE_EMPLOYEE_FAILED';
