/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/benchmark.actionTypes';

export default (
  state = {
    currentPlanDetails: [],
    currentPlanDetailsError: [],
    benchmarksPage: {
      data: [],
      error: null,
      isFetching: false
    },
    configStatus: { id: null, value: 'false', lastUpdatedTs: null },
    // Top level benchmark object
    benchmark: {},
    census: {},
    benchmarkSaveData: {
      benchmarkSaveResult: null,
      saveBenchmarkError: null,
      isCreatingBenchmark: false
    },
    metadata: {},
    benchmarkPlans: [],
    benchmarkPlansLoading: false,
    fetchBenchmarkByIdLoading: false,
    publishedBenchmark: {},
    clonedBenchmark: null,
    benchmarkCloningError: null,
    benchmarkDataError: false,
    benchmarkOverallRates: {
      inProgress: false,
      rates: {},
      error: null
    }
  },
  { type, payload }
) => {
  /* eslint-disable */
  switch (type) {
    case ActionTypes.GET_BENCHMARKS_PAGE_IN_PROGRESS:
      return {
        ...state,
        benchmarksPage: {
          ...state.benchmarksPage,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_BENCHMARKS_PAGE_COMPLETED:
      return {
        ...state,
        benchmarksPage: {
          data: payload,
          error: null,
          isFetching: false
        },
      };
    case ActionTypes.GET_BENCHMARKS_PAGE_FAILED:
      return {
        ...state,
        benchmarksPage: {
          ...state.benchmarksPage,
          isFetching: false,
          error: payload
        }
      };
    case ActionTypes.SAVE_MEDICAL_CURRENT_PLANS:
      return {
        ...state,
        currentPlanDetails: payload
      };
    case ActionTypes.SAVE_MEDICAL_CURRENT_PLANS_ERROR:
      return {
        ...state,
        currentPlanDetailsError: payload
      };

    case ActionTypes.FIND_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        configStatus: payload
      };
    case ActionTypes.CREATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        // Re-assiging the state value to trigger state change at component level
        // since it's always 204 from the backend
        employerConfigCreate: Object.assign(payload)
      };
    case ActionTypes.CREATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES_ERROR:
      return {
        ...state,
        employerConfigCreateError: payload
      };
    case ActionTypes.UPDATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        // Re-assiging the state value to trigger state change at component level
        // since it's always 204 from the backend
        employerConfigUpdate: Object.assign(payload)
      };
    case ActionTypes.UPDATE_BENCHMARKS_EMPLOYER_CONFIGURATION_MODULES_ERROR:
      return {
        ...state,
        employerConfigUpdateError: payload
      };
    case ActionTypes.FIND_BENCHMARK_BY_ID_IN_PROGRESS:
      return {
        ...state,
        fetchBenchmarkByIdLoading: true
      };
    case ActionTypes.FIND_BENCHMARK_BY_ID:
      return {
        ...state,
        fetchBenchmarkByIdLoading: false,
        benchmark: payload
      };
    case ActionTypes.FIND_BENCHMARK_BY_ID_ERROR:
      return {
        ...state,
        fetchBenchmarkByIdLoading: false,
        findBenchmarkError: payload
      };
    case ActionTypes.FIND_PUBLISHED_BENCHMARK_BY_ID:
      return {
        ...state,
        publishedBenchmark: payload
      };
    case ActionTypes.FIND_PUBLISHED_BENCHMARK_BY_ID_ERROR:
      return {
        ...state,
        findPublishedBenchmarkError: payload
      };
    case ActionTypes.FIND_CENSUS_BY_ID:
      return {
        ...state,
        census: payload
      };
    case ActionTypes.FIND_CENSUS_BY_ID_ERROR:
      return {
        ...state,
        findCensusError: payload
      };
    case ActionTypes.GET_BENCHMARK_METADATA:
      return {
        ...state,
        metadata: payload
      };
    case ActionTypes.GET_BENCHMARK_METADATA_ERROR:
      return {
        ...state,
        metadataError: payload
      };
    case ActionTypes.SAVE_BENCHMARK_BY_ID_IN_PROGRESS:
      return {
        ...state,
        benchmarkSaveData: {
          ...state.benchmarkSaveData,
          isCreatingBenchmark: true
        }
      };
    case ActionTypes.SAVE_BENCHMARK_BY_ID:
      return {
        ...state,
        benchmarkSaveData: {
          ...state.benchmarkSaveData,
          benchmarkSaveResult: payload,
          isCreatingBenchmark: false
        }
      };
    case ActionTypes.SAVE_BENCHMARK_BY_ID_ERROR:
      return {
        ...state,
        benchmarkSaveData: {
          ...state.benchmarkSaveData,
          saveBenchmarkError: payload,
          isCreatingBenchmark: false
        }
      };
    case ActionTypes.CREATE_BENCHMARK_BY_ID_IN_PROGRESS:
      return {
        ...state,
        benchmarkSaveData: {
          ...state.benchmarkSaveData,
          isCreatingBenchmark: true
        }
      };
    case ActionTypes.CREATE_BENCHMARK_BY_ID:
      return {
        ...state,
        benchmarkSaveData: {
          ...state.benchmarkSaveData,
          benchmarkSaveResult: payload,
          isCreatingBenchmark: false
        }
      };
    case ActionTypes.CREATE_BENCHMARK_BY_ID_ERROR:
      return {
        ...state,
        benchmarkSaveData: {
          ...state.benchmarkSaveData,
          saveBenchmarkError: payload,
          isCreatingBenchmark: false
        }
      };
    case ActionTypes.FIND_BENCHMARK_PLAN_IN_PROGRESS:
      return {
        ...state,
        benchmarkPlansLoading: true
      };
    case ActionTypes.FIND_BENCHMARK_PLAN:
      return {
        ...state,
        benchmarkPlans: payload,
        benchmarkPlansLoading: false
      };
    case ActionTypes.FIND_BENCHMARK_PLAN_ERROR:
      return {
        ...state,
        benchmarkPlansError: payload,
        benchmarkPlansLoading: false
      };
    case ActionTypes.CLONE_BENCHMARK:
      return {
        ...state,
        clonedBenchmark: payload
      };
    case ActionTypes.CLONE_BENCHMARK_ERROR:
      return {
        ...state,
        benchmarkCloningError: payload
      };
    case ActionTypes.VALIDATE_BENCHMARK_DATA:
      return {
        ...state,
        benchmarkDataError: payload
      };
    case ActionTypes.GET_BENCHMARKS_OVERALL_RATES_IN_PROGRESS:
      return {
        ...state,
        benchmarkOverallRates: {
          rates: state.benchmarkOverallRates.rates,
          inProgress: true
        }
      };
    case ActionTypes.GET_BENCHMARKS_OVERALL_RATES_SUCCES:
      return {
        ...state,
        benchmarkOverallRates: {
          inProgress: false,
          rates: payload
        }
      };
    case ActionTypes.GET_BENCHMARKS_OVERALL_RATES_ERROR:
      return {
        ...state,
        benchmarkOverallRates: {
          inProgress: false,
          error: payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
