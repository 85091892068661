/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React, { Component } from 'react';
import { Row, Col, Label } from 'components/Atoms';
import { formatCurrency } from 'util/commonUtil';
import { isValuePresent } from '../../util';

export const DETAIL_PRESENTAGE = 'PERCENTAGE';
export const DETAIL_CURRENCY = 'CURRENCY';
export const UP_TO = 'UP_TO';
export const TEXT = 'TEXT';

class PlanDetailsLine extends Component {
  render() {
    const {
      lable,
      value,
      type = DETAIL_CURRENCY,
      splitValue = false
    } = this.props;
    let formatedValue = value;
    if (!splitValue) {
      switch (type) {
        case DETAIL_CURRENCY:
          formatedValue = isValuePresent(value) ? formatCurrency(value) : '-';
          break;
        case DETAIL_PRESENTAGE:
          formatedValue = `${value}%`;
          break;
        case UP_TO:
          formatedValue = `Up to $${value}`;
          break;
        case TEXT:
          formatedValue = value;
          break;
        default:
          break;
      }
    } else {
      const values = value.split('/');
      const leftValue = isValuePresent(values[0])
        ? formatCurrency(values[0])
        : '-';
      const rightValue = isValuePresent(values[1])
        ? formatCurrency(values[1])
        : '-';
      formatedValue = `${leftValue} / ${rightValue}`;
    }

    return (
      <Row className="current-plan-detail-line-item">
        <Col className="current-plan-detail-line-inner">
          <Row>
            <Col>
              <Label className="plan-attr-lable">{lable}</Label>
            </Col>
            <Col>
              {value || value === 0 ? (
                <Label className="float-right">{formatedValue}</Label>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default PlanDetailsLine;
