/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/document.actionTypes';
import { EmployerDocumentType } from 'modules/Employers/constants';

const initialState = {
  employerResult: {},
  documentsStatus: {
    [EmployerDocumentType.EMPLOYEE_HANDBOOK.value]: {
      isUploading: false,
      isDeleting: false,
      error: null
    },
    [EmployerDocumentType.LEGAL_NOTICES.value]: {
      isUploading: false,
      isDeleting: false,
      error: null
    },
    [EmployerDocumentType.CARRIER_CONTACT_SHEET.value]: {
      isUploading: false,
      isDeleting: false,
      error: null
    }
  },
  additionalDocumentsStatus: {
    isUpdating: false,
    error: null
  },
  planYearDocuments: {
    data: [],
    isInprogress: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPLOAD_DOCUMENT_IN_PROGRESS:
      return {
        ...state,
        documentsStatus: {
          ...state.documentsStatus,
          [action.documentType]: {
            ...state.documentsStatus[action.documentType],
            isUploading: true,
            error: null
          }
        }
      };
    case ActionTypes.UPLOAD_DOCUMENT_COMPLETED:
      return {
        ...state,
        employerResult: action.payload,
        documentsStatus: {
          ...state.documentsStatus,
          [action.documentType]: {
            ...state.documentsStatus[action.documentType],
            isUploading: false,
            error: null
          }
        }
      };
    case ActionTypes.UPLOAD_DOCUMENT_FAILED:
      return {
        ...state,
        documentsStatus: {
          ...state.documentsStatus,
          [action.documentType]: {
            ...state.documentsStatus[action.documentType],
            isUploading: false,
            error: action.payload
          }
        }
      };
    case ActionTypes.DELETE_DOCUMENT_IN_PROGRESS:
      return {
        ...state,
        documentsStatus: {
          ...state.documentsStatus,
          [action.documentType]: {
            ...state.documentsStatus[action.documentType],
            isDeleting: true,
            error: null
          }
        }
      };
    case ActionTypes.DELETE_DOCUMENT_COMPLETED:
      return {
        ...state,
        employerResult: action.payload,
        documentsStatus: {
          ...state.documentsStatus,
          [action.documentType]: {
            ...state.documentsStatus[action.documentType],
            isDeleting: false,
            error: null
          }
        }
      };
    case ActionTypes.DELETE_DOCUMENT_FAILED:
      return {
        ...state,
        documentsStatus: {
          ...state.documentsStatus,
          [action.documentType]: {
            ...state.documentsStatus[action.documentType],
            isDeleting: false,
            error: action.payload
          }
        }
      };
    case ActionTypes.UPDATE_ADDITIONAL_DOCUMENTS_IN_PROGRESS:
      return {
        ...state,
        additionalDocumentsStatus: {
          isUpdating: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_ADDITIONAL_DOCUMENTS_COMPLETED:
      return {
        ...state,
        employerResult: action.payload,
        additionalDocumentsStatus: {
          isUpdating: false,
          error: null
        }
      };
    case ActionTypes.UPDATE_ADDITIONAL_DOCUMENTS_FAILED:
      return {
        ...state,
        additionalDocumentsStatus: {
          isUpdating: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_DOCUMENTS_IN_PGOGRESS:
      return {
        ...state,
        planYearDocuments: {
          ...state.planYearDocuments,
          isInprogress: true,
          error: null
        }
      };
    case ActionTypes.GET_DOCUMENTS_COMPLETED:
      return {
        ...state,
        planYearDocuments: {
          ...state.planYearDocuments,
          isInprogress: false,
          data: action.payload
        }
      };
    case ActionTypes.GET_DOCUMENTS_FAILED:
      return {
        ...state,
        planYearDocuments: {
          ...state.planYearDocuments,
          isInprogress: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
