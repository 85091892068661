/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import { Button, DropdownToggle } from 'components/Atoms';
import Icon from 'components/Icons';

import './PageActionButton.scss';

const PageActionButton = (props) => {
  switch (props.type) {
    case 'add':
      return AddButton(props);
    case 'progress':
      return ProgressButton(props);
    case 'sync':
      return SyncButton(props);
    case 'dropdown':
      return DropdownToggleButton(props);
    case 'withCustomIcon':
      return ButtonWithCustomIcon(props);
    default:
      return DefaultButton(props);
  }
};

PageActionButton.propTypes = {
  type: PropTypes.oneOf([
    '',
    'add',
    'progress',
    'sync',
    'dropdown',
    'withCustomIcon'
  ])
};

PageActionButton.defaultProps = {
  type: ''
};

const DefaultButton = (props) => {
  const { className = '', children, ...rest } = props;

  return (
    <Button
      color="primary"
      size="lg"
      className={`page-action-button ${className}`}
      {...rest}
    >
      {children}
    </Button>
  );
};

const AddButton = (props) => {
  const { children, ...rest } = props;
  return (
    <DefaultButton {...rest}>
      <Icon icon="plus" className="icon-margin-right" />
      {children}
    </DefaultButton>
  );
};

const ProgressButton = (props) => {
  const { children, ...rest } = props;
  return (
    <DefaultButton {...rest} disabled>
      <Icon icon="circle-notch" className="icon-margin-right" spin />
      {children}
    </DefaultButton>
  );
};

const SyncButton = (props) => {
  const { children } = props;
  return (
    <DefaultButton {...props} disabled>
      <Icon icon="sync" className="icon-margin-right" spin />
      {children}
    </DefaultButton>
  );
};

const DropdownToggleButton = (props) => {
  const { className = '', children, ...rest } = props;

  return (
    <DropdownToggle
      color="primary"
      size="lg"
      className={`page-action-button ${className}`}
      caret
      {...rest}
    >
      {children}
    </DropdownToggle>
  );
};

const ButtonWithCustomIcon = (props) => {
  const { children, icon, ...rest } = props;
  return (
    <DefaultButton {...rest}>
      <Icon icon={icon} className="icon-margin-right" />
      {children}
    </DefaultButton>
  );
};

export default PageActionButton;
