import _ from 'lodash';
import { isNullOrUndefined } from 'util';

import placeholderAvatar from '../assets/images/avatar-placeholder.png';

export const convertDisplayMapToOptions = (displayMap) => {
  return Object.entries(displayMap).map((entry) => {
    return { value: entry[0], text: entry[1] };
  });
};

// TODO: Remove the options logic from SelectFormGroup and directly pass in a list of html options.
export const convertEnumToOptions = (enums) => {
  return Object.values(enums).map((configs) => ({
    value: configs.value,
    text: configs.label
  }));
};

export const convertEnumToOptionsSortedByLabel = (enums) => {
  return Object.values(enums)
    .sort((a, b) => a.label.localeCompare(b.label))
    .map((config) => ({ value: config.value, text: config.label }));
};

/**
 * Takes an array of objects and returns a map with keys as the property values and the values as the objects.
 *
 * Does not do any type-checking.
 */
export const convertArrayToMapByProperty = (array, property) => {
  const mapObj = {};
  array.forEach((obj) => (mapObj[obj[property]] = obj));
  return mapObj;
};

export const formatCurrency = (amount) => {
  return amount || amount === 0
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(amount)
    : amount;
};

export const formatBMKPlanCurrentValue = (prefix, currentValue, suffix) => {
  if (prefix.includes('Up to')) {
    currentValue = parseFloat(currentValue).toFixed(2);
  }
  return prefix.concat(currentValue).concat(suffix);
};

export const formatCurrencyWithDefaultValue = (amount, defaultValue) => {
  let displayValue = defaultValue;
  switch (true) {
    case amount < 0:
      displayValue = `(${formatCurrency(Math.abs(amount))})`;
      break;
    case amount > 0:
      displayValue = formatCurrency(amount);
      break;
    default:
      displayValue = '-';
      break;
  }
  return displayValue;
};

export const formatCount = (count) => {
  if (count === 0) {
    return '-';
  } else {
    return count.toLocaleString();
  }
};

// Cleans input for phones but does not validate format
export const sanitizePhoneInput = (phoneNumber) => {
  return (
    phoneNumber
      // Remove any characters that are not 0-9, parentheses, or hyphen
      .replace(RegExp('[^0-9()-]'), '')
      // Replace any repeating hyphens with single hyphen
      .replace(RegExp('-{2,}'), '-')
  );
};
export const checkPhoneNumberIsValid = (value) => {
  let matcher = value.match(/[^0-9()-]/g);
  return !matcher;
};

// Cleans input for dates with the given delimiter
export const sanitizeDateInput = (date, delimiter) => {
  // Default delimiter matches backend LocalDate format (ISO-8601 calendar system of YYYY-MM-DD)
  delimiter = _.defaultTo(delimiter, '-');
  return (
    date
      // Remove any characters that are not digits or the given delimiter
      .replace(RegExp(`[^0-9${delimiter}]`), '')
      // Replace any repeating delimiter with single delimiter
      .replace(RegExp(`${delimiter}{2,}`), delimiter)
  );
};

export const sanitizeSsnInput = (ssnInput) => {
  // Retain only digits.
  const stripped = ssnInput.replace(RegExp('[^0-9]', 'g'), '');

  // Split the digits into the three portions of an SSN, AAA-GG-SSSS.
  const areaNumber = stripped.slice(0, 3);
  const groupNumber = stripped.slice(3, 5);
  const serialNumber = stripped.slice(5, 9);

  if (groupNumber && serialNumber) {
    // All digits present so add both hyphens.
    return `${areaNumber}-${groupNumber}-${serialNumber}`;
  } else if (groupNumber) {
    // Serial number is missing so don't add last hyphen.
    return `${areaNumber}-${groupNumber}`;
  } else {
    return areaNumber;
  }
};

// Explanation: http://rion.io/2013/09/10/validating-social-security-numbers-through-regular-expressions-2/
export const isValidSsn = (ssnInput) => {
  if (ssnInput.includes('-')) {
    // With hyphens
    return RegExp(
      '^(?!219-09-9999|078-05-1120)(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$'
    ).test(ssnInput);
  } else {
    // Without hyphens
    return RegExp(
      '^(?!219099999|078051120)(?!666|000|9\\d{2})\\d{3}(?!00)\\d{2}(?!0{4})\\d{4}$'
    ).test(ssnInput);
  }
};

export const formatLimitedText = (limit, text) => {
  if (text.length >= limit) {
    return text.substring(0, limit);
  }
};

// Returns true if any of boolObject's fields evaluate to true.
// Recursively called where the values are also objects.
export const reduceBooleanObject = (boolObject) => {
  return _.reduce(
    boolObject,
    (cumulativeTrue, currentValue) => {
      if (typeof currentValue === 'object') {
        return cumulativeTrue || reduceBooleanObject(currentValue);
      } else {
        return cumulativeTrue || currentValue;
      }
    },
    false
  );
};

export const doesObjectHaveNonNullValue = (obj) => {
  return obj && Object.values(obj).filter((v) => !isNullOrUndefined(v)).length;
};

export const isEqualObjectIgnoreUndefined = (thisObj, thatObj) => {
  return (
    _.isEqual(thisObj, thatObj) ||
    _.isEqual(
      JSON.parse(JSON.stringify(thisObj)),
      JSON.parse(JSON.stringify(thatObj))
    )
  );
};

/**
 * Only checks one level deep (no objects).
 * Treats strings and numbers as equal if they can be parsed to the same number.
 * Treats empty string, null, and undefined as equal.
 */
export const isEqualIgnoreType = (thisVar, thatVar) => {
  // Parse and compare both if one is a number
  if (typeof thisVar === 'number' || typeof thatVar === 'number') {
    const parsedThis = Number.parseFloat(thisVar);
    const parsedThat = Number.parseFloat(thatVar);
    return (
      parsedThis === parsedThat ||
      // NaN cannot be compared with equals
      (isNaN(parsedThis) && isNaN(parsedThat))
    );
  } else {
    // Check if they are both false by boolean conversion to catch "", null, undefined
    return (!Boolean(thisVar) && !Boolean(thatVar)) || thisVar === thatVar;
  }
};

/**
 * Iterate through all values of both objects and compare values by key.
 * Shallow comparison on non-objects only.
 */
export const isObjectEqualIgnoreType = (thisObj, thatObj) => {
  const allKeys = _.union(Object.keys(thisObj), Object.keys(thatObj));
  const accumulation = _.reduce(
    allKeys,
    (cumulativeTrue, currentKey) => {
      return (
        cumulativeTrue &&
        isEqualIgnoreType(thisObj[currentKey], thatObj[currentKey])
      );
    },
    true
  );
  return accumulation;
};

export const getAvatarOrDefault = (avatarUrl) => {
  return avatarUrl || placeholderAvatar;
};

export const formatNameObject = ({ firstName, lastName }, username = null) => {
  if (username) {
    return `${firstName || ''} ${lastName || ''} (${username})`;
  } else {
    return `${firstName || ''} ${lastName || ''}`;
  }
};

/**
 * Iterate through all values of both objects and arrays
 * then trim all the string values
 */
export const trimAll = (untrimObject) => {
  if (untrimObject === null || untrimObject === undefined) {
    return untrimObject;
  } else if (Array.isArray(untrimObject)) {
    return untrimObject.map((object) => trimAll(object));
  } else if (typeof untrimObject === 'object') {
    const untrimKeys = Object.keys(untrimObject);
    untrimKeys.forEach((key) => {
      untrimObject[key] = trimAll(untrimObject[key]);
    });
    return untrimObject;
  } else if (
    typeof untrimObject === 'string' ||
    untrimObject instanceof String
  ) {
    return untrimObject.trim();
  } else {
    return untrimObject;
  }
};

/**
 * Returns ture if sting is email
 */
export const isEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
