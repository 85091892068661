/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import {
  FIND_SSO_EMPLOYER_CONFIGURATION_MODULES,
  CREATE_SSO_EMPLOYER_CONFIGURATION_MODULES,
  CREATE_SSO_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  VALIDATE_SSO_URL,
  VALIDATE_SSO_URL_INPROGRESS,
  VALIDATE_SSO_URL_ERROR,
  SAVE_SSO_CONFIGURATION,
  SAVE_SSO_CONFIGURATION_INPROGRESS,
  SAVE_SSO_CONFIGURATION_ERROR,
  GET_SSO_CONFIGURATION,
  GET_SSO_CONFIGURATION_ERROR
} from '../actions/actionTypes';

export default (
  state = {
    configStatus: { id: null, value: 'false', lastUpdatedTs: null },
    validatedMetaDataUrl: {
      validatingStatus: 'NOT_STARTED',
      errorResponse: null,
      isValidated: null,
      responseStatus: null
    },
    savedSSOConfig: {
      savedStatus: 'NOT_STARTED',
      errorResponse: null,
      isSaved: false,
      ssoConfig: null
    },
    ssoConfiguration: {
      errorResponse: null,
      getStatus: 'NOT_STARTED',
      ssoData: null
    }
  },
  { type, payload }
) => {
  switch (type) {
    case FIND_SSO_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        configStatus: payload
      };
    case CREATE_SSO_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        employerConfigCreate: Object.assign(payload)
      };
    case CREATE_SSO_EMPLOYER_CONFIGURATION_MODULES_ERROR:
      return {
        ...state,
        employerConfigCreateError: payload
      };
    case VALIDATE_SSO_URL:
      return {
        ...state,
        validatedMetaDataUrl: {
          validatingStatus: 'SSO_VALIDATION_COMPLETED',
          errorResponse: null,
          isValidated: true,
          responseStatus: payload.status
        }
      };
    case VALIDATE_SSO_URL_INPROGRESS:
      return {
        ...state,
        validatedMetaDataUrl: {
          validatingStatus: 'SSO_VALIDATION_INPROGRESS',
          errorResponse: null,
          isValidated: false
        }
      };
    case VALIDATE_SSO_URL_ERROR:
      return {
        ...state,
        validatedMetaDataUrl: {
          validatingStatus: 'SSO_VALIDATION_FAILED',
          errorResponse: payload,
          isValidated: null,
          responseStatus: payload.response.status
        }
      };
    case SAVE_SSO_CONFIGURATION:
      return {
        ...state,
        savedSSOConfig: {
          savedStatus: 'SAVE_SSO_COMPLETED',
          errorResponse: null,
          isSaved: true,
          ssoConfig: payload
        }
      };
    case SAVE_SSO_CONFIGURATION_INPROGRESS:
      return {
        ...state,
        savedSSOConfig: {
          savedStatus: 'SAVE_SSO_INPROGRESS',
          errorResponse: null,
          isSaved: false
        }
      };
    case SAVE_SSO_CONFIGURATION_ERROR:
      return {
        ...state,
        savedSSOConfig: {
          savedStatus: 'SAVE_SSO_FAILED',
          errorResponse: payload,
          isSaved: false,
          ssoConfig: null
        }
      };
    case GET_SSO_CONFIGURATION:
      return {
        ...state,
        ssoConfiguration: {
          errorResponse: null,
          ssoData: payload,
          getStatus: 'GET_EMPLOYER_SUCCESS'
        },
        savedSSOConfig: {
          savedStatus: 'NOT_STARTED',
          errorResponse: null,
          isSaved: false,
          ssoConfig: null
        }
      };
    case GET_SSO_CONFIGURATION_ERROR:
      return {
        ...state,
        ssoConfiguration: {
          errorResponse: payload,
          ssoData: null,
          getStatus: 'GET_EMPLOYER_FAILED'
        }
      };
    default:
      return {
        ...state
      };
  }
};
