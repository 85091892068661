/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import * as ActionTypes from '../actionTypes/chatbotConfigActionTypes';

const initialState = {
  configStatus: { id: null, value: 'false', lastUpdatedTs: null },
  chatbotConfigStatus: { id: null, value: 'false', lastUpdatedTs: null },
  dbgChatbotConfigStatus: { id: null, value: 'false', lastUpdatedTs: null },
  slackChatbotConfigStatus: { id: null, value: 'false', lastUpdatedTs: null },
  employerChatbotConfigLoading: false,
  employerDBGChatbotConfigLoading: false,
  employerSlackChatbotConfigLoading: false,
  chatbotConfigUpdating: false,
  dbgChatbotConfigUpdating: false,
  slackChatbotConfigUpdating: false
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FIND_MOBILE_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        configStatus: action.payload
      };
    case ActionTypes.FIND_CHATBOT_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        chatbotConfigStatus: action.payload
      };
    case ActionTypes.FIND_DBG_CHATBOT_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        dbgChatbotConfigStatus: action.payload
      };
    case ActionTypes.FIND_SLACK_CHATBOT_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        slackChatbotConfigStatus: action.payload
      };
    case ActionTypes.FIND_CONSULTATION_CONFIGURATION_MODULES:
      return {
        ...state,
        bConsultationConfigStatus: action.payload
      };
    case ActionTypes.CREATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        // Re-assiging the state value to trigger state change at component level
        // since it's always 204 from the backend
        employerConfigCreate: Object.assign(action.payload)
      };
    case ActionTypes.CREATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR:
      return {
        ...state,
        employerConfigCreateError: action.payload
      };
    case ActionTypes.UPDATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES:
      return {
        ...state,
        // Re-assiging the state value to trigger state change at component level
        // since it's always 204 from the backend
        employerConfigUpdate: Object.assign(action.payload)
      };
    case ActionTypes.UPDATE_MOBILE_EMPLOYER_CONFIGURATION_MODULES_ERROR:
      return {
        ...state,
        employerConfigUpdateError: action.payload
      };
    case ActionTypes.GET_CHATBOT_EMPLOYER_IN_PROGRESS:
      return {
        ...state,
        employerChatbotConfigLoading: true
      };
    case ActionTypes.GET_CHATBOT_EMPLOYER_SUCCESS:
      return {
        ...state,
        employerChatbotConfigLoading: false,
        employerChatbot: action.payload
      };
    case ActionTypes.GET_CHATBOT_EMPLOYER_ERROR:
      return {
        ...state,
        employerChatbotConfigLoading: false,
        employerErrorResponse: action.payload
      };

    case ActionTypes.GET_DBG_CHATBOT_EMPLOYER_IN_PROGRESS:
      return {
        ...state,
        employerDBGChatbotConfigLoading: true
      };
    case ActionTypes.GET_DBG_CHATBOT_EMPLOYER_SUCCESS:
      return {
        ...state,
        employerDBGChatbotConfigLoading: false,
        employerChatbot: action.payload
      };
    case ActionTypes.GET_DBG_CHATBOT_EMPLOYER_ERROR:
      return {
        ...state,
        employerDBGChatbotConfigLoading: false,
        employerErrorResponse: action.payload
      };

    case ActionTypes.GET_SLACK_CHATBOT_EMPLOYER_IN_PROGRESS:
      return {
        ...state,
        employerSlackChatbotConfigLoading: true
      };
    case ActionTypes.GET_SLACK_CHATBOT_EMPLOYER_SUCCESS:
      return {
        ...state,
        employerSlackChatbotConfigLoading: false,
        employerChatbot: action.payload
      };
    case ActionTypes.GET_SLACK_CHATBOT_EMPLOYER_ERROR:
      return {
        ...state,
        employerSlackChatbotConfigLoading: false,
        employerErrorResponse: action.payload
      };

    case ActionTypes.UPDATE_EMPLOYER_CHATBOT_CONFIG_IN_PROGRESS:
      return {
        ...state,
        chatbotConfigUpdating: true
      };
    case ActionTypes.UPDATE_EMPLOYER_CHATBOT_CONFIG:
      return {
        ...state,
        chatbotConfigUpdating: false
      };
    case ActionTypes.UPDATE_EMPLOYER_CHATBOT_CONFIG_ERROR:
      return {
        ...state,
        chatbotConfigUpdating: false
      };

    case ActionTypes.UPDATE_EMPLOYER_DBG_CHATBOT_CONFIG_IN_PROGRESS:
      return {
        ...state,
        dbgChatbotConfigUpdating: true
      };
    case ActionTypes.UPDATE_EMPLOYER_DBG_CHATBOT_CONFIG:
      return {
        ...state,
        dbgChatbotConfigUpdating: false
      };
    case ActionTypes.UPDATE_EMPLOYER_DBG_CHATBOT_CONFIG_ERROR:
      return {
        ...state,
        dbgChatbotConfigUpdating: false
      };

    case ActionTypes.UPDATE_EMPLOYER_SLACK_CHATBOT_CONFIG_IN_PROGRESS:
      return {
        ...state,
        slackChatbotConfigUpdating: true
      };
    case ActionTypes.UPDATE_EMPLOYER_SLACK_CHATBOT_CONFIG:
      return {
        ...state,
        slackChatbotConfigUpdating: false
      };
    case ActionTypes.UPDATE_EMPLOYER_SLACK_CHATBOT_CONFIG_ERROR:
      return {
        ...state,
        slackChatbotConfigUpdating: false
      };

    case ActionTypes.REQUEST_EMPLOYER_IN_PROGRESS:
      return {
        ...state,
      };
    case ActionTypes.REQUEST_EMPLOYER_SUCCESS:
      return {
        ...state,
        employerInfo: action.payload
      };
    case ActionTypes.REQUEST_EMPLOYER_ERROR:
      return {
        ...state
      };

    default:
      return {
        ...state
      };
  }
};
