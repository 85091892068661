/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import Notifications from 'components/Notifications';
import './CarrierFiles.scss';
import { uploadCarrierFile } from '../../../../actions/billingAction';
import ButtonFileUploadFormGroup from '../../../../../../components/DataForm/ButtonFileUploadFormGroup';
import moment from 'moment';
import { BILLING_MONTH_FORMAT } from '../../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CarrierFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadFileName: null,
      isValidFile: true,
      fileBlob: null,
      isUploading: false
    };
  }
  static propTypes = {
    employerName: PropTypes.string.isRequired,
    employerId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    selectedFile: PropTypes.object,
    uploadCarrierFile: PropTypes.func,
    uploadingStatus: PropTypes.string,
    progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };
  componentDidMount() {
    const { billingRow } = this.props;
    this.setState({ uploadFileName: billingRow.carrierFileName });
  }
  componentDidUpdate(prevProps, prevState) {
    const { onClose } = this.props;
    if (
      this.props.carrierFilesStatus.uploadingStatus !==
      prevProps.carrierFilesStatus.uploadingStatus
    ) {
      switch (this.props.carrierFilesStatus.uploadingStatus) {
        case 'UPLOAD_ERROR':
          NotificationManager.error('File Upload failed');
          this.setState({ isUploading: false });
          break;
        case 'UPLOAD_COMPLETE':
          this.setState({ isUploading: false });
          NotificationManager.success('File Uploaded Successfully');
          onClose();
          break;
        default:
          break;
      }
    }
  }
  selectFile = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileBlob = new Blob([selectedFile], { type: selectedFile.type });
      this.setState({
        uploadFileName: selectedFile.name,
        file: selectedFile,
        fileBlob: fileBlob
      });
      this.setState({ isPreviewEnable: true });
    }
  };
  attachFiles = () => {
    const { uploadFileName, fileBlob } = this.state;
    const { uploadCarrierFile, employerName } = this.props;
    const { id } = this.props.billingRow;
    if (!uploadFileName.startsWith(employerName)) {
      Notifications.error(
        `Employer name on this zip file does not match this employer, ${employerName}. Please rename the file to start with employer’s name given in the system and try again.`
      );
      return;
    }
    uploadCarrierFile(id, uploadFileName, fileBlob);
    this.setState({ isUploading: true, isPreviewEnable: false });
  };
  renderFileUploadForm = () => {
    const {
      uploadFileName,
      isPreviewEnable,
      isUploading,
      fileBlob
    } = this.state;
    const { carrierFilesStatus } = this.props;
    return (
      <div className="carrier-file-upload-form">
        <ButtonFileUploadFormGroup
          id="billing-file-uploader"
          labelDisplay="Choose File"
          inputName={'carrier-file-uploader'}
          fileLabel={uploadFileName}
          onClickChoose={(event) => this.selectFile(event)}
          acceptedFileTypes=".pdf"
          showLoading={carrierFilesStatus.uploadingStatus === 'UPLOADING'}
          showProgress={fileBlob !== null}
          showRemove={false}
          progress={carrierFilesStatus.progress}
          disabled={isUploading}
          buttonText="File"
        />
        <Button
          color="primary"
          size="lg"
          className="file-submit-button"
          disabled={!isPreviewEnable}
          onClick={this.attachFiles}
        >
          Attach Files &nbsp;
          {isUploading && (
            <FontAwesomeIcon icon="circle-notch" className="mr-2" spin />
          )}
        </Button>
      </div>
    );
  };
  render() {
    const { billingMonth, year } = this.props.billingRow;
    const monthAndYear = `${billingMonth} ${year}`;
    return (
      <div className="carrier-file-upload-content">
        <div className="text-center">
          {moment(monthAndYear, BILLING_MONTH_FORMAT).format(
            BILLING_MONTH_FORMAT
          )}
        </div>
        {this.renderFileUploadForm()}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  uploadCarrierFile: (billRecordId, fileName, fileBlob) =>
    dispatch(uploadCarrierFile(billRecordId, fileName, fileBlob))
});
const mapStateToProps = (state) => {
  const { carrierFilesStatus } = state.billingReducer;
  return {
    carrierFilesStatus
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CarrierFiles);
