/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import React from 'react';
import { Collapse } from 'reactstrap';

import SummaryRow from './SummaryRow';
import PlanSummaryRow from './PlanSummaryRow';
import { BILLING_STATUS_DISPLAY_MAP } from '../../../../constants';

import './SummaryPreview.scss';

class BillStatusSummaryRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { summary } = this.props;
    const { isOpen } = this.state;
    const { status, planSummaries } = summary;

    return (
      <>
        <SummaryRow
          showCollapseIcon={planSummaries}
          name={BILLING_STATUS_DISPLAY_MAP[status]}
          summary={summary}
          className={`group-row ${
            planSummaries ? 'font-weight-bold' : 'font-weight-normal'
          }`}
          onClickIcon={this.toggle}
          isOpen={isOpen}
        />
        {planSummaries && (
          <Collapse isOpen={isOpen} className="collapse-summary">
            {planSummaries.map((planSummary) => (
              <PlanSummaryRow
                key={`${status}_${planSummary.planName}`}
                summary={planSummary}
              />
            ))}
          </Collapse>
        )}
      </>
    );
  }
}

export default BillStatusSummaryRow;
