/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
export const UPLOAD_PLAN_DOCUMENT_IN_PROGRESS = 'UPLOAD_PLAN_DOCUMENT_IN_PROGRESS';
export const UPLOAD_PLAN_DOCUMENT_COMPLETED = 'UPLOAD_PLAN_DOCUMENT_COMPLETED';
export const UPLOAD_PLAN_DOCUMENT_FAILED = 'UPLOAD_PLAN_DOCUMENT_FAILED';

export const DELETE_PLAN_DOCUMENT_IN_PROGRESS = 'DELETE_PLAN_DOCUMENT_IN_PROGRESS';
export const DELETE_PLAN_DOCUMENT_COMPLETED = 'DELETE_PLAN_DOCUMENT_COMPLETED';
export const DELETE_PLAN_DOCUMENT_FAILED = 'DELETE_PLAN_DOCUMENT_FAILED';
