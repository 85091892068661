export const MODULE_BENADMIN_INTEGRATION_ENABLED =
  'MODULE_BENADMIN_INTEGRATION_ENABLED';

export const UPDATE_SUCCESS = 204;
export const CREATE_SUCCESS = 200;

export const FLOCK_BENADMIN_TYPE = 'flock';

export const ADAPTER_NAMES = {
  FLOCK: {
    name: 'Flock',
    code: 'FLOCK'
  },
  PLATFORM: {
    name: 'Platform',
    code: 'PLATFORM'
  },
  WORKDAY_BENADMIN: {
    name: 'Workday',
    code: 'WORKDAY_BENADMIN'
  },
};

export const SYNC_TYPE = {
  EMPLOYEE_SYNC: 'EMPLOYEE_SYNC',
  ENROLLMENT_SYNC: 'ENROLLMENT_SYNC'
};

export const SYNC_STATUS_FILTER = {
  FAILED: 'Error',
  PARTIAL_SUCCESS: 'Request to Process',
  SUCCESS: 'Success'
};

export const BENADMIN_MENU_ACTIONS = {
  DOWNLOAD_EMPLOYEE_SYNC_DETAILS: 'DOWNLOAD_EMPLOYEE_SYNC_DETAILS',
  DOWNLOAD_ENROLLMENT_SYNC_DETAILS: 'DOWNLOAD_ENROLLMENT_SYNC_DETAILS',
  VIEW_SYNC_DETAILS: 'VIEW_SYNC_DETAILS'
};

export const EXPRESSION_PATTERN = {
  TIME_FORMAT: '.00',
  START: '0 0 ',
  END: ' * * ?'
};

export const SCHEDULE_TIME_SLOTS = [
  '00.00',
  '01.00',
  '02.00',
  '03.00',
  '04.00',
  '05.00',
  '06.00',
  '07.00',
  '08.00',
  '09.00',
  '10.00',
  '11.00',
  '12.00',
  '13.00',
  '14.00',
  '15.00',
  '16.00',
  '17.00',
  '18.00',
  '19.00',
  '20.00',
  '21.00',
  '22.00',
  '23.00'
];
