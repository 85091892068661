import React from 'react';
import { FormGroup, FormFeedback, Label, CustomInput } from 'components/Atoms';

const FileFormGroup = (props) => {
  const {
    labelDisplay,
    inputName,
    onChange,
    fileLabel,
    acceptedFileTypes,
    isInvalid,
    feedback
  } = props;
  return (
    <FormGroup>
      <Label for={inputName}>{labelDisplay}</Label>
      <CustomInput
        className={`lumity-browse form-control upload ${
          isInvalid === true ? 'is-invalid' : ''
        }`}
        type="file"
        id={inputName}
        label={fileLabel}
        onChange={onChange}
        onClick={(event) => {
          event.target.value = '';
        }}
        accept={
          typeof acceptedFileTypes === 'object'
            ? acceptedFileTypes.join(',')
            : acceptedFileTypes
        }
      />
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
    </FormGroup>
  );
};

export default FileFormGroup;
