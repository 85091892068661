/**********************************************************************************************************************
 * Copyright (C) 2020 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import axios from 'axios';
import Util, {
  CONTENT_TYPE_MULTIPART_FORM,
  CONTENT_TYPE_HEADER
} from '../util/apiUtil';
import { BENEFIT_KIND_ADDITIONAL_PERK } from '../constants/benefitConstants';

//configs
const baseURL = `${Util.baseApi}/${Util.version}/plans/perks`;

export default {
  /**
   * get all additional perkss
   *
   * @returns {AxiosPromise<any>}
   */
  findAll(employerId, selectedPlanYearIds, page, size, sort, query) {
    return axios.get(
      `${baseURL}/roster?employer-id=${employerId}&plan-year-id=${selectedPlanYearIds}&${Util.getPagingQueryString(
        page,
        size,
        sort,
        query
      )}`
    );
  },

  /**
   * get all basic plan info
   *
   * @param employerId
   * @returns {AxiosPromise<any>}
   */
  findAllBasicPlanInfo(employerId, planYearId) {
    return axios.get(
      `${baseURL}/basic?employerId=${employerId}&benefitCategory=${BENEFIT_KIND_ADDITIONAL_PERK}&planYearId=${planYearId}`
    );
  },

  /**
   * get all basic plan info inclduing archived plans
   *
   * @param employerId
   * @returns {AxiosPromise<any>}
   */
  findAllBasicPlanInfoWithArchived(employerId, planYearId) {
    return axios.get(
      `${baseURL}/basic?employerId=${employerId}&benefitCategory=${BENEFIT_KIND_ADDITIONAL_PERK}&withArchived=true&planYearId=${planYearId}`
    );
  },

  /**
   * find plan by id.
   *
   * @param id - plan id.
   * @returns {AxiosPromise<any>}
   */
  findById(id) {
    return axios.get(`${baseURL}/${id}`);
  },

  /**
   * create new additional perks.
   *
   * @param payload - Plans Payload
   * @returns {AxiosPromise<any>}
   */
  create(payload) {
    const newPayload = { ...payload };
    const formData = new FormData();
    if (newPayload.file) {
      formData.append('file', newPayload.file, newPayload.fileName);
    }
    delete newPayload.file;
    delete newPayload.fileName;

    let keys = Object.keys(newPayload);
    keys.forEach((key) => {
      if(key === 'planCloneDetails'){
        let planClonesData = newPayload[key];
        formData.append('clonedPlanId', planClonesData.clonedPlanId);
        formData.append('clonedPlanMasterId', planClonesData.clonedPlanMasterId);
        formData.append('clonedPlanRevision', planClonesData.clonedPlanRevision);
      } else {
        formData.append(key, newPayload[key]);
      }
    });
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };
    return axios.post(`${baseURL}`, formData, config);
  },

  /**
   * update existing additional perks.
   *
   * @param payload - Plans Payload
   * @returns {AxiosPromise<any>}
   */
  update(payload, id) {
    const newPayload = { ...payload };

    delete newPayload.createdTs;
    delete newPayload.lastUpdatedTs;
    const formData = new FormData();

    if (newPayload.file) {
      formData.append('file', newPayload.file, newPayload.fileName);
    }

    delete newPayload.file;
    delete newPayload.fileName;

    let keys = Object.keys(newPayload);
    keys.forEach((key) => {
      formData.append(key, newPayload[key]);
    });
    const config = {
      headers: {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM
      }
    };

    return axios.put(`${baseURL}/${id}`, formData, config);
  },

  /**
   * search all additional perkss for given employer ID and search query.
   * @param employerId - Employer ID.
   * @param query - Search Query.
   */
  search(employerId, query) {
    const params = {
      'employer-id': employerId
    };
    if (query) {
      params['name'] = encodeURIComponent(query);
    }

    return axios.get(baseURL, { params: params });
  },

  /**
   * delete existing plan.
   *
   * @param employerId 
   * @returns {AxiosPromise<any>}
   */
   delete(employerId, planMasterId) {
    return axios.delete(
      `${baseURL}/employers/${employerId}/plan?planMasterId=${planMasterId}`
    );
  },

  /**
   * validate before delete existing plan.
   *
   * @param employerId 
   * @returns {AxiosPromise<any>}
   */
  validate(employerId, planMasterId) {
    return axios.get(
      `${baseURL}/employers/${employerId}/plan/delete-validate?planMasterId=${planMasterId}`
    );
  }
};
