/**********************************************************************************************************************
 * Copyright (C) 2019-2020 Lumity Inc - All Rights Reserved                                                           *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import * as ActionTypes from '../actionTypes/roles.actionTypes';

const initialState = {
  employeeRolesList: {
    roles: [],
    employerId: '',
    isFetching: false,
    error: null
  },
  updateUserRolesStatus: {
    inProgress: false,
    error: null
  }
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_EMPLOYEE_ROLES_LIST_IN_PROGRESS:
      return {
        ...state,
        employeeRolesList: {
          roles: [],
          employerId: '',
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_EMPLOYEE_ROLES_LIST_COMPLETED:
      return {
        ...state,
        employeeRolesList: {
          ...state.employeeRolesList,
          isFetching: false,
          roles: action.roles,
          employerId: action.employerId
        }
      };
    case ActionTypes.GET_EMPLOYEE_ROLES_LIST_FAILED:
      return {
        ...state,
        employeeRolesList: {
          ...state.employeeRolesList,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.UPDATE_EMPLOYEE_USER_ROLES_IN_PROGRESS:
      return {
        ...state,
        updateUserRolesStatus: {
          ...state.updateUserRolesStatus,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_EMPLOYEE_USER_ROLES_COMPLETED:
      return {
        ...state,
        updateUserRolesStatus: {
          ...state.updateUserRolesStatus,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.UPDATE_EMPLOYEE_USER_ROLES_FAILED:
      return {
        ...state,
        updateUserRolesStatus: {
          ...state.updateUserRolesStatus,
          inProgress: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default rolesReducer;
