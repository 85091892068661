/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/
import filesize from 'filesize';
import FileSaver from 'file-saver';

// Converts byteSize number into human-readable String of higher units such as KB or MB.
export const parseByteSize = (byteSize) => {
  const options = { round: 2 };
  return filesize(byteSize, options);
};

export const getReadableFileSizeString = (size) => {
  if (size === 0) return '0 B';
  const logFileSize = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, logFileSize)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][logFileSize]
  );
};

// Save the source of the file (Blob or URL) as the specified name if provided
export const saveFileAs = (source, filename) => {
  FileSaver.saveAs(source, filename);
};

export const buildDocumentSearchQuery = (data) => {
  let queryArray = [];
  if (data.employerId) {
    queryArray.push(`employerId=${data.employerId}`);
  }
  if (data.documentId) {
    queryArray.push(`documentId=${data.documentId}`);
  }
  if (data.planYearIds) {
    queryArray.push(`planYearIds=${data.planYearIds}`);
  }
  return queryArray.join('&');
};
