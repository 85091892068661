import React from 'react';
import { connect } from 'react-redux';

import { Button, Table } from 'components/Atoms';

import './Preview.scss';

export const VALIDATION_PASSED = 'COMPLETE';
export const VALIDATION_SKIPPED = 'SKIPPED';
export const VALIDATION_INCOMPLETE = 'INCOMPLETE';

class ValidationTable extends React.Component {
  render() {
    const { benchmark, onEditClick, status, isReadOnly } = this.props;

    return (
      <div>
        <Table className="validation-table">
          <thead className="validation-table-head">
            <tr>
              <th className="validation-table-head-col wf-175">Status</th>
              <th className="validation-table-head-col">Content</th>
              <th className="validation-table-head-col"></th>
            </tr>
          </thead>
          <tbody>
            <SummaryPageRow
              status={status}
              benchmark={benchmark}
              onEditClick={() => onEditClick()}
              isReadOnly={isReadOnly}
            />
          </tbody>
        </Table>
      </div>
    );
  }
}

const SummaryPageRow = (props) => {
  const { status, onEditClick, isReadOnly } = props;

  const styleClass =
    status === VALIDATION_INCOMPLETE ? 'validation-error-row' : '';
  return (
    <tr className={styleClass}>
      <td className="validation-table-row wf-175">
        <Status status={status} />
      </td>
      <td className="benchmark-content-text validation-table-row">
        Summary Page
      </td>
      <td className="validation-table-row text-right">
        {!isReadOnly && (
          <Button
            color="link"
            onClick={() => {
              onEditClick();
            }}
          >
            Edit
          </Button>
        )}
      </td>
    </tr>
  );
};

const Status = (props) => {
  return (
    <>
      {props.status === VALIDATION_SKIPPED && (
        <div className="validation-status disabled">
          <span>SKIPPED</span>
        </div>
      )}
      {props.status === VALIDATION_PASSED && (
        <div className="validation-status passed">
          <span>COMPLETED</span>
        </div>
      )}
      {props.status === VALIDATION_INCOMPLETE && (
        <div className="validation-status incomplete">
          <span>PENDING</span>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => {
  const { benchmark } = state.benchmarkReducer;

  return {
    benchmark
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationTable);
