/**********************************************************************************************************************
 * Copyright (C) 2019 Lumity Inc - All Rights Reserved                                                                *
 *
 * CONFIDENTIAL                                                                                                       *
 *
 * All information contained herein is, and remains the property of Lumity Inc and its partners,                      *
 * if any.  The intellectual and technical concepts contained herein are proprietary to Lumity Inc  and its           *
 * partners and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or  *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from Lumity Inc.                                                              *
 *                                                                                                                    *
 *                                                                                                                    *
 **********************************************************************************************************************/

import EmployerFeatureConfigurationService from 'services/EmployerFeatureConfigurationService';
import EmployerService from 'services/EmployerService';

import {
  FIND_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  CREATE_EMPLOYER_CONFIGURATION_MODULES,
  CREATE_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  UPDATE_EMPLOYER_CONFIGURATION_MODULES,
  UPDATE_EMPLOYER_CONFIGURATION_MODULES_ERROR,
  FIND_EMPLOYER_CONFIGURATION_ISSUE_LOG_ACCOUNT_LEVEL,
  FIND_EMPLOYER_CONFIGURATION_ISSUE_LOG_EMPLOYEE_LEVEL,
  GET_ISSUE_LOG_EMPLOYER,
  GET_ISSUE_LOG_EMPLOYER_ERROR
} from '../actionTypes/issueLog.actionTypes';

import { MODULE_ISSUES_LOG_ACCOUNT_LEVEL, MODULE_ISSUES_LOG_EMPLOYEE_LEVEL, UPDATE_SUCCESS, CREATE_SUCCESS}  from '../constants';

export const findConfigModules = (employerId) => {
  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.findConfigModules(employerId);
      response.data.forEach(config => {
        switch (config.key) {
          case MODULE_ISSUES_LOG_ACCOUNT_LEVEL:
            dispatch({ type: FIND_EMPLOYER_CONFIGURATION_ISSUE_LOG_ACCOUNT_LEVEL, payload: config });
            break;
          case MODULE_ISSUES_LOG_EMPLOYEE_LEVEL:
            dispatch({ type: FIND_EMPLOYER_CONFIGURATION_ISSUE_LOG_EMPLOYEE_LEVEL, payload: config });
            break;

          default:
            break;
        }
      });
    } catch (error) {
      dispatch({ type: FIND_EMPLOYER_CONFIGURATION_MODULES_ERROR, payload: error });
    }
  };
};

export const createConfigModule = (configKey, employerId, enableValue) => {
  const config = {
    employerId: employerId,
    key: configKey,
    value: String(enableValue === 'false')
  };

  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.createConfigModule(config);
      const isSuccess = response.status === CREATE_SUCCESS;
      dispatch({ type: CREATE_EMPLOYER_CONFIGURATION_MODULES, payload: isSuccess });
    } catch (error) {
      dispatch({ type: CREATE_EMPLOYER_CONFIGURATION_MODULES_ERROR, payload: error });
    }
  };
};

export const updateConfigModule = (configId, configKey, employerId, enableValue) => {
  const config = {
    id: configId,
    employerId: employerId,
    key: configKey,
    value: String(enableValue === 'false')
  };

  return async (dispatch) => {
    try {
      const response = await EmployerFeatureConfigurationService.updateConfigModule(config);
      const isSuccess = response.status === UPDATE_SUCCESS;
      dispatch({ type: UPDATE_EMPLOYER_CONFIGURATION_MODULES, payload: isSuccess });
    } catch (error) {
      dispatch({ type: UPDATE_EMPLOYER_CONFIGURATION_MODULES_ERROR, payload: error });
    }
  };
};

export const switchOffBothAccountAndAnalytics = (
  accLevelIssueConfigId,
  employeeLevelIssueConfigId,
  accLevelKey,
  employeeLevelKey,
  employerId
) => {
  return async (dispatch) => {
    try {
      let response = await EmployerFeatureConfigurationService.updateConfigModule(
        {
          id: accLevelIssueConfigId,
          employerId: employerId,
          key: accLevelKey,
          value: 'false'
        }
      );
      response = await EmployerFeatureConfigurationService.updateConfigModule({
        id: employeeLevelIssueConfigId,
        employerId: employerId,
        key: employeeLevelKey,
        value: 'false'
      });
      const isSuccess = response.status === UPDATE_SUCCESS;
      dispatch({
        type: UPDATE_EMPLOYER_CONFIGURATION_MODULES,
        payload: isSuccess
      });
    } catch (error) {
      dispatch({
        type: UPDATE_EMPLOYER_CONFIGURATION_MODULES_ERROR,
        payload: error
      });
    }
  };
};

export const getEmployer = (employerId) => {
  return async (dispatch) => {
    try {
      const response = await EmployerService.findById(employerId);
      dispatch({ type: GET_ISSUE_LOG_EMPLOYER, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_ISSUE_LOG_EMPLOYER_ERROR, payload: error });
    }
  };
};